import React, { useCallback, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import * as yup from "yup";
import { useAppDispatch } from "../../hooks/hooks";
import {
  updateElementValueUserProfileReducer,
  userPasswordReset,
  useUserProfileReducer,
} from "../../redux/slice/userProfileListSlice";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import AlertMessage from "../common/AlertMessage";
import ButtonComponent from "../common/ButtonComponent";
import { isValidPassword } from "../common/CommonUtil";
import TextInputComponent from "../common/TextInputComponent";

let defaultCountySchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required.")
    .test("check-password", function (password) {
      if (password !== "" && !isValidPassword(password)) {
        return this.createError({
          message:
            "Password must contain at least 8 characters including at least one special character and one numeric value",
        });
      } else {
        return true;
      }
    }),
  confirmPassword: yup.string().required("Confirm password is required."),
  passwordConfirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Password and confirm password does not match."
    ),
});

interface UserPassWordResetProps {
  show: boolean;
  onHide: () => void;
  children?: React.ReactNode;
  userName: string;
}

const UserPassWordReset: React.FC<UserPassWordResetProps> = (props) => {
  const { show, onHide, children, userName } = props;
  const [enableFileName, setEnableFileName] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [messageVariant, setAlertMessageVariant] =
    React.useState<string>("danger");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [resetViaEmail, setResetViaEmail] = React.useState(true);
  const [enableRestFields, setEnableRestFields] = React.useState(false);
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const userProfileReducer = useUserProfileReducer();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userProfileReducer.resetSuccessMessage) {
      errorMessageAlert.clearError();
      setAlertMessage(userProfileReducer.resetSuccessMessage);

      dispatch(
        updateElementValueUserProfileReducer({
          elementName: "resetSuccessMessage",
          value: "",
        })
      );
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    } else if (userProfileReducer.resetErrorMessage) {
      let error: string[] = [userProfileReducer.resetErrorMessage];
      errorMessageAlert.handleError(error);
    }
  }, [userProfileReducer.resetSuccessMessage, dispatch]);

  useEffect(() => {
    if (userProfileReducer.resetErrorMessage) {
      let error: string[] = [userProfileReducer.resetErrorMessage];
      errorMessageAlert.clearError();
      errorMessageAlert.handleError(error);

      dispatch(
        updateElementValueUserProfileReducer({
          elementName: "resetErrorMessage",
          value: "",
        })
      );
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [userProfileReducer.resetErrorMessage, dispatch]);

  const onClickHandle = (event: any) => {
    setResetViaEmail(event.target.checked);
    setEnableRestFields(!event.target.checked);
  };

  const onChangePassword = (event: any) => {
    setPassword(event.target.value);
  };

  const onChangeConfirmPassword = (event: any) => {
    setConfirmPassword(event.target.value);
  };

  const hidePopup = () => {
    onHide();
    setEnableRestFields(false);
    errorMessageAlert.clearError();
    setAlertMessage("");
    setPassword("");
    setConfirmPassword("");
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const handleSubmit = async () => {
    let dataForVal = {
      password: password,
      confirmPassword: confirmPassword,
      passwordConfirmation: confirmPassword,
    };

    let dataObj = {
      username: userName,
      password: password,
      oldPassword: "",
    };
    try {
      await defaultCountySchema.validate(dataForVal, {
        abortEarly: false,
      });
      dispatch(userPasswordReset(dataObj));
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => hidePopup()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <b>Reset Password</b>
      </Modal.Header>
      <ErrorMessageAlert
        messages={errorMessageAlert.messages}
        clearError={errorMessageAlert.clearError}
      />
      <AlertMessage
        message={alertMessage}
        onClose={() => setAlertMessage("")}
      />
      <Modal.Body>
        {/* <Row>
                    <Col>
                        <Form.Check
                            inline
                            name="group1"
                            type="checkbox"
                            className="fw-bold me-2"
                            defaultChecked={resetViaEmail}
                            onChange={onClickHandle}
                        />
                        <label>
                            Reset password through email
                        </label>
                    </Col>
                </Row> */}
        <Row className="mt-3">
          <Col>
            <TextInputComponent
              type="text"
              className="form-control"
              placeholder="Email"
              name="ePassword"
              disabled
              value={userName}
              //onChange={(e) => handleValChange(e)}
            />
          </Col>
        </Row>
        {/* {enableRestFields ? (
                    <> */}
        <Row className="mt-3">
          <Col>
            <TextInputComponent
              type="password"
              className="form-control"
              placeholder="Enter Password"
              name={Date.now().toString()}
              //value={username}
              onChange={(e) => onChangePassword(e)}
              autoComplete="new-password"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <TextInputComponent
              type="password"
              className="form-control"
              placeholder="Enter Confirm Password"
              name={Date.now().toString()}
              //value={username}
              onChange={(e) => onChangeConfirmPassword(e)}
              autoComplete="new-password"
            />
          </Col>
        </Row>
        {/* </>
                ) : (
                    ""
                )} */}
        <hr />
        <Row className="g-2">
          <Col>
            <ButtonComponent
              variant="primary"
              onClick={handleSubmit}
              className="w-100"
            >
              <RiCheckFill /> Reset Password
            </ButtonComponent>
          </Col>
          <Col>
            <ButtonComponent
              variant="danger"
              onClick={() => hidePopup()}
              className="w-100"
            >
              <RiCloseFill /> Cancel
            </ButtonComponent>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UserPassWordReset;
