import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { logoutUser } from "./loginSlice";

interface webRmsState {
  webRmsError?: string;
  status: LoadingType;

  webrmsList: [];
  complaintList : [];
  witnessList : [];
}

const initialState = {
  webRmsError: undefined,
  status: "idle",

  webrmsList: [],
  complaintList: [],
  witnessList : []
} as webRmsState;

export const getWebRmsList = createAsyncThunk<
  any,
  {
    incidentData : any;
  },
  { state: RootState; rejectValue: string }
>(
  "webRMS/getWebRmsList",
  async (incidentData, { getState, rejectWithValue, dispatch }) => {

    let { token } = getState().login;
    
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "soapservice/getWebRmsList",
        token,
        type: "POST",
        body: incidentData,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      }
      const error = await response.text();
      return rejectWithValue(
        error || "Error while fetching Lookup Web RMS data"
      );
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching Lookup Web RMS data"
      );
    }
  }
);

const webRmsSlice = createSlice({
  name: "webRms",
  initialState,
  reducers: {
    updateElementValueWebRmsReducer: updateElementValue,
  },
  extraReducers(builder) {
    builder
      .addCase(getWebRmsList.pending, (state) => {
        state.status = "loading";
        state.webRmsError = undefined;
      })
      .addCase(getWebRmsList.fulfilled, (state, action) => {
        state.status = "success";
        state.webrmsList = action.payload;
      })
      .addCase(getWebRmsList.rejected, (state, action) => {
        state.status = "error";
        state.webRmsError = undefined;
      });
  },
});

export const { updateElementValueWebRmsReducer } = webRmsSlice.actions;

export const useWebRmsReducer = () => useAppSelector((state) => state.webRms);

export default webRmsSlice.reducer;
