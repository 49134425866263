import React, { useCallback, useEffect, useState, useRef } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import ButtonComponent from "../common/ButtonComponent";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import TextInputComponent from "../common/TextInputComponent";
import { getUserDataByUsername, useUserChangeProfileReducer, updateElementValueUserChangeProfileReducer } from "../../redux/slice/userChangeProfileSlice";
import { useLoginReducer, logoutUser } from "../../redux/slice/loginSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { twoFactorRegistration, twoFactorRegistrationviaText } from "../../redux/slice/userProfileSlice"
import { getLoggedInUserCounties, useCountyListReducer } from "../../redux/slice/countyListSlice"
import AlertMessage from "../common/AlertMessage";
import CheckBoxComponent from "../common/CheckBoxComponent";

import {
  RiArrowLeftSFill,
  RiArrowRightSFill,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import { isEmailValidation, isValidPhone } from "../common/CommonUtil";
import * as yup from "yup";

interface TwoFactorAuthenticationPopupProps {
  show: boolean;
  onHide: () => void;
}

interface LocalState {
  windowNumber: number;
  optionValue: string;
}
const initialState: LocalState = {
  windowNumber: 1,
  optionValue: "",
};

export const TwoFactorAuthenticationPopup: React.FC<
  TwoFactorAuthenticationPopupProps
> = (props) => {

  let defaultTwoFactorEmailSchema = yup.object().shape({
    twofactorEmail: yup
      .string()
      .required("Email is required.")
      .test("check-email", function (value) {
        if (!isEmailValidation(userChangeProfileReducer.twofactorEmail)) {
          return this.createError({
            message: "Please insert a valid Email Address",
          });
        } else {
          return true;
        }
      }),
  })

  let defaultTwoFactorMobileSchema = yup.object().shape({
    twofactorPhone: yup
      .string()
      .required("Mobile Number is required.")
      .test("validate-mobileNo", function (value) {
        if (
          userChangeProfileReducer.twofactorPhone.length > 0 &&
          !isValidPhone(userChangeProfileReducer.twofactorPhone)
        ) {
          return this.createError({
            message: "Please insert a valid Mobile Number",
          });
        } else {
          return true;
        }
      }),
  })


  const setState = useCallback((e: Partial<LocalState>) => {
    _((s) => {
      return { ...s, ...e } as LocalState;
    });
  }, []);
  const dispatch = useAppDispatch();
  const loginReducer = useLoginReducer();
  const userChangeProfileReducer = useUserChangeProfileReducer();
  const { onHide } = props;
  const errorMessageAlert = useAlert();
  const [state, _] = React.useState(initialState);
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [combinedValue, setCombinedValue] = useState('');
  const [userVerificationCode, setUserVerificationCode] = useState('');
  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const input3Ref = useRef<HTMLInputElement>(null);
  const input4Ref = useRef<HTMLInputElement>(null);
  const input5Ref = useRef<HTMLInputElement>(null);
  const input6Ref = useRef<HTMLInputElement>(null);
  const [errorKeys, setErrorKeys] = useState([""]);
  const countyListReducer = useCountyListReducer();
  const [isDupageIncluded, setIsDupageIncluded] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [isSelectedPhone, setIsSelectedPhone] = useState(false);
  const [isSelectedEmail, setIsSelectedEmail] = useState(false);

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const setCookie = (name: string, value: number, days: number) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    const expires = `expires=${expirationDate.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  useEffect(() => {
    if (loginReducer.isValid) {
      dispatch(getLoggedInUserCounties());
      dispatch(getUserDataByUsername({
        username: loginReducer.username,
      }));
      if (loginReducer.twoFactorEnable) {
        if (loginReducer.twoFactorType === "Email") {
          setState({
            optionValue: "email",
          })
        } else if (loginReducer.twoFactorType === "Phone") {
          setState({
            optionValue: "phone",
          })
        }
      }
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    setIsDupageIncluded(countyListReducer.countyList.some((county) => county.countyName === 'DUPAGE'));
  }, [countyListReducer.loggedInUserCountyFetchStatus === "success"]);

  const generateRandomCode = (): string => {
    const codeLength = 6;
    let code = '';

    for (let i = 0; i < codeLength; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      code += randomDigit.toString();
    }
    setUserVerificationCode(code);
    return code;
  };

  const onHidePopup = () => {
    setState({ ...initialState });
    onHide();
  };

  const logout = () => {
    dispatch(logoutUser());
  };

  const SendOTP = async () => {
    clearAlert();
    try {
      let verifyCode = generateRandomCode();
      if (isSelectedPhone || state.optionValue === "phone") {
        let Obj = userChangeProfileReducer
        await defaultTwoFactorMobileSchema.validate(Obj, { abortEarly: false, });
        setOtp(verifyCode);
        setMinutes(1);
        setSeconds(30);
        const convertedPhoneNumber = userChangeProfileReducer.twofactorPhone.replace(/-/g, '');
        const currentUrl = window.location.origin;
        dispatch(twoFactorRegistrationviaText({
          mobile: convertedPhoneNumber,
          verificationCode: verifyCode,
          county: isDupageIncluded ? "DUPAGE" : "Other",
          url: currentUrl,
        }
        )).then((res) => {
          setAlertMessage(res.payload.message);
          setIsSent(true);
        })
      } else if (isSelectedEmail || state.optionValue === "email") {
        let Obj = userChangeProfileReducer
        await defaultTwoFactorEmailSchema.validate(Obj, { abortEarly: false, });
        setOtp(verifyCode);
        setMinutes(1);
        setSeconds(30);
        dispatch(twoFactorRegistration({
          email: userChangeProfileReducer.twofactorEmail,
          verificationCode: verifyCode,
          name: userChangeProfileReducer.firstName + " " + userChangeProfileReducer.lastName,
          mailtype: "Authentication"
        }
        )).then((res) => {
          setAlertMessage(res.payload.message);
          setIsSent(true);
        })
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const handleValChangeCheck = (event: any) => {
    if ("chkPhone" === event.target.name) {
      setIsSelectedPhone(true);
      setIsSelectedEmail(false)
    } else if ("chkEmail" === event.target.name) {
      setIsSelectedPhone(false);
      setIsSelectedEmail(true);
    }
  };

  const clearAlert = () => {
    setErrorKeys(['']);
    handleError([''], []);
    setAlertMessage("");
  }

  function hideEmail(email: string) {
    if (!email) {
      return '';
    } else if (email.length > 2) {
      return email.substring(0, 2) + '*'.repeat(email.length - 2) + email.substring(email.indexOf('@'));
    }
  }

  const hidePhoneNumber = (phoneNumber: string) => {
    const visibleLength = 4;
    const hiddenLength = phoneNumber.length - visibleLength;

    return phoneNumber.slice(0, hiddenLength).replace(/./g, '*') + phoneNumber.slice(hiddenLength);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header>
        <b>Two Factor Authentication</b>
      </Modal.Header>
      <Modal.Body>
        {errorMessageAlert.messages.length > 0 ? (
          <div className="p-3">
            <ErrorMessageAlert
              messages={errorMessageAlert.messages}
              clearError={errorMessageAlert.clearError}
            />
          </div>
        ) : (
          ""
        )}
        <AlertMessage
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />
        <section className="multi_step_form">
          <form id="authform">
            {state.windowNumber === 1 ? (
              <section>
                {loginReducer.twoFactorType === "Both" ?
                  <p><b>
                    Protecting your account is our top priority. Please select a method to verify your two factor authentication.
                    We will send you the verification code to the selected method.
                    Input the code to verify.</b>
                  </p> :
                  <p><b>
                    Protecting your account is our top priority.
                    Please confirm your account for the registered two-factor authentication type.</b>
                  </p>
                }
                <br />
                {loginReducer.twoFactorType === "Both" ?
                  <div>
                    <Row>
                      <Col xs={"auto"}>
                        <CheckBoxComponent
                          label={hidePhoneNumber(userChangeProfileReducer.twofactorPhone)}
                          name="chkPhone"
                          checked={isSelectedPhone}
                          // isError={errorKeys.includes("chkImpoundTest")}
                          onChange={handleValChangeCheck}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={"auto"}>
                        <CheckBoxComponent
                          label={hideEmail(userChangeProfileReducer.twofactorEmail)}
                          name="chkEmail"
                          checked={isSelectedEmail}
                          // isError={errorKeys.includes("chkImpoundTest")}
                          onChange={handleValChangeCheck}
                        />
                      </Col>
                    </Row>
                    <ButtonComponent variant="danger" className="ms-3" onClick={SendOTP} disabled={seconds > 0 || minutes > 0}>
                      Send Code
                    </ButtonComponent>
                  </div>
                  :
                  <Row className="g-0 justify-content-md-center">
                    <Col xs={8} className="d-flex align-items-center">
                      {state.optionValue === "phone" ?
                        <TextInputComponent
                          placeholder="xxx-xxxx-xxxx"
                          className="mt-1"
                          value={hidePhoneNumber(userChangeProfileReducer.twofactorPhone)}
                          isError={errorKeys.includes('twofactorPhone')}
                        />
                        : <TextInputComponent
                          placeholder="sample@sample.com"
                          className="mt-1"
                          value={hideEmail(userChangeProfileReducer.twofactorEmail)}
                          isError={errorKeys.includes('twofactorEmail')}
                        />}
                      <ButtonComponent variant="danger" className="ms-3" onClick={SendOTP} disabled={seconds > 0 || minutes > 0}>
                        Send Code
                      </ButtonComponent>
                    </Col>
                  </Row>
                }
                {isSent ?
                  <h6 className="pt-5 pb-3">
                    A security code was sent to{" "}
                    {state.optionValue === "phone" ? "your phone" : "your email"}.{" "}
                    <br />
                    Please enter it here.
                  </h6> : ""
                }
                <h5>
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      Resend Verification Code in: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    otp != "" ?
                      <p>Didn't recieve code? <a href="#" onClick={SendOTP}>resend</a></p>
                      : ""
                  )}
                </h5>
                <br />
                <Row className="justify-content-center mt-0">
                  <Col className="d-flex justify-content-center" xs={6}>
                    <TextInputComponent
                      className="text-center"
                      value={combinedValue[0]}
                      onChange={e => {
                        const newValue = e.target.value.slice(0, 6);
                        setCombinedValue(newValue + combinedValue.slice(1));
                        if (newValue.length === 1) {
                          input2Ref.current?.focus();
                        }
                      }}
                      ref={input1Ref}
                    />&nbsp;
                    <TextInputComponent
                      className="text-center"
                      value={combinedValue[1]}
                      onChange={e => {
                        const newValue = e.target.value.slice(0, 6);
                        setCombinedValue(combinedValue.slice(0, 1) + newValue + combinedValue.slice(2));
                        if (newValue.length === 1) {
                          input3Ref.current?.focus();
                        }
                        if (newValue.length === 0) {
                          input1Ref.current?.focus();
                        }
                      }}
                      ref={input2Ref}
                    />&nbsp;
                    <TextInputComponent
                      className="text-center"
                      value={combinedValue[2]}
                      onChange={e => {
                        const newValue = e.target.value.slice(0, 6);
                        setCombinedValue(combinedValue.slice(0, 2) + newValue + combinedValue.slice(3));
                        if (newValue.length === 1) {
                          input4Ref.current?.focus();
                        }
                        if (newValue.length === 0) {
                          input2Ref.current?.focus();
                        }
                      }}
                      ref={input3Ref}
                    />&nbsp;
                    <TextInputComponent
                      className="text-center"
                      value={combinedValue[3]}
                      onChange={e => {
                        const newValue = e.target.value.slice(0, 6);
                        setCombinedValue(combinedValue.slice(0, 3) + newValue + combinedValue.slice(4));
                        if (newValue.length === 1) {
                          input5Ref.current?.focus();
                        }
                        if (newValue.length === 0) {
                          input3Ref.current?.focus();
                        }
                      }}
                      ref={input4Ref}
                    />&nbsp;
                    <TextInputComponent
                      className="text-center"
                      value={combinedValue[4]}
                      onChange={e => {
                        const newValue = e.target.value.slice(0, 6);
                        setCombinedValue(combinedValue.slice(0, 4) + newValue + combinedValue.slice(5));
                        if (newValue.length === 1) {
                          input6Ref.current?.focus();
                        }
                        if (newValue.length === 0) {
                          input4Ref.current?.focus();
                        }
                      }}
                      ref={input5Ref}
                    />&nbsp;
                    <TextInputComponent
                      className="text-center"
                      value={combinedValue[5]}
                      onChange={e => {
                        const newValue = e.target.value.slice(0, 6);
                        setCombinedValue(combinedValue.slice(0, 5) + newValue);
                        if (newValue.length === 0) {
                          input5Ref.current?.focus();
                        }
                      }}
                      ref={input6Ref}
                    />
                  </Col>
                </Row>
              </section>
            ) : (
              ""
            )}
          </form>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Row className="g-2">
          <Col>
            <ButtonComponent
              className="ps-4 pe-2 d-flex align-items-center"
              onClick={() => {
                clearAlert();
                if (state.windowNumber === 1) {
                  handleError([], []);
                  if (combinedValue.length == 6 && combinedValue !== null) {
                    if (userVerificationCode === combinedValue) {
                      handleError([], []);
                      setCookie('TwoFactorAuthentication:' + loginReducer.userProfileId, loginReducer.userProfileId, parseInt(loginReducer.twoFactorAuthPopupExpireDays));
                      onHidePopup();
                    } else {
                      setAlertMessage("");
                      handleError(
                        ["verificationCode"],
                        [
                          "Verification codes doesn't match",
                        ]
                      );
                    }
                  }
                }
              }}
              disabled={!(isSelectedEmail || isSelectedPhone || state.optionValue === "phone" || state.optionValue === "email") ? true : false || ((state.windowNumber === 1) ? ((combinedValue.length == 6 && combinedValue !== null && combinedValue !== "0000") ? false : true) : false)}
            >
              {"Finish"}&nbsp;
              <RiArrowRightSFill />
            </ButtonComponent>
          </Col>
          <Col>
            <ButtonComponent variant="danger" onClick={() => logout()}>
              Cancel
            </ButtonComponent>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
