import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { ErrorType, LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import Cookies from "js-cookie";
import { RELOGIN_ACTION, LOGIN_ACTION } from "../../constants/common";

interface CounterState {
  username: string;
  password: string;
  isValid: boolean;
  token?: string;
  userType: string;
  loginAttempt: number;
  loginError?: ErrorType;
  status: LoadingType;
  canAccessApplicationValue: string;
  userManualList?: any[];
  county?: string;
  countyId?: number;
  badgeNumber: number;
  hideCompletedSW?: boolean;
  receiverName: string[];
  connected: boolean;
  message: string;
  incorrectCredentialError: string;
  firstName: string;
  lastName: string;
  userProfileId: number;
  canViewAuditTrail: boolean;
  canDeleteEComplaint: boolean;
  canCreateSearchWarrant: boolean;
  canCreateEComplaint: boolean;
  canViewDeleteEComplaint: boolean;
  longFormEnable: boolean;
  forgotPwStatus: LoadingType;
  forgotPwMessage: string;
  changePwStatus: LoadingType;
  changePwMessage: string;
  ecUserAgency: any;
  ecIsCompalintSupervised: false;
  canReSubmitEComplaint: boolean;
  canAssignCaseNumbers: boolean;
  canUpdateCMSSyncStatus: boolean;
  twoFactorEnable: boolean;
  twoFactorType: string;
  twoFactorAuthPopupExpireDays: string;
  twoFactorRegPopupExpireDays: string;
  safeTactEnable: boolean;
  loadingpopupString: boolean;

  refreshTokenstatus: string;
  refreshToken: string;
  refreshTokenExpired: boolean;
  accessTokenRenewTime: number;

  reloginStatus: string;
  summonsToAppear: boolean;
  agencyCode: string; //KHL PR 11294 13/02/24
}

const initialState = {
  username: "",
  password: "",
  isValid: false,
  loginAttempt: 0,
  status: "idle",
  canAccessApplicationValue: "",
  receiverName: [""],
  connected: false,
  message: "",
  incorrectCredentialError: "",
  firstName: "",
  lastName: "",
  userProfileId: 0,
  badgeNumber: 0,
  canViewAuditTrail: false,
  canDeleteEComplaint: false,
  canCreateSearchWarrant: false,
  canCreateEComplaint: false,
  canViewDeleteEComplaint: false,
  longFormEnable: false,
  forgotPwStatus: "idle",
  forgotPwMessage: "",
  changePwStatus: "idle",
  changePwMessage: "",
  ecUserAgency: [],
  ecIsCompalintSupervised: false,
  canReSubmitEComplaint: false,
  canAssignCaseNumbers: false,
  twoFactorEnable: false,
  twoFactorType: "NONE",
  twoFactorAuthPopupExpireDays: "",
  twoFactorRegPopupExpireDays: "",
  safeTactEnable: false,
  loadingpopupString: true,

  refreshTokenstatus: "",
  refreshToken: "",
  refreshTokenExpired: false,
  accessTokenRenewTime: 0,

  reloginStatus: "idle",
  summonsToAppear: false,
  agencyCode: "", //KHL PR 11294 13/02/24
} as CounterState;

export const getCurrentAuthUser = createAsyncThunk(
  "users/getCurrentAuthUser",
  async (_, { rejectWithValue, dispatch }) => {
    let token = Cookies.get("token");
    if (!token) return rejectWithValue("No token found");
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "authenticateservice/currentAuthUser",
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const res = await response.json();
        if (res && !res.token) {
          Cookies.remove("token");
          return rejectWithValue("Invalid token");
        }
        return res;
      }
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(response.status);
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error occured, please login again");
    }
  }
);

export const loginUser = createAsyncThunk<
  any,
  {
    username: string;
    password: string;
  }
>("users/loginUser", async (data, { getState, rejectWithValue, dispatch }) => {
  const { username, password } = data;
  dispatch(
    updateElementValueInLoginReducer({
      elementName: "loadingpopupString",
      value: true,
    })
  );
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "authenticateservice/authenticate",
      type: "POST",
      body: {
        username,
        password,
        action: LOGIN_ACTION,
      },
    });
    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      const res = await response.json();
      if (res.token) Cookies.set("token", res.token);
      return res;
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(response.status);
    }
  } catch (error) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error);
  }
});

export const logoutUser = createAsyncThunk<
  any,
  undefined,
  {
    state: RootState;
  }
>("users/logoutUser", async (_, { getState, rejectWithValue, dispatch }) => {
  let { token } = getState().login;
  Cookies.remove("token");
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "authenticateservice/logout",
      type: "POST",
      token: token,
    });
    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      return response.json();
    } else {
      dispatch(showHideLoadingGif(false));
    }
    return rejectWithValue(response.status);
  } catch (error) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue("error");
  }
});

export const forgotPassword = createAsyncThunk<
  any,
  {
    userName: string;
  }
>(
  "users/forgotPassword",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { userName } = data;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "authenticateservice/forgotPassword",
        type: "POST",
        body: userName,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.text();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(response.status);
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk<
  any,
  {
    code: any;
    password: string;
    navigate: any;
  }
>(
  "users/changePassword",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { code, password, navigate } = data;
    let resetData = {
      token: code,
      password: password,
    };
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "authenticateservice/changePassword",
        type: "POST",
        body: resetData,
      });
      if (response.ok) {
        let res = response.text();
        res.then((result) => {
          navigate("/");
        });
        dispatch(showHideLoadingGif(false));
        return res;
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);

export const reNewAccessToken = createAsyncThunk<
  any,
  {
    callingFrom: string;
    navigate: any;
    refreshToken: string;
  },
  {
    state: RootState;
  }
>(
  "users/reNewAccessToken",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;

    const { callingFrom, navigate, refreshToken } = data;
    try {
      const response = await doFetch({
        url: `authenticateservice/refreshToken?&callingFrom=${callingFrom}`,
        type: "POST",
        token: token,
        body: { refreshToken },
      });

      if (response.ok) {
        let res = response.json();
        res.then((result) => {
          if (!result.refreshTokenExpired && result.token === null) {
            dispatch(resetLoginData());
            dispatch(
              updateElementValueInLoginReducer({
                elementName: "incorrectCredentialError",
                value: "Token expired. Please login again.",
              })
            );
            navigate("/login");
          }
        });
        return res;
      }
      return rejectWithValue(response.status);
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

export const tryReLogin = createAsyncThunk<
  any,
  {
    username: string;
    password: any;
  },
  {
    state: RootState;
  }
>("users/tryReLogin", async (data, { getState, rejectWithValue, dispatch }) => {
  let { token } = getState().login;

  const { username, password } = data;
  try {
    const response = await doFetch({
      url: `authenticateservice/authenticate`,
      type: "POST",
      token: token,
      body: { username, password, action: RELOGIN_ACTION },
    });

    if (response.ok) {
      let res = response.json();
      return res;
    }
    return rejectWithValue(response.status);
  } catch (error) {
    return rejectWithValue("error");
  }
});

export const usernameReset = createAsyncThunk<
  any,
  {
    username: string;
    password: string;
    newUsername: string;
  },
  {
    state: RootState;
  }
>("users/usernameReset", async (data, { getState, rejectWithValue, dispatch }) => {
  const { username, password, newUsername } = data;

  let queryParam = "";
  queryParam += `newUsername=${newUsername}`;
  let { token } = getState().login;
  dispatch(showHideLoadingGif(true));
  try {
    const response: any = await doFetch({
      url: `userprofileservices/resetusername?${queryParam}`,
      type: "POST",
      body: {
        username,
        password,
      },
      token: token,
    });
    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      const res = await response.json();
      if (res.token && res.token === "diffPassword") {
        return res;
      } else if (res.token) {
        Cookies.set("token", res.token);
        return res;
      }
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(response.status);
    }
  } catch (error) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error);
  }
});

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateElementValueInLoginReducer: updateElementValue,
    resetLoginData: (state: CounterState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCurrentAuthUser.pending, (state) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(getCurrentAuthUser.fulfilled, (state, action) => {
        if (action.payload.token === null) {
          return {
            ...state,
            ...action.payload,
            status: "success",
            isValid: false,
            error: "Invalid token",
          };
        } else {
          return {
            ...state,
            ...action.payload,
            status: "success",
            isValid: true,
            error: "",
          };
        }
      })
      .addCase(getCurrentAuthUser.rejected, (state, action) => {
        return {
          ...state,
          loginAttempt: state.loginAttempt + 1,
          status: "error",
          isValid: false,
          error: "Invalid token",
        };
      })

      .addCase(loginUser.pending, (state) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        // Handles success
        if (
          action.payload.token === null ||
          action.payload.token === "mismatch"
        ) {
          return {
            ...state,
            ...action.payload,
            status: "success",
            isValid: false,
            error: "",
            incorrectCredentialError: "Invalid Username or Password",
          };
        } else {
          return {
            ...state,
            ...action.payload,
            status: "success",
            isValid: true,
            error: "",
            incorrectCredentialError: "",
          };
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        // Handles rejected state
        return {
          ...state,
          loginAttempt: state.loginAttempt + 1,
          status: "error",
          isValid: false,
          error: action.error.message,
          incorrectCredentialError: "Unknown error. Please contact support.",
        };
      })

      //User Logout
      .addCase(logoutUser.pending, (state) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        // Handles success
        return {
          ...state,
          ...initialState,
          status: "success",
        };
      })
      .addCase(logoutUser.rejected, (state, action) => {
        // Handles rejected state
        return {
          ...state,
          status: "error",
          error: action.error.message,
        };
      })

      // Forgot Password
      .addCase(forgotPassword.pending, (state) => {
        return {
          ...state,
          forgotPwStatus: "loading",
        };
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPwStatus = "success";
        state.forgotPwMessage = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        return {
          ...state,
          forgotPwStatus: "error",
          error: action.error.message,
        };
      })

      // Change Password
      .addCase(changePassword.pending, (state) => {
        return {
          ...state,
          changePwStatus: "loading",
        };
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePwStatus = "success";
        state.changePwMessage = action.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        return {
          ...state,
          changePwStatus: "error",
          error: action.error.message,
        };
      })
      //Refresh Token
      .addCase(reNewAccessToken.pending, (state) => {
        return {
          ...state,
          refreshTokenstatus: "loading",
        };
      })
      .addCase(reNewAccessToken.fulfilled, (state, action) => {
        // Refresh Token success
        state.refreshTokenstatus = "success";
        state.token = action.payload.token;
        state.refreshTokenExpired = action.payload.refreshTokenExpired;
      })
      .addCase(reNewAccessToken.rejected, (state, action) => {
        // Refresh Token rejected
        return {
          ...state,
          refreshTokenstatus: "error",
          error: action.error.message,
        };
      })
      //Re Login
      .addCase(tryReLogin.pending, (state) => {
        return {
          ...state,
          reloginStatus: "idle",
        };
      })
      .addCase(tryReLogin.fulfilled, (state, action) => {
        if (
          action.payload.token === null &&
          action.payload.refreshToken === null
        ) {
          return {
            ...state,
            ...action.payload,
            reloginStatus: "success",
            isValid: false,
            error: "",
            incorrectCredentialError: "Invalid Username or Password",
          };
        } else {
          return {
            ...state,
            ...action.payload,
            reloginStatus: "success",
            isValid: true,
            error: "",
            incorrectCredentialError: "",
          };
        }
      })
      .addCase(tryReLogin.rejected, (state, action) => {
        return {
          ...state,
          reloginStatus: "error",
          error: action.error.message,
        };
      })
      .addCase(usernameReset.fulfilled, (state, action) => {
        // Handles success
        if (
          action.payload.token === null ||
          action.payload.token === "diffPassword"
        ) {
          state.incorrectCredentialError = "The password does not match the current one";
        } else if (action.payload.token === "noAccount") {
          state.incorrectCredentialError = "Invalid Username or Password";
        } else if (action.payload.token === "failed") {
          state.incorrectCredentialError = "Error on changing username";
        }
        else if (action.payload.token === "duplicate") {
          state.incorrectCredentialError = "The username already exists";
        }
        else {
          return {
            ...state,
            ...action.payload,
            ...action.payload,
            status: "success",
            isValid: true,
            error: "",
            incorrectCredentialError: "success",
          };
        }
      })
      .addCase(usernameReset.rejected, (state, action) => {
        // Handles rejected state
        return {
          ...state,
          error: action.error.message,
          incorrectCredentialError: "Unknown error. Please contact support.",
        };
      });
  },
});

export const { updateElementValueInLoginReducer, resetLoginData } =
  loginSlice.actions;

export const useLoginReducer = () => useAppSelector((state) => state.login);

export default loginSlice.reducer;
