import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { ErrorType, LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { properties, application_status } from "../../constants/common";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import {
  ROLE_COUNTY_ADMIN,
  ROLE_JUDGE,
  ROLE_CIRCUIT_CLERK,
  ROLE_CASE_CREATOR,
  ROLE_POLICE_OFFICER,
  ROLE_AGENCY_ADMIN,
  ROLE_STATES_ATTORNEY,
  ROLE_ATTORNEY_GENERAL,
  ROLE_LOCAL_PROSECUTOR,
} from "../../constants/common";
import { showHideLoadingGif } from "./commonSlice";

interface UserprofileListState {
  userList: string[];
  uploadUserListErr: string [];
  county: object;
  agency: object;
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  userError?: ErrorType;
  pagedUserListFetchstatus: LoadingType;
  isValid: boolean;
  listUserError: string;
  listValue: string;
  resetSuccessMessage?: string;
  resetErrorMessage?: string;
  downloadFilestatus: LoadingType;
  uploadFilestatus: LoadingType;
  resetPasswordstatus: LoadingType;
  changePasswordStatus: LoadingType;
  changePasswordMessage: string;
  currentPassword : string;
  newPassword : string;
  confirmPassword : string;
  countyId:number;
  agencyId:number;
  userTypename: string;
  firstName: string;
  lastName: string;
  userStatus: string;
  userName: string;
  badgeNumber: number;
  localCounty: string;
  localAgency: string;
  twoFactorEnable : boolean,
  twoFactorType: string;
}

const initialState = {
  userList: [],
  uploadUserListErr: [],
  county:{},
  agency: {},
  currentPage: 1,
  totalPages: 1,
  totalRecords: 0,
  pagedUserListFetchstatus: "idle",
  isValid: false,
  listUserError: "",
  listValue: "",
  resetSuccessMessage: undefined,
  resetErrorMessage: undefined,
  downloadFilestatus: "idle",
  uploadFilestatus: "idle",
  resetPasswordstatus: "idle",
  changePasswordStatus :"idle",
  changePasswordMessage : "",
  currentPassword : "",
  newPassword : "",
  confirmPassword: "",
  countyId: 0,
  agencyId: 0,
  userTypename: "-- ALL --",
  firstName: "",
  lastName: "",
  userStatus: "",
  userName: "",
  badgeNumber: 0,
  localCounty: "-- All --",
  localAgency: "-- All --",
  twoFactorEnable : false,
  twoFactorType: "NONE",
} as UserprofileListState;

export const listUser = createAsyncThunk<
  any,
  {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    username?: string;
    badgeNumber?: number;
    status: string;
    countyId?: number;
    agencyId?: number;
    userTypename?: string;
    userTypeObj: string[];
    pageNo?: number;
  },
  {
    state: RootState;
  }
>("user/listUser", async (data, { getState, rejectWithValue, dispatch }) => {
  const {
    firstName,
    lastName,
    middleName,
    username,
    badgeNumber,
    status = "true",
    countyId,
    agencyId,
    userTypename,
    userTypeObj,
    pageNo = 0,
  } = data;

  let params = {
    pageNo: pageNo,
    firstName: firstName,
    lastName: lastName,
    middleName: middleName,
    username: username,
    badgeNumber: badgeNumber,
    countyId: countyId,
    agencyId: agencyId,
    userType: userTypename,
    availUserTypes: userTypeObj,
    status: status,
  };

  let { token } = getState().login;

  token = `jwtToken=Bearer ${token}`;

  dispatch(showHideLoadingGif(true));
  try {
     const response = await doFetch({
      url: `userprofileservices/userProfiles?${token}`,
      body: params,
      type: "POST",
    });
    if (response.ok) {
      dispatch(showHideLoadingGif(false));
       return response.json();

    } else {

      dispatch(showHideLoadingGif(false));
      return rejectWithValue(response.status);
    }
  } catch (error) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error);
  }
});

export const updateUserStatus = createAsyncThunk<
  any,
  { userId: number; userTypeObj: string[]; status: string; listViewData : any },
  { state: RootState; rejectValue: string }
>(
  "user/updateUserStatus",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { userId, userTypeObj, status, listViewData } = data;

    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: `userprofileservices/updateUserStatus/${userId}`,
        token,
        type: "PUT",
      });
      dispatch(listUser(listViewData));
        if (response.ok) {
          dispatch(showHideLoadingGif(false));
          const id = await response.text();
          return {
            message: `User ${id} status updated successfully`,
          };
        } else {
          
          dispatch(showHideLoadingGif(false));
          return rejectWithValue("Error while updating user status");
        }
      } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while updating user status"
      );
    }
  }
);

export const downloadTemplateFileForUser = createAsyncThunk<
  any,
  { userTypeId: number; userTypeName: string },
  { state: RootState; rejectValue: string }
>("user/downloadTemplate", async (data, { getState, rejectWithValue, dispatch }) => {
  const { userTypeId, userTypeName } = data;

  let queryParam = `userTypeId=${userTypeId}&`;

  let { token } = getState().login;

  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: `userprofileservices/downloadUserImportTemplateFile?${queryParam}`,
      token,
      type: "POST",
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      await response.blob().then((blob) => {
        let fileName = "";

        if (
          ROLE_COUNTY_ADMIN === userTypeName ||
          ROLE_JUDGE === userTypeName ||
          ROLE_CIRCUIT_CLERK === userTypeName ||
          ROLE_CASE_CREATOR === userTypeName
          ) {
            fileName = "Common_Template";
          } else if (ROLE_POLICE_OFFICER === userTypeName) {
            fileName = "PO_Template";
          } else if (ROLE_AGENCY_ADMIN === userTypeName) {
            fileName = "AGENCY_ADMIN_Template";
          } else if (ROLE_STATES_ATTORNEY === userTypeName) {
            fileName = "STATES_ATTORNEY_Template";
          } else if (ROLE_ATTORNEY_GENERAL === userTypeName) {
            fileName = "AG_Template";
          } else if (ROLE_LOCAL_PROSECUTOR === userTypeName) {
            fileName = "LP_Template";
          }
          
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName + ".xlsx";
          a.click();
          return {
            message: "Download User import template file successfully",
          };
        });
      } else {

        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while updating user status");
      }
    } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Error while updating user status");
  }
});



export const userFileUpload = createAsyncThunk<
any,
{ userTypeId : number;
  fileName : string ;
  fileData: string ;
  navigate: any 
},
{ state: RootState; rejectValue: string }
>("user/uploadUserFile", 
async (data, { getState, rejectWithValue , dispatch}) => {
  const { userTypeId, fileName, fileData, navigate } = data;
  
      let queryParam = ''
      queryParam += `userTypeId=${userTypeId}&`
      queryParam += `fileName=${fileName}`
      var formData = new FormData();
      formData.append('fileData', fileData);

      let { token } = getState().login;
 
 
  dispatch(showHideLoadingGif(true));
  try {
     const response = await doFetch({
      url: `userprofileservices/handleUserFileUploadProcess?${queryParam}`,
      token,
      type: 'POST',
      headers: {
               Accept: 'application/json',
              'Authorization':  `Bearer ${token}`
          },
      body: formData,
      defaultBody :true
    
     });
     if (response.ok) {
      
      dispatch(showHideLoadingGif(false));
     
    } else {
       dispatch(showHideLoadingGif(false));
  
      return response.json();
    }
  } catch (error: any) {
  //  navigate("/UserList");
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Error while uploading user file");
  }
});
   



export const userPasswordReset = createAsyncThunk<
  any,
  {
    username: string;
    password: string;
    oldPassword: string;
  },
  {
    state: RootState;
  }
>("users/userPasswordReset", async (data, { getState, rejectWithValue, dispatch }) => {
  const { username, password, oldPassword } = data;

  let queryParam = "";
  queryParam += `oldPassword=${oldPassword}`;
  let { token } = getState().login;
  dispatch(showHideLoadingGif(true));
  try {
    const response: any = await doFetch({
      url: `userprofileservices/resetpassword?${queryParam}`,
      type: "POST",
      body: {
        username,
        password,
      },
      token: token,
    });
    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      const result = await response.text();
      if (result === "success") {
        return {
          message: "Successfully changed password.",
        };
      }else if (result === "invalid"){
        return {
          errorMessage: "Current Password is not valid.",
        };
      }else if (result === "noAccount") {
        return {
          errorMessage: "User does not exist or in inactive state.",
        };
      } else if (result === "samePassword") {
        return {
          errorMessage: "New Password must be different than current Password.",
        };
      } else {
        return {
          errorMessage:
          "Error occured while  reset password. Please try again.",
        };
      }
    } else {

      dispatch(showHideLoadingGif(false));
      return rejectWithValue(response.status);
    }
  } catch (error) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error);
  }
});

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    updateElementValueUserProfileReducer: updateElementValue,
    resetUserProfileList: (state: UserprofileListState) => {
      return {
        ...state,
        ...initialState,
      };
    },
    updateUserProfileReducer: (state : UserprofileListState, action) => {
      return {
        ...state,
        countyId : action.payload.countyId,
        agencyId : action.payload.agencyId,
        localCounty : action.payload.localCounty,
        localAgency : action.payload.localAgency,
        userTypename : action.payload.userTypename,
        firstName : action.payload.firstName,
        lastName : action.payload.lastName,
        userStatus : action.payload.status,
        userName : action.payload.userName,
        badgeNumber : action.payload.badgeNumber,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(listUser.pending, (state) => {
        state.pagedUserListFetchstatus = "loading";
      })
      .addCase(listUser.fulfilled, (state, action) => {
        state.pagedUserListFetchstatus = "success";
        state.userList = action.payload.userList;
        state.totalPages = action.payload.totalPages;
        state.totalRecords = action.payload.totalRecords;
        state.currentPage = action.payload.currentPage;
        state.isValid = true;
        state.listUserError = "";
      })
      .addCase(listUser.rejected, (state, action) => {
        state.pagedUserListFetchstatus = "error";
        state.isValid = false;
        state.listUserError =
          JSON.stringify(action.payload) || "Error while fetching user list";
      })

      //download File
      .addCase(downloadTemplateFileForUser.pending, (state) => {
        state.downloadFilestatus = "loading";
      })
      .addCase(downloadTemplateFileForUser.fulfilled, (state, action) => {
        state.downloadFilestatus = "success";
        state.listUserError = "";
      })
      .addCase(downloadTemplateFileForUser.rejected, (state, action) => {
        state.downloadFilestatus = "error";
        state.listUserError =
          JSON.stringify(action.payload) || "Error download temple file";
      })

      // upload user File
      .addCase(userFileUpload.pending, (state) => {
        state.uploadFilestatus = "loading";
      })
      .addCase(userFileUpload.fulfilled, (state, action) => {
        state.uploadFilestatus = "success";
        state.uploadUserListErr = action.payload;   
      })
      .addCase(userFileUpload.rejected, (state, action) => {
        alert("error");
        state.uploadFilestatus = "error";
        state.listUserError = 
        JSON.stringify(action.payload) || "Error download temple file";
        console.log(JSON.stringify(action.payload));
      })

      // reset password
      .addCase(userPasswordReset.pending, (state) => {
        state.resetPasswordstatus = "loading";
      })
      .addCase(userPasswordReset.fulfilled, (state, action) => {
        state.resetPasswordstatus = "success";
        state.resetSuccessMessage = action.payload.message;
        state.resetErrorMessage = action.payload.errorMessage;
        state.listUserError = "";
      })
      .addCase(userPasswordReset.rejected, (state, action) => {
        state.resetPasswordstatus = "error";
        state.listUserError =
          JSON.stringify(action.payload) || "Error download temple file";
      });
  },
});

export const { updateElementValueUserProfileReducer, resetUserProfileList, updateUserProfileReducer } =
  userProfileSlice.actions;

export const useUserProfileReducer = () =>
  useAppSelector((state) => state.userProfileList);

export default userProfileSlice.reducer;
