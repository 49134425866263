import React, { useCallback, useEffect } from "react";
import { Row, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import ButtonComponent from "../../common/ButtonComponent";
import { RiEraserFill } from "react-icons/ri";
import TextInputComponent, {
} from "../../common/TextInputComponent";
import ReactSignatureCanvas from "react-signature-canvas";
import { HiArrowsExpand } from "react-icons/hi";
import useModel from "../../../hooks/useModel";
import SignPanelExpandPopup from "./SignPanelExpandPopup";
import { useAppDispatch } from "../../../hooks/hooks";
import {
  resetSignature,
  setSignature,
  updateElementValueInSignPanelReducer,
} from "../../../redux/slice/signPanelSlice";
import { useSignPanelReducer } from "../../../redux/slice/signPanelSlice";
import { useLoginReducer } from "../../../redux/slice/loginSlice";
import InfoPopup from "../../common/InfoPopup";
import { usePreTrialReleaseRecognizance } from "../../../redux/slice/preTrialReleaseRecognizanceSlice";
import {updateElementValuePpretiralData } from "../../../redux/slice/preTrialReleaseRecognizanceSlice"
import { APPLICATION_AND_ORDER, BLANK_ORDER, FORMID_ENCODING_SECRET, PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE, ROLE_JUDGE } from "../../../constants/common";
import { LuClipboardSignature } from "react-icons/lu";
import useQuery from "../../../hooks/useQuery";
import { getJudgeSavedSignature } from "../../../redux/slice/userProfileSlice";

interface SignPanelProps { }

interface SignPanelProps {
  [x: string]: any;
  remoteCaptureSign: () => void;
}

interface ObjData {
  sessionId?: string;
  error?: string;
  imageData?: string;
  refresh?:boolean;
}

const SignPanel: React.FC<SignPanelProps> = (props) => {
  const { className, appearPersonText, whoSign, swText, hideFooter, isBorderVisible, parentComponent, remotecaptureSignEnabled, remoteCaptureSign, formType } = props;
  const sigPad = React.useRef<ReactSignatureCanvas>(null);
  const { isValid } = useLoginReducer();
  const signPanelPopup = useModel();
  const dispatch = useAppDispatch();
  const [sessionId, setSessionId] = React.useState("");
  const [wsSignature, setWsSignature] = React.useState("");

  const signPanelReducer = useSignPanelReducer();
  const {userType, userProfileId} = useLoginReducer();
  const [showIntoModalMessage, setshowIntoModalMessage] = React.useState("");
  const [showInfoModal, setshowInfoModal] = React.useState(false);
  const [imgFileName, setImgFileName] = React.useState("");
  const [clearPanel, setClearPanel] = React.useState(false);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(true);
  const query = useQuery();

  const acceptInfoModal = () => {
    setshowInfoModal(false);
  }

  const { pretiralData } =
    usePreTrialReleaseRecognizance();
  const {isRefusedSign} = pretiralData;

  const ecFormId = Number(query.get("ecFormId"));

  const encodeNumber = (num: number): string => {
    const encodedValue = num + FORMID_ENCODING_SECRET;
    return btoa(encodedValue.toString());
  };
  

  function prepareMessage(msg: any) {
    let buff = [];
    if (msg && msg.trim().length > 0) {
      let arr = msg.split("<br>");
      let line = "";
      for (let i = 0; i < arr.length; i++) {
        line = arr[i];
        buff.push(<div>{line}</div>);
      }
    }
    return buff;
  }

  const onEnd = () => {
    const sign = sigPad.current!.getCanvas().toDataURL("image/png");
    dispatch(setSignature(sign));
    setSignForPixelCanvas(sign);
  };

  useEffect(() => {
    sigPad.current?.clear();
    sigPad.current?.fromDataURL(signPanelReducer.sign, { width: 475, height: 200 });
    setSignForPixelCanvas(signPanelReducer.sign);
  }, []);

  const handleFileChange = async (event: any) => {
    try {
      
      const file = event.target.files[0];
      if(file){
        const fileName = file.name;
        const fileNameLower = fileName.toLowerCase();
        const acceptFormats = ['jpg', 'png', 'jpeg'];
        
        const fileExtension = fileNameLower.substring(fileNameLower.lastIndexOf('.') + 1);
        if (!(acceptFormats.includes(fileExtension))) {
          setshowIntoModalMessage("Document must be a JPG, JPEG, or PNG format.");
          throw "Only image formats allowed";
        };
        setImgFileName(fileName);
        let sign = String(await convertBase64(file));
        sigPad.current?.fromDataURL(sign, { width: 475, height: 200 });
        setTimeout(() => {
          let signNew = sigPad.current!.getCanvas().toDataURL('image/png');
          dispatch(setSignature(signNew));
          setSignForPixelCanvas(signNew);
        }, 700);
        if(isRefusedSign === "Y"){
          dispatch(
            updateElementValuePpretiralData({
              elementName: "isRefusedSign",
              value: "N",
            })
          )
        }
  
      }
      event.target.value = null;
    } catch (e) {
      setshowInfoModal(true);
      event.preventDefault();
      event.target.value = null;
      return;
    }
  };

  useEffect(() => {
    if(parentComponent === PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE){
      if(isRefusedSign === "Y") {
        const imgFilePath = `/images/REFUSED.png`;
        setRefusedSignature(imgFilePath);
      }else if(isRefusedSign === "N"){
        sigPad.current?.clear();
        dispatch(setSignature(""));
      }
    }
  }, [isRefusedSign])

const getFileObjectByPath = async (filePath: string) => {
  const response = await fetch(filePath);
  const fileBlob = await response.blob();
  const file = new File([fileBlob], filePath);
  return file;
};
 
const setRefusedSignature = async (imageFilePath: any) => {
  clearImg();
  setImgFileName("");
  await getFileObjectByPath(imageFilePath)
  .then(file => {
    let sign = convertBase64(file);
    sign.then(result => {
      console.log(String(result));
      sigPad.current?.fromDataURL(String(result), { width: 475, height: 200 });
    });
    setTimeout(() => {
      let signNew = sigPad.current!.getCanvas().toDataURL('image/png');
      dispatch(setSignature(signNew));
      setSignForPixelCanvas(signNew);
    }, 700);

  })
}

  const convertBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  };
  const submitSignExpand: any = (exSign: any) => {
    sigPad.current?.clear();
    sigPad.current?.fromDataURL(exSign, { width: 475, height: 200 });
    setSignature(exSign);
    signPanelPopup.close();
  };

  const clear = useCallback(() => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
    dispatch(setSignature(""));
    setImgFileName("");
    setClearPanel(true);
    if(parentComponent === PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE){
      dispatch(
        updateElementValuePpretiralData({
          elementName : "isRefusedSign",
          value: "N"
        })
      );
    }
  }, [dispatch]);

  const connect = () => {
    var URL =
    process.env.REACT_APP_WSURL +
    "?user=" +
    process.env.REACT_APP_WSUSERNAME +
    "&password=" +
    process.env.REACT_APP_WSPASSWORD +
    "&application=ICS";
    console.log(URL);
    var ws = new WebSocket(URL);

    const that: any = this; // cache the this
    var connectInterval: string | number | NodeJS.Timeout | undefined;
    let sessionId;
    let error;
    let signature;

    ws.onopen = () => {
      console.log("connected websocket main component");

      console.log("connected websocket main component" + ws);
    };

    setLoading(true);
    ws.onmessage = (e: MessageEvent) => {
      try {
        const objData: ObjData = JSON.parse(e.data);

        if (objData.sessionId != null && objData.sessionId !== "") {
          const updatedSessionId: string = objData.sessionId;
          setSessionId(updatedSessionId);
          dispatch(
            updateElementValueInSignPanelReducer({
              elementName: "sessionId",
              value: updatedSessionId,
            })
          );
          setError("");
        }
        if (objData.refresh && objData.sessionId != null && objData.sessionId !== ""){
          let param = "";
          if (parentComponent === PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE){
            param = "&docPreviewURL="+process.env.REACT_APP_REST_URL+"docs/ecomplaintreceiptservice/previewDocumentByFormId?ecFormId="+encodeNumber(ecFormId)+"&"
          }
          let Obj = {
            url: process.env.REACT_APP_MYSIGNATURE_URL_SIGNATURE+objData.sessionId+param,
          }
          ws.send(JSON.stringify(Obj));
        }

        if (objData.error != null && objData.error !== "") {
          console.log(objData.error);
          setError(
            "Unable to generate QR code. Your browser or network may be blocking access to https://mysignature.net"
          );
        }

        if (objData.imageData != null && objData.imageData !== "") {
          const updatedSignature: string = objData.imageData;
          setWsSignature(updatedSignature);

          sigPad.current?.clear();

          setSignForPixelCanvas(updatedSignature);
          sigPad.current?.fromDataURL(updatedSignature, { width: 475, height: 200 });
          dispatch(setSignature(updatedSignature));
        }
        setLoading(false);
      } catch (error) {
        console.error("Error processing WebSocket message:", error);
        setLoading(false);
      }
    };
    // websocket onclose event listener
    ws.onclose = (e) => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      );

      that.timeout = that.timeout + that.timeout; //increment retry interval
    };

    // websocket onerror event listener
    ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",

        "Closing socket"
      );

      ws.close();
    };
  };

  const setSignForPixelCanvas = (sign: string) => {
    let canvas: any = document.getElementById("pixelcanvas");
    let ctx = canvas.getContext("2d");
    let image = new Image();
    image.onload = function () {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0);
    };

    image.src = sign;
  };

  const onMessageReceived = (payload: any) => { };
  useEffect(() => {
    if (isValid && (remotecaptureSignEnabled === false || remotecaptureSignEnabled === undefined)) {
      connect();
    }
  }, [isValid]);

  const clearImg = () => {
    setImgFileName("");
    sigPad.current?.clear();
    dispatch(setSignature(""));
    setSignForPixelCanvas("");

  }

  return (
    <div className={className || ""}>
      <div className={isBorderVisible ? "custem-border " : "mt-2 mb-2 p-3 border rounded"}>
        <div className={isBorderVisible ? "mt-1 mb-1 p-2" : ""}>
          <Row>
            <Col>
              <p className="fw-bold text-danger m-0">
                There are three options available to collect your signature
              </p>
            </Col>
          </Row>
          <hr />
          <Row className="g-5">
            <Col md={5}>
              {remotecaptureSignEnabled === true ? (
                <div>
                  <p className="fw-bold text-danger m-0">OPTION 1:</p>
                  <div>
                    {signPanelReducer.isRemoteCaptureSign ? (
                      <div className="text-center">
                          <p className="fw-bold m-0">
                            Using a separate touch screen smartphone or tablet, open{" "}
                            <br />
                            {process.env.REACT_APP_MYSIGNATURE_URL}
                          </p>
                          {loading ? (
                            <p className="loading-text">
                              <br></br>Generating Session ID...
                            </p>
                          ) : sessionId ? (
                            <>
                              <p className="fw-bold m-0 pt-2">
                                Enter the signature ID shown below
                              </p>
                              <div className="text-danger fw-bold">
                                <label>Signature ID:</label>
                                <h3>{sessionId}</h3>
                              </div>
                              <div className="mt-2">
                                <img
                                  id="qrCodeImg"
                                  src={process.env.REACT_APP_QRCODE_IMAGE_SRC + sessionId + "%26docPreviewURL=" +process.env.REACT_APP_REST_URL+"docs/ecomplaintreceiptservice/previewDocumentByFormId?ecFormId="+ encodeNumber(ecFormId)}
                                  alt="qr"
                                  style={{ maxWidth: '270px', maxHeight: '270px' }}
                                />
                              </div>
                              <label>
                                <b>
                                  {" "}
                                  <b>{process.env.REACT_APP_MYSIGNATURE_URL}</b>
                                </b>
                              </label>
                              <p>
                                This signature is for one-time use only. We will not store
                                this signature in our system.
                              </p>
                            </>
                          ) : (
                            <div className="alert alert-danger mt-3" role="alert">
                              <label>Error:</label>
                              <p>
                                Unable to generate QR code. Your browser or network may be
                                blocking access to {process.env.REACT_APP_MYSIGNATURE_URL}
                              </p>
                            </div>
                          )}
                      </div>
                    ) : (
                      <div>
                      <p className="fw-bold m-0">
                      Please click the "Capture Signature Remotely" button to generate the QR code to <br/> capture the signature remotely on a different device. 
                      </p>
                      <div className="text-center">
                      <ButtonComponent className="btn-info mt-4" onClick={() => { remoteCaptureSign(); connect();}}>
                        Capture Signature Remotely <LuClipboardSignature />
                      </ButtonComponent>
                      </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <p className="fw-bold text-danger m-0">OPTION 1:</p>
                  <div className="text-center">
                    <p className="fw-bold m-0">
                      Using a separate touch screen smartphone or tablet, open{" "}
                      <br />
                      {process.env.REACT_APP_MYSIGNATURE_URL}
                    </p>
                    {loading ? (
                      <p className="loading-text">
                        <br></br>Generating Session ID...
                      </p>
                    ) : sessionId ? (
                      <>
                        <p className="fw-bold m-0 pt-2">
                          Enter the signature ID shown below
                        </p>
                        <div className="text-danger fw-bold">
                          <label>Signature ID:</label>
                          <h3>{sessionId}</h3>
                        </div>
                        <div className="mt-2">
                          <img
                            id="qrCodeImg"
                            src={process.env.REACT_APP_QRCODE_IMAGE_SRC + sessionId}
                            alt="qr"
                          />
                        </div>
                        <label>
                          <b>
                            {" "}
                            <b>{process.env.REACT_APP_MYSIGNATURE_URL}</b>
                          </b>
                        </label>
                        <p>
                          This signature is for one-time use only. We will not store
                          this signature in our system.
                        </p>
                      </>
                    ) : (
                      <div className="alert alert-danger mt-3" role="alert">
                        <label>Error:</label>
                        <p>
                          Unable to generate QR code. Your browser or network may be
                          blocking access to {process.env.REACT_APP_MYSIGNATURE_URL}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Col>
            <Col md={4}>
              <p className="fw-bold text-danger m-0">OPTION 2:</p>
              <p className="fw-bold m-0">
                Sign directly in the box below
                <br />
                Use your touchscreen or mouse
              </p>
              <ButtonComponent className="btn-danger" onClick={clear}>
                Clear <RiEraserFill />
              </ButtonComponent>
              <Row className="align-items-start no-gutters">
                <Col>
                  <div className="text-end" style={{ width: "490px" }}>
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">Expand</Tooltip>}
                    >
                      <span className="d-inline-block">
                        <HiArrowsExpand
                          onClick={signPanelPopup.open}
                          className="text-secondary"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </OverlayTrigger>
                  </div>
                  <ReactSignatureCanvas
                    penColor="black"
                    ref={sigPad}
                    onEnd={onEnd}
                    canvasProps={{
                      className: `${props.isError
                        ? "border border-danger ck-editor-border-err"
                        : ""
                        } sigCanvas border-sigCanvas`,
                      width: 475,
                      height: 200,
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={3} style={{ height: 120 }}>
              <p className="fw-bold text-danger m-0">OPTION 3:</p>
              <p className="fw-bold m-0">
                Browse for a saved signature image file
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <TextInputComponent
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={handleFileChange}
                    accept=".png,.jpg,.jpeg"
                    style={{ width: "131px", color: "transparent" }}
                  />
                </div>
                <div>
                  {imgFileName ? <a style={{ margin: "0 auto" }} className="text-primary fileLink">{imgFileName.length > 30 ? (imgFileName.slice(0, 20) + "...") : imgFileName}</a> : ""}
                  {imgFileName ? <Button style={{ margin: "0 auto" }} variant="danger" onClick={clearImg} className={`fileButton`} >x</Button> : ""}
                </div>
              </div>

            </Col>
          </Row>

          <canvas id={"pixelcanvas"} width={"475"} height={"200"} hidden></canvas>
          <SignPanelExpandPopup
            show={signPanelPopup.isOpen}
            onHide={signPanelPopup.close}
            submitSignExpand={submitSignExpand}
          />
        </div>
      </div>

      {!hideFooter && (
        <Row className="text-end">
          <Col>
            {appearPersonText ? (
              <div>
                <label>{"(" + appearPersonText + ")"}</label>
              </div>
            ) : (
              ""
            )}
            <div>{whoSign || ""}</div>
            <div>{swText || ""}</div>
          </Col>
        </Row>
      )}

      <InfoPopup
        show={showInfoModal}
        onHide={acceptInfoModal}
        variant="danger" >
        {prepareMessage(showIntoModalMessage)}
      </InfoPopup>
    </div>
  );
};

export default SignPanel;
