import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { RiCheckboxCircleFill } from "react-icons/ri";
import Select, {
  components,
  GroupBase,
  ValueContainerProps,
  PlaceholderProps,
  Props,
} from "react-select";

export type SelectComponentProps = {
  isRequired?: boolean;
  isLabeled?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  labelName?: string;
  isDefaultLabel?: boolean;
  inLine?: boolean;
  labelSize?: string;
};
type OptionType = {
  label: string;
  value: string;
};
function SelectComponent<
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>
>(props: Props<OptionType, IsMulti, Group> & SelectComponentProps) {
  const {
    isRequired,
    isLabeled,
    isError,
    isWarning,
    labelName,
    isDefaultLabel,
    inLine,
    labelSize,
    ...rest
  } = props;
  return (
    <Col>
      <Form.Group as={inLine ? Row : Col}>
        {isLabeled && (
          <Form.Label column sm={`${labelSize || "auto"}` as any}>
            {labelName || props.name}
            {isRequired && <span>*</span>}
          </Form.Label>
        )}
        <Select
          // @ts-ignore
          components={
            !props.isMulti
              ? {
                  ValueContainer: CustomValueContainer,
                }
              : {}
          }
          styles={
            !props.isMulti
              ? {
                  control: (provided, state) => ({
                    ...provided,
                    paddingTop: 2,
                    border: isError
                      ? "1px solid #dc3545"
                      : isWarning
                      ? "1px solid #ffc107"
                      : "1px solid #ced4da",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #f3f5f6",
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    marginTop: isDefaultLabel ? "-2px" : "8px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 99,
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                    paddingTop: "5px;",
                    color: "#6c757d",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top:
                      state.hasValue || state.selectProps.inputValue
                        ? -9
                        : "10%",
                    transition: "top 0.1s, font-size 0.1s",
                    // fontWeight:
                    //   (state.hasValue || state.selectProps.inputValue) && 700,
                    fontSize:
                      (state.hasValue || state.selectProps.inputValue) &&
                      "0.9em",
                    opacity: isDefaultLabel
                      ? (state.hasValue || state.selectProps.inputValue) && 0
                      : 1,
                    color: isError ? "#dc3545" : isWarning ? "#ffc107" : "",
                  }),
                }
              : {
                  control: (provided, state) => ({
                    ...provided,
                    paddingTop: 2,
                    border: isError
                      ? "1px solid #dc3545"
                      : isWarning
                      ? "1px solid #ffc107"
                      : "1px solid #ced4da",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #f3f5f6",
                    },
                  }),
                }
          }
          {...rest}
        />
      </Form.Group>
    </Col>
  );
}

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({
  children,
  ...props
}: ValueContainerProps & PlaceholderProps) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}{" "}
      </Placeholder>
      {React.Children.map(children, (child) =>
        // @ts-ignore
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

export default SelectComponent;
