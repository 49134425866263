import React, { useCallback, useEffect, useState } from "react";
import Header from "../common/Header";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import FormComponent from "../common/FormComponent";
import {
  PreTrialReleaseRecognizanceProps,
  getOfficerDatesOrAgencyDates,
  getPreTrialData,
  resetPreTrialReleaseRecognizanceReducer,
  savePreTrialReleaseFormData,
  updateElementValuePpretiralData,
  usePreTrialReleaseRecognizance,
} from "../../redux/slice/preTrialReleaseRecognizanceSlice";
import { useAppDispatch } from "../../hooks/hooks";
import {
  getCountryList,
  useComplaintReducer,
} from "../../redux/slice/complaintSlice";
import {
  CONFIRM_SUBMIT_PRETRIAL_RELEASE_OWN_RECOGNIZANCE_MESSAGE,
  PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE,
  STATE_LIST,
} from "../../constants/common";
import { Col, Row } from "react-bootstrap";
import ButtonComponent from "../common/ButtonComponent";
import {
  formatAddress,
  formattedZipcode,
  validateDate,
} from "../../utils/common";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import { useNavigate } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import ConfirmPopup from "../common/ConfirmPopup";
import useModel from "../../hooks/useModel";
import * as Yup from "yup";
import WarningAlert from "../common/WarningAlert";
import moment from "moment";
import {
  resetSignature,
  useSignPanelReducer,
} from "../../redux/slice/signPanelSlice";
import { previewPreTrialRelease } from "../../redux/slice/commonFormSlice";
import CheckBoxComponent from "../common/CheckBoxComponent";
import TextInputComponent from "../common/TextInputComponent";
import SelectComponent from "../common/SelectComponent";
import { useDashboardReducer,updateElementValueDashboardReducer } from "../../redux/slice/dashboardSlice";
import { savePreTrialReleaseDataTemp } from "../../redux/slice/complaintMainSlice";
import { updateElementValueInSignPanelReducer } from "../../redux/slice/signPanelSlice";
import { RiAddCircleLine } from "react-icons/ri";
import { useAgencyListReducer } from "../../redux/slice/agencyListSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import InfoPopup from "../common/InfoPopup";

const preTrialReleaseOnOwnRecognizanceRequiredSchema1 = Yup.object().shape({
  courtDateData: Yup.string()
    .nullable()
    .required("''Court Proceedings Options'' are required.")
    .test("validate-date", function (value) {
      const splitString = value?.split('-');
      let iso8601DateTime = ""
      if (splitString && splitString.length >= 2) {
        const datePart = splitString[0];
        const timePart = splitString[1];
        iso8601DateTime = new Date(`${datePart} ${timePart}`).toISOString();
      }
      const isFutureDate = moment(iso8601DateTime).isAfter(moment());
      if (!isFutureDate) {
        return this.createError({
          message: "''Court Date'' should be a future date.",
        });
      } else {
        return true;
      }
    }),
  radioDcnCollectedNo: Yup.string()
    .nullable()
    .required("Defendant Fingerprints Collected option is not selected."),
  radioDcnCollectedYes: Yup.string()
    .nullable()
    .required("Defendant Fingerprints Collected option is not selected."),
    documentControlNumber: Yup.string().when("radioDcnCollectedYes", {
      is: "Y",
      then: Yup.string().required("''Document Control Number'' is required."),
    }),
});

const preTrialReleaseOnOwnRecognizanceRequiredSchema2 = Yup.object().shape(
  {
    radioNoCourtDetail: Yup.string().nullable()
    .required("''Court Proceedings Options'' are required.").when("radioNoCourtText", {
      is: "N",
      then: Yup.string()
        .equals(["Y"], "''Court Proceedings Options'' are required."),
    }),
    radioNoCourtText: Yup.string().nullable()
    .required("''Court Proceedings Options'' are required.").when("radioNoCourtDetail", {
      is: "N",
      then: Yup.string()
        .equals(["Y"], "''Court Proceedings Options'' are required."),
    }),
    courtDate: Yup.string()
      .nullable()
      .when("radioNoCourtDetail", {
        is: "Y",
        then: Yup.string().nullable().test("court-date-future-date", function (value) {
          if (value) {
            const isFutureDate = moment(value).isAfter(moment().add(14, 'days'));
            if (!isFutureDate) {
              return this.createError({
                message: "''Court Date'' should be a future date after the next 14 days.",
              });
            }
          }
          return true;
        }),
      }),
    courtTime: Yup.string()
      .nullable()
      .when("radioNoCourtDetail", {
        is: "Y",
        then: Yup.string().nullable().required("''Court Time'' is required."),
      }),
    radioDcnCollectedNo: Yup.string()
      .nullable()
      .required("Defendant Fingerprints Collected option is not selected."),
    radioDcnCollectedYes: Yup.string()
      .nullable()
      .required("Defendant Fingerprints Collected option is not selected."),
    documentControlNumber: Yup.string().when("radioDcnCollectedYes", {
        is: "Y",
        then: Yup.string().required("''Document Control Number'' is required."),
      }),
  },
  [["radioNoCourtDetail", "radioNoCourtText"]]
);

const preTrialReleaseOnOwnRecognizanceWarningSchema = Yup.object().shape({
  documentControlNumber: Yup.string().when("radioDcnCollectedYes", {
    is: "Y",
    then: Yup.string().min(9, "DCN length must be at least 9 chars."),
  }),
});

export default function ECPreTrialReleaseOnOwnRecognizPage() {
  const {
    handleError: handleErrorMessages,
    clearError: clearErrorMessages,
    messages: errorMessages,
  } = useAlert();
  const {
    handleError: handleErrorWarnings,
    clearError: clearErrorWarnings,
    messages: warningMessages,
  } = useAlert();
  const confirmationModel = useModel();
  const signPanelReducer = useSignPanelReducer();
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const {agencyList} = useAgencyListReducer();
  const { countries } = useComplaintReducer();
  const ecomplaintReducer = useComplaintMainReducer();
  const { pretiralData, officerDateProxy, isOfficerSchedulerListEmpty, getOfficerDatesOrAgencyDatesStatus } =
    usePreTrialReleaseRecognizance();

  const [errorKeys, setErrorKeys] = useState([""]);
  const [warningKeys, setWarningKeys] = useState([""]);
  const [minDate, setMinDate] = useState(""); 
  const [showAdditionalDateBtn, setShowAdditionalDateBtn] = useState(false);
  const {
    courtDateData,
    radioDcnCollectedNo,
    radioDcnCollectedYes,
    documentControlNumber,
    defDLConfiscated,
    defAddressUnknown,
    defForeignAddress,
    defAddressLine1,
    defAddressLine2,
    defCity,
    defState,
    defZip,
    defCountry,
    courtDate,
    courtRoom,
    courtTime,
    radioNoCourtDetail,
    radioNoCourtText,
    isRefusedSign,
    arrestingReleasingAgency,
  } = pretiralData;
  const dashboardReducer = useDashboardReducer();
  const [showInfoPopup, setShowInfoPopup] = useState({
    show: false,
    info: "",
  });


  useEffect(() => {
    dispatch(resetSignature());
    dispatch(getCountryList());
    const ecomplaintId = Number(query.get("ecomplaintId"));
    if (ecomplaintId) {
      dispatch(getOfficerDatesOrAgencyDates(ecomplaintId));
    }
    const ecFormId = Number(query.get("ecFormId"));
    if (ecFormId) {
      dispatch(
        getPreTrialData({
          ecFormId,
          navigate,
        })
      );
    }
  }, [dispatch, query, navigate]);

  const calculateMinDate = () => {
    const today = new Date();
    const minAllowedDate = new Date();
    minAllowedDate.setDate(today.getDate() + 14);
    return minAllowedDate.toISOString().split("T")[0];
  };

  useEffect(()=>{
   setMinDate(calculateMinDate());
    return() =>{
      dispatch(resetSignature());
      dispatch(updateElementValuePpretiralData({
        elementName : 'isRefusedSign',
        value : "N"
      }));
    }
  },[])

  useEffect(() => {
    return () => {
      dispatch(resetPreTrialReleaseRecognizanceReducer());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateElementValuePpretiralData({ elementName: "courtRoom", value: officerDateProxy.assignedCourtLocation}));
  }, [getOfficerDatesOrAgencyDatesStatus === "success"])

  const handelChangeRadio = (event: any) => {
    const { id, checked } = event.target;
    if (id === "radioDcnCollectedNo" && checked) {
      dispatch(
        updateElementValuePpretiralData({
          elementName: "radioDcnCollectedYes",
          value: "N",
        })
      );
      dispatch(
        updateElementValuePpretiralData({
          elementName: "documentControlNumber",
          value: "",
        })
      );
    } else if (id === "radioDcnCollectedYes" && checked) {
      dispatch(
        updateElementValuePpretiralData({
          elementName: "radioDcnCollectedNo",
          value: "N",
        })
      );
    } else if (id === "defAddressUnknown") {
      dispatch(
        updateElementValuePpretiralData({
          elementName: "defState",
          value: "",
        })
      );
      if (checked) {
        dispatch(
          updateElementValuePpretiralData({
            elementName: "defAddressLine1",
            value: "",
          })
        );
        dispatch(
          updateElementValuePpretiralData({
            elementName: "defAddressLine2",
            value: "",
          })
        );
        dispatch(
          updateElementValuePpretiralData({
            elementName: "defCity",
            value: "",
          })
        );

        dispatch(
          updateElementValuePpretiralData({
            elementName: "defZip",
            value: "",
          })
        );
        dispatch(
          updateElementValuePpretiralData({
            elementName: "defCountry",
            value: "",
          })
        );
        dispatch(
          updateElementValuePpretiralData({
            elementName: "defForeignAddress",
            value: "N",
          })
        );
      } else {
        dispatch(
          updateElementValuePpretiralData({
            elementName: "defCountry",
            value: "UNITED STATES",
          })
        );
      }
    } else if (id === "defForeignAddress") {
      dispatch(
        updateElementValuePpretiralData({
          elementName: "defState",
          value: "",
        })
      );
      if (checked) {
        dispatch(
          updateElementValuePpretiralData({
            elementName: "defAddressUnknown",
            value: "N",
          })
        );
        dispatch(
          updateElementValuePpretiralData({
            elementName: "defCountry",
            value: "",
          })
        );
      } else {
        dispatch(
          updateElementValuePpretiralData({
            elementName: "defCountry",
            value: "UNITED STATES",
          })
        );
      }
    } else if (id === "radioNoCourtDetail") {
      dispatch(
        updateElementValuePpretiralData({
          elementName: "radioNoCourtText",
          value: "N",
        })
      );
      dispatch(
        updateElementValuePpretiralData({
          elementName: "courtRoom",
          value: officerDateProxy.assignedCourtLocation,
        })
      );
    } else if (id === "radioNoCourtText") {
      dispatch(
        updateElementValuePpretiralData({
          elementName: "radioNoCourtDetail",
          value: "N",
        })
      );
      dispatch(
        updateElementValuePpretiralData({
          elementName: "courtRoom",
          value: "",
        })
      );
      dispatch(
        updateElementValuePpretiralData({
          elementName: "courtDate",
          value: "",
        })
      );
      dispatch(
        updateElementValuePpretiralData({
          elementName: "courtTime",
          value: "",
        })
      );
      dispatch(
        updateElementValuePpretiralData({
          elementName: "courtAddress",
          value: "",
        })
      );
    }else if (id === 'isRefusedSign'){
      if (checked){
        dispatch(updateElementValuePpretiralData({
          elementName : 'isRefusedSign',
          value : "Y"
        }));
      }dispatch(updateElementValuePpretiralData({
        elementName : 'isRefusedSign',
        value : "N"
      }));
    } else if (id === 'defDLConfiscated'){
      if (checked){
        dispatch(updateElementValuePpretiralData({
          elementName : 'defDLConfiscated',
          value : "Y"
        }));
      }dispatch(updateElementValuePpretiralData({
        elementName : 'defDLConfiscated',
        value : "N"
      }));
    }
    dispatch(
      updateElementValuePpretiralData({
        elementName: id,
        value: checked ? "Y" : "N",
      })
    );
  };

  const handelChangeSelect =
    (name: string) => (event: { label: string; value: string } | any) => {
      dispatch(
        updateElementValuePpretiralData({
          elementName: name,
          value: event.value,
        })
      );
    };

  const handleValChange = (event: any) => {
    const { value, name } = event.target;
    dispatch(
      updateElementValuePpretiralData({
        elementName: name,
        value,
      })
    );
  };

  const handleDateValChange = (event: any) => {
    const { value, name } = event.target;
    dispatch(
      updateElementValuePpretiralData({
        elementName: name,
        value,
      })
    );
  };

  const handleUpperCaseInputChange = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementValuePpretiralData({ 
        elementName: name, 
        value: value.toUpperCase() 
      })
    );
  }

  const handleInputBlur = (event: any) => {
    let { name, value } = event.target;
    value = validateDate(value);
    dispatch(
      updateElementValuePpretiralData({ elementName: name, value: value })
    );
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let { value, name } = event.target;
    if ((minDate && value < minDate)) {
      console.log(event.target.value)
      event.target.value = "";
    } else {
      let value2 = validateDate(value);
      dispatch(
        updateElementValuePpretiralData({ elementName: name, value: value2 })
      );
    }
  };

  const handleNumbersDashKeyPress = (evt: any) => {
    if (
      evt.which !== 8 &&
      evt.which !== 9 &&
      (evt.which < 48 || evt.which > 57) &&
      evt.key !== "-"
    ) {
      evt.preventDefault();
    }
  };

  const handleFormatZip = (event: any) => {
    event.target.value = formattedZipcode(event.target.value);
    handleValChange(event);
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      console.log("handleError", _errorKeys, errorMessages);
      setErrorKeys(_errorKeys);
      const removeDuplicate = [...new Set(errorMessages)];
      handleErrorMessages(removeDuplicate);
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    },

    [handleErrorMessages]
  );

  const handleWarning = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setWarningKeys(_errorKeys);
      handleErrorWarnings(errorMessages);
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    },

    [handleErrorWarnings]
  );

  const handleConfirmation = async () => {
    try {
      handleError([], []);
      if (isOfficerSchedulerListEmpty) {
        await preTrialReleaseOnOwnRecognizanceRequiredSchema2.validate(
          pretiralData,
          {
            abortEarly: false,
          }
        );
      } else {
        await preTrialReleaseOnOwnRecognizanceRequiredSchema1.validate(
          pretiralData,
          {
            abortEarly: false,
          }
        );
      }
    } catch (error: any) {
      confirmationModel.close();
      return handleError(
        error.inner.map((e: any) => e.path),
        error.errors
      );
    }

    try {
      await preTrialReleaseOnOwnRecognizanceWarningSchema.validate(
        pretiralData,
        {
          abortEarly: false,
        }
      );
    } catch (error: any) {
      const _errorKeys = error.inner.map((e: any) => e.path);
      const isSameWarning =
        _errorKeys.includes("documentControlNumber") &&
        warningKeys.includes("documentControlNumber");
      if (!isSameWarning) {
        confirmationModel.close();
        return handleWarning(_errorKeys, error.errors);
      }
    }
    const data = {
      ...pretiralData,
    };
    if (isOfficerSchedulerListEmpty) {
      if (pretiralData.radioNoCourtDetail === "Y") {
        data.courtDate = moment(pretiralData.courtDate).format("MM/DD/YYYY");
        data.courtAddress = officerDateProxy.courtAddress;
      } else {
      }
    } else {
      const officerSchedule = officerDateProxy.officerScheduleList.find(
        (item) => {
          const label = `${item.courtDate}-${item.courtTime}-${item.courtLocation}`;
          return label === pretiralData.courtDateData;
        }
      );
      if (officerSchedule) {
        data.courtDate = officerSchedule.courtDate;
        data.courtTime = officerSchedule.courtTime;
        data.courtRoom = officerSchedule.courtLocation;
        data.courtAddress = officerDateProxy.courtAddress;
      }
    }
    const defAddress = formatAddress(
      data.defAddressLine1,
      data.defAddressLine2,
      data.defCity,
      data.defState,
      data.defZip,
      data.defCountry
    );
    data.defAddress = defAddress;
    data.defSignature = signPanelReducer.sign;
    const ecFormId = Number(query.get("ecFormId"));
    const ecomplaintId = Number(query.get("ecomplaintId"));

    dispatch(
      savePreTrialReleaseFormData({
        ecFormId,
        ecomplaintId,
        navigate,
        data,
      })
    );
  };

  const handleCancel = () => {
    navigate("/EComplaintDashboard");
  };

  const getAdditionalDates = () => {
    const scheduleSet = new Set(officerDateProxy.officerScheduleList.map(schedule => `${schedule.courtDate}-${schedule.courtTime}-${schedule.courtLocation}`));
    const uniqueAgencySchedules = officerDateProxy.agencyScheduleList.filter(schedule => {
      const key = `${schedule.courtDate}-${schedule.courtTime}-${schedule.courtLocation}`;
      if (scheduleSet.has(key)) {
        return false;
      } else {
        scheduleSet.add(key);
        return true;
      }
    });

    const updatedScheduleList = [
      ...officerDateProxy.officerScheduleList,
      ...uniqueAgencySchedules,
    ];

    dispatch(
      updateElementValuePpretiralData({
        elementName: "officerScheduleList",
        value: updatedScheduleList,
      })
    );
    setShowAdditionalDateBtn(true);
    setShowInfoPopup({
      info: "Please choose more dates from the dropdown.",
      show: true,
    });

    return [...officerDateProxy.officerScheduleList, ...uniqueAgencySchedules];
  };

  const handlePreviewClick = () => {
    const ecFormId = Number(query.get("ecFormId"));
    const data = {
      ...pretiralData,
    };
    if (isOfficerSchedulerListEmpty) {
      if (pretiralData.radioNoCourtDetail === "Y") {
        data.courtDate = moment(pretiralData.courtDate).format("MM/DD/YYYY");
        data.courtAddress = officerDateProxy.courtAddress;
      } else {
      }
    } else {
      const officerSchedule = officerDateProxy.officerScheduleList.find(
        (item) => {
          const label = `${item.courtDate}-${item.courtTime}-${item.courtLocation}`;
          return label === pretiralData.courtDateData;
        }
      );
      if (officerSchedule) {
        data.courtDate = officerSchedule.courtDate;
        data.courtTime = officerSchedule.courtTime;
        data.courtRoom = officerSchedule.courtLocation;
        data.courtAddress = officerDateProxy.courtAddress;
      }
    }
    const defAddress = formatAddress(
      data.defAddressLine1,
      data.defAddressLine2,
      data.defCity,
      data.defState,
      data.defZip,
      data.defCountry
    );
    data.defAddress = defAddress;
    data.defSignature = signPanelReducer.sign;
    dispatch(
      previewPreTrialRelease({
        ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
        ecFormId: ecFormId,
        formData: data,
      })
    );
  };

  const remoteCaptureSign = () => {

    const data = {
      ...pretiralData,
    };
    if (isOfficerSchedulerListEmpty) {
      if (pretiralData.radioNoCourtDetail === "Y") {
        data.courtDate = moment(pretiralData.courtDate).format("MM/DD/YYYY");
        data.courtAddress = officerDateProxy.courtAddress;
      } else {
      }
    } else {
      const officerSchedule = officerDateProxy.officerScheduleList.find(
        (item) => {
          const label = `${item.courtDate}-${item.courtTime}-${item.courtLocation}`;
          return label === pretiralData.courtDateData;
        }
      );
      if (officerSchedule) {
        data.courtDate = officerSchedule.courtDate;
        data.courtTime = officerSchedule.courtTime;
        data.courtRoom = officerSchedule.courtLocation;
        data.courtAddress = officerDateProxy.courtAddress;
      }
    }
    const defAddress = formatAddress(
      data.defAddressLine1,
      data.defAddressLine2,
      data.defCity,
      data.defState,
      data.defZip,
      data.defCountry
    );
    data.defAddress = defAddress;
    data.defSignature = signPanelReducer.sign;
    const ecFormId = Number(query.get("ecFormId"));
    const ecomplaintId = Number(query.get("ecomplaintId"));

    dispatch(
      savePreTrialReleaseDataTemp({
        ecFormId,
        ecomplaintId,
        data,
      })
    ).then(() => {
      dispatch(
        updateElementValueInSignPanelReducer({
          elementName: "isRemoteCaptureSign",
          value: true,
        })
      );
    })
  }

  const agencies =
    agencyList.map((agency) => {
      return {
        label: agency.agencyCode+"-"+agency.agencyName,
        value: agency.agencyCode+"-"+agency.agencyName,
      };
    })


  let lstCourtTime = officerDateProxy.courtTimeList
    ? officerDateProxy.courtTimeList.split(";").map((item) => {
        return { label: item, value: item };
      })
    : [];

  const lstCourtProceedings = officerDateProxy.officerScheduleList
    ? officerDateProxy.officerScheduleList.map((item) => {
        const label = `${item.courtDate}-${item.courtTime}-${item.courtLocation}`;
        return { label, value: label };
      })
    : [];

  // If the value prevously set is not available anymore. This won't happen unless the save fails.
  useEffect(() => {
    
    if (lstCourtProceedings.length > 0 && courtDateData) {
      const isCourtDareDataExist = lstCourtProceedings.find(
        (item) => item.value === courtDateData
      );
      if (!isCourtDareDataExist) {
        dispatch(
          updateElementValuePpretiralData({
            elementName: "courtDateData",
            value: "",
          })
        );
      }
    }
  }, [lstCourtProceedings, courtDateData, dispatch]);

  const DefendantFingerPrintCheckComponent = () => {
    return (
      <div className="d-flex flex-row me-1">
        <p className="pt-2">Defendant Fingerprints Collected :</p>
        <CheckBoxComponent
          label="No"
          type="radio"
          name="radioDcnCollectedNo"
          id="radioDcnCollectedNo"
          checked={radioDcnCollectedNo === "Y"}
          onChange={handelChangeRadio}
          isError={errorKeys.includes("radioDcnCollectedNo")}
          className="mx-4 pt-1"
        />
        <CheckBoxComponent
          label="Yes"
          type="radio"
          name="radioDcnCollectedYes"
          id="radioDcnCollectedYes"
          checked={radioDcnCollectedYes === "Y"}
          onChange={handelChangeRadio}
          isError={errorKeys.includes("radioDcnCollectedYes")}
          className=" pt-1"
        />
      </div>
    );
  };

  const CourtProceedingComponent = () => {
    return (
      <>
        <Row className="g-2 align-items-center">
          <Col className="d-flex align-items-center" xs={"auto"}>
            <label style={{paddingRight : "2px"}}>Court&nbsp;Proceedings :</label>
            <CheckBoxComponent
              label=""
              type="radio"
              name="radioCourtText"
              id="radioNoCourtDetail"
              onChange={handelChangeRadio}
              checked={radioNoCourtDetail === "Y"}
              isError={errorKeys.includes("radioNoCourtDetail")}
              className="ps-2 mt-1"
            />
          </Col>
          <Col md={2}>
            <TextInputComponent
              type="text"
              name="courtRoom"
              onChange={handleValChange}
              value={courtRoom}
              disabled={true}
              isWarning={warningKeys.includes("courtRoom")}
            />
          </Col>
          <Col md={2}>
            <TextInputComponent
              type="date"
              name="courtDate"
              onChange={handleValChange}
              onBlur={handleBlur}
              value={courtDate}
              disabled={radioNoCourtText === "Y"}
              isError={errorKeys.includes("courtDate")}
              
              min={minDate}
            />
          </Col>
          <Col md={2}>
            <SelectComponent
              options={[
                { label: "-- SELECT --", value: "" },
                ...lstCourtTime,
              ]}
              placeholder="-- SELECT --"
              name="courtTime"
              onChange={handelChangeSelect("courtTime")}
              isError={errorKeys.includes("courtTime")}
              isDisabled={radioNoCourtText === "Y"}
              isDefaultLabel={true}
              value={lstCourtTime.find((item) => item.value === courtTime)}
            />
          </Col>

        </Row>
        <Row>
          <Col xs={{ span: 11, offset: 1 }}>
            <CheckBoxComponent
              label={<label style={{paddingLeft : "12px"}}>The Circuit Clerk's Office will mail a court date to the address listed below.</label>}
              type="radio"
              name="radioCourtText"
              id="radioNoCourtText"
              onChange={handelChangeRadio}
              checked={radioNoCourtText === "Y"}
              isError={errorKeys.includes("radioNoCourtText")}
              className="mt-3 ps-3"
            />
          </Col>
        </Row>
      </>
    );
  };

  const AddMoreAgencyDates = () => {
    return (
      <>
        <Col>
          <ButtonComponent variant="info" onClick={getAdditionalDates} hidden={officerDateProxy.additionalRecord !== 'true'} disabled={showAdditionalDateBtn}>
            <RiAddCircleLine /> Show More Dates
          </ButtonComponent>
        </Col>
      </>
    );
  };

  const data = [
    {
      type: "htmlTag",
      props: {
        value: (
          <>
            <b><i>Court Proceedings loading.....</i></b>
          </>
        )
      },
      colSize: 12,
      colClassName: "mt-2 mb-2",
      colHide: getOfficerDatesOrAgencyDatesStatus != "loading",
    },
    {
      type: "selectInput",
      props: {
        options: [{ label: "-- SELECT --", value: "" }, ...lstCourtProceedings],
        placeholder: "-- SELECT --",
        name: "courtDateData",
        onChange: handelChangeSelect("courtDateData"),
        isError: errorKeys.includes("courtDateData"),
        value: lstCourtProceedings.find((item) => item.value === courtDateData),
        isDefaultLabel: true
      },
      label: "Court Proceedings :",
      inLine: true,
      colSize: 5,
      colHide: isOfficerSchedulerListEmpty || getOfficerDatesOrAgencyDatesStatus === "loading",     
    },
    {
      type: "htmlTag",
      props: {
        value: <AddMoreAgencyDates />,
      },
      colSize: 2,
      colHide: isOfficerSchedulerListEmpty || getOfficerDatesOrAgencyDatesStatus === "loading",
    },
    {
      type: "empty",
      colHide: isOfficerSchedulerListEmpty || getOfficerDatesOrAgencyDatesStatus === "loading",
      colSize: 4,
    },
    {
      type: "htmlTag",
      props: {
        value: <CourtProceedingComponent />,
      },
      colSize: 12,
      colHide: !isOfficerSchedulerListEmpty || getOfficerDatesOrAgencyDatesStatus === "loading",
    },
    {
      type: "htmlTag",
      props: {
        value: <DefendantFingerPrintCheckComponent />,
      },
      colSize: 4,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "documentControlNumber",
        onChange: handleUpperCaseInputChange,
        value: documentControlNumber,
        disabled: radioDcnCollectedNo === "Y",
        isError: errorKeys.includes("documentControlNumber"),
        isWarning: warningKeys.includes("documentControlNumber")
      },
      colSize: 4,
      label: "Document Control Number :",
      inLine: true,
      emptyCol: 4,
    },
    {
      type: "selectInput",
      props: {
        options: [{ label: "-- SELECT --", value: "" }, ...agencies],
        placeholder: "-- SELECT --",
        name: "arrestingReleasingAgency",
        onChange: handelChangeSelect("arrestingReleasingAgency"),
        isError: errorKeys.includes("arrestingReleasingAgency"),
        value: agencies.find((item) => item.label === arrestingReleasingAgency),
        isDefaultLabel: true,
        isDisabled: arrestingReleasingAgency
      },
      label: "Arresting/Releasing Agency :",
      inLine: true,
      colSize: 6,
      emptyCol: 6, 
    },
    {
      type: "checkbox",
      props: {
        label: "Drivers License/Permit Confiscated and Forwarded to the Circuit Court Clerk",
        type: "checkbox",
        name: "defDLConfiscated",
        onChange: handelChangeRadio,
        id: "defDLConfiscated",
        checked: defDLConfiscated === "Y",
        autoComplete: "nope",
      },
      colSize: 8,
      colClassName: "mb-2",
    },
    {
      type: "htmlTag",
      props: {},
      colSize: 4,
    },
    {
      type: "checkbox",
      props: {
        label: "Unknown Address",
        type: "checkbox",
        name: "address",
        onChange: handelChangeRadio,
        id: "defAddressUnknown",
        checked: defAddressUnknown === "Y",
        autoComplete:"nope",
      },
      colClassName: "mb-2",
      colSize: 2,
    },
    {
      type: "checkbox",
      props: {
        label: "Foreign Address",
        type: "checkbox",
        name: "address",
        onChange: handelChangeRadio,
        id: "defForeignAddress",
        checked: defForeignAddress === "Y",
        autoComplete:"nope",
      },
      colSize: 3,
      colClassName: "mb-2",
      emptyCol: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "defAddressLine1",
        onChange: handleUpperCaseInputChange,
        value: defAddressLine1,
        disabled: defAddressUnknown === "Y",
        autoComplete:"nope",
      },
      colSize: 6,
      label: "Address Line 1 :",
      inLine: true,
      emptyCol: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "defAddressLine2",
        onChange: handleUpperCaseInputChange,
        disabled: defAddressUnknown === "Y",
        value: defAddressLine2,
        autoComplete:"nope",
      },
      colSize: 6,
      label: "Address Line 2 :",
      inLine: true,
      emptyCol: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "defCity",
        onChange: handleUpperCaseInputChange,
        disabled: defAddressUnknown === "Y",
        value: defCity,
        autoComplete:"nope",
      },
      colSize: 3,
      label: "City :",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "defState",
        onChange: handleUpperCaseInputChange,
        disabled: defAddressUnknown === "Y",
        value: defState,
        autoComplete:"nope",
      },
      colSize: 3,
      label: "State/Province :",
      inLine: true,
      colHide: defForeignAddress !== "Y",
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...STATE_LIST.map((sta: any) => {
            return { label: sta, value: sta };
          }),
        ],
        name: "defState",
        isDisabled: defAddressUnknown === "Y",
        onChange: handelChangeSelect("defState"),
        defaultValue: { label: "-- Select --", value: "" },
        value: [
          { label: "-- Select --", value: "" },
          ...STATE_LIST.map((sta: any) => {
            return { label: sta, value: sta };
          }),
        ].find((item) => {
          if (item.value === defState) {
            return true;
          }
        }),
        isDefaultLabel: true,
        autoComplete:"nope",
      },
      label: "State :",
      inLine: true,
      colSize: 3,
      colHide: defForeignAddress === "Y",
      isDefaultLabel: true
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "defZip",
        onChange: handleValChange,
        disabled: defAddressUnknown === "Y",
        onKeyPress: handleNumbersDashKeyPress,
        onBlur: handleFormatZip,
        value: defZip,
        autoComplete:"nope",
      },
      colSize: 3,
      label: "Zip : ",
      inLine: true,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...countries.map((cntry: any) => {
            return { label: cntry, value: cntry };
          }),
        ],
        labelName: "Country",
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...countries.map((cntry: any) => {
            return { label: cntry, value: cntry };
          }),
        ].find((item) => {
          if (item.value === defCountry) {
            return true;
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defCountry",
        onChange: handelChangeSelect("defCountry"),
        isSelected: true,
        isDefaultLabel: true,
        isDisabled: defAddressUnknown === "Y" || defForeignAddress === "N",
        autoComplete:"nope",
      },
      label: "Country :",
      inLine: true,
      colSize: 3,
    },
    {
      type: "checkbox",
      props: {
        label: "Defendant Refused to Sign",
        type: "checkbox",
        name: "refusedSign",
        onChange: handelChangeRadio,
        id: "isRefusedSign",
        checked: isRefusedSign === "Y",
        autoComplete: "nope",
      },
      colSize: 3,
      colClassName: "mb-2",
      emptyCol: 6,
    },
    {
      type: "signPanel",
      props: {
        className: "d-block",
        name: "sign",
        parentComponent: PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE,
        remotecaptureSignEnabled: true,
        remoteCaptureSign: remoteCaptureSign
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <Row>
            <Col className="text-start"></Col>
            <Col className="text-center">
              <ButtonComponent
                variant="success"
                className="mx-1"
                onClick={confirmationModel.open}
              >
                Save
              </ButtonComponent>
            </Col>
            <Col className="text-end">
              <ButtonComponent
                variant="info"
                className="mx-1"
                onClick={handlePreviewClick}
              >
                Preview
              </ButtonComponent>
              <ButtonComponent variant="danger" onClick={handleCancel}>
                Cancel
              </ButtonComponent>
            </Col>
          </Row>
        ),
      },
      colSize: 12,
    },
  ];

  const  handleLongFormListClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/"+dashboardReducer.PreviousURLLFC.replace(/^.*\/\/.*?\//, ''), itemName: "Long Form List", itemFunction: handleLongFormListClick },
  ];
  return (
    <>
      <Header headerName="Pre-Trial Release on own Recognizance" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Pre-Trial Release on own Recognizance"
        />
        <ErrorMessageAlert
          messages={errorMessages}
          clearError={clearErrorMessages}
        />
        <WarningAlert
          messages={warningMessages}
          clearWarning={clearErrorWarnings}
        />
        <FormComponent totalColumns={12} colSize={6} componentData={data} />
        <ConfirmPopup
          children={CONFIRM_SUBMIT_PRETRIAL_RELEASE_OWN_RECOGNIZANCE_MESSAGE}
          show={confirmationModel.isOpen}
          onHide={confirmationModel.close}
          isConfirmation={handleConfirmation}
        />
        <InfoPopup
          children={showInfoPopup.info}
          onHide={() =>
            setShowInfoPopup({
              show: false,
              info: "",
            })
          }
          show={showInfoPopup.show}
      />
      </div>
    </>
  );
}
