import React from "react";
import { Form, FormControlProps } from "react-bootstrap";

export type TextAreaProps = {
  labelName?: string;
  className?: string;
  isError?: boolean;
  isWarning?: boolean;
  isRequired?: boolean;
  name?: string;
  maxLength?:number;
  rows?: number;
} & FormControlProps;

const TextAreaComponent: React.FC<TextAreaProps> = (props) => {
  const { labelName, className, isError, isWarning, isRequired, name, maxLength, rows, ...rest } =
    props;
  return (
    <Form.Group>
      {labelName ? (
        <label>
          {labelName} {isRequired ? <span>*</span> : ""}
        </label>
      ) : (
        ""
      )}
      <Form.Control
        {...rest}
        as="textarea"
        rows={rows || 3}
        className={`${className ? className : ""} ${
          isError ? "border-danger" : ""
        } ${isWarning ? "border-warning" : ""} ${"p-2"}`}
        name={name}
        maxLength={maxLength}
      />
    </Form.Group>
  );
};

export default TextAreaComponent;
