import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { LoadingType } from "../../types";
import { showHideLoadingGif } from "./commonSlice";
import { extractFileName } from "../../utils/common";
import { getSWConcurrentObj } from "./commonFormSlice";
import { updateCountyStatus } from "./countyListSlice";
import { useDispatch } from "react-redux";
import { updateCountyObject } from "./countySlice";

type errorInfo = {
    error : boolean,
    errorMessage : string,
    errorMessageList?: string[]

};
interface CaseDetailState {
  searchWarrantId: number;
  agencyCode: string;
  agencyName: string;
  approvalOrderBySA: string;
  approvalSA: string;
  approvalSAId: number;
  approvalUserTypeId: number;
  approvalUserTypeName: string;
  caseNumber: string;
  countyId: number;
  countyName: string;
  stateName: string;
  stateAttorneyCode: string;
  // countyAdmonishment: string;
  // autoCompleteInventory: boolean;
  createFormList: any[];
  creatorEmail: string;
  creatorId: number;
  creatorName: string;
  creatorPhone: string;
  formBadgeNumber: string;
  county: any;
  messageList: string[];
  onBehalf: boolean;
  searchWarrantList: any[];
  status: string;
  templateId: number;
  templateName: string;
  agencyCaseNumber: string;

  swFailSuccessMessage: string;

  fetchDetailStatus: LoadingType;
  fetchDetailError: string;

  responseMessage: string;
  responseErrorMessage: string;

  //navigateFrom : string;

  uploadedFileName: string;
  uploadedFile: string;

  protectedComplaint: boolean;
  errorInfo: errorInfo;
}

const initialState = {
  searchWarrantId: 0,
  agencyCode: "",
  agencyName: "",
  approvalOrderBySA: "",
  approvalSA: "",
  approvalSAId: 0,
  approvalUserTypeId: 0,
  approvalUserTypeName: "",
  caseNumber: "",
  countyId: 0,
  countyName: "",
  stateName: "",
  stateAttorneyCode: "",
  // countyAdmonishment: "",
  // autoCompleteInventory: false,
  createFormList: [],
  creatorEmail: "",
  creatorId: 0,
  creatorName: "",
  creatorPhone: "",
  formBadgeNumber: "",
  county: {},
  messageList: [],
  onBehalf: false,
  searchWarrantList: [],
  status: "",
  templateId: 0,
  templateName: "",
  agencyCaseNumber: "",

  swFailSuccessMessage: "",

  fetchDetailStatus: "idle",
  fetchDetailError: "",

  responseMessage: "",
  responseErrorMessage: "",

  //navigateFrom : "",
  uploadedFileName: "",
  uploadedFile: "",

  protectedComplaint: false,
  errorInfo: { error: false, errorMessage: '', errorMessageList: [] },
} as CaseDetailState;

export const getSWCaseDetail = createAsyncThunk<
  any,
  { searchWarrantId: number; action: string; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "caseDetail/getSWCaseDetail",
  async (
    { searchWarrantId, action, navigate },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url:
          "casedetailservices/getSWCaseDetail/" +
          searchWarrantId +
          "/" +
          action,
        token,
      });
      if (response.ok) {
        const result = await response.json();
        dispatch(updateCountyObject(result.county));
        dispatch(showHideLoadingGif(false));

        navigate("/CaseDetails?searchWarrantId=" + searchWarrantId);
        return result;
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching search warrant data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching search warrant data"
      );
    }
  }
);

export const modifyDocument = createAsyncThunk<
  any,
  {
    searchWarrantid: number;
    formType: string;
    file: any;
  },
  { state: RootState; rejectValue: string }
>(
  "caseDetail/modifyDocument",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantid, formType, file } = data;
    let { token } = getState().login;

    try {
      dispatch(showHideLoadingGif(true));
      const response: any = await doFetch({
        url:
          "searchwarrantservices/modifyDocument/" +
          searchWarrantid +
          "/" +
          formType,
        token,
        type: "POST",
        body: file,
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while generating pdf. Try again later."
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while generating pdf. Try again later."
      );
    }
  }
);

export const generateDocument = createAsyncThunk<
  any,
  {
    searchWarrantid: number;
    formType: string;
  },
  { state: RootState; rejectValue: string }
>(
  "caseDetail/generateDocument",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantid, formType } = data;
    let { token } = getState().login;
    try {
      dispatch(showHideLoadingGif(true));
      const response: any = await doFetch({
        url:
          "searchwarrantservices/generateDocument/" +
          searchWarrantid +
          "/" +
          formType,
        token,
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        let disposition = response.headers.get("Content-Disposition");
        let fileName = extractFileName(disposition);

        response.blob().then((blob: any) => {
          if (blob && blob.size > 0) {
            const url = URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            let a = document.createElement("a");
            a.href = url;
            a.download = formType + ".pdf";
            a.click();
            console.log("show Preview Popup");
          } else {
            console.log("show Error Popup");
          }
        });
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while generating pdf. Try again later."
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while generating pdf. Try again later."
      );
    }
  }
);

const caseDetailSlice = createSlice({
  name: "caseDetail",
  initialState,
  reducers: {
    updateElementValueCaseDetailReducer: updateElementValue,
    updateCaseDetailObject: (state: CaseDetailState, action) => {
      let object = action.payload as CaseDetailState;
      return {
        ...state,
        ...object,
      };
    },
    resetCaseDetailData: (state: CaseDetailState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      //   .addCase(createBlankOrder.pending, (state) => {
      //     console.log("Hey");
      //     state.status = "loading";
      //     state.userTypeError = undefined;
      //   })
      //   .addCase(createBlankOrder.fulfilled, (state, action) => {
      //     console.log("there")
      //     console.log(action.payload)
      //     state.blankOrderObj = action.payload;
      //   })
      //   .addCase(createBlankOrder.rejected, (state, action) => {
      //     console.log("Hi");
      //     state.status = "error";
      //     state.userTypeError = undefined;
      //   })

      .addCase(getSWCaseDetail.pending, (state) => {
        state.fetchDetailStatus = "loading";
        state.fetchDetailError = "";
      })
      .addCase(getSWCaseDetail.fulfilled, (state, action) => {
        state = action.payload;
        return {
          ...state,
          ...action.payload,
          fetchDetailStatus: "success",
        };
      })
      .addCase(getSWCaseDetail.rejected, (state, action) => {
        state.fetchDetailStatus = "error";
        state.fetchDetailError = "";
      })

      .addCase(modifyDocument.pending, (state) => {
        state.fetchDetailStatus = "loading";
        state.fetchDetailError = "";
      })
      .addCase(modifyDocument.fulfilled, (state, action) => {
        return {
          ...state,
          fetchDetailStatus: "success",
          responseMessage: action.payload,
        };
      })
      .addCase(modifyDocument.rejected, (state, action) => {
        state.fetchDetailStatus = "error";
        state.responseErrorMessage = "Error occurred while replacing the PDF";
      })
      .addCase(generateDocument.pending, (state) => {
        state.fetchDetailStatus = "loading";
        state.fetchDetailError = "";
      })
      .addCase(generateDocument.fulfilled, (state, action) => {
        return {
          ...state,
          fetchDetailStatus: "success",
        };
      })
      .addCase(generateDocument.rejected, (state, action) => {
        state.fetchDetailStatus = "error";
        state.fetchDetailError = "";
      });
  },
});
export const {
  updateElementValueCaseDetailReducer,
  updateCaseDetailObject,
  resetCaseDetailData,
} = caseDetailSlice.actions;
export const useCaseDetailReducer = () =>
  useAppSelector((state) => state.caseDetail);
export default caseDetailSlice.reducer;
