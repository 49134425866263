import React,{useCallback, useEffect} from "react";
import { Modal, Row, Col } from "react-bootstrap";
import SelectComponent from "../../common/SelectComponent";
import ButtonComponent from "../../common/ButtonComponent";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";
import { useAppDispatch } from "../../../hooks/hooks";
import { createAmendInventory } from "../../../redux/slice/amendInventorySlice";
import { useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";

let defaultSchema = yup.object().shape({
  selectType: yup.string().required("Form Type is required."),
});

type AmendInventorySourcePopupProps = {
  show: boolean;
  toggleShow: () => void;
};

const AmendInventorySourcePopup: React.FC<AmendInventorySourcePopupProps> = (
  props
) => {
  const { show, toggleShow } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const caseDetailReducer = useCaseDetailReducer();

  const [selectType, setSelectType] = React.useState("");
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();

  const generateAmend = async () => {
    try {
      await defaultSchema.validate({selectType}, {
        abortEarly: false,
      });
      dispatch(createAmendInventory({searchWarrantId : caseDetailReducer.searchWarrantId, amendFormType : selectType, navigate : navigate}));
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
    //navigate("/AmendInventory");
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const handleValChange = (event: any) => {
    setSelectType(event.value);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={toggleShow}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <label className="fw-bold"> Select Amended Inventory source.</label>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <Row className="mt-2 align-items-center no-gutters">
          <Col>
            <SelectComponent
              name="Select form type"
              isRequired={true}
              placeholder="Select form type"
              options={[
                {
                  value: "NewAmendedInventory",
                  label: "New Amended Inventory",
                },
                {
                  value: "CopyfromOriginalInventory",
                  label: "Pull the data from the most recent Inventory",
                },
              ]}
              onChange={handleValChange}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col xs={8} className="text-end">
          <ButtonComponent onClick={generateAmend}>Generate</ButtonComponent>{" "}
          <ButtonComponent variant="danger" onClick={toggleShow}>
            Cancel
          </ButtonComponent>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default AmendInventorySourcePopup;
