import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { RiAlertLine, RiCheckboxCircleLine, RiCloseCircleLine, RiInformationLine } from "react-icons/ri";
import { IoWarningOutline } from "react-icons/io5";
interface InfoPopupProps {
  show: boolean;
  onHide: () => void;
  children?: React.ReactNode;
  title?: string;
  variant?: "info"  | "success" | "danger" | "warning" ;
}

const InfoPopup: React.FC<InfoPopupProps> = (props) => {
  const { show, onHide, children, title, variant } = props;

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      backdrop="static">
      <Modal.Header closeButton>
        <b>
            {variant === undefined ||  variant === "info" ? 
              (title ? title  : "Infomation") : ""}
            {variant !== undefined &&  variant === "danger" ? 
              (title ? title  : "Error") : ""}
            {variant !== undefined &&  variant === "warning" ? 
              (title ? title  : "Warning") : ""}
            {variant !== undefined &&  variant === "success" ? 
              (title ? title  : "Success") : ""}
        </b>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="text-center">
            <h1>
              {variant === undefined ||  variant === "info" ? 
                <RiInformationLine className="text-primary"/> : ""}
              {variant !== undefined &&  variant === "danger" ? 
                <RiCloseCircleLine className="text-danger"/> : ""}
              {variant !== undefined &&  variant === "warning" ? 
                <RiAlertLine className="text-warning"/> : ""}
              {variant !== undefined &&  variant === "success" ? 
                <RiCheckboxCircleLine className="text-success"/> : ""}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-2">
            <label> {children}</label>
          </Col>
        </Row>
        <hr />
        <Row className="g-2">
          <Col className="text-center">
            <Button
              variant={`${variant !== undefined ? variant : "info"}`}
              onClick={() => onHide()}
              className="ps-5 pe-5"
            >
              OK
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default InfoPopup;
