import React, { useEffect } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import ButtonComponent from "../common/ButtonComponent";
import Header from "../common/Header";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  RiSearchLine,
  RiAddCircleLine,
  RiDraftFill,
  RiFileExcelLine,
  RiFileTextLine,
} from "react-icons/ri";
import ListViewComponent from "../common/ListViewComponent";
import { useAppDispatch } from "../../hooks/hooks";
import {
  getLoggedInUserCounties,
  listCounty,
  resetCountiesData,
  updateCountiesReducer,
  updateCountyStatus,
  updateElementValueCountiesReducer,
  useCountyListReducer,
} from "../../redux/slice/countyListSlice";
import {
  updateElementValueCountyReducer,
  useCountyReducer,
} from "../../redux/slice/countySlice";
import AlertMessage from "../common/AlertMessage";
import ConfirmPopup from "../common/ConfirmPopup";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { USER_TYPES } from "../../constants/common";
import { TableActionComponent } from "../common/TableActionComponent";

interface CountyListProps {}

export const CountyList: React.FC<CountyListProps> = (props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {
    pagedCountyList,
    totalPages,
    currentPage,
    totalRecords,
    countyList,
    county,
    circuitCourtNumber,
    status,
    countiesId,
  } = useCountyListReducer();
  const { userType, countyId } = useLoginReducer();
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const countyReducer = useCountyReducer();
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
    countyID: 0,
    active: false,
  });

  useEffect(() => {
    return () => {
      dispatch(resetCountiesData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (userType === USER_TYPES.ADMINISTRATOR) {
      dispatch((_dispatch) => {
        _dispatch(
          listCounty({
            status: "all",
          })
        );
        _dispatch(getLoggedInUserCounties());
      });
      navigate(location.pathname);
    } else {
      navigate("/");
    }
  }, [dispatch, userType, navigate, location.pathname]);

  const setUrlData = () => {
    let countValue;
    countyList.find((item: any) => {
      if (
        item.countyID ===
        parseInt(searchParams.get("countyId")?.toString() || "0")
      ) {
        countValue = item.countyName;
      }
    });

    let urlPathObj: any = {
      status: searchParams.get("status"),
      county: countValue,
      countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
      circuitCourtNumber: searchParams.get("judicialNumber")?.toString() || "",
    };

    dispatch(updateCountiesReducer(urlPathObj));
  };

  useEffect(() => {
    if (countyReducer.successMessage) {
      setAlertMessage(countyReducer.successMessage);

      dispatch(
        updateElementValueCountyReducer({
          elementName: "successMessage",
          value: "",
        })
      );
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [countyReducer.successMessage, dispatch]);

  let tableHeader = [
    "COUNTY NAME",
    "CIRCUIT COURT #",
    "CLERK NAME",
    "STATUS",
    "ACTION",
  ];
  const handleCountyStatusUpdate =
    (countyID: number, active: boolean) => () => {
      setconfirmPopupData({
        show: true,
        countyID,
        active,
      });
    };

  const handleCountyStatusUpdateConfirm = () => {
    let urlPath: any = {
      status: searchParams.get("status"),
      countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
      judicialNumber: parseInt(
        searchParams.get("judicialNumber")?.toString() || "0"
      ),
      pageNo: parseInt(searchParams.get("pageNo")?.toString() || "0") - 1,
    };

    let data = {
      countyId: confirmPopupData.countyID,
      listViewData: urlPath,
    };
    dispatch(updateCountyStatus(data));
    setconfirmPopupData({
      show: false,
      countyID: 0,
      active: false,
    });
  };

  const handleCountyEdit = (countyID: number) => () => {
    navigate("/EditCounty/" + countyID);
  };

  const handleSubmit = (state: any) => {
    let obj: any = {
      status: status || "all",
      countyId: countiesId,
      judicialNumber: circuitCourtNumber,
    };
    dispatch(listCounty(obj));

    let urlPathObj: any = {
      status: status || "all",
      countyId: countiesId || 0,
      judicialNumber: circuitCourtNumber,
      pageNo: 1,
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlPathObj)}`,
    });
  };

  const paginationHandle = (page: number, searchBarState: any) => {
    let obj = {
      status: searchBarState.status || "all",
      countyId: searchBarState.county,
      judicialNumber: searchBarState.circuitCourtNumber,
      pageNo: page,
    };
    dispatch(listCounty(obj));

    let urlPathObj: any = {
      status: searchBarState.status || "all",
      countyId: searchBarState.county || 0,
      judicialNumber: searchBarState.circuitCourtNumber,
      pageNo: (page + 1).toString(),
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlPathObj)}`,
    });
  };

  window.onpopstate = () => {
    let paramsValue = location.search;
    if (paramsValue.trim().length > 0) {
      let countValue;
      countyList.find((item: any) => {
        if (
          item.countyID ===
          parseInt(searchParams.get("countyId")?.toString() || "0")
        ) {
          countValue = item.countyName;
        }
      });

      let urlPath: any = {
        status: searchParams.get("status"),
        countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
        county: countValue,
        judicialNumber: parseInt(
          searchParams.get("judicialNumber")?.toString() || "0"
        ),
        pageNo: parseInt(searchParams.get("pageNo")?.toString() || "0") - 1,
      };
      setUrlData();
      dispatch(listCounty(urlPath));
    } else {
      dispatch(
        listCounty({
          status: "all",
        })
      );
      setUrlData();
    }
  };

  const handleInputChange = (event: any) => {
    dispatch(
      updateElementValueCountiesReducer({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  const handleCounty = (e: any) => {
    dispatch(
      updateElementValueCountiesReducer({
        elementName: "county",
        value: e.label,
      })
    );
    dispatch(
      updateElementValueCountiesReducer({
        elementName: "countiesId",
        value: e.value,
      })
    );
  };
  const handleStatus = (event: any) => {
    dispatch(
      updateElementValueCountiesReducer({
        elementName: "status",
        value: event.value,
      })
    );
  };

  let tableData = pagedCountyList.map(
    ({
      countyID,
      countyName,
      judicialNumber,
      circuitClerkName,
      active,
    }: any) => [
      countyID,
      countyName,
      judicialNumber,
      circuitClerkName,
      active ? "Active" : "Inactive",
      handleCountyStatusUpdate(countyID, active),
      handleCountyEdit(countyID),
    ]
  );

  const countyListOptions: any = [];
  if (countyList.length! > 1) {
    countyListOptions.push({ label: "-- ALL --", value: 0 });
  }
  countyListOptions.push(
    ...countyList.map((county: any) => {
      return { label: county.countyName, value: county.countyID };
    })
  );

  const statusOptions: any = [];
  statusOptions.push(
    { value: "all", label: "-- ALL --" },
    { value: "true", label: "ACTIVE" },
    { value: "false", label: "INACTIVE" }
  );

  let itemLinkList = [{ itemLink: "/", itemName: "DashBoard" }];
  const data = {
    colSize: 3,
    componentData: [
      {
        type: "selectInput",
        props: {
          options: countyListOptions,
          value: countyListOptions.find((item: any) => {
            if (item.value === countiesId) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select County",
          name: "county",
          defaultValue: { label: "-- ALL --", value: 0 },
          onChange: handleCounty,
        },
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Circuit Court #",
          type: "text",
          name: "circuitCourtNumber",
          placeholder: "Enter Circuit Court #",
          value: circuitCourtNumber,
          onChange: handleInputChange,
        },
      },
      {
        type: "selectInput",
        props: {
          options: statusOptions,
          value: statusOptions.find((item: any) => {
            if (item.value === status) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select Status",
          name: "status",
          defaultValue: { value: "all", label: "-- ALL --" },
          onChange: handleStatus,
        },
      },

      {
        type: "button",
        colSize: 2,
        props: {
          className: "w-100",
          children: (
            <>
              <RiSearchLine /> Search
            </>
          ),
          type: "submit",
          onClick: (state: any) => handleSubmit(state),
        },
      },
    ],
  };

  return (
    <>
      <Header headerName="County List" />

      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="County List" />
        <AlertMessage
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />
        <ListViewComponent
          table={{
            tableHeader: tableHeader,
            tableData: tableData,
            renderTableRow: renderTableRow,
          }}
          searchBar={{
            colSize: data.colSize,
            componentData: data.componentData,
          }}
          middleComponent={() => (
            <ListViewMiddleComponent totalCounties={totalRecords} />
          )}
          pagination={{
            totalPages,
            numberOfPagesToShow: 5,
            currentPage,
            onPageChange: (page: number, searchBarState: any) => {
              paginationHandle(page, searchBarState);
            },
          }}
        />
        <ConfirmPopup
          children={
            confirmPopupData.active === true
              ? "Selected county will be inactivated. Please confirm."
              : "Selected county will be activated. Please confirm."
          }
          show={confirmPopupData.show}
          onHide={() =>
            setconfirmPopupData({
              show: false,
              countyID: 0,
              active: false,
            })
          }
          isConfirmation={handleCountyStatusUpdateConfirm}
        />
      </div>
    </>
  );
};

const ListViewMiddleComponent = ({
  totalCounties,
}: {
  totalCounties: number;
}) => (
  <Row>
    <Col>
      <h4>County List</h4>
      <h6 className="my-n2">Total County : {totalCounties}</h6>
    </Col>
    <Col className="text-end">
      <ButtonComponent variant="info">
        <Link to={"/AddCounty"}>
          <RiAddCircleLine /> Create County
        </Link>
      </ButtonComponent>
    </Col>
  </Row>
);

const renderTableRow = (row: any[]) => {
  const [
    countyID,
    countyName,
    judicialNumber,
    judicialNumberText,
    active,
    handleCountyStatusUpdate,
    handleCountyEdit,
  ] = row;
  return (
    <tr key={countyID}>
      <td>
        <Link to={`/ViewCounty/${countyID}`}>
          <b>{countyName}</b>
        </Link>
      </td>
      <td>{judicialNumber}</td>
      <td>{judicialNumberText}</td>
      <td>{active}</td>
      <td className="justify-content-center d-flex align-items-stretch">
        {handleCountyEdit && (
          <TableActionComponent
            tootltip="Edit"
            children={<RiDraftFill />}
            actionFunction={handleCountyEdit}
            color="text-primary"
            text="Edit"
          />
        )}
        {handleCountyStatusUpdate &&
          (active !== "Active" ? (
            <TableActionComponent
              tootltip="Activate"
              children={<RiFileTextLine />}
              actionFunction={handleCountyStatusUpdate}
              color="text-success"
              text="Activate"
            />
          ) : (
            <TableActionComponent
              tootltip="Inactivate"
              children={<RiFileExcelLine />}
              actionFunction={handleCountyStatusUpdate}
              color="text-danger"
              text="Inactivate"
            />
          ))}
      </td>
    </tr>
  );
};

export default CountyList;
