import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "../common/Header";
import { Card, Row, Col, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  RiFile2Fill,
  RiArrowGoBackFill,
  RiArrowLeftLine,
  RiCloseCircleFill,
  RiDraftFill,
  RiMapPinLine,
  RiVideoChatFill,
  RiLink,
  RiCheckFill,
  RiWindow2Fill,
  RiFileTextLine,
  RiFileCopy2Fill,
  RiShareBoxFill,
} from "react-icons/ri";
import { BsSlashCircle } from "react-icons/bs";
import { FaFileSignature, FaReceipt } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../common/ButtonComponent";
import TableComponent from "../common/TableComponent";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import ConfirmPopup from "../common/ConfirmPopup";
import InfoPopup from "../common/InfoPopup";
import useModel from "../../hooks/useModel";
import AuditTrialPopup from "../common/AuditTrailPopup";
import { useAppDispatch } from "../../hooks/hooks";
import AlertMessage from "../common/AlertMessage";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import {
  forceCreateArrestWarrant,
  forceCreateProbableCauseStatement,
  getEcomplaintMainDetails,
getInitialPreTrialData,
  submitForReviewWithSupervisor,
  tackBack,
  undoCertification,
  updateElementValueComplaintMainReducer,
  useComplaintMainReducer,
  forceCreateSummon,
  getLoggedInUserCountiesForLFC
} from "../../redux/slice/complaintMainSlice";
import { loginUser, useLoginReducer } from "../../redux/slice/loginSlice";
import { TableActionComponent } from "../common/TableActionComponent";
import {
  previewEcomplaint,
  previewArrestWarrent,
  previewPreTrialRelease,
  probableCauseStatementPreview,
  victimInfoPreview,
  summonsPreview,
  previewComplaintReceipt
} from "../../redux/slice/commonFormSlice";
import {
  getVideoConferenceURL,
  updateElementValueVideoConferenceReducer,
} from "../../redux/slice/videoConferenceSlice";
import VideoConferenceSplitScreenPopUp from "../warrantlist/form/VideoConferenceSplitScreenPopUp";
import {
  editComplaint,
  takeComplaintBack,
  deleteArrestWarrant,
  updateLockById,
  useComplaintDashReducer,
  updateElementValuecomplaintDashReducer,
  resubmitAccordingToTheFormType,
} from "../../redux/slice/ecomplaintDashboardSlice";
import {
  LF_SEND_TO_JUDGE_STATUS,
  LF_REJECTED_STATUS,
  LF_VOID_STATUS,
  LF_ARREST_WARRANT,
  LF_COMPLAINT,
  ROLE_JUDGE,
  ROLE_ADMINISTRATOR,
  ROLE_POLICE_OFFICER,
  ROLE_AGENCY_ADMIN,
  ROLE_COUNTY_ADMIN,
  LF_DELETED_STATUS,
  CREATE_LF_FORM_ARREST_WARRANT,
  LF_PENDING_STATUS,
  LF_FORM_TYPE_COMPLAINT,
  LF_IMPOUND_ORDER,
  LF_NOTICE_OF_ARREST_WITHOUT_WARRANT,
  LF_SIGNED_STATUS,
  LF_RETURNED_STATUS,
  LF_SEND_TO_SA_STATUS,
  LF_COMPLETE_STATUS,
  LF_FILED_STATUS,
  LF_ARRW_SIGNED_STATUS,
  FETCH_LF_MAIN_DETAILS_FROM_DASHBOARD,
  PREVIEW_LF_COMPLAINT_FROM_DASHBOARD,
  PREVIEW_LF_COMPLAINT_FROM_VIDEO_CONFERENCE,
  ROLE_STATES_ATTORNEY,
  ROLE_SA_ADMIN,
  LF_SEND_TO_OFFICER_STATUS,
  LF_STATUS_PENDING_SUPERVISOR_REVIEW,
  LF_STATUS_PENDING_SUPERVISOR_RETURNED,
  PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE,
  LF_COMPLAINT_PROCESSING,
  REVISED_PROBABLE_CAUSE_STATEMENT,
  FETCH_LF_MAIN_DETAILS_FROM_ARRESTWARRANT,
  AUDIT_TYPE_ACCESS,
  AUDIT_TYPE_MODIFICATION,
  VICTIM_INFO,
  TYPE_OF_ARREST_ONVIEWARREST,
  CREATE_LF_FORM_SUMMON,
  FETCH_LF_MAIN_DETAILS_FROM_SUMMON,
  LF_SUMMONS,
  LF_COMMUNI_FAILED_STATUS
} from "../../constants/common";
import {
    updateElementValueComplaintReducer,
  useComplaintReducer,
} from "../../redux/slice/complaintSlice";

import {
  returnToOfficerArrestWarrant,
  setECFormData,
  updateAwState,
  updateElementObjectValueArrestWarrantReducer,
} from "../../redux/slice/arrestWarrantSlice";
import { updateElementValuePreTrialReleaseRecognizanceReducer, usePreTrialReleaseRecognizance } from "../../redux/slice/preTrialReleaseRecognizanceSlice";
import { fetchProbableCauseStatementData } from "../../redux/slice/probableCauseStatementSlice";
import {
  fetchAuditTrailData,
  fetchAuditTrailDataForLongForm,
  useAuditTrailReducer,
} from "../../redux/slice/auditTrailSlice";
import {
  useDashboardReducer,
  updateElementValueDashboardReducer,
} from "../../redux/slice/dashboardSlice";
import { updateSummonState, returnToOfficerSummons } from "../../redux/slice/summonsSlice";

interface EComplaintDashBoardProps {}
function isAllowPreview(caseStatus: string, formType: string, formStatus: String) {
  if (LF_VOID_STATUS === caseStatus || LF_DELETED_STATUS === caseStatus) {
    return false;
  }

  if (CREATE_LF_FORM_SUMMON === formType){
    if(LF_COMMUNI_FAILED_STATUS === formStatus) return false;
    else return true;
  }

  if (
    LF_COMPLAINT === formType ||
    LF_NOTICE_OF_ARREST_WITHOUT_WARRANT === formType ||
    PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE === formType ||
    REVISED_PROBABLE_CAUSE_STATEMENT === formType ||
    CREATE_LF_FORM_ARREST_WARRANT === formType ||
    LF_IMPOUND_ORDER === formType ||
    VICTIM_INFO === formType ||
    CREATE_LF_FORM_SUMMON
  ) {
    return true;
  }
  return false;
}

function isAllowReturn(userType: string, formStatus: string, formType: string) {
  if (LF_ARREST_WARRANT === formType || CREATE_LF_FORM_SUMMON === formType) {
    if (
      (ROLE_JUDGE === userType && LF_SEND_TO_JUDGE_STATUS === formStatus) ||
      (ROLE_AGENCY_ADMIN === userType &&
        LF_STATUS_PENDING_SUPERVISOR_REVIEW === formStatus)
    ) {
      return true;
    }
  }
  return false;
}

function isAllowReturnComplaint(
  userType: string,
  formType: string,
  formStatus: string,
  isOnViewArrest: boolean
) {
  if (
    LF_STATUS_PENDING_SUPERVISOR_REVIEW === formStatus &&
    userType === ROLE_AGENCY_ADMIN &&
    isOnViewArrest &&
    !(
      LF_NOTICE_OF_ARREST_WITHOUT_WARRANT === formType ||
      LF_ARREST_WARRANT === formType ||
      LF_SUMMONS === formType ||
      LF_IMPOUND_ORDER === formType
    )
  ) {
    return true;
  }
  return false;
}

function isAllowReject(userType: string, formStatus: string, formType: string) {
  if (LF_ARREST_WARRANT === formType || CREATE_LF_FORM_SUMMON === formType) {
    if (ROLE_JUDGE === userType && LF_SEND_TO_JUDGE_STATUS === formStatus) {
      return true;
    }
  }
  return false;
}

function isAllowSign(userType: string, formStatus: string, formType: string) {
  if (LF_ARREST_WARRANT === formType  || CREATE_LF_FORM_SUMMON === formType) {
    if (ROLE_JUDGE === userType && LF_SEND_TO_JUDGE_STATUS === formStatus) {
      return true;
    }
  }
  return false;
}

function isAllowVoid(
  userType: string,
  formStatus: string,
  formType: string,
  caseNumber: string
) {
  if (LF_COMPLAINT === formType && LF_VOID_STATUS !== formStatus) {
    if (
      (ROLE_POLICE_OFFICER === userType || ROLE_AGENCY_ADMIN === userType) &&
      (!caseNumber || caseNumber.trim() === "")
    ) {
      return true;
    }
    if (
      (ROLE_ADMINISTRATOR === userType || ROLE_COUNTY_ADMIN === userType) &&
      (!caseNumber || caseNumber.trim() === "")
    ) {
      return true;
    }
  }
  return false;
}

function isAllowDelete(
  compStatus: string,
  userType: string,
  formType: string,
  formStatus: string,
  canDeleteEComplaint: any
) {
  if (LF_COMPLAINT === formType) {
    if (canDeleteEComplaint && LF_DELETED_STATUS !== formStatus) {
      return true;
    }
  } else if (
    ROLE_POLICE_OFFICER === userType &&
    LF_ARREST_WARRANT === formType
  ) {
    if (LF_FILED_STATUS === compStatus && LF_SIGNED_STATUS !== formStatus) {
      return true;
    }
  }
  return false;
}

function isAllowAudit(formType: string, auditTrailSecurity: any) {
  if (LF_COMPLAINT === formType && auditTrailSecurity) {
    return true;
  } else {
    return false;
  }
}

function isAllowViewEdit(
  compStatus: string,
  formType: string,
  formStatus: string,
  userType: string
) {
  if (LF_COMPLAINT === formType && LF_COMPLAINT_PROCESSING !== compStatus) {
    return true;
  } else if (CREATE_LF_FORM_ARREST_WARRANT === formType || CREATE_LF_FORM_SUMMON === formType) {
    if (
      ROLE_JUDGE === userType &&
      !(
        LF_SIGNED_STATUS === formStatus ||
        LF_REJECTED_STATUS === formStatus ||
        LF_RETURNED_STATUS === formStatus ||
        LF_COMPLAINT_PROCESSING === formStatus 
      )
    ) {
      return true;
    } else if (
      (ROLE_POLICE_OFFICER === userType || ROLE_AGENCY_ADMIN === userType) &&
      LF_SEND_TO_SA_STATUS !== formStatus &&
      (LF_PENDING_STATUS === formStatus ||
        LF_REJECTED_STATUS === formStatus ||
        (LF_RETURNED_STATUS === formStatus &&
          !(
            LF_STATUS_PENDING_SUPERVISOR_RETURNED === compStatus &&
            ROLE_AGENCY_ADMIN === userType
          )) ||
        LF_SEND_TO_JUDGE_STATUS === formStatus ||
        LF_COMPLETE_STATUS === formStatus ||
        LF_VOID_STATUS === formStatus ||
        LF_STATUS_PENDING_SUPERVISOR_REVIEW === formStatus)
    ) {
      return true;
    } else if (
      ROLE_STATES_ATTORNEY === userType &&
      LF_SEND_TO_OFFICER_STATUS !== compStatus &&
      (LF_PENDING_STATUS === formStatus ||
        LF_REJECTED_STATUS === formStatus ||
        LF_RETURNED_STATUS === formStatus ||
        LF_COMPLETE_STATUS === formStatus ||
        LF_VOID_STATUS === formStatus)
    ) {
      return true;
    } else if (ROLE_COUNTY_ADMIN === userType) {
      if (LF_FILED_STATUS === compStatus && LF_SIGNED_STATUS === formStatus) {
        return true;
      }
      else if(LF_COMMUNI_FAILED_STATUS === formStatus){
        return true;
      }
    } else if (LF_DELETED_STATUS === formStatus) {
      return true;
    }
  } else if (formType === PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE) {
    if (
      (ROLE_POLICE_OFFICER === userType || ROLE_AGENCY_ADMIN === userType) &&
      formStatus === LF_PENDING_STATUS
    ) {
      return true;
    }
  } else if (REVISED_PROBABLE_CAUSE_STATEMENT === formType) {
    if (
      (ROLE_POLICE_OFFICER === userType || ROLE_STATES_ATTORNEY === userType) &&
      LF_PENDING_STATUS === formStatus
    ) {
      return true;
    }
  }
  return false;
}

const isAllowResubmit = (
  formStatus: string,
  formType: string,
  canResubmitEcomplaint: boolean,
  countyIntegratedWithCMS: boolean,
  preTrialReleaseUploadFailed: boolean,
  probableCauseUploadFailed: boolean
) => {
  if (
    formType === PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE ||
    formType === REVISED_PROBABLE_CAUSE_STATEMENT ||
    formType === VICTIM_INFO
  ) {
    if (
      formStatus === LF_COMPLETE_STATUS &&
      canResubmitEcomplaint &&
      countyIntegratedWithCMS
    ) {
      if (preTrialReleaseUploadFailed || probableCauseUploadFailed) {
        return true;
      }
    }
  }
  return false;
};

const isAllowTakeBack = (
  formStatus: string,
  comStatus: string,
  userRole: string
) => {
  if (
    (ROLE_POLICE_OFFICER === userRole || ROLE_AGENCY_ADMIN === userRole) &&
    (LF_SEND_TO_JUDGE_STATUS === comStatus ||
      LF_SEND_TO_JUDGE_STATUS === formStatus ||
      LF_STATUS_PENDING_SUPERVISOR_REVIEW === comStatus ||
      LF_STATUS_PENDING_SUPERVISOR_REVIEW === formStatus)
  ) {
    return true;
  }
  return false;
};

const isSupervisor = (userType: string) => {
  if (ROLE_AGENCY_ADMIN === userType) {
    return true;
  }
  return false;
};

function isAllowUnlock(lockedBy: string, userRole: string, userName: string) {
  if (userRole === ROLE_JUDGE && lockedBy && lockedBy === userName) {
    return true;
  }
}

const EComplaintDashBoard: React.FC<EComplaintDashBoardProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const complaintReducer = useComplaintMainReducer();
  const preTrialReducer = usePreTrialReleaseRecognizance();
  const { complaintObject } = useComplaintReducer();
  const ecomplaintReducer = useComplaintMainReducer();
  const loginReducer = useLoginReducer();
  const createdcomplaint = useComplaintReducer();
  const { successMessage, failureMessage } = useComplaintDashReducer();
  const { statechangeMsg, lfcCountyList } = useComplaintMainReducer();
  const complaintMainReducer = useComplaintMainReducer();
  const { userType, canViewAuditTrail, canDeleteEComplaint } =
    useLoginReducer();
  const videoConferencePopup = useModel();
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [confirmOriginPlace, setconfirmOriginPlace] = React.useState("");
  const [statechangeMsgVal, setStatechangeMsgVal] = React.useState("");
  const [successMessageVal, setSuccessMessageVal] = React.useState("");
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
    ecObj: {},
  });
  const [showInfoPopup, setShowInfoPopup] = React.useState({
    show: false,
    info: "",
  });
  const [showVideoConference, setShowVideoConference] = React.useState(false);
  const errorMessageAlert = useAlert();
  const [errorKeys, setErrorKeys] = useState([""]);
  const complaintMain = useComplaintMainReducer();

  const tableHeader = ["#", "FORMS", "STATUS", "LOCKED BY", "ACTIONS"];

  const [showAuditTrail, setShowAuditTrail] = React.useState(false);
  const [searchWarrantIdForAuditTrail, setSearchWarrantIdForAuditTrail] =
    React.useState(0);
  const [formTypeForAuditTrail, setFormTypeForAuditTrail] = React.useState("");
  const [caseNumberForAuditTrail, setCaseNumberForAuditTrail] =
    React.useState("");
  const [auditType, setAuditType] = React.useState("");
  const auditTrailReducer = useAuditTrailReducer();
  const dashboardReducer = useDashboardReducer();

  //KHL PR 1256 06/02/24 - START
  //mount
  useEffect(() => {
    dispatch(getLoggedInUserCountiesForLFC());
  }, []);
  //KHL PR 1256 06/02/24 - END

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );
  //KHL PR 1256 25/01/24 - START
  const ecCountyList = lfcCountyList.filter((county: any) => {
    if (county.countyID !== 0) {
      return county;
    }
  });
  //KHL PR 1256 25/01/24 - END

  //KHL PR 11264 09/01/24 - START
  function isAllowGenerateReceipt(
    compStatus: string,
    formType: string,
    formStatus: string
  ) {
    if (LF_COMPLAINT === formType) {
      if (LF_FILED_STATUS === compStatus && complaintMainReducer.receiptEnable) {
        return true;
      }else{
        return false;
      }
    }else if(PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE === formType){
      if (formStatus === LF_COMPLETE_STATUS && complaintMainReducer.receiptEnable) {
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  //KHL PR 11264 09/01/24 - END

  useEffect(() => {
    if (failureMessage) {
      handleError([], [failureMessage]);
      dispatch(
        updateElementValuecomplaintDashReducer({
          elementName: "failureMessage",
          value: "",
        })
      );
    }
  }, [failureMessage, dispatch, handleError]);

  useEffect(() => {
    if (statechangeMsg && statechangeMsg.length > 0) {
      setStatechangeMsgVal(statechangeMsg);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      dispatch(
        updateElementValueComplaintMainReducer({
          elementName: "statechangeMsg",
          value: "",
        })
      );
    }
  }, [statechangeMsg]);

  useEffect(() => {
    if (successMessage) {
      setSuccessMessageVal(successMessage);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      dispatch(
        updateElementValuecomplaintDashReducer({
          elementName: "successMessage",
          value: "",
        })
      );
    }
  }, [successMessage]);

  useEffect(() => {
    dispatch(
      getEcomplaintMainDetails({
        ecomplaintId:
          createdcomplaint.tempECId === 0
            ? complaintReducer.complaint.ecomplaintId
            : createdcomplaint.tempECId,
        callingFrom: FETCH_LF_MAIN_DETAILS_FROM_DASHBOARD,
      })
    );
  }, []);

  useEffect(() => {
    updateAwStateForArrestWarrant();
  }, [complaintReducer.ecFormList, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(
        updateElementValueComplaintReducer({
          elementName: "navigateFrom",
          value: "",
        })
      );
    };
  }, []);

  useEffect(() => {
    if (preTrialReducer.createStatus == "success") {
      if (preTrialReducer.pretiralData.formId <= 0) {
        setShowInfoPopup({
          show: true,
          info: "Circuit Clerk case initiation is in progress, this can take between 1-5 minutes to complete. Please try again in a few minutes.",
        });
        dispatch(
          updateElementValuePreTrialReleaseRecognizanceReducer({
            elementName: "createStatus",
            value: "idle",
          })
        );
      }
    }
  }, [preTrialReducer.createStatus]);

  const backToEcomplaintList = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
    const url = dashboardReducer.PreviousURLLFC;
    const newUrl = url.replace(/^.*\/\/.*?\//, "");
    navigate("/" + newUrl);
  };

  const onBreadcrumbClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  };

  const updateAwStateForArrestWarrant = () => {
    if (complaintReducer.ecFormList instanceof Array) {
      const arrestWarrant = complaintReducer.ecFormList.find(
        (item: any) => item.formTypeName === LF_ARREST_WARRANT
      );
      if (arrestWarrant) {
        if (arrestWarrant.formData) {
          dispatch(updateAwState("edit"));
        } else {
          dispatch(updateAwState("createFromDashBoard"));
        }
      }
    }
  };

  const updateSummonStateForSummonPage = () => {
    if (complaintReducer.ecFormList instanceof Array) {
      const summon = complaintReducer.ecFormList.find(
        (item: any) => item.formTypeName === CREATE_LF_FORM_SUMMON
      );
      if (summon) {
        if (summon.formData) {
          dispatch(updateSummonState("edit"));
        } else {
          dispatch(updateSummonState("createFromDashBoard"));
        }
      }
    }
  };

  const refreshPage = () => {
    dispatch(
      getEcomplaintMainDetails({
        ecomplaintId: createdcomplaint.tempECId,
        callingFrom: FETCH_LF_MAIN_DETAILS_FROM_DASHBOARD,
      })
    );
  };

  // function handleECEdit(message: any) {
  //   let confirmText = (
  //     <>
  //       <label>{message}</label>
  //     </>
  //   );
  //   setconfirmText(confirmText);
  //   setconfirmOriginPlace("handleFileEdit");
  // }

  // function handleECTakeBack(message: any) {
  //   let confirmText = (
  //     <>
  //       <label>{message}</label>
  //     </>
  //   );
  //   setconfirmText(confirmText);
  //   setconfirmOriginPlace("handleFileTakeBack");
  // }

  function handleTakeBack(message: string) {
    let confirmText = (
      <>
        <label>{message}</label>
      </>
    );
    setconfirmText(confirmText);
    setconfirmOriginPlace("handleTakeBack");
  }

  function handleECVoid(message: any) {
    let confirmText = (
      <>
        <label>{message}</label>
      </>
    );
    setconfirmText(confirmText);
    setconfirmOriginPlace("handleFileVoid");
  }

  function handleECReturn(message: any) {
    let confirmText = (
      <>
        <label>{message}</label>
      </>
    );
    setconfirmText(confirmText);
    setconfirmOriginPlace("handleECReturn");
  }

  function handleECReject(message: any) {
    let confirmText = (
      <>
        <label>{message}</label>
      </>
    );
    setconfirmText(confirmText);
    setconfirmOriginPlace("handleECReject");
  }

  function handleECDelete(message: any) {
    let confirmText = (
      <>
        <label>{message}</label>
      </>
    );
    setconfirmText(confirmText);
    setconfirmOriginPlace("handleECDelete");
  }

  const onHide = () => {
    dispatch(
      updateElementValueVideoConferenceReducer({
        elementName: "videoConferenceStatus",
        value: "idle",
      })
    );
    setShowVideoConference(false);
  };

  const showVideoConferenceModal = () => {
    // if (
    //   caseDetailReducer.countyAdmonishment != null &&
    //   caseDetailReducer.countyAdmonishment != ""
    // ) {
    //   setShowAdmonishment(true);
    // } else {
    //   onConfirmAdmonishment();
    // }
    let cNumber = "";
    if (complaintReducer.complaint.caseNumber)
      cNumber = complaintReducer.complaint.caseNumber;
    else if (complaintReducer.complaint.complaintNumber)
      cNumber = complaintReducer.complaint.complaintNumber;

    let data = {
      agencyCode: complaintReducer.complaint.officerAgencyCode,
      caseNumber: cNumber,
      searchWarrantId: 0,
      ecomplaintId: complaintReducer.complaint.ecomplaintId,
    };
    dispatch(getVideoConferenceURL(data));
    // this.props.getEcomplaintDocData(this.props.ecomplaintReducer.ecomplaintId, false, PREVIEW_LF_COMPLAINT_FROM_VIDEO_CONFERENCE);
    setShowVideoConference(true);
  };

  const showAuditTrailModal = () => {
    let ecomplaintId = complaintMain.complaint.ecomplaintId;
    let formType = "";
    let caseNumber = complaintMain.complaint.caseNumber;

    let auditObj = {
      ecomplaintId: ecomplaintId,
      auditType: AUDIT_TYPE_ACCESS,
    };
    dispatch(fetchAuditTrailDataForLongForm(auditObj));
    setShowAuditTrail(true);
    setSearchWarrantIdForAuditTrail(ecomplaintId);
    setFormTypeForAuditTrail(formType);
    setCaseNumberForAuditTrail(caseNumber);
    setAuditType(AUDIT_TYPE_ACCESS);
  };

  const afterConfirmOnVoidClick = (obj: any) => {
    let data = {
      formId: obj.formId,
      formType: obj.formType,
      ecomplaintId: obj.ecomplaintId,
      status: LF_VOID_STATUS,
      navigate: navigate,
    };
    dispatch(editComplaint(data));
  };

  const afterConfirmOnTakeBackClick = (obj: any) => {
    console.log(obj);
    dispatch(
      takeComplaintBack({
        complaintId: complaintReducer.complaint.ecomplaintId,
        objUse: obj,
        navigate: navigate,
      })
    );
  };

  const afterConfirmOnReturnClick = (obj: any) => {
    if (
      obj.status === LF_STATUS_PENDING_SUPERVISOR_REVIEW &&
      complaintReducer.complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST
    ) {
      let complaintObj = { ...complaintReducer.complaint };
      complaintObj.caseStatus = LF_STATUS_PENDING_SUPERVISOR_RETURNED;

      dispatch(
        submitForReviewWithSupervisor({
          ecomplaint: complaintObj,
          navigate: navigate,
        })
      );
    } else {
      if (obj.formType === "Arrest Warrant"){
        dispatch(
          returnToOfficerArrestWarrant({
            navigate: navigate,
            navigateFrom: "Dashboard",
            formId: obj.formId,
          })
        );
      }else if (obj.formType === "Summon"){
        dispatch(
          returnToOfficerSummons({
            navigate: navigate,
            navigateFrom: "Dashboard",
            formId: obj.formId,
          })
        )
      }
    }
  };

  const afterConfirmOnRejectClick = (obj: any) => {
    let data = {
      formId: obj.formId,
      formType: obj.formType,
      ecomplaintId: obj.ecomplaintId,
      status: LF_REJECTED_STATUS,
      navigate: navigate,
    };
    dispatch(editComplaint(data));
  };

  const afterConfirmOnDeleteClick = (obj: any) => {
    let data = {
      formId: obj.formId,
      formType: obj.formType,
      ecomplaintId: obj.ecomplaintId,
      status: LF_DELETED_STATUS,
      navigate: navigate,
    };
    if (LF_COMPLAINT === data.formType) {
      dispatch(editComplaint(data));
    } else if (LF_ARREST_WARRANT === obj.formType) {
      dispatch(
        deleteArrestWarrant({
          formId: obj.formId,
          formType: obj.formType,
          ecomplaintId: obj.ecomplaintId,
          status: LF_DELETED_STATUS,
        })
      );
    }
  };

  // const afterConfirmOnTakeBack = (obj: any) => {
  //   if (obj !== null) {
  //     if (
  //       obj.status !== LF_FILED_STATUS &&
  //       complaintReducer.complaint.onViewArrest
  //     ) {
  //       let data = {
  //         formId: obj.formId,
  //         formType: obj.formType,
  //         ecomplaintId: obj.ecomplaintId,
  //         status: LF_PENDING_STATUS,
  //         navigate: navigate,
  //       };
  //       dispatch(editComplaint(data));
  //     }
  //       let ecForm;
  //       for (const element of complaintReducer.ecFormList) {
  //         if (element.formTypeName === CREATE_LF_FORM_ARREST_WARRANT) {
  //           ecForm = element;
  //         }
  //       }
  //       if (ecForm.formId > 0) {
  //         let container: any = {};
  //         container.className = "Ecomplaint";
  //         let propsValues: any = [];
  //         container.propsValues = propsValues;
  //         let dataObj = {
  //           ecomplaintId: complaintReducer.complaint.ecomplaintId,
  //           ecStatus: LF_COMPLETE_STATUS,
  //           dto: container,
  //           identifier: "takeBack",
  //           navigate: navigate,
  //         };

  //         let undoObj = {
  //           ecomplaintId: complaintReducer.complaint.ecomplaintId,
  //           navigate: navigate,
  //         };
  //         if (LF_FILED_STATUS !== complaintReducer.complaint.caseStatus) {
  //           propsValues.push(["caseStatus", LF_PENDING_STATUS]);
  //           dispatch(undoCertification(undoObj));
  //         }
  //         dispatch(tackBack(dataObj));
  //       }
  //   }
  // };

  const handleConfirmation = () => {
    setconfirmPopupData({ show: false, ecObj: {} });
    if (confirmOriginPlace === "handleTakeBack") {
      afterConfirmOnTakeBackClick(confirmPopupData.ecObj);
    } else if (confirmOriginPlace === "handleFileVoid") {
      afterConfirmOnVoidClick(confirmPopupData.ecObj);
    } else if (confirmOriginPlace === "handleECReturn") {
      afterConfirmOnReturnClick(confirmPopupData.ecObj);
    } else if (confirmOriginPlace === "handleECReject") {
      afterConfirmOnRejectClick(confirmPopupData.ecObj);
    } else if (confirmOriginPlace === "handleECDelete") {
      afterConfirmOnDeleteClick(confirmPopupData.ecObj);
    }
    // else if (confirmOriginPlace === "handleTakeBack") {
    //   afterConfirmOnTakeBack(confirmPopupData.ecObj);
    // }
  };

  const cancelAuditTrailModal = () => {
    setShowAuditTrail(false);
  };

  const onEditClick =
    (
      formType: string,
      formStatus: string,
      formId: number,
      ecomplaintId: number
    ) =>
    () => {
      let objUse: any = {};
      objUse.formId = formId;
      objUse.status = formStatus;
      objUse.formType = formType;
      objUse.ecomplaintId = ecomplaintId;
      let ec_msg_confirm_takeBack =
        "You are about to take this complaint back from the Judge. This will require you to re-submit it after any edits are made.";
      let ec_msg_confirm_takeBack_from_supervisor =
        "You are about to take this complaint back from the Supervisor. This will require you to re-submit it after any edits are made";
      let message;
      if (formType === "Complaint") {
        if (
          isAllowTakeBack(
            formStatus,
            complaintReducer.complaint.caseStatus,
            userType
          ) &&
          !(
            isSupervisor(userType) &&
            formStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW
          )
        ) {
          if (formStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW) {
            message = ec_msg_confirm_takeBack_from_supervisor;
          } else {
            message = ec_msg_confirm_takeBack;
          }

          setconfirmPopupData({
            show: true,
            ecObj: objUse,
          });
          handleTakeBack(message);
        } else {
          navigate("/eComplaintMainPage");
        }
      } else if (formType === "Arrest Warrant") {
        if (
          isAllowTakeBack(
            formStatus,
            complaintReducer.complaint.caseStatus,
            userType
          ) &&
          !(
            isSupervisor(userType) &&
            formStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW
          )
        ) {
          if (formStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW) {
            message = ec_msg_confirm_takeBack_from_supervisor;
          } else {
            message = ec_msg_confirm_takeBack;
          }
          setconfirmPopupData({
            show: true,
            ecObj: objUse,
          });
          handleTakeBack(message);
        } else {
          if (
            ROLE_JUDGE === userType &&
            complaintReducer.complaint.caseStatus === LF_FILED_STATUS
          ) {
            const data = {
              formId: formId,
              ecomplaintId: complaintReducer.complaint.ecomplaintId,
              action: "lockArrestWarrant",
            };
            dispatch(updateLockById(data));
          }
          //KHL PR 1256 06/02/24 - START
          if(ecCountyList.filter((county) => county.impoundEnable === true && 
          complaintReducer.complaint.countyName === county.countyName).length > 0){
            dispatch(updateElementObjectValueArrestWarrantReducer({
              elementName: "isImpoundWarrant",
              value: "Y",
            }));
          }
          //KHL PR 1256 06/02/24 - END      
          getEcomplaintMainDetails({
            ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
            callingFrom: FETCH_LF_MAIN_DETAILS_FROM_ARRESTWARRANT,
          });
          updateAwStateForArrestWarrant();
          dispatch(setECFormData({ status: formStatus, formId }));
          navigate("/arrestWarrant");
        }
      } else if (formType === PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE) {
        navigate(
          "/PreTrialReleaseOnOwnRecognizPage?ecFormId=" +
            formId +
            "&ecomplaintId=" +
            ecomplaintId
        );
      } else if (formType === REVISED_PROBABLE_CAUSE_STATEMENT) {
        let Obj = {
          formId: formId,
          navigate: navigate,
        };
        dispatch(fetchProbableCauseStatementData(Obj));
      } else if (formType === "Summon") {
        if (
          isAllowTakeBack(
            formStatus,
            complaintReducer.complaint.caseStatus,
            userType
          ) &&
          !(
            isSupervisor(userType) &&
            formStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW
          )
        ) {
          if (formStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW) {
            message = ec_msg_confirm_takeBack_from_supervisor;
          } else {
            message = ec_msg_confirm_takeBack;
          }
          setconfirmPopupData({
            show: true,
            ecObj: objUse,
          });
          handleTakeBack(message);
        } else {
          if (
            ROLE_JUDGE === userType &&
            complaintReducer.complaint.caseStatus === LF_FILED_STATUS
          ) {
            const data = {
              formId: formId,
              ecomplaintId: complaintReducer.complaint.ecomplaintId,
              action: "lockSummons",
            };
            dispatch(updateLockById(data));
          }
          getEcomplaintMainDetails({
            ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
            callingFrom: FETCH_LF_MAIN_DETAILS_FROM_SUMMON,
          })
          updateSummonStateForSummonPage();
          dispatch(setECFormData({ status: formStatus, formId }));
          navigate("/issueSummons");
        }
        
      }
    };

  const onVoidClick =
    (
      formId: number,
      ecomplaintId: number,
      formType: string,
      formStatus: string
    ) =>
    () => {
      let objUse: any = {};
      objUse.formId = formId;
      objUse.ecomplaintId = ecomplaintId;
      objUse.status = formStatus;
      objUse.formType = formType;

      let message = "Are you sure you want to VOID this complaint ?";
      setconfirmPopupData({
        show: true,
        ecObj: objUse,
      });
      handleECVoid(message);
    };

  const onReturnClick =
    (
      formId: number,
      ecomplaintId: number,
      formType: string,
      formStatus: string
    ) =>
    () => {
      let objUse: any = {};
      objUse.formId = formId;
      objUse.ecomplaintId = ecomplaintId;
      objUse.status = formStatus;
      objUse.formType = formType;

        let message = ""
        if (formType === LF_ARREST_WARRANT){
          message =
          "Do you want to return the arrest warrant to the officer without signing the warrant ?";
        }else if (formType === CREATE_LF_FORM_SUMMON){
          message =
          "Do you want to return the summons to the officer without signing the summons ?";
        }

      if (formStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW) {
        if (formType === LF_ARREST_WARRANT){
        message = "Do you want to return the arrest warrant to the officer?";
        }else if (formType === CREATE_LF_FORM_SUMMON){
          message = "Do you want to return the summons to the officer?";
        }
      }

      setconfirmPopupData({
        show: true,
        ecObj: objUse,
      });
      handleECReturn(message);
    };

  const onRejectClick =
    (
      formId: number,
      ecomplaintId: number,
      formType: string,
      formStatus: string
    ) =>
    () => {
      let objUse: any = {};
      objUse.formId = formId;
      objUse.ecomplaintId = ecomplaintId;
      objUse.status = formStatus;
      objUse.formType = formType;
      let message = ""
        if (formType === LF_ARREST_WARRANT){
          message =
          "Do you want to reject the arrest warrant without signing the warrant ?";
        }else if (formType === CREATE_LF_FORM_SUMMON){
          message =
          "Do you want to reject the summons without signing the summons ?";
        }
        setconfirmPopupData({
          show: true,
          ecObj: objUse,
        });
        handleECReject(message);
    };

  const onDeleteClick =
    (
      formId: number,
      ecomplaintId: number,
      formType: string,
      formStatus: string,
      lockUserName: string
    ) =>
    () => {
      let alertMessage;
      let objUse: any = {};
      objUse.formId = formId;
      objUse.ecomplaintId = ecomplaintId;
      objUse.status = formStatus;
      objUse.formType = formType;

      if (LF_COMPLAINT === formType) {
        let message = "Are you sure you want to DELETE this complaint ?";
        setconfirmPopupData({
          show: true,
          ecObj: objUse,
        });
        handleECDelete(message);
      } else if (LF_ARREST_WARRANT === formType) {
        if (lockUserName) {
          alertMessage = "Arrest Warrant is Locked for reviewing.";
          setShowInfoPopup({ show: true, info: alertMessage });
        } else {
          let message = "Are you sure you want to DELETE this Arrest Warrant ?";
          setconfirmPopupData({
            show: true,
            ecObj: objUse,
          });
          handleECDelete(message);
        }
      }
    };

  const onResubmitClick =
    (ecomplaintId: number, fromScheduler: boolean, formType: string) => () => {
      let dataObj = {
        ecomplaintId: ecomplaintId,
        fromScheduler: fromScheduler,
        formType: formType,
      };
      dispatch(resubmitAccordingToTheFormType(dataObj));
    };
  
  //KHL PR 11264 09/01/24 - START
  const onReceiptClick = (formType: string, formId: number) => () => {
      dispatch(
        previewComplaintReceipt({
          ecomplaintId: complaintReducer.complaint.ecomplaintId,
          formType: formType,
          formId: formId
        })
      );
  }
  //KHL PR 11264 09/01/24 - END

  const getAuditTrails =
    (ecomplaintId: number, formType: string, caseNumber: string) => () => {
      let auditObj = {
        ecomplaintId: ecomplaintId,
        auditType: AUDIT_TYPE_MODIFICATION,
      };
      dispatch(fetchAuditTrailDataForLongForm(auditObj));
      setShowAuditTrail(true);
      setSearchWarrantIdForAuditTrail(ecomplaintId);
      setFormTypeForAuditTrail(formType);
      setCaseNumberForAuditTrail(caseNumber);
      setAuditType(AUDIT_TYPE_MODIFICATION);
    };

  const handlePreview =
    (formType: string, formId: number, formStatus: string) => () => {
      let alertMessage;
      let data = {
        ecomplaintId: complaintReducer.complaint.ecomplaintId,
        isRedacted: false,
        callingFrom: PREVIEW_LF_COMPLAINT_FROM_DASHBOARD,
      };
            if (formType === LF_FORM_TYPE_COMPLAINT) {
        dispatch(previewEcomplaint(data));
      } else if (formType === PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE) {
        dispatch(
          previewPreTrialRelease({
            ecomplaintId: complaintReducer.complaint.ecomplaintId,
            ecFormId: formId,
            formData: {},
          })
        );
      } else if (formType === REVISED_PROBABLE_CAUSE_STATEMENT) {
        dispatch(
          probableCauseStatementPreview({
            ecomplaintId: complaintReducer.complaint.ecomplaintId,
            ecFormId: formId,
            formData: {},
          })
        );
      } else if (
        formStatus === LF_PENDING_STATUS &&
        formType === CREATE_LF_FORM_ARREST_WARRANT
      ) {
        alertMessage = "Arrest Warrant document is not available yet.";
        setShowInfoPopup({ show: true, info: alertMessage });
      } else if (formType === VICTIM_INFO) {
        dispatch(
          victimInfoPreview({
            ecomplaintId: complaintReducer.complaint.ecomplaintId,
            ecFormId: formId,
            formData: {},
          })
        );
      }else if (formType === CREATE_LF_FORM_SUMMON){
        dispatch(
          summonsPreview({
            ecomplaintId: complaintReducer.complaint.ecomplaintId,
            ecFormId: formId,
            formData: {},
          })
        );
      } else {
        dispatch(
          previewArrestWarrent({
            ecomplaintId: complaintReducer.complaint.ecomplaintId,
            ecFormId: formId,
            formData: {},
          })
        );
      }
    };

  const handleFormLinkClick = (formName: string) => () => {
    if (formName === CREATE_LF_FORM_ARREST_WARRANT) {
      //KHL PR 1256 25/01/24 - START 
      if(ecCountyList.filter((county) => county.impoundEnable === true && 
      complaintReducer.complaint.countyName === county.countyName).length > 0){
        dispatch(updateElementObjectValueArrestWarrantReducer({
          elementName: "isImpoundWarrant",
          value: "Y",
        }));
      } else {
        dispatch(updateElementObjectValueArrestWarrantReducer({
          elementName: "isImpoundWarrant",
          value: "N",
        }));
      }
      //KHL PR 1256 25/01/24 - END
      dispatch(updateAwState("createFromDashBoard"));
      navigate("/arrestWarrant");
      let dataObj = {
        ecomplaintId: complaintReducer.complaint.ecomplaintId,
        modifyComplaint: false,
        navigate: navigate,
      };
      dispatch(forceCreateArrestWarrant(dataObj));
    }else if (formName === CREATE_LF_FORM_SUMMON){
      dispatch(updateSummonState("createFromDashBoard"));
      navigate("/issueSummons");
      let dataObj = {
        ecomplaintId: complaintReducer.complaint.ecomplaintId,
        modifyComplaint: false,
        navigate: navigate,
      };
      dispatch(forceCreateSummon(dataObj));
    } else if (formName === PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE) {
      const ecomplaintId = complaintReducer.complaint.ecomplaintId;
      dispatch(
        getInitialPreTrialData({
          ecomplaintId: ecomplaintId,
          navigate,
        })
      );
    } else if ((formName = REVISED_PROBABLE_CAUSE_STATEMENT)) {
      const ecomplaintId = complaintReducer.complaint.ecomplaintId;
      let Obj = {
        ecomplaintId: ecomplaintId,
        navigate: navigate,
      };
      dispatch(forceCreateProbableCauseStatement(Obj));
    }
  };

  const backToList = () => {
    navigate(-1);
  };

  let countList = complaintReducer.countList ? complaintReducer.countList : [];

  let defName =
    complaintReducer.complaint.defFirstName +
    " " +
    complaintReducer.complaint.defMiddleName +
    " " +
    complaintReducer.complaint.defLastName +
    " " +
    complaintReducer.complaint.defSuffix;

  let officerName =
    complaintReducer.complaint.officerFirstName +
    " " +
    complaintReducer.complaint.officerLastName;

  let eComplaintFormUIs = [];
  let kountRecodes = [];

  let eComplaintFormTypeName = [];

  let allowPreview = false;
  let allowReject = false;
  let allowReturn = false;
  let allowSign = false;
  let allowVoid = false;
  let allowDelete = false;
  let allowAudit = false;
  let allowEdit = false;
  let allowReturnComplaint = false;
  let allowTakeBack = false;
  let allowUnlock = false;
  let allowFileWithCircuitClerk = true;
  let ecomplaintForm;
  let lockBy = "-";
  let complaintMainObject = {};
  let allowResubmit = false;
  let allowGenerateReceipt = false; //KHL PR 11264 09/01/24

  let tableData = complaintReducer.ecFormList.map(
    ({ formId, formTypeName, status, lockBy }: any, i: any) => [
      i,
      (ecomplaintForm = complaintReducer.ecFormList[i]),
      formId,
      formTypeName,
      status,
      (lockBy =
        ecomplaintForm.lockUserName !== null
          ? ecomplaintForm.lockUserName
          : " - "),
      (allowPreview = isAllowPreview(
        complaintReducer.complaint.caseStatus,
        ecomplaintForm.formTypeName,
        ecomplaintForm.status
      )),
      (allowReturn = isAllowReturn(
        userType,
        ecomplaintForm.status,
        ecomplaintForm.formTypeName
      )),
      (allowReject = isAllowReject(
        userType,
        ecomplaintForm.status,
        ecomplaintForm.formTypeName
      )),
      (allowSign = isAllowSign(
        userType,
        ecomplaintForm.status,
        ecomplaintForm.formTypeName
      )),
      (allowVoid = isAllowVoid(
        userType,
        ecomplaintForm.status,
        ecomplaintForm.formTypeName,
        complaintReducer.complaint.caseNumber
      )),
      (allowDelete = isAllowDelete(
        complaintReducer.complaint.caseStatus,
        userType,
        ecomplaintForm.formTypeName,
        ecomplaintForm.status,
        canDeleteEComplaint
      )),
      (allowAudit = isAllowAudit(
        ecomplaintForm.formTypeName,
        canViewAuditTrail
      )),
      (allowEdit = isAllowViewEdit(
        complaintReducer.complaint.caseStatus,
        ecomplaintForm.formTypeName,
        ecomplaintForm.status,
        userType
      )),
      (allowReturnComplaint = isAllowReturnComplaint(
        userType,
        ecomplaintForm.formTypeName,
        ecomplaintForm.status,
        complaintReducer.complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST
      )),
      (allowResubmit = isAllowResubmit(
        ecomplaintForm.status,
        ecomplaintForm.formTypeName,
        loginReducer.canReSubmitEComplaint,
        complaintReducer.countyIntegratedWithCMS,
        complaintReducer.preTrialReleaseUploadFailed,
        complaintReducer.probableCauseUploadFailed
      )),
      (allowGenerateReceipt = isAllowGenerateReceipt(
        complaintReducer.complaint.caseStatus,
        ecomplaintForm.formTypeName,
        ecomplaintForm.status
      )), //KHL PR 11264 09/01/24
      // (allowTakeBack = isAllowTakeBack(
      //   complaintReducer.complaint.caseStatus,
      //   ecomplaintForm.status,
      //   userType
      // )),
      (complaintMainObject = complaintReducer.complaint),
      handlePreview(
        ecomplaintForm.formTypeName,
        ecomplaintForm.formId,
        ecomplaintForm.status
      ),
      onEditClick(
        ecomplaintForm.formTypeName,
        ecomplaintForm.status,
        ecomplaintForm.formId,
        ecomplaintForm.ecomplaintId
      ),
      onVoidClick(
        ecomplaintForm.formId,
        ecomplaintForm.ecomplaintId,
        ecomplaintForm.formTypeName,
        ecomplaintForm.status
      ),
      onReturnClick(
        ecomplaintForm.formId,
        ecomplaintForm.ecomplaintId,
        ecomplaintForm.formTypeName,
        ecomplaintForm.status
      ),
      onRejectClick(
        ecomplaintForm.formId,
        ecomplaintForm.ecomplaintId,
        ecomplaintForm.formTypeName,
        ecomplaintForm.status
      ),
      onDeleteClick(
        ecomplaintForm.formId,
        ecomplaintForm.ecomplaintId,
        ecomplaintForm.formTypeName,
        ecomplaintForm.status,
        ecomplaintForm.lockUserName
      ),
      onResubmitClick(
        ecomplaintForm.ecomplaintId,
        false,
        ecomplaintForm.formTypeName
      ),
      onReceiptClick(
        ecomplaintForm.formTypeName,
        ecomplaintForm.formId
        ), //KHL PR 11264 09/01/24
      getAuditTrails(
        ecomplaintForm.ecomplaintId,
        ecomplaintForm.formTypeName,
        complaintReducer.complaint.complaintNumber
      ),
    ]
  );

  if (complaintReducer.getComplaintMainStatus === "loading") {
    kountRecodes.push(
      <Row>
        <Col>Loading......</Col>
      </Row>
    );
  } else if (complaintReducer.getComplaintMainStatus === "error") {
    kountRecodes.push(
      <Row>
        <Col>Error Occured</Col>
      </Row>
    );
  } else if (complaintReducer.getComplaintMainStatus === "success") {
    if (complaintReducer.countList.length === 0) {
      kountRecodes.push(
        <Row>
          <Col>No case records found.</Col>
        </Row>
      );
    } else {
      let caseNumb = "";
      for (let i = 0; i < complaintReducer.countList.length; i++) {
        if (!complaintReducer.multiCaseNumberCounty) {
          if (i === 0) {
            caseNumb = complaintReducer.complaint.caseNumber;
          } else {
            caseNumb = "";
          }
        } else {
          caseNumb = countList[i].caseNumber;
        }
        kountRecodes.push(
          <Row>
            <Col>{caseNumb}</Col>
            <Col md={1}>{countList[i].caseType}</Col>
            <Col md={2}>{countList[i].classOfViolation}</Col>
            <Col md={6}>{countList[i].violationDescription}</Col>
          </Row>
        );
      }
    }
  }

  const formsDropdown = useMemo(() => {
    let list: { formName: any; disabled: boolean; }[] = [];
    const ecForms = complaintReducer.ecFormList || [];
    const createFormList = complaintReducer.createFormList || [];
    list = ecForms
      .filter((item: any) => item.formTypeName !== LF_FORM_TYPE_COMPLAINT)
      .map((item: any) => {
        return {
          formName: item.formTypeName,
          disabled: true,
        };
      });
    const complaint = ecForms.find(
      (item: any) => item.formTypeName === LF_FORM_TYPE_COMPLAINT
    );
    const isComplaintFiled =
      complaint && complaint.status
        ? complaint.status === LF_FILED_STATUS
        : false;
    if (
      (userType === ROLE_POLICE_OFFICER || userType === ROLE_AGENCY_ADMIN) &&
      isComplaintFiled
    ) {
let isArrestWarrant: boolean = false;
      createFormList.forEach((item: any) => {
        const isExist = ecForms
          ? ecForms.some((i: any) => i.formTypeName === item.formName)
          : false;
if (item.formName === CREATE_LF_FORM_ARREST_WARRANT) {
          let summonForm = ecForms.find((ecForm: any) => ecForm.formTypeName === CREATE_LF_FORM_SUMMON);
          let summonStatus = "";
          if (summonForm) summonStatus = summonForm.status;
          if (isExist) isArrestWarrant = true;
          else if (!isExist) {
            if(!summonForm){
              list.push({
                formName: item.formName,
                disabled: false
              });
            }else if(summonForm && summonStatus === LF_SIGNED_STATUS){
              list.push({
                formName: item.formName,
                disabled: false
              });
            }
          }
        } else if (item.formName === CREATE_LF_FORM_SUMMON) {
          if (!isArrestWarrant && !isExist) {
            list.push({
              formName: item.formName,
              disabled: false
            });
          }
        } else if (item.formName !== CREATE_LF_FORM_ARREST_WARRANT && item.formName !== CREATE_LF_FORM_SUMMON) {
        if (!isExist) {
          if (item.formName === REVISED_PROBABLE_CAUSE_STATEMENT) {
            if (
              userType === ROLE_POLICE_OFFICER ||
              userType === ROLE_AGENCY_ADMIN
            ) {
              if (
                complaintMainReducer.complaint.postSafeTAct &&
                !complaintMainReducer.complaint.defendantReleased
              ) {
                list.push({
                  formName: item.formName,
                  disabled: false,
                });
              }
            }
          } else {
            list.push({
              formName: item.formName,
              disabled: false,
            });
}
          }
        }
      });
    }

    return list;
  }, [complaintReducer.createFormList, complaintReducer.ecFormList, userType]);

  // if (complaintReducer.createFormList.length > 0) {
  //   for (const element of complaintReducer.createFormList) {
  //     ecomplaintForm = element;
  //     const disabled =
  //       complaintReducer.ecFormList instanceof Array
  //         ? complaintReducer.ecFormList.some(
  //             (item) => item.formTypeName === element.formTypeName
  //           )
  //         : false;
  //     if (disabled) {
  //       eComplaintFormTypeName.push(
  //         <div>
  //           <span>{element.formTypeName} </span>{" "}
  //         </div>
  //       );
  //     } else {
  //       eComplaintFormTypeName.push(
  //         <div
  //           role="button"
  //           onClick={handleFormLinkClick(CREATE_LF_FORM_ARREST_WARRANT)}
  //         >
  //           <span className="text-info">
  //             <RiCheckFill /> {element.formTypeName}{" "}
  //           </span>{" "}
  //         </div>
  //       );
  //     }
  //     if (ecomplaintForm.formTypeName !== LF_FORM_TYPE_COMPLAINT) {

  //     } else if (userType === ROLE_ADMINISTRATOR) {
  //       eComplaintFormTypeName.push(
  //         <span>
  //           <span className="text-danger">
  //             <RiLink /> Access Logs{" "}
  //           </span>
  //           |{" "}
  //         </span>
  //       );
  //     }
  //   }
  // }

  if (
    (userType === ROLE_POLICE_OFFICER || userType === ROLE_AGENCY_ADMIN) &&
    complaintReducer.complaint.caseStatus === LF_FILED_STATUS
  ) {
    let isExistFormName = false;
    for (const element of complaintReducer.ecFormList) {
      if (element.formTypeName === CREATE_LF_FORM_ARREST_WARRANT) {
        isExistFormName = true;
      }
    }
    if (!isExistFormName) {
      eComplaintFormTypeName.push(
        <div
          role="button"
          onClick={handleFormLinkClick(CREATE_LF_FORM_ARREST_WARRANT)}
        >
          <span className="text-info">
            <RiCheckFill /> {CREATE_LF_FORM_ARREST_WARRANT} |{" "}
          </span>
        </div>
      );
    }
  }

  let officerPhone = complaintReducer.officerPhone;
  let newOfficerPhone;
  if (officerPhone.includes("X")) {
    let phoneNum = officerPhone.split("X");
    newOfficerPhone = phoneNum[0];
  } else {
    newOfficerPhone = officerPhone;
  }

  let listName = userType === ROLE_JUDGE ? "Warrant List" : "Complaint List";

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    {
      itemLink:
        "/" + dashboardReducer.PreviousURLLFC.replace(/^.*\/\/.*?\//, ""),
      itemName: listName,
      itemFunction: onBreadcrumbClick,
    },
  ];

  return (
    <>
      <Header headerName="Long Form Complaints" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Existing Forms"
        />

        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />

        <AlertMessage
          message={successMessageVal || ""}
          onClose={() => {
            setSuccessMessageVal("");
          }}
        />
        <AlertMessage
          message={statechangeMsgVal || ""}
          onClose={() => {
            setStatechangeMsgVal("");
          }}
        />
        <Row>
          <Col md={8} className="mt-3">
            <Card className="shadow">
              <Card.Body>
                <h4>Case Information</h4>
                <hr className="hrStyle" />
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={6} className="fw-bold">
                        Complaint Number
                      </Col>
                      <Col md={6}>
                        {complaintReducer.complaint.complaintNumber}
                      </Col>
                    </Row>
                    <hr className="hrStyle" />
                    <Row>
                      <Col md={6} className="fw-bold">
                        Report/Incident No
                      </Col>
                      <Col md={6} className="text-secondary">
                        {complaintReducer.complaint.primaryAgencyRepNumber}
                      </Col>
                    </Row>
                    <hr className="hrStyle" />
                    <Row>
                      <Col md={6} className="fw-bold">
                        Case Status
                      </Col>
                      <Col md={6} className="text-secondary">
                        {complaintReducer.complaint.caseStatus}
                      </Col>
                    </Row>
                    <hr className="hrStyle" />
                    <Row>
                      <Col className="fw-bold">State's Attorney Approval</Col>
                      <Col md={6} className="text-secondary">
                        {complaintReducer.approvedSAInitial}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6} className="fw-bold">
                        Defendant
                      </Col>
                      <Col md={6}>{defName}</Col>
                    </Row>
                    <hr className="hrStyle" />
                    <Row>
                      <Col md={6} className="fw-bold">
                        County
                      </Col>
                      <Col md={6} className="text-secondary">
                        {complaintReducer.complaint.countyName}
                      </Col>
                    </Row>
                    <hr className="hrStyle" />
                    <Row>
                      <Col md={6} className="fw-bold">
                        Arresting Agency
                      </Col>
                      <Col md={6} className="text-secondary">
                        {complaintReducer.complaint.officerAgencyName}
                      </Col>
                    </Row>
                    <hr className="hrStyle" />
                    <Row>
                      <Col md={6} className="fw-bold">
                        Impounded
                      </Col>
                      <Col md={6} className="text-secondary">
                        {complaintReducer.complaint.impoundWarrant
                          ? "Yes"
                          : "No"}
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <hr className="hrStyle" />
                    <Row>
                      <Col md={3}>
                        <span className="fw-bold">Case Number</span>
                      </Col>
                      <Col md={1}>
                        <span className="fw-bold">Type</span>
                      </Col>
                      <Col md={2}>
                        <span className="fw-bold">Class</span>
                      </Col>
                      <Col md={5}>
                        <span className="fw-bold">Charge</span>
                      </Col>
                    </Row>
                    <hr className="hrStyle" />
                    <div
                      style={{
                        maxHeight: "200px",
                        overflow: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      {kountRecodes}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mt-3">
            <Card className="shadow">
              <Card.Body>
                <h4>Officer Information</h4>
                <hr className="hrStyle" />
                <Row>
                  <Col md={4} className="fw-bold">
                    Name
                  </Col>
                  <Col md={8}>{officerName}</Col>
                </Row>
                <hr className="hrStyle" />
                <Row>
                  <Col md={4} className="fw-bold">
                    Email
                  </Col>
                  <Col md={8} className="text-secondary">
                    {complaintReducer.complaint.ecOfficerUserProfileEmail
                      ? complaintReducer.complaint.ecOfficerUserProfileEmail
                      : "-"}
                  </Col>
                </Row>
                <hr className="hrStyle" />
                <Row>
                  <Col md={4} className="fw-bold">
                    Phone
                  </Col>
                  <Col md={8} className="text-secondary">
                    {complaintReducer.complaint.ecOfficerUserProfilePhone
                      ? complaintReducer.complaint.ecOfficerUserProfilePhone
                      : "-"}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <br />
            {/* <Card className="shadow mt-1">
              <Card.Body>
                <h4>Create Forms</h4>
                {eComplaintFormTypeName.length > 0 ? (
                  <Row>
                    <hr className="hrStyle" />
                    <Col md={12} className="fw-bold">
                      {eComplaintFormTypeName}
                    </Col>
                  </Row>
                ) : (
                  <div>
                    <hr className="hrStyle" />
                    <Row>
                      <Col>N/A</Col>
                    </Row>
                  </div>
                )}
              </Card.Body>
            </Card> */}
          </Col>
        </Row>

        <Card className="shadow mt-3">
          <Card.Body>
            <Row>
              <Col xs="auto">
                <ButtonComponent onClick={backToEcomplaintList}>
                  <RiArrowLeftLine />
                  {userType === ROLE_JUDGE
                    ? "Back to Warrant List"
                    : "Back to Complaint List"}
                </ButtonComponent>
              </Col>
              <Col className="mt-3" xs={3}>
                <h4>Existing Forms</h4>
              </Col>
              <Col className="text-end">
                <ButtonComponent
                  variant="info"
                  onClick={showVideoConferenceModal}
                >
                  <RiVideoChatFill /> Video Conference
                </ButtonComponent>{" "}
                {userType === ROLE_ADMINISTRATOR && (
                  <ButtonComponent
                    variant="info"
                    className="me-1"
                    onClick={showAuditTrailModal}
                  >
                    <RiFileCopy2Fill /> Access Logs
                  </ButtonComponent>
                )}
                {formsDropdown.length > 0 && (
                  <Dropdown className="d-inline-block">
                    <Dropdown.Toggle
                      id="dropdown-button-dark-example1"
                      variant="secondary"
                    >
                      Create Forms
                    </Dropdown.Toggle>

                    <Dropdown.Menu variant="dark">
                      {formsDropdown.map((form: any, i: number) => {
                        return (
                          <Dropdown.Item
                            key={i}
                            eventKey={i}
                            disabled={form.disabled}
                            className={form.disabled ? "opacity-50" : ""}
                            onClick={handleFormLinkClick(form.formName)}
                          >
                            {form.formName}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                &nbsp;
                {ROLE_JUDGE === userType || ROLE_COUNTY_ADMIN === userType ? (
                  <ButtonComponent variant="info" onClick={refreshPage}>
                    <FiRefreshCcw /> Refresh
                  </ButtonComponent>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            {/* <Row>
              {ROLE_JUDGE === userType ? (
                <Col xs="auto">
                  <Link to={"/WarrantList"}>
                    <ButtonComponent>
                      <RiArrowLeftLine /> Back to Complaint List
                    </ButtonComponent>
                  </Link>
                </Col>
              ) : (
                <Col xs="auto">
                  <Link to={"/SearchEComplaint"}>
                    <ButtonComponent>
                      <RiArrowLeftLine /> Back to Complaint List
                    </ButtonComponent>
                  </Link>
                </Col>
              )}
              <Col xs="auto">
                <ButtonComponent onClick={backToList}>
                  <RiArrowLeftLine /> Back to Complaint List
                </ButtonComponent>
              </Col>
              <Col className="mt-3" xs={3}>
                <h4>Existing Forms</h4>
              </Col>
              <Col className="text-end">
                <ButtonComponent
                  variant="info"
                  onClick={showVideoConferenceModal}
                >
                  <RiVideoChatFill /> Video Conference
                </ButtonComponent>
              </Col>
            </Row> */}
            {/* {ROLE_JUDGE === userType ? (
              <Row>
                <Col className="text-end">
                  <ButtonComponent variant="info" onClick={refreshPage}>
                    <FiRefreshCcw />
                  </ButtonComponent>
                </Col>
              </Row>
            ) : (
              ""
            )} */}
            <TableComponent
              tableHeader={tableHeader}
              tableData={tableData}
              renderTableRow={renderTableRow}
            />
          </Card.Body>
        </Card>

        <ConfirmPopup
          children={confirmText}
          show={confirmPopupData.show}
          onHide={() =>
            setconfirmPopupData({
              show: false,
              ecObj: {},
            })
          }
          isConfirmation={handleConfirmation}
        />

        <InfoPopup
          children={showInfoPopup.info}
          onHide={() => setShowInfoPopup({ show: false, info: "" })}
          show={showInfoPopup.show}
        />

        <VideoConferenceSplitScreenPopUp
          show={showVideoConference}
          onHide={onHide}
          isTestWebCam={false}
        />
        {
          showAuditTrail &&
          <AuditTrialPopup
            showAuditTrail={showAuditTrail}
            onHide={cancelAuditTrailModal}
            searchwarrantid={searchWarrantIdForAuditTrail}
            formType={formTypeForAuditTrail}
            caseNumber={caseNumberForAuditTrail}
            auditType={auditType}
          />
        }
      </div>
    </>
  );
};

const renderTableRow = (row: any[]) => {
  const [
    i,
    ecomplaintForm,
    formId,
    formTypeName,
    status,
    lockBy,
    allowPreview,
    allowReturn,
    allowReject,
    allowSign,
    allowVoid,
    allowDelete,
    allowAudit,
    allowEdit,
    allowReturnComplaint,
    allowResubmit,
    allowGenerateReceipt, //KHL PR 11264 09/01/24
    // allowTakeBack,
    complaintMainObject,
    handlePreview,
    onEditClick,
    onVoidClick,
    onReturnClick,
    onRejectClick,
    onDeleteClick,
    onResubmitClick,
    onReceiptClick,   //KHL PR 11264 09/01/24
    getAuditTrails,
    isEditable = allowEdit,
    //  || allowTakeBack,
    dispatch,
  ] = row;
  return (
    <tr key={formId}>
      <td>{i + 1}</td>
      <td>
        {isEditable ? (
          <a role="button" className="link-info" onClick={onEditClick}>
            {formTypeName}
          </a>
        ) : (
          formTypeName
        )}
      </td>
      <td>{status}</td>
      <td>{lockBy}</td>
      <td className="justify-content-center d-flex align-items-stretch">
        {allowPreview ? (
          <TableActionComponent
            tootltip="Preview"
            children={<RiFileTextLine />}
            actionFunction={handlePreview}
            color="text-info"
            text="Preview"
          />
        ) : (
          ""
        )}

        {/* {allowSubmit ? (
          <TableActionComponent
            tootltip={
              submitImage === "submit-to-judge"
                ? "Submit to Judge"
                : "Submit for Screening"
            }
            children={
              submitImage === "submit-to-judge" ? (
                <RiArrowRightFill />
              ) : (
                <RiSendPlane2Fill />
              )
            }
            actionFunction={onSubmitClick}
            color="text-danger"
          />
        ) : (
          ""
        )} */}

        {allowEdit ? (
          <TableActionComponent
            tootltip={status === LF_FILED_STATUS ? "Open" : "Edit"}
            children={
              status === LF_FILED_STATUS ? <RiShareBoxFill /> : <RiDraftFill />
            }
            actionFunction={onEditClick}
            text={status === LF_FILED_STATUS ? "Open" : "Edit"}
          />
        ) : (
          ""
        )}

        {allowSign ? (
          <TableActionComponent
            tootltip="Sign"
            children={<FaFileSignature />}
            actionFunction={onEditClick}
            color="text-success"
            text="Sign"
          />
        ) : (
          ""
        )}

        {allowVoid ? (
          <TableActionComponent
            tootltip="Void"
            children={<RiCloseCircleFill />}
            actionFunction={onVoidClick}
            color="text-danger"
            text="Void"
          />
        ) : (
          ""
        )}

        {allowReturn ? (
          <TableActionComponent
            tootltip="Return to Officer"
            children={<RiArrowGoBackFill />}
            actionFunction={onReturnClick}
            color="text-info"
            text="Return to Officer"
          />
        ) : (
          ""
        )}

        {allowReturnComplaint ? (
          <TableActionComponent
            tootltip="Return to Officer"
            children={<RiArrowGoBackFill />}
            actionFunction={onReturnClick}
            color="text-info"
            text="Return to Officer"
          />
        ) : (
          ""
        )}

        {allowReject ? (
          <TableActionComponent
            tootltip="Reject"
            children={<BsSlashCircle />}
            actionFunction={onRejectClick}
            color="text-danger"
            text="Reject"
          />
        ) : (
          ""
        )}

        {allowDelete ? (
          <TableActionComponent
            tootltip="Delete"
            children={<RiWindow2Fill />}
            actionFunction={onDeleteClick}
            color="text-info"
            text="Delete"
          />
        ) : (
          ""
        )}

        {allowAudit ? (
          <TableActionComponent
            tootltip="Audit Trail"
            children={<RiFile2Fill />}
            actionFunction={getAuditTrails}
            color="text-success"
            text="Audit Trail"
          />
        ) : (
          ""
        )}

        {/* {allowTakeBack ? (
          <TableActionComponent
            tootltip="Take Back"
            children={<RiArrowGoBackLine />}
            actionFunction={onEditClick}
            color="text-success"
          />
        ) : (
          ""
        )} */}

        {complaintMainObject.certificationIndicator &&
        (complaintMainObject.userType === ROLE_POLICE_OFFICER ||
          complaintMainObject.userType === ROLE_AGENCY_ADMIN) &&
        (complaintMainObject.caseStatus === LF_PENDING_STATUS ||
          complaintMainObject.caseStatus === LF_SEND_TO_OFFICER_STATUS ||
          (complaintMainObject.arrestWarrantStatus === LF_ARRW_SIGNED_STATUS &&
            complaintMainObject.caseStatus !== LF_FILED_STATUS &&
            complaintMainObject.caseStatus !== LF_SIGNED_STATUS &&
            ecomplaintForm.formTypeName === "Complaint" &&
            complaintMainObject.caseStatus !== LF_SEND_TO_JUDGE_STATUS)) ? (
          <TableActionComponent
            tootltip="FileWithCircuitClerk"
            children={<RiMapPinLine />}
            // actionFunction={showFileWithCircuitClerkConfirmModal}
            color="text-info"
            text="File With Circuit Clerk"
          />
        ) : (
          ""
        )}

        {allowResubmit ? (
          <TableActionComponent
            tootltip={"reSubmit"}
            children={<RiFile2Fill />}
            actionFunction={onResubmitClick}
            color="text-info"
            text="reSubmit"
          />
        ) : (
          ""
        )}

        {/* KHL PR 11264 09/01/24 - START */}
        {allowGenerateReceipt ? (
          <TableActionComponent
            tootltip={"Generate Receipt"}
            children={<FaReceipt />}
            actionFunction={onReceiptClick}
            color="text-info"
            text="Receipt"
          />
        ) : (
          ""
        )}
        {/* KHL PR 11264 09/01/24 - END */}

      </td>
    </tr>
  );
};

export default EComplaintDashBoard;
