export const properties = {
  hostType: "Test",
  //Local API connection
  //restURL: "http://localhost:8083/api/",
  // socketWS: "http://localhost:8083/ws",

  //Local Test API connection
  // restURL: "http://localhost:8083/icstestapi/api/",
  // socketWS: "http://localhost:8083/icstestapi/ws",

  //Test Searver API connection
  // restURL: "https://icstestapi.illinoiscourtservices.net/icstestapi/api/",
  // socketWS: "https://icstestapi.illinoiscourtservices.net/icstestapi/ws",
  // wsURL: "wss://test.mysignature.net/mysignature/signature/client",
  // wsUserName: "ccdwh",
  // wsPassword: "ccdwh",

  // qrCodeImageSrc: "https://chart.apis.google.com/chart?cht=qr&chs=300x300&chl=https://test.mysignature.net/mysignature/signature.jsp?sessionId=",
  // qrCodeImageSrc: "https://qr.illinoiscourtservices.net/QrAPI/api/qrcodegenerator/createQR?chs=300x300&chl=https://test.mysignature.net/mysignature/signature.jsp?sessionId=",

  captchaSiteKey: "6LfM838aAAAAAGi-qrbRSha99Yq4uXdpwUCi6eQu",
};

export const application_status = {
  LOADING: "Loading",
  SUCSESS: "Suceess",
  FAIL: "Fail",
};

export const COMPL_SEARCH_WARRANT = "ComplaintSearch Warrant";
export const COMPL_SEARCH_WARRANT_WITH_SLASH = "Complaint/Search Warrant";
export const PROOF_OF_SERVICE = "Proof of Service";
export const INVENTORY = "Inventory";
export const ORDER_OF_CUSTODY = "Order of Custody";
export const BLANK_ORDER = "Blank Order";
export const AMENDED_INVENTORY = "Amended Inventory";
export const APPLICATION_AND_ORDER = "Application and Order";

export const SEARCH_WARRANT_PAGE_SIZE = 10;

export const ROLE_ADMINISTRATOR = "ADMINISTRATOR";
export const ROLE_COUNTY_ADMIN = "COUNTY ADMIN";
export const ROLE_AGENCY_ADMIN = "AGENCY ADMIN";
export const ROLE_POLICE_OFFICER = "POLICE OFFICER";
export const ROLE_STATES_ATTORNEY = "STATES ATTORNEY";
export const ROLE_JUDGE = "JUDGE";
export const ROLE_CIRCUIT_CLERK = "CIRCUIT CLERK";
export const ROLE_CASE_CREATOR = "CASE CREATOR";
export const ROLE_ATTORNEY_GENERAL = "ATTORNEY GENERAL";
export const ROLE_LOCAL_PROSECUTOR = "LOCAL PROSECUTOR";
export const ROLE_SA_ADMIN = "SA ADMIN";

export const ACTIVE_STATUS = "Active";
export const DEACTIVE_STATUS = "Inactive";

export const LF_NA_STATUS = "N/A";

export const LF_FILED_STATUS = "FILED";
export const LF_PENDING_STATUS = "PENDING";
export const LF_SEND_TO_JUDGE_STATUS = "SENT TO JUDGE";
export const LF_SIGNED_STATUS = "SIGNED";
export const LF_APPROVED_STATUS = "APPROVED";
export const LF_REJECTED_STATUS = "REJECTED";
export const LF_RETURNED_STATUS = "RETURNED";
export const LF_REQUIRED_STATUS = "REQUIRED";
export const LF_COMPLETE_STATUS = "COMPLETE";
export const LF_VOID_STATUS = "VOID";
export const LF_DELETED_STATUS = "DELETED";
export const LF_SEND_TO_SA_STATUS = "SENT TO SA";
export const LF_CASE_ASSIGNMENT_READY_STATUS = "CASE ASSIGNMENT READY";
export const LF_STATUS_PENDING_SUPERVISOR_REVIEW =
  "PENDING (SUPERVISOR REVIEW)";
export const LF_STATUS_PENDING_SUPERVISOR_RETURNED =
  "PENDING (SUPERVISOR RETURNED)";
export const LF_ARRW_RETURNED_STATUS = "AW RETURNED";
export const LF_ARRW_REJECTED_STATUS = "AW REJECTED";
export const LF_SUMMON_RETURNED_STATUS = "SUMMON RETURNED";
export const LF_SUMMON_REJECTED_STATUS = "SUMMON REJECTED";
export const LF_COMMUNI_FAILED_STATUS = "COMM FAILED";
export const LF_SEND_TO_OFFICER_STATUS = "SENT TO OFFICER";
export const LF_ARRW_SIGNED_STATUS = "AW SIGNED";
export const LF_ARREST_WARRANT = "Arrest Warrant";
export const LF_SUMMONS = "Summons";
export const LF_COMPLAINT = "Complaint";
export const LF_DELETE_BUTTON_TOKEN = "canDeleteEComplaint";
export const LF_VIEW_DELETE_TOKEN = "canViewDeleteEComplaint";
export const LF_COMPLAINT_PROCESSING = "PROCESSING..";

export const SEARCH_WAR_OFFICER_PENDING_STATUS = "Officer Pending";
export const SEARCH_WAR_SA_PENDING_STATUS = "SA Pending";
export const SEARCH_WAR_FILED_STATUS = "Filed";
export const SEARCH_WAR_INCOMPLETE_STATUS = "Incomplete";
export const SEARCH_WAR_EXECUTED_STATUS = "Executed";
export const SEARCH_WAR_COMPLETE_STATUS = "Complete";
export const SEARCH_WAR_VOID_STATUS = "Void";
export const SEARCH_WAR_DENIED_STATUS = "Denied";
export const SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS = "Assigned to Judge";
export const SEARCH_WAR_ASSIGNED_TO_SA_STATUS = "Assigned to SA";
export const SEARCH_WAR_ASSIGNED_TO_LP_STATUS = "Assigned to LP";
export const SEARCH_WAR_ASSIGNED_TO_AG_STATUS = "Assigned to AG";
export const SEARCH_WAR_LOCKED_BY_SA_STATUS = "Locked by SA";
export const SEARCH_WAR_LOCKED_BY_JUDGE_STATUS = "Locked by Judge";
export const SEARCH_WAR_LOCKED_BY_AG_STATUS = "Locked by AG";
export const SEARCH_WAR_LOCKED_BY_LP_STATUS = "Locked by LP";
export const SEARCH_WAR_DELETED_STATUS = "DELETED";
export const SEARCH_WAR_ACTIVE_STATUS = "Active";
export const SEARCH_WAR_PROCESSED_STATUS = "PROCESSED";

export const SEARCH_WAR_HALF_STATUS_ASSIGNED_TO = "Assigned to ";
export const SEARCH_WAR_HALF_STATUS_LOCKED_BY = "Locked by ";

export const ACTIONTYPE_SAVE = "SAVE";

export const INVENTORY_RETURN_TYPE_VIDEO = "Video Return";
export const INVENTORY_RETURN_TYPE_VERIFICATION = "Verification";

//Error Messages
export const ERROR_REQ_SEIZED1TEXT1 = "Seized List is required. \n";
export const ERROR_REQ_SEIZEDINFO = "Seized information is required. \n";
export const ERROR_REQ_PERSONPREMISES =
  "Person or premises value for Seized information is required. \n";
export const ERROR_REQ_INVNTRYPRSNPRMSTXT =
  "InvntryPrsnPrmsTxt is required. \n";
export const ERROR_REQ_PRESENTINGDATE =
  "Date of presenting seized inventory Judge is required. \n";
export const ERROR_REQ_PRESENTINGDATE_AND_CURRENT_DATE =
  "Date of presenting seized inventory information to Judge cannot be a future date. \n";
export const ERROR_REQ_PRESENTINGDATE_AND_EXECUTION_DATE =
  "Date of presenting seized inventory information to Judge should be after warrant execution date.";
export const ERROR_REQ_SIGNATURE = "Signature required. \n";
export const ERROR_REQ_ORDERTYPE = "Order Type is required. \n";
export const ERROR_REQ_APPLICATIONTITLE = "Application Title is require. \n";
export const ERROR_REQ_APPLICATIONINFO =
  "Application Information is required. \n";
export const ERROR_REQ_ORDERTITLE = "Order Title is required. \n";
export const ERROR_REQ_ORDERINFO = "Order Information is required. \n";
export const ERROR_REQ_BADGENUMBER = "Badge number is required. \n";
export const ERROR_INVALID_BADGENUMBER =
  "Badge number should be an Integer. \n";
export const ERROR_REQ_SEARCHWARRANTTYPE =
  "Search warrant type in required. \n";
export const ERROR_REQ_APPROVALUSERTYPE = "Approval agencie is required. \n";
export const ERROR_REQ_TXTPERSONINFO =
  "Person/Premises/Item information is required. \n";
export const ERROR_REQ_OFFENCE = "Offence is required. \n";
export const ERROR_REQ_SEIZED = "Seized is required. \n";
export const ERROR_REQ_POLICESOFFICERINFO =
  "Probable cause / Officer background information is required. \n";
export const ERROR_REQ_COUNTY = "County is required. \n";
export const ERROR_REQ_COUNTYID = "County ID is required. \n";
export const ERROR_REQ_TEMPLATENAME = "Template Name is required. \n";
export const ERROR_REQ_EXECUTION_OPTIONS =
  "Warrant execution option is not selected. \n";
export const ERROR_REQ_EXECUTION_DATE =
  "Warrant execution date is required. \n";
export const ERROR_REQ_EXECUTION_TIME =
  "Warrant execution time is required. \n";
export const ERROR_REQ_IMPOUND_OPTION =
  "Warrant impound option is not selected. \n";
export const ERROR_REQ_PERSONPREMISESFILED =
  "Person Premises Field is Required. \n";
export const ERROR_REQ_EXECUTION_DATE_NOT_VALID =
  "Warrant execution date is not valid. \n";
export const ERROR_REQ_EXECUTION_AND_ISSUANCE_DATE =
  "Warrant execution date/time should be after its issuance date/time. \n";
export const ERROR_REQ_EXECUTION_AND_CURRENT_DATE =
  "Warrant execution date cannot be future date. \n";
export const ERROR_REQ_EXECUTION_TIME_NOT_VALID =
  "Time of warrant execution is not valid. \n";
// canAccessApplication token values

export const CAN_ACCESS_APPLICATION = {
  USER_MAINTEN: "USER_MAINTENANCE",
  COUNTY_MAINTEN: "COUNTY_MAINTENANCE",
  AGENCY_MAINTEN: "AGENCY_MAINTENANCE",
  TEMPLATE_MAINTEN: "TEMPLATE_MAINTENANCE",
  WARRANT_LIST: "WARRANT_LIST",
  AGENCY_LIST: "AGENCY_LIST",
  EC_VIOLATION_LIST: "EC_VIOLATION_LIST",
  ECOMPLAINT_LIST: "ECOMPLAINT_LIST",
  IMPORT_USERS: "IMPORT_USERS",
  ACTIVE_USER_SESSIONS: "ACTIVE_USER_SESSIONS",
  SYNC_IUCS_DATA: "SYNC_IUCS_DATA",
  LONGFORM_USER_MANUAL: "LONGFORM_USER_MANUAL",
  USER_MANUAL: "USER_MANUAL",
  TEST_WEBCAM: "TEST_WEBCAM",
  SEARCH_WARRANTS: "SEARCH_WARRANTS",
  EORDERS: "EORDERS",
  REMOTE_HEARING: "REMOTE_HEARING",
  Warrants_Summons_List: "Warrants/Summons_List",
  CAPTURE_SIGNATURE:"CAPTURE_SIGNATURE",
  RELEASE_ON_RECOG: "RELEASE_ON_RECOG" // PR-11312
};

export const RELEASE_ON_RECOG = "Release on Recognizance"; // PR-11312

export const CONFERENCE_TYPE_JITSI = "JITSI";

export const _CREATE = "Create";
export const _UPDATE = "Update";
export const _EDIT = "Edit";
export const _COPY = "Copy";

export const MAX_DIFFERENCE_WARRANT_EXECUTION = 96 * 60 * 60 * 1000; //96 hours in milliseconds
export const SEARCH_WAR_POS_MAX_EXECUTION_TIME_WARNING =
  "WARNING: The Warrant execution date/time you have entered is greater than 96 hours from the issuance date/time of the search warrant. If you continue, this action cannot be undone. Are you sure the executed date/time entered is correct? Please confirm.";
export const SEARCH_WAR_PROOF_OF_SERVICE_CONFIRM_COMPLETE =
  "On completing Proof of Service, status of the Complaint/Search Warrant will be updated to ";

export const ERROR_REQ_USER_TYPE = "User Type is required. \n";
export const ERROR_REQ_IMPORT_USER_FILE = "File is required. \n";
export const ERROR_IMPORT_USER_INVALID_FILE =
  "Invalid file. Only Excel & CSV files can be uploaded.";

export const AUTO_SAVE_TIME = 300000; //milliseconds

export const AUDIT_TYPE_MODIFICATION = "MODIFY";
export const AUDIT_TYPE_ACCESS = "ACCESS";

export const TOKEN_RENEW_TIME = 1 * 60 * 1000; //milliseconds

export const RENEW_TOKEN_BY_TIMER = "RENEW_TOKEN_BY_TIMER";
export const RENEW_TOKEN_BY_ACTIVATING_BUTTON =
  "RENEW_TOKEN_BY_ACTIVATING_BUTTON";

export const RELOGIN_ACTION = "Relogin";
export const LOGIN_ACTION = "Login";

export const USE_OFFICERS_FROM_IUCS = "USE OFFICERS FROM IUCS";

export const MCHENRY_COUNTY = "MCHENRY";

export const DEFAULT_STATE = "IL";

export const FIRST_NAMES = [
  ["ALBERT", "020"],
  ["ALICE", "020"],
  ["ANN", "040"],
  ["ANNA", "040"],
  ["ANNE", "040"],
  ["ANNIE", "040"],
  ["ARTHUR", "040"],
  ["BERNARD", "080"],
  ["BETTE", "080"],
  ["BETTIE", "080"],
  ["CARL", "120"],
  ["CATHERINE", "120"],
  ["CHARLES", "140"],
  ["CLARA", "140"],
  ["DONALD", "180"],
  ["DOROTHY", "180"],
  ["EDWARD", "220"],
  ["ELIZABETH", "220"],
  ["FLORENCE", "260"],
  ["FRANK", "260"],
  ["GEORGE", "300"],
  ["GRACE", "300"],
  ["HAROLD", "340"],
  ["HARRIET", "340"],
  ["HARRY", "360"],
  ["HAZEL", "360"],
  ["HELEN", "380"],
  ["HENRY", "380"],
  ["JAMES", "440"],
  ["JANE", "440"],
  ["JAYNE", "440"],
  ["JEAN", "460"],
  ["JOHN", "460"],
  ["JOAN", "480"],
  ["JOSEPH", "480"],
  ["MARGARET", "560"],
  ["MARTIN", "560"],
  ["MARVIN", "580"],
  ["MARY", "580"],
  ["MELVIN", "600"],
  ["MILDRED", "600"],
  ["PATRICIA", "680"],
  ["PAUL", "680"],
  ["RICHARD", "740"],
  ["RUBY", "740"],
  ["ROBERT", "760"],
  ["RUTH", "760"],
  ["THELMA", "820"],
  ["THOMAS", "820"],
  ["WALTER", "900"],
  ["WANDA", "900"],
  ["WILLIAM", "920"],
  ["WILMA", "920"],
];

export const MIDDLE_INITIAL_LETTERS = [
  [" ", "00"],
  ["A", "01"],
  ["B", "02"],
  ["C", "03"],
  ["D", "04"],
  ["E", "05"],
  ["F", "06"],
  ["G", "07"],
  ["H", "08"],
  ["I", "09"],
  ["J", "10"],
  ["K", "11"],
  ["L", "12"],
  ["M", "13"],
  ["N", "14"],
  ["O", "14"],
  ["P", "15"],
  ["Q", "15"],
  ["R", "16"],
  ["S", "17"],
  ["T", "18"],
  ["U", "18"],
  ["V", "18"],
  ["W", "19"],
  ["X", "19"],
  ["Y", "19"],
  ["Z", "19"],
];

export const FIRST_INITIAL_LETTERS = [
  [" ", "000"],
  ["A", "000"],
  ["B", "060"],
  ["C", "100"],
  ["D", "160"],
  ["E", "200"],
  ["F", "240"],
  ["G", "280"],
  ["H", "320"],
  ["I", "400"],
  ["J", "420"],
  ["K", "500"],
  ["L", "520"],
  ["M", "540"],
  ["N", "620"],
  ["O", "640"],
  ["P", "660"],
  ["Q", "700"],
  ["R", "720"],
  ["S", "780"],
  ["T", "800"],
  ["U", "840"],
  ["V", "860"],
  ["W", "880"],
  ["X", "940"],
  ["Y", "960"],
  ["Z", "980"],
];

export const LF_COMPLAINT_SIGN = "SIGN";
export const LF_COMPLAINT_SIGN_WITH_NOTARIZE = "SIGN WITH NOTARIZE";
export const LF_COMPLAINT_SIGN_WITH_SENDTOJUDGE = "SIGN WITH SEND TO JUDGE";
export const VC_CONFERENCE_TYPE_JITSI = "JITSI";
export const STATE_LIST = [
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "FM",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MH",
  "MI",
  "MN",
  "MO",
  "MP",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "PW",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
  "ZZ",
];

const prosecutionList = [
  { label: "STATE", value: "STATE" },
  { label: "LOCAL", value: "LOCAL" },
];
const offenseOccurredList = [
  { label: "DUPAGE", value: "DUPAGE" },
  { label: "WILL", value: "WILL" },
  { label: "MCHENRY", value: "MCHENRY" },
  { label: "DEKALB", value: "DEKALB" },
  { label: "KANE", value: "KANE" },
  { label: "KANKAKEE", value: "KANKAKEE" },
  { label: "COOK", value: "COOK" },
  { label: "LAKE", value: "LAKE" },
  { label: "TRAINING", value: "TRAINING" },
  { label: "MOULTRIE", value: "MOULTRIE" },
  { label: "CLARK", value: "CLARK" },
  { label: "LASALLE", value: "LASALLE" },
  { label: "WINNEBAGO", value: "WINNEBAGO" },
  { label: "DDD", value: "DDD" },
  { label: "KENDALL", value: "KENDALL" },
  { label: "HENDERSON", value: "HENDERSON" },
  { label: "KNOX", value: "KNOX" },
  { label: "MCDONOUGH", value: "MCDONOUGH" },
  { label: "WARREN", value: "WARREN" },
  { label: "ILLINOIS", value: "ILLINOIS" },
  { label: "ADAMS", value: "ADAMS" },
  { label: "SANGAMON", value: "SANGAMON" },
];
const stateList = [
  { label: "AK", value: "AK" },
  { label: "AL", value: "AL" },
  { label: "AR", value: "AR" },
  { label: "IL", value: "IL" },
];

export const LF_FORM_TYPE_COMPLAINT = "Complaint";
export const CREATE_LF_FORM_ARREST_WARRANT = "Arrest Warrant";
export const PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE =
  "PreTrial Release on Own Recognizance";
export const REVISED_PROBABLE_CAUSE_STATEMENT = "Probable Cause Statement";
export const VICTIM_INFO = "Victim Infomation";
export const CREATE_LF_FORM_SUMMON = "Summon";

export const DEVELOPER_OPTION = "Developer option";

export const LF_IMPOUND_ORDER = "Impound Order";
export const LF_NOTICE_OF_ARREST_WITHOUT_WARRANT =
  "Notice of Arrest without Warrant";

export const APP_SEARCH_WARRANT = "SEARCH WARRANT";
export const APP_LONG_FORM_COMPLAINT = "ARREST WARRANT";
export const APP_SUMMON = "SUMMON";

export const APP_AUDIT_LOG_CONST_ACCESS = "ACCESS";
export const APP_AUDIT_LOG_CONST_MODIFY = "MODIFY";

export const SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT = "Complaint/Search Warrant";
export const SEARCH_WAR_TEMPLATE_TYPE_ORDER = "Application and Order";
export const SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER = "Blank Order";
export const SEARCH_WAR_TEMPLATE_TYPE_INVENTORY = "Inventory";

export const FETCH_LF_MAIN_DETAILS_FROM_DASHBOARD = "dashBoard";
export const FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE = "mainPage";
export const FETCH_LF_MAIN_DETAILS_FROM_ARRESTWARRANT = "arrestWarrant";
export const FETCH_LF_MAIN_DETAILS_FROM_SUMMON = "summon";

export const PREVIEW_LF_COMPLAINT_FROM_DASHBOARD = "dashBoard";
export const PREVIEW_LF_COMPLAINT_FROM_MAIN_PAGE = "mainPage";
export const PREVIEW_LF_COMPLAINT_FROM_VIDEO_CONFERENCE = "videoConference";

export const PERSON_OF_HEADER =
  "Judicial Circuit, and request the issuance of a Search Warrant to search the person of and/or the place of and/or the item of";
export const OFFENSE_INFO_HEADER =
  "and therefrom seize the following instruments, articles and things which have been used in the commission of, or which constitute evidence of, the offense of";
export const SEIZED_INFO_HEADER = "To wit";
export const POLICE_OFFICER_INFO_HEADER =
  "Complainant states that there is probable cause to believe, based on the following facts, that the above listed to beseized are now located upon the person of and/or the place of and/or the item of set forth above.";
export const PROOF_OF_SERVICE_PERSON_AND_PREMISES = "Person and Premises";
export const PROOF_OF_SERVICE_PERSON_AND_PREMISES_LANGUAGE =
  "I executed this Warrant by serving the above named";
export const INVENTORY_PERSON_AND_PREMISES = "the Person and Premises";
export const INVENTORY_PERSON_AND_PREMISES_LANGUAGE =
  "which directed the search of the person of and/or the place of and/or the item of";
export const CONFIRM_SUBMIT_PRETRIAL_RELEASE_OWN_RECOGNIZANCE_MESSAGE =
  "You are about to file this PreTrial Release on Own Recognizance form. Are you sure you want to proceed?";


// PR 0011324 - START
export const PERSON_OF_HEADER_PREFIX =
  "Complainant, before the undersigned Judge of the ";
export const PERSON_OF_HEADER_SUFFIX =
  "PERSON/PREMISES/ITEM INFORMATION :";
export const OFFENSE_INFO_HEADER_SUFFIX =
  "LIST OFFENSES HERE :";
export const POLICE_OFFICER_INFO_SUFFIX =
  "Complainant, before the undersigned Judge of the";
export const IMPOUND_LANGUAGE =
  "Due to the ongoing nature of this investigation, it is hereby ordered that the complaint for search warrant, search warrant, proof of service for the search warrant and the search warrant inventory are to be impounded by the Circuit Court Clerk and not disclosed or released to the public in any manner until further order of court";
export const COMPLAINT_HEADER_PREFIX =
  "Now appears [[OFFICER NAME]] Badge/Employee No: [[BADGE]], Complainant, before the undersigned Judge of the";
export const WARRANT_FIELD01_PREFIX =
  "ON THIS DAY [[OFFICER NAME]], hereinafter called the Complainant, has subscribed and sworn to a Complaint for a Search Warrant before me. Upon examination of the Complaint, I find that it states the facts sufficient to show probable cause and therefore I command that the person of and/or the place of and/or the item of";
export const WARRANT_FIELD02_PREFIX =
  "be searched and the following instruments, articles and things which have been used in the commission of, or which constitute evidence of the offense of";
export const WARRANT_HEADER03_PREFIX =
  "Be seized therefrom";
export const WARRANT_ORDER_COMMAND =
  "I further command that a return of anything so seized shall be made without unnecessary delay before me or before any Court of competent jurisdiction.";
export const COMPLAINT_TITLE =
  "COMPLAINT FOR SEARCH WARRANT";
export const SEARCH_WARRANT_TITLE =
  "SEARCH WARRANT";
export const COMPLAINT_IMPOUND_LANG = 
  "Due to the ongoing nature of this investigation, your complainant requests that the complaint for search warrant, search warrant, proof of service for the search warrant and search warrant inventory be impounded by the Circuit Court Clerk and not disclosed or released to the public in any manner until further order of court.";
// PR 0011324 - END

export const USER_TYPES = {
  ADMINISTRATOR: "ADMINISTRATOR",
  "COUNTY ADMIN": "COUNTY ADMIN",
  "AGENCY ADMIN": "AGENCY ADMIN",
  "POLICE OFFICER": "POLICE OFFICER",
  "STATES ATTORNEY": "STATES ATTORNEY",
  "ATTORNEY GENERAL": "ATTORNEY GENERAL",
  "LOCAL PROSECUTOR": "LOCAL PROSECUTOR",
  JUDGE: "JUDGE",
};

export const CODE_TABLE_SUFFIX = "SUFFIX";
export const CODE_TABLE_GENDER = "TSS_GENDER";
export const CODE_TABLE_TSS_RACE = "TSS_RACE";
export const CODE_TABLE_EYECOLOR = "EYECOLOR";
export const CODE_TABLE_HAIRCOLOR = "HAIRCOLOR";

export const FIELDS_BLANK_ORDER = ["OrderTitle", "OrderInfo"];

export const FIELDS_APPLICATION_ORDER = [
  "ApplicationTitle",
  "ApplicationInfo",
  "OrderTitle",
  "OrderInfo",
];

export const FIELDS_COMPLAINT_ORDER = [
  "PersonOf",
  "PersonOfHeader",
  "Offenseinfo",
  "OffenseinfoHeader",
  "Seizedinfo",
  "SeizedinfoHeader",
  "PoliceOfficerinfo",
  "PoliceOfficerinfoHeader",
  "POSPrsnPremisesText",
  "InvntryPrsnPrmsTxt",
  "POSPrsnPremisesPrint",
  "InvntryPrsnPrmsPrint",
  "personOfHeaderPrefix",
  "personOfHeaderSuffix",
  "offenseInfoHeaderSuffix",
  "policeOfficerInfoHeaderSuffix",
  "impoundLanguage",
  "complaintHeaderPrefix",
  "warrantField1Prefix",
  "warrantField2Prefix",
  "warrantHeader3Prefix",
  "warrantOrderCommand",
  "complaintTitle",
  "swTitle",
  "impoundComplaintLanguage",
];

export const AUDIT_TRAIL_FETCH_DATA = "AUDIT_TRAIL_FETCH_DATA";
export const AUDIT_TRAIL_FETCH_FAILURE = "AUDIT_TRAIL_FETCH_FAILURE";
export const AUDIT_TRAIL_FETCH_SUCCESS = "AUDIT_TRAIL_FETCH_SUCCESS";
export const AUDIT_TRAIL_RESET_FORM = "AUDIT_TRAIL_RESET_FORM";
//Notarization
export const NOTARY_USER_CANNOT_BE_SAMEASTHISUSER =
  "Arresting user or logged in user can not notarize.";
export const NOTARY_COMMISSION_EXPIRED = "Commission expired.";
export const NOTARY_PASSWORD_NOTMATCH = "Notary Password does not match.";
export const NOTARY_USER_NOT_FOUNDED = "Associate notary user is not found.";
//
export const EC_MSG_CONCURRENTCHANGE =
  "Concurrent modifications were found, please reload your screen";
export const ec_msg_confirm_no_action_sendOfficer =
  "There is/are felony count(s) which is/are not rejected or approved. Do you want to send this to officer? ";
export const ec_msg_confirm_sendOfficer =
  "Do you really want to send this to officer?";
export const ec_msg_confirm_sendSA =
  "Do you really want to send this to states attorney?";

export const POLICE_DEPT = "[[POLICE_DEPT]]";
export const BADGENO = "[[BADGENO]]";
export const POLICE_OFFICER_NAME = "[[POLICE_OFFICER_NAME]]";
export const OFFICER_TITLE_AND_NAME = "[[OFFICER_TITLE_AND_NAME]]";
export const TODAY_DATE = "[[TODAY_DATE]]";

export const CASE_DETAIL_IMOUNDED = "(Impounded)";
export const EC_MSG_SENTTOSA =
  "The complaint has been sent to SA for approval.";
export const EC_MSG_SENTTOOFFICE =
  "The complaint has been sent to the officer.";
export const EC_MSG_CONFIRM_PREVIEWREDACTED =
  "This will generate the complaint document with witness information redacted.";
export const EC_MSG_WANTTOCONTINUE = "Do you want to continue?";
//getInchoateDesc in countPopup
export const INCHO_TYPE_ATTEMPT_DESC = "ATTEMPT";
export const INCHO_TYPE_CONSPIRACY_DESC = "CONSPIRACY";
export const INCHO_TYPE_SOLICIT_DESC = "SOLICIT";
export const INCHO_TYPE_DRUG_DESC = "DRUG CONSPIRACY";

export const INCHO_TYPE_ATTEMPT = "A";
export const INCHO_TYPE_CONSPIRACY = "C";
export const INCHO_TYPE_SOLICIT = "S";
export const INCHO_TYPE_DRUG = "D";

export const CASEPROSECUTIONTYPELIST = [
  { label: "-- Select --", value: "-- Select --" },
  { label: "STATE", value: "STATE" },
  { label: "LOCAL", value: "LOCAL" },
];

export const COUNT_LEADER = "LEADER";
export const COUNT_LEADER_MANUAL = "LEADER MANUALLY";

export const EC_MSG_ALREADY_SENT_TO_JUDGE =
  'This warrant has already been sent to the judge. If you wish to make any changes, please click the "Arrest Warrant" link on the Case Information page.';
export const EC_MSG_CONFIRM_VICTIMNAMESPRESENTINSIDECOMPLAINT =
  "Full Victim or Witness names should not be added to the text of the complaint. Please remove the names and use initials instead.";
export const EC_MSG_CONFIRM_VICTIMNAMESPRESENTINSIDESEXCOMPLAINT =
  "Full Victim names should not be added to the text of the complaint pursuant to 725 ILCS 191/1. Please remove any names and use initials instead.";
export const EC_MSG_OFFICER_INFORMATION_NOT_MATCH =
  "Your IUCS officer information doesn't match with the user profile last name and badge number. Please contact your agency admin user (listed below) to correct your IUCS record or your user profile on Illinois court services.";
export const EC_MSG_ALREADY_SENT_TO_JUDGE_SUMMON =
  'This summon has already been sent to the judge. If you wish to make any changes, please click the "Summon" link on the Case Information page.';

export const UNITED_STATES = "UNITED STATES";
export const SW_CONFIRM_CMSPENDING = `Do you want to update CMS SYNC STATUS from "Sync" to "Pending"?`;
export const SW_MSG_CMSPENDING_SUCCESSFULLY =
  "CMS SYNC STATUS is updated successfully.";
export const CMS_SYNC_STATUS_PENDING = "Pending";
export const CMS_SYNC_STATUS_QUERIED = "Queried";

export const CMS_SYNC_STATUS_SYNC = "Sync";

export const RESET_SECURITY_PASSWORD = "Reset Security Password";
export const REMOVE_SECURITY_PASSWORD = "Successfully Removed Security Password";
export const ERROR_OCCURRED_RESET_PASSWORD = "Error Occurred while Reset the Security Password";

export const RELOGIN_PREVIEW_TIME = 60;

export const TYPE_OF_ARREST_ONVIEWARREST = "ONVIEWARREST";
export const TYPE_OF_ARREST_ARRESTWARRANT = "ARRESTWARRANT";
export const TYPE_OF_ARREST_SUMMON = "SUMMON";

export const FORMID_ENCODING_SECRET = 73284