import React from "react";
// import { useCommonReducer } from "../../redux/slice/commonSlice";
import { Col, Modal, Row } from "react-bootstrap";

const LoadingPopup: React.FC<any> = (props) => {
  const onHide = () => {};
  const {loadingpopupString} = props;
  return (
    <Modal
      {...props}
      dialogClassName={loadingpopupString ? "modal-10w" : "modal-11w"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      backdrop="static"
    >
      <Modal.Body style={{ textAlign: "center",}}>
        <div>
          {!loadingpopupString ?
            <h6><b>Processing...</b></h6>
            : ""}
          <img
            src={require("../../assets/time_gif.gif")}
            width="100"
            height="100"
          />
        </div>
        {loadingpopupString ?
          <h6><b>Processing...</b></h6>
          :
          <Row>
            <Col className="mt-2">
              <p>
                Please referesh the browser using <span className="text-danger-3">&nbsp; CTRL + F5 &nbsp;</span>if you think you are stuck for too long.
              </p>
            </Col>
          </Row>
        }
      </Modal.Body>
    </Modal>
  );
};
export default LoadingPopup;
