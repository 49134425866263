import React, { useEffect, useCallback } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LF_COMPLAINT_SIGN, LF_COMPLAINT_SIGN_WITH_NOTARIZE, LF_COMPLAINT_SIGN_WITH_SENDTOJUDGE, ROLE_POLICE_OFFICER, TYPE_OF_ARREST_ARRESTWARRANT, TYPE_OF_ARREST_ONVIEWARREST, TYPE_OF_ARREST_SUMMON } from "../../constants/common";
import { useAppDispatch } from "../../hooks/hooks";
import { useCaseDetailReducer } from "../../redux/slice/caseDetailSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import moment from "moment";
import ButtonComponent from "../../components/common/ButtonComponent";
import {
  updateElementObjectValue as updateElementInventoryObjectValue,
  useInventoryReducer,
  signInventory,
} from "../../redux/slice/inventorySlice";
import {
  updateElementObjectValue as updateElementSearchWarrantObjectValue,
  useSearchWarrantReducer,
  signSearchwarrant,
} from "../../redux/slice/searchWarrantSlice";
import {
  signApplicationAndOrder,
  updateElementObjectValue as updateElementApplicationOrderObjectValue,
  useApplicationAndOrderReducer,
} from "../../redux/slice/applicationAndOrderSlice";
import {
  updateElementObjectValue as updateElementBlankOrderObjectValue,
  useBlankOrderReducer,
} from "../../redux/slice/blankOrderSlice";
import {
  signAmendInventory,
  updateElementObjectValue as updateElementAmendInventoryObjectValue,
  useAmendInventoryReducer,
} from "../../redux/slice/amendInventorySlice";
import {
  resetSignature,
  useSignPanelReducer,
} from "../../redux/slice/signPanelSlice";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import {
  useCertificationReducer,
  updateElementValueCertifyReducer,
  loadInitialData,
  signAndNotarization,
} from "../../redux/slice/certificationSlice";
import { formatName, isFelonyCount, validateDate } from "../../utils/common";
import FormComponent from "../common/FormComponent";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { validateSignaturePixel } from "../common/CommonUtil";
import ConfirmPopup from "../common/ConfirmPopup";
import NotarizationPopup from "./NotarizationPopup";

interface CertificationPopupProps {
  show: boolean;
  onHide: () => void;
  afterCertifying: (endWith: string) => void;
}

const CertificationPopup: React.FC<CertificationPopupProps> = (props) => {
  const { show, onHide, afterCertifying } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loginReducer = useLoginReducer();
  const searchWarrantReducer = useSearchWarrantReducer();
  const applicationAndOrderReducer = useApplicationAndOrderReducer();
  const blankOrderReducer = useBlankOrderReducer();
  const inventoryReducer = useInventoryReducer();
  const amendInventoryReducer = useAmendInventoryReducer();
  const caseDetailReducer = useCaseDetailReducer();

  const [complainantNameFooter, setComplainantNameFooter] = React.useState("");
  const [notarizationPopupVisible, setNotartizationPopupVisible] =
    React.useState(false);

  const {
    complaint,
    ecFormList,
    countList,
    witnessList,
    felonyScreeningRequired,
    complaintSupervised,
    countyIntegratedWithCMS,
    multiCaseNumberCounty,
    notarizationReq,
  } = useComplaintMainReducer();
  const {
    txtStateAttorney,
    officerAsComplainingWitness,
    certifierName,
    optionalID,
    certifyDate,
    officerAsComplainingWitnessRequired,
  } = useCertificationReducer();

  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();

  const [signConfirmationModalShow, setSignConfirmationModalShow] =
    React.useState(false);
  const [showInstruction, setShowInstruction] = React.useState(false);
  const signPanelReducer = useSignPanelReducer();

  const [
    submitArrestWarrantConfirmationModalShow,
    setSubmitArrestWarrantConfirmationModalShow,
  ] = React.useState(false);

  const [
    submitSummonConfirmationModalShow,
    setSubmitSummonConfirmationModalShow,
  ] = React.useState(false);

  let defaultSchema = yup.object().shape({
    txtStateAttorney: yup
      .string()
      .trim()
      .test("check-StateAttorneyPanelOn", function (value) {
        if (
          isStateAttorneyPanelOn() &&
          (!txtStateAttorney || txtStateAttorney.trim() === "")
        ) {
          return this.createError({
            message: "Name of Attorney is required.",
          });
        } else {
          return true;
        }
      }),
    officerAsComplainingWitness: yup
      .string()
      .trim()
      .test("check-officerAsComplainingWitness", function (value) {
        if (
          officerAsComplainingWitnessRequired &&
          !officerAsComplainingWitness
        ) {
          return this.createError({
            message: "Complaining witness does not exist.",
          });
        } else {
          return true;
        }
      }),
    certifierName: yup.string().trim().required("'Name' is required."),
    certifyDate: yup.string().trim().required("'Date' is required."),
    sign: yup.string().nullable().required("Signature required."),
  });

  const handelChangeCheck = (event: any) => {
    dispatch(
      updateElementValueCertifyReducer({
        elementName: event.target.name,
        value: event.target.checked,
      })
    );
    if (event.target.checked) {
      let obj = {
        witnesses: witnessList,
        complaint: complaint,
        clickOnOfficerAsComplainingWitness: true,
      };
      dispatch(loadInitialData(obj));
    } else {
      dispatch(
        updateElementValueCertifyReducer({
          elementName: "certifierName",
          value: "",
        })
      );
      dispatch(
        updateElementValueCertifyReducer({
          elementName: "optionalID",
          value: "",
        })
      );
      dispatch(
        updateElementValueCertifyReducer({
          elementName: "certifyDate",
          value: "",
        })
      );
    }
  };

  const handleUpperCaseInputChange = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementValueCertifyReducer({
        elementName: name,
        value: value.toUpperCase(),
      })
    );
  };

  const handleInputChange = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementValueCertifyReducer({ elementName: name, value: value })
    );
  };
  const handleInputBlur = (event: any) => {
    let { name, value } = event.target;
    value = validateDate(value);
    dispatch(
      updateElementValueCertifyReducer({ elementName: name, value: value })
    );
  };
  const acceptSignConfirmModal = () => {
    setSignConfirmationModalShow(false);
    continueOnSign();
  };

  useEffect(() => {
    // const {lastName, firstName } = loginReducer;
    // let userName = formatName(firstName, lastName, "");
    // let complainantNameFooter = "";
    // if (userName && userName.trim().length > 0) {
    //     complainantNameFooter = userName;
    // }
    // setComplainantNameFooter(complainantNameFooter);
  }, []);

  const cancelPopup = (endWith: string) => {
    dispatch(resetSignature());
    onHide();
    afterCertifying(endWith);
  };

  const onSign = async () => {
    let Obj = {
      sign: signPanelReducer.sign,
      txtStateAttorney,
      officerAsComplainingWitness,
      certifierName,
      optionalID,
      certifyDate,
    };
    try {
      await defaultSchema.validate(Obj, {
        abortEarly: false,
      });
      let isValidateSignPixel = validateSignaturePixel(
        true,
        signPanelReducer.signThreshold
      );
      if (!isValidateSignPixel) {
        setSignConfirmationModalShow(true);
      } else {
        continueOnSign();
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const continueOnSign = () => {
    if (complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST) {
      if (notarizationReq) {
        setNotartizationPopupVisible(true);
      } else {
        signProcess(LF_COMPLAINT_SIGN, "");
      }
    } else if (complaint.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT) {
      setSubmitArrestWarrantConfirmationModalShow(true);
    } else if (complaint.typeOfArrest === TYPE_OF_ARREST_SUMMON) {
      setSubmitSummonConfirmationModalShow(true);
    }
  };

  const acceptSubmitArrestWarrantConfirmation = () => {
    signProcess(LF_COMPLAINT_SIGN_WITH_SENDTOJUDGE, "");
  };

  const acceptSubmitSummonConfirmation = () => {
    signProcess(LF_COMPLAINT_SIGN_WITH_SENDTOJUDGE, "");
  };

  const signProcess = (signType: string, notaryUserName: string) => {
    let certifyData = {
      officeAsComplainingWitness: officerAsComplainingWitness,
      certifierName: certifierName,
      optionalID: optionalID,
      certifyDate: certifyDate,
      stateAttorney: txtStateAttorney,
      notaryUserName: notaryUserName,
      complaintSupervised: complaintSupervised,
      countyIntegratedWithCMS: countyIntegratedWithCMS,
      multiCaseNumberCounty: multiCaseNumberCounty,
      sign: signPanelReducer.sign,
    };

    let dataObj = {
      ecomplaintId: complaint.ecomplaintId,
      signType: signType,
      certifyData: certifyData,
      navigate: navigate,
      cancelPopup: cancelPopup,
    };
    dispatch(signAndNotarization(dataObj));
  };

  const isStateAttorneyPanelOn = () => {
    if (!felonyScreeningRequired && hasFelonyKounts()) {
      return true;
    }
    return false;
  };

  const hasFelonyKounts = () => {
    for (let i: number = 0; i < countList.length; i++) {
      if (
        isFelonyCount(countList[i].caseType, countList[i].classOfViolation) &&
        (!countList[i].editedViolation || "CM" !== countList[i].caseType)
      ) {
        return true;
      }
    }
    return false;
  };

  const showHideInstructions = () => {
    setShowInstruction(!showInstruction);
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const setData = (notaryName: string) => {
    signProcess(LF_COMPLAINT_SIGN_WITH_NOTARIZE, notaryName);
  };

  const data = [
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "Felony Charges Approved By States Attorney:",
        placeholder: "Enter Name of Attorney Here",
        name: "txtStateAttorney",
        value: txtStateAttorney,
        isError: errorKeys.includes("txtStateAttorney"),
        onChange: handleInputChange,
        isRequired: true,
        isLabeled: true,
      },
      colHide: !isStateAttorneyPanelOn(),
      colSize: 5,
      emptyCol: !isStateAttorneyPanelOn() ? 0 : 7,
      colClassName: "mb-3",
    },
    {
      type: "checkbox",
      props: {
        label: "Use Officer as Complaining Witness",
        placeholder: "Use Officer as Complaining Witness",
        type: "checkbox",
        name: "officerAsComplainingWitness",
        isError: errorKeys.includes("officerAsComplainingWitness"),
        value: officerAsComplainingWitness,
        onChange: handelChangeCheck,
        checked: officerAsComplainingWitness,
        isRequired: true,
        disabled: !officerAsComplainingWitnessRequired,
      },
      colSize: "auto",
      colClassName: officerAsComplainingWitnessRequired ? "" : "mb-3",
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <>
            <div>
              <span className={"text-danger"}>*</span>
            </div>
          </>
        ),
      },
      colHide: !officerAsComplainingWitnessRequired,
      colSize: "auto",
      colClassName: "mb-3 g-0",
    },
    {
      type: "htmlTag",
      props: {
        value:
          "Under penalties as provided by law pursuant to Section 1-109 of the Code of Civil procedure, the undersigned certifies that the statements set forth in this instrument are true and correct, except as to matters therein stated to be an information and belief and as to such matters the undersigned certifies as aforesaid that he verily believes the same to be true.",
      },
      colSize: 12,
      //colClassName: "d-flex align-items-center"
      colClassName: "mb-2",
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "Name",
        name: "certifierName",
        value: certifierName,
        isError: errorKeys.includes("certifierName"),
        onChange: handleUpperCaseInputChange,
        isRequired: true,
        isLabeled: true,
        autoComplete: "nope",
      },
      colSize: 4,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "Optional ID",
        placeholder: "Either badge number or SAO attorney ID",
        name: "optionalID",
        value: optionalID,
        isError: errorKeys.includes("optionalID"),
        onChange: handleUpperCaseInputChange,
        isLabeled: true,
        autoComplete: "nope",
      },
      colSize: 3,
    },
    {
      type: "textInput",
      props: {
        type: "Date",
        labelName: "Date",
        name: "certifyDate",
        value: certifyDate,
        isError: errorKeys.includes("certifyDate"),
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        isRequired: true,
        isLabeled: true,
        autoComplete: "nope",
      },
      colSize: 2,
      emptyCol: 3,
    },
    {
      type: "signPanel",
      props: {
        className: "d-block",
        name: "sign",
        isError: errorKeys.includes("sign"),
      },
      colSize: 12,
    },
  ];
  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-85w"
        onHide={() => cancelPopup("")}
        backdrop="static"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <h5>
            <b>SIGN AND NOTARIZE</b>
          </h5>
        </Modal.Header>
        <Modal.Body>
          <ErrorMessageAlert
            messages={errorMessageAlert.messages}
            clearError={errorMessageAlert.clearError}
          />
          <FormComponent
            totalColumns={12}
            colSize={12}
            componentData={data}
            formCardStyle={"none"}
            isSpace={"mt-0"}
          />
        </Modal.Body>
        <Modal.Footer>
          <Col md={12}>
            {showInstruction ? (
              <Row>
                <Col xs={12} style={{ color: "blue" }}>
                  <div>
                    <b>
                      YOU MUST KEEP THIS WINDOW OPEN IN ORDER TO CAPTURE THE
                      SIGNATURE
                    </b>
                  </div>
                  <div>
                    Step 1: Call the complainant, have them go to
                    http://mysignature.net using a smartphone (A device with a
                    touch screen).
                  </div>
                  <div>
                    Step 2: Have the complainant enter{" "}
                    {signPanelReducer.sessionId} in the session ID field and
                    click "Draw".
                  </div>
                  <div>
                    Step 3: The Complainant can now sign in the whitespace
                    provided using the touch screen.
                  </div>
                  <div>
                    Step 4: Once the Complainant "Submits" the signature using
                    the "Send Sign" button, the Complainant signature should
                    appear on your screen in the Signature box above. Verify to
                    make sure the signature is displayed, then you can click the
                    sign button to accept the signature.
                  </div>
                  <hr />
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              <Col xs={4}>
                <ButtonComponent
                  variant="primary"
                  className="pull-left mx-2"
                  onClick={() => showHideInstructions()}
                >
                  {showInstruction
                    ? "HIDE REMOTE SIGN INSTRUCTIONS"
                    : "HAVE COMPLAINANT SIGN REMOTELY"}
                </ButtonComponent>
              </Col>
              <Col xs={8} className="d-flex justify-content-end">
                <ButtonComponent
                  variant="success"
                  className="mx-2"
                  onClick={() => onSign()}
                >
                  &nbsp;&nbsp;&nbsp;{"Sign"}&nbsp;&nbsp;&nbsp;
                </ButtonComponent>
                <ButtonComponent
                  variant="danger"
                  className="mx-2"
                  onClick={() => cancelPopup("")}
                >
                  {"Cancel"}
                </ButtonComponent>
              </Col>
            </Row>
          </Col>
        </Modal.Footer>
      </Modal>

      {signConfirmationModalShow && (
        <ConfirmPopup
          show={signConfirmationModalShow}
          onHide={() => setSignConfirmationModalShow(false)}
          isConfirmation={acceptSignConfirmModal}
        >
          Are you sure the signature you provided is valid?"
        </ConfirmPopup>
      )}

      {submitArrestWarrantConfirmationModalShow && (
        <ConfirmPopup
          show={submitArrestWarrantConfirmationModalShow}
          onHide={() => setSubmitArrestWarrantConfirmationModalShow(false)}
          isConfirmation={acceptSubmitArrestWarrantConfirmation}
        >
          {complaintSupervised &&
          loginReducer.userType === ROLE_POLICE_OFFICER ? (
            <div className="text-start">
              You are about to submit this complaint and arrest warrant to the
              Supervisor.<br></br>
              <br></br>
              Do you want to continue?
            </div>
          ) : (
            <div className="text-start">
              You are about to submit this complaint and arrest warrant to the
              Judge for signature.<br></br>
              <br></br>
              Do you want to continue?
            </div>
          )}
        </ConfirmPopup>
      )}
      {submitSummonConfirmationModalShow && (
        <ConfirmPopup
          show={submitSummonConfirmationModalShow}
          onHide={() => setSubmitSummonConfirmationModalShow(false)}
          isConfirmation={acceptSubmitSummonConfirmation}
        >
          {complaintSupervised &&
          loginReducer.userType === ROLE_POLICE_OFFICER ? (
            <div className="text-start">
              You are about to submit this complaint and summon to the
              Supervisor.<br></br>
              <br></br>
              Do you want to continue?
            </div>
          ) : (
            <div className="text-start">
              You are about to submit this complaint and summon to the
              Judge for signature.<br></br>
              <br></br>
              Do you want to continue?
            </div>
          )}
        </ConfirmPopup>
      )}
      <NotarizationPopup
        show={notarizationPopupVisible}
        onHide={() => setNotartizationPopupVisible(false)}
        data={setData}
      />
    </>
  );
};

export default CertificationPopup;
