import React, { useEffect, useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { RiAddCircleLine, RiDownload2Fill, RiSearchLine } from "react-icons/ri";
import {
  useNavigate,
  createSearchParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import ConfirmPopup from "../../components/common/ConfirmPopup";
import {
  ROLE_ADMINISTRATOR,
  ROLE_COUNTY_ADMIN,
  ROLE_POLICE_OFFICER,
  SEARCH_WARRANT_PAGE_SIZE,
  ROLE_LOCAL_PROSECUTOR,
  ROLE_STATES_ATTORNEY,
  ROLE_JUDGE,
  ROLE_AGENCY_ADMIN,
  ROLE_ATTORNEY_GENERAL,
  ROLE_CIRCUIT_CLERK,
  ROLE_SA_ADMIN,
  SEARCH_WAR_ASSIGNED_TO_SA_STATUS,
  SEARCH_WAR_ACTIVE_STATUS,
  SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS,
  SEARCH_WAR_ASSIGNED_TO_AG_STATUS,
  SEARCH_WAR_ASSIGNED_TO_LP_STATUS,
  SEARCH_WAR_OFFICER_PENDING_STATUS,
  LF_SEND_TO_JUDGE_STATUS,
  LF_ARREST_WARRANT,
  CREATE_LF_FORM_SUMMON,
  APP_SUMMON,
  CAN_ACCESS_APPLICATION,
  ROLE_CASE_CREATOR,
} from "../../constants/common";
import { useAppDispatch } from "../../hooks/hooks";
import useToken from "../../hooks/useToken";
import {
  getSWCaseDetail,
  updateElementValueCaseDetailReducer,
} from "../../redux/slice/caseDetailSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import {
  loadLoggedInUserCounties,
  loadLoggedInUserAgencies,
  loadAgencyListByCounty,
  loadStatusList,
  loadIssuedTimeList,
  downloadSearchWarrantSearchList,
  downloadSearchWarrantSearchWorkReport,
  listDocuments,
  useSearchWarrantListReducer,
  updateElementValueInSearchWarrantListReducer,
  resetSearchWarrantList,
  updateSearchWarrantListReducer,
  listPendingSearchDocuments,
} from "../../redux/slice/searchWarrantListSlice";
import { updateElementValueDashboardReducer } from "../../redux/slice/dashboardSlice";
import { createSearchWarrant } from "../../redux/slice/searchWarrantSlice";
import { useUserProfilesReducer, getPoliceOfficersListbyAgency, getPoliceOfficersListByCounty } from "../../redux/slice/userProfileSlice";
import { getTextColorByStatus } from "../../utils/common";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import ButtonComponent from "../common/ButtonComponent";
import Header from "../common/Header";
import ListViewComponent from "../common/ListViewComponent";
import SelectCountyAgencyPopup from "../common/SelectCountyAgencyPopup";
import {
  APP_SEARCH_WARRANT,
  APP_LONG_FORM_COMPLAINT,
} from "../../constants/common";
import * as yup from "yup";
import { updateElementValueComplaintReducer } from "../../redux/slice/complaintSlice";
import { useDashboardReducer } from "../../redux/slice/dashboardSlice";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import {
  updateElementValueSelectCountyAgencyReducer,
  getPoliceOfficersList,
} from "../../redux/slice/selectCountyAgencySlice";
import OptionPopup from "../common/OptionsPopup";
import {
  formatComplaintNumber,
  formatCaseNumber,
  validCaseNumber,
} from "../../utils/common";
import AlertMessage from "../common/AlertMessage";
import { wait } from "@testing-library/user-event/dist/utils";
import InfoPopup from "../common/InfoPopup";
import moment from "moment";
import SecurityPassword from "../common/SecurityPasswordPopup";
import { LoadingType } from "../../types";
import LoadingPopup from "../common/LoadingPopup";
import { useCountyReducer, getCountyDataById, } from "../../redux/slice/countySlice";

interface WarrantListProps {}

export const WarrantList: React.FC<WarrantListProps> = (props) => {
  let defaultWarrantListSearchSchema = yup.object().shape({
    warrantCase: yup
      .string()
      .ensure()
      .when("check", {
        is: true,
        then: yup.string().test("validat-eComplaint", function (state) {
          let caseNumb = "";
          if (userType === ROLE_JUDGE) {
            return true;
          }
          if (warrantCase !== null && warrantCase.length > 0) {
            caseNumb = warrantCase.trim();
            if (caseNumb !== null && caseNumb.length > 0) {
              let isValid = validCaseNumber(caseNumb.trim(), false);
              if (!isValid) {
                return this.createError({
                  message: "Case Number is invalid.\n",
                });
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else if (
            caseOrComplaintNumber !== null &&
            caseOrComplaintNumber.length > 0
          ) {
            caseNumb = caseOrComplaintNumber.trim();
            if (caseNumb !== null && caseNumb.length > 0) {
              let regExp = /[A-Za-z]{2}[0-9]{12}/;
              let regExp1 = /[0-9]{4}[A-Za-z]{2}[0-9]{6}/;
              let regExp2 = /[0-9]{4}[A-Za-z]{1}[0-9]{6}/;
              if (
                !(
                  regExp.test(caseNumb) ||
                  regExp1.test(caseNumb) ||
                  regExp2.test(caseNumb)
                )
              ) {
                return this.createError({
                  message: "Case Number is invalid.\n",
                });
              }
              return true;
            }
          }
          return false;
        }),
      }),
  });

  let defaultDownloadSearchWarrantSchema = yup.object().shape({
    searchWarrantCount: yup
      .number()
      .test("validate-searchWarrant", function (state) {
        if (!documentList || documentList.length === 0) {
          return this.createError({
            message: "No SearchWarrants to download",
          });
        } else {
          return true;
        }
      }),
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { userType, hideCompletedSW, userProfileId, firstName, lastName, agencyCode  } = useLoginReducer(); //KHL PR 11294 13/02/24
  const { allSwsEnable } = useCountyReducer(); //KHL PR 11294 13/02/24

  const isTokenValueAvailable = useToken();

  const {
    countyList,
    agencyList,
    statusList,
    issueTimeList,
    documentList,
    totalPages,
    currentPage,
    totalRecords,
    localCounty,
    localAgency,
    warrantStatus,
    agencyId,
    countyId,
    sortBy,
    issueTime,
    formType,
    warrantCase,
    caseOrComplaintNumber,
    searchParameters,
    didMount,
    countyListReady,
    agencyListReady,
    statusListReady,
    issueTimeListReady,
    responseMessage,
    responseErrorMessage,
    agencyCaseNumber,
    officerId, //KHL PR 11294 13/02/24
  } = useSearchWarrantListReducer();
  const { policeOfficersList } = useUserProfilesReducer();
  const [confirmPopupData, setconfirmPopupData] = React.useState(false);
  const [agencyCountyModal, setAgencyCountyModal] = React.useState(false);
  const [showWorkReportPopup, setShowWorkReportPopup] = React.useState(false);
  const [warrantNumber, setWarrantNumber] = React.useState("");
  const loginReducer = useLoginReducer();
  const { searchWarrantIds, pendingSWCount, cardName, isCardActive, previousLink } =
    useDashboardReducer();
  const [previousPendingSWCount, setPreviousPendingSWCount] = React.useState("");
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const [agencyListForCounty, setAgecyListForCounty] = React.useState([{}]);
  const [topalertMessage, setTopalertMessage] = React.useState<string>("");
  const [toperroralertMessage, setToperroralertMessage] =
    React.useState<string>("");
  const [searchParams] = useSearchParams();
  const [showInfoPopup, setShowInfoPopup] = useState({
    show: false,
    info: "",
  });
  const [securityPasswordProps, setSecurityPasswordProps] = React.useState<{
    complaintId: number;
    caseNumber: string;
    action: string;
  }>({ complaintId: 0, caseNumber: "", action: "" });
  const [securityPasswordShow, setSecurityPasswordShow] = React.useState(false);
  const [formTag, setFormTag] = React.useState("Warrant List");
  const [formHeader, setFormHeader] = React.useState("");

  const countyListOptions: any = [];

  if (countyList.length !== 1 && userType !== ROLE_LOCAL_PROSECUTOR) {
    countyListOptions.push({ label: "-- ALL --", value: {} });
  }
  countyListOptions.push(
    ...countyList.map((county: any) => {
      return { label: county.countyName, value: county };
    })
  );

  const agencyListOptions: any = [];
  if (agencyList.length !== 1 && userType !== ROLE_LOCAL_PROSECUTOR) {
    agencyListOptions.push({ label: "-- ALL --", value: {} });
  }
  agencyListOptions.push(
    ...agencyList.map((agency: any) => {
      return { label: agency.agencyName, value: agency };
    })
  );

  //KHL PR 11294 13/02/24 START
  const policeOfficersListOptions: any = [];
  if (userType == ROLE_POLICE_OFFICER || userType == ROLE_AGENCY_ADMIN) {
    if (userType == ROLE_POLICE_OFFICER) {
      policeOfficersListOptions.push({ label: agencyCode + " - " + firstName + " " + lastName, value: userProfileId });
    } else if (userType == ROLE_AGENCY_ADMIN) {
      if (countyList.length > 1 && localCounty === undefined || localCounty === "-- ALL --") {
        for (let i = 0; i < countyList.length; i++) {
          const county: any = countyList[i];
          if (county.allSwsEnable) {
            policeOfficersListOptions.push({ label: "-- ALL --", value: 0 });
            policeOfficersListOptions.push(
              ...policeOfficersList.map((officer: any) => {
                return { label: officer.agencyCode + " - " + officer.firstName + " " + officer.lastName, value: officer.userProfileID };
              })
            );
            break;
          }
        }
        if (policeOfficersListOptions.length < 1) {
          policeOfficersListOptions.push({ label: agencyCode + " - " + firstName + " " + lastName, value: userProfileId });
        }
      } else {
        if (allSwsEnable) {
          policeOfficersListOptions.push({ label: "-- ALL --", value: 0 });
          policeOfficersListOptions.push(
            ...policeOfficersList.map((officer: any) => {
              return { label: officer.agencyCode + " - " + officer.firstName + " " + officer.lastName, value: officer.userProfileID };
            })
          );
        } else {
          policeOfficersListOptions.push({ label: agencyCode + " - " + firstName + " " + lastName, value: userProfileId });
        }
      }
    }
  } else {
    policeOfficersListOptions.push({ label: "-- ALL --", value: 0 });
    if (policeOfficersList.length > 0) {
      policeOfficersListOptions.push(
        ...policeOfficersList.map((officer: any) => {
          return { label: officer.agencyCode + " - " + officer.firstName + " " + officer.lastName, value: officer.userProfileID };
        })
      );
    }
  }
  //KHL PR 11294 13/02/24 END

  const statusListOptions: any = [];
  if (statusList.length !== 1 && userType !== ROLE_LOCAL_PROSECUTOR) {
    statusListOptions.push({ label: "-- ALL --", value: "-- ALL --" });
  }
  statusListOptions.push(
    ...statusList.map((status: any) => {
      return { label: status.displayValue, value: status.displayValue };
    })
  );

  const issueTimeListOptions: any = [];
  if (issueTimeList.length !== 1) {
    issueTimeListOptions.push({ label: "-- ALL --", value: {} });
  }
  issueTimeListOptions.push(
    ...issueTimeList.map((status: any) => {
      return { label: status.displayValue, value: status.displayValue };
    })
  );
  
  let formTypeList = [
    { label: "-- ALL --", value: "-- ALL --" },
    { label: APP_SEARCH_WARRANT, value: APP_SEARCH_WARRANT },
    { label: APP_LONG_FORM_COMPLAINT, value: APP_LONG_FORM_COMPLAINT }
  ];
    
  if (loginReducer.summonsToAppear){
    formTypeList.push({ label: APP_SUMMON, value: APP_SUMMON });
  }

  //mount
  useEffect(() => {
    let paramsValue = location.search;
    if (loginReducer.summonsToAppear){
      setFormTag("Warrants/Summons List");
      setFormHeader("Warrants/Summons List");
    }
    else if (loginReducer.userType === ROLE_JUDGE){
      setFormHeader("Warrants List");
    } else{
      setFormHeader("Search Warrants");
    }
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "didMount",
        value: true,
      })
    );
    dispatch(loadLoggedInUserCounties());
    dispatch(loadStatusList());
    dispatch(loadIssuedTimeList());
    if (paramsValue.trim().length > 0) {
      if (parseInt(searchParams.get("countyId")?.toString() || "0") === 0) {
        dispatch(loadLoggedInUserAgencies());
      } else {
        dispatch(
          loadAgencyListByCounty({
            countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
          })
        );
      }
    }
    //KHL PR 11294 13/02/24 START
    if (userType == ROLE_AGENCY_ADMIN || userType == ROLE_POLICE_OFFICER) {
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "officerId",
          value: userProfileId,
        })
      );
    }
    if (userType === ROLE_ADMINISTRATOR || userType === ROLE_ATTORNEY_GENERAL) {
        dispatch(getPoliceOfficersList());
    }
    //KHL PR 11294 13/02/24 END
  }, []);

  //unmount
  useEffect(
    () => () => {
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "previousLink",
          value: "",
        })
      );
      dispatch(resetSearchWarrantList());
    },
    []
  );

  useEffect(() => {
    let paramsValue = location.search;
    if (countyList && countyList.length > 0) {
      if (!paramsValue || paramsValue.trim().length < 0 || !didMount) {
        if (countyList.length === 1 || userType === ROLE_LOCAL_PROSECUTOR) {
          dispatch(
            updateElementValueInSearchWarrantListReducer({
              elementName: "localCounty",
              value: countyList[0].countyName,
            })
          );
          dispatch(
            updateElementValueInSearchWarrantListReducer({
              elementName: "countyId",
              value: countyList[0].countyID,
            })
          );
          dispatch(
            loadAgencyListByCounty({ countyId: countyList[0].countyID })
          );
        } else {
          dispatch(
            updateElementValueInSearchWarrantListReducer({
              elementName: "localCounty",
              value: "-- ALL --",
            })
          );
          dispatch(
            updateElementValueInSearchWarrantListReducer({
              elementName: "countyId",
              value: 0,
            })
          );
          dispatch(loadLoggedInUserAgencies());
        }
      }
      //KHL PR 11294 14/02/24
      dispatch(
        getCountyDataById({
          id: countyList[0].countyID,
          navigate,
        })
      );
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "countyListReady",
          value: true,
        })
      );
      if (userType === ROLE_COUNTY_ADMIN || userType === ROLE_JUDGE || userType === ROLE_CIRCUIT_CLERK || userType === ROLE_CASE_CREATOR) {
        if (countyList[0].countyID !== undefined && countyList[0].countyID !== 0) {
          dispatch(
            getPoliceOfficersListByCounty({
              countyIds: [countyList[0].countyID]
            })
          );
        }
        else {
          dispatch(getPoliceOfficersList());
        }
      } else if (userType === ROLE_STATES_ATTORNEY || userType === ROLE_SA_ADMIN) {
        let countyIdList: any[] = [];
        for (let i = 0; i < countyList.length; i++) {
          const county: any = countyList[i];
          countyIdList.push(county.countyID);
        }
        dispatch(
          getPoliceOfficersListByCounty({
            countyIds: countyIdList
          })
        );
      }
    }
  }, [countyList]);

  useEffect(() => {
    let paramsValue = location.search;
    if (agencyList && agencyList.length > 0) {
      if (!paramsValue || paramsValue.trim().length < 0 || !didMount) {
        if (agencyList.length === 1 || userType === ROLE_LOCAL_PROSECUTOR) {
          dispatch(
            updateElementValueInSearchWarrantListReducer({
              elementName: "localAgency",
              value: agencyList[0].agencyName,
            })
          );
          dispatch(
            updateElementValueInSearchWarrantListReducer({
              elementName: "agencyId",
              value: agencyList[0].agencyId,
            })
          );
          //KHL PR 11294 13/02/24 START
          if (userType !== ROLE_POLICE_OFFICER){
          dispatch(
            getPoliceOfficersListbyAgency({
              agencyId: agencyList[0].agencyId
            })
          );
          }
          //KHL PR 11294 13/02/24 END
        } else {
          dispatch(
            updateElementValueInSearchWarrantListReducer({
              elementName: "localAgency",
              value: "-- ALL --",
            })
          );
          dispatch(
            updateElementValueInSearchWarrantListReducer({
              elementName: "agencyId",
              value: 0,
            })
          );
        }
      }
      dispatch(
        getPoliceOfficersListbyAgency({
          agencyId: agencyList[0].agencyId
        })
      );
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "agencyListReady",
          value: true,
        })
      );
    }
  }, [agencyList]);

  useEffect(() => {
    if (statusList && statusList.length > 0) {
      if (userType === ROLE_STATES_ATTORNEY || userType === ROLE_SA_ADMIN) {
        dispatch(
          updateElementValueInSearchWarrantListReducer({
            elementName: "warrantStatus",
            value: SEARCH_WAR_ASSIGNED_TO_SA_STATUS,
          })
        );
      }
      if (userType === ROLE_JUDGE) {
        dispatch(
          updateElementValueInSearchWarrantListReducer({
            elementName: "warrantStatus",
            value: SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS,
          })
        );
      }
      if (userType === ROLE_ATTORNEY_GENERAL) {
        dispatch(
          updateElementValueInSearchWarrantListReducer({
            elementName: "warrantStatus",
            value: SEARCH_WAR_ASSIGNED_TO_AG_STATUS,
          })
        );
      }
      if (userType === ROLE_LOCAL_PROSECUTOR) {
        dispatch(
          updateElementValueInSearchWarrantListReducer({
            elementName: "warrantStatus",
            value: SEARCH_WAR_ASSIGNED_TO_LP_STATUS,
          })
        );
      }
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "statusListReady",
          value: true,
        })
      );
    }
  }, [statusList]);

  useEffect(() => {
    if (issueTimeList && issueTimeList.length > 0) {
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "issueTimeListReady",
          value: true,
        })
      );
    }
  }, [issueTimeList]);

  useEffect(() => {
    dispatch(
      updateElementValueSelectCountyAgencyReducer({
        elementName: "agenciesForCounty",
        value: agencyList,
      })
    );
    if (agencyList.length === 1) {
      dispatch(
        updateElementValueSelectCountyAgencyReducer({
          elementName: "agencyId",
          value: agencyList[0].agencyId,
        })
      );
      dispatch(
        updateElementValueSelectCountyAgencyReducer({
          elementName: "agencyName",
          value: agencyList[0].agencyName,
        })
      );
    }
    if (countyList.length === 1) {
      dispatch(
        updateElementValueSelectCountyAgencyReducer({
          elementName: "countyID",
          value: countyList[0].countyID,
        })
      );
      dispatch(
        updateElementValueSelectCountyAgencyReducer({
          elementName: "countyName",
          value: countyList[0].countyName,
        })
      );
    }
  }, [agencyCountyModal]);

  useEffect(() => {
    if (
      countyListReady &&
      agencyListReady &&
      statusListReady &&
      issueTimeListReady
    ) {
      if (
        (searchWarrantIds && searchWarrantIds.length > 0) ||
        !(userType === ROLE_ADMINISTRATOR || userType === ROLE_COUNTY_ADMIN)
      ) {  
        if (didMount && isCardActive) {
          handleWarrantSearch(0, true, false, false);
        }
      }
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "didMount",
          value: false,
        })
      );
      if (!isCardActive) {
        setUrlData();
      }
    }
  }, [countyListReady, agencyListReady, statusListReady, issueTimeListReady]);

  useEffect(() => {
    if (responseMessage) {
      setTopalertMessage(responseMessage);

      window.scroll({
        top: 0,
        behavior: "smooth",
      });

      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "responseMessage",
          value: "",
        })
      );
    }
  }, [responseMessage, dispatch]);

  useEffect(() => {
    if (responseErrorMessage) {
      setToperroralertMessage(responseErrorMessage);

      window.scroll({
        top: 0,
        behavior: "smooth",
      });

      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "responseErrorMessage",
          value: "",
        })
      );
    }
  }, [responseErrorMessage, dispatch]);

  const changeCreateSwPopupData = () => {
    setconfirmPopupData(true);
  };

  const handleConfirmation = () => {
    setconfirmPopupData(false);
    let onBehalfId = 0;
    let loggedInUserRole = userType;
    if (loggedInUserRole === ROLE_ADMINISTRATOR) {
      // onBehalfId = this.props.scaReducer.officerUserProfileId;
    }

    let agency: any = agencyList[0];
    let county: any = countyList[0];
    let dataObj = {
      agencyId: agency.agencyId,
      countyId: county.countyID,
      onBehalfId: 0,
      navigate: navigate,
    };
    dispatch(createSearchWarrant(dataObj));
  };

  const createSearchWarrantClick = () => {
    if (agencyList && countyList) {
      if (
        countyList.length === 1 &&
        agencyList.length === 1 &&
        ROLE_POLICE_OFFICER === userType
      ) {
        setconfirmPopupData(true);
      } else {
        dispatch(
          updateElementValueSelectCountyAgencyReducer({
            elementName: "policeOfficers",
            value: policeOfficersList,
          })
        );
        dispatch(getPoliceOfficersList());
        setAgencyCountyModal(true);
      }
    }
    if (window.location.href.includes("?")) {
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "PreviousURLSW",
          value: window.location.href,
        })
      );
    } else {
      let spageNo = 1;
      let spageSize = SEARCH_WARRANT_PAGE_SIZE;
      let sagencyId = agencyId ? agencyId : 0;
      let scountyId = countyId ? countyId : 0;
      let sstatus = warrantStatus ? warrantStatus : "-- ALL --";
      let scaseNumber =
        userType === ROLE_JUDGE ? caseOrComplaintNumber === undefined ? "" : caseOrComplaintNumber : warrantCase === undefined ? "" : warrantCase;
      let sformType = userType !== ROLE_JUDGE ? APP_SEARCH_WARRANT : formType ? formType === "-- ALL --" ? "" : formType : "";
      let sissueTime = issueTime ? issueTime : "-- ALL --";
      let lfcNumber = userType === ROLE_JUDGE ? caseOrComplaintNumber === undefined ? "" : caseOrComplaintNumber : "";
      let ssortBy = sortBy ? (sortBy === "-- DEFAULT --" ? "" : sortBy) : "";
      let sagencyCaseNumber = agencyCaseNumber ? agencyCaseNumber === undefined ? "" : agencyCaseNumber : "";

      let slocalCounty = localCounty;
      let slocalAgency = localAgency;
      let sOfficerId = officerId; //KHL PR 11294 13/02/24

      let queryParam = "";
      if (spageNo) queryParam += `pageNo=${spageNo}&`;
      if (spageSize) queryParam += `pageSize=${spageSize}&`;
      queryParam += `agencyId=${sagencyId}&`;
      queryParam += `countyId=${scountyId}&`;
      if (sstatus) queryParam += `status=${sstatus}&`;
      if (scaseNumber) queryParam += `caseNumber=${scaseNumber}&`;
      if (sformType) queryParam += `formType=${sformType}&`;
      if (sissueTime) queryParam += `issueTime=${sissueTime}&`;
      if (lfcNumber) queryParam += `lfcNumber=${lfcNumber}&`;
      if (ssortBy) queryParam += `sortBy=${ssortBy}&`;
      if (sagencyCaseNumber) queryParam += `agencyCaseNumber=${sagencyCaseNumber}&`;
      if (slocalCounty) queryParam += `localCounty=${slocalCounty}&`;
      if (slocalAgency) queryParam += `localAgency=${slocalAgency}`;
      if (sOfficerId) queryParam += `OfficerID=${sOfficerId}`; //KHL PR 11294 13/02/24

      let newURL = window.location.href + "?" + queryParam

      dispatch(
        updateElementValueDashboardReducer({
          elementName: "PreviousURLSW",
          value: newURL,
        })
      );
    }
  };

  const downloadSearchList = (state: any) => async () => {
    try {
      setErrorKeys([""]);
      let searchWarrantCount = documentList ? documentList.length : 0;
      let warrantListSearchObject = {
        searchWarrantCount: searchWarrantCount,
      };
      await defaultDownloadSearchWarrantSchema.validate(
        warrantListSearchObject,
        {
          abortEarly: false,
        }
      );

      if (searchParameters) {
        let searchParamsLocal = { ...searchParameters };
        searchParamsLocal.pageNo = 0;
        searchParamsLocal.pageSize = totalRecords < 2000 ? totalRecords : 2000;
        dispatch(downloadSearchWarrantSearchList(searchParamsLocal));
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const onRowClick = (item: any) => () => {
    if (item.appType === APP_SEARCH_WARRANT) {
      if (
        userType === ROLE_JUDGE &&
        warrantStatus[0] === SEARCH_WAR_OFFICER_PENDING_STATUS
      ) {
        setShowInfoPopup({
          info: "PENDING cases cannot be signed until the officer submits them.",
          show: true,
        });
      } else {
        dispatch(
          getSWCaseDetail({
            searchWarrantId: item.searchWarrantId,
            action: "getDetailsOnRowClick",
            navigate: navigate,
          })
        );
      }
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "listFrom",
          value: "Warrant List",
        })
      );
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "PreviousURLSW",
          value: window.location.href,
        })
      );
    } else if (item.appType === LF_ARREST_WARRANT || item.appType === CREATE_LF_FORM_SUMMON) {
      dispatch(
        updateElementValueComplaintReducer({
          elementName: "tempECId",
          value: item.searchWarrantId,
        })
      );
      dispatch(
        updateElementValueComplaintReducer({
          elementName: "navigateFrom",
          value: "Search EComplaint",
        })
      );
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "PreviousURLLFC",
          value: window.location.href,
        })
      );
      navigate("/EComplaintDashboard");
    }
  };

  const passwordProtector =
    (searchWarrantId: number, caseNumber: string) => () => {
      setSecurityPasswordShow(true);
      setSecurityPasswordProps({
        complaintId: searchWarrantId,
        caseNumber: caseNumber,
        action: "CHECK",
      });
    };

  const changePassword =
    (searchWarrantId: number, caseNumber: string) => () => {
      setSecurityPasswordShow(true);
      setSecurityPasswordProps({
        complaintId: searchWarrantId,
        caseNumber: caseNumber,
        action: "RESET",
      });
    };

  const PageRefresh = (password: string, isPasswordReset: boolean) => {
    if (isPasswordReset) {
      handleWarrantSearch(currentPage - 1, false, true, false);
    }
  };

  const updateCaseNumber = (event: any) => {
    let { value } = event.target;
    value = formatComplaintNumber(value);
    setWarrantNumber(value);
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "caseOrComplaintNumber",
        value: value,
      })
    );

    //setIsCaseNumber(isCaseNumber?false:true);
  };

  const formatSWCaseNumber = async (event: any) => {
    let value = formatCaseNumber(event.target.value, true);
    value = value === null ? event.target.value : value;
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: event.target.name,
        value: value,
      })
    );
  };

  const handleInputChange = (event: any) => {
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  const getWarrantList = () => {
    dispatch(updateElementValueDashboardReducer({ elementName: "pendingSWCount", value: "" }));
    handleWarrantSearch(0, true, false, true); 
  };

  useEffect(()=>{
    if( previousPendingSWCount !== "" && pendingSWCount === "") {
      handleWarrantSearch(0, true, false, false);
    }
  },[pendingSWCount]);

  const handleWarrantSearch = (
    pageNo: number,
    keepParamsSearch: boolean,
    isButton: boolean,
    search: boolean
  ) => {
    let urlPathObj;
    if (keepParamsSearch) {
      let searchParamsLocal = {
        pageNo: pageNo,
        pageSize: SEARCH_WARRANT_PAGE_SIZE,
        agencyId: agencyId ? agencyId : 0,
        countyId: countyId ? countyId : 0,
        status: warrantStatus ? warrantStatus : "-- ALL --",
        caseNumber:
          userType === ROLE_JUDGE ? caseOrComplaintNumber === undefined? "": caseOrComplaintNumber : warrantCase === undefined? "" : warrantCase,
        formType:
          userType !== ROLE_JUDGE
            ? APP_SEARCH_WARRANT
            : formType
            ? formType === "-- ALL --"
              ? ""
              : formType
            : "",
        issueTime: issueTime ? issueTime : "-- ALL --",
        lfcNumber: userType === ROLE_JUDGE ? caseOrComplaintNumber === undefined? "" : caseOrComplaintNumber : "",
        sortBy: sortBy ? (sortBy === "-- DEFAULT --" ? "" : sortBy) : "",
        agencyCaseNumber: agencyCaseNumber ? agencyCaseNumber === undefined ? "" : agencyCaseNumber : "",
        officerId: officerId ? officerId : 0, //KHL PR 11294 13/02/24
      };
      if(!search && pendingSWCount && pendingSWCount.length >0){
        setPreviousPendingSWCount(pendingSWCount);
        dispatch(listPendingSearchDocuments(searchParamsLocal));
        dispatch(updateElementValueDashboardReducer({ elementName: "searchWarrantIds", value: [] }));
      } else{
        dispatch(listDocuments(searchParamsLocal));
        setPreviousPendingSWCount("");
      }
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "searchParameters",
          value: searchParamsLocal,
        })
      );
      urlPathObj = searchParamsLocal;
    } else {
      let searchParamsLocal = { ...searchParameters };
      searchParamsLocal.pageNo = pageNo;
      if(!search && pendingSWCount && pendingSWCount.length >0){
        setPreviousPendingSWCount(pendingSWCount);
        dispatch(listPendingSearchDocuments(searchParamsLocal));
        dispatch(updateElementValueDashboardReducer({ elementName: "searchWarrantIds", value: [] }));
      } else{
        dispatch(listDocuments(searchParamsLocal));
        setPreviousPendingSWCount("");
      }
      urlPathObj = searchParamsLocal;
    }

    let urlParamsLocal;
    urlParamsLocal = { ...urlPathObj };
    if (!isButton) {
      urlParamsLocal.pageNo = 1;
    } else {
      urlParamsLocal.pageNo = pageNo + 1;
    }
    urlParamsLocal.localCounty = localCounty;
    urlParamsLocal.localAgency = localAgency;
    urlPathObj = urlParamsLocal;
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlPathObj)}`,
    });

    // dispatch(
    //   updateElementValueDashboardReducer({
    //     elementName: "searchWarrantIds",
    //     value: [],
    //   })
    // );
  };

  window.onpopstate = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
    let paramsValue = location.search;
    if (paramsValue.trim().length > 0) {
      let urlPathObj: any = {
        pageNo: parseInt(searchParams.get("pageNo")?.toString() || "0") - 1,
        pageSize: SEARCH_WARRANT_PAGE_SIZE,
        agencyId: parseInt(searchParams.get("agencyId")?.toString() || "0"),
        countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
        status: searchParams.get("status") || "",
        caseNumber: searchParams.get("caseNumber") || "",
        formType: searchParams.get("formType") || "",
        issueTime: searchParams.get("issueTime") || "-- ALL --",
        lfcNumber: searchParams.get("lfcNumber") || "",
        sortBy: searchParams.get("sortBy") || "",
        agencyCaseNumber: searchParams.get("agencyCaseNumber") || "",
        officerId: parseInt(searchParams.get("officerId")?.toString() || "0"), //KHL PR 11294 13/02/24
      };

      if(pendingSWCount && pendingSWCount.length >0){
        setPreviousPendingSWCount(pendingSWCount);
        dispatch(listPendingSearchDocuments(urlPathObj));
        dispatch(updateElementValueDashboardReducer({ elementName: "searchWarrantIds", value: [] }));
      } else{
        dispatch(listDocuments(urlPathObj));
        setPreviousPendingSWCount("");
      }
    } else {
      if (loginReducer.userType !== "ADMINISTRATOR") {
        if (previousLink === "dashboard") {
          navigate("/");
        } else {
          navigate(-1);
        }
      }
      dispatch(resetSearchWarrantList());
    }
  };

  const setUrlData = () => {
    let pageNoValue = parseInt(searchParams.get("pageNo")?.toString() || "0");
    let urlPathObj: any = {
      pageNo: pageNoValue === 0 ? 0 : pageNoValue - 1,
      pageSize: SEARCH_WARRANT_PAGE_SIZE,
      agencyId: parseInt(searchParams.get("agencyId")?.toString() || "0"),
      countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
      status: searchParams.get("status") || "",
      caseNumber: searchParams.get("caseNumber") || "",
      formType: searchParams.get("formType") || "",
      issueTime: searchParams.get("issueTime") || "-- ALL --",
      lfcNumber: searchParams.get("lfcNumber") || "",
      sortBy: searchParams.get("sortBy") || "",
      agencyCaseNumber: searchParams.get("agencyCaseNumber") || "",
      officerId: parseInt(searchParams.get("officerId")?.toString() || "0"), //KHL PR 11294 13/02/24
    };
    dispatch(listDocuments(urlPathObj));

    let urlPath: any = {
      agencyId: parseInt(searchParams.get("agencyId")?.toString() || "0"),
      countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
      localCounty: searchParams.get("localCounty")?.toString(),
      localAgency: searchParams.get("localAgency")?.toString(),
      sortBy: searchParams.get("sortBy") || "",
      warrantCase: searchParams.get("caseNumber") || "",
      warrantStatus: searchParams.get("status") || "-- ALL --",
      issueTime: searchParams.get("issueTime") || "",
      formType: searchParams.get("formType") || "",
      lfcNumber: searchParams.get("lfcNumber") || "",
      officerId: parseInt(searchParams.get("officerId")?.toString() || "0"), //KHL PR 11294 13/02/24
      searchWarrantIds: searchWarrantIds,
      searchParameters: urlPathObj,
    };
    dispatch(updateSearchWarrantListReducer(urlPath));
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const handleCountySelect = (event: any) => {
    let seleectedCountyLabel = event.label;
    loadAgencyList(seleectedCountyLabel);
  };

  const handleCounty = (e: any) => {
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "localCounty",
        value: e.value.countyName,
      })
    );
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "countyId",
        value: e.value.countyID,
      })
    );
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "localAgency",
        value: "-- ALL --",
      })
    );
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "agencyId",
        value: 0,
      })
    );
    //KHL PR 11294 13/02/24
    if (e.value.countyID === undefined){
      dispatch(getPoliceOfficersList());
    }else{
      dispatch(
        getPoliceOfficersListByCounty({
          countyIds: [e.value.countyID]
        })
      );
    }
    //KHL PR 11294 13/02/24
    if(e.value.countyID !== undefined){
      dispatch(
        getCountyDataById({
          id: e.value.countyID,
          navigate,
        })
      );
    }
    if (e.label === "-- ALL --") {
      dispatch(loadLoggedInUserAgencies());
    } else {
      dispatch(loadAgencyListByCounty({ countyId: e.value.countyID }));
    }
    //KHL PR 11294 13/02/24 START
    if(userType == ROLE_AGENCY_ADMIN || userType == ROLE_POLICE_OFFICER){
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "officerId",
          value: userProfileId,
        })
      );
    }else{
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "officerId",
          value: 0,
        })
      );
    }
    //KHL PR 11294 13/02/24 END
  };

  const handleAgency = (e: any) => {
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "localAgency",
        value: e.value.agencyName,
      })
    );
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "agencyId",
        value: e.value.agencyId,
      })
    );
    //KHL PR 11294 13/02/24 START
    if(e.value.agencyId === undefined){
      if (countyId === undefined){
        dispatch(getPoliceOfficersList());
      }else{
        dispatch(
          getPoliceOfficersListByCounty({
            countyIds: [countyId]
          })
        );
      }
    }else{
      dispatch(
        getPoliceOfficersListbyAgency({
          agencyId: e.value.agencyId
        })
      );
    }
    if(userType == ROLE_AGENCY_ADMIN || userType == ROLE_POLICE_OFFICER){
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "officerId",
          value: userProfileId,
        })
      );
    }else{
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "officerId",
          value: 0,
        })
      );
    }
  };

  const handleofficerChange = (e: any) => {
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "officerId",
        value: e.value,
      })
    );
  }
  //KHL PR 11294 13/02/24 END

  const handleStatus = (e: any) => {
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "warrantStatus",
        value: [e.label],
      })
    );
  };

  const handleSortByChange = (e: any) => {
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "sortBy",
        value: e.value,
      })
    );
  };

  const handleIssueTimeChange = (e: any) => {
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "issueTime",
        value: e.label,
      })
    );
    if (
      e.label &&
      e.label.trim() !== "" &&
      "-- ALL --" !== e.label &&
      !(userType === ROLE_JUDGE && hideCompletedSW)
    ) {
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "warrantStatus",
          value: SEARCH_WAR_ACTIVE_STATUS,
        })
      );
    }
  };

  const handleFormTypeChange = (e: any) => {
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "formType",
        value: e.label,
      })
    );
  };

  const loadAgencyList = (selectedCounty: any) => {
    let agencyArr: { countyList: string | any[] }[] = [];
    if ("-- ALL --" === selectedCounty) {
      setAgecyListForCounty(agencyList);
    } else {
      agencyList.map((agencyItem: { countyList: string | any[] }) =>
        agencyItem.countyList
          ? agencyItem.countyList.includes(selectedCounty)
            ? agencyArr.push(agencyItem)
            : ""
          : ""
      );
      setAgecyListForCounty(agencyArr);
    }
  };

  const onContinuePDFandExcel = (pdfExcelValue: string) => {
    dispatch(
      downloadSearchWarrantSearchWorkReport({
        countyId: 1,
        documentType: pdfExcelValue,
      })
    );
    setShowWorkReportPopup(false);
  };

  let tableHeader = [
    "Case # / Complaint #",
    "County",
    "Officer Details",
    loginReducer.summonsToAppear?"Warrant/Summon":"Warrant",
    "Proof of Service",
    "Inventory",
    "Warrant Signed By",
    "Form Type",
    "Last Updated Date",
  ];

  let tableData = documentList.map((item: any) => {
    let wStatus = "";
    let isProtected = false;
    if (APP_LONG_FORM_COMPLAINT === item.appType) {
      if (
        ROLE_JUDGE === userType &&
        LF_SEND_TO_JUDGE_STATUS === item.formStatus
      ) {
        wStatus = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
      } else {
        wStatus = item.formStatus;
      }
    } else {
      wStatus = item.formStatus;
    }

    if (
      item.protectedComplaint &&
      userProfileId !== item.formCreatorId &&
      userType !== ROLE_ADMINISTRATOR
    ) {
      isProtected = true;
    } else {
      isProtected = false;
    }

    return [
      item.caseNumber,
      item.countyName,
      item.officerDetail,
      wStatus,
      item.pos,
      item.inv,
      item.judgeName,
      item.appType,
      item.maxAuditTimeStamp,
      item.protectedComplaint,
      isProtected,
      onRowClick(item),
      passwordProtector(item.searchWarrantId, item.caseNumber),
      changePassword(item.searchWarrantId, item.caseNumber),
    ];
  });

  const accessToComplaint = (complaintId: number) => {
    if (
      userType === ROLE_JUDGE &&
      warrantStatus[0] === SEARCH_WAR_OFFICER_PENDING_STATUS
    ) {
      setShowInfoPopup({
        info: "PENDING cases cannot be signed until the officer submits them.",
        show: true,
      });
    } else {
      dispatch(
        getSWCaseDetail({
          searchWarrantId: complaintId,
          action: "getDetailsOnRowClick",
          navigate: navigate,
        })
      );
    }
    dispatch(
      updateElementValueCaseDetailReducer({
        elementName: "navigateFrom",
        value: "Warrant List",
      })
    );
  };

  let itemLinkList = [{ itemLink: "/", itemName: "DashBoard" }];
  const data = {
    colSize: 3,
    componentData: [
      {
        type: "selectInput",
        props: {
          options: countyListOptions,
          value: countyListOptions.find((item: any) => {
            if (item.label === localCounty) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "County",
          name: "localCounty",
          defaultValue: { label: "-- ALL --", value: {} },
          onChange: handleCounty,
          isDisabled:
            countyList.length === 1 &&
            (userType === ROLE_STATES_ATTORNEY ||
              userType === ROLE_JUDGE ||
              userType === ROLE_AGENCY_ADMIN ||
              userType === ROLE_COUNTY_ADMIN ||
              userType === ROLE_CIRCUIT_CLERK ||
              userType === ROLE_SA_ADMIN)
              ? true
              : false,
        },
      },
      {
        type: "selectInput",
        props: {
          options: agencyListOptions,
          value: agencyListOptions.find((item: any) => {
            if (item.label === localAgency) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Agency",
          name: "localAgency",
          defaultValue: { label: "-- ALL --", value: {} },
          onChange: handleAgency,
        },
      },
      //KHL PR 11294 13/02/24 START
      {
        type: "selectInput",
        props: {
          options: policeOfficersListOptions,
          value: policeOfficersListOptions.find((item: any) => {
            if (item.value === officerId) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Officer List",
          onChange: handleofficerChange,
          name: "officerList",
          defaultValue: { label: "-- ALL --", value: 0 },
        },
      },
      //KHL PR 11294 13/02/24 END
      {
        type: "selectInput",
        props: {
          options: sortByList,
          value: sortByList.find((item: any) => {
            if (item.label === sortBy) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Sort By",
          onChange: handleSortByChange,
          name: "sortBy",
          defaultValue: { label: "-- DEFAULT --", value: "-- DEFAULT --" },
        },
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Search Warrant/Case #",
          type: "text",
          placeholder: "Enter Search Warrant/Case #",
          name: "warrantCase",
          onBlur: formatSWCaseNumber,
          onChange: handleInputChange,
          value: warrantCase,
          isError: errorKeys.includes("warrantCase"),
          maxLength: 12,
        },
        colHide: userType === ROLE_JUDGE,
      },
      {
        type: "selectInput",
        props: {
          options: statusListOptions,
          value: statusListOptions.find((item: any) => {
            if (item.label === warrantStatus) {
              return { label: item.label, value: item.value };
            }
          }),
          defaultValue: { label: "-- ALL --", value: "-- ALL --" },
          placeholder: "Status",
          name: "warrantStatus",
          onChange: handleStatus,
          isDisabled:
            (userType === ROLE_JUDGE && hideCompletedSW) ||
            (issueTime && issueTime.trim() !== "" && "-- ALL --" !== issueTime),
        },
      },
      {
        type: "selectInput",
        props: {
          options: issueTimeListOptions,
          value: issueTimeListOptions.find((item: any) => {
            if (item.label === issueTime) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Issue Time",
          name: "issueTime",
          defaultValue: { label: "-- ALL --", value: "-- ALL --" },
          onChange: handleIssueTimeChange,
        },
      },
      {
        type: "selectInput",
        props: {
          options: formTypeList,
          value: formTypeList.find((item: any) => {
            if (item.label === formType) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Form Type",
          name: "formType",
          defaultValue: { label: "-- ALL --", value: "-- ALL --" },
          onChange: handleFormTypeChange,
        },
        colHide: userType !== ROLE_JUDGE,
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Case/Complaint #",
          type: "text",
          placeholder: "Enter Case/Complaint #",
          name: "caseOrComplaintNumber",
          onChange: handleInputChange,
          value: caseOrComplaintNumber,
          isError: errorKeys.includes("warrantCase"),
          maxLength: 14,
        },
        colHide: userType !== ROLE_JUDGE,
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Agency Case Number",
          placeholder: "Enter Agency Case Number #",
          type: "text",
          name: "agencyCaseNumber",
          onChange: handleInputChange,
          value: agencyCaseNumber,
          maxLength: 12,
        },
      },
      {
        type: "button",
        props: {
          className: "w-100",
          children: (
            <>
              <RiSearchLine /> Search
            </>
          ),
          type: "submit",
          onClick: async (state: any) => {
            setTopalertMessage("");
            setToperroralertMessage("");
            setErrorKeys([""]);
            handleError([""], []);
            try {
              setErrorKeys([""]);
              let val = warrantCase ? warrantCase : caseOrComplaintNumber;
              let check = false;
              if (val != null && val.length > 0) {
                check = true;
              }
              let warrantListSearchObject = {
                warrantCase: val,
                check: check,
              };
              await defaultWarrantListSearchSchema.validate(
                warrantListSearchObject,
                {
                  abortEarly: false,
                }
              );
              getWarrantList();
            } catch (err: any) {
              handleError(
                err.inner.map((e: any) => e.path),
                err.errors
              );
            }
          },
        },
        colSize: 2,
      },
    ],
  };

  return (
    <>
      <Header headerName={formHeader} />
      <ErrorMessageAlert
        messages={errorMessageAlert.messages}
        clearError={errorMessageAlert.clearError}
        // show = {(errorKeys[0] === '')?false:true}
      />
      <AlertMessage
        message={topalertMessage}
        onClose={() => setTopalertMessage("")}
      />
      <AlertMessage
        message={toperroralertMessage}
        onClose={() => setToperroralertMessage("")}
        variant="danger"
      />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive={formTag}
        />
        <ListViewComponent
          table={{
            tableHeader: tableHeader,
            tableData: tableData,
            renderTableRow: renderTableRow,
            emptyTableMessage: "No data to display",
          }}
          searchBar={{
            colSize: data.colSize,
            componentData: data.componentData,
          }}
          middleComponent={(state) => (
            <ListViewMiddleComponent
              createSearchWarrantClick={createSearchWarrantClick}
              downloadSearchList={downloadSearchList(state)}
              showReportPopup={() => setShowWorkReportPopup(true)}
              totalRecords={totalRecords}
              canCreateSearchWarrant={loginReducer.canCreateSearchWarrant}
              isDownloadReportVisible={userType === ROLE_SA_ADMIN}
              formTag={formTag}
            />
          )}
          pagination={{
            totalPages,
            numberOfPagesToShow: 5,
            currentPage: currentPage - 1,
            onPageChange: (page: number, state) => {
              handleWarrantSearch(page, false, true, false);
            },
          }}
        />
      </div>
      <ConfirmPopup
        children="New Search warrant will be generated. Please confirm."
        show={confirmPopupData}
        onHide={() => setconfirmPopupData(false)}
        isConfirmation={handleConfirmation}
      />
      <SelectCountyAgencyPopup
        show={agencyCountyModal}
        onHide={() => setAgencyCountyModal(false)}
      />
      <OptionPopup
        show={showWorkReportPopup}
        onHideFunc={() => setShowWorkReportPopup(false)}
        header="States Attorney Search Warrant Work Report"
        children={<div>Select File type</div>}
        buttons={[
          {
            name: "DOWNLOAD AS PDF",
            variant: "primary",
            onClick: () => onContinuePDFandExcel("PDF"),
          },
          {
            name: "DOWNLOAD AS EXCEL",
            variant: "primary",
            onClick: () => onContinuePDFandExcel("EXCEL"),
          },
        ]}
      />

      <InfoPopup
        children={showInfoPopup.info}
        onHide={() =>
          setShowInfoPopup({
            show: false,
            info: "",
          })
        }
        show={showInfoPopup.show}
      />
      <SecurityPassword
        show={securityPasswordShow}
        onHide={() => {
          setSecurityPasswordShow(false);
        }}
        securityProps={securityPasswordProps}
        submitPassword={PageRefresh}
        accessToComplaint={accessToComplaint}
      />
    </>
  );
};

const ListViewMiddleComponent = ({
  createSearchWarrantClick,
  downloadSearchList,
  showReportPopup,
  totalRecords,
  canCreateSearchWarrant,
  isDownloadReportVisible,
  formTag,
}: {
  createSearchWarrantClick: () => void;
  downloadSearchList: () => void;
  showReportPopup: () => void;
  totalRecords: number;
  canCreateSearchWarrant: boolean;
  isDownloadReportVisible: boolean;
  formTag: string;
}) => (
  <Row>
    <Col xs={12} className="text-danger text-end">
      Note: You can download max 2000 records from Search Result List.
    </Col>
    <Col>
      <h4>{formTag}</h4>
      <h6 className="my-n2">{(formTag === "Warrants/Summons List")?"Total Warrants/Summons":"Total Warrants"} : {totalRecords}</h6>
    </Col>
    <Col className="text-end">
      {canCreateSearchWarrant && (
        <ButtonComponent variant="info" onClick={createSearchWarrantClick}>
          <RiAddCircleLine /> Create Search warrant
        </ButtonComponent>
      )}{" "}
      <ButtonComponent
        variant="success"
        onClick={downloadSearchList}
        title="Download SearchResult Excel"
      >
        <RiDownload2Fill /> Download SearchList
      </ButtonComponent>{" "}
      {isDownloadReportVisible ? (
        <ButtonComponent
          variant="success"
          onClick={showReportPopup}
          title="Approved SearchWarrants Report"
        >
          <RiDownload2Fill /> Download Report
        </ButtonComponent>
      ) : (
        ""
      )}
    </Col>
  </Row>
);

const renderTableRow = (row: any[]) => {
  const [
    caseNumber,
    countyName,
    officerDetail,
    formStatus,
    pos,
    inv,
    judgeName,
    appType,
    maxAuditTimeStamp,
    protectedComplaint,
    isProtected,
    onRowClick,
    passwordProtector,
    changePassword,
  ] = row;

  return (
    <tr
      key={caseNumber}
      // onClick={isProtected?passwordProtector:protectedComplaint?changePassword:onRowClick}
      onClick={isProtected ? passwordProtector : onRowClick}
      style={{
        cursor: "pointer",
      }}
    >
      <td>
        <div>
          <b>{caseNumber}</b>
        </div>
      </td>
      <td>{countyName}</td>
      <td>{officerDetail}</td>
      <td className={`${getTextColorByStatus(formStatus)} `}>
        <strong>{formStatus}</strong>
      </td>
      <td className={`${getTextColorByStatus(pos)} `}>
        <strong>{pos}</strong>
      </td>
      <td className={`${getTextColorByStatus(inv)} `}>
        <strong>{inv}</strong>
      </td>
      <td>{judgeName}</td>
      <td
        className={
          appType === APP_SEARCH_WARRANT ? "text-success" : "text-info"
        }
      >
        {appType}
      </td>
      <td>{moment(maxAuditTimeStamp).format("MM/DD/YYYY hh:mm A")}</td>
    </tr>
  );
};

const sortByList = [
  { label: "-- DEFAULT --", value: "-- DEFAULT --" },
  { label: "CASE #", value: "CASE_NUMBER" },
  { label: "SIGNED_BY", value: "SIGNED_BY" },
];

const PreloadWarrantListComponent = () => {
  const dispatch = useAppDispatch();
  const [fetching, setFetching] = useState<LoadingType>("idle");

  useEffect(() => {
    setFetching("loading");
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "cardName",
        value: "Search Warrants",
      })
    );
    // dispatch(
    //   updateElementValueDashboardReducer({
    //     elementName: "isCardActive",
    //     value: true,
    //   })
    // );
    setFetching("success");
  }, []);
  return <WarrantList />;
};
export default PreloadWarrantListComponent;
