import React, { useCallback, useEffect, useReducer, useState, useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Header from "../common/Header";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import moment, { min } from "moment";
import {
  listUserTypes,
  useUserTypeReducer,
} from "../../redux/slice/userTypeSlice";
import {
  getLoggedInUserCounties,
  useCountyListReducer,
} from "../../redux/slice/countyListSlice";
import {
  useAgencyListReducer,
  getLoggedInUserAgencies,
  updateElementValueAgencyListReducer,
} from "../../redux/slice/agencyListSlice";
import { useAppDispatch } from "../../hooks/hooks";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { merge, validateDate } from "../../utils/common";
import {
  createUser,
  getUserDataByUsername,
  resetUserProfilesData,
  updateElementValueUserProfilesReducer,
  useUserProfilesReducer,
} from "../../redux/slice/userProfileSlice";
import {
  isEmailValidation,
  isValidPhone,
  formatPhoneNumber,
  isValidPassword,
} from "../common/CommonUtil";
import {
  ROLE_ADMINISTRATOR,
  ROLE_COUNTY_ADMIN,
  ROLE_AGENCY_ADMIN,
  ROLE_POLICE_OFFICER,
  ROLE_STATES_ATTORNEY,
  ROLE_JUDGE,
  ROLE_CIRCUIT_CLERK,
  ROLE_CASE_CREATOR,
  ROLE_ATTORNEY_GENERAL,
  ROLE_LOCAL_PROSECUTOR,
  ROLE_SA_ADMIN,
} from "../../constants/common";
import { useSignPanelReducer,resetSignature } from "../../redux/slice/signPanelSlice";
import ConfirmPopup from "../common/ConfirmPopup";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import MergeProfilePopup from "./MergeProfilePopup";
import AlertMessage from "../common/AlertMessage";
type UserProps = {};

const User: React.FC<UserProps> = (props) => {
  let regExp = /^[0-9\b]+$/;

  let defaultUserSchema = yup.object().shape({
    username: yup
      .string()
      .required("User Name is required.")
      .test("check-username", function (value) {
        if (
          userType !== ROLE_ADMINISTRATOR &&
          !isEmailValidation(userReducer.username) && location.pathname.split("/")[1] === "User"
        ) {
          return this.createError({
            message: "UserName must be Email address like XYZ@XYZ.XYZ",
          });
        } else {
          return true;
        }
      }),
    userType: yup.string().required("User Type is required.")
    .test("check-usertype", function (value) {
      if (userReducer.userType === 0) {
        return this.createError({
          message: "User Type is required.",
        });
      } else {
        return true;
      }
    }),
    password: yup
      .string()
      .required("Password is required.")
      .test("check-password", function (value) {
        if (
          userReducer.password !== "" &&
          !isValidPassword(userReducer.password) &&
          location.pathname === "/User"
        ) {
          return this.createError({
            message:
              "Password must contain at least 8 characters including at least one special character and one numeric value",
          });
        } else if (
          userReducer.password !== "" &&
          userReducer.username !== "" &&
          userReducer.username === userReducer.password
        ) {
          return this.createError({
            message: "Name and Password Can not be same",
          });
        } else {
          return true;
        }
      }),
    email: yup
      .string()
      .required("Email is required.")
      .test("check-email", function (value) {
        if (!isEmailValidation(userReducer.email)) {
          return this.createError({
            message: "Please insert a valid Email Address",
          });
        } else {
          return true;
        }
      }),
    workPhone: yup
      .string()
      .required("Work Phone is required.")
      .test("validate-workphone", function (value) {
        if (
          userReducer.workPhone.length > 0 &&
          !isValidPhone(userReducer.workPhone)
        ) {
          return this.createError({
            message: "Please insert a valid Work Phone",
          });
        } else {
          return true;
        }
      }),
    homePhone: yup.string().test("validate-homephone", function (value) {
      if (
        userReducer.homePhone.length > 0 &&
        !isValidPhone(userReducer.homePhone)
      ) {
        return this.createError({
          message: "Please insert a valid Home Phone",
        });
      } else {
        return true;
      }
    }),
    mobileNo: yup.string().test("validate-mobilephone", function (value) {
      if (
        (userReducer.mobileNo.length > 0 && userReducer.mobileNo.length < 10) ||
        userReducer.mobileNo == null
      ) {
        return this.createError({
          message: "Please insert a valid Mobile Phone",
        });
      } else {
        return true;
      }
    }),
    workExtension: yup.string().test("validate-wextension", function (value) {
      if (
        userReducer.workExtension != null &&
        userReducer.workExtension.length > 0 &&
        !regExp.test(userReducer.workExtension)
      ) {
        return this.createError({
          message: "Please insert a valid Work Phone Extension.",
        });
      } else {
        return true;
      }
    }),
    homeExtension: yup.string().test("validate-hextension", function (value) {
      if (
        userReducer.homeExtension != null &&
        userReducer.homeExtension.length > 0 &&
        !regExp.test(userReducer.homeExtension)
      ) {
        return this.createError({
          message: "Please insert a valid Home Phone Extension.",
        });
      } else {
        return true;
      }
    }),
    firstName: yup.string().trim().required("First Name is required."),
    lastName: yup.string().trim().required("Last Name is required."),
    middleName: yup
      .string()
      .trim("Middle Name cannot contain whitespaces.")
      .strict(true),
  });

  const location = useLocation();
  const { userTypeList } = useUserTypeReducer();
  const { countyList,loggedInUserCountyFetchStatus } = useCountyListReducer();
  const { agencyList,loggedInUseragenciFetchStatus } = useAgencyListReducer();
  const userReducer = useUserProfilesReducer();
  const signPanelReducer = useSignPanelReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errorKeys, setErrorKeys] = useState([""]);
  const errorMessageAlert = useAlert();
  const [countyDisable, setCountyDisable] = useState(Boolean);
  const [agencyDisable, setAgencyDisable] = useState(Boolean);
  const [countyEditDisable, setCountyEditDisable] = useState(Boolean);
  const [agencyEditDisable, setAgencyEditDisable] = useState(Boolean);
  const [badgeNumberDisable, setBadgeNumberDisable] = useState(Boolean);
  const [commissionDisable, setCommissionDisable] = useState(Boolean);
  const [attorneyIdDisable, setAttorneyIdDisable] = useState(Boolean);
  const [expDateDisable, setExpDateDisable] = useState(Boolean);
  const [multipleCountyEnable, setMultipleCountyEnable] = useState(Boolean);
  const [multipleAgencyEnable, setMultipleAgencyEnable] = useState(Boolean);
  const [passwordsDisable, setPasswordsDisable] = useState(true);
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
  });
  const [userMergeProfilePopup, setUserMergeProfilePopup] = React.useState({
    show: false,
    userName: "",
  });
  const inputRef = useRef();
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const { userType } = useLoginReducer();
  let availableAgenciesForLP: any = [];

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  let userTypesOptions: any = [];
  if (userTypeList.length !== 1) {
    userTypesOptions.push({ label: "-- Select --", value: 0 });
  }
  userTypesOptions.push(...userTypeList.map((userType: any) => {
    return { label: userType.type, value: userType.userTypeID };
  }));

  useEffect(() => {
    const userName = location.pathname.split("/")[2];
    dispatch(resetSignature());
    if (userName) {
      dispatch(
        getUserDataByUsername({
          username: userName,
          navigate,
        })
      );
    }else{
      dispatch(resetUserProfilesData());
    }
  }, [dispatch, location, navigate]);

  useEffect(() => {
    if (userReducer.UserProfilesError) {
      handleError([], [userReducer.UserProfilesError]);
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "UserProfilesError",
          value: "",
        })
      );
    }
    if (userReducer.updateUserProfilesError) {
      handleError([], [userReducer.updateUserProfilesError]);
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "updateUserProfilesError",
          value: "",
        })
      );
    }
    if (userReducer.successMessage) {
      setUserMergeProfilePopup({
        show: false,
        userName: "",
      });
      setAlertMessage(userReducer.successMessage);
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "successMessage",
          value: "",
        })
      );
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [
    userReducer.UserProfilesError,
    userReducer.updateUserProfilesError,
    userReducer.successMessage,
    dispatch,
    handleError,
  ]);

  useEffect(() => {
    setBadgeNumberDisable(true);
    setCommissionDisable(true);
    setAttorneyIdDisable(true);
    setExpDateDisable(true);

    dispatch(listUserTypes({}));
    dispatch(getLoggedInUserCounties());
    dispatch(getLoggedInUserAgencies());
    enableDisableCountyAgency();
    // agencyListSelection();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetUserProfilesData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (userReducer.getUserProfilesFetchStatus === "success") {
      enableDisabledField();
      enableDisableCountyAgency();
      // agencyListSelection();
    }
  }, [userReducer.getUserProfilesFetchStatus]);

  useEffect(() => {
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "sign",
        value: signPanelReducer.sign,
      })
    );
  }, [signPanelReducer.sign]);

  useEffect(() => {
    if (userReducer.agencySelectList !== null){
      let agnId: string;
      const arrAgency: Array<string> = [];
      userReducer.agencySelectList.map((agency: any) => {
        arrAgency.push(agency.agencyId);
      });
      agnId = arrAgency.join();
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "agencyIdList",
          value: agnId,
        })
      );
    }
  }, [userReducer.agencySelectList]);

  useEffect(() => {
    if (userReducer.countySelectList !== null){
    let ctyId: string;
    const arrCounty: Array<string> = [];
    userReducer.countySelectList.map((county: any) => {
      arrCounty.push(county.countyID);
    });
    ctyId = arrCounty.join();
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "countyIdList",
        value: ctyId,
      })
    );
  }
  }, [userReducer.countySelectList]);

  useEffect(() => {
    if (userReducer.isPublcNotary === true) {
      setExpDateDisable(false);
      setCommissionDisable(false);
    } else {
      setExpDateDisable(true);
      setCommissionDisable(true);
    }
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "isPublcNotary",
        value: userReducer.isPublcNotary,
      })
    );
  }, [userReducer.isPublcNotary]);

  useEffect(() => {
    if ((multipleCountyEnable) && (countyList.length > 1)) {
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "countySelectList",
          value: [],
        })
      );
    }
    if (multipleAgencyEnable) {
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "agencySelectList",
          value: [],
        })
      );
    }
  }, [userReducer.userType]);

  useEffect(() => {
    if (userReducer.typeName === ROLE_LOCAL_PROSECUTOR){
    const availableAgenciesForLP1: any = [];
    for (let i = 0; i < agencyList.length; i++) {
      if (agencyList[i].isLPAllowed == true){
        availableAgenciesForLP1.push(agencyList[i])
      }
    }
    dispatch(
      updateElementValueAgencyListReducer({
        elementName: "agencyList",
        value: availableAgenciesForLP1,
      })
    );
    }

    if (agencyList.length === 1){
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "agencySelectList",
          value: [{agencyName: agencyList[0].agencyName, agencyId: agencyList[0].agencyId}],
        })
      );
    }
  }, [loggedInUseragenciFetchStatus === "success"]);

  useEffect(() => {
    if (countyList.length === 1){
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "countySelectList",
          value: [{countyName: countyList[0].countyName, countyID: countyList[0].countyID}],
        })
      );
    }
  }, [loggedInUserCountyFetchStatus=== "success"]);

//   const agencyListSelection = () => {
//     // const response = await dispatch(getLoggedInUserAgencies());
//     dispatch(getLoggedInUserAgencies());
//     if (userReducer.typeName === ROLE_LOCAL_PROSECUTOR){
//     let availableAgenciesForLP1: any = [];
//     console.log(agencyList);
//     console.log(agencyList.length);
//     for (let i = 0; i < agencyList.length; i++) {
//       console.log("X");
//       if (agencyList[i].isLPAllowed == true){
//         availableAgenciesForLP1.push(agencyList[i])
//       }
//     }
//     console.log(availableAgenciesForLP1);
//     dispatch(
//       updateElementValueAgencyListReducer({
//         elementName: "agencyList",
//         value: availableAgenciesForLP1,
//       })
//     );
//     }
// }

  const handlIsPublicNotoryUpdateConfirm = () => {
    dispatch(resetSignature());
    resetExpDate();
    resetCommission();
    setconfirmPopupData({
      show: false,
    });
  };

  const handlIsPublicNotoryUpdateDecline = () => {
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "isPublcNotary",
        value: true,
      })
    );
      setconfirmPopupData({
        show: false,
      })
  }

  const resetAgency = () => {
    if (userType !== ROLE_ATTORNEY_GENERAL){
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "agencySelectList",
        value: [{ agencyName: "-- Select --", agencyId: 0 }],
      })
    );
  }
  };

  const resetCounty = () => {
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "countySelectList",
        value: [{ countyName: "-- Select --", countyID: 0 }],
      })
    );
  };

  const resetAttorneyID = () => {
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "attorneyID",
        value: "",
      })
    );
  };

  const resetBadgeNumber = () => {
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "badgeNumber",
        value: "",
      })
    );
  };

  const resetExpDate = () => {
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "expirationDate",
        value: "",
      })
    );
  };

  const resetCommission = () => {
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "commissionNumber",
        value: "",
      })
    );
  };

  const handleAgency = (e: any) => {
    let AgencyObject: string[] = [];
    if (multipleAgencyEnable) {
      AgencyObject = e;
    } else {
      AgencyObject.push(e);
    }
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "agencySelectList",
        value: AgencyObject,
      })
    );
  };

  const handleCounty = (e: any) => {
    let countyObject: string[] = [];
    if (multipleCountyEnable) {
      countyObject = e;
    } else {
      countyObject.push(e);
    }
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: "countySelectList",
        value: countyObject,
      })
    );
  };

    const handleMergeProfile = () => {
      setUserMergeProfilePopup({
        show: true,
        userName: userReducer.username,
      });
    };

  const enableDisableCountyAgency = () => {
    const typeName = userReducer.typeName;
    if( userType === ROLE_AGENCY_ADMIN) {
      setAgencyEditDisable(true);
    }else {
      setAgencyEditDisable(false);
    }

    if (location.pathname.split("/")[1] === "editUser"){
      if (userReducer.typeName === ROLE_AGENCY_ADMIN || userReducer.typeName === ROLE_POLICE_OFFICER){
        setAgencyEditDisable(true);
      }
    }

    if(( userType === ROLE_COUNTY_ADMIN) || (( userType === ROLE_SA_ADMIN)&&(countyList.length === 1))) {
      setCountyEditDisable(true);
    }else {
      setCountyEditDisable(false);
    }
  }

  const enableDisabledField = () => {
    const typeName = userReducer.typeName;

    if (typeName === ROLE_ATTORNEY_GENERAL || typeName === ROLE_ADMINISTRATOR) {
      setMultipleCountyEnable(false);
      setMultipleAgencyEnable(false);
      setCountyDisable(true);
      setAgencyDisable(true);
      setBadgeNumberDisable(true);
      setCommissionDisable(true);
      setAttorneyIdDisable(true);
      setExpDateDisable(true);
    } else if (typeName === ROLE_STATES_ATTORNEY) {
      setMultipleCountyEnable(true);
      setMultipleAgencyEnable(false);
      setCountyDisable(false);
      setAgencyDisable(true);
      setBadgeNumberDisable(true);
      setCommissionDisable(true);
      setAttorneyIdDisable(false);
      setExpDateDisable(true);
    } else if (
      typeName === ROLE_COUNTY_ADMIN ||
      typeName === ROLE_JUDGE ||
      typeName === ROLE_CIRCUIT_CLERK ||
      typeName === ROLE_CASE_CREATOR
    ) {
      setMultipleCountyEnable(false);
      setMultipleAgencyEnable(false);
      setAgencyDisable(true);
      setBadgeNumberDisable(true);
      setCommissionDisable(true);
      setAttorneyIdDisable(true);
      setExpDateDisable(true);
      setCountyDisable(false);
    } else if (
      typeName === ROLE_AGENCY_ADMIN ||
      typeName === ROLE_POLICE_OFFICER
    ) {
      setMultipleCountyEnable(false);
      setMultipleAgencyEnable(false);
      setCountyDisable(true);
      setAgencyDisable(false);
      setBadgeNumberDisable(false);
      setCommissionDisable(true);
      setAttorneyIdDisable(true);
      setExpDateDisable(true);
    } else if (typeName === ROLE_LOCAL_PROSECUTOR) {
      setMultipleCountyEnable(false);
      setMultipleAgencyEnable(true);
      setCountyDisable(true);
      setAgencyDisable(false);
      setBadgeNumberDisable(true);
      setCommissionDisable(true);
      setAttorneyIdDisable(true);
      setExpDateDisable(true);
    } else if (typeName === ROLE_SA_ADMIN) {
      setMultipleCountyEnable(true);
      setMultipleAgencyEnable(false);
      setCountyDisable(false);
      setAgencyDisable(true);
      setBadgeNumberDisable(true);
      setAttorneyIdDisable(true);
    } else {
      setMultipleCountyEnable(false);
      setMultipleAgencyEnable(false);
      setCountyDisable(false);
      setAgencyDisable(false);
      setBadgeNumberDisable(false);
      setAttorneyIdDisable(false);
    }
  };

  const handleSelectChange = (name: any) => (e: any) => {
    let typeName = e.label;
    if (typeName === ROLE_LOCAL_PROSECUTOR) {
    for (let i = 0; i < agencyList.length; i++) {
      if (agencyList[i].isLPAllowed == true){
        availableAgenciesForLP.push(agencyList[i])
      }
    }
    dispatch(
      updateElementValueAgencyListReducer({
        elementName: "agencyList",
        value: availableAgenciesForLP,
      })
    );
    } else {
      dispatch(getLoggedInUserAgencies());
    }

    if (typeName === ROLE_ATTORNEY_GENERAL || typeName === ROLE_ADMINISTRATOR) {
      setMultipleCountyEnable(false);
      setMultipleAgencyEnable(false);
      setCountyDisable(true);
      setAgencyDisable(true);
      setBadgeNumberDisable(true);
      setAttorneyIdDisable(true);
      if (userType === ROLE_ATTORNEY_GENERAL && typeName === ROLE_ATTORNEY_GENERAL){
        dispatch(
          updateElementValueUserProfilesReducer({
            elementName: "agencySelectList",
            value: [{ agencyName: "-- Select --", agencyId: 0 }],
          })
        );
      }
    } else if (typeName === ROLE_STATES_ATTORNEY) {
      setMultipleCountyEnable(true);
      setMultipleAgencyEnable(false);
      setCountyDisable(false);
      setAgencyDisable(true);
      setBadgeNumberDisable(true);
      setAttorneyIdDisable(false);
    } else if (
      typeName === ROLE_COUNTY_ADMIN ||
      typeName === ROLE_JUDGE ||
      typeName === ROLE_CIRCUIT_CLERK ||
      typeName === ROLE_CASE_CREATOR
    ) {
      setMultipleCountyEnable(false);
      setMultipleAgencyEnable(false);
      setAgencyDisable(true);
      setBadgeNumberDisable(true);
      setAttorneyIdDisable(true);
      setCountyDisable(false);
    } else if (
      typeName === ROLE_AGENCY_ADMIN ||
      typeName === ROLE_POLICE_OFFICER
    ) {
      setMultipleCountyEnable(false);
      setMultipleAgencyEnable(false);
      setCountyDisable(true);
      setAgencyDisable(false);
      setBadgeNumberDisable(false);
      setAttorneyIdDisable(true);
      if (userType === ROLE_ATTORNEY_GENERAL && typeName === ROLE_POLICE_OFFICER){
        dispatch(
          updateElementValueUserProfilesReducer({
            elementName: "agencySelectList",
            value: [{agencyName: 'ILLINOIS ATTORNEY GENERAL', agencyId: 272}],
          })
        );
      }
    } else if (typeName === ROLE_LOCAL_PROSECUTOR) {
      setMultipleCountyEnable(false);
      setMultipleAgencyEnable(true);
      setCountyDisable(true);
      setAgencyDisable(false);
      setBadgeNumberDisable(true);
      setAttorneyIdDisable(true);
    } else if (typeName === ROLE_SA_ADMIN) {
      setMultipleCountyEnable(true);
      setMultipleAgencyEnable(false);
      setCountyDisable(false);
      setAgencyDisable(true);
      setBadgeNumberDisable(true);
      setAttorneyIdDisable(true);
    } else if (typeName === "-- Select --") {
      setMultipleCountyEnable(false);
      setMultipleAgencyEnable(false);
      setCountyDisable(false);
      setAgencyDisable(false);
      setBadgeNumberDisable(false);
      setAttorneyIdDisable(false);
    }

    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: name,
        value: e.value,
      })
    );

    if (name === "userType") {
      if (userType !== typeName){
        if(userType !== ROLE_COUNTY_ADMIN && (typeName !== ROLE_JUDGE || typeName !== ROLE_CIRCUIT_CLERK)){
         resetCounty();
        }
        if (!(typeName === ROLE_AGENCY_ADMIN || typeName === ROLE_POLICE_OFFICER)) {
          resetAgency();
        }
       
      }
        if (countyList.length === 1){
          dispatch(
            updateElementValueUserProfilesReducer({
              elementName: "countySelectList",
              value: [{countyName: countyList[0].countyName, countyID: countyList[0].countyID}],
            })
          );
        }
        if (agencyList.length === 1){
          dispatch(
            updateElementValueUserProfilesReducer({
              elementName: "agencySelectList",
              value: [{agencyName: agencyList[0].agencyName, agencyId: agencyList[0].agencyId}],
            })
          );
        }
      resetAttorneyID();
      if (!(typeName === ROLE_AGENCY_ADMIN || typeName === ROLE_POLICE_OFFICER)) {
      resetBadgeNumber();
      }
    }
  };

  const workPhoneNumberFormat = (event: any) => {
    if (userReducer.workPhone.length > 9 && userReducer.workPhone != null) {
      var mobileNumber = formatPhoneNumber(event.target.value);
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: event.target.name,
          value: mobileNumber,
        })
      );
    }
  };

  const MobileNumberFormat = (event: any) => {
    if (userReducer.mobileNo.length > 9 && userReducer.mobileNo != null) {
      var mobileNumber = formatPhoneNumber(event.target.value);
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: event.target.name,
          value: mobileNumber,
        })
      );
    }
  };

  const handlePhoneNumberKeyPress = (evt: any) => {
    if (
      evt.which !== 8 &&
      evt.which !== 9 &&
      (evt.which < 48 || evt.which > 57) &&
      evt.key !== "-"
    ) {
      evt.preventDefault();
    }
  };

  const handleconfirmPassword = async (event: any) => {
    if (
      (userReducer.password !== "" || userReducer.confirmPassword !== "") &&
      !isValidPassword(userReducer.password)
    ) {
      handleError(
        [event.target.name],
        [
          "Password must contain at least 8 characters including at least one special character and one numeric value",
        ]
      );
      return;
    } else {
      handleError([], []);
    }
    if (userReducer.password === "" || userReducer.confirmPassword === "") {
      return true;
    } else if (userReducer.password !== userReducer.confirmPassword) {
      handleError([event.target.name], ["Passwords do not match"]);
    } else {
      handleError([], []);
    }
  };

  const handleusernameEdit = (event: any) => {
    if ((event.target.name === "username") && (location.pathname.split("/")[1] === "editUser") && (userReducer.oldUsername !== event.target.value)){
      setPasswordsDisable(false);
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "password",
          value: "",
        })
      );
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "confirmPassword",
          value: "",
        })
      );
    }
  }

  const handleInputChange = (event: any) => {
    if ((event.target.name === "isPublcNotary") && (event.target.value === "true") && (signPanelReducer.sign)){
      setconfirmPopupData({
        show: true
      });
    }

    // Do not allow AttorneyId and badge number to have letters
    if (
      event.target.name === "attorneyID" ||
      event.target.name === "badgeNumber" ||
      event.target.name === "homeExtension" ||
      event.target.name === "workExtension" ||
      event.target.name === "mobileNo"
    ) {
      event.target.value = event.target.value.replace(/[a-zA-Z]/g, "");
    }

    // If the value is true or false then convert it to boolean (For checkbox)
    const value =
      event.target.value === "true" || event.target.value === "false"
        ? event.target.value !== "true"
        : event.target.value;

    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: event.target.name,
        value,
      })
    );
  };
  const handleInputBlur = (event: any) => {
    let { name, value } = event.target;
    value = validateDate(value);
     dispatch(updateElementValueUserProfilesReducer({ elementName: name, value: value }));
  }

  const handleToUpperCaseOnBlur = (e: any) => {
    dispatch(
      updateElementValueUserProfilesReducer({
        elementName: e.target.name,
        value: e.target.value.toUpperCase(),
      })
    );
  };

  const getValidationError = (value: any) => {
    if (value == null){
      return false;
    } else if (value.length === 0) {
      return false;
    } else if (value[0].countyName === "-- Select --") {
      return true;
    } else if (value[0].agencyName === "-- Select --") {
      return true;
    }
  };

  const handleSubmit = async () => {
    try {
      let schema = [];
      schema.push(defaultUserSchema);

      if (countyDisable === false) {
        schema.unshift(
          yup.object().shape({
            countySelectList: yup
              .array()
              .min(1, "County is required.")
              .nullable()
              .required("County is required.")
              .test("validator-countySelect", function (value) {
                const errMessage = getValidationError(value);
                console.log(errMessage);
                if (errMessage) {
                  return this.createError({
                    message: "County is required.",
                  });
                } else {
                  return true;
                }
              }),
          })
        );
      }

      if (agencyDisable === false) {
        schema.unshift(
          yup.object().shape({
            agencySelectList: yup
              .array()
              .min(1, "Agency is required.")
              .nullable()
              .required("Agency is required.")
              .test("validator-agencySelect", function (value) {
                const errMessage = getValidationError(value);
                console.log(errMessage);
                if (errMessage === true) {
                  return this.createError({
                    message: "Agency is required.",
                  });
                } else {
                  return true;
                }
              }),
          })
        );
      }

      if (badgeNumberDisable === false) {
        schema.unshift(
          yup.object().shape({
            badgeNumber: yup
              .string()
              .nullable()
              .required("Badge Number is required.")
              .test("validate-badgenumber", function (value) {
                if (
                  (userReducer.badgeNumber != null &&
                    userReducer.badgeNumber.length > 0 &&
                    !regExp.test(userReducer.badgeNumber)) ||
                    userReducer.badgeNumber.toString() === "0"
                ) {
                  return this.createError({
                    message: "Valid Badge Number is required.",
                  });
                } else {
                  return true;
                }
              }),
          })
        );
      }

      if (commissionDisable === false) {
        schema.unshift(
          yup.object().shape({
            commissionNumber: yup
              .string()
              .trim("Commission cannot contain empty spaces")
              .strict(true)
              .required("Commission is required.")
              .nullable(),
          })
        );
      }

      if (expDateDisable === false) {
        schema.unshift(
          yup.object().shape({
            expirationDate: yup
              .date()
              .required("Exp Date is required.")
              .nullable()
              .min(new Date(),"Expiration Date should be a future date."),
          })
        );
      }

      if (attorneyIdDisable === false) {
        schema.unshift(
          yup.object().shape({
            attorneyID: yup
              .string()
              .nullable()
              .required("Valid Attorney ID is required for a State attorney.")
              .test("validate-attorneyId", function (value) {
                if (
                  userReducer.attorneyID != null &&
                  userReducer.attorneyID.length > 0 &&
                  !regExp.test(userReducer.attorneyID)
                ) {
                  return this.createError({
                    message:
                      "Valid Attorney ID is required for a State attorney.",
                  });
                } else {
                  return true;
                }
              }),
          })
        );
      }

      if (userReducer.isPublcNotary === true) {
        schema.unshift(
          yup.object().shape({
            sign: yup.string().nullable().required("Signature is required."),
          })
        );
      }

      if (location.pathname === "/User") {
        schema.unshift(
          yup.object().shape({
            confirmPassword: yup
              .string()
              .required("Confirm Password is required.")
              .oneOf([yup.ref("password"), null], "Passwords do not match"),
          })
        );
      }

      schema = merge(...schema);
      const user = await schema.validate(userReducer, {
        abortEarly: false,
      });

      if (location.pathname === "/User") {
        user.userOparation = "Create";
        dispatch(createUser({ user, navigate }));
      } else {
        dispatch(createUser({ user, navigate }));
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/UserList", itemName: "User List" },
  ];
  const data = [
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "User Name",
        type: "text",
        name: "username",
        value: userReducer.username,
        onChange: handleInputChange,
        onBlur: handleusernameEdit,
        placeholder: "Enter User Name",
        isRequired: true,
        isError: errorKeys.includes("username"),
        disabled: (userType === ROLE_AGENCY_ADMIN && location.pathname.split("/")[1] === "editUser") ? true : false,
        autoComplete: "off",
      },
    },
    {
      type: "selectInput",
      props: {
        options: userTypesOptions,
        defaultValue: { label: "-- Select --", value: 0 },
        placeholder: "User Type",
        name: "userType",
        value: userTypesOptions.find((item: any) => {
          if (item.value === userReducer.userType) {
            return { label: item.label, value: item.value };
          } else if (!userReducer.userType) {
            return { label: "-- Select --", value: 0 };
          }
        }
      ),
        onChange: handleSelectChange("userType"),
        isSelected: true,
        isRequired: true,
        isError: errorKeys.includes("userType"),
      },
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Password",
        type: "password",
        name: "password",
        ref: inputRef,
        value: userReducer.password,
        onChange: handleInputChange,
        onBlur: handleconfirmPassword,
        isCorrect: isValidPassword(userReducer.password),
        disabled: location.pathname === "/User" ? false : passwordsDisable,
        placeholder: "Enter Password",
        isRequired: true,
        isError: errorKeys.includes("password"),
        autoComplete: "new-password",
       
      },
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Confirm Password",
        type: "password",
        name: "confirmPassword",
        value: userReducer.confirmPassword,
        autoComplete:"new-password",
        onChange: handleInputChange,
        onBlur: handleconfirmPassword,
        isCorrect: (userReducer.confirmPassword !== "") && (userReducer.password !== "") && (userReducer.confirmPassword === userReducer.password),
        disabled: location.pathname === "/User" ? false : passwordsDisable,
        placeholder: "Enter Password",
        isRequired: true,
        isError: errorKeys.includes("password"),
      },
    },
    {
      type: "selectInput",
      props: {
        options: [
          { countyName: "-- Select --", countyID: 0 },
          ...countyList.map((county: any) => {
            return { countyName: county.countyName, countyID: county.countyID };
          }),
        ],
        value: userReducer.countySelectList === null? { countyName: "-- Select --", countyID: 0 }:
          userReducer.countySelectList.length === 0 && !multipleCountyEnable
            ? { countyName: "-- Select --", countyID: 0 }
            : userReducer.countySelectList,
        defaultValue: { countyName: "-- Select --", countyID: 0 },
        placeholder: "County",
        name: "countySelectList",
        isMulti: multipleCountyEnable,
        onChange: handleCounty,
        isDisabled: (countyDisable) || (countyEditDisable),
        isRequired: !countyDisable,
        getOptionLabel: (option: any) => option.countyName,
        getOptionValue: (option: any) => option.countyID,
        isSelected: true,
        isError: errorKeys.includes("countySelectList"),
      },
    },
    {
      type: "selectInput",
      props: {
        options: [
          { agencyName: "-- Select --", agencyId: 0 },
          ...agencyList.map((agency: any) => {
            return { agencyName: agency.agencyName, agencyId: agency.agencyId };
          }),
        ],
        value: userReducer.agencySelectList === null? { agencyName: "-- Select --", agencyId: 0 }:
          userReducer.agencySelectList.length === 0 && !multipleAgencyEnable
            ? { agencyName: "-- Select --", agencyId: 0 }
            : userReducer.agencySelectList,
        defaultValue: { agencyName: "-- Select --", agencyId: 0 },
        placeholder: "Agency",
        name: "agencySelectList",
        isMulti: multipleAgencyEnable,
        onChange: handleAgency,
        isDisabled: (agencyDisable) || ((userType === ROLE_ATTORNEY_GENERAL)&&(location.pathname.split("/")[1] === "User")?true:false) || (agencyEditDisable),
        isRequired: !agencyDisable,
        getOptionLabel: (option: any) => option.agencyName,
        getOptionValue: (option: any) => option.agencyId,
        isError: errorKeys.includes("agencySelectList"),
      },
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "First Name",
        type: "text",
        name: "firstName",
        value: userReducer.firstName,
        onChange: handleInputChange,
        placeholder: "Enter First Name",
        isRequired: true,
        isError: errorKeys.includes("firstName"),
        onBlur: handleToUpperCaseOnBlur,
        autoComplete:"nope",
      },
      colSize: 3,
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Middle Name",
        type: "text",
        name: "middleName",
        value: userReducer.middleName,
        onChange: handleInputChange,
        placeholder: "Enter Middle Name",
        isError: errorKeys.includes("middleName"),
        onBlur: handleToUpperCaseOnBlur,
        autoComplete:"nope",
      },
      colSize: 3,
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Last Name",
        type: "text",
        name: "lastName",
        value: userReducer.lastName,
        onChange: handleInputChange,
        isRequired: true,
        placeholder: "Enter Last Name",
        isError: errorKeys.includes("lastName"),
        onBlur: handleToUpperCaseOnBlur,
        autoComplete:"nope",
      },
      colSize: 6,
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Email",
        type: "email",
        name: "email",
        value: userReducer.email,
        onChange: handleInputChange,
        isRequired: true,
        placeholder: "Enter Email",
        isError: errorKeys.includes("email"),
        autoComplete:"off",
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value:
          "(Multiple email address must be seperated with comma e.g. email add. 1,email add. 2)",
      },
      colSize: 12,
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Work Phone (xxx-xxx-xxxx)",
        type: "text",
        name: "workPhone",
        value: userReducer.workPhone,
        onChange: handleInputChange,
        placeholder: "Enter Work Phone",
        onBlur: workPhoneNumberFormat,
        isRequired: true,
        onKeyPress: handlePhoneNumberKeyPress,
        isError: errorKeys.includes("workPhone"),
        maxLength: 12,
        autoComplete:"nope",
      },
      colSize: 4,
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Extension",
        type: "text",
        name: "workExtension",
        maxlength: "10",
        value: userReducer.workExtension,
        onChange: handleInputChange,
        placeholder: "Enter Extension",
        isError: errorKeys.includes("workExtension"),
        autoComplete:"nope",
        // onBlur: handleToUpperCaseOnBlur,
      },
      colSize: 2,
    },
    // {
    //   type: "textInputFloting",
    //   props: {
    //     floatingLabel: "Home Phone (xxx-xxx-xxxx)",
    //     type: "text",
    //     name: "homePhone",
    //     value: userReducer.homePhone,
    //     onChange: handleInputChange,
    //     placeholder: "Enter Home Phone",
    //     onBlur: MobileNumberFormat,
    //     onKeyPress: handlePhoneNumberKeyPress,
    //     isError: errorKeys.includes("homePhone"),
    //     maxLength: 12,
    //   },
    //   colSize: 4,
    // },
    // {
    //   type: "textInputFloting",
    //   props: {
    //     floatingLabel: "Extension",
    //     type: "text",
    //     name: "homeExtension",
    //     maxlength: "10",
    //     value: userReducer.homeExtension,
    //     onChange: handleInputChange,
    //     placeholder: "Enter Extension",
    //     isError: errorKeys.includes("homeExtension"),
    //     onBlur: handleToUpperCaseOnBlur,
    //   },
    //   colSize: 2,
    // },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Mobile",
        type: "text",
        name: "mobileNo",
        value: userReducer.mobileNo,
        onChange: handleInputChange,
        maxlength: "12",
        placeholder: "Enter Mobile",
        onBlur: MobileNumberFormat,
        autoComplete:"nope",
        isError: errorKeys.includes("mobileNo"),
      },
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Badge Number",
        type: "text",
        name: "badgeNumber",
        value: userReducer.badgeNumber ? userReducer.badgeNumber : "",
        onChange: handleInputChange,
        placeholder: "Enter Badge Number",
        maxlength: "10",
        isRequired: !badgeNumberDisable,
        disabled: badgeNumberDisable,
        autoComplete:"nope",
        isError: errorKeys.includes("badgeNumber"),
      },
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Attorney ID",
        type: "text",
        name: "attorneyID",
        disabled: attorneyIdDisable,
        value: userReducer.attorneyID ? userReducer.attorneyID : "",
        maxlength: "10",
        onChange: handleInputChange,
        isRequired: !attorneyIdDisable,
        placeholder: "Enter Badge Number",
        autoComplete:"nope",
        isError: errorKeys.includes("attorneyID"),
      },
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Commission #",
        type: "text",
        placeholder: "Enter Commission #",
        name: "commissionNumber",
        disabled: commissionDisable,
        value: userReducer.commissionNumber,
        isRequired: !commissionDisable,
        onChange: handleInputChange,
        isError: errorKeys.includes("commissionNumber"),
        autoComplete:"nope",
      },
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Exp. date",
        type: "Date",
        placeholder: "Exp. date",
        name: "expirationDate",
        disabled: expDateDisable,
        isRequired: !expDateDisable,
        value: moment(userReducer.expirationDate).format("YYYY-MM-DD"),
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        isError: errorKeys.includes("expirationDate"),
        autoComplete:"nope",
      },
    },
    {
      type: "checkbox",
      props: {
        label: "File On Behalf Of Others",
        placeholder: "File On Behalf Of Others",
        name: "fileOnBehalfOfOthers",
        value: userReducer.fileOnBehalfOfOthers,
        onChange: handleInputChange,
        checked: userReducer.fileOnBehalfOfOthers,
        isError: errorKeys.includes("fileOnBehalfOfOthers"),
        autoComplete:"nope",
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Receive Notification",
        placeholder: "Receive Notification",
        name: "receiveNotification",
        value: userReducer.receiveNotification,
        onChange: handleInputChange,
        checked: userReducer.receiveNotification,
        isError: errorKeys.includes("receiveNotification"),
        autoComplete:"nope",
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Notary Public",
        placeholder: "Notary Public",
        name: "isPublcNotary",
        value: userReducer.isPublcNotary,
        onChange: handleInputChange,
        checked: userReducer.isPublcNotary,
        isError: errorKeys.includes("isPublcNotary"),
        autoComplete:"nope",
      },
    },
    userReducer.isPublcNotary === true
      ? {
          type: "signPanel",
          props: {
            name: "sign",
            isError: errorKeys.includes("sign"),
          },
          colSize: 12,
        }
      : {
          type: "empty",
          node: () => <div />,
        },
    {
      type: "button",
      props: {
        children: location.pathname === "/User" ? "Save" : "Update",
        onClick: handleSubmit,
        className: "mx-2",
      },
    },
    ((location.pathname !== "/User") && (userType === ROLE_ADMINISTRATOR) && (userReducer.typeName === ROLE_POLICE_OFFICER))
    ?
    {
      type: "button",
      props: {
        children: "Merge Profile",
        onClick: handleMergeProfile,
        className: "mx-2",
      },
    }      : {
      type: "empty",
      node: () => <div />,
    },
    {
      type: "button",
      props: {
        children: "Cancel",
        className: "btn-danger",
        onClick: () => {
          navigate(-1);
        },
      },
    },
  ];

  return (
    <>
      <Header headerName="User" />
      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="User" />

        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />

        <AlertMessage
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />

        <ConfirmPopup
          children="Your Signature will be removed. Confirm to continue."
          show={confirmPopupData.show}
          onHide={handlIsPublicNotoryUpdateDecline}
          isConfirmation={handlIsPublicNotoryUpdateConfirm}
        />

        <MergeProfilePopup
          show={userMergeProfilePopup.show}
          onHide={() =>
            setUserMergeProfilePopup({
              show: false,
              userName: "",
            })
          }
          userName={userMergeProfilePopup.userName}
        />

        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data}
          header="User"
          subHeader={
            location.pathname === "/User"
              ? "User Registration Form"
              : "Edit User"
          }
          components={[
            {
              type: "hideAutoComplete",
              node: () => (
                <>
                  <input
                    id="username-hidden"
                    style={{ display: "none" }}
                    type="text"
                    name="fakeusernameremembered"
                  />

                  <input
                    id="password-hidden"
                    style={{
                      display: "none",
                    }}
                    type="password"
                    name="fakepasswordremembered"
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default User;
