import React, { useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import TextAreaComponent from "../common/TextAreaComponent";
import { useAppDispatch } from "../../hooks/hooks";
import {
  useLoginReducer,
  updateElementValueInLoginReducer,
} from "../../redux/slice/loginSlice";
import { useLocation, useNavigate } from "react-router-dom";

interface MessagePopupProps {
  // show: boolean;
  // onHide: () => void;
  handleConfirm: (status: string) => void;
}

const MessagePopup: React.FC<MessagePopupProps> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //const { show, onHide } = props;

  const { message } = useLoginReducer();

  const { handleConfirm } = props;
  const location = useLocation();

  const handleInputChange = (event: any) => {
    dispatch(
      updateElementValueInLoginReducer({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  const hidePopup = () => {
    navigate("/ActiveUserSessionsList");
  };

  useEffect(() => {
    dispatch(
      updateElementValueInLoginReducer({
        elementName: "receiverName",
        value: location.state,
      })
    );
  }, []);

  const confirmMessagePopup = () => {
    handleConfirm("MESSAGE");
    navigate("/ActiveUserSessionsList");
  };

  return (
    <Modal
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => hidePopup()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <b>Write Message to send to selected users.</b>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="mt-2">
            <TextAreaComponent
              labelName="Message"
              isRequired
              name="message"
              value={message}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <hr />
        <Row className="g-2">
          <Col>
            <Button
              variant="info"
              onClick={confirmMessagePopup}
              className="w-100"
            >
              Send
            </Button>
          </Col>
          <Col>
            <Button variant="danger" onClick={hidePopup} className="w-100">
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default MessagePopup;
