import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { RiArrowGoBackFill, RiArrowLeftLine, RiArrowRightSFill, RiBallPenLine, RiCheckboxMultipleFill, RiCloseCircleFill, RiFeedbackFill, RiFileTextLine, RiSave3Line, RiSendPlane2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ConfirmPopup from "../../../components/common/ConfirmPopup";
import {
  ACTIONTYPE_SAVE, COMPL_SEARCH_WARRANT, COMPL_SEARCH_WARRANT_WITH_SLASH, ROLE_ADMINISTRATOR, ROLE_AGENCY_ADMIN, ROLE_ATTORNEY_GENERAL, ROLE_COUNTY_ADMIN, ROLE_JUDGE, ROLE_LOCAL_PROSECUTOR, ROLE_POLICE_OFFICER, ROLE_SA_ADMIN, ROLE_STATES_ATTORNEY, SEARCH_WAR_ASSIGNED_TO_AG_STATUS,
  SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS, SEARCH_WAR_ASSIGNED_TO_LP_STATUS, SEARCH_WAR_ASSIGNED_TO_SA_STATUS, SEARCH_WAR_DENIED_STATUS, SEARCH_WAR_INCOMPLETE_STATUS, SEARCH_WAR_LOCKED_BY_JUDGE_STATUS, SEARCH_WAR_LOCKED_BY_SA_STATUS, SEARCH_WAR_OFFICER_PENDING_STATUS
} from "../../../constants/common";
import { useAppDispatch } from "../../../hooks/hooks";
import { getSearchWarrantData, previewOrder } from "../../../redux/slice/commonFormSlice";
import { useLoginReducer } from "../../../redux/slice/loginSlice";
import { approveSearchwarrant, denySearchWarrant, returnSearchWarrant, saveSearchWarrant, signSearchwarrant, submitSearchWarrant, updateElementObjectValue, useSearchWarrantReducer,updateElementValueSearchWarrantReducer } from "../../../redux/slice/searchWarrantSlice";
import ButtonComponent from "../../common/ButtonComponent";
import { Util } from "../../common/FormUtil";
import Header from "../../common/Header";
import RemarkPopup from "../../common/RemarkPopup";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";
import SecurityPassword from "../../common/SecurityPasswordPopup";
import CheckBoxComponent from "../../common/CheckBoxComponent";
import { useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";
import OptionPopup from "../../common/OptionsPopup";
import { approvalStatusForms } from "../../common/CommonUtil";
import { updateElementSecurityPasswordObjectValue, useSecurityPasswordReducer } from "../../../redux/slice/securityPasswordSlice";

interface SearchWarrantSubmitProps {}

const SearchWarrantSubmit: React.FC<SearchWarrantSubmitProps> = (props) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const searchWarrantReducer = useSearchWarrantReducer();
  const loginReducer = useLoginReducer();
  const caseDetailReducer = useCaseDetailReducer();
  const {securityPasswordObject, fetchStatus} = useSecurityPasswordReducer();

  const [confirmPopupData, setconfirmPopupData] = React.useState(false);
  const [unImpoundCaseVisible, setUnImpoundCaseVisible] = React.useState(false);
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [confirmOriginPlace, setconfirmOriginPlace] = React.useState("");
  const [remarkModalShow, setRemarkModalShow] = React.useState(false);
  const [remarkRequired, setRemarkRequired] = React.useState(false);
  const [remarkFor, setRemarkFor] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [action, setAction] = React.useState("");

  const [btnRevertVisible , setBtnRevertVisible ] = React.useState(true);
  const [btnSignVisible , setBtnSignVisible ] = React.useState(true);
  const [btnApproveVisible , setBtnApproveVisible ] = React.useState(true);
  const [btnDeniedVisible , setBtnDeniedVisible ] = React.useState(true);
  const [btnSubmitVisible , setBtnSubmitVisible ] = React.useState(true);
  const [btnSaveVisible , setBtnSaveVisible ] = React.useState(true);
  const [btnSubmitLabel, setBtnSubmitLabel] = React.useState("Submit to Judge");
  const [btnRevertLabel, setBtnRevertLabel] = React.useState("Return to Officer");
  const errorMessageAlert = useAlert();
  const [errorKeys, setErrorKeys] = useState([""]);

  const [securityPasswordShow, setSecurityPasswordShow] = React.useState(false);
  const [securityPasswordProps, setSecurityPasswordProps] 
  = React.useState<{complaintId: number, caseNumber: string, action : string}>({complaintId: 0, caseNumber:"", action : ""});
  const [showOptionPopup, setShowOptionPopup] = React.useState(false);
  const [optionObj, setOptionObj] = React.useState({
    header: "",
    body: <></>,
    buttons: [{ name: "", variant: "", onClick: () => {}}]
  });
  const [formApprovedStatus, setFormApprovedStatus] = React.useState("");


  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  useEffect(() => {
    if (searchWarrantReducer.updateSwError) {
      handleError([], [searchWarrantReducer.updateSwError]);
      dispatch(
        updateElementValueSearchWarrantReducer({
          elementName: "updateSwError",
          value: "",
        })
      );
    }
  }, [
    searchWarrantReducer.updateSwError,
    dispatch,
    handleError,
  ]);

  useEffect(() => {
    if (searchWarrantReducer.searchWarrantObject.searchWarrantId > 0) {

      let loggedInUserId = loginReducer.userProfileId;
      let loggedInUserRole = loginReducer.userType;
      let { formStatus, creatorId, isOnBehalf, approvalSAId, approvalUserType, isSARequired, creatorUserType } = searchWarrantReducer.searchWarrantObject;
      formStatus = formStatus ? formStatus : "";

      if (loggedInUserRole) {
        if (!(loggedInUserRole === ROLE_STATES_ATTORNEY || loggedInUserRole === ROLE_SA_ADMIN || loggedInUserRole === ROLE_LOCAL_PROSECUTOR
          || loggedInUserRole === ROLE_ATTORNEY_GENERAL || loggedInUserRole === ROLE_JUDGE)) {
          if (!approvalSAId || approvalSAId === 0) {
            if (approvalUserType === ROLE_STATES_ATTORNEY) {
              if (isSARequired) {
                setBtnSubmitLabel("Submit for Screening");
              }
            } else if (approvalUserType === ROLE_JUDGE) {
              setBtnSubmitLabel("Submit to Judge");
            }
            else {
              setBtnSubmitLabel("Submit for Screening");
            }
          }
        } else if (loggedInUserRole === ROLE_JUDGE) {
          if (!isOnBehalf) {
            if (creatorUserType === ROLE_LOCAL_PROSECUTOR) {
              setBtnRevertLabel("Return to LP");
            } else if (creatorUserType === ROLE_ATTORNEY_GENERAL) {
              setBtnRevertLabel("Return to AG");
            } else if (creatorUserType === ROLE_STATES_ATTORNEY) {
              setBtnRevertLabel("Return to SA");
            } else if (creatorUserType === ROLE_SA_ADMIN) {
              setBtnRevertLabel("Return to SA Admin");
            }
          }
        }

        if (ROLE_ADMINISTRATOR === loggedInUserRole) {
          if (formStatus === SEARCH_WAR_INCOMPLETE_STATUS || formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS
            || formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) {
            setBtnRevertVisible(false);
            setBtnSignVisible(false);
            setBtnApproveVisible(false);
            setBtnDeniedVisible(false);
            if (formStatus === SEARCH_WAR_INCOMPLETE_STATUS && creatorId !== loggedInUserId) {
              setBtnSubmitVisible(false);
            }
          }
          if (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) {
            setBtnRevertVisible(true);
            setBtnSignVisible(false);
            setBtnApproveVisible(true);
            setBtnSaveVisible(false);
            setBtnDeniedVisible(false);
            setBtnSubmitVisible(false);
          }
          if (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS) {
            setBtnRevertVisible(true);
            setBtnSignVisible(true);
            setBtnApproveVisible(false);
            setBtnSaveVisible(false);
            setBtnDeniedVisible(true);
            setBtnSubmitVisible(false);
            if (creatorId !== loggedInUserId) {
              setBtnSignVisible(false);
            }
            setUnImpoundCaseVisible(true);
          }
        } else if (ROLE_COUNTY_ADMIN === loggedInUserRole) {
          if (formStatus === SEARCH_WAR_INCOMPLETE_STATUS
            || formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS
            || formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS
            || formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) {
            setBtnRevertVisible(false);
            setBtnSignVisible(false);
            setBtnApproveVisible(false);
            setBtnDeniedVisible(false);
            if (formStatus === SEARCH_WAR_INCOMPLETE_STATUS && creatorId !== loggedInUserId) {
              setBtnSubmitVisible(false);
            }
          }
          if (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS
            || formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) {
            setBtnRevertVisible(true);
            setBtnSignVisible(false);
            setBtnApproveVisible(true);
            setBtnSaveVisible(false);
            setBtnDeniedVisible(false);
            setBtnSubmitVisible(false);
          }
          if (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS
            || formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS) {
            setBtnRevertVisible(true);
            setBtnSignVisible(true);
            setBtnApproveVisible(false);
            setBtnSaveVisible(false);
            setBtnDeniedVisible(true);
            setBtnSubmitVisible(false);
            if (creatorId !== loggedInUserId) {
              setBtnSignVisible(false);
            }
          }
        } else if (ROLE_AGENCY_ADMIN === loggedInUserRole) {
          setBtnRevertVisible(false);
          setBtnSignVisible(false);
          setBtnApproveVisible(false);
          setBtnDeniedVisible(false);
          if (formStatus === SEARCH_WAR_INCOMPLETE_STATUS &&
            creatorId !== loggedInUserId) {
            setBtnSubmitVisible(false);
          }
        } else {
          if (creatorId === loggedInUserId) {
            if (ROLE_POLICE_OFFICER === loggedInUserRole
              || ROLE_STATES_ATTORNEY === loggedInUserRole
              || ROLE_SA_ADMIN === loggedInUserRole
              || ROLE_ATTORNEY_GENERAL === loggedInUserRole
              || ROLE_LOCAL_PROSECUTOR === loggedInUserRole) {
              setBtnRevertVisible(false);
              setBtnSignVisible(false);
              setBtnApproveVisible(false);
              setBtnDeniedVisible(false);
            }
          } else if ((ROLE_STATES_ATTORNEY === loggedInUserRole || ROLE_SA_ADMIN === loggedInUserRole
            || ROLE_ATTORNEY_GENERAL === loggedInUserRole
            || ROLE_LOCAL_PROSECUTOR === loggedInUserRole)
            && (creatorId !== loggedInUserId
              || (creatorId === loggedInUserId && isOnBehalf))) {
            setBtnSignVisible(false);
            setBtnRevertVisible(true);
            setBtnApproveVisible(true);
            setBtnSaveVisible(false);
            setBtnDeniedVisible(false);
            setBtnSubmitVisible(false);
            if (formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
              formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) {
              setBtnRevertVisible(false);
              setBtnSaveVisible(true);
              setBtnApproveVisible(false);
            }
          } else if (ROLE_JUDGE === loggedInUserRole) {
            setUnImpoundCaseVisible(true);

            setBtnRevertVisible(true);
            setBtnSignVisible(true);
            setBtnApproveVisible(false);
            setBtnSaveVisible(false);
            setBtnSubmitVisible(false);
            setBtnDeniedVisible(true);
          }
        }
      }
    }
  }, [searchWarrantReducer.searchWarrantObject.searchWarrantId]);

  const handleYesRadioChange = (event: any) => {
    let { name, checked } = event.target;
    dispatch(updateElementObjectValue({ elementName: name, value: checked }));
  }

  const handleNoRadioChange = (event: any) => {
    let { name, checked } = event.target;
    dispatch(updateElementObjectValue({ elementName: name, value: !checked }));
  }

  const backClick = () => {
    dispatch(getSearchWarrantData({ searchWarrantId : searchWarrantReducer.searchWarrantObject.searchWarrantId, 
      formName: COMPL_SEARCH_WARRANT, navigate : navigate }));
  }

  const handleConfirmation = () => {
    setconfirmPopupData(false);
    if (confirmOriginPlace === "saveClick") {
      afterConfirmHandleSaveClick();
    } else if (confirmOriginPlace === "submitClick") {
      afterConfirmHandleSubmitClick();
    } else if (confirmOriginPlace === "signClick") {
      afterConfirmHandleSignClick();
    } else if (confirmOriginPlace === "approveClick") {
      afterConfirmHandleApproveClick();
    } else if (confirmOriginPlace === "returnClick") {
      afterConfirmHandleReturnClick();
    } else if (confirmOriginPlace === "denyClick") {
      afterConfirmHandleDenyClick();
    }
  };

  const afterConfirmHandleSaveClick = () => {
    let status = "";
    let unImpoundCase = searchWarrantReducer.searchWarrantObject.unImpoundCase;
    if (searchWarrantReducer.searchWarrantObject.creatorId === loginReducer.userProfileId) {
        if (ROLE_ATTORNEY_GENERAL === loginReducer.userType) {
            status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
        } else if (ROLE_LOCAL_PROSECUTOR === loginReducer.userType) {
            status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
        } else if (ROLE_STATES_ATTORNEY === loginReducer.userType || ROLE_SA_ADMIN === loginReducer.userType) {
            status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
        } else {
            status = SEARCH_WAR_OFFICER_PENDING_STATUS;
        }
    } else {
      status = SEARCH_WAR_INCOMPLETE_STATUS;
    }

    let proxy = { formStatus :status, unImpoundCase: unImpoundCase }
    let dataObj = {
      searchWarrantId: searchWarrantReducer.searchWarrantObject.searchWarrantId,
      proxy: proxy,
      navigate: navigate,
      navigateTo: `/CaseDetails`,
    };
    dispatch(saveSearchWarrant(dataObj));
  }

  const saveClick = () => {
    const { unImpoundCase, creatorId, approvalSAId, creatorUserType } = searchWarrantReducer.searchWarrantObject;
    const { userProfileId, userType } = loginReducer;
    let confirmText: any = Util.getConfirmMessage(ACTIONTYPE_SAVE, COMPL_SEARCH_WARRANT_WITH_SLASH,
      userProfileId, creatorId, "", userType, creatorUserType, approvalSAId);
    setconfirmText(confirmText);
    setconfirmOriginPlace("saveClick");
    setconfirmPopupData(true);
  }

  const handelChangeOnCheck = (event: any) => {
    dispatch(updateElementSecurityPasswordObjectValue({elementName: "protection", value: event.target.checked}));
  }

  const afterConfirmHandleSubmitClick = () => {
      if (securityPasswordObject.protection){
      setSecurityPasswordProps({complaintId: searchWarrantReducer.searchWarrantObject.searchWarrantId, caseNumber:searchWarrantReducer.searchWarrantObject.txtCaseNumber, action : "SAVE"});
      setSecurityPasswordShow(true);
    }else if (searchWarrantReducer.searchWarrantObject.protectedComplaint){
      setShowOptionPopup(true);
      setOptionObj({
        header: "",
        body: <>
          <div>Password protected complaint. Do you want to change it?</div>
        </>,
        buttons: [{ name: "Reset", variant: "primary", onClick: ()=>{removeAndResetPassword("RESET")} },
        { name: "Remove", variant: "primary", onClick: ()=>{removeAndResetPassword("REMOVE")} },
        { name: "Cancel", variant: "danger", onClick: cancel }]
      })
    } else{
      submitPasswordContinue("");
    }
  }

  const cancel = () =>{
    setShowOptionPopup(false);
    submitPasswordContinue("");
    setOptionObj({
      header: "",
      body: <></>,
      buttons: []
    })
  }

  const removeAndResetPassword = (passwordAction: string) => {
    let actionValue: boolean = false;
    if (passwordAction === "RESET") {
      actionValue = true;
    } else if (passwordAction === "REMOVE") {
      actionValue = false;
    }
    setSecurityPasswordProps({
      complaintId: caseDetailReducer.searchWarrantId,
      caseNumber: caseDetailReducer.caseNumber,
      action : passwordAction
    });

    setSecurityPasswordShow(true);
  };

  const submitClick = () => {
    const { unImpoundCase, creatorId, approvalSAId, approvalUserType, isSARequired } = searchWarrantReducer.searchWarrantObject;
    const { userProfileId, userType } = loginReducer;
    let confirmMsg = null;
    if (approvalSAId && approvalSAId > 0) {
      confirmMsg = 'Do you want to submit the form for review to judge?';
    } else {
      if (approvalUserType && approvalUserType.trim().length > 0) {
        if (ROLE_STATES_ATTORNEY === approvalUserType.trim() || ROLE_SA_ADMIN === approvalUserType.trim()) {
          if (!isSARequired) {
            confirmMsg = 'Do you want to submit the form for review to judge?';
          } else {
            confirmMsg = 'Do you want to submit the form for screening?';
          }
        } else if (ROLE_JUDGE === approvalUserType.trim()){
          confirmMsg = 'Do you want to submit the form for review to judge?';
        } else {
          confirmMsg = 'Do you want to submit the form for screening?';
        }
      } else {
        confirmMsg = 'Do you want to submit the form for review to judge?';
      }
    }

    setconfirmText(confirmMsg);
    setconfirmOriginPlace("submitClick");
    setconfirmPopupData(true);
  }

  const denyClick = () => {
    setconfirmText(COMPL_SEARCH_WARRANT_WITH_SLASH + " cannot be modified once denied. Do you want to continue?");
    setconfirmOriginPlace("denyClick");
    setconfirmPopupData(true);
  }

  const afterConfirmHandleDenyClick = () => {
    let status = SEARCH_WAR_DENIED_STATUS;
    let unImpoundCase = searchWarrantReducer.searchWarrantObject.unImpoundCase;
    let proxy = { formStatus: status, unImpoundCase: unImpoundCase }
    let navigatePath ="";
    if (loginReducer.summonsToAppear){
      navigatePath = "/WarrantsSummonsList";
    }else{
      navigatePath = "/WarrantList";
    }
    let dataObj = {
      searchWarrantId: searchWarrantReducer.searchWarrantObject.searchWarrantId,
      proxy: proxy,
      navigate: navigate,
      navigateTo: navigatePath,
    };
    dispatch(denySearchWarrant(dataObj));
  }



  const submitRemark = (remark: string, remarkFor: string) => {
    if ("RETURN" === remarkFor) {
      returnForm(remark);
    }
  }

  const returnClick = () => {
    setRemarkRequired(false);
    setRemarkFor("RETURN");
    setRemarkModalShow(true);
  }

  const returnForm = (remark: string) => {
    const { unImpoundCase, formStatus,
      creatorId, isOnBehalf, approvalSAId, creatorUserType } = searchWarrantReducer.searchWarrantObject;
    const { userProfileId, userType } = loginReducer;

    setRemark(remark);
    let status = "";
    if (ROLE_JUDGE === userType && !isOnBehalf) {
      if (ROLE_STATES_ATTORNEY === creatorUserType || ROLE_SA_ADMIN === creatorUserType) {
        status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
      } else if (ROLE_LOCAL_PROSECUTOR === creatorUserType) {
        status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
      } else if (ROLE_ATTORNEY_GENERAL === creatorUserType) {
        status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
      } else {
        status = SEARCH_WAR_OFFICER_PENDING_STATUS;
      }
    } else {
      status = SEARCH_WAR_OFFICER_PENDING_STATUS;
    }

    let confirmMsg = Util.getConfirmMessage(status, COMPL_SEARCH_WARRANT_WITH_SLASH,
      userProfileId, creatorId, formStatus, userType, creatorUserType, approvalSAId);

    setconfirmText(confirmMsg);
    setconfirmOriginPlace("returnClick");
    setconfirmPopupData(true);
  }

  const afterConfirmHandleReturnClick = () => {
    const { unImpoundCase, isOnBehalf, creatorUserType } = searchWarrantReducer.searchWarrantObject;
    const { userType } = loginReducer;
    let status = "";
    if (ROLE_JUDGE === userType && !isOnBehalf) {
      if (ROLE_STATES_ATTORNEY === creatorUserType || ROLE_SA_ADMIN === creatorUserType) {
        status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
      } else if (ROLE_LOCAL_PROSECUTOR === creatorUserType) {
        status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
      } else if (ROLE_ATTORNEY_GENERAL === creatorUserType) {
        status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
      } else {
        status = SEARCH_WAR_OFFICER_PENDING_STATUS;
      }
    } else {
      status = SEARCH_WAR_OFFICER_PENDING_STATUS;
    }
    
    let proxy = { formStatus: status, unImpoundCase: unImpoundCase, remark: remark }
    let navigatePath ="";
    if (loginReducer.summonsToAppear){
      navigatePath = "/WarrantsSummonsList";
    }else{
      navigatePath = "/WarrantList";
    }
    let dataObj = {
      searchWarrantId: searchWarrantReducer.searchWarrantObject.searchWarrantId,
      proxy: proxy,
      navigate: navigate,
      navigateTo: navigatePath,
    };
    dispatch(returnSearchWarrant(dataObj));
  }


  const approveClick = () => {
    const { unImpoundCase, formStatus, creatorId, approvalSAId,
      creatorUserType } = searchWarrantReducer.searchWarrantObject;
    const { userProfileId, userType } = loginReducer;

    let confirmMsg = Util.getConfirmMessage(SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS, COMPL_SEARCH_WARRANT_WITH_SLASH,
      userProfileId, creatorId, formStatus, userType, creatorUserType, approvalSAId);

    setconfirmText(confirmMsg);
    setconfirmOriginPlace("approveClick");
    if (approvalStatusForms(caseDetailReducer, loginReducer.userType, loginReducer.userProfileId, loginReducer.username).length > 0){
      setShowOptionPopup(true);
      setOptionObj({
        header: "",
        body: <>
          <div>There are other forms attached to this complaint/searchwarrant. Do you want to submit all of them?</div>
        </>,
        buttons: [{ name: "Submit All", variant: "primary", onClick: onSubmitAllForms },
        { name: "Submit This", variant: "primary", onClick: onSubmitOneForm },
        { name: "Cancel", variant: "danger", onClick: cancelOptionPopup }]
      })
    } else{
      setconfirmPopupData(true);
    }
   
  }

  const onSubmitAllForms = () =>{
    setFormApprovedStatus("ALL");
    cancelOptionPopup();
  }

  const onSubmitOneForm = () =>{
    setFormApprovedStatus("ONE");
    cancelOptionPopup();
  }

  const cancelOptionPopup = () =>{
    setShowOptionPopup(false);
    setconfirmPopupData(true);
  }

  const afterConfirmHandleApproveClick = () => {
    let status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
    let unImpoundCase = searchWarrantReducer.searchWarrantObject.unImpoundCase;
    let proxy = { 
      formStatus: status, 
      unImpoundCase: unImpoundCase, 
      otherForms: formApprovedStatus === "ALL"?approvalStatusForms(caseDetailReducer, loginReducer.userType, loginReducer.userProfileId, loginReducer.username):[], 
    }
    let dataObj = {
      searchWarrantId: searchWarrantReducer.searchWarrantObject.searchWarrantId,
      proxy: proxy,
      navigate: navigate,
      navigateTo: "/CaseDetails",
    };
    dispatch(approveSearchwarrant(dataObj));
  }

  const signClick = () => {
    setconfirmText("Has the officer sworn to the content of this Complaint/Search Warrant?");
    setconfirmOriginPlace("signClick");
    setconfirmPopupData(true);
  }

  const afterConfirmHandleSignClick = () => {
    let dataObj = {
      searchWarrantId: searchWarrantReducer.searchWarrantObject.searchWarrantId,
      callFrom: "SubmitPage",
      searchWarrantObject: searchWarrantReducer.searchWarrantObject,
      navigate: navigate,
      navigateTo: "/CaseDetails",
    };
    dispatch(signSearchwarrant(dataObj));
  }

  const previewClick = () => {
    dispatch(
      previewOrder({
        formId:searchWarrantReducer.searchWarrantObject.searchWarrantId,
        formName: COMPL_SEARCH_WARRANT,
        formObject: {},
      })
    );
  }
  
  const submitPassword = (password: string, isPasswordReset: boolean) =>{
      submitPasswordContinue(password);
      setSecurityPasswordShow(false);
  }
  const submitPasswordContinue = (password: string) => {
    try{
      let status = "";
      let approvalSAId = searchWarrantReducer.searchWarrantObject.approvalSAId;
      let approvalUserType = searchWarrantReducer.searchWarrantObject.approvalUserType;
      let unImpoundCase = searchWarrantReducer.searchWarrantObject.unImpoundCase;
      let isSARequired = searchWarrantReducer.searchWarrantObject.isSARequired;

      if (approvalSAId && approvalSAId > 0) {
        status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
      } else {
        if (approvalUserType && approvalUserType.trim().length > 0) {
          if (!isSARequired && ROLE_STATES_ATTORNEY === approvalUserType.trim()) {
            status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
          } else {
            if (ROLE_STATES_ATTORNEY === approvalUserType.trim()) {
              status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
            } else if (ROLE_LOCAL_PROSECUTOR === approvalUserType.trim()) {
              status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
            } else if (ROLE_JUDGE === approvalUserType.trim()) {
              status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
            } else {
              status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
            }
          }
        } else {
          status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
        }
      }

      let proxy = { formStatus: status, unImpoundCase: unImpoundCase, password: password }
      let dataObj = {
        searchWarrantId: searchWarrantReducer.searchWarrantObject.searchWarrantId,
        proxy: proxy,
        navigate: navigate,
        navigateTo: `/CaseDetails`,
      };
      dispatch(submitSearchWarrant(dataObj))
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }

  const cancelSecurityPasswordPopup = () =>{
    setSecurityPasswordShow(false);
    submitPasswordContinue(""); 
  }

  return (
    <>
      <Header headerName="Search Warrant Submit" />
      <ErrorMessageAlert
        messages={errorMessageAlert.messages}
        clearError={errorMessageAlert.clearError}
      />
      <div className="container-center">
        <Row>
          <Col>
            <Card className="shadow">
              <Card.Body className="p-4">
                <Row>
                  <Col>
                    <h5>
                      Case Number :{" "}
                      <span className="fw-bold">{
                        searchWarrantReducer.searchWarrantObject.txtCaseNumber
                      }</span>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>
                      Complaint Name :{" "}
                      <span className="fw-bold">{
                        searchWarrantReducer.searchWarrantObject.txtAppears
                      }</span>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>
                      Issuing Agency : <span className="fw-bold"> {
                        searchWarrantReducer.searchWarrantObject.agencyName
                      }</span>
                    </h5>
                  </Col>
                </Row>
                {(caseDetailReducer.creatorId === loginReducer.userProfileId && !searchWarrantReducer.searchWarrantObject.protectedComplaint) ? 
                 <OverlayTrigger
                 placement="right"
                 trigger="hover"
                 overlay={(
                   <Tooltip id="tooltip-disabled">By setting a password, you are limiting other users (ASAs, Judges) from accessing this complaint without this password.<br></br> Only use this option, when you want to have a specific ASA or a Judge to review this petition.</Tooltip>
                 )
                 }
               >
                  <Row>
                    <Col>
                        <CheckBoxComponent
                          type="checkbox"
                          label="Secure this complaint by setting a password"
                          onChange={handelChangeOnCheck}
                          checked={searchWarrantReducer.searchWarrantObject.protectedComplaint || securityPasswordObject.protection}
                        />
                    </Col>
                  </Row>
                  </OverlayTrigger>
                :""}
                {unImpoundCaseVisible ?
                  <Row className="mt-2 align-items-center justify-content-start no-gutters">
                    <Col xs={12} className="text-left">
                      <label style={{ marginBottom: "0px" }} className="pr-3">
                        <b>Case is currently impounded. Do you want to UN-impound it?</b>
                      </label>&nbsp;&nbsp;&nbsp;
                      <Form.Check inline type="radio"
                        checked={searchWarrantReducer.searchWarrantObject.unImpoundCase}
                        name="unImpoundCase" onChange={handleYesRadioChange} />
                      <label className="ml-n2 pr-4">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                      <Form.Check inline type="radio"
                        checked={!searchWarrantReducer.searchWarrantObject.unImpoundCase}
                        name="unImpoundCase" onChange={handleNoRadioChange} />
                      <label className="ml-n2">No</label>
                    </Col>
                  </Row>
                  : ""}
                <Row className="mt-2 align-items-center justify-content-center no-gutters">
                  <Col xs={12} className="text-center">
                    { btnSubmitVisible ?
                      <ButtonComponent variant="success" className="" onClick={() => submitClick()}>
                        { (btnSubmitLabel === "Submit to Judge") ? "Submit to Judge " : "Submit for Screening " }
                        <RiSendPlane2Fill />
                      </ButtonComponent>
                      : ""}
                    {" "}
                    { btnDeniedVisible ?
                      <ButtonComponent variant="danger" className="" onClick={() => denyClick()}>Deny <RiCloseCircleFill /></ButtonComponent>
                      : ""}
                    {" "}
                    { btnRevertVisible ?
                      <ButtonComponent variant="danger" className="" onClick={() => returnClick()}>
                        { btnRevertLabel } <RiArrowGoBackFill /></ButtonComponent>
                      : ""}

                    {" "}
                    { btnApproveVisible ?
                      <ButtonComponent variant="success" className="" onClick={() => approveClick()}>Approve <RiCheckboxMultipleFill /></ButtonComponent>
                      : ""}
                    {" "}
                    { btnSaveVisible ?
                      <ButtonComponent className="" onClick={() => saveClick()}>Save <RiSave3Line /></ButtonComponent>
                      : ""}
                    {" "}
                    { btnSignVisible ?
                      <ButtonComponent variant="success" className="" onClick={() => signClick()}>Sign <RiBallPenLine /></ButtonComponent>
                      : ""}
                    {" "}
                    <ButtonComponent className="" onClick={() => previewClick()}>Preview <RiFileTextLine /></ButtonComponent>
                    {" "}
                    <ButtonComponent className="" onClick={() => backClick()}>Back <RiArrowLeftLine/></ButtonComponent>

                  </Col>
                </Row>

                <Row className="pt-3">
                  <Col>
                    { btnSubmitVisible ?
                      <div className="text-start  mt-2">
                        <label>
                          <RiArrowRightSFill />
                          {((btnSubmitLabel === "Submit to Judge") ? "Submit to Judge" : "Submit for Screening") + " button submits Search Warrant for review."}
                        </label>
                      </div>
                      : ""}

                    { btnDeniedVisible ?
                      <div className="text-start  mt-2">
                        <label>
                          <RiArrowRightSFill />
                          Deny button denies Search Warrant.
                        </label>
                      </div>
                      : ""}


                    { btnRevertVisible ?
                      <div className="text-start  mt-2">
                        <label>
                          <RiArrowRightSFill />{((btnRevertLabel === "Return to LP") ? "Return to LP"
                            : (btnRevertLabel === "Return to AG") ? "Return to AG"
                              : (btnRevertLabel === "Return to SA") ? "Return to SA" : "Return To Officer")
                            + ' button returns Search Warrant to '
                            + ((btnRevertLabel === "Return to LP") ? "LP"
                              : (btnRevertLabel === "Return to AG") ? "AG"
                                : (btnRevertLabel === "Return to SA") ? "SA" : "officer")
                            + ' with status '
                            + ((btnRevertLabel === "Return to LP") ? '"Assigned to LP"'
                              : (btnRevertLabel === "Return to AG") ? '"Assigned to AG"'
                                : (btnRevertLabel === "Return to SA") ? '"Assigned to SA"' : '"Officer pending"')
                            + '.'}
                        </label>
                      </div>
                      : ""}


                    { btnApproveVisible ?
                      <div className="text-start  mt-2">
                        <label>
                          <RiArrowRightSFill />
                          Approve button approves Search Warrant.
                        </label>
                      </div>
                      : ""}

                    { btnSaveVisible && searchWarrantReducer.searchWarrantObject.creatorId === loginReducer.userProfileId ?
                      <div className="text-start mt-2">
                        <label>
                          <RiArrowRightSFill />
                          Save button saves Search Warrant data with status
                          "Officer Pending".
                        </label>
                      </div>
                      : ""}

                    { btnSignVisible ?
                      <div className="text-start mt-2">
                        <label>
                          <RiArrowRightSFill />
                          Sign button imprints judge signature on Search Warrant.
                        </label>
                      </div>
                      : ""}

                    <div className="text-start mt-2">
                      <label>
                        <RiArrowRightSFill />
                        Preview button opens Search Warrant in PDF.
                      </label>
                    </div>

                    <div className="text-start mt-2">
                      <label>
                        <RiArrowRightSFill />
                        Back button will take you on complaint form for this
                        search warrant.
                      </label>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <RemarkPopup show={remarkModalShow} onHide={() => setRemarkModalShow(false)}
        submitRemark={submitRemark} remarkFor={remarkFor}
        action={action} remarkRequired={remarkRequired}
        onlyView={false} remarksForView={""} />
      <ConfirmPopup
        children={confirmText}
        show={confirmPopupData}
        onHide={() => setconfirmPopupData(false)}
        isConfirmation={handleConfirmation}
      />
      <SecurityPassword
        show ={securityPasswordShow}
        onHide={cancelSecurityPasswordPopup}
        securityProps={securityPasswordProps}
        submitPassword = {submitPassword}
        accessToComplaint= {()=>{}}
      />
      <OptionPopup
        show={showOptionPopup}
        header={optionObj.header}
        onHideFunc={() => setShowOptionPopup(false)}
        children={optionObj.body}
        buttons={optionObj.buttons}
      />
    </>
  );
};

export default SearchWarrantSubmit;
function aync(password: any, string: any) {
  throw new Error("Function not implemented.");
}

