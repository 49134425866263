import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../hooks/hooks';
import { updateElementValueInLoginReducer, useLoginReducer, usernameReset } from '../../redux/slice/loginSlice';
import ButtonComponent from '../common/ButtonComponent';
import FormComponent from '../common/FormComponent';
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import * as yup from "yup";


interface ChangeUsername {
    show: boolean;
    onHide: () => void;
}

export const ChangeUsername: React.FC<ChangeUsername> = (props) => {
    let regExp = /^[0-9\b]+$/;

    let defaultUserSchema = yup.object().shape({
      username: yup.string().required("User Name is required."),
      newusername: yup.string().trim().required("New User Name is required."),
    });

    const {show, onHide} = props;
    const dispatch = useAppDispatch();
    const errorMessageAlert = useAlert();
    const [errorKeys, setErrorKeys] = React.useState([""]);
    const { username,incorrectCredentialError, status } = useLoginReducer();
    const[newUsername, setNewUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const validateUserName = (username:any) => {
      return /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(username);
    }
let ChangeUsernameSchema = yup.object().shape({
    password: yup
    .string()
    .required("Password  is required."),
  newUsername: yup
    .string()
    .trim()
    .required("New User Name is required.")
    .test(
      "is-email",
      "Email address is required for new user name",
      (val) => {
        return validateUserName(val);
      }
      )
  });

  useEffect(()=>{
    return ()=>{
      dispatch(
        updateElementValueInLoginReducer({
          elementName: "incorrectCredentialError",
          value: "",
        })
      );
    }
  },[]);

  useEffect(() => {
    errorMessageAlert.clearError();
  },[show])

  const saveClick = () => {
    errorMessageAlert.clearError();
    dispatch(
      updateElementValueInLoginReducer({
        elementName: "incorrectCredentialError",
        value: "",
      })
    );
     try {
      ChangeUsernameSchema.validateSync({ newUsername, password });
      

      let newUsernameobj = {
        username: username,
        newUsername: newUsername,
        password: password,
      } 
      const result = dispatch(usernameReset(newUsernameobj))
      console.log(result)
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }

    const handleError = useCallback(
      (_errorKeys: string[], errorMessages: string[]) => {
        setErrorKeys(_errorKeys);
        errorMessageAlert.handleError(errorMessages);
        window.scroll({ top: 0, behavior: "smooth" });
      },
      []
    );
    
    const handleInputChange = (e: any) => {
      
        if( e.target.name === "newUsername") {
          setNewUsername(e.target.value);
        } else if(e.target.name === "password") {
          setPassword(e.target.value)
        }
    }
  
   const onhidingchangepopup = () => {
    setNewUsername("");
    dispatch(
      updateElementValueInLoginReducer({
        elementName: "incorrectCredentialError",
        value: "",
      })
    );
    
    onHide();
   }

    const data = [

        {
          type: "textInput",
          props: {
            labelName: "User Name",
            name: "Current User Name",
            value: username,
            isRequired : true,
            onChange: handleInputChange,
            isError: errorKeys.includes("username"),
            disabled : true,
          },

          colSize: 12,
        },
        {
          type: "textInput",
          props: {
            type: "text",
            name: "newUsername",
            labelName: "New User Name",
            value : newUsername,
            isRequired : true,
            onChange : handleInputChange,
            isError: errorKeys.includes("newUsername"),
            autoComplete: "nope",
          },
          colSize: 12,
      },
        {
            type: "textInput",
            props: {
              type: "password",
              name: "password",
              labelName: "Password",
              value : password,
              isRequired : true,
              onChange : handleInputChange
            },
            colSize: 12,
        },
    ]

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered onHide={props.onHide}
      backdrop='static'
      scrollable={true} >
      {errorMessageAlert.messages.length > 0 ? <div className='p-3'>
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
      </div> : ""}
      <Modal.Header><b>Change Username</b></Modal.Header>
      <Modal.Body>
        <label className={incorrectCredentialError === "success" ? "text-success" : "text-danger"}>
          {incorrectCredentialError === "success" ? "The username has been updated to " + username : incorrectCredentialError}
        </label>
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data} />
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent onClick={() => saveClick()}>Save</ButtonComponent>
        <ButtonComponent variant='danger' onClick={() => onhidingchangepopup()}>Close</ButtonComponent>
      </Modal.Footer>
    </Modal>
  );
}