import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";

interface UserManualState {
  downloadFileStatus: LoadingType;
  downloadFileError?: string;
}
const initialState = {
  downloadFileStatus: "idle",
} as UserManualState;

export const downloadUserManualById = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: string }
>(
  "userManual/downloadUserManual",
  async (id, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: `usermanualservices/downloadUserManual/${id}`,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const contentDisposition = response.headers.get("Content-Disposition");
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1]
          : "userManual";
        console.log(response.headers.get("Content-Disposition"));
        await response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName + ".pdf";
          a.click();
          return {
            message: "Download User manual successfully",
          };
        });
      } else {

        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while downloading user manual");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while downloading user manual"
      );
    }
  }
);

const userManualSlice = createSlice({
  name: "userManual",
  initialState,
  reducers: {
    updateElementValueUserManualReducer: updateElementValue,
  },
  extraReducers(builder) {
    builder
      .addCase(downloadUserManualById.pending, (state) => {
        state.downloadFileStatus = "loading";
        state.downloadFileError = undefined;
      })
      .addCase(downloadUserManualById.fulfilled, (state) => {
        state.downloadFileStatus = "success";
      })
      .addCase(downloadUserManualById.rejected, (state, action) => {
        state.downloadFileStatus = "error";
        state.downloadFileError = action.payload;
      });
  },
});

export const { updateElementValueUserManualReducer } = userManualSlice.actions;
export const useUserManualReducer = () =>
  useAppSelector((state) => state.userManual);
export default userManualSlice.reducer;
