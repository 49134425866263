import { doFetch } from "../utils/doFetch";

export const getPoliceOfficers = async ({
    token,
}: {
    token?: string;
}) => {
    const response = await doFetch({
        url: "userprofileservices/getPoliceOfficerList",
        token,
    });
    return response;
};

//KHL PR 11294 13/02/24 START
export const getPoliceOfficersByAgencyId = async ({
    agencyId,
    token,
}: {
    agencyId: number;
    token?: string;
}) => {
    const response = await doFetch({
        url: `userprofileservices/getOfficersByAgency/${agencyId}`,
        token,
    });
    return response;
};
//KHL PR 11294 13/02/24 END

//KHL PR 11294 15/02/24 START
export const getPoliceOfficersByCountyIds = async ({
    countyIds,
    token,
}: {
    countyIds: any;
    token?: string;
}) => {
    const response = await doFetch({
        url: `userprofileservices/getOfficersByCounty/${countyIds}`,
        token,
    });
    return response;
};
//KHL PR 11294 15/02/24 END