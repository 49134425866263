import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif, updateDocGenerateError } from "./commonSlice";
import { getEcomplaintMainDetails, updateElementValueComplaintMainReducer } from "./complaintMainSlice";
import { FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE } from "../../constants/common";
import { updateElementObject } from "../../utils/updateElementObject";
import { getAllEcCounties } from "../../services/EComplaintService";
import { getCodeTableEntries } from "../../services/CodeTableEntryService";
import { getLFCConcurrentObj } from "./commonFormSlice";

interface countFormObject {
  kountId: number,
  caseNumber: string,
  originalEcViolation: object,
  prosecution: string,
  violationkey: string,
  offenseCode: string,
  violationCode: string,
  violationDescription: string,
  classOfViolation: string,
  caseType: string,
  citationNumber: string,
  editedViolation: boolean,
  originalViolationId: number,
  originalViolationDesc: string,
  originalCaseType: string,
  inchoate: string,
  chargeNarrative: string,
  offenceCounty: string,
  offenceState: string,
  offenseOccurred: string,
  offenceOccurredOn: boolean,
  offenceOccurredFrom: boolean,
  offenceDate: string,
  offenceOccTime: string,
  offenceFromDate: string,
  offenceThroughDate: string,
  incidentNo: string,
  complaintTicketNumber: string,
  textBlock1: string,
  textBlock2: '',
  countFrom: string,
  uploadedFileName: string,
  hiddenUploadPdf: string,
  formId: string,

  effectiveDate: string,
  inactiveDate: string,
  saApprovalStatus: string,
}

interface countFormState {

  countFormObject: countFormObject,
  ecCountyList: [];
  fugitiveList: [];
  countSavingStatus: LoadingType,
  countSavingError: string,

  countFetchStatus: LoadingType,
  countFetchError: string,

  violationDetailsStatus: LoadingType
  fetchingError?: string
  isAddCount:boolean
}

const initialState = {
  countFormObject: {
    kountId: 0,
    caseNumber: '',
    originalEcViolation: {},
    prosecution: '',
    violationkey: '',
    offenseCode: '',
    violationCode: '',
    violationDescription: '',
    classOfViolation: '',
    caseType: '',
    citationNumber: '',
    editedViolation: false,
    originalViolationId: 0,
    originalViolationDesc: '',
    originalCaseType: '',
    inchoate: '',
    chargeNarrative: '',
    offenceCounty: '',
    offenceState: '',
    offenseOccurred: '',
    offenceOccurredOn: false,
    offenceOccurredFrom: false,
    offenceDate: '',
    offenceOccTime: '',
    offenceFromDate: '',
    offenceThroughDate: '',
    incidentNo: '',
    complaintTicketNumber: '',
    textBlock1: '',
    textBlock2: '',
    countFrom: '',
    uploadedFileName: '',
    hiddenUploadPdf: "",
    formId: "",

    effectiveDate: '',
    inactiveDate: '',
    saApprovalStatus: "",
  },
  ecCountyList: [],
  fugitiveList: [],
  countSavingStatus: "idle",
  countSavingError: '',

  countFetchStatus: "idle",
  countFetchError: '',
  violationDetailsStatus: "idle",
  fetchingError: '',
  isAddCount: false

} as countFormState;

export const saveCount = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    countDetails: object[];
  },
  { state: RootState; rejectValue: string }
>("countForm/saveCountByCountId",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, countDetails } = data;
    let { token } = getState().login;
    token = `Bearer ${token}`;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response: any = await doFetch({
        url: "ecomplaintservices/saveCount/" + ecomplaintId + `?jwtToken=${token}`,
        type: 'POST',
        body: countDetails,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        const data = await response.json();
        dispatch(showHideLoadingGif(false));
        if (data && data.errorInfo && data.errorInfo.error && data.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: data.errorInfo.validationErrors,
            })
          );
          return "ErrorFound";
        } else {
          dispatch(updateElementValueComplaintMainReducer({ elementName: 'countList', value: data }))
          dispatch(
            getEcomplaintMainDetails({
              ecomplaintId: ecomplaintId,
              callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
            })
          );
          return data;
        }
      }
      return rejectWithValue("Error while fetching saveCount");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      console.log('error');
      return rejectWithValue("Error while fetching save count");
    }

  }
);

export const deleteCountDetails = createAsyncThunk<
  any,
  {
    ecomplaintId: number,
    countId: number,
  },
  { state: RootState; rejectValue: string }
>("countForm/deleteCount",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, countId } = data;
    let { token } = getState().login;
    token = `Bearer ${token}`;
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response: any = await doFetch({
        url: "ecomplaintservices/deleteCount/" + countId + `?jwtToken=${token}`,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        const data = await response.json();
        dispatch(updateElementValueComplaintMainReducer({ elementName: 'countList', value: data }))
        dispatch(
          getEcomplaintMainDetails({
            ecomplaintId: ecomplaintId,
            callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
          })
        );

        return data
      }
      return rejectWithValue("Error while fetching deleteCountDetails");
    } catch (error) {
      return rejectWithValue("Error while fetching delete count");
      console.log('error');
    }

  }
)

export const updateCountStatus = createAsyncThunk<
  any,
  {
    ecomplaintId: number,
    countId: number,
    status: string,
  },
  { state: RootState; rejectValue: string }
>("countForm/deleteCount",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, countId, status } = data;
    let { token } = getState().login;
    token = `Bearer ${token}`;
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response: any = await doFetch({
        url: "ecomplaintservices/updateCountStatus/" + ecomplaintId + "/" + countId + "/" + status + `?jwtToken=${token}`,
        type: 'POST',
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        const data = await response.json();
        dispatch(updateElementValueComplaintMainReducer({ elementName: 'countList', value: data }));
        dispatch(
          getEcomplaintMainDetails({
            ecomplaintId: ecomplaintId,
            callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
          })
        );
        return data
      }
      return rejectWithValue("Error while fetching rejectedCountDetails");
    } catch (error) {
      return rejectWithValue("Error while fetching reject count");
      console.log('error');
    }

  }
)

export const getAllEcCounty = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/getEcCounties",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await getAllEcCounties({ token });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching E Complaint Data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching E Complaint Data"
      );
    }

  }
);

export const getFugitiveList = createAsyncThunk<
  any, { tableName: string },
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/getFugitive",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    const { tableName } = data;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await getCodeTableEntries({ tableName, token });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching E Complaint Data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching E Complaint Data"
      );
    }

  }
);




export const countFormSlice = createSlice({
  name: "countForm",
  initialState,
  reducers: {
    updateElementValueCountFormReducer: updateElementValue,
    updateElementObjectValueCountFormObjectReducer: (state: countFormState, action) => {
      let elementObj = state.countFormObject;
      updateElementObject(state, action, elementObj);
    },
    updateCountFormObject: (state: countFormState, action) => {
      state.countFormObject = action.payload;
    },
    resetCountFormData: (state: countFormState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(saveCount.pending, (state) => {
        state.countSavingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(saveCount.fulfilled, (state, action) => {
        state.countSavingStatus = "success";
      })
      .addCase(saveCount.rejected, (state) => {
        state.countSavingStatus = "error";
        state.fetchingError = undefined;
      })
      .addCase(deleteCountDetails.pending, (state) => {
        state.countSavingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(deleteCountDetails.fulfilled, (state, action) => {
        state.countSavingStatus = "success";
      })
      .addCase(deleteCountDetails.rejected, (state) => {
        state.countSavingStatus = "error";
        state.fetchingError = undefined;
      })
      .addCase(getAllEcCounty.pending, (state) => {
        state.countSavingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(getAllEcCounty.fulfilled, (state, action) => {
        state.countSavingStatus = "success";
        state.ecCountyList = action.payload;
      })
      .addCase(getAllEcCounty.rejected, (state) => {
        state.countSavingStatus = "error";
        state.fetchingError = undefined;
      })
      .addCase(getFugitiveList.pending, (state) => {
        state.countSavingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(getFugitiveList.fulfilled, (state, action) => {
        state.countSavingStatus = "success";
        state.fugitiveList = action.payload;
      })
      .addCase(getFugitiveList.rejected, (state) => {
        state.countSavingStatus = "error";
        state.fetchingError = undefined;
      })
  }
})

export const { updateElementValueCountFormReducer, updateElementObjectValueCountFormObjectReducer, updateCountFormObject, resetCountFormData } = countFormSlice.actions;
export const useCountFormReducer = () =>
  useAppSelector((state) => state.countForm);
export default countFormSlice.reducer;