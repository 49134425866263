import moment from "moment";
import { Exception } from "sass";
import { number } from "yup";
import {
  FIRST_NAMES,
  MIDDLE_INITIAL_LETTERS,
  FIRST_INITIAL_LETTERS,
  POLICE_DEPT,
  POLICE_OFFICER_NAME,
  TODAY_DATE,
  BADGENO,
  OFFICER_TITLE_AND_NAME,
  INCHO_TYPE_ATTEMPT_DESC,
  INCHO_TYPE_CONSPIRACY_DESC,
  INCHO_TYPE_SOLICIT_DESC,
  INCHO_TYPE_DRUG_DESC,
  INCHO_TYPE_ATTEMPT,
  INCHO_TYPE_CONSPIRACY,
  INCHO_TYPE_SOLICIT,
  INCHO_TYPE_DRUG,
} from "../constants/common";

export const FEMALE = 1;

export const merge = (...schemas: any[]) => {
  const [first, ...rest] = schemas;

  return rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  );
};

export const extractFileName = (disposition:any) => {
  if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) { 
        return matches[1].replace(/['"]/g, '');
      }
  }
  return "untitled"
}

export const formattedZipcode = (zipcode: string) => {
  let zip = zipcode;
  zip = zip.replaceAll("-", "");
  if (zip.length > 5) {
    if (zip.length < 9) {
      zip = zip.substring(0, 5) + "-" + zip.substring(5, zip.length);
    } else {
      zip = zip.substring(0, 5) + "-" + zip.substring(5, 9);
    }
    return zip;
  } else {
    return zip;
  }
};

export const formatName = (name1: string, name2: string, name3: string) => {
  let name = "";
  if (name1 && name1.trim().length > 0) {
    name = name + name1.trim() + " ";
  }
  if (name2 && name2.trim().length > 0) {
    name = name + name2.trim() + " ";
  }
  if (name3 && name3.trim().length > 0) {
    name = name + name3.trim();
  }
  return name.trim();
};

export const formatNameWithSpaces = (
  namePart1: string,
  namePart2: string,
  namePart3: string,
  namePart4: string
) => {
  let name: string = "";
  if (namePart1 && namePart1.trim().length > 0) {
    name = name + namePart1.trim() + " ";
  }
  if (namePart2 && namePart2.trim().length > 0) {
    name = name + namePart2.trim() + " ";
  }
  if (namePart3 && namePart3.trim().length > 0) {
    name = name + namePart3.trim() + " ";
  }
  if (namePart4 && namePart4.trim().length > 0) {
    name = name + namePart4.trim();
  }
  return name.trim();
};

export const formatNameWithSuffix = (
  namePart1: string,
  namePart2: string,
  namePart3: string,
  suffix: string
) => {
  let name = "";
  if (namePart1 && namePart1.trim().length > 0) {
    name = name + namePart1.trim() + " ";
  }
  if (namePart2 && namePart2.trim().length > 0) {
    name = name + namePart2.trim() + " ";
  }
  if (namePart3 && namePart3.trim().length > 0) {
    name = name + namePart3.trim() + " ";
  }
  if (suffix && suffix.trim().length > 0) {
    name = name + suffix.trim();
  }
  return name.trim();
};

export const formatCurrency = (value: any) => {
  if (!value || isNaN(value)) {
    return "";
  }
    var formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2, 
    });
    value = formatter.format(value);
    value = value.replaceAll("\\$", "");
    value = value.replaceAll(",", "");

    return value;
}

export const formatAddress = (
  addressLine1: string,
  addressLine2: string,
  city: string,
  state: string,
  zip: string,
  country: string
) => {
  let address: string = "";
  if (addressLine1 && addressLine1.trim().length > 0) {
    address = address + addressLine1.trim() + ", ";
  }
  if (addressLine2 && addressLine2.trim().length > 0) {
    address = address + addressLine2.trim() + ", ";
  }
  if (city && city.trim().length > 0) {
    address = address + city.trim() + ", ";
  }
  if (state && state.trim().length > 0) {
    address = address + state.trim() + ", ";
  }
  if (zip && zip.trim().length > 0) {
    address = address + zip.trim() + ", ";
  }
  if (country && country.trim().length > 0) {
    address = address + country.trim() + ", ";
  }
  if (address.trim().lastIndexOf(", ") !== -1) {
    address = address.substring(0, address.lastIndexOf(", "));
  }
  return address.trim();
};

export const formatTextForCkEditor = (replaceString: string) => {

  return singleStringReplace(
    singleStringReplace(replaceString, "\n", "</p><p>").trim(),
    "<p>&nbsp;</p>",
    ""
  ) 

};

export const setTemplateDataToFields = (replaceString: string, agencyName: string, badgeNo: string, name:string) => {

  let text = replaceString;
  let date = moment().format("MM/DD/yyyy");
  if (agencyName && agencyName.trim().length > 0){
    text = singleStringReplace(text,POLICE_DEPT,agencyName);
  }
  if (badgeNo && badgeNo.trim().length > 0){
    text = singleStringReplace(text,BADGENO,badgeNo);
  }
  if (name && name.trim().length > 0){
    text = singleStringReplace(singleStringReplace(text,POLICE_OFFICER_NAME,name),OFFICER_TITLE_AND_NAME,name);
  }
  text = singleStringReplace(text, TODAY_DATE, date);
  return text;
}

export const updateTagValue = (replaceString: string, replace: string) =>{

  let text = replaceString.split(" ");
  text.forEach((element, index) =>{
    if (element == "Badge:"){
      text[index+1] = replace;
    }
  })
  return text.join(" ");
}

export const singleStringReplace = (
  replaceString: string,
  find: string,
  replase: string
) =>
  replaceString && replaceString !== null && replaceString !== ""
    ? replaceString.replaceAll(find, replase)
    : "";

export const getTextColorByStatus = (status: string) => {
  if (status === "Active" || status === "Executed" || status === "Complete") {
    return "text-success";
  } else if (status === "Incomplete" || status === "Denied") {
    return "text-danger";
  }else if (status === "Void") {
    return "text-secondary";
  } else {
    return "text-info";
  }
};

export const mapToSelectOptions = (
  data: any[],
  label: string,
  value: string,
  extra?: {
    obj: any;
    count: number;
  }
) => {
  let options: any[] = [];
  if (extra && extra.obj && extra.count > 0 && extra.count < data.length) {
    options.push({
      label: extra.obj["label"],
      value: extra.obj["value"],
    });
  }
  if (data && data.length > 0) {
    data.forEach((item) => {
      options.push({
        label: item[label],
        value: item[value],
      });
    });
  }
  return options;
  // return data instanceof Array
  //   ? data.map((item) => ({
  //       value: item[value],
  //       label: item[label],
  //     }))
  //   : [];
};

export const arrInsertStart = (values: any[], newValue: any) => {
  if (values.length > 1) {
    return [newValue, ...values];
  }
  return values;
};

export const validateIncidentNoForAgency = (
  incidentNumberFormat: string,
  incidentNumberValue: string
) => {
  let regExp = new RegExp("^\\d+$");
  if (!incidentNumberFormat) {
    return true;
  }
  incidentNumberFormat = incidentNumberFormat.trim();
  if (incidentNumberFormat.length !== incidentNumberValue.length) {
    return false;
  }
  let consPartFormat: string = getIncidentNoByAgencyFormat(
    incidentNumberFormat
  ).replaceAll("#", "");
  let consPartFormatForLastYear: string =
    getIncidentNoByAgencyFormatForLastYear(incidentNumberFormat).replaceAll(
      "#",
      ""
    );

  if (consPartFormat.length > 0 || consPartFormatForLastYear.length > 0) {
    let hashPoints = getHashPoints(incidentNumberFormat);
    if (hashPoints.length >= 1) {
      let sb: string = "";
      let partNumber: string = "";
      let beginIndex: number = 0;
      for (let j: number = 0; j < hashPoints.length; j++) {
        try {
          if (incidentNumberValue.length < hashPoints[j][1] + 1) {
            return false;
          }
          partNumber = incidentNumberValue.substring(
            hashPoints[j][0],
            hashPoints[j][1] + 1
          );
          for (
            let i: number = beginIndex;
            i < incidentNumberValue.length;
            i++
          ) {
            if (i >= hashPoints[j][0] && i <= hashPoints[j][1]) {
              if (i === hashPoints[j][1]) {
                beginIndex = hashPoints[j][1] + 1;
                break;
              } else {
                continue;
              }
            } else {
              if (!incidentNumberValue.charAt(i)) {
                return false;
              }
              sb = sb + incidentNumberValue.charAt(i);
            }
          }
        } catch (err: any) {
          return false;
        }
        regExp = new RegExp(
          "^(\\d{" + (hashPoints[j][1] + 1 - hashPoints[j][0]) + "})$"
        );
        if (!regExp.test(partNumber)) {
          return false;
        }
      }
      if (consPartFormat === sb || consPartFormatForLastYear === sb) {
        return true;
      }
    } else {
      if (
        consPartFormat === incidentNumberValue ||
        consPartFormatForLastYear === incidentNumberValue
      ) {
        return true;
      }
    }
  } else {
    regExp = new RegExp("^(\\d{" + incidentNumberFormat.length + "})$");
    if (regExp.test(incidentNumberValue)) {
      return true;
    }
  }
  return false;
};

export const getIncidentNoByAgencyFormat = (incidentNumberFormat: string) => {
  let formattedNo: string = "";
  if (incidentNumberFormat) {
    if (incidentNumberFormat.includes("yyyy")) {
      let year: string = moment(new Date()).format("YYYY");
      formattedNo = incidentNumberFormat.replace("yyyy", year);
    } else if (incidentNumberFormat.includes("yy")) {
      let yearDigits: string = moment(new Date()).format("YY");
      formattedNo = incidentNumberFormat.replace("yy", yearDigits);
    } else {
      formattedNo = incidentNumberFormat;
    }
  }
  return formattedNo;
};

const getIncidentNoByAgencyFormatForLastYear = (
  incidentNumberFormat: string
) => {
  let formattedNo: string = "";
  if (incidentNumberFormat) {
    if (incidentNumberFormat.includes("yyyy")) {
      let year: string = moment(new Date()).format("YYYY");
      year = "" + (parseInt(year) - 1);
      formattedNo = incidentNumberFormat.replace("yyyy", year);
    } else if (incidentNumberFormat.includes("yy")) {
      let yearDigits: string = moment(new Date()).format("YY");
      if (yearDigits !== "00") {
        yearDigits = "" + (parseInt(yearDigits) - 1);
        if (yearDigits.length < 2) {
          yearDigits = "0" + yearDigits;
        }
      } else {
        yearDigits = "99";
      }
      formattedNo = incidentNumberFormat.replace("yy", yearDigits);
    } else {
      formattedNo = incidentNumberFormat;
    }
  }
  return formattedNo;
};

const getHashPoints = (str: string) => {
  let hashPoints: any = [];
  let c: string = "#";
  let foundOne: boolean = false;
  let points: number[] = [];
  for (let i: number = 0; i < str.length; i++) {
    if (str.charAt(i) === c) {
      if (!foundOne) {
        points = [];
        points[0] = i;
      }
      foundOne = true;
      if (str.length === i + 1) {
        points[1] = i;
        hashPoints.push(points);
      }
    } else {
      if (foundOne) {
        foundOne = false;
        points[1] = i - 1;
        hashPoints.push(points);
      }
    }
  }
  return hashPoints;
};

export const getMonth = (date:any) => {
  if(date !== null) return date.getMonth()+1;;
  return 0;
}

export const validateDLNumber = (
  dl: string,
  fName: string,
  mName: string,
  lName: string,
  dateOfBirth: string,
  sex: string
) => {
    let dob: any = dateOfBirth
    ? moment(dateOfBirth).toDate()
    : undefined;

    if (!dl || !dob || !sex) return false;

  if (!mName || mName.trim().length === 0) mName = " ";

  dl = dl ? dl.trim() : "";
  sex = sex ? sex.trim() : "";

  let type: number = 0;
  if (sex && sex === "FEMALE") type = 1;
  else if (sex && sex === "MALE") type = 2;
  else if (sex && sex === "X"){
    if(getMonth(dob) < 7) type = 2;
    else type = 1;
  }

  


  let lastName: string = lName != null ? lName.trim().toUpperCase() : "";
  let firstName: string = fName != null ? fName.trim().toUpperCase() : "";
  let middleName: string = mName != null ? mName.trim().toUpperCase() : "";

  let dlNumber: string = "";

  let segment1: string = getFirstSegment(lastName);
  let segment2: string = getMiddleSegment(firstName, middleName);
  let segment3: string = moment(dob).format("YY");
  let segment4: string = getLastThree(dob, type);
  dlNumber = segment1 + segment2 + segment3 + segment4;

  if (dl === dlNumber) return true;
  else return false;
};

const getFirstSegment = (lastName: string) => {
  return soundex(lastName);
};

const getMiddleSegment = (firstName: string, middleName: string) => {
  let middleInitial: string =
    middleName && middleName.trim().length > 0
      ? middleName.substring(0, 1)
      : " ";
  let num1: number = lookup(FIRST_NAMES, firstName);
  let num2: number = lookup(MIDDLE_INITIAL_LETTERS, middleInitial);
  if (num1 === 0)
    num1 = lookup(FIRST_INITIAL_LETTERS, firstName.substring(0, 1));

  return padInt(num1 + num2, 3);
};

const getLastThree = (dob: string, sex: number) => {
  let birthDay: any = moment(dob, "YYYY-MM-DD").toDate();
  let db: string = moment(birthDay).format("MM-DD-YYYY");
  let dateOfBirth = db.split("-");
  let result: number = 0;
  let bday: number = parseInt(dateOfBirth[1]);
  let bmonth: number = parseInt(dateOfBirth[0]) - 1;
  result = bmonth * 31 + bday;
  if (FEMALE === sex) {
    result += 600;
  }
  return padInt(result, 3);
};

const padInt = (value: number, length: number) => {
  let valueString: string = "" + value;
  let result: string = "";
  for (let i: number = 0; i < length - valueString.length; i++) {
    result = result + "0";
  }
  result = result + valueString;
  return result;
};

const lookup = (table: any, value: string) => {
  let number: number = 0;
  for (let i: number = 0; i < table.length; i++) {
    if (table[i][0] === value) {
      number = parseInt(table[i][1]);
      break;
    }
  }
  return number;
};

const soundex = (s: string) => {
  if (!s || s.length === 0) {
    return "";
  }
  s = s.toUpperCase();
  let x = s.split("");
  let firstLetter: string = x[0];

  // convert letters to numeric code
  for (let i: number = 0; i < x.length; i++) {
    switch (x[i]) {
      case "B":
      case "F":
      case "P":
      case "V": {
        x[i] = "1";
        break;
      }

      case "C":
      case "G":
      case "J":
      case "K":
      case "Q":
      case "S":
      case "X":
      case "Z": {
        x[i] = "2";
        break;
      }

      case "D":
      case "T": {
        x[i] = "3";
        break;
      }

      case "L": {
        x[i] = "4";
        break;
      }

      case "M":
      case "N": {
        x[i] = "5";
        break;
      }

      case "R": {
        x[i] = "6";
        break;
      }

      default: {
        x[i] = "0";
        break;
      }
    }
  }

  // remove duplicates
  let output: string = "" + firstLetter;
  for (let i: number = 1; i < x.length; i++) {
    if (x[i] !== x[i - 1] && x[i] !== "0") {
      output += x[i];
    }
  }

  // pad with 0's or truncate
  output = output + "0000";
  return output.substring(0, 4);
};

export const getTemplateFieldValueByFieldName = (
  fieldName: string,
  templateFields: any[],
  templateValue: any[]
) => {
  let fieldValue: string = "";
  templateFields.forEach((field: any, i: number) => {
    if (field.toLowerCase() === fieldName.toLowerCase()) {
      fieldValue = templateValue[i];
    }
  });
  return fieldValue;
};

export const heightFormatValidation = (value: string) => {
  let isError: boolean = false;
  if (!value) {
    return isError;
  }

  for (let i: number = 0; i < value.trim().length; i++) {
    try {
      parseInt(value.substring(i, i + 1));
    } catch (err: any) {
      return true;
    }
  }

  let feet = value.substring(0, 1);
  let inches: string = "";
  if (value.length > 2) inches = value.substring(1, 3);

  let intFeet: number = parseInt(feet);
  let intInches: number = inches ? parseInt(inches) : 0;

  if (value.length === 2) {
    isError = true;
  } else if (intFeet > 7 || intFeet < 2) {
    isError = true;
  } else if (intInches > 11) {
    isError = true;
  }
  return isError;
};

export const formatComplaintNumber = (complaintNum: any) => {
  if (!complaintNum || complaintNum === "") {
    return '';
  }
  try {
    complaintNum = complaintNum.trim();
    
    if (complaintNum.length > 14) {
      return complaintNum;
    }
    
    let withoutOW: string;
    if (complaintNum.toUpperCase().startsWith("OW")) {
      withoutOW = complaintNum.substring(2, complaintNum.length);
    } else {
      withoutOW = complaintNum;
    }

    let regExp = /[0-9]{1}/;

    let array: string [];
    array = withoutOW.split("");
    for (let i = 0; i < array.length; i++) {
      let el = array[i];
      if (!regExp.test(el)) {
        return complaintNum;
      }
    }

    if (withoutOW.length <= 2) {
      let thisY = moment().format('YYYY');
      let se = getComplaintFormattedSeqNo(withoutOW);
      return "OW" + thisY + se;
    }
    
    let yearString;
    let seq;
    let year;
    if (withoutOW.length > 4) {
      yearString = withoutOW.substring(0, 4);
      let thisYear = moment().format('YYYY');
      if (yearString <= thisYear) {
        seq = withoutOW.substring(4, withoutOW.length);
      } else {
        yearString = withoutOW.substring(0, 2);
        seq = withoutOW.substring(2, withoutOW.length);
        if (seq.length > 8) {
          return complaintNum;
        }
      }
      year = yearString.length < 4 ? ("20" + yearString) : yearString;
    } else {
      yearString = withoutOW.substring(0, 2);
      seq = withoutOW.substring(2, withoutOW.length);
      year = "20" + yearString;
    }
    
    let caseSeqNo = seq;
    let strCaseYear = year;

    seq = getComplaintFormattedSeqNo(caseSeqNo);
    return "OW" + strCaseYear + seq;
  } catch {
    return complaintNum;
  }
  
}

function getComplaintFormattedSeqNo(obj:any) {
  var retVal = '';
  for (let i = obj.length; i < 8; i++) {
      retVal = '0' + retVal;
  }
  return retVal + obj;
}

export const convertCaseNumber = (caseNum: string) => {
  if (!caseNum) {
    return "";
  }

  let casenumber: string = caseNum;

  var emptyStringRegEx = /^\s*$/;
  if (emptyStringRegEx.test(casenumber)) {
    return casenumber;
  }

  var casetyperegex1 = /^\d{2}\s*[a-zA-Z][a-zA-Z]\s*\d+$/;
  var casetyperegex2 = /^\d{2}\s*[a-zA-Z]\s*\d+$/;
  var casetyperegex3 = /^\d{4}\s*[a-zA-Z][a-zA-Z]\s*\d+$/;
  var casetyperegex4 = /^\d{4}\s*[a-zA-Z]\s*\d+$/;

  var caseNumber = casenumber.replace(/\s/g, "");
  var caseyear: string;
  var casetype: string;
  var caseseqno: string;

  if (casetyperegex1.test(caseNumber)) {
    // YY TT NNNN format
    caseyear = caseNumber.substring(0, 2);
    casetype = caseNumber.substring(2, 4);
    caseseqno = caseNumber.substring(4, 4 + caseNumber.length);
    if (parseInt(caseyear) < 40) {
      caseyear = "20" + caseyear;
    } else {
      caseyear = "19" + caseyear;
    }
  } else if (casetyperegex2.test(caseNumber)) {
    // YY T NNNN format
    caseyear = caseNumber.substring(0, 2);
    casetype = caseNumber.substring(2, 3);
    caseseqno = caseNumber.substring(3, 3 + caseNumber.length);
    if (parseInt(caseyear) < 40) {
      caseyear = "20" + caseyear;
    } else {
      caseyear = "19" + caseyear;
    }
  } else if (casetyperegex3.test(caseNumber)) {
    // YYYY TT NNNN format
    caseyear = caseNumber.substring(0, 4);
    casetype = caseNumber.substring(4, 6);
    caseseqno = caseNumber.substring(6, 6 + caseNumber.length);
  } else if (casetyperegex4.test(caseNumber)) {
    // YYYY T NNNN format
    caseyear = caseNumber.substring(0, 4);
    casetype = caseNumber.substring(4, 5);
    caseseqno = caseNumber.substring(5, 5 + caseNumber.length);
  } else {
    return casenumber;
  }
  if (caseseqno.length > 6) {
    return casenumber;
  }
  return caseyear + casetype.toUpperCase() + getFormattedSeqNo(caseseqno);
};

export const validCaseNumber = (caseNum: string, longForm: boolean) => {
  if (!caseNum) {
    return true;
  }
  let caseNo = caseNum;
  if (caseNo.length !== 0 && caseNo.length !== 12 && caseNo.length !== 11) {
    return false;
  } else if (caseNo.length === 12) {
    let regExp = /[0-9]{4}[A-Za-z]{2}[0-9]{6}/;
    let matchFound = regExp.test(caseNo);
    if (!matchFound || (longForm) ? "" : (caseNo.substring(4, 6) !== "SW")) {
      return false;
    }
  } else if (caseNo.length === 11) {
    let regExp = /[0-9]{4}[A-Za-z]{1}[0-9]{6}/;
    let matchFound = regExp.test(caseNo);
    if (!matchFound) {
      return false;
    }
  }
  return true;
};

function getFormattedSeqNo(obj: string) {
  var retVal = "";
  for (let i = obj.length; i < 6; i++) {
    retVal = "0" + retVal;
  }
  return retVal + obj;
}

export const isFelonyCount = (caseType: string, classofViolation: string) => {
  if (caseType === "CF") {
    return true;
  }
  let felonyViolation = "1234MX";
  if (classofViolation) {
    if (felonyViolation.indexOf(classofViolation) >= 0) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export const formatCaseNumber = (casenum: string, isCase: boolean) => {
  if (casenum.length == 0) 
    return casenum;
  try{
    let values: Object[] = getCourtFileIDValues(casenum);
    const caseYear: number = Number(values[0]);
    const caseType: string = String(values[1]);
    const caseSeqNo: number = Number(values[2]); 
    const caseNumber: string = getCourtFileIDString(
      caseYear,
      caseType,
      caseSeqNo
    );
    if (isCase && caseType !== "SW"){
      return null;
    }
    return caseNumber;
  }
  catch{
    return null;
  }
  
};


export function getCourtFileIDValues(_courtFileID: string) {
  let values: Object[] = new Array(3);
  let courtFileID: string = "";
  try {
    courtFileID = convertCourtFileID(_courtFileID.toUpperCase());
    let yearLength: any = 0;
    let typeLength: any = 0;
    while (
      yearLength < 4 &&
      !isNaN(Number(courtFileID.charAt(yearLength))) &&
      Number.isInteger(Number(courtFileID.charAt(yearLength)))
    ) {
      yearLength++;
    }
    while (
      typeLength < 3 &&
      !/\d/.test(courtFileID.charAt(yearLength + typeLength))
    ) {
      typeLength++;
    }
    let yearString: string = "1/1/" + courtFileID.substring(0, yearLength);
    const type: string = courtFileID.substring(
      yearLength,
      yearLength + typeLength
    );
    let seq: string = courtFileID.substring(yearLength + typeLength).trim();

    if (yearLength === 0){
      throw new Error("year length is null");
    }
    let regExp = /[0-9]{1}/;
    seq.split("").forEach((el) =>{
      if (!regExp.test(el)){
        throw new Error("cannot parse case sequence number");
      }
    })
    //Calendar yearCalendar = Calendar.getInstance();
    //System.out.println("Year String:::::" + yearString);
    //System.out.println("Parse:::::" + dateFormat.parse(yearString));
    //yearCalendar.setTime(dateFormat.parse(yearString));
    ////System.out.println(DateTimeFormat.getFullDateFormat().parse(yearString));
    //int caseYear = yearCalendar.get(Calendar.YEAR);

    //to-DO
    // Date date = DateTimeFormat.getFormat("mm/dd/yy").parse(yearString);
    // String strCaseYear = DateTimeFormat.getFormat( "d-M-yyyy" ).format( date).split("-")[2];
    // int caseYear = Integer.valueOf(strCaseYear);

    const date: Date = new Date(yearString);
    let formattedDate: string = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
    let strCaseYear: string = date
      .toLocaleDateString("en-US", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })
      .split("/")[2];
    let caseYear: number = parseInt(strCaseYear);

    //If the user entered the 'TXD' or 'TX ' and did not enter the full sequence error out (PER DWH)
    if ((type === "TXD" || type === "TX ") && seq.length < 5) {
      alert("Sequence no must be 5 positions for a TXD or TO case");
    }

    let caseType: string = type === "TXD" ? "TD" : type === "TX " ? "TO" : type;
    let caseSeqNo: number = parseInt(seq);

    //##DUPAGE - NEW (NMM 11/06/2002)
    //caseSeqNo is not modified for bad cases
    if (
      (caseType === "TD" || caseType === "TO") &&
      caseYear < 2002 &&
      caseSeqNo > 10000 &&
      !isBadCase(caseYear, caseType, caseSeqNo)
    )
      caseSeqNo = caseSeqNo - 10000;
    //##DUPAGE - END

    values[0] = caseYear;
    values[1] = caseType;
    values[2] = caseSeqNo;
  } catch (NumberFormatException) {
    throw new Error("cannot parse case sequence number");
  }
  // catch (IndexOutOfBoundsException e)
  // {
  //     throw new IllegalArgumentException("cannot parse case id");
  // }
  /*catch (ParseException e)
    {
        throw new IllegalArgumentException("cannot parse case year");
    }*/
  return values;
}

function isBadCase(caseYear: number, caseType: string, caseSeqNo: number) {
  return badCases.has("" + caseYear + caseType.toUpperCase() + caseSeqNo);
}

let badCases: Map<string, any> = new Map<string, any>();
{
  badCases.set("1999TD1", "1999TD100001");
  badCases.set("1999TD100001", "1999TD1");
  badCases.set("1998TD1", "1998TD100001");
  badCases.set("1998TD100001", "1998TD1");
  badCases.set("1998TD2", "1998TD100002");
  badCases.set("1998TD100002", "1998TD2");
  badCases.set("1998TD5", "1998TD100005");
  badCases.set("1998TD100005", "1998TD5");
  badCases.set("1998TD6", "1998TD100006");
  badCases.set("1998TD100006", "1998TD6");
}

function convertCourtFileID(courtFileID: string) {
  if (courtFileID === null || courtFileID.length === 0) {
    return courtFileID;
  }
  if (isChildSupportIdentifier(courtFileID))
    return convertChildSupportID(courtFileID);
  //if(isTaxCaseIdentifier(courtFileID))
  //    return convertTaxCaseID(courtFileID);
  return courtFileID;
}

function isChildSupportIdentifier(courtFileID: string) {
  let allDigits: boolean = true;
  let chars: string[] = courtFileID.toUpperCase().split("");
  if (chars[0] == "C") {
    for (let i: number = 1; i < chars.length; i++) {
      if (isNaN(parseInt(chars[i]))) {
        allDigits = false;
        break;
      }
    }
    if (allDigits) return true;
  }
  for (let x: number = 0; x < chars.length; x++) {
    if (isNaN(parseInt(chars[x]))) return false;
  }
  return true;
}

function convertChildSupportID(courtFileID: string) {
  let chars: string[] = courtFileID.toUpperCase().split("");
  // StringBuffer result = new StringBuffer();
  let result: string = "";
  if (chars[0] == "C") {
    result = result.concat("1960CS");
    let seqNo: string = courtFileID.substring(1, courtFileID.length);
    result = result.concat(seqNo);
    return result.toString();
  }
  /*else
    {
        result.append("1970CS");
        result.append(courtFileID);
        return result.toString();
    }*/
  return "";
}

function getCourtFileIDString(
  caseYear: number,
  caseType: string,
  caseSeqNo: number
): string {
  let type =
    caseYear < 2002 && caseType.toUpperCase() === "TD"
      ? "TXD"
      : caseYear < 2002 && caseType.toUpperCase() === "TO"
      ? "TX "
      : caseType;

  let seq = "";
  if (
    caseYear < 2002 &&
    (caseType.toUpperCase() === "TD" || caseType.toUpperCase() === "TO")
  ) {
    if (caseSeqNo < 10000) {
      seq = "1" + padInt(caseSeqNo, 4);
    } else {
      let s = String(caseSeqNo);
      seq = "1" + padInt(parseInt(s.substring(1)), 4);
    }
  } else {
    seq = padInt(caseSeqNo, 6);
  }

  if (!type.trim().toUpperCase().startsWith("TX")) {
    type = type.trim();
  }

  return caseYear + type + seq;
}

export const getInchoateDesc = (val : string) =>{
  if (val == null)
    return "";
  if (INCHO_TYPE_ATTEMPT === val.toUpperCase())
    return INCHO_TYPE_ATTEMPT_DESC;
  else if (INCHO_TYPE_CONSPIRACY === val.toUpperCase())
    return INCHO_TYPE_CONSPIRACY_DESC;
  else if (INCHO_TYPE_SOLICIT === val.toUpperCase())
    return INCHO_TYPE_SOLICIT_DESC;
  else if (INCHO_TYPE_DRUG === val.toUpperCase())
    return INCHO_TYPE_DRUG_DESC;
  return "";
}

export const isDUICount = (caseType: string) => {
  if (caseType.toUpperCase() === "DT")
    return true;
  return false;
}

export const isDateBetween = (date: string, fromDate: string, toDate: string) => {

  let dates = moment(date, "YYYY-MM-DD").toDate();
  let fromDates = moment(fromDate, "YYYY-MM-DD").toDate();
  let toDates = moment(toDate, "YYYY-MM-DD").toDate();

  if ((dates.getTime() >= fromDates.getTime() && dates.getTime() < toDates.getTime())) {
    return true;
  }
  return false;
}

export const validateDate = (inputValue:any) => {
  const regex = /^(\d{2,4})-(\d{2})-(\d{2})$/;
  const match = regex.exec(inputValue);
  if (!match) {
    return null;
  }
  const [, yyyy, mm, dd] = match;
  let year = parseInt(yyyy);
  if (year < 100) {
    const currentYear = new Date().getFullYear();
    const century = Math.floor(currentYear / 100) * 100;
    year = parseInt(yyyy) <= (currentYear % 100) ? parseInt(yyyy) + century : parseInt(yyyy) + century - 100;
  } else if(year < 999) {
    return null;
  }
  const month = parseInt(mm);
  const day = parseInt(dd);
 if(day < 1 && month < 0) {
    return null;
  }
  const date = new Date(year, month - 1, day);
  if (isNaN(date.getTime())) {
    return null;
  }

  return `${year}-${mm}-${dd}`;
};

export const validateDateLong = (date: any) => {
  const regExp = RegExp("(0[1-9]|1[012])/(0?[1-9]|[12][0-9]|3[01])/((19|20)\\d\\d)");
  if (regExp.test(date)) {
    try {
      const inputDate = moment(date, "MM/DD/YYYY");
      const currentDate = moment();
      if (inputDate.isValid() && inputDate.isSameOrBefore(currentDate)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  } else {
    return false;
  }
}

export const findBrackets = (content:string) => {
  const reg = /(\w*)\[\[(.*?)]]/g;
  const matchIndex = [];
  let match;
  while ((match = reg.exec(content)) != null) {
    matchIndex.push({
      fieldName: match[2],
      fieldVal: match[0],
      start: match.index,
      end: match.index + match[0].length
    });
  }
  return matchIndex;
};

export const removeHtmlTags = (content:string) => {
  const reg = /(&nbsp;|<([^>]+)>)/ig
  if(content) content = content.replace(reg,"");
  return content;
}

export const setSelectionRange = (textarea: any, selectionStart: number, selectionEnd: number) => {
  if(textarea){  
    const fullText = textarea.value;
    textarea.value = fullText.substring(0, selectionEnd);
    const scrollHeight = textarea.scrollHeight
    textarea.value = fullText;
    let scrollTop = scrollHeight;
    const textareaHeight = textarea.clientHeight;
    if (scrollTop > textareaHeight){
        scrollTop -= textareaHeight / 2;
    } else{
        scrollTop = 0;
    }
    textarea.scrollTop = scrollTop;
    textarea.setSelectionRange(selectionStart, selectionEnd);
  }
}

// export const fetchEcitationSession = async (url:string,method:string,body:any) => {
//   const requestOptions = {
//     method:method ,
//     headers: { 'Content-Type': 'application/json' },
//     body:JSON.stringify(body)
// };
// const endPoint = process.env.REACT_APP_ECITATION_REST_URL + url;
//   const response = await fetch(endPoint, requestOptions);
//   const data = await response.json();
//   return data;
// }
export const fetchEcitationSession = async (url: string, method: string, body: any) => {
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  
  const endPoint = process.env.REACT_APP_ECITATION_REST_URL + url;
  

  try {
    const response = await fetch(endPoint, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      // Check if the response status code indicates an error
      const errorMessage = data.message || 'There is an issue while accessing the eCitation. Please try again after sometime.';

      // Check if the code is 1, and only throw an error in that case
      if (data.code === 1) {
        if(data?.data && data?.data?.length >= 1){
          throw new Error(data?.data[0]?.errorMessage)
        }
        throw new Error(errorMessage);
      }
    }

    return data;
  } catch (error) {
    throw error; // Rethrow the error for the calling code to handle
  }
};
export const fetchEcitationAdminSession = async (url: string, method: string, body: any) => {
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  
  const endPoint = process.env.REACT_APP_ECITATION_ADMIN_REST_URL + url;
  

  try {
    const response = await fetch(endPoint, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      // Check if the response status code indicates an error
      const errorMessage = data.message || 'There is an issue while accessing the eCitation. Please try again after sometime.';

      // Check if the code is 1, and only throw an error in that case
      if (data.code === 1) {
        if(data?.data && data?.data?.length >= 1){
          throw new Error(data?.data[0]?.errorMessage)
        }
        throw new Error(errorMessage);
      }
    }

    return data;
  } catch (error) {
    throw error; // Rethrow the error for the calling code to handle
  }
};