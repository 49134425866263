import React from "react";
import { Col, Pagination, Row } from "react-bootstrap";
/**
 * This component takes the following props:
 * @param {number} totalPages - The total number of pages in the pagination.
 * @param {number} currentPage - The current page that is being viewed.
 * @param {(page:number) => void} onPageChange - A function that will be called when the user clicks on a page.
 * @param {number} numberOfPagesToShow - The number of pages to show in the pagination.
 * @param {any} state - If used in the list view component the search bar state would be passed as state.
 */
export type PaginationProps = {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number, searchBarState?: any) => void;
  numberOfPagesToShow?: number;
  state?: any;
  className?:string;
};
const PaginationBar = ({
  totalPages,
  currentPage,
  onPageChange,
  numberOfPagesToShow = 5,
  state,
  className,
}: PaginationProps) => {
  let _currentPage = currentPage + 1;
  let startNumber =
    totalPages <= numberOfPagesToShow
      ? 1
      : _currentPage - 2 < 1
      ? 1
      : totalPages - (_currentPage - 2) < numberOfPagesToShow
      ? totalPages - 4
      : _currentPage - 2;

  let endNumber =
    totalPages <= numberOfPagesToShow
      ? totalPages
      : _currentPage + 2 > totalPages
      ? totalPages
      : _currentPage + 2 < numberOfPagesToShow
      ? numberOfPagesToShow
      : _currentPage + 2;

  let showingPagesArray = Array(endNumber - startNumber + 1)
    .fill("")
    .map((_, idx) => startNumber + idx);

  return (
    <Row>
      <Col className={className?className:"d-flex justify-content-start"}>
        <ul className="pagination">
          <Pagination.First
            disabled={_currentPage <= 1 || totalPages < 2 ? true : false}
            onClick={() => onPageChange(0, state)}
          >
            <b>&laquo; First</b>
          </Pagination.First>
        </ul>
        <ul className="pagination">
          <Pagination.Prev
            disabled={_currentPage <= 1 || totalPages < 2 ? true : false}
            onClick={() => onPageChange(_currentPage - 2, state)}
          >
            <b>&lsaquo; Previous</b>
          </Pagination.Prev>
        </ul>
      </Col>

      <Col className="d-flex justify-content-center">
        <ul className="pagination">
          {startNumber > 1 && <Pagination.Ellipsis disabled />}
          {showingPagesArray.map((number) => (
            <Pagination.Item
              key={number}
              active={number === _currentPage}
              onClick={() => onPageChange(number - 1, state)}
            >
              {number}
            </Pagination.Item>
          ))}
          {endNumber < totalPages && <Pagination.Ellipsis disabled />}
        </ul>
      </Col>

      <Col className="d-flex justify-content-end">
        <ul className="pagination">
          <Pagination.Next
            disabled={
              _currentPage === totalPages || totalPages < 2 ? true : false
            }
            onClick={() => onPageChange(_currentPage, state)}
          >
            <b>Next &rsaquo;</b>
          </Pagination.Next>
        </ul>
        <ul className="pagination">
          <Pagination.Last
            disabled={
              _currentPage === totalPages || totalPages < 2 ? true : false
            }
            onClick={() => onPageChange(totalPages - 1, state)}
          >
            <b>Last &raquo;</b>
          </Pagination.Last>
        </ul>
      </Col>
    </Row>
  );
};

export default PaginationBar;
