import React, { useState, useMemo, useEffect } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import Header from "../common/Header";
import { Card, Row, Col, Button } from "react-bootstrap";
import ButtonComponent from "../common/ButtonComponent";
import TextInputComponent from "../common/TextInputComponent";
import {
  RiFileEditLine,
  RiDraftFill,
  RiCloseCircleFill,
  RiCheckboxMultipleFill,
  RiDeleteBin5Fill,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import {
  resetComplaintData,
  getEComplaintData,
  updateElementValueComplaintReducer,
} from "../../redux/slice/complaintSlice";
import {
  formatNameWithSpaces,
  formatAddress,
  convertCaseNumber,
  validCaseNumber,
  isFelonyCount,
  getCourtFileIDValues,
} from "../../utils/common";
import CountPopup from "./AddCountPopup";
import TextAreaComponent from "../common/TextAreaComponent";
import {
  deleteCountDetails,
  updateCountStatus,
  resetCountFormData,
  updateCountFormObject,
  updateElementObjectValueCountFormObjectReducer,
  updateElementValueCountFormReducer,
  useCountFormReducer,
  getFugitiveList,
} from "../../redux/slice/countFormSlice";
import { FaCaretRight, FaTrashAlt } from "react-icons/fa";
import {
  useComplaintMainReducer,
  updateElementValueComplaintMainReducer,
  forceCreateArrestWarrant,
  updateElementObjectValueComplaintMain,
  saveComplaint,
  getWitnesses,
  undoCertification,
  submitForReviewWithSupervisor,
  fileWithCircuitClerk,
  insertEcomplaintData,
  getAllEcCounty,
  forceCreateProbableCauseStatement,
  resubmitImageToCms,
  resubmitComplaint,
  reGenerateComplaint,
  getEcDocumentsStatus,
  saveEcomplaintDocument,
  forceCreateSummon
} from "../../redux/slice/complaintMainSlice";
import {
  resetCertifyData,
  loadInitialData,
} from "../../redux/slice/certificationSlice";
import {
  LF_APPROVED_STATUS,
  LF_REJECTED_STATUS,
  LF_SEND_TO_SA_STATUS,
  ROLE_STATES_ATTORNEY,
  ROLE_SA_ADMIN,
  LF_ARREST_WARRANT,
  LF_ARRW_SIGNED_STATUS,
  LF_SIGNED_STATUS,
  ROLE_POLICE_OFFICER,
  ROLE_AGENCY_ADMIN,
  ROLE_ADMINISTRATOR,
  ROLE_COUNTY_ADMIN,
  LF_PENDING_STATUS,
  LF_SEND_TO_OFFICER_STATUS,
  LF_REQUIRED_STATUS,
  LF_COMPLETE_STATUS,
  LF_RETURNED_STATUS,
  LF_STATUS_PENDING_SUPERVISOR_REVIEW,
  LF_STATUS_PENDING_SUPERVISOR_RETURNED,
  LF_FILED_STATUS,
  LF_SEND_TO_JUDGE_STATUS,
  LF_ARRW_REJECTED_STATUS,
  LF_ARRW_RETURNED_STATUS,
  LF_VOID_STATUS,
  LF_DELETED_STATUS,
  LF_CASE_ASSIGNMENT_READY_STATUS,
  LF_COMMUNI_FAILED_STATUS,
  ec_msg_confirm_no_action_sendOfficer,
  ec_msg_confirm_sendOfficer,
  ec_msg_confirm_sendSA,
  LF_COMPLAINT,
  LF_COMPLAINT_PROCESSING,
  REVISED_PROBABLE_CAUSE_STATEMENT,
  COUNT_LEADER,
  PREVIEW_LF_COMPLAINT_FROM_DASHBOARD,
  EC_MSG_CONFIRM_PREVIEWREDACTED,
  EC_MSG_WANTTOCONTINUE,
  ROLE_JUDGE,
  EC_MSG_CONFIRM_VICTIMNAMESPRESENTINSIDECOMPLAINT,
  EC_MSG_CONFIRM_VICTIMNAMESPRESENTINSIDESEXCOMPLAINT,
  TYPE_OF_ARREST_ONVIEWARREST,
  TYPE_OF_ARREST_ARRESTWARRANT,
  TYPE_OF_ARREST_SUMMON,
  CREATE_LF_FORM_SUMMON,
  LF_SUMMON_REJECTED_STATUS,
  LF_SUMMON_RETURNED_STATUS,
} from "../../constants/common";
import ConfirmPopup from "../common/ConfirmPopup";
import OptionPopup from "../common/OptionsPopup";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import moment from "moment";
import { WitnessPopup } from "./WitnessPopup";
import { TableActionComponent } from "../common/TableActionComponent";
import AlertMessage from "../common/AlertMessage";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import CertificationPopup from "./CertificationPopup";
import {
  deleteWitnessDetails,
  getWitnessData,
  resetWitnessData,
} from "../../redux/slice/witnessSlice";
import {
  setECFormData,
  updateAwState,
  updateElementObjectValueArrestWarrantReducer,
} from "../../redux/slice/arrestWarrantSlice";
import { getLookupLeaderInfo } from "../../redux/slice/leaderCountSlice";
import InfoPopup from "../common/InfoPopup";
import { AssignCaseNumberPopup } from "./AssignCaseNumberPopup";
import LeaderCountPopup from "./LeaderCountPopup";
import { previewEcomplaint } from "../../redux/slice/commonFormSlice";
import { editComplaint } from "../../redux/slice/ecomplaintDashboardSlice";
import { fetchProbableCauseStatementData, useProbableCauseStatementReducer } from "../../redux/slice/probableCauseStatementSlice";
import {
  useDashboardReducer,
  updateElementValueDashboardReducer,
} from "../../redux/slice/dashboardSlice";
import { updateSummonState } from "../../redux/slice/summonsSlice";
import { resetSignature, setSignature } from "../../redux/slice/signPanelSlice";
import { getJudgeSavedSignature } from "../../redux/slice/userProfileSlice";

interface EComplaintMainPageProps {}

const EComplaintMainPage: React.FC<EComplaintMainPageProps> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [countModalShow, setcountModalShow] = React.useState(false);
  const [status, setstatus] = React.useState("");
  const [selectItem, setSelectItem] = React.useState({
    itemName: "",
    popupMessage: "",
  });
  const [confirmPopupData, setconfirmPopupData] = React.useState(false);
  const [confirmReductedPopupData, setConfirmReductedPopupData] =
    React.useState(false);
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [countId, setcountId] = React.useState(0);
  const [witnessId, setWitnessId] = React.useState(0);
  const complaintMain = useComplaintMainReducer();
  const loginReducer = useLoginReducer();
  const { countFormObject, fugitiveList } = useCountFormReducer();
  const {
    lfcCountyList,
    complaint,
    ecFormList,
    countList,
    localNote,
    successMessage,
    witnessList,
    complaintSupervised,
    createFormList,
  } = useComplaintMainReducer();
  const { safeTactEnable } = useLoginReducer();
  const [showWitnessModal, setshowWitnessModal] = React.useState(false);
  const [showAsiignCaseNumber, setShowAsiignCaseNumber] = React.useState(false);
  const [primaryCaseNumberLocal, setPrimaryCaseNumberLocal] = useState("");
  const [startEditPrimaryCaseNumber, setStartEditPrimaryCaseNumber] =
    useState(false);
  const [primaryCaseNoBtnName, setPrimaryCaseNoBtnName] = useState("");
  const [showCertificationPopup, setShowCertificationPopup] =
    React.useState(false);
  const [
    showAddArrestWarrantConfirmPopup,
    setShowAddArrestWarrantConfirmPopup,
  ] = React.useState(false);
  const [showModifyComplaintConfirmPopup, setShowModifyComplaintConfirmPopup] =
    React.useState({    
      show: false,
      form: ""});
  const [
    showModifyComplaintWithArrestWarrantConfirmPopup,
    setShowModifyComplaintWithArrestWarrantConfirmPopup,
  ] = React.useState(false);
  const [
    showUndoCertificationConfirmPopup,
    setShowUndoCertificationConfirmPopup,
  ] = React.useState(false);
  const [showReturnToOfficerConfirmPopup, setShowReturnToOfficerConfirmPopup] =
    React.useState(false);
  const [
    supervisorReviewConfirmationModalShow,
    setSupervisorReviewConfirmationModalShow,
  ] = React.useState("");
  const [
    fillingProcessConfirmationModalShow,
    setFillingProcessConfirmationModalShow,
  ] = React.useState("");
  const errorMessageAlert = useAlert();
  const [hasComplainingWit, setHasComplainingWit] = useState(false);
  const [witnessCompId, setWitnessCompId] = useState(0);
  const [isLookupLeaderInfo, setIsLookupLeaderInfo] = React.useState(false);
  const [showError, setShowError] = useState({
    show: false,
    error: "",
  });
  const [showResubmitToJudge, setShowResubmitToJudge] = useState(false);
  const [singleCaseVPanel, setSingleCaseVPanel] = useState(false);
  const [reqCaseNumber, setReqCaseNumber] = useState(false);
  const [scroller, setScroller] = useState(true);
  const [populateCountyList, setPopulateCountyList] = useState(true);
  const [reqMultipleCaseNumbers, setReqMultipleCaseNumbers] = useState(false);
  const [assignCaseNUmber, setAssignCaseNUmber] = useState(false);
  const [felonycounty, setFelonycounty] = useState(false);
  const [isEnableBtnSendToSA, setIsEnableBtnSendToSA] = React.useState(false);
  const [isEnableBtnAddCount, setIsEnableBtnAddCount] = React.useState(true);
  const [isEnableBtnAddLeaderCounty, setIsEnableBtnAddLeaderCounty] =
    React.useState(true);
  const [isEnableBtnAddWitness, setIsEnableBtnAddWitness] =
    React.useState(true);
  const [isEnableBtnModifyCaseInfo, setIsEnableBtnModifyCaseInfo] =
    React.useState(true);
  const [isEnableCounts, setIsEnableCounts] = React.useState(true);
  const [isEnableWitness, setIsEnableWitness] = React.useState(true);
  const [isVisibleBtnDeleteCaseNumber, setIsVisibleBtnDeleteCaseNumber] = React.useState(false);
  const [isVisibleBtnReturnToOfficer, setIsVisibleBtnReturnToOfficer] = React.useState(false);
  const [isVisibleBtnArrestWarrant, setIsVisibleBtnArrestWarrant] = React.useState(false);
  const [isVisibleBtnSummon, setIsVisibleBtnSummon] = React.useState(false);
  const [isVisibleBtnPcStatement, setIsVisibleBtnPcStatement] = React.useState(false);
  const [isVisibleBtnSendToSA, setIsVisibleBtnSendToSA] = React.useState(true);
  const [isVisibleBtnCertification, setIsVisibleBtnCertification] =
    React.useState(true);
  const [isVisibleBtnUndoCertification, setIsVisibleBtnUndoCertification] =
    React.useState(false);
  const [isVisibleBtnAssignCaseNumber, setIsVisibleBtnAssignCaseNumber] =
    React.useState(false);
  const [isVisibleBtnFileWithCC, setIsVisibleBtnFileWithCC] =
    React.useState(false);
  const [isVisibleBtnSubmitToJudge, setIsVisibleBtnSubmitToJudge] =
    React.useState(false);
  const [isVisibleBtnSubmitForReview, setIsVisibleBtnSubmitForReview] =
    React.useState(false);
  const [isVisibleBtnReSubmitImg, setIsVisibleBtnReSubmitImg] =
    React.useState(false);
  const [isVisibleBtnReSubmitComplaint, setIsVisibleBtnReSubmitComplaint] =
    React.useState(false);
  const [isVisibleBtnReGenerateDocuments, setIsVisibleBtnReGenerateDocuments] =
    React.useState(false);
  const [isVisibleBtnViewRedactedCopy, setIsVisibleBtnViewRedactedCopy] =
    React.useState(false);
  const [isVisibleBtnViewCasePDFs, setIsVisibleBtnViewCasePDFs] =
    React.useState(true);
  const [isTxtComplaintNote, setIsTxtComplaintNote] = React.useState(true);
  const [btnSendToSA, setBtnSendToSA] = React.useState("Send to SA");
  const [rejectCount, setRejectCount] = useState(false);
  const [approvedRejectObj, setApprovedRejectObj] = useState({
    ecomplaintId: 0,
    countId: 0,
    status: "",
  });
  const [message, setMessage] = React.useState<React.ReactNode>();
  const [showEditConfirmation, setShowEditConfirmation] = React.useState(false);
  const dashboardReducer = useDashboardReducer();
  const defendantName = useMemo(
    () =>
      formatNameWithSpaces(
        complaint.defFirstName,
        complaint.defMiddleName,
        complaint.defLastName,
        complaint.defSuffix
      ),
    [
      complaint.defFirstName,
      complaint.defMiddleName,
      complaint.defLastName,
      complaint.defSuffix,
    ]
  );
  const defAddress = useMemo(
    () =>
      formatAddress(
        complaint.defAddressLine1,
        complaint.defAddressLine2,
        complaint.defCity,
        complaint.defState,
        complaint.defZip,
        complaint.defCountry
      ),
    [
      complaint.defAddressLine1,
      complaint.defAddressLine2,
      complaint.defCity,
      complaint.defState,
      complaint.defZip,
      complaint.defCountry,
    ]
  );
  const officerName = useMemo(
    () =>
      formatNameWithSpaces(
        complaint.officerLastName,
        complaint.officerFirstName,
        "",
        ""
      ),
    [complaint.officerLastName, complaint.officerFirstName]
  );
  const [getWitnessNameInsidepopup, setGetWitnessNameInsidepopup] =
    React.useState(false);
  const [
    getWitnessNameInsidepopupMessage,
    setGetWitnessNameInsidepopupMessage,
  ] = React.useState<React.ReactNode>();

  const [showRegenerateDoc, setShowRegenerateDoc] = React.useState(false);
  const [showSaveDocAgain, setShowSaveDocAgain] = React.useState(false);

  let impoundedFlag: string = "NO";
  if (complaint.impoundWarrant) {
    impoundedFlag = "REQUESTED";
  }

  let arrestWarrantExist: boolean = false;
  let arrestWarrantStatus: string = "";
  let ecomplaintForm: any = null;
  // TODO double check this logic - start
  if (ecFormList && ecFormList.length > 0) {
    for (const element of ecFormList) {
      ecomplaintForm = element;
      if (ecomplaintForm.formTypeName === LF_ARREST_WARRANT) {
        arrestWarrantStatus = ecomplaintForm.status
          ? ecomplaintForm.status.trim()
          : "";
        arrestWarrantExist = true;
        if (
          (arrestWarrantStatus === LF_ARRW_SIGNED_STATUS ||
            arrestWarrantStatus === LF_SIGNED_STATUS) &&
          ecomplaintForm.formData
        ) {
          let arrestWarrForm = JSON.parse(ecomplaintForm.formData);
          if (arrestWarrForm.impoundWarrant) {
            if (arrestWarrForm.grantCheck) {
              impoundedFlag = "GRANTED";
            } else if (arrestWarrForm.denyCheck) {
              impoundedFlag = "DENIED";
            }
          }
        }
      }
    }
  }
  // TODO double check this logic - end
  let showPrimaryCaseBtnUse: boolean = false;
  let disablePrimaryCaseTextUse: boolean = false;

  let primaryCaseNoBtnNameUse = "";
  if (
    !complaint.primaryCaseNumber ||
    complaint.primaryCaseNumber.trim().length === 0
  ) {
    primaryCaseNoBtnNameUse = "Save";
  } else {
    primaryCaseNoBtnNameUse = "Edit";
  }
  let fieldPrimaryCaseNumber = "";
  primaryCaseNoBtnNameUse =
    primaryCaseNoBtnName === "Update" ? "Update" : primaryCaseNoBtnNameUse;
  if (primaryCaseNoBtnNameUse === "Edit") {
    disablePrimaryCaseTextUse = true;
    fieldPrimaryCaseNumber =
      primaryCaseNumberLocal.trim().length > 0 ||
      primaryCaseNoBtnName === "SavingProgress"
        ? primaryCaseNumberLocal.trim()
        : complaint.primaryCaseNumber;
    if (
      primaryCaseNumberLocal.trim().length === 0 &&
      primaryCaseNoBtnName === "SavingProgress"
    ) {
      primaryCaseNoBtnNameUse = "Update";
      disablePrimaryCaseTextUse = false;
    }
  } else {
    disablePrimaryCaseTextUse = false;
    fieldPrimaryCaseNumber =
      startEditPrimaryCaseNumber || primaryCaseNoBtnName === "SavingProgress"
        ? primaryCaseNumberLocal
        : complaint.primaryCaseNumber;
  }

  if (
    (loginReducer.userType === ROLE_POLICE_OFFICER ||
      loginReducer.userType === ROLE_AGENCY_ADMIN ||
      loginReducer.userType === ROLE_ADMINISTRATOR ||
      loginReducer.userType === ROLE_COUNTY_ADMIN) &&
    (complaint.caseStatus === LF_PENDING_STATUS ||
      complaint.caseStatus === LF_SEND_TO_OFFICER_STATUS) &&
    !complaint.certificationIndicator
  ) {
    showPrimaryCaseBtnUse = true;
  } else {
    disablePrimaryCaseTextUse = true;
  }

  const loadData = () => {
    if (
      loginReducer.userType === ROLE_STATES_ATTORNEY ||
      loginReducer.userType === ROLE_SA_ADMIN
    ) {
      setBtnSendToSA("Send to Officer");
    }
  };

  useEffect(() => {
    if (
      "Send to SA" === btnSendToSA &&
      !(
        loginReducer.userType === ROLE_STATES_ATTORNEY ||
        loginReducer.userType === ROLE_SA_ADMIN
      )
    ) {
      updateCountReleted();
    }
  }, [btnSendToSA, complaintMain.countList]);

  useEffect(() => {
    if (!complaint.certificationIndicator) {
      handleEnableDisable(true);
    }
  }, [complaint.certificationIndicator]);

  const updateCountReleted = () => {
    if ("Send to SA" === btnSendToSA) {
      let enableSentToSA: boolean = false;
      complaintMain.countList.map((items: any) => {
        if (
          isFelonyCount(items.caseType, items.classOfViolation) &&
          LF_PENDING_STATUS === items.saApprovalStatus
        ) {
          enableSentToSA = true;
          return;
        }
      });

      if (enableSentToSA) {
        setIsVisibleBtnSendToSA(true);
      } else {
        setIsVisibleBtnSendToSA(false);
      }
      if (complaintMain.countList.length > 0) {
        if (LF_STATUS_PENDING_SUPERVISOR_REVIEW !== complaint.caseStatus) {
          setIsVisibleBtnCertification(true);
          setIsVisibleBtnUndoCertification(false);

          if (
            LF_STATUS_PENDING_SUPERVISOR_RETURNED === complaint.caseStatus &&
            complaint.certificationIndicator &&
            ROLE_POLICE_OFFICER === loginReducer.userType
          ) {
            setIsVisibleBtnCertification(false);
            setIsVisibleBtnUndoCertification(true);
          }
        }
      } else {
        setIsVisibleBtnCertification(false);
      }
    }
    handleEnableDisable(false);
  };

  const handleEnableDisable = (isOnAction: boolean) => {
    if (
      (complaint.certificationIndicator &&
        !(
          (LF_STATUS_PENDING_SUPERVISOR_REVIEW === complaint.caseStatus &&
            ROLE_AGENCY_ADMIN === loginReducer.userType) ||
          (LF_STATUS_PENDING_SUPERVISOR_RETURNED === complaint.caseStatus &&
            ROLE_POLICE_OFFICER === loginReducer.userType)
        )) ||
      LF_FILED_STATUS === complaint.caseStatus ||
      LF_SEND_TO_JUDGE_STATUS === complaint.caseStatus ||
      LF_CASE_ASSIGNMENT_READY_STATUS === complaint.caseStatus ||
      LF_REJECTED_STATUS === complaint.caseStatus ||
      (LF_ARRW_RETURNED_STATUS === complaint.caseStatus &&
        !(
          ROLE_POLICE_OFFICER === loginReducer.userType ||
          ROLE_AGENCY_ADMIN === loginReducer.userType
        )) ||
      LF_COMMUNI_FAILED_STATUS === complaint.caseStatus ||
      (ROLE_POLICE_OFFICER === loginReducer.userType &&
        LF_SEND_TO_SA_STATUS === complaint.caseStatus) ||
      (ROLE_AGENCY_ADMIN === loginReducer.userType &&
        LF_SEND_TO_SA_STATUS === complaint.caseStatus) ||
      (ROLE_POLICE_OFFICER === loginReducer.userType &&
        LF_VOID_STATUS === complaint.caseStatus) ||
      (ROLE_AGENCY_ADMIN === loginReducer.userType &&
        LF_VOID_STATUS === complaint.caseStatus) ||
      LF_DELETED_STATUS === complaint.caseStatus ||
      ((ROLE_STATES_ATTORNEY === loginReducer.userType ||
        ROLE_SA_ADMIN === loginReducer.userType) &&
        LF_SEND_TO_OFFICER_STATUS === complaint.caseStatus)
    ) {
      setIsEnableBtnAddCount(false);
      setIsEnableBtnAddLeaderCounty(false);
      setIsEnableBtnAddWitness(false);
      setIsEnableBtnModifyCaseInfo(false);
      setIsEnableCounts(false);
      setIsEnableWitness(false);
      setIsTxtComplaintNote(false);

      if (
        LF_FILED_STATUS === complaint.caseStatus ||
        LF_SEND_TO_JUDGE_STATUS === complaint.caseStatus ||
        LF_ARRW_REJECTED_STATUS === complaint.caseStatus ||
        (LF_ARRW_RETURNED_STATUS === complaint.caseStatus &&
          !(
            ROLE_POLICE_OFFICER === loginReducer.userType ||
            ROLE_AGENCY_ADMIN === loginReducer.userType
          )) ||
        LF_SUMMON_REJECTED_STATUS === complaint.caseStatus ||
        (LF_SUMMON_RETURNED_STATUS === complaint.caseStatus &&
          !(
            ROLE_POLICE_OFFICER === loginReducer.userType ||
            ROLE_AGENCY_ADMIN === loginReducer.userType
          )) ||
        LF_VOID_STATUS === complaint.caseStatus ||
        LF_DELETED_STATUS === complaint.caseStatus ||
        LF_CASE_ASSIGNMENT_READY_STATUS === complaint.caseStatus
      ) {
        setIsVisibleBtnSendToSA(false);
        setIsVisibleBtnCertification(false);
        setIsVisibleBtnFileWithCC(false);
        setIsVisibleBtnSubmitForReview(false);
      } else {
        if (
          LF_COMMUNI_FAILED_STATUS === complaint.caseStatus ||
          (LF_STATUS_PENDING_SUPERVISOR_RETURNED === complaint.caseStatus &&
            ROLE_AGENCY_ADMIN === loginReducer.userType)
        ) {
          setIsVisibleBtnCertification(false);
        } else {
          if (complaint.certificationIndicator) {
            setIsVisibleBtnCertification(false);
            setIsVisibleBtnUndoCertification(true);
          } else {
            setIsVisibleBtnCertification(true);
            setIsVisibleBtnUndoCertification(false);
          }
        }
        if (complaint.certificationIndicator) {
          if (loginReducer.ecUserAgency !== null) {
            if (loginReducer.userType === ROLE_AGENCY_ADMIN) {
              setIsVisibleBtnSubmitForReview(false);
              if (
                complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST &&
                complaint.caseStatus !== LF_STATUS_PENDING_SUPERVISOR_RETURNED
              ) {
                setIsVisibleBtnFileWithCC(true);
                setIsVisibleBtnSubmitToJudge(false);
              }
            } else {
              if (complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST) {
                const agencyObj: any = loginReducer.ecUserAgency;
                setIsVisibleBtnSubmitForReview(agencyObj.isComplaintSupervised);
                setIsVisibleBtnFileWithCC(!agencyObj.isComplaintSupervised);
              }
            }
          } else if (complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST) {
            setIsVisibleBtnFileWithCC(true);
          }
        }
      }

      if (
        ((ROLE_STATES_ATTORNEY === loginReducer.userType ||
          ROLE_SA_ADMIN === loginReducer.userType) &&
          LF_SEND_TO_OFFICER_STATUS === complaint.caseStatus) ||
        (ROLE_POLICE_OFFICER === loginReducer.userType &&
          LF_VOID_STATUS === complaint.caseStatus) ||
        (ROLE_AGENCY_ADMIN === loginReducer.userType &&
          LF_VOID_STATUS === complaint.caseStatus) ||
        LF_DELETED_STATUS === complaint.caseStatus ||
        (ROLE_POLICE_OFFICER === loginReducer.userType &&
          LF_SEND_TO_SA_STATUS === complaint.caseStatus) ||
        (ROLE_AGENCY_ADMIN === loginReducer.userType &&
          LF_SEND_TO_SA_STATUS === complaint.caseStatus)
      ) {
        setIsEnableBtnSendToSA(false);
        setIsVisibleBtnSendToSA(false);
        setIsVisibleBtnCertification(false);
        setIsVisibleBtnFileWithCC(false);
        setIsVisibleBtnSubmitForReview(false);
      }
      if (
        (ROLE_POLICE_OFFICER === loginReducer.userType &&
          LF_VOID_STATUS === complaint.caseStatus) ||
        (ROLE_AGENCY_ADMIN === loginReducer.userType &&
          LF_VOID_STATUS === complaint.caseStatus) ||
        LF_DELETED_STATUS === complaint.caseStatus
      ) {
        setIsVisibleBtnViewCasePDFs(false);
        setIsVisibleBtnArrestWarrant(false);
        setIsVisibleBtnSendToSA(false);
        setIsVisibleBtnPcStatement(false);
        setIsVisibleBtnSummon(false);
      }
    } else if (isOnAction) {
      setVisibleElements();
      setIsVisibleBtnSubmitForReview(false);
      setIsVisibleBtnFileWithCC(false);
      setIsEnableBtnModifyCaseInfo(true);
      setIsEnableBtnAddCount(true);
      setIsEnableBtnAddLeaderCounty(true);
      setIsEnableBtnAddWitness(true);
      setIsEnableCounts(true);
      setIsEnableWitness(true);
      setIsTxtComplaintNote(true);
    }

    if (LF_STATUS_PENDING_SUPERVISOR_REVIEW === complaint.caseStatus) {
      if (complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST) {
        setIsVisibleBtnFileWithCC(true);
        setIsVisibleBtnSubmitToJudge(false);
        setIsVisibleBtnReturnToOfficer(true);
      } else {
        setIsVisibleBtnFileWithCC(false);
        if (
          loginReducer.userType === ROLE_POLICE_OFFICER ||
          loginReducer.userType === ROLE_AGENCY_ADMIN
        ) {
          setIsVisibleBtnSubmitToJudge(true);
        }
      }
      setIsVisibleBtnCertification(false);
    } else if (LF_STATUS_PENDING_SUPERVISOR_RETURNED === complaint.caseStatus) {
      if (loginReducer.userType === ROLE_POLICE_OFFICER) {
        setIsVisibleBtnSubmitForReview(true);
      }
    }
  };

  useEffect(() => {
    if (LF_SEND_TO_JUDGE_STATUS === complaint.caseStatus) {
      setIsVisibleBtnSubmitToJudge(false);
    }
  }, [complaint.caseStatus]);

  const setVisibleElements = () => {
    if (LF_FILED_STATUS === complaint.caseStatus) {
      setIsVisibleBtnViewRedactedCopy(true);
    }
    if (
      loginReducer.canReSubmitEComplaint &&
      complaintMain.countyIntegratedWithCMS &&
      complaintMain.documentUploadFailed
    ) {
      setIsVisibleBtnReSubmitImg(true);
    }
    if (
      loginReducer.canReSubmitEComplaint &&
      complaintMain.countyIntegratedWithCMS &&
      complaint.commFailed
    ) {
      setIsVisibleBtnReSubmitComplaint(true);
    }

    if (
      ROLE_ADMINISTRATOR === loginReducer.userType &&
      (complaint.caseStatus === LF_FILED_STATUS ||
        complaint.caseStatus === LF_COMMUNI_FAILED_STATUS)
    ) {
      setIsVisibleBtnReGenerateDocuments(true);
    }

    if (
      ROLE_ADMINISTRATOR === loginReducer.userType &&
      !complaintMain.countyIntegratedWithCMS &&
      complaint.caseStatus === LF_FILED_STATUS
    ) {
      setIsVisibleBtnDeleteCaseNumber(true);
    }

    if (
      loginReducer.canAssignCaseNumbers &&
      complaint !== null &&
      (LF_CASE_ASSIGNMENT_READY_STATUS === complaint.caseStatus ||
        LF_COMMUNI_FAILED_STATUS === complaint.caseStatus) &&
      !alreadyCaseNumberAssigned()
    ) {
      setIsVisibleBtnAssignCaseNumber(true);
      setIsVisibleBtnFileWithCC(false);
      setIsVisibleBtnSubmitForReview(false);
    }

    if (
      !(
        ROLE_POLICE_OFFICER === loginReducer.userType ||
        ROLE_AGENCY_ADMIN === loginReducer.userType
      ) &&
      !loginReducer.canAssignCaseNumbers
    ) {
      setIsVisibleBtnCertification(false);
      setIsVisibleBtnFileWithCC(false);
      setIsVisibleBtnSubmitForReview(false);
    }

    if (complaintMain.countList.length <= 0) {
      setIsVisibleBtnSendToSA(false);
      setIsVisibleBtnCertification(false);
      setIsVisibleBtnFileWithCC(false);
      setIsVisibleBtnSubmitForReview(false);
    }

    let isPcStatement: boolean = false;
    createFormList.map((items: any) => {
      if (REVISED_PROBABLE_CAUSE_STATEMENT === items.formName) {
        isPcStatement = true;
      }
    });

    if (LF_FILED_STATUS !== complaint.caseStatus) {
      if (complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST) {
        if (isPcStatement && !complaint.defendantReleased && safeTactEnable) {
          setIsVisibleBtnPcStatement(true);
        } else {
          setIsVisibleBtnPcStatement(false);
        }
      } else {
        setIsVisibleBtnPcStatement(false);
      }
    } else {
      setIsVisibleBtnPcStatement(false);
    }

    const arrestWarrantObj = ecFormList.filter((item:any) => LF_ARREST_WARRANT === item.formTypeName)[0];
    const summonOnj = ecFormList.filter((item:any) => "Summon" === item.formTypeName)[0];

      if (arrestWarrantObj) {
        let allowViewEdit = isAllowViewEdit(
          arrestWarrantObj.status,
          arrestWarrantObj.formTypeName,
          complaint.caseStatus,
          loginReducer.userType
        );

        if (allowViewEdit) {
          if (
            !(
              LF_VOID_STATUS === complaint.caseStatus ||
              LF_STATUS_PENDING_SUPERVISOR_RETURNED === complaint.caseStatus ||
              LF_DELETED_STATUS === complaint.caseStatus
            )
          ) {
            if (isPcStatement){
              setIsVisibleBtnPcStatement(true);
            }
            setIsVisibleBtnArrestWarrant(true);
          }
        } else {
          setIsVisibleBtnArrestWarrant(false);
        }
      } else {
        setIsVisibleBtnArrestWarrant(false);
        if (summonOnj) {
          let allowViewEdit = isAllowViewEdit(
            summonOnj.status,
            summonOnj.formTypeName,
            complaint.caseStatus,
            loginReducer.userType
          );
  
          if (allowViewEdit) {
            if (
              !(
                LF_VOID_STATUS === complaint.caseStatus ||
                LF_STATUS_PENDING_SUPERVISOR_RETURNED === complaint.caseStatus ||
                LF_DELETED_STATUS === complaint.caseStatus
              )
            ) {
              setIsVisibleBtnSummon(true);
            }
          } else {
            setIsVisibleBtnSummon(false);
          }
        }else {
          setIsVisibleBtnSummon(false);
        }
      }

  };

  const alreadyCaseNumberAssigned = () => {
    if (complaintMain.multiCaseNumberCounty) {
      countList.map((item: any) => {
        if (item.caseNumber === null || item.caseNumber.trim() === "") {
          return false;
        }
      });
      return true;
    } else {
      if (complaint.caseNumber !== "") {
        return true;
      }
    }
    return false;
  };

  const isAllowViewEdit = (
    formStatus: string,
    formType: string,
    comStatus: string,
    userRole: string
  ) => {
    if (LF_COMPLAINT === formType && LF_COMPLAINT_PROCESSING !== comStatus) {
      return true;
    } else if (LF_ARREST_WARRANT === formType || CREATE_LF_FORM_SUMMON === formType) {
      if (
        ROLE_JUDGE === userRole &&
        !(
          LF_SIGNED_STATUS === formStatus ||
          LF_REJECTED_STATUS === formStatus ||
          LF_RETURNED_STATUS === formStatus ||
          LF_COMPLAINT_PROCESSING === formStatus
        )
      ) {
        return true;
      } else if (
        (ROLE_POLICE_OFFICER === userRole || ROLE_AGENCY_ADMIN === userRole) &&
        LF_SEND_TO_SA_STATUS !== comStatus &&
        (LF_PENDING_STATUS === formStatus ||
          LF_REJECTED_STATUS === formStatus ||
          (LF_RETURNED_STATUS === formStatus &&
            !(
              LF_STATUS_PENDING_SUPERVISOR_RETURNED === comStatus &&
              ROLE_AGENCY_ADMIN === userRole
            )) ||
          LF_SEND_TO_JUDGE_STATUS === formStatus ||
          LF_COMPLETE_STATUS === formStatus ||
          LF_VOID_STATUS === formStatus ||
          LF_STATUS_PENDING_SUPERVISOR_REVIEW === formStatus)
      ) {
        return true;
      } else if (
        (ROLE_STATES_ATTORNEY === userRole || ROLE_SA_ADMIN === userRole) &&
        LF_SEND_TO_OFFICER_STATUS !== comStatus &&
        (LF_PENDING_STATUS === formStatus ||
          LF_REJECTED_STATUS === formStatus ||
          LF_RETURNED_STATUS === formStatus ||
          LF_COMPLETE_STATUS === formStatus ||
          LF_VOID_STATUS === formStatus)
      ) {
        return true;
      } else if (ROLE_COUNTY_ADMIN === userRole) {
        if (LF_FILED_STATUS === comStatus && LF_SIGNED_STATUS === formStatus)
          return true;
      } else if (LF_DELETED_STATUS === formStatus) {
        return true;
      }
    } else if (REVISED_PROBABLE_CAUSE_STATEMENT === formType) {
      if (
        (ROLE_POLICE_OFFICER === userRole &&
          LF_PENDING_STATUS === formStatus) ||
        (ROLE_AGENCY_ADMIN === userRole && LF_PENDING_STATUS === formStatus)
      ) {
        return true;
      }
    } else if (REVISED_PROBABLE_CAUSE_STATEMENT === formType) {
      if (
        (ROLE_POLICE_OFFICER === userRole &&
          LF_PENDING_STATUS === formStatus) ||
        (ROLE_AGENCY_ADMIN === userRole && LF_PENDING_STATUS === formStatus)
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleLongFormListClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  };

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    {
      itemLink:
        "/" + dashboardReducer.PreviousURLLFC.replace(/^.*\/\/.*?\//, ""),
      itemName: "Long Form List",
      itemFunction: handleLongFormListClick,
    },
    { itemLink: "/EComplaintDashboard", itemName: "Existing Forms" },
  ];

  const onBlurNotes = (event: any) => {
    let { name, value } = event.target;

    dispatch(
      updateElementObjectValueComplaintMain({ elementName: name, value: value })
    );

    if (!localNote && value.trim() === "") {
      return;
    }
    if (localNote !== value) {
      let container: any = {};
      container.className = "Ecomplaint";
      let propsValues: any = [];
      propsValues.push(["notes", value]);
      container.propsValues = propsValues;
      let dataObj = {
        ecomplaintId: complaint.ecomplaintId,
        dto: container,
        identifier: "SaveNote",
      };
      errorMessageAlert.handleError([]);
      dispatch(saveComplaint(dataObj));
    }
  };

  const onChangeNotes = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementObjectValueComplaintMain({ elementName: name, value: value })
    );
  };

  const handlePrimaryCaseNoChange = (event: any) => {
    setPrimaryCaseNumberLocal(event.target.value);
    setStartEditPrimaryCaseNumber(true);
  };

  const handlePrimaryCaseNoBlur = (event: any) => {
    let caseNum = convertCaseNumber(event.target.value);
    setPrimaryCaseNumberLocal(caseNum);
  };

  const handlePrimaryCaseSave = (event: any) => {
    if (event.target.value === "Save" || event.target.value === "Update") {
      if (validatePrimaryCaseNo(primaryCaseNumberLocal, event.target.value)) {
        let container: any = {};
        container.className = "Ecomplaint";
        let propsValues: any = [];
        propsValues.push(["primaryCaseNumber", primaryCaseNumberLocal]);
        container.propsValues = propsValues;
        let dataObj = {
          ecomplaintId: complaint.ecomplaintId,
          dto: container,
          identifier: "SavePrimaryCaseNumber",
        };
        errorMessageAlert.handleError([]);
        dispatch(saveComplaint(dataObj));
        setPrimaryCaseNoBtnName("SavingProgress");
        setStartEditPrimaryCaseNumber(false);
      }
    } else if (event.target.value === "Edit") {
      setPrimaryCaseNumberLocal(complaint.primaryCaseNumber);
      setPrimaryCaseNoBtnName("Update");
      setStartEditPrimaryCaseNumber(false);
    }
  };

  const validatePrimaryCaseNo = (caseNo: string, buttonVal: string) => {
    let message = "";
    if (buttonVal !== "Update") {
      if (!caseNo || caseNo.trim().length === 0) {
        message = "Primary Case Number is required.";
      }
    }
    if (caseNo && caseNo.trim().length === 0) {
      caseNo = "";
    }
    if (message.trim().length === 0) {
      let isValid = validCaseNumber(caseNo, true);
      if (!isValid) {
        message = "Primary Case Number is invalid.";
      }
    }
    if (message.trim().length > 0) {
      dispatch(
        updateElementValueComplaintMainReducer({
          elementName: "successMessage",
          value: "",
        })
      );
      let errorMessages: string[] = [];
      errorMessages.push(message);
      errorMessageAlert.handleError(errorMessages);
      return false;
    } else {
      return true;
    }
  };

  const afterCertifying = (endWith: string) => {
    if (endWith === "ConfirmPopup") {
      if (
        complaintSupervised &&
        loginReducer.userType === ROLE_POLICE_OFFICER
      ) {
        setSupervisorReviewConfirmationModalShow("showWithHeader");
      } else {
        setFillingProcessConfirmationModalShow("showWithHeader");
      }
    }
  };

  const onFillingReturnClick = () => {
    setFillingProcessConfirmationModalShow("hide");
    handleEnableDisable(true);
  };

  const onFileWithCircuitClerkClick = () => {
    setFillingProcessConfirmationModalShow("hide");
    let dataObj = {
      ecomplaintId: complaint.ecomplaintId,
      navigate: navigate,
    };
    dispatch(fileWithCircuitClerk(dataObj));
  };

  const onSupervisorReviewingReturnClick = () => {
    setSupervisorReviewConfirmationModalShow("hide");
  };

  const onSupervisorReviewClick = () => {
    let complaintObj = { ...complaint };
    complaintObj.caseStatus = LF_STATUS_PENDING_SUPERVISOR_REVIEW;

    dispatch(
      submitForReviewWithSupervisor({
        ecomplaint: complaintObj,
        navigate: navigate,
      })
    );
    setSupervisorReviewConfirmationModalShow("hide");
  };

  const reSubmitToJudgeClick = () => {
    setShowResubmitToJudge(true);
  };

  const confirmReSubmitToJudgeClick = () => {
    let data: any = {
      formId: "0",
      formType: LF_COMPLAINT,
      ecomplaintId: complaint.ecomplaintId,
      status: LF_SEND_TO_JUDGE_STATUS,
      navigate: navigate,
    };
    dispatch(editComplaint(data));
    setShowResubmitToJudge(false);
  };

  const fileWithCClerkOrSubmitSupervisorReview = (buttonName: string) => {
    errorMessageAlert.handleError([]);
    let errorMessages: string[] = [];
    if (!complaint.certificationIndicator) {
      errorMessages.push("Complaint should be certified.");
    } else if (LF_REQUIRED_STATUS === complaint.caseSaApproved) {
      errorMessages.push("Felony counts must be approved by State's Attorney.");
    } else if (
      LF_REJECTED_STATUS === complaint.caseSaApproved ||
      containsRejectedCounts()
    ) {
      errorMessages.push("Can not proceed with rejected counts.");
    } else if (
      !disablePrimaryCaseTextUse &&
      (fieldPrimaryCaseNumber ? fieldPrimaryCaseNumber.trim() : "") !==
        (complaint.primaryCaseNumber ? complaint.primaryCaseNumber.trim() : "")
    ) {
      errorMessages.push("Primary Case Number is not saved.");
    }

    if (errorMessages.length > 0) {
      errorMessageAlert.handleError(errorMessages);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    if ("submitForReview" === buttonName) {
      setSupervisorReviewConfirmationModalShow("showWithoutHeader");
    } else {
      setFillingProcessConfirmationModalShow("showWithoutHeader");
    }
  };

  const existingEcForms = () => {
    let existingFormTypeNameList: string[] = [];
    if (ecFormList) {
      ecFormList.forEach((element: any) => {
        existingFormTypeNameList.push(element.formTypeName);
      });
    }
    return existingFormTypeNameList;
  };

  const isProbablyFormComplete = async () => {
    if (ecFormList instanceof Array) {
      const pc = ecFormList.find(
        (item: any) => item.formTypeName === REVISED_PROBABLE_CAUSE_STATEMENT
      );
      if (pc) {
        if (pc.formId > 0) {
          let Obj = {
            formId: pc.formId,
            navigate: null,
          };
          dispatch(fetchProbableCauseStatementData(Obj));
          try {
            const action = await dispatch(fetchProbableCauseStatementData(Obj));
            const probableDataObject = action.payload as any;
            if (
              (probableDataObject.victimNames !== null && probableDataObject.victimPhoneNumbers !== null) ||
              (probableDataObject.factsCrime !== null && probableDataObject.factsCrime !== "")
            ) {
              return false;
            } else {
              return true;
            }
          } catch (error) {
            console.error('Error fetching probable cause statement data:', error);
            return false;
          }
        }
      }
    }
  };

  const certifyClick = async () => {
    dispatch(getFugitiveList({ tableName: "FUGFRMJSTC_CMSKEY" }));
    errorMessageAlert.handleError([]);
    let errorMessages: string[] = [];
    let existingForms = existingEcForms();
    if (complaint.certificationIndicator) {
      errorMessages.push("Already certified.");
    } else if (LF_REQUIRED_STATUS === complaint.caseSaApproved) {
      errorMessages.push("Felony counts must be approved by State's Attorney.");
    } else if (!countList || countList.length === 0) {
      errorMessages.push("Certification can not proceed without count(s).");
    } else if (
      LF_REJECTED_STATUS === complaint.caseSaApproved ||
      containsRejectedCounts()
    ) {
      errorMessages.push("Can not proceed with rejected counts.");
    } else if (
      complaint.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT
    ) {
      if (!isArrestWarrantComplete()){
        errorMessages.push("Completed arrest warrant not found.");
      }
      if (isVisibleBtnPcStatement && !existingForms.includes(REVISED_PROBABLE_CAUSE_STATEMENT)){
        errorMessages.push("Probable Cause Statement not found.");
      }
      
    } else if (complaint.typeOfArrest === TYPE_OF_ARREST_SUMMON && !isSummonsComplete()) {
      errorMessages.push("Completed Summons not found.");
    } else if (!disablePrimaryCaseTextUse
      && (fieldPrimaryCaseNumber ? fieldPrimaryCaseNumber.trim() : "") !== (complaint.primaryCaseNumber ? complaint.primaryCaseNumber.trim() : "")) {
      errorMessages.push("Primary Case Number is not saved.");
    } else if (complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST && !complaint.defendantReleased && isVisibleBtnPcStatement && !existingForms.includes(REVISED_PROBABLE_CAUSE_STATEMENT)) {
      errorMessages.push("Please create a Probable Cause Statement before certifying the complaint.");
    } else if (await isProbablyFormComplete()) {
      errorMessages.push("Probable Cause Statement should be complete before certifying the complaint.");
    }
    if (errorMessages.length > 0) {
      errorMessageAlert.handleError(errorMessages);
      window.scrollTo({
        top: -500,
        behavior: "smooth",
      });
      return;
    }
    const warningMessage = getWitnessNamesInsideComplaintTextWarningMessage();
    if (warningMessage !== null) {
      setGetWitnessNameInsidepopupMessage(warningMessage);
      setShowAddArrestWarrantConfirmPopup(false);
      setGetWitnessNameInsidepopup(true);
      return;
    }
    if (complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST) {
      setShowAddArrestWarrantConfirmPopup(true);
    } else {
      if (!isFilledDemographics()) {
        setShowModifyComplaintWithArrestWarrantConfirmPopup(true);
      } else {
        onContinueClick();
      }
    }
  };

  const previewPdf = (isRedacted: boolean) => {
    let data = {
      ecomplaintId: complaint.ecomplaintId,
      isRedacted: isRedacted,
      callingFrom: PREVIEW_LF_COMPLAINT_FROM_DASHBOARD,
    };
    dispatch(previewEcomplaint(data));
    setConfirmReductedPopupData(false);
  };
  const ViewRedactedCopyClick = () => {
    let confrimtext = (
      <>
        {EC_MSG_CONFIRM_PREVIEWREDACTED} <br />
        {EC_MSG_WANTTOCONTINUE}
      </>
    );
    setconfirmText(confrimtext);
    setConfirmReductedPopupData(true);
  };
  const handleConfirmReductedPopup = () => {
    previewPdf(true);
  };
  const containsRejectedCounts = () => {
    for (let i: number = 0; i < countList.length; i++) {
      if (LF_REJECTED_STATUS === countList[i].saApprovalStatus) {
        return true;
      }
    }
    return false;
  };

  const isArrestWarrantComplete = () => {
    for (let i: number = 0; i < ecFormList.length; i++) {
      if (LF_ARREST_WARRANT === ecFormList[i].formTypeName) {
        if (
          LF_COMPLETE_STATUS === ecFormList[i].status ||
          LF_RETURNED_STATUS === ecFormList[i].status
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const isSummonsComplete = () => {
    for (let i: number = 0; i < ecFormList.length; i++) {
      if ("Summon" === ecFormList[i].formTypeName) {
        if (LF_COMPLETE_STATUS === ecFormList[i].status || LF_RETURNED_STATUS === ecFormList[i].status) {
          return true;
        }
      }
    }
    return false;
  }

  const getWitnessNamesInsideComplaintTextWarningMessage = () => {
    console.log("1");
    console.log(witnessList);
    const witnessNames = new Set<string>();
    for (const ecWitness of witnessList) {
      console.log(ecWitness);
      if (ecWitness.firstName !== "" && ecWitness.firstName.trim().length > 1) {
        console.log("3");
        witnessNames.add(ecWitness.firstName);
      }
      if (ecWitness.lastName !== "" && ecWitness.lastName.trim().length > 1) {
        console.log("4");
        witnessNames.add(ecWitness.lastName);
      }
      if (
        ecWitness.middleName !== "" &&
        ecWitness.middleName.trim().length > 1
      ) {
        console.log("5");
        witnessNames.add(ecWitness.middleName);
      }
    }
    let hasSexInViolation = false;
    let hasNamesInside = false;
    let sexViolationCode = null;
    console.log("2");
    console.log(witnessNames);
    for (const ecKount of countList) {
      console.log("3");
      for (const name of witnessNames) {
        console.log(ecKount);
        console.log(name);
        if (
          ecKount.chargeNarrative.toUpperCase().includes(name.toUpperCase())
        ) {
          hasNamesInside = true;
        }
      }
      if (ecKount.violationDescription.includes("SEX")) {
        hasSexInViolation = true;
        sexViolationCode = ecKount.violationCode;
      }
    }
    let warningMessage = null;
    console.log(hasNamesInside);
    if (hasNamesInside) {
      warningMessage = EC_MSG_CONFIRM_VICTIMNAMESPRESENTINSIDECOMPLAINT;
    } else if (hasSexInViolation) {
      warningMessage = EC_MSG_CONFIRM_VICTIMNAMESPRESENTINSIDESEXCOMPLAINT;
    }
    return warningMessage;
  };

  const ignoregetWitnessNameInsidepopupMessage = () => {
    setGetWitnessNameInsidepopup(false);
    if (complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST) {
      setShowAddArrestWarrantConfirmPopup(true);
    } else {
      if (!isFilledDemographics()) {
        setShowModifyComplaintWithArrestWarrantConfirmPopup(true);
      } else {
        onContinueClick();
      }
    }
  };

  const isFilledDemographics = () => {
    if (
      !complaint.defDob ||
      complaint.defDob.trim() === "" ||
      !complaint.defGender ||
      complaint.defGender.trim() === "" ||
      !complaint.defHairColor ||
      complaint.defHairColor.trim() === "" ||
      !complaint.defEyeColor ||
      complaint.defEyeColor.trim() === "" ||
      !complaint.defHeight ||
      complaint.defHeight.trim() === "" ||
      !complaint.defWeight ||
      complaint.defWeight.trim() === ""
    ) {
      return false;
    }
    return true;
  };

  const onContinueClickAfterCheckedLookupLeader = async () => {
    dispatch(resetCertifyData());
    dispatch(resetSignature());
    const res = await dispatch(getJudgeSavedSignature({
      userprofileId: loginReducer.userProfileId
    }));
    if (res.payload.message) {
      dispatch(setSignature(res.payload.message));
    }

    let obj = {
      witnesses: witnessList,
      complaint: complaint,
      clickOnOfficerAsComplainingWitness: false,
    };
    
    dispatch(loadInitialData(obj));
    setShowCertificationPopup(true);
  };

  const canAddLeaderCounts = () => {
    for (let i = 0; i < complaintMain.countList.length; i++) {
      const violation: any = complaintMain.countList[i].violationCode;
      const violationKey: any = complaintMain.countList[i].violationkey;

      if (violation && isfugFrmJstcVialationfunction(violationKey)) {
        return false;
      }
    };
    return true;
  }

  const isfugFrmJstcVialationfunction = (violationKey: string) => {
    let fug: string[] = [];
    fugitiveList.forEach((el: any) => {
      fug.push(el.displayValue);
    })
    if (fug.includes(violationKey)) {
      return true;
    } else {
      return false;
    }
  };

  const onContinueClick = async () => {
    setShowAddArrestWarrantConfirmPopup(false);
    if (!canAddLeaderCounts()) {
      onContinueClickAfterCheckedLookupLeader();
    }
    else{
      let data = await dispatch(
        getLookupLeaderInfo({ ecomplaint: complaintMain.complaint })
      );
      if (!("error" in data)) {
        if (data.payload.length !== 0) {
          setIsLookupLeaderInfo(true);
        } else {
          onContinueClickAfterCheckedLookupLeader();
        }
      } else {
        console.log("Error occurred in IUCS service");
        onContinueClickAfterCheckedLookupLeader();
      }
    }
  };
  //KHL PR 1256 25/01/24 - START
  let ecCountyList = lfcCountyList.filter((county: any) => {
    if (county.countyID !== 0) {
      return county;
    }
  });
  //KHL PR 1256 25/01/24 - END

  const onAddArrestWarrantClick = () => {
    dispatch(updateAwState("create"));
    setShowAddArrestWarrantConfirmPopup(false);
    if (isFilledDemographics()) {
      let dataObj = {
        ecomplaintId: complaint.ecomplaintId,
        modifyComplaint: false,
        navigate: navigate,
      };
      dispatch(forceCreateArrestWarrant(dataObj));
    } else {
      setShowModifyComplaintConfirmPopup({    
        show: true,
        form: "AW"});
    }
    //KHL PR 1256 25/01/24 - START
    if(ecCountyList.filter((county) => county.impoundEnable === true && 
    complaintMain.complaint.countyName === county.countyName).length > 0){
      dispatch(updateElementObjectValueArrestWarrantReducer({
        elementName: "isImpoundWarrant",
        value: "Y",
      }));
    } else {
      dispatch(updateElementObjectValueArrestWarrantReducer({
        elementName: "isImpoundWarrant",
        value: "N",
      }));
    }
    //KHL PR 1256 25/01/24 - END
  };

  const onAddSummonsClick = () => {
    dispatch(updateSummonState("create"));
    setShowAddArrestWarrantConfirmPopup(false);
    if (isFilledDemographics()) {
      let dataObj = {
        ecomplaintId: complaint.ecomplaintId,
        modifyComplaint: false,
        navigate: navigate,
      };
      dispatch(forceCreateSummon(dataObj));
    } else {
      setShowModifyComplaintConfirmPopup({    
      show: true,
      form: "SUMMON"});
    }
  };

  const onModifyComplaintConfirmClick = (form:string) => () => {
    if (form === "AW"){
      if (showModifyComplaintConfirmPopup.show) {
        setShowModifyComplaintConfirmPopup({    
          show: false,
          form: ""});
        let dataObj = {
          ecomplaintId: complaint.ecomplaintId,
          modifyComplaint: true,
          navigate: navigate,
        };
        dispatch(forceCreateArrestWarrant(dataObj));
      } else if (showModifyComplaintWithArrestWarrantConfirmPopup) {
        setShowModifyComplaintWithArrestWarrantConfirmPopup(false);
        dispatch(resetComplaintData());
        let obj = {
          ecomplaintId: complaint.ecomplaintId,
          navigate: navigate,
        };
        dispatch(getEComplaintData(obj));
        dispatch(
          updateElementValueComplaintReducer({
            elementName: "returnToPage",
            value: "ComplaintMainPage",
          })
        );
      }
    }else if(form === "SUMMON"){
      if (showModifyComplaintConfirmPopup.show) {
        setShowModifyComplaintConfirmPopup({    
          show: false,
          form: ""});
        let dataObj = {
          ecomplaintId: complaint.ecomplaintId,
          modifyComplaint: true,
          navigate: navigate,
        };
        dispatch(forceCreateSummon(dataObj));
      } else if (showModifyComplaintWithArrestWarrantConfirmPopup) {
        setShowModifyComplaintWithArrestWarrantConfirmPopup(false);
        dispatch(resetComplaintData());
        let obj = {
          ecomplaintId: complaint.ecomplaintId,
          navigate: navigate,
        };
        dispatch(getEComplaintData(obj));
        dispatch(
          updateElementValueComplaintReducer({
            elementName: "returnToPage",
            value: "ComplaintMainPage",
          })
        );
      }
    }
  };

  const onModifyComplaintConfirmPopupCancelClick = () => {
    setShowModifyComplaintConfirmPopup({    
      show: false,
      form: ""});
    setShowModifyComplaintWithArrestWarrantConfirmPopup(false);
  };

  const undoCertifyClick = () => {
    setShowUndoCertificationConfirmPopup(true);
  };

  const returnToOfficer = () => {
    setShowReturnToOfficerConfirmPopup(true);
  };

  const continueOnUndoCertification = () => {
    setShowUndoCertificationConfirmPopup(false);
    let dataObj = {
      ecomplaintId: complaint.ecomplaintId,
      navigate: navigate,
    };
    dispatch(undoCertification(dataObj));
  };

  const continueOnReturnToOfficer = () => {
    let complaintObj = { ...complaint };
    complaintObj.caseStatus = LF_STATUS_PENDING_SUPERVISOR_RETURNED;

    dispatch(
      submitForReviewWithSupervisor({
        ecomplaint: complaintObj,
        navigate: navigate,
      })
    );
    setShowReturnToOfficerConfirmPopup(false);
    navigate("/EComplaintDashboard");
  };

  const cancelModalShow = () => {
    setcountModalShow(false);
  };

  const showCountModal = () => {
    dispatch(resetCountFormData());
    setInitialDataToCountPopup();
    dispatch(
      updateElementValueCountFormReducer({
        elementName: "isAddCount",
        value: true,
      })
    );
    setcountModalShow(true);
    setstatus("create");
  };

  const getHighestCount = () => {
    for (let i = 0; i < complaintMain.countList.length; i++) {
      let element = complaintMain.countList[i];
      if (element.countnumber === 1) {
        return element;
      }
    }
    return null;
  };

  const getSecondHighestCount = () => {
    for (let i = 0; i < complaintMain.countList.length; i++) {
      let element = complaintMain.countList[i];
      if (element.countnumber === 2) {
        return element;
      }
    }
    return null;
  };

  const setInitialDataToCountPopup = () => {
    dispatch(resetCountFormData());
    dispatch(
      updateElementValueCountFormReducer({
        elementName: "ecCountyList",
        value: complaintMain.ecCountyList,
      })
    );
    dispatch(getFugitiveList({ tableName: "FUGFRMJSTC_CMSKEY" }));
    let offenceDate = "";
    let offenceOccTime = "";
    let highestCount = getHighestCount();
    if (complaintMain.complaint.firstIncidentDateTime) {
      offenceDate = moment(
        complaintMain.complaint.firstIncidentDateTime
      ).format("YYYY-MM-DD");
    }
    if (complaintMain.countList.length > 0) {
      if (highestCount) {
        offenceOccTime = moment(highestCount.offenceOccTime, "hh:mm A").format(
          "HH:mm"
        );
      }
    }
    let Obj = {
      ...countFormObject,
      prosecution: complaintMain.complaint.caseProsecutionType,
      caseNumber: complaintMain.complaint.caseNumber,
      offenceOccurredOn: true,
      offenceOccurredFrom: false,
      offenceCounty: complaintMain.complaint.countyName,
      offenceState: "IL",
      offenceDate: offenceDate,
      offenceOccTime: offenceOccTime,
    };
    dispatch(updateCountFormObject(Obj));
  };

  const witnessModalShow = () => {
    dispatch(getWitnesses({ ecomplientId: complaint.ecomplaintId }));
    setHasComplainingWit(false);
    if (witnessList.length > 0) {
      witnessList.map((witness: any) => {
        if (witness.complainingWitness) {
          setHasComplainingWit(true);
          setWitnessCompId(witness.witnessId);
        }
      });
    }
    setshowWitnessModal(true);
    dispatch(resetWitnessData());
    setstatus("create");
  };

  useEffect(() => {
    if (successMessage && successMessage.length > 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [successMessage]);

  useEffect(() => {
    loadData();
    handleEnableDisable(false);
    setVisibleElements();

    dispatch(getAllEcCounty());
    if (complaintMain.countList.length < 1) {
      showCountModal();
    }
  }, []);

  const showeCitationModal = () => {
    setInitialDataToCountPopup();

    setstatus("create");
    dispatch(
      updateElementObjectValueCountFormObjectReducer({
        elementName: "countFrom",
        value: COUNT_LEADER,
      })
    );
    setcountModalShow(true);
  };

  const continueEdit = () => {
    setShowEditConfirmation(false);
    editCountAfterConfirm();
  };

  const editCountAfterConfirm = () => {
    for (let i = 0; i < complaintMain.countList.length; i++) {
      if (complaintMain.countList[i].kountId === countId) {
        let offenceDate = "";
        let offenceOccTime = "";
        let offenceFromDate = "";
        let offenceThroughDate = "";
        if (complaintMain.countList[i].offenceOccurredOn) {
          offenceDate = complaintMain.countList[i].offenceDate;
          offenceOccTime = complaintMain.countList[i].offenceOccTime;
        } else {
          offenceFromDate = complaintMain.countList[i].offenceFromDate;
          offenceThroughDate = complaintMain.countList[i].offenceThroughDate;
        }
        let Obj = {
          ...complaintMain.countList[i],
          offenceDate: offenceDate,
          offenceOccTime: offenceOccTime,
          offenceFromDate: offenceFromDate,
          offenceThroughDate: offenceThroughDate,
          uploadedFileName: complaintMain.countList[i].hiddenUploadPdf
            ? complaintMain.countList[i].citationNumber + ".pdf"
            : "",
        };
        dispatch(
          updateElementValueCountFormReducer({
            elementName: "countFormObject",
            value: Obj,
          })
        );
        break;
      }
    }
    dispatch(
      updateElementValueCountFormReducer({
        elementName: "ecCountyList",
        value: complaintMain.ecCountyList,
      })
    );
    setcountModalShow(true);
  };

  const editCount = (id: any, type: string) => {
    if (type === "count") {
      setstatus("edit");
      let caseType = complaint.caseNumber
        ? getCourtFileIDValues(complaint.caseNumber)[0]
        : null;
      let secondHighestKount = getSecondHighestCount();
      let nextCaseType = secondHighestKount
        ? secondHighestKount.caseType
        : null;
      for (let i = 0; i < complaintMain.countList.length; i++) {
        if (complaintMain.countList[i].kountId === id) {
          if (
            complaintMain.countList[i].countnumber === 1 &&
            caseType !== null &&
            caseType !== nextCaseType
          ) {
            setcountId(id);
            setMessage(
              <>
                <div>
                  You are about to change the highest count of this incident.
                  This edit may cause the case number to be un-assigned.
                </div>
                <div>Do you want to continue?"</div>
              </>
            );
            setShowEditConfirmation(true);
          } else {
            let offenceDate = "";
            let offenceOccTime = "";
            let offenceFromDate = "";
            let offenceThroughDate = "";
            if (complaintMain.countList[i].offenceOccurredOn) {
              offenceDate = complaintMain.countList[i].offenceDate;
              offenceOccTime = complaintMain.countList[i].offenceOccTime;
            } else {
              offenceFromDate = complaintMain.countList[i].offenceFromDate;
              offenceThroughDate =
                complaintMain.countList[i].offenceThroughDate;
            }
            let Obj = {
              ...complaintMain.countList[i],
              offenceDate: offenceDate,
              offenceOccTime: offenceOccTime,
              offenceFromDate: offenceFromDate,
              offenceThroughDate: offenceThroughDate,
              uploadedFileName: complaintMain.countList[i].hiddenUploadPdf
                ? complaintMain.countList[i].citationNumber + ".pdf"
                : "",
            };
            dispatch(
              updateElementValueCountFormReducer({
                elementName: "countFormObject",
                value: Obj,
              })
            );
            dispatch(
              updateElementValueCountFormReducer({
                elementName: "ecCountyList",
                value: complaintMain.ecCountyList,
              })
            );
            setcountModalShow(true);
          }
          break;
        }
      }
    } else if (type === "witness") {
      dispatch(getWitnesses({ ecomplientId: complaint.ecomplaintId }));
      setHasComplainingWit(false);
      if (witnessList.length > 0) {
        witnessList.map((witness: any) => {
          if (witness.complainingWitness) {
            setHasComplainingWit(true);
            setWitnessCompId(witness.witnessId);
          }
        });
      }
      setshowWitnessModal(true);
      setstatus("edit");

      let Obj = {
        witnessId: id,
        ecomplaintId: complaintMain.complaint.ecomplaintId,
      };
      dispatch(getWitnessData(Obj));
    }
  };

  const deleteCount = (id: any, type: string) => {
    if (type === "count") {
      setconfirmPopupData(true);
      let caseType = complaint.caseNumber
        ? getCourtFileIDValues(complaint.caseNumber)[0]
        : null;
      let secondHighestKount = getSecondHighestCount();
      let nextCaseType = secondHighestKount
        ? secondHighestKount.caseType
        : null;
      for (let i = 0; i < complaintMain.countList.length; i++) {
        if (complaintMain.countList[i].kountId === id) {
          if (
            complaintMain.countList[i].countnumber === 1 &&
            caseType !== null &&
            caseType !== nextCaseType
          ) {
            setcountId(id);
            setSelectItem({
              itemName: "count",
              popupMessage: `You are about to delete the count and this will remove the case number that was assigned. Do you want to continue? `,
            });
          } else {
            setSelectItem({
              itemName: "count",
              popupMessage: `Do you really want to delete this  Count `,
            });
            setcountId(id);
          }
          break;
        }
      }
    } else if (type === "witness") {
      setconfirmPopupData(true);
      setSelectItem({
        itemName: "witness",
        popupMessage: `Do you really want to delete this  Count `,
      });
      setWitnessId(id);
    }
  };

  const handleConfirmation = () => {
    if (selectItem.itemName === "count") {
      let Obj = {
        ecomplaintId: complaintMain.complaint.ecomplaintId,
        countId: countId,
      };
      dispatch(deleteCountDetails(Obj));
      setconfirmPopupData(false);
    } else if (selectItem.itemName === "witness") {
      let Obj = {
        ecomplaintId: complaintMain.complaint.ecomplaintId,
        witnessId: witnessId,
      };
      dispatch(deleteWitnessDetails(Obj));
      setconfirmPopupData(false);
    } else if (selectItem.itemName === "felony") {
      confirmSending();
    } else if (selectItem.itemName === "sendTosa") {
      let _complaint = { ...complaint };
      _complaint.caseStatus = LF_SEND_TO_SA_STATUS;
      _complaint.saViewed = true;
      let data = dispatch(
        insertEcomplaintData({
          _complaint,
          navigate: navigate,
          navigateTo: "/EComplaintDashboard",
        })
      );
      setconfirmPopupData(false);
    } else if (selectItem.itemName === "confrim") {
      let hasCaseNumber = true;
      if (complaintMain.multiCaseNumberCounty) {
        if (
          complaintMain.countList === null ||
          complaintMain.countList.length < 1
        ) {
          hasCaseNumber = false;
        } else {
          for (let i: number = 0; i < countList.length; i++) {
            if (
              countList[i].caseNumber == null ||
              countList[i].caseNumber.trim() === ""
            ) {
              hasCaseNumber = false;
            }
          }
        }
      } else {
        hasCaseNumber =
          complaint.caseNumber !== null && complaint.caseNumber.trim() !== "";
      }
      if (
        LF_APPROVED_STATUS === complaint.caseSaApproved &&
        !hasCaseNumber &&
        getHighestFelonyCount() != null
      ) {
        if (complaintMain.multiCaseNumberCounty) {
          if (
            complaintMain.countList === null ||
            complaintMain.countList.length < 1
          ) {
            errorMessageAlert.handleError([]);
            let errorMessages: string[] = [];
            errorMessages.push("No counts found");
            if (errorMessages.length > 0) {
              errorMessageAlert.handleError(errorMessages);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              return;
            }
          }
          setSingleCaseVPanel(false);
          setPopulateCountyList(true);
          setFelonycounty(true);
          if (complaintMain.countyIntegratedWithCMS) {
            setReqMultipleCaseNumbers(true);
          }
          setconfirmPopupData(false);
          setShowAsiignCaseNumber(true);
        } else {
          setFelonycounty(true);
          setScroller(false);
          setAssignCaseNUmber(true);
          setSingleCaseVPanel(true);
          if (complaintMain.countyIntegratedWithCMS) {
            setReqCaseNumber(true);
            setAssignCaseNUmber(false);
          }
          setPopulateCountyList(false);
          setReqMultipleCaseNumbers(false);
          setShowAsiignCaseNumber(true);
          setconfirmPopupData(false);
        }
      } else {
        sendToOfficer(null, false);
        setconfirmPopupData(false);
      }
    }
  };
  const approveRejectCount = (countId: number, status: string) => {
    let Obj = {
      ecomplaintId: complaintMain.complaint.ecomplaintId,
      countId: countId,
      status: status,
    };
    setApprovedRejectObj(Obj);
    if (status === LF_REJECTED_STATUS) setRejectCount(true);
    else dispatch(updateCountStatus(Obj));
  };

  const confirmRejectCount = () => {
    dispatch(updateCountStatus(approvedRejectObj));
    setRejectCount(false);
  };

  const handleArrestWarrantClick = (e: any) => {
    if (ecFormList instanceof Array) {
      const arrestWarrant = ecFormList.find(
        (item: any) => item.formTypeName === LF_ARREST_WARRANT
      );
      if (arrestWarrant) {
        if (arrestWarrant.formId > 0 && arrestWarrant.formData) {
          dispatch(updateAwState("editThrounghMainPage"));
          dispatch(
            setECFormData({
              status: arrestWarrant.status,
              formId: arrestWarrant.formId,
            })
          );
        } else {
          dispatch(updateAwState("create"));
        }
        navigate("/arrestWarrant");
      }
    }
  };

  const handleSummonClick = (e:any) => {
    if (ecFormList instanceof Array) {
      const summons = ecFormList.find(
        (item: any) => item.formTypeName === "Summon"
      );
      if (summons) {
        if (summons.formId > 0 && summons.formData) {
          dispatch(updateSummonState("editThrounghMainPage"));
          dispatch(setECFormData({
            status: summons.status,
            formId: summons.formId,
          }))
        } else {
          dispatch(updateSummonState("create"));
        }
        navigate("/issueSummons")
      }
    }
  }

  const handleProbableCauseClick = () =>{
    if (ecFormList instanceof Array) {
      const pc = ecFormList.find(
        (item: any) => item.formTypeName === REVISED_PROBABLE_CAUSE_STATEMENT
      );
      if (pc) {
        if (pc.formId > 0) {
          let Obj = {
            formId: pc.formId,
            navigate: navigate,
          };
          dispatch(fetchProbableCauseStatementData(Obj));
        }
      } else {
        const ecomplaintId = complaint.ecomplaintId;
        let Obj = {
          ecomplaintId: ecomplaintId,
          navigate: navigate,
        };
        dispatch(forceCreateProbableCauseStatement(Obj));
      }
    }
  };

  const sendToOfficer = (_caseNumber: any, setCaseNumber: boolean) => {
    if (complaint) {
      if (setCaseNumber) {
        complaint.caseNumber = _caseNumber;
      }
      let _complaint = { ...complaint };
      _complaint.caseStatus = LF_SEND_TO_OFFICER_STATUS;
      dispatch(
        insertEcomplaintData({
          _complaint,
          navigate: navigate,
          navigateTo: "/EComplaintDashboard",
        })
      );
    }
  };
  const confirmSending = () => {
    setSelectItem({
      itemName: "confrim",
      popupMessage: ec_msg_confirm_sendOfficer,
    });
    setconfirmPopupData(true);
  };
  const getHighestFelonyCount = () => {
    for (let i: number = 0; i < countList.length; i++) {
      if (
        countList[i].countnumber === 1 &&
        isFelonyCount(countList[i].caseType, countList[i].classOfViolation)
      ) {
        return countList[i];
      }
      return null;
    }
  };

  const handleSendTo = () => {
    errorMessageAlert.handleError([]);
    let errorMessages: string[] = [];
    let confirmKountSend = true;
    if (complaint != null) {
      if (
        disablePrimaryCaseTextUse &&
        (fieldPrimaryCaseNumber ? fieldPrimaryCaseNumber.trim() : "") !==
          (complaint.primaryCaseNumber
            ? complaint.primaryCaseNumber.trim()
            : "")
      ) {
        errorMessages.push("Primary Case Number is not saved.");
      }
      if ("Send to Officer" === btnSendToSA) {
        for (let i: number = 0; i < countList.length; i++) {
          if (
            isFelonyCount(
              countList[i].caseType,
              countList[i].classOfViolation
            ) &&
            countList[i].saApprovalStatus.trim() !== LF_APPROVED_STATUS &&
            countList[i].saApprovalStatus.trim() !== LF_REJECTED_STATUS
          ) {
            confirmKountSend = false;
          }
        }
        if (!confirmKountSend) {
          setSelectItem({
            itemName: "felony",
            popupMessage: ec_msg_confirm_no_action_sendOfficer,
          });
          setconfirmPopupData(true);
        } else {
          setSelectItem({
            itemName: "confrim",
            popupMessage: ec_msg_confirm_sendOfficer,
          });
          setconfirmPopupData(true);
        }
      } else {
        setSelectItem({
          itemName: "sendTosa",
          popupMessage: ec_msg_confirm_sendSA,
        });
        setconfirmPopupData(true);
      }
      if (errorMessages.length > 0) {
        errorMessageAlert.handleError(errorMessages);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      }
    }
  };
  const selectedLeaders = (list: any, isIgnoreAndContinue: boolean) => {
    setIsLookupLeaderInfo(false);
    if (isIgnoreAndContinue) {
      onContinueClickAfterCheckedLookupLeader();
    } else if (list.length > 0) {
      if (list.length === 1) {
        setcountModalShow(true);
        setstatus("createCertification");
        dispatch(
          updateElementValueCountFormReducer({
            elementName: "ecCountyList",
            value: complaintMain.ecCountyList,
          })
        );
        let Obj = {
          ...list[0],
          prosecution: complaintMain.complaint.caseProsecutionType,
          caseNumber: complaintMain.complaint.caseNumber,
          offenceCounty: complaintMain.complaint.countyName,
          offenceState: "IL",
        };
        dispatch(updateCountFormObject(Obj));
      }
    }
  };

  const onReGenerateComplaint = async () => {
    let isHaveEcDocument = await dispatch(
      getEcDocumentsStatus({
        ecomplaintId: complaintMain.complaint.ecomplaintId,
      })
    );
    if (isHaveEcDocument.payload) {
      setShowRegenerateDoc(true);
    } else if (!isHaveEcDocument.payload) {
      setShowSaveDocAgain(true);
    }
  };

  const regenerateDocAfterConfirm = () => {
    let _ecCaseDetail = { ...complaintMain };
    let dataObj = {
      _ecCaseDetail,
      navigate: navigate,
    };
    dispatch(reGenerateComplaint(dataObj));
  };

  const saveDocAgainAfterConfirm = () => {
    let dataObj = {
      ecomplaintId: complaint.ecomplaintId,
      navigate: navigate,
    };
    dispatch(saveEcomplaintDocument(dataObj));
  };

  const onResubmitComplaint = () => {
    let _ecCaseDetail = { ...complaintMain };
    let dataObj = {
      _ecCaseDetail,
      fromScheduler: false,
      navigate: navigate,
    };
    dispatch(resubmitComplaint(dataObj));
  };

  const onResubmitImageToCms = () => {
    let dataObj = {
      ecomplaintId: complaint.ecomplaintId,
      fromScheduler: false,
      navigate: navigate,
    };
    dispatch(resubmitImageToCms(dataObj));
  };
  return (
    <>
      <Header headerName="Criminal Case Complaints" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Criminal Case Complaints"
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <AlertMessage
          message={successMessage || ""}
          onClose={() => {
            dispatch(
              updateElementValueComplaintMainReducer({
                elementName: "successMessage",
                value: "",
              })
            );
          }}
        />
        <Row>
          <Col md={12} className="mt-3">
            <Card className="shadow">
              <Card.Body>
                <Row className="g-2">
                  <Col xs={"auto"}>
                    <span className="me-3">
                      Primary case number for linking co-defendants:
                    </span>
                    <div className="d-inline-block mt-2 me-3 mb-2">
                      <TextInputComponent
                        type="text"
                        className="form-control"
                        name="primaryCaseNumber"
                        value={fieldPrimaryCaseNumber}
                        disabled={disablePrimaryCaseTextUse}
                        onChange={handlePrimaryCaseNoChange}
                        onBlur={handlePrimaryCaseNoBlur}
                        onFocus={() => {
                          errorMessageAlert.handleError([]);
                          dispatch(
                            updateElementValueComplaintMainReducer({
                              elementName: "successMessage",
                              value: "",
                            })
                          );
                        }}
                      />
                    </div>
                    {showPrimaryCaseBtnUse ? (
                      <ButtonComponent
                        variant="info"
                        className="pl-2 pr-2"
                        onClick={handlePrimaryCaseSave}
                        value={primaryCaseNoBtnNameUse}
                      >
                        &nbsp;&nbsp; {primaryCaseNoBtnNameUse} &nbsp;&nbsp;
                      </ButtonComponent>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col>
                    <div className="mt-2 text-md-end">
                      <ButtonComponent
                        variant="danger"
                        disabled={!isEnableBtnModifyCaseInfo}
                        onClick={() => {
                          dispatch(resetComplaintData());
                          let dataObj = {
                            ecomplaintId: complaint.ecomplaintId,
                            navigate: navigate,
                          };
                          dispatch(getEComplaintData(dataObj));
                          dispatch(
                            updateElementValueComplaintReducer({
                              elementName: "returnToPage",
                              value: "ComplaintMainPage",
                            })
                          );
                        }}
                      >
                        <RiFileEditLine /> Modify Complaint Information
                      </ButtonComponent>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="mt-3" border="info">
                      <Card.Body>
                        <Row className="g-2">
                          <Col md={5}>
                            <Row>
                              <Col md={3}>
                                <label className="fw-bold">Defendant</label>
                              </Col>
                              <Col md={9}>{defendantName}</Col>
                            </Row>
                            <hr className="hrStyle" />
                            <Row>
                              <Col md={3}>
                                <label className="fw-bold">Address</label>
                              </Col>
                              <Col md={9}>{defAddress}</Col>
                            </Row>
                            <hr className="hrStyle" />
                            <Row>
                              <Col md={3}>
                                <label className="fw-bold">Officer</label>
                              </Col>
                              <Col md={9}>{officerName}</Col>
                            </Row>
                            <hr className="hrStyle" />
                            <Row>
                              <Col md={3}>
                                <label className="fw-bold">Type</label>
                              </Col>
                              <Col md={9} className="pe-3">
                                {complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST ? "ON VIEW ARREST" : complaint.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT ? "ARREST WARRANT" : complaint.typeOfArrest === TYPE_OF_ARREST_SUMMON ? "SUMMON" : ""}
                                {complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST && complaint.defendantReleased ? " (Defendant to be released on own recognizance)" :
                                  complaint.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST && !complaint.defendantReleased ? " (Defendant will be held and brought before a Judge)" : ""}
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <Row>
                              <Col md={4}>
                                <label className="fw-bold">DOB</label>
                              </Col>
                              <Col md={8}>
                                {complaint.defDob ? complaint.defDob : ""}
                              </Col>
                            </Row>
                            <hr className="hrStyle" />
                            <Row>
                              <Col md={4}>
                                <label className="fw-bold">
                                  Arresting Agency
                                </label>
                              </Col>
                              <Col md={8}>
                                {complaint.officerAgencyName
                                  ? complaint.officerAgencyName
                                  : ""}
                              </Col>
                            </Row>
                            <hr className="hrStyle" />
                            <Row>
                              <Col md={4}>
                                <label className="fw-bold">
                                  Incident Number
                                </label>
                              </Col>
                              <Col md={8}>
                                {complaint.primaryAgencyRepNumber
                                  ? complaint.primaryAgencyRepNumber
                                  : ""}
                              </Col>
                            </Row>
                            <hr className="hrStyle" />
                            <Row>
                              <Col md={4}>
                                <label className="fw-bold">Case Status</label>
                              </Col>
                              <Col md={8}>
                                {complaint.caseStatus
                                  ? complaint.caseStatus
                                  : ""}
                              </Col>
                            </Row>
                          </Col>
                          <Col md={3}>
                            <Row>
                              <Col md={4}>
                                <label className="fw-bold">SA Approval</label>
                              </Col>
                              <Col md={8}>
                                {complaint.caseSaApproved
                                  ? complaint.caseSaApproved
                                  : ""}
                              </Col>
                            </Row>
                            <hr className="hrStyle" />
                            <Row>
                              <Col md={4}>
                                <label className="fw-bold">Certified</label>
                              </Col>
                              <Col md={8}>
                                {complaint.certificationIndicator ? "Y" : "N"}
                              </Col>
                            </Row>
                            <hr className="hrStyle" />
                            <Row>
                              <Col md={4}>
                                <label className="fw-bold">Case Number</label>
                              </Col>
                              <Col md={8}>
                                {complaint.caseNumber
                                  ? complaint.caseNumber
                                  : ""}
                              </Col>
                            </Row>
                            <hr className="hrStyle" />
                            <Row>
                              <Col md={4}>
                                <label className="fw-bold">Impounded</label>
                              </Col>
                              <Col md={8}>{impoundedFlag}</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="mt-4 mb-2 text-md-end">
                      <ButtonComponent
                        variant="primary"
                        onClick={showCountModal}
                        disabled={!isEnableBtnAddCount}
                      >
                        Add Count
                      </ButtonComponent>{" "}
                      <ButtonComponent
                        variant="primary"
                        onClick={showeCitationModal}
                        disabled={!isEnableBtnAddLeaderCounty}
                      >
                        Add eCitation
                      </ButtonComponent>
                    </div>
                    <Card border="info">
                      <Card.Body>
                        <Row>
                          <Col md={12}>
                            <h4 className="m-0 p-0">Counts</h4>
                            <span className="title-text">
                              <p>
                                Number of Counts in this Case :{" "}
                                {complaintMain.countList.length}{" "}
                              </p>
                            </span>
                          </Col>
                        </Row>

                        {complaintMain.countList.length > 0 &&
                          complaintMain.countList.map((countItem: any) => (
                            <div className="form-control p-2 rounded mt-4">
                              <Row>
                                <Col>
                                  <div
                                    className={`rounded p-1 mb-1 custom-bg-${
                                      countItem.saApprovalStatus ===
                                      LF_REJECTED_STATUS
                                        ? "danger"
                                        : countItem.saApprovalStatus ===
                                          LF_APPROVED_STATUS
                                        ? "success"
                                        : ""
                                    }`}
                                  >
                                    <Row>
                                      <Col md={2}>
                                        <FaCaretRight />
                                        <b>
                                          <label
                                            className={
                                              countItem.editedViolation
                                                ? "text-danger-2"
                                                : ""
                                            }
                                          >
                                            {countItem.violationCode}
                                          </label>
                                        </b>
                                      </Col>
                                      <Col md={4}>
                                        <label
                                          className={
                                            countItem.editedViolation
                                              ? "text-danger-2"
                                              : ""
                                          }
                                        >
                                          {countItem.violationDescription}
                                        </label>
                                      </Col>
                                      <Col md={3}>
                                        <label
                                          className={
                                            countItem.editedViolation
                                              ? "text-danger-2"
                                              : "text-secondary"
                                          }
                                        >
                                          {countItem.caseType}
                                        </label>
                                        <label
                                          className={
                                            countItem.editedViolation
                                              ? "text-danger-2"
                                              : "ml-4"
                                          }
                                        >
                                          &emsp;&emsp;Class:{" "}
                                          {countItem.classOfViolation}
                                        </label>
                                        <label
                                          className={`text-secondary ${
                                            countItem.saApprovalStatus ===
                                              LF_APPROVED_STATUS &&
                                            "text-success"
                                          } 
                                    ${
                                      countItem.saApprovalStatus ===
                                        LF_REJECTED_STATUS && "text-danger"
                                    }`}
                                        >
                                          &emsp;&emsp;
                                          {countItem.saApprovalStatus}
                                        </label>
                                      </Col>

                                      <Col md={3}>
                                        <Row
                                          className="text-center"
                                          style={{ width: "300px" }}
                                        >
                                          <TableActionComponent
                                            tootltip="Edit"
                                            children={<RiDraftFill />}
                                            actionFunction={() =>
                                              editCount(
                                                countItem.kountId,
                                                "count"
                                              )
                                            }
                                            backgroundStyle="cutom-table-button"
                                            isDisable={!isEnableCounts}
                                          />
                                          <TableActionComponent
                                            tootltip="Delete"
                                            children={<RiDeleteBin5Fill />}
                                            actionFunction={() =>
                                              deleteCount(
                                                countItem.kountId,
                                                "count"
                                              )
                                            }
                                            color="text-danger"
                                            backgroundStyle="cutom-table-button"
                                            isDisable={!isEnableCounts}
                                          />

                                          {isFelonyCount(
                                            countItem.caseType,
                                            countItem.classOfViolation
                                          ) &&
                                          (loginReducer.userType ===
                                            ROLE_STATES_ATTORNEY ||
                                            loginReducer.userType ===
                                              ROLE_SA_ADMIN) &&
                                          complaintMain.complaint.caseStatus ===
                                            LF_SEND_TO_SA_STATUS
                                            ? countItem.saApprovalStatus !==
                                                LF_APPROVED_STATUS && (
                                                <TableActionComponent
                                                  tootltip="Approve"
                                                  color="text-success"
                                                  children={
                                                    <RiCheckboxMultipleFill />
                                                  }
                                                  actionFunction={() =>
                                                    approveRejectCount(
                                                      countItem.kountId,
                                                      LF_APPROVED_STATUS
                                                    )
                                                  }
                                                  backgroundStyle="cutom-table-button"
                                                  isDisable={!isEnableCounts}
                                                />
                                              )
                                            : ""}
                                          {isFelonyCount(
                                            countItem.caseType,
                                            countItem.classOfViolation
                                          ) &&
                                          (loginReducer.userType ===
                                            ROLE_STATES_ATTORNEY ||
                                            loginReducer.userType ===
                                              ROLE_SA_ADMIN) &&
                                          complaintMain.complaint.caseStatus ===
                                            LF_SEND_TO_SA_STATUS
                                            ? countItem.saApprovalStatus !==
                                                LF_REJECTED_STATUS && (
                                                <TableActionComponent
                                                  tootltip="Reject"
                                                  children={
                                                    <RiCloseCircleFill />
                                                  }
                                                  color="text-danger"
                                                  actionFunction={() =>
                                                    approveRejectCount(
                                                      countItem.kountId,
                                                      LF_REJECTED_STATUS
                                                    )
                                                  }
                                                  backgroundStyle="cutom-table-button"
                                                  isDisable={!isEnableCounts}
                                                />
                                              )
                                            : ""}
                                        </Row>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <div
                                    className="form-control p-2 overflow-auto rounded"
                                    style={{
                                      height: "4em",
                                      backgroundColor: "#e9ecef",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: countItem.chargeNarrative,
                                    }}
                                  ></div>
                                </Col>
                              </Row>
                            </div>
                          ))}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mt-4 mb-2 text-md-end">
                      <ButtonComponent
                        variant="primary"
                        onClick={witnessModalShow}
                        disabled={!isEnableBtnAddWitness}
                      >
                        Add Witness
                      </ButtonComponent>
                    </div>
                    <Card border="info">
                      <Card.Body>
                        <Row>
                          <Col md={12}>
                            <h4 className="m-0 p-0">Witness</h4>
                            <span className="title-text">
                              <p>
                                Number of Witness in this Case :{" "}
                                {witnessList.length}
                              </p>
                            </span>
                          </Col>
                        </Row>
                        {witnessList.length > 0 &&
                          witnessList.map((witnessItem: any) => (
                            <Row className="mt-2 fw-bold">
                              <Col md={2}>
                                <FaCaretRight />
                                <b>
                                  <label className="text-secondary">
                                    {witnessItem.complainingWitness
                                      ? "Complaining"
                                      : ""}
                                  </label>
                                </b>
                              </Col>

                              <Col md={4}>
                                <label className="mt-2">
                                  {" "}
                                  {witnessItem.firstName}{" "}
                                  {witnessItem.middleName}{" "}
                                  {witnessItem.lastName}
                                </label>
                              </Col>
                              <Col md={3}>
                                <div className="mt-2">
                                  <label className="text-secondary">
                                    {witnessItem.addressLine1},
                                    {witnessItem.addressLine2}
                                    {witnessItem.city},{witnessItem.state},
                                    {witnessItem.zip},{witnessItem.country}
                                  </label>
                                </div>
                              </Col>

                              <Col md={3}>
                                <Row
                                  className="text-center"
                                  style={{ width: "300px" }}
                                >
                                  <TableActionComponent
                                    tootltip="Edit"
                                    children={<RiDraftFill size={18} />}
                                    actionFunction={() =>
                                      editCount(
                                        witnessItem.witnessId,
                                        "witness"
                                      )
                                    }
                                    backgroundStyle="cutom-table-button"
                                    isDisable={!isEnableWitness}
                                  />
                                  <TableActionComponent
                                    tootltip="Delete"
                                    children={<FaTrashAlt size={18} />}
                                    actionFunction={() =>
                                      deleteCount(
                                        witnessItem.witnessId,
                                        "witness"
                                      )
                                    }
                                    color="text-danger"
                                    backgroundStyle="cutom-table-button"
                                    isDisable={!isEnableWitness}
                                  />
                                </Row>
                              </Col>
                            </Row>
                          ))}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Card border="info">
                      <Card.Body>
                        <Row>
                          <Col md={12}>
                            <div className="title-text">
                              <h4>Note</h4>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-2 fw-bold">
                          <Col md={12}>
                            <TextAreaComponent
                              rows={3}
                              maxLength={2000}
                              name="notes"
                              value={complaint.notes}
                              onBlur={onBlurNotes}
                              onChange={onChangeNotes}
                              onFocus={() => {
                                dispatch(
                                  updateElementValueComplaintMainReducer({
                                    elementName: "successMessage",
                                    value: "",
                                  })
                                );
                              }}
                              disabled={!isTxtComplaintNote}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="text-start">
                    {isVisibleBtnDeleteCaseNumber ? (
                      <>
                        <Button className="btn-danger mt-1">
                          Delete case number
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnUndoCertification ? (
                      <>
                        <Button
                          className="btn-danger mt-1"
                          onClick={undoCertifyClick}
                        >
                          Undo Certification
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnReturnToOfficer ? (
                      <>
                        <Button
                          className="btn-danger mt-1"
                          onClick={returnToOfficer}
                        >
                          Return to Officer
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col className="text-center">
                    {isVisibleBtnArrestWarrant ? (
                      <>
                        <Button
                          className="btn-success mt-1"
                          onClick={handleArrestWarrantClick}
                        >
                          Arrest Warrant
                        </Button>{" "}
                      </> ): ""
                    }
                    {isVisibleBtnSummon ?
                      <>
                        <Button className="btn-success mt-1" onClick={handleSummonClick}>
                          Summons
                        </Button>{" "}
                      </> : ""
                    }
                    {isVisibleBtnPcStatement ?(
                      <>
                        <Button
                          className="btn-success mt-1"
                          onClick={handleProbableCauseClick}
                        >
                          Probable Cause Statement
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnSendToSA ? (
                      <>
                        <Button
                          className="btn-success mt-1"
                          onClick={() => handleSendTo()}
                        >
                          {btnSendToSA}
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnCertification &&
                    !complaint.certificationIndicator ? (
                      <>
                        <Button
                          className="btn-success mt-1"
                          onClick={certifyClick}
                        >
                          Certification
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnAssignCaseNumber ? (
                      <>
                        <Button className="btn-success mt-1">
                          Assign Case Number
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnFileWithCC ? (
                      <>
                        <Button
                          className="btn-success mt-1"
                          onClick={() =>
                            fileWithCClerkOrSubmitSupervisorReview("fileWithCC")
                          }
                        >
                          File with circuit clerk
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnSubmitToJudge ? (
                      <>
                        <Button
                          className="btn-success mt-1"
                          onClick={reSubmitToJudgeClick}
                        >
                          Submit to judge
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnSubmitForReview ? (
                      <>
                        <Button
                          className="btn-success mt-1"
                          onClick={() =>
                            fileWithCClerkOrSubmitSupervisorReview(
                              "submitForReview"
                            )
                          }
                        >
                          Submit for Supervisor Review
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnReSubmitImg ? (
                      <>
                        <Button
                          className="btn-success mt-1"
                          onClick={() => onResubmitImageToCms()}
                        >
                          Re-submit image to CMS
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnReSubmitComplaint ? (
                      <>
                        <Button
                          className="btn-success mt-1"
                          onClick={() => onResubmitComplaint()}
                        >
                          Re-submit
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnReGenerateDocuments ? (
                      <>
                        <Button
                          className="btn-success mt-1"
                          onClick={() => onReGenerateComplaint()}
                        >
                          Re-generate documents
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col className="text-end">
                    {isVisibleBtnViewRedactedCopy ? (
                      <>
                        <Button
                          className="btn-dark mt-1"
                          onClick={() => ViewRedactedCopyClick()}
                        >
                          Redacted copy
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {isVisibleBtnViewCasePDFs ? (
                      <>
                        <Button
                          className="btn-dark mt-1"
                          onClick={() => previewPdf(false)}
                        >
                          Print Preview
                        </Button>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    <Button
                      className="btn-dark mt-1"
                      onClick={() => navigate("/EComplaintDashboard")}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <CountPopup
        show={countModalShow}
        onHide={cancelModalShow}
        status={status}
      />
      <LeaderCountPopup
        show={isLookupLeaderInfo}
        isLookupLeaderInfo={isLookupLeaderInfo}
        onHide={() => setIsLookupLeaderInfo(false)}
        selectedLeader={selectedLeaders}
      />
      <ConfirmPopup
        children={selectItem.popupMessage}
        show={confirmPopupData}
        onHide={() => setconfirmPopupData(false)}
        isConfirmation={handleConfirmation}
      />
      <ConfirmPopup
        children={confirmText}
        show={confirmReductedPopupData}
        onHide={() => setConfirmReductedPopupData(false)}
        isConfirmation={handleConfirmReductedPopup}
      />
      <WitnessPopup
        show={showWitnessModal}
        onHide={() => setshowWitnessModal(false)}
        status={status}
        isComplient={hasComplainingWit}
        witnessCompId={witnessCompId}
      />
      <AssignCaseNumberPopup
        show={showAsiignCaseNumber}
        onHide={() => setShowAsiignCaseNumber(false)}
        reqCaseNumber={reqCaseNumber}
        scroller={scroller}
        singleCaseVPanel={singleCaseVPanel}
        populateCountyList={populateCountyList}
        assignCaseNUmber={assignCaseNUmber}
        reqMultipleCaseNumbers={reqMultipleCaseNumbers}
        felonycounty={felonycounty}
      />
      {showAddArrestWarrantConfirmPopup && (
        <OptionPopup
          modalClass="modal-35w"
          show={showAddArrestWarrantConfirmPopup}
          header="Confirmation"
          children={
            complaintMain.summonsToAppear ?
            <div className="text-start">
              ON VIEW / WARRANTLESS ARREST has been selected.<br></br>
              <br></br>
              Please click "Continue" to move forward with this selection. For
              an Arrest warrant, please click "Add Arrest Warrant". For a Summon, please
              click "Add Summons".
            </div>
            :
            <div className="text-start">
              ON VIEW / WARRANTLESS ARREST has been selected.<br></br>
              <br></br>
              Please click "Continue" to move forward with this selection. For
              an Arrest warrant, please click "Add Arrest Warrant".
            </div>
          }
          buttons={[
            { name: "CONTINUE", variant: "primary", onClick: onContinueClick },
            {
              name: "ADD ARREST WARRANT",
              variant: "danger",
              onClick: onAddArrestWarrantClick,
            },
            complaintMain.summonsToAppear?
            {
              name: "ADD SUMMONS",
              variant: "danger",
              onClick: onAddSummonsClick,
            }:null
          ].filter(Boolean)}
        />
      )}
      {showCertificationPopup && (
        <CertificationPopup
          show={showCertificationPopup}
          onHide={() => setShowCertificationPopup(false)}
          afterCertifying={afterCertifying}
        />
      )}
      {(showModifyComplaintConfirmPopup.show ||
        showModifyComplaintWithArrestWarrantConfirmPopup) && (
        <OptionPopup
          show={
            showModifyComplaintConfirmPopup.show ||
            showModifyComplaintWithArrestWarrantConfirmPopup
          }
          header="Confirmation"
          children={
            <div className="text-start">
              Please complete all demographic details before submitting this to
              the Judge.
            </div>
          }
          buttons={[
            {
              name: "Modify Complaint",
              variant: "primary",
              onClick: onModifyComplaintConfirmClick(showModifyComplaintConfirmPopup.form),
            },
            {
              name: "Cancel",
              variant: "danger",
              onClick: onModifyComplaintConfirmPopupCancelClick,
            },
          ]}
        />
      )}
      {showUndoCertificationConfirmPopup && (
        <ConfirmPopup
          children={"Do you really want to undo certification?"}
          show={showUndoCertificationConfirmPopup}
          onHide={() => setShowUndoCertificationConfirmPopup(false)}
          isConfirmation={continueOnUndoCertification}
        />
      )}
      {(fillingProcessConfirmationModalShow === "showWithHeader" ||
        fillingProcessConfirmationModalShow === "showWithoutHeader") && (
        <OptionPopup
          show={
            fillingProcessConfirmationModalShow === "showWithHeader" ||
            fillingProcessConfirmationModalShow === "showWithoutHeader"
          }
          header={
            fillingProcessConfirmationModalShow === "showWithHeader"
              ? "After Certifying"
              : ""
          }
          children={
            <div className="text-start">
              Once filed, a case cannot be updated or deleted.<br></br>
              <br></br>
              Do you want to file the case with the circuit clerk, or return to
              the Case Complaint Screen?
            </div>
          }
          buttons={[
            {
              name: "Return",
              variant: "danger",
              onClick: onFillingReturnClick,
            },
            {
              name: "File with Circuit Clerk",
              variant: "primary",
              onClick: onFileWithCircuitClerkClick,
            },
          ]}
        />
      )}
      {(supervisorReviewConfirmationModalShow === "showWithHeader" ||
        supervisorReviewConfirmationModalShow === "showWithoutHeader") && (
        <OptionPopup
          show={
            supervisorReviewConfirmationModalShow === "showWithHeader" ||
            supervisorReviewConfirmationModalShow === "showWithoutHeader"
          }
          header={
            supervisorReviewConfirmationModalShow === "showWithHeader"
              ? "After Certifying"
              : ""
          }
          children={
            <div className="text-start">
              Once submitted, a case cannot be updated or deleted.<br></br>
              <br></br>
              Do you want to submit the case for supervisor review, or return to
              the Case Complaint Screen?
            </div>
          }
          buttons={[
            {
              name: "Return",
              variant: "danger",
              onClick: onSupervisorReviewingReturnClick,
            },
            {
              name: "Submit for Supervisor Review",
              variant: "primary",
              onClick: onSupervisorReviewClick,
            },
          ]}
        />
      )}
      <InfoPopup
        children={showError.error}
        onHide={() =>
          setShowError({
            show: false,
            error: "",
          })
        }
        show={showError.show}
        variant="danger"
      />
      <ConfirmPopup
        children={"Do you want to return the complaint to the officer?"}
        show={showReturnToOfficerConfirmPopup}
        onHide={() => setShowReturnToOfficerConfirmPopup(false)}
        isConfirmation={continueOnReturnToOfficer}
      />
      <ConfirmPopup
        show={rejectCount}
        onHide={() => setRejectCount(false)}
        isConfirmation={confirmRejectCount}
      >
        Do you really want to reject this count?
      </ConfirmPopup>
      <ConfirmPopup
        children={message}
        show={showEditConfirmation}
        onHide={() => setShowEditConfirmation(false)}
        isConfirmation={continueEdit}
      />
      <ConfirmPopup
        children={
          "You are about to submit this complaint and arrest warrant to the Judge for signature."
        }
        show={showResubmitToJudge}
        onHide={() => setShowResubmitToJudge(false)}
        isConfirmation={confirmReSubmitToJudgeClick}
      />
      <ConfirmPopup
        children={
          "Do you want to save the image in the system with the generated one?"
        }
        show={showRegenerateDoc}
        onHide={() => setShowRegenerateDoc(false)}
        isConfirmation={regenerateDocAfterConfirm}
      />
      <ConfirmPopup
        children={
          "Do you want to override the image in the system with the generated one?"
        }
        show={showSaveDocAgain}
        onHide={() => setShowSaveDocAgain(false)}
        isConfirmation={saveDocAgainAfterConfirm}
      />
      <OptionPopup
        show={getWitnessNameInsidepopup}
        header="Warning"
        children={getWitnessNameInsidepopupMessage}
        buttons={[
          {
            name: "CHANGE",
            variant: "success",
            onClick: () => setGetWitnessNameInsidepopup(false),
          },
          {
            name: "IGNORE",
            variant: "secondary",
            onClick: () => ignoregetWitnessNameInsidepopupMessage(),
          },
        ]}
      />
    </>
  );
};
export default EComplaintMainPage;

