import React, { useCallback, useEffect, useState } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import Header from "../common/Header";
import { useAppDispatch } from "../../hooks/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import {
  updateElementValuetemplateReducer,
  useTemplateReducer,
  getTemplateDataById,
  createUpdateTemplate,
  resetTemplateData,
} from "../../redux/slice/templateSlice";
import { useCountyListReducer } from "../../redux/slice/countyListSlice";
import {
  ROLE_COUNTY_ADMIN,
  SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER,
  FIELDS_BLANK_ORDER,
} from "../../constants/common";
import { useLoginReducer } from "../../redux/slice/loginSlice";

let defaultCountySchema = yup.object().shape({
  templateBlankCountyId: yup.number().min(1, "County is required."),
  templateName: yup.string().required("Template Name is required."),
  orderTitle: yup.string().required("Order Title is required."),
  orderInfo: yup.string().required("Order Info is required."),
});

interface BlankOrderTempTemProps {}

const BlankOrderTempTemp: React.FC<BlankOrderTempTemProps> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const templateReducer = useTemplateReducer();
  const [errorKeys, setErrorKeys] = useState([""]);
  const errorMessageAlert = useAlert();
  const { countyList } = useCountyListReducer();
  const location = useLocation();
  const { userType } = useLoginReducer();

  const handleSubmit = async () => {
    try {
      await defaultCountySchema.validate(templateReducer, {
        abortEarly: false,
      });

      let templateObj = {
        templateId: 0,
        countyIdParam: templateReducer.templateBlankCountyId,
        templateName: templateReducer.templateName,
        warrantFormName: SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER,
        templateField: FIELDS_BLANK_ORDER,
        templateValue: [templateReducer.orderTitle, templateReducer.orderInfo],
      };

      let fetchType;
      if (location.pathname.split("/")[1].includes("edit")) {
        fetchType = "PUT";
        templateObj.templateId = templateReducer.templateId;
      } else {
        fetchType = "POST";
      }
      dispatch(createUpdateTemplate({ templateObj, fetchType, navigate }));
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const handleInputChange = (e: any) => {
    dispatch(
      updateElementValuetemplateReducer({
        elementName: e.target.name,
        value: e.target.value,
      })
    );
  };

  const handleCkEditorValChng = (name: any, editor: any) => {
    dispatch(
      updateElementValuetemplateReducer({
        elementName: name,
        value: editor.getData(),
      })
    );
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const handleSelectChange = (name: string) => (e: any) => {
    dispatch(
      updateElementValuetemplateReducer({
        elementName: name,
        value: e.value,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetTemplateData());
    };
  }, []);

  useEffect(() => {
    dispatch(
      updateElementValuetemplateReducer({
        elementName: "templateType",
        value: SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER,
      })
    );
  }, []);

  useEffect(() => {
    const templateId = location.pathname.split("/")[2];
    if (templateId) {
      dispatch(resetTemplateData());
      dispatch(getTemplateDataById(templateId));
    }
  }, [dispatch, location, navigate]);

  useEffect(() => {
    if (templateReducer.templateError) {
      handleError([], [templateReducer.templateError]);
      dispatch(
        updateElementValuetemplateReducer({
          elementName: "templateError",
          value: "",
        })
      );
    }
  }, [templateReducer.templateError, dispatch, handleError]);

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/TemplateList", itemName: "Template List" },
  ];

  const data = [
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- SELECT --", value: 0, Judicial: "" },
          ...countyList.map((county: any) => {
            return {
              label: county.countyName,
              value: county.countyID,
              Judicial: county.judicialNumberText,
            };
          }),
        ],
        value: countyList
          .map((county: any) => {
            return {
              label: county.countyName,
              value: county.countyID,
              Judicial: county.judicialNumberText,
            };
          })
          .find(
            (item) =>
              item.value ===
              (location.pathname.split("/")[2]
                ? templateReducer.templateBlankCountyId
                : 0)
          ),
        onChange: handleSelectChange("templateBlankCountyId"),
        placeholder: "Select County",
        name: "templateBlankCountyId",
        defaultValue: { label: "-- SELECT --", value: 0, Judicial: "" },
        isError: errorKeys.includes("templateBlankCountyId"),
        isDisabled:
          location.pathname.split("/")[1].includes("edit") ||
          (location.pathname.split("/")[1].includes("copy") &&
            userType === ROLE_COUNTY_ADMIN),
      },
      colSize: 4,
      label: "County",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        placeholder: "Enter Template Name",
        onChange: handleInputChange,
        name: "templateName",
        value: templateReducer.templateName,
        disabled: location.pathname.split("/")[1].includes("edit") || false,
        isError: errorKeys.includes("templateName"),
      },
      colSize: 4,
      label: "Template Name",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        placeholder: "Enter Template type",
        onChange: handleInputChange,
        name: "templateType",
        disabled: true,
        value: templateReducer.templateType,
      },
      colSize: 4,
      label: "Template type",
      inLine: true,
    },
    {
      type: "editor",
      props: {
        data: templateReducer.orderTitle ? templateReducer.orderTitle : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("orderTitle", editor);
        },
        isError: errorKeys.includes("orderTitle"),
      },
      colSize: 12,
      label: "Order Title",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.orderInfo ? templateReducer.orderInfo : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("orderInfo", editor);
        },
        isError: errorKeys.includes("orderInfo"),
      },
      colSize: 12,
      label: "Order Information",
    },

    {
      type: "button",
      props: {
        children: location.pathname.split("/")[1].includes("edit")
          ? "Update"
          : "Save",
        className: "btn-primary ms-2",
        onClick: handleSubmit,
      },
    },
    {
      type: "button",
      props: {
        children: "Cancel",
        className: "btn-danger ms-2",
        onClick: () => {
          navigate(-1);
        },
      },
    },
  ];

  return (
    <>
      <Header headerName="Blank Order" />
      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="Blank Order" />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data}
          subHeader="Blank Order Form"
        />
      </div>
    </>
  );
};

export default BlankOrderTempTemp;
