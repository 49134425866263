import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { updateElementValue } from "../../utils/updateElementValue";

interface CommonState {
  loadingCount: number;
  showPopup: boolean;
  showSavingGif: boolean;
  concurrentPopup : boolean;
  concurrentMsg : string;
  pdfgenerationerror: boolean;
  pdfgenerationErrorMsg: string;
  docGenerateError: boolean;
  docGenerateErrorMsg: any;
}

const initialState = {
  loadingCount: 0,
  showPopup: false,
  showSavingGif: false,
  concurrentPopup : false,
  concurrentMsg : "No messages",
  pdfgenerationerror: false,
  pdfgenerationErrorMsg: "No message",
  docGenerateError: false,
  docGenerateErrorMsg: undefined,
} as CommonState;

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    showHideLoadingGif: (
      state: CommonState,
      action: PayloadAction<boolean>
    ) => {
      if (action.payload) {
        state.loadingCount += 1;
        state.showPopup = true;
      } else {
        if (state.loadingCount === 1) {
          state.loadingCount = 0;
          state.showPopup = false;
        } else if (state.loadingCount > 0){
          state.loadingCount = state.loadingCount - 1;
        }
      }
    },
    showHideSavingGif: (state: CommonState, action: PayloadAction<boolean>) => {
      state.showSavingGif = action.payload;
    },
    updateElementValueInCommonReducer: updateElementValue,
    updatePdfGenerationError: (
      state: CommonState,
      action: PayloadAction<{ error: boolean; errorMsg: string }>
    ) => {
      state.pdfgenerationerror = action.payload.error;
      state.pdfgenerationErrorMsg = action.payload.errorMsg;
    },
    updateDocGenerateError: (
      state: CommonState,
      action: PayloadAction<{ error: boolean; errorMsg: any }>
    ) => {
      state.docGenerateError = action.payload.error;
      state.docGenerateErrorMsg = action.payload.errorMsg;
    },
  },
});

export const {
  showHideLoadingGif,
  showHideSavingGif,
  updateElementValueInCommonReducer,
  updatePdfGenerationError,
  updateDocGenerateError,
} = commonSlice.actions;

export const  useCommonReducer = () => useAppSelector((state) => state.common);

export default commonSlice.reducer;
