import React, { useState, useEffect } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";
import { useComplaintReducer } from "../../../redux/slice/complaintSlice";
import { useVideoConferenceReducer } from "../../../redux/slice/videoConferenceSlice";
import VideoConferenceSplitScreenMenu from "./VideoConferenceSplitScreenMenu";

type VideoConferenceSplitScreenPopUpProps = {
  show: boolean;
  onHide: () => void;
  isTestWebCam: boolean;
};
export default function VideoConferenceSplitScreenPopUp({
  show,
  onHide,
  isTestWebCam,
}: VideoConferenceSplitScreenPopUpProps) {
  const [scale, setScale] = useState(1);
  const [showSpliter, setShowSpliter] = useState(true);
  const [headerTxt, setHeaderTxt] = React.useState("");
  const { videoConferenceURL, videoConferenceStatus } = useVideoConferenceReducer();
  const caseDetailReducer = useCaseDetailReducer();
  const ecomplaintReducer = useComplaintReducer();
  const handleDownload = () => { };
  const handlePrint = () => { };


  useEffect(() => {
    if (caseDetailReducer.searchWarrantId > 0) {
      const { caseNumber, countyName, searchWarrantId } = caseDetailReducer;
      if (searchWarrantId > 0)
        setHeaderTxt("Video Conference - " + countyName + " - " + caseNumber);
      else {
        const { caseNumber, complaintNumber, countyName } = ecomplaintReducer.complaintObject;
        let number = caseNumber ? caseNumber : complaintNumber;
        setHeaderTxt("Video Conference - " + countyName + " - " + number);
      }
    }
  }, [caseDetailReducer.searchWarrantId]);

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      backdrop="static"
      dialogClassName="modal-95w"
    >
      <Modal.Header closeButton>
        <div className="d-none d-lg-block position-fixed text-wrap" style={{ width: '29%' }}>
          {isTestWebCam ? "Test Webcam" : headerTxt} </div>
        <VideoConferenceSplitScreenMenu
          handleDownload={handleDownload}
          handleZoomIn={() => setScale(scale + 0.05)}
          handleZoomOut={() => setScale(scale - 0.05)}
          handleReset={() => setScale(1)}
          handlePrint={handlePrint}
          handleSplitScreen={() => setShowSpliter(!showSpliter)}
          showSpliter={showSpliter}
        />
      </Modal.Header>
      <Modal.Body
        className="modal-body-video"
        style={{
          minHeight: "78vh",
        }}
      >
        <div>
          <Container
            fluid
            style={{
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Row>
              <Col className="text-center">
                <div
                  style={{
                    height: "calc(80vh - 50px)",
                  }}
                >
                  <iframe
                    width="100%"
                    height="100%"
                    src={videoConferenceURL}
                    frameBorder="0"
                    allow="microphone; camera *"
                    allowFullScreen={true}
                    title="Video Conference"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal.Body>
      <Modal.Footer> &nbsp; </Modal.Footer>
    </Modal>
  );
}
