import { Col, Modal, Row } from "react-bootstrap";
import TextInputComponent from "./TextInputComponent";
import ButtonComponent from "./ButtonComponent";
import { useAppDispatch } from "../../hooks/hooks";
import {
  checkSecurityPassword,
  resetOrRemovePasswordToForm,
  resetSecurityPasswordReducer,
  updateElementSecurityPasswordObjectValue,
  useSecurityPasswordReducer,
} from "../../redux/slice/securityPasswordSlice";
import { useCallback, useEffect, useState } from "react";
import ErrorMessageAlert, { useAlert } from "./Alert";
import * as yup from "yup";
import { isValidPassword } from "./CommonUtil";
import { ERROR_OCCURRED_RESET_PASSWORD } from "../../constants/common";
import { updateElementValueInSearchWarrantListReducer } from "../../redux/slice/searchWarrantListSlice";
import { useNavigate } from "react-router-dom";

let defaultSecurityPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .ensure()
    .when("passwordValidation", {
      is: true,
      then: yup.string().required("Password is required"),
    }),
  confirmPassword: yup
    .string()
    .ensure()
    .when("confirmPasswordValidation", {
      is: true,
      then: yup.string().required("Confirm Password is required"),
    }),
  match: yup
    .string()
    .ensure()
    .when("matchValidation", {
      is: true,
      then: yup
        .string()
        .required("Password and Confirm Password does not match"),
    }),
  validPassword: yup
    .string()
    .ensure()
    .when("isValidEnteredPassword", {
      is: true,
      then: yup
        .string()
        .required(
          "Password must contain at least 8 characters including at least one special character and one numeric value"
        ),
    }),
});

interface securityProps {
  complaintId: number;
  caseNumber: string;
  action: string;
}

interface SecurityPasswordProps {
  show: boolean;
  onHide: () => void;
  accessToComplaint: (searchWarrantId: number) => void;
  submitPassword: (password: string, isPasswordRest: boolean) => void;
  securityProps: securityProps;
}

const SecurityPassword: React.FC<SecurityPasswordProps> = (props) => {
  const { show, onHide, securityProps, submitPassword, accessToComplaint } =
    props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { securityPasswordObject } = useSecurityPasswordReducer();
  const errorMessageAlert = useAlert();
  const [errorKeys, setErrorKeys] = useState([""]);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleValChange = (e: any) => {
    if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    } else {
      dispatch(
        updateElementSecurityPasswordObjectValue({
          elementName: "password",
          value: e.target.value,
        })
      );
    }
  };

  const savePassword = async (e: any) => {
    try {
      let Ob = validation();
      await defaultSecurityPasswordSchema.validate(Ob, { abortEarly: false });
        let Obj = {
          searchWarrantid: securityProps.complaintId,
          password: securityPasswordObject.password,
          navigate : navigate
        };
        let data = await dispatch(resetOrRemovePasswordToForm(Obj));
      cancelPopup();
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const compare = async (e: any) => {
    handleError([], []);
    try {
      let Ob = validation();
      await defaultSecurityPasswordSchema.validate(Ob, { abortEarly: false });

      let Obj = {
        searchWarrantid: securityProps.complaintId,
        password: securityPasswordObject.password,
      };
      let passwordCorrect = await dispatch(checkSecurityPassword(Obj));
      if (passwordCorrect.payload) {
        dispatch(
          updateElementSecurityPasswordObjectValue({
            elementName: "password",
            value: "",
          })
        );
        accessToComplaint(securityProps.complaintId);
        cancelPopup();
      } else {
        handleError(["password"], ["Password is incorrect"]);
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const cancelPopup = () => {
    dispatch(resetSecurityPasswordReducer());
    setConfirmPassword("");
    setErrorKeys([""]);
    handleError([], []);
    onHide();
  };

  const validation = () => {
    let passwordValidation = false;
    let confirmPasswordValidation = false;
    let matchValidation = false;
    let isValidEnteredPassword = false;


    if (securityProps.action === "SAVE" || securityProps.action === "RESET" ) {
      if (securityPasswordObject.password !== confirmPassword) {
        matchValidation = true;
      }
      if (!confirmPassword) {
        confirmPasswordValidation = true;
      }
      if (!securityPasswordObject.password) {
        passwordValidation = true;
      }
      isValidEnteredPassword = !isValidPassword(
        securityPasswordObject.password
      );
      
    } else if (securityProps.action !== "REMOVE") {
      if (!securityPasswordObject.password) {
        passwordValidation = true;
      }
    }
    let Obj = {
      passwordValidation: passwordValidation,
      confirmPasswordValidation: confirmPasswordValidation,
      matchValidation: matchValidation,
      isValidEnteredPassword: isValidEnteredPassword,
    };
    return Obj;
  };
  let displayText =
    securityProps.action === "CHECK"
      ?
      <>
        <div className="text-danger pb-3">This search warrant is password proteced. Please contact the officer who created this search warrant to obtain that password</div>
        <div>Please Enter the Password to Access this Search Warrant Complaint</div>
      </>
      : securityProps.action === "SAVE"
        ? <>Please Enter the Password to Protect this Search Warrant Complaint</>
        : securityProps.action === "RESET"
          ? <>Please enter the new Password</>
          : <>Remove the Protected Password</>;
  return (
    <Modal
      show={show}
      dialogClassName="modal-30w"
      onHide={cancelPopup}
      centered
      backdrop="static"
    >
      <Modal.Header>
        <h5>
          <b>Security Password</b>
        </h5>
      </Modal.Header>
      <ErrorMessageAlert
        messages={errorMessageAlert.messages}
        clearError={errorMessageAlert.clearError}
      />
      <Modal.Body>
        <div className="p-3">
          <label className="text-center">
            <b>{displayText}</b>
          </label>
          <div className="pt-4">
            <label>Case Number</label>
            <TextInputComponent
              inLine={true}
              name="caseNumber"
              value={securityProps.caseNumber}
              disabled={true}
            />
          </div>
          {securityProps.action === "REMOVE" ? (
            ""
          ) : securityProps.action !== "CHECK" ? (
            <>
              <div className="pt-3">
                <label>Enter Password</label>
                <TextInputComponent
                  type="password"
                  inLine={true}
                  name="password"
                  onChange={handleValChange}
                  value={securityPasswordObject.password}
                  isError={errorKeys.includes("password" || "match")}
                />
              </div>
              <div className="pt-3">
                <label>Confirm Password</label>
                <TextInputComponent
                  inLine={true}
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleValChange}
                  isError={errorKeys.includes("confirmPassword" || "match")}
                />
              </div>
            </>
          ) : (
            <div className="pt-3">
              <label>Enter Password</label>
              <TextInputComponent
                type="password"
                inLine={true}
                name="password"
                onChange={handleValChange}
                value={securityPasswordObject.password}
                isError={errorKeys.includes("password" || "match")}
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          {securityProps.action !== "CHECK" ? (
            <Col className="text-end p-1" xs={"auto"}>
              <ButtonComponent variant="primary" onClick={savePassword}>
                {securityProps.action === "REMOVE"
                  ? "Remove Password"
                  : securityProps.action === "RESET"
                  ? "Reset Password"
                  : securityProps.action === "SAVE"
                  ? "Set Password"
                  : ""}
              </ButtonComponent>
            </Col>
          ) : (
            ""
          )}
          {securityProps.action === "CHECK" ? (
            <Col className="text-end p-1" xs={"auto"}>
              <ButtonComponent variant="primary" onClick={compare}>
                Access the Complaint
              </ButtonComponent>
            </Col>
          ) : (
            ""
          )}
          {securityProps.action !== "SAVE" ? (
            <Col className="text-end p-1" xs={"auto"}>
              <ButtonComponent
                variant="danger"
                onClick={cancelPopup}
              >
                Cancel
              </ButtonComponent>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default SecurityPassword;