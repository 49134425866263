import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VC_CONFERENCE_TYPE_JITSI } from "../../constants/common";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";

interface VideoConferenceType {
  videoConferenceURL: string;
  videoConferenceStatus: LoadingType;
  videoConferenceError?: string;
}
const initialState: VideoConferenceType = {
  videoConferenceURL: "",
  videoConferenceStatus: "idle",
};

export const getVideoConferenceURL = createAsyncThunk<
  any,
  { agencyCode: string; caseNumber: string; searchWarrantId: number; ecomplaintId: number; },
  { state: RootState; rejectValue: string }
>(
  "videoConference/getVideoConferenceURL",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { agencyCode, caseNumber, searchWarrantId, ecomplaintId } = data;
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url:
          "videoconferenceservice/videoConferenceURL/" +
          VC_CONFERENCE_TYPE_JITSI + "/" + agencyCode + "/" + caseNumber + "/" + searchWarrantId + "/" + ecomplaintId,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const videoConferenceURL = await response.text();
        return {
          videoConferenceURL,
        };
      } else {

        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while getting video conference URL"
          );
        }
      } catch (error: any) {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(
          error.message || "Error while getting video conference URL"
          );
        }
  }
);


export const getTestVideoConferenceURL = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: string }
>(
  "videoConference/getTestVideoConferenceURL",
  async (countyId, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url:
          "videoconferenceservice/testVideoConferenceURL/" +
          VC_CONFERENCE_TYPE_JITSI +
          "/" +
          countyId,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const videoConferenceURL = await response.text();
        return {
          videoConferenceURL,
        };
      } else {

        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while getting video conference URL"
          );
        }
        } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while getting video conference URL"
      );
    }
  }
);

const videoConferenceSlice = createSlice({
  name: "videoConference",
  initialState,
  reducers: {
    updateElementValueVideoConferenceReducer: updateElementValue,
  },
  extraReducers(builder) {
    builder
      .addCase(getVideoConferenceURL.pending, (state) => {
        state.videoConferenceStatus = "loading";
      })
      .addCase(getVideoConferenceURL.fulfilled, (state, action) => {
        state.videoConferenceStatus = "success";
        state.videoConferenceURL = action.payload.videoConferenceURL;
      })
      .addCase(getVideoConferenceURL.rejected, (state, action) => {
        state.videoConferenceStatus = "error";
        state.videoConferenceError = action.payload;
      })

      .addCase(getTestVideoConferenceURL.pending, (state) => {
        state.videoConferenceStatus = "loading";
      })
      .addCase(getTestVideoConferenceURL.fulfilled, (state, action) => {
        state.videoConferenceStatus = "success";
        state.videoConferenceURL = action.payload.videoConferenceURL;
      })
      .addCase(getTestVideoConferenceURL.rejected, (state, action) => {
        state.videoConferenceStatus = "error";
        state.videoConferenceError = action.payload;
      });
  },
});

export const { updateElementValueVideoConferenceReducer } =
  videoConferenceSlice.actions;

export const useVideoConferenceReducer = () =>
  useAppSelector((state) => state.videoConference);

export default videoConferenceSlice.reducer;
