import React from "react";
import { Col, Row, Alert } from "react-bootstrap";

type AlertProps = {
  variant?: "success" | "danger" | "warning" | "info";
  message: string;
  onClose: () => void;
} & React.HTMLAttributes<HTMLDivElement>;
export default function AlertMessage({
  message,
  variant = "success",
  ...props
}: AlertProps) {
  return !!message ? (
    <Row>
      <Col className="m-2">
        <Alert show={!!message} {...{ variant }} {...props} dismissible>
          <div>
            <label>{message}</label>
          </div>
        </Alert>
      </Col>
    </Row>
  ) : (
    <div />
  );
}
