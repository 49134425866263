import React, { useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useAppDispatch } from '../../hooks/hooks';
import {
  getAgencyAdminNameByAgency,
  getNewUserAgencies,
  updateElementValueAgencyListReducer,
  useAgencyListReducer
} from '../../redux/slice/agencyListSlice';
import ErrorMessageAlert, { useAlert } from '../common/Alert';
import FormComponent from '../common/FormComponent';
import ButtonComponent from '../common/ButtonComponent';
import AlertMessage from '../common/AlertMessage';
import { mapToSelectOptions } from '../../utils/common';

interface NewAgencyRegPopupProps {
    show: boolean;
    onHide: () => void;
}
export const NewAgencyRegPopup: React.FC<NewAgencyRegPopupProps> = (props) => {
    const {show, onHide} = props;
    const errorMessageAlert = useAlert();
    const dispatch = useAppDispatch();
    const { agencyList , userList, getAgencyAdminNameByAgencyIdFetchStatus} = useAgencyListReducer();
    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const [alertColor, setAlertColor] = React.useState(false);
    const [showMsg, setShowMsg] = React.useState(false);

    useEffect(() => {
        dispatch(getNewUserAgencies());
    }, [dispatch]);

    useEffect(() => {
        let adminuserList: any = [];
        if(getAgencyAdminNameByAgencyIdFetchStatus === "success"){
            if(userList && userList.length > 0) {
                setShowMsg(true);
                userList.forEach((user: any) => {
                    setAlertColor(true);
                    adminuserList.push(<li>{user.username}</li>)
                })
            } else {
                setShowMsg(false);
                setAlertColor(false);
                adminuserList.push(<li>No agency admin available for this agency. Please contact County administrator</li>)
            }
            setAlertMessage(adminuserList);
        }
    }, [userList, getAgencyAdminNameByAgencyIdFetchStatus]);

    const agencyAdminByAgency = (obj: any) => {
        const agencyId = obj.value;
        dispatch(getAgencyAdminNameByAgency({agencyId}));      
    }
    const alertonClose = () => {
        onHide();
        setAlertMessage("");
        setShowMsg(false);
        dispatch(updateElementValueAgencyListReducer({ elementName: "getAgencyAdminNameByAgencyIdFetchStatus", value: "idle", }));
        dispatch(updateElementValueAgencyListReducer({ elementName: "userList", value: [], })); 
    }

    const data = [
        {
            type: "selectInput",
            props: {
                className : "fw-bold",
                labelName : <label>Agency<span>*</span></label>,
                isSelected: true,
                isLabeled : true,
                options: [
                    {label: "--SELECT--", value: 0},
                     ...mapToSelectOptions(agencyList, "agencyName", "agencyId"),
                ],
                placeholder: "Select Agency",
                name: "agencyId",
                defaultValue: { label: "--SELECT--", value: 0 },
                onChange: agencyAdminByAgency,
            },
        }, 
    ]

    return(
        <Modal
             show={show}
             dialogClassName="modal-40w"
             centered
             onHide={() => onHide()}
             backdrop='static'>
             <Modal.Header>
                <Modal.Title>
                    <b>New Agency User Registration</b>
                </Modal.Title>
             </Modal.Header>
            <ErrorMessageAlert
                messages={errorMessageAlert.messages}
                clearError={errorMessageAlert.clearError}
            />
            <Modal.Body>
                <FormComponent
                     totalColumns={12}
                     colSize={12} 
                     componentData={data} />
                      {showMsg?
                      <Row>Please contact your agency administrator listed below to create a login to this site.</Row>
                      :''
                    }
            </Modal.Body>
           <Modal.Footer>
                <Row className='w-100'>
                    <Col>
                        <AlertMessage
                            variant={alertColor ? 'info' : 'danger'}
                            message={alertMessage}
                            onClose={() => setAlertMessage("")}
                        />
                    </Col>
                </Row>
                <Row className='w-100'>
                    <Col  className='text-end'>
                        <ButtonComponent variant='danger' href='#' onClick={() => alertonClose()}>Close</ButtonComponent>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>

    );
}

