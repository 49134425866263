import React, { useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { LF_SEND_TO_OFFICER_STATUS } from '../../constants/common';
import { useAppDispatch } from '../../hooks/hooks';
import {
  addModifyEcCountsEcomplaint,
  getCMSIntegratedFelonyCaseNumber,
  insertEcomplaintData,
  updateElementComplaintObjectValue,
  updateElementValueComplaintMainReducer,
  useComplaintMainReducer
} from '../../redux/slice/complaintMainSlice';
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { convertCaseNumber, isFelonyCount, validCaseNumber } from '../../utils/common';
import ErrorMessageAlert, { useAlert } from "../common/Alert"
import AlertMessage from '../common/AlertMessage';
import TableComponent from '../common/TableComponent';
import TextInputComponent from '../common/TextInputComponent';
import { useNavigate } from 'react-router-dom';
import ConfirmPopup from '../common/ConfirmPopup';

interface AssignCaseNumberPopupProps {
  show: boolean;
  onHide: () => void;
  reqCaseNumber?: boolean;
  assignCaseNUmber?: boolean;
  singleCaseVPanel?: boolean;
  scroller?: boolean;
  setGlassEnabled?: boolean;
  setAutoHideEnabled?: boolean;
  felonycounty?: boolean;
  populateCountyList?: boolean;
  reqMultipleCaseNumbers?: boolean;
  txtAssignCaseNumber?: string;
  assignCaseNumbershow?: boolean;

}

export const AssignCaseNumberPopup: React.FC<AssignCaseNumberPopupProps> = (props) => {

  const { show, onHide, populateCountyList, reqMultipleCaseNumbers, scroller } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useLoginReducer();
  const errorMessageAlert = useAlert();
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    showpopup: false,
    popupMessage: ""
  });
  const tableHeader = ["Count Number", "Violation Code", "Violation Description", "Class", "Case Type", "Case Number"];
  const complaintMain = useComplaintMainReducer();
  const { complaint, countList, reqCmsCaseNumberError } = useComplaintMainReducer();
  const [txtAssignCaseNumber, setTxtAssignCaseNumber] = React.useState({
    placeholder: "",
    value: complaint.caseNumber
  });
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [erralertMessage, setErrAlertMessage] = React.useState<string>("");
  const [caseCountyList, setCaseCountyList] = React.useState(countList);
  const [validatedCaseNumber, setValidatedCaseNumber] = React.useState("");

  useEffect(() => {
    if (props.felonycounty) {
      if (complaintMain.countyIntegratedWithCMS) {
        setTxtAssignCaseNumber((txtAssignCaseNumber) => ({
          ...txtAssignCaseNumber,
        }));
      } else {
        setTxtAssignCaseNumber((txtAssignCaseNumber) => ({
          ...txtAssignCaseNumber,
          placeholder: "Please enter the Felony case # if you have one",
        }));
      }
    } else {
      setTxtAssignCaseNumber((txtAssignCaseNumber) => ({
        ...txtAssignCaseNumber,
        placeholder: "Please enter the case number",
      }));
    }
  }, [show])

  useEffect(() => {
    if (reqCmsCaseNumberError && reqCmsCaseNumberError.length > 0) {
      setErrAlertMessage(reqCmsCaseNumberError);
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [reqCmsCaseNumberError]);

  useEffect(() => {
    setCaseCountyList(countList);
  }, countList);

  const caseNumberchange = (countNumber: number, e: any) => {
    const newTodos = caseCountyList.map((county: any) => {
      if (county.countnumber === countNumber) {

        let generatedcaseNum = convertCaseNumber(e.target.value);
        let caseNum = validCaseNumber(generatedcaseNum, true);
        if (caseNum) {
          return { ...county, caseNumber: generatedcaseNum };
        } else {
          return { ...county, caseNumber: generatedcaseNum };
        }
      } else {
        return { ...county };
      }
    });
    let newtestval = newTodos
    setCaseCountyList(newtestval);
  }

  const handlePrimaryCaseNoBlur = (event: any) => {
    let caseNum;
    let message = "";
    let generatedcaseNum = convertCaseNumber(event.target.value);
    caseNum = validCaseNumber(generatedcaseNum, true);
    if (caseNum) {
      event.target.value = generatedcaseNum;
      setValidatedCaseNumber(generatedcaseNum);
      message = "";
    } else {
      message = "Case Number is invalid.";
      event.target.value = generatedcaseNum;
      setValidatedCaseNumber(generatedcaseNum);
    }
    if (scroller) {
      dispatch(updateElementValueComplaintMainReducer({ elementName: "countList", value: caseCountyList }));
    }
    setErrAlertMessage(message);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  const validate = () => {
    let message = "";
    let isValid;

    if (props.singleCaseVPanel) {
      if (props.felonycounty) {
        if (validatedCaseNumber !== null) {
          isValid = validCaseNumber(validatedCaseNumber as string, true);
          if (!isValid) {
            message = "Case Number is invalid.";
          }
        }
      } else {
        isValid = validCaseNumber(validatedCaseNumber as string, true);
        if (!isValid) {
          message = "Case Number is invalid.";
        }
      }
      if (message.length > 0) {
        setErrAlertMessage(message);
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
        return false;
      } else {
        setErrAlertMessage(message);
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
        return true;
      }
    }
    if (props.scroller) {
      caseCountyList.map((county: any) => {
        if (props.felonycounty) {
          if (county.caseNumber !== null) {
            isValid = validCaseNumber(county.caseNumber as string, true);
            if (!isValid) {
              message = "Case Number is invalid.";
            }
          }
        } else {
          isValid = validCaseNumber(county.caseNumber as string, true);
          if (!isValid) {
            message = "Case Number is invalid.";
          }
        }
      });
      if (message.length > 0) {
        setErrAlertMessage(message);
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
        return false;
      }
      else {
        setErrAlertMessage(message);
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
        return true;
      }
    }
  }

  const sendToOfficer = (_caseNumber: any, setCaseNumber: boolean) => {
    let _complaint = { ...complaint };
    if (complaint) {
      _complaint.caseNumber = validatedCaseNumber;
      _complaint.caseStatus = LF_SEND_TO_OFFICER_STATUS;
      dispatch(insertEcomplaintData(
        {
          _complaint,
          navigate: navigate,
          navigateTo: "/EComplaintDashboard"
        }
      ));
    }
  }

  const ecKountListsendToOfficer = (countList: any) => {
    if (complaintMain.complaint && countList != null) {
      let _complaint = { ...complaint };
      _complaint.caseNumber = countList[0].caseNumber;
      _complaint.caseStatus = LF_SEND_TO_OFFICER_STATUS;
      dispatch(addModifyEcCountsEcomplaint(
        {
          complaint: _complaint,
          addModifyEcomplaint: true,
          countList: countList,
        }));
      let data = dispatch(insertEcomplaintData(
        {
          _complaint,
          navigate: navigate,
          navigateTo: "/EComplaintDashboard"
        }
      ));
      if (!('error' in data)) {
        onCloseAssingNUmberpopup();
      }
    }
  }
  
  const onBtnAssignCaseClick = () => {
    if (!validate()) {
      return;
    }
    if (!handlePrimaryCaseNoBlur) {
      return;
    }
    if (props.singleCaseVPanel) {
      if (props.felonycounty) {
        sendToOfficer(validatedCaseNumber, true);
      }
    }
    if (props.scroller) {
      if (props.felonycounty) {
        ecKountListsendToOfficer(countList);
      }
    }
    sendToOfficer(validatedCaseNumber, true);
  }  

  const reqMultipleCaseNumbersfunc = () => {

    setconfirmPopupData({
      showpopup: true,
      popupMessage: `Not yet implemented `
    });
  }

  const requestCaseNumber = async () => {
    if (complaint) {
      if (getHighestFelonyCount()) {
        const felonyCaseType = getHighestFelonyCount().caseType;
        let responseCaseNumer = await getCMSIntegratedFelonyCaseNumber(complaint.ecomplaintId, felonyCaseType, (token ? token : ""), dispatch);
        setValidatedCaseNumber(responseCaseNumer);
      }
    }
  }

  const onCloseAssingNUmberpopup = () => {
    dispatch(updateElementComplaintObjectValue({ elementName: "caseNumber", value: "" }));
    setValidatedCaseNumber("");
    const newTodos = countList.map((county: any) => {
      return { ...county, caseNumber: "" }
    });

    let newtestval = newTodos;
    setCaseCountyList(newtestval);
    dispatch(updateElementValueComplaintMainReducer({ elementName: "countList", value: newtestval }))
    setTxtAssignCaseNumber((txtAssignCaseNumber) => ({
      ...txtAssignCaseNumber,
      value: "",
    }));
    onHide();
  } 

  let tableData = countList.map(({
    countnumber,
    violationCode,
    violationDescription,
    classOfViolation,
    caseType

  }: any) => [
      countnumber,
      violationCode,
      violationDescription,
      classOfViolation,
      caseType,
    ]
  );
  const getHighestFelonyCount = () => {
    for (let i: number = 0; i < countList.length; i++) {
      if ((countList[i].countnumber === 1) && isFelonyCount(countList[i].caseType, countList[i].classOfViolation)) {
        return countList[i];
      }
      return null;
    }
  }
  const renderTableRow = (row: any[]) => {
    const [
      countNumber,
      violationcode,
      violationDescription,
      classOfViolation,
      caseType
    ] = row;
    return (
      <tr>
        <td  key={countNumber}>{countNumber}</td>
        <td>{violationcode}</td>
        <td>{violationDescription}</td>
        <td>{classOfViolation}</td>
        <td>{caseType}</td>
        <td>
          <TextInputComponent
            type="text"
            className="form-control"
            name="caseNumber"
            onBlur={(e) => handlePrimaryCaseNoBlur(e)}
            onChange={(e) => caseNumberchange(countNumber, e)}
          />
        </td>
      </tr>
    );
  };
  return (
    <> 
      <Modal size={scroller ? 'xl' : 'lg'}
          show={show}
          dialogClassName="custom-modal"
          centered
          onHide={() => onHide()}
          backdrop='static'>
          <Modal.Header>
            <Modal.Title>
              <b>Assign Case Number</b>
            </Modal.Title>
            <AlertMessage
              message={alertMessage}
              onClose={() => setAlertMessage("")}
              variant={"warning"}
            />
            <AlertMessage
              message={erralertMessage}
              onClose={() => setErrAlertMessage("")}
              variant={"danger"}
            />
          </Modal.Header>
          <ErrorMessageAlert
            messages={errorMessageAlert.messages}
            clearError={errorMessageAlert.clearError} />
          <Modal.Body>
            <div style={{ display: "flex" }}>
              {props.reqCaseNumber ?
                <><TextInputComponent
                  type="text"
                  className="form-control"
                  placeholder="Case Number"
                  name="caseNumber"
                  value={validatedCaseNumber}
                  disabled={true}
                />
                  <br />
                  <div>
                    <Button style={{ marginLeft: '1rem' }}
                      onClick={() => { requestCaseNumber() }}
                    >  Request CF case #
                    </Button>
                  </div></> : ""}
              {props.assignCaseNUmber ?
                <>
                  <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formInlineName">
                      <Form.Label column md={3}>
                        Case Number :
                      </Form.Label>
                      <Col sm={9}>
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          placeholder={txtAssignCaseNumber.placeholder}
                          name="caseNumber"
                          style={{ width: "500px" }}
                          onBlur={(e) => handlePrimaryCaseNoBlur(e)}
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </>
                : ""}

              {reqMultipleCaseNumbers ?
                <Button style={{ marginLeft: "auto" }}
                  onClick={() => {
                    reqMultipleCaseNumbersfunc();
                  }}
                > Request CF case Numbers
                </Button> : ""}
            </div>
            {populateCountyList ?
              <TableComponent
                tableHeader={tableHeader}
                tableData={tableData}
                renderTableRow={renderTableRow} />
              : ""}

          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => { onBtnAssignCaseClick() }}
            >
              SEND TO OFFICER
            </Button>
            <>  </>
            <Button
              variant="danger"
              onClick={() => { onCloseAssingNUmberpopup() }}>
              CANCEL
            </Button>
          </Modal.Footer>
        </Modal>
      <ConfirmPopup
        children={confirmPopupData.popupMessage}
        show={confirmPopupData.showpopup}
        onHide={() => setconfirmPopupData({
          popupMessage: "",
          showpopup: false
        })}
        isConfirmation={() => setconfirmPopupData({
          popupMessage: "",
          showpopup: false
        })}
      />
    </>
  );
}

