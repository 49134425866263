import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import { useCountyListReducer } from "../../../redux/slice/countyListSlice";
import Alert, { useAlert } from "../../common/Alert";
import SelectComponent from "../../common/SelectComponent";
import {
  resetCountiesData,
  updateElementValueCountiesReducer,
} from "../../../redux/slice/countyListSlice";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";

type VideoTestSelectionPopupProps = {
  show: boolean;
  onHide: () => void;
  handleContinue: (id: number) => void;
};

export default function VideoTestSelectionPopup({
  show,
  onHide,
  handleContinue,
}: VideoTestSelectionPopupProps) {
  const {countyList,localCounty,localCountyID,loggedInUserCountyFetchStatus} = useCountyListReducer();
  const alert = useAlert();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCountiesData());
    };
  }, [dispatch]);

  const handleSelectChange = (name: string) => (e: any) => {
    dispatch(
      updateElementValueCountiesReducer({
        elementName: "localCounty",
        value: e.label,
      })
    );
    dispatch(
      updateElementValueCountiesReducer({
        elementName: "localCountyID",
        value: e.value,
      })
    )
  };

  const CountyListOptions: any = [];
  if (countyList.length ! > 1) {
    CountyListOptions.push({ label: "-- All --", value: {} });
  }
  CountyListOptions.push(
    ...countyList.map((county: any) => {
      return { label: county.countyName, value: county.countyID };
    })
  );

  useEffect(() => {
    if (loggedInUserCountyFetchStatus == "success"){
      getAvailableCountyIds();
    }
  }, [loggedInUserCountyFetchStatus]);

  const getAvailableCountyIds = () => {
    if (countyList.length === 1) {
      dispatch(
        updateElementValueCountiesReducer({
          elementName: "localCounty",
          value: CountyListOptions[0].label,
        })
      );
      dispatch(
        updateElementValueCountiesReducer({
          elementName: "localCountyID",
          value: CountyListOptions[0].value,
        })
      )
    }
  };

  const handleContinueClick = () => {
    if (!localCountyID) {
      alert.handleError(["Please select a county"]);
    } else {
      handleContinue(localCountyID);
    }
  };
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <b>Select County</b>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Alert {...alert} />
        </Row>
        <Row md={12}>
          <SelectComponent
            options={CountyListOptions}
            value= {CountyListOptions.find((item: any) => {
              if (item.label === localCounty) {
                return { label: item.label, value: item.value };
              }
            })}
            onChange={handleSelectChange("county")}
            isError={alert.messages.length > 0}
          />
        </Row>
        <hr />
        <Row className="g-2">
          <Col>
            <Button
              variant="primary"
              className="w-100"
              onClick={handleContinueClick}
            >
              <RiCheckFill /> Continue
            </Button>
          </Col>
          <Col>
            <Button variant="danger" onClick={() => onHide()} className="w-100">
              <RiCloseFill /> Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
