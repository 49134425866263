import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { AgencyType } from "./agencySlice";
import { getCountiesByUserRole } from "../../services/CountyService";
import { getAgenciesByUserRole, getAgencyListByCounty } from "../../services/AgencyService";
import { getCodeTableEntryList } from "../../services/CodeTableEntryService";

interface SearchWarrantListState {
  countyList: any[];
  loadLoggedInUserCountyFetchStatus: LoadingType;
  loadLoggedInUserCountyError?: string;
  agencyList: AgencyType[];
  loadLoggedInUserAgencyFetchStatus: LoadingType;
  loadLoggedInUserAgencyError?: string;
  loadAgencyListByCountyFetchStatus: LoadingType;
  loadAgencyListByCountyError?: string;
  statusList: any[];
  loadStatusListFetchStatus: LoadingType;
  loadStatusListError?: string;
  issueTimeList: any[];
  loadIssuedTimeListFetchStatus: LoadingType;
  loadIssuedTimeListError?: string;

  countyListReady: boolean;
  agencyListReady: boolean;
  statusListReady: boolean;
  issueTimeListReady: boolean;

  searchParameters: any;
  didMount: boolean;

  documentList: any[];
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  fetchDocumentListStatus: LoadingType;
  fetchDocumentListError?: string;
  localAgency:string;
  localCounty:string;
  warrantStatus:string;
  agencyId:number;
  countyId:number;
  warrantCase:string;
  caseOrComplaintNumber:string;
  formType:string;
  issueTime:string;
  sortBy:string;
  agencyCaseNumber: string,
  officerId: number, //KHL PR 11294 13/02/24

  responseMessage: string;
  responseErrorMessage: string;
}
const initialState = {
  countyList: [],
  loadLoggedInUserCountyFetchStatus: "idle",
  loadLoggedInUserCountyError: undefined,
  agencyList: [],
  loadLoggedInUserAgencyFetchStatus: "idle",
  loadLoggedInUserAgencyError: undefined,
  loadAgencyListByCountyFetchStatus: "idle",
  loadAgencyListByCountyError: undefined,
  statusList: [],
  loadStatusListFetchStatus: "idle",
  loadStatusListError: undefined,
  issueTimeList: [],
  loadIssuedTimeListFetchStatus: "idle",
  loadIssuedTimeListError: undefined,

  countyListReady: false,
  agencyListReady: false,
  statusListReady: false,
  issueTimeListReady: false,

  searchParameters: undefined,
  didMount: false,

  documentList: [],
  currentPage: 0,
  totalPages: 0,
  totalRecords: 0,
  fetchDocumentListStatus: "idle",
  localAgency:"",
  localCounty:"",
  warrantStatus:"",
  agencyId:0,
  countyId:0,
  warrantCase:"",
  caseOrComplaintNumber:"",
  formType:"",
  issueTime:"",
  sortBy:"",
  agencyCaseNumber:"",
  officerId: 0, //KHL PR 11294 13/02/24

  responseMessage: "",
  responseErrorMessage: "",
} as SearchWarrantListState;

export const loadLoggedInUserCounties = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "counties/loadLoggedInUserCounties",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await getCountiesByUserRole({ token });
      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else { 
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching logged in user county list");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching logged in user county list"
      );
    }
  }
);

export const loadLoggedInUserAgencies = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "agencies/loadLoggedInUserAgencies",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await getAgenciesByUserRole({ token });
      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(
          "Error while fetching logged in user agencies list"
        );
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        "Error while fetching logged in user agencies list"
      );
    }
  }
);

export const loadAgencyListByCounty = createAsyncThunk<
  any,
  { countyId?: number; },
  { state: RootState; }
>("selectCountyAgency/loadAgencyListByCounty", async (data, { getState, rejectWithValue, dispatch }) => {
  let { countyId } = data;
  let { token } = getState().login;
  dispatch(showHideLoadingGif(true));
  try {
    const reponse = await getAgencyListByCounty({
      countyId: countyId,
      token: token
    });
    dispatch(showHideLoadingGif(false));
    return await reponse.json();
  } catch (error) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error);
  }
});

export const loadStatusList = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string; }
>(
  "codeTableEntry/getStatusList",
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const respons = await getCodeTableEntryList({
        token: token,
        type: "statusList",
      });
      if (respons.ok) {
        dispatch(showHideLoadingGif(false));
        return respons.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching status list from code table entries.");
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching status list from code table entries.");
    }
  }
);

export const loadIssuedTimeList = createAsyncThunk<
  any,
  undefined,
  {
    state: RootState;
    rejectValue: string;
  }
>(
  "codeTableEntry/getIssuedTimeList",
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const respons = await getCodeTableEntryList({
        token: token,
        type: "issuedTimeList",
      });
      dispatch(showHideLoadingGif(false));
      if (respons.ok) {
        return respons.json();
      }else {
        
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching warrant issue time duration list.");
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching warrant issue time duration list.");
    }
  }
);

export const listDocuments = createAsyncThunk<
  any,
  {
    pageNo: number;
    pageSize: number;
    caseNumber: string;
    countyId: number;
    agencyId: number;
    issueTime: string;
    formType: string;
    status: string;
    lfcNumber: string;
    sortBy: string;
    agencyCaseNumber?:string;
    officerId?: number; //KHL PR 11294 13/02/24
  },
  { state: RootState; rejectValue: string }
>(
  "searchWarrantList/listDocuments",
  async (
    {
      pageNo,
      pageSize,
      agencyId,
      countyId,
      caseNumber,
      formType,
      issueTime,
      lfcNumber,
      status,
      sortBy,
      agencyCaseNumber,
      officerId, //KHL PR 11294 13/02/24
    },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    let queryParam = "";
    queryParam += `pageNo=${pageNo}&`;
    if (pageSize) queryParam += `pageSize=${pageSize}&`;
    if (caseNumber) queryParam += `caseNumber=${caseNumber}&`;
    queryParam += `countyId=${countyId}&`;
    queryParam += `agencyId=${agencyId}&`;
    if (issueTime) queryParam += `issueTime=${issueTime}&`;
    if (formType) queryParam += `formType=${formType}&`;
    if (status) queryParam += `status=${status}&`;
    if (lfcNumber) queryParam += `lfcNumber=${lfcNumber}&`;
    if (sortBy) queryParam += `sortBy=${sortBy}&`;
    if (agencyCaseNumber) queryParam += `agencyCaseNumber=${agencyCaseNumber}&`;
    queryParam += `officerId=${officerId}`; //KHL PR 11294 13/02/24
    dispatch(showHideLoadingGif(true));
    try {
      const response:any = await doFetch({
        url: "docrootservices/listSearchDocuments?" + queryParam,
        token,
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const searchWarrantList = await response.json();
        return searchWarrantList;
      } else {
        
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching search warrant list");
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching search warrant list");
    }
  }
)

export const listPendingSearchDocuments = createAsyncThunk<
  any,
  {
    pageNo: number;
    pageSize: number;
    caseNumber: string;
    countyId: number;
    agencyId: number;
    issueTime: string;
    formType: string;
    status: string;
    lfcNumber: string;
    sortBy: string;
    agencyCaseNumber?:string;
    officerId?:number; //KHL PR 11294 13/02/24
  },
  { state: RootState; rejectValue: string }
>(
  "searchWarrantList/listPendingSearchDocuments",
  async (
    {
      pageNo,
      pageSize,
      agencyId,
      countyId,
      caseNumber,
      formType,
      issueTime,
      lfcNumber,
      status,
      sortBy,
      agencyCaseNumber,
      officerId //KHL PR 11294 13/02/24
    },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    let queryParam = "";
    queryParam += `pageNo=${pageNo}&`;
    if (pageSize) queryParam += `pageSize=${pageSize}&`;
    if (caseNumber) queryParam += `caseNumber=${caseNumber}&`;
    queryParam += `countyId=${countyId}&`;
    queryParam += `agencyId=${agencyId}&`;
    if (issueTime) queryParam += `issueTime=${issueTime}&`;
    if (formType) queryParam += `formType=${formType}&`;
    if (status) queryParam += `status=${status}&`;
    if (lfcNumber) queryParam += `lfcNumber=${lfcNumber}&`;
    if (sortBy) queryParam += `sortBy=${sortBy}&`;
    if (agencyCaseNumber) queryParam += `agencyCaseNumber=${agencyCaseNumber}`;
    queryParam += `officerId=${officerId}`; //KHL PR 11294 13/02/24
    dispatch(showHideLoadingGif(true));
    try {
      const response:any = await doFetch({
        url: "docrootservices/listPendingSearchDocuments?" + queryParam,
        token,
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const searchWarrantList = await response.json();
        return searchWarrantList;
      } else {
        
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching search warrant list");
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching search warrant list");
    }
  }
)

export const downloadSearchWarrantSearchList = createAsyncThunk<
  any,
  {
    pageNo: number;
    pageSize: number;
    caseNumber: string;
    countyId: number;
    agencyId: number;
    issueTime: string;
    formType: string;
    status: string;
    lfcNumber: string;
    sortBy: string;
    searchWarrantIds?: any[];
    agencyCaseNumber?: string;
  },
  { state: RootState; rejectValue: string }
>("searchWarrantList/downloadSearchWarrantSearchList", async (data, { getState, rejectWithValue, dispatch }) => {
  let { token } = getState().login;
  const { pageNo, pageSize, caseNumber, countyId, agencyId, issueTime, formType, status, lfcNumber, sortBy, searchWarrantIds, agencyCaseNumber} = data;

  let queryParam = "";
  queryParam += `pageNo=${pageNo}&`;
  if (pageSize) queryParam += `pageSize=${pageSize}&`;
  if (caseNumber) queryParam += `caseNumber=${caseNumber}&`;
  queryParam += `countyId=${countyId}&`;
  queryParam += `agencyId=${agencyId}&`;
  if (issueTime) queryParam += `issueTime=${issueTime}&`;
  if (formType) queryParam += `formType=${formType}&`;
  if (status) queryParam += `status=${status}&`;
  if (lfcNumber) queryParam += `lfcNumber=${lfcNumber}&`;
  if (sortBy) queryParam += `sortBy=${sortBy}&`;
  if (searchWarrantIds) queryParam += `searchWarrantIds=${searchWarrantIds}&`;
  if (agencyCaseNumber) queryParam += `agencyCaseNumber=${agencyCaseNumber}`;
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "docrootservices/downloadsearchwarrantsearchlist?" + queryParam,
      token,
      type: "POST",
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      await response.blob().then((blob) => {
        let fileName = "WarrantsListExcel";
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName + ".xlsx";
        a.click();
        return {
          message: "Download Search warrant Search List successfully",
        };
      });
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while downloading search list");
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Error while downloading search list");
  }
});

export const downloadSearchWarrantSearchWorkReport = createAsyncThunk<
  any,
  {
    countyId: number;
    documentType: string;
  },
  { state: RootState; rejectValue: string }
>("searchWarrantList/downloadSearchWarrantSearchWorkReport", async (data, { getState, rejectWithValue, dispatch }) => {

  const { countyId, documentType  } = data;
  let { token } = getState().login;

  let queryParam = "";
  queryParam += `countyId=${countyId}&`;
  queryParam += `documentType=${documentType}`;

  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "docrootservices/downloadSearchWarrantSearchWorkReport?" + queryParam,
      token,
      type: "POST",
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      await response.blob().then((blob) => {
        if (blob && blob.size > 0) {
          let fileName = "SAReport";
          let url;
          if(documentType === "PDF"){
            url = URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName + ".pdf";
            a.click();
          }else if(documentType === "EXCEL"){
            url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName + ".xlsx";
            a.click();
          }
          return {
            message: "Download States Attorney Search Warrant Work Report successfully",
          };
        }
      });
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while downloading States Attorney Search Warrant Work Report");
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Error while downloading States Attorney Search Warrant Work Report");
  }
});

export const searchWarrantListSlice = createSlice({
  name: "searchWarrantList",
  initialState,
  reducers: {
    updateElementValueInSearchWarrantListReducer: updateElementValue,
    updateSearchWarrantListReducer : (state : SearchWarrantListState, action) => {
      return {
        ...state,
        countyId: action.payload.countyId,
        agencyId: action.payload.agencyId,
        localCounty: action.payload.localCounty,
        localAgency: action.payload.localAgency,
        sortBy: action.payload.sortBy,
        warrantCase: action.payload.warrantCase,
        warrantStatus: action.payload.warrantStatus,
        issueTime: action.payload.issueTime,
        formType: action.payload.formType,
        caseOrComplaintNumber: action.payload.caseOrComplaintNumber,
        searchParameters : action.payload.searchParameters,
        lfcNumber : action.payload.lfcNumber,
        searchWarrantIds : action.payload.searchWarrantIds,
        officerId : action.payload.officerId //KHL PR 11294 13/02/24
      };
    },
    resetSearchWarrantList: (state: SearchWarrantListState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //loadLoggedInUserCounties
      .addCase(loadLoggedInUserCounties.pending, (state) => {
        state.loadLoggedInUserCountyFetchStatus = "loading";
        state.loadLoggedInUserCountyError = undefined;
      })
      .addCase(loadLoggedInUserCounties.fulfilled, (state, action) => {
        state.loadLoggedInUserCountyFetchStatus = "success";
        state.countyList = action.payload;
      })
      .addCase(loadLoggedInUserCounties.rejected, (state, action) => {
        state.loadLoggedInUserCountyFetchStatus = "error";
        state.loadLoggedInUserCountyError = action.payload;
      })

      //loadLoggedInUserAgencies
      .addCase(loadLoggedInUserAgencies.pending, (state) => {
        state.loadLoggedInUserAgencyFetchStatus = "loading";
        state.loadLoggedInUserAgencyError = undefined;
      })
      .addCase(loadLoggedInUserAgencies.fulfilled, (state, action) => {
        state.loadLoggedInUserAgencyFetchStatus = "success";
        state.agencyList = action.payload;
      })
      .addCase(loadLoggedInUserAgencies.rejected, (state, action) => {
        state.loadLoggedInUserAgencyFetchStatus = "error";
        state.loadLoggedInUserAgencyError = action.payload;
      })

      //loadAgencyListByCounty
      .addCase(loadAgencyListByCounty.pending, (state) => {
        state.loadAgencyListByCountyFetchStatus = "loading";
        state.loadAgencyListByCountyError = undefined;
      })
      .addCase(loadAgencyListByCounty.fulfilled, (state, action) => {
        state.loadAgencyListByCountyFetchStatus = "success";
        state.agencyList = action.payload;
      })
      .addCase(loadAgencyListByCounty.rejected, (state, action) => {
        state.loadAgencyListByCountyFetchStatus = "error";
        state.loadAgencyListByCountyError = undefined;
      })

      //loadStatusList
      .addCase(loadStatusList.pending, (state) => {
        state.loadStatusListFetchStatus = "loading";
        state.loadStatusListError = undefined;
      })
      .addCase(loadStatusList.fulfilled, (state, action) => {
        state.statusList = action.payload;
        state.loadStatusListFetchStatus = "success";
      })
      .addCase(loadStatusList.rejected, (state, action) => {
        state.loadStatusListFetchStatus = "error";
        state.loadStatusListError = action.payload;
      })

      //loadIssuedTimeList
      .addCase(loadIssuedTimeList.pending, (state) => {
        state.loadIssuedTimeListFetchStatus = "loading";
        state.loadIssuedTimeListError = undefined;
      })
      .addCase(loadIssuedTimeList.fulfilled, (state, action) => {
        state.issueTimeList = action.payload;
        state.loadIssuedTimeListFetchStatus = "success";
      })
      .addCase(loadIssuedTimeList.rejected, (state, action) => {
        state.loadIssuedTimeListFetchStatus = "error";
        state.loadIssuedTimeListError = action.payload;
      })

      .addCase(listDocuments.pending, (state) => {
        state.fetchDocumentListStatus = "loading";
      })
      .addCase(listDocuments.fulfilled, (state, action) => {
        state.documentList = action.payload.documentList;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.totalRecords = action.payload.totalRecords;
        state.fetchDocumentListStatus = "success";
      })
      .addCase(listDocuments.rejected, (state, action) => {
        state.fetchDocumentListStatus = "error";
        state.fetchDocumentListError = action.payload;
      })

      //listPendingSearchDocuments
      .addCase(listPendingSearchDocuments.pending, (state) => {
        state.fetchDocumentListStatus = "loading";
      })
      .addCase(listPendingSearchDocuments.fulfilled, (state, action) => {
        state.documentList = action.payload.documentList;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.totalRecords = action.payload.totalRecords;
        state.fetchDocumentListStatus = "success";
      })
      .addCase(listPendingSearchDocuments.rejected, (state, action) => {
        state.fetchDocumentListStatus = "error";
        state.fetchDocumentListError = action.payload;
      })

      .addCase(downloadSearchWarrantSearchList.pending, (state) => {
        state.fetchDocumentListStatus = "loading";
        state.fetchDocumentListError = undefined;
      })
      .addCase(downloadSearchWarrantSearchList.fulfilled, (state, action) => {
        state.fetchDocumentListStatus = "success";
      })
      .addCase(downloadSearchWarrantSearchList.rejected, (state, action) => {
        state.fetchDocumentListStatus = "error";
        state.fetchDocumentListError = undefined;
      })
      .addCase(downloadSearchWarrantSearchWorkReport.pending, (state) => {
        state.fetchDocumentListStatus = "loading";
        state.fetchDocumentListError = undefined;
      })
      .addCase(downloadSearchWarrantSearchWorkReport.fulfilled, (state, action) => {
        state.fetchDocumentListStatus = "success";
      })
      .addCase(downloadSearchWarrantSearchWorkReport.rejected, (state, action) => {
        state.fetchDocumentListStatus = "error";
        state.fetchDocumentListError = undefined;
      })
  },
});
export const useSearchWarrantListReducer = () =>
  useAppSelector((state) => state.searchWarrantList);
export const { updateElementValueInSearchWarrantListReducer, resetSearchWarrantList, updateSearchWarrantListReducer } =
  searchWarrantListSlice.actions;

export default searchWarrantListSlice.reducer;
