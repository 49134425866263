import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconType } from "react-icons";
import { RiArrowRightSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import useToken from "../../hooks/useToken";

const DashBoardCard = (props: {
  link?: string;
  icon: React.ReactElement<IconType>;
  title: string |  React.ReactElement;
  hide?: boolean;
  token: string | string[];
  onClick?: () => void;
  onCardClick?:()=> void;
  tooltip? : string
}) => {
  const isTokenValueAvailable = useToken();
  if (props.hide || !isTokenValueAvailable(props.token)) {
    return null;
  }
  if (props.link) {
    return (
      <OverlayTrigger overlay={props.tooltip? <Tooltip id="tooltip-disabled">{props.tooltip}</Tooltip>:<></>}>
      <Col md={3}>
        <Link to={props.link} className="main-link" onClick={props.onCardClick}>
          <div className="card-box shadow">
            <div className="card">
              <div className="card-inner">
                <div className="card-icon">{props.icon}</div>
                <div className="card-text">
                  <h5>{props.title}</h5>
                </div>
              </div>
              <div className="card-next">
                <RiArrowRightSLine />
              </div>
            </div>
          </div>
        </Link>
      </Col>
      </OverlayTrigger>
    );
  }
  return (
    <OverlayTrigger overlay={props.tooltip? <Tooltip id="tooltip-disabled">{props.tooltip}</Tooltip>:<></>}>
    <Col md={3}>
      <span className="main-link" onClick={props.onClick}>
        <div className="card-box shadow">
          <div className="card">
            <div className="card-inner">
              <div className="card-icon">{props.icon}</div>
              <div className="card-text">
                <h5>{props.title}</h5>
              </div>
            </div>
            <div className="card-next">
              <RiArrowRightSLine />
            </div>
          </div>
        </div>
      </span>
    </Col>
    </OverlayTrigger>
  );
};
export default DashBoardCard;
