import { APPLICATION_AND_ORDER, BLANK_ORDER, ROLE_ADMINISTRATOR, ROLE_ATTORNEY_GENERAL, ROLE_COUNTY_ADMIN, ROLE_LOCAL_PROSECUTOR, ROLE_SA_ADMIN, ROLE_STATES_ATTORNEY, SEARCH_WAR_ASSIGNED_TO_AG_STATUS, SEARCH_WAR_ASSIGNED_TO_LP_STATUS, SEARCH_WAR_ASSIGNED_TO_SA_STATUS, SEARCH_WAR_LOCKED_BY_AG_STATUS, SEARCH_WAR_LOCKED_BY_LP_STATUS, SEARCH_WAR_LOCKED_BY_SA_STATUS } from "../../constants/common";

const reg = /(\w*)\[\[(.*?)]]/gi;

export const zipCodeRegex = /^\d{5}((\-|\s)+\d{4})?$/;
export const phoneNumberRegex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

// export const isEmailValidation = (isEmail:any) =>{
//     return new RegExp("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@" + "[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,8})$").test(isEmail.trim());
// }

export const isEmailValidation = (isEmail: any) => {
  var RegExp = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  var result = isEmail.replace(/\s/g, "").split(/,|;/);
  for (var i = 0; i < result.length; i++) {
    if (!RegExp.test(result[i])) {
      return false;
    }
  }
  return true;
};

export const isValidPhone = (phone: any) => {
  var phoneLocal = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
  var phoneInternational =
    /^[+][0-9] (\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
  if (phone.match(phoneLocal) || phone.match(phoneInternational)) {
    return true;
  } else {
    return false;
  }
};

export const isValidPassword = (userpassword: any) => {
  var password =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  if (userpassword.match(password)) {
    return true;
  } else {
    return false;
  }
};

export const formatPhoneNumber = (phoneNumberString: any) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  if (phoneNumberString.length > 10) {
    let newphone = phoneNumberString.substring(0, 10);
    var cleaned = ("" + newphone).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    return "";
  }
  return phoneNumberString;
};

export const formatPhoneNumberWhileEditing = (phone: any) => {
  let tel = phone;
  tel = tel.replaceAll("-", "");
  if (tel.length > 6) {
    tel =
      tel.substring(0, 3) +
      "-" +
      tel.substring(3, 6) +
      "-" +
      tel.substring(6, 10);
    return tel;
  } else if (tel.length > 3) {
    tel = tel.substring(0, 3) + "-" + tel.substring(3, tel.length);
    return tel;
  } else {
    return tel;
  }
};

export const validateSignaturePixel = (
  signatureCanvasVisible: boolean,
  signThreshold: any
) => {
  if (signatureCanvasVisible) {
    return validateSignPixel(signThreshold);
  }
  return true;
};

export const validateSignPixel = (signThreshold: any) => {
  let canvas: any = document.getElementById("pixelcanvas");
  let ctx = canvas.getContext("2d");
  let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let pix = imageData.data;
  let newColor = { r: 0, g: 0, b: 0, a: 0 };

  for (let i = 0, n = pix.length; i < n; i += 4) {
    let r = pix[i],
      g = pix[i + 1],
      b = pix[i + 2];

    // If its white then change it
    if (r == 255 && g == 255 && b == 255) {
      // Change the white to whatever.
      pix[i] = newColor.r;
      pix[i + 1] = newColor.g;
      pix[i + 2] = newColor.b;
      pix[i + 3] = newColor.a;
    }
  }

  ctx.putImageData(imageData, 0, 0);
  let arrayLength = imageData.data.length;
  let count = 0;
  for (let i = 0; i < arrayLength; i++) {
    let pixel = imageData.data[i];
    if (pixel > 0) {
      count++;
    }
  }
  if (count < signThreshold) return false;
  else return true;
};

export const getValidationError = (field: string, query: any) => {
  const reg = /(\w*)\[\[(.*?)]]/gi;
  let matches: any = {},
    m,
    count = 1,
    errText = "";
  //creating an object that include [[tags]]
  while ((m = reg.exec(query))) {
    if (m[2] == "") {
      matches[""] = m[0];
    } else {
      matches[count] = m[0];
      count++;
    }
  }
  errText += getErrTextForSqureBracketValidation(field, matches);
  return errText;
};

const getErrTextForSqureBracketValidation = (field: string, matches: any) => {
  let SqureBracketErrText = "";
  let values = [];
  values = Object.values(matches);
  //check for null object (a text without squre bracets.)
  if (values.length !== 0) {
    let i;
    SqureBracketErrText +=
      "Information is not entered in " + field + "  field for tags: ";
    for (i = 0; i < values.length; i++) {
      if (i < 3) {
        if (i == 0) {
          SqureBracketErrText += values[i];
        } else {
          SqureBracketErrText += ", " + values[i];
        }
      }
    }
    if (values.length > 3) {
      SqureBracketErrText += " and more tags. \n";
    } else {
      SqureBracketErrText += ". \n";
    }
  }
  return SqureBracketErrText;
};

export const handleUnloadMessage = (e: any) => {
  //var message = "\o/";
  var message = "You will be logged off. Do you want to continue?";

  (e || window.event).returnValue = message; //Gecko + IE
  return message;
};


export const approvalStatusForms = (caseDetail: any, loggedInUserRole: string, loggedInUserId: number, loggedInUserName: string) =>{
  let approvalStatusFormList: any[] = [];
  let onBehalf = caseDetail.onBehalf;
  caseDetail.searchWarrantList.forEach((el:any)=>{
    if ((el.formType === APPLICATION_AND_ORDER || el.formType === BLANK_ORDER) && 
    (isAllowApprove(el.formStatus, el.lockUserName, el.creatorId, onBehalf, loggedInUserRole, loggedInUserId, loggedInUserName))){
      approvalStatusFormList.push(el.searchWarrantId);
    }
  });
  return approvalStatusFormList;
}

function isAllowApprove(
  formStatus: string,
  lockedBy: string,
  creatorID: number,
  onBehalf: boolean,
  userRole: string,
  userId: number,
  userName: string
) {
  if (userRole === ROLE_ADMINISTRATOR) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS
        || formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS || formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS
        || formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) {
        return true;
    }
    return false;
  }
  if (userRole === ROLE_COUNTY_ADMIN) {
    if (
      formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
    ) {
      return true;
    }
  }
  if(userRole === ROLE_SA_ADMIN) {
    if(formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS
        || formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS){
      return true;
    }
  }
  if (
    userRole === ROLE_STATES_ATTORNEY &&
    ((userId && userId !== creatorID) || (creatorID === userId && onBehalf))
  ) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_STATES_ATTORNEY
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) {
        return true;
      }
    }
  }
  if (
    userRole === ROLE_ATTORNEY_GENERAL &&
    ((userId && userId !== creatorID) || (creatorID === userId && onBehalf))
  ) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_ATTORNEY_GENERAL
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS) {
        return true;
      }
    }
  }
  if (
    userRole === ROLE_LOCAL_PROSECUTOR &&
    ((userId && userId !== creatorID) || (creatorID === userId && onBehalf))
  ) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_LOCAL_PROSECUTOR
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) {
        return true;
      }
    }
  }
  return false;
}
