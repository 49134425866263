import { Col } from "react-bootstrap";
import { IconType } from "react-icons";
import { RiArrowRightSLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const EcitationCard = (props: {
  link?: string;
  icon: React.ReactElement<IconType>;
  title: string;
  hide?: boolean;
  onClick?: () => void;
  onCardClick?:()=> void;
}) => {
  if (props.hide) {
    return null;
  }
  if (props.link) {
    return (
      <Col md={3}>
        <Link to={props.link} className="main-link" onClick={props.onCardClick}>
          <div className="card-box shadow">
            <div className="card">
              <div className="card-inner">
                <div className="card-icon">{props.icon}</div>
                <div className="card-text">
                  <h5>{props.title}</h5>
                </div>
              </div>
              <div className="card-next">
                <RiArrowRightSLine />
              </div>
            </div>
          </div>
        </Link>
      </Col>
    );
  }
  return (
    <Col md={3}>
      <span className="main-link" onClick={props.onClick}>
        <div className="card-box shadow">
          <div className="card">
            <div className="card-inner">
              <div className="card-icon">{props.icon}</div>
              <div className="card-text">
                <h5>{props.title}</h5>
              </div>
            </div>
            <div className="card-next">
              <RiArrowRightSLine />
            </div>
          </div>
        </div>
      </span>
    </Col>
  );
};
export default EcitationCard;
