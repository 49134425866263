import React, { useCallback, useEffect, useState } from "react";
import Header from "../../common/Header";
import { Card, Row, Col } from "react-bootstrap";
import ButtonComponent from "../../common/ButtonComponent";
import { RiArrowGoBackFill, RiArrowLeftLine, RiArrowRightSFill, RiBallPenLine, RiCheckboxMultipleFill, RiCloseCircleFill, RiFileTextLine, RiSave3Line, RiSendPlane2Fill } from "react-icons/ri";
import ConfirmPopup from "../../common/ConfirmPopup";
import {
  approveApplicationAndOrder,
  returnApplicationAndOrder,
  saveApplicationAndOrder,
  submitApplicationAndOrder,
  useApplicationAndOrderReducer,
  updateElementValueApplicationAndOrderReducer,
} from "../../../redux/slice/applicationAndOrderSlice";
import {
  ACTIONTYPE_SAVE,
  APPLICATION_AND_ORDER,
  ROLE_ADMINISTRATOR,
  ROLE_AGENCY_ADMIN,
  ROLE_ATTORNEY_GENERAL,
  ROLE_COUNTY_ADMIN,
  ROLE_JUDGE,
  ROLE_LOCAL_PROSECUTOR,
  ROLE_POLICE_OFFICER,
  ROLE_SA_ADMIN,
  ROLE_STATES_ATTORNEY,
  SEARCH_WAR_ASSIGNED_TO_AG_STATUS,
  SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS,
  SEARCH_WAR_ASSIGNED_TO_LP_STATUS,
  SEARCH_WAR_ASSIGNED_TO_SA_STATUS,
  SEARCH_WAR_INCOMPLETE_STATUS,
  SEARCH_WAR_LOCKED_BY_AG_STATUS,
  SEARCH_WAR_LOCKED_BY_JUDGE_STATUS,
  SEARCH_WAR_LOCKED_BY_LP_STATUS,
  SEARCH_WAR_LOCKED_BY_SA_STATUS,
  SEARCH_WAR_OFFICER_PENDING_STATUS,
} from "../../../constants/common";
import { useNavigate } from "react-router-dom";
import { useLoginReducer } from "../../../redux/slice/loginSlice";
import { useAppDispatch } from "../../../hooks/hooks";
import {
  getSearchWarrantData,
  previewOrder,
} from "../../../redux/slice/commonFormSlice";
import { Util } from "../../common/FormUtil";
import RemarkPopup from "../../common/RemarkPopup";
import { useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";
import InfoPopup from "../../common/InfoPopup";
import { resetSignature, setSignature } from "../../../redux/slice/signPanelSlice";
import SignPopup from "../../common/SignPopup";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";

interface ApplicationAndOrderSubmitProps {}

export const ApplicationAndOrderSubmit: React.FC<
  ApplicationAndOrderSubmitProps
> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const applicationAndOrderReducer = useApplicationAndOrderReducer();
  const loginReducer = useLoginReducer();
  const caseDetailReducer = useCaseDetailReducer();

  const [confirmPopupData, setconfirmPopupData] = React.useState({
    isConfirm: false,
    clickButtonName: "",
  });
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [btnRevertVisible , setBtnRevertVisible ] = React.useState(true);
  const [btnSignVisible , setBtnSignVisible ] = React.useState(true);
  const [btnApproveVisible , setBtnApproveVisible ] = React.useState(true);
  const [btnDeniedVisible , setBtnDeniedVisible ] = React.useState(true);
  const [btnSubmitVisible , setBtnSubmitVisible ] = React.useState(true);
  const [btnSaveVisible , setBtnSaveVisible ] = React.useState(true);
  const [btnSubmitLabel, setBtnSubmitLabel] = React.useState("Submit to Judge");
  const [btnRevertLabel, setBtnRevertLabel] = React.useState("Return to Officer");
  const [whoSign, setwhoSign] = React.useState("");
   const [swornText, setawornText] = React.useState("");
  const [remarkModalShow, setRemarkModalShow] = React.useState(false);
  const [remarkRequired, setRemarkRequired] = React.useState(false);
  const [remarkFor, setRemarkFor] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [action, setAction] = React.useState("");
  const [complainantNameFooter, setcomplainantNameFooter] = React.useState("");
  const [type, settype] = React.useState("");
  const [id, setid] = React.useState(Number);
  const [isSignPanelPopup, setisSignPanelPopup] = React.useState(false);
  const errorMessageAlert = useAlert();
  const [errorKeys, setErrorKeys] = useState([""]);

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  useEffect(() => {
    if (applicationAndOrderReducer.aaoError) {
      handleError([], [applicationAndOrderReducer.aaoError]);
      dispatch(
        updateElementValueApplicationAndOrderReducer({
          elementName: "aaoError",
          value: "",
        })
      );
    }
  }, [
    applicationAndOrderReducer.aaoError,
    dispatch,
    handleError,
  ]);

  const [showInfoPopup, setShowInfoPopup] = React.useState({show: false,info: "",});
  let loggedInUserId = loginReducer.userProfileId;
  const handleConfirmation = () => {
    if (confirmPopupData.clickButtonName === "onSubmit") {
      continueOnSubmitProcess();
    } else if (confirmPopupData.clickButtonName === "onSave") {
      continueOnSaveProcess();
    }else if (confirmPopupData.clickButtonName === "onReturn") {
      continueOnReturnProcess();
    }else if (confirmPopupData.clickButtonName === "onApprove") {
      continueOnApproveProcess();
    }else if (confirmPopupData.clickButtonName === "onSign") {
      continueOnSignProcess();
    }
  };
  

  const continueOnSaveProcess = () => {
    const { creatorId } = applicationAndOrderReducer.applicationAndOrderObject;

    const { userProfileId, userType } = loginReducer;

    let status = "";
    if (
      creatorId === userProfileId &&
      (ROLE_POLICE_OFFICER === userType ||
        ROLE_COUNTY_ADMIN === userType ||
        ROLE_ADMINISTRATOR === userType ||
        ROLE_AGENCY_ADMIN === userType)
    ) {
      status = SEARCH_WAR_OFFICER_PENDING_STATUS;
    } else if (
      creatorId == userProfileId &&
      ROLE_STATES_ATTORNEY === userType
    ) {
      status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
    } else if (
      creatorId == userProfileId &&
      ROLE_ATTORNEY_GENERAL === userType
    ) {
      status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
    } else if (
      creatorId == userProfileId &&
      ROLE_LOCAL_PROSECUTOR === userType
    ) {
      status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
    } else {
      status = SEARCH_WAR_INCOMPLETE_STATUS;
    }

    dispatch(
      saveApplicationAndOrder({
        formId:
          applicationAndOrderReducer.applicationAndOrderObject.searchWarrantId,
        status: status,
        navigate: navigate,
        navigateTo : "/CaseDetails"
      })
    );
  };

  const continueOnSubmitProcess = () => {
    try {
      const {
        searchWarrantId,
        creatorId,
        approvalUserType,
        approvalSAId,
        isSARequired,
      } = applicationAndOrderReducer.applicationAndOrderObject;
      const { userProfileId, userType } = loginReducer;


      let status = "";
      if (
        userProfileId === creatorId &&
        (ROLE_STATES_ATTORNEY === userType ||
          ROLE_SA_ADMIN === userType ||
          ROLE_ATTORNEY_GENERAL === userType ||
          ROLE_LOCAL_PROSECUTOR === userType)
      ) {
        status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
      } else {
        if (approvalSAId && approvalSAId > 0) {
          status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
        } else {
          if (approvalUserType && approvalUserType.trim().length > 0) {
            if (
              !isSARequired &&
              ROLE_ATTORNEY_GENERAL !== approvalUserType.trim()
            ) {
              status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
            } else {
              if (ROLE_STATES_ATTORNEY === approvalUserType.trim()) {
                status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
              } else if (ROLE_LOCAL_PROSECUTOR === approvalUserType.trim()) {
                status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
              } else {
                status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
              }
            }
          } else {
            status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
          }
        }
      }

      dispatch(
        submitApplicationAndOrder({ formId: searchWarrantId, status: status, navigate: navigate, navigateTo: "/CaseDetails" })
      );
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const continueOnReturnProcess = () => {
    const {approvalSAId, creatorId, creatorUserType} = applicationAndOrderReducer.applicationAndOrderObject;
    const { userProfileId, userType } = loginReducer;
    let status = "";
    if (
      (userType === ROLE_ADMINISTRATOR || userType === ROLE_JUDGE) &&
      (!approvalSAId || approvalSAId === 0) &&
      userProfileId !== creatorId
    ) {
      if (ROLE_ATTORNEY_GENERAL === creatorUserType) {
        status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
      } else if (ROLE_LOCAL_PROSECUTOR === creatorUserType) {
        status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
      } else {
        status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
      }
    } else {
      status = SEARCH_WAR_OFFICER_PENDING_STATUS;
    }

    let proxy = { formStatus: status, remark: remark }
    let dataObj = {
      searchWarrantId: applicationAndOrderReducer.applicationAndOrderObject.searchWarrantId,
      proxy: proxy,
      navigate: navigate,
      navigateTo: "/CaseDetails",
    };
    dispatch(returnApplicationAndOrder(dataObj));
  };

  const continueOnApproveProcess = () => {
    let proxy = { formStatus: SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS }
    let dataObj = {
      searchWarrantId: applicationAndOrderReducer.applicationAndOrderObject.searchWarrantId,
      proxy: proxy,
      navigate: navigate,
      navigateTo: "/CaseDetails",
    };
    dispatch(approveApplicationAndOrder(dataObj));
  }


  const submitRemark = (remark: string, remarkFor: string) => {
    if ("RETURN" === remarkFor) {
      returnForm(remark);
    }
  }

  const returnForm = (remark : string) => {
    const { formStatus, creatorId, creatorUserType, approvalSAId } = applicationAndOrderReducer.applicationAndOrderObject;
    const { userProfileId, userType } = loginReducer;

    let confirmMsg = Util.getConfirmMessage(ACTIONTYPE_SAVE, APPLICATION_AND_ORDER,
        userProfileId, creatorId, formStatus, userType, creatorUserType, approvalSAId);

        setRemark(remark);
        setconfirmText(confirmMsg);
        setconfirmPopupData({ isConfirm: true, clickButtonName: "onReturn" });
}

  const onReturnClick = (actionName : string) =>{
    setAction(actionName);
    setRemarkRequired(true);
    setRemarkFor("RETURN");
    setRemarkModalShow(true);
  }

  const onApproveClick = () => {
    const { formStatus, creatorId, creatorUserType, approvalSAId } = applicationAndOrderReducer.applicationAndOrderObject;
    const { userProfileId, userType } = loginReducer;

    if (!caseDetailReducer.approvalSAId || caseDetailReducer.approvalSAId === 0) {

      setShowInfoPopup({show: true, info: "Please approve Search Warrant before approving Application and Order form."});
        return;
    }

    let confirmMsg = Util.getConfirmMessage(SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS, APPLICATION_AND_ORDER,
        userProfileId, creatorId, formStatus, userType, creatorUserType, approvalSAId);

        setconfirmText(confirmMsg);
        setconfirmPopupData({ isConfirm: true, clickButtonName: "onApprove" });    
  }

  const onSignClick = () =>{
    setconfirmText("Form cannot be changed once signed. Do you want to continue?");
    setconfirmPopupData({ isConfirm: true, clickButtonName: "onSign" });
  }

  const continueOnSignProcess = () =>{
  let {searchWarrantId} = applicationAndOrderReducer.applicationAndOrderObject;

    dispatch(resetSignature());
    if (loginReducer.userType === ROLE_JUDGE) {
      if (applicationAndOrderReducer.applicationAndOrderObject.hiddenJudgeSignature === ""
        || applicationAndOrderReducer.applicationAndOrderObject.hiddenJudgeSignature === undefined) {
       
          dispatch(setSignature(applicationAndOrderReducer.applicationAndOrderObject.hiddenUserSignature));
      }
    } else if (applicationAndOrderReducer.applicationAndOrderObject.hiddenComplaintSignature === "") {
      dispatch(setSignature(applicationAndOrderReducer.applicationAndOrderObject.hiddenUserSignature))
    }

  setconfirmPopupData({isConfirm: false, clickButtonName: ""});
  setwhoSign(loggedInUserId + " SIGNATURE");
      setawornText(
        "Complainant Signature will be only applied after the content of this complaint is sworn before a judge"
      );
      setcomplainantNameFooter(
        `( ${loginReducer.firstName} ${loginReducer.lastName})`
      );
      settype(APPLICATION_AND_ORDER);
      setid(searchWarrantId);
      setisSignPanelPopup(true);

  //  let dataObj = {
  //   searchWarrantId: applicationAndOrderReducer.applicationAndOrderObject.searchWarrantId,
  //   callFrom: "SubmitPage",
  //   searchWarrantObject: applicationAndOrderReducer.applicationAndOrderObject,
  //   navigate: navigate,
  //   navigateTo: "/CaseDetails",
  // };
  // dispatch(signSearchwarrant(dataObj));
}
  


  const onSubmitButtonClick = () => {
    const { approvalUserType, isSARequired, approvalSAId } =
      applicationAndOrderReducer.applicationAndOrderObject;

    let confirmMsg = "";

    if (approvalSAId && approvalSAId > 0) {
      confirmMsg = "Do you want to submit the form for review to judge?";
    } else {
      if (approvalUserType && approvalUserType.trim().length > 0) {
        if (!isSARequired) {
          if (ROLE_JUDGE === approvalUserType.trim()) {
            confirmMsg = "Do you want to submit the form for review to judge?";
          } else {
            confirmMsg = "Do you want to submit the form for screening?";
          }
        } else {
            confirmMsg = "Do you want to submit the form for screening?";      
        }
      } else {
        confirmMsg = "Do you want to submit the form for review to judge?";
      }
    }

    setconfirmText(confirmMsg);
    setconfirmPopupData({ isConfirm: true, clickButtonName: "onSubmit" });
  };

  const onSaveButtonClick = () => {
    const { creatorId, creatorUserType, approvalSAId } =
      applicationAndOrderReducer.applicationAndOrderObject;

    const { userProfileId, userType } = loginReducer;

    let confirmMsg = Util.getConfirmMessage(
      ACTIONTYPE_SAVE,
      APPLICATION_AND_ORDER,
      userProfileId,
      creatorId,
      "",
      userType,
      creatorUserType,
      approvalSAId
    );

    setconfirmText(confirmMsg);
    setconfirmPopupData({ isConfirm: true, clickButtonName: "onSave" });
  };

  const backClick = () => {
    dispatch(
      getSearchWarrantData({
        searchWarrantId:
          applicationAndOrderReducer.applicationAndOrderObject.searchWarrantId,
        formName: APPLICATION_AND_ORDER,
        navigate: navigate,
      })
    );
  };

  const previewForm = () => {
    dispatch(
      previewOrder({
        formId:
          applicationAndOrderReducer.applicationAndOrderObject.searchWarrantId,
        formName: APPLICATION_AND_ORDER,
        formObject: null,
      })
    );
  };


  useEffect(() => {
    if (applicationAndOrderReducer.applicationAndOrderObject.searchWarrantId > 0) {
      let loggedInUserId = loginReducer.userProfileId;
      let loggedInUserRole = loginReducer.userType;
      let { formStatus, creatorId, approvalSAId, isOnBehalf, approvalUserType, isSARequired, creatorUserType,  } = applicationAndOrderReducer.applicationAndOrderObject;
      formStatus = formStatus ? formStatus : "";

      if (ROLE_ATTORNEY_GENERAL === creatorUserType) {
        setBtnRevertLabel("Return toAG");
      } else if (ROLE_LOCAL_PROSECUTOR === creatorUserType) {
        setBtnRevertLabel("Return to LP");
      } else if (ROLE_STATES_ATTORNEY === creatorUserType) {
        setBtnRevertLabel("Return to SA");
      } else if (ROLE_SA_ADMIN === creatorUserType) {
        setBtnRevertLabel("Return to SA Admin");
      }

      if (!isSARequired || (approvalSAId && approvalSAId > 0)) {
        if (ROLE_ATTORNEY_GENERAL === approvalUserType) {
          setBtnSubmitLabel("Submit for Screening");
        } else {
          setBtnSubmitLabel("Submit to Judge");
        }
      } else if (
        (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
          formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
          formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
          formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
          formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
          formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) &&
        (!approvalSAId || approvalSAId === 0)
      ) {
        setBtnSubmitLabel("Submit to Judge");
      } else {
        setBtnSubmitLabel("Submit for Screening");
      }

      if (loggedInUserRole) {
        if (ROLE_ADMINISTRATOR === loggedInUserRole) {
          if (
            formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
            formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
          ) {
            setBtnRevertVisible(false);
            setBtnSignVisible(false);
            setBtnApproveVisible(false);
            setBtnDeniedVisible(false);
            if (
              formStatus === SEARCH_WAR_INCOMPLETE_STATUS &&
              creatorId !== loggedInUserId
            ) {
              setBtnSubmitVisible(false);
            }
          }
          if (
            formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
          ) {
            setBtnRevertVisible(true);
            setBtnSignVisible(false);
            setBtnApproveVisible(true);
            setBtnSaveVisible(false);
            setBtnDeniedVisible(false);
            setBtnSubmitVisible(false);
          }
          if (
            formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS
          ) {
            setBtnRevertVisible(true);
            setBtnSignVisible(true);
            setBtnApproveVisible(false);
            setBtnSaveVisible(false);
            setBtnDeniedVisible(false);
            setBtnSubmitVisible(false);
            if (creatorId !== loggedInUserId) {
              setBtnSignVisible(false);
            }
          }
        } else if (ROLE_COUNTY_ADMIN === loggedInUserRole) {
          if (
            formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
            formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
          ) {
            setBtnRevertVisible(false);
            setBtnSignVisible(false);
            setBtnApproveVisible(false);
            setBtnDeniedVisible(false);
            if (
              formStatus === SEARCH_WAR_INCOMPLETE_STATUS &&
              creatorId !== loggedInUserId
            ) {
              setBtnSubmitVisible(false);
            }
          }
          if (
            formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
          ) {
            setBtnRevertVisible(true);
            setBtnSignVisible(false);
            setBtnApproveVisible(true);
            setBtnSaveVisible(false);
            setBtnDeniedVisible(false);
            setBtnSubmitVisible(false);
          }
          if (
            formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
            formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS
          ) {
            setBtnRevertVisible(true);
            setBtnSignVisible(true);
            setBtnApproveVisible(false);
            setBtnSaveVisible(false);
            setBtnDeniedVisible(false);
            setBtnSubmitVisible(false);
            if (creatorId !== loggedInUserId) {
              setBtnSignVisible(false);
            }
          }
        } else if (ROLE_AGENCY_ADMIN === loggedInUserRole) {
          setBtnRevertVisible(false);
          setBtnSignVisible(false);
          setBtnApproveVisible(false);
          setBtnDeniedVisible(false);
          if (
            formStatus === SEARCH_WAR_INCOMPLETE_STATUS &&
            creatorId !== loggedInUserId
          ) {
            setBtnSubmitVisible(false);
          }
        } else if (
          ROLE_STATES_ATTORNEY === loggedInUserRole ||
          ROLE_SA_ADMIN === loggedInUserRole ||
          ROLE_ATTORNEY_GENERAL === loggedInUserRole ||
          ROLE_LOCAL_PROSECUTOR === loggedInUserRole
        ) {
          if (creatorId === loggedInUserId) {
            setBtnSubmitLabel("Submit to Judge");
          }
          if (
            formStatus === SEARCH_WAR_INCOMPLETE_STATUS &&
            creatorId === loggedInUserId
          ) {
            setBtnSubmitVisible(true);
            setBtnRevertVisible(false);
            setBtnSignVisible(false);
            setBtnApproveVisible(false);
            setBtnDeniedVisible(false);
          } else if (
            (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
              formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
              formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) &&
            creatorId === loggedInUserId
          ) {
            setBtnSubmitVisible(true);
            setBtnRevertVisible(false);
            setBtnSignVisible(false);
            setBtnApproveVisible(false);
            setBtnDeniedVisible(false);
          } else if (
            (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
              formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
              formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
              formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
              formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
              formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) &&
            creatorId !== loggedInUserId
          ) {
            setBtnSubmitVisible(false);
            setBtnRevertVisible(true);
            setBtnSignVisible(false);
            setBtnSaveVisible(false);
            setBtnApproveVisible(true);
            setBtnDeniedVisible(false);
          } else if (
            (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
              formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
              formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
              formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
              formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
              formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) &&
            creatorId === loggedInUserId
          ) {
            setBtnSubmitLabel("Submit to Judge");
            setBtnSubmitVisible(true);
            setBtnRevertVisible(false);
            setBtnSignVisible(false);
            setBtnSaveVisible(true);
            setBtnApproveVisible(false);
            setBtnDeniedVisible(false);
          }
        } else {
          if (creatorId === loggedInUserId) {
            if (
              ROLE_POLICE_OFFICER === loggedInUserRole ||
              ROLE_STATES_ATTORNEY === loggedInUserRole ||
              ROLE_SA_ADMIN === loggedInUserRole ||
              ROLE_ATTORNEY_GENERAL === loggedInUserRole ||
              ROLE_LOCAL_PROSECUTOR === loggedInUserRole
            ) {
              setBtnRevertVisible(false);
              setBtnSignVisible(false);
              setBtnApproveVisible(false);
              setBtnDeniedVisible(false);
            }
          } else if (
            (ROLE_STATES_ATTORNEY === loggedInUserRole ||
              ROLE_SA_ADMIN === loggedInUserRole ||
              ROLE_ATTORNEY_GENERAL === loggedInUserRole ||
              ROLE_LOCAL_PROSECUTOR === loggedInUserRole) &&
            (creatorId !== loggedInUserId ||
              (creatorId === loggedInUserId && isOnBehalf))
          ) {
            setBtnSignVisible(false);
            setBtnRevertVisible(true);
            setBtnApproveVisible(true);
            setBtnSaveVisible(false);
            setBtnDeniedVisible(false);
            setBtnSubmitVisible(false);
            if (
              formStatus != null &&
              (formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
                formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS)
            ) {
              setBtnRevertVisible(false);
              setBtnSaveVisible(true);
              setBtnApproveVisible(false);
            }
          } else if (ROLE_JUDGE === loggedInUserRole) {
            setBtnRevertVisible(true);
            setBtnSignVisible(true);
            setBtnApproveVisible(false);
            setBtnSaveVisible(false);
            setBtnSubmitVisible(false);
            setBtnDeniedVisible(false);
          }
        }
      }
    }
  }, [applicationAndOrderReducer.applicationAndOrderObject.formStatus]);

  return (
    <>
      <Header headerName="Application and Order Submit" />
      <ErrorMessageAlert
        messages={errorMessageAlert.messages}
        clearError={errorMessageAlert.clearError}
      />
      <div className="container-center">
        <Row>
          <Col>
            <Card className="shadow">
              <Card.Body className="p-4">
                <Row>
                  <Col>
                    <h5>
                      Case Number :{" "}
                      <span className="fw-bold">
                        {
                          applicationAndOrderReducer.applicationAndOrderObject
                            .txtCaseNumber
                        }
                      </span>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>
                      Complaint Name :{" "}
                      <span className="fw-bold">
                        {
                          applicationAndOrderReducer.applicationAndOrderObject
                            .txtAppears
                        }
                      </span>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>
                      Issuing Agency :{" "}
                      <span className="fw-bold">
                        {
                          applicationAndOrderReducer.applicationAndOrderObject
                            .agencyName
                        }
                      </span>
                    </h5>
                  </Col>
                </Row>
                <Row className="g-2 align-items-center pt-3">
                  <Col md={12} className="text-center">
                    {btnSubmitVisible ? (
                      <ButtonComponent variant="success" onClick={onSubmitButtonClick}>
                        {btnSubmitLabel} <RiSendPlane2Fill />
                      </ButtonComponent>
                    ) : (
                      ""
                    )}{" "}
                    {btnDeniedVisible ? (
                      <ButtonComponent>Deny <RiCloseCircleFill /></ButtonComponent>
                    ) : (
                      ""
                    )}{" "}
                    {btnRevertVisible ? (
                      <ButtonComponent variant="danger" onClick={()=>onReturnClick(btnRevertLabel)}>
                        {btnRevertLabel === "Return to SA"
                          ? "Return to SA"
                          : btnRevertLabel} <RiArrowGoBackFill />
                      </ButtonComponent>
                    ) : (
                      ""
                    )}{" "}
                    {btnApproveVisible ? (
                      <ButtonComponent variant="success" onClick={onApproveClick}>Approve <RiCheckboxMultipleFill /></ButtonComponent>
                    ) : (
                      ""
                    )}{" "}
                    {btnSaveVisible ? (
                      <ButtonComponent onClick={onSaveButtonClick}>
                        Save <RiSave3Line />
                      </ButtonComponent>
                    ) : (
                      ""
                    )}{" "}
                    {btnSignVisible ? (
                      <ButtonComponent variant="success" onClick={onSignClick}>Sign <RiBallPenLine /></ButtonComponent>
                    ) : (
                      ""
                    )}{" "}
                    <ButtonComponent onClick={previewForm}>
                      Preview <RiFileTextLine />
                    </ButtonComponent>{" "}
                    <ButtonComponent onClick={backClick}>
                      Back <RiArrowLeftLine />
                    </ButtonComponent>
                  </Col>
                </Row>

                <Row className="pt-3">
                  <Col>
                    {btnSubmitVisible ? (
                      <div className="text-start">
                        <label>
                          <RiArrowRightSFill />
                          {(btnSubmitLabel === "Submit to Judge"
                            ? "Submit to Judge"
                            : "Submit for Screening") +
                            " button submits Application and Order form for review."}
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    {btnDeniedVisible ? (
                      <div className="text-start">
                        <label>
                          <RiArrowRightSFill />
                          Deny button denies Application and Order form.
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    {btnRevertVisible ? (
                      <div className="text-start">
                        <label>
                          <RiArrowRightSFill />
                          {(btnRevertLabel === "Return to LP"
                            ? "Return to LP"
                            : btnRevertLabel === "Return to AG"
                            ? "Return to AG"
                            : btnRevertLabel === "Return to SA"
                            ? "Return to SA"
                            : "Return to Officer") +
                            " button returns Application and Order form to " +
                            (btnRevertLabel === "Return to LP"
                              ? "LP"
                              : btnRevertLabel === "Return to AG"
                              ? "AG"
                              : btnRevertLabel === "Return to SA"
                              ? "SA"
                              : "officer") +
                            " with status " +
                            (btnRevertLabel === "Return to LP"
                              ? '"Assigned to LP"'
                              : btnRevertLabel === "Return to AG"
                              ? '"Assigned to AG"'
                              : btnRevertLabel === "Return to SA"
                              ? '"Assigned to SA"'
                              : '"Officer pending"') +
                            "."}
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {btnApproveVisible ? (
                      <div className="text-start">
                        <label>
                          <RiArrowRightSFill />
                          Approve button approves Application and Order form.
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {btnSaveVisible &&
                    applicationAndOrderReducer.applicationAndOrderObject
                      .creatorId === loginReducer.userProfileId ? (
                      <div className="text-start mt-2">
                        <label>
                          <RiArrowRightSFill />
                          Save button saves Application and Order form data with
                          status "Officer Pending".
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {btnSaveVisible &&
                    applicationAndOrderReducer.applicationAndOrderObject
                      .creatorId !== loginReducer.userProfileId ? (
                      <div className="text-start mt-2">
                        <label>
                          <RiArrowRightSFill />
                          Save button saves Application and Order form data with
                          status "Incomplete".
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {btnSignVisible ? (
                      <div className="text-start mt-2">
                        <label>
                          <RiArrowRightSFill />
                          Sign button imprints judge signature on Application
                          and Order form.
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text-start mt-2">
                      <label>
                        <RiArrowRightSFill />
                        Preview button opens Application and Order form in PDF.
                      </label>
                    </div>
                    <div className="text-start mt-2">
                      <label>
                        <RiArrowRightSFill />
                        Back button will take you on application of an order
                        form for this search warrant.
                      </label>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <RemarkPopup show={remarkModalShow} onHide={() => setRemarkModalShow(false)} 
        submitRemark={submitRemark} remarkFor={remarkFor}
        action={action} remarkRequired={remarkRequired}
        onlyView={false} remarksForView={""} /> 

      <ConfirmPopup
        children={confirmText}
        show={confirmPopupData.isConfirm}
        onHide={() =>
          setconfirmPopupData({ isConfirm: false, clickButtonName: "" })
        }
        isConfirmation={handleConfirmation}
      />

      <InfoPopup children={showInfoPopup.info} onHide={() => setShowInfoPopup({show: false, info: ""})} 
        show={showInfoPopup.show}/>

      <SignPopup
          show={isSignPanelPopup}
          onHide={() => setisSignPanelPopup(false)}
          formType = {type}
          searchWarrantId = {id}
      />

    </>
  );
};
export default ApplicationAndOrderSubmit;
