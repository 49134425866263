import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    AUDIT_TRAIL_FETCH_DATA, AUDIT_TRAIL_FETCH_FAILURE, AUDIT_TRAIL_FETCH_SUCCESS,AUDIT_TRAIL_RESET_FORM
  } from "../../constants/common";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { updateElementValue } from "../../utils/updateElementValue";
import { useAppSelector } from "../../hooks/hooks";

interface auditTrailSlice {
    auditTrailId: number,
    caseNumber: string,
    searchWarrantId: number,
    description: string,
    auditUserID: number,
    auditTimeStamp: string,
    creatorID: number,
    creationTimeStamp: string,
    auditType: string,
    auditCategory: string,
    userIp: string,
    auditTrailList :any[],

    auditStatus: string,
    error: string,
}

const initialState = {
    auditTrailId: 0,
    caseNumber: '',
    searchWarrantId: 0,
    description: '',
    auditUserID: 0,
    auditTimeStamp: '',
    creatorID: 0,
    creationTimeStamp: '',
    auditType: '',
    auditCategory: '',
    userIp: '',
    auditTrailList : [],

    auditStatus: '',
    error: '',

} as auditTrailSlice

export const fetchAuditTrailData = createAsyncThunk<
any,
{
    searchWarrantId?: number;
    auditType?: string;
},
{ state: RootState; rejectValue: string }
>(
    "applicationaudittrailservices/swAuditTrailList",
  async (
    {
        searchWarrantId,
        auditType,
    },
    { getState, rejectWithValue, dispatch }
    ) => {
        try {
            let { token } = getState().login;
            const response: any = await doFetch({
                url: "applicationaudittrailservices/swAuditTrailList/" + searchWarrantId +"/"+auditType,
                type:"GET",
                token
              });
              dispatch(showHideLoadingGif(false));
            if(response.ok){
                const auditTrailList = await response.json();
                return auditTrailList
            }else{
                dispatch(showHideLoadingGif(false));
                const error = await response.text();
                return rejectWithValue(error || "Error while creating SW");
            }
            
        } catch (error:any) {
            dispatch(showHideLoadingGif(false));
            return rejectWithValue(error.message || "Error while creating SW");
        }
    }
)

export const fetchAuditTrailDataForLongForm = createAsyncThunk<
any,
{
    ecomplaintId?: number;
    auditType?: string;
},
{ state: RootState; rejectValue: string }
>(
    "applicationaudittrailservices/ecAuditTrailList",
  async (
    {
      ecomplaintId,
        auditType,
    },
    { getState, rejectWithValue, dispatch }
    ) => {
        try {
            let { token } = getState().login;
            const response: any = await doFetch({
              url: "applicationaudittrailservices/ecAuditTrailList/" + ecomplaintId
                + "/" + auditType,
                type:"GET",
                token
              });
              dispatch(showHideLoadingGif(false));
            if(response.ok){
                const auditTrailList = await response.json();
              return auditTrailList
            }else{
                dispatch(showHideLoadingGif(false));
                const error = await response.text();
                return rejectWithValue(error || "Error while creating SW");
            }
            
        } catch (error:any) {
            dispatch(showHideLoadingGif(false));
            return rejectWithValue(error.message || "Error while creating SW");
        }
    }
)
const auditTrailSlice = createSlice({
    name: "auditTrail",
    initialState, 
    reducers:{
        updateElementValueInAuditTrailReducer: updateElementValue,
        resetAuditTrailList: (state) =>{
          state.auditTrailList = [];
        }
    },
    extraReducers:(builder) =>{
        builder
        .addCase(fetchAuditTrailData.pending, (state) => {
          state.auditStatus = "loading";
        })
        .addCase(fetchAuditTrailData.fulfilled, (state, action) => {
          state.auditTrailList = action.payload;
          state.auditStatus = "success";
        })
        .addCase(fetchAuditTrailData.rejected, (state, action) => {
          state.auditStatus = "error";
          state.error = "failed";
        })
        .addCase(fetchAuditTrailDataForLongForm.pending, (state) => {
          state.auditStatus = "loading";
        })
        .addCase(fetchAuditTrailDataForLongForm.fulfilled, (state, action) => {
          state.auditTrailList = action.payload;
          state.auditStatus = "success";
        })
        .addCase(fetchAuditTrailDataForLongForm.rejected, (state, action) => {
          state.auditStatus = "error";
          state.error = "failed";
        })
          
      }
}
)

export const {resetAuditTrailList} = auditTrailSlice.actions;
export const useAuditTrailReducer = () =>
useAppSelector((state) => state.auditTrail);
export default auditTrailSlice.reducer;
