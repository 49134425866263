import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateElementValue } from "../../utils/updateElementValue";
import { useAppSelector } from "../../hooks/hooks";
import { RootState } from "../store";
import { showHideLoadingGif, updatePdfGenerationError } from "./commonSlice";
import { doFetch } from "../../utils/doFetch";
import { LoadingType } from "../../types";
import { resetSignature } from "./signPanelSlice";
import { getLFCConcurrentObj } from "./commonFormSlice";

export type PreTrialReleaseRecognizanceProps = {
  formId: number,
  courtDateData: string;
  radioNoCourtDetail: string;
  radioNoCourtText: string;
  radioDcnCollectedNo: string;
  radioDcnCollectedYes: string;
  courtRoom: string;
  courtDate: string;
  courtTime: string;
  courtAddress: string;
  documentControlNumber: string;
  defDLConfiscated: string;
  defAddressUnknown: string;
  defForeignAddress: string;
  defAddressLine1: string;
  defAddressLine2: string;
  defCity: string;
  defState: string;
  defZip: string;
  defAddress: string;
  defCountry: string;
  signDate: string;
  defSignature: string;
  isRefusedSign: String;
  arrestingReleasingAgency: String;
};

export type OfficerScheduleProps = {
  courtDate: string;
  courtTime: string;
  courtLocation: string;
  address: string;
};

export type OfficerDateProxyProps = {
  courtAddress: string;
  assignedCourtLocation: string;
  courtTimeList: string;
  additionalRecord: string;
  officerScheduleList: OfficerScheduleProps[];
  agencyScheduleList: OfficerScheduleProps[];
};

const preTrialReleaseRecognizanceObj: PreTrialReleaseRecognizanceProps = {
  formId: 0,
  courtDateData: "",
  radioNoCourtDetail: "N",
  radioNoCourtText: "N",
  radioDcnCollectedNo: "N",
  radioDcnCollectedYes: "N",
  courtRoom: "",
  courtDate: "",
  courtTime: "",
  courtAddress: "",
  documentControlNumber: "",
  defDLConfiscated: "",
  defAddressUnknown: "",
  defForeignAddress: "",
  defAddressLine1: "",
  defAddressLine2: "",
  defCity: "",
  defState: "",
  defZip: "",
  defAddress: "",
  defCountry: "UNITED STATES",
  signDate: "",
  defSignature: "",
  isRefusedSign: 'N',
  arrestingReleasingAgency: "",
};

const officerDateProxyProps: OfficerDateProxyProps = {
  courtAddress: "",
  assignedCourtLocation: "",
  courtTimeList: "",
  additionalRecord: "",
  officerScheduleList: [],
  agencyScheduleList: [],
};

type InitialState = {
  pretiralData: PreTrialReleaseRecognizanceProps;
  createStatus: LoadingType;
  createError?: string;
  getStatus: LoadingType;
  getError?: string;
  saveStatus: LoadingType;
  saveError?: string;
  getOfficerDatesOrAgencyDatesStatus: LoadingType;
  getOfficerDatesOrAgencyDatesError?: string;
  officerDateProxy: OfficerDateProxyProps;
  isOfficerSchedulerListEmpty: boolean;
};

const initialState = {
  pretiralData: preTrialReleaseRecognizanceObj,
  officerDateProxy: officerDateProxyProps,
  createStatus: "idle",
  createError: undefined,
  getStatus: "idle",
  getError: undefined,
  saveStatus: "idle",
  saveError: undefined,
  getOfficerDatesOrAgencyDatesStatus: "idle",
  getOfficerDatesOrAgencyDatesError: undefined,
  isOfficerSchedulerListEmpty: true,
} as InitialState;


export const getPreTrialData = createAsyncThunk<
  any,
  {
    ecFormId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "preTrialReleaseRecognizance/getPreTrialData",
  async ({ ecFormId, navigate }, { rejectWithValue, getState, dispatch }) => {
    dispatch(showHideLoadingGif(true));
    dispatch(resetSignature());
    try {
      const response = await doFetch({
        url: "ecomplaintservices/getPreTrialData/" + ecFormId,
        token: getState().login.token,
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        const data = await response.json();
        return data;
      }
      navigate(-1);
      return rejectWithValue("Error occured while creating pre trial data");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      navigate(-1);
      return rejectWithValue("Error occured while creating pre trial data");
    }
  }
);

export const savePreTrialReleaseFormData = createAsyncThunk<
  any,
  {
    ecFormId: number;
    navigate: any;
    ecomplaintId: number;
    data: PreTrialReleaseRecognizanceProps;
  },
  { state: RootState; rejectValue: string }
>(
  "preTrialReleaseRecognizance/savePreTrialReleaseFormData",
  async (
    { ecFormId, navigate, ecomplaintId, data },
    { rejectWithValue, getState, dispatch }
  ) => {
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url:
          "ecomplaintservices/savePreTrialReleaseFormData/" +
          ecomplaintId +
          "/" +
          ecFormId,
        token: getState().login.token,
        body: data,
        type: "POST",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {  
        const data = await response.text();
        if(data ==="IO_EXCEPTION") {
          dispatch(
            updatePdfGenerationError({
              error: true,
              errorMsg: "Document generation failed, There may be special characters or content that is in this document that are not supported by the app. Please remove those special characters and try again.",
            })
          );
          navigate("/EComplaintDashboard");
        } else if (data ==="CONNECTION_EXCEPTION"){
          dispatch(
            updatePdfGenerationError({
              error: true,
              errorMsg: "Document generation service is unavailable this time, please try again later.",
            })
          );
          navigate("/EComplaintDashboard");
        } else {
          navigate("/EComplaintDashboard");
          return data;
        }
      }
      return rejectWithValue("Error occured while creating pre trial data");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error occured while creating pre trial data");
    }
  }
);

export const getOfficerDatesOrAgencyDates = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: string }
>(
  "preTrialReleaseRecognizance/getOfficerDatesOrAgencyDates",
  async (complaintId, { rejectWithValue, getState, dispatch }) => {
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "ecomplaintservices/getOfficerDatesOrAgencyDates/" + complaintId,
        token: getState().login.token,
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        const data = await response.json();
        return data;
      }
      return rejectWithValue("Error occured while creating pre trial data");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error occured while creating pre trial data");
    }
  }
);

export const preTrialReleaseRecognizanceSlice = createSlice({
  name: "preTrialReleaseRecognizance",
  initialState,
  reducers: {
    updateElementValuePreTrialReleaseRecognizanceReducer: updateElementValue,
    updateElementValuePpretiralData: (state, action) => {
      const { elementName, value } = action.payload;
      if (elementName === "officerScheduleList") {
        state.officerDateProxy.officerScheduleList = value;
      } else {
        state.pretiralData = {
          ...state.pretiralData,
          [elementName]: value,
        } as PreTrialReleaseRecognizanceProps;
      }
    },
    updatePreTrialReleaseRecognizanceObject: (state: InitialState, action) => {
      state.pretiralData = action.payload;
    },
    resetPreTrialReleaseRecognizanceReducer: (state) => {
      return {
        ...state,
        ...initialState,
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPreTrialData.pending, (state) => {
        state.getStatus = "loading";
        state.getError = undefined;
      })
      .addCase(getPreTrialData.fulfilled, (state, action) => {
        const data = action.payload as PreTrialReleaseRecognizanceProps;
        state.pretiralData = data;
        state.getStatus = "success";
      })
      .addCase(getPreTrialData.rejected, (state, action) => {
        state.getStatus = "error";
        state.getError = action.payload as string;
      });

    builder
      .addCase(savePreTrialReleaseFormData.pending, (state) => {
        state.saveStatus = "loading";
        state.saveError = undefined;
      })
      .addCase(savePreTrialReleaseFormData.fulfilled, (state, action) => {
        state.pretiralData.formId = action.payload;
        state.saveStatus = "success";
      })
      .addCase(savePreTrialReleaseFormData.rejected, (state, action) => {
        state.saveStatus = "error";
        state.saveError = action.payload as string;
      });

    builder
      .addCase(getOfficerDatesOrAgencyDates.pending, (state) => {
        state.getOfficerDatesOrAgencyDatesStatus = "loading";
        state.getOfficerDatesOrAgencyDatesError = undefined;
      })
      .addCase(getOfficerDatesOrAgencyDates.fulfilled, (state, action) => {
        const data = action.payload as OfficerDateProxyProps;
        if (data.officerScheduleList.length > 0) {
          state.isOfficerSchedulerListEmpty = false;
        }
        state.officerDateProxy = data;
        state.getOfficerDatesOrAgencyDatesStatus = "success";
      })
      .addCase(getOfficerDatesOrAgencyDates.rejected, (state, action) => {
        state.getOfficerDatesOrAgencyDatesStatus = "error";
        state.getOfficerDatesOrAgencyDatesError = action.payload as string;
        state.isOfficerSchedulerListEmpty = true;
      });
  },
});

export const {
  updateElementValuePreTrialReleaseRecognizanceReducer,
  updateElementValuePpretiralData,
  resetPreTrialReleaseRecognizanceReducer,
  updatePreTrialReleaseRecognizanceObject,
} = preTrialReleaseRecognizanceSlice.actions;

export const usePreTrialReleaseRecognizance = () =>
  useAppSelector((state) => state.preTrialReleaseRecognizance);

export default preTrialReleaseRecognizanceSlice.reducer;
