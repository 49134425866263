import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementObject } from "../../utils/updateElementObject";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";

interface leaderCountObject {
  ecomplaintNumber: string,
}
interface leaderCountState {
  leaderCountList: [],
  status: LoadingType,
  leaderCountObject: leaderCountObject,
}

const initialState = {
  leaderCountList: [],
  status: "idle",
  leaderCountObject: {
    ecomplaintNumber: "",
    violationCode: "",
    classOfViolation: "",
    caseType: "",
    citationNumber: "",
    chargeNarrative: "",
    formId: "",
  }
} as leaderCountState;

export const getLeaderCounts = createAsyncThunk<
  any,
  {
    ecomplaintId: string,
    officerAgency: string,
  },
  { state: RootState; rejectValue: string }
>("leaderCounts/getLeaderCounts",
  async (
    {
      ecomplaintId,
      officerAgency
    },
    { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    token = `Bearer ${token}`;
    let queryParam = "";
    queryParam += `officerAgency=${officerAgency}`;
    try {
      dispatch(showHideLoadingGif(true));
      const response: any = await doFetch({

        url: `ecomplaintservices/getLeaderCounts/${ecomplaintId}` + `?jwtToken=${token}` + `&${queryParam}`,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching getLeaderCount");
      console.log('error');
    }

  }
);

export const getLookupLeaderInfo = createAsyncThunk<
  any,
  {
    ecomplaint: object,
  },
  { state: RootState; rejectValue: string }
>("leaderCounts/getLookupLeaderInfo",
  async (
    {
      ecomplaint,
    },
    { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    try {
      dispatch(showHideLoadingGif(true));
      const response: any = await doFetch({

        url: `ecomplaintservices/getLookupLeaderInfo`,
        token,
        type: "POST",
        body: ecomplaint,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching getLookupLeaderInfo");
      console.log('error');
    }

  }
);

export const leaderCountSlice = createSlice({
  name: "leaderCountReducer",
  initialState,
  reducers: {
    updateElementValueLeaderCountReducer: updateElementValue,
    updateElementObjectValue: (state: leaderCountState, action) => {
      let elementObj = state.leaderCountObject;
      updateElementObject(state, action, elementObj);
    },
    resetLeaderCountData: (state: leaderCountState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLeaderCounts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLeaderCounts.fulfilled, (state, action) => {
        state.status = "success";
        if (action.payload !== null && action.payload.length > 0) {
          state.leaderCountList = action.payload;
        }
        else{
          state.leaderCountList = [];
        }
      })
      .addCase(getLeaderCounts.rejected, (state) => {
        state.status = "error";
      })
      .addCase(getLookupLeaderInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLookupLeaderInfo.fulfilled, (state, action) => {
        state.status = "success";
        state.leaderCountList = action.payload;
      })
      .addCase(getLookupLeaderInfo.rejected, (state) => {
        state.status = "error";
      })
  }
})
export const { updateElementValueLeaderCountReducer, updateElementObjectValue, resetLeaderCountData } = leaderCountSlice.actions;
export const useLeaderCountReducer = () =>
  useAppSelector((state) => state.leaderCount);
export default leaderCountSlice.reducer;
