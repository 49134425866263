import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import {
  createCounty,
  getCountyDataById,
  resetCountyData,
  updateCounty,
  updateElementValueCountyReducer,
  useCountyReducer,
} from "../../redux/slice/countySlice";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import Header from "../common/Header";
import * as yup from "yup";
import Alert, { useAlert } from "../common/Alert";
import { formattedZipcode, merge } from "../../utils/common";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { USER_TYPES } from "../../constants/common";

let defaultCountySchema = yup.object().shape({
  countyName: yup
    .string()
    .required("County is required."),
    //.matches(/^[aA-zZ\s]+$/, "County can only contain letters."),
  street: yup.string().required("Address is required."),
  state: yup.string().required("State is required."),
  city: yup.string().required("City is required."),
  zip: yup.mixed().required("Zip Code is required."),
  judicialNumber: yup.string().required("Circuit Court # is required."),
  circuitClerkName: yup.string().required("Clerk Name is required."),
  
 
  
 
});

export default function AddCounty() {
  const location = useLocation();
  const countyReducer = useCountyReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errorKeys, setErrorKeys] = useState([""]);
  const alert = useAlert();
  const loginReducer = useLoginReducer();

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      alert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const countyID = location.pathname.split("/")[2];
    if (countyID) {
      dispatch(
        getCountyDataById({
          id: parseInt(countyID),
          navigate,
        })
      );
    }
  }, [dispatch, location, navigate]);

  useEffect(() => {
    if (countyReducer.createCountyError) {
      handleError([], [countyReducer.createCountyError]);
      dispatch(
        updateElementValueCountyReducer({
          elementName: "createCountyError",
          value: "",
        })
      );
    }
    if (countyReducer.updateCountyError) {
      handleError([], [countyReducer.updateCountyError]);
      dispatch(
        updateElementValueCountyReducer({
          elementName: "updateCountyError",
          value: "",
        })
      );
    }
  }, [
    countyReducer.createCountyError,
    countyReducer.updateCountyError,
    dispatch,
    handleError,
  ]);

  // Clear the form data when the component unmounts
  useEffect(() => {
    return () => {
      dispatch(resetCountyData());
    };
  }, [dispatch]);

  const handleInputChange = (e: any) => {
    // Do not allow countyName to have numbers
    if (e.target.name === "countyName") {
      e.target.value = e.target.value.replace(/[0-9]/g, "");
    }
    // Do not allow zip to have letters and only allow 10 digits
    if (e.target.name === "zip") {
      e.target.value = e.target.value.replace(/[a-zA-Z]/g, "").slice(0, 10);
    }
    // Only allpw judicialNumber to have two digits if the value only has numbers
    if (e.target.name === "judicialNumber") {
      if (parseInt(e.target.value)) {
        e.target.value = e.target.value.slice(0, 2);
      }
    }
    // Only allow alphabet, dot and dash in the circuitClerkName
    if (e.target.name === "circuitClerkName") {
      e.target.value = e.target.value.replace(/[^a-zA-Z .-]/g, "");
    }

    // If the value is true or false then convert it to boolean (For checkbox)
    const value =
      e.target.value === "true" || e.target.value === "false"
        ? e.target.value !== "true"
        : e.target.value;

    // Clear the filestamp if both appliedFileStamp and appliedFileStampDate are false
    if (
      (e.target.name === "appliedFileStamp" ||
        e.target.name === "ecAppliedFileStamp") &&
      !value
    ) {
      if (
        (e.target.name === "appliedFileStamp" &&
          !countyReducer.ecAppliedFileStamp) ||
        (e.target.name === "ecAppliedFileStamp" &&
          !countyReducer.appliedFileStamp)
      ) {
        dispatch(
          updateElementValueCountyReducer({
            elementName: "fileStamp",
            value: "",
          })
        );
      }
    }
    dispatch(
      updateElementValueCountyReducer({
        elementName: e.target.name,
        value,
      })
    );
  };

  const handleToUpperCaseOnBlur = (e: any) => {
    dispatch(
      updateElementValueCountyReducer({
        elementName: e.target.name,
        value: e.target.value.toUpperCase(),
      })
    );
  };

  const handleSelectChange = (name: string) => (e: any) => {
    dispatch(
      updateElementValueCountyReducer({
        elementName: name,
        value: e.value,
      })
    );
  };

  const handleFileChange = (event: any) => {
    if (event.target.files[0]) {
      let extention = event.target.files[0].name.split(".").pop().toLowerCase();
      if (extention === "png" || extention === "jpg" || extention === "jpeg") {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            dispatch(
              updateElementValueCountyReducer({
                elementName: event.target.name,
                // @ts-ignore
                value: btoa(reader.result),
              })
            );
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        handleError([event.target.name], ["Invalied file for county"]);
      }
    }
  };

  const handleFormatZip = (e: any) => {
    const _zip = formattedZipcode(e.target.value);
    dispatch(
      updateElementValueCountyReducer({
        elementName: e.target.name,
        value: _zip,
      })
    );
  };

  const handleSubmit = async () => {
    if (location.pathname.includes("/ViewCounty")) {
      const countyID = location.pathname.split("/")[2];
      return navigate("/EditCounty/" + countyID);
    }
    handleError([""], []);
    try {
      let schema = [];
      schema.push(defaultCountySchema);
      if (countyReducer.appliedFileStamp || countyReducer.ecAppliedFileStamp) {
        schema.unshift(
          yup.object().shape({
            fileStamp: yup.mixed().required("File Stamp is required."),
          })
        );
      }
      if (countyReducer.longFormEnabled) {
        schema.unshift(
          yup.object().shape({
            customGeoLimits: yup
              .mixed()
              .required(
                "Arrest Warrant GEO Limit is required for Longform Enabled Counties."
              ),
          })
        );
      }
      if (countyReducer.integratedWithCMS) {
        schema.unshift(
          yup.object().shape({
            cmsUrl: yup.mixed().required("Info Service URL is required."),
            cmsUser: yup
              .mixed()
              .required("Info Service User Name is required."),
            cmsPassword: yup
              .mixed()
              .required("Info Service Password is required."),
          })
        );
      }
      if (location.pathname === "/AddCounty") {
        schema.unshift(
          yup.object().shape({
            warrentlessFormType: yup
            .string()
            .required("Warrentless Form Type is required.")
            .nullable(),
          })
        );
      }
      schema = merge(...schema);
      const county = await schema.validate(countyReducer, {
        abortEarly: false,
      });
      if (location.pathname === "/AddCounty") {
        dispatch(createCounty({ county, navigate }));
      } else {
        dispatch(updateCounty({ county, navigate }));
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const data = [
    {
      type: "textInput",
      props: {
        labelName: "County",
        type: "text",
        placeholder: "County Name",
        name: "countyName",
        value: countyReducer.countyName,
        onChange: handleInputChange,
        isError: errorKeys.includes("countyName"),
        pattern: "[a-zA-Z]*",
        onBlur: handleToUpperCaseOnBlur,
        disabled: !location.pathname.includes("/AddCounty"),
        isRequired: true,
      },
    },
    {
      type: "textInput",
      props: {
        labelName: "Circuit Court #",
        type: "text",
        placeholder: "Circuit Court",
        name: "judicialNumber",
        value: countyReducer.judicialNumber,
        onChange: handleInputChange,
        isError: errorKeys.includes("judicialNumber"),
        pattern: "[0-9]*",
        onBlur: handleToUpperCaseOnBlur,
        disabled: location.pathname.includes("/ViewCounty"),
        isRequired: true,
      },
    },
    {
      type: "textInput",
      props: {
        labelName: "Address",
        type: "text",
        placeholder: "Address",
        name: "street",
        value: countyReducer.street,
        onChange: handleInputChange,
        isError: errorKeys.includes("street"),
        onBlur: handleToUpperCaseOnBlur,
        disabled: location.pathname.includes("/ViewCounty"),
        isRequired: true,
      },
    },
    {
      type: "textInput",
      props: {
        labelName: "Clerk Name",
        type: "text",
        placeholder: "Clerk Name",
        name: "circuitClerkName",
        value: countyReducer.circuitClerkName,
        onChange: handleInputChange,
        isError: errorKeys.includes("circuitClerkName"),
        onBlur: handleToUpperCaseOnBlur,
        disabled: location.pathname.includes("/ViewCounty"),
        isRequired: true,
      },
    },
    {
      type: "selectInput",
      props: {
        options: stateList,
        placeholder: "State",
        labelName: "State",
        name: "state",
        value: stateList.find(
          (state: any) => state.value === countyReducer.state
        ),
        onChange: handleSelectChange("state"),
        isError: errorKeys.includes("state"),
        isDisabled: location.pathname.includes("/ViewCounty"),
        isRequired: true,
        isLabeled: true,
        isDefaultLabel : true
      },
    },
    
    {
      type: "textInput",
      props: {
        placeholder: "City",
        name: "city",
        labelName: "City",
        value: countyReducer.city,
        onChange: handleInputChange,
        isError: errorKeys.includes("city"),
        onBlur: handleToUpperCaseOnBlur,
        disabled: location.pathname.includes("/ViewCounty"),
        isRequired: true,
      },
    },
    {
      type: "textInput",
      props: {
        placeholder: "Zip Code",
        name: "zip",
        labelName: "Zip Code",
        value: countyReducer.zip,
        onChange: handleInputChange,
        isError: errorKeys.includes("zip"),
        onBlur: handleFormatZip,
        disabled: location.pathname.includes("/ViewCounty"),
        isRequired: true,
      },
    },
    {
      type: "htmlTag",
      props: {},
      colSize : 6
    },
    {
      type: "checkbox",
      props: {
        label: "SW - SA Approval Required",
        placeholder: "SW - SA Approval Required",
        name: "isSARequired",
        value: countyReducer.isSARequired,
        onChange: handleInputChange,
        checked: countyReducer.isSARequired,
        isError: errorKeys.includes("isSARequired"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
      colSize : 6
    },
    {
      type: "checkbox",
      props: {
        label: "Order of Custody",
        placeholder: "Order of Custody",
        name: "isOFCRequired",
        value: countyReducer.isOFCRequired,
        onChange: handleInputChange,
        checked: countyReducer.isOFCRequired,
        isError: errorKeys.includes("isOFCRequired"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Apply Filestamp",
        placeholder: "Apply Filestamp",
        name: "appliedFileStamp",
        value: countyReducer.appliedFileStamp,
        onChange: handleInputChange,
        checked: countyReducer.appliedFileStamp,
        isError: errorKeys.includes("appliedFileStamp"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Long Form Enable",
        placeholder: "Long Form Enable",
        name: "longFormEnabled",
        value: countyReducer.longFormEnabled,
        onChange: handleInputChange,
        checked: countyReducer.longFormEnabled,
        isError: errorKeys.includes("longFormEnabled"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Local Procecutor Allowed",
        placeholder: "Local Procecutor Allowed",
        name: "isLPAllowed",
        value: countyReducer.isLPAllowed,
        onChange: handleInputChange,
        checked: countyReducer.isLPAllowed,
        isError: errorKeys.includes("isLPAllowed"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "LFC - Apply Filestamp",
        placeholder: "LFC - Apply Filestamp",
        name: "ecAppliedFileStamp",
        value: countyReducer.ecAppliedFileStamp,
        onChange: handleInputChange,
        checked: countyReducer.ecAppliedFileStamp,
        isError: errorKeys.includes("ecAppliedFileStamp"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Integrated With CMS",
        placeholder: "Integrated With CMS",
        name: "integratedWithCMS",
        value: countyReducer.integratedWithCMS,
        onChange: handleInputChange,
        checked: countyReducer.integratedWithCMS,
        isError: errorKeys.includes("integratedWithCMS"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Multiple Case Number",
        placeholder: "Multiple Case Number",
        name: "multiCaseNumber",
        value: countyReducer.multiCaseNumber,
        onChange: handleInputChange,
        checked: countyReducer.multiCaseNumber,
        isError: errorKeys.includes("multiCaseNumber"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Notarization Required",
        placeholder: "Notarization Required",
        name: "notarizationReq",
        value: countyReducer.notarizationReq,
        onChange: handleInputChange,
        checked: countyReducer.notarizationReq,
        isError: errorKeys.includes("notarizationReq"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "LFC - Felony Screening by SA Required",
        placeholder: "LFC - Felony Screening by SA Required",
        name: "felonyScreeningRequired",
        value: countyReducer.felonyScreeningRequired,
        onChange: handleInputChange,
        checked: countyReducer.felonyScreeningRequired,
        isError: errorKeys.includes("felonyScreeningRequired"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "eOrder Enable",
        placeholder: "eOrder Enable",
        name: "eorderEnabled",
        value: countyReducer.eorderEnabled,
        onChange: handleInputChange,
        checked: countyReducer.eorderEnabled,
        isError: errorKeys.includes("eorderEnabled"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Cache LF Case Numbers Enable",
        placeholder: "Cache LF Case Numbers Enable",
        name: "cacheLFCasenumbers",
        value: countyReducer.cacheLFCasenumbers,
        onChange: handleInputChange,
        checked: countyReducer.cacheLFCasenumbers,
        isError: errorKeys.includes("cacheLFCasenumbers"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Watermark PDF Preview",
        placeholder: "Watermark PDF Preview",
        name: "watermarkDraft",
        value: countyReducer.watermarkDraft,
        onChange: handleInputChange,
        checked: countyReducer.watermarkDraft,
        isError: errorKeys.includes("watermarkDraft"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Hide Completed Search Warrants to Judge",
        placeholder: "Hide Completed Search Warrants to Judge",
        name: "hideCompletedSW",
        value: countyReducer.hideCompletedSW,
        onChange: handleInputChange,
        checked: countyReducer.hideCompletedSW,
        isError: errorKeys.includes("hideCompletedSW"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Send Email for Incomplete POS and Inventory",
        placeholder: "Send Email for Incomplete POS and Inventory",
        name: "sendEmail",
        value: countyReducer.sendEmail,
        onChange: handleInputChange,
        checked: countyReducer.sendEmail,
        isError: errorKeys.includes("sendEmail"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Use officers from IUCS",
        placeholder: "Use officers from IUCS",
        name: "useOfficersFromIucs",
        value: countyReducer.useOfficersFromIucs,
        onChange: handleInputChange,
        checked: countyReducer.useOfficersFromIucs,
        isError: errorKeys.includes("useOfficersFromIucs"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Pre-Trial Release on own Recognizance",
        placeholder: "Pre-Trial Release on own Recognizance",
        name: "preTrialReleaseOnOwnRecognizance",
        value: countyReducer.preTrialReleaseOnOwnRecognizance,
        onChange: handleInputChange,
        checked: countyReducer.preTrialReleaseOnOwnRecognizance,
        isError: errorKeys.includes("sendEmail"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Probable Cause Statement",
        placeholder: "Probable Cause Statement",
        name: "revisedProbableCauseStatement",
        value: countyReducer.revisedProbableCauseStatement,
        onChange: handleInputChange,
        checked: countyReducer.revisedProbableCauseStatement,
        isError: errorKeys.includes("useOfficersFromIucs"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Summons To Appear",
        placeholder: "Summons To Appear",
        name: "summonsToAppear",
        value: countyReducer.summonsToAppear,
        onChange: handleInputChange,
        checked : countyReducer.summonsToAppear,
        // isError: errorKeys.includes("useOfficersFromIucs"),
        disabled: location.pathname.includes("/ViewCounty"),
        
      }
    },
    //KHL PR 11264 09/01/24 - START
    {
      type: "checkbox",
      props: {
        label: "Receipt Enable",
        placeholder: "Receipt Enable",
        name: "receiptEnable",
        value: countyReducer.receiptEnable,
        onChange: handleInputChange,
        checked : countyReducer.receiptEnable,
        // isError: errorKeys.includes("useOfficersFromIucs"),
        disabled: location.pathname.includes("/ViewCounty"),
        
      }
    },
    //KHL PR 11264 09/01/24 - END
    //KHL PR 1256 25/01/24 - START
    {
      type: "checkbox",
      props: {
        label: "Auto Impound Arrest Warrant Request",
        placeholder: "Impound Enable",
        name: "impoundEnable",
        value: countyReducer.impoundEnable,
        onChange: handleInputChange,
        checked : countyReducer.impoundEnable,
        // isError: errorKeys.includes("useOfficersFromIucs"),
        disabled: location.pathname.includes("/ViewCounty"),
        
      }
    },
    //KHL PR 11294 13/02/24 START
    {
      type: "checkbox",
      props: {
        label: "Allow Agency Admins to see all SWs",
        placeholder: "Allow Agency Admins to see all SWs",
        name: "allSwsEnable",
        value: countyReducer.allSwsEnable,
        onChange: handleInputChange,
        checked : countyReducer.allSwsEnable,
        disabled: location.pathname.includes("/ViewCounty"),
        
      }
    },
    //KHL PR 11294 13/02/24 END
    {
      type: "empty"
    },
    //KHL PR 1256 25/01/24 - END
    {
      type: "selectInput",
      props: {
        options: warrentlessFormTypeList,
        placeholder: "Warrantless Form Type",
        labelName: "Warrantless Form Type",
        name: "warrentlessFormType",
        value: warrentlessFormTypeList.find(
          (item) => item.value === countyReducer.warrentlessFormType
        ),
        onChange: handleSelectChange("warrentlessFormType"),
        isError: errorKeys.includes("warrentlessFormType"),
        isDisabled: location.pathname.includes("/ViewCounty"),
        isLabeled: true,
        isDefaultLabel : true
      },
    },
    {
      type: "selectInput",
      props: {
        options: inventoryReturnTypeList,
        placeholder: "Inventory Return",
        labelName: "Inventory Return",
        name: "inventoryReturnType",
        value: inventoryReturnTypeList.find(
          (item) => item.value === countyReducer.inventoryReturnType
        ),
        onChange: handleSelectChange("inventoryReturnType"),
        isError: errorKeys.includes("inventoryReturnType"),
        isDisabled: location.pathname.includes("/ViewCounty"),
        isLabeled: true,
        isDefaultLabel : true
      },
    },
    {
      type: "textInput",
      props: {
        labelName: "Arrest Warrant GEO limits",
        type: "text",
        placeholder: "Arrest Warrant GEO limits",
        name: "customGeoLimits",
        value: countyReducer.customGeoLimits,
        onChange: handleInputChange,
        isError: errorKeys.includes("customGeoLimits"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "textInput",
      props: {
        labelName: "Info Service URL",
        type: "text",
        placeholder: "Info Service URL",
        name: "cmsUrl",
        value: countyReducer.cmsUrl,
        onChange: handleInputChange,
        isError: errorKeys.includes("cmsUrl"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "textInput",
      props: {
        labelName: "Info Service User Name",
        type: "text",
        placeholder: "Info Service User Name",
        name: "cmsUser",
        value: countyReducer.cmsUser,
        onChange: handleInputChange,
        isError: errorKeys.includes("cmsUser"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "textInput",
      props: {
        labelName: "Info Service Password",
        type: "text",
        placeholder: "Info Service Password",
        name: "cmsPassword",
        value: countyReducer.cmsPassword,
        onChange: handleInputChange,
        isError: errorKeys.includes("cmsPassword"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "customFileUpload",
      props: {
        name: "fileStamp",
        value: atob(countyReducer.fileStamp),
        onChange: handleFileChange,
        isError: errorKeys.includes("fileStamp"),
        disabled: location.pathname.includes("/ViewCounty"),
      },
    },
    {
      type: "button",
      props: {
        children: location.pathname.includes("/ViewCounty") ? "Edit" : "Save",
        className: "mx-2",
        onClick: handleSubmit,
      },
    },
    {
      type: "button",
      props: {
        children: "Cancel",
        className: "btn-danger",
        onClick: () => {
          navigate(-1);
        },
      },
    },
  ];
  const itemLinkList =
    loginReducer.userType === USER_TYPES.ADMINISTRATOR
      ? [
          { itemLink: "/", itemName: "DashBoard" },
          { itemLink: "/CountyList", itemName: "County List" },
        ]
      : [{ itemLink: "/", itemName: "DashBoard" }];

  return (
    <>
      <Header headerName="County" />
      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="County" />
        <Alert messages={alert.messages} clearError={alert.clearError} />
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data}
          header="County"
          subHeader={
            location.pathname === "/AddCounty" ? "Add County" : "Edit County"
          }
          components={[
            {
              type: "customFileUpload",
              node: ({ isError, value, ...rest }: any) => (
                <>
                  <div className="mt-1">
                    <label style={{ marginBottom: "0px" }}>
                      <b>File stamp</b>{" "}
                      {countyReducer.appliedFileStamp ||
                      countyReducer.ecAppliedFileStamp ? (
                        <span className="text-danger">*</span>
                      ) : null}
                    </label>
                  </div>
                  <div
                    className="mt-4 pl-3"
                    style={
                      countyReducer.appliedFileStamp ||
                      countyReducer.ecAppliedFileStamp
                        ? { cursor: "pointer" }
                        : { cursor: "not-allowed" }
                    }
                  >
                    <input
                      type="file"
                      style={
                        countyReducer.appliedFileStamp ||
                        countyReducer.ecAppliedFileStamp
                          ? { color: "transparent", width: "110px" }
                          : {
                              cursor: "not-allowed",
                              color: "transparent",
                              width: "110px",
                            }
                      }
                      accept=".jpg, .jpeg, .png"
                      name="fileStamp"
                      disabled={
                        !(
                          countyReducer.appliedFileStamp ||
                          countyReducer.ecAppliedFileStamp
                        )
                      }
                      {...rest}
                    />
                  </div>
                  <div
                    className="mt-4"
                    style={{
                      border: !isError ? "2px solid gray" : "red 1px solid",
                      borderRadius: "20px",
                      width: "205px",
                      height: "205px",
                    }}
                  >
                    <img
                      style={{
                        width: "200px",
                        height: "200px",
                        borderRadius: "20px",
                        display: value ? "block" : "none",
                      }}
                      alt="fileStamp"
                      src={value}
                    />
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}

const stateList = [
  { label: "--SELECT--", value: "" },
  { label: "ILLINOIS", value: "ILLINOIS" },
];
const warrentlessFormTypeList = [
  { label: "--SELECT--", value: 0 },
  {
    label: "Notice of Arrest without Warrant",
    value: "Notice of Arrest without Warrant",
  },
];
const inventoryReturnTypeList = [
  { label: "--SELECT--", value: "" },
  { label: "Verification", value: "Verification" },
  { label: "Video Return", value: "Video Return" },
];
