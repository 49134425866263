import React, { useState } from "react";
import SideMenu from "./SideMenu";
import Footer from "./Footer";

export default function LayoutWrapper({ children }: { children: any }) {
  const [inactive, setInactive] = useState(false);

  return (
    <div>
      <SideMenu inactive={inactive} setInactive={setInactive} />
      <div className="blank"></div>
      <div className={`container-side ${inactive ? "inactive" : ""}`}>
        {children}
      </div>
      <Footer />
    </div>
  );
}
