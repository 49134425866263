import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useRef, useState } from "react";
import { RiArrowRightSFill } from "react-icons/ri";

type Props = {
  id: string;
  editor?: any;
  config?: any;
  data?: string;
  isError?: boolean;
  disabled?: boolean;
  onInit?: (editor: any) => void;
  onChange: (event: any, editor: any) => void;
  onBlur?: (event: any, editor: any) => void;
  onFocus?: (event: any, editor: any) => void;
};

// TODO: Update Default _onInit and _config to use the above props
const _onInit = (editor: any) => {
  editor.editing.view.change((writer: any) => {
    writer.setStyle("height", '200px', editor.editing.view.document.getRoot());
  });
};

const _config = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "outdent",
    "indent",
    "|",
    "blockQuote",
    "|",
    "undo",
    "redo",
  ],  
};

const Editor = (props: Props) => {
  const {
    id,
    editor = ClassicEditor,
    config = _config,
    data,
    disabled,
    onInit = _onInit,
    onChange,
    onBlur,
    onFocus,
    isError,
  } = props;

  const [isResizing, setIsResizing] = useState(false);
  const [initialHeight, setInitialHeight] = useState(0);
  const [editorHeight, setEditorHeight] = useState(200);

  const editorRef = useRef<any>(null);
  const resizeHandleRef = useRef<HTMLDivElement | null>(null);

  const handleMouseDown = (e: MouseEvent) => {
    setIsResizing(true);
    setInitialHeight(e.pageY);
    document.removeEventListener('mousedown', handleMouseDown)
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    document.removeEventListener('mousedown', handleMouseDown);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing) return;
    const resizeHandle = document.getElementById('resize');
    if (resizeHandle !== null) {
      const newHeight = -initialHeight + e.pageY + editorHeight;
      if (newHeight > 200) {
        setEditorHeight(newHeight)
        if (editorRef.current != null) {
          editorRef.current.editing.view.change((writer: any) => {
            writer.setStyle("height", newHeight + "px", editorRef.current.editing.view.document.getRoot());
          });
        }
      }
    }
  };

  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener('mousedown', handleMouseDown)
    }

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [initialHeight, isResizing]);
  
  return (
    <div className={`${isError ? "ck-editor-border-err" : ""}`}>
      <CKEditor
        disabled={disabled}
        id={id}
        editor={editor}
        config={config}
        data={data || ""}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onReady={(editor: any)=>{
          onInit(editor);
          editorRef.current = editor;
        }}
      />
       <div
       ref={resizeHandleRef}
        id="resize"
        className="ck-editor-footer"
        onMouseDown={()=>document.addEventListener('mousedown', handleMouseDown)}
      ><RiArrowRightSFill className="ck-editor-exapnd" style={{fontSize:"23px"}}/>
      </div>
    </div>
  );
};
export default Editor;
