import { doFetch } from "../utils/doFetch";

export const getCodeTableEntryList = async ({
    token,
    type
}: {
    token?: string;
    type?: string;
}) => {
    const response = await doFetch({
        url: `codetabelentryservices/getCodeTableEntry/${type}`,
        token
    });
    return response;
};

export const getCodeTableEntries = async ({
    token,
    tableName
}: {
    token?: string;
    tableName?: string
}) => {
    const response = await doFetch({
        url: `codetabelentryservices/getCodeTableEntries/${tableName}`,
        token,
    });
    return response;
};