import React, { useCallback, useEffect, useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { RiArrowRightLine, RiCloseFill, RiFileTextLine, RiSave3Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import ConfirmPopup from "../../../components/common/ConfirmPopup";
import { COMPL_SEARCH_WARRANT, COMPL_SEARCH_WARRANT_WITH_SLASH, ROLE_ADMINISTRATOR, ROLE_AGENCY_ADMIN, ROLE_ATTORNEY_GENERAL, ROLE_COUNTY_ADMIN, ROLE_JUDGE, ROLE_LOCAL_PROSECUTOR, ROLE_POLICE_OFFICER, ROLE_STATES_ATTORNEY, SEARCH_WAR_ASSIGNED_TO_AG_STATUS, SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS, SEARCH_WAR_ASSIGNED_TO_LP_STATUS, SEARCH_WAR_ASSIGNED_TO_SA_STATUS, SEARCH_WAR_INCOMPLETE_STATUS, SEARCH_WAR_LOCKED_BY_AG_STATUS, SEARCH_WAR_LOCKED_BY_JUDGE_STATUS, SEARCH_WAR_LOCKED_BY_LP_STATUS, SEARCH_WAR_LOCKED_BY_SA_STATUS, SEARCH_WAR_OFFICER_PENDING_STATUS,SEARCH_WARRANT_PAGE_SIZE, ROLE_SA_ADMIN } from "../../../constants/common";
import { useAppDispatch } from "../../../hooks/hooks";
import { useAgencyListReducer } from "../../../redux/slice/agencyListSlice";
import { previewDraftingOrder } from "../../../redux/slice/commonFormSlice";
import { useCountyListReducer } from "../../../redux/slice/countyListSlice";
import { useLoginReducer } from "../../../redux/slice/loginSlice";
import {
  getWarrantTemplateData, listSWApproveUserTypes, updateElementObjectValue, updateSearchWarrant, useSearchWarrantReducer
} from "../../../redux/slice/searchWarrantSlice";
import { setSignature, updateElementValueInSignPanelReducer, useSignPanelReducer } from "../../../redux/slice/signPanelSlice";
import { getWarrantTemplateListWithData, listSearchWarrantType, useTemplateReducer } from "../../../redux/slice/templateSlice";
import { formatCaseNumber, formatName, merge, setTemplateDataToFields, singleStringReplace, updateTagValue } from "../../../utils/common";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";
import BreadcrumbComponent from "../../common/BreadcrumbComponent";
import { getValidationError, validateSignaturePixel } from "../../common/CommonUtil";
import FormComponent from "../../common/FormComponent";
import Header from "../../common/Header";
import InfoPopup from "../../common/InfoPopup";
import { listDocuments } from "../../../redux/slice/searchWarrantListSlice";
import ButtonComponent from "../../common/ButtonComponent";
import FormFiller from "../../common/FormFiller";
import { useDashboardReducer, updateElementValueDashboardReducer } from "../../../redux/slice/dashboardSlice";
import { getJudgeSavedSignature } from "../../../redux/slice/userProfileSlice";

let defaultSwSchema = yup.object().shape({
  searchWarrantType: yup.string().nullable().required("Search warrant type in required."),
  // approvalUserTypeId: yup.number().nullable().required("Approval agencie is required."),
  badgeNo: yup.string().required("Badge number is required."),
  txtPersonInfo: yup.string().nullable().required("Person/Premises/Item information is required.").test('validator-ck-editor', function (value) {
    const errMessage = getValidationError("PERSON/PREMISES/ITEM INFORMATION", value);
    if (errMessage.length > 0) {
      return this.createError({
        message: errMessage,
      });
    }
    else {
      return true;
    }
  }),
  txtOffense1: yup.string().nullable().required("Offence is required.").test('validator-ck-editor', function (value) {
    const errMessage = getValidationError("LIST OFFENSES", value);
    if (errMessage.length > 0) {
      return this.createError({
        message: errMessage,
      });
    }
    else {
      return true;
    }
  }),
  txtSeized1: yup.string().nullable().required("Seized is required.").test('validator-ck-editor', function (value) {
    const errMessage = getValidationError("To Wit (LIST ITEMS TO SEIZE)", value);
    if (errMessage.length > 0) {
      return this.createError({
        message: errMessage,
      });
    }
    else {
      return true;
    }
  }),
  txtPoliceOfficerInfo: yup.string().nullable().required("Probable cause / Officer background information is required.").test('validator-ck-editor', function (value) {
    const errMessage = getValidationError("DESCRIBE OFFICER BACKGROUND AND PROBABLE CAUSE", value);
    if (errMessage.length > 0) {
      return this.createError({
        message: errMessage,
      });
    }
    else {
      return true;
    }
  }),
  sign: yup.string().ensure().when('signatureCanvasVisible',{is:true,then:yup.string().required("Signature required.")}),
});

let fieldTitles =['PERSON/PREMISES/ITEM INFORMATION','LIST OFFENSES HERE','To Wit (LIST ITEMS TO SEIZE HERE)','DESCRIBE OFFICER BACKGROUND AND PROBABLE CAUSE'];
let names = ['txtPersonInfo','txtOffense1','txtSeized1','txtPoliceOfficerInfo'];

interface SearchWarrantProps { }

const SearchWarrant: React.FC<SearchWarrantProps> = (props) => {

  const { countyList } = useCountyListReducer();
  const { agencyList } = useAgencyListReducer();
  const { swApprovalUserTypes } = useSearchWarrantReducer();
  const { warrantTemplateList } = useTemplateReducer();
  const { swTypeList } = useTemplateReducer();
  const [county, setCounty] = React.useState();
  const [searchWarrantType, setSearchWarrantType] = React.useState("");
  const [templateId, setTemplateId] = React.useState(0);
  const [confirmPopupData, setconfirmPopupData] = React.useState(false);
  const [confirmOriginPlace, setconfirmOriginPlace] = React.useState("");
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const searchWarrantReducer = useSearchWarrantReducer();
  const signPanelReducer = useSignPanelReducer();
  const loginReducer = useLoginReducer();
  const [signConfirmationModalShow , setsignConfirmationModalShow ] = React.useState(false);
  const [showIntoModalMessage , setshowIntoModalMessage] = React.useState("");
  const [approvalUserTypeEnable , setApprovalUserTypeEnable ] = React.useState(false);
  const [approvalUserTypeVisible , setApprovalUserTypeVisible ] = React.useState(false);
  const [searchWarrantTypeEnable , setSearchWarrantTypeEnable ] = React.useState(true);
  const [uploadedFileName, setUploadedFileName] = React.useState("");
  const [whoSign, setWhoSign] = React.useState("");
  const [swornText, setSwornText] = React.useState("");
  const [complainantNameFooter, setComplainantNameFooter] = React.useState(""); 
  const [signatureCanvasVisible , setSignatureCanvasVisible ] = React.useState(false);
  const [fileUploaderVisible , setFileUploaderVisible ] = React.useState(false);
  const [file, setfile] = React.useState("");
  const [showInfoModal, setshowInfoModal] = React.useState(false);
  const dashboardReducer = useDashboardReducer();
  const [showFormFiller, setShowFormFiller] = React.useState(false);
  const [fieldTitleValues, setFieldTitleValues] = React.useState<String[]>([]);
  const [isCanEdit, setIsCanEdit] = React.useState(false);
  const [loadUserSignature, setLoadUserSignature] = React.useState(false);

  const sortedData = useMemo(()=>{
    return Array.from(swTypeList).sort((a: any, b: any) => a.templateName.localeCompare(b.templateName));
  },[swTypeList]);

  const  onBreadcrumbClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }

  const getItemsLink = () =>{
    if (loginReducer.summonsToAppear){
      return"/WarrantsSummonsList";
    }else{
      return"/WarrantList";
    }
  }
  
  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: dashboardReducer.PreviousURLSW == "" ? getItemsLink() : "/"+dashboardReducer.PreviousURLSW.replace(/^.*\/\/.*?\//, ''), itemName: "Warrant List", itemFunction: onBreadcrumbClick },
    { itemLink: "/CaseDetails", itemName: "Existing Forms" },
  ];

  useEffect(() => {
    if (loginReducer.userType === ROLE_JUDGE && searchWarrantReducer.searchWarrantObject && !searchWarrantReducer.searchWarrantObject.hiddenJudgeSignature) {
      setLoadUserSignature(true);
    } else if (searchWarrantReducer.searchWarrantObject && !searchWarrantReducer.searchWarrantObject.hiddenComplaintSignature) {
        setLoadUserSignature(true);
      }
  }, [])

  useEffect(() => {
    if (searchWarrantReducer.searchWarrantObject.searchWarrantId > 0) {
      let dataObj1 = {
        countyId: searchWarrantReducer.searchWarrantObject.countyID,
        templateType: COMPL_SEARCH_WARRANT,
      };
      setCounty(county);
      dispatch(listSearchWarrantType(dataObj1));
      dispatch(getWarrantTemplateListWithData(dataObj1));
      let dataObj2 = {
        countyId: searchWarrantReducer.searchWarrantObject.countyID,
        agencyId: searchWarrantReducer.searchWarrantObject.agencyID,
      };
      dispatch(listSWApproveUserTypes(dataObj2));


      let loggedInUserId = loginReducer.userProfileId;
      let loggedInUserRole = loginReducer.userType;
      let { formStatus, creatorId, approvalSAId } = searchWarrantReducer.searchWarrantObject;
      formStatus = formStatus ? formStatus : "";

      if (approvalSAId && approvalSAId > 0) {
        setApprovalUserTypeEnable(false);
      }

      if (!(ROLE_STATES_ATTORNEY === loggedInUserRole || ROLE_LOCAL_PROSECUTOR === loggedInUserRole
        || ROLE_ATTORNEY_GENERAL === loggedInUserRole || ROLE_JUDGE === loggedInUserRole)) {
        setApprovalUserTypeVisible(true);
      }

      if (ROLE_ADMINISTRATOR === loggedInUserRole ||
          ROLE_COUNTY_ADMIN === loggedInUserRole || ROLE_STATES_ATTORNEY === loggedInUserRole ||
          ROLE_ATTORNEY_GENERAL === loggedInUserRole || ROLE_SA_ADMIN === loggedInUserRole ||
           ROLE_LOCAL_PROSECUTOR === loggedInUserRole) {

        if (creatorId !== loggedInUserId) {
          setSearchWarrantTypeEnable(false);
        }
        if (ROLE_ADMINISTRATOR === loggedInUserRole || ROLE_COUNTY_ADMIN === loggedInUserRole || ROLE_STATES_ATTORNEY === loggedInUserRole || ROLE_SA_ADMIN === loggedInUserRole
          || ROLE_LOCAL_PROSECUTOR === loggedInUserRole || ROLE_ATTORNEY_GENERAL === loggedInUserRole) {
          if (creatorId === loggedInUserId || formStatus === SEARCH_WAR_INCOMPLETE_STATUS
            || formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) {
            setSearchWarrantTypeEnable(true);
          }
        }

      } else {
        if (ROLE_POLICE_OFFICER !== loggedInUserRole) {
          if (creatorId !== loggedInUserId) {
            setSearchWarrantTypeEnable(false);
          }
        }
        if (ROLE_POLICE_OFFICER === loggedInUserRole || ROLE_AGENCY_ADMIN === loggedInUserRole) {
          setSearchWarrantTypeEnable(true);
        } else if (ROLE_STATES_ATTORNEY === loggedInUserRole || ROLE_JUDGE === loggedInUserRole || ROLE_SA_ADMIN === loggedInUserRole
          || ROLE_LOCAL_PROSECUTOR === loggedInUserRole || ROLE_ATTORNEY_GENERAL === loggedInUserRole) {
          if (creatorId === loggedInUserId || formStatus === SEARCH_WAR_INCOMPLETE_STATUS
            || formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) {
            setSearchWarrantTypeEnable(true);
          }
        }
      }
      fileUploaderAndSignaturePanelVisibility();
    }
  }, [searchWarrantReducer.searchWarrantObject.searchWarrantId]);

  const fileUploaderAndSignaturePanelVisibility = () =>{
    let loggedInUserId = loginReducer.userProfileId;
    let loggedInUserRole = loginReducer.userType;
    let { formStatus, creatorId, uploadedFileName, isOnBehalf } = searchWarrantReducer.searchWarrantObject;
    let userName = formatName(loginReducer.firstName, loginReducer.lastName, "");
    setSwornText("Complainant Signature will be only applied after the content of this complaint is sworn before a judge");
    setWhoSign("SIGNATURE OF COMPLAINANT");
    if (userName && userName.trim().length > 0) {
      setComplainantNameFooter(userName);
    }
    if (ROLE_ADMINISTRATOR === loggedInUserRole || ROLE_COUNTY_ADMIN === loggedInUserRole){
      if (formStatus === SEARCH_WAR_INCOMPLETE_STATUS || formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) {
        setIsCanEdit(false);
        setFileUploaderVisible(false);
        setSignatureCanvasVisible(false);
        if (creatorId === loggedInUserId || formStatus === SEARCH_WAR_INCOMPLETE_STATUS || formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) {
          setIsCanEdit(true);
          setFileUploaderVisible(true);
          if (!isOnBehalf) {
            setSignatureCanvasVisible(true);
          }
        }
      }
      else if (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS
        || formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS || formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS
        || formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
        || formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS || formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
          setIsCanEdit(false);
          setFileUploaderVisible(false);
          setSignatureCanvasVisible(false);
      }
    }
    else{
      if (loggedInUserRole === ROLE_POLICE_OFFICER || ROLE_AGENCY_ADMIN === loggedInUserRole) {
        setIsCanEdit(true);
        setFileUploaderVisible(true);
        setSignatureCanvasVisible(true);
      }
      else if (loggedInUserRole === ROLE_JUDGE || loggedInUserRole == ROLE_STATES_ATTORNEY ||
        loggedInUserRole === ROLE_SA_ADMIN || loggedInUserRole === ROLE_LOCAL_PROSECUTOR ||
        loggedInUserRole === ROLE_ATTORNEY_GENERAL) {
        setIsCanEdit(false);
        setFileUploaderVisible(false);
        setSignatureCanvasVisible(false);
        if (creatorId === loggedInUserId || formStatus === SEARCH_WAR_INCOMPLETE_STATUS || formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) {
          setIsCanEdit(true);
          setFileUploaderVisible(true);
          if (!isOnBehalf) {
            setSignatureCanvasVisible(true);
          }
          if (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS
            || formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS || formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS
            || formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
            || formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS || formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
            setIsCanEdit(false);
            setFileUploaderVisible(false);
          }
        }
        if (uploadedFileName && uploadedFileName.trim().length > 0) {
          setFileUploaderVisible(true);
        }
        if (loggedInUserRole === ROLE_JUDGE){
          setSignatureCanvasVisible(true);
          setWhoSign("JUDGE SIGNATURE");
         
        }
      }
    }
  }


  useEffect(() => {
    if (searchWarrantReducer.searchWarrantObject.templateId >0){
      if (searchWarrantReducer.searchWarrantObject.txtOffense1 && searchWarrantReducer.searchWarrantObject.txtOffense1.trim() !=null)
        dispatch(updateElementObjectValue({elementName: "txtOffense1", value: updateTagValue(searchWarrantReducer.searchWarrantObject.txtOffense1, searchWarrantReducer.searchWarrantObject.badgeNo)}))
      if (searchWarrantReducer.searchWarrantObject.txtSeized1 && searchWarrantReducer.searchWarrantObject.txtSeized1.trim() !=null)
        dispatch(updateElementObjectValue({elementName: "txtSeized1", value: updateTagValue(searchWarrantReducer.searchWarrantObject.txtSeized1, searchWarrantReducer.searchWarrantObject.badgeNo)}))
      if (searchWarrantReducer.searchWarrantObject.txtPoliceOfficerInfo && searchWarrantReducer.searchWarrantObject.txtPoliceOfficerInfo.trim() !=null)
        dispatch(updateElementObjectValue({elementName: "txtPoliceOfficerInfo", value: updateTagValue(searchWarrantReducer.searchWarrantObject.txtPoliceOfficerInfo, searchWarrantReducer.searchWarrantObject.badgeNo)}))
      if (searchWarrantReducer.searchWarrantObject.txtPersonInfo && searchWarrantReducer.searchWarrantObject.txtPersonInfo.trim() !=null)
        dispatch(updateElementObjectValue({elementName: "txtPersonInfo", value: updateTagValue(searchWarrantReducer.searchWarrantObject.txtPersonInfo, searchWarrantReducer.searchWarrantObject.badgeNo)}))
    }
  }, [searchWarrantReducer.searchWarrantObject.badgeNo])

  const handleConfirmation = () => {
    let templateName = searchWarrantType
    setconfirmPopupData(false);
    if (confirmOriginPlace === "handleFileChange") {
      // afterConfirmHandleFileChange();
    } else if (confirmOriginPlace === "onDeleteClick") {
      // this.afterConfirmOnDeleteClick();
    } else if (confirmOriginPlace === "warrantTypeSelect") {
      afterConfirmWarrantTypeSelect(templateName);
    } else if (confirmOriginPlace === "onSaveIncompleteClick") {
      afterConfirmOnSaveIncompleteClick();
    }
  };

  const acceptInfoModal = () =>{
    setshowInfoModal(false);
  }

  function prepareMessage(msg:any) {
    let buff = [];
    if (msg && msg.trim().length > 0) {
        let arr = msg.split("<br>");
        let line = "";
        for (let i = 0; i < arr.length; i++) {
            line = arr[i];
            buff.push(<div>{line}</div>);
        }
    }
    return buff;
  }
  const cancelSignConfirmModal = () => {
    setsignConfirmationModalShow(false);
  }

  const acceptSignConfirmModal = () => {
    setsignConfirmationModalShow(false);
    updateSearchWarrantOnNext();
    
  }
  // const fieldValueChangeHandler = (fieldName: any, fieldValue: any) => {
  //   let fieldObj = fieldName.split(".")[0];
  //   let fieldN = fieldName.split(".")[1];

  //   dispatch(
  //     updateElementValueSearchWarrantReducer({
  //       elementName: fieldObj,
  //       value: {
  //         ...searchWarrantReducer.searchWarrantObject,
  //         [fieldN]: fieldValue,
  //       },
  //     })
  //   );
  // };

  const handleWarrantTypeValChange = (name: string) => (e: any) => {
    let swType = e.label;
    let swTemplateId = e.value;
    
    if(searchWarrantReducer.searchWarrantObject.templateId ==0 || swType === "-- Select --"){
      dispatch(updateElementObjectValue({ elementName: "templateId", value: swTemplateId }));
      setconfirmOriginPlace("warrantTypeSelect");
      setSearchWarrantType(swType);
      setTemplateId(swTemplateId);
      loadFieldValuesForInitialTemplate(swType) 
    }else{
      let confirmText = (
        <>
          <label>
            Data entered in all fields of this form will be replaced using the
            template - {swType}{" "}
          </label>{" "}
          <label>Do you want to continue?</label>
        </>
      );
      setSearchWarrantType(swType);
      setTemplateId(swTemplateId);
      dispatch(updateElementObjectValue({ elementName: "templateId", value: swTemplateId }));
      setconfirmText(confirmText);
      setconfirmOriginPlace("warrantTypeSelect");
      setconfirmPopupData(true);
    }
    
  };

  const handleApprovalUserTypeValChange = (e: any) => {
    let { label, value } = e;
    let approvalUserTypeId = value;
    dispatch(updateElementObjectValue({ elementName: "approvalUserType", value: label }));
    dispatch(updateElementObjectValue({ elementName: "approvalUserTypeId", value: approvalUserTypeId }));
  }

  const handleCkEditorValChange = (name: string, editor: any) => {
    dispatch(updateElementObjectValue({ elementName: name, value: editor.getData() }));
  }

  const handleValChange = (e: any) => {
    dispatch(updateElementObjectValue({ elementName: e.target.name, value: e.target.value }));
  }

  const handleBadgeNoValChange = (e: any) => {
    e.target.value = e.target.value.replace(/[a-zA-Z]/g, "");
    dispatch(updateElementObjectValue({ elementName: e.target.name, value: e.target.value }));
  }

  const updateSearchWarrantOnNext = () => {
    const warrantFormObj = {...searchWarrantReducer.searchWarrantObject};
       if (ROLE_JUDGE === loginReducer.userType) {
          dispatch(updateElementObjectValue({elementName:"hiddenJudgeSignature", value:signPanelReducer.sign}));
          warrantFormObj.hiddenJudgeSignature = signPanelReducer.sign;
       } else {
          dispatch(updateElementObjectValue({elementName:"hiddenComplaintSignature", value:signPanelReducer.sign}));
          warrantFormObj.hiddenComplaintSignature = signPanelReducer.sign;
       }

    let dataObj = {
      searchWarrantId: searchWarrantReducer.searchWarrantObject.searchWarrantId,
      warrantFormObj: fillTags(warrantFormObj),
      fileData: "documentFile",
      navigate: navigate,
      navigateTo: "/SearchWarrantsubmit",
      callingfrom : "onNext"
    };
    dispatch(updateSearchWarrant(dataObj));
  }

  const saveIncompleteClick = async () => {
    let confirmText = (
      <>
        <label>
        Form will be saved with Incomplete status.
        </label>{" "}
        <label>Do you want to continue?</label>
      </>
    );
    setconfirmText(confirmText);
    setconfirmOriginPlace("onSaveIncompleteClick");
    setconfirmPopupData(true);
  }

  const afterConfirmOnSaveIncompleteClick = () => {
    let warrantFormObj = {
      ...searchWarrantReducer.searchWarrantObject,
      formStatus: SEARCH_WAR_INCOMPLETE_STATUS
    }
    if (ROLE_JUDGE === loginReducer.userType) {
      dispatch(updateElementObjectValue({elementName:"hiddenJudgeSignature", value:signPanelReducer.sign}));
      warrantFormObj.hiddenJudgeSignature = signPanelReducer.sign;
   } else {
      dispatch(updateElementObjectValue({elementName:"hiddenComplaintSignature", value:signPanelReducer.sign}));
      warrantFormObj.hiddenComplaintSignature = signPanelReducer.sign;
   }
    let dataObj = {
      searchWarrantId: searchWarrantReducer.searchWarrantObject.searchWarrantId,
      warrantFormObj: fillTags(warrantFormObj),
      fileData: "documentFile",
      navigate: navigate,
      navigateTo: "/CaseDetails",
      callingfrom : "SWincomplete"
    };
    
    dispatch(updateSearchWarrant(dataObj));
  }

  const nextClick = async () => {
    let Obj = {
      searchWarrantType: searchWarrantReducer.searchWarrantObject.searchWarrantType,
      approvalUserTypeId: searchWarrantReducer.searchWarrantObject.approvalUserTypeId,
      badgeNo: searchWarrantReducer.searchWarrantObject.badgeNo,
      txtPersonInfo: searchWarrantReducer.searchWarrantObject.txtPersonInfo,
      txtOffense1: searchWarrantReducer.searchWarrantObject.txtOffense1,
      txtSeized1: searchWarrantReducer.searchWarrantObject.txtSeized1,
      txtPoliceOfficerInfo: searchWarrantReducer.searchWarrantObject.txtPoliceOfficerInfo,
      sign: signPanelReducer.sign,
      signatureCanvasVisible: signatureCanvasVisible,
    }
    try {
      let schema = [];
      schema.push(defaultSwSchema);

      if (!(ROLE_STATES_ATTORNEY === loginReducer.userType || ROLE_LOCAL_PROSECUTOR === loginReducer.userType 
        || ROLE_ATTORNEY_GENERAL === loginReducer.userType || ROLE_JUDGE === loginReducer.userType || ROLE_SA_ADMIN === loginReducer.userType)) {
        schema.unshift(
          yup.object().shape({
            approvalUserTypeId: yup.number().nullable().required("Approval agencie is required."),
          })
        );
      }
      schema = merge(...schema);
      await schema.validate(Obj, {
        abortEarly: false,
      });


      let isValidateSignPixel = validateSignaturePixel(signatureCanvasVisible, signPanelReducer.signThreshold);
      if (!isValidateSignPixel) {
        setsignConfirmationModalShow(true);
        setshowIntoModalMessage("Are you sure the signature you provided is valid?");
      }
      else {
        updateSearchWarrantOnNext();
      }

    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const afterConfirmWarrantTypeSelect = async (templateName:any) => {
    let warrantArray = warrantTemplateList;
    let isTemplateAfterConfiguration = false;
    let personInfoHeader = "";
    let offense1Header = "";
    let seized1Header = "";
    let policeOfficerInfoHeader = "";
    // PR 0011324 - START
    let personOfHeaderPrefix = "";
    let personOfHeaderSuffix = "";
    let offenseInfoHeaderSuffix = "";
    let policeOfficerInfoHeaderSuffix = "";
    let impoundLanguage = "";
    let complaintHeaderPrefix = "";
    let warrantField1Prefix = "";
    let warrantField2Prefix = "";
    let warrantHeader3Prefix = "";
    let warrantOrderCommand = "";
    let complaintTitle = "";
    let swTitle = "";
    let impoundComplaintLanguage = "";
    // PR 0011324 - END
    for (const tempElement of warrantArray as any){
      
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "PersonOfHeader")){
        isTemplateAfterConfiguration = true;
        personInfoHeader = tempElement.templateValue

      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "OffenseinfoHeader")){
        isTemplateAfterConfiguration = true;
        offense1Header =tempElement.templateValue

      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "SeizedinfoHeader")){
        isTemplateAfterConfiguration = true;
        seized1Header =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "PoliceOfficerinfoHeader")){
        isTemplateAfterConfiguration = true;
        policeOfficerInfoHeader =tempElement.templateValue
      }
      // PR 0011324 - START
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "personOfHeaderPrefix")){
        isTemplateAfterConfiguration = true;
        personOfHeaderPrefix =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "personOfHeaderSuffix")){
        isTemplateAfterConfiguration = true;
        personOfHeaderSuffix =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "offenseInfoHeaderSuffix")){
        isTemplateAfterConfiguration = true;
        offenseInfoHeaderSuffix =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "policeOfficerInfoHeaderSuffix")){
        isTemplateAfterConfiguration = true;
        policeOfficerInfoHeaderSuffix =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "impoundLanguage")){
        isTemplateAfterConfiguration = true;
        impoundLanguage =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "complaintHeaderPrefix")){
        isTemplateAfterConfiguration = true;
        complaintHeaderPrefix =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "warrantField1Prefix")){
        isTemplateAfterConfiguration = true;
        warrantField1Prefix =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "warrantField2Prefix")){
        isTemplateAfterConfiguration = true;
        warrantField2Prefix =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "warrantHeader3Prefix")){
        isTemplateAfterConfiguration = true;
        warrantHeader3Prefix =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "warrantOrderCommand")){
        isTemplateAfterConfiguration = true;
        warrantOrderCommand =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "complaintTitle")){
        isTemplateAfterConfiguration = true;
        complaintTitle =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "swTitle")){
        isTemplateAfterConfiguration = true;
        swTitle =tempElement.templateValue
      }
      if ((tempElement.templateName === templateName) && (tempElement.templateField === "impoundComplaintLanguage")){
        isTemplateAfterConfiguration = true;
        impoundComplaintLanguage =tempElement.templateValue
      }
      // PR 0011324 - END
    }
    if(isTemplateAfterConfiguration === false){
      dispatch(updateElementObjectValue({ elementName: "txtPersonInfoHeader", value: "Complainant, before the undersigned Judge of the "+searchWarrantReducer.searchWarrantObject.countyNumberCircuitCourt?searchWarrantReducer.searchWarrantObject.countyNumberCircuitCourt:"Eighteenth Judicial Circuit, and requests the issuance of a Search Warrant to search the person of and/or the place of and/or the item of" }));
      dispatch(updateElementObjectValue({ elementName: "txtOffense1Header", value: "and therefrom seize the following instruments, articles and things which have been used in the commission of, or which constitute evidence of, the offense of :" }));
      dispatch(updateElementObjectValue({ elementName: "txtSeized1Header", value: "To Wit" }));
      dispatch(updateElementObjectValue({ elementName: "txtPoliceOfficerInfoHeader", value: "COMPLAINANT states that there is probable cause to believe, based on the following facts, that the above listed to be seized are now located upon the person of and/or the place of and/or the item ofset forth above." }));
    }
    
    dispatch(updateElementObjectValue({elementName: "txtPersonInfoHeader", value: setTemplateDataToFields(personInfoHeader, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "txtOffense1Header", value:  setTemplateDataToFields(offense1Header, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "txtSeized1Header", value: setTemplateDataToFields(seized1Header, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "txtPoliceOfficerInfoHeader", value: setTemplateDataToFields(policeOfficerInfoHeader, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    // PR 0011324 - START
    dispatch(updateElementObjectValue({elementName: "personOfHeaderPrefix", value:   setTemplateDataToFields(personOfHeaderPrefix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "personOfHeaderSuffix", value:   setTemplateDataToFields(personOfHeaderSuffix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "offenseInfoHeaderSuffix", value:   setTemplateDataToFields(offenseInfoHeaderSuffix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "policeOfficerInfoHeaderSuffix", value:   setTemplateDataToFields(policeOfficerInfoHeaderSuffix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "complaintHeaderPrefix", value:   setTemplateDataToFields(complaintHeaderPrefix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "warrantField1Prefix", value:   setTemplateDataToFields(warrantField1Prefix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "warrantField2Prefix", value:   setTemplateDataToFields(warrantField2Prefix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "warrantHeader3Prefix", value:   setTemplateDataToFields(warrantHeader3Prefix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "warrantOrderCommand", value:   setTemplateDataToFields(warrantOrderCommand, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "impoundLanguage", value:   setTemplateDataToFields(impoundLanguage, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "complaintTitle", value:   setTemplateDataToFields(complaintTitle, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "swTitle", value: setTemplateDataToFields(swTitle, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    dispatch(updateElementObjectValue({elementName: "impoundComplaintLanguage", value: setTemplateDataToFields(impoundComplaintLanguage, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears)}))
    // PR 0011324 - END
    
    let county: any = countyList[0];
    dispatch(updateElementObjectValue({ elementName: "searchWarrantType", value: templateName/*searchWarrantType*/ }));
    //setLoadUserSignature(true);
    if (loginReducer.userType === ROLE_JUDGE && searchWarrantReducer.searchWarrantObject.hiddenJudgeSignature === null) {
        dispatch(setSignature(searchWarrantReducer.searchWarrantObject.hiddenUserSignature));
    } else {
        if(searchWarrantReducer.searchWarrantObject.hiddenComplaintSignature === null) {
        dispatch(setSignature(searchWarrantReducer.searchWarrantObject.hiddenUserSignature))  
        }
    }
    dispatch(
      getWarrantTemplateData({
        countyId: searchWarrantReducer.searchWarrantObject.countyID,
        warrantForm: COMPL_SEARCH_WARRANT,
        templateName: templateName/*searchWarrantType*/,
      })
    );
  };

  const loadFieldValuesForInitialTemplate = (templateName:any) => {
    afterConfirmWarrantTypeSelect(templateName)
  }

  const previewForm = () => {
    const warrantFormObj = { ...searchWarrantReducer.searchWarrantObject };
    if (ROLE_JUDGE === loginReducer.userType) {
      dispatch(updateElementObjectValue({ elementName: "hiddenJudgeSignature", value: signPanelReducer.sign }));
      warrantFormObj.hiddenJudgeSignature = signPanelReducer.sign;
    } else {
      dispatch(updateElementObjectValue({ elementName: "hiddenComplaintSignature", value: signPanelReducer.sign }));
      warrantFormObj.hiddenComplaintSignature = signPanelReducer.sign;
    }

    dispatch(
      previewDraftingOrder({
        formId: searchWarrantReducer.searchWarrantObject.searchWarrantId,
        formName: COMPL_SEARCH_WARRANT,
        formObject: fillTags(warrantFormObj),
      })
    );
  };



  const fillTags = (obj: any) => {
    return (
      {
        ...obj,
        personOfHeaderPrefix : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.personOfHeaderPrefix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        personOfHeaderSuffix : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.personOfHeaderSuffix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        offenseInfoHeaderSuffix : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.offenseInfoHeaderSuffix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        policeOfficerInfoHeaderSuffix : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.policeOfficerInfoHeaderSuffix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),

        impoundLanguage : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.impoundLanguage, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        complaintHeaderPrefix : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.complaintHeaderPrefix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        warrantField1Prefix : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.warrantField1Prefix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        warrantField2Prefix : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.warrantField2Prefix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),

        warrantHeader3Prefix : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.warrantHeader3Prefix, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        warrantOrderCommand : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.warrantOrderCommand, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        complaintTitle : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.complaintTitle, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        swTitle : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.swTitle, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),

        txtPoliceOfficerInfoHeader : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.txtPoliceOfficerInfoHeader, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        txtPersonInfoHeader : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.txtPersonInfoHeader, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        txtSeized1Header : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.txtSeized1Header, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
        txtOffense1Header : setTemplateDataToFields(searchWarrantReducer.searchWarrantObject.txtOffense1Header, searchWarrantReducer.searchWarrantObject.agencyName,searchWarrantReducer.searchWarrantObject.badgeNo, searchWarrantReducer.searchWarrantObject.txtAppears),
      }
    )
  }

  const formFillerClick = () =>{
    let array = [
      searchWarrantReducer.searchWarrantObject.txtPersonInfo, searchWarrantReducer.searchWarrantObject.txtOffense1, 
      searchWarrantReducer.searchWarrantObject.txtSeized1, searchWarrantReducer.searchWarrantObject.txtPoliceOfficerInfo
    ];
    setShowFormFiller(true);
    setFieldTitleValues(array);
  }

  const updateFieldFromWizard = (name: string, value: string) =>{
    dispatch(updateElementObjectValue({elementName:name, value: value}));
  }

  const removeTags = (input: string) =>{
    if (input != null)
      return input.replace("<p>", "").replace("</p>", " ").replaceAll("--", "");
    return input;
  }

  // const replaceDefinedTags = (input: string) =>{
  //   return input?.replace("[[OFFICER_NAME]]", searchWarrantReducer.searchWarrantObject.txtAppears).replace("[[AGENCY]]", searchWarrantReducer.searchWarrantObject.agencyName)
  // }

  const formData = [
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: "STATE OF ILLINOIS",
        className: "fw-bold text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h5",
        value: (
          <>
            UNITED STATES OF AMERICA
            <br />
            {searchWarrantReducer.searchWarrantObject.lblHeadingInTheCircuitSW}
          </>
        ),
        className: "fw-bold text-center",
      },
      colSize: 6,
    },
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: searchWarrantReducer.searchWarrantObject.lblHeadingCountySW,
        className: "fw-bold text-end text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
    },

    {
      type: "textInput",
      props: {
        type: "text",
        value: searchWarrantReducer.searchWarrantObject.txtAgency,
        name: "templateName",
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h4",
        value: "COMPLAINT FOR SEARCH WARRANT",
        className: "fw-bold text-center text-danger",
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        value: searchWarrantReducer.searchWarrantObject.txtCaseNumber,
        name: "templateName",
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: 0 },
          ...sortedData.map((swtype: any) => {
            return {
              label: swtype.templateName,
              value: swtype.templateId,
            };
          }),
        ],
        isDefaultLabel : true,
        defaultValue: { label: "-- Select --", value: 0 },
        onChange: handleWarrantTypeValChange("searchWarrantObject.searchWarrantType"),
        isError: errorKeys.includes("searchWarrantType"),
        placeholder: "-- Select --",
        value: sortedData
          .map((swtype: any) => {
            return {
              label: swtype.templateName,
              value: swtype.templateId,
            };
          })
          .find(
            (item) => {
              if (item.label = searchWarrantReducer.searchWarrantObject.searchWarrantType)
                return {
                  label: item.label,
                  value: item.value,
                };
            }
          ),
        isDisabled: !searchWarrantTypeEnable,
      },
      colSize: 5,
      label: "Search Warrant Type :",
      inLine: true,
    },
    {
      type: "htmlTag",
      props: {
        type: "div",
        value: searchWarrantReducer.searchWarrantObject.templateId==0?"*Note: Please select Order type.":"",
        className: "text-padding",
      },
      colSize: 6,
    },
    {
      type: "selectInput",
      props: {
        options: [
          ...swApprovalUserTypes.map((approvalUserType: any) => {
            return {
              label: approvalUserType.type,
              value: approvalUserType.userTypeID,
            };
          }),
        ],
        onChange: handleApprovalUserTypeValChange,
        isError: errorKeys.includes("approvalUserTypeId"),
        placeholder: "-- Select --",
        value: swApprovalUserTypes
        .map((approvalUserType: any) => {
          return {
            label: approvalUserType.type,
            value: approvalUserType.userTypeID,
          };
        })
        .find(
          (item) => {
            if (item.label = searchWarrantReducer.searchWarrantObject.approvalUserType)
              return {
                label: item.label,
                value: item.value,
              };
          }
        ),
      },
      colSize: 5,
      emptyCol: 12,
      label: "Approval Agency :",
      inLine: true,
      colHide: (ROLE_STATES_ATTORNEY === loginReducer.userType || ROLE_LOCAL_PROSECUTOR === loginReducer.userType 
        || ROLE_ATTORNEY_GENERAL === loginReducer.userType || ROLE_JUDGE === loginReducer.userType || ROLE_SA_ADMIN === loginReducer.userType) ? true : false,
    },
    {
      type: "textInput",
      props: {
        placeholder: searchWarrantTypeEnable?"Agency Case Number":"",
        name: "agencyCaseNumber",
        value: searchWarrantReducer.searchWarrantObject.agencyCaseNumber,
        onChange: handleValChange,
        disabled: !searchWarrantTypeEnable,
        maxlength: "50",
      },
      colSize: 5,
      emptyCol: 12,
      label: "Agency Case Number :",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        // onChange: handleSelectChange(),
        placeholder: "Officer Name",
        name: "searchWarrantObject.txtAppears",
        value: searchWarrantReducer.searchWarrantObject.txtAppears,
        disabled: true,
      },
      colSize: 5,
      label: "Now appears :",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        name: "badgeNo",
        value: searchWarrantReducer.searchWarrantObject.badgeNo,
        onChange: handleBadgeNoValChange,
        isError: errorKeys.includes("badgeNo"),
        placeholder: "Badge",
        
      },
      colSize: 3,
      label: "Badge/Employee #:",
      inLine: true,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
      emptyCol:10,
    },
  ];

  let formField = [
    {
      type: 'formButton',
      props: {
        children: "Form Filler Wizard",
        onClick: formFillerClick,
        className: "text-end"
      },
      inLine: true,
      colSize: 2,
    },
    {
      type: "htmlTag",
      props: {
        value:           
        <div
          dangerouslySetInnerHTML={{
            __html: removeTags((searchWarrantReducer.searchWarrantObject.personOfHeaderPrefix)) +removeTags((searchWarrantReducer.searchWarrantObject.txtPersonInfoHeader)),
          }}
        >
        </div>

      },
      colSize: 10,
    },
    {
      type: "htmlTag",
      props: {
        value:           
        <div
          dangerouslySetInnerHTML={{
            __html: (searchWarrantReducer.searchWarrantObject.personOfHeaderSuffix),
          }}
        >
        </div>
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: searchWarrantReducer.searchWarrantObject.txtPersonInfo
          ? searchWarrantReducer.searchWarrantObject.txtPersonInfo
          : "",
        //data: "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("txtPersonInfo", editor);
        },
        isError: errorKeys.includes("txtPersonInfo"),
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value:           
        <div
          dangerouslySetInnerHTML={{
            __html: removeTags((searchWarrantReducer.searchWarrantObject.txtOffense1Header)),
          }}
        >
        </div>

      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: <div
          dangerouslySetInnerHTML={{
            __html: removeTags((searchWarrantReducer.searchWarrantObject.offenseInfoHeaderSuffix)),
          }}
        >
        </div>,
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: searchWarrantReducer.searchWarrantObject.txtOffense1
          ? searchWarrantReducer.searchWarrantObject.txtOffense1
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("txtOffense1", editor);
        },
        isError: errorKeys.includes("txtOffense1"),
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value:           
        <div
        dangerouslySetInnerHTML={{
          __html: removeTags((searchWarrantReducer.searchWarrantObject.txtSeized1Header)),
        }}
      >
      </div>
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: searchWarrantReducer.searchWarrantObject.txtSeized1
          ? searchWarrantReducer.searchWarrantObject.txtSeized1
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("txtSeized1", editor);
        },
        isError: errorKeys.includes("txtSeized1"),
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value:           
        <div
        dangerouslySetInnerHTML={{
          __html: removeTags((searchWarrantReducer.searchWarrantObject.txtPoliceOfficerInfoHeader)),
        }}
      >
      </div>
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: 
        <div
        dangerouslySetInnerHTML={{
          __html: removeTags((searchWarrantReducer.searchWarrantObject.policeOfficerInfoHeaderSuffix)),
        }}
      >
      </div>
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: searchWarrantReducer.searchWarrantObject.txtPoliceOfficerInfo
          ? searchWarrantReducer.searchWarrantObject.txtPoliceOfficerInfo
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("txtPoliceOfficerInfo", editor);
        },
        isError: errorKeys.includes("txtPoliceOfficerInfo"),
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: (        
        <div
          dangerouslySetInnerHTML={{
            __html: (searchWarrantReducer.searchWarrantObject.impoundComplaintLanguage),
          }}
        >
        </div>
        ),

      },
      colSize: 12,
    },
    {
      type: "fileUploader",
      props: {
        className: fileUploaderVisible? "" : "d-none",
        fileName:searchWarrantReducer.searchWarrantObject.uploadedFileName,
        formType: COMPL_SEARCH_WARRANT_WITH_SLASH,
        isCanEdit: isCanEdit,
      },
      colSize: 12,
    },
    {
      type: "signPanel",
      props: {
        className: signatureCanvasVisible ? "d-block" : "d-none",
        name: "sign",
        appearPersonText : complainantNameFooter,
        whoSign : whoSign,
        swText : swornText,
        isError: errorKeys.includes("sign"),
        loadUserSignature: loadUserSignature,
       // isJudgeSignatureAvaiable: searchWarrantReducer.searchWarrantObject.hiddenJudgeSignature ? true : false
      },
      colSize: 12,
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-primary ms-2",
        children: (
          <>
            Preview <RiFileTextLine />
          </>
        ),
        onClick: () => {
          previewForm();
        },
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: ROLE_POLICE_OFFICER === loginReducer.userType || ROLE_AGENCY_ADMIN === loginReducer.userType
          || ROLE_ADMINISTRATOR === loginReducer.userType ? "d-block btn-primary ms-2" : "d-none btn-primary ms-2" ,
        children: (
          <>
            Save Incomplete <RiSave3Line />
          </>
        ),
        onClick: () => {
          saveIncompleteClick();
        },
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-primary ms-2",
        children: (
          <>
            Next <RiArrowRightLine />
          </>
        ),
        onClick: () => {
          nextClick();
        },
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-danger ms-2",
        children: (
          <>
            <RiCloseFill /> Cancel
          </>
        ),
        onClick: () => {
          navigate("/CaseDetails");
          dispatch(
            listDocuments({
              pageNo: 0,
              pageSize: SEARCH_WARRANT_PAGE_SIZE,
              agencyId: 0,
              countyId: 0,
              status: "-- ALL --",
              caseNumber: "",
              formType: "-- ALL --",
              issueTime: "-- ALL --",
              lfcNumber: "",
              sortBy: "",
            })
          );
        },
      },
    },
  ];

  return (
    <>
      <Header headerName="Search Warrant" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Search Warrant"
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent 
          totalColumns={12} 
          colSize={6} 
          componentData={searchWarrantReducer.searchWarrantObject.templateId==0?formData:[...formData,...formField]}
          
          components = {[
            {
              type: "formButton",
              node: (props: any) => (
                <div className="text-end">
                  <ButtonComponent {...props}>{props.children}</ButtonComponent>
                </div>
              ),
            }
          ]}
        />
      </div>
      <ConfirmPopup
        children={confirmText}
        show={confirmPopupData}
        onHide={() => setconfirmPopupData(false)}
        isConfirmation={handleConfirmation}
      />
      <ConfirmPopup 
        show={signConfirmationModalShow}
         onHide={cancelSignConfirmModal} 
        isConfirmation={acceptSignConfirmModal}>
        { prepareMessage(showIntoModalMessage) }
      </ConfirmPopup>
      <InfoPopup 
        show={showInfoModal} 
        onHide={acceptInfoModal} variant = "danger">
        { prepareMessage(showIntoModalMessage) }
        </InfoPopup>
      <FormFiller
       show={showFormFiller}
       onHide = {() => setShowFormFiller(false)}
       fieldTitles={fieldTitles}
       names = {names}
       values = {fieldTitleValues}
       onChangeHandler={updateFieldFromWizard}
      />
    </>
  );
};

export default SearchWarrant;
