import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementObject } from "../../utils/updateElementObject";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import moment from "moment";
import { showHideLoadingGif, updateDocGenerateError } from "./commonSlice";
import { fetchCountryList } from "../../services/EComplaintService";
import {
  getEcomplaintMainDetails,
  setComplaintMainData,
  updateReducerElement,
  updateElementObjectValueComplaintMain
} from "./complaintMainSlice";
import { getLFCConcurrentObj } from "./commonFormSlice";
import { FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE, CREATE_LF_FORM_SUMMON, LF_COMPLAINT_PROCESSING, LF_SIGNED_STATUS, LF_FILED_STATUS, LF_CASE_ASSIGNMENT_READY_STATUS, LF_STATUS_PENDING_SUPERVISOR_REVIEW, LF_STATUS_PENDING_SUPERVISOR_RETURNED, LF_RETURNED_STATUS, LF_COMPLETE_STATUS, LF_REJECTED_STATUS, ROLE_POLICE_OFFICER, LF_SEND_TO_JUDGE_STATUS, ROLE_AGENCY_ADMIN, TYPE_OF_ARREST_ARRESTWARRANT, TYPE_OF_ARREST_SUMMON, LF_ARRW_REJECTED_STATUS, LF_SUMMON_RETURNED_STATUS, LF_SUMMON_REJECTED_STATUS } from "../../constants/common";
import { editComplaintFromSummons, updateElementValuecomplaintDashReducer } from "./ecomplaintDashboardSlice";
import Summons from "../../components/longform/Summons";


interface defInfoObj {
  defFirstName: string,
  defMiddleName: string,
  defLastName: string,
  defSuffix: string,
  gender: string,
  defRace: string,
  defDob: string,
  defHeight: string,
  defWeight: string,
  defEyes: string,
  defHair: string,
  defDriversLicense: string,
  defDlState: string,
  dcn: string,
  defAddressUnknown: string,
  defForeignAddress: string,
  defAddress1: string,
  defAddress2: string,
  defCity: string,
  defState: string,
  defZip: string,
  defTelephone: string,
  defCountry: string
}


interface SummonsDataObject {
  formId: number;
  caseNumber: string;
  courtDate: string;
  courtTime: string;
  courtRoom: string;
  isAppearInPerson: string;
  isAppearRemotely: string;
  judgeSignature: string;
  defFirstName: string,
  defMiddleName: string,
  defLastName: string,
  defSuffix: string,
  gender: string,
  defRace: string,
  defDob: string,
  defHeight: any,
  defWeight: any,
  defEyes: string,
  defHair: string,
  defDriversLicense: string,
  defDlState: string,
  dcn: string,
  defAddressUnknown: string,
  defForeignAddress: string,
  defAddress1: string,
  defAddress2: string,
  defCity: string,
  defState: string,
  defZip: string,
  defTelephone: string,
  defCountry: string,
  conditionsOfPretrialRelease: string,
  defendentInfo: {
    defFirstName: string,
    defMiddleName: string,
    defLastName: string,
    defSuffix: string,
    gender: string,
    defRace: string,
    defDob: string,
    defHeight: any,
    defWeight: any,
    defEyes: string,
    defHair: string,
    defDriversLicense: string,
    defDlState: string,
    dcn: string,
    defAddressUnknown: string,
    defForeignAddress: string,
    defAddress1: string,
    defAddress2: string,
    defCity: string,
    defState: string,
    defZip: string,
    defTelephone: string,
    defCountry: string
  };
}

interface SummonsDataState {
  saveStatus: LoadingType;
  signStatus: LoadingType;
  status: LoadingType;
  error: LoadingType;
  countries: any[];
  summonState: string;
  fetchSummonStatus: LoadingType;
  summonsDataObject: SummonsDataObject;
  defInfoObject: defInfoObj
}

const initialSummonState: SummonsDataObject = {
  formId: 0,
  caseNumber: "",
  courtDate: "",
  courtTime: "",
  courtRoom: "",
  isAppearInPerson: "",
  isAppearRemotely: "",
  judgeSignature: "",

  defFirstName: "",
  defMiddleName: "",
  defLastName: "",
  defSuffix: "",
  gender: "",
  defRace: "",
  defDob: "",
  defHeight: "",
  defWeight: "",
  defEyes: "",
  defHair: "",
  defDriversLicense: "",
  defDlState: "",
  dcn: "",
  defAddressUnknown: "",
  defForeignAddress: "",
  defAddress1: "",
  defAddress2: "",
  defCity: "",
  defState: "",
  defZip: "",
  defTelephone: "",
  defCountry: "",
  conditionsOfPretrialRelease: "",
  defendentInfo: {
    defFirstName: "",
    defMiddleName: "",
    defLastName: "",
    defSuffix: "",
    gender: "",
    defRace: "",
    defDob: "",
    defHeight: "",
    defWeight: "",
    defEyes: "",
    defHair: "",
    defDriversLicense: "",
    defDlState: "",
    dcn: "",
    defAddressUnknown: "",
    defForeignAddress: "",
    defAddress1: "",
    defAddress2: "",
    defCity: "",
    defState: "",
    defZip: "",
    defTelephone: "",
    defCountry: ""
  }
}

const initialDefInfoObjectState: defInfoObj = {
  defFirstName: "",
  defMiddleName: "",
  defLastName: "",
  defSuffix: "",
  gender: "",
  defRace: "",
  defDob: "",
  defHeight: "",
  defWeight: "",
  defEyes: "",
  defHair: "",
  defDriversLicense: "",
  defDlState: "",
  dcn: "",
  defAddressUnknown: "",
  defForeignAddress: "",
  defAddress1: "",
  defAddress2: "",
  defCity: "",
  defState: "",
  defZip: "",
  defTelephone: "",
  defCountry: ""
}

const initialState = {
  saveStatus: "idle",
  signStatus: "idle",
  status: "idle",
  error: "idle",
  countries: [],
  summonState: "",
  fetchSummonStatus: "idle",
  defInfoObject: initialDefInfoObjectState,
  summonsDataObject: initialSummonState
} as SummonsDataState;


export const fetchSummons = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: string }
>(
  "issueSummons/fetchSummons",
  async (ecFormId, { rejectWithValue, getState, dispatch }) => {
    dispatch(showHideLoadingGif(true));
    try {
      let { token } = getState().login;
      const response = await doFetch({
        url: "ecomplaintservices/getSummon/" + ecFormId,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching arrest warrant"
      );
    }
  }
);

export const fetchInitialSummons = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: string }
>(
  "issueSummons/fetchInitialSummons",
  async (ecomplaintId, { rejectWithValue, getState, dispatch }) => {
    dispatch(showHideLoadingGif(true));
    try {
      let { token } = getState().login;
      const response = await doFetch({
        url: "ecomplaintservices/getInitialSummonData/" + ecomplaintId,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();

      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching arrest warrant"
      );
    }
  }
);

export const saveSummons = createAsyncThunk<
  any,
  {
    ecSummonsData: SummonsDataObject;
    ecomplaintId: number;
    status: string;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "issueSummons/saveSummons",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const { summonState } = getState().summons;
    dispatch(showHideLoadingGif(true));

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      let { token } = getState().login;
      const response = await doFetch({
        url:
          "ecomplaintservices/saveSummons/" +
          data.ecomplaintId +
          "/" +
          data.status,
        token,
        type: "POST",
        body: data.ecSummonsData,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        if (summonState === "create" || summonState === "editThrounghMainPage") {
          dispatch(getEcomplaintMainDetails({ ecomplaintId: data.ecomplaintId, callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE }));
          data.navigate("/eComplaintMainPage");
        } else {
          data.navigate("/eComplaintDashBoard");
        }
        dispatch(resetSummons());
        dispatch(showHideLoadingGif(false));
        const complaintMain = await response.json();
        dispatch(updateElementObjectValueComplaintMain(complaintMain));
        return;
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while saving arrest warrant"
      );
    }
  }
);

export const createSummon = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>("issueSummons/createSummons",
  async (
    {
      ecomplaintId,
      navigate,
    },
    { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    try {
      dispatch(showHideLoadingGif(true));
      let ecList = getState().complaintMain.ecFormList;
      let concurrentObj = getLFCConcurrentObj(ecList);

      const response: any = await doFetch({
        url: "ecomplaintservices/createSummons/" + ecomplaintId,
        token,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const resp = await response.json();
        if (resp && resp.ecFormList) {
          resp.ecFormList.forEach((element: any) => {
            if (element.formTypeName === CREATE_LF_FORM_SUMMON) {
              let data = JSON.parse(element.formData);
              // let Obj = {
              //   ...data,
              //   defDob: moment(data.defDob).format("YYYY-MM-DD"),
              // };
              dispatch(updateSummonsCauseObject(data));
            }
          });
        }
        dispatch(getEcomplaintMainDetails({ ecomplaintId: ecomplaintId, callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE }));
        navigate("/issueSummons")
        return resp;
      }
      return rejectWithValue("Error");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error");
    }

  }
);

export const signSummons = createAsyncThunk<
  any,
  {
    summonObj: SummonsDataObject;
    ecomplaintId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "issueSummons/signSummons",
  async (data, { rejectWithValue, getState, dispatch }) => {
    dispatch(showHideLoadingGif(true));

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      let { token } = getState().login;
      const response = await doFetch({
        url: "ecomplaintservices/signSummons/" + data.ecomplaintId,
        token,
        type: "POST",
        body: data.summonObj,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        data.navigate("/eComplaintDashBoard");
        dispatch(showHideLoadingGif(false));
        const responseData = await response.text();
        if (responseData) {
          let message = "";
          if (responseData === LF_COMPLAINT_PROCESSING) {
            message = "The summon has been submitted for processing.";
          } else if (responseData === LF_SIGNED_STATUS) {
            message = "The summon has been signed.";
          } else if (responseData === LF_FILED_STATUS) {
            message = "The summon has been signed.";
          } else if (responseData === LF_CASE_ASSIGNMENT_READY_STATUS) {
            message = "The summon has been submitted for assigning case number.";
          }
          dispatch(
            updateElementValuecomplaintDashReducer({
              elementName: "successMessage",
              value: message,
            })
          );
        }
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while saving summons"
      );
    }
  }
);

export const returnToOfficerSummons = createAsyncThunk<
  any,
  {
    navigate: any;
    navigateFrom: string;
    formId: number
    formData?: any
  },
  { state: RootState; rejectValue: string }
>(
  "issueSummons/returnToOfficerSummons",
  async ({ navigate, navigateFrom, formId, formData }, { getState, dispatch }) => {
    let ecomplaintReducer = getState().complaintMain;
    let { ecIsCompalintSupervised, userType } = getState().login;

    const summon = ecomplaintReducer.ecFormList.find(
      (item: any) => item.formTypeName === CREATE_LF_FORM_SUMMON
    );
    let statusObj;
    let data: any;
    if (summon.status === LF_STATUS_PENDING_SUPERVISOR_REVIEW) {
      statusObj = insertEcFormData(
        summon.status,
        LF_STATUS_PENDING_SUPERVISOR_RETURNED,
        ecIsCompalintSupervised,
        userType,
        ecomplaintReducer
      );
      data = {
        formId: summon.formId,
        formType: summon.formTypeName,
        ecomplaintId: summon.ecomplaintId,
        status: statusObj.caseStatus,
        formData: formData ? formData : {},
        navigate: navigate,
      };

    } else {
      statusObj = insertEcFormData(
        summon.status,
        LF_RETURNED_STATUS,
        ecIsCompalintSupervised,
        userType,
        ecomplaintReducer
      );
      data = {
        formId: summon.formId,
        formType: summon.formTypeName,
        ecomplaintId: summon.ecomplaintId,
        status: statusObj.ecFormStatus,
        formData: formData ? formData : {},
        navigate: navigate,
      };

    }
    dispatch(editComplaintFromSummons(data));
  }
);

export const resubmitSummonImage = createAsyncThunk<
  any,
  {
    ecCaseDetail: any;
    ecomplaintId: number;
    fromScheduler: boolean;
    navigate: any
  },
  { state: RootState; rejectValue: string }
>("summon/resubmitSummonImage",
  async ({ ecCaseDetail, ecomplaintId, fromScheduler, navigate }, { rejectWithValue, getState, dispatch }) => {

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    dispatch(showHideLoadingGif(true));
    try {
      let { token } = getState().login;
      const response = await doFetch({
        url: `ecomplaintservices/resubmitSummonImage/${ecomplaintId}/${fromScheduler}`,
        token,
        type: "POST",
        body: ecCaseDetail,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        response.json().then((result: any) => {
          navigate("/EComplaintDashboard");
          return result;
        });
        navigate(("/EComplaintDashboard"))

      } else {
        dispatch(showHideLoadingGif(false));
      }
    } catch (error: any) {

    }
  }

)

const insertEcFormData = (
  ecStatus: string,
  status: string,
  ecIsCompalintSupervised: boolean,
  userType: string,
  ecomplaintReducer: any
) => {
  let ecFormStatus;
  if (
    LF_COMPLETE_STATUS === status &&
    (LF_REJECTED_STATUS === ecStatus ||
      LF_RETURNED_STATUS === ecStatus ||
      LF_STATUS_PENDING_SUPERVISOR_REVIEW === ecStatus)
  ) {
    if (ecIsCompalintSupervised && userType === ROLE_POLICE_OFFICER) {
      ecFormStatus = LF_STATUS_PENDING_SUPERVISOR_REVIEW;
    } else {
      ecFormStatus = LF_SEND_TO_JUDGE_STATUS;
    }
  } else if (LF_STATUS_PENDING_SUPERVISOR_RETURNED === status) {
    ecFormStatus = LF_RETURNED_STATUS;
  } else {
    ecFormStatus = status;
  }

  if ((ROLE_POLICE_OFFICER === userType || ROLE_AGENCY_ADMIN === userType) &&
    LF_FILED_STATUS === ecomplaintReducer.complaint.caseStatus
  ) {
    if (
      ecIsCompalintSupervised &&
      userType === ROLE_POLICE_OFFICER &&
      ecomplaintReducer.complaint.typeOfArrest === TYPE_OF_ARREST_SUMMON
    ) {
      if (ecStatus === LF_COMPLETE_STATUS || ecStatus === LF_RETURNED_STATUS) {
        ecFormStatus = LF_STATUS_PENDING_SUPERVISOR_REVIEW;
      }
    } else if (LF_STATUS_PENDING_SUPERVISOR_RETURNED !== status)
      ecFormStatus = LF_SEND_TO_JUDGE_STATUS;
  }

  let caseStatus;

  if (
    LF_REJECTED_STATUS === status &&
    LF_FILED_STATUS !== ecomplaintReducer.complaint.caseStatus
  ) {
    caseStatus = LF_ARRW_REJECTED_STATUS;
  } else if (
    LF_RETURNED_STATUS === status &&
    LF_SUMMON_RETURNED_STATUS !== ecomplaintReducer.complaint.caseStatus &&
    LF_FILED_STATUS !== ecomplaintReducer.complaint.caseStatus
  ) {
    caseStatus = LF_SUMMON_RETURNED_STATUS;
  } else if (
    (LF_STATUS_PENDING_SUPERVISOR_RETURNED === status ||
      LF_STATUS_PENDING_SUPERVISOR_REVIEW === status) &&
    LF_FILED_STATUS !== ecomplaintReducer.complaint.caseStatus
  ) {
    caseStatus = status;
  } else if (LF_COMPLETE_STATUS === status) {
    if (
      ecFormStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW &&
      LF_FILED_STATUS !== ecomplaintReducer.complaint.caseStatus
    ) {
      caseStatus = LF_STATUS_PENDING_SUPERVISOR_REVIEW;
    } else if (
      LF_SUMMON_REJECTED_STATUS === ecomplaintReducer.complaint.caseStatus ||
      (ecFormStatus === LF_SEND_TO_JUDGE_STATUS &&
        LF_FILED_STATUS !== ecomplaintReducer.complaint.caseStatus)
    ) {
      caseStatus = LF_SEND_TO_JUDGE_STATUS;
    }
  }

  const statusObj = {
    caseStatus: caseStatus,
    ecFormStatus: ecFormStatus,
  };

  return statusObj;
};

export const summonsSlice = createSlice({
  name: "summons",
  initialState,
  reducers: {
    updateElementValueSummonsReducer: updateElementValue,
    updateElementObjectValue: (state: SummonsDataState, action) => {
      updateElementObject(state, action, state.summonsDataObject);
    },
    updateSummonsCauseObject: (state: SummonsDataState, action) => {
      state.summonsDataObject = action.payload;
    },
    updateElementReducerValue: (state: SummonsDataState, action) => {
      updateElementObject(state, action, state);
    },
    updateElementObjectValueDefInfoObject: (state: SummonsDataState, action) => {
      updateElementObject(state, action, state.defInfoObject);
    },
    resetSummons: (state: SummonsDataState) => {
      // return {
      //   ...state,
      //   ...initialState,
      // };
      state.summonsDataObject = initialSummonState;
    },
    updateSummonState: (
      state: SummonsDataState,
      action: PayloadAction<string>
    ) => {
      state.summonState = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSummons.pending, (state) => {
        state.fetchSummonStatus = "loading";
      })
      .addCase(fetchSummons.fulfilled, (state, action) => {
        state.summonsDataObject = action.payload;
        state.fetchSummonStatus = "success";
      })
      .addCase(fetchSummons.rejected, (state, action) => {
        state.fetchSummonStatus = "error";
      })
      .addCase(fetchInitialSummons.pending, (state) => {
        state.fetchSummonStatus = "loading";
      })
      .addCase(fetchInitialSummons.fulfilled, (state, action) => {
        state.summonsDataObject = action.payload;
        state.fetchSummonStatus = "success";
      })
      .addCase(fetchInitialSummons.rejected, (state, action) => {
        state.fetchSummonStatus = "error";
      })
      .addCase(createSummon.pending, (state) => {
        state.status = "loading";
        state.error = "loading";
      })
      .addCase(createSummon.fulfilled, (state, action) => {
        state.status = "success";
        state.summonsDataObject = action.payload;
      })
      .addCase(createSummon.rejected, (state, action) => {
        state.status = "error";
        state.error = "error";
      })
      .addCase(saveSummons.pending, (state) => {
        state.saveStatus = "loading";
      })
      .addCase(saveSummons.fulfilled, (state, action) => {
        state.saveStatus = "success";
      })
      .addCase(saveSummons.rejected, (state, action) => {
        state.saveStatus = "error";
      })
      .addCase(signSummons.pending, (state) => {
        state.signStatus = "loading";
      })
      .addCase(signSummons.fulfilled, (state, action) => {
        state.signStatus = "success";
      })
      .addCase(signSummons.rejected, (state, action) => {
        state.signStatus = "error";
      });
  }
});
export const {
  updateElementValueSummonsReducer,
  updateElementObjectValue,
  updateSummonsCauseObject,
  resetSummons,
  updateElementReducerValue,
  updateSummonState,
  updateElementObjectValueDefInfoObject
} = summonsSlice.actions;
export const useSummonsReducer = () => useAppSelector((state) => state.summons);
export default summonsSlice.reducer;
