import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { updateElementObject } from "../../utils/updateElementObject";
import { resetSignature } from "./signPanelSlice";
import { updateCaseDetailObject, updateElementValueCaseDetailReducer } from "./caseDetailSlice";
import { showHideLoadingGif, updateDocGenerateError, updatePdfGenerationError } from "./commonSlice";
import { formatTextForCkEditor, setTemplateDataToFields } from "../../utils/common";
import { APPLICATION_AND_ORDER, SEARCH_WAR_ASSIGNED_TO_AG_STATUS, SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS, SEARCH_WAR_ASSIGNED_TO_LP_STATUS, SEARCH_WAR_ASSIGNED_TO_SA_STATUS, SEARCH_WAR_DENIED_STATUS, SEARCH_WAR_LOCKED_BY_JUDGE_STATUS, SEARCH_WAR_OFFICER_PENDING_STATUS, SEARCH_WAR_VOID_STATUS, COMPL_SEARCH_WARRANT_WITH_SLASH } from "../../constants/common";
import { updateElementValueInSearchWarrantListReducer } from "./searchWarrantListSlice";
import { getSWConcurrentObj } from "./commonFormSlice";

interface applicationAndOrderObj {
  searchWarrantId: number;

  applicationTitle: string;
  applicationInfo: string;
  orderTitle: string;
  txtPersonInfo: string;

  txtAgency: string;
  txtCaseNumber: string;
  txtAppears: string;
  searchWarrantType: string;
  templateId: number;
  approvalUserType: string;
  approvalSAId: number;
  badgeNo: string;
  txtPoliceOfficerInfo: string;
  hiddenComplaintSignature: string;
  hiddenJudgeSignature: string;
  hiddenUserSignature: string,
  // lblHeadingStateSW: string;
  lblHeadingCountySW: string;
  lblHeadingInTheCircuitSW: string;

  creatorId: number;
  creatorUserType: string;
  onBehalf: boolean;

  remark: string;

  // agencyID: number;
  agencyName: string;
  // agencyCode: string;
  countyID: number;
  countyName: string;
  isSARequired: boolean;
  isOnBehalf : boolean;

  formStatus: string;
}

interface applicationAndOrderState {
  applicationAndOrderObject: applicationAndOrderObj;

  swApprovalUserTypes: string[];
  warrantTemplateList: string[];
  aaoError?: string;
  status: LoadingType;
  aaoSuccessMsg : string;
}

const initialState = {
  swApprovalUserTypes: [],
  warrantTemplateList: [],
  aaoError: undefined,
  status: "idle",
  aaoSuccessMsg : "",

  //Application And Order Reducer Form Data
  applicationAndOrderObject: {
    searchWarrantId: 0,

    applicationTitle: "",
    applicationInfo: "",
    orderTitle: "",
    txtPersonInfo: "",

    txtAgency: "",
    txtCaseNumber: "",
    txtAppears: "",
    searchWarrantType: "",
    templateId: 0,
    approvalUserType: "",
    approvalSAId: 0,
    badgeNo: "",
    txtPoliceOfficerInfo: "",
    hiddenComplaintSignature: "",
    hiddenJudgeSignature: "",
    hiddenUserSignature: "",
    // lblHeadingStateSW: "",
    lblHeadingCountySW: "",
    lblHeadingInTheCircuitSW: "",

    creatorId: 0,
    creatorUserType: "",
    onBehalf: false,

    remark: "",

    // agencyID: 0,
    agencyName: "",
    // agencyCode: "",
    countyID: 0,
    countyName: "",
    isSARequired: false,
    isOnBehalf : false,

    formStatus: "",
  },
} as applicationAndOrderState;

export const createApplicationAndOrder = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "applicationAndOrder/createApplicationAndOrder",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, navigate } = data;
    let { token } = getState().login;

    dispatch(resetApplicationAndOrderData());
    dispatch(resetSignature())

    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url:
          "searchwarrantservices/createApplicationAndOrder/" +
          APPLICATION_AND_ORDER +
          "/" +
          searchWarrantId,
        token,
        type: "POST",
      });
      if (response.ok) {
        const result = await response.json();
        dispatch(showHideLoadingGif(false));
        dispatch(
          updateElementValueCaseDetailReducer({
            elementName: "searchWarrantList",
            value: result.searchWarrantList,
          }));
        navigate("/ApplicationAndOrder");
        return result;
      }

      const error = await response.text();
      return rejectWithValue(error || "Error while creating SW");
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while creating SW");
    }
  }
);

export const getTemplateDataForApplicationOrder = createAsyncThunk<
  any,
  {
    countyId?: number;
    warrantForm?: string;
    templateName?: string;
  },
  {
    state: RootState;
  }
>(
  "applicationAndOrder/getTemplateDataForApplicationOrder",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { countyId, warrantForm, templateName } = data;

    let { token } = getState().login;
    let queryParam = `jwtToken=Bearer ${token}`;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url:
          "templateservices/warranttemplatetypes/" +
          countyId +
          "/" +
          warrantForm +
          "/" +
          templateName +
          `?${queryParam}`,
          concurrentObject : concurrObj,
          concurrentIdentifier: "SW",
          dispatch: dispatch
      });
      dispatch(showHideLoadingGif(false));
      return await reponse.json();
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);

export const submitApplicationAndOrder = createAsyncThunk<
  any,
  {
    formId: number;
    status: string;
    navigate: any;
    navigateTo: string;
  },
  { state: RootState; rejectValue: string }
>(
  "applicationAndOrder/submitApplicationAndOrder",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { formId, status, navigate, navigateTo } = data;

    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);

    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url:
          "searchwarrantservices/submitapplicationandorder/" +
          formId +
          "/" +
          status,
        token,
        type: "PUT",
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result: any) => {
          if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
            dispatch(
              updateDocGenerateError({
                error: true,
                errorMsg: result.errorInfo.validationErrors,
              })
            );
            return;
          } else {
            let message = "";
            if (status === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
              message = "Form submitted to SA for screening.";
            } else if (status === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
              message = "Form submitted to LP for screening.";
            } else if (status === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
              message = "Form submitted to AG for screening.";
            } else if (status === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) {
              message = "Form submitted to Judge for approval.";
            }

            result.responseMessage = message;

            let caseDetail = result;
            dispatch(updateCaseDetailObject(caseDetail));
          }
        });
        navigate(navigateTo);
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(
          APPLICATION_AND_ORDER + " form data submitting process is failed."
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message ||
          APPLICATION_AND_ORDER + " form data submitting process is failed."
      );
    }
  }
);

export const saveApplicationAndOrder = createAsyncThunk<
  any,
  {
    formId: number;
    status: string;
    navigate: any;
    navigateTo : string
  },
  { state: RootState; rejectValue: string }
>(
  "applicationAndOrder/saveApplicationAndOrder",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { formId, status, navigate, navigateTo} = data;

    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url:
          "searchwarrantservices/saveapplicationandorder/" +
          formId +
          "/" +
          status,
        token,
        type: "PUT",
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result: any) => {
          if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
            dispatch(
              updateDocGenerateError({
                error: true,
                errorMsg: result.errorInfo.validationErrors,
              })
            );
            return;
          } else {
            let message =
              "Application of an Order saved successfully for " +
              getState().caseDetail.caseNumber +
              ".";

            result.responseMessage = message;

            let caseDetail = result;
            dispatch(updateCaseDetailObject(caseDetail));
          }
        });
        navigate(navigateTo);
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error ||
            APPLICATION_AND_ORDER + " form data saving process is failed."
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message ||
          APPLICATION_AND_ORDER + " form data saving process is failed."
      );
    }
  }
);


export const returnApplicationAndOrder = createAsyncThunk<
  any,
  { searchWarrantId: number; proxy?: any; navigate: any, navigateTo: string },
  { state: RootState; rejectValue: string }
>("applicationAndOrder/returnApplicationAndOrder", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, proxy, navigate, navigateTo } = data;
  let { token } = getState().login;
  let caseNumber = getState().caseDetail.caseNumber;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  try {
    dispatch(showHideLoadingGif(true));
    const response = await doFetch({
      url: "searchwarrantservices/returnapplicationandorder/" + searchWarrantId,
      type: "PUT",
      token,
      body: proxy,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
      dispatch: dispatch
    });

    dispatch(showHideLoadingGif(false));
    if (response.ok) {
      let res = response.json().then((result) => {
        let message = "";
        if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
          message = "Return to SA for " + caseNumber + ".";
        } else if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
          message = "Return to LP for " + caseNumber + ".";
        } else if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
          message = "Return to AG for " + caseNumber + ".";
        } else if (proxy.formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) {
          message = "RETURN TO OFFICER for " + caseNumber + ".";
        }

        result.responseMessage = message;

        let caseDetail = result;
        dispatch(updateCaseDetailObject(caseDetail));
      });
      navigate(navigateTo);
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        APPLICATION_AND_ORDER +
          " form data returning process is failed for " +
          caseNumber +
          "."
      );
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(
      error.message ||
        APPLICATION_AND_ORDER +
          " form data returning process is failed for " +
          caseNumber +
          "."
    );
  }
}
);


export const approveApplicationAndOrder = createAsyncThunk<
  any,
  { searchWarrantId: number; proxy?: object; navigate: any, navigateTo: string },
  { state: RootState; rejectValue: string }
>("applicationAndOrder/approveApplicationAndOrder", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, proxy, navigate, navigateTo } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);

  try {
    dispatch(showHideLoadingGif(true));
    const response = await doFetch({
      url:
        "searchwarrantservices/approveapplicationandorder/" + searchWarrantId,
      type: "PUT",
      token,
      body: proxy,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
      dispatch: dispatch
    });

    dispatch(showHideLoadingGif(false));
    if (response.ok) {
      let res = response.json().then((result) => {
        if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: result.errorInfo.validationErrors,
            })
          );
          return;
        } else {
          let message =
            "Petition is approved for case number " +
            getState().caseDetail.caseNumber +
            ".";

          let caseDetail = result;
          dispatch(updateCaseDetailObject(caseDetail));
          dispatch(
            updateElementValueCaseDetailReducer({
              elementName: "responseMessage",
              value: message,
            })
          );
          navigate(navigateTo);
        }
      });
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while updating status of the form.");
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(
      error.message || "Error while updating status of the form."
    );
  }
}
);

export const signApplicationAndOrder = createAsyncThunk<
  any,
  { searchWarrantId: number; applicationObject: object; navigate: any, navigateTo: string },
  { state: RootState; rejectValue: string }
>("applicationAndOrder/signApplicationAndOrder", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId,  applicationObject, navigate, navigateTo } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  try {
    dispatch(showHideLoadingGif(true));
    const response = await doFetch({
      url: "searchwarrantservices/signapplicationandorder/" + searchWarrantId,
      type: "PUT",
      token,
      body: applicationObject,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
      dispatch: dispatch
    });
    dispatch(showHideLoadingGif(false));
    if (response.ok) {
      let res = response.json().then((result) => {
        if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: result.errorInfo.validationErrors,
            })
          );
          return;
        }
        else if (result.errorInfo && result.errorInfo.error) {
          dispatch(
            updateElementValueCaseDetailReducer({
              elementName: "responseErrorMessage",
              value: result.errorInfo.errorMessage,
            })

          );
          dispatch(updatePdfGenerationError({
            error: result.errorInfo.error,
            errorMsg: result.errorInfo.errorMessage,
          }));
          navigate(navigateTo);
        }else {
          let message =
            "Order is issued for " + getState().caseDetail.caseNumber + ".";

          result.responseMessage = message;

          let caseDetail = result;
          dispatch(updateCaseDetailObject(caseDetail));
        }
        
      });
      navigate(navigateTo);
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        APPLICATION_AND_ORDER + " form signing process is failed."
      );
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(
      error.message ||
        APPLICATION_AND_ORDER + " form signing process is failed."
    );
  }
}
);

const applicationAndOrderSlice = createSlice({
  name: "applicationAndOrder",
  initialState,
  reducers: {
    updateElementValueApplicationAndOrderReducer: updateElementValue,
    updateElementObjectValue: (state: applicationAndOrderState, action) => {
      updateElementObject(state, action, state.applicationAndOrderObject);
    },
    updateApplicationAndOrderObject: (
      state: applicationAndOrderState,
      action
    ) => {
      state.applicationAndOrderObject = action.payload;
    },
    resetApplicationAndOrderData: (state: applicationAndOrderState) => {
      return {
        ...state,
        ...initialState,
      };
    },
      updateSignElementApplicationAndOrder: (state: applicationAndOrderState, action) => {
      updateElementObject(state, action, state.applicationAndOrderObject);
    },
  },
  extraReducers(builder) {
    builder
      //create
      .addCase(createApplicationAndOrder.pending, (state) => {
        state.status = "loading";
        state.aaoError = undefined;
      })
      .addCase(createApplicationAndOrder.fulfilled, (state, action) => {
        state.status = "success"
        state.applicationAndOrderObject = action.payload;
      })
      .addCase(createApplicationAndOrder.rejected, (state, action) => {
        state.status = "error";
        state.aaoError = action.payload;
      })
      //getTemplateData
      .addCase(getTemplateDataForApplicationOrder.pending, (state) => {
        state.status = "loading";
        state.aaoError = undefined;
      })
      .addCase(
        getTemplateDataForApplicationOrder.fulfilled,
        (state, action) => {
          state.status = "success";
          state.applicationAndOrderObject.applicationTitle =
          formatTextForCkEditor(setTemplateDataToFields(action.payload.applicationAndOrder.applicationTitle, state.applicationAndOrderObject.agencyName, state.applicationAndOrderObject.badgeNo, state.applicationAndOrderObject.txtAppears));
          state.applicationAndOrderObject.applicationInfo =
          formatTextForCkEditor(setTemplateDataToFields(action.payload.applicationAndOrder.applicationInfo, state.applicationAndOrderObject.agencyName, state.applicationAndOrderObject.badgeNo, state.applicationAndOrderObject.txtAppears));
          state.applicationAndOrderObject.orderTitle =
          formatTextForCkEditor(setTemplateDataToFields(action.payload.applicationAndOrder.orderTitle, state.applicationAndOrderObject.agencyName, state.applicationAndOrderObject.badgeNo, state.applicationAndOrderObject.txtAppears));
          state.applicationAndOrderObject.txtPersonInfo =
          formatTextForCkEditor(setTemplateDataToFields(action.payload.applicationAndOrder.txtPersonInfo, state.applicationAndOrderObject.agencyName, state.applicationAndOrderObject.badgeNo, state.applicationAndOrderObject.txtAppears));
        }
      )
      .addCase(getTemplateDataForApplicationOrder.rejected, (state, action) => {
        state.status = "error";
        state.aaoError = undefined;
      })

      //submit
      .addCase(submitApplicationAndOrder.pending, (state) => {
        state.status = "loading";
        state.aaoError = undefined;
      })
      .addCase(submitApplicationAndOrder.fulfilled, (state, action) => {
        state.status = "success";
      //  state.applicationAndOrderObject = action.payload;
      })
      .addCase(submitApplicationAndOrder.rejected, (state, action) => {
        state.status = "error";
        state.aaoError = action.payload;
      })

      //save
      .addCase(saveApplicationAndOrder.pending, (state) => {
        state.status = "loading";
        state.aaoError = undefined;
      })
      .addCase(saveApplicationAndOrder.fulfilled, (state, action) => {
        state.status = "success";
      //  state.applicationAndOrderObject = action.payload;
      })
      .addCase(saveApplicationAndOrder.rejected, (state, action) => {
        state.status = "error";
        state.aaoError = action.payload;
      })

      // return
      .addCase(returnApplicationAndOrder.pending, (state) => {
        state.status = "loading";
        state.aaoError = undefined;
      })
      .addCase(returnApplicationAndOrder.fulfilled, (state, action) => {
        state.status = "success";
        state.aaoError = action.payload;
      })
      .addCase(returnApplicationAndOrder.rejected, (state, action) => {
        state.status = "error";
        state.aaoError = action.payload;
      })
      
      //approve
      .addCase(approveApplicationAndOrder.pending, (state) => {
        state.status = "loading";
        state.aaoError = undefined;
      })
      .addCase(approveApplicationAndOrder.fulfilled, (state, action) => {
        state.status = "success";
        state.aaoSuccessMsg = action.payload;
      })
      .addCase(approveApplicationAndOrder.rejected, (state, action) => {
        state.status = "error";
        state.aaoError = action.payload;
      })

      //sign
      .addCase(signApplicationAndOrder.pending, (state) => {
        state.status = "loading";
        state.aaoError = undefined;
      })
      .addCase(signApplicationAndOrder.fulfilled, (state, action) => {
        state.status = "success";
        state.aaoError = action.payload;
      })
      .addCase(signApplicationAndOrder.rejected, (state, action) => {
        state.status = "error";
        state.aaoError = action.payload;
      });
  },
});

export const {
  updateElementValueApplicationAndOrderReducer,
  resetApplicationAndOrderData,
  updateElementObjectValue,
  updateApplicationAndOrderObject,
  updateSignElementApplicationAndOrder,
} = applicationAndOrderSlice.actions;

export const useApplicationAndOrderReducer = () =>
  useAppSelector((state) => state.applicationAndOrder);

export default applicationAndOrderSlice.reducer;
