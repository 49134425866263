import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RiDashboardFill, RiMenuFill, RiMenu3Fill } from "react-icons/ri";
import Dashboard from "../dashboard/Dashboard";
import {
  FaBook,
  FaBuilding,
  FaCamera,
  FaCameraRetro,
  FaDatabase,
  FaEye,
  FaFile,
  FaList,
  FaTicketAlt,
  FaUniversity,
  FaUser,
} from "react-icons/fa";
import {
  CAN_ACCESS_APPLICATION,
  EC_MSG_OFFICER_INFORMATION_NOT_MATCH,
  RELEASE_ON_RECOG,
  ROLE_AGENCY_ADMIN,
  ROLE_POLICE_OFFICER,
  USER_TYPES,
  USE_OFFICERS_FROM_IUCS,
} from "../../constants/common";
import useToken from "../../hooks/useToken";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { updateElementValueDashboardReducer } from "../../redux/slice/dashboardSlice";
import { getAgencyAdminNameByAgency } from "../../redux/slice/agencyListSlice";
import { getOfficerListForLoggedInUser, getUpdatesEcOfficersFromIucs } from "../../redux/slice/complaintSlice";
import OptionPopup from "./OptionsPopup";
import { fetchEcitationAdminSession, fetchEcitationSession } from "../../utils/common";
import { Toast } from "react-bootstrap";
import { handleUnloadMessage } from "./CommonUtil";
import Cookies from "js-cookie";
import CryptoJS from 'crypto-js';


type SideMenuProps = {
  inactive: boolean;
  setInactive: (inactive: boolean) => void;
};

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const { inactive, setInactive } = props;
  const isTokenValueAvailable = useToken();
  const {
    userType,
    county,
    countyId,
    ecUserAgency,
    username,
    userProfileId,
    firstName,
    lastName,
    badgeNumber,
    longFormEnable,
    summonsToAppear,
  } = useLoginReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showIUCSwarning, setShowIUCSwarning] = React.useState(false);
  const [content, setContent] = React.useState<React.ReactNode>();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const onCardClick = async (cardName: string, isActive: boolean) => {
    if (cardName === "Long Form Complaints") {
      if (userType === ROLE_POLICE_OFFICER || userType === ROLE_AGENCY_ADMIN) {
        let agencyAdminList = await dispatch(
          getAgencyAdminNameByAgency({ agencyId: ecUserAgency.agencyId })
        );
        let officerList = await dispatch(getOfficerListForLoggedInUser());
        let policeOfficerObject = officerList
          ? officerList.payload["EC POLICE OFFICER OBJECT"]
          : null;
        if (agencyAdminList.payload) {
          if (
            policeOfficerObject !== null &&
            policeOfficerObject.syncStatus === USE_OFFICERS_FROM_IUCS &&
            (policeOfficerObject.officerId === null ||
              policeOfficerObject.officerId === 0)
          ) {
            officerList = await dispatch(getUpdatesEcOfficersFromIucs());
            policeOfficerObject = officerList ? officerList.payload["EC POLICE OFFICER OBJECT"] : null;
            if ((policeOfficerObject !== null && policeOfficerObject.syncStatus === USE_OFFICERS_FROM_IUCS) &&
              (policeOfficerObject.officerId === null || policeOfficerObject.officerId === 0)) {
              let index = 1;
              setShowIUCSwarning(true);
              setContent(
                <>
                  <div className="text-start">
                    {EC_MSG_OFFICER_INFORMATION_NOT_MATCH}
                  </div>
                  <br></br>
                  <div>
                    {agencyAdminList.payload.map((data: any) => (
                      <div className="text-start">
                        {index++ + ". " + data.firstName + " " + data.lastName}
                        <br></br>
                      </div>
                    ))}
                  </div>
                </>
              );
            }
            else {
              onContinueLFclick();
            }
          } else {
            onContinueLFclick();
          }
        }
      } else {
        onContinueLFclick();
      }
    }
    // PR-11312 START
    else if (cardName === RELEASE_ON_RECOG) {
      navigate("/SearchEComplaint");
      dispatch(updateElementValueDashboardReducer({ elementName: "cardName", value: RELEASE_ON_RECOG }));
      dispatch(updateElementValueDashboardReducer({ elementName: "isCardActive", value: true }));
    }
    // PR-11312 END
    else {
      if (location.pathname === "/") {
        dispatch(
          updateElementValueDashboardReducer({
            elementName: "previousLink",
            value: "dashboard",
          })
        );
      } else {
        dispatch(
          updateElementValueDashboardReducer({
            elementName: "previousLink",
            value: location.pathname,
          })
        );
      }
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "cardName",
          value: cardName,
        })
      );
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "isCardActive",
          value: isActive,
        })
      );
    }
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "searchWarrantIds",
        value: [],
      })
    );
  };
  // const handleEcitationClick = async () => {
  //   const url = "user/login";
  //   const method = "POST";
  //   const body = {
  //     userName: username,
  //     securityKey: process.env.REACT_APP_ECITATION_SECURITY_KEY,
  //     userProfileId: userProfileId,
  //     firstName: firstName,
  //     lastName: lastName,
  //     badgeNumber: badgeNumber,
  //   };
  //   const response = await fetchEcitationSession(url, method, body);
  //   const redirectUrl = response.data.redirectUrl;
  //   window.location.href = redirectUrl;
  // };

  class AesUtil {
    private keySize: number;
    private iterationCount: number;

    constructor(keySize: number, iterationCount: number) {
        this.keySize = keySize / 32;
        this.iterationCount = iterationCount;
    }

    private generateKey(salt: string, passPhrase: string | CryptoJS.lib.WordArray) {
        return CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
            keySize: this.keySize,
            iterations: this.iterationCount
        });
    }

    public encrypt(salt: string, iv: string, passPhrase: any, plainText: string | CryptoJS.lib.WordArray) {
        const key = this.generateKey(salt, passPhrase);
        return CryptoJS.AES.encrypt(plainText, key, { iv: CryptoJS.enc.Hex.parse(iv) });
    }

    public decrypt(salt: string, iv: string, passPhrase: any, ciphertext: string | CryptoJS.lib.CipherParams) {
        const key = this.generateKey(salt, passPhrase);
        const decrypted = CryptoJS.AES.decrypt(ciphertext, key, { iv: CryptoJS.enc.Hex.parse(iv) });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}
  // const handleEcitationClick = async () => {
    
    
  //   let icsToken = Cookies.get("token");
  //   window.removeEventListener("beforeunload", handleUnloadMessage);
  //   const url = "user/login";
  //   const method = "POST";
  //   const body = {
  //     userName: username.trim(),
  //     securityKey: process.env.REACT_APP_ECITATION_SECURITY_KEY,
  //     userProfileId: userProfileId,
  //     firstName: firstName.trim(),
  //     lastName: lastName.trim(),
  //     badgeNumber: badgeNumber,
  //     agencyCode : ecUserAgency ? ecUserAgency?.agencyCode : null,
  //     agencyOri: ecUserAgency ? ecUserAgency?.agencyOri : null,
  //     icstoken: icsToken ? icsToken : null
  //   };

  //   try {
  //     const response = await fetchEcitationSession(url, method, body);
      

  //     if (response && response.data && response.data.redirectUrl) {
  //       const redirectUrl = response.data.redirectUrl;
  //       window.location.href = redirectUrl;
  //     } else {
  //       // Handle the case where response or its properties are undefined
  //       showToastMessage("Invalid response from the server");
  //     }
  //   } catch (error: any) {
  //     // Pass the error message to showToastMessage
  //     showToastMessage(
  //       error.message || "An error occurred while fetching eCitation data."
  //     );
  //   }
  //   finally {
  //     // Re-add the event listener after the click event handling is done
  //     window.addEventListener("beforeunload", handleUnloadMessage);
  //   }
  // };
  const handleEcitationClick = async () => {
    let icsToken = Cookies.get("token");
    window.removeEventListener("beforeunload", handleUnloadMessage);
    const url = 'user/login';
    const method = 'POST';
    
    const aesUtil = new AesUtil(128, 1000);
    const iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
  
    const body = {
          userName: username.trim(),
          securityKey: process.env.REACT_APP_ECITATION_SECURITY_KEY,
          userProfileId: userProfileId,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          badgeNumber: badgeNumber,
          agencyCode : ecUserAgency ? ecUserAgency?.agencyCode : null,
          agencyOri: ecUserAgency ? ecUserAgency?.agencyOri : null,
          icstoken: icsToken ? icsToken : null
        };
  
    try {
        const ciphertext = aesUtil.encrypt(salt, iv, process.env.REACT_APP_ECITATION_PASSPHRASE, JSON.stringify(body));
        
        const encryptedBody = {
            eci: iv,
            ecs: salt,
            ecRequest: ciphertext.toString()
        };
  
        let response = await fetchEcitationSession(url, method, encryptedBody);
  
        // if (response && response?.code === 0 && response?.data && response?.data?.redirectUrl) {
        //     const redirectUrl = response.data.redirectUrl;
        //     window.location.href = redirectUrl;
        // } else {
        //     showToastMessage('There is an issue while accessing the eCitation. Please try again after sometime.');
        // }
        if (response && response?.eci && response?.ecs && response?.ecResponse) {
          const { eci, ecs, ecResponse } = response;
          const decryptedData = aesUtil.decrypt(ecs, eci, process.env.REACT_APP_ECITATION_PASSPHRASE, ecResponse);
          response = JSON.parse(decryptedData);
      }
  
      if (response?.code === 0 && response?.data?.redirectUrl) {
          const redirectUrl = response.data.redirectUrl;
          window.location.href = redirectUrl;
      } else {
        showToastMessage("Invalid response from the server");
      }
    } catch (error: any) {
      showToastMessage(
              error.message || "An error occurred while fetching eCitation data."
            );
    } finally {
        window.addEventListener("beforeunload", handleUnloadMessage);
    }
  };
  // const handleEcitationAdminClick = async () => {
  //   let icsToken = Cookies.get("token");
  //   window.removeEventListener("beforeunload", handleUnloadMessage);
  //   const url = 'user/login';
  //   const method = 'POST';
    
  //   const body = {
  //     userName: username,
  //     securityKey: process.env.REACT_APP_ECITATION_ADMIN_SECURITY_KEY,
  //     userProfileId: userProfileId,
  //     firstName: firstName,
  //     lastName: lastName,
  //     badgeNumber: badgeNumber,
  //     agencyCode : ecUserAgency ? ecUserAgency?.agencyCode : null,
  //     agencyOri: ecUserAgency ? ecUserAgency?.agencyOri : null,
  //     icstoken: icsToken ? icsToken : null
  //   };
  //   try {
  //     const response = await fetchEcitationAdminSession(url, method, body);

  //     if (response && response?.code === 0 && response?.data && response?.data?.redirectUrl) {
  //       const redirectUrl = response.data.redirectUrl;
  //       window.location.href = redirectUrl;
  //     } else {
  //       // Handle the case where response or its properties are undefined
  //       showToastMessage('There is an issue while accessing the eCitation. Please try again after sometime.');
  //     }
  //   } catch (error: any) {
  //     // Pass the error message to showToastMessage
  //     showToastMessage(error.message || 'There is an issue while accessing the eCitation. Please try again after sometime.');
  //   }
  //   // finally {
  //   //   // Re-add the event listener after the click event handling is done
  //   //    window.addEventListener("beforeunload", handleUnloadMessage);
  //   // }
  // };
  const handleEcitationAdminClick = async () => {
    let icsToken = Cookies.get("token");
    window.removeEventListener("beforeunload", handleUnloadMessage);
    const url = 'user/login';
    const method = 'POST';
    
    const aesUtil = new AesUtil(128, 1000);
    const iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

    const body = {
          userName: username,
          securityKey: process.env.REACT_APP_ECITATION_ADMIN_SECURITY_KEY,
          userProfileId: userProfileId,
          firstName: firstName,
          lastName: lastName,
          badgeNumber: badgeNumber,
          agencyCode : ecUserAgency ? ecUserAgency?.agencyCode : null,
          agencyOri: ecUserAgency ? ecUserAgency?.agencyOri : null,
          icstoken: icsToken ? icsToken : null
        };

    try {
        const ciphertext = aesUtil.encrypt(salt, iv, process.env.REACT_APP_ECITATION_PASSPHRASE, JSON.stringify(body));
        
        const encryptedBody = {
            eci: iv,
            ecs: salt,
            ecRequest: ciphertext.toString()
        };

        let response = await fetchEcitationAdminSession(url, method, encryptedBody);

        if (response && response?.eci && response?.ecs && response?.ecResponse) {
            const { eci, ecs, ecResponse } = response;
            const decryptedData = aesUtil.decrypt(ecs, eci, process.env.REACT_APP_ECITATION_PASSPHRASE, ecResponse);
            response = JSON.parse(decryptedData);
        }

        if (response?.code === 0 && response?.data?.redirectUrl) {
            const redirectUrl = response.data.redirectUrl;
            window.location.href = redirectUrl;
        } else {
          showToastMessage('There is an issue while accessing the eCitation. Please try again after sometime.');
        }
    } catch (error: any) {
        showToastMessage(error.message || 'There is an issue while accessing the eCitation. Please try again after sometime.');
    } finally {
        window.addEventListener("beforeunload", handleUnloadMessage);
    }
};
  const showToastMessage = (message: any) => {
    setToastMessage(message);
    setShowToast(true);
  };

  const onContinueLFclick = () => {
    setShowIUCSwarning(false);
    navigate("/SearchEComplaint");
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "cardName",
        value: "Long Form Complaints",
      })
    );
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: true,
      })
    );
  };
  const hasEcitations = () => {
    if (
      USER_TYPES["POLICE OFFICER"] === userType ||
      USER_TYPES["AGENCY ADMIN"] === userType
    ) {
      if (ecUserAgency.ecitationKey) {
        return true;
      }
    } else if (USER_TYPES["COUNTY ADMIN"] === userType && county === "DUPAGE") {
      return true;
    } 
    // else if (USER_TYPES["ADMINISTRATOR"] === userType) {
    //   return true;
    // }

    return false;
  };
  const hasEcitationJobCardOnly = () => {
    if (USER_TYPES["ADMINISTRATOR"] === userType) {
        return true
    } else{
      return false
    
    } 
  };
  const menuItems = [
    {
      name: "Dashboard",
      to: "/",
      iconClassName: <RiDashboardFill />,
      components: <Dashboard />,
      onCardClick: () => onCardClick("Dashboard", false),
    },
    {
      name: "User Maintenance",
      to: "/UserList",
      iconClassName: <FaUser />,
      token: CAN_ACCESS_APPLICATION.USER_MAINTEN,
      onCardClick: () => onCardClick("User Maintenance", true),
    },
    {
      name: "County Maintenance",
      to:
        userType === USER_TYPES["COUNTY ADMIN"]
          ? "/ViewCounty/" + countyId
          : "/CountyList",
      iconClassName: <FaUniversity />,
      token: CAN_ACCESS_APPLICATION.COUNTY_MAINTEN,
      onCardClick: () => onCardClick("County Maintenance", true),
    },
    {
      name: "Agency Maintenance",
      to: "/AgencyList",
      iconClassName: <FaBuilding />,
      token: [
        CAN_ACCESS_APPLICATION.AGENCY_MAINTEN,
        CAN_ACCESS_APPLICATION.AGENCY_LIST,
      ],
      onCardClick: () => onCardClick("Agency Maintenance", true),
    },
    {
      name: "Long Form Complaints",
      to: window.location.pathname,
      iconClassName: <FaFile />,
      hide: !longFormEnable,
      token: CAN_ACCESS_APPLICATION.ECOMPLAINT_LIST,
      onCardClick: () => onCardClick("Long Form Complaints", true),
    },
    {
      name: RELEASE_ON_RECOG,
      to: window.location.pathname,
      iconClassName: <FaFile />,
      hide: !longFormEnable,
      token: CAN_ACCESS_APPLICATION.RELEASE_ON_RECOG,
      onCardClick: () => onCardClick(RELEASE_ON_RECOG, true),
    },
    {
      name: summonsToAppear ? "Warrants/Summons List" : "Warrant List",
      to: summonsToAppear ? "/WarrantsSummonsList" : "/WarrantList",
      iconClassName: <FaList />,
      token: CAN_ACCESS_APPLICATION.WARRANT_LIST,
      onCardClick: () => onCardClick(summonsToAppear ? "Warrants/Summons List" : "Warrant List", true),
    },
    {
      name: "Search Warrants",
      to: "/WarrantList",
      iconClassName: <FaList />,
      token: CAN_ACCESS_APPLICATION.SEARCH_WARRANTS,
      onCardClick: () => onCardClick("Search Warrants", true),
    },
    {
      name: "Template List",
      to: "/TemplateList",
      iconClassName: <FaBook />,
      token: CAN_ACCESS_APPLICATION.TEMPLATE_MAINTEN,
      onCardClick: () => onCardClick("Template List", true),
    },
    {
      name: "Sync IUCS Data",
      to: "/SyncIucsData",
      iconClassName: <FaDatabase />,
      token: CAN_ACCESS_APPLICATION.SYNC_IUCS_DATA,
      onCardClick: () => onCardClick("Sync IUCS Data", true),
    },
    {
      name: "Active User Sessions",
      to: "/ActiveUserSessionsList",
      iconClassName: <FaEye />,
      token: CAN_ACCESS_APPLICATION.ACTIVE_USER_SESSIONS,
      onCardClick: () => onCardClick("Active User Sessions", true),
    },
    {
      name: "Test Webcam",
      to: "/TestWebcam",
      iconClassName: <FaCamera />,
      token: CAN_ACCESS_APPLICATION.TEST_WEBCAM,
      onCardClick: () => onCardClick("Test Webcam", true),
    },
    {
      name: "Remote Hearing",
      to: "/RemoteHearing",
      iconClassName: <FaCameraRetro />,
      token: CAN_ACCESS_APPLICATION.REMOTE_HEARING,
      onCardClick: () => onCardClick("Remote Hearing", true),
    },
    {
      name: "Statutes",
      to: "/StatutesList",
      iconClassName: <FaBook />,
      token: CAN_ACCESS_APPLICATION.EC_VIOLATION_LIST,
      onCardClick: () => onCardClick("Statutes", true),
    }
  ];

  useEffect(() => {
    let menuItems = document.querySelectorAll(".menu-item");
    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        localStorage.clear();
        const next = el.nextElementSibling;
        menuItems.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);

  return (
    <>
      <div className={`side-menu ${inactive ? "inactive" : ""}`}>
        <div className="top-section">
          {/* <div className="logo">
          <img src={Logo} />
        </div>  */}
          <div className="logo_name">:.ICS.:</div>
          <div
            onClick={() => setInactive(!inactive)}
            className="toggle-menu-btn"
          >
            {inactive ? <RiMenuFill /> : <RiMenu3Fill />}
          </div>
        </div>

        <div className="divider mt-2"></div>

        <div className="main-menu mt-2">
          <ul>
            {menuItems.map(
              (menuItem) =>
                !menuItem.hide &&
                isTokenValueAvailable(menuItem.token) && (
                  <li onClick={menuItem.onCardClick} key={menuItem.name}>
                    <div className="tooltip-custom">
                      <div className="tooltip-inner-custom mt-2">
                        {menuItem.name}
                      </div>
                    </div>
                    <Link to={menuItem.to} className={`menu-item`}>
                      <div className="menu-icon">{menuItem.iconClassName}</div>
                      <span>{menuItem.name}</span>
                    </Link>
                  </li>
                )
            )}
            {hasEcitations() && (
              <li onClick={() => handleEcitationClick()} key="eCitation">
                <div className="tooltip-custom">
                  <div className="tooltip-inner-custom mt-2">eCitation</div>
                </div>
                <Link to="#" className={`menu-item`}>
                  <div className="menu-icon">
                    {" "}
                    <FaTicketAlt />
                  </div>
                  <span>eCitation</span>
                </Link>
              </li>
            )}
            {hasEcitationJobCardOnly() && (
              <li  onClick={()=>handleEcitationAdminClick()} key="eCitation Admin">
                <div className="tooltip-custom">
                  <div className="tooltip-inner-custom mt-2">eCitation Admin</div>
                </div>
                <Link to="#" className={`menu-item`}>
                  <div className="menu-icon">
                    {" "}
                    <FaTicketAlt />
                  </div>
                  <span>eCitation Admin</span>
                </Link>
              </li>
            )}
          </ul>
        </div>

        {/* <div className="side-menu-footer">
        <RiLogoutBoxLine />
        <div className="user-info">
          Logout
        </div>
      </div> */}
      </div>
      <OptionPopup
        show={showIUCSwarning}
        header={"Warning"}
        children={content}
        classStyle={""}
        questionMarkVisible={true}
        buttons={[
          {
            name: "OK",
            variant: "primary",
            onClick: () => onContinueLFclick(),
          },
          {
            name: "Cancel",
            variant: "danger",
            onClick: () => setShowIUCSwarning(false),
          },
        ]}
      />
      <Toast
        autohide
        delay={2000}
        show={showToast}
        onClose={() => setShowToast(false)}
        style={{
          position: "fixed",
          top: "0", // Adjust the top position to place it at the very top
          right: "0",
          zIndex: "9999", // Ensure it's on top of other elements
        }}
      >
        <Toast.Header className="bg-danger text-white">
          <strong className="mr-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </>
  );
};

export default SideMenu;
