import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";

interface SignPanelState {
  sign: string;
  isValidStatus: LoadingType;
  isValidSignature: boolean;
  fetchSignThresholdStatus: LoadingType;
  signThreshold: number;
  sessionId: string;
  isRemoteCaptureSign: boolean;
}

const initialState = {
  sign: "",
  isValidStatus: "idle",
  isValidSignature: false,
  fetchSignThresholdStatus: "idle",
  signThreshold: 200,
  sessionId: "",
  isRemoteCaptureSign: false,
} as SignPanelState;

export const signPanelSlice = createSlice({
  name: "signPanel",
  initialState,
  reducers: {
    updateElementValueInSignPanelReducer: updateElementValue,
    setSignature: (state: SignPanelState, action: PayloadAction<string>) => {
      state.sign = action.payload;
    },
    resetSignature: (state: SignPanelState) => {
      return {
        ...state,
        ...initialState,
      };
    },

  },
});

export const { updateElementValueInSignPanelReducer, setSignature,resetSignature} =
  signPanelSlice.actions;

export const useSignPanelReducer = () => 
  useAppSelector((state) => state.signPanel);

export default signPanelSlice.reducer;
