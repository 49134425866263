import React, { useEffect } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import Header from "../common/Header";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { RiSearchLine } from "react-icons/ri";
import ListViewComponent from "../common/ListViewComponent";
import {
  useCountyListReducer,
  getLoggedInUserECCounties,
  resetCountiesData,
} from "../../redux/slice/countyListSlice";
import {
  useAgencyListReducer,
  getLoggedInUserECAgencies,
  updateElementValueAgencyListReducer,
  resetAgenciesData,
} from "../../redux/slice/agencyListSlice";
import {
  listStatutes,
  useStatutesListReducer,
  updateElementValueStatutesListReducer,
  resetStatutesSearchData,
  updateStatutesListReducer,
} from "../../redux/slice/statutesListSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { useAppDispatch } from "../../hooks/hooks";
import {
  agencyListByCounty,
  useSelectCountyAgencyReducer,
  updateElementValueSelectCountyAgencyReducer,
} from "../../redux/slice/selectCountyAgencySlice";
import { ROLE_SA_ADMIN, ROLE_STATES_ATTORNEY } from "../../constants/common";

interface StatutesListProps {}

export const StatutesList: React.FC<StatutesListProps> = (props) => {
  const {
    totalPages,
    currentPage,
    totalRecords,
    statutesList,
    availableCounties,
    availableAgencies,
    refCode,
    desc,
    caseType,
    classofViolation,
    violationType,
    offenseCode,
    localCounty,
    localAgency,
    pagedStatutesListFetchstatus,
    didMount,
  } = useStatutesListReducer();
  const { ecCountyList, loggedInUserCountyFetchStatus } =
    useCountyListReducer();
  const { ecAgencyList, loggedInUseragenciFetchStatus } =
    useAgencyListReducer();
  const { userType } = useLoginReducer();
  const { agenciesForCounty, status } = useSelectCountyAgencyReducer();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetCountiesData());
    dispatch(resetAgenciesData());
    dispatch(resetStatutesSearchData());
    dispatch(getLoggedInUserECCounties());
    dispatch(getLoggedInUserECAgencies());
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "didMount",
        value: true,
      })
    );
  }, []);

  useEffect(() => {
    if (didMount === true) {
      getAvailableCountyIds();
      getAvailableAgencyIds();
      if (
        loggedInUserCountyFetchStatus === "success" &&
        loggedInUseragenciFetchStatus === "success" &&
        pagedStatutesListFetchstatus === "idle"
      ) {
        dispatch(
          listStatutes({
            countyIds: availableCountuIds,
            agencyIds: availableAgencyIds,
            refCode: undefined,
            desc: undefined,
            caseType: "-- ALL --",
            classofViolation: "-- ALL --",
            violationType: violationType ? violationType : "-- ALL --",
            offenseCode: undefined,
            pageNo: 0,
          })
        );
        navigate(location.pathname);
      }
      //default SV violation type for SA users
      if (userType === ROLE_SA_ADMIN || userType === ROLE_STATES_ATTORNEY) {
        dispatch(
          updateElementValueStatutesListReducer({
            elementName: "violationType",
            value: "SV",
          })
        );
      }
    }
  }, [
    didMount,
    loggedInUserCountyFetchStatus,
    loggedInUseragenciFetchStatus,
    navigate,
    location.pathname,
  ]);

  useEffect(() => {
    dispatch(
      updateElementValueAgencyListReducer({
        elementName: "agencyList",
        value: agenciesForCounty,
      })
    );
    dispatch(
      updateElementValueSelectCountyAgencyReducer({
        elementName: "status",
        value: "loading",
      })
    );
    getAvailableAgencyIds();
  }, [status == "success"]);

  let availableCountuIds: any = [];
  let availableAgencyIds: any = [];

  const getAvailableCountyIds = () => {
    availableCountuIds = [];
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "localCounty",
        value: "-- ALL --",
      })
    );
    if (CountyListOptions.length > 1) {
      for (let i = 1; i < CountyListOptions.length; i++) {
        availableCountuIds.push(CountyListOptions[i].value.countyID);
      }
    } else {
      availableCountuIds.push(CountyListOptions[0].value.countyID);
      dispatch(
        updateElementValueStatutesListReducer({
          elementName: "localCounty",
          value: CountyListOptions[0].label,
        })
      );
    }
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "availableCounties",
        value: availableCountuIds,
      })
    );
  };

  const getAvailableAgencyIds = () => {
    availableAgencyIds = [];
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "localAgency",
        value: "-- ALL --",
      })
    );
    if (AgencyListOptions.length > 1) {
      for (let i = 1; i < AgencyListOptions.length; i++) {
        availableAgencyIds.push(AgencyListOptions[i].value.agencyId);
      }
    } else {
      availableAgencyIds.push(AgencyListOptions[0].value.agencyId);
      dispatch(
        updateElementValueStatutesListReducer({
          elementName: "localAgency",
          value: AgencyListOptions[0].label,
        })
      );
    }
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "availableAgencies",
        value: availableAgencyIds,
      })
    );
  };

  const CountyListOptions: any = [];
  if (ecCountyList.length !== 1) {
    CountyListOptions.push({ label: "-- ALL --", value: {} });
  }
  CountyListOptions.push(
    ...ecCountyList.map((county: any) => {
      return { label: county.countyName, value: county };
    })
  );

  const AgencyListOptions: any = [];
  if (ecAgencyList.length !== 1) {
    AgencyListOptions.push({ label: "-- ALL --", value: {} });
  }
  AgencyListOptions.push(
    ...ecAgencyList.map((agency: any) => {
      return { label: agency.agencyName, value: agency };
    })
  );

  const handleCounty = (e: any) => {
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "localCounty",
        value: e.value.countyName,
      })
    );
    if (e.label !== "-- ALL --") {
      dispatch(
        updateElementValueStatutesListReducer({
          elementName: "availableCounties",
          value: [e.value.countyID],
        })
      );
    }
    if (e.label == "-- ALL --") {
      dispatch(getLoggedInUserECAgencies());
    } else {
      dispatch(agencyListByCounty({ countyId: e.value.countyID }));
    }
    resetAgency();
  };

  const handleAgency = (e: any) => {
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "localAgency",
        value: e.value.agencyName,
      })
    );
    if (e.label !== "-- ALL --") {
      dispatch(
        updateElementValueStatutesListReducer({
          elementName: "availableAgencies",
          value: [e.value.agencyId],
        })
      );
    } else {
      resetAgency();
    }
  };

  const resetAgency = () => {
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "localAgency",
        value: "-- ALL --",
      })
    );
    getAvailableAgencyIds();
    // dispatch(
    //   updateElementValueStatutesListReducer({
    //     elementName: "availableAgencies",
    //     value: 0,
    //   })
    // );
  };

  const handleCaseTypeChange = (event: any) => {
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "caseType",
        value: event.value,
      })
    );
  };

  const handleClassChange = (event: any) => {
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "classofViolation",
        value: event.value,
      })
    );
  };

  const handleViolationTypeChange = (event: any) => {
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: "violationType",
        value: event.value,
      })
    );
  };

  const handleInputChange = (event: any) => {
    dispatch(
      updateElementValueStatutesListReducer({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  const handleSubmit = async (e: any) => {
    let obj = {
      countyIds: availableCounties ? availableCounties : undefined,
      agencyIds: availableAgencies ? availableAgencies : undefined,
      refCode: refCode ? refCode : undefined,
      desc: desc ? desc : undefined,
      caseType: caseType ? caseType : undefined,
      classofViolation: classofViolation ? classofViolation : undefined,
      violationType: violationType ? violationType : undefined,
      offenseCode: offenseCode ? offenseCode : undefined,
      pageNo: 0,
    };
    dispatch(listStatutes(obj));

    let urlObj: any = {
      refCode: refCode ? refCode : "",
      desc: desc ? desc : "",
      caseType: caseType ? caseType : "",
      classofViolation: classofViolation ? classofViolation : "",
      violationType: violationType ? violationType : "",
      offenseCode: offenseCode ? offenseCode : "",
      pageNo: 1,
      countyIds: availableCounties ? availableCounties.toString() : "",
      agencyIds: availableAgencies ? availableAgencies.toString() : "",
    };

    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlObj)}`,
    });
  };

  const paginationHandle = (page: number, searchBarState: any) => {
    let urlObj: any = {
      refCode: refCode ? refCode : "",
      desc: desc ? desc : "",
      caseType: caseType ? caseType : "",
      classofViolation: classofViolation ? classofViolation : "",
      violationType: violationType ? violationType : "",
      offenseCode: offenseCode ? offenseCode : "",
      pageNo: (page + 1).toString(),
      countyIds: availableCounties ? availableCounties.toString() : "",
      agencyIds: availableAgencies ? availableAgencies.toString() : "",
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlObj)}`,
    });

    let obj = {
      countyIds: availableCounties ? availableCounties : undefined,
      agencyIds: availableAgencies ? availableAgencies : undefined,
      refCode: refCode ? refCode : undefined,
      desc: desc ? desc : undefined,
      caseType: caseType ? caseType : undefined,
      classofViolation: classofViolation ? classofViolation : undefined,
      violationType: violationType ? violationType : undefined,
      offenseCode: offenseCode ? offenseCode : undefined,
      pageNo: page,
    };
    dispatch(listStatutes(obj));
  };

  window.onpopstate = () => {
    let paramsValue = location.search;

    if (paramsValue.trim().length > 0) {
      let countyIdString = searchParams.get("countyIds")?.toString() || "0";
      let agencyIdString = searchParams.get("agencyIds")?.toString() || "0";

      let countyIdStringList = new Array();
      countyIdStringList = countyIdString.split(",").map(Number);

      let agencyIdStringList = new Array();
      agencyIdStringList = agencyIdString.split(",").map(Number);

      let urlObj: any = {
        countyIds: countyIdStringList,
        agencyIds: agencyIdStringList,
        refCode: searchParams.get("refCode") || undefined,
        desc: searchParams.get("desc") || undefined,
        caseType: searchParams.get("caseType") || undefined,
        classofViolation: searchParams.get("classofViolation") || undefined,
        violationType: searchParams.get("violationType") || undefined,
        offenseCode: searchParams.get("offenseCode") || undefined,
        pageNo: parseInt(searchParams.get("pageNo")?.toString() || "0") - 1,
      };
      setUrlData();
      dispatch(listStatutes(urlObj));
    } else {
      dispatch(resetCountiesData());
      dispatch(resetAgenciesData());
      dispatch(resetStatutesSearchData());
      dispatch(getLoggedInUserECCounties());
      dispatch(getLoggedInUserECAgencies());
    }
  };

  const setUrlData = () => {
    let countyIdString = searchParams.get("countyIds")?.toString() || "0";
    let agencyIdString = searchParams.get("agencyIds")?.toString() || "0";

    let countyIdStringList = new Array();
    countyIdStringList = countyIdString.split(",").map(Number);

    let agencyIdStringList = new Array();
    agencyIdStringList = agencyIdString.split(",").map(Number);

    let urlObj: any = {
      localCounty:
        countyIdStringList.length > 1 ? "-- ALL --" : countyIdStringList,
      localAgency:
        agencyIdStringList.length > 1 ? "-- ALL --" : agencyIdStringList,
      countyIds: countyIdStringList,
      agencyIds: agencyIdStringList,
      refCode: searchParams.get("refCode") || "",
      desc: searchParams.get("desc") || "",
      caseType:
        searchParams.get("caseType") === ""
          ? "-- ALL --"
          : searchParams.get("caseType"),
      classofViolation: searchParams.get("classofViolation") || "",
      violationType: searchParams.get("violationType") || "",
      offenseCode: searchParams.get("offenseCode") || "",
    };
    dispatch(updateStatutesListReducer(urlObj));
  };

  let tableHeader = [
    "VIOLATION CODE",
    "VIOLATION DESCRIPTION",
    "CASE TYPE",
    "CLASS",
    "VIOLATION TYPE",
    "OFFENCE CODE",
    "AGENCY",
  ];

  let selectCaseTypeList = [
    { value: "-- ALL --", label: "-- ALL --" },
    { label: "CC", value: "CC" },
    { label: "CF", value: "CF" },
    { label: "CL", value: "CL" },
    { label: "CM", value: "CM" },
    { label: "CV", value: "CV" },
    { label: "DT", value: "DT" },
    { label: "DV", value: "DV" },
    { label: "J", value: "J" },
    { label: "JD", value: "JD" },
    { label: "MR", value: "MR" },
    { label: "MT", value: "MT" },
    { label: "OV", value: "OV" },
    { label: "QC", value: "QC" },
    { label: "SV", value: "SV" },
    { label: "TR", value: "TR" },
    { label: "TV", value: "TV" },
  ];

  let selectClassList = [
    { value: "-- ALL --", label: "-- ALL --" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "J", value: "J" },
    { label: "M", value: "M" },
    { label: "O", value: "O" },
    { label: "P", value: "P" },
    { label: "U", value: "U" },
    { label: "X", value: "X" },
    { label: "Z", value: "Z" },
  ];

  let selectTypeList = [
    { value: "-- ALL --", label: "-- ALL --" },
    { value: "SV", label: "SV" },
    { value: "LSV", label: "LSV" },
    { value: "LCV", label: "LCV" },
  ];

  let tableData = statutesList.map(
    ({
      violationId,
      offenseCode,
      violationCode,
      violationDescription,
      violationType,
      caseType,
      classOfViolation,
      agencyName,
    }: any) => [
      violationCode,
      violationDescription,
      caseType,
      classOfViolation,
      violationType,
      offenseCode,
      agencyName ? agencyName : "-",
    ]
  );

  let itemLinkList = [{ itemLink: "/", itemName: "DashBoard" }];
  const data = {
    colSize: 3,
    componentData: [
      {
        type: "selectInput",
        props: {
          options: CountyListOptions,
          value: CountyListOptions.find((item: any) => {
            if (item.label === localCounty) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select County",
          onChange: handleCounty,
          isDisabled: ecCountyList.length === 1 ? true : false,
          name: "county",
          defaultValue: { label: "-- ALL --", value: {} },
        },
      },
      {
        type: "selectInput",
        props: {
          options: AgencyListOptions,
          value: AgencyListOptions.find((item: any) => {
            if (item.label === localAgency) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select Agency",
          onChange: handleAgency,
          isDisabled: ecAgencyList.length === 1 ? true : false,
          name: "agency",
          defaultValue: { label: "-- ALL --", value: {} },
        },
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Violation Code",
          type: "text",
          name: "refCode",
          placeholder: "Enter Violation Code",
          onChange: handleInputChange,
          value: refCode,
        },
        colSize: 3,
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Violation Description",
          type: "text",
          name: "desc",
          placeholder: "Violation Description",
          onChange: handleInputChange,
          value: desc,
        },
        colSize: 3,
      },
      {
        type: "selectInput",
        props: {
          options: selectCaseTypeList,
          value: selectCaseTypeList.find((item: any) => {
            if (item.label === caseType) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select Case Type",
          onChange: handleCaseTypeChange,
          name: "caseType",
          defaultValue: { label: "-- ALL --", value: "" },
        },
      },
      {
        type: "selectInput",
        props: {
          options: selectClassList,
          value: selectClassList.find((item: any) => {
            if (item.label === classofViolation) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Class",
          onChange: handleClassChange,
          name: "classofViolation",
          defaultValue: { value: "", label: "-- ALL --" },
        },
        colSize: 3,
      },
      {
        type: "selectInput",
        props: {
          options: selectTypeList,
          value: selectTypeList.find((item: any) => {
            if (item.label === violationType) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Violation Type",
          onChange: handleViolationTypeChange,
          name: "violationType",
          defaultValue: { value: "all", label: "-- ALL --" },
        },
        colSize: 3,
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Offense Code",
          type: "text",
          name: "offenseCode",
          placeholder: "Offense Code",
          onChange: handleInputChange,
          value: offenseCode,
        },
        colSize: 2,
      },

      {
        type: "button",
        props: {
          className: "w-100",
          children: (
            <>
              <RiSearchLine /> Search
            </>
          ),
          onClick: handleSubmit,
        },
      },
    ],
  };

  return (
    <>
      <Header headerName="Statutes List" />

      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Statutes List"
        />

        <ListViewComponent
          table={{
            tableHeader: tableHeader,
            tableData: tableData,
            renderTableRow: renderTableRow,
          }}
          searchBar={{
            colSize: data.colSize,
            componentData: data.componentData,
          }}
          middleComponent={() => (
            <ListViewMiddleComponent totalRecords={totalRecords} />
          )}
          pagination={{
            totalPages,
            numberOfPagesToShow: 5,
            currentPage,
            onPageChange: (page: number, searchBarState: any) => {
              paginationHandle(page, searchBarState);
            },
          }}
        />
      </div>
    </>
  );
};

const ListViewMiddleComponent = ({
  totalRecords,
}: {
  totalRecords: number;
}) => (
  <Row>
    <Col>
      <h4>Statutes List</h4>
      <h6 className="my-n2">Total Statutes : {totalRecords}</h6>
    </Col>
  </Row>
);
const renderTableRow = (row: any[]) => {
  const [
    refCode,
    desc,
    caseType,
    classofViolation,
    violationType,
    offenseCode,
    agencyName,
    violationId,
  ] = row;
  return (
    <tr key={violationId}>
      <td>{refCode}</td>
      <td>{desc}</td>
      <td>{caseType}</td>
      <td>{classofViolation}</td>
      <td>{violationType}</td>
      <td>{offenseCode}</td>
      <td>{agencyName}</td>
    </tr>
  );
};
export default StatutesList;
