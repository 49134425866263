import React, { useCallback, useEffect } from "react";
import Header from "../../common/Header";
import { RiArrowRightLine, RiCloseLine } from "react-icons/ri";
import BreadcrumbComponent from "../../common/BreadcrumbComponent";
import FormComponent from "../../common/FormComponent";
import { useNavigate } from "react-router-dom";
import { useTemplateReducer } from "../../../redux/slice/templateSlice";
import ConfirmPopup from "../../common/ConfirmPopup";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";
import {
  useApplicationAndOrderReducer,
  getTemplateDataForApplicationOrder,
  updateElementObjectValue,
} from "../../../redux/slice/applicationAndOrderSlice";
import { listSearchWarrantType } from "../../../redux/slice/templateSlice";
import { useAppDispatch } from "../../../hooks/hooks";
import { APPLICATION_AND_ORDER, ROLE_ADMINISTRATOR, ROLE_AGENCY_ADMIN, ROLE_ATTORNEY_GENERAL, ROLE_COUNTY_ADMIN, ROLE_LOCAL_PROSECUTOR, ROLE_POLICE_OFFICER, ROLE_SA_ADMIN, ROLE_STATES_ATTORNEY, SEARCH_WAR_ASSIGNED_TO_AG_STATUS, SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS, SEARCH_WAR_ASSIGNED_TO_LP_STATUS, SEARCH_WAR_ASSIGNED_TO_SA_STATUS, SEARCH_WAR_INCOMPLETE_STATUS, SEARCH_WAR_LOCKED_BY_AG_STATUS, SEARCH_WAR_LOCKED_BY_JUDGE_STATUS, SEARCH_WAR_LOCKED_BY_LP_STATUS, SEARCH_WAR_LOCKED_BY_SA_STATUS, SEARCH_WAR_OFFICER_PENDING_STATUS } from "../../../constants/common";
import { updateForm } from "../../../redux/slice/commonFormSlice";
import { getSWCaseDetail, useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";
import { setSignature, useSignPanelReducer } from "../../../redux/slice/signPanelSlice";
import {useLoginReducer} from "../../../redux/slice/loginSlice";
import {ROLE_JUDGE} from "../../../constants/common";
import {getValidationError, validateSignaturePixel} from "../../common/CommonUtil"
import { formatName, updateTagValue } from "../../../utils/common";
import ButtonComponent from "../../common/ButtonComponent";
import FormFiller from "../../common/FormFiller";
import { useDashboardReducer, updateElementValueDashboardReducer } from "../../../redux/slice/dashboardSlice";
interface ApplicationAndOrderProps {}

let fieldTitles =['Application Title','APPLICATION INFORMATION HERE','ORDER TITLE HERE','ORDER INFORMATION HERE'];
let names = ['applicationTitle','applicationInfo','orderTitle','txtPersonInfo'];

let defaultSwSchema = yup.object().shape({
  searchWarrantType: yup.string().nullable().required("Order Type in required."),
  txtAppears: yup.string().required("Officer Name in required."),
  badgeNo: yup.string().nullable().required("Badge No in required."),
  applicationTitle: yup.string().nullable().required("Application Title is required.").test('validator-ck-editor', function (value) {
    const errMessage = getValidationError("APPLICATION TITLE", value);
    if (errMessage.length > 0) {
      return this.createError({
        message: errMessage,
      });
    }
    else {
      return true;
    }
  }),
  applicationInfo: yup.string().nullable().required("Application Info is required.").test('validator-ck-editor', function (value) {
    const errMessage = getValidationError("APPLICATION INFORMATION", value);
    if (errMessage.length > 0) {
      return this.createError({
        message: errMessage,
      });
    }
    else {
      return true;
    }
  }),
  orderTitle: yup.string().nullable().required("Order Title is required.").test('validator-ck-editor', function (value) {
    const errMessage = getValidationError("ORDER TITLE", value);
    if (errMessage.length > 0) {
      return this.createError({
        message: errMessage,
      });
    }
    else {
      return true;
    }
  }),
  txtPersonInfo: yup.string().nullable().required("Order Info is required.").test('validator-ck-editor', function (value) {
    const errMessage = getValidationError("ORDER INFORMATION", value);
    if (errMessage.length > 0) {
      return this.createError({
        message: errMessage,
      });
    }
    else {
      return true;
    }
  }),
 
});

const ApplicationAndOrder: React.FC<ApplicationAndOrderProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { swTypeList } = useTemplateReducer();
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [searchWarrantType, setSearchWarrantType] = React.useState("");
  const [aaoTemplateId, setAaoTemplateId] = React.useState(0);
  const [confirmPopupData, setconfirmPopupData] = React.useState(false);
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const applicationAndOrderReducer = useApplicationAndOrderReducer();
  const caseDetailReducer = useCaseDetailReducer();
  const loginReducer = useLoginReducer();
  const signPanelReducer = useSignPanelReducer();
  const [signConfirmationModalShow , setsignConfirmationModalShow ] = React.useState(false)
  const [signatureCanvasVisible , setSignatureCanvasVisible ] = React.useState(false);
  const [complainantNameFooter, setComplainantNameFooter] = React.useState(""); 
  const [searchWarrantTypeEnable , setSearchWarrantTypeEnable ] = React.useState(false);
  const [whoSign, setWhoSign] = React.useState("");
  const [swText, setSwText] = React.useState("");

  const [showFormFiller, setShowFormFiller] = React.useState(false);
  const [fieldTitleValues, setFieldTitleValues] = React.useState<String[]>([]);
  const dashboardReducer = useDashboardReducer();

  useEffect(() => {
    if (applicationAndOrderReducer.applicationAndOrderObject.templateId >0){
      if (applicationAndOrderReducer.applicationAndOrderObject.applicationTitle && applicationAndOrderReducer.applicationAndOrderObject.applicationTitle.trim() !=null)
        dispatch(updateElementObjectValue({elementName: "applicationTitle", value: updateTagValue(applicationAndOrderReducer.applicationAndOrderObject.applicationTitle, applicationAndOrderReducer.applicationAndOrderObject.badgeNo)}))
      if (applicationAndOrderReducer.applicationAndOrderObject.applicationInfo && applicationAndOrderReducer.applicationAndOrderObject.applicationInfo.trim() !=null)
        dispatch(updateElementObjectValue({elementName: "applicationInfo", value: updateTagValue(applicationAndOrderReducer.applicationAndOrderObject.applicationInfo, applicationAndOrderReducer.applicationAndOrderObject.badgeNo)}))
      if (applicationAndOrderReducer.applicationAndOrderObject.orderTitle && applicationAndOrderReducer.applicationAndOrderObject.orderTitle.trim() !=null)
        dispatch(updateElementObjectValue({elementName: "orderTitle", value: updateTagValue(applicationAndOrderReducer.applicationAndOrderObject.orderTitle, applicationAndOrderReducer.applicationAndOrderObject.badgeNo)}))
      if (applicationAndOrderReducer.applicationAndOrderObject.txtPersonInfo && applicationAndOrderReducer.applicationAndOrderObject.txtPersonInfo.trim() !=null)
        dispatch(updateElementObjectValue({elementName: "txtPersonInfo", value: updateTagValue(applicationAndOrderReducer.applicationAndOrderObject.txtPersonInfo, applicationAndOrderReducer.applicationAndOrderObject.badgeNo)}))
    }
  }, [applicationAndOrderReducer.applicationAndOrderObject.badgeNo])

  const handleWarrantTypeValChange = (e: any) => {
    let swType = e.label;
    if(applicationAndOrderReducer.applicationAndOrderObject.searchWarrantType === null|| swType === "-- Select --"){
     setconfirmPopupData(false);
    dispatch(
      updateElementObjectValue({
        elementName: "searchWarrantType",
        value: swType,
      })
    );
    dispatch(
      updateElementObjectValue({
        elementName: "templateId",
        value: e.value,
      })
    );
    dispatch(
      getTemplateDataForApplicationOrder({
        countyId: applicationAndOrderReducer.applicationAndOrderObject.countyID,
        warrantForm: APPLICATION_AND_ORDER,
        templateName: swType,
      })
    );
    }
    else{
    let confirmText = (
      <>
        <label>
          Data entered in all fields of this form will be replaced using the
          template - {swType}{" "}
        </label>{" "}
        <label>Do you want to continue?</label>
      </>
    );
    setSearchWarrantType(swType);
    setAaoTemplateId(e.value);
    setconfirmText(confirmText);
    setconfirmPopupData(true);
    }

    if (loginReducer.userType === ROLE_JUDGE && applicationAndOrderReducer.applicationAndOrderObject.hiddenJudgeSignature === null) {
      dispatch(setSignature(applicationAndOrderReducer.applicationAndOrderObject.hiddenUserSignature));
    } else if (applicationAndOrderReducer.applicationAndOrderObject.hiddenComplaintSignature === null) {
      dispatch(setSignature(applicationAndOrderReducer.applicationAndOrderObject.hiddenUserSignature))
    } 
      
  };

  const handleChangeObjectValue = (event: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  function prepareMessage(msg:any) {
    let buff = [];
    if (msg && msg.trim().length > 0) {
        let arr = msg.split("<br>");
        let line = "";
        for (let i = 0; i < arr.length; i++) {
            line = arr[i];
            buff.push(<div>{line}</div>);
        }
    }
    return buff;
  }
  const cancelSignConfirmModal = () => {
    setsignConfirmationModalShow(false);
  }

  const acceptSignConfirmModal = () => {
    setsignConfirmationModalShow(false);
    updateApplicationAndOrderOnNext();
    
  }

  const handleChangeObjectValueForCkEditor = (name: string, editor: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: name,
        value: editor.getData(),
      })
    );
  };

  const handleConfirmation = () => {
    console.log(searchWarrantType);
    setconfirmPopupData(false);
    dispatch(
      updateElementObjectValue({
        elementName: "searchWarrantType",
        value: searchWarrantType,
      })
    );
    dispatch(
      updateElementObjectValue({
        elementName: "templateId",
        value: aaoTemplateId,
      })
    );
    dispatch(
      getTemplateDataForApplicationOrder({
        countyId: applicationAndOrderReducer.applicationAndOrderObject.countyID,
        warrantForm: APPLICATION_AND_ORDER,
        templateName: searchWarrantType,
      })
    );
  };

  const updateApplicationAndOrderOnNext=() =>{
    const formObj = {...applicationAndOrderReducer.applicationAndOrderObject};
       if (ROLE_JUDGE === loginReducer.userType) {
          // dispatch(updateElementObjectValue({elementName:"hiddenJudgeSignature", value:signPanelReducer.sign}));
          // formObj.hiddenJudgeSignature = signPanelReducer.sign;
       } else {
          dispatch(updateElementObjectValue({elementName:"hiddenComplaintSignature", value:signPanelReducer.sign}));
          formObj.hiddenComplaintSignature = signPanelReducer.sign;
       }
       dispatch(
        updateForm({
          formId:formObj.searchWarrantId,
          formObject: formObj,
          formName : APPLICATION_AND_ORDER,
          navigate : navigate
        })
      );
     // navigate("/ApplicationAndOrderSubmit");
  };
  
  const nextClick = async () => {
    try {
      let schema = [];
      schema.push(defaultSwSchema);
      if (signatureCanvasVisible) {
        schema.unshift(
          yup.object().shape({
            sign: yup.string().nullable().required("Signature required."),
          })
        );
      }


      let Obj = {
        searchWarrantType: applicationAndOrderReducer.applicationAndOrderObject.searchWarrantType,
        txtAppears: applicationAndOrderReducer.applicationAndOrderObject.txtAppears,
        badgeNo: applicationAndOrderReducer.applicationAndOrderObject.badgeNo,
        applicationTitle: applicationAndOrderReducer.applicationAndOrderObject.applicationTitle,
        applicationInfo: applicationAndOrderReducer.applicationAndOrderObject.applicationInfo,
        orderTitle: applicationAndOrderReducer.applicationAndOrderObject.orderTitle,
        txtPersonInfo: applicationAndOrderReducer.applicationAndOrderObject.txtPersonInfo,
        sign:signPanelReducer.sign
      }
      await defaultSwSchema.validate(
        Obj,
        {
          abortEarly: false,
        }
      );
      let isValidateSignPixel = validateSignaturePixel(signatureCanvasVisible, signPanelReducer.signThreshold);
      if(!isValidateSignPixel){
       setsignConfirmationModalShow(true);
     }
     else{
      updateApplicationAndOrderOnNext()
     }
      
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const cancelClick = () =>{
    dispatch(getSWCaseDetail(({searchWarrantId: caseDetailReducer.searchWarrantId, action:"cancelForm", navigate: navigate})));
  }

  useEffect(() => 
  {
    let dataObj = {
      countyId: caseDetailReducer.countyId,
      templateType: APPLICATION_AND_ORDER,
    };
    dispatch(listSearchWarrantType(dataObj));
    //dispatch(getWarrantTemplateListWithData(dataObj));

  }, []);


  useEffect(()=>{
    let loggedInUserRole = loginReducer.userType;
    let loggedInUserId = loginReducer.userProfileId;
    let userName = formatName(loginReducer.firstName, loginReducer.lastName, "");
    let { formStatus, creatorId, approvalSAId } = applicationAndOrderReducer.applicationAndOrderObject;


    setWhoSign("SIGNATURE OF COMPLAINANT");
    setSwText("Complainant Signature will be only applied after the content of this application and order is sworn before a judge");

    if (
      ROLE_ADMINISTRATOR === loggedInUserRole ||
      ROLE_COUNTY_ADMIN === loggedInUserRole
    ) {
      if (
        formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS
      ) {
        setSignatureCanvasVisible(true);
        if (userName && userName.trim().length > 0) {
          setComplainantNameFooter(userName);
        }
      }
      if (
        formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
        creatorId !== loggedInUserId
      ) {
        setSignatureCanvasVisible(false);
      }
    } else {

      if (ROLE_POLICE_OFFICER !== loggedInUserRole) {
        if (creatorId !== loggedInUserId) {
          setSearchWarrantTypeEnable(false)
        }
      }
      if (
        ROLE_POLICE_OFFICER === loggedInUserRole ||
        ROLE_AGENCY_ADMIN === loggedInUserRole
      ) {
        setSearchWarrantTypeEnable(true)
        setSignatureCanvasVisible(true);
        if (userName && userName.trim().length > 0) {
          setComplainantNameFooter(userName);
        }
      } 

      if (creatorId === loggedInUserId) {
        if (
          ROLE_POLICE_OFFICER === loggedInUserRole ||
          ROLE_STATES_ATTORNEY === loggedInUserRole ||
          ROLE_SA_ADMIN === loggedInUserRole ||
          ROLE_AGENCY_ADMIN === loggedInUserRole ||
          ROLE_LOCAL_PROSECUTOR === loggedInUserRole ||
          ROLE_ATTORNEY_GENERAL === loggedInUserRole
        ) {
          setSignatureCanvasVisible(true);
          if (userName && userName.trim().length > 0) {
            setComplainantNameFooter(userName);
          }
        }
      }

      if (creatorId !== loggedInUserId) {
        setSignatureCanvasVisible(false);
      }
    }
  },[applicationAndOrderReducer.applicationAndOrderObject.creatorId]);

  const  onBreadcrumbClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/"+dashboardReducer.PreviousURLSW.replace(/^.*\/\/.*?\//, ''), itemName: "Warrant List", itemFunction: onBreadcrumbClick  },
    { itemLink: "/CaseDetails", itemName: "Existing Forms" },
  ];

  const formFillerClick = () =>{
    let array = [
      applicationAndOrderReducer.applicationAndOrderObject.applicationTitle, applicationAndOrderReducer.applicationAndOrderObject.applicationInfo,
      applicationAndOrderReducer.applicationAndOrderObject.orderTitle, applicationAndOrderReducer.applicationAndOrderObject.txtPersonInfo
    ];
    setShowFormFiller(true);
    setFieldTitleValues(array);
  }

  const updateFieldFromWizard = (name: string, value: string) =>{
    dispatch(updateElementObjectValue({elementName:name, value: value}));
  }

  const data = [
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: "STATE OF ILLINOIS",
        className: "fw-bold text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h5",
        value: (
          <>
            UNITED STATES OF AMERICA
            <br />
            {applicationAndOrderReducer.applicationAndOrderObject.lblHeadingInTheCircuitSW}
          </>
        ),
        className: "fw-bold text-center",
      },
      colSize: 6,
    },
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value:
          applicationAndOrderReducer.applicationAndOrderObject
            .lblHeadingCountySW,
        className: "fw-bold text-end text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
    },

    {
      type: "textInput",
      props: {
        type: "text",
        name: "agencyName",
        value: applicationAndOrderReducer.applicationAndOrderObject.txtAgency,
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h4",
        value: "APPLICATION FOR AN ORDER",
        className: "fw-bold text-center text-danger",
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "txtCaseNumber",
        value:
          applicationAndOrderReducer.applicationAndOrderObject.txtCaseNumber,
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: 0 },
          ...swTypeList.map((swtype: any) => {
            return {
              label: swtype.templateName,
              value: swtype.templateId,
            };
          }),
        ],
        isDefaultLabel : true,
        onChange: handleWarrantTypeValChange,
        isError: errorKeys.includes("searchWarrantType"),
        placeholder: "-- Select --",
        value: swTypeList
          .map((swtype: any) => {
            return {
              label: swtype.templateName,
              value: swtype.templateId,
            };
          })
          .find((item) => {
            if (
              (item.label =
                applicationAndOrderReducer.applicationAndOrderObject.searchWarrantType)
            )
              return {
                label: item.label,
                value: item.value,
              };
          }),
      },
      colSize: 5,
      label: "Order Type :",
      inLine: true,
    },
    {
      type: "htmlTag",
      props: {
        type: "div",
        value: applicationAndOrderReducer.applicationAndOrderObject.searchWarrantType==null?"*Note: Please select Order type.":"",
        className: "text-padding",
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        onChange: handleChangeObjectValue,
        placeholder: "Officer/Employee Name",
        name: "txtAppears",
        isError: errorKeys.includes("txtAppears"),
        value: applicationAndOrderReducer.applicationAndOrderObject.txtAppears,
        disabled: true,
      },
      colSize: 5,
      label: "Officer/Employee Name :",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        onChange: handleChangeObjectValue,
        value: applicationAndOrderReducer.applicationAndOrderObject.badgeNo,
        placeholder: "Badge",
        name: "badgeNo",
        isError: errorKeys.includes("badgeNo"),
      },
      colSize: 3,
      label: "Badge/Employee # :",
      inLine: true,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
      emptyCol: 10
    },
  ];
  let formFiled = [
    {
      type: 'formButton',
      props: {
        children: "Form Filler Wizard",
        onClick: formFillerClick,
      },
      inLine: true,
      colSize: 2,
    },
    {
      type: "htmlTag",
      props: {
        value: "Application Title :",
      },
      colSize: 10,
    },
    {
      type: "editor",
      props: {
        data: applicationAndOrderReducer.applicationAndOrderObject
          .applicationTitle
          ? applicationAndOrderReducer.applicationAndOrderObject
              .applicationTitle
          : "",
        onChange: (event: any, editor: any) => {
          handleChangeObjectValueForCkEditor("applicationTitle", editor);
        },
        isError: errorKeys.includes("applicationTitle"),
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: "Application Information Here :",
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: applicationAndOrderReducer.applicationAndOrderObject
          .applicationInfo
          ? applicationAndOrderReducer.applicationAndOrderObject.applicationInfo
          : "",
        onChange: (event: any, editor: any) => {
          handleChangeObjectValueForCkEditor("applicationInfo", editor);
        },
        isError: errorKeys.includes("applicationInfo"),
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: "Order Title Here :",
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: applicationAndOrderReducer.applicationAndOrderObject.orderTitle
          ? applicationAndOrderReducer.applicationAndOrderObject.orderTitle
          : "",
        onChange: (event: any, editor: any) => {
          handleChangeObjectValueForCkEditor("orderTitle", editor);
        },
        isError: errorKeys.includes("orderTitle"),
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: "Order Information Here :",
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: applicationAndOrderReducer.applicationAndOrderObject.txtPersonInfo
          ? applicationAndOrderReducer.applicationAndOrderObject.txtPersonInfo
          : "",
        onChange: (event: any, editor: any) => {
          handleChangeObjectValueForCkEditor("txtPersonInfo", editor);
        },
        isError: errorKeys.includes("txtPersonInfo"),
      },
      colSize: 12,
    },
    {
      type: "signPanel",
      props: {
        className: signatureCanvasVisible ? "d-block" : "d-none",
        name: "sign",
        appearPersonText : complainantNameFooter,
        whoSign : whoSign,
        swText : swText,
        isError: errorKeys.includes("sign")
      },
      colSize: 12,
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-primary ms-2",
        children: (
          <>
            Next <RiArrowRightLine />
          </>
        ),
        onClick: () => {
          nextClick();
        },
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-danger ms-2",
        children: (
          <>
            Cancel <RiCloseLine />
          </>
        ),
        onClick: () => {
          cancelClick();
          //navigate("/CaseDetails");
        },
      },
    },
  ]
  return (
    <>
      <Header headerName="Application and Order" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Application and Order"
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={
            applicationAndOrderReducer.applicationAndOrderObject
              .searchWarrantType == null ||
            applicationAndOrderReducer.applicationAndOrderObject
              .searchWarrantType === "-- Select --"
              ? data
              : [...data, ...formFiled]
          }
          components={[
            {
              type: "formButton",
              node: (props: any) => (
                <div className="text-end">
                <ButtonComponent {...props}>{props.children}</ButtonComponent>
              </div>
                
              ),
            }
          ]}
        />
      </div>
      <ConfirmPopup
        children={confirmText}
        show={confirmPopupData}
        onHide={() => setconfirmPopupData(false)}
        isConfirmation={handleConfirmation}
      />
      <ConfirmPopup
        show={signConfirmationModalShow}
        onHide={cancelSignConfirmModal}
        isConfirmation={acceptSignConfirmModal}
      >
        {prepareMessage("Are you sure the signature you provided is valid?")}
      </ConfirmPopup>
      <FormFiller
       show={showFormFiller}
       onHide = {() => setShowFormFiller(false)}
       fieldTitles={fieldTitles}
       names = {names}
       values = {fieldTitleValues}
       onChangeHandler={updateFieldFromWizard}
      />
    </>
  );
};

export default ApplicationAndOrder;
