import { PayloadAction } from "@reduxjs/toolkit";

/**
 * This function bypasses the type checker to update the value of an element. Use at your own risk.
 * @param state the redux state
 * @param action your payload action
 */

export const updateElementObject = (
  state: any,
  action: PayloadAction<{ elementName: string; value: any }>,
  elementObj: any
) => {
  // @ts-ignore - This method doesn't have typesupport
  elementObj[action.payload.elementName] = action.payload.value;
};
