import { Navigate, useLocation } from "react-router-dom";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { PropsWithChildren } from "react";

const PublicRoute = ({ children }: PropsWithChildren<any>) => {
  const { status, isValid } = useLoginReducer();
  const location = useLocation();

  if ((status === "success" || status === "error") && isValid) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
};

export default PublicRoute;
