import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "./hooks";
import {
  resetLoginData,
  updateElementValueInLoginReducer,
} from "../redux/slice/loginSlice";

export default function useLogOut() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logout = (errorMsg?: string) => {
    dispatch(resetLoginData());
    if (errorMsg) {
      dispatch(
        updateElementValueInLoginReducer({
          elementName: "incorrectCredentialError",
          value: errorMsg,
        })
      );
    }
    navigate("/login");
  };
  return logout;
}
