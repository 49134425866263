import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import {
  AMENDED_INVENTORY,
  APPLICATION_AND_ORDER,
  BLANK_ORDER,
  COMPL_SEARCH_WARRANT, COMPL_SEARCH_WARRANT_WITH_SLASH,
  INVENTORY,
  ROLE_JUDGE,
} from "../../constants/common";
import { updateSearchWarrantObject } from "./searchWarrantSlice";
import { updateApplicationAndOrderObject } from "./applicationAndOrderSlice";
import { updateBlankOrderObject } from "./blankOrderSlice";
import { setSignature } from "./signPanelSlice";
import { useLoginReducer } from "./loginSlice";
import { updateInventoryObject } from "./inventorySlice";
import { updateAmendInventoryObject } from "./amendInventorySlice";
import { extractFileName } from "../../utils/common";
import { showHideLoadingGif, updateDocGenerateError, updatePdfGenerationError } from "./commonSlice";
import { LF_COMPLAINT } from "../../constants/common";
import { updateElementValueCaseDetailReducer } from "./caseDetailSlice";
import { getJudgeSavedSignature } from "./userProfileSlice";

interface commonFormState {
  status: LoadingType;
  previewStatus: LoadingType;
  fetchingError?: string;

  searchWarrantId: number;
  caseNumber: string;
  warrantFormId: string;
  formStatus: string;
  remark: string;
  reationDate: string;
  formData: string;
  applicationAndOrder: any[];
  searchWarrantComplaint: any[];
  blankOrder: any[];
  auditTimeStamp: string;
  creationTimeStamp: string;
  impounded: boolean;
  cmsSyncStatus: string;
}

const initialState = {
  status: "idle",
  previewStatus: "idle",
  searchWarrantId: 0,
  caseNumber: "",
  warrantFormId: "",
  formStatus: "",
  remark: "",
  reationDate: "",
  formData: "",
  applicationAndOrder: [],
  searchWarrantComplaint: [],
  blankOrder: [],
  auditTimeStamp: "",
  creationTimeStamp: "",
  impounded: false,
  cmsSyncStatus: "",
} as commonFormState;

export const getSWConcurrentObj = (swList: any[]) => {
  let concurrObj = undefined;
  if (swList && swList.length > 0) {
    let searchWarrantList: any[] = [];
    let proxy: any = {};
    for (let i = 0; i < swList.length; i++) {
      proxy = {};
      proxy.searchWarrantId = swList[i].searchWarrantId;
      proxy.caseNumber = swList[i].caseNumber;
      proxy.auditTimeStamp = swList[i].auditTimeStamp;
      proxy.countyId = swList[i].countyId;
      searchWarrantList.push(proxy);
    }
    concurrObj = { searchWarrantList };
  }
  return concurrObj;
}

export const getLFCConcurrentObj = (lfcList: any[]) => {
  let concurrObj : any = undefined;
  if (lfcList && lfcList.length > 0) {
    let ecFormList: any[] = [];
    let proxy: any = {};
    let auditTimeStamp;
    let ecomplaintId;
    for (let i = 0; i < lfcList.length; i++) {
      if(lfcList[i].formTypeName === LF_COMPLAINT){
        ecomplaintId = lfcList[i].ecomplaintId;
        auditTimeStamp = lfcList[i].auditTimeStamp;
      }else{
        proxy = {};
        proxy.ecomplaintId = lfcList[i].ecomplaintId;
        proxy.formId = lfcList[i].formId;
        proxy.formTypeName = lfcList[i].formTypeName;
        proxy.auditTimeStamp = lfcList[i].auditTimeStamp;
        ecFormList.push(proxy);
      }
    }
    concurrObj = {ecomplaintId,auditTimeStamp,ecFormList};
  }
  return concurrObj;
}

export const updateForm = createAsyncThunk<
  any,
  {
    formId: number;
    formObject: any;
    formName: string;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "commonForm/updateForm",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { formId, formObject, formName, navigate } = data;

    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "searchwarrantservices/updateForm/" + formId,
        token,
        type: "PUT",
        body: formObject,
        concurrentObject: concurrObj,
        concurrentIdentifier: "SW",
        dispatch: dispatch
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then(
          (result) => {
            if (formName === APPLICATION_AND_ORDER) {
              if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
                dispatch(
                  updateDocGenerateError({
                    error: true,
                    errorMsg: result.errorInfo.validationErrors,
                  })
                );
                return;
              }
              else if (result.errorInfo !== null) {
                dispatch(updatePdfGenerationError({
                  error: result.errorInfo.error,
                  errorMsg: result.errorInfo.errorMessage,
                }));
              } else {
                if (result && result.formData) {
                  let aaoForm = JSON.parse(result.formData);
                  dispatch(updateApplicationAndOrderObject(aaoForm));
                }
                dispatch(
                  updateElementValueCaseDetailReducer({
                    elementName: "searchWarrantList",
                    value: result.searchWarrantList,
                  }));
              }
              navigate("/ApplicationAndOrderSubmit"); 
            } else if (formName === BLANK_ORDER) {
              if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
                dispatch(
                  updateDocGenerateError({
                    error: true,
                    errorMsg: result.errorInfo.validationErrors,
                  })
                );
                return;
              } else if (result.errorInfo !== null) {
                dispatch(updatePdfGenerationError({
                  error: result.errorInfo.error,
                  errorMsg: result.errorInfo.errorMessage,
                }));
              } else {
                let blankForm = JSON.parse(result.formData);
                blankForm.formStatus = result.formStatus;
                dispatch(updateBlankOrderObject(blankForm));
                dispatch(
                  updateElementValueCaseDetailReducer({
                    elementName: "searchWarrantList",
                    value: result.searchWarrantList,
                  }));
              }
               navigate("/BlankOrderSubmit");
            }
          });      
        return res;
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while updating SW form");
    }
  }
);

export const getSearchWarrantData = createAsyncThunk<
  any,
  { searchWarrantId: number; formName: string; navigate: any; searchWarrantList?: any[]; },
  { state: RootState; rejectValue: string }
>(
  "searchWarrant/getSearchWarrantData",
  async (
    { searchWarrantId, formName, navigate, searchWarrantList },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    let swList = searchWarrantList && searchWarrantList.length > 0 ? searchWarrantList : getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "searchwarrantservices/getSearchWarrant/" + searchWarrantId,
        token,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
        dispatch : dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result: any) => {
          let formData = JSON.parse(result.formData);
          if (formName === COMPL_SEARCH_WARRANT || formName === COMPL_SEARCH_WARRANT_WITH_SLASH) {
            dispatch(updateSearchWarrantObject(formData));
            if (ROLE_JUDGE === getState().login.userType) {
              if (formData.hiddenJudgeSignature) {
                dispatch(setSignature(formData.hiddenJudgeSignature));
              } else if (formData.hiddenUserSignature) {
                dispatch(setSignature(formData.hiddenUserSignature));
              }
            } else {
              if (formData.hiddenComplaintSignature) {
                dispatch(setSignature(formData.hiddenComplaintSignature));
              } else if (formData.hiddenUserSignature) {
                dispatch(setSignature(formData.hiddenUserSignature));
              }
            }
            navigate(`/SearchWarrant`);
          } else if (formName === APPLICATION_AND_ORDER) {

           dispatch(updateApplicationAndOrderObject(formData));
            if (ROLE_JUDGE === getState().login.userType) {
              if (formData.hiddenJudgeSignature === undefined || formData.hiddenJudgeSignature === "") {
                dispatch(setSignature(formData.hiddenUserSignature));
              }
              //  else if (formData.hiddenUserSignature) {
              //   dispatch(setSignature(formData.hiddenUserSignature));
              // }
            } else {
              if (formData.hiddenComplaintSignature) {
                dispatch(setSignature(formData.hiddenComplaintSignature));
              } else if (formData.hiddenUserSignature) {
                dispatch(setSignature(formData.hiddenUserSignature));
              }
            }
            navigate(`/ApplicationAndOrder`);
          } else if (formName === BLANK_ORDER) {
            dispatch(updateBlankOrderObject(formData));
            if (ROLE_JUDGE === getState().login.userType) {
              if (formData.hiddenJudgeSignature) {
                dispatch(setSignature(formData.hiddenJudgeSignature));
              } else if (formData.hiddenUserSignature) {
                dispatch(setSignature(formData.hiddenUserSignature));
              }
            } else {
              if (formData.hiddenComplaintSignature) {
                dispatch(setSignature(formData.hiddenComplaintSignature));
              } else if (formData.hiddenUserSignature) {
                dispatch(setSignature(formData.hiddenUserSignature));
              }
            }
            navigate(`/BlankOrder`);
          } else if (formName === INVENTORY) {
            dispatch(updateInventoryObject(formData));

            if (getState().login.userType === ROLE_JUDGE) {
              if (getState().inventory.inventoryObject.hiddenJudgeSignature === undefined) {
                dispatch(setSignature(getState().inventory.inventoryObject.hiddenUserSignature));
              } else {
                dispatch(setSignature(formData.hiddenJudgeSignature));
              }
            } else if (getState().inventory.inventoryObject.hiddenComplaintSignature === undefined) {
              dispatch(setSignature(getState().inventory.inventoryObject.hiddenUserSignature));
            } else {
              dispatch(setSignature(formData.hiddenComplaintSignature));
            }
            navigate(`/Inventory`);
          } else if (formName === AMENDED_INVENTORY) {
            dispatch(updateAmendInventoryObject(formData));
            if (getState().login.userType === ROLE_JUDGE) {
              if (getState().amendInventory.amendInventoryObject.hiddenJudgeSignature === undefined) {
                dispatch(setSignature(getState().amendInventory.amendInventoryObject.hiddenUserSignature));
              } else {
                dispatch(setSignature(formData.hiddenJudgeSignature));
              }
            } else if (getState().amendInventory.amendInventoryObject.hiddenComplaintSignature === undefined) {
              dispatch(setSignature(getState().amendInventory.amendInventoryObject.hiddenUserSignature));
            } else {
              dispatch(setSignature(formData.hiddenComplaintSignature));
            }
            navigate(`/AmendInventory`);
          }
        });

        return res;
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching search warrant data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching search warrant data"
      );
    }
  }
);

export const previewOrder = createAsyncThunk<
  any,
  {
    formId: number;
    formName: string;
    formObject: any;
  },
  { state: RootState; rejectValue: string }
>(
  "commonForm/previewOrder",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { formId, formName, formObject } = data;
    let { token } = getState().login;

    let swFormId = formId;
    if (formId === undefined || formId === null) {
      swFormId = 0;
    }
    try {
      dispatch(showHideLoadingGif(true));
      const response: any = await doFetch({
        url:
          "searchwarrantservices/searchwarrantPreview/" +
          swFormId +
          "/" +
          formName,
        token,
        type: "POST",
        body: formObject,
        dispatch: dispatch
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        const blob = await response.blob();

        if (blob && blob.size > 0) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = formName + ".pdf";
          a.click();
          // show Preview Popup
        } else {
          //show Error Popup
          console.log("show Error Popup");
        }
      } 
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while generating pdf. Try again later."
      );
    }
  }
);

export const previewDraftingOrder = createAsyncThunk<
  any,
  {
    formId: number;
    formName: string;
    formObject: any;
  },
  { state: RootState; rejectValue: string }
>(
  "commonForm/previewDraftingOrder",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { formId, formName, formObject } = data;
    let { token } = getState().login;

    let swFormId = formId;
    if (formId === undefined || formId === null) {
      swFormId = 0;
    }

    try {
      const response: any = await doFetch({
        url:
          "searchwarrantservices/previewdraftingform/" +
          swFormId +
          "/" +
          formName,
        token,
        type: "POST",
        body: formObject,
        dispatch: dispatch
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        const blob = await response.blob();

        if (blob && blob.size > 0) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = formName + ".pdf";
          a.click();
          // show Preview Popup
          console.log("show Preview Popup");
        } else {
          //show Error Popup
          console.log("show Error Popup");
        }
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while generating pdf. Try again later."
      );
    }
  }
);


export const previewEcomplaint = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    isRedacted: boolean;
    callingFrom: any;
  },
  { state: RootState; rejectValue: string }
>(
  "commonForm/previewEcomplaint",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, isRedacted, callingFrom } = data;
    let { token } = getState().login;

    let queryParam = "";
    queryParam += `eComplaintId=${ecomplaintId}&`;
    if (isRedacted) queryParam += `isRedacted=${true}&`;
    queryParam += `callingFrom=${callingFrom}&`;

    console.log(queryParam);
    try {
      const response: any = await doFetch({
        url: `ecomplaintservices/ecomplaintPreview?${queryParam}`,
        token,
        type: "POST",
        dispatch: dispatch
      });
      if (response.ok) {
        let disposition = response.headers.get("Content-Disposition");
        let fileName = extractFileName(disposition);
        response.blob().then((blob: any) => {
          if (blob && blob.size > 0) {
            const url = URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            ////////
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName + ".pdf";
            a.click();
            // show Preview Popup
            console.log("show Preview Popup");
          } else {
            //show Error Popup
            console.log("show Error Popup");
          }
        });
        return response.json();
      }

      const error = await response.text();
      return rejectWithValue(
        error || "Error while generating pdf. Try again later."
      );
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Error while generating pdf. Try again later."
      );
    }
  }
);

export const previewArrestWarrent = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    ecFormId: number;
    formData: any;
  },
  { state: RootState; rejectValue: string }
>(
  "commonForm/previewArrestWarrent",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, ecFormId, formData } = data;
    let { token } = getState().login;

    let queryParam = "";
    queryParam += `eComplaintId=${ecomplaintId}&ecFormId=${ecFormId}&`;

    console.log(queryParam);
    try {
      const response: any = await doFetch({
        url: `ecomplaintservices/arrestWarrantPreview?${queryParam}`,
        token,
        body: formData,
        type: "POST",
        dispatch: dispatch
      });
      if (response.ok) {
        let disposition = response.headers.get("Content-Disposition");
        let fileName = extractFileName(disposition);

        response.blob().then((blob: any) => {
          if (blob && blob.size > 0) {
            const url = URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            ////////
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName + ".pdf";
            a.click();
            // show Preview Popup
            console.log("show Preview Popup");
          } else {
            //show Error Popup
            console.log("show Error Popup");
          }
        });
        return response.json();
      }

      const error = await response.text();
      return rejectWithValue(
        error || "Error while generating pdf. Try again later."
      );
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Error while generating pdf. Try again later."
      );
    }
  }
);

export const previewPreTrialRelease = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    ecFormId: number;
    formData: any;
  },
  { state: RootState; rejectValue: string }
>(
  "commonForm/previewPreTrialRelease",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, ecFormId, formData } = data;
    let { token } = getState().login;

    let queryParam = "";
    queryParam += `eComplaintId=${ecomplaintId}&ecFormId=${ecFormId}&`;

    console.log(queryParam);
    try {
      const response: any = await doFetch({
        url: `ecomplaintservices/preTrialReleasePreview?${queryParam}`,
        token,
        body: formData,
        type: "POST",
        dispatch: dispatch
      });
      if (response.ok) {
        let disposition = response.headers.get("Content-Disposition");
        let fileName = extractFileName(disposition);

        response.blob().then((blob: any) => {
          if (blob && blob.size > 0) {
            const url = URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            ////////
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName + ".pdf";
            a.click();
            // show Preview Popup
            console.log("show Preview Popup");
          } else {
            //show Error Popup
            console.log("show Error Popup");
          }
        });
        return response.json();
      }

      const error = await response.text();
      return rejectWithValue(
        error || "Error while generating pdf. Try again later."
      );
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Error while generating pdf. Try again later."
      );
    }
  }
);

export const probableCauseStatementPreview = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    ecFormId: number;
    formData: any;
  },
  { state: RootState; rejectValue: string }
>(
  "commonForm/probableCauseStatementPreview",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, ecFormId, formData } = data;
    let { token } = getState().login;
    let queryParam = "";
    queryParam += `eComplaintId=${ecomplaintId}&ecFormId=${ecFormId}&`;

    try {
      const response: any = await doFetch({
        url: `ecomplaintservices/probableCauseStatementPreview?${queryParam}`,
        token,
        body: formData,
        type: "POST",
        dispatch : dispatch
      });
      if (response.ok) {
        let disposition = response.headers.get("Content-Disposition");
        let fileName = extractFileName(disposition);

        response.blob().then((blob: any) => {
          if (blob && blob.size > 0) {
            const url = URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName + ".pdf";
            a.click();
            // show Preview Popup
            console.log("show Preview Popup");
          } else {
            //show Error Popup
            console.log("show Error Popup");
          }
        });
        return response.json();
      }

      const error = await response.text();
      return rejectWithValue(
        error || "Error while generating pdf. Try again later."
      );
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Error while generating pdf. Try again later."
      );
    }
  }
);

export const summonsPreview = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    ecFormId: number;
    formData: any;
  },
  { state: RootState; rejectValue: string }
>(
  "commonForm/summonsPreview",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, ecFormId, formData } = data;
    let { token } = getState().login;
    let queryParam = "";
    queryParam += `eComplaintId=${ecomplaintId}&ecFormId=${ecFormId}&`;
    try {
      const response: any = await doFetch({
        url: `ecomplaintservices/summonsPreview?${queryParam}`,
        token,
        body: formData,
        type: "POST",
        dispatch : dispatch
      });
      if (response.ok) {
        let disposition = response.headers.get("Content-Disposition");
        let fileName = extractFileName(disposition);

        response.blob().then((blob: any) => {
          if (blob && blob.size > 0) {
            const url = URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName + ".pdf";
            a.click();
            // show Preview Popup
            console.log("show Preview Popup");
          } else {
            //show Error Popup
            console.log("show Error Popup");
          }
        });
        return response.json();
      }

      const error = await response.text();
      return rejectWithValue(
        error || "Error while generating pdf. Try again later."
      );
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Error while generating pdf. Try again later."
      );
    }
  }
);

export const victimInfoPreview = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    ecFormId: number;
    formData: any;
  },
  { state: RootState; rejectValue: string }
>(
  "commonForm/victimInfoPreview",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, ecFormId, formData } = data;
    let { token } = getState().login;
    let queryParam = "";
    queryParam += `eComplaintId=${ecomplaintId}&ecFormId=${ecFormId}&`;

    try {
      const response: any = await doFetch({
        url: `ecomplaintservices/victimInfoPreview?${queryParam}`,
        token,
        body: formData,
        type: "POST",
        dispatch: dispatch
      });
      if (response.ok) {
        let disposition = response.headers.get("Content-Disposition");
        let fileName = extractFileName(disposition);

        response.blob().then((blob: any) => {
          if (blob && blob.size > 0) {
            const url = URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName + ".pdf";
            a.click();
            // show Preview Popup
            console.log("show Preview Popup");
          } else {
            //show Error Popup
            console.log("show Error Popup");
          }
        });
        return response.json();
      }

      const error = await response.text();
      return rejectWithValue(
        error || "Error while generating pdf. Try again later."
      );
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Error while generating pdf. Try again later."
      );
    }
  }
);

//KHL PR 11264 09/01/24 - START
export const previewComplaintReceipt = createAsyncThunk<
any,
{
  ecomplaintId: number;
  formType: string;
  formId: number;
},
{ state: RootState; rejectValue: string }
>(
"commonForm/previewComplaintReceipt",
async (data, { getState, rejectWithValue }) => {
  const { ecomplaintId, formType, formId } = data;
  let { token } = getState().login;
  let queryParam = "";
  queryParam += `eComplaintId=${ecomplaintId}&formType=${formType}&formId=${formId}&`;
  try {
    const response: any = await doFetch({
      url: `docs/ecomplaintreceiptservice/previewComplaintReceipt?${queryParam}`,
      token,
      type: "POST"
    });
    if (response.ok) {
      let disposition = response.headers.get("Content-Disposition");
      let fileName = extractFileName(disposition);

      response.blob().then((blob: any) => {
        if (blob && blob.size > 0) {
          const url = URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName + ".pdf";
          a.click();
          // show Preview Popup
          console.log("show Preview Popup");
        } else {
          //show Error Popup
          console.log("show Error Popup");
        }
      });
      return response.json();
    }

    const error = await response.text();
    return rejectWithValue(
      error || "Error while generating pdf. Try again later."
    );
  } catch (error: any) {
    return rejectWithValue(
      error.message || "Error while generating pdf. Try again later."
    );
  }
}
);
//KHL PR 11264 09/01/24 - END

const commonFormSlice = createSlice({
  name: "commonForm",
  initialState,
  reducers: {
    updateElementValuecommonFormReducer: updateElementValue,
    resetcommonFormData: (state: commonFormState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateForm.pending, (state) => {
        state.status = "loading";
        state.fetchingError = undefined;
      })
      .addCase(updateForm.fulfilled, (state, action) => {
        state.status = "success";
      //  state.formData = JSON.parse(action.payload.formData);
      })
      .addCase(updateForm.rejected, (state) => {
        state.status = "error";
        state.fetchingError = undefined;
      })

      .addCase(getSearchWarrantData.pending, (state) => {
        state.status = "loading";
        state.fetchingError = undefined;
      })
      .addCase(getSearchWarrantData.fulfilled, (state, action) => {
        state.status = "success";
        if (action.payload && action.payload.formData) {
          //let warrantForm = JSON.parse(action.payload.formData);
          state.formData = JSON.parse(action.payload.formData);
        }
      })
      .addCase(getSearchWarrantData.rejected, (state, action) => {
        state.status = "error";
        state.fetchingError = undefined;
      })

      //preview Form
      .addCase(previewOrder.pending, (state) => {
        state.previewStatus = "loading";
      })
      .addCase(previewOrder.fulfilled, (state) => {
        state.previewStatus = "success";
      })
      .addCase(previewOrder.rejected, (state, action) => {
        state.previewStatus = "error";
        state.fetchingError = action.payload;
      })

      //preview drafting Form
      .addCase(previewDraftingOrder.pending, (state) => {
        state.previewStatus = "loading";
      })
      .addCase(previewDraftingOrder.fulfilled, (state) => {
        state.previewStatus = "success";
      })
      .addCase(previewDraftingOrder.rejected, (state, action) => {
        state.previewStatus = "error";
        state.fetchingError = action.payload;
      })

      //preview Ecomplaint
      .addCase(previewEcomplaint.pending, (state) => {
        state.previewStatus = "loading";
      })
      .addCase(previewEcomplaint.fulfilled, (state) => {
        state.previewStatus = "success";
      })
      .addCase(previewEcomplaint.rejected, (state, action) => {
        state.previewStatus = "error";
        state.fetchingError = action.payload;
      })

      //preview ArrestWarrent
      .addCase(previewArrestWarrent.pending, (state) => {
        state.previewStatus = "loading";
      })
      .addCase(previewArrestWarrent.fulfilled, (state) => {
        state.previewStatus = "success";
      })
      .addCase(previewArrestWarrent.rejected, (state, action) => {
        state.previewStatus = "error";
        state.fetchingError = action.payload;
      })

      //preview PreTrialRelese
      .addCase(previewPreTrialRelease.pending, (state) => {
        state.previewStatus = "loading";
      })
      .addCase(previewPreTrialRelease.fulfilled, (state) => {
        state.previewStatus = "success";
      })
      .addCase(previewPreTrialRelease.rejected, (state, action) => {
        state.previewStatus = "error";
        state.fetchingError = action.payload;
      })
      //preview Probablycause statement
      .addCase(probableCauseStatementPreview.pending, (state) => {
        state.previewStatus = "loading";
      })
      .addCase(probableCauseStatementPreview.fulfilled, (state) => {
        state.previewStatus = "success";
      })
      .addCase(probableCauseStatementPreview.rejected, (state, action) => {
        state.previewStatus = "error";
        state.fetchingError = action.payload;
      });
  },
});

export const { updateElementValuecommonFormReducer, resetcommonFormData } =
  commonFormSlice.actions;

export const useCommonFormReducer = () =>
  useAppSelector((state) => state.commonForm);

export default commonFormSlice.reducer;
