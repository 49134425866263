import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { RiQuestionLine, RiCheckFill, RiCloseFill } from "react-icons/ri";

interface OptionPopupProps {
  modalClass?: string;
  show: boolean;
  header: string;
  onHideFunc?: () => void;
  children?: React.ReactNode;
  classStyle?: string;
  questionMarkVisible ?:boolean;
  buttons: any;
}

const OptionPopup: React.FC<OptionPopupProps> = (props) => {
  const { modalClass, show, header, onHideFunc, children, buttons, classStyle, questionMarkVisible } = props;

  const buttonsUIs = [];
  buttonsUIs.push(...buttons.map((button: any) => {
    return <Col>
        <Button variant={button.variant} onClick={button.onClick} className="w-100">
            {button.name}
        </Button>
    </Col>;
  }));

  const topHeader = [];
  if (header && header.trim().length > 0) {
    if (onHideFunc) {
      topHeader.push(<Modal.Header closeButton>
        <b>{header}</b>
      </Modal.Header>);
    } else {
      topHeader.push(<Modal.Header>
        <b>{header}</b>
    </Modal.Header>);
    }
  }

  return (
    <Modal
      show={show}
      dialogClassName = {modalClass ? modalClass : "modal-30w"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHideFunc && onHideFunc()}
      backdrop="static"
    >
      {topHeader}
      <Modal.Body>
        {!questionMarkVisible ?
          <Row>
            <Col className="text-center">
              <h1>
                <RiQuestionLine className="text-danger" />
              </h1>
            </Col>
          </Row> : ""
        }
        <Row>
          <Col className={ classStyle ? classStyle : "text-center mt-2"}>
            {children}
          </Col>
        </Row>
        <hr />
        <Row className="g-2">
            { buttonsUIs }
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default OptionPopup;
