import { doFetch } from "../utils/doFetch";

export const getCountiesByUserRole = async ({
    token
}: {
    token?: string;
}) => {
    const response = await doFetch({
        url: `countieservices/loggedInUserCounties`,
        token,
    });
    return response;
};

export const getECCountiesByUserRole = async ({
    token
}: {
    token?: string;
}) => {
    const response = await doFetch({
        url: `countieservices/loggedInUserECCounties`,
        token,
    });
    return response;
};