import { doFetch } from "../utils/doFetch";

export const getAgenciesByUserRole = async ({
    token
}: {
    token?: string;
}) => {
    const response = await doFetch({
        url: `agencyservices/loggedInUserAgencies`,
        token,
    });
    return response;
};

export const getECAgenciesByUserRole = async ({
    token
}: {
    token?: string;
}) => {
    const response = await doFetch({
        url: `agencyservices/loggedInUserECAgencies`,
        token,
    });
    return response;
};

export const getLoggedInUserLFCAgencies = async ({
    token
}: {
    token?: string;
}) => {
    const response = await doFetch({
        url: `agencyservices/getLoggedInUserAgenciesForLFC`,
        token,
    });
    return response;
};

export const getAgencyListByCounty = async ({
    token,
    countyId
}: {
    token?: string;
    countyId?: number
}) => {
    const response = await doFetch({
        url: `agencyservices/getAgencyListByCounty/${countyId}?jwtToken=Bearer ${token}`,
    });
    return response;
};