import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { getTemplateFieldValueByFieldName } from "../../utils/common";


interface templateListState {
    templateList: string[];
    currentPage: number;
    totalPages: number;
    totalRecords: number;
    
    templateError: string;
    status: LoadingType;
  
    countyId : number;
    template : string;
    listStatus : string;
  }
  
  const initialState = {
    templateList: [],
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
    templateError: "",
    status: "idle",
  
    countyId : 0,
    template : "-- ALL --",
    listStatus :""
  } as templateListState;


  export const templateListData = createAsyncThunk<
  any,
  {
    countyId?: number;
    templateName?: string;
    status?: string;
    stateId?: number;
    pageNo?: number;
  },
  {
    state: RootState;
  }
>(
  "templateList/templateListData",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { countyId, templateName, status = "all", pageNo = 0 } = data;

    let queryParam = `pageNo=${pageNo}&status=${status}`;
    if (countyId) queryParam += `&countyIdParam=${countyId}`;
    if (templateName) queryParam += `&templateName=${templateName}`;

    dispatch(showHideLoadingGif(true));
    try {
      let { token } = getState().login;
      const response = await doFetch({
        url: `templateservices/warrantTemplates?${queryParam}`,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(response.status);
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);


const templateListSlice = createSlice({
    name: "templatesList",
    initialState,
    reducers: {
      updateElementValuetemplateListReducer: updateElementValue,
      resetTemplateListData: (state: templateListState) => {
        return {
          ...state,
          ...initialState,
        };
      },
      updatetemplateListReducer : (state : templateListState, action) => {
        return {
          ...state,
          template : action.payload.templateName,
          countyId : action.payload.countyId,
          listStatus : action.payload.status,
        };
      },
    },
    extraReducers(builder) {
      builder  
        // template List
        .addCase(templateListData.pending, (state) => {
          state.status = "loading";
          state.templateError = "";
        })
        .addCase(templateListData.fulfilled, (state, action) => {
          state.status = "success";
          state.templateList = action.payload.warrantTemplateList;
          state.totalPages = action.payload.totalPages;
          state.totalRecords = action.payload.totalRecords;
          state.currentPage = action.payload.currentPage;
        })
        .addCase(templateListData.rejected, (state, action) => {
          state.status = "error";
          state.templateError = "";
        })
    },
  });
  

export const {
    updateElementValuetemplateListReducer,
    resetTemplateListData,
    updatetemplateListReducer
} = templateListSlice.actions;
  
export const useTemplateListReducer = () =>
  useAppSelector((state) => state.templateList);
  
export default templateListSlice.reducer;