import React from "react";
import { Col, Form, FormCheckProps } from "react-bootstrap";

export type CheckBoxComponentProps = {
  label?: React.ReactNode;
  isError?: boolean;
  className?: string;
  value?: boolean;
} & FormCheckProps;

const CheckBoxComponent: React.FC<CheckBoxComponentProps> = (props) => {
  const { label, className, isError, value, ...rest } = props;
  return (
    <Form.Group className={className}>
      <Col
        className={isError ? "p-1 me-2 border-danger border rounded" : "p-1"}
      >
        <Form.Check {...rest} label={label} value={value} />
      </Col>
    </Form.Group>
  );
};

export default CheckBoxComponent;
