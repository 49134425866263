import React , { useCallback,useEffect }from "react";
import Header from "../../common/Header";
import { RiArrowRightLine, RiCloseFill } from "react-icons/ri";
import BreadcrumbComponent from "../../common/BreadcrumbComponent";
import FormComponent from "../../common/FormComponent";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch } from "../../../hooks/hooks";
import { listSearchWarrantType,useTemplateReducer } from "../../../redux/slice/templateSlice";
import { BLANK_ORDER,
        ROLE_ADMINISTRATOR,
        ROLE_AGENCY_ADMIN,
        ROLE_ATTORNEY_GENERAL,
        ROLE_COUNTY_ADMIN, ROLE_LOCAL_PROSECUTOR, ROLE_POLICE_OFFICER, ROLE_SA_ADMIN, ROLE_STATES_ATTORNEY, SEARCH_WAR_ASSIGNED_TO_AG_STATUS,
        SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS,
        SEARCH_WAR_ASSIGNED_TO_LP_STATUS, SEARCH_WAR_ASSIGNED_TO_SA_STATUS,
        SEARCH_WAR_INCOMPLETE_STATUS, SEARCH_WAR_LOCKED_BY_AG_STATUS,
        SEARCH_WAR_LOCKED_BY_JUDGE_STATUS,
        SEARCH_WAR_LOCKED_BY_LP_STATUS, SEARCH_WAR_LOCKED_BY_SA_STATUS,
        SEARCH_WAR_OFFICER_PENDING_STATUS } from "../../../constants/common";
import {getWarrantTemplateListWithData} from "../../../redux/slice/searchWarrantSlice";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";
import ConfirmPopup from "../../../components/common/ConfirmPopup";
import { useBlankOrderReducer,getTemplateDataForBlankOrder,updateElementObjectValue } from "../../../redux/slice/blankOrderSlice";
import { updateForm } from "../../../redux/slice/commonFormSlice";
import {setSignature, useSignPanelReducer} from "../../../redux/slice/signPanelSlice";
import {useLoginReducer} from "../../../redux/slice/loginSlice";
import {ROLE_JUDGE} from "../../../constants/common";
import { getValidationError, validateSignaturePixel } from "../../common/CommonUtil";
import { formatName, updateTagValue } from "../../../utils/common";
import { getSWCaseDetail, useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";
import FormFiller from "../../common/FormFiller";
import ButtonComponent from "../../common/ButtonComponent";
import { useDashboardReducer, updateElementValueDashboardReducer } from "../../../redux/slice/dashboardSlice";

let defaultSwSchema = yup.object().shape({
  searchWarrantType: yup.string().required("Order type is required."),
  txtAppears: yup.string().required("Officer/Employee Name is required."),
  badgeNo: yup.string().required("Badge No in required."),
  orderTitle: yup.string().nullable().required("Order Title in required.").test('validator-ck-editor', function (value) {
    const errMessage = getValidationError("ORDER TITLE", value);
    if(errMessage.length > 0) {
      return this.createError({
        message: errMessage,
      });
    }
    else {
      return true;
    }
  }),
  txtOrder: yup.string().nullable().required("Order Info in required.").test('validator-ck-editor', function (value) {
   const errMessage = getValidationError("ORDER INFO", value);
   if(errMessage.length > 0) {
     return this.createError({
      message: errMessage,
     });
   }
   else {
    return true;
   }
  }),
  //sign: yup.string().nullable().required("Signature required."),
  // sign: yup.string().when([], {
  //   is: (signatureCanvasVisible)
  // })
  
});

interface BlankOrderProps {}

let fieldTitles =['Order Title','Order Information Below'];
let names = ['orderTitle','txtOrder'];

const BlankOrder: React.FC<BlankOrderProps> = (props) => {
  const navigate = useNavigate();
  
  const dispatch = useAppDispatch();
  const { swTypeList } = useTemplateReducer();
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [searchWarrantType, setSearchWarrantType] = React.useState("");
  const [blankOrderTemplateId, setBlankOrderTemplateId] = React.useState(0);
  const [confirmPopupData, setconfirmPopupData] = React.useState(false);
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const blankOrderReducer = useBlankOrderReducer();
  const caseDetailReducer = useCaseDetailReducer();
  const loginReducer = useLoginReducer();
  const signPanelReducer = useSignPanelReducer();
  const [signConfirmationModalShow , setsignConfirmationModalShow ] = React.useState(false);
  const [signatureCanvasVisible , setSignatureCanvasVisible ] = React.useState(false);
  const [complainantNameFooter, setComplainantNameFooter] = React.useState(""); 
  const [whoSign, setWhoSign] = React.useState("");
  const [swTest, setSwText] = React.useState("");

  const [showFormFiller, setShowFormFiller] = React.useState(false);
  const [fieldTitleValues, setFieldTitleValues] = React.useState<String[]>([]);
  const dashboardReducer = useDashboardReducer();
  
  useEffect(() => {
    if (blankOrderReducer.blankOrderObj.templateId >0){
      if (blankOrderReducer.blankOrderObj.orderTitle && blankOrderReducer.blankOrderObj.orderTitle.trim() !== null)
        dispatch(updateElementObjectValue({elementName: "orderTitle", value: updateTagValue(blankOrderReducer.blankOrderObj.orderTitle, blankOrderReducer.blankOrderObj.badgeNo)}))
      if (blankOrderReducer.blankOrderObj.txtOrder && blankOrderReducer.blankOrderObj.txtOrder.trim() !== null)
        dispatch(updateElementObjectValue({elementName: "txtOrder", value: updateTagValue(blankOrderReducer.blankOrderObj.txtOrder, blankOrderReducer.blankOrderObj.badgeNo)}))
    }
  }, [blankOrderReducer.blankOrderObj.badgeNo])

  const handleWarrantTypeValChange = (e: any) => {
  
    let swType = e.label;
    if(blankOrderReducer.blankOrderObj.searchWarrantType==null || swType === "-- Select --"){
      dispatch(
        updateElementObjectValue({
          elementName: "searchWarrantType",
          value: swType,
        })
      );
      dispatch(
        updateElementObjectValue({
          elementName: "templateId",
          value: e.value,
        })
      );
      dispatch(
        getTemplateDataForBlankOrder({
          countyId: blankOrderReducer.blankOrderObj.countyID,
          warrantForm: BLANK_ORDER,
          templateName: swType,
        })
      );
    }
    else{
    let confirmText = (
      <>
        <label>
          Data entered in all fields of this form will be replaced using the
          template - {swType}{" "}
        </label>{" "}
        <label>Do you want to continue?</label>
      </>
    );
    setSearchWarrantType(swType);
    setBlankOrderTemplateId(e.value);
    setconfirmText(confirmText);
    setconfirmPopupData(true);
    }

    if (loginReducer.userType === ROLE_JUDGE && blankOrderReducer.blankOrderObj.hiddenJudgeSignature === null) {
      dispatch(setSignature(blankOrderReducer.blankOrderObj.hiddenUserSignature));
    } else {
      if (blankOrderReducer.blankOrderObj.hiddenComplaintSignature === null) {
        dispatch(setSignature(blankOrderReducer.blankOrderObj.hiddenUserSignature))
      }
    }
    
  };
  const handleChangeObjectValue = (event: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  const handleChangeObjectValueForCkEditor = (name: string, editor: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: name,
        value: editor.getData(),
      })
    );
  };
  function prepareMessage(msg:any) {
    let buff = [];
    if (msg && msg.trim().length > 0) {
        let arr = msg.split("<br>");
        let line = "";
        for (let i = 0; i < arr.length; i++) {
            line = arr[i];
            buff.push(<div>{line}</div>);
        }
    }
    return buff;
  }

  const cancelSignConfirmModal = () => {
    setsignConfirmationModalShow(false);
  }

  const acceptSignConfirmModal = () => {
    setsignConfirmationModalShow(false);
    updateBlankOrderOnNext();
    
  }

  const handleConfirmation = () => {
    setconfirmPopupData(false);
    dispatch(
      updateElementObjectValue({
        elementName: "searchWarrantType",
        value: searchWarrantType,
      })
    );
    dispatch(
      updateElementObjectValue({
        elementName: "templateId",
        value: blankOrderTemplateId,
      })
    );
    dispatch(
      getTemplateDataForBlankOrder({
        countyId: blankOrderReducer.blankOrderObj.countyID,
        warrantForm: BLANK_ORDER,
        templateName: searchWarrantType,
      })
    );
  };

  const updateBlankOrderOnNext=() =>{
    const formObj = {...blankOrderReducer.blankOrderObj};
    if (ROLE_JUDGE === loginReducer.userType) {
      //  dispatch(updateElementObjectValue({elementName:"hiddenJudgeSignature", value:signPanelReducer.sign}));
      //  formObj.hiddenJudgeSignature = signPanelReducer.sign;
    } else {
       dispatch(updateElementObjectValue({elementName:"hiddenComplaintSignature", value:signPanelReducer.sign}));
       formObj.hiddenComplaintSignature = signPanelReducer.sign;
    }
    dispatch(
      updateForm({
        formId:formObj.searchWarrantId,
        formObject: formObj,
        formName : BLANK_ORDER,
        navigate : navigate
      })
    );
  };

  const nextClick = async () => {

    try {
      let Obj={
        searchWarrantType: blankOrderReducer.blankOrderObj.searchWarrantType,
        txtAppears: blankOrderReducer.blankOrderObj.txtAppears,
        badgeNo: blankOrderReducer.blankOrderObj.badgeNo,
        orderTitle: blankOrderReducer.blankOrderObj.orderTitle,
        txtOrder: blankOrderReducer.blankOrderObj.txtOrder,
        sign: signPanelReducer.sign,
      }
      await defaultSwSchema.validate(Obj, {
        abortEarly: false,
      });
      let isValidateSignPixel = validateSignaturePixel(signatureCanvasVisible, signPanelReducer.signThreshold);
      if(!isValidateSignPixel){
       setsignConfirmationModalShow(true);
     }
     else{
      updateBlankOrderOnNext()
     }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  useEffect(() => {
    let dataObj = {
      countyId: caseDetailReducer.countyId,
      templateType: BLANK_ORDER,
    };
    dispatch(listSearchWarrantType(dataObj));
    dispatch(getWarrantTemplateListWithData(dataObj));
  }, []);

  useEffect(() => {
    const { userProfileId, userType } = loginReducer;
    let loggedInUserId = userProfileId;
    let loggedInUserRole = userType;
    let {formStatus, creatorId, templateId} = blankOrderReducer.blankOrderObj;
    let userName = formatName(loginReducer.firstName, loginReducer.lastName, "");
    setWhoSign("SIGNATURE OF COMPLAINANT");
    setSwText("Complainant Signature will be only applied after the content of this blank order is sworn before a judge");

   
    if(ROLE_ADMINISTRATOR === loggedInUserRole || ROLE_COUNTY_ADMIN === loggedInUserRole) {
      if (formStatus === SEARCH_WAR_INCOMPLETE_STATUS || formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS
        || formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS
        || formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS
        || formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) {
        setSignatureCanvasVisible(true);
        console.log(userName);
        if(userName && userName.trim().length > 0) {
          setComplainantNameFooter(userName);
         
        }
    }
    if (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS
      || formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS || formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS
      || formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS
      || formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS || formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
      || creatorId !== loggedInUserId) {
        setSignatureCanvasVisible(false);
  }
      
    } else {

      if (loggedInUserRole === ROLE_POLICE_OFFICER || loggedInUserRole === ROLE_AGENCY_ADMIN) {
        setSignatureCanvasVisible(true);
        if (userName && userName.trim().length > 0) {
            if (creatorId === loggedInUserId) {
                //textBoxAppear.setText(userName);
            } else {
                //textBoxAppear.setText(caseDetail.getCreatorName());
            }
            setComplainantNameFooter(userName);
        }
    }
    if (creatorId === loggedInUserId) {
      if (loggedInUserRole === ROLE_POLICE_OFFICER || loggedInUserRole === ROLE_STATES_ATTORNEY || loggedInUserRole === ROLE_SA_ADMIN
          || loggedInUserRole === ROLE_ATTORNEY_GENERAL || loggedInUserRole === ROLE_LOCAL_PROSECUTOR
          || loggedInUserRole === ROLE_AGENCY_ADMIN) {
              setSignatureCanvasVisible(true);
          if (userName && userName.trim().length > 0) {
              setComplainantNameFooter(userName);
          }
      }
  }					
        
  if (creatorId !== loggedInUserId) {
      setSignatureCanvasVisible(false);
  }

    }
  //   if (ROLE_POLICE_OFFICER === loggedInUserRole ){
  //     setSignatureCanvasVisible(false); 
     
  // }
    
  }, [blankOrderReducer.blankOrderObj.searchWarrantId]);

  const cancelClick = () =>{
    dispatch(getSWCaseDetail(({searchWarrantId: caseDetailReducer.searchWarrantId, action:"cancelForm", navigate: navigate})));
  }

  const  onBreadcrumbClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/"+dashboardReducer.PreviousURLSW.replace(/^.*\/\/.*?\//, ''), itemName: "Warrant List", itemFunction: onBreadcrumbClick  },
    { itemLink: "/CaseDetails", itemName: "Existing Forms" },
  ];

  const formFillerClick = () =>{
    let array = [
      blankOrderReducer.blankOrderObj.orderTitle, blankOrderReducer.blankOrderObj.txtOrder
    ];
    setShowFormFiller(true);
    setFieldTitleValues(array);
  }

  const updateFieldFromWizard = (name: string, value: string) =>{
    dispatch(updateElementObjectValue({elementName:name, value: value}));
  }
 
  const data = [
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: "STATE OF ILLINOIS",
        className: "fw-bold text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h5",
        value: (
          <>
            UNITED STATES OF AMERICA
            <br />
            {
              blankOrderReducer.blankOrderObj
                .lblHeadingInTheCircuitSW
            }
          </>
        ),
        className: "fw-bold text-center",
      },
      colSize: 6,
    },
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: blankOrderReducer.blankOrderObj.lblHeadingCountySW,
        className: "fw-bold text-end text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
    },

    {
      type: "textInput",
      props: {
        type: "text",
        value: blankOrderReducer.blankOrderObj.txtAgency,
        //onChange: handleChangeValueForObject,
        name: "agencyName",
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h4",
        value: "Blank Order",
        className: "fw-bold text-center text-danger",
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        value: blankOrderReducer.blankOrderObj.txtCaseNumber,
       // onChange: handleChangeValueForObject,
        name: "txtCaseNumber",
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: 0 },
          ...swTypeList.map((swtype: any) => {
            return {
              label: swtype.templateName,
              value: swtype.templateId,
            };
          }),
        ],
          isDefaultLabel : true,
          onChange: handleWarrantTypeValChange,
          isError: errorKeys.includes("searchWarrantType"),
          placeholder: "-- Select --",
          value: swTypeList
              .map((swType: any) => {
                return {
                  label: swType.templateName,
                  value:swType.templateId
                };
              })
              .find((item) => {
                if((item.label = 
                   blankOrderReducer.blankOrderObj.searchWarrantType
                ))
                return {
                  label: item.label,
                  value: item.value,

                }
              })

      },
      colSize: 4,
      label: "Order Type :",
      inLine: true,
    },
    {
      type: "htmlTag",
      props: {
        type: "div",
        value: blankOrderReducer.blankOrderObj.searchWarrantType==null?"*Note: Please select Order type.":"",
        className: "text-padding",
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        onChange: handleChangeObjectValue,
        placeholder: "Officer/Employee Name",
        name: "txtAppears",
        isError: errorKeys.includes("txtAppears"),
        value: blankOrderReducer.blankOrderObj.txtAppears,
        disabled: true,
      },
      colSize: 4,
      label: "Officer/Employee Name :",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        onChange: handleChangeObjectValue,
        value: blankOrderReducer.blankOrderObj.badgeNo,
        placeholder: "Badge",
        isError: errorKeys.includes("badgeNo"),
        name: "badgeNo",

      },
      colSize: 2,
      label: "Badge/Employee #:",
      inLine: true,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
      emptyCol: 10,
    },
  ];

  let formField = [
    {
      type: 'formButton',
      props: {
        children: "Form Filler Wizard",
        onClick: formFillerClick,
      },
      inLine: true,
      colSize: 2,
    },
    {
      type: "htmlTag",
      props: {
        value: "Order Title :",
      },
      colSize: 10,
    },
    {
      type: "editor",
      props: {data: blankOrderReducer.blankOrderObj.orderTitle
        ? blankOrderReducer.blankOrderObj.orderTitle
        : "",
        onChange: (event: any, editor: any) => {
          handleChangeObjectValueForCkEditor("orderTitle", editor);
        },
        isError: errorKeys.includes("orderTitle")},
      
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <>
            Order Information Below : <br />
            This cause coming before the Court; the Court being fully advised in
            the premises, and having jurisdiction of the subject matter,{" "}
            <b className="text-danger">IT IS HEREBY ORDERED</b> :
          </>
        ),
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {data: blankOrderReducer.blankOrderObj.txtOrder
        ? blankOrderReducer.blankOrderObj.txtOrder
        : "",
        onChange: (event: any, editor: any) => {
          handleChangeObjectValueForCkEditor("txtOrder", editor);
        },
        isError: errorKeys.includes("txtOrder")},
        
      colSize: 12,
    },
    {
      type: "signPanel",
      props: {  isError: errorKeys.includes("sign"),
      className: signatureCanvasVisible ? "d-block" : "d-none",
      appearPersonText : complainantNameFooter,
      whoSign : whoSign,
      swText : swTest,

    
             },
      colSize: 12,
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-primary ms-2",
        children: (
          <>
            Next <RiArrowRightLine />
          </>
        ),
        onClick: nextClick
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-danger ms-2",
        children: (
          <>
             Cancel<RiCloseFill />
          </>
        ),
        onClick: () => {
          cancelClick();
        },
      },
    },
  ]

  return (
    <>
      <Header headerName="Blank Order" />
      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="Blank Order" />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={
            blankOrderReducer.blankOrderObj.searchWarrantType == null ||
            blankOrderReducer.blankOrderObj.searchWarrantType === "-- Select --"
              ? data
              : [...data, ...formField]
          }
          components={[
            {
              type: "formButton",
              node: (props: any) => (
                <div className="text-end">
                <ButtonComponent {...props}>{props.children}</ButtonComponent>
              </div>
                
              ),
            }
          ]}
        />
      </div>
      <ConfirmPopup
        children={confirmText}
        show={confirmPopupData}
        onHide={() => setconfirmPopupData(false)}
        isConfirmation={handleConfirmation}
      />
      <ConfirmPopup
        show={signConfirmationModalShow}
        onHide={cancelSignConfirmModal}
        isConfirmation={acceptSignConfirmModal}
      >
        {prepareMessage("Are you sure the signature you provided is valid?")}
      </ConfirmPopup>
      <FormFiller
       show={showFormFiller}
       onHide = {() => setShowFormFiller(false)}
       fieldTitles={fieldTitles}
       names = {names}
       values = {fieldTitleValues}
       onChangeHandler={updateFieldFromWizard}
      />
    </>
  );
};

export default BlankOrder;
