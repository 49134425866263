import React, { useMemo } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

export type SearchBarComponentProps = {
  totalColumns?: number;
  colSize: number;
  components: {
    type: string;
    node: React.FC<any>;
  }[];
  componentData: {
    type: string;
    props?: any;
    colSize?: number;
    colHide?: boolean;
  }[];
  state: any;
  dispatch: any;
};
/**
 * This component takes the following props:
 * By default, this component will handle state management by using the name as the key.
 * The state can be accessed by using onClick on the button component which has a type of "submit".
 * @param {number} totalColumns - The total number of columns in the search bar (defalut 12).
 * This will be the total number of units that all the columns will take up.
 * @param {number} colSize - The number of columns in each component (default 4).
 * This will be the number of columns that each component will take up based on the total number of columns.
 * This prop will be used for the all the components unless specified in the componentData prop.
 * @param {Array} components - An array of components to be rendered in the search bar.
 * The components will be list of different types of components that can be rendered in the search bar.
 * Should be an array of objects where type is your component name and node will be the component that should be rendered.
 * @param {Array} componentData - An array of data to be passed to the components.
 * The type has to match to the type of component that is being passed in the components prop.
 * The props should be based on the type of component you want to render.
 * The colSize will be the number of columns that the component will take up. This will override colSize for this individual component (default 4)
 * @param {any} state - The state will be holding the search bar values.
 * @param {any} dispatch - The dispatch that will be used to update the state.
 * @returns {JSX.Element}
 *
 */

const SearchBarComponent: React.FC<SearchBarComponentProps> = (props) => {
  const { components, componentData, colSize, totalColumns, state, dispatch } =
    props;
  const componentStore = useMemo(() => {
    let _componentStore: { [key: string]: React.FC<any> } = {};
    for (const element of components) {
      _componentStore = {
        ..._componentStore,
        [element.type]: element.node,
      };
    }
    return _componentStore;
  }, [components]);

  return (
    <Form>
      <Row>
        <Col md={totalColumns || 12} className="mt-3">
          <Card className="shadow">
            <Card.Body>
              <Row className="g-2">
                {componentStore &&
                  componentData.map((element, index) => {
                    if (element.props && element.props.type === "submit") {
                      return (
                        <Col key={index} md={element.colSize || colSize}>
                          {componentStore[element.type]({
                            ...element.props,
                            onClick: (e: any) => {
                              e.preventDefault();
                              if (element.props.onClick) {
                                element.props.onClick(state);
                              }
                            },
                          })}
                        </Col>
                      );
                    } else {
                      if (!element.colHide) {
                        return (
                          <Col md={element.colSize || colSize || 4} key={index}>
                            {componentStore[element.type]({
                              ...element.props,
                              onChange: ({
                                e,
                                value,
                              }: {
                                e: any;
                                value: string;
                              }) => {
                                if (element.props.onChange) {
                                  element.props.onChange(e);
                                }
                                dispatch({
                                  type: "updateValue",
                                  payload: {
                                    name: element.props.name,
                                    value,
                                  },
                                });
                              },
                            })}
                          </Col>
                        );
                      }
                    }
                  })}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};
export default SearchBarComponent;
