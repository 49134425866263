import React, { useCallback, useEffect } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useAppDispatch } from "../../hooks/hooks";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import { useComplaintReducer } from "../../redux/slice/complaintSlice";
import { useCountFormReducer } from "../../redux/slice/countFormSlice";
import { fetchEcViolationList, resetEcViolationData, updateElementObjectValue, useEcViolationReducer } from "../../redux/slice/EcViolationSlice";
import PaginationBar from "../common/Pagination";
import * as yup from "yup";
import { isDateBetween } from "../../utils/common";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import moment from "moment";
import TableComponent from "../common/TableComponent";
import OptionPopup from "../common/OptionsPopup";
import { IoWarning } from "react-icons/io5";

interface ViolationPopupProps {
  show: boolean;
  onHide: () => void;
  selectedViolationData: any;
}
const ViolationPopup: React.FC<ViolationPopupProps> = (props) => {
  const { show, onHide, selectedViolationData } = props;

  const dispatch = useAppDispatch();

  const [referenceCode, setreferenceCode] = React.useState("");
  const [status, setstatus] = React.useState("active");
  const [description, setdescription] = React.useState("");
  const [felony, setfelony] = React.useState(false);
  const [misd, setmisd] = React.useState(false);
  const [inchoate, setinchoate] = React.useState(false);
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const { complaint } = useComplaintMainReducer();
  const { complaintObject } = useComplaintReducer();
  const { ecViolationList, pageStatus, totalPages, currentPage } = useEcViolationReducer();
  const { countFormObject } = useCountFormReducer();
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
    data: "",
  });
  const [content, setContent] = React.useState<React.ReactNode>();

  let defaultValidationSchema = yup.object().shape({
    violation: yup.string().ensure().when('error', { is: true, then: yup.string().required(`This violation is not effective on the recorded date(s) of offense ${moment(countFormObject.offenceDate).format('MM/DD/YYYY')} and cannot be used.`) }),
  });

  const cancelViolationPopup = () => {
    setInitialState();
    onHide();
  }

  const setInitialState = () => {
    setErrorKeys([""])
    handleError([''], []);
    setreferenceCode('');
    setstatus('active');
    setdescription('');
    setfelony(false);
    setmisd(false);
    setinchoate(false);
  }

  const handleConfirm = async (data: any) => {
    try {
      let error = validation(data);
      let Obj = {
        error: error?.error,
        date: error?.dates,
      }
      await defaultValidationSchema.validate(Obj, { abortEarly: false, });
      dispatch(updateElementObjectValue(data))
      selectedViolationData(data)
      cancelViolationPopup()
      dispatch(resetEcViolationData());
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
    setconfirmPopupData({
      show: false,
      data: "",
    });
  };

  const handleKeyChange = (event:any) =>{
    if (event.key === "Enter"){
      getViolationList(0);
    }
  } 

  const handleValueChange = (event: any) => {
    let { name, value } = event.target;
    if (name === 'referenceCode') {
      setreferenceCode(value)

    }
    else if (name === 'status') {
      setstatus(value)
    }
    else if (name === 'description') {
      setdescription(value)
    }
  }

  const handleChekBoxChange = (event: any) => {
    let { name, value } = event.target;
    if (name === 'felony') {
      if(!felony){
        setmisd(felony)
      }
      setfelony(!felony);
    } else if (name === "misd") {
      if(!misd){
        setfelony(misd);
      }
      setmisd(!misd); 
    } else if (name === "inchoate") {
      setinchoate(!inchoate);
    }
  }

  const getViolationList = (pageNo: number) => {

    let Obj = {
      pageNo: pageNo,
      refCode: referenceCode,
      desc: description,
      status: status,
      isFelony: felony,
      isMisd: misd,
      isInchoate: inchoate,
      agencyCode: complaint.municipalityAgencyCode ? complaint.municipalityAgencyCode.trim() : "",
      filingCounty: complaint.countyName,
      prosecutionLevel: complaint.caseProsecutionType,
      primaryOffDate: checkInctiveDate(),
    }
    dispatch(fetchEcViolationList(Obj));
  };

    useEffect(() => {
     if (show)
      getViolationList(0);
   },[status, felony, misd, inchoate]);

   useEffect(() => {
    if (show)
    dispatch(resetEcViolationData());
  },[show]);

  const validation = (data: any) => {
    if (data !== null && Object.keys(data).length > 0) {

      let offenceOccurredDate = countFormObject.offenceDate? countFormObject.offenceDate: "";
      let occurredFromDate = countFormObject.offenceFromDate ? countFormObject.offenceFromDate : "";
      let occurredThroughDate = countFormObject.offenceThroughDate ? countFormObject.offenceThroughDate : "";
      let effectiveDate = data.effectiveDate ? data.effectiveDate : "";
      let inactiveDate = data.inactiveDate ? data.inactiveDate : "";
      let dates = "";
      let error = false;
      if (effectiveDate !== "" && inactiveDate !== "") {
        if (offenceOccurredDate !== null) {
          dates = offenceOccurredDate;
          if (!isDateBetween(offenceOccurredDate, effectiveDate, inactiveDate)) {
            error = true;
          }
        }
        if (occurredFromDate !== "") {
          dates = occurredFromDate + " -";
          if (!isDateBetween(occurredFromDate, effectiveDate, inactiveDate)) {
            error = true;
          }
        }

        if (occurredThroughDate !== "") {
          dates = dates + " " + occurredThroughDate;
          if (!isDateBetween(occurredThroughDate, effectiveDate, inactiveDate)) {
            error = true;
          }
        }
        if (dates.endsWith("-")) {
          dates = dates.substring(0, dates.length - 1)
        }
      }
      else if (inactiveDate !== "") {
        let inactDate = moment(inactiveDate, "YYYY-MM-DD").toDate();
        if (offenceOccurredDate !== "") {
          let offenceOccuDate = moment(offenceOccurredDate, "YYYY-MM-DD").toDate();
          dates = offenceOccurredDate;
          if (offenceOccuDate >= inactDate) {
            error = true;
          }
        }
        if (occurredFromDate !== "") {
          let occuFromDate = moment(occurredFromDate, "YYYY-MM-DD").toDate();
          dates = dates = occurredFromDate + " -";
          if (occuFromDate >= inactDate) {
            error = true;
          }
        }

        if (occurredThroughDate !== "") {
          let occuThroughDate = moment(occurredThroughDate, "YYYY-MM-DD").toDate();
          dates = dates + " " + occurredThroughDate;
          if (occuThroughDate >= inactDate) {
            error = true;
          }
        }
        if (dates.endsWith("-")) {
          dates = dates.substring(0, dates.length - 1)
        }
      }
      else if (effectiveDate !== "") {
        let effeDate = moment(effectiveDate, "YYYY-MM-DD").toDate();
        if (offenceOccurredDate !== "") {
          let offenceOccuDate = moment(offenceOccurredDate, "YYYY-MM-DD").toDate();
          dates = offenceOccurredDate;
          if (offenceOccuDate < effeDate) {
            error = true;
          }
        }
        if (occurredFromDate !== "") {
          let occuFromDate = moment(occurredFromDate, "YYYY-MM-DD").toDate();
          dates = occurredFromDate + " -"
          if (occuFromDate < effeDate) {
            error = true;
          }
        }

        if (occurredThroughDate !== "") {
          let occuThroughDate = moment(occurredThroughDate, "YYYY-MM-DD").toDate();
          dates = dates + " " + occurredThroughDate;
          if (occuThroughDate < effeDate) {
            error = true;
          }
        }
        if (dates.endsWith("-")) {
          dates = dates.substring(0, dates.length - 1)
        }
      }
      return { error, dates };
    }
  }


  const handleSelect = async (data: any) => {
    if (data.caseType === "CV") {
      setconfirmPopupData({
        show: true,
        data: data,
      });
      setContent(
        <>
          <Row>
            <Col className="text-center">
              <h1>
                <IoWarning className="text-warning" />
              </h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-2">
              {"You are adding a CONSERVATION charge to this complaint. Is this correct?"}
            </Col>
          </Row>
        </>
      )
    }
    else {
      try {
        let error = validation(data);
        let Obj = {
          error: error?.error,
          date: error?.dates,
        }
        await defaultValidationSchema.validate(Obj, { abortEarly: false, });
        dispatch(updateElementObjectValue(data))
        selectedViolationData(data)
        cancelViolationPopup()
        dispatch(resetEcViolationData());
      } catch (err: any) {
        handleError(
          err.inner.map((e: any) => e.path),
          err.errors
        );
      }
    }
  };
  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );
  const checkInctiveDate = () => {
    let primaryOffDate = "";
    let offenDate = null;
    let offenceOccurredDate = countFormObject.offenceDate;
    let occurredFromDate = countFormObject.offenceFromDate;
    let occurredThroughDate = countFormObject.offenceThroughDate;
    let offenceDate = complaint.firstIncidentDateTime;

    if (offenceOccurredDate !== null)
      offenDate = offenceOccurredDate;
    else if (occurredFromDate !== null)
      offenDate = occurredFromDate;
    else if (occurredThroughDate !== null)
      offenDate = occurredThroughDate;
    else if (offenceDate !== null)
      offenDate = offenceDate;

    primaryOffDate = offenDate ? offenDate : "";

    return primaryOffDate;
  }

    const tableHeader = ["Ref Code", "Description", "Case Type", "Class", "Inchoate", "Effective", "Inactive Date", ""];

    let tableData = ecViolationList.map((item: any) => 
         [
           item,
          item.violationId,
          item.violationCode,
          item.violationDescription,
          item.caseType,
          item.classOfViolation,
          item.inChoateOffense,
          item.effectiveDate,
          item.inactiveDate,
        ]
      )

      const renderTableRow = (row: any[]) => {
        const [
          item,
          violationId,
          violationCode,
          violationDescription,
          caseType,
          classOfViolation,
          inChoateOffense,
          effectiveDate,
          inactiveDate,
        ] = row;
        return(
          <tr
            key={violationId}
            onClick={() => handleSelect(item)}
            style = {{cursor: "pointer"}}
          >
          <td style={{ width: "180px" }}>{violationCode}</td>
          <td style={{ width: "450px" }}>{violationDescription}</td>
          <td>{caseType}</td>
          <td>{classOfViolation}</td>
          <td>{inChoateOffense}</td>
          <td>{effectiveDate ? effectiveDate.split("T")[0] : ""}</td>
          <td>{inactiveDate ? inactiveDate.split("T")[0] : ""}</td>

          <td><button className="btn btn-primary">Select</button></td>
        </tr>
        );

        };
  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-65w"
        onHide={() => cancelViolationPopup()}
        backdrop="static"
        scrollable={true}
        centered={true}
        Id='ViolationPopup'
      >
        <Modal.Header closeButton><div className="popupHeader"><b>{"Add/Edit Count > Search and select an offense"}</b></div></Modal.Header>
        <Modal.Body>
          <ErrorMessageAlert
            messages={errorMessageAlert.messages}
            clearError={errorMessageAlert.clearError}
          />
          <Row className="mt-3">
            <Col md={7} >
              <div className="d-flex">
                <label className="flex-fill mt-2"><b>Reference&nbsp;Code&nbsp;:&nbsp;</b></label>
                <input type="text" name="referenceCode" className="flex-fill form-control"
                  onChange={handleValueChange} onKeyDown ={handleKeyChange}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="status"
                  value="active"
                  onChange={handleValueChange}
                  checked={status === "active"}
                />
                <label className="form-check-label">Show Active</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="status"
                  value="all"
                  onChange={handleValueChange}
                  checked={status === "all"}
                />
                <label className="form-check-label">Show All</label>
              </div>
            </Col>
            <Col className="text-md-right" md={2}>
              <div className='text-end'>
                <Button className="btn-primary pl-5 pr-5" type="submit"
                  onClick={() => getViolationList(0)}
                >
                  Search
                </Button>
              </div>

            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="d-flex">
                <label className="flex-fill mt-2"><b>Description&nbsp;:&nbsp;</b></label>
                <input type="text" name="description" className="flex-fill form-control"
                  onChange={handleValueChange} onKeyDown ={handleKeyChange}
                />
              </div>
            </Col>
          </Row>
          <div className='text-end'>
            <Row className="mt-3">
              <Col className="text-md-right">
                <div className="form-check form-check-inline">
                  <input type="checkbox" name="felony"
                    onChange={handleChekBoxChange}
                    checked={felony}
                  />
                  <label className="form-check-label">
                    &nbsp;&nbsp;Felony Only
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input type="checkbox" name="misd"
                    onChange={handleChekBoxChange}
                    checked={misd}
                  />
                  <label className="form-check-label">
                    &nbsp;&nbsp;Misd Only
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input type="checkbox" name="inchoate"
                    onChange={handleChekBoxChange}
                  />
                  <label className="form-check-label">
                    &nbsp;&nbsp;Inchoate Only
                  </label>
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col className="mt-4">
              <div style={{ overflowY: 'auto', height: '300px' }}
              >
                <TableComponent
                  tableHeader={tableHeader}
                  tableData={tableData}
                  renderTableRow={renderTableRow}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col md={7}>
            {ecViolationList && ecViolationList.length>1?
            <PaginationBar className="d-flex justify-content-start" totalPages={totalPages} currentPage={currentPage} onPageChange={getViolationList} />
            :""}
          </Col>
          <Col></Col>
          <div>
            <Button
              variant="danger"
              onClick={() => cancelViolationPopup()}
              className="w-100"
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <OptionPopup
        show={confirmPopupData.show}
        header={"Warning"}
        children={content}
        classStyle={""}
        questionMarkVisible={true}
        buttons={[{ name: "Yes", variant: "primary", onClick: () => handleConfirm(confirmPopupData.data) },
        {
          name: "No", variant: "warning", onClick: () =>
            setconfirmPopupData({
              show: false,
              data: "",
            })
        }]}
      />
    </>
  );
};

export default ViolationPopup;
