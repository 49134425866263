import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { updateElementValue } from "../../utils/updateElementValue";
import { LoadingType } from "../../types";
import { AgencyType } from "./agencySlice";
import { getCountiesByUserRole } from "../../services/CountyService";
import { getLoggedInUserLFCAgencies, getAgencyListByCounty } from "../../services/AgencyService";

interface LfcDocumentState {
  countyList: any[];
  loadLoggedInUserCountyFetchStatus: LoadingType;
  loadLoggedInUserCountyError?: string;
  agencyList: AgencyType[];
  loadLoggedInUserLFCAgenciesFetchStatus: LoadingType;
  loadLoggedInUserLFCAgenciesError?: string;
  loadAgencyListByCountyFetchStatus: LoadingType;
  loadAgencyListByCountyError?: string;

  countyListReady: boolean;
  agencyListReady: boolean;

  searchParameters: any;
  didMount: boolean;

  agencyDisplay:string;
  localCounty:string;
  agencyId:number;
  countyId:number;
  comStatus:string;
  dateOfBirth: string;                // PR-11312
  defLastName: string;                // PR-11312
  defFirstName: string;               // PR-11312
  warrantControlNo: string;          // PR-11312
  toDate:string;      
  fromDate:string;
  complaintNumber:string;
  agencyReportNumber:string;
  circuitClerkCaseNumber:string;


    totalPages: number,
    currentPage: number,
    lfcDocumentList: any[],
    totalRecords:number,
    fetchDocsStatus: string,
    fetchDocsError: string,
}

const initialState = {
  countyList: [],
  loadLoggedInUserCountyFetchStatus: "idle",
  loadLoggedInUserCountyError: undefined,
  agencyList: [],
  loadLoggedInUserLFCAgenciesFetchStatus: "idle",
  loadLoggedInUserLFCAgenciesError: undefined,
  loadAgencyListByCountyFetchStatus: "idle",
  loadAgencyListByCountyError: undefined,

  countyListReady: false,
  agencyListReady: false,

  searchParameters: undefined,
  didMount: false,
  agencyDisplay:"",
  localCounty:"",
  agencyId:0,
  countyId:0,
  comStatus:"",
  dateOfBirth: "",                // PR-11312
  defLastName: "",                // PR-11312
  defFirstName: "",               // PR-11312
  warrantControlNo: "",          // PR-11312
  toDate:"",
  fromDate:"",
  complaintNumber:"",
  agencyReportNumber:"",
  circuitClerkCaseNumber:"",

    totalPages: 0,
    currentPage: 0,
    lfcDocumentList: [],
    totalRecords:0,
    fetchDocsStatus: "",
    fetchDocsError: "idle",
  } as LfcDocumentState;

export const loadLoggedInUserCounties = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "counties/loadLoggedInUserCounties",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await getCountiesByUserRole({ token });
      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else { 
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching logged in user county list");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching logged in user county list"
      );
    }
  }
);

export const loadLoggedInUserLFCAgencies = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: String }
>(
  "agencies/getLoggedInUserLFCAgencies",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    try {
      dispatch(showHideLoadingGif(true));

      const reponse = await getLoggedInUserLFCAgencies({ token });

      dispatch(showHideLoadingGif(false));
      if (reponse.ok) {
        return reponse.json();
      }
      return rejectWithValue(
        "Error while fetching logged in user agencies list"
      );
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        "Error while fetching logged in user agencies list"
      );
    }
  }
);

export const loadAgencyListByCounty = createAsyncThunk<
  any,
  { countyId?: number; },
  { state: RootState; }
>("selectCountyAgency/loadAgencyListByCounty", async (data, { getState, rejectWithValue, dispatch }) => {
  let { countyId } = data;
  let { token } = getState().login;
  dispatch(showHideLoadingGif(true));
  try {
    const reponse = await getAgencyListByCounty({
      countyId: countyId,
      token: token
    });
    dispatch(showHideLoadingGif(false));
    return await reponse.json();
  } catch (error) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error);
  }
});

export const searchLfcDocumentList = createAsyncThunk<
  any,
  {
    pageNo: number;
    pageSize: number;
    countyName?:string;
    countyId: number;
    agencyId: number;
    complaintNumber: string;
    agencyReportNumber: string;
    circuitClerkCaseNumber: string;
    statusList: string[];
    fromFDate: string;
    toFDate: string;
    defFirstName: string;                 // PR-11312
    defLastName: string,                  // PR-11312
    warrantControlNo: string,             // PR-11312
    dateOfBirth: string,                  // PR-11312
    searchFrom: string,                  // PR-11312
  },
  { state: RootState; rejectValue: string }
>(
  "lfcDocument/searchLfcDocumentList",
  async (
    {
      pageNo,
      pageSize,
      countyName,
      countyId,
      agencyId,
      complaintNumber,
      agencyReportNumber,
      circuitClerkCaseNumber,
      statusList,
      fromFDate,
      toFDate,
      defFirstName,               // PR-11312
      defLastName,                 // PR-11312
      warrantControlNo,            // PR-11312
      dateOfBirth,                 // PR-11312
      searchFrom,
    },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    let queryParam = ''
    queryParam += `pageNo=${pageNo}&`
    if (pageSize) queryParam += `pageSize=${pageSize}&`
    if (countyName) queryParam += `countyName=${countyName}&`
    queryParam += `countyId=${countyId}&`
    queryParam += `agencyId=${agencyId}&`
    if (complaintNumber) queryParam += `complaintNumber=${complaintNumber}&`
    if (agencyReportNumber) queryParam += `agencyReportNumber=${agencyReportNumber}&`
    if (circuitClerkCaseNumber) queryParam += `circuitClerkCaseNumber=${circuitClerkCaseNumber}&`
    if (statusList) queryParam += `statusList=${statusList}&`
    if (fromFDate) queryParam += `fromFDate=${fromFDate}&`
    if (toFDate) queryParam += `toFDate=${toFDate}&`
    if (defFirstName) queryParam += `defFirstName=${defFirstName}&`
    if (defLastName) queryParam += `defLastName=${defLastName}&`
    if (warrantControlNo) queryParam += `warrantControlNo=${warrantControlNo}&`
    if (dateOfBirth) queryParam += `dateOfBirth=${dateOfBirth}&`
    if (searchFrom) queryParam += `searchFrom=${searchFrom}&`
    try {
      console.log(queryParam)
      dispatch(showHideLoadingGif(true));
      const response: any = await doFetch({
        url: "docrootservices/listLfcSearchDocuments?" + queryParam,
        token,
      });

      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        const lfcDocumentList = await response.json();
        console.log("lfcDocumentList", lfcDocumentList);
        return lfcDocumentList;
      }
      return rejectWithValue("Error while fetching search warrant list");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching search warrant list");
    }
  }
)

  const lfcDocumentSlice = createSlice({
    name:'lfcDocumentSlice',
    initialState,
    reducers:{
      updateElementValueInEComplaintListReducer: updateElementValue,
      resetEComplaintList: (state: LfcDocumentState) => {
        return {
          ...state,
          ...initialState,
        };
      },
    },
    extraReducers:(builder) =>{
      builder

      //loadLoggedInUserCounties
      .addCase(loadLoggedInUserCounties.pending, (state) => {
        state.loadLoggedInUserCountyFetchStatus = "loading";
        state.loadLoggedInUserCountyError = undefined;
      })
      .addCase(loadLoggedInUserCounties.fulfilled, (state, action) => {
        state.loadLoggedInUserCountyFetchStatus = "success";
        state.countyList = action.payload;
      })
      .addCase(loadLoggedInUserCounties.rejected, (state, action) => {
        state.loadLoggedInUserCountyFetchStatus = "error";
        state.loadLoggedInUserCountyError = action.payload;
      })

      //getLoggedInUserLFCAgencies
      .addCase(loadLoggedInUserLFCAgencies.pending, (state) => {
        state.loadLoggedInUserLFCAgenciesFetchStatus = "loading";
        state.loadLoggedInUserLFCAgenciesError = undefined;
      })
      .addCase(loadLoggedInUserLFCAgencies.fulfilled, (state, action) => {
        state.loadLoggedInUserLFCAgenciesFetchStatus = "success";
        state.agencyList = action.payload;
      })
      .addCase(loadLoggedInUserLFCAgencies.rejected, (state, action) => {
        state.loadLoggedInUserLFCAgenciesError = "error";
      })

      //loadAgencyListByCounty
      .addCase(loadAgencyListByCounty.pending, (state) => {
        state.loadAgencyListByCountyFetchStatus = "loading";
        state.loadAgencyListByCountyError = undefined;
      })
      .addCase(loadAgencyListByCounty.fulfilled, (state, action) => {
        state.loadAgencyListByCountyFetchStatus = "success";
        state.agencyList = action.payload;
      })
      .addCase(loadAgencyListByCounty.rejected, (state, action) => {
        state.loadAgencyListByCountyFetchStatus = "error";
        state.loadAgencyListByCountyError = undefined;
      })

      .addCase(searchLfcDocumentList.pending, (state) => {
        state.fetchDocsStatus = "loading";
      })
      .addCase(searchLfcDocumentList.fulfilled, (state, action) => {
        state.lfcDocumentList = action.payload.lfcDocumentList;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.totalRecords = action.payload.totalRecords
        state.fetchDocsStatus = "success";
      })
      .addCase(searchLfcDocumentList.rejected, (state, action) => {
        state.fetchDocsStatus = "error";
        state.fetchDocsError = "failed"
      })
        
    }


  });

export const useLfcDocumentReducer = () => useAppSelector((state) => state.lfcDocumentList);

export const { updateElementValueInEComplaintListReducer, resetEComplaintList } = lfcDocumentSlice.actions;

export default lfcDocumentSlice.reducer;