import React, { useEffect } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import ButtonComponent from "../common/ButtonComponent";
import Header from "../common/Header";
import { Row, Col } from "react-bootstrap";
import {
  RiSearchLine,
  RiAddCircleLine,
  RiFileCopyLine,
  RiDraftFill,
  RiFileExcelLine,
  RiCheckboxMultipleLine,
} from "react-icons/ri";
import ListViewComponent from "../common/ListViewComponent";
import { useAppDispatch } from "../../hooks/hooks";
import {
  useCountyListReducer,
  getLoggedInUserCounties,
} from "../../redux/slice/countyListSlice";
import {
  getLoggedInUserWarrantTemplates,
  useTemplateReducer,
  updateTemplateStatus,
} from "../../redux/slice/templateSlice";
import {
  templateListData,
  useTemplateListReducer,
  updateElementValuetemplateListReducer,
  resetTemplateListData,
  updatetemplateListReducer,
} from "../../redux/slice/templateListSlice";
import {
  ACTIVE_STATUS,
  ROLE_AGENCY_ADMIN,
  ROLE_COUNTY_ADMIN,
  SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER,
  SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT,
  SEARCH_WAR_TEMPLATE_TYPE_ORDER,
  _COPY,
  _EDIT,
  _UPDATE,
} from "../../constants/common";
import ConfirmPopup from "../common/ConfirmPopup";
import SelectTemplatePopup from "./SelectTemplatePopup";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TableActionComponent } from "../common/TableActionComponent";
import moment from "moment";
import { useLoginReducer } from "../../redux/slice/loginSlice";

interface TemplateListProps {}

export const TemplateList: React.FC<TemplateListProps> = (props) => {
  const { countyList } = useCountyListReducer();
  const { templateListByCounty } = useTemplateReducer();
  const {
    templateList,
    totalPages,
    currentPage,
    totalRecords,
    countyId,
    template,
    listStatus,
  } = useTemplateListReducer();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userType } = useLoginReducer();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
    templateId: 0,
    status: "",
    templateType: "",
  });

  const [templateImportPopupData, setTemplateImportPopupData] = React.useState({
    show: false,
  });

  const handleTemplateStatusUpdate =
    (templateId: number, active: boolean, templateType: string) => () => {
      setconfirmPopupData({
        show: true,
        templateId,
        status: _UPDATE,
        templateType: templateType,
      });
    };

  const handleTemplateEdit =
    (templateId: number, templateType: string) => () => {
      if (!templateType || !templateId) {
        alert("Invalid template. Missing template type or template id");
        return;
      }
      setconfirmPopupData({
        show: true,
        templateId,
        status: _EDIT,
        templateType: templateType,
      });
    };

  const handleTemplateCopy =
    (templateId: number, templateType: string) => () => {
      setconfirmPopupData({
        show: true,
        templateId,
        status: _COPY,
        templateType: templateType,
      });
    };

  const changeTemplateImportPopupData = () => {
    setTemplateImportPopupData({ show: true });
  };

  const handleTemplateStatusUpdateConfirm = () => {
    const template = templateList.find(
      (temp: any) => temp.templateId === confirmPopupData.templateId
    ) as any;

    let urlPathObj: any = {
      countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
      templateName: searchParams.get("templateName") || 0,
      status: searchParams.get("status") || 0,
      pageNo: parseInt(searchParams.get("pageNo")?.toString() || "0") - 1,
    };
    let dataObj = {
      templateId: confirmPopupData.templateId,
      status: template && template?.status === ACTIVE_STATUS ? false : true,
      listViewData: urlPathObj,
    };
    dispatch(updateTemplateStatus(dataObj));
    setconfirmPopupData({
      show: false,
      templateId: 0,
      status: "",
      templateType: "",
    });
  };

  const handleTemplateCopyConfirm = () => {
    if (confirmPopupData.templateType === SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT) {
      navigate(
        "/copyComplaintSearchwarrantTemp/" + confirmPopupData.templateId
      );
    } else if (
      confirmPopupData.templateType === SEARCH_WAR_TEMPLATE_TYPE_ORDER
    ) {
      navigate("/copyApplicationAndOrderTemp/" + confirmPopupData.templateId);
    } else if (
      confirmPopupData.templateType === SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER
    ) {
      navigate("/copyBlankOrderTemp/" + confirmPopupData.templateId);
    }
  };

  const handleTemplateEditConfirm = () => {
    if (confirmPopupData.templateType === SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT) {
      navigate(
        "/editComplaintSearchwarrantTemp/" + confirmPopupData.templateId
      );
    } else if (
      confirmPopupData.templateType === SEARCH_WAR_TEMPLATE_TYPE_ORDER
    ) {
      navigate("/editApplicationAndOrderTemp/" + confirmPopupData.templateId);
    } else if (
      confirmPopupData.templateType === SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER
    ) {
      navigate("/editBlankOrderTemp/" + confirmPopupData.templateId);
    }
    setconfirmPopupData({
      show: false,
      templateId: 0,
      status: "",
      templateType: "",
    });
  };

  const handleSubmit = (state: any) => {
    let obj = {
      countyId: countyId,
      templateName:
        template === "0" || template === "-- ALL --" ? "" : template,
      status: listStatus || "all",
    };
    dispatch(templateListData(obj));
    let urlPathObj: any = {
      countyId: countyId,
      templateName:
        template === "0" || template === "-- ALL --" ? "" : template,
      status: listStatus || "all",
      pageNo: 1,
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlPathObj)}`,
    });
  };

  const paginationHandle = (page: number, state: any) => {
    let urlPathObj: any = {
      countyId: countyId,
      templateName:
        template === "0" || template === "-- ALL --" ? "" : template,
      status: listStatus || "all",
      pageNo: (page + 1).toString(),
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlPathObj)}`,
    });

    let obj = {
      countyId: state.county,
      templateName: state.template,
      status: state.status || "all",
      pageNo: page,
    };

    dispatch(templateListData(obj));
  };

  window.onpopstate = () => {
    let paramsValue = location.search;
    if (paramsValue.trim().length > 0) {
      let urlPathObj: any = {
        countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
        templateName: searchParams.get("templateName") || 0,
        status: searchParams.get("status") || 0,
        pageNo: parseInt(searchParams.get("pageNo")?.toString() || "0") - 1,
      };
      setUrlData();
      dispatch(templateListData(urlPathObj));
    } else {
      dispatch(resetTemplateListData());
      dispatch(getLoggedInUserCounties());
      dispatch(getLoggedInUserWarrantTemplates());
      dispatch(templateListData({}));
    }
  };

  let tableHeader = [
    "TEMPLATE NAME",
    "COUNTY",
    "STATUS",
    "TEMPLATE TYPE",
    "CREATION DETAILS",
    "MODIFICATION DETAILS",
    "ACTION",
  ];

  let tableData = templateList.map(
    ({
      templateName,
      countyName,
      status,
      templateType,
      creationTimeStamp,
      auditTimeStamp,
      auditUserName,
      creatorName,
      templateId,
    }: any) => [
      templateName,
      countyName,
      status,
      templateType,
      creationTimeStamp,
      auditTimeStamp,
      auditUserName,
      creatorName,
      templateId,
      handleTemplateStatusUpdate(templateId, status, templateType),
      handleTemplateEdit(templateId, templateType),
      handleTemplateCopy(templateId, templateType),
    ]
  );

  let itemLinkList = [{ itemLink: "/", itemName: "DashBoard" }];

  const countyListOptions: any = [];
  if (countyList.length! > 1) {
    countyListOptions.push({ label: "-- ALL --", value: 0 });
  }
  countyListOptions.push(
    ...countyList.map((county: any) => {
      return { label: county.countyName, value: county.countyID };
    })
  );

  const templateListOptions: any = [];
  if (templateListByCounty.length! > 1) {
    templateListOptions.push({ label: "-- ALL --", value: 0 });
  }
  templateListOptions.push(
    ...templateListByCounty.map((template: any) => {
      return { label: template.templateName, value: template.templateName };
    })
  );

  const statusOptions: any = [];
  statusOptions.push(
    { value: "all", label: "-- ALL --" },
    { value: "true", label: "ACTIVE" },
    { value: "false", label: "INACTIVE" }
  );

  const handleCounty = (e: any) => {
    dispatch(
      updateElementValuetemplateListReducer({
        elementName: "countyId",
        value: e.value,
      })
    );
  };

  const handleTemplate = (e: any) => {
    dispatch(
      updateElementValuetemplateListReducer({
        elementName: "template",
        value: e.value,
      })
    );
  };

  const handleStatus = (e: any) => {
    dispatch(
      updateElementValuetemplateListReducer({
        elementName: "listStatus",
        value: e.value,
      })
    );
  };

  const data = {
    colSize: 4,
    componentData: [
      {
        type: "selectInput",
        props: {
          options: countyListOptions,
          value: countyListOptions.find((item: any) => {
            if (item.value === countyId) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select County",
          name: "county",
          defaultValue:
            userType === ROLE_COUNTY_ADMIN || userType === ROLE_AGENCY_ADMIN
              ? countyListOptions[0]
              : countyListOptions,
          onChange: handleCounty,
          isDisabled:
            (countyList.length === 1 && userType === ROLE_COUNTY_ADMIN) ||
            userType === ROLE_AGENCY_ADMIN
              ? true
              : false,
        },
      },
      {
        type: "selectInput",
        props: {
          options: templateListOptions,
          value: templateListOptions.find((item: any) => {
            if (item.label === template) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select Template",
          name: "template",
          defaultValue: { label: "-- ALL --", value: 0 },
          onChange: handleTemplate,
        },
      },
      {
        type: "selectInput",
        props: {
          options: statusOptions,
          value: statusOptions.find((item: any) => {
            if (item.value === listStatus) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select Status",
          name: "status",
          defaultValue: { value: "all", label: "-- ALL --" },
          onChange: handleStatus,
        },
        colSize: 3,
      },

      {
        type: "button",
        props: {
          className: "w-100",
          children: (
            <>
              <RiSearchLine /> Search
            </>
          ),
          type: "submit",
          onClick: (state: any) => handleSubmit(state),
        },
        colSize: 1,
      },
    ],
  };

  useEffect(() => {
    return () => {
      dispatch(resetTemplateListData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLoggedInUserCounties());
    dispatch(getLoggedInUserWarrantTemplates());
    dispatch(templateListData({}));
    navigate(location.pathname);
  }, [dispatch, location.pathname, navigate]);

  const setUrlData = () => {
    let urlPath: any = {
      templateName: searchParams.get("templateName"),
      status: searchParams.get("status") || "all",
      countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
    };
    dispatch(updatetemplateListReducer(urlPath));
  };

  return (
    <>
      <Header headerName="Template List" />

      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Template List"
        />
        <ListViewComponent
          table={{
            tableHeader: tableHeader,
            tableData: tableData,
            renderTableRow: renderTableRow,
          }}
          searchBar={{
            colSize: data.colSize,
            componentData: data.componentData,
          }}
          middleComponent={() => (
            <ListViewMiddleComponent
              totalRecords={totalRecords}
              changeTemplateImportPopupData={changeTemplateImportPopupData}
            />
          )}
          pagination={{
            totalPages: totalPages,
            numberOfPagesToShow: 5,
            currentPage: currentPage,
            onPageChange: (page: number, state: any) => {
              paginationHandle(page, state);
            },
          }}
        />
      </div>

      <ConfirmPopup
        children={
          confirmPopupData.status === _EDIT
            ? `Are you sure you want to Edit ${confirmPopupData.templateType} template?`
            : confirmPopupData.status === _COPY
            ? `Are you sure you want to Copy ${confirmPopupData.templateType} template?`
            : `Are you sure you want to active/inactive ${confirmPopupData.templateType} template?`
        }
        show={confirmPopupData.show}
        onHide={() =>
          setconfirmPopupData({
            show: false,
            templateId: 0,
            status: "",
            templateType: "",
          })
        }
        isConfirmation={
          confirmPopupData.status === _EDIT
            ? handleTemplateEditConfirm
            : confirmPopupData.status === _COPY
            ? handleTemplateCopyConfirm
            : handleTemplateStatusUpdateConfirm
        }
      />

      <SelectTemplatePopup
        show={templateImportPopupData.show}
        onHide={() =>
          setTemplateImportPopupData({
            show: false,
          })
        }
      />
    </>
  );
};

const ListViewMiddleComponent = ({
  totalRecords,
  changeTemplateImportPopupData,
}: {
  totalRecords: number;
  changeTemplateImportPopupData: () => void;
}) => (
  <Row>
    <Col>
      <h4>Template List</h4>
      <h6 className="my-n2">Total Templates : {totalRecords}</h6>
    </Col>
    <Col className="text-end">
      <ButtonComponent variant="info" onClick={changeTemplateImportPopupData}>
        <RiAddCircleLine /> Create Template
      </ButtonComponent>
    </Col>
  </Row>
);

const renderTableRow = (row: any[]) => {
  const [
    templateName,
    countyName,
    status,
    templateType,
    creationTimeStamp,
    auditTimeStamp,
    auditUserName,
    creatorName,
    templateId,
    handleTemplateStatusUpdate,
    handleTemplateEdit,
    handleTemplateCopy,
  ] = row;
  return (
    <tr key={templateId}>
      <td>
        <div onClick={handleTemplateEdit}>
          <b>{templateName}</b>
        </div>
      </td>
      <td>{countyName}</td>
      <td>{status}</td>
      <td>{templateType}</td>
      <td>
        {creatorName} - {moment(creationTimeStamp).format("M/D/YYYY hh:mm A")}
      </td>
      <td>
        {auditUserName} - {moment(auditTimeStamp).format("M/D/YYYY hh:mm A")}
      </td>
      <td className="justify-content-center d-flex align-items-stretch">
        <TableActionComponent
          tootltip="User Edit"
          children={<RiDraftFill />}
          actionFunction={handleTemplateEdit}
          text="User Edit"
        />

        {status === ACTIVE_STATUS ? (
          <TableActionComponent
            tootltip="Template Inactive"
            children={<RiFileExcelLine />}
            actionFunction={handleTemplateStatusUpdate}
            color="text-danger"
            text="Template Inactive"
          />
        ) : (
          <TableActionComponent
            tootltip="Template Active"
            children={<RiCheckboxMultipleLine />}
            actionFunction={handleTemplateStatusUpdate}
            color="text-success"
            text="Template Active"
          />
        )}

        <TableActionComponent
          tootltip="Create Copy"
          children={<RiFileCopyLine />}
          actionFunction={handleTemplateCopy}
          color="text-info"
          text="Create Copy"
        />
      </td>
    </tr>
  );
};

export default TemplateList;
