import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import TextInputFlotingComponent from "./TextInputFlotingComponent";
import TableComponent, { TableComponentProps } from "./TableComponent";
import ButtonComponent from "./ButtonComponent";
import SearchBarComponent, {
  SearchBarComponentProps,
} from "./SearchBarComponent";
import PaginationBar, { PaginationProps } from "./Pagination";
import SelectComponent from "./SelectComponent";

type ListViewComponentProps = {
  table: TableComponentProps;
  searchBar?: {
    componentData: SearchBarComponentProps["componentData"];
    components?: SearchBarComponentProps["components"];
    totalColumns?: SearchBarComponentProps["totalColumns"];
    colSize: SearchBarComponentProps["colSize"];
  };
  middleComponent?: (state?: any) => JSX.Element;
  pagination?: PaginationProps;
};
/**
 * The ListViewComponent is a wrapper component that contains a TableComponent and a SearchBarComponent.
 * This component takes the following props:
 * This component will handle state management by using the name as the key.
 * The state can be accessed by using onClick on the button component which has a type of "submit".
 * @param {TableComponentProps} table - The table component props. Please refer to the TableComponent.tsx for more information.
 * @param {SearchBarComponentProps} searchBar - The search bar component props. Please refer to the SearchBarComponent.tsx for more information.
 * @param {() => JSX.Element} middleComponent - A function that returns a component to be rendered in between the search bar and the table.
 * @param {PaginationProps} pagination - The pagination component props. Please refer to the PaginationBar.tsx for more information.
 */

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "updateValue":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};
export default function ListViewComponent({
  table,
  searchBar,
  middleComponent,
  pagination,
}: ListViewComponentProps) {
  const [state, dispatch] = React.useReducer(reducer, {});
  return (
    <>
      {searchBar && (
        <SearchBarComponent
          components={components}
          {...searchBar}
          {...{ state, dispatch }}
        />
      )}
      <Card className="shadow mt-4">
        <Card.Body>
          <>
            {middleComponent && middleComponent(state)}
            <TableComponent {...table} />
          </>
        </Card.Body>
      </Card>
      <Row className="mt-3">
        <Col>
          {pagination && <PaginationBar {...pagination} state={state} />}
        </Col>
      </Row>
    </>
  );
}

const components = [
  {
    type: "textInputFloting",
    node: (props: any) => (
      <TextInputFlotingComponent
        {...props}
        onChange={(e: any) => {
          props.onChange({
            e,
            value: e.target.value,
          });
        }}
      />
    ),
  },
  {
    type: "selectInput",
    node: (props: any) => (
      <SelectComponent
        {...props}
        onChange={(e: any) => {
          props.onChange({
            e,
            value: e.value,
          });
        }}
      />
    ),
  },
  {
    type: "button",
    node: (props: any) => (
      <ButtonComponent {...props}>{props.children}</ButtonComponent>
    ),
  },
];
