import React from "react";
import { Link } from "react-router-dom";

type itemObj = {
  itemLink: string;
  itemName: string;
  itemFunction?: () => void;
};

type BreadcrumbComponentProps = {
  itemList: itemObj[];
  itemActive: string;
};

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = (props) => {
  const { itemList, itemActive } = props;
  return (
    <>
      <div className="mt-3">
        <ol className="breadcrumb">
          {itemList.map((item) => (
            <li className="breadcrumb-item" key={item.itemLink}>
              <Link to={item.itemLink} onClick={item.itemFunction}>{item.itemName}</Link>
            </li>
          ))}
          <li className="breadcrumb-item active">{itemActive}</li>
        </ol>
      </div>
    </>
  );
};
export default BreadcrumbComponent;
