import React, { useState } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import FormComponent from "../common/FormComponent";
import ButtonComponent from "../common/ButtonComponent";
import { getIncidentNoByAgencyFormat, validateIncidentNoForAgency } from "../../utils/common";
import { useAppDispatch } from "../../hooks/hooks";
import { updateElementObjectValueComplaint } from "../../redux/slice/complaintSlice";

interface IncidentNumberConfirmPopupProps {
    show: boolean;
    onHide: () => void;
    isConfirmation: (incidentNumber: string) => void;
    incidentNoFormat: string;
}

const IncidentNumberConfirmPopup: React.FC<IncidentNumberConfirmPopupProps> = (props) => {

    const { show, onHide, isConfirmation, incidentNoFormat } = props;

    const dispatch = useAppDispatch();

    const [incidentNo, setIncidentNo] = useState("");
    const [incidentNumberError, setIncidentNumberError] = useState(false);
    const [incidentNumberRequired, setIncidentNumberRequired] = useState(false);
    const [errorShowCount, setErrorShowCount] = useState(0);

    const handleInputChange = (event: any) => {
        let { value } = event.target;
        setIncidentNo(value);
    }

    const onFocusPrimaryAgencyRepNumber = (incedFormat: string) => (event: any) => {
        let { value } = event.target;
        if (!value) {
            let val: string = getIncidentNoByAgencyFormat(incedFormat);
            val = val.replaceAll("#", "");
            setIncidentNo(val);
        }
    }

    const onBlurPrimaryAgencyRepNumber = (incedFormat: string) => (event: any) => {
        let { value } = event.target;
        setIncidentNo(value.trim());
    }

    const validate = (incedFormat: string) => {
        let value: string = incidentNo;
        if (value) {
            let inciNo: string = value.trim();
            if (!validateIncidentNoForAgency(incedFormat, inciNo)) {
                setIncidentNumberError(true);
                setErrorShowCount(errorShowCount + 1);
                setIncidentNumberRequired(false);
                return;
            } else {
                dispatch(updateElementObjectValueComplaint({ elementName: "primaryAgencyRepNumber", value: inciNo }));
                isConfirmation(inciNo);
            }
        } else {
            setIncidentNumberRequired(true);
            setIncidentNumberError(false);
        }
    }

    const continueON = (incedFormat: string) => {
        let value: string = incidentNo;
        let inciNo: string = value.trim();
        if (inciNo) {
            dispatch(updateElementObjectValueComplaint({ elementName: "primaryAgencyRepNumber", value: inciNo }));
            isConfirmation(inciNo);
        } else {
            setIncidentNumberRequired(true);
            setIncidentNumberError(false);
        }
    }

    const data = [
        {
            type: "htmlTag",
            props: {
                type: "label",
                value: "The incident number you have entered does not follow the proper format for your agency."
            },
            colSize: 12,
        },
        {
            type: "htmlTag",
            props: {
                type: "label",
                value: "Re-enter the incident number below."
            },
            colSize: 12,
        },
        {
            type: "htmlTag",
            props: {
                value:(
                    <>
                      <div>Format: <b>{incidentNoFormat}</b></div>
                    </>
                  )
            },
            colSize: 12,
        },
        {
            type: "textInput",
            props: {
                type: "text",
                placeholder: incidentNoFormat,
                name: "incidentNo",
                value: incidentNo,
                onChange: handleInputChange,
                onFocus: onFocusPrimaryAgencyRepNumber(incidentNoFormat),
                onBlur: onBlurPrimaryAgencyRepNumber(incidentNoFormat),
            },
            label: "Incident Number:",
            inLine: true,
            colSize: 10,
        },
        {
            type: "htmlTag",
            props: {
                type: "label",
                value: "Incident Number is required.",
                className: "text-danger",
            },
            colSize: 12,
            colHide: !incidentNumberRequired
        },
        {
            type: "htmlTag",
            props: {
                type: "label",
                value: "The incident number entered still does not match the required format.",
                className: "text-danger",
            },
            colSize: 12,
            colHide: !incidentNumberError
        },
        {
            type: "htmlTag",
            props: {
                type: "label",
                value: "Click Validate to try again, or Continue if you are positive this number is correct.",
                className: "text-danger",
            },
            colSize: 12,
            colHide: !incidentNumberError
        },
        {
            type: "htmlTag",
            props: {
                value: <hr />,
            },
            colSize: 12,
            colClassName: "mt-3"
        },
    ]

    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => onHide()}
            backdrop="static"
            dialogClassName="modal-50w"
        >
            <Modal.Header closeButton>
                <b>Confirm Incident Number</b>
            </Modal.Header>
            <Modal.Body>
                <FormComponent totalColumns={12} colSize={6} componentData={data} />
            </Modal.Body>
            <Modal.Footer>
                <Row className="justify-content-end">
                    <Col md={"auto"}>
                        <ButtonComponent
                            className="mx-2"
                            onClick={() => {
                                validate(incidentNoFormat);
                            }}
                            >{"Validate"}
                        </ButtonComponent>
                        {errorShowCount > 0 ?
                            <ButtonComponent
                            onClick={() => {
                                continueON(incidentNoFormat);
                            }}
                            >{"Continue"}
                        </ButtonComponent>
                        : ""}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default IncidentNumberConfirmPopup;