import moment from "moment";
import React, { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RiAddCircleLine, RiCheckFill, RiCloseFill, RiQuestionLine } from "react-icons/ri";
import { useAppDispatch } from "../../hooks/hooks";
import { updateElementObjectValueComplaint, useComplaintReducer } from "../../redux/slice/complaintSlice";
import { updateElementValueWebRmsReducer, useWebRmsReducer } from "../../redux/slice/webRmsSlice";
import ButtonComponent from "../common/ButtonComponent";
import { TableActionComponent } from "../common/TableActionComponent";
import TableComponent from "../common/TableComponent";
import TextInputComponent from "../common/TextInputComponent";

interface LookupWebRMSPopupProps {
  show: boolean;
  onHide: () => void;
}

export const LookupWebRMSPopup: React.FC<LookupWebRMSPopupProps> = (props) => {

  const dispatch = useAppDispatch();
  const { complaintObject } = useComplaintReducer();
  const { webrmsList } = useWebRmsReducer();

  const { show, onHide } = props;

  let witnessList :any =[];
  let complaintList :any =[];

  useEffect(()=>{
    dispatch(updateElementValueWebRmsReducer({
      elementName : "witnessList",
      value : witnessList
    }));
    dispatch(updateElementValueWebRmsReducer({
      elementName : "complaintList",
      value : complaintList
    }));
  },[webrmsList]); 

  const handleClickAddWebrmsInfo = (webRmsObj : any) =>()=>{
    if(webRmsObj.middleName !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defMiddleName", value : webRmsObj.middleName }));
    if(webRmsObj.dob !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defDob", value : moment(webRmsObj.dob).format('yyyy-MM-DD')}));
    if(webRmsObj.addressLine1 !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defAddressLine1",value : webRmsObj.addressLine1}));
    if(webRmsObj.addressLine2 !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defAddressLine2", value : webRmsObj.addressLine2}));
    if(webRmsObj.sex !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defGender", value : webRmsObj.sex}));
    if(webRmsObj.city !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defCity", value : webRmsObj.city}));
    if(webRmsObj.state !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defState", value : webRmsObj.state}));
    if(webRmsObj.zip !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defZip", value : webRmsObj.zip}));
    if(webRmsObj.email !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defEmail", value : webRmsObj.email}));
    if(webRmsObj.race !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defRace", value : webRmsObj.race}));
    if(webRmsObj.hairColor !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defHairColor", value : webRmsObj.hairColor}));
    if(webRmsObj.eyeColor !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defEyeColor", value : webRmsObj.eyeColor}));
    if(webRmsObj.height !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defHeight", value : webRmsObj.height}));
    if(webRmsObj.weight !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defWeight", value : webRmsObj.weight}));
    if(webRmsObj.homePhoneNumber !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defPhoneNumber1", value : webRmsObj.homePhoneNumber}));
    if(webRmsObj.cellPhoneNumber !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defPhoneNumber2", value : webRmsObj.cellPhoneNumber}));
    if(webRmsObj.driverLicenseNumber !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defDriverlicense", value : webRmsObj.driverLicenseNumber}));
    if(webRmsObj.driverLicenseState !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defDlState", value : webRmsObj.driverLicenseState}));
    if(webRmsObj.stateIdentificationNumber !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defSID", value : webRmsObj.stateIdentificationNumber}));
    if(webRmsObj.fbiGeneratedNumber !== null)
    dispatch(updateElementObjectValueComplaint({ elementName: "defFBI", value : webRmsObj.fbiGeneratedNumber}));

    onHide();

  }
  
  webrmsList.map((data : any)=>{
    if(data.involvementName === "WITNESS"){
      witnessList.push(data);
    }else if(data.firstName === complaintObject.defFirstName && data.lastName === complaintObject.defLastName){
      complaintList.push(data);
    }
  });

  let tableData: any = complaintList.map(
    (
      webRmsObj: any,
      i: any
    ) => [
      i + 1,
      webRmsObj.firstName,
      webRmsObj.middleName,
      webRmsObj.lastName,
      webRmsObj.sex,
      webRmsObj.dob,
      webRmsObj.race,
      handleClickAddWebrmsInfo(webRmsObj),
    ]
  );

  return (
    <Modal
      show={show}
      size="xl" 
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <b>Web RMS Information</b>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="mt-2">
            <TextInputComponent
              labelName="Incident Number"
              value={complaintObject.primaryAgencyRepNumber}
              disabled
            />
          </Col>
          <Col className="mt-2">
            <TextInputComponent
              labelName="First Name"
              value={complaintObject.defFirstName}
              disabled
            />
          </Col>
          <Col className="mt-2">
            <TextInputComponent
              labelName="Last Name"
              value={complaintObject.defLastName}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TableComponent
            tableHeader={tableHeader}
            tableData={tableData}
            renderTableRow = {renderTableRow}/>
          </Col>
        </Row>
        <hr />
        <Row className="g-2 text-end">
          <Col>
            {/* <ButtonComponent
              variant="primary"
              onClick={() => isConfirmation()}
              className = "pe-3 ps-3"
            >
              <RiCheckFill /> Add Web RMS Info
            </ButtonComponent>{" "} */}
            <ButtonComponent
              variant="danger"
              onClick={() => onHide()}
              className = "pe-3 ps-3"
            >
              <RiCloseFill /> Cancel
            </ButtonComponent>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const tableHeader = [
  "#",
  "First Name",
  "Middle Name",
  "Last Name",
  "Gender",
  "Date of Birth",
  "Race",
  "Action"
];

const renderTableRow = (row: any[]) => {
  const [
    i,
    firstName,
    middleName,
    lastName,
    sex,
    dob,
    race,
    handleClickAddWebrmsInfo,
  ] = row;
  return (
    <tr>
      <td>{i}</td>
      <td>{firstName}</td>
      <td>{middleName}</td>
      <td>{lastName}</td>
      <td>{sex}</td>
      <td>{dob}</td>
      <td>{race}</td>
      <td className="text-center">
        <TableActionComponent
          tootltip="Add Web RMS Info"
          children={<RiAddCircleLine />}
          actionFunction={handleClickAddWebrmsInfo}
          color="text-primary"
          text="Add"
        />
      </td>
    </tr>
  );
};