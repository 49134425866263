import React from "react";
import { Form, FormControlProps } from "react-bootstrap";

interface HTMLComponentProps {
  type?: string;
  value: React.ReactNode;
  className: string;
}

export const HTMLComponent: React.FC<HTMLComponentProps> = (props) => {
  const { type, value, className, ...rest } = props;
  const CustomTag = `${type}` as keyof JSX.IntrinsicElements;
  return (
    <>  
      {type === "htmlTag" || typeof type === 'undefined' ? value :
        <CustomTag {...rest} className={className}>
          {value}
        </CustomTag>
      }
    </>
  );
};
