import React, { useCallback, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/hooks';
import { useLoginReducer } from '../../redux/slice/loginSlice';
import { changeUserProfile, updateElementValueUserChangeProfileReducer, useUserChangeProfileReducer } from '../../redux/slice/userChangeProfileSlice';
import ButtonComponent from '../common/ButtonComponent';
import FormComponent from '../common/FormComponent';
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import * as yup from "yup";
import { formatPhoneNumber, isEmailValidation, isValidPassword, isValidPhone } from '../common/CommonUtil';
import { ROLE_AGENCY_ADMIN, ROLE_POLICE_OFFICER } from '../../constants/common';
import { merge } from '../../utils/common';



interface UserChangeProfilePopupProps {
    show: boolean;
    onHide: () => void;
}

export const UserChangeProfilePopup: React.FC<UserChangeProfilePopupProps> = (props) => {
    let regExp = /^[0-9\b]+$/;

    let defaultUserSchema = yup.object().shape({
      username: yup.string().required("User Name is required."),
      userType: yup.string().required("User Type is required."),
      email: yup
        .string()
        .required("Email is required.")
        .test("check-email", function (value) {
          if (!isEmailValidation(userChangeProfileReducer.email)) {
            return this.createError({
              message: "Please insert a valid Email Address",
            });
          } else {
            return true;
          }
        }),
      workPhone: yup
        .string()
        .required("Work Phone is required.")
        .test("validate-workphone", function (value) {
          if (
            userChangeProfileReducer.workPhone.length > 0 &&
            !isValidPhone(userChangeProfileReducer.workPhone)
          ) {
            return this.createError({
              message: "Please insert a valid Work Phone",
            });
          } else {
            return true;
          }
        }),
      homePhone: yup.string().test("validate-homephone", function (value) {
        if (
          userChangeProfileReducer.homePhone.length > 0 &&
          !isValidPhone(userChangeProfileReducer.homePhone)
        ) {
          return this.createError({
            message: "Please insert a valid Home Phone",
          });
        } else {
          return true;
        }
      }),
      mobileNo: yup.string().test("validate-mobilephone", function (value) {
        if (
          (userChangeProfileReducer.mobileNo.length > 0 && userChangeProfileReducer.mobileNo.length < 10) ||
          userChangeProfileReducer.mobileNo == null
        ) {
          return this.createError({
            message: "Please insert a valid Mobile Phone",
          });
        } else {
          return true;
        }
      }),
      workExtension: yup.string().test("validate-wextension", function (value) {
        if (
          userChangeProfileReducer.workExtension != null &&
          userChangeProfileReducer.workExtension.length > 0 &&
          !regExp.test(userChangeProfileReducer.workExtension)
        ) {
          return this.createError({
            message: "Please insert a valid Work Phone Extension.",
          });
        } else {
          return true;
        }
      }),
      homeExtension: yup.string().test("validate-hextension", function (value) {
        if (
          userChangeProfileReducer.homeExtension != null &&
          userChangeProfileReducer.homeExtension.length > 0 &&
          !regExp.test(userChangeProfileReducer.homeExtension)
        ) {
          return this.createError({
            message: "Please insert a valid Home Phone Extension.",
          });
        } else {
          return true;
        }
      }),
      firstName: yup.string().trim().required("First Name is required."),
      lastName: yup.string().trim().required("Last Name is required."),
      middleName: yup
        .string()
        .trim("Middle Name cannot contain whitespaces.")
        .strict(true),
    });

    const {show, onHide} = props;

    const dispatch = useAppDispatch();
    const loginReducer = useLoginReducer();
    const navigate = useNavigate();
    const errorMessageAlert = useAlert();
    const [errorKeys, setErrorKeys] = React.useState([""]);
    const [badgeNumberVisible, setBadgeNumberVisible] = React.useState(Boolean);

    const userChangeProfileReducer = useUserChangeProfileReducer();



    useEffect(()=>{
      if (loginReducer.userType === ROLE_AGENCY_ADMIN || loginReducer.userType === ROLE_POLICE_OFFICER) {
        setBadgeNumberVisible(true);
      }
    },[]);

    const saveClick = async () => {
      try {
        let schema = [];
        schema.push(defaultUserSchema);
        
        if (badgeNumberVisible === false) {
          schema.unshift(
            yup.object().shape({
              badgeNumber: yup
                .string()
                .nullable()
                .required("Badge Number is required.")
                .test("validate-badgenumber", function (value) {
                  if (
                    userChangeProfileReducer.badgeNumber != null &&
                    userChangeProfileReducer.badgeNumber.length > 0 &&
                    !regExp.test(userChangeProfileReducer.badgeNumber)
                  ) {
                    return this.createError({
                      message: "Valid Badge Number is required.",
                    });
                  } else {
                    return true;
                  }
                }),
            })
          );
        }

        schema = merge(...schema);
        const user = await schema.validate(userChangeProfileReducer, {
          abortEarly: false,
        });

        dispatch(changeUserProfile({ user, navigate }));

      } catch (err: any) {
        handleError(
          err.inner.map((e: any) => e.path),
          err.errors
        );
      }
    };

    const handleError = useCallback(
      (_errorKeys: string[], errorMessages: string[]) => {
        setErrorKeys(_errorKeys);
        errorMessageAlert.handleError(errorMessages);
        window.scroll({ top: 0, behavior: "smooth" });
      },
      []
    );
    const handleInputChange = (e: any) => {
      const value =  e.target.value === "true" || e.target.value === "false"
        ? e.target.value !== "true"
        : e.target.value;

        
        dispatch(
          updateElementValueUserChangeProfileReducer({
              elementName: e.target.name,
              value : value ,
            })
        );
    }

    const workPhoneNumberFormat = (event: any) => {
      if (userChangeProfileReducer.workPhone.length > 9 && userChangeProfileReducer.workPhone != null) {
        var mobileNumber = formatPhoneNumber(event.target.value);
        dispatch(
          updateElementValueUserChangeProfileReducer({
            elementName: event.target.name,
            value: mobileNumber,
          })
        );
      }
    };
  
    const MobileNumberFormat = (event: any) => {
      if (userChangeProfileReducer.mobileNo.length > 9 && userChangeProfileReducer.mobileNo != null) {
        var mobileNumber = formatPhoneNumber(event.target.value);
        dispatch(
          updateElementValueUserChangeProfileReducer({
            elementName: event.target.name,
            value: mobileNumber,
          })
        );
      }
    };

    const data = [

        {
          type: "textInput",
          props: {
            labelName: "UserName",
            name: "username",
            value: userChangeProfileReducer.username,
            isRequired : true,
            onChange: handleInputChange,
            disabled : true,
          },
          colSize: 12,
        },
        {
            type: "textInput",
            props: {
              type: "password",
              name: "password",
              labelName: "Password",
              value : userChangeProfileReducer.password,
              disabled : true,
              isRequired : true,
              onChange : handleInputChange
            },
            colSize: 12,
        },
        {
            type: "textInput",
            props: {
              type: "text",
              name: "firstName",
              labelName: "First Name",
              value : userChangeProfileReducer.firstName,
              isRequired : true,
              onChange : handleInputChange
            },
            colSize: 12,
        },
        {
            type: "textInput",
            props: {
              type: "text",
              name: "lastName",
              labelName: "Last Name",
              value : userChangeProfileReducer.lastName,
              isRequired : true,
              onChange : handleInputChange
            },
            colSize: 12,
        },
        {
            type: "textInput",
            props: {
              type: "text",
              name: "middleName",
              labelName: "Middle Name",
              value : userChangeProfileReducer.middleName,
              onChange : handleInputChange
            },
            colSize: 12,
        },
        
        {
            type: "textInput",
            props: {
              type: "number",
              name: "badgeNumber",
              className : badgeNumberVisible ? "d-block" : "d-none",
              value : userChangeProfileReducer.badgeNumber,
              onChange : handleInputChange
            },
            colSize: 12,
        },
        {
            type: "textInput",
            props: {
              type: "text",
              name: "workPhone",
              labelName: "Work Phone",
              value : userChangeProfileReducer.workPhone,
              isRequired : true,
              maxLength: 12,
              onBlur: workPhoneNumberFormat,
              onChange : handleInputChange
            },
            colSize: 8,
        },
        {
            type: "textInput",
            props: {
              type: "text",
              name: "workExtension",
              labelName: <>&nbsp;</>,
              value : userChangeProfileReducer.workExtension,
              onChange : handleInputChange
            },
            colSize: 4,
        },
        {
            type: "textInput",
            props: {
              type: "text",
              name: "mobileNo",
              labelName: "Mobile Phone",
              maxLength: 12,
              value : userChangeProfileReducer.mobileNo,
              onBlur: MobileNumberFormat,
              onChange : handleInputChange
            },
            colSize: 12,
        },
        {
            type: "textInput",
            props: {
              type: "text",
              name: "email",
              labelName: "Email",
              value : userChangeProfileReducer.email,
              isRequired : true,
              onChange : handleInputChange
            },
            colSize: 12,
        },
        {
            type: "htmlTag",
            props: {
              type: "label",
              value: "(Multiple email address must be seperated with comma e.g. email add. 1, email add. 2)",
            },
            colSize: 12,
        },
        {
          type: "checkbox",
          props: {
            label: "Receive Notification",
            name: "receiveNotification",
            value: userChangeProfileReducer.receiveNotification|| false,
            checked: userChangeProfileReducer.receiveNotification || false,
            onChange: handleInputChange,
          },
        },
    ]

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered onHide={props.onHide}
            backdrop='static'
            scrollable={true} >
              {errorMessageAlert.messages.length>0 ? <div className='p-3'>
              <ErrorMessageAlert
                  messages={errorMessageAlert.messages}
                  clearError={errorMessageAlert.clearError}
                />
            </div> :""}
            <Modal.Header><b>Change Profile</b></Modal.Header>
            <Modal.Body>
              <FormComponent 
                totalColumns={12} 
                colSize={6} 
                componentData={data}/>
            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent onClick={()=>saveClick()}>Save</ButtonComponent>
                <ButtonComponent variant='danger' onClick={()=>onHide()}>Close</ButtonComponent>
            </Modal.Footer>
        </Modal>
    );
}