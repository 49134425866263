import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { AgencyType } from "./agencySlice";
import { getAgenciesByUserRole, getECAgenciesByUserRole } from "../../services/AgencyService";

interface AgenciesState {
  agencyList: AgencyType[];
  ecAgencyList: AgencyType[];
  userList: string[];
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  pagedAgencyList: [];
  agencyError?: string;
  pagedAgencyListFetchstatus: LoadingType;
  listAgencyError?: string;
  loggedInUseragenciFetchStatus: LoadingType;
  loggedInUseragenciError?: string;
  updateAgencyFetchStatus: LoadingType;
  updateAgencyError?: string;
  getAgencyAdminNameByAgencyIdFetchStatus: LoadingType;
  getAgencyAdminNameByAgencyIdError?: string;
  agencyId : number;
  status : string;
}

const initialState = {
  agencyList: [],
  ecAgencyList:[],
  userList: [],
  currentPage: 1,
  totalPages: 1,
  totalRecords: 0,
  pagedAgencyList: [],
  pagedAgencyListFetchstatus: "idle",
  listAgencyError: undefined,
  updateAgencyFetchStatus: "idle",
  updateAgencyError: undefined,
  loggedInUseragenciFetchStatus: "idle",
  loggedInUseragenciError: undefined,
  getAgencyAdminNameByAgencyIdFetchStatus: "idle",
  getAgencyAdminNameByAgencyIdError: undefined,
  agencyId : 0,
  status : ""
} as AgenciesState;

export const listAgencies = createAsyncThunk<
  any,
  {
    agencyId?: number;
    status: string;
    pageNo?: number;
  },
  {
    state: RootState;
    rejectValue: string;
  }
>(
  "agencies/listAgencies",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { agencyId = 0, status, pageNo = 0 } = data;

    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `agencyservices/getAgencies/${agencyId}/${status}/${pageNo}`,
        token,
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching agencies list");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching agencies list");
    }
  }
);

export const getLoggedInUserAgencies = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "agencies/loggedInUserAgencies",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await getAgenciesByUserRole({ token });
      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(
          "Error while fetching logged in user agencies list"
        );
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        "Error while fetching logged in user agencies list"
      );
    }
  }
);
export const getNewUserAgencies = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>("agencies/getNewUserAgencies", async (_, { rejectWithValue, dispatch }) => {
  dispatch(showHideLoadingGif(true));
  try {
    const reponse = await doFetch({
      url: `agencyservices/NewUserAgenciesList`,
    });

    if (reponse.ok) {
      dispatch(showHideLoadingGif(false));
      return reponse.json();
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        "Error while fetching logged in user agencies list"
      );
    }
  } catch (error) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue("Error while fetching logged in user agencies list");
  }
});
export const getAgencyAdminNameByAgency = createAsyncThunk<
  any,
  {
    agencyId: any;
  },
  { state: RootState; rejectValue: String }
>(
  "agencies/getAgencyAdminNamwByAgency",
  async (data, { rejectWithValue, dispatch }) => {
    const { agencyId } = data;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: `userprofileservices/getAgencyAdminNameByAgency/` + agencyId,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching Agency Admins of Agency");
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching Agency Admins of Agency");
    }
  }
);


export const updateAgencyStatus = createAsyncThunk<
  any,
  { agenciId : number; listViewData : any},
  { state: RootState; rejectValue: string }
>(
  "agencies/updateAgencyStatus",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { agenciId, listViewData } = data;

    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `agencyservices/updateAgencyStatus/${agenciId}`,
        token,
        type: "PUT",
      });
      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        dispatch(listAgencies(listViewData));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while updating agencies status");
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while updating agencies status");
    }
  }
);

export const getLoggedInUserECAgencies = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "agencies/loggedInUserECAgencies",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await getECAgenciesByUserRole({ token });
      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(
          "Error while fetching logged in user ECagencies list"
        );
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        "Error while fetching logged in user ECagencies list"
      );
    }
  }
);

const agenciesSlice = createSlice({
  name: "agencies",
  initialState,
  reducers: {
    updateElementValueAgencyListReducer: updateElementValue,
    resetAgenciesData: (state: AgenciesState) => {
      return {
        ...state,
        ...initialState,
      };
    },
    updateAgencyListReducer : (state : AgenciesState, action) => {
      return {
        ...state,
        agencyId : action.payload.agencyId,
        status : action.payload.status,
      };
    },
  },
  extraReducers(builder) {
    builder
      // list agencies
      .addCase(listAgencies.pending, (state) => {
        state.pagedAgencyListFetchstatus = "loading";
        state.listAgencyError = undefined;
      })
      .addCase(listAgencies.fulfilled, (state, action) => {
        state.pagedAgencyListFetchstatus = "success";
        state.totalPages = action.payload.totalPages;
        state.totalRecords = action.payload.totalRecords;
        state.currentPage = action.payload.currentPage;
        state.pagedAgencyList = action.payload.agenciesDTOList;
      })
      .addCase(listAgencies.rejected, (state, action) => {
        state.pagedAgencyListFetchstatus = "error";
        state.listAgencyError = action.payload;
      })

      // getLoggedInUserAgencies
      .addCase(getLoggedInUserAgencies.pending, (state) => {
        state.loggedInUseragenciFetchStatus = "loading";
        state.loggedInUseragenciError = undefined;
      })
      .addCase(getLoggedInUserAgencies.fulfilled, (state, action) => {
        state.loggedInUseragenciFetchStatus = "success";
        state.agencyList = action.payload;
      })
      .addCase(getLoggedInUserAgencies.rejected, (state, action) => {
        state.loggedInUseragenciFetchStatus = "error";
        state.loggedInUseragenciError = action.payload;
      })
      // getLoggedInUserAgencies
      .addCase(getNewUserAgencies.pending, (state) => {
        state.loggedInUseragenciFetchStatus = "loading";
        state.loggedInUseragenciError = undefined;
      })
      .addCase(getNewUserAgencies.fulfilled, (state, action) => {
        state.loggedInUseragenciFetchStatus = "success";
        state.agencyList = action.payload;
      })
      .addCase(getNewUserAgencies.rejected, (state, action) => {
        state.loggedInUseragenciFetchStatus = "error";
        state.loggedInUseragenciError = action.payload;
      })

      // updateAgencyStatus
      .addCase(updateAgencyStatus.pending, (state) => {
        state.updateAgencyFetchStatus = "loading";
        state.updateAgencyError = undefined;
      })
      .addCase(updateAgencyStatus.fulfilled, (state, action) => {
        state.updateAgencyFetchStatus = "success";
      })
      .addCase(updateAgencyStatus.rejected, (state, action) => {
        state.updateAgencyFetchStatus = "error";
        state.updateAgencyError = "Error while updating agencies status";
      })

      .addCase(getAgencyAdminNameByAgency.pending, (state) => {
        state.getAgencyAdminNameByAgencyIdFetchStatus = "loading";
        state.getAgencyAdminNameByAgencyIdError = undefined;
        state.userList = [];
      })
      .addCase(getAgencyAdminNameByAgency.fulfilled, (state, action) => {
        state.getAgencyAdminNameByAgencyIdFetchStatus = "success";
        state.userList = action.payload;
      })
      .addCase(getAgencyAdminNameByAgency.rejected, (state, action) => {
        state.getAgencyAdminNameByAgencyIdFetchStatus = "error";
        state.getAgencyAdminNameByAgencyIdError =
          "Error while fetching agent admin usrlist";
      })

      // getLoggedInUserECAgencies
      .addCase(getLoggedInUserECAgencies.pending, (state) => {
        state.loggedInUseragenciFetchStatus = "loading";
        state.loggedInUseragenciError = undefined;
      })
      .addCase(getLoggedInUserECAgencies.fulfilled, (state, action) => {
        state.loggedInUseragenciFetchStatus = "success";
        state.ecAgencyList = action.payload;
      })
      .addCase(getLoggedInUserECAgencies.rejected, (state, action) => {
        state.loggedInUseragenciFetchStatus = "error";
        state.loggedInUseragenciError = action.payload;
      });
  },
});

export const { updateElementValueAgencyListReducer, resetAgenciesData, updateAgencyListReducer } =
agenciesSlice.actions;

export const useAgencyListReducer = () =>
  useAppSelector((state) => state.agencies);

export default agenciesSlice.reducer;
