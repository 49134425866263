import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementObject } from "../../utils/updateElementObject";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";

interface ecViolationObject {
    violationId: string,
    offenseCode: string,
    violationCode: string,
    violationDescription: string,
    violationType: string,
    countyId: number,
    agencyId: number,
    ncicCode: string,
    repealDate: string,
    auditUserId: string,
    auditTimeStamp: string,
    intialFineAmt: number,
    incrementedFineAmt: number,
    effectiveDate: string,
    inactiveDate: string,
    syncStatus: string,
    courtTypeId: number,
    useOnForm: string,
    parentViolationId: string,
    inChoateOffense: string,
    caseType: string,
    classOfViolation: string,
    remarks: string,
    countyViolationKey: string,
    ucr: string,
    isapprovedbycounty: boolean,
    overWeight: string,
    fineSchedule: string,
    bondAmount: string,
    mustAppear: string,
    paymentVerbiageId: number,
    courtChoiceRequired: boolean,
    complaintVerbiageId: number,
    footerVerbiageId: number,
    agencyName: string,
    verbiage: string,
    instructionVerbiageId: number,
    paymentInstruction: string,
    complaintVerbiageName: string,
  }
interface ecViolationState{
  pageStatus: LoadingType,
  saveStatus: LoadingType,
  status: LoadingType,
  ecViolationList: string[],
  totalRecords: number,
  totalPages: number,
  currentPage: number,  
  pageError: string,  
  error: string,
  saveError: string, 
  ecViolationObject: ecViolationObject
}
  
  const initialState = {
    
      pageStatus: "idle",
      saveStatus: "idle",
      status: "idle",
      ecViolationList: [],
      totalRecords: 0,
      totalPages: 0,
      currentPage: 0,  
      pageError: "",  
      error: "",
      saveError: "", 
      ecViolationObject:{
        violationId: "",
        offenseCode: "",
        violationCode: "",
        violationDescription: "",
        violationType: "",
        countyId: 0,
        agencyId: 0,
        ncicCode: "",
        repealDate: "",
        auditUserId: "",
        auditTimeStamp: "",
        intialFineAmt: 0,
        incrementedFineAmt: 0,
        effectiveDate: "",
        inactiveDate: "",
        syncStatus: "",
        courtTypeId: 0,
        useOnForm: "",
        parentViolationId: "",
        inChoateOffense: "",
        caseType: "",
        classOfViolation: "",
        remarks: "",
        countyViolationKey: "",
        ucr: "",
        isapprovedbycounty: false,
        overWeight: "",
        fineSchedule: "",
        bondAmount: "",
        mustAppear: "",
        paymentVerbiageId: 0,
        courtChoiceRequired: false,
        complaintVerbiageId: 0,
        footerVerbiageId: 0,
        agencyName: "",
        verbiage: "",
        instructionVerbiageId: 0,
        paymentInstruction: "",
        complaintVerbiageName: "",
    }

  } as ecViolationState;

  export const fetchEcViolationList = createAsyncThunk<
  any,
  {
    pageNo: number; 
    refCode:string;
    desc:string;
    status:string;
    isFelony : boolean;
    isMisd : boolean;
    isInchoate : boolean;
    agencyCode:string;
    filingCounty:string;
    prosecutionLevel:string;
    primaryOffDate:string;
  },
  { state: RootState; rejectValue: string }
>("ecViolation/violations",
  async (
    { 
      pageNo,
      refCode,
      desc,
      status,
      isFelony,
      isMisd,
      isInchoate,
      agencyCode,
      filingCounty,
      prosecutionLevel,
      primaryOffDate,
    },
     { getState, rejectWithValue, dispatch }) =>{
    let { token } = getState().login;
    token = `Bearer ${token}`;
    let queryParam = "";
    queryParam += `pageNo=${pageNo}&`;
    queryParam += `isFelony=${isFelony}&`;
    queryParam += `isMisdemeanor=${isMisd}&`;
    queryParam += `isInchoate=${isInchoate}&`;
    if (refCode) queryParam += `&refCode=${refCode}`;
    if (desc) queryParam += `&desc=${desc}`;
    if (agencyCode) queryParam += `&agencyCode=${agencyCode}`;
    if (filingCounty) queryParam += `&filingCounty=${filingCounty}`;
    if (prosecutionLevel) queryParam += `&prosecutionLevel=${prosecutionLevel}`;
    if (primaryOffDate) queryParam += `&primaryOffDate=${primaryOffDate}`;
    if (status === "active") {
        queryParam += `&showActive=true`;
    } else {
        queryParam += `&showAll=true`;
    }
    try {
      dispatch(showHideLoadingGif(true));
      const response: any = await doFetch({
        url: "ecomplaintservices/violations" + `?jwtToken=${token}` + `&${queryParam}`,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      }
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching EcViolation");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching search warrant list");
      console.log('error');
    }

  }
);



export const ecViolationSlice = createSlice({
    name: "ecViolation",
    initialState,
    reducers: {
        updateElementValueEcViolationReducer: updateElementValue,
        updateElementObjectValue: (state: ecViolationState, action) => {
          updateElementObject(state, action, state.ecViolationObject);
        },
        resetEcViolationData: (state: ecViolationState) => {
          return {
            ...state,
            ...initialState,
          };
        },
      },
      extraReducers(builder) {
        builder
          .addCase(fetchEcViolationList.pending, (state) => {
            state.pageStatus = "loading";
          })
          .addCase(fetchEcViolationList.fulfilled, (state, action) => {
            state.pageStatus = "success";
            if (action.payload.statutesList && action.payload.statutesList.length>0){
              state.ecViolationList = action.payload.statutesList;
              state.totalPages = action.payload.totalPages;
              state.totalRecords = action.payload.totalRecords;
              state.currentPage = action.payload.currentPage;
            } else{
              state.ecViolationList = [];
              state.totalPages = 0;
              state.totalRecords = 0;
              state.currentPage = 0;
            }
              })
          .addCase(fetchEcViolationList.rejected, (state) => {
            state.pageStatus = "error";
          })
        }

})
export const { updateElementValueEcViolationReducer, updateElementObjectValue, resetEcViolationData } = ecViolationSlice.actions;
export const useEcViolationReducer = () =>
    useAppSelector((state) => state.ecViolation);
  export default ecViolationSlice.reducer;
