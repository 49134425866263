import React, { useEffect, useCallback } from "react";
import Header from "../../common/Header";
import { RiBallPenLine, RiCloseFill, RiFileTextLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/hooks";
import {
  useOrderOfCustodyReducer,
  saveOrderOfCustody,
  updateElementObjectValue,
  resetOrderOfCustodyData,
} from "../../../redux/slice/orderOfCustodySlice";
import { useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";
import { useSignPanelReducer } from "../../../redux/slice/signPanelSlice";
import * as yup from "yup";
import BreadcrumbComponent from "../../common/BreadcrumbComponent";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";
import FormComponent from "../../common/FormComponent";
import { validateSignaturePixel } from "../../common/CommonUtil";
import ConfirmPopup from "../../../components/common/ConfirmPopup";
import { previewOrder } from "../../../redux/slice/commonFormSlice";
import { ORDER_OF_CUSTODY, ROLE_ADMINISTRATOR, ROLE_AGENCY_ADMIN, ROLE_COUNTY_ADMIN, ROLE_JUDGE, ROLE_POLICE_OFFICER, ROLE_SA_ADMIN, ROLE_STATES_ATTORNEY } from "../../../constants/common";
import { Row, Col } from "react-bootstrap";
import TextInputComponent from "../../common/TextInputComponent";
import CheckBoxComponent from "../../common/CheckBoxComponent";
import {useLoginReducer} from "../../../redux/slice/loginSlice";
import ButtonComponent from "../../common/ButtonComponent";
import FormFiller from "../../common/FormFiller";
import { useDashboardReducer, updateElementValueDashboardReducer } from "../../../redux/slice/dashboardSlice";

let defaultOfCSchema = yup.object().shape({
  txtPersonPremises: yup
    .string()
    .nullable()
    .required("Person Premises is required."),
  chkImpoundTest: yup
    .boolean()
    .nullable()
    .required("Warrant impound option is not selected."),
  sign: yup.string().nullable().required("Signature is required."),
});

interface OrderOfCustodyProps {}

let fieldTitles =[' '];
let names = ['txtPersonPremises'];

const OrderOfCustody: React.FC<OrderOfCustodyProps> = (props) => {

  const dashboardReducer = useDashboardReducer();
  
  const  onBreadcrumbClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/"+dashboardReducer.PreviousURLSW.replace(/^.*\/\/.*?\//, ''), itemName: "Warrant List", itemFunction: onBreadcrumbClick  },
    { itemLink: "/CaseDetails", itemName: "Existing Forms" },
  ];

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const OrderOfCustodyReducer = useOrderOfCustodyReducer();
  const signPanelReducer = useSignPanelReducer();
  const caseDetailReducer = useCaseDetailReducer();
  const errorMessageAlert = useAlert();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const [signConfirmationModalShow, setsignConfirmationModalShow] =
    React.useState(false);
  const [signatureCanvasVisible, setSignatureCanvasVisible] =
    React.useState(false);
  const [signPerson, setSignPerson] = React.useState("");
  const [whoSign, setWhoSign] = React.useState("");
  const loginReducer = useLoginReducer();

  const [showFormFiller, setShowFormFiller] = React.useState(false);
  const [fieldTitleValues, setFieldTitleValues] = React.useState<String[]>([]);
  const [loadUserSignature, setLoadUserSignature] = React.useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetOrderOfCustodyData());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      selectSignRole();
    };
  }, [OrderOfCustodyReducer.createStatus === "success"]);

  useEffect(() => {
    if (loginReducer.userType === ROLE_JUDGE && OrderOfCustodyReducer.orderOfCustodyObj && !OrderOfCustodyReducer.orderOfCustodyObj.hiddenJudgeSignature) {
      setLoadUserSignature(true);
    } else if (OrderOfCustodyReducer.orderOfCustodyObj && !OrderOfCustodyReducer.orderOfCustodyObj.hiddenComplaintSignature) {
        setLoadUserSignature(true);
      }
    
  }, [])

  const cancelSignConfirmModal = () => {
    setsignConfirmationModalShow(false);
  };

  const handleChangeObjectValueForCkEditor = (name: string, editor: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: name,
        value: editor.getData(),
      })
    );
  };

  const getSelectFormWarrantStatus = (formType:string) => {
    if(caseDetailReducer.searchWarrantList !=null && caseDetailReducer.searchWarrantList.length>0){
        for (let i = 0; i < caseDetailReducer.searchWarrantList.length; i++) {
        let swObj = caseDetailReducer.searchWarrantList[i];
				if(swObj.formType === formType ){
					return swObj.formStatus;
				}
			}
		}
		return "";
  }

  const selectSignRole = () => {

    let formStatus = getSelectFormWarrantStatus("Order of Custody");

    if (loginReducer.userType === ROLE_ADMINISTRATOR || loginReducer.userType === ROLE_COUNTY_ADMIN) {
      if (formStatus === "" || formStatus === "Incomplete" || formStatus === "Officer Pending") {
        setWhoSign("OFFICER SIGNATURE");
        setSignPerson(loginReducer.firstName + " " + loginReducer.lastName);
      }

      // if(formStatus.status === "Locked by Judge" || formStatus.status === "Assigned to Judge"){
      // String userNameValue = EntityUtility.getFormatedLoginUserName((UserProfile)sessionMap.get(ApplicationConstants.USERID));
      // }

    } else {
      if (caseDetailReducer.creatorId === loginReducer.userProfileId) {
        if (loginReducer.userType === ROLE_POLICE_OFFICER || loginReducer.userType === ROLE_STATES_ATTORNEY || loginReducer.userType === ROLE_SA_ADMIN || loginReducer.userType === ROLE_AGENCY_ADMIN) {
          setWhoSign("OFFICER SIGNATURE");
          setSignPerson(loginReducer.firstName + " " + loginReducer.lastName);
        }
      }
    }

    if (loginReducer.userType === ROLE_JUDGE) {
      setWhoSign("JUDGE SIGNATURE");
      setSignPerson(loginReducer.firstName + " " + loginReducer.lastName);
    }

  };

  const handleValChangeCheck = (event: any) => {
    if ("chkImpounded" === event.target.name) {
      dispatch(
        updateElementObjectValue({
          elementName: event.target.name,
          value: event.target.checked,
        })
      );
      dispatch(
        updateElementObjectValue({
          elementName: "chkNotImpounded",
          value: !event.target.checked,
        })
      );
    } else if ("chkNotImpounded" === event.target.name) {
      dispatch(
        updateElementObjectValue({
          elementName: event.target.name,
          value: event.target.checked,
        })
      );
      dispatch(
        updateElementObjectValue({
          elementName: "chkImpounded",
          value: !event.target.checked,
        })
      );
    }
  };

  const onsign = async () => {
    try {
      let Obj = {
        txtPersonPremises:
          OrderOfCustodyReducer.orderOfCustodyObj.txtPersonPremises,
        sign: signPanelReducer.sign,
        chkImpoundTest:
          OrderOfCustodyReducer.orderOfCustodyObj.chkImpounded ||
          OrderOfCustodyReducer.orderOfCustodyObj.chkNotImpounded,
      };
      await defaultOfCSchema.validate(Obj, {
        abortEarly: false,
      });
      let isValidateSignPixel = validateSignaturePixel(
        signatureCanvasVisible,
        signPanelReducer.signThreshold
      );
      if (!isValidateSignPixel) {
        setsignConfirmationModalShow(true);
      } else {
        setsignConfirmationModalShow(true);
      }
      dispatch(updateElementObjectValue({elementName:"hiddenJudgeSignature", value:signPanelReducer.sign}));
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  function prepareMessage(msg: any) {
    let buff = [];
    if (msg && msg.trim().length > 0) {
      let arr = msg.split("<br>");
      let line = "";
      for (let i = 0; i < arr.length; i++) {
        line = arr[i];
        buff.push(<div>{line}</div>);
      }
    }
    return buff;
  }

  const handleConfirmation = () => {
    dispatch(
      saveOrderOfCustody({
        searchWarrantId: caseDetailReducer.searchWarrantId,
        formdata: OrderOfCustodyReducer.orderOfCustodyObj,
        navigate: navigate,
      })
    );
  };

  const previewForm = () => {
    dispatch(
      previewOrder({
        formId: OrderOfCustodyReducer.orderOfCustodyObj.searchWarrantId,
        formName: ORDER_OF_CUSTODY,
        formObject: OrderOfCustodyReducer.orderOfCustodyObj,
      })
    );
  };

  const formFillerClick = () =>{
    let array = [
      OrderOfCustodyReducer.orderOfCustodyObj.txtPersonPremises
    ];
    setShowFormFiller(true);
    setFieldTitleValues(array);
  }

  const updateFieldFromWizard = (name: string, value: string) =>{
    dispatch(updateElementObjectValue({elementName:name, value: value}));
  }

  const formData = [
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: OrderOfCustodyReducer.orderOfCustodyObj.lblHeadingStateSW,
        className: "fw-bold text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h5",
        value: (
          <>
            UNITED STATES OF AMERICA
            <br />
            {OrderOfCustodyReducer.orderOfCustodyObj.lblHeadingInTheCircuitSW}
          </>
        ),
        className: "fw-bold text-center",
      },
      colSize: 6,
    },
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value:
          "COUNTY OF " + OrderOfCustodyReducer.orderOfCustodyObj.countyName,
        className: "fw-bold text-end text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
    },
    {
      type: "customComponentsfortitle",
      props: {},
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h4",
        value: "ORDER OF CUSTODY",
        className: "fw-bold text-center text-danger",
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        value: OrderOfCustodyReducer.orderOfCustodyObj.txtCaseNumber,
        name: "templateName",
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
      emptyCol: 10,
    },
    {
      type: 'formButton',
      props: {
        children: "Form Filler Wizard",
        onClick: formFillerClick,
      },
      inLine: true,
      colSize: 2,
    },
    // {
    //   type: "htmlTag",
    //   props: {
    //     value: <hr />,
    //   },
    //   colSize: 12,
    // },
    {
      type: "editor",
      props: {
        name: "txtPersonPremises",
        data: OrderOfCustodyReducer.orderOfCustodyObj.txtPersonPremises
          ? OrderOfCustodyReducer.orderOfCustodyObj.txtPersonPremises
          : "",
        onChange: (event: any, editor: any) => {
          handleChangeObjectValueForCkEditor("txtPersonPremises", editor);
        },
        isError: errorKeys.includes("txtPersonPremises"),
      },
      colSize: 12,
    },
    {
      type: "customComponentforDescription",
      props: {},
      colSize: 12,
    },
    {
      type: "customComponentsforCheckbox",
      props: {},
      colSize: 12,
    },
    {
      type: "signPanel",
      props: {
        name: "sign",
        appearPersonText : signPerson,
        whoSign : whoSign,
        isError: errorKeys.includes("sign"),
        loadUserSignature : loadUserSignature
      },
      colSize: 12,
    },
    {
      type: "button",
      colSize: 2,
      props: {
        variant: "success",
        className: "btn-primary ms-2",
        children: (
          <>
            Sign <RiBallPenLine />
          </>
        ),
        onClick: onsign,
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-primary ms-2",
        children: (
          <>
            Preview <RiFileTextLine />
          </>
        ),
        onClick: previewForm,
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-danger ms-2",
        children: (
          <>
            <RiCloseFill /> Cancel
          </>
        ),
        onClick: () => {
          navigate("/CaseDetails");
        },
      },
    },
  ];

  return (
    <>
      <Header headerName="Order of Custody" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Order of Custody"
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={formData}
          components={[
            {
              type: "customComponentforDescription",
              node: () => (
                <>
                <Row className="g-3 align-items-center">
                <Col xs={"auto"}>
                      <span className="me-3">This matter coming before the Court for the filling of a Search Warrant Inventory Return, and this Court having jurisdiction and being fully advised of the premises:</span>
                      </Col>
                </Row>
                  <Row className="g-3 align-items-center">
                    <Col xs={"auto"}>
                      <span className="me-2 fw-bold">IT IS HEREBY ORDERED</span>
                      <span className="me-3">
                        that any and all articles seized as listed in the Search
                        Warrant Inventory shall remain in the custody of
                      </span>
                      <div className="d-inline-block mt-2 me-3 w-25">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          name="txtAgency"
                          value={
                            OrderOfCustodyReducer.orderOfCustodyObj.txtAgency
                          }
                        />
                      </div>
                      <span className="me-3">
                        or its designated agents, until further order of this
                        Court or upon disposition of the underlying criminal
                        case.
                      </span>
                    </Col>
                    <Col xs={12}>
                      <label>
                        <span className="fw-bold">IT IS FURTHER ORDERED</span>{" "}
                        that the above-captioned case:
                      </label>
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              type: "customComponentsfortitle",
              node: () => (
                <>
                  <div className="fw-bold text-center">
                    THE PEOPLE OF THE STATE OF ILLINOIS
                  </div>
                  <div className="mb-71 fw-bold text-center">V</div>
                  <br />
                </>
              ),
            },
            {
              type: "customComponentsforCheckbox",
              node: () => (
                <>
                  <Row>
                    <Col xs={"auto"}>
                      <CheckBoxComponent
                        label="Shall be impounded until further order of the Court."
                        name="chkImpounded"
                        checked={
                          OrderOfCustodyReducer.orderOfCustodyObj.chkImpounded
                        }
                        isError={errorKeys.includes("chkImpoundTest")}
                        onChange={handleValChangeCheck}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={"auto"}>
                      <CheckBoxComponent
                        label="Shall not be impounded unless otherwise ordered by the Court."
                        name="chkNotImpounded"
                        checked={
                          OrderOfCustodyReducer.orderOfCustodyObj
                            .chkNotImpounded
                        }
                        isError={errorKeys.includes("chkImpoundTest")}
                        onChange={handleValChangeCheck}
                      />
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              type: "formButton",
              node: (props: any) => (
                <div className="text-end">
                <ButtonComponent {...props}>{props.children}</ButtonComponent>
              </div>
                
              ),
            }
          ]}
        />
        <ConfirmPopup
          show={signConfirmationModalShow}
          onHide={cancelSignConfirmModal}
          isConfirmation={handleConfirmation}
        >
          {prepareMessage(
            "Form will be saved with Complete status. Do you want to continue?"
          )}
        </ConfirmPopup>
        <FormFiller
       show={showFormFiller}
       onHide = {() => setShowFormFiller(false)}
       fieldTitles={fieldTitles}
       names = {names}
       values = {fieldTitleValues}
       onChangeHandler={updateFieldFromWizard}
      />
      </div>
    </>
  );
};

export default OrderOfCustody;
