import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { USER_TYPES } from "../../constants/common";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
interface CountyType {
  countyID: number;
  countyName: string;
  judicialNumber: string;
  street: string;
  circuitClerkName: string;
  state: string;
  isSARequired: boolean;
  city: string;
  isOFCRequired: boolean;
  zip: string;
  appliedFileStamp: boolean;
  longFormEnabled: boolean;
  isLPAllowed: boolean;
  ecAppliedFileStamp: boolean;
  integratedWithCMS: boolean;
  multiCaseNumber: boolean;
  notarizationReq: boolean;
  felonyScreeningRequired: boolean;
  eorderEnabled: boolean;
  cacheLFCasenumbers: boolean;
  watermarkDraft: boolean;
  useOfficersFromIucs: boolean;
  hideCompletedSW: boolean;
  sendEmail: boolean;
  preTrialReleaseOnOwnRecognizance: boolean;
  revisedProbableCauseStatement: boolean;
  warrentlessFormType: string;
  inventoryReturnType: string;
  customGeoLimits: string;
  cmsUrl: string;
  cmsUser: string;
  cmsPassword: string;
  fileStamp: string;
  summonsToAppear: boolean;
  receiptEnable: boolean; //KHL PR 11264 09/01/24
  impoundEnable: boolean; //KHL PR 1256 25/01/24
  allSwsEnable: boolean; //KHL PR 11294 13/02/24
}
interface CountyReducerType extends CountyType {
  getCountyFetchStatus: LoadingType;
  getCountyError?: string;
  createCountyStatus: LoadingType;
  createCountyError?: string;
  updateCountyStatus: LoadingType;
  updateCountyError?: string;
  successMessage?: string;
}

const initialCountyState: CountyType = {
  countyID: 0,
  countyName: "",
  judicialNumber: "",
  street: "",
  circuitClerkName: "",
  state: "",
  isSARequired: false,
  city: "",
  isOFCRequired: false,
  zip: "",
  appliedFileStamp: false,
  longFormEnabled: false,
  isLPAllowed: false,
  ecAppliedFileStamp: false,
  integratedWithCMS: false,
  multiCaseNumber: false,
  notarizationReq: false,
  felonyScreeningRequired: false,
  eorderEnabled: false,
  cacheLFCasenumbers: false,
  watermarkDraft: false,
  useOfficersFromIucs: false,
  hideCompletedSW: false,
  sendEmail: false,
  preTrialReleaseOnOwnRecognizance: false,
  revisedProbableCauseStatement: false,
  warrentlessFormType: "",
  inventoryReturnType: "",
  customGeoLimits: "",
  cmsUrl: "",
  cmsUser: "",
  cmsPassword: "",
  fileStamp: "",
  summonsToAppear: false,
  receiptEnable: false, //KHL PR 11264 09/01/24
  impoundEnable: false, //KHL PR 1256 25/01/24
  allSwsEnable: false //KHL PR 11294 13/02/24
};

const initialState = {
  ...initialCountyState,
  getCountyFetchStatus: "idle",
  getCountyError: undefined,
  createCountyStatus: "idle",
  createCountyError: undefined,
  updateCountyStatus: "idle",
  updateCountyError: undefined,
  successMessage: undefined,
} as CountyReducerType;

export const getCountyDataById = createAsyncThunk<
  any,
  {
    id: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "county/getCountyDataById",
  async ({ id, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token, userType } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "countieservices/counties/" + id,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      }
      if (userType === USER_TYPES.ADMINISTRATOR) {
        dispatch(showHideLoadingGif(false));
        navigate("/CountyList");
      } else {
        dispatch(showHideLoadingGif(false));
        navigate("/");
      }

      const error = await response.text();
      return rejectWithValue(error || "Error while fetching county data");
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      if (userType === USER_TYPES.ADMINISTRATOR) {
        navigate("/CountyList");
      } else {
        navigate("/");
      }
      return rejectWithValue(
        error.message || "Error while fetching county data"
      );
    }
  }
);

export const createCounty = createAsyncThunk<
  any,
  { county: CountyType; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "county/createCounty",
  async ({ county, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "countieservices/counties",
        token,
        body: county,
        type: "POST",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const createdCountyName = await response.text();
        navigate(-1);
        return {
          message: `${createdCountyName} county created successfully`,
        };
      } else {

        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while creating county");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while creating county");
    }
  }
);

export const updateCounty = createAsyncThunk<
  any,
  {
    county: CountyType;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "county/updateCounty",
  async ({ county, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;

    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "countieservices/counties/" + county.countyID,
        token,
        body: county,
        type: "PUT",
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const updatedCountyName = await response.text();
        navigate("/CountyList");
        return {
          message: `${updatedCountyName} county updated successfully`,
        };
      }
      else {

        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while updating county");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while updating county");
    }
  }
);

const countySlice = createSlice({
  name: "county",
  initialState,
  reducers: {
    updateElementValueCountyReducer: updateElementValue,
    updateCountyObject: (state: CountyReducerType, action) => {
      let object = action.payload as CountyReducerType;
      return {
        ...state,
        ...object,
      };
    },
    resetCountyData: (state: CountyReducerType) => {
      return {
        ...state,
        ...initialCountyState,
      };
    },
  },
  extraReducers(builder) {
    builder
      // Get county data by id
      .addCase(getCountyDataById.pending, (state, action) => {
        state.getCountyFetchStatus = "loading";
        state.getCountyError = undefined;
      })
      .addCase(getCountyDataById.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          getCountyFetchStatus: "success",
        };
      })
      .addCase(getCountyDataById.rejected, (state, action) => {
        state.getCountyFetchStatus = "error";
        state.getCountyError = action.payload;
      })
      // Create county
      .addCase(createCounty.pending, (state) => {
        state.createCountyStatus = "loading";
        state.createCountyError = undefined;
      })
      .addCase(createCounty.fulfilled, (state, action) => {
        state.createCountyStatus = "success";
        state.successMessage = action.payload.message;
      })
      .addCase(createCounty.rejected, (state, action) => {
        state.createCountyStatus = "error";
        state.createCountyError = action.payload;
      })
      // Update county
      .addCase(updateCounty.pending, (state) => {
        state.updateCountyStatus = "loading";
        state.updateCountyError = undefined;
      })
      .addCase(updateCounty.fulfilled, (state, action) => {
        state.updateCountyStatus = "success";
        state.successMessage = action.payload.message;
      })
      .addCase(updateCounty.rejected, (state, action) => {
        state.updateCountyStatus = "error";
        state.updateCountyError = action.payload;
      });
  },
});

export const { updateElementValueCountyReducer, resetCountyData, updateCountyObject } =
  countySlice.actions;

export const useCountyReducer = () => useAppSelector((state) => state.county);

export default countySlice.reducer;
