import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";

interface dashboardState {
  successMessage?: string;
  searchWarrantIds : string[];
  status: LoadingType;
  incompleteSearchWarrantCountList :  any[];
  cardName : string;
  isCardActive : boolean;
  previousLink : string;
  listFrom :  string;
  PreviousURLSW :  string;
  PreviousURLLFC: string;
  pendingSWCount: string;
}

const initialState = {
  incompleteSearchWarrantCountList: [],
  searchWarrantIds : [],
  status: "idle",
  successMessage: undefined,
  cardName : "",
  isCardActive : false,
  previousLink : "",
  listFrom : "",
  PreviousURLSW : "",
  PreviousURLLFC: "",
  pendingSWCount: ""
} as dashboardState;

export const getIncompleteSearchWarrantCount = createAsyncThunk<
  any,
  undefined,
  {
    state: RootState;
  }
>(
  "dashboard/getIncompleteSearchWarrantCount",
  async (_, { getState, rejectWithValue, dispatch }) => {
    
    let { token } = getState().login;

    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `docrootservices/getIncompleteSearchWarrantCount`,
        token,
      });
      dispatch(showHideLoadingGif(false));
      return await reponse.json();
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching data");
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateElementValueDashboardReducer: updateElementValue,
    resetDashboardData: (state: dashboardState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getIncompleteSearchWarrantCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIncompleteSearchWarrantCount.fulfilled, (state, action) => {
        state.status = "success";
        state.incompleteSearchWarrantCountList = action.payload;
      })
      .addCase(getIncompleteSearchWarrantCount.rejected, (state, action) => {
        state.status = "error";
      });
  },
});

export const {
  updateElementValueDashboardReducer,
  resetDashboardData,
} = dashboardSlice.actions;

export const useDashboardReducer = () =>
  useAppSelector((state) => state.dashboard);

export default dashboardSlice.reducer;
