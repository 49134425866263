import React from "react";
import { Col, Form, FormControlProps, Row } from "react-bootstrap";
import { RiCheckboxCircleFill } from "react-icons/ri";

export type TextInputProps = {
  labelName?: string;
  className?: string;
  isError?: boolean;
  isWarning?: boolean;
  isRequired?: boolean;
  name?: string;
  accept?: string;
  isCorrect?: boolean;
  max?: string;
  inLine?: boolean;
  labelSize?: string;
  autoComplete?: string;
  min?: string;
} & FormControlProps;

const TextInputComponent = React.forwardRef((props: TextInputProps, ref) => {
  const {
    labelName,
    className,
    isError,
    isWarning,
    isRequired,
    name,
    isCorrect,
    max,
    inLine,
    labelSize,
    min,
    ...rest
  } = props;
  return (
    <Col>
      <Form.Group as={inLine ? Row : Col}>
        {labelName ? (
          <Form.Label column sm={`${labelSize || "auto"}` as any}>
            {labelName} {isRequired ? <span>*</span> : ""}
          </Form.Label>
        ) : (
          ""
        )}
        <Col>
          <Form.Control
            {...rest}
            className={`${className ? className : ""} ${
              isError ? "border-danger" : ""
            } ${isWarning ? "border-warning" : ""} ${"p-2"}`}
            name={name}
            ref={ref}
            max={max}
            min={min}
          />
          {isCorrect ? (
            <div className="isCorrect isCorrect-input">
              <RiCheckboxCircleFill />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Form.Group>
    </Col>
  );
});

export default TextInputComponent;
