import React, { useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import TextInputComponent from "./TextInputComponent";
import { useAppDispatch } from "../../hooks/hooks";
import {
  loginUser,
  updateElementValueInLoginReducer,
  useLoginReducer,
} from "../../redux/slice/loginSlice";
import { RELOGIN_PREVIEW_TIME } from "../../constants/common";

interface ReLoginProps {
  show: boolean;
  onHide: () => void;
}

export const ReLogin: React.FC<ReLoginProps> = (props) => {
  const { show, onHide } = props;
  const dispatch = useAppDispatch();
  const { username, password, incorrectCredentialError, refreshTokenExpired } =
    useLoginReducer();
  const [counter, setCounter] = React.useState(RELOGIN_PREVIEW_TIME);

  const handleValChange = (event: any) => {
    dispatch(
      updateElementValueInLoginReducer({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  useEffect(() => {
    if (refreshTokenExpired) {
      const interval: any = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter <= 1) {
            dispatch(
              updateElementValueInLoginReducer({
                elementName: "loadingpopupString",
                value: false,
              })
            );
            onHide();
            clearInterval(interval);
            return prevCounter;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else {
      setCounter(RELOGIN_PREVIEW_TIME);
    }
  }, [dispatch, onHide, refreshTokenExpired]);

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <b>Re-login User</b>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="mt-2">
            <p>
              Your session is going to expire. Please add your credentials again
              within
              <span className="text-danger">&nbsp;{counter}</span> seconds to
              continue.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3 fw-bold">
              <label>Username</label>
              <TextInputComponent
                type="text"
                className="form-control"
                placeholder="Enter username"
                name="username"
                value={username}
                onChange={(e) => handleValChange(e)}
              />
            </div>
            <div className="mb-3 fw-bold">
              <label>Password</label>
              <TextInputComponent
                type="password"
                className="form-control"
                placeholder="Enter password"
                name="password"
                value={password}
                onChange={(e) => handleValChange(e)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch(
                      loginUser({
                        username: username,
                        password: password,
                      })
                    );
                  }
                }}
              />
            </div>
            <label className="text-danger pt-3">
              {incorrectCredentialError}
            </label>
          </Col>
        </Row>
        <hr />
        <Row className="g-2">
          <Col className="text-end">
            <Button
              onClick={() =>
                dispatch(
                  loginUser({
                    username: username,
                    password: password,
                  })
                )
              }
              className="ps-5 pe-5"
            >
              Login
            </Button>{" "}
            <Button
              variant="danger"
              onClick={() => onHide()}
              className="ps-5 pe-5"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
