import { FetchType } from "../types";
import { updateElementValueInCommonReducer, updatePdfGenerationError, updateDocGenerateError } from "../redux/slice/commonSlice";
import { Buffer } from 'buffer';
/**
 * This method is a wrapper of the fetch method.
 * NOTE: USE THE REQUEST PARAMETER IF YOUR BODY IS NOT A JSON OBJECT,
 * BECAUSE THE FETCH METHOD WILL STRINGIFY THE BODY BY DEFAULT.
 * @param {url, type , body, headers, token, request}
 * (The base url will be appended to the begining by default to the url passed)
 * (type is the fetch method type; by defalut it is set to "GET")
 * (body is a javascript object)
 * (For "PUT" and "POST" method the default headers for passing json objetcs is added, can be overriden by passing headers.)
 * (The token will be added to the url if it is passed)
 * (The request parameter is used to override the request object passed to the fetch method)
 * @returns
 */

export const doFetch = async ({
  url,
  type = "GET",
  body,
  headers,
  token,
  request,
  defaultBody,
  concurrentObject,
  concurrentIdentifier,
  dispatch,
}: {
  url: string;
  type?: FetchType;
  body?: any;
  headers?: HeadersInit;
  token?: string;
  request?: RequestInit;
  defaultBody?: boolean;
  concurrentObject?: any;
  concurrentIdentifier?: string;
  dispatch?: any;
}) => {
  let fethcUrl = token ? appendToken(url, token) : url;
  fethcUrl = concurrentObject ? appendConcurrentJson(fethcUrl, concurrentObject, concurrentIdentifier) : fethcUrl;

  if (request) {
    return fetch(fethcUrl, request);
  }

  const response = await fetch(
    // properties.restURL + fethcUrl,
   process.env.REACT_APP_REST_URL + fethcUrl,
    type === "POST" || type === "PUT" || type === "DELETE"
      ? {
          method: type,
          headers: headers || {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: defaultBody ? body : JSON.stringify(body),
        }
      : {}
  );

  if (response.ok) {
    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/text") !== -1) {
      response.text().then((result) => {
       if (result.length >0 ) {
          dispatch(
            updateElementValueInCommonReducer({
              elementName: "concurrentMsg",
              value: result,
            })
          );
          dispatch(
            updateElementValueInCommonReducer({
              elementName: "concurrentPopup",
              value: true,
            })
          );
          return;
        }
      });
    }
  } else {
    if (response.status && response.status === 406) {
      response.text().then((result) => {
        dispatch(
          updatePdfGenerationError({
            error: true,
            errorMsg: "Document generation failed, There may be special characters or content that is in this document that are not supported by the app. Please remove those special characters and try again.",
          })
        );
        return;
      });
    } else if  (response.status && response.status === 412) {
      response.text().then((result) => {
         dispatch(
          updatePdfGenerationError({
            error: true,
            errorMsg: "Document generation will be failed, There may be special characters or content that is in this document that are not supported by the app. Please remove those special characters and try again.",
          })
        );
        return;
      });
    } else if  (response.status && response.status === 307) {
      response.text().then((result) => {
         dispatch(
          updatePdfGenerationError({
            error: true,
            errorMsg: "Document generation service is unavailable this time, please try again later.",
          })
        );
        return;
      });
    } else if(response.status && response.status === 410) {
      response.text().then((result) => {
        dispatch(
         updatePdfGenerationError({
           error: true,
           errorMsg: "Filed complaint document is corrupted or not found, Please contact support@conscisys.com with information about your document for help.",
         })
       );
       return;
     });
    }
    /*else if (response.status && response.status === 411) {
      console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&==409=409=409=409=====");
      const contentType = response.headers.get("content-type");
      console.log(contentType);
      console.log("response-response-response-response");
      console.log(response);
      if (contentType) {
        if (contentType.indexOf("application/json") !== -1) {
          response.json().then((result) => {
            console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
            console.log(result);
            console.log("&&&&&&&&&&&&&&====");
            for (const [key, value] of Object.entries(result)) {
              console.log(key + ":" + value);
            }
            if (result) {
              dispatch(
                updateDocGenerateError({
                  error: true,
                  errorMsg: result,
                })
              );
              return;
            }
          });
        }
      }
    }*/
  }
  return response;
};

const appendToken = (url: string, token: string) => {
  if (url) {
    if (url.includes("?")) {
      if (url.endsWith("&")) {
        return `${url}jwtToken=Bearer ${token}`;
      } else {
        return `${url}&jwtToken=Bearer ${token}`;
      }
    } else {
      return `${url}?jwtToken=Bearer ${token}`;
    }
  }
  return url;
};

const appendConcurrentJson = (url: string, concurrentObject: any, concurrentIdentifier: any) => {
  if (url) {
    let restURL;
    if (url.includes("?")) {
      if (url.endsWith("&")) {
        restURL = `${url}`;
      } else {
        restURL = `${url}&`;
      }
    } else {
      restURL = `${url}?`;
    }

    if (concurrentIdentifier === "SW") {
      let conObj = JSON.stringify(concurrentObject);
      const encodedString = Buffer.from(conObj).toString('base64');
      restURL = restURL + `concurrentIdentifier=${"SW"}&concurrentObject=${encodedString}`;
    } else if (concurrentIdentifier === "LFC") {
      let conObj = JSON.stringify(concurrentObject);
      const encodedString = Buffer.from(conObj).toString('base64');
      restURL = restURL + `concurrentIdentifier=${"LFC"}&concurrentObject=${encodedString}`;
    }

    return restURL;
  }
  return url;
};
