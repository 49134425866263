import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import ButtonComponent from "../common/ButtonComponent";
import SelectInput from "../common/SelectComponent";
import TextInputComponent from "../common/TextInputComponent";
import {
  ERROR_IMPORT_USER_INVALID_FILE,
  ROLE_ADMINISTRATOR,
  ROLE_AGENCY_ADMIN,
  ROLE_ATTORNEY_GENERAL,
  ROLE_CASE_CREATOR,
  ROLE_CIRCUIT_CLERK,
  ROLE_COUNTY_ADMIN,
  ROLE_JUDGE,
  ROLE_LOCAL_PROSECUTOR,
  ROLE_POLICE_OFFICER,
  ROLE_STATES_ATTORNEY,
} from "../../constants/common";
import { useAppDispatch } from "../../hooks/hooks";
import {
  downloadTemplateFileForUser,
  useUserProfileReducer,
  userFileUpload,
  listUser,
  updateElementValueUserProfileReducer,
} from "../../redux/slice/userProfileListSlice";
import {
  useUserTypeReducer,
  listUserTypes,
} from "../../redux/slice/userTypeSlice";
import { useNavigate } from "react-router-dom";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import ConfirmPopup from "../common/ConfirmPopup";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { merge } from "../../utils/common";

interface UserImportProps {
  show: boolean;
  onHide: () => void;
  children?: React.ReactNode;
}

const UserImport: React.FC<UserImportProps> = (props) => {

  let defaultImportUserSchema = yup.object().shape({
    importUserType: yup.string().required("User Type is required."),
  });

  const { show, onHide, children } = props;
  const [enableFileName, setEnableFileName] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [uploadedFileName, setUploadedFileName] = React.useState("");
  const [documentFile, setDocumentFile] = React.useState("");
  const [userTypeData, setUserTypeData] = React.useState({
    value: 0,
    label: "",
  });
  const dispatch = useAppDispatch();
  const { userTypeList } = useUserTypeReducer();
  const userListReducer = useUserProfileReducer();
  const navigate = useNavigate();
  const { userType } = useLoginReducer();
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
    message: "",
 
  });
  const [errorKeys, setErrorKeys] = useState([""]);
  const errorMessageAlert = useAlert();
  const [importUserData, setImportUserData] = React.useState({
    importUserType: "",
    ImportFilePath: "",
  });

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  useEffect(() => {
    return () => {
      setImportUserData({
        importUserType: "",
        ImportFilePath: "",
      });
    };
  }, []);

  useEffect(() => {
    if (alertMessage !== null && alertMessage !== ""){
      handleError([], [alertMessage]);
    }
  }, [alertMessage]);

  const onChangeHandle = (event: any) => {
    setUserTypeData({
      value: event.value,
      label: event.label,
    });
    setImportUserData({
      importUserType: event.label,
      ImportFilePath: "",
    });
    setEnableFileName(true);
  };

  const hidePopup = () => {
    onHide();
    setEnableFileName(false);
    setAlertMessage("");
    dispatch(updateElementValueUserProfileReducer({
      elementName :"uploadUserListErr",
      value : []
    }));
  };

  let uTypeObj = [
    ROLE_ADMINISTRATOR,
    ROLE_COUNTY_ADMIN,
    ROLE_AGENCY_ADMIN,
    ROLE_POLICE_OFFICER,
    ROLE_STATES_ATTORNEY,
    ROLE_JUDGE,
    ROLE_CIRCUIT_CLERK,
    ROLE_CASE_CREATOR,
    ROLE_ATTORNEY_GENERAL,
    ROLE_LOCAL_PROSECUTOR,
  ];
  if (userType === ROLE_AGENCY_ADMIN) {
    uTypeObj = [ROLE_AGENCY_ADMIN, ROLE_POLICE_OFFICER];
  } else if (userType === ROLE_COUNTY_ADMIN) {
    uTypeObj = [
      ROLE_COUNTY_ADMIN,
      ROLE_AGENCY_ADMIN,
      ROLE_POLICE_OFFICER,
      ROLE_STATES_ATTORNEY,
      ROLE_JUDGE,
      ROLE_CIRCUIT_CLERK,
      ROLE_CASE_CREATOR,
      ROLE_LOCAL_PROSECUTOR,
    ];
  } else if (userType === ROLE_CIRCUIT_CLERK) {
    uTypeObj = [ROLE_CIRCUIT_CLERK];
  } else if (userType === ROLE_ATTORNEY_GENERAL) {
    uTypeObj = [ROLE_ATTORNEY_GENERAL];
  }

  useEffect(() => {
    setAlertMessage("");
    if(userListReducer.uploadUserListErr && userListReducer.uploadUserListErr.length > 0) {
     let errlist: any = [];
     userListReducer.uploadUserListErr.map((err: any) => {
        errlist.push(<li>{err}</li>);
     })
      setAlertMessage(errlist);
      
    } 
    if(typeof userListReducer.uploadUserListErr === 'undefined') {
     
      hidePopup();
      dispatch(
        listUser({
          userTypeObj: uTypeObj,
          status: "all",
        })
      );
    }
  }, [userListReducer.uploadUserListErr]);  

  const resetFilePath = (event: any) => {
    event.target.value = "";
  };
  
  const handleFileChange = (event: any) => {
    let templateFile = event.target.files[0];

    if (!templateFile.name.match(/\.(xlsx|xls|csv)$/)) {
      setAlertMessage(ERROR_IMPORT_USER_INVALID_FILE);
    }
    if (templateFile) {
      try {
        var fileSize = 0;
        fileSize = event.target.files[0].size; //size in kb
        fileSize = fileSize / 1048576; //size in mb
        if (fileSize > 5) throw "Too big";
      } catch (e) {
        setAlertMessage("File size should be less than " + 5 + " MB.");
        return;
      }
      if (uploadedFileName && uploadedFileName.trim().length > 0) {
        setconfirmPopupData({
          show: true,
          message: `Existing image document will be replaced. Please confirm.`
        });
      }

      setUploadedFileName(event.target.files[0].name);
      let documentFile = templateFile;
      getBase64(documentFile)
        .then((result) => {
          documentFile["base64"] = result;
        })
        .catch((err) => {
          console.log(err);
        });
       setDocumentFile(documentFile);
    }

    setImportUserData({
      importUserType: importUserData.importUserType,
      ImportFilePath: event.target.files[0].name,
    });
  };

  const getBase64 = (file: Blob) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const downloadTemplateFile = () => {
    let dataObj = {
      userTypeId: userTypeData.value,
      userTypeName: userTypeData.label,
    };
    dispatch(downloadTemplateFileForUser(dataObj));
  };

  const handleSubmit = async () => {
    try {
    let schema = [];
    schema.push(defaultImportUserSchema);

      if (enableFileName === true) {
        schema.unshift(
          yup.object().shape({
            ImportFilePath: yup.string().required("File should be selected."),
          })
        );
      }

    schema = merge(...schema);
    const user = await schema.validate(importUserData, {
      abortEarly: false,
    });

    let dataObj : any = {
      userTypeId: userTypeData.value,
      fileName: uploadedFileName,
      fileData: documentFile,
      navigate : navigate
    };
    dispatch(userFileUpload(dataObj));

  } catch (err: any) {
    handleError(
      err.inner.map((e: any) => e.path),
      err.errors
    );
  }
  };

  useEffect(() => {
    dispatch(listUserTypes(""));
  }, []);

  let filterUserTypeList: any[] = [];
  userTypeList.map((item: any) => {
    if (item.type !== ROLE_ADMINISTRATOR) {
      return filterUserTypeList.push(item);
    }
  });

  const handleConfirmationPopUp = () => {
    setconfirmPopupData({
      show: false,
      message: "", 
    }); 
    return;
  }
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => hidePopup()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <b>Select User Type & File Name</b>
      </Modal.Header>
      <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
      />
       <ConfirmPopup
          children={confirmPopupData.message}
          show={confirmPopupData.show}
          onHide={() =>
            setconfirmPopupData({
              show: false,
              message: "",     
            })
          }
          isConfirmation={handleConfirmationPopUp}
        />
      <Modal.Body>
        <Row>
          <Col>
            <SelectInput
              name="importUserType"
              isLabeled
              isRequired
              placeholder="Select User Type"
              options={filterUserTypeList.map((userType: any) => {
                return {
                  value: userType.userTypeID,
                  label: userType.type,
                };
              })}
              onChange={onChangeHandle}
            />
          </Col>
        </Row>
        {enableFileName ? (
          <>
            <Row className="mt-3">
              <Col>
                <TextInputComponent
                  className="form-control"
                  name="ImportFilePath"
                  type="File"
                  id="formFile"
                  isRequired
                  labelName="File Name"
                  disabled={!enableFileName}
                  onChange={handleFileChange}
                  onClick={resetFilePath}
                  //ref = {fileInputRef}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-md-end mt-2">
                <ButtonComponent
                  variant="info"
                  size="sm"
                  style={{ paddingLeft: "35px" }}
                  onClick={downloadTemplateFile}
                >
                  Download Template
                </ButtonComponent>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        <hr />
        <Row className="g-2">
          <Col>
            <ButtonComponent
              variant="primary"
              onClick={handleSubmit}
              className="w-100"
            >
              <RiCheckFill /> Ok
            </ButtonComponent>
          </Col>
          <Col>
            <ButtonComponent
              variant="danger"
              onClick={() => hidePopup()}
              className="w-100"
            >
              <RiCloseFill /> Cancel
            </ButtonComponent>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UserImport;
