import { useLoginReducer } from "../redux/slice/loginSlice";

export default function useToken() {
  const { canAccessApplicationValue } = useLoginReducer();
  return (token?: string | string[]) => {
    if (canAccessApplicationValue) {
      const items = canAccessApplicationValue.split(";");
      if (token instanceof Array) {
        return token.find((item) => items.includes(item));
      } else if (token) {
        return items.includes(token);
      } else {
        return true;
      }
    }
    return false;
  };
}
