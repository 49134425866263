import { Button, Col, Row } from "react-bootstrap";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import Header from "../common/Header";
import TextInputComponent from "../common/TextInputComponent";
import {
  fetchExistingProbableCauseStatementData,
  fetchProbableCauseStatementData,
  getCountryList,
  resetprobableCauseStatement,
  saveProbableCauseStatmentData,
  updateElementObjectValue,
  updateprobableCauseObject,
  useProbableCauseStatementReducer
} from "../../redux/slice/probableCauseStatementSlice";
import { REVISED_PROBABLE_CAUSE_STATEMENT, STATE_LIST, UNITED_STATES } from "../../constants/common";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/hooks";
import { useComplaintReducer } from "../../redux/slice/complaintSlice";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { formattedZipcode } from "../../utils/common";
import ConfirmPopup from "../common/ConfirmPopup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { probableCauseStatementPreview } from "../../redux/slice/commonFormSlice";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import moment from "moment";
import { useDashboardReducer, updateElementValueDashboardReducer } from "../../redux/slice/dashboardSlice";

let defaultPcSchema = yup.object().shape({
  victimNames: yup.string().nullable().required("Victim Names' is required."),
  victimPhoneNumbers: yup.string().nullable().required("Victim Phone Numbers' is required."),
});


interface ProbableCauseStatementProps { }

const ProbableCauseStatement: React.FC<ProbableCauseStatementProps> = (props) => {

  const { probableDataObject, countries } = useProbableCauseStatementReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const errorMessageAlert = useAlert();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const ecomplaintReducer = useComplaintMainReducer();
  const [confirmationModalShow, setConfirmationModalShow] = React.useState(false);
  const [message, setMessage] = React.useState<React.ReactNode>();
  const dashboardReducer = useDashboardReducer();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    dispatch(getCountryList());
    return () => {
      dispatch(resetprobableCauseStatement());
    }
  }, []);

  const handleChangeValue = (e: any) => {

    if (e.target.name === "defAddressUnknown") {
      if (e.target.checked) {
        let Obj = {
          ...probableDataObject,
          defForeignAddress: false,
          defAddressUnknown: true,
          defAddressLine1: "",
          defAddressLine2: "",
          defCity: "",
          defState: "",
          defZip: "",
          defCountry: "",
        }
        dispatch(updateprobableCauseObject(Obj))
      }
      else {
        dispatch(updateElementObjectValue({ elementName: "defAddressUnknown", value: e.target.checked }));
        dispatch(updateElementObjectValue({ elementName: "defCountry", value: UNITED_STATES }));
      }
    }
    else if (e.target.name === "defForeignAddress") {
      dispatch(updateElementObjectValue({ elementName: "defForeignAddress", value: e.target.checked }));
      if (e.target.checked) {
        dispatch(updateElementObjectValue({ elementName: "defAddressUnknown", value: !e.target.checked }));
        dispatch(updateElementObjectValue({ elementName: "defCountry", value: "" }))
      }
      else {
        dispatch(updateElementObjectValue({ elementName: "defCountry", value: UNITED_STATES }));
      }
    }
    else {
      dispatch(updateElementObjectValue({ elementName: e.target.name, value: e.target.value }));
    }
  }

  const handleDefStateChangeValue = (e: any) => {
    dispatch(updateElementObjectValue({ elementName: "defState", value: e.label }));
  }

  const handleDefCountryChangeValue = (e: any) => {
    dispatch(updateElementObjectValue({ elementName: "defCountry", value: e.label }))
  }

  const handleCkEditorValChange = (name: string, editor: any) => {
let updatedTextString = editor.getData().replace(/—/g, '-');
    dispatch(updateElementObjectValue({ elementName: name, value:updatedTextString }));
  }

  const handleUpperCaseInputChange = (e: any) => {
    dispatch(updateElementObjectValue({ elementName: e.target.name, value: e.target.value.toUpperCase() }));
  }

  const handleNumbersDashKeyPress = (evt: any) => {
    if (evt.which != 8 && evt.which != 9 && (evt.which < 48 || evt.which > 57) && evt.key !== '-') {
      evt.preventDefault();
    }
  }

  const handleFormatZip = (event: any) => {
    let { name, value } = event.target;
    const _zip = formattedZipcode(value);
    dispatch(updateElementObjectValue({ elementName: name, value: _zip }));
  };

  const handlePreviewClick = () => {
    const data = {
      ...probableDataObject,
    }
    data.defDob = moment(probableDataObject.defDob).format("MM/DD/YYYY");
    data.arrestDate = moment(probableDataObject.arrestDate).format("MM/DD/YYYY");
    dispatch(
      probableCauseStatementPreview({
        ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
        ecFormId: probableDataObject.formId,
        formData: data,
      })
    );
  };

  const saveProbableCauseData = async () => {

    let Obj = {
      victimNames: probableDataObject.victimNames,
      victimPhoneNumbers: probableDataObject.victimPhoneNumbers,
    }
    try {
      if (probableDataObject.victimsAndInvolved){
        await defaultPcSchema.validate(Obj, {
          abortEarly: false,
        });
      }
      let msg = <>You are about to complete the Probable Cause Statement form. Are you sure you want to proceed?</>
      setConfirmationModalShow(true);
      setMessage(msg);
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }

  }

  const saveProbableCauseDataAfterConfirm = () => {
    const data = {
      ...probableDataObject,
    }
    data.defDob = moment(probableDataObject.defDob).format("MM/DD/YYYY");
    data.arrestDate = moment(probableDataObject.arrestDate).format("MM/DD/YYYY");

    let Obj = {
      formTypeName: "Probable Cause Statement",
      countyName: "",
      navigate: navigate,
      ecProbCauseData: data,
      ecomplaintId: ecomplaintReducer.complaint.ecomplaintId
    }
    dispatch(saveProbableCauseStatmentData(Obj))
  }

  const cancelProbableCauseStatement = async () => {

    let Obj = {
      formId: probableDataObject.formId,
    };
    try {
      const action = await dispatch(fetchExistingProbableCauseStatementData(Obj));
      const savedProbableDataObject = action.payload as any;

      if (
        (savedProbableDataObject.victimNames !== null && savedProbableDataObject.victimPhoneNumbers !== null) ||
        (savedProbableDataObject.factsCrime !== null && savedProbableDataObject.factsCrime !== "")
      ) {
        if (savedProbableDataObject.victimNames !== probableDataObject.victimNames || savedProbableDataObject.victimPhoneNumbers !== probableDataObject.victimPhoneNumbers
          || savedProbableDataObject.factsCrime !== probableDataObject.factsCrime
        ) {
          setShowPopup(true);
        }
        else {
          navigate("/EComplaintDashboard");
        }

      } else {
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error fetching probable cause statement data:', error);
      return false;
    }
  }

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleConfirmation = () => {
    navigate("/EComplaintDashboard");
    setShowPopup(false);
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const  handleLongFormListClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/"+dashboardReducer.PreviousURLLFC.replace(/^.*\/\/.*?\//, ''), itemName: "Long Form List", itemFunction: handleLongFormListClick },
  ];

  const toggleHandleValChange = (e: any) =>{
    dispatch(updateElementObjectValue({elementName: "victimsAndInvolved", value: e.target.checked}));
  }

  let data = [
    {
      type: "textInput",
      props: {
        type: "text",
        name: "arrestingAgency",
        labelName: <b>Case Number :</b>,
        value: probableDataObject.caseNumber,
        inLine: true,
        disabled: true,
        autoComplete : "nope"
      },
      colSize: 4,
      emptyCol: 8,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "arrestingAgency",
        labelName: <b>Arresting Agency :</b>,
        value: probableDataObject.arrestingAgency,
        onChange: handleChangeValue,
        inLine: true,
        autoComplete : "nope"
      },
      colSize: 4,

    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "agencyRptNo",
        labelName: <b>Agency Rpt. Number :</b>,
        value: probableDataObject.agencyRptNo,
        onChange: handleChangeValue,
        inLine: true,
        autoComplete : "nope"
      },
      colSize: 3,
      emptyCol: 5,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "defName",
        labelName: <b>Defendant's Name :</b>,
        value: probableDataObject.defName,
        onChange: handleChangeValue,
        inLine: true,
        autoComplete : "nope"
      },
      colSize: 4,

    },
    {
      type: "textInput",
      props: {
        type: "Date",
        name: "defDob",
        labelName: <b>Date Of Birth :</b>,
        value: probableDataObject.defDob,
        onChange: handleChangeValue,
        inLine: true,
        autoComplete : "nope"
      },
      colSize: 3,
      emptyCol: 5,
    },
    {
      type: "checkbox",
      props: {
        label: <b>Unknown Address</b>,
        name: "defAddressUnknown",
        value: probableDataObject.defAddressUnknown,
        onClick: handleChangeValue,
        checked: probableDataObject.defAddressUnknown,
      },
      colSize: "auto",
      colClassName: "mt-1"
    },
    {
      type: "checkbox",
      props: {
        label: <b>Foreign Address</b>,
        name: "defForeignAddress",
        value: probableDataObject.defForeignAddress,
        onClick: handleChangeValue,
        checked: probableDataObject.defForeignAddress,
      },
      colSize: "auto",
      emptyCol: 9,
      colClassName: "mt-1"
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Address Line 1</b>,
        value: probableDataObject.defAddressLine1,
        onChange: handleUpperCaseInputChange,
        name: "defAddressLine1",
        disabled: probableDataObject.defAddressUnknown,
        autoComplete : "nope"
      },
      colSize: 4,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Address Line 2</b>,
        value: probableDataObject.defAddressLine2,
        onChange: handleUpperCaseInputChange,
        name: "defAddressLine2",
        disabled: probableDataObject.defAddressUnknown,
        autoComplete : "nope"
      },
      colSize: 4,
      emptyCol: 4,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>City</b>,
        value: probableDataObject.defCity,
        onChange: handleUpperCaseInputChange,
        name: "defCity",
        disabled: probableDataObject.defAddressUnknown,
        autoComplete : "nope",
      },
      colSize: 3,
    },
    probableDataObject.defForeignAddress ?
      {
        type: "textInput",
        props: {
          type: "text",
          labelName: <b>State/Province</b>,
          name: "defState",
          value: probableDataObject.defState,
          onChange: handleChangeValue,
          disabled: probableDataObject.defAddressUnknown,
          autoComplete : "nope",
        },
        colSize: 2,
      }
      :
      {
        type: "selectInput",
        props: {
          options:
            STATE_LIST.map((data: any) => {
              return {
                label: data,
                value: data
              };
            }),
          defaultValue: { label: "-- Select --", value: "" },
          isDefaultLabel: true,
          name: "defState",
          isSelected: true,
          labelName: <b>State</b>,
          value: STATE_LIST
            .map((data: any) => {
              return {
                label: data,
                value: data
              };
            })
            .find(
              (item: any) => {
                if (item.label === probableDataObject.defState)
                  return {
                    label: item,
                  };
              }
            ),
          onChange: handleDefStateChangeValue,
          isLabeled: true,
          autoComplete: "nope",
          isDisabled: probableDataObject.defAddressUnknown,
        },
        colSize: 2,
      },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Zip</b>,
        name: "defZip",
        value: probableDataObject.defZip,
        onChange: handleChangeValue,
        onKeyPress: handleNumbersDashKeyPress,
        onBlur: handleFormatZip,
        placeholder: "XXXXX-XXXX",
        maxLength: 10,
        autoComplete: "nope",
        disabled: probableDataObject.defAddressUnknown,
      },
      colSize: 2,
    },
    {
      type: "selectInput",
      props: {
        options: countries.map((data: any) => {
          return {
            label: data,
            value: data
          };
        }),
        labelName: <b>Country</b>,
        placeholder: "-- Select --",
        value: countries
          .map((data: any) => {
            return {
              label: data,
              value: data
            };
          })
          .find(
            (item: any) => {
              if (item.label === probableDataObject.defCountry)
                return {
                  label: item,
                };
            }
          ),
        onChange: handleDefCountryChangeValue,
        defaultValue: { label: "-- Select --", value: "" },
        name: "defCountry",
        isSelected: true,
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete: "nope",
        isDisabled: !probableDataObject.defForeignAddress,
      },
      colSize: 3,
      emptyCol: 2,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "arrestingOfficer",
        value: probableDataObject.arrestingOfficer,
        onChange: handleChangeValue,
        labelName: <b>Arresting Officer :</b>,
        autoComplete : "nope",
      },
      colSize: 3,

    },
    {
      type: "textInput",
      props: {
        type: "Date",
        name: "arrestDate",
        labelName: <b>Arrest Date :</b>,
        onChange: handleChangeValue,
        value: probableDataObject.arrestDate,
        autoComplete : "nope",
      },
      colSize: 2,
      emptyCol: 7,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr className="hrStyle" />,
      },
      colSize: 12,
    },
    {
      type:"toggleButton",
      props:{
        value: probableDataObject.victimsAndInvolved,
        label: "Victims are Involved",
        onChange: toggleHandleValChange,
      },
      colSize: "auto",
      emptyCol: 12,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "relationVictimAndDef",
        labelName: <b>Relationship if any between Victim and Defendant :</b>,
        value: probableDataObject.relationVictimAndDef,
        onChange: handleChangeValue,
        inLine: true,
        autoComplete : "nope",
      },
      colSize: 9,
      colHide: !probableDataObject.victimsAndInvolved,
      emptyCol: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <b>{"Victim Names (NOTE: If victim is a minor, also include name of custodial adult):"}</b>,
      },
      colSize: 12,
      colHide: !probableDataObject.victimsAndInvolved,
    },
    {
      type: "editor",
      props: {
        name: "victimNames",
        data: probableDataObject.victimNames ? probableDataObject.victimNames : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("victimNames", editor);
        },
        isError: errorKeys.includes("victimNames"),
        autoComplete : "nope",
      },
      colSize: 12,
      colHide: !probableDataObject.victimsAndInvolved,
    },
    {
      type: "htmlTag",
      props: {
        value: <b>{"Victim Phone Numbers (NOTE: If victim is a minor, also include phone number of custodial adult):"}</b>,
      },
      colSize: 12,
      colHide: !probableDataObject.victimsAndInvolved,
    },
    {
      type: "editor",
      props: {
        name: "victimPhoneNumbers",
        data: probableDataObject.victimPhoneNumbers ? probableDataObject.victimPhoneNumbers : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("victimPhoneNumbers", editor);
        },
        isError: errorKeys.includes("victimPhoneNumbers"),
        autoComplete : "nope",
      },
      colSize: 12,
      colHide: !probableDataObject.victimsAndInvolved,
    },
    {
      type: "htmlTag",
      props: {
        value: <b>Facts of underlying Crime :</b>,
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        name: "factsCrime",
        data: probableDataObject.factsCrime ? probableDataObject.factsCrime : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("factsCrime", editor);
        },
        autoComplete : "nope"
      },
      colSize: 12,
    },
    {
      type: "fileUploader",
      props: {
        fileName: probableDataObject.uploadedFileName,
        formType: REVISED_PROBABLE_CAUSE_STATEMENT,
        isCanEdit: true,
      },
      colSize: 12,
    },
    {
      type: 'formButtonPanel',
      props: {},
      colSize: 12,
    },

  ]

  return (
    <>
      <Header headerName="Probable Cause Statement" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Probable Cause Statement"
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
                <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data}
          components={[
            {
              type: "formButtonPanel",
              node: (props: any) => (
                <Row className="mt-2">
                  <Col className="text-start"></Col>
                  <Col className="text-center">
                    <Button className="btn-success mt-3" onClick={() => saveProbableCauseData()}>
                      Save
                    </Button>
                  </Col>
                  <Col className="text-end">
                    <Button className="btn-dark mt-3" onClick={handlePreviewClick}>
                      Preview
                    </Button>{" "}
                    <Button className="btn-dark mt-3" onClick={() => cancelProbableCauseStatement()}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              ),
            }
          ]}
        />
      </div>
      <ConfirmPopup
        show={confirmationModalShow}
        onHide={() => setConfirmationModalShow(false)}
        isConfirmation={saveProbableCauseDataAfterConfirm}>
        {message}
      </ConfirmPopup>

      <ConfirmPopup
        show={showPopup}
        onHide={handlePopupClose}
        isConfirmation={handleConfirmation}
      >
        Canceling will lose info. Click Save to keep. Still cancel and continue?
      </ConfirmPopup>
    </>
  )
}

export default ProbableCauseStatement;