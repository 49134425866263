import React, { useEffect } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import ButtonComponent from "../common/ButtonComponent";
import Header from "../common/Header";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  RiSearchLine,
  RiAddCircleLine,
  RiDraftFill,
  RiFileExcelLine,
  RiFileTextLine,
} from "react-icons/ri";
import ListViewComponent from "../common/ListViewComponent";
import { useAppDispatch } from "../../hooks/hooks";
import {
  getLoggedInUserAgencies,
  listAgencies,
  updateAgencyStatus,
  useAgencyListReducer,
  updateElementValueAgencyListReducer,
  updateAgencyListReducer,
  resetAgenciesData,
} from "../../redux/slice/agencyListSlice";
import ConfirmPopup from "../common/ConfirmPopup";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { USER_TYPES } from "../../constants/common";
import { TableActionComponent } from "../common/TableActionComponent";
import {
  updateElementValueAgencyReducer,
  useAgencyReducer,
} from "../../redux/slice/agencySlice";
import Alert, { useAlert } from "../common/Alert";

interface AgencyListProps {}

export const AgencyList: React.FC<AgencyListProps> = (props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const alertMessage = useAlert();
  const { userType } = useLoginReducer();
  const {
    agencyList,
    pagedAgencyList,
    totalPages,
    currentPage,
    totalRecords,
    status,
    agencyId,
  } = useAgencyListReducer();
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
    agencyId: 0,
    active: false,
  });
  const agencyReducer = useAgencyReducer();

  useEffect(() => {
    return () => {
      dispatch(resetAgenciesData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch((_dispatch) => {
      _dispatch(
        listAgencies({
          status: "all",
        })
      );
      _dispatch(getLoggedInUserAgencies());
    });
    navigate(location.pathname);
  }, [dispatch, location.pathname, navigate]);

  useEffect(() => {
    if (agencyReducer.successMessage) {
      alertMessage.handleError([agencyReducer.successMessage]);

      dispatch(
        updateElementValueAgencyReducer({
          elementName: "successMessage",
          value: "",
        })
      );
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [agencyReducer.successMessage, dispatch]);

  const setUrlData = () => {
    console.log("*********************************");
    let paramsValue = location.search;
    if (paramsValue.trim().length > 0) {
      let urlPath: any = {
        status: searchParams.get("status") || "all",
        agencyId: parseInt(searchParams.get("agencyId")?.toString() || "0"),
      };
      dispatch(updateAgencyListReducer(urlPath));
    } else {
      dispatch(
        listAgencies({
          status: "all",
        })
      );
    }
  };

  const agencyListOptions: any = [];
  if (agencyList.length! > 1) {
    agencyListOptions.push({ label: "-- ALL --", value: 0 });
  }
  agencyListOptions.push(
    ...agencyList.map((agency: any) => {
      return {
        label: `${agency.agencyCode}-${agency.agencyName}`,
        value: agency.agencyId,
      };
    })
  );

  const statusOptions: any = [];
  statusOptions.push(
    { value: "all", label: "-- ALL --" },
    { value: "true", label: "ACTIVE" },
    { value: "false", label: "INACTIVE" }
  );

  const handleSelect = (e: any, field: string) => {
    dispatch(
      updateElementValueAgencyListReducer({
        elementName: field,
        value: e.value,
      })
    );
  };

  const data = {
    colSize: 3,
    componentData: [
      {
        type: "selectInput",
        props: {
          options: agencyListOptions,
          value: agencyListOptions.find((item: any) => {
            if (item.value === agencyId) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select Agency",
          name: "agency",
          defaultValue: { label: "-- ALL --", value: 0 },
          onChange: (e: any) => handleSelect(e, "agencyId"),
        },
      },
      {
        type: "selectInput",
        props: {
          options: statusOptions,
          value: statusOptions.find((item: any) => {
            if (item.value === status) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select Status",
          name: "status",
          defaultValue: { value: "all", label: "-- ALL --" },
          onChange: (e: any) => handleSelect(e, "status"),
        },
      },
      {
        type: "button",
        props: {
          className: "w-100",
          children: (
            <>
              <RiSearchLine /> Search
            </>
          ),
          type: "submit",
          onClick: (state: any) => handleSubmit(state),
        },
        colSize: 2,
      },
    ],
  };
  const handleAgencyStatusUpdate =
    (agencyId: number, active: boolean) => () => {
      setconfirmPopupData({
        show: true,
        agencyId,
        active,
      });
    };
  const handleAgencyEdit = (agencyId: number) => () => {
    dispatch(
      updateElementValueAgencyReducer({
        elementName: "countySelectList",
        value: agencyReducer.countySelectList,
      })
    );
    navigate("/EditAgency/" + agencyId);
  };
  const handleAgencyStatusUpdateConfirm = () => {
    let urlPathObj: any = {
      status: searchParams.get("status") || "all",
      agencyId: searchParams.get("agencyId") || 0,
      pageNo: parseInt(searchParams.get("pageNo")?.toString() || "0") - 1,
    };
    let data = {
      agenciId: confirmPopupData.agencyId,
      listViewData: urlPathObj,
    };
    dispatch(updateAgencyStatus(data));
    setconfirmPopupData({
      show: false,
      agencyId: 0,
      active: false,
    });
  };

  let tableData = pagedAgencyList.map(
    ({
      agencyId,
      agencyCode,
      agencyName,
      countyList,
      active,
      creationTimeWithUser,
      modificationTimeWithUser,
    }: any) => [
      agencyId,
      agencyCode,
      agencyName,
      countyList,
      active ? "Active" : "Inactive",
      creationTimeWithUser,
      modificationTimeWithUser,
      userType === USER_TYPES.ADMINISTRATOR
        ? handleAgencyStatusUpdate(agencyId, active)
        : null,
      userType === USER_TYPES.ADMINISTRATOR ? handleAgencyEdit(agencyId) : null,
    ]
  );
  let tableHeader =
    userType === USER_TYPES.ADMINISTRATOR
      ? [
          "AGENCY CODE",
          "AGENCY NAME",
          "COUNTIES",
          "STATUS",
          "CREATION DETAILS",
          "MODIFICATION DETAILS",
          "ACTION",
        ]
      : [
          "AGENCY CODE",
          "AGENCY NAME",
          "COUNTIES",
          "STATUS",
          "CREATION DETAILS",
          "MODIFICATION DETAILS",
        ];

  const handleSubmit = (state: any) => {
    let obj = {
      status: status || "all",
      agencyId: agencyId,
    };
    dispatch(listAgencies(obj));
    let urlPathObj: any = {
      status: status || "all",
      agencyId: agencyId,
      pageNo: 1,
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlPathObj)}`,
    });
  };

  const paginationHandle = (page: number, searchBarState: any) => {
    let urlPathObj: any = {
      status: status || "all",
      agencyId: agencyId,
      pageNo: (page + 1).toString(),
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlPathObj)}`,
    });

    let obj = {
      status: searchBarState.status || "all",
      agencyId: searchBarState.agency,
      pageNo: page,
    };
    dispatch(listAgencies(obj));
  };

  window.onpopstate = () => {
    let paramsValue = location.search;
    if (paramsValue.trim().length > 0) {
      let urlPath: any = {
        status: searchParams.get("status") || "all",
        agencyId: searchParams.get("agencyId") || 0,
        pageNo: parseInt(searchParams.get("pageNo")?.toString() || "0") - 1,
      };
      dispatch(listAgencies(urlPath));
      setUrlData();
    } else {
      dispatch(resetAgenciesData());
      dispatch(
        listAgencies({
          status: "all",
        })
      );
      dispatch(getLoggedInUserAgencies());
    }
  };

  return (
    <>
      <Header headerName="Agency List" />

      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="Agency List" />
        <Alert {...alertMessage} variant="success" isExclamationMark={false} />
        <ListViewComponent
          table={{
            tableHeader: tableHeader,
            tableData: tableData,
            renderTableRow: renderTableRow,
          }}
          searchBar={{
            colSize: data.colSize,
            componentData: data.componentData,
          }}
          middleComponent={() => (
            <ListViewMiddleComponent
              totalAgencies={totalRecords}
              showCreateButton={userType === USER_TYPES.ADMINISTRATOR}
            />
          )}
          pagination={{
            totalPages,
            numberOfPagesToShow: 5,
            currentPage,
            onPageChange: (page: number, searchBarState: any) => {
              paginationHandle(page, searchBarState);
            },
          }}
        />
      </div>
      <ConfirmPopup
        children={
          confirmPopupData.active === true
            ? "Selected agency will be inactivated. Please confirm."
            : "Selected agency will be activated. Please confirm."
        }
        show={confirmPopupData.show}
        onHide={() =>
          setconfirmPopupData({ show: false, agencyId: 0, active: false })
        }
        isConfirmation={handleAgencyStatusUpdateConfirm}
      />
    </>
  );
};

const ListViewMiddleComponent = ({
  totalAgencies,
  showCreateButton = false,
}: {
  totalAgencies: number;
  showCreateButton?: boolean;
}) => (
  <Row>
    <Col>
      <h4>Agency List</h4>
      <h6 className="my-n2">Total Agency : {totalAgencies}</h6>
    </Col>

    <Col className="text-end">
      {showCreateButton && (
        <ButtonComponent variant="info">
          <Link to={"/AddAgency"}>
            <RiAddCircleLine /> Create Agency
          </Link>
        </ButtonComponent>
      )}
    </Col>
  </Row>
);

const renderTableRow = (row: any[]) => {
  const [
    agencyId,
    agencyCode,
    agencyName,
    countyList,
    active,
    creationTimeWithUser,
    modificationTimeWithUser,
    handleAgencyStatusUpdate,
    handleAgencyEdit,
  ] = row;
  return (
    <tr key={agencyId}>
      <td>
        <b>{agencyCode}</b>
      </td>
      <td>{agencyName}</td>
      <td>{countyList}</td>
      <td>{active}</td>
      <td style={{ width: "250px" }}>{creationTimeWithUser}</td>
      <td>{modificationTimeWithUser}</td>
      {handleAgencyEdit && handleAgencyStatusUpdate && (
        <td className="justify-content-center d-flex align-items-stretch">
          {handleAgencyEdit && (
            <TableActionComponent
              tootltip="Edit"
              children={<RiDraftFill />}
              actionFunction={handleAgencyEdit}
              color="text-primary"
              text="Edit"
            />
          )}
          {handleAgencyStatusUpdate &&
            (active !== "Active" ? (
              <TableActionComponent
                tootltip="Activate"
                children={<RiFileTextLine />}
                actionFunction={handleAgencyStatusUpdate}
                color="text-success"
                text="Activate"
              />
            ) : (
              <TableActionComponent
                tootltip="Inactivate"
                children={<RiFileExcelLine />}
                actionFunction={handleAgencyStatusUpdate}
                color="text-danger"
                text="Inactivate"
              />
            ))}
        </td>
      )}
    </tr>
  );
};

export default AgencyList;

let itemLinkList = [{ itemLink: "/", itemName: "DashBoard" }];
