import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  RiHome2Fill,
  RiKeyFill,
  RiLogoutCircleFill,
  RiMore2Fill,
  RiUserHeartLine,
  RiUserSettingsFill,
} from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import { logoutUser, useLoginReducer } from "../../redux/slice/loginSlice";
import { getUserDataByUsername } from "../../redux/slice/userChangeProfileSlice";
import { UserChangeProfilePopup } from "../user/UserChangeProfilePopup";
import { MdPassword, MdSignLanguage } from "react-icons/md";
import { TwoFactorAuthenticationRegisterPopup} from "../user/TwoFactorAuthenticationRegisterPopup";
import { FaSignature } from "react-icons/fa";
import useToken from "../../hooks/useToken";
import { CAN_ACCESS_APPLICATION } from "../../constants/common";
import InfoPopup from "./InfoPopup";
import { ChangeUsername } from "../user/ChangeUsername";

interface HeaderProps {
  headerName: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { headerName } = props;
  const dispatch = useAppDispatch();
  const [showProfileModal, setShowProfileModal] = React.useState(false);
  const [showChangeUsername, setShowChangeUsername] = React.useState(false);
  const [showTwoFactorAuthenicationModal, setShowTwoFactorAuthenicationModal] = React.useState(false);
  const { firstName, lastName, userType, username, isValid, twoFactorEnable} = useLoginReducer();
  const navigate = useNavigate();
  const isTokenValueAvailable = useToken();
  const logout = () => {
    dispatch(logoutUser());
  };
  const [showError, setShowError] = useState({
    show: false,
    error: "",
  });

  const onClickChangeProfie = () =>{
    setShowProfileModal(true);
    dispatch(getUserDataByUsername({
      username: username,
    }));
  }

  const onClickChangeUsername = () => {
    setShowChangeUsername(true);
    dispatch(getUserDataByUsername({
      username: username,
    }));
  }
  const onClickTwoFactorAuthenication = () =>{
    dispatch(getUserDataByUsername({
      username: username,
    }));
    setShowTwoFactorAuthenicationModal(true);
  }

  const onClickJudgeSignatureRegister = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    if (twoFactorEnable) {
      navigate("/captureSignature");
    } else {
      setShowError({ show: true, error: "Please enable two factor Authentication" });
    }
  };
  return (
    <div className="page-header pt-2 pb-2 sticky-top">
      <Row className="m-0">
        <Col>
          <label>{headerName}</label>
        </Col>
        {isValid ? 
        <Col className="text-end pe-3">
          <div className="d-inline-block pe-3">
            {firstName} {lastName} [ {userType} ]
          </div>
          <div className="d-inline-block pe-2">
            <Link to={"/"}>
              <RiHome2Fill />
            </Link>
          </div>
          <div className="d-inline-block dropdown-right">
            <span className="drop-right">
              <RiMore2Fill />
            </span>
            <div className="dropdown-content-right text-start">
              <Link to={""} onClick= {onClickChangeProfie}>
                <RiUserSettingsFill />{" "}
                <label className="ps-2">Change Profile</label>
              </Link>
                <Link to={""} onClick={onClickChangeUsername}  >
                  <RiUserHeartLine className="text-info" />{" "}
                  <label className="ps-2">Change Username</label>
                </Link>
              <Link to={"/changePassword"}>
                <RiKeyFill className="text-warning"/>{" "}
                <label className="ps-2">Change Password</label>
              </Link>
              <Link to={""} onClick= {onClickTwoFactorAuthenication}>
                <MdPassword />{" "}
                <label className="ps-2">Two Factor Authentication</label>
              </Link>
                <Link to={""} onClick={onClickJudgeSignatureRegister}>
                  <FaSignature />{" "}
                  <label className="ps-2">Save My Signature</label>
                </Link>
              <Link to={"/"} onClick={logout}>
                <RiLogoutCircleFill /> <label className="ps-2">Logout</label>
              </Link>
            </div>
          </div>
        </Col> : ""}
      </Row>
      <UserChangeProfilePopup
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
      />
      <ChangeUsername
        show={showChangeUsername}
        onHide={() => setShowChangeUsername(false)}
      />
      <TwoFactorAuthenticationRegisterPopup
        show={showTwoFactorAuthenicationModal}
        onHide={() => setShowTwoFactorAuthenicationModal(false)}
      />
      <InfoPopup
        children={showError.error}
        onHide={() =>
          setShowError({
            show: false,
            error: "",
          })
        }
        show={showError.show}
        variant = "danger"
      />
    </div>
  );
};

export default Header;
