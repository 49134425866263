import React, { Children, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import {
  RiArrowGoBackFill,
  RiArrowLeftLine,
  RiArrowRightFill,
  RiCheckboxMultipleFill,
  RiCloseCircleFill,
  RiDeleteBin5Fill,
  RiDraftFill,
  RiEditBoxFill,
  RiFileCopy2Fill,
  RiFileListFill,
  RiFileTextLine,
  RiLockUnlockFill,
  RiMapPinLine,
  RiPenNibFill,
  RiSendPlane2Fill,
  RiVideoChatFill,
  RiWindow2Fill,
  RiFileDownloadLine,
  RiFileUploadLine,
  RiDeleteBin2Line,
  RiExchangeLine,
} from "react-icons/ri";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  AMENDED_INVENTORY,
  APPLICATION_AND_ORDER,
  BLANK_ORDER,
  COMPL_SEARCH_WARRANT,
  COMPL_SEARCH_WARRANT_WITH_SLASH,
  INVENTORY,
  ORDER_OF_CUSTODY,
  PROOF_OF_SERVICE,
  ROLE_ADMINISTRATOR,
  ROLE_AGENCY_ADMIN,
  ROLE_ATTORNEY_GENERAL,
  ROLE_COUNTY_ADMIN,
  ROLE_JUDGE,
  ROLE_LOCAL_PROSECUTOR,
  ROLE_POLICE_OFFICER,
  ROLE_STATES_ATTORNEY,
  ROLE_SA_ADMIN,
  SEARCH_WAR_ACTIVE_STATUS,
  SEARCH_WAR_ASSIGNED_TO_AG_STATUS,
  SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS,
  SEARCH_WAR_ASSIGNED_TO_LP_STATUS,
  SEARCH_WAR_ASSIGNED_TO_SA_STATUS,
  SEARCH_WAR_COMPLETE_STATUS,
  SEARCH_WAR_DELETED_STATUS,
  SEARCH_WAR_EXECUTED_STATUS,
  SEARCH_WAR_HALF_STATUS_ASSIGNED_TO,
  SEARCH_WAR_HALF_STATUS_LOCKED_BY,
  SEARCH_WAR_INCOMPLETE_STATUS,
  SEARCH_WAR_LOCKED_BY_AG_STATUS,
  SEARCH_WAR_LOCKED_BY_JUDGE_STATUS,
  SEARCH_WAR_LOCKED_BY_LP_STATUS,
  SEARCH_WAR_LOCKED_BY_SA_STATUS,
  SEARCH_WAR_OFFICER_PENDING_STATUS,
  SEARCH_WAR_VOID_STATUS,
  AUDIT_TYPE_MODIFICATION,
  AUDIT_TYPE_ACCESS,
  CASE_DETAIL_IMOUNDED,
  SW_CONFIRM_CMSPENDING,
  CMS_SYNC_STATUS_SYNC,
  CMS_SYNC_STATUS_PENDING,
} from "../../constants/common";
import { useAppDispatch } from "../../hooks/hooks";
import useModel from "../../hooks/useModel";
import { createApplicationAndOrder, useApplicationAndOrderReducer } from "../../redux/slice/applicationAndOrderSlice";
import {
  fetchAuditTrailData,
  useAuditTrailReducer,
} from "../../redux/slice/auditTrailSlice";
import { createBlankOrder, useBlankOrderReducer } from "../../redux/slice/blankOrderSlice";
import {
  getSWCaseDetail,
  useCaseDetailReducer,
  updateElementValueCaseDetailReducer,
} from "../../redux/slice/caseDetailSlice";
import commonFormSlice, {
  getSearchWarrantData,
  previewOrder,
  useCommonFormReducer,
} from "../../redux/slice/commonFormSlice";
import { createInventory, updateElementValueInventoryReducer, useInventoryReducer } from "../../redux/slice/inventorySlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { createProofOfService } from "../../redux/slice/proofOfServiceSlice";
import {
  deleteSearchWarrant,
  getSearchWarrantDetails,
  updateCmsSyncFormStatusToPending,
  updateElementValueSearchWarrantReducer,
  updateSearchWarrantDetail,
  useSearchWarrantReducer,
} from "../../redux/slice/searchWarrantSlice";
import { resetSignature, setSignature } from "../../redux/slice/signPanelSlice";
import {
  getVideoConferenceURL,
  updateElementValueVideoConferenceReducer,
} from "../../redux/slice/videoConferenceSlice";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import ButtonComponent from "../common/ButtonComponent";
import ConfirmPopup from "../common/ConfirmPopup";
import Header from "../common/Header";
import InfoPopup from "../common/InfoPopup";
import RemarkPopup from "../common/RemarkPopup";
import SignPopup from "../common/SignPopup";
import { TableActionComponent } from "../common/TableActionComponent";
import TableComponent from "../common/TableComponent";
import AmendInventorySourcePopup from "./form/AmendInventorySourcePopup";
import VideoConferenceSplitScreenPopUp from "./form/VideoConferenceSplitScreenPopUp";
import AduitTrailPopup from "../common/AuditTrailPopup";
import { getTextColorByStatus } from "../../utils/common";
import AlertMessage from "../common/AlertMessage";
import { createOrderOfCustody } from "../../redux/slice/orderOfCustodySlice";
import DeveloperOptionPopup from "./form/DeveloperOptionPopup";
import {
  getCountyDataById,
  useCountyReducer,
} from "../../redux/slice/countySlice";
import SearchWarrant from "./form/SearchWarrant";
import { useAlert } from "../common/Alert";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import SecurityPassword from "../common/SecurityPasswordPopup";
import {
  updateElementValueDashboardReducer,
  useDashboardReducer,
} from "../../redux/slice/dashboardSlice";
import OptionPopup from "../common/OptionsPopup";
import { approvalStatusForms } from "../common/CommonUtil";
import { LoadingType } from "../../types";
import { useAmendInventoryReducer } from "../../redux/slice/amendInventorySlice";

const tableHeader = [
  "#",
  "Form",
  "DATE CREATED",
  "SING DATE",
  "STATUS",
  "LOCKED BY",
  "ACTIONS",
];

function getConfirmedEditMessage(
  formStatus: string,
  creatorId: number,
  formCreatorId: number,
  userId: number,
  userRole: string,
  onBehalf: boolean
) {
  if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS &&
    userRole === ROLE_STATES_ATTORNEY &&
    onBehalf
  ) {
    return "Form will be locked for review. Please confirm.";
  } else if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS &&
    userRole === ROLE_ATTORNEY_GENERAL &&
    onBehalf
  ) {
    return "Form will be locked for review. Please confirm.";
  } else if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS &&
    userRole === ROLE_LOCAL_PROSECUTOR &&
    onBehalf
  ) {
    return "Form will be locked for review. Please confirm.";
  } else if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
    creatorId === userId &&
    userRole === ROLE_STATES_ATTORNEY
  ) {
    return 'Form status will be changed to "Locked By SA" before you can review. \nDo you want to proceed?';
  } else if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
    creatorId === userId &&
    userRole === ROLE_ATTORNEY_GENERAL
  ) {
    return 'Form status will be changed to "Locked By AG" before you can review. \nDo you want to proceed?';
  } else if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
    creatorId === userId &&
    userRole === ROLE_LOCAL_PROSECUTOR
  ) {
    return 'Form status will be changed to "Locked By LP" before you can review. \nDo you want to proceed ?';
  } else if (
    (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) &&
    ROLE_STATES_ATTORNEY === userRole &&
    (userId === creatorId || userId === formCreatorId)
  ) {
    return 'Form status will be changed to "Locked By SA" before you can review. \nDo you want to proceed?';
  } else if (
    (formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) &&
    ROLE_ATTORNEY_GENERAL === userRole &&
    (userId === creatorId || userId === formCreatorId)
  ) {
    return 'Form status will be changed to "Locked By AG" before you can review. \nDo you want to proceed?';
  } else if (
    (formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) &&
    ROLE_LOCAL_PROSECUTOR === userRole &&
    (userId === creatorId || userId === formCreatorId)
  ) {
    return 'Form status will be changed to "Locked By LP" before you can review. \nDo you want to proceed ?';
  } else if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
    ROLE_JUDGE === userRole &&
    (userId === creatorId || userId === formCreatorId)
  ) {
    return 'Form status will be changed to "Locked By Judge" before you can review. \nDo you want to proceed ?';
  } else if (
    (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) &&
    (userId === creatorId || userId === formCreatorId)
  ) {
    return 'Form status will be changed to "Officer Pending" before you can review. \nDo you want to proceed?';
  } else if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS &&
    creatorId !== userId &&
    userRole === ROLE_STATES_ATTORNEY
  ) {
    return "Form will be locked for review. Please confirm.";
  } else if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS &&
    userRole === ROLE_ATTORNEY_GENERAL
  ) {
    return "Form will be locked for review. Please confirm.";
  } else if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS &&
    userRole === ROLE_LOCAL_PROSECUTOR
  ) {
    return "Form will be locked for review. Please confirm.";
  } else if (
    formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
    userRole === ROLE_JUDGE
  ) {
    return "Form will be locked for review. Please confirm.";
  } else if (
    (formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) &&
    userRole === ROLE_STATES_ATTORNEY
  ) {
    return "Form will be locked for review. Please confirm.";
  } else if (
    (formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) &&
    userRole === ROLE_ATTORNEY_GENERAL
  ) {
    return "Form will be locked for review. Please confirm.";
  } else if (
    (formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) &&
    userRole === ROLE_LOCAL_PROSECUTOR
  ) {
    return "Form will be locked for review. Please confirm.";
  } else {
    return "";
  }
}

function isAllowEdit(
  formStatus: string,
  creatorId: number,
  lockedBy: string,
  formCreatorId: number,
  formType: string,
  warrantId: number,
  warrantApprovalSAId: number,
  warrantApprovalUserType: string,
  caseDetailReducer: any,
  userRole: string,
  userId: number,
  userName: string
) {
  if (userRole === ROLE_ADMINISTRATOR) {
    if (
      (formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) &&
      !(
        formType === INVENTORY ||
        formType === AMENDED_INVENTORY ||
        formType === PROOF_OF_SERVICE
      )
    ) {
      return true;
    } else if (
      formType === INVENTORY &&
      creatorId === userId &&
      (formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS)
    ) {
      return true;
    } else if (
      formType === PROOF_OF_SERVICE &&
      creatorId === userId &&
      (formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_INCOMPLETE_STATUS)
    ) {
      return true;
    } else if (
      formType === AMENDED_INVENTORY &&
      creatorId === userId &&
      (formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS)
    ) {
      return true;
    }
    // if (
    //   (formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
    //     formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
    //     formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
    //     formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
    //     formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
    //     formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
    //     formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
    //     formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
    //     formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
    //     formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
    //     formStatus === SEARCH_WAR_SA_PENDING_STATUS ||
    //     formStatus === SEARCH_WAR_FILED_STATUS ||
    //     formStatus === SEARCH_WAR_VOID_STATUS ||
    //     formStatus === SEARCH_WAR_DENIED_STATUS ||
    //     formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
    //     formStatus === SEARCH_WAR_DELETED_STATUS ||
    //     formStatus === SEARCH_WAR_PROCESSED_STATUS ||
    //     formStatus === SEARCH_WAR_HALF_STATUS_ASSIGNED_TO ||
    //     formStatus === SEARCH_WAR_HALF_STATUS_LOCKED_BY) &&
    //   (formType === COMPL_SEARCH_WARRANT_WITH_SLASH ||
    //     formType === PROOF_OF_SERVICE ||
    //     formType === INVENTORY ||
    //     formType === ORDER_OF_CUSTODY ||
    //     formType === BLANK_ORDER ||
    //     formType === AMENDED_INVENTORY ||
    //     formType === APPLICATION_AND_ORDER) &&
    //   (lockedBy == null || userName === lockedBy)
    // ) {
    //   return true;
    // }
    // return false;
  } else if (userRole === ROLE_COUNTY_ADMIN) {
    if (
      (formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) &&
      (lockedBy == null || userName === lockedBy) &&
      !(
        formType === INVENTORY ||
        formType === AMENDED_INVENTORY ||
        formType === PROOF_OF_SERVICE
      ) &&
      creatorId === userId
    ) {
      return true;
    } else if (
      formType === INVENTORY &&
      creatorId === userId &&
      (formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS)
    ) {
      return true;
    } else if (
      formType === AMENDED_INVENTORY &&
      creatorId === userId &&
      (formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS)
    ) {
      return true;
    } else if (
      formType === PROOF_OF_SERVICE &&
      creatorId === userId &&
      (formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_INCOMPLETE_STATUS)
    ) {
      return true;
    }
  } else if (userRole === ROLE_AGENCY_ADMIN) {
    if (
      (formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        ((formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
          formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
          formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
          formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
          formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
          formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
          formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
          formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
          formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
          formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) &&
          (lockedBy == null || userName === lockedBy))) &&
      !(
        formType === INVENTORY ||
        formType === AMENDED_INVENTORY ||
        formType === PROOF_OF_SERVICE
      ) &&
      creatorId === userId
    ) {
      return true;
    } else if (
      (formType === INVENTORY ||
        formType === AMENDED_INVENTORY ||
        formType === PROOF_OF_SERVICE) &&
      creatorId === userId &&
      (formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_INCOMPLETE_STATUS)
    ) {
      return true;
    }
  } else if (userRole === ROLE_SA_ADMIN) {
    if (
      (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) &&
      !(
        formType === INVENTORY ||
        formType === AMENDED_INVENTORY ||
        formType === PROOF_OF_SERVICE ||
        formType === ORDER_OF_CUSTODY
      ) &&
      caseDetailReducer &&
      caseDetailReducer.approvalSAId &&
      lockedBy == null
    ) {
      return true;
    }
    if (
      (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) &&
      (APPLICATION_AND_ORDER === formType || BLANK_ORDER === formType) &&
      caseDetailReducer !== null
    ) {
      return true;
    }
  } else if (userRole === ROLE_STATES_ATTORNEY) {
    if (
      (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) &&
      !(
        formType === INVENTORY ||
        formType === AMENDED_INVENTORY ||
        formType === PROOF_OF_SERVICE ||
        formType === ORDER_OF_CUSTODY
      ) &&
      caseDetailReducer &&
      caseDetailReducer.approvalSAId &&
      userId === caseDetailReducer.approvalSAId &&
      (!lockedBy || userName === lockedBy)
    ) {
      return true;
    }
    if (
      (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) &&
      (APPLICATION_AND_ORDER === formType || BLANK_ORDER === formType)
    ) {
      if (userId === formCreatorId) {
        return true;
      }

      if (
        ((warrantApprovalSAId && warrantApprovalSAId > 0) ||
          (warrantApprovalUserType &&
            ROLE_STATES_ATTORNEY === warrantApprovalUserType)) &&
        (!lockedBy || userName === lockedBy)
      ) {
        return true;
      }
      if (
        !caseDetailReducer.approvalSAId ||
        caseDetailReducer.approvalSAId === 0
      ) {
        return false;
      }
    }
  } else if (userRole === ROLE_ATTORNEY_GENERAL) {
    if (
      (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS) &&
      !(
        formType === INVENTORY ||
        formType === AMENDED_INVENTORY ||
        formType === PROOF_OF_SERVICE ||
        formType === ORDER_OF_CUSTODY
      ) &&
      caseDetailReducer &&
      caseDetailReducer.approvalSAId &&
      userId === caseDetailReducer.approvalSAId &&
      (!lockedBy || userName === lockedBy)
    ) {
      return true;
    }
    if (
      (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS) &&
      (APPLICATION_AND_ORDER === formType || BLANK_ORDER === formType)
    ) {
      if (userId === formCreatorId) {
        return true;
      }
      if (
        ((warrantApprovalSAId && warrantApprovalSAId > 0) ||
          (warrantApprovalUserType &&
            ROLE_ATTORNEY_GENERAL === warrantApprovalUserType)) &&
        (!lockedBy || userName === lockedBy)
      ) {
        return true;
      }

      if (
        !caseDetailReducer.approvalSAId ||
        caseDetailReducer.approvalSAId === 0
      ) {
        return false;
      }
    }
  } else if (userRole === ROLE_LOCAL_PROSECUTOR) {
    if (
      (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) &&
      !(
        formType === INVENTORY ||
        formType === AMENDED_INVENTORY ||
        formType === PROOF_OF_SERVICE ||
        formType === ORDER_OF_CUSTODY
      ) &&
      caseDetailReducer &&
      caseDetailReducer.approvalSAId &&
      userId === caseDetailReducer.approvalSAId &&
      (!lockedBy || userName === lockedBy)
    ) {
      return true;
    }
    if (
      (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) &&
      (APPLICATION_AND_ORDER === formType || BLANK_ORDER === formType)
    ) {
      if (userId === formCreatorId) {
        return true;
      }
      if (
        ((warrantApprovalSAId && warrantApprovalSAId > 0) ||
          (warrantApprovalUserType &&
            ROLE_LOCAL_PROSECUTOR === warrantApprovalUserType)) &&
        (!lockedBy || userName === lockedBy)
      ) {
        return true;
      }

      if (
        !caseDetailReducer.approvalSAId ||
        caseDetailReducer.approvalSAId === 0
      ) {
        return false;
      }
    }
  }

  if (!lockedBy) {
    lockedBy = "";
  }

  if (
    formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
    formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
    formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS
  ) {
    if (
      userRole === ROLE_POLICE_OFFICER ||
      userRole === ROLE_STATES_ATTORNEY ||
      userRole === ROLE_ATTORNEY_GENERAL ||
      userRole === ROLE_LOCAL_PROSECUTOR
    ) {
      if (creatorId === userId && (lockedBy == "" || userName === lockedBy)) {
        return true;
      } else if (
        formCreatorId === userId &&
        (lockedBy === "" || userName === lockedBy)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      userRole === ROLE_JUDGE &&
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS
    ) {
      return true;
    } else {
      return false;
    }
  } else if (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) {
    if (
      (userRole === ROLE_STATES_ATTORNEY && lockedBy === userName) ||
      userRole === ROLE_SA_ADMIN
    ) {
      return true;
    } else {
      return false;
    }
  } else if (formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS) {
    if (userRole === ROLE_JUDGE && lockedBy === userName) {
      return true;
    } else {
      return false;
    }
  } else if (formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS) {
    if (userRole === ROLE_ATTORNEY_GENERAL && lockedBy === userName) {
      return true;
    } else {
      return false;
    }
  } else if (formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) {
    if (userRole === ROLE_LOCAL_PROSECUTOR && lockedBy === userName) {
      return true;
    } else {
      return false;
    }
  } else if (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
    if (
      userRole === ROLE_POLICE_OFFICER &&
      (creatorId === userId || formCreatorId === userId)
    ) {
      return true;
    } else if (
      userRole === ROLE_STATES_ATTORNEY ||
      userRole === ROLE_SA_ADMIN
    ) {
      return true;
    } else {
      return false;
    }
  } else if (formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
    if (
      userRole === ROLE_POLICE_OFFICER &&
      (creatorId === userId || formCreatorId === userId)
    ) {
      return true;
    } else if (userRole === ROLE_ATTORNEY_GENERAL) {
      return true;
    } else {
      return false;
    }
  } else if (formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
    if (
      userRole === ROLE_POLICE_OFFICER &&
      (creatorId === userId || formCreatorId === userId)
    ) {
      return true;
    } else if (userRole === ROLE_LOCAL_PROSECUTOR) {
      return true;
    } else {
      return false;
    }
  } else if (
    formStatus === SEARCH_WAR_ACTIVE_STATUS ||
    formStatus === SEARCH_WAR_COMPLETE_STATUS ||
    formStatus === SEARCH_WAR_EXECUTED_STATUS ||
    formStatus === SEARCH_WAR_VOID_STATUS
  ) {
    return false;
  } else {
    return false;
  }
}


function isAllowPreviewClick(
  isCmsPending: boolean,
  integratedWithCMS: boolean,
  cmsSyncStatus: string
) {
  if (isCmsPending && integratedWithCMS) {
    if (cmsSyncStatus && cmsSyncStatus !== "") {
      return true;
    }
  }
}

function isAllowSign(
  formStatus: string,
  lockedBy: string,
  creatorId: number,
  loggedInUserRole: string,
  loggedInUserId: number,
  userName: string
) {
  // if (ROLE_ADMINISTRATOR === loggedInUserRole) return false;
  if (
    (ROLE_ADMINISTRATOR === loggedInUserRole ||
      ROLE_COUNTY_ADMIN === loggedInUserRole) &&
    creatorId === loggedInUserId &&
    (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS)
  ) {
    return true;
  } else if (
    ROLE_JUDGE === loggedInUserRole &&
    (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS)
  ) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) {
      return true;
    }
    if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS
    ) {
      return true;
    }
  }
  return false;
}

function isAllowDeveloperOption(loggedInUserRole: string) {
  if (loggedInUserRole === ROLE_ADMINISTRATOR) {
    return true;
  } else {
    return false;
  }
}

function isAllowUnlock(
  formStatus: string,
  lockedBy: string,
  userRole: string,
  userName: string,
  formType: string
) {
  if (userRole === ROLE_ADMINISTRATOR) {
    if (
      (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
        //formStatus === SEARCH_WAR_HALF_STATUS_LOCKED_BY ||
        formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) &&
      lockedBy
    ) {
      return true;
    } else {
      return false;
    }
  }
  if (userRole === ROLE_SA_ADMIN) {
    if (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) {
      return true;
    }
  }

  if (userRole === ROLE_COUNTY_ADMIN) {
    if (
      formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
    ) {
      return true;
    } else {
      return false;
    }
  }

  if (
    (userRole === ROLE_AGENCY_ADMIN || userRole === ROLE_POLICE_OFFICER) &&
    lockedBy &&
    lockedBy === userName
  ) {
    return true;
  }

  if (
    (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) &&
    lockedBy &&
    lockedBy === userName
  ) {
    return true;
  } else {
    return false;
  }
}

function isAllowSubmit(
  formStatus: string,
  creatorId: number,
  formCreatorId: number,
  saApproval: number,
  lockedBy: string,
  onBehalf: boolean,
  userRole: string,
  userId: number,
  userName: string
) {
  if (
    userRole === ROLE_ADMINISTRATOR &&
    (formCreatorId === userId || creatorId === userId)
  ) {
    if (SEARCH_WAR_OFFICER_PENDING_STATUS === formStatus) {
      return true;
    }
    return false;
  }
  if (
    userRole === ROLE_COUNTY_ADMIN &&
    (formCreatorId === userId || creatorId === userId)
  ) {
    if (SEARCH_WAR_OFFICER_PENDING_STATUS === formStatus) {
      return true;
    }
  }
  if (
    userRole === ROLE_AGENCY_ADMIN &&
    (formCreatorId === userId || creatorId === userId) &&
    (!lockedBy || lockedBy === userName)
  ) {
    if (SEARCH_WAR_OFFICER_PENDING_STATUS === formStatus) {
      return true;
    }
  }
  if (userRole === ROLE_STATES_ATTORNEY) {
    if (
      SEARCH_WAR_OFFICER_PENDING_STATUS === formStatus &&
      (formCreatorId === userId || creatorId === userId)
    ) {
      return true;
    }
    if (
      (SEARCH_WAR_ASSIGNED_TO_SA_STATUS === formStatus ||
        SEARCH_WAR_LOCKED_BY_SA_STATUS === formStatus) &&
      !onBehalf &&
      (formCreatorId === userId || creatorId === userId)
    ) {
      return true;
    }
  }
  if (userRole === ROLE_ATTORNEY_GENERAL) {
    if (
      SEARCH_WAR_OFFICER_PENDING_STATUS === formStatus &&
      (formCreatorId === userId || creatorId === userId)
    ) {
      return true;
    }
    if (
      (SEARCH_WAR_ASSIGNED_TO_AG_STATUS === formStatus ||
        SEARCH_WAR_LOCKED_BY_AG_STATUS === formStatus) &&
      !onBehalf &&
      (formCreatorId === userId || creatorId === userId)
    ) {
      return true;
    }
  }
  if (userRole === ROLE_LOCAL_PROSECUTOR) {
    if (
      SEARCH_WAR_OFFICER_PENDING_STATUS === formStatus &&
      (formCreatorId === userId || creatorId === userId)
    ) {
      return true;
    }
    if (
      (SEARCH_WAR_ASSIGNED_TO_LP_STATUS === formStatus ||
        SEARCH_WAR_LOCKED_BY_LP_STATUS === formStatus) &&
      !onBehalf &&
      (formCreatorId === userId || creatorId === userId)
    ) {
      return true;
    }
  }
  if (userRole === ROLE_POLICE_OFFICER) {
    if (!lockedBy || lockedBy === userName) {
      if (
        formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS &&
        saApproval &&
        saApproval > 0
      ) {
        return true;
      }
      if (
        formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS &&
        formCreatorId === userId
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  return false;
}

function isScreeningRequiredFunc(
  approvalUserType: string,
  isSARequired: boolean
) {
  let isScreeningRequired = false;
  if (approvalUserType) {
    if (!isSARequired) {
      if (ROLE_JUDGE === approvalUserType.trim()) {
        isScreeningRequired = false;
      } else {
        isScreeningRequired = true;
      }
    } else {
      isScreeningRequired = true;
    }
  }
  return isScreeningRequired;
}

function isAllowApprove(
  formStatus: string,
  lockedBy: string,
  creatorID: number,
  onBehalf: boolean,
  userRole: string,
  userId: number,
  userName: string
) {
  if (userRole === ROLE_ADMINISTRATOR) {
    if (
      formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
    ) {
      return true;
    }
    return false;
  }
  if (userRole === ROLE_COUNTY_ADMIN) {
    if (
      formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS
    ) {
      return true;
    }
  }
  if (userRole === ROLE_SA_ADMIN) {
    if (
      formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS
    ) {
      return true;
    }
  }
  if (
    userRole === ROLE_STATES_ATTORNEY &&
    ((userId && userId !== creatorID) || (creatorID === userId && onBehalf))
  ) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_STATES_ATTORNEY
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) {
        return true;
      }
    }
  }
  if (
    userRole === ROLE_ATTORNEY_GENERAL &&
    ((userId && userId !== creatorID) || (creatorID === userId && onBehalf))
  ) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_ATTORNEY_GENERAL
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS) {
        return true;
      }
    }
  }
  if (
    userRole === ROLE_LOCAL_PROSECUTOR &&
    ((userId && userId !== creatorID) || (creatorID === userId && onBehalf))
  ) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_LOCAL_PROSECUTOR
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) {
        return true;
      }
    }
  }
  return false;
}

function isAllowRevert(
  formStatus: string,
  formType: string,
  lockedBy: string,
  creatorID: number,
  formCreatorType: string,
  onBehalf: boolean,
  userRole: string,
  userId: number,
  userName: string
) {
  if (userRole === ROLE_ADMINISTRATOR) {
    if (
      formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS
    ) {
      return true;
    }
  }
  if (userRole === ROLE_COUNTY_ADMIN) {
    if (
      formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS
    ) {
      return true;
    }
  }
  if (userRole === ROLE_SA_ADMIN) {
    if (
      formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS
    ) {
      return true;
    }
  }
  if (
    userRole === ROLE_STATES_ATTORNEY &&
    ((userId && userId !== creatorID) || (creatorID === userId && onBehalf))
  ) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_STATES_ATTORNEY
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) {
        return true;
      }
    }
  } else if (
    userRole === ROLE_ATTORNEY_GENERAL &&
    ((userId && userId !== creatorID) || (creatorID === userId && onBehalf))
  ) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_ATTORNEY_GENERAL
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS) {
        return true;
      }
    }
  } else if (
    userRole === ROLE_LOCAL_PROSECUTOR &&
    ((userId && userId !== creatorID) || (creatorID === userId && onBehalf))
  ) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_LOCAL_PROSECUTOR
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) {
        return true;
      }
    }
  } else if (userRole === ROLE_JUDGE) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_JUDGE
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS) {
        return true;
      }
    }
    // if (
    //   formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
    //   userId &&
    //   userId !== creatorID
    // ) {
    //   if (BLANK_ORDER === formType && formCreatorType === ROLE_JUDGE)
    //     return false;
    //   else return true;
    // } else if (
    //   lockedBy &&
    //   userName &&
    //   lockedBy === userName &&
    //   userRole === ROLE_JUDGE
    // ) {
    //   if (formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS) {
    //     if (BLANK_ORDER === formType && formCreatorType === ROLE_JUDGE)
    //       return false;
    //     else return true;
    //   }
    // }
  }
  return false;
}

function isAllowDenied(
  formStatus: string,
  lockedBy: string,
  userRole: string,
  userName: string,
  formType: string
) {
  if (userRole === ROLE_ADMINISTRATOR) {
    if (
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS
    ) {
      return true;
    }
  }
  if (userRole === ROLE_COUNTY_ADMIN) {
    if (
      formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
      formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS
    ) {
      return true;
    }
  }
  if (userRole === ROLE_JUDGE) {
    if (formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) {
      return true;
    } else if (
      lockedBy &&
      userName &&
      lockedBy === userName &&
      userRole === ROLE_JUDGE
    ) {
      if (formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS) {
        return true;
      }
    }
  }
  return false;
}

function isAllowVoid(formStatus: string, userRole: string, formType: string) {
  if (formType === COMPL_SEARCH_WARRANT_WITH_SLASH) {
    if (
      (formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) &&
      (userRole === ROLE_ADMINISTRATOR || userRole === ROLE_COUNTY_ADMIN)
    ) {
      return true;
    }
  }
  if (
    formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
    formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
    formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
    formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
    formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
    formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
    formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS ||
    formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
    formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
    formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS
  ) {
    if (
      formType === COMPL_SEARCH_WARRANT_WITH_SLASH &&
      (userRole === ROLE_AGENCY_ADMIN || userRole === ROLE_POLICE_OFFICER)
    ) {
      return true;
    }
  }
  return false;
}

function isAllowDelete(
  formStatus: string,
  creatorId: number,
  userRole: string,
  userId: number,
  formType: string
) {
  if (
    formType === INVENTORY ||
    formType === AMENDED_INVENTORY ||
    formType === PROOF_OF_SERVICE ||
    formType === ORDER_OF_CUSTODY
  ) {
    if (
      (formStatus === SEARCH_WAR_INCOMPLETE_STATUS &&
        (userRole === ROLE_ADMINISTRATOR ||
          userRole === ROLE_COUNTY_ADMIN ||
          userRole === ROLE_STATES_ATTORNEY ||
          userRole === ROLE_SA_ADMIN ||
          userRole === ROLE_ATTORNEY_GENERAL ||
          userRole === ROLE_LOCAL_PROSECUTOR ||
          userRole === ROLE_AGENCY_ADMIN)) ||
      (formStatus !== SEARCH_WAR_COMPLETE_STATUS &&
        (userRole === ROLE_COUNTY_ADMIN || userRole === ROLE_AGENCY_ADMIN)) ||
      (formStatus !== SEARCH_WAR_COMPLETE_STATUS && userId === creatorId) ||
      userRole === ROLE_ADMINISTRATOR
    ) {
      return true;
    }
    return false;
  }
  if (formType === APPLICATION_AND_ORDER || formType === BLANK_ORDER) {
    if (
      (userRole === ROLE_ADMINISTRATOR ||
        userRole === ROLE_COUNTY_ADMIN ||
        userRole === ROLE_POLICE_OFFICER ||
        userRole === ROLE_STATES_ATTORNEY ||
        userRole === ROLE_SA_ADMIN ||
        userRole === ROLE_ATTORNEY_GENERAL ||
        userRole === ROLE_LOCAL_PROSECUTOR ||
        userRole === ROLE_JUDGE) &&
      (formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
        formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
        formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS)
    ) {
      return true;
    }
    return false;
  }
}

function isAllowViewRemarks(formStatus: string, remarks: string) {
  if (
    remarks &&
    remarks.trim().length > 0 &&
    SEARCH_WAR_VOID_STATUS !== formStatus
  ) {
    return true;
  }
  return false;
}

function isAllowAccessAuditTrail(userRole: string) {
  if (userRole === ROLE_ADMINISTRATOR) {
    return true;
  }

  return false;
}

function showStatus(stateAttorneyCode: string, statusIn: string) {
  if (SEARCH_WAR_ASSIGNED_TO_SA_STATUS === statusIn) {
    return SEARCH_WAR_HALF_STATUS_ASSIGNED_TO + stateAttorneyCode;
  } else if (SEARCH_WAR_LOCKED_BY_SA_STATUS === statusIn) {
    return SEARCH_WAR_HALF_STATUS_LOCKED_BY + stateAttorneyCode;
  }
  return statusIn;
}

function getSelectSWForm(searchWarrantList: any[], formType: string) {
  if (searchWarrantList && searchWarrantList.length > 0) {
    let sw = null;
    for (let i = 0; i < searchWarrantList.length; i++) {
      sw = searchWarrantList[i];
      if (sw.formType === formType) {
        return sw;
      }
    }
  }
  return null;
}

function getImgRemarksAndTitle(formCreatorType: string) {
  let imgRemarks = "revert";
  let imgRemarksTitle = "Return To Officer";
  if (formCreatorType === ROLE_LOCAL_PROSECUTOR) {
    imgRemarks = "revertLP";
    imgRemarksTitle = "Return To LP";
  } else if (
    formCreatorType === ROLE_STATES_ATTORNEY ||
    formCreatorType === ROLE_SA_ADMIN
  ) {
    imgRemarks = "revertSA";
    imgRemarksTitle = "Return To SA";
  } else if (formCreatorType === ROLE_ATTORNEY_GENERAL) {
    imgRemarks = "revertAG";
    imgRemarksTitle = "Return To AG";
  }
  return { imgRemarks, imgRemarksTitle };
}

const CaseDetails: React.FC = (props) => {
  const caseDetailReducer = useCaseDetailReducer();
  const auditTrailReducer = useAuditTrailReducer();
  const searchWarrantReducer = useSearchWarrantReducer();
  const applicationAndOrderReducer = useApplicationAndOrderReducer();
  const blankOrderReducer = useBlankOrderReducer();
  const inventoryReducer = useInventoryReducer();
  const amendedInventory = useAmendInventoryReducer();
  const dashboardReducer = useDashboardReducer();
  const { userProfileId, username, userType, canViewAuditTrail } =
    useLoginReducer();
  const commonFormreducer = useCommonFormReducer();
  const amendInventorySourcePopup = useModel();
  const videoConferencePopup = useModel();
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [confirmOriginPlace, setconfirmOriginPlace] = React.useState("");
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
    swObj: {},
  });
  const [showInfoPopup, setShowInfoPopup] = React.useState({
    show: false,
    info: "",
  });
  const [isSignPanelPopup, setisSignPanelPopup] = React.useState(false);
  // const [showAdmonishment, setShowAdmonishment] = React.useState(false);
  const [showVideoConference, setShowVideoConference] = React.useState(false);
  const [whoSign, setwhoSign] = React.useState("");
  const [swornText, setawornText] = React.useState("");
  const [complainantNameFooter, setcomplainantNameFooter] = React.useState("");
  const [showAuditTrail, setShowAuditTrail] = React.useState(false);
  const [searchWarrantIdForAuditTrail, setSearchWarrantIdForAuditTrail] =
    React.useState(0);
  const [formTypeForAuditTrail, setFormTypeForAuditTrail] = React.useState("");
  const [caseNumberForAuditTrail, setCaseNumberForAuditTrail] =
    React.useState("");
  const [auditType, setAuditType] = React.useState("");
  const [isEditClick, setIsEditClick] = React.useState(false);
  const [topalertMessage, setTopalertMessage] = React.useState<string>("");
  const [securityPasswordShow, setSecurityPasswordShow] = React.useState(false);
  const [securityPasswordProps, setSecurityPasswordProps] = React.useState<{
    complaintId: number;
    caseNumber: string;
    action: string;
  }>({ complaintId: 0, caseNumber: "", action: "" });

  const [returnDetails, setReturnDetails] = React.useState({
    searchWarrantId: 0,
    formType: "",
    formStatus: "",
    creatorId: 0,
    formCreatorId: 0,
    formCreatorType: "",
    approvalSAId: 0,
    status: "",
    remark: "",
  });
  const [viewRemark, setViewRemark] = React.useState("");
  const [onlyView, setOnlyView] = React.useState(false);
  const [remarkModalShow, setRemarkModalShow] = React.useState(false);
  const [remarkModalData, setRemarkModalData] = React.useState({});
  const [remarkRequired, setRemarkRequired] = React.useState(false);
  const [remarkFor, setRemarkFor] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [action, setAction] = React.useState("");
  const [voidData, setVoidData] = React.useState({
    searchWarrantId: 0,
    formType: "",
    formStatus: "",
    remark: "",
  });

  const [type, settype] = React.useState("");
  const [id, setid] = React.useState(Number);
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const loginReducer = useLoginReducer();
  const countyReducer = useCountyReducer();
  let loggedInUserId = userProfileId;
  let loggedInUserRole = userType;
  let loggedInUserName = username;

  const [developerPopupVisible, setDeveloperOptionVisible] =
    React.useState(false);
  const [generate, setGenerate] = React.useState(false);
  const [formType, setFormType] = React.useState("");
  const [formId, setFormId] = React.useState(0);
  const [warningVariant, setWarningVariant] = React.useState(false);
  const [showOptionPopup, setShowOptionPopup] = React.useState(false);
  const [optionObj, setOptionObj] = React.useState({
    header: "",
    body: <></>,
    buttons: [{ name: "", variant: "", onClick: () => {} }],
  });
  const [formApprovedStatus, setFormApprovedStatus] = React.useState("");
  const [confirmPopupObj, setconfirmPopupObj] = React.useState({});
  const [isSubmit, setIsSubmit] = React.useState(false);

  useEffect(() => {
    if (caseDetailReducer.responseMessage) {
      setTopalertMessage(caseDetailReducer.responseMessage);
      setWarningVariant(false);
      window.scroll({
        top: 0,
        behavior: "smooth",
      });

      dispatch(
        updateElementValueCaseDetailReducer({
          elementName: "responseMessage",
          value: "",
        })
      );
    }
  }, [caseDetailReducer.responseMessage, dispatch]);

  useEffect(() => {
    const countyID = loginReducer.countyId;
    if (countyID) {
      dispatch(
        getCountyDataById({
          id: countyID,
          navigate,
        })
      );
    }
  }, [dispatch, navigate]);
  useEffect(() => {
    if (caseDetailReducer.responseErrorMessage) {
      setTopalertMessage(caseDetailReducer.responseErrorMessage);
      setWarningVariant(true);
      window.scroll({
        top: 0,
        behavior: "smooth",
      });

      dispatch(
        updateElementValueCaseDetailReducer({
          elementName: "responseMessage",
          value: "",
        })
      );
    }
  }, [caseDetailReducer.responseErrorMessage, dispatch]);

  useEffect(() => {
    const countyID = loginReducer.countyId;
    if (countyID) {
      dispatch(
        getCountyDataById({
          id: countyID,
          navigate,
        })
      );
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (formApprovedStatus !== "") {
      setconfirmPopupData({
        swObj: confirmPopupObj,
        show: true,
      });
    }
  }, [formApprovedStatus]);

  useEffect(() => {
    loadingSignaturedetails(formType)
  }, [commonFormreducer.status])

  const loadingSignaturedetails = (formType: string) => {
    if (APPLICATION_AND_ORDER === formType) {
      if (loginReducer.userType === ROLE_JUDGE) {
        if (applicationAndOrderReducer.applicationAndOrderObject.hiddenJudgeSignature === undefined ||
          applicationAndOrderReducer.applicationAndOrderObject.hiddenJudgeSignature === "") {
          dispatch(setSignature(applicationAndOrderReducer.applicationAndOrderObject.hiddenUserSignature));
        }
      } else if (applicationAndOrderReducer.applicationAndOrderObject.hiddenComplaintSignature === undefined) {
        dispatch(setSignature(applicationAndOrderReducer.applicationAndOrderObject.hiddenUserSignature))
      }
    } else if (BLANK_ORDER === formType) {
      if (loginReducer.userType === ROLE_JUDGE) {
        if (blankOrderReducer.blankOrderObj.hiddenJudgeSignature === undefined ||
          blankOrderReducer.blankOrderObj.hiddenJudgeSignature === "") {
          dispatch(setSignature(blankOrderReducer.blankOrderObj.hiddenUserSignature));
        }
      } else if (blankOrderReducer.blankOrderObj.hiddenComplaintSignature === undefined) {
        dispatch(setSignature(blankOrderReducer.blankOrderObj.hiddenUserSignature))
      }
    } else if (formType === COMPL_SEARCH_WARRANT || formType === COMPL_SEARCH_WARRANT_WITH_SLASH) {
      if (loginReducer.userType === ROLE_JUDGE) {
        if (searchWarrantReducer.searchWarrantObject.hiddenJudgeSignature === undefined ||
          searchWarrantReducer.searchWarrantObject.hiddenJudgeSignature === "") {
          dispatch(setSignature(searchWarrantReducer.searchWarrantObject.hiddenUserSignature));
        }
      } else if (searchWarrantReducer.searchWarrantObject.hiddenComplaintSignature === undefined) {
        dispatch(setSignature(searchWarrantReducer.searchWarrantObject.hiddenUserSignature))
      }
    } else if (formType === AMENDED_INVENTORY) {
      if (loginReducer.userType === ROLE_JUDGE) {
        if (amendedInventory.amendInventoryObject.hiddenJudgeSignature === undefined ||
          amendedInventory.amendInventoryObject.hiddenJudgeSignature === "") {
          dispatch(setSignature(amendedInventory.amendInventoryObject.hiddenUserSignature));
        }
      } else if (amendedInventory.amendInventoryObject.hiddenComplaintSignature === undefined) {
        dispatch(setSignature(amendedInventory.amendInventoryObject.hiddenUserSignature))
      }
    } else if (formType === INVENTORY) {
      if (loginReducer.userType === ROLE_JUDGE) {
        if (inventoryReducer.inventoryObject.hiddenJudgeSignature === undefined ||
          inventoryReducer.inventoryObject.hiddenJudgeSignature === "") {
          dispatch(setSignature(inventoryReducer.inventoryObject.hiddenUserSignature));
        }
      } else if (inventoryReducer.inventoryObject.hiddenComplaintSignature === undefined) {
        dispatch(setSignature(inventoryReducer.inventoryObject.hiddenUserSignature))
      }
    }
  }
  
  const onVoidClick =
    (searchWarrantId: number, formType: string, status: any) => () => {
      setVoidData({
        searchWarrantId: searchWarrantId,
        formType: formType,
        formStatus: status,
        remark: "",
      });

      setconfirmOriginPlace("onVoidClick");

      if (loggedInUserRole !== ROLE_POLICE_OFFICER) {
        setAction("Void Search Warrant");
        setRemarkRequired(true);
        setRemarkFor("VOID");
        setRemarkModalShow(true);
        setIsEditClick(false);
        setOnlyView(false);
      } else {
        voidForm("");
      }
    };

  const submitRemark = (remark: string, remarkFor: string) => {
    if ("VOID" === remarkFor) {
      voidForm(remark);
    } else if ("RETURN" === remarkFor) {
      returnForm(remark);
    }
  };

  const returnForm = (remark: string) => {
    let formstatus = "";
    let comMessage = "";
    if (
      (userType === ROLE_ADMINISTRATOR || userType === ROLE_JUDGE) &&
      !returnDetails.approvalSAId &&
      caseDetailReducer.county.isSARequired &&
      userProfileId !== returnDetails.formCreatorId &&
      (returnDetails.formType === APPLICATION_AND_ORDER ||
        returnDetails.formType === BLANK_ORDER)
    ) {
      formstatus = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
      comMessage =
        "Form will be saved with Assign to SA status. Do you want to continue?";
    } else {
      formstatus = SEARCH_WAR_OFFICER_PENDING_STATUS;
      comMessage =
        "Form will be saved with Officer Pending status. Do you want to continue?";
    }
    if (userType === ROLE_JUDGE || userType === ROLE_ADMINISTRATOR) {
      if (returnDetails.formCreatorType === ROLE_STATES_ATTORNEY) {
        formstatus = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
        comMessage =
          "Form will be saved with Assign to SA status. Do you want to continue?";
      } else if (returnDetails.formCreatorType === ROLE_ATTORNEY_GENERAL) {
        formstatus = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
        comMessage =
          "Form will be saved with Assign to AG status. Do you want to continue?";
      } else if (returnDetails.formCreatorType === ROLE_LOCAL_PROSECUTOR) {
        formstatus = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
        comMessage =
          "Form will be saved with Assign to LP status. Do you want to continue?";
      } else {
        formstatus = SEARCH_WAR_OFFICER_PENDING_STATUS;
        comMessage =
          "Form will be saved with Officer Pending status. Do you want to continue?";
      }
    }

    setReturnDetails({ ...returnDetails, remark: remark, status: formstatus });

    setconfirmText(comMessage);
    setconfirmPopupData({
      show: true,
      swObj: {},
    });
  };

  const voidForm = (remark: string) => {
    setconfirmText("Search warrant will be voided. Please confirm");

    if (loggedInUserRole !== ROLE_POLICE_OFFICER) {
      setVoidData({ ...voidData, remark: remark });
    }

    setconfirmPopupData({
      show: true,
      swObj: {},
    });
  };

  const afterConfirmOnVoidClick = () => {
    dispatch(
      getSearchWarrantDetails({
        searchWarrantId: voidData.searchWarrantId,
        action: "VOID",
        detailObj: voidData,
        navigate: navigate,
      })
    );
  };

  // const showVideoConferenceModal = () => {
  //   if (
  //     caseDetailReducer.countyAdmonishment != null &&
  //     caseDetailReducer.countyAdmonishment != ""
  //   ) {
  //     setShowAdmonishment(true);
  //   } else {
  //     onConfirmAdmonishment();
  //    }
  // };

  const showVideoConferenceModal = () => {
    let data = {
      agencyCode: caseDetailReducer.agencyCode,
      caseNumber: caseDetailReducer.caseNumber,
      searchWarrantId: caseDetailReducer.searchWarrantId,
      ecomplaintId: 0,
    };
    dispatch(getVideoConferenceURL(data));
    setShowVideoConference(true);
  };

  // const onHideAdmonishment = () => {
  //   // setShowAdmonishment(false);
  // };

  const onHide = () => {
    dispatch(
      updateElementValueVideoConferenceReducer({
        elementName: "videoConferenceStatus",
        value: "idle",
      })
    );
    setShowVideoConference(false);
  };

  const handleConfirmation = () => {
    setconfirmPopupData({ show: false, swObj: {} });
    if (confirmOriginPlace === "handleFileEdit") {
      afterConfirmOnEditClick(confirmPopupData.swObj);
    } else if (confirmOriginPlace === "onSubmitClick") {
      passwordProtector(confirmPopupData.swObj);
    } else if (confirmOriginPlace === "onApproveClick") {
      afterConfirmOnApproveClick(confirmPopupData.swObj);
    } else if (confirmOriginPlace === "EditFailed") {
      //
    } else if (confirmOriginPlace === "onVoidClick") {
      afterConfirmOnVoidClick();
    } else if (confirmOriginPlace === "onReturnClick") {
      afterConfirmOnReturnClick();
    } else if (confirmOriginPlace === "onDeleteClick") {
      afterConfirmOnDeleteClick(confirmPopupData.swObj);
    } else if (confirmOriginPlace === "onDenyClick") {
      afterConfirmOnDenyClick(confirmPopupData.swObj);
    } else if (confirmOriginPlace === "onUnlockClick") {
      afterConfirmOnUnlockClick(confirmPopupData.swObj);
    } else if (confirmOriginPlace === "onSignClick") {
      afterConfirmSignClick(confirmPopupData.swObj);
    } else if (confirmOriginPlace === "OnCmsPendingClick") {
      updateCmsFormStatusFromSyncToPending(confirmPopupData.swObj);
    } else if (confirmOriginPlace === "onPasswordSetClick") {
      afterConfirmPasswordSetClick(confirmPopupObj);
    }
  };

  const afterConfirmPasswordSetClick = (swObj: any) => {
    setSecurityPasswordProps({
      complaintId: caseDetailReducer.searchWarrantId,
      caseNumber: caseDetailReducer.caseNumber,
      action: "SAVE",
    });
    setSecurityPasswordShow(true);
  };

  const passwordProtector = (swObj: any) => {
    if (swObj.formType === COMPL_SEARCH_WARRANT_WITH_SLASH) {
      setIsSubmit(true);
      setconfirmPopupObj(swObj);
      if (caseDetailReducer.protectedComplaint) {
        setShowOptionPopup(true);
        setOptionObj({
          header: "",
          body: (
            <>
              <div>Password protected complaint. Do you want to change it?</div>
            </>
          ),
          buttons: [
            {
              name: "Reset",
              variant: "primary",
              onClick: () => {
                removeAndResetPassword("RESET");
              },
            },
            {
              name: "Remove",
              variant: "primary",
              onClick: () => {
                removeAndResetPassword("REMOVE");
              },
            },
            { name: "Cancel", variant: "danger", onClick: cancelPopup },
          ],
        });
      } else {
        let formMessage =
          "Do you want to secure this complaint by setting a password?";
        setconfirmText(formMessage);
        setconfirmOriginPlace("onPasswordSetClick");
        setconfirmPopupData({
          show: true,
          swObj: swObj,
        });
      }
    } else {
      afterConfirmOnSubmitClick(swObj);
    }
  };

  const cancelSecurityPasswordPopup = () => {
    setShowOptionPopup(false);
    setSecurityPasswordShow(false);
    setconfirmPopupData({ show: false, swObj: {} });
    console.log(isSubmit);
    if (isSubmit) {
      afterConfirmOnSubmitClick(confirmPopupObj);
      setIsSubmit(false);
    }
  };

  const cancelPopup = () => {
    setShowOptionPopup(false);
    setOptionObj({
      header: "",
      body: <></>,
      buttons: [],
    });
    setconfirmPopupData({ show: false, swObj: {} });
    afterConfirmOnSubmitClick(confirmPopupObj);
  };

  const afterConfirmSignClick = async (swObj: any) => {
    let formName = swObj.formType.replace("/", "");
    dispatch(resetSignature());
    loadingSignaturedetails(swObj.formType);
    await dispatch(
      getSearchWarrantData({
        searchWarrantId: swObj.searchWarrantId,
        formName: formName,
        navigate: "navigate",
      })
    );
    setwhoSign(loggedInUserRole + " SIGNATURE");
    setawornText(
      "Complainant Signature will be only applied after the content of this complaint is sworn before a judge"
    );
    setcomplainantNameFooter(
      `( ${loginReducer.firstName} ${loginReducer.lastName})`
    );
    settype(swObj.formType);
    setid(swObj.searchWarrantId);
    setisSignPanelPopup(true);
  };

  const updateCmsFormStatusFromSyncToPending = (swObj: any) => {
    dispatch(
      updateCmsSyncFormStatusToPending({
        searchWarrantId: swObj.searchWarrantId,
        navigate: navigate,
      })
    );
  };

  const afterConfirmOnUnlockClick = (swObj: any) => {
    let newStatus = "";
    if (swObj.formStatus === SEARCH_WAR_LOCKED_BY_SA_STATUS) {
      newStatus = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
    } else if (swObj.formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS) {
      newStatus = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
    } else if (swObj.formStatus === SEARCH_WAR_LOCKED_BY_AG_STATUS) {
      newStatus = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
    } else if (swObj.formStatus === SEARCH_WAR_LOCKED_BY_LP_STATUS) {
      newStatus = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
    } else {
      newStatus = swObj.formStatus;
    }

    let proxyObj: any = {};
    proxyObj.formStatus = newStatus;
    proxyObj.notificationCallFrom = "Unlock";

    dispatch(
      updateSearchWarrantDetail({
        searchWarrantId: swObj.searchWarrantId,
        action: "unlockWarrant",
        warrantFormObj: proxyObj,
      })
    );
  };

  const afterConfirmOnDenyClick = (swObj: any) => {
    dispatch(
      getSearchWarrantDetails({
        searchWarrantId: swObj.searchWarrantId,
        action: "DENY",
        detailObj: swObj,
        navigate: navigate,
      })
    );
  };

  const afterConfirmOnDeleteClick = (swObj: any) => {
    let proxy: any = {};
    proxy.formStatus = SEARCH_WAR_DELETED_STATUS;
    proxy.formType = swObj.formType;
    proxy.icon = "Delete";
    dispatch(
      deleteSearchWarrant({
        searchWarrantId: swObj.searchWarrantId,
        proxy: proxy,
        navigate: navigate,
      })
    );
  };

  const afterConfirmOnReturnClick = () => {
    let returnObject: any = { ...returnDetails };
    returnObject.userType = userType;
    returnObject.userProfileId = userProfileId;
    returnObject.isSARequired = caseDetailReducer.county.isSARequired;

    dispatch(
      getSearchWarrantDetails({
        searchWarrantId: returnDetails.searchWarrantId,
        action: "RETURN",
        detailObj: returnObject,
        navigate: navigate,
      })
    );
  };

  const afterConfirmOnSubmitClick = (swObj: any) => {
    const { searchWarrantId, status, formType, saApprovalId, creatorId } =
      swObj;
    let newStatus = "";
    if (
      SEARCH_WAR_OFFICER_PENDING_STATUS === status ||
      SEARCH_WAR_ASSIGNED_TO_AG_STATUS === status ||
      SEARCH_WAR_ASSIGNED_TO_LP_STATUS === status ||
      SEARCH_WAR_ASSIGNED_TO_SA_STATUS === status
    ) {
      if (INVENTORY === formType || AMENDED_INVENTORY === formType) {
        newStatus = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
      } else {
        if (
          COMPL_SEARCH_WARRANT_WITH_SLASH === formType ||
          APPLICATION_AND_ORDER === formType ||
          BLANK_ORDER === formType
        ) {
          let isScreeningRequired = isScreeningRequiredFunc(
            caseDetailReducer.approvalUserTypeName,
            caseDetailReducer.county.isSARequired
          );
          if (isScreeningRequired) {
            if (saApprovalId && saApprovalId > 0) {
              newStatus = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
            } else {
              if (caseDetailReducer.approvalUserTypeName) {
                if (
                  caseDetailReducer.approvalUserTypeName ===
                  ROLE_ATTORNEY_GENERAL
                ) {
                  newStatus = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
                } else if (
                  caseDetailReducer.approvalUserTypeName ===
                  ROLE_LOCAL_PROSECUTOR
                ) {
                  newStatus = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
                } else {
                  newStatus = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
                }
              } else {
                newStatus = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
              }
            }
          } else {
            newStatus = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
          }
        }
      }
    } else if (
      (APPLICATION_AND_ORDER === formType || BLANK_ORDER === formType) &&
      (status === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
        status === SEARCH_WAR_LOCKED_BY_SA_STATUS ||
        status === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
        status === SEARCH_WAR_LOCKED_BY_AG_STATUS ||
        status === SEARCH_WAR_ASSIGNED_TO_LP_STATUS ||
        status === SEARCH_WAR_LOCKED_BY_LP_STATUS) &&
      creatorId === loginReducer.userProfileId
    ) {
      newStatus = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS;
    } else {
      newStatus = status;
    }

    let approvalSAId = 0;
    if (!(APPLICATION_AND_ORDER === formType || BLANK_ORDER === formType)) {
      if (
        newStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
        caseDetailReducer.approvalSA &&
        caseDetailReducer.approvalSA.trim().length > 0
      ) {
        if (saApprovalId && saApprovalId > 0) {
          approvalSAId = saApprovalId;
        }
      }
    } else {
      if (
        (newStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
          caseDetailReducer.approvalOrderBySA != null) ||
        (newStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
          loginReducer.userProfileId === creatorId)
      ) {
        if (saApprovalId && saApprovalId > 0) {
          approvalSAId = saApprovalId;
        }
      }
    }

    let proxy = {
      formStatus: newStatus,
      formType: swObj.formType,
      remarks: swObj.remarks,
      notificationCallFrom: "Submit",
    };
    dispatch(
      updateSearchWarrantDetail({
        searchWarrantId: swObj.searchWarrantId,
        action: "updateStatus",
        warrantFormObj: proxy,
      })
    );
  };

  const afterConfirmOnApproveClick = (swObj: any) => {
    let proxy = {
      formStatus: SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS,
      formType: swObj.formType,
      remarks: swObj.remarks,
      notificationCallFrom: "Approve",
      otherForms:
        formApprovedStatus === "ALL"
          ? approvalStatusForms(
              caseDetailReducer,
              loggedInUserRole,
              loggedInUserId,
              loggedInUserName
            )
          : [],
    };
    dispatch(
      updateSearchWarrantDetail({
        searchWarrantId: swObj.searchWarrantId,
        action: "updateReviewer",
        warrantFormObj: proxy,
      })
    );
  };

  function handleSearchWarrantEdit(message: any) {
    let confirmText = (
      <>
        <label>{message}</label>
      </>
    );
    setconfirmText(confirmText);
    setconfirmOriginPlace("handleFileEdit");
  }

  function afterConfirmOnEditClick(editConfirmPopupData: any) {
    updateStatusOnEdit(
      editConfirmPopupData.formStatus,
      editConfirmPopupData.formType,
      editConfirmPopupData.creatorId,
      editConfirmPopupData.searchWarrantId,
      editConfirmPopupData.formCreatorId,
      editConfirmPopupData.loggedInUserId,
      editConfirmPopupData.loggedInUserRole,
      editConfirmPopupData.onBehalf
    );
  }

  function getSubmitImage(formType: string, userRole: string) {
    let submitImage = "";
    let isScreeningRequired = false;
    const { county, approvalSA, approvalUserTypeName } = caseDetailReducer;
    if (
      INVENTORY === swForm.formType ||
      AMENDED_INVENTORY === swForm.formType
    ) {
      submitImage = "submit-to-judge";
    } else {
      
      isScreeningRequired = isScreeningRequiredFunc(approvalUserTypeName, county.isSARequired);
      if (COMPL_SEARCH_WARRANT_WITH_SLASH === swForm.formType) {
        if (isScreeningRequired) {
          if (approvalSA && approvalSA.trim().length > 0) {
            submitImage = "submit-to-judge";
          } else {
            submitImage = "submit-screening";
          }
        } else {
          submitImage = "submit-to-judge";
        }
      } else if (
        APPLICATION_AND_ORDER === swForm.formType ||
        BLANK_ORDER === swForm.formType
      ) {
        if (isScreeningRequired) {
          if (swForm.approvalSAId && swForm.approvalSAId > 0) {
            submitImage = "submit-to-judge";
          } else if (
            (loggedInUserRole === ROLE_STATES_ATTORNEY ||
              loggedInUserRole === ROLE_ATTORNEY_GENERAL ||
              loggedInUserRole === ROLE_LOCAL_PROSECUTOR) &&
            swForm.creatorId === loggedInUserId
          ) {
            submitImage = "submit-to-judge";
          } else {
            submitImage = "submit-screening";
          }
        } else {
          submitImage = "submit-to-judge";
        }
      }
    }
    return submitImage;
  }

  function redirectToForm(formType: any, searchWarrantId: number) {
    //updateFailSuccessMessage("");
    if (COMPL_SEARCH_WARRANT_WITH_SLASH === formType) {
      dispatch(
        getSearchWarrantData({
          searchWarrantId: searchWarrantId,
          formName: COMPL_SEARCH_WARRANT,
          navigate: navigate,
        })
      );
    } else if (INVENTORY === formType) {
      dispatch(
        getSearchWarrantData({
          searchWarrantId: searchWarrantId,
          formName: INVENTORY,
          navigate: navigate,
        })
      );
    } else if (AMENDED_INVENTORY === formType) {
      dispatch(
        getSearchWarrantData({
          searchWarrantId: searchWarrantId,
          formName: AMENDED_INVENTORY,
          navigate: navigate,
        })
      );
    } else if (BLANK_ORDER === formType) {
      dispatch(
        getSearchWarrantData({
          searchWarrantId: searchWarrantId,
          formName: BLANK_ORDER,
          navigate: navigate,
        })
      );
    } else if (APPLICATION_AND_ORDER === formType) {
      dispatch(
        getSearchWarrantData({
          searchWarrantId: searchWarrantId,
          formName: APPLICATION_AND_ORDER,
          navigate: navigate,
        })
      );
    } else {
      alert("Need to implement.");
    }
  }

  function updateStatusOnEdit(
    formStatus: any,
    formName: string,
    creatorId: number,
    searchWarrantId: number,
    formCreatorId: number,
    userId: number,
    userRole: string,
    onBehalf: boolean
  ) {
    let otherForms: any[] = [];
    let currentStatus: any = formStatus;
    if (currentStatus && formStatus === currentStatus) {
      if (ROLE_ADMINISTRATOR === userRole) {
        redirectToForm(formName, searchWarrantId);
      } else if (ROLE_COUNTY_ADMIN === userRole) {
        redirectToForm(formName, searchWarrantId);
      } else {
        let newStatus = currentStatus;
        if (
          currentStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS ||
          currentStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS ||
          currentStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
          currentStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS ||
          currentStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS
        ) {
          if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS &&
            userRole === ROLE_STATES_ATTORNEY &&
            onBehalf
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_SA_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS &&
            userRole === ROLE_ATTORNEY_GENERAL &&
            onBehalf
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_AG_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS &&
            userRole === ROLE_LOCAL_PROSECUTOR &&
            onBehalf
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_LP_STATUS;
          } else if (
            (creatorId === userId || formCreatorId === userId) &&
            userRole !== ROLE_STATES_ATTORNEY &&
            userRole !== ROLE_ATTORNEY_GENERAL &&
            userRole !== ROLE_LOCAL_PROSECUTOR &&
            userRole !== ROLE_JUDGE
          ) {
            newStatus = SEARCH_WAR_OFFICER_PENDING_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS &&
            userRole === ROLE_STATES_ATTORNEY &&
            (creatorId !== userId || formCreatorId === userId)
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_SA_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS &&
            userRole === ROLE_ATTORNEY_GENERAL &&
            (creatorId !== userId || formCreatorId === userId)
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_AG_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS &&
            userRole === ROLE_LOCAL_PROSECUTOR &&
            (creatorId !== userId || formCreatorId === userId)
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_LP_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS &&
            userRole === ROLE_STATES_ATTORNEY &&
            creatorId !== userId &&
            formCreatorId === userId
          ) {
            newStatus = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS &&
            userRole === ROLE_ATTORNEY_GENERAL &&
            creatorId !== userId &&
            formCreatorId === userId
          ) {
            newStatus = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS &&
            userRole === ROLE_LOCAL_PROSECUTOR &&
            creatorId !== userId &&
            formCreatorId === userId
          ) {
            newStatus = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS &&
            userRole === ROLE_JUDGE &&
            creatorId !== userId &&
            formCreatorId === userId
          ) {
            // && ((creatorId !== userId && !formCreatorId === userId))) {
            newStatus = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS &&
            userRole === ROLE_STATES_ATTORNEY &&
            (creatorId !== userId || formCreatorId === userId)
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_SA_STATUS;
          } else if (
            SEARCH_WAR_ASSIGNED_TO_SA_STATUS === currentStatus &&
            creatorId === userId &&
            userRole === ROLE_STATES_ATTORNEY
          ) {
            newStatus = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
          } else if (
            SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS === currentStatus &&
            creatorId === userId &&
            userRole === ROLE_STATES_ATTORNEY
          ) {
            newStatus = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
          } else if (
            SEARCH_WAR_ASSIGNED_TO_AG_STATUS === currentStatus &&
            creatorId === userId &&
            userRole === ROLE_ATTORNEY_GENERAL
          ) {
            newStatus = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
          } else if (
            SEARCH_WAR_ASSIGNED_TO_LP_STATUS === currentStatus &&
            creatorId === userId &&
            userRole === ROLE_LOCAL_PROSECUTOR
          ) {
            newStatus = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
          } else if (
            SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS === currentStatus &&
            creatorId === userId &&
            userRole === ROLE_ATTORNEY_GENERAL
          ) {
            newStatus = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
          } else if (
            SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS === currentStatus &&
            creatorId === userId &&
            userRole === ROLE_LOCAL_PROSECUTOR
          ) {
            newStatus = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
          } else if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
            userRole === ROLE_JUDGE
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_JUDGE_STATUS;
          } else if (
            (currentStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
              currentStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) &&
            userRole === ROLE_STATES_ATTORNEY
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_SA_STATUS;
          } else if (
            (currentStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
              currentStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) &&
            userRole === ROLE_ATTORNEY_GENERAL
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_AG_STATUS;
          } else if (
            (currentStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
              currentStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) &&
            userRole === ROLE_LOCAL_PROSECUTOR
          ) {
            newStatus = SEARCH_WAR_LOCKED_BY_LP_STATUS;
          } else {
          }
          if (
            currentStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS &&
            (userRole === ROLE_LOCAL_PROSECUTOR ||
              userRole === ROLE_ATTORNEY_GENERAL ||
              userRole === ROLE_STATES_ATTORNEY)
          ) {
            caseDetailReducer.searchWarrantList.forEach((el: any) => {
              if (
                (el.formType === APPLICATION_AND_ORDER ||
                  el.formType === BLANK_ORDER) &&
                el.formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS
              ) {
                otherForms.push(el.searchWarrantId);
              }
            });
          }
        }
        if (
          newStatus !== currentStatus ||
          newStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
          currentStatus === SEARCH_WAR_OFFICER_PENDING_STATUS ||
          currentStatus === SEARCH_WAR_INCOMPLETE_STATUS
        ) {
          let onBehalf = caseDetailReducer.onBehalf;
          let proxyObj: any = {};
          proxyObj.formStatus = newStatus;
          proxyObj.formType = formName;
          proxyObj.notificationCallFrom = "N/A";
          proxyObj.otherForms = otherForms;

          dispatch(
            updateSearchWarrantDetail({
              searchWarrantId: searchWarrantId,
              action: "updateStatus",
              warrantFormObj: proxyObj,
              navigate: navigate,
            })
          );
        } else {
          redirectToForm(formName, searchWarrantId);
        }
      }
    } else {
      let message = "Form has been changed from another machine.";
      handleSearchWarrantEdit(message);
      dispatch(
        getSWCaseDetail({
          searchWarrantId: searchWarrantId,
          action: "getDetails",
          navigate: navigate,
        })
      );
    }
  }

  const remarkAfterEdit = () => {
    let data: any = remarkModalData;
    onEditClickAfter(
      data.searchWarrantId,
      data.formType,
      data.status,
      data.creatorId,
      data.formCreatorId,
      data.lockUserId
    );
  };

  const onEditClick =
    (
      searchWarrantId: number,
      formType: string,
      status: any,
      creatorId: number,
      formCreatorId: number,
      lockUserId: number,
      remark: string
    ) =>
    () => {
      if (remark && remark.trim().length > 0) {
        setRemarkModalData({
          searchWarrantId,
          formType,
          status,
          creatorId,
          formCreatorId,
          lockUserId,
        });

        setOnlyView(true);
        setViewRemark(remark);
        setRemarkModalShow(true);
        setIsEditClick(true);
      } else {
        onEditClickAfter(
          searchWarrantId,
          formType,
          status,
          creatorId,
          formCreatorId,
          lockUserId
        );
      }
    };

  const oninventorySignClick =
    (
      searchWarrantId: number,
      formType: string,
      status: any,
      creatorId: number,
      formCreatorId: number,
      lockUserId: number,
      remark: string
    ) =>
      () => {
        if (formType === INVENTORY) {
          dispatch(
            updateElementValueInventoryReducer({
              elementName: "judgeSignControl",
              value: true,
            })
          );
        }
        onEditClickAfter(
          searchWarrantId,
          formType,
          status,
          creatorId,
          formCreatorId,
          lockUserId
        );
      };

  const onEditClickAfter = (
    searchWarrantId: number,
    formType: string,
    status: any,
    creatorId: number,
    formCreatorId: number,
    lockUserId: number
  ) => {
    if (
      status &&
      (SEARCH_WAR_OFFICER_PENDING_STATUS !== status ||
        !lockUserId ||
        lockUserId <= 0 ||
        loggedInUserId === lockUserId ||
        ROLE_ADMINISTRATOR === loggedInUserRole ||
        ROLE_COUNTY_ADMIN === loggedInUserRole)
    ) {
      // if (ROLE_ADMINISTRATOR !== loggedInUserRole
      //   || ROLE_COUNTY_ADMIN !== loggedInUserRole) {
      let message = getConfirmedEditMessage(
        status,
        creatorId,
        formCreatorId,
        loggedInUserId,
        loggedInUserRole,
        caseDetailReducer.onBehalf
      );
      if (message && message.trim().length > 0) {
        let objUse: any = {};
        objUse.formStatus = status;
        objUse.formType = formType;
        objUse.creatorId = creatorId;
        objUse.searchWarrantId = searchWarrantId;
        objUse.formCreatorId = formCreatorId;
        objUse.loggedInUserId = loggedInUserId;
        objUse.loggedInUserRole = loggedInUserRole;
        objUse.onBehalf = caseDetailReducer.onBehalf;
        setconfirmPopupData({
          show: true,
          swObj: objUse,
        });
        handleSearchWarrantEdit(message);
        return;
      }
      updateStatusOnEdit(
        status,
        formType,
        creatorId,
        searchWarrantId,
        formCreatorId,
        loggedInUserId,
        loggedInUserRole,
        caseDetailReducer.onBehalf
      );
    } else {
      let message = "Form has been changed from another machine.";
      handleSearchWarrantEdit(message);
      dispatch(
        getSWCaseDetail({
          searchWarrantId: searchWarrantId,
          action: "getDetails",
          navigate: navigate,
        })
      );
    }
    // }
    // this.props.showLoading(false);
  };

  const onPreviewClick =
    (searchWarrantId: number, formType: string, formStatus: string) => () => {
      if (COMPL_SEARCH_WARRANT_WITH_SLASH === formType) {
        dispatch(
          previewOrder({
            formId: searchWarrantId,
            formName: COMPL_SEARCH_WARRANT,
            formObject: {},
          })
        );
      } else if (APPLICATION_AND_ORDER === formType) {
        dispatch(
          previewOrder({
            formId: searchWarrantId,
            formName: APPLICATION_AND_ORDER,
            formObject: {},
          })
        );
      } else if (PROOF_OF_SERVICE === formType) {
        dispatch(
          previewOrder({
            formId: searchWarrantId,
            formName: PROOF_OF_SERVICE,
            formObject: {},
          })
        );
      } else if (BLANK_ORDER === formType) {
        dispatch(
          previewOrder({
            formId: searchWarrantId,
            formName: BLANK_ORDER,
            formObject: {},
          })
        );
      } else if (INVENTORY === formType) {
        dispatch(
          previewOrder({
            formId: searchWarrantId,
            formName: INVENTORY,
            formObject: {},
          })
        );
      } else if (AMENDED_INVENTORY === formType) {
        dispatch(
          previewOrder({
            formId: searchWarrantId,
            formName: AMENDED_INVENTORY,
            formObject: {},
          })
        );
      } else if (ORDER_OF_CUSTODY === formType) {
        dispatch(
          previewOrder({
            formId: searchWarrantId,
            formName: ORDER_OF_CUSTODY,
            formObject: {},
          })
        );
      } else {
        alert("Need to implement.");
      }
    };

  const onGeneratePDFClick =
    (formType: string, searchWarrantId: number) => () => {
      setDeveloperOptionVisible(true);
      setGenerate(true);
      setFormType(formType);
      setFormId(searchWarrantId);
    };

  const onReplacePDFClick =
    (formType: string, searchWarrantId: number) => () => {
      setDeveloperOptionVisible(true);
      setGenerate(false);
      setFormType(formType);
      setFormId(searchWarrantId);
    };

  const onSubmitClick =
    (
      searchWarrantId: number,
      formType: string,
      formStatus: string,
      remarks: string,
      creatorId: number,
      saApprovalId: number
    ) =>
    () => {
      if (BLANK_ORDER === formType || APPLICATION_AND_ORDER === formType) {
        submitProcess(
          searchWarrantId,
          formType,
          formStatus,
          remarks,
          creatorId,
          saApprovalId
        );
      } else {
        submitProcess(
          searchWarrantId,
          formType,
          formStatus,
          remarks,
          creatorId,
          saApprovalId
        );
      }
    };

  const submitProcess = (
    searchWarrantId: number,
    formType: string,
    formStatus: string,
    remarks: string,
    creatorId: number,
    saApprovalId: number
  ) => {
    if (
      (!saApprovalId || saApprovalId <= 0) &&
      (loginReducer.userType === ROLE_STATES_ATTORNEY ||
        loginReducer.userType === ROLE_ATTORNEY_GENERAL ||
        loginReducer.userType === ROLE_LOCAL_PROSECUTOR)
    ) {
      saApprovalId = loginReducer.userProfileId;
    }

    if (remarks && remarks.trim().length > 0) {
      let alertMessage = "";
      if (COMPL_SEARCH_WARRANT_WITH_SLASH === formType) {
        alertMessage =
          "Search Warrant application can be resubmitted only after it is modified as per the remarks added. " +
          "Click on remarks link to view details.";
      } else if (INVENTORY === formType) {
        alertMessage =
          "Inventory application can be resubmitted after it is modified as per remarks. " +
          "Click on remarks link to view details.";
      } else if (AMENDED_INVENTORY === formType) {
        alertMessage =
          "Amended Inventory application can be resubmitted after it is modified as per remarks. " +
          "Click on remarks link to view details.";
      } else if (APPLICATION_AND_ORDER === formType) {
        alertMessage =
          "Application can be resubmitted after it is modified as per remarks. " +
          "Click on remarks link to view details.";
      } else if (BLANK_ORDER === formType) {
        alertMessage =
          "Order can be resubmitted after it is modified as per remarks. " +
          "Click on remarks link to view details.";
      }
      if (alertMessage && alertMessage.trim().length > 0) {
        setShowInfoPopup({ show: true, info: alertMessage });
      }
    } else {
      let message = "";
      if (COMPL_SEARCH_WARRANT_WITH_SLASH === formType) {
        if (
          caseDetailReducer.approvalSA &&
          caseDetailReducer.approvalSA.trim().length > 0
        ) {
          message = "Do you want to submit the form for review to judge?";
        } else {
          let isScreeningRequired = isScreeningRequiredFunc(
            caseDetailReducer.approvalUserTypeName,
            caseDetailReducer.county.isSARequired
          );
          if (!isScreeningRequired) {
            message = "Do you want to submit the form for review to judge?";
          } else {
            message = "Do you want to submit the form for screening?";
          }
        }
      } else if (INVENTORY === formType) {
        message = "Do you want to submit the form for review to judge?";
      } else if (AMENDED_INVENTORY == formType) {
        message = "Do you want to submit the form for review to judge?";
      } else if (
        APPLICATION_AND_ORDER === formType ||
        BLANK_ORDER === formType
      ) {
        if (saApprovalId && saApprovalId > 0) {
          message = "Do you want to submit the form for review to judge?";
        } else if (
          (!saApprovalId || saApprovalId === 0) &&
          creatorId === loginReducer.userProfileId &&
          (loginReducer.userType === ROLE_STATES_ATTORNEY ||
            loginReducer.userType === ROLE_ATTORNEY_GENERAL ||
            loginReducer.userType === ROLE_LOCAL_PROSECUTOR)
        ) {
          message = "Do you want to submit the form for review to judge?";
        } else {
          let isScreeningRequired = isScreeningRequiredFunc(
            caseDetailReducer.approvalUserTypeName,
            caseDetailReducer.county.isSARequired
          );
          if (!isScreeningRequired) {
            message = "Do you want to submit the form for review to judge?";
          } else {
            message = "Do you want to submit the form for screening?";
          }
        }
      }

      if (message && message.trim().length > 0) {
        // let objUse = {};
        // objUse.status = status;
        // objUse.formType = formType;
        // objUse.saApprovalId = saApprovalId;
        // objUse.creatorId = creatorId;
        // objUse.searchWarrantId = searchWarrantId;
        // this.setState({
        //   confirmationModalShow: true,
        //   confirmMessage: message,
        //   confirmOriginPlace: "onSubmitClick",
        //   objUseAfterConfirm: objUse
        // });

        setconfirmText(message);
        setconfirmOriginPlace("onSubmitClick");
        setconfirmPopupData({
          show: true,
          swObj: {
            searchWarrantId: searchWarrantId,
            status: formStatus,
            formType: formType,
            saApprovalId: saApprovalId,
            creatorId: creatorId,
            remarks: remarks,
          },
        });
        return;
      }

      // this.continueOnSubmitProcess(status, formType, saApprovalId, creatorId, searchWarrantId);
    }
  };

  const onApproveClick =
    (searchWarrantId: number, formType: string, remarks: string) => () => {
      if (
        !caseDetailReducer.approvalSAId ||
        caseDetailReducer.approvalSAId === 0
      ) {
        if (APPLICATION_AND_ORDER === formType) {
          setShowInfoPopup({
            show: true,
            info: "Please approve Search Warrant before approving Application and Order form.",
          });
          return;
        } else if (BLANK_ORDER === formType) {
          setShowInfoPopup({
            show: true,
            info: "Please approve Search Warrant before approving Blank order form.",
          });
          return;
        }
      }
      let confirmText = (
        <>
          <label>
            {
              'Form status will be changed to "Assigned to Judge". Do you want to continue?'
            }
          </label>
        </>
      );
      setconfirmText(confirmText);
      setconfirmOriginPlace("onApproveClick");
      if (
        COMPL_SEARCH_WARRANT_WITH_SLASH === formType &&
        approvalStatusForms(
          caseDetailReducer,
          loggedInUserRole,
          loggedInUserId,
          loggedInUserName
        ).length > 0
      ) {
        setShowOptionPopup(true);
        setOptionObj({
          header: "",
          body: (
            <>
              <div>
                There are other forms attached to this complaint/searchwarrant.
                Do you want to submit all of them?
              </div>
            </>
          ),
          buttons: [
            {
              name: "Submit All",
              variant: "primary",
              onClick: onSubmitAllForms,
            },
            {
              name: "Submit This",
              variant: "primary",
              onClick: onSubmitOneForm,
            },
            { name: "Cancel", variant: "danger", onClick: cancelOptionPopup },
          ],
        });
        setconfirmPopupObj({
          searchWarrantId: searchWarrantId,
          formType: formType,
          remarks: remarks,
        });
      } else {
        setconfirmPopupData({
          show: true,
          swObj: {
            searchWarrantId: searchWarrantId,
            formType: formType,
            remarks: remarks,
          },
        });
      }
    };

  const signClick =
    (
      searchWarrantId: number,
      formType: string,
      status: any,
      creatorId: number,
      formCreatorId: number,
      lockUserId: number
    ) =>
    async () => {
      let confitmTextMessage =
        "Form cannot be changed once signed. Do you want to continue?";
      if (formType === COMPL_SEARCH_WARRANT_WITH_SLASH) {
        confitmTextMessage =
          "Has the officer sworn to the content of this Complaint/Search Warrant?";
      } else if (formType === INVENTORY) {
        confitmTextMessage =
          "Form cannot be changed once signed. Do you want to continue?";
      } else if (formType === AMENDED_INVENTORY) {
        confitmTextMessage =
          "Form cannot be changed once signed. Do you want to continue?";
      } else {
        confitmTextMessage =
          "Form cannot be changed once signed. Do you want to continue?";
      }
      let confirmText = (
        <>
          <label>{confitmTextMessage}</label>
        </>
      );
      setconfirmText(confirmText);
      setconfirmOriginPlace("onSignClick");
      setconfirmPopupData({
        show: true,
        swObj: {
          searchWarrantId: searchWarrantId,
          formType: formType,
        },
      });
    };

  const onSubmitAllForms = () => {
    setFormApprovedStatus("ALL");
    cancelOptionPopup();
  };

  const onSubmitOneForm = () => {
    setFormApprovedStatus("ONE");
    cancelOptionPopup();
  };

  const cancelOptionPopup = () => {
    setShowOptionPopup(false);
  };

  const cmsPendinfClick = (searchWarrantId: number) => () => {
    let confirmText = <label>{SW_CONFIRM_CMSPENDING}</label>;
    setconfirmText(confirmText);
    setconfirmOriginPlace("OnCmsPendingClick");
    setconfirmPopupData({
      show: true,
      swObj: {
        searchWarrantId: searchWarrantId,
      },
    });
  };

  const onViewRemarkClick = (remark: string) => () => {
    setOnlyView(true);
    setViewRemark(remark);
    setRemarkModalShow(true);
    setIsEditClick(false);
  };

  const getAuditTrails =
    (searchWarrantId: number, formType: string, caseNumber: string) => () => {
      let auditObj = {
        searchWarrantId: searchWarrantId,
        auditType: AUDIT_TYPE_MODIFICATION,
      };
      dispatch(fetchAuditTrailData(auditObj));
      if (auditTrailReducer.auditStatus === "success") {
        setShowAuditTrail(true);
        setSearchWarrantIdForAuditTrail(searchWarrantId);
        setFormTypeForAuditTrail(formType);
        setCaseNumberForAuditTrail(caseNumber);
        setAuditType(AUDIT_TYPE_MODIFICATION);
      }
    };

  const showAuditTrailModal = () => {
    let searchWarrantId = caseDetailReducer.searchWarrantId;
    let formType = "";
    let caseNumber = caseDetailReducer.caseNumber;

    let auditObj = {
      searchWarrantId: searchWarrantId,
      auditType: AUDIT_TYPE_ACCESS,
    };
    dispatch(fetchAuditTrailData(auditObj));
    setShowAuditTrail(true);
    setSearchWarrantIdForAuditTrail(searchWarrantId);
    setFormTypeForAuditTrail(formType);
    setCaseNumberForAuditTrail(caseNumber);
    setAuditType(AUDIT_TYPE_ACCESS);
    //setAuditTrialPopupData(searchWarrantId,formType,caseNumber);
  };
  const cancelAuditTrailModal = () => {
    setShowAuditTrail(false);
  };

  const onUnlockClick = (searchWarrantId: number, formStatus: string) => () => {
    setconfirmText("Do you want to unlock the form?");
    setconfirmOriginPlace("onUnlockClick");

    let objUnlock: any = {};
    objUnlock.searchWarrantId = searchWarrantId;
    objUnlock.formStatus = formStatus;

    setconfirmPopupData({
      show: true,
      swObj: objUnlock,
    });
  };

  const onDenyClick =
    (searchWarrantId: number, formType: string, formStatus: string) => () => {
      setconfirmText(
        formType + " cannot be modified once denied. Do you want to continue?"
      );
      setconfirmOriginPlace("onDenyClick");
      let objDeny: any = {};
      objDeny.searchWarrantId = searchWarrantId;
      objDeny.formStatus = formStatus;
      objDeny.formType = formType;

      setconfirmPopupData({
        show: true,
        swObj: objDeny,
      });
    };

  const onDeleteClick =
    (
      searchWarrantId: number,
      formType: string,
      formStatus: string,
      creatorId: number,
      formCreatorId: number,
      formCreatorType: string,
      approvalSAId: number
    ) =>
    () => {
      let formMessage = "";
      if (BLANK_ORDER === formType || APPLICATION_AND_ORDER === formType) {
        formMessage = "Do you want to delete the form?";
      } else if (AMENDED_INVENTORY === formType) {
        formMessage = "Do you want to delete the amended inventory form?";
      } else if (INVENTORY === formType) {
        formMessage = "Do you want to delete the inventory form?";
      } else if (ORDER_OF_CUSTODY === formType) {
        formMessage = "Do you want to delete the order of custody form?";
      } else if (PROOF_OF_SERVICE === formType) {
        formMessage = "Do you want to delete the proof of service form?";
      }

      let objUse: any = {};
      objUse.searchWarrantId = searchWarrantId;
      objUse.formType = formType;

      setconfirmText(formMessage);
      setconfirmOriginPlace("onDeleteClick");
      setconfirmPopupData({
        show: true,
        swObj: objUse,
      });
    };

  const onReturnClick =
    (
      searchWarrantId: number,
      formType: string,
      formStatus: string,
      creatorId: number,
      formCreatorId: number,
      formCreatorType: string,
      approvalSAId: number
    ) =>
    () => {
      const { imgRemarksTitle } = getImgRemarksAndTitle(formCreatorType);

      setAction(imgRemarksTitle);
      setIsEditClick(false);
      setRemarkRequired(true);
      setRemarkFor("RETURN");
      setRemarkModalShow(true);
      setOnlyView(false);
      setReturnDetails({
        searchWarrantId: searchWarrantId,
        formType: formType,
        formStatus: formStatus,
        creatorId: creatorId,
        formCreatorId: formCreatorId,
        formCreatorType: formCreatorType,
        approvalSAId: approvalSAId,
        status: "",
        remark: "",
      });
      setconfirmOriginPlace("onReturnClick");
    };

  const getFormTypeLink = (
    formType: string,
    isImpounded: boolean,
    templateName: string,
    isAllowEdit: boolean
  ) => {
    let impounStr = isImpounded ? " - " + CASE_DETAIL_IMOUNDED : "";
    let tempName =
      templateName !== null && templateName !== "" ? " - " + templateName : "";

    let formTypeLink;
    if (isAllowEdit) {
      formTypeLink = (
        <b className="text-danger">
          {formType} {tempName} {impounStr}
        </b>
      );
    } else {
      formTypeLink = (
        <>
          {formType} <br />
          <b className="text-danger">
            {tempName} {impounStr}
          </b>
        </>
      );
    }
    return formTypeLink;
  };

  let swForm: any;
  let allowSign: boolean = false;
  let submitImage: string = "";
  let allowEdit: boolean = false;
  let allowSubmit: boolean = false;
  let allowUnlock: boolean = false;
  let allowApprove: boolean = false;
  let allowRevert: boolean = false;
  let allowDenied: boolean = false;
  let allowVoid: boolean = false;
  let allowDelete: boolean = false;
  let allowViewRemarks: boolean = false;
  let allowDeveloperOption: boolean = false;

  let officerEmail = caseDetailReducer.creatorEmail;
  let officerEmailUis = [];

  if (officerEmail) {
    if (officerEmail.includes("<br/>")) {
      let emails = officerEmail.split("<br/>");
      if (emails && emails.length > 0) {
        let line;
        for (const element of emails) {
          line = element;
          officerEmailUis.push(<span>{line}</span>);
        }
      }
    } else {
      officerEmailUis.push(<div>{officerEmail}</div>);
    }
  }

  let officerPhone = caseDetailReducer.creatorPhone;
  let officerPhoneUis = [];

  if (officerPhone) {
    if (officerPhone.includes("<br/>")) {
      let phones = officerPhone.split("<br/>");
      if (phones && phones.length > 0) {
        let line;
        for (const element of phones) {
          line = element;
          if (line.includes("X")) {
            let phoneNum = line.split("X");
            officerPhoneUis.push(<div>{phoneNum[0]}</div>);
          } else {
            officerPhoneUis.push(<div>{line}</div>);
          }
        }
      }
    } else {
      officerPhoneUis.push(<div>{officerPhone}</div>);
    }
  }

  const createOrder = (orderName: string) => {
    if (orderName === APPLICATION_AND_ORDER) {
      dispatch(
        createApplicationAndOrder({
          searchWarrantId: caseDetailReducer.searchWarrantId,
          navigate: navigate,
        })
      );
    }
    if (orderName === BLANK_ORDER) {
      dispatch(
        createBlankOrder({
          searchWarrantId: caseDetailReducer.searchWarrantId,
          navigate: navigate,
        })
      );
    }
    if (orderName === INVENTORY) {
      dispatch(
        createInventory({
          searchWarrantId: caseDetailReducer.searchWarrantId,
          navigate: navigate,
        })
      );
    }
    if (orderName === ORDER_OF_CUSTODY) {
      dispatch(createOrderOfCustody({ navigate: navigate }));
    }
    if (orderName === PROOF_OF_SERVICE) {
      dispatch(
        createProofOfService({
          searchWarrantId: caseDetailReducer.searchWarrantId,
          navigate: navigate,
        })
      );
    }
    if (orderName === AMENDED_INVENTORY) {
      amendInventorySourcePopup.open();
    }
  };
  const formsDropdown = useMemo(() => {
    let formsDropdown: { formName: string; disable: boolean }[] = [];
    const { createFormList, creatorId, searchWarrantList, county } =
      caseDetailReducer;
    if (createFormList && createFormList.length > 0) {
      let swFormLink;
      for (const form of createFormList) {
        swFormLink = { ...form };

        //Need to remove this code segment - start
        /*if (AMENDED_INVENTORY === swFormLink.formName) {
              createformUIs.push(
                  <Dropdown.Item eventKey={(i + 1)} disabled={true}>
                      <FaFolderPlus className="mr-4" />{swFormLink.formName}
                  </Dropdown.Item>
              );
              createformUIs.push(
                  <Dropdown.Divider />
              );
              continue;
          }*/
        //Need to remove this code segment - end

        if (creatorId !== loggedInUserId) {
          swFormLink.enable = false;
        }

        if (AMENDED_INVENTORY === swFormLink.formName) {
          let isCompleteAmendedInventoryExist = false;
          let isAmendedInventoryExist = false;
          let swCase = null;
          for (const sw of searchWarrantList) {
            swCase = sw;
            if (swCase.formType && AMENDED_INVENTORY === swCase.formType) {
              isAmendedInventoryExist = true;
              if (
                swCase.formStatus &&
                SEARCH_WAR_COMPLETE_STATUS === swCase.formStatus
              ) {
                isCompleteAmendedInventoryExist = true;
              } else {
                isCompleteAmendedInventoryExist = false;
              }
            }
          }
          if (isAmendedInventoryExist) {
            if (
              isCompleteAmendedInventoryExist &&
              creatorId === loggedInUserId
              // || ROLE_ADMINISTRATOR === loggedInUserRole
            ) {
              formsDropdown.push({
                formName: swFormLink.formName,
                disable: false,
              });
            } else {
              formsDropdown.push({
                formName: swFormLink.formName,
                disable: true,
              });
            }
          } else {
            let formSWInven = getSelectSWForm(searchWarrantList, INVENTORY);
            let inventoryStatus = formSWInven ? formSWInven.formStatus : "";
            if (
              formSWInven &&
              inventoryStatus === SEARCH_WAR_COMPLETE_STATUS &&
              creatorId === loggedInUserId
              // || ROLE_ADMINISTRATOR === loggedInUserRole
            ) {
              formsDropdown.push({
                formName: swFormLink.formName,
                disable: false,
              });
            } else {
              formsDropdown.push({
                formName: swFormLink.formName,
                disable: true,
              });
            }
          }
          continue;
        }

        if (ORDER_OF_CUSTODY === swFormLink.formName) {
          if (county.isOFCRequired) {
            let prewarrant = getSelectSWForm(searchWarrantList, INVENTORY);
            if (prewarrant) {
              let warrant = getSelectSWForm(
                searchWarrantList,
                ORDER_OF_CUSTODY
              );
              if (!warrant) {
                warrant = {};
                warrant.formStatus = SEARCH_WAR_INCOMPLETE_STATUS;
              }
              if (
                (SEARCH_WAR_COMPLETE_STATUS === prewarrant.formStatus &&
                  ROLE_JUDGE === loggedInUserRole &&
                  SEARCH_WAR_COMPLETE_STATUS !== warrant.formStatus) ||
                (swFormLink.enable && ROLE_COUNTY_ADMIN === loggedInUserRole)
              ) {
                formsDropdown.push({
                  formName: swFormLink.formName,
                  disable: false,
                });
              } else if (
                swFormLink.enable &&
                ROLE_ADMINISTRATOR === loggedInUserRole
              ) {
                formsDropdown.push({
                  formName: swFormLink.formName,
                  disable: false,
                });
              } else {
                formsDropdown.push({
                  formName: swFormLink.formName,
                  disable: true,
                });
              }
            } else {
              formsDropdown.push({
                formName: swFormLink.formName,
                disable: true,
              });
            }
            continue;
          } else {
            break;
          }
        }

        if (BLANK_ORDER === swFormLink.formName) {
          let sWarrant = getSelectSWForm(
            searchWarrantList,
            COMPL_SEARCH_WARRANT_WITH_SLASH
          );
          if (
            (ROLE_STATES_ATTORNEY === loggedInUserRole ||
              ROLE_SA_ADMIN === loggedInUserRole ||
              ROLE_POLICE_OFFICER === loggedInUserRole ||
              ROLE_ADMINISTRATOR === loggedInUserRole ||
              ROLE_COUNTY_ADMIN === loggedInUserRole ||
              ROLE_AGENCY_ADMIN === loggedInUserRole ||
              ROLE_ATTORNEY_GENERAL === loggedInUserRole ||
              ROLE_LOCAL_PROSECUTOR === loggedInUserRole) /*||
              ROLE_JUDGE === loggedInUserRole*/ &&
            SEARCH_WAR_INCOMPLETE_STATUS !== sWarrant.formStatus
          ) {
            formsDropdown.push({
              formName: swFormLink.formName,
              disable: false,
            });
          } else {
            formsDropdown.push({
              formName: swFormLink.formName,
              disable: true,
            });
          }
          continue;
        } else if (APPLICATION_AND_ORDER === swFormLink.formName) {
          let sWarrant = getSelectSWForm(
            searchWarrantList,
            COMPL_SEARCH_WARRANT_WITH_SLASH
          );
          if (
            (ROLE_STATES_ATTORNEY === loggedInUserRole ||
              ROLE_SA_ADMIN === loggedInUserRole ||
              ROLE_POLICE_OFFICER === loggedInUserRole ||
              ROLE_ADMINISTRATOR === loggedInUserRole ||
              ROLE_COUNTY_ADMIN === loggedInUserRole ||
              ROLE_AGENCY_ADMIN === loggedInUserRole ||
              ROLE_ATTORNEY_GENERAL === loggedInUserRole ||
              ROLE_LOCAL_PROSECUTOR === loggedInUserRole) &&
            SEARCH_WAR_INCOMPLETE_STATUS !== sWarrant.formStatus
          ) {
            formsDropdown.push({
              formName: swFormLink.formName,
              disable: false,
            });
          } else {
            formsDropdown.push({
              formName: swFormLink.formName,
              disable: true,
            });
          }
          continue;
        }

        if (
          (swFormLink.enable && creatorId === loggedInUserId) ||
          (swFormLink.enable && ROLE_COUNTY_ADMIN === loggedInUserRole)
        ) {
          formsDropdown.push({
            formName: swFormLink.formName,
            disable: false,
          });
        } else if (
          swFormLink.enable &&
          ROLE_ADMINISTRATOR === loggedInUserRole
        ) {
          formsDropdown.push({
            formName: swFormLink.formName,
            disable: false,
          });
        } else {
          formsDropdown.push({
            formName: swFormLink.formName,
            disable: true,
          });
        }
      }
    }
    return formsDropdown;
  }, [caseDetailReducer.createFormList]);

  const backToWarrantList = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
    const url = dashboardReducer.PreviousURLSW;
    const newUrl = url.replace(/^.*\/\/.*?\//, '');
    if (newUrl === ""){
      if (loginReducer.summonsToAppear){
        navigate("/WarrantsSummonsList");
      }else{
        navigate("/WarrantList");
      }
    }else{
      navigate("/"+newUrl);
    }
  };

  const removeAndResetPassword = (passwordAction: string) => {
    let actionValue: boolean = false;
    if (passwordAction === "RESET") {
      actionValue = true;
    } else if (passwordAction === "REMOVE") {
      actionValue = false;
    }
    setSecurityPasswordProps({
      complaintId: caseDetailReducer.searchWarrantId,
      caseNumber: caseDetailReducer.caseNumber,
      action: passwordAction,
    });

    setSecurityPasswordShow(true);
  };

  const onBreadcrumbClick = () =>{
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }

  const getItemsLink = () =>{
    if (loginReducer.summonsToAppear){
      return"WarrantsSummonsList";
    }else{
      return"WarrantList";
    }
  }

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/"+dashboardReducer.PreviousURLSW.replace(/^.*\/\/.*?\//, ''), itemName: getItemsLink(), itemFunction: onBreadcrumbClick },
  ];

  useEffect(() => {
    return () => {
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "navigateFrom",
          value: "",
        })
      );
      dispatch(updateElementValueDashboardReducer({elementName: "listFrom",value: ""}));
    };
  }, []);

  const renderTableHeader = (
    <>
      <th>#</th>
      <th>Form</th>
      <th>DATE CREATED</th>
      <th>SIGN DATE</th>
      <th>STATUS</th>
      <th>LOCKED BY</th>
      <th style={{ width: "250px", textAlign: "center" }}>ACTIONS</th>
      {userType === ROLE_ADMINISTRATOR ? <th>DEVELOPER OPTION</th> : ""}
    </>
  );

  let tableData = caseDetailReducer.searchWarrantList.map(
    (
      {
        searchWarrantId,
        formType,
        creationDate,
        signDate,
        formStatus,
        lockUserName,
        impounded,
        templateName,
      }: any,
      i: any
    ) => [
      i,
      (swForm = caseDetailReducer.searchWarrantList[i]),
      searchWarrantId,
      getFormTypeLink(
        formType,
        impounded,
        templateName,
        isAllowEdit(
          swForm.formStatus,
          swForm.creatorId,
          swForm.lockUserName,
          swForm.formCreatorId,
          swForm.formType,
          swForm.searchWarrantId,
          swForm.approvalSAId,
          swForm.approvalUserType,
          caseDetailReducer,
          loggedInUserRole,
          loggedInUserId,
          loggedInUserName
        )
      ),
      creationDate,
      signDate,
      formStatus,
      lockUserName,
      isAllowEdit(
        swForm.formStatus,
        swForm.creatorId,
        swForm.lockUserName,
        swForm.formCreatorId,
        swForm.formType,
        swForm.searchWarrantId,
        swForm.approvalSAId,
        swForm.approvalUserType,
        caseDetailReducer,
        loggedInUserRole,
        loggedInUserId,
        loggedInUserName
      ),
      isAllowPreviewClick(
        loginReducer.canUpdateCMSSyncStatus,
        countyReducer.integratedWithCMS,
        swForm.cmsSyncStatus
      ),
      swForm.cmsSyncStatus,
      isAllowSubmit(
        swForm.formStatus,
        swForm.creatorId,
        swForm.formCreatorId,
        swForm.approvalSAId,
        swForm.lockUserName,
        caseDetailReducer.onBehalf,
        loggedInUserRole,
        loggedInUserId,
        loggedInUserName
      ),
      isAllowDenied(
        swForm.formStatus,
        swForm.lockUserName,
        loggedInUserRole,
        loggedInUserName,
        swForm.formType
      ),
      isAllowRevert(
        swForm.formStatus,
        swForm.formType,
        swForm.lockUserName,
        swForm.creatorId,
        swForm.formCreatorType,
        caseDetailReducer.onBehalf,
        loggedInUserRole,
        loggedInUserId,
        loggedInUserName
      ),
      isAllowSign(
        swForm.formStatus,
        swForm.lockUserName,
        swForm.creatorId,
        loggedInUserRole,
        loggedInUserId,
        loggedInUserName
      ),
      isAllowApprove(
        swForm.formStatus,
        swForm.lockUserName,
        swForm.creatorId,
        caseDetailReducer.onBehalf,
        loggedInUserRole,
        loggedInUserId,
        loggedInUserName
      ),
      isAllowUnlock(
        swForm.formStatus,
        swForm.lockUserName,
        loggedInUserRole,
        loggedInUserName,
        swForm.formType
      ),
      isAllowVoid(swForm.formStatus, loggedInUserRole, swForm.formType),
      isAllowDelete(
        swForm.formStatus,
        swForm.creatorId,
        loggedInUserRole,
        loggedInUserId,
        swForm.formType
      ),
      isAllowViewRemarks(swForm.formStatus, swForm.remark),
      (submitImage = getSubmitImage(swForm.formType, loggedInUserRole)),
      onSubmitClick(
        swForm.searchWarrantId,
        swForm.formType,
        swForm.formStatus,
        swForm.remark,
        swForm.creatorId,
        swForm.approvalSAId
      ),
      getAuditTrails(
        swForm.searchWarrantId,
        swForm.formType,
        swForm.caseNumber
      ),
      canViewAuditTrail,
      onEditClick(
        swForm.searchWarrantId,
        swForm.formType,
        swForm.formStatus,
        swForm.creatorId,
        swForm.formCreatorId,
        swForm.lockUserId,
        swForm.remark
      ),
        oninventorySignClick(
          swForm.searchWarrantId,
          swForm.formType,
          swForm.formStatus,
          swForm.creatorId,
          swForm.formCreatorId,
          swForm.lockUserId,
          swForm.remark
        ),
      onPreviewClick(
        swForm.searchWarrantId,
        swForm.formType,
        swForm.formStatus
      ),
      onVoidClick(swForm.searchWarrantId, swForm.formType, swForm.formStatus),
      onApproveClick(swForm.searchWarrantId, swForm.formType, swForm.remark),
      signClick(
        swForm.searchWarrantId,
        swForm.formType,
        swForm.formStatus,
        swForm.creatorId,
        swForm.formCreatorId,
        swForm.lockUserId
      ),
      cmsPendinfClick(swForm.searchWarrantId),
      // returnToOfficerClick(

      // swForm.searchWarrantId,
      // swForm.formType,
      // swForm.creatorId,
      // swForm.approvalSAID,
      // swForm.creatortUserType,

      // ),

      getImgRemarksAndTitle(swForm.formCreatorType),
      caseDetailReducer.stateAttorneyCode,
      onViewRemarkClick(swForm.remark),
      onReturnClick(
        swForm.searchWarrantId,
        swForm.formType,
        swForm.formStatus,
        swForm.creatorId,
        swForm.formCreatorId,
        swForm.formCreatorType,
        swForm.approvalSAId
      ),
      onDeleteClick(
        swForm.searchWarrantId,
        swForm.formType,
        swForm.formStatus,
        swForm.creatorId,
        swForm.formCreatorId,
        swForm.formCreatorType,
        swForm.approvalSAId
      ),
      onDenyClick(swForm.searchWarrantId, swForm.formType, swForm.formStatus),
      onUnlockClick(swForm.searchWarrantId, swForm.formStatus),
      onReplacePDFClick(swForm.formType, swForm.searchWarrantId),
      onGeneratePDFClick(swForm.formType, swForm.searchWarrantId),
      isAllowDeveloperOption(loggedInUserRole),
    ]
  );

  const cancelConfirmationPopup = () => {
    setconfirmPopupData({
      show: false,
      swObj: {},
    });
    if (isSubmit) {
      afterConfirmOnSubmitClick(confirmPopupObj);
      setIsSubmit(false);
    }
  };

  useEffect(() => {
    if (searchWarrantReducer.swSuccessMsg) {
      setTopalertMessage(searchWarrantReducer.swSuccessMsg);
      dispatch(
        updateElementValueSearchWarrantReducer({
          elementName: "swSuccessMsg",
          value: "",
        })
      );
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [searchWarrantReducer.swSuccessMsg, dispatch]);
  return (
    <>
      <Header headerName="Search Warrant" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Existing Forms"
        />
        <AlertMessage
          message={topalertMessage}
          onClose={() => setTopalertMessage("")}
          variant={warningVariant ? "danger" : "success"}
        />
        <Row className="mb-3">
          <Col md={6} className="mt-3">
            <Card className="shadow">
              <Card.Body>
                <h4>Case Information</h4>
                <hr className="hrStyle" />
                <Row>
                  <Col md={4} className="fw-bold">
                    Case Number
                  </Col>
                  <Col md={3}>{caseDetailReducer.caseNumber}</Col>
                  {caseDetailReducer.approvalSA &&
                    loginReducer.userType === ROLE_JUDGE && (
                      <Col className="text-end">
                        {"Reviewed by: " + caseDetailReducer.approvalSA}
                      </Col>
                    )}
                </Row>
                <hr className="hrStyle" />
                <Row>
                  <Col md={4} className="fw-bold">
                    County
                  </Col>
                  <Col md={8} className="text-secondary">
                    {caseDetailReducer.countyName}
                  </Col>
                </Row>
                <hr className="hrStyle" />
                <Row>
                  <Col md={4} className="fw-bold">
                    Issuing Agency
                  </Col>
                  <Col md={8} className="text-secondary">
                    {caseDetailReducer.agencyName}
                  </Col>
                </Row>
                <hr className="hrStyle" />
                <Row>
                  <Col md={4} className="fw-bold">
                    Agency Case Number
                  </Col>
                  <Col md={8} className="text-secondary">
                    {caseDetailReducer.agencyCaseNumber
                      ? caseDetailReducer.agencyCaseNumber
                      : ""}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mt-3">
            <Card className="shadow">
              <Card.Body>
                <h4>Officer Information</h4>
                <hr className="hrStyle" />
                <Row>
                  <Col md={4} className="fw-bold">
                    Name
                  </Col>
                  <Col md={8}>{caseDetailReducer.creatorName}</Col>
                </Row>
                <hr className="hrStyle" />
                <Row>
                  <Col md={4} className="fw-bold">
                    Email
                  </Col>
                  <Col md={8} className="text-secondary">
                    {officerEmailUis}
                  </Col>
                </Row>
                <hr className="hrStyle" />
                <Row>
                  <Col md={4} className="fw-bold">
                    Phone
                  </Col>
                  <Col md={8} className="text-secondary">
                    {officerPhoneUis}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row
          className={
            caseDetailReducer.protectedComplaint ? "d-block" : "d-none"
          }
        >
          <Col>
            <Row className="justify-content-end">
              <Col xs="auto">
                <h5 className="text-danger pt-3">
                  <b className="border border-danger p-1 rounded">
                    PASSWORD PROTECTED
                  </b>
                </h5>
              </Col>
              <Col
                xs="auto"
                className={
                  userType === ROLE_ADMINISTRATOR ||
                  caseDetailReducer.creatorId === userProfileId
                    ? "d-block"
                    : "d-none"
                }
              >
                <div className="p-2 pb-0 rounded-top custom-bg-danger">
                  <ButtonComponent
                    variant="danger"
                    className="rounded-0 rounded-top"
                    onClick={() => removeAndResetPassword("REMOVE")}
                  >
                    <RiDeleteBin2Line /> Remove Password
                  </ButtonComponent>{" "}
                  <ButtonComponent
                    variant="success"
                    className="rounded-0 rounded-top"
                    onClick={() => removeAndResetPassword("RESET")}
                  >
                    <RiExchangeLine /> <b>Reset Password</b>
                  </ButtonComponent>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Card className="shadow">
          <Card.Body>
            <Row>
              <Col xs="auto">
                <ButtonComponent onClick={backToWarrantList}>
                  <RiArrowLeftLine /> Back to warrant List
                </ButtonComponent>
              </Col>
              <Col className="mt-3" xs={3}>
                <h4>Existing Forms</h4>
              </Col>
              <Col className="text-end">
                <ButtonComponent
                  variant="info"
                  onClick={showVideoConferenceModal}
                >
                  <RiVideoChatFill /> Video Conference
                </ButtonComponent>{" "}
                {isAllowAccessAuditTrail(loggedInUserRole) && (
                  <ButtonComponent
                    variant="info"
                    className="me-1"
                    onClick={showAuditTrailModal}
                  >
                    <RiFileCopy2Fill /> Access Logs
                  </ButtonComponent>
                )}
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                  >
                    Create Forms
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant="dark">
                    {formsDropdown.map((form, index) => {
                      return !form.disable ? (
                        <Dropdown.Item
                          key={index}
                          eventKey={index}
                          disabled={form.disable}
                          className={form.disable ? "opacity-50" : ""}
                          onClick={() => createOrder(form.formName)}
                        >
                          {form.formName}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          key={index}
                          eventKey={index}
                          disabled={form.disable}
                          className={form.disable ? "opacity-50" : ""}
                        >
                          {form.formName}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <TableComponent
              tableHeader={tableHeader}
              tableData={tableData}
              renderTableRow={renderTableRow}
              renderTableHeader={renderTableHeader}
            />
          </Card.Body>
        </Card>
        <ConfirmPopup
          children={confirmText}
          show={confirmPopupData.show}
          onHide={cancelConfirmationPopup}
          isConfirmation={handleConfirmation}
        />

        <InfoPopup
          children={showInfoPopup.info}
          onHide={() => setShowInfoPopup({ show: false, info: "" })}
          show={showInfoPopup.show}
        />
      </div>

      <AmendInventorySourcePopup
        show={amendInventorySourcePopup.isOpen}
        toggleShow={amendInventorySourcePopup.toggle}
      />
      <SignPopup
        show={isSignPanelPopup}
        onHide={() => setisSignPanelPopup(false)}
        formType={type}
        searchWarrantId={id}
      />

      <VideoConferenceSplitScreenPopUp
        show={showVideoConference}
        onHide={onHide}
        isTestWebCam={false}
      />

      {/* <AdmonishmentPopup
        show={showAdmonishment}
        onHide={onHideAdmonishment}
        onConfirmAdmonishment={onConfirmAdmonishment}
        admonishmentMsg={caseDetailReducer.countyAdmonishment}
      /> */}

      <RemarkPopup
        show={remarkModalShow}
        onHide={() => setRemarkModalShow(false)}
        submitRemark={submitRemark}
        remarkFor={remarkFor}
        action={action}
        remarkRequired={remarkRequired}
        onlyView={onlyView}
        remarksForView={viewRemark}
        remarkAfterEdit={remarkAfterEdit}
        isEditClick={isEditClick}
      />

      <AduitTrailPopup
        showAuditTrail={showAuditTrail}
        onHide={cancelAuditTrailModal}
        searchwarrantid={searchWarrantIdForAuditTrail}
        formType={formTypeForAuditTrail}
        caseNumber={caseNumberForAuditTrail}
        auditType={auditType}
      />
      <DeveloperOptionPopup
        show={developerPopupVisible}
        onHide={() => setDeveloperOptionVisible(false)}
        isGenerate={generate}
        formTypeName={formType}
        formId={formId}
      />
      <SecurityPassword
        show={securityPasswordShow}
        onHide={cancelSecurityPasswordPopup}
        securityProps={securityPasswordProps}
        submitPassword={() => {}}
        accessToComplaint={() => {}}
      />
      <OptionPopup
        show={showOptionPopup}
        header={optionObj.header}
        onHideFunc={() => setShowOptionPopup(false)}
        children={optionObj.body}
        buttons={optionObj.buttons}
      />
    </>
  );
};

const renderTableRow = (row: any[]) => {
  const [
    i,
    swForm,
    searchWarrantId,
    formType,
    creationDate,
    signDate,
    formStatus,
    lockUserName,
    allowEdit,
    allowPreviewClick,
    cmsSyncStatus,
    allowSubmit,
    allowDenied,
    allowRevert,
    allowSign,
    allowApprove,
    allowUnlock,
    allowVoid,
    allowDelete,
    allowViewRemarks,
    submitImage,
    onSubmitClick,
    getAuditTrails,
    canViewAuditTrail,
    onEditClick,
    oninventorySignClick,
    onPreviewClick,
    onVoidClick,
    onApproveClick,
    signClick,
    cmsPendinfClick,
    { imgRemarksTitle, imgRemarks },
    stateAttorneyCode,
    onViewRemarkClick,
    onReturnClick,
    onDeleteClick,
    onDenyClick,
    onUnlockClick,
    onReplacePDFClick,
    onGeneratePDFClick,
    allowDeveloperOption,
  ] = row;
  return (
    <tr key={searchWarrantId} style={{ verticalAlign: "middle" }}>
      <td>{i + 1}</td>
      <td>{formType}</td>
      <td>{creationDate}</td>
      <td>{signDate}</td>
      <td className={`${getTextColorByStatus(formStatus)} `}>
        <strong>{formStatus}</strong>
      </td>
      <td>
        {(formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
          formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) &&
        lockUserName !== ""
          ? lockUserName
          : "-"}
      </td>
      <td className="justify-content-center d-flex align-items-stretch">
        {allowEdit ? (
          <TableActionComponent
            tootltip="Edit"
            children={<RiDraftFill />}
            //children={<img src={require("../../assets/action_icons/edit-icon.png")}/>}
            actionFunction={onEditClick}
            text="Edit"
          />
        ) : (
          ""
        )}
        {allowPreviewClick ? (
          cmsSyncStatus === CMS_SYNC_STATUS_SYNC ? (
            <TableActionComponent
              tootltip={"Update Sync Status to pending"}
              children={<RiEditBoxFill />}
              actionFunction={cmsPendinfClick}
              color="text-success"
              text={
                cmsSyncStatus === CMS_SYNC_STATUS_SYNC ? "Sync CMS Pending" : ""
              }
            />
          ) : cmsSyncStatus === CMS_SYNC_STATUS_PENDING ? (
            <strong className="text-success">{"CMS Pending"}</strong>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {allowSubmit ? (
          <TableActionComponent
            tootltip={
              submitImage === "submit-to-judge"
                ? "Submit to Judge"
                : "Submit for Screening"
            }
            children={
              submitImage === "submit-to-judge" ? (
                // <img src={require("../../assets/action_icons/submit-icon.png")}/>
                <RiArrowRightFill />
              ) : (
                // <img src={require("../../assets/action_icons/submit-icon.png")}/>
                <RiSendPlane2Fill />
              )
            }
            actionFunction={onSubmitClick}
            color="text-success"
            text={
              submitImage === "submit-to-judge"
                ? "Submit to Judge"
                : "Submit for Screening"
            }
          />
        ) : (
          ""
        )}

        {allowDenied ? (
          <TableActionComponent
            tootltip="Deny"
            children={<RiCloseCircleFill />}
            //children={<img src={require("../../assets/action_icons/deny-icon.png")}/>}
            actionFunction={onDenyClick}
            color="text-danger"
            text="Deny"
          />
        ) : (
          ""
        )}

        {allowRevert ? (
          <TableActionComponent
            tootltip={imgRemarksTitle}
            children={<RiArrowGoBackFill />}
            //children={<img src={require("../../assets/action_icons/revert-icon.png")}/>}
            actionFunction={onReturnClick}
            color="text-danger"
            text={imgRemarksTitle}
          />
        ) : (
          ""
        )}

        {allowSign && swForm.formType !== INVENTORY ? (
          <TableActionComponent
            tootltip="Sign"
            children={<RiPenNibFill />}
            //children={<img src={require("../../assets/action_icons/sign-icon.png")}/>}
            actionFunction={signClick}
            color="text-success"
            text="Sign"
          />
        ) : (
          ""
        )}

        {allowSign && swForm.formType === INVENTORY ? (
          <TableActionComponent
            tootltip="Sign"
            children={<RiPenNibFill />}
            actionFunction={oninventorySignClick}
            color="text-success"
            text="Sign"
          />
        ) : (
          ""
        )}

        {allowApprove ? (
          <TableActionComponent
            tootltip="Approve"
            children={<RiCheckboxMultipleFill />}
            //children={<img src={require("../../assets/action_icons/approve-icon.png")}/>}
            actionFunction={onApproveClick}
            color="text-success"
            text="Approve"
          />
        ) : (
          ""
        )}

        {allowUnlock ? (
          <TableActionComponent
            tootltip="Unlock"
            children={<RiLockUnlockFill />}
            //children={<img src={require("../../assets/action_icons/unlock-icon.png")}/>}
            actionFunction={onUnlockClick}
            color="text-info"
            text="Unlock"
          />
        ) : (
          ""
        )}

        {COMPL_SEARCH_WARRANT_WITH_SLASH === swForm.formType && allowVoid ? (
          <TableActionComponent
            tootltip="Void Search Warrant"
            children={<RiWindow2Fill />}
            //children={<img src={require("../../assets/action_icons/void-icon.png")}/>}
            actionFunction={onVoidClick}
            color="text-danger"
            text="Void Search Warrant"
          />
        ) : (
          ""
        )}

        {COMPL_SEARCH_WARRANT_WITH_SLASH !== swForm.formType && allowDelete ? (
          <TableActionComponent
            tootltip="Delete"
            children={<RiDeleteBin5Fill />}
            //children={<img src={require("../../assets/action_icons/delete-icon.png")}/>}
            actionFunction={onDeleteClick}
            color="text-danger"
            text="Delete"
          />
        ) : (
          ""
        )}

        {allowViewRemarks ? (
          <TableActionComponent
            tootltip="Remarks"
            children={<RiMapPinLine />}
            //children={<img src={require("../../assets/action_icons/remarks-icon.png")}/>}
            actionFunction={onViewRemarkClick}
            color="text-info"
            text="Remarks"
          />
        ) : (
          ""
        )}

        <TableActionComponent
          tootltip="Preview"
          children={<RiFileTextLine />}
          //children={<img src={require("../../assets/action_icons/preview-icon.png")}/>}
          actionFunction={onPreviewClick}
          color="text-info"
          text="Preview"
        />

        {canViewAuditTrail ? (
          <TableActionComponent
            tootltip="Audit Trail"
            children={<RiFileListFill />}
            //children={<img src={require("../../assets/action_icons/audit-icon.png")}/>}
            actionFunction={getAuditTrails}
            color="text-info"
            text="Audit Trail"
          />
        ) : (
          ""
        )}
      </td>
      {allowDeveloperOption ? (
        <td>
          <div className="justify-content-center d-flex align-items-stretch">
            {formStatus === SEARCH_WAR_EXECUTED_STATUS ||
            formStatus === SEARCH_WAR_COMPLETE_STATUS ||
            formStatus === SEARCH_WAR_ACTIVE_STATUS ? (
              <TableActionComponent
                tootltip="Generate PDF"
                children={<RiFileDownloadLine />}
                actionFunction={onGeneratePDFClick}
                color="text-info"
                text="Generate PDF"
              />
            ) : (
              ""
            )}

            {formStatus === SEARCH_WAR_EXECUTED_STATUS ||
            formStatus === SEARCH_WAR_COMPLETE_STATUS ||
            formStatus === SEARCH_WAR_ACTIVE_STATUS ? (
              <TableActionComponent
                tootltip="Replace PDF"
                children={<RiFileUploadLine />}
                actionFunction={onReplacePDFClick}
                text="Replace PDF"
              />
            ) : (
              ""
            )}
          </div>
        </td>
      ) : (
        ""
      )}
    </tr>
  );
};

const PreloadCaseDetails = () => {
  const [fetching, setFetching] = useState<LoadingType>("idle");
  const dispatch = useAppDispatch();
  const {
    searchWarrantObject: { searchWarrantId },
  } = useSearchWarrantReducer();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { fetchDetailStatus } = useCaseDetailReducer();

  useEffect(() => {
    const _searchWarrantId = searchParams.get("searchWarrantId");
    if (!_searchWarrantId) {
      navigate("/CaseDetails?searchWarrantId=" + searchWarrantId);
    }
    const id = _searchWarrantId ?? searchWarrantId;
    if (fetchDetailStatus !== "success") {
      dispatch(
        getSWCaseDetail({
          searchWarrantId: Number(id),
          action: "getDetailsOnRowClick",
          navigate: navigate,
        })
      );
    }
  }, []);

  return <CaseDetails />;
};
export default PreloadCaseDetails;
