import React from "react";
import { FloatingLabel, Form, FormControlProps } from "react-bootstrap";
import { RiCheckboxCircleFill } from "react-icons/ri";

export type TextInputFlotingComponentProps = {
  floatingLabel: string;
  labelClassName?: string;
  isError?: boolean;
  isWarning?: boolean;
  isRequired?: boolean;
  isCorrect?: boolean;
} & FormControlProps;

const TextInputFlotingComponent: React.FC<TextInputFlotingComponentProps> = (
  props
) => {
  const {
    floatingLabel,
    labelClassName,
    isError,
    isWarning,
    isRequired,
    isCorrect,
    ...rest
  } = props;

  let labelValue = isRequired ? (
    <>
      {floatingLabel}
      <span>*</span>
    </>
  ) : (
    floatingLabel
  );

  return (
    <FloatingLabel
      label={labelValue}
      className={`${labelClassName || ""} ${isError ? "text-danger" : ""} ${
        isWarning ? "text-warning" : ""
      }`}
    >
      <Form.Control
        {...rest}
        className={` ${isError ? "border-danger" : ""} ${
          isWarning ? "border-warning" : ""
        }`}
      />
      {isCorrect ? (
        <div className="isCorrect isCorrect-input">
          <RiCheckboxCircleFill />
        </div>
      ) : (
        ""
      )}
    </FloatingLabel>
  );
};

export default TextInputFlotingComponent;
