import React, { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SignPanel from "../warrantlist/form/SignPanel";
import ButtonComponent from "./ButtonComponent";
import Editor from "./CKEditor";
import { HTMLComponent } from "./HTMLComponent";
import SelectComponent from "./SelectComponent";
import TextAreaComponent, { TextAreaProps } from "./TextAreaComponent";
import TextInputComponent, { TextInputProps } from "./TextInputComponent";
import TextInputFlotingComponent, {
  TextInputFlotingComponentProps,
} from "./TextInputFlotingComponent";
import CheckBoxComponent, { CheckBoxComponentProps } from "./CheckBoxComponent";
import FileUploader from "../warrantlist/form/FileUploader";
import ToggleButtonComponent from "./ToggleButtonComponent";

type FormComponentProps = {
  totalColumns?: number;
  colSize: any;
  header?: string;
  subHeader?: string;
  isSpace?: string;
  formCardStyle?: string;
  components?: {
    type: string;
    node: React.FC<any>;
  }[];
  componentData: {
    type: string;
    props?: any;
    colSize?: any;
    label?: string;
    labelSize?: string;
    inLine?: boolean;
    offSetSize?: number;
    emptyCol?: number;
    colClassName?: string;
    colHide?: boolean;
  }[];
};
export default function FormComponent({
  totalColumns,
  colSize,
  header,
  subHeader,
  componentData,
  components,
  isSpace,
  formCardStyle,
}: FormComponentProps) {
  const componentStore = useMemo(() => {
    let _componentStore: { [key: string]: React.FC<any> } = {};
    const comp = components ? [..._components, ...components] : _components;
    for (const element of comp) {
      _componentStore = {
        ..._componentStore,
        [element.type]: element.node,
      };
    }
    return _componentStore;
  }, [components]);

  return (
    <form autoComplete="off">
      <Row>
        <Col md={totalColumns || 12} className={isSpace ? isSpace : "mt-3"}>
          <div className={formCardStyle || "card card-body shadow"}>
            <div className={isSpace ? isSpace : "p-3"}>
              {(header || subHeader) && (
                <Row>
                  <Col>
                    {header ? <h4>{header}</h4> : ""}
                    {subHeader && (
                      <div className="title-text">
                        <h6>{subHeader}</h6>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              <Row className={isSpace ? isSpace : "mt-3"}>
                {componentData.map((element, index) => {
                  if (element.type !== "button") {
                    return (
                      componentStore[element.type] && (
                        <React.Fragment key={index}>
                          {!element.colHide ? (
                            <Col
                              className={
                                element.colClassName ? element.colClassName : ""
                              }
                              md={element.colSize || colSize || 6}
                            >
                              <Form.Group
                                className={isSpace ? isSpace : "mt-2"}
                                as={element.inLine ? Row : Col}
                              >
                                {element.label ? (
                                  <Form.Label
                                    column
                                    sm={`${element.labelSize || "auto"}` as any}
                                    className={element.inLine ? "" : "p-0 mt-2"}
                                  >
                                    {element.label}
                                  </Form.Label>
                                ) : (
                                  ""
                                )}
                                <Col>
                                  {componentStore[element.type](element.props)}
                                </Col>
                              </Form.Group>
                            </Col>
                          ) : (
                            ""
                          )}
                          {element.emptyCol ? (
                            <Col md={element.emptyCol} />
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      )
                    );
                  }
                  return null;
                })}
              </Row>
              <div className="d-flex flex-row justify-content-end mt-1">
                {componentData.map((element, index) => {
                  if (element.type === "button") {
                    return (<div>
                      {!element.colHide ?
                        <div key={index} className="mt-3">
                          {componentStore[element.type](element.props)}
                        </div>
                        : ""}

                    </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </form>
  );
}

const _components = [
  {
    type: "textInputFloting",
    node: (props: TextInputFlotingComponentProps) => (
      <TextInputFlotingComponent {...props} />
    ),
  },
  {
    type: "textInput",
    node: (props: TextInputProps) => <TextInputComponent {...props} />,
  },
  {
    type: "textArea",
    node: (props: TextAreaProps) => <TextAreaComponent {...props} />,
  },
  {
    type: "selectInput",
    node: (props: any) => <SelectComponent {...props} />,
  },
  {
    type: "button",
    node: (props: any) => (
      <ButtonComponent {...props}>{props.children}</ButtonComponent>
    ),
  },
  {
    type: "empty",
    node: (props: any) => <div {...props} />,
  },
  {
    type: "checkbox",
    node: (props: CheckBoxComponentProps) => <CheckBoxComponent {...props} />,
  },
  {
    type: "htmlTag",
    node: (props: any) => <HTMLComponent {...props} />,
  },
  {
    type: "editor",
    node: (props: any) => <Editor {...props}>{props.children}</Editor>,
  },
  {
    type: "signPanel",
    node: (props: any) => <SignPanel {...props} />,
  },
  {
    type: "fileUploader",
    node: (props: any) => <FileUploader {...props} />,
  },
  {
    type: "toggleButton",
    node: (props: any) => <ToggleButtonComponent {...props} />,
  },
];
