import React, { useCallback, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import * as yup from "yup";
import { useAppDispatch } from "../../hooks/hooks";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import AlertMessage from "../common/AlertMessage";
import ButtonComponent from "../common/ButtonComponent";
import TextInputComponent from "../common/TextInputComponent";
import {
    mergeUserByUsername,
    useUserProfilesReducer,
    updateElementValueUserProfilesReducer
  } from "../../redux/slice/userProfileSlice";
import { Navigate } from "react-router-dom";

let defaultMergeUserSchema = yup.object().shape({
    mergeUsername: yup.string().required("Please enter User Name."),
});

interface UserMergProps {
  show: boolean;
  onHide: () => void;
  children?: React.ReactNode;
  userName: string;
}

const MergeProfile: React.FC<UserMergProps> = (props) => {
  const { show, onHide, children, userName } = props;
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [mergeUsername, setMergeUsername] = React.useState("");
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const dispatch = useAppDispatch();
  const userReducer = useUserProfilesReducer();

  const handleMergeUsername = (event: any) => {
    setMergeUsername(event.target.value);
  };

  const hidePopup = () => {
    onHide();
    errorMessageAlert.clearError();
    setAlertMessage("");
    setMergeUsername("");
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  useEffect(() => {
    if (userReducer.MergProfileError) {
      handleError([], [userReducer.MergProfileError]);
      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "MergProfileError",
          value: "",
        })
      );
    }
  }, [
    userReducer.MergProfileError,
    dispatch,
    handleError,
  ]);

  const handleSubmit = async () => {
    let dataForVal = {
        mergeUsername: mergeUsername,
    };

    let dataObj = {
      mergeUserName: mergeUsername,
      username: userName,
      navigate: Navigate,
    };

    if (
        (dataObj.mergeUserName === dataObj.username)
      ) {
        handleError(
          [dataObj.username],
          [
            "Can not merge same user.",
          ]
        );
        return;
      } else {
        handleError([], []);
      }

    try {
      await defaultMergeUserSchema.validate(dataForVal, {
        abortEarly: false,
      });
      dispatch(mergeUserByUsername(dataObj));
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => hidePopup()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <b>Merge Profile</b>
      </Modal.Header>
      <ErrorMessageAlert
        messages={errorMessageAlert.messages}
        clearError={errorMessageAlert.clearError}
      />
      <AlertMessage
        message={alertMessage}
        onClose={() => setAlertMessage("")}
      />
      <Modal.Body>
        <Row className="mt-3">
            <label>
                User Name :
            </label>
          <Col>
            <TextInputComponent
              type="text"
              className="form-control"
              placeholder="User Name"
              name="mergeUsername"
              value={mergeUsername}
              onChange={(e) => handleMergeUsername(e)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
        <label>
            Merge Into :
            </label>
          <Col>
            <TextInputComponent
              type="text"
              className="form-control"
              name="mergeTo"
              value={userName}
              disabled
            />
          </Col>
        </Row>
        <hr />
        <Row className="g-2">
          <Col>
            <ButtonComponent
              variant="primary"
              onClick={handleSubmit}
              className="w-100"
            >
              <RiCheckFill /> Merge User
            </ButtonComponent>
          </Col>
          <Col>
            <ButtonComponent
              variant="danger"
              onClick={() => hidePopup()}
              className="w-100"
            >
              <RiCloseFill /> Cancel
            </ButtonComponent>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default MergeProfile;
