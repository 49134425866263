import React, { useCallback, useEffect } from "react";
import { Col, Modal, ModalProps, Row } from "react-bootstrap";
import ReactSignatureCanvas from "react-signature-canvas";
import { RiBallPenLine, RiCloseFill } from "react-icons/ri";
import { useAppDispatch } from "../../../hooks/hooks";
import { setSignature, useSignPanelReducer } from "../../../redux/slice/signPanelSlice";
import ButtonComponent from "../../common/ButtonComponent";
type SignaturePopupProps = {} & ModalProps;
export default function SignPanelExpandPopup(props: SignaturePopupProps) {
  const signPad = React.useRef<ReactSignatureCanvas>(null);
  const dispatch = useAppDispatch();
  const signPanelReducer = useSignPanelReducer();
 
  useEffect(()=>{
    signPad.current?.clear();
    signPad.current?.fromDataURL(signPanelReducer.sign);
    console.log(signPanelReducer.sign)
  },[]);

  const onEnd = () => {
    const sign = signPad.current!.getCanvas().toDataURL("image/png");
  };
  const clear = useCallback(() => {
    if (signPad.current) {
      signPad.current.clear();
    }
  }, [dispatch]);

   const handleSubmit = () =>{
    dispatch(setSignature(signPad.current!.getCanvas().toDataURL("image/png")));
     props.submitSignExpand(signPad.current!.getCanvas().toDataURL("image/png"));
   }


  return (
    <Modal
      size="lg"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {"Signature"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {errorMsg && errorMsg.trim().length > 0 ? (
          <Row className="mt-1">
            <Col>
              <label className="text-danger pr-4">{errorMsg}</label>
            </Col>
          </Row>
        ) : (
          ""
        )} */}
        {
          <Row className="mt-1">
            <Col className="d-flex justify-content-center">
              <ReactSignatureCanvas
                penColor="black"
                ref={signPad}
                onEnd={onEnd}
                canvasProps={{
                  className: "border-sigCanvas",
                  width : 725,
                  height: 290,
                }}
              />
            </Col>
          </Row>
        }
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col xs={5}>
            <ButtonComponent size="sm" className="btn-danger" onClick={clear} style={{width:90, height:40}}>
              Clear
            </ButtonComponent>
          </Col>
          <Col xs={7} className="d-flex justify-content-end">
            <ButtonComponent
              variant="success"
              size="sm"
              className="mx-2"
              onClick={handleSubmit}
              style={{width:90, height:40}}
            >
            SIGN <RiBallPenLine />
            </ButtonComponent>
            <ButtonComponent
              size="sm"
              className="btn-danger"
              onClick={props.onHide}
              style={{width:90, height:40}}
            >
              CANCEL <RiCloseFill />
            </ButtonComponent>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}