import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { getCodeTableEntryList } from "../../services/CodeTableEntryService";

interface CodeTableEntryState {
  statusList: any[];
  statusListFetchStatus: string;
  statusListError?: string;
  issueTimeList: any[];
  issueTimeListFetchStatus: string;
  issueTimeListError?: string;
}
const initialState = {
  statusList: [],
  statusListFetchStatus: "idle",
  statusListError: undefined,
  warrantFormTypeList: [],
  warrantFormTypeFetchStatus: "idle",
  warrantFormTypeError: undefined,
  issueTimeList: [],
  issueTimeListFetchStatus: "idle",
  issueTimeListError: undefined,
} as CodeTableEntryState;

export const getStatusList = createAsyncThunk<
  any,
  undefined,
  {
    state: RootState;
    rejectValue: string;
  }
>(
  "codeTableEntry/getStatusList",
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const respons = await getCodeTableEntryList({
        token: token,
        type: "statusList",
      });
      if (respons.ok) {
        dispatch(showHideLoadingGif(false));
        return respons.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching status list from code table entries.");
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching status list from code table entries.");
    }
  }
);

export const getIssuedTimeList = createAsyncThunk<
  any,
  undefined,
  {
    state: RootState;
    rejectValue: string;
  }
>(
  "codeTableEntry/getIssuedTimeList",
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const respons = await getCodeTableEntryList({
        token: token,
        type: "issuedTimeList",
      });
      dispatch(showHideLoadingGif(false));
      if (respons.ok) {
        return respons.json();
      }else {
        
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching warrant issue time duration list.");
      }
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching warrant issue time duration list.");
    }
  }
);

const codeTabelEntrySlice = createSlice({
  name: "codeTableEntry",
  initialState,
  reducers: {
    updateElementValueCodeTableEntryReducer: updateElementValue,
    resetCodeTableEntryData: (state: CodeTableEntryState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStatusList.pending, (state) => {
        state.statusListFetchStatus = "loading";
      })
      .addCase(getStatusList.fulfilled, (state, action) => {
        state.statusList = action.payload;
        state.statusListFetchStatus = "success";
      })
      .addCase(getStatusList.rejected, (state, action) => {
        state.statusListFetchStatus = "error";
        state.statusListError = action.payload;
      })
      .addCase(getIssuedTimeList.pending, (state) => {
        state.issueTimeListFetchStatus = "loading";
      })
      .addCase(getIssuedTimeList.fulfilled, (state, action) => {
        state.issueTimeList = action.payload;
        state.issueTimeListFetchStatus = "success";
      })
      .addCase(getIssuedTimeList.rejected, (state, action) => {
        state.issueTimeListFetchStatus = "error";
        state.issueTimeListError = action.payload;
      })
    
  },
});

export const { updateElementValueCodeTableEntryReducer , resetCodeTableEntryData } =
  codeTabelEntrySlice.actions;

export const useCodeTableEntryReducer = () =>
  useAppSelector((state) => state.codeTableEntry);
export default codeTabelEntrySlice.reducer;
