import React, { useEffect, useCallback, useRef, useState, useMemo } from "react";
import Header from "../common/Header";
import { Row, Col } from "react-bootstrap";
import {
  RiSearchLine,
  RiAddCircleLine,
  RiArrowGoBackFill,
} from "react-icons/ri";
import ButtonComponent from "../common/ButtonComponent";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import ListViewComponent from "../common/ListViewComponent";
import {
  useNavigate,
  createSearchParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import {
  getOfficerListForLoggedInUser,
  resetComplaintData,
  updateElementValueComplaintReducer,
} from "../../redux/slice/complaintSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import {
  useDashboardReducer,
  updateElementValueDashboardReducer,
} from "../../redux/slice/dashboardSlice";
import {
  SEARCH_WARRANT_PAGE_SIZE,
  ROLE_ADMINISTRATOR,
  ROLE_POLICE_OFFICER,
  ROLE_COUNTY_ADMIN,
  ROLE_STATES_ATTORNEY,
  ROLE_SA_ADMIN,
  ROLE_CASE_CREATOR,
  ROLE_CIRCUIT_CLERK,
  ROLE_AGENCY_ADMIN,
  LF_PENDING_STATUS,
  LF_DELETED_STATUS,
  LF_SEND_TO_SA_STATUS,
  LF_SEND_TO_OFFICER_STATUS,
  LF_SEND_TO_JUDGE_STATUS,
  LF_ARRW_RETURNED_STATUS,
  LF_ARRW_REJECTED_STATUS,
  LF_CASE_ASSIGNMENT_READY_STATUS,
  LF_VOID_STATUS,
  LF_COMMUNI_FAILED_STATUS,
  LF_FILED_STATUS,
  LF_STATUS_PENDING_SUPERVISOR_REVIEW,
  LF_STATUS_PENDING_SUPERVISOR_RETURNED,
  LF_SUMMON_RETURNED_STATUS,
  LF_SUMMON_REJECTED_STATUS,
  CAN_ACCESS_APPLICATION,
  RELEASE_ON_RECOG,
} from "../../constants/common";
import moment from "moment";
import {
  searchLfcDocumentList,
  useLfcDocumentReducer,
  updateElementValueInEComplaintListReducer,
  resetEComplaintList,
  loadLoggedInUserCounties,
  loadLoggedInUserLFCAgencies,
  loadAgencyListByCounty,
} from "../../redux/slice/lfcDocumentSlice";
import { getTextColorByStatus, validateDate } from "../../utils/common";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import {
  merge,
  convertCaseNumber,
  formatComplaintNumber,
} from "../../utils/common";
import LoadingPopup from "../common/LoadingPopup";
import { getAgencyAdminNameByAgency } from "../../redux/slice/agencyListSlice";
import { LoadingType } from "../../types";

const getStatusesForRole = (
  loginReducer: any,
  isSearch: boolean,
  isSpecialDefault: boolean
) => {
  let role = loginReducer.userType;
  let statuses: string[] = [];

  if (isSpecialDefault) {
    if (loginReducer.canViewDeleteEComplaint) {
      statuses.push(LF_DELETED_STATUS);
    }
    if (role === ROLE_POLICE_OFFICER || role === ROLE_AGENCY_ADMIN) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_VOID_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      statuses.push(LF_SUMMON_RETURNED_STATUS);
      statuses.push(LF_SUMMON_REJECTED_STATUS);
      if (loginReducer.ecIsCompalintSupervised) {
        statuses.push(LF_STATUS_PENDING_SUPERVISOR_REVIEW);
        if (role === ROLE_POLICE_OFFICER) {
          statuses.push(LF_STATUS_PENDING_SUPERVISOR_RETURNED);
        }
      }
      return statuses;
    } else if (role === ROLE_STATES_ATTORNEY || role === ROLE_SA_ADMIN) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    } else if (role === ROLE_ADMINISTRATOR) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_FILED_STATUS);
      statuses.push(LF_VOID_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      statuses.push(LF_STATUS_PENDING_SUPERVISOR_REVIEW);
      statuses.push(LF_STATUS_PENDING_SUPERVISOR_RETURNED);
      return statuses;
    } else if (role === ROLE_COUNTY_ADMIN) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_VOID_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    } else if (role === ROLE_CASE_CREATOR) {
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    } else if (role === ROLE_CIRCUIT_CLERK) {
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    }
  }

  if (isSearch) {
    if (role === ROLE_POLICE_OFFICER || role === ROLE_AGENCY_ADMIN) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_VOID_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      if (loginReducer.ecIsCompalintSupervised) {
        statuses.push(LF_STATUS_PENDING_SUPERVISOR_REVIEW);
        if (role === ROLE_POLICE_OFFICER) {
          statuses.push(LF_STATUS_PENDING_SUPERVISOR_RETURNED);
        }
      }
      return statuses;
    } else if (role === ROLE_STATES_ATTORNEY || role === ROLE_SA_ADMIN) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    } else if (role === ROLE_ADMINISTRATOR) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_FILED_STATUS);
      statuses.push(LF_VOID_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      statuses.push(LF_STATUS_PENDING_SUPERVISOR_REVIEW);
      statuses.push(LF_STATUS_PENDING_SUPERVISOR_RETURNED);
      return statuses;
    } else if (role === ROLE_COUNTY_ADMIN) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_FILED_STATUS);
      statuses.push(LF_VOID_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    } else if (role === ROLE_CASE_CREATOR) {
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    } else if (role === ROLE_CIRCUIT_CLERK) {
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    }
  } else {
    if (loginReducer.canViewDeleteEComplaint) {
      statuses.push(LF_DELETED_STATUS);
    }
    if (role === ROLE_POLICE_OFFICER || role === ROLE_AGENCY_ADMIN) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_SUMMON_RETURNED_STATUS);
      statuses.push(LF_SUMMON_REJECTED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_FILED_STATUS);
      statuses.push(LF_VOID_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      if (loginReducer.ecIsCompalintSupervised) {
        statuses.push(LF_STATUS_PENDING_SUPERVISOR_REVIEW);
        if (role === ROLE_POLICE_OFFICER) {
          statuses.push(LF_STATUS_PENDING_SUPERVISOR_RETURNED);
        }
      }
      return statuses;
    } else if (role === ROLE_STATES_ATTORNEY || role === ROLE_SA_ADMIN) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_FILED_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    } else if (role === ROLE_ADMINISTRATOR) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_FILED_STATUS);
      statuses.push(LF_VOID_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      statuses.push(LF_STATUS_PENDING_SUPERVISOR_REVIEW);
      statuses.push(LF_STATUS_PENDING_SUPERVISOR_RETURNED);
      return statuses;
    } else if (role === ROLE_COUNTY_ADMIN) {
      statuses.push(LF_PENDING_STATUS);
      statuses.push(LF_SEND_TO_SA_STATUS);
      statuses.push(LF_SEND_TO_OFFICER_STATUS);
      statuses.push(LF_SEND_TO_JUDGE_STATUS);
      statuses.push(LF_ARRW_REJECTED_STATUS);
      statuses.push(LF_ARRW_RETURNED_STATUS);
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_FILED_STATUS);
      statuses.push(LF_VOID_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    } else if (role === ROLE_CASE_CREATOR) {
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    } else if (role === ROLE_CIRCUIT_CLERK) {
      statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
      statuses.push(LF_COMMUNI_FAILED_STATUS);
      return statuses;
    }
  }

  return statuses;
};

//PR-11312 START
const getAllStatus = () =>{
  let statuses: string[] = [];
  statuses.push(LF_PENDING_STATUS);
  statuses.push(LF_SEND_TO_SA_STATUS);
  statuses.push(LF_SEND_TO_OFFICER_STATUS);
  statuses.push(LF_SEND_TO_JUDGE_STATUS);
  statuses.push(LF_ARRW_REJECTED_STATUS);
  statuses.push(LF_ARRW_RETURNED_STATUS);
  statuses.push(LF_CASE_ASSIGNMENT_READY_STATUS);
  statuses.push(LF_FILED_STATUS);
  statuses.push(LF_VOID_STATUS);
  statuses.push(LF_COMMUNI_FAILED_STATUS);
  statuses.push(LF_STATUS_PENDING_SUPERVISOR_REVIEW);
  statuses.push(LF_STATUS_PENDING_SUPERVISOR_RETURNED);
  return statuses;
};
//PR-11312 END

interface SearchEComplaintProps {}

export const SearchEComplaint: React.FC<SearchEComplaintProps> = (props) => {
  const bothDateReq: string =
    "Both 'From' and 'To' dates are required and correctly formatted.";
  const dateRangeMatch: string = "Date range must be less than 31 days.";
  const dateLessThan: string = "'From Date' must be less than 'To Date'.";

  let defaultComplaintSearchSchema = yup.object().shape({
    toDate: yup
      .string()
      .nullable()
      .test("validate-toFromDate", function (state) {
        let toDateFor =
          !toDate || toDate.trim() === ""
            ? null
            : moment(new Date(toDate), "YYYY-MM-DD");
        let fromDateFor =
          !fromDate || fromDate.trim() === ""
            ? null
            : moment(new Date(fromDate), "YYYY-MM-DD");
        let dateDiff;
        if (
          (!complaintNumber || complaintNumber.trim().length === 0) &&
          (!agencyReportNumber || agencyReportNumber.trim().length === 0) &&
          (!circuitClerkCaseNumber ||
            circuitClerkCaseNumber.trim().length === 0)
        ) {
          if (!toDateFor || !fromDateFor) {
            dateDiff = 0;
             return this.createError({
               message: bothDateReq,
             });
          } else {
            dateDiff = toDateFor.diff(fromDateFor, "days");
            if (dateDiff > 30) {
              return this.createError({
                message: dateRangeMatch,
              });
            } else if (dateDiff < 0) {
              return this.createError({
                message: dateLessThan,
              });
            }
          }
        }
        return true;
      }),
    agencyId: yup
      .number()
      .nullable()
      .test("validate-agency", function () {
        if (userType === ROLE_COUNTY_ADMIN && agencyId === 0) {
          return this.createError({
            message: "One 'Reporting Agency' is required.",
          });
        }
        return true;
      }),
    complaintNumber: yup
      .string()
      .nullable()
      .test("validate-complaintNumber", function () {
        if (complaintNumber && complaintNumber.trim().length > 0) {
          let msg = "'Complaint Number' is not formatted correctly.";
          if (
            !complaintNumber.trim().startsWith("OW") ||
            complaintNumber.trim().length !== 14
          ) {
            return this.createError({
              message: msg,
            });
          } else {
            let withoutOW = complaintNumber
              .trim()
              .substring(2, complaintNumber.trim().length);
            let letter: boolean = false;
            for (var i = 0; i < withoutOW.length; i++) {
              if (!/\d/.test(withoutOW.charAt(i))) {
                letter = true;
                break;
              }
            }
            if (letter) {
              return this.createError({
                message: msg,
              });
            }
          }
        }
        return true;
      }),
  });

  let defaultComplaintReleaseOnRecognizeSearchSchema = yup.object().shape({
    releaseOnRecog: yup.boolean().required().oneOf([false], "Defendant information, Warrant Control #, Agency Report #, or Clerk's Case Number is required."),
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { userType } = useLoginReducer();
  const loginReducer = useLoginReducer();
  const {
    countyList,
    agencyList,
    localCounty,
    countyId,
    agencyDisplay,
    agencyId,
    comStatus,
    dateOfBirth,               // PR-11312
    defLastName,               // PR-11312
    defFirstName,              // PR-11312
    warrantControlNo,         // PR-11312
    toDate,
    fromDate,
    complaintNumber,
    agencyReportNumber,
    circuitClerkCaseNumber,
    totalPages,
    currentPage,
    lfcDocumentList,
    totalRecords,
    searchParameters,
    didMount,
    countyListReady,
    agencyListReady,
  } = useLfcDocumentReducer();
  const { isCardActive, previousLink } = useDashboardReducer();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const [errorMessages, setErrorMessages] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const { cardName } = useDashboardReducer();

  let statusList: string[] = getStatusesForRole(loginReducer, false, false);

  let tableHeader = [
    "Complaint Number	",
    "Incident Number",
    "Defendant Name",
    "County",
    "Officer",
    "Complaint",
    "Warrant",
    "Case Number",
    "Created By",
    "Last Updated Date",
  ];

  const countyListOptions: any = [];

  if (countyList.length !== 1) {
    countyListOptions.push({ label: "-- ALL --", value: { countyID: 0 } });
  }
  countyListOptions.push(
    ...countyList.map((county: any) => {
      return { label: county.countyName, value: county };
    })
  );

  const agencyListOptions: any = [];
  if (agencyList.length !== 1) {
    agencyListOptions.push({
      label: "-- ALL --",
      value: { displayingName: "-- ALL --", agencyId: 0 },
    });
  }
  agencyListOptions.push(
    ...agencyList.map((agency: any) => {
      return { label: agency.displayingName, value: agency };
    })
  );

  const statusListOptions: any = [];
  if (statusList.length !== 1) {
    statusListOptions.push({ label: "-- ALL --", value: "-- ALL --" });
  }
  statusListOptions.push(
    ...statusList.map((status: string) => {
      return { label: status, value: status };
    })
  );

  //mount
  useEffect(() => {
    componentDidMount();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(resetEComplaintList());
      componentDidMount();
    };
  
    fetchData();
  }, [cardName]);

  //unmount
  useEffect(
    () => () => {
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "previousLink",
          value: "",
        })
      );
      dispatch(resetEComplaintList());
    },
    []
  );

  useEffect(() => {
    let paramsValue = location.search;
    if (countyList && countyList.length > 0) {
      if (!paramsValue || paramsValue.trim().length < 0 || !didMount) {
        if (countyList.length === 1) {
          dispatch(
            updateElementValueInEComplaintListReducer({
              elementName: "localCounty",
              value: countyList[0].countyName,
            })
          );
          dispatch(
            updateElementValueInEComplaintListReducer({
              elementName: "countyId",
              value: countyList[0].countyID,
            })
          );
          dispatch(
            loadAgencyListByCounty({ countyId: countyList[0].countyID })
          );
        } else {
          dispatch(
            updateElementValueInEComplaintListReducer({
              elementName: "localCounty",
              value: "-- ALL --",
            })
          );
          dispatch(
            updateElementValueInEComplaintListReducer({
              elementName: "countyId",
              value: 0,
            })
          );
          dispatch(loadLoggedInUserLFCAgencies());
        }
      }
      dispatch(
        updateElementValueInEComplaintListReducer({
          elementName: "countyListReady",
          value: true,
        })
      );
    }
    if (cardName === RELEASE_ON_RECOG) {
      dispatch(
        updateElementValueInEComplaintListReducer({
          elementName: "localCounty",
          value: "-- ALL --",
        })
      );
    }
  }, [countyList]);

  useEffect(() => {
    let paramsValue = location.search;
    if (agencyList && agencyList.length > 0) {
      if (!paramsValue || paramsValue.trim().length < 0 || !didMount) {
        if (agencyList.length === 1) {
          dispatch(
            updateElementValueInEComplaintListReducer({
              elementName: "agencyDisplay",
              value: agencyList[0].displayingName,
            })
          );
          dispatch(
            updateElementValueInEComplaintListReducer({
              elementName: "agencyId",
              value: agencyList[0].agencyId,
            })
          );
        } else {
          dispatch(
            updateElementValueInEComplaintListReducer({
              elementName: "agencyDisplay",
              value: "-- ALL --",
            })
          );
          dispatch(
            updateElementValueInEComplaintListReducer({
              elementName: "agencyId",
              value: 0,
            })
          );
        }
      }
      dispatch(
        updateElementValueInEComplaintListReducer({
          elementName: "agencyListReady",
          value: true,
        })
      );
    }
  }, [agencyList]);

  useEffect(() => {
    if (
      (userType === ROLE_ADMINISTRATOR && countyListReady && agencyListReady) ||
      (userType !== ROLE_ADMINISTRATOR && agencyListReady)
    ) {
      if (didMount && isCardActive && cardName !== RELEASE_ON_RECOG) {
        handleWarrantSearch(0, true, false);
      }
      dispatch(
        updateElementValueInEComplaintListReducer({
          elementName: "didMount",
          value: false,
        })
      );
      if (!isCardActive) {
        setUrlData();
      }
    }
  }, [countyListReady, agencyListReady]);


  const componentDidMount = useCallback(() =>{
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "didMount",
        value: true,
      })
    );
    if (userType === ROLE_ADMINISTRATOR) {
      dispatch(loadLoggedInUserCounties());
    }

    let paramsValue = location.search;
    if (paramsValue.trim().length > 0) {
      if (userType === ROLE_ADMINISTRATOR) {
        if (parseInt(searchParams.get("countyId")?.toString() || "0") === 0) {
          dispatch(loadLoggedInUserLFCAgencies());
        } else {
          dispatch(
            loadAgencyListByCounty({
              countyId: parseInt(
                searchParams.get("countyId")?.toString() || "0"
              ),
            })
          );
        }
      } else {
        dispatch(loadLoggedInUserLFCAgencies());
      }
    } else {
      dispatch(loadLoggedInUserLFCAgencies());
      let today = new Date();
      let fromDate = moment(moment(today).subtract("30", "days")).format(
        "YYYY-MM-DD"
      );
      let toDate = moment(moment(today)).format("YYYY-MM-DD");
      dispatch(
        updateElementValueInEComplaintListReducer({
          elementName: "fromDate",
          value: fromDate,
        })
      );
      dispatch(
        updateElementValueInEComplaintListReducer({
          elementName: "toDate",
          value: toDate,
        })
      );
    }
  },[location, cardName])

  const onRowClick = (ecomplaintId: number) => () => {
    dispatch(
      updateElementValueComplaintReducer({
        elementName: "tempECId",
        value: ecomplaintId,
      })
    );

    dispatch(
      updateElementValueComplaintReducer({
        elementName: "navigateFrom",
        value: "Search EComplaint",
      })
    );
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "PreviousURLLFC",
        value: window.location.href,
      })
    );

    navigate("/EComplaintDashboard");
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      setErrorMessages(errorMessages);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const handleCountySelect = (e: any) => {
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "localCounty",
        value: e.value.countyName,
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "countyId",
        value: e.value.countyID,
      })
    );
    if (e.label === "-- ALL --") {
      dispatch(loadLoggedInUserLFCAgencies());
    } else {
      dispatch(loadAgencyListByCounty({ countyId: e.value.countyID }));
    }
  };

  const handleAgency = (e: any) => {
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "agencyDisplay",
        value: e.value.displayingName,
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "agencyId",
        value: e.value.agencyId,
      })
    );
  };

  const handleStatus = (e: any) => {
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "comStatus",
        value: e.label,
      })
    );
  };

  const handleReset = () => {
    handleError([], []);
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "localCounty",
        value: "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "countyId",
        value: 0,
      })
    );

    if (userType === ROLE_ADMINISTRATOR) {
      dispatch(loadLoggedInUserCounties());
    }
    dispatch(loadLoggedInUserLFCAgencies());

    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "agencyDisplay",
        value: "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "agencyId",
        value: 0,
      })
    );

    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "complaintNumber",
        value: "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "agencyReportNumber",
        value: "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "circuitClerkCaseNumber",
        value: "",
      })
    );

    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "comStatus",
        value: "-- ALL --",
      })
    );

    let today = new Date();
    let fromDate = moment(moment(today).subtract("30", "days")).format(
      "YYYY-MM-DD"
    );
    let toDate = moment(moment(today)).format("YYYY-MM-DD");
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "fromDate",
        value: fromDate,
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "toDate",
        value: toDate,
      })
    );

    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "defFirstName",
        value: "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "defLastName",
        value: "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "warrantControlNo",
        value: "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "dateOfBirth",
        value: "",
      })
    );
  };

  window.onpopstate = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
    let paramsValue = location.search;
    if (paramsValue.trim().length > 0) {
      let pageNoValue = parseInt(searchParams.get("pageNo")?.toString() || "0");

      let statuses: string[] = [];
      if (
        !searchParams.get("comStatus") ||
        searchParams.get("comStatus") === "-- ALL --"
      ) {
        statuses = getStatusesForRole(loginReducer, false, false);
      } else {
        statuses.push("" + searchParams.get("comStatus"));
      }

      if (cardName === RELEASE_ON_RECOG)
        statuses = getAllStatus();

      let urlPathObj: any = {
        pageNo: pageNoValue === 0 ? 0 : pageNoValue - 1,
        pageSize: SEARCH_WARRANT_PAGE_SIZE,
        countyName: searchParams.get("localCounty") || "",
        countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
        agencyId: parseInt(searchParams.get("agencyId")?.toString() || "0"),
        complaintNumber: searchParams.get("complaintNumber") || "",
        agencyReportNumber: searchParams.get("agencyReportNumber") || "",
        circuitClerkCaseNumber:
          searchParams.get("circuitClerkCaseNumber") || "",
        statusList: statuses ? statuses : [],
        fromFDate: searchParams.get("fromFDate") || "",
        toFDate: searchParams.get("toFDate") || "",
        defFirstName: searchParams.get("defFirstName") || "",               // PR-11312
        defLastName: searchParams.get("defLastName") || "",                 // PR-11312
        warrantControlNo: searchParams.get("warrantControlNo") || "",       // PR-11312
        dateOfBirth: searchParams.get("dateOfBirth") || "",                 // PR-11312
        searchFrom: cardName === RELEASE_ON_RECOG? "ROR": "LFC"             // PR-11312
      };
      console.log(4)
      if (cardName !== RELEASE_ON_RECOG || (cardName === RELEASE_ON_RECOG && !isCardActive))
        dispatch(searchLfcDocumentList(urlPathObj));
    } else {
      if (loginReducer.userType !== "ADMINISTRATOR") {
        if (previousLink === "dashboard") {
          navigate("/");
        } else {
          navigate(-1);
        }
      }
      dispatch(resetEComplaintList());
    }
  };

  const setUrlData = () => {
    let pageNoValue = parseInt(searchParams.get("pageNo")?.toString() || "0");

    let statuses: string[] = [];
    if (
      !searchParams.get("comStatus") ||
      searchParams.get("comStatus") === "-- ALL --"
    ) {
      statuses = getStatusesForRole(loginReducer, false, false);
    } else {
      statuses.push("" + searchParams.get("comStatus"));
    }

    if (cardName === RELEASE_ON_RECOG)
      statuses = getAllStatus();

    let urlPathObj: any = {
      pageNo: pageNoValue === 0 ? 0 : pageNoValue - 1,
      pageSize: SEARCH_WARRANT_PAGE_SIZE,
      countyName: searchParams.get("localCounty") || "",
      countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
      agencyId: parseInt(searchParams.get("agencyId")?.toString() || "0"),
      complaintNumber: searchParams.get("complaintNumber") || "",
      agencyReportNumber: searchParams.get("agencyReportNumber") || "",
      circuitClerkCaseNumber: searchParams.get("circuitClerkCaseNumber") || "",
      statusList: statuses ? statuses : [],
      fromFDate: searchParams.get("fromFDate") || "",
      toFDate: searchParams.get("toFDate") || "",
      defFirstName: searchParams.get("defFirstName") || "",               // PR-11312
      defLastName: searchParams.get("defLastName") || "",                 // PR-11312
      warrantControlNo: searchParams.get("warrantControlNo") || "",       // PR-11312
      dateOfBirth: searchParams.get("dateOfBirth") || "",                 // PR-11312
      searchFrom: cardName === RELEASE_ON_RECOG? "ROR": "LFC"             // PR-11312
    };
    console.log(1)
    if (cardName !== RELEASE_ON_RECOG || (cardName === RELEASE_ON_RECOG && !isCardActive))
      dispatch(searchLfcDocumentList(urlPathObj));

    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "localCounty",
        value: searchParams.get("localCounty")?.toString(),
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "countyId",
        value: parseInt(searchParams.get("countyId")?.toString() || "0"),
      })
    );

    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "agencyDisplay",
        value: searchParams.get("agencyDisplay")?.toString(),
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "agencyId",
        value: parseInt(searchParams.get("agencyId")?.toString() || "0"),
      })
    );

    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "complaintNumber",
        value: searchParams.get("complaintNumber") || "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "agencyReportNumber",
        value: searchParams.get("agencyReportNumber") || "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "circuitClerkCaseNumber",
        value: searchParams.get("circuitClerkCaseNumber") || "",
      })
    );

    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "comStatus",
        value: searchParams.get("comStatus") || "-- ALL --",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "fromDate",
        value: searchParams.get("fromFDate") || "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "toDate",
        value: searchParams.get("toFDate") || "",
      })
    );
    // PR-11312 START
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "DateOfBirth",
        value: searchParams.get("DateOfBirth") || "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "defFirstName",
        value: searchParams.get("defFirstName") || "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "defLastName",
        value: searchParams.get("defLastName") || "",
      })
    );
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "warrantControlNo",
        value: searchParams.get("warrantControlNo") || "",
      })
    );
    // PR-11312 END
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: "searchParameters",
        value: urlPathObj,
      })
    );
  };

  const handleWarrantSearch = (
    pageNo: number,
    keepParamsSearch: boolean,
    isPaginationButton: boolean
  ) => {
    let comLStatus: string = comStatus;
    let urlPathObj;
    if (keepParamsSearch) {
      let fromLDate: string = fromDate;
      let toLDate: string = toDate;

      if (
        (complaintNumber && complaintNumber.trim().length > 0) ||
        (agencyReportNumber && agencyReportNumber.trim().length > 0) ||
        (circuitClerkCaseNumber && circuitClerkCaseNumber.trim().length > 0)
      ) {
        dispatch(
          updateElementValueInEComplaintListReducer({
            elementName: "comStatus",
            value: "-- ALL --",
          })
        );
        dispatch(
          updateElementValueInEComplaintListReducer({
            elementName: "fromDate",
            value: "",
          })
        );
        dispatch(
          updateElementValueInEComplaintListReducer({
            elementName: "toDate",
            value: "",
          })
        );
        comLStatus = "-- ALL --";
        fromLDate = "";
        toLDate = "";
      }

      let statuses: string[] = [];
      if (!comLStatus || comLStatus === "-- ALL --") {
        statuses = getStatusesForRole(loginReducer, false, false);
      } else {
        statuses.push(comLStatus);
      }
      let searchParamsLocal;
      if (cardName !== RELEASE_ON_RECOG)  //PR-11312
      searchParamsLocal = {
        pageNo: pageNo,
        pageSize: SEARCH_WARRANT_PAGE_SIZE,
        countyName: localCounty,
        countyId: countyId ? countyId : 0,
        agencyId: agencyId ? agencyId : 0,
        complaintNumber: complaintNumber ? complaintNumber === undefined? "" : complaintNumber  : "",
        agencyReportNumber: agencyReportNumber ? agencyReportNumber === undefined? "" : agencyReportNumber : "",
        circuitClerkCaseNumber: circuitClerkCaseNumber
          ? circuitClerkCaseNumber === undefined ? "" : circuitClerkCaseNumber
          : "",
        statusList: statuses ? statuses : [],
        fromFDate: fromLDate ? fromLDate : "",
        toFDate: toLDate ? toLDate : "",
        defFirstName: "",                             // PR-11312
        defLastName: "",                              // PR-11312
        warrantControlNo: "",                         // PR-11312
        dateOfBirth: "",                              // PR-11312
        searchFrom: "LFC",                            // PR-11312
      };
      //PR-11312 START
      else
        searchParamsLocal = {
          pageNo: pageNo,
          pageSize: SEARCH_WARRANT_PAGE_SIZE,
          countyName: localCounty,
          countyId: 0,
          agencyId: 0,
          complaintNumber: complaintNumber ? complaintNumber === undefined? "" : complaintNumber  : "",
          agencyReportNumber: agencyReportNumber ? agencyReportNumber: "",
          circuitClerkCaseNumber: circuitClerkCaseNumber
            ? circuitClerkCaseNumber === undefined ? "" : circuitClerkCaseNumber
            : "",
          statusList: getAllStatus(),
          fromFDate: "",
          toFDate: "",
          defFirstName: defFirstName ? defFirstName: "",
          defLastName: defLastName ? defLastName: "",
          warrantControlNo: warrantControlNo ? warrantControlNo: "",
          dateOfBirth: dateOfBirth ? dateOfBirth: "",
          searchFrom: "ROR",  
        }
      //PR-11312 END
      console.log(7)
      dispatch(searchLfcDocumentList(searchParamsLocal));
      dispatch(
        updateElementValueInEComplaintListReducer({
          elementName: "searchParameters",
          value: searchParamsLocal,
        })
      );
      urlPathObj = searchParamsLocal;
    } else {
      let searchParamsLocal = { ...searchParameters };
      searchParamsLocal.pageNo = pageNo;
      searchParamsLocal.searchFrom = cardName === RELEASE_ON_RECOG? "ROR": "LFC"             // PR-11312
      console.log(3)
      if (cardName !== RELEASE_ON_RECOG || (cardName === RELEASE_ON_RECOG && !isCardActive))
        dispatch(searchLfcDocumentList(searchParamsLocal));
      urlPathObj = searchParamsLocal;
    }

    let urlParamsLocal;
    urlParamsLocal = { ...urlPathObj };
    if (!isPaginationButton) {
      urlParamsLocal.pageNo = 1;
    } else {
      urlParamsLocal.pageNo = pageNo + 1;
    }
    urlParamsLocal.localCounty = localCounty;
    urlParamsLocal.agencyDisplay = agencyDisplay;
    urlParamsLocal.comStatus = comLStatus;
    urlPathObj = urlParamsLocal;
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlPathObj)}`,
    });
  };

  const handleInputChange = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: name,
        value: value,
      })
    );
  };

  const handleCaseNoBlur = (event: any) => {
    let { name, value } = event.target;
    let caseNum = convertCaseNumber(value);
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: name,
        value: caseNum,
      })
    );
  };

  const handleComplaintNumberBlur = (event: any) => {
    let { name, value } = event.target;
    let comNum = formatComplaintNumber(value);
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: name,
        value: comNum,
      })
    );
  };
  

  const handleDateChange = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: name,
        value: value,
      })
    );
  };
  const handleDateBlur = (event: any) => {
    let { name, value } = event.target;
    value = validateDate(value);
    dispatch(
      updateElementValueInEComplaintListReducer({
        elementName: name,
        value: value,
      })
    );
  };

  const nextRef = useRef(null);
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 9 && !e.shiftKey) {
      // tab key was pressed
      handleDateBlur(e);
    }
  };
  let tableData = lfcDocumentList.map((item: any) => {
    return [
      item.complaintNumber,
      item.primaryAgencyRepNumber,
      item.defendantName,
      item.countyName,
      item.officerDetails,
      item.complaintStatus,
      item.arrestWarrantStatus,
      item.caseNumber,
      item.createdBy,
      item.auditTimeStamp,
      onRowClick(item.ecomplaintId),
    ];
  });

  let itemLinkList = [{ itemLink: "/", itemName: "DashBoard" }];

  const data = {
    colSize: 3,
    componentData: [
      {
        type: "selectInput",
        props: {
          options: countyListOptions,
          value: countyListOptions.find((item: any) => {
            if (item.label === localCounty) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "County",
          name: "localCounty",
          defaultValue: { label: "-- ALL --", value: { countyID: 0 } },
          onChange: handleCountySelect,
        },
        colHide: userType !== ROLE_ADMINISTRATOR || cardName === RELEASE_ON_RECOG, //PR-11312
        colSize: 2,
      },
            //PR-11312 START
            {
              type: "textInputFloting",
              props: {
                floatingLabel: "Defendant Last Name ",
                type: "text",
                placeholder: "Enter Defendant Last Name ",
                name: "defLastName",
                isError: errorKeys.includes("defLastName"),
                value: defLastName,
                onChange: handleInputChange,
                maxLength: 14,
              },
              colSize: 2,
              colHide: cardName !== RELEASE_ON_RECOG,
            },
            {
              type: "textInputFloting",
              props: {
                floatingLabel: "Defendant First Name ",
                type: "text",
                placeholder: "Enter Defendant First Name ",
                isError: errorKeys.includes("defFirstName"),
                name: "defFirstName",
                value: defFirstName,
                onChange: handleInputChange,
                maxLength: 14,
              },
              colSize: 2,
              colHide: cardName !== RELEASE_ON_RECOG,
            },
            {
              type: "textInputFloting",
              props: {
                floatingLabel: "Date of Birth",
                type: "date",
                placeholder: "Enter Date of Birth",
                value: dateOfBirth ? dateOfBirth.toString() : "",
                name: "dateOfBirth",
                isError: errorKeys.includes("dateOfBirth"),
                onChange: handleDateChange,
                onBlur: handleCaseNoBlur,
                onKeyDown: handleKeyDown,
                ref: nextRef,
              },
              colSize: 2,
              colHide: cardName !== RELEASE_ON_RECOG,
            },
            {
              type: "textInputFloting",
              props: {
                floatingLabel: "Warrant Control Number",
                type: "text",
                placeholder: "Enter Warrant Control Number",
                name: "warrantControlNo",
                value: warrantControlNo,
                onChange: handleInputChange,
                maxLength: 14,
              },
              colSize: 2,
              colHide: cardName !== RELEASE_ON_RECOG,
            },
            //PR-11312 END
      {
        type: "selectInput",
        props: {
          options: agencyListOptions,
          value: agencyListOptions.find((item: any) => {
            if (item.label === agencyDisplay) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Reporting Agency",
          name: "agencyDisplay",
          defaultValue: {
            label: "-- ALL --",
            value: { displayingName: "-- ALL --", agencyId: 0 },
          },
          onChange: handleAgency,
          isError: errorKeys.includes("agencyId"),
        },
        colHide: cardName === RELEASE_ON_RECOG, //PR-11312
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Complaint #",
          type: "text",
          placeholder: "Enter Complaint #",
          name: "complaintNumber",
          value: complaintNumber,
          onChange: handleInputChange,
          isError: errorKeys.includes("complaintNumber"),
          onBlur: handleComplaintNumberBlur,
          maxLength: 14,
        },
        colSize: 2,
        colHide: cardName === RELEASE_ON_RECOG, //PR-11312
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Incident #",
          type: "text",
          placeholder: "Enter Incident #",
          name: "agencyReportNumber",
          value: agencyReportNumber,
          onChange: handleInputChange,
        },
        colSize: 2,
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Circuit Clerk Case Number",
          type: "text",
          placeholder: "Enter Circuit Clerk Case Number",
          name: "circuitClerkCaseNumber",
          value: circuitClerkCaseNumber,
          onChange: handleInputChange,
          onBlur: handleCaseNoBlur,
          maxLength: 12,
        },
        colSize: 2,
      },
      {
        type: "selectInput",
        props: {
          options: statusListOptions,
          value: statusListOptions.find((item: any) => {
            if (item.label === comStatus) {
              return { label: item.label, value: item.value };
            }
          }),
          defaultValue: { label: "-- ALL --", value: "-- ALL --" },
          placeholder: "Complaint Status",
          name: "comStatus",
          onChange: handleStatus,
        },
        colSize: 2,
        colHide: cardName === RELEASE_ON_RECOG, //PR-11312
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "From Date",
          type: "date",
          placeholder: "Enter From Date",
          value: fromDate ? fromDate.toString() : "",
          name: "fromDate",
          isError:
            errorKeys.includes("fromDate") ||
            errorMessages.includes(bothDateReq) ||
            errorMessages.includes(dateRangeMatch) ||
            errorMessages.includes(dateLessThan),
          onChange: handleDateChange,
          onBlur: handleDateBlur,
          onKeyDown: handleKeyDown,
          ref: nextRef,
        },
        colSize: 2,
        colHide: cardName === RELEASE_ON_RECOG, //PR-11312
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "To Date",
          type: "date",
          placeholder: "Enter To Date",
          value: toDate ? toDate.toString() : "",
          name: "toDate",
          isError: errorKeys.includes("toDate"),
          onChange: handleDateChange,
          onBlur: handleCaseNoBlur,
          onKeyDown: handleKeyDown,
          ref: nextRef,
        },
        colSize: 2,
        colHide: cardName === RELEASE_ON_RECOG, //PR-11312
      },
      {
        type: "button",
        props: {
          className: "w-100",
          children: (
            <>
              <RiSearchLine /> Search
            </>
          ),
          type: "submit",
          onClick: async (state: any) => {
            try {
              handleError([], []);
              let schema = [];
              if (cardName !== RELEASE_ON_RECOG) {            //PR-11312
                schema.push(defaultComplaintSearchSchema);
                schema = merge(...schema);
  
                let searchObject = {
                  complaintNumber: complaintNumber,
                  toDate: toDate,
                  fromDate: fromDate,
                  agencyId: agencyId,
                };
  
                await schema.validate(searchObject, {
                  abortEarly: false,
                });
              }
              //PR-11312 START
              else {
                 schema.push(defaultComplaintReleaseOnRecognizeSearchSchema);
                 schema = merge(...schema);
  
                 let searchObject = {
                  releaseOnRecog: true,
                 };

                 if (((defFirstName!== null && defFirstName !=="") &&
                    (defLastName!== null && defLastName !== "") &&
                    (dateOfBirth!== null && dateOfBirth !== "")) || 
                    (warrantControlNo !== null && warrantControlNo !== "") ||
                    (agencyReportNumber !== null && agencyReportNumber !==  "") ||
                    (circuitClerkCaseNumber != null && circuitClerkCaseNumber !== ""))
                      searchObject.releaseOnRecog = false;
  
                  await schema.validate(searchObject, {
                    abortEarly: false,
                  });
              }
              //PR-11312 END
              handleWarrantSearch(0, true, false);
            } catch (err: any) {
              handleError(
                err.inner.map((e: any) => e.path),
                err.errors
              );
            }
          },
        },
        colSize: 2,
      },
      {
        type: "button",
        props: {
          className: "w-100 btn-danger",
          children: (
            <>
              <RiArrowGoBackFill /> Reset
            </>
          ),
          type: "submit",
          onClick: (state: any) => {
            handleReset();
          },
        },
        colSize: 1,
      },
    ],
  };

  return (
    <>
      <Header headerName="Long Form Complaints" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Complaint List"
        />
        <ErrorMessageAlert
          className="mb-n2"
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <ListViewComponent
          table={{
            tableHeader: tableHeader,
            tableData: tableData,
            renderTableRow: renderTableRow,
          }}
          searchBar={{
            colSize: data.colSize,
            componentData:
              userType === ROLE_ADMINISTRATOR
                ? data.componentData
                : data.componentData.slice(1, data.componentData.length),
          }}
          middleComponent={(state) => (
            <ListViewMiddleComponent totalRecords={totalRecords} />
          )}
          pagination={{
            totalPages,
            numberOfPagesToShow: 5,
            currentPage,
            onPageChange: (page: number, state) => {
              handleWarrantSearch(page, false, true);
            },
          }}
        />
      </div>
    </>
  );
};

const ListViewMiddleComponent = ({
  totalRecords,
}: {
  totalRecords: number;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loginReducer = useLoginReducer();
  const { cardName } = useDashboardReducer();
  return (
    <Row>
      <Col>
        <h4>Long Form Complaints</h4>
        <h6 className="my-n2">Total Long Form Complaints : {totalRecords}</h6>
      </Col>
      <Col className="text-end">
        {loginReducer.canCreateEComplaint && cardName !== RELEASE_ON_RECOG? (
          <ButtonComponent
            variant="info"
            onClick={() => {
              dispatch(
                updateElementValueDashboardReducer({
                  elementName: "PreviousURLLFC",
                  value: window.location.href,
                })
              );
              dispatch(resetComplaintData());
              navigate("/EComplaintCaseInfoPage");
              dispatch(
                updateElementValueComplaintReducer({
                  elementName: "returnToPage",
                  value: "ComplaintList",
                })
              );
            }}
          >
            <RiAddCircleLine /> Create E-Complaint
          </ButtonComponent>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

const renderTableRow = (row: any[]) => {
  const [
    ComplaintNumber,
    IncidentNumber,
    DefendantName,
    county,
    officer,
    complaint,
    warrant,
    casenumber,
    createdby,
    auditTimeStamp,
    onRowClick,
  ] = row;
  return (
    <tr
      key={ComplaintNumber}
      onClick={onRowClick}
      style={{
        cursor: "pointer",
      }}
    >
      <td>
        <div>
          <b>{ComplaintNumber}</b>
        </div>
      </td>
      <td>{IncidentNumber}</td>
      <td>{DefendantName}</td>
      <td>{county}</td>
      <td>{officer}</td>
      <td className={`${getTextColorByStatus(complaint)} `}>
        <strong>{complaint}</strong>
      </td>
      <td className={`${getTextColorByStatus(warrant)} `}>
        <strong>{warrant}</strong>
      </td>
      <td>{casenumber}</td>
      <td>{createdby}</td>
      <td>{moment(auditTimeStamp).format("MM/DD/YYYY hh:mm A")}</td>
    </tr>
  );
};

const PreloadSearchEComplaintComponent = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { userType } = useLoginReducer();
  const { agencyList } = useLfcDocumentReducer();
  const [fetching, setFetching] = useState<LoadingType>("idle");
  const navigate = useNavigate();
  const { cardName } = useDashboardReducer();

  const preload = async () => {
    if (!cardName) {
      setFetching("loading");
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "cardName",
          value: "Long Form Complaints",
        })
      );
      // dispatch(
      //   updateElementValueDashboardReducer({
      //     elementName: "isCardActive",
      //     value: true,
      //   })
      // );
      navigate("/SearchEComplaint");
      try {
        if (
          userType === ROLE_POLICE_OFFICER ||
          userType === ROLE_AGENCY_ADMIN
        ) {
          if (agencyList.length === 0) {
            const _agencyId = searchParams.get("agencyId")?.toString() ?? "0";
            await dispatch(getAgencyAdminNameByAgency({ agencyId: _agencyId }));
          }
          await dispatch(getOfficerListForLoggedInUser());
        }
        setFetching("success");
      } catch (error) {
        setFetching("error");
      }
    }
    setFetching("success");
  };
  useEffect(() => {
    preload();
  }, []);
  return fetching === "success" || fetching === "error" ? (
    <SearchEComplaint />
  ) : null;
};

export default PreloadSearchEComplaintComponent;
