import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { updateElementObject } from "../../utils/updateElementObject";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { getEcomplaintMainDetails, setComplaintMainData } from "./complaintMainSlice";
import { fetchCountryList, insertUpdateEcomplaintData } from "../../services/EComplaintService";
import {
  CODE_TABLE_SUFFIX,
  CODE_TABLE_GENDER,
  CODE_TABLE_TSS_RACE,
  CODE_TABLE_EYECOLOR,
  CODE_TABLE_HAIRCOLOR,
  FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
} from "../../constants/common";
import { getLFCConcurrentObj } from "./commonFormSlice";

interface complaintObj {
  ecomplaintId: number;
  complaintNumber: string;
  caseNumber: string;
  primaryCaseNumber: string;

  impoundWarrant: boolean;
  typeOfArrest: string;
  onViewArrestOptionClickOnce: boolean;
  countyName: string;
  firstIncidentDateTime: string;
  primaryAgencyRepNumber: string;
  defFirstName: string;
  defMiddleName: string;
  defLastName: string;
  defSuffix: string;
  defDob: string;
  defRace: string;
  defHairColor: string;
  defEyeColor: string;
  defHeight: string;
  defWeight: string;
  defAddressLine1: string;
  defAddressLine2: string;
  defCity: string;
  defState: string;
  defZip: string;
  defCountry: string;
  defEmail: string;
  defAddressUnknown: boolean;
  defForeignAddress: boolean;
  defPhoneNumber1: string;
  defPhoneNumber2: string;
  defGender: string;
  defDriverlicense: string;
  defDlState: string;
  dcn: string;
  defSID: string;
  defFBI: string;
  nextCourtDate: string;
  nextCourtTime: string;
  nextCourtLocation: string;
  totalBond: string;

  agencyDisplayName: string;
  officerDisplayingName: string;
  caseProsecutionType: string;
  municipalityAgencyDisplayName: string;

  officerAgencyName: string;
  officerAgencyOri: string;
  officerAgencyCode: string;

  officerFirstName: string;
  officerLastName: string;
  officerBadge: string;

  municipalityAgencyName: string;
  municipalityAgencyCode: string;

  deleteEcform: boolean;

  witnessList: [];

  onViewArrestWarrantDefToBeRelease: boolean;
  onViewArrestWarrantDefWillBeHeld: boolean;

  defendantReleased: boolean;
  postSafeTAct: boolean;

  countList : [];
  currentCountyName : string;
  countyChanged : boolean;
}

interface existingDataObj {
  typeOfArrest: string;
  countyName: string;
}

interface complaintReducer {
  complaintObject: complaintObj;

  existingDataToCompare: existingDataObj;

  lfcCountyList: any[];
  loggedInUserCountyForLfcFetchStatus: LoadingType;
  loggedInUserCountyForLfcFetchError?: string;

  loggedInUserAgencyList: any[];
  loggedInUserAgencyListFetchStatus: LoadingType;
  loggedInUserAgencyListFetchError?: string;

  loggedInUserOfficersByCounty: any[];
  loggedInUserOfficersByCountyFetchStatus: LoadingType;
  loggedInUserOfficersByCountyFetchError?: string;

  agenciesForLfcCounty: any[];
  agenciesForLfcCountyFetchStatus: LoadingType;
  agenciesForLfcCountyFetchError?: string;

  policeOfficers: any[];
  policeOfficersFetchStatus: LoadingType;
  policeOfficersFetchError?: string;

  suffixList: any[];
  genderList: any[];
  raceList: any[];
  hairList: any[];
  eyesList: any[];
  codeTableEntriesFetchStatus: LoadingType;
  codeTableEntriesFetchError?: string;

  countries: any[];
  countriesFetchStatus: LoadingType;
  countriesFetchError?: string;

  getComplaintFetchStatus: LoadingType;
  getComplaintError?: string;
  complaintStatus: LoadingType;
  complaintError?: string;
  updateComplaintStatus: LoadingType;
  updateComplaintError?: string;
  successMessage?: string;

  returnToPage: string;

  tempECId: number;

  navigateFrom : string;
}

const initialState = {
  lfcCountyList: [],
  loggedInUserCountyForLfcFetchStatus: "idle",
  loggedInUserCountyForLfcFetchError: undefined,

  loggedInUserAgencyList: [],
  loggedInUserAgencyListFetchStatus: "idle",
  loggedInUserAgencyListFetchError: undefined,

  loggedInUserOfficersByCounty: [],
  loggedInUserOfficersByCountyFetchStatus: "idle",
  loggedInUserOfficersByCountyFetchError: undefined,

  agenciesForLfcCounty: [],
  agenciesForLfcCountyFetchStatus: "idle",
  agenciesForLfcCountyFetchError: undefined,

  policeOfficers: [],
  policeOfficersFetchStatus: "idle",
  policeOfficersFetchError: undefined,

  suffixList: [],
  genderList: [],
  raceList: [],
  hairList: [],
  eyesList: [],
  codeTableEntriesFetchStatus: "idle",
  codeTableEntriesFetchError: undefined,

  countries: [],
  countriesFetchStatus: "idle",
  countriesFetchError: undefined,

  getComplaintFetchStatus: "idle",
  getComplaintError: undefined,

  complaintStatus: "idle",
  complaintError: undefined,
  updateComplaintStatus: "idle",
  updateComplaintError: undefined,
  successMessage: undefined,

  returnToPage: "",

  tempECId: 0,

  navigateFrom : "",


  complaintObject: {
    ecomplaintId: 0,
    complaintNumber: "",
    caseNumber: "",
    primaryCaseNumber: "",

    impoundWarrant: false,
    typeOfArrest: "",
    onViewArrestOptionClickOnce: false,
    countyName: "",
    firstIncidentDateTime: "",
    primaryAgencyRepNumber: "",
    defFirstName: "",
    defMiddleName: "",
    defLastName: "",
    defSuffix: "",
    defDob: "",
    defRace: "",
    defHairColor: "",
    defEyeColor: "",
    defHeight: "",
    defWeight: "",
    defAddressLine1: "",
    defAddressLine2: "",
    defCity: "",
    defState: "",
    defZip: "",
    defCountry: "UNITED STATES",
    defEmail: "",
    defAddressUnknown: false,
    defForeignAddress: false,
    defPhoneNumber1: "",
    defPhoneNumber2: "",
    defGender: "",
    defDriverlicense: "",
    defDlState: "",
    dcn: "",
    defSID: "",
    defFBI: "",
    nextCourtDate: "",
    nextCourtTime: "",
    nextCourtLocation: "",
    totalBond: "",

    agencyDisplayName: "",
    officerDisplayingName: "",
    caseProsecutionType: "",
    municipalityAgencyDisplayName: "",

    officerAgencyName: "",
    officerAgencyOri: "",
    officerAgencyCode: "",

    officerFirstName: "",
    officerLastName: "",
    officerBadge: "",

    municipalityAgencyName: "",
    municipalityAgencyCode: "",

    deleteEcform: false,

    witnessList: [],

    onViewArrestWarrantDefToBeRelease: false,
    onViewArrestWarrantDefWillBeHeld: false,

    defendantReleased: false,
    postSafeTAct: false,

    countList : [],
    currentCountyName : "",
    countyChanged : false,
  },

  existingDataToCompare: {
    typeOfArrest: "",
    countyName: "",
  },
} as complaintReducer;

export const getLoggedInUserCountiesForLFC = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "counties/getUserCountiesForLfc",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `countieservices/loggedInUserCountiesForLfc`,
        token,
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(
          "Error while fetching logged in user county list For LFC"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message ||
        "Error while fetching logged in user county list For LFC"
      );
    }
  }
);

export const getLoggedInUserAgencies = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "agencies/getLoggedInUserAgencies",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `agencyservices/loggedInUserAgencies`,
        token,
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(
          "Error while fetching logged in user agency list For LFC"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message ||
        "Error while fetching logged in user agency list For LFC"
      );
    }
  }
);

export const getOfficerListForLoggedInUser = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "officers/getOfficerListForLoggedInUser",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `officerservices/getOfficerListForLoggedInUser`,
        token,
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(
          "Error while fetching logged in user Officer list For LFC"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message ||
        "Error while fetching logged in user Officer list For LFC"
      );
    }
  }
);

export const getUpdatesEcOfficersFromIucs = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "officers/getUpdatesEcOfficersFromIucs",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `officerservices/getUpdatesEcOfficersFromIucs`,
        token,
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(
          "Error while fetching logged in user Officer list For LFC"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message ||
        "Error while fetching logged in user Officer list For LFC"
      );
    }
  }
);

export const fetchAgencyListByLfcCounty = createAsyncThunk<
  any,
  { countyName: string; ecomplaintId: number },
  { state: RootState; rejectValue: string }
>(
  "agencies/getAgencyListByLfcCounty",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { countyName, ecomplaintId } = data;
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `agencyservices/getEcAgencyListByCounty/${countyName}`,
        token,
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching agencies for LFC county");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching agencies for LFC county"
      );
    }
  }
);

export const fetchOfficersByAgency = createAsyncThunk<
  any,
  { agencyCode: string; countyName: string },
  { state: RootState; rejectValue: string }
>(
  "officers/getOfficersByAgencyNameOrEcomplaint",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { agencyCode, countyName } = data;
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `officerservices/getOfficersByAgency/${agencyCode|| "no_agency_code"}/${countyName}`,
        token,
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching officers for Agency");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching officers for Agency"
      );
    }
  }
);

export const getCodeTableEntries = createAsyncThunk<
  any,
  { tableName: string },
  { state: RootState; rejectValue: string }
>(
  "codetabelentry/getCodeTableEntries",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { tableName } = data;
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `codetabelentryservices/getCodeTableEntries/${tableName}`,
        token,
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        const res = await reponse.json();
        return {
          tableName: tableName,
          list: res,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching Code Table Entries");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching Code Table Entries"
      );
    }
  }
);

export const getCountryList = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "countries/getCountriesForLfc",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await fetchCountryList({token})

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching country list");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching country list"
      );
    }
  }
);

export const saveComplaint = createAsyncThunk<
  any,
  { comp: complaintObj; navigate: any; returnToPage : string },
  { state: RootState; rejectValue: string }
>(
  "complaint/saveComplaint",
  async ({ comp, navigate, returnToPage }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj : any = getLFCConcurrentObj(ecList);

    try {
      const response = await insertUpdateEcomplaintData({
        token,
        body: comp,
        concurrentObject: concurrentObj,
        dispatch: dispatch 
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const userRes = await response.json();
        dispatch(setComplaintMainData(userRes));
        dispatch(getEcomplaintMainDetails({ecomplaintId: userRes.complaint.ecomplaintId, callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE}));
        if (comp.typeOfArrest) {
          navigate("/EComplaintMainPage");
        } else {
          if (returnToPage === "Arrest Warrant") {
            navigate("/arrestWarrant");
          } else {
            navigate("/EComplaintMainPage");
          }
        }

        return {
          message: "Success",
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(error || "Error while saving complaint");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while saving complaint");
    }
  }
);

export const getEComplaintData = createAsyncThunk<
  any,
  { ecomplaintId: number; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/getEComplaintData",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, navigate } = data;
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `ecomplaintservices/getLFCById/${ecomplaintId}`,
        token,
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        navigate("/EComplaintCaseInfoPage");
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching E Complaint Data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching E Complaint Data"
      );
    }
  }
);

export const getSyncIucsData = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/synciucsdata",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `soapservice/syncIucsData`,
        token,
        type: "POST"
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching E Complaint Data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching E Complaint Data"
      );
    }

  }
)



const complaintManageSlice = createSlice({
  name: "CreateComplaint",
  initialState,
  reducers: {
    updateElementValueComplaintReducer: updateElementValue,
    updateElementObjectValueComplaint: (state: complaintReducer, action) => {
      updateElementObject(state, action, state.complaintObject);
    },
    resetComplaintData: (state: complaintReducer) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      // getLoggedInUserCounties
      .addCase(getLoggedInUserCountiesForLFC.pending, (state) => {
        state.loggedInUserCountyForLfcFetchStatus = "loading";
        state.loggedInUserCountyForLfcFetchError = undefined;
      })
      .addCase(getLoggedInUserCountiesForLFC.fulfilled, (state, action) => {
        state.loggedInUserCountyForLfcFetchStatus = "success";
        state.lfcCountyList = action.payload;
      })
      .addCase(getLoggedInUserCountiesForLFC.rejected, (state, action) => {
        state.loggedInUserCountyForLfcFetchStatus = "error";
        state.loggedInUserCountyForLfcFetchError = action.payload;
      })
      // getLoggedInUserAgencies
      .addCase(getLoggedInUserAgencies.pending, (state) => {
        state.loggedInUserAgencyListFetchStatus = "loading";
        state.loggedInUserAgencyListFetchError = undefined;
      })
      .addCase(getLoggedInUserAgencies.fulfilled, (state, action) => {
        state.loggedInUserAgencyListFetchStatus = "success";
        state.loggedInUserAgencyList = action.payload;
      })
      .addCase(getLoggedInUserAgencies.rejected, (state, action) => {
        state.loggedInUserAgencyListFetchStatus = "error";
        state.loggedInUserAgencyListFetchError = action.payload;
      })
      // getOfficerListForLoggedInUser
      .addCase(getOfficerListForLoggedInUser.pending, (state) => {
        state.loggedInUserOfficersByCountyFetchStatus = "loading";
        state.loggedInUserOfficersByCountyFetchError = undefined;
      })
      .addCase(getOfficerListForLoggedInUser.fulfilled, (state, action) => {
        state.loggedInUserOfficersByCountyFetchStatus = "success";
        state.loggedInUserOfficersByCounty = action.payload;
      })
      .addCase(getOfficerListForLoggedInUser.rejected, (state, action) => {
        state.loggedInUserOfficersByCountyFetchStatus = "error";
        state.loggedInUserOfficersByCountyFetchError = action.payload;
      })
      //getUpdatesEcOfficersFromIucs
      .addCase(getUpdatesEcOfficersFromIucs.pending, (state) => {
        state.loggedInUserOfficersByCountyFetchStatus = "loading";
        state.loggedInUserOfficersByCountyFetchError = undefined;
      })
      .addCase(getUpdatesEcOfficersFromIucs.fulfilled, (state, action) => {
        state.loggedInUserOfficersByCountyFetchStatus = "success";
        state.loggedInUserOfficersByCounty = action.payload;
      })
      .addCase(getUpdatesEcOfficersFromIucs.rejected, (state, action) => {
        state.loggedInUserOfficersByCountyFetchStatus = "error";
        state.loggedInUserOfficersByCountyFetchError = action.payload;
      })
      // fetchAgencyListByLfcCounty
      .addCase(fetchAgencyListByLfcCounty.pending, (state) => {
        state.agenciesForLfcCountyFetchStatus = "loading";
        state.agenciesForLfcCountyFetchError = undefined;
      })
      .addCase(fetchAgencyListByLfcCounty.fulfilled, (state, action) => {
        state.agenciesForLfcCountyFetchStatus = "success";
        state.agenciesForLfcCounty = action.payload;
      })
      .addCase(fetchAgencyListByLfcCounty.rejected, (state, action) => {
        state.agenciesForLfcCountyFetchStatus = "error";
        state.agenciesForLfcCountyFetchError = action.payload;
      })
      // fetchOfficersByAgency
      .addCase(fetchOfficersByAgency.pending, (state) => {
        state.policeOfficersFetchStatus = "loading";
        state.policeOfficersFetchError = undefined;
      })
      .addCase(fetchOfficersByAgency.fulfilled, (state, action) => {
        state.policeOfficersFetchStatus = "success";
        state.policeOfficers = action.payload;
      })
      .addCase(fetchOfficersByAgency.rejected, (state, action) => {
        state.policeOfficersFetchStatus = "error";
        state.policeOfficersFetchError = action.payload;
      })
      // getCodeTableEntries
      .addCase(getCodeTableEntries.pending, (state) => {
        state.codeTableEntriesFetchStatus = "loading";
        state.codeTableEntriesFetchError = undefined;
      })
      .addCase(getCodeTableEntries.fulfilled, (state, action) => {
        state.codeTableEntriesFetchStatus = "success";
        if (action.payload.tableName === CODE_TABLE_SUFFIX) {
          state.suffixList = action.payload.list;
        } else if (action.payload.tableName === CODE_TABLE_GENDER) {
          state.genderList = action.payload.list;
        } else if (action.payload.tableName === CODE_TABLE_TSS_RACE) {
          state.raceList = action.payload.list;
        } else if (action.payload.tableName === CODE_TABLE_HAIRCOLOR) {
          state.hairList = action.payload.list;
        } else if (action.payload.tableName === CODE_TABLE_EYECOLOR) {
          state.eyesList = action.payload.list;
        }
      })
      .addCase(getCodeTableEntries.rejected, (state, action) => {
        state.codeTableEntriesFetchStatus = "error";
        state.codeTableEntriesFetchError = action.payload;
      })
      // getCodeTableEntries
      .addCase(getCountryList.pending, (state) => {
        state.countriesFetchStatus = "loading";
        state.countriesFetchError = undefined;
      })
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.countriesFetchStatus = "success";
        state.countries = action.payload;
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.countriesFetchStatus = "error";
        state.countriesFetchError = action.payload;
      })
      // Create user
      .addCase(saveComplaint.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(saveComplaint.fulfilled, (state, action) => {
        state.complaintStatus = "success";
        state.successMessage = action.payload.message;
      })
      .addCase(saveComplaint.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = action.payload;
      })

      //getCodeTableEntries
      .addCase(getEComplaintData.pending, (state) => {
        state.getComplaintFetchStatus = "loading";
        state.getComplaintError = undefined;
      })
      .addCase(getEComplaintData.fulfilled, (state, action) => {
        state.getComplaintFetchStatus = "success";
        state.complaintObject = action.payload;

        let existingDataToCompare = {
          typeOfArrest: "",
          countyName: "",
        };
        existingDataToCompare.typeOfArrest = state.complaintObject.typeOfArrest;
        existingDataToCompare.countyName = state.complaintObject.countyName;
        state.existingDataToCompare = existingDataToCompare;
      })
      .addCase(getEComplaintData.rejected, (state, action) => {
        state.getComplaintFetchStatus = "error";
        state.getComplaintError = action.payload;
      });
  },
});

export const {
  updateElementValueComplaintReducer,
  updateElementObjectValueComplaint,
  resetComplaintData,
} = complaintManageSlice.actions;

export const useComplaintReducer = () =>
  useAppSelector((state) => state.complaintForm);

export default complaintManageSlice.reducer;
