import React, { useCallback, useEffect } from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import ButtonComponent from "../common/ButtonComponent";
import FormComponent from "../common/FormComponent";
import TableComponent from "../common/TableComponent";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { getLeaderCounts, resetLeaderCountData, updateElementObjectValue, useLeaderCountReducer } from "../../redux/slice/leaderCountSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { updateElementObjectValueCountFormObjectReducer, useCountFormReducer } from "../../redux/slice/countFormSlice";
import ConfirmPopup from "../common/ConfirmPopup";
import InfoPopup from "../common/InfoPopup";
import moment from "moment";
import { COUNT_LEADER, COUNT_LEADER_MANUAL } from "../../constants/common";
import { IoWarning } from "react-icons/io5";

let defaultLeaderSchema = yup.object().shape({
  ecomplaintNumber: yup.string().nullable().required("complaint Number is required."),
})
let defaultLeaderSearchSchema = yup.object().shape({
  selectedCount: yup.string().nullable().required("Leader counts are not found.."),
})

interface LeaderCountPopupProps {
  show: boolean,
  isLookupLeaderInfo?: boolean,
  onHide: () => void,
  selectedLeader: (list: any, isIgnoreAndContinue:boolean) => void,
}

const LeaderCountPopup: React.FC<LeaderCountPopupProps> = (props) => {
  const { show, onHide, isLookupLeaderInfo, selectedLeader } = props;
  const errorMessageAlert = useAlert();
  const dispatch = useAppDispatch();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const [eticketVisible, setEticketVisible] = React.useState(false);
  const complaintMainReducer = useComplaintMainReducer();
  const { leaderCountObject, leaderCountList, status } = useLeaderCountReducer();
  const {countFormObject, fugitiveList} = useCountFormReducer();
  const [confirmationModalShow, setConfirmationModalShow] = React.useState(false);
  const [message, setMessage] = React.useState<React.ReactNode>();
  const [isCheck, setIsCheck] = React.useState<String[]>([]);
  const [dltLeaders, setDltLeaders] = React.useState(false);
  const [selectedCount, setSelectedCount] = React.useState<string[]>([]);
  const [formNumber, setFormNumber] = React.useState('');
  const [countList, setCountList] = React.useState<Object[]>([]);
  const [showError, setShowError] = React.useState({
    show: false,
    error: "",
  });

  useEffect(()=>{
    if (show && status === "success"){
      if (leaderCountList.length === 0){
        setEticketVisible(true);
      }
      else{
        if (addETicketButtonVisible(leaderCountList)){
          setEticketVisible(true);
        }else{
          setEticketVisible(false);
        }
      }
    }
  },[leaderCountList]);

  const addETicketButtonVisible = (leaderList: any) =>{
    for (let i = 0; i < leaderList.length ; i++){
      if(!disbledLeader(leaderList[i]).isDisabled){
        return false;
      }
    }
    return true;
  }

  const acceptConfirmModal = () => {
    setMessage("");
    if (dltLeaders) {
      selectedLeader(countList, false);
      cancelPopup();
    }
    else {
      setSelectedCount([...selectedCount, formNumber]);
      setIsCheck([...isCheck, "checkBox" + formNumber]);
    }
    setConfirmationModalShow(false);
  }
  const cancelConfirmModal = () => {
    setMessage("");
    setDltLeaders(false);
    setConfirmationModalShow(false);
  }
  const cancelPopup = () => {
    dispatch(resetLeaderCountData());
    setInitialState();
    onHide();
  }
  const ignoreAndContinue = () =>{
    selectedLeader([],true);
    dispatch(resetLeaderCountData());
    setInitialState();
  }
  const setInitialState = () => {
    setErrorKeys(['']);
    handleError([''], []);
    setDltLeaders(false);
    setIsCheck([]);
    setSelectedCount([]);
    setFormNumber('');
    setCountList([]);
    setEticketVisible(false);
  }
  const handleKeyChange = (event:any) =>{
    if (event.key === "Enter"){
      searchLeader();
    }
  } 
  const handleValueChange = (event: any) => {
    dispatch(updateElementObjectValue({ elementName: "ecomplaintNumber", value: event.target.value }));
  };
  const addEticketManual = () => {
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: 'countFrom', value: COUNT_LEADER_MANUAL }));
    cancelPopup()
  }
  const cancelLookupLeaderPopup = () => {
    onHide();
    setInitialState();
    dispatch(resetLeaderCountData());
  }
  const searchLeader = async () => {
    setErrorKeys(['']);
    handleError([''], []);
    let Obj = {
      ecomplaintNumber: leaderCountObject.ecomplaintNumber,
    }
    try {
      await defaultLeaderSchema.validate(Obj, { abortEarly: false, });
      let Obj1 = {
        ecomplaintId: leaderCountObject.ecomplaintNumber,
        officerAgency: complaintMainReducer.complaint.officerAgencyOri,
      };
      let data = await dispatch(getLeaderCounts(Obj1));
      if (('error' in data)) {
        setShowError({
          show: true,
          error: "Error occurred in IUCS service",
        })
      }
    }
    catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }
  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
    },
    []
  );
  const selectLeaderBeforeValidate = (item: any) => {
    let isChecked = false;
    if (item.violationInfoFormBean.violationType !== complaintMainReducer.complaint.caseProsecutionType) {
      setShowError({
        show: true,
        error: `Complaint Prosecution Level is "${complaintMainReducer.complaint.caseProsecutionType}", does not match with the selected Leader Prosecution Level`,
      })
    } else {
    let formid = item.formId;
    setFormNumber(formid ? formid : "");
    isCheck.map((checkbox) => {
      if (checkbox.includes("checkBox" + formid)) {
        isChecked = true;
      }
    });
    if ((complaintMainReducer.complaint.defLastName !== item.primaryDriverInfoFormBean.lastName || complaintMainReducer.complaint.defDob !== item.primaryDriverInfoFormBean.dob) && !isChecked) {
      let msg =
        <>
          <div>
            The LEADER citation does not match the case information. Please confirm the LEADER citation
          </div>
          <br></br>
          <div>
            {`LEADER: Defendant Last Name (${item.primaryDriverInfoFormBean.lastName}) and DOB (${item.primaryDriverInfoFormBean.dob ? item.primaryDriverInfoFormBean.dob : ""})`}
          </div>
          <div>
            {`Criminal Complaint: Defendant Last Name (${complaintMainReducer.complaint.defLastName}) and DOB (${complaintMainReducer.complaint.defDob})`}
          </div>
        </>
      setMessage(msg);
      setConfirmationModalShow(true);
    }
    else{
      setSelectedCount([...selectedCount, formid]);
      setIsCheck([...isCheck, "checkBox" + formid]);
    }
    if (isChecked) {
      setIsCheck(isCheck.filter((item) => item !== "checkBox" + formid));
      setSelectedCount(selectedCount.filter((item) => item !== formid));
    }
    }
  };
  const setSelectedData = (item: any) => {
    let Obj = {
      ...countFormObject,
      classOfViolation: item.violationInfoFormBean.classOfViolation,
      violationCode: item.violationInfoFormBean.violationCode,
      caseType: item.violationInfoFormBean.casetype,
      chargeNarrative: item.violationInfoFormBean.violationDescription,
      countFrom: COUNT_LEADER,
      citationNumber: item.citationNumber,
      formId: item.formId,
      violationkey: item.violationInfoFormBean.countyViolationKey,
      offenceDate: moment(item.offenceOccurredDate).format('YYYY-MM-DD'),
      offenceOccTime: moment(item.offenceOccurredTime, 'hh:mm A').format('HH:mm'),
      offenceOccurredOn: true,
      offenceOccurredFrom: false,
      violationDescription: item.violationInfoFormBean.violationDescription,
    }
    setCountList(countList => [...countList, Obj]);
    return Obj;
  }
  const isSelected = (Id: any) => {
    let obj = leaderCountList.map(async (item: any) => {
      if (Id === item.formId) {

        setSelectedData(item);
      }
    })
  }
  const addLeaderCount = async () => {

    if (!canAddLeaderCounts()) {
      setShowError({
        show: true,
        error: `A Leader Count cannot be added to a Peace Bond/Fugitive from Justice complaint. Fugitive from Justice Count has to be filed as a standalone complaint.`,
      })
    } else {
      let Obj = {
      selectedCount: selectedCount[0],
    }
    try {
      await defaultLeaderSearchSchema.validate(Obj, { abortEarly: false, });
      if (selectedCount.length === 1) {
        leaderCountList.map((item: any) => {
          if (selectedCount[0] === item.formId) {
            const countlist: Array<{}> = [setSelectedData(item)]
            selectedLeader(countlist, false)
            cancelPopup();
          }
        })
      }
      else {
        selectedCount.forEach(isSelected);
        let msg = "These citations will be removed from Leader Transmittal.Do you really want to proceed?";
        setMessage(msg);
        setDltLeaders(true);
        setConfirmationModalShow(true);

      }
    }
    catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }
  }

  const canAddLeaderCounts = () => {
    for (let i = 0; i < complaintMainReducer.countList.length; i++) {
      const violation: any = complaintMainReducer.countList[i].violationCode;
      const violationKey: any = complaintMainReducer.countList[i].violationkey;

      if (violation && isfugFrmJstcVialationfunction(violationKey)) {
        return false;
      }
    };
    return true;
  }

  const isfugFrmJstcVialationfunction = (violationKey: string) => {
    let fug: string[] = [];
    fugitiveList.forEach((el: any) => {
      fug.push(el.displayValue);
    })
    if (fug.includes(violationKey)) {
      return true;
    } else {
      return false;
    }
  };

  const disbledLeader = (leaderCount: any) => {
    let tooltip = "";
    if (leaderCount.offenceOccurredDate === null || leaderCount.offenceOccurredDate === "")
      tooltip = tooltip + "Offence Occurred Date is empty" + "\n";
    if (leaderCount.offenceOccurredTime === null || leaderCount.offenceOccurredTime === "")
      tooltip = tooltip + "Offence Occurred Time is empty" + "\n";
    if (leaderCount.offenceOccurredCountyName === null || leaderCount.offenceOccurredCountyName === "")
      tooltip = tooltip + "Offence Occurred County Name is empty" + "\n";
    if (leaderCount.violationInfoFormBean !== null) {
      if (leaderCount.violationInfoFormBean.violationCode === null || leaderCount.violationInfoFormBean.violationCode === "")
        tooltip = tooltip + "Violation Code is empty" + "\n";
      if (leaderCount.violationInfoFormBean.violationDescription === null || leaderCount.violationInfoFormBean.violationDescription === "")
        tooltip = tooltip + "Violation Description is empty" + "\n";
      if (leaderCount.violationInfoFormBean.classOfViolation === null || leaderCount.violationInfoFormBean.classOfViolation === "")
        tooltip = tooltip + "Class of Violation is empty" + "\n";
      if (leaderCount.violationInfoFormBean.casetype === null || leaderCount.violationInfoFormBean.casetype === "")
        tooltip = tooltip + "Case Type is empty" + "\n";
      if (leaderCount.violationInfoFormBean.countyViolationKey === null || leaderCount.violationInfoFormBean.countyViolationKey === "")
        tooltip = tooltip + "County Violation Key is empty" + "\n";
    }
    else {
      tooltip = tooltip + "Violation Code is empty" + "\n";
      tooltip = tooltip + "Violation Description is empty" + "\n";
      tooltip = tooltip + "Class of Violation is empty" + "\n";
      tooltip = tooltip + "Class of Violation is empty" + "\n";
      tooltip = tooltip + "Case Type is empty" + "\n";
      tooltip = tooltip + "County Violation Key is empty" + "\n";
    }
    if (tooltip === "") {
      return { isDisabled: false, tooltip: tooltip };
    }
    else {
      return { isDisabled: true, tooltip: tooltip }
    }
  }
  let formData = [
    {
      type: 'htmlTag',
      props: {
        type: 'label',
        value: 'Once a LEADER citation has been added to the complaint, it will be marked as REMOVED in LEADER. This citation will not be available for transmittal through LEADER once added to a criminal complaint.',
      },
      colSize: 12,
    },
    {
      type: 'htmlTag',
      props: {
        type: 'label',
        value: 'If this LEADER citation is removed from the criminal complaint, the citation must be manually marked as VALID in the LEADER Stop List.',
      },
      colSize: 12,
    },
    {
      type: 'textInput',
      props: {
        type: 'text',
        name: "ecomplaintNumber",
        isError: errorKeys.includes("ecomplaintNumber"),
        onChange: handleValueChange,
        value: leaderCountObject.ecomplaintNumber,
        onKeyDown : handleKeyChange,
      },
      label: 'Complaint Number: ',
      inLine: true,
      colSize: "auto",
    },
    {
      type: 'formButton',
      props: {
        children: "Search LEADER",
        onClick: searchLeader,
      },
      inLine: true,
      colSize: 2,
      emptyCol: 2,
    },
    {
      type: 'htmlTag',
      props: {
        type: 'label',
        value: 'Defendant Name (From Complaint):   ' + complaintMainReducer.complaint.defFirstName + ' ' + complaintMainReducer.complaint.defLastName,
      },
      colSize: 12,
      inLine: true,
    },
    {
      type: 'htmlTag',
      props: {
        type: 'label',
        value: 'Defendant DOB (From Complaint):   ' + complaintMainReducer.complaint.defDob,
      },
      inLine: true,
      colSize: 12,
    },
  ];

  let formDataLookupLeader = [
    {
      type: 'htmlTag',
      props: {
        type: 'label',
        value: 'Once a LEADER citation has been added to the complaint, it will be marked as REMOVED in LEADER. This citation will not be available for transmittal through LEADER once added to a criminal complaint.',
      },
      colSize: 12,
    },
  ];
  const tableHeader = ["#", "CITATION#", "ISSUE DATE", "DEFFENDANT Name", "VOILATION CODE", "VOILATION DESCRIPTION", "CLASS", "CLASS TYPE"];

  let tableData = leaderCountList.map((item: any) =>
    [
      item,
      item.citationNumber,
      item.issueDate,
      item.defendantName,
      item.violationInfoFormBean ? item.violationInfoFormBean.violationCode : "",
      item.violationInfoFormBean ? item.violationInfoFormBean.violationDescription : "",
      item.violationInfoFormBean ? item.violationInfoFormBean.classOfViolation : "",
      item.violationInfoFormBean ? item.violationInfoFormBean.casetype : "",
      item.formId,
      item.agencyName,
    ]
  )
  const renderTableRow = (row: any[]) => {
    const [
      item,
      citationNumber,
      issueDate,
      defendantName,
      violationCode,
      violationDescription,
      classOfViolation,
      casetype,
      formId,
      agencyName,
    ] = row;
    return (
      <OverlayTrigger placement='bottom' overlay={disbledLeader(item).isDisabled?<Tooltip id="tooltip-disabled">{disbledLeader(item).tooltip}</Tooltip>:agencyName?<Tooltip>{"Agency: " + agencyName}</Tooltip>:<></>}>
      <tr
        key={citationNumber}
        className = {disbledLeader(item).isDisabled?"text-disabled":""}
        style ={{cursor:disbledLeader(item).isDisabled?"not-allowed":""}}  
      >
        <td>
          <div className="d-flex align-items-stretch">
            <Form.Check
              type="checkbox"
              className="mt-1"
              id={"checkBox" + formId}
              onChange={() => selectLeaderBeforeValidate(item)}
              checked={isCheck.includes("checkBox" + formId)}
              disabled={disbledLeader(item).isDisabled}
              style ={{cursor:disbledLeader(item).isDisabled?"not-allowed":"pointer"}}
            />
            {disbledLeader(item).isDisabled?
           <h5 className="ps-2" style ={{cursor:disbledLeader(item).isDisabled?"not-allowed":""}}><IoWarning/></h5>
            :""}
          </div>
        </td>
   
        <td>
          <div>
            <b>{citationNumber}</b>
          </div>
        </td>
        <td>{issueDate}</td>
        <td>{defendantName}</td>
        <td>{violationCode}</td>
        <td>{violationDescription}</td>
        <td>{classOfViolation}</td>
        <td>{casetype}</td>
        
      </tr>
      </OverlayTrigger>
    );

  };

  return (
    <>
      <Modal
        show={show}
        onHide={isLookupLeaderInfo ? cancelLookupLeaderPopup : cancelPopup}
        dialogClassName="modal-55w"
        backdrop="static"
        scrollable={true}
        centered={true}
      >
        <Modal.Header closeButton><div className="popupHeader"><b>
          {isLookupLeaderInfo ? "Warning" : "Search LEADER Count"}
          </b></div>
        </Modal.Header>
        <Modal.Body>
          <ErrorMessageAlert
            messages={errorMessageAlert.messages}
            clearError={errorMessageAlert.clearError}
          />
          <FormComponent
            totalColumns={12}
            colSize={6}
            isSpace={"none"}
            componentData={isLookupLeaderInfo ? formDataLookupLeader : formData}
            formCardStyle={"none"}
            components={[
              {
                type: "formButton",
                node: (props: any) => (
                  <ButtonComponent {...props}>{props.children}</ButtonComponent>
                ),
              }
            ]}
          />
          <TableComponent
            tableHeader={tableHeader}
            tableData={tableData}
            renderTableRow={renderTableRow}
          />
        </Modal.Body>
        <Modal.Footer>
          <Col >
            <div>
              {(eticketVisible && !isLookupLeaderInfo) ?
                <Button onClick={() => addEticketManual()}>
                  ADD E-TICKET MANUALLY
                </Button>
                : ""}
            </div>
          </Col>
          <Col xs={6}></Col>
          <div>
            <Button
              onClick={() => addLeaderCount()}>
              ADD LEADER COUNTS
            </Button>
            <>  </>
            <Button
              variant={!isLookupLeaderInfo ? "danger" : "primary"}
              onClick={isLookupLeaderInfo ? ignoreAndContinue : cancelPopup}
            >
              {isLookupLeaderInfo ? "IGNORE AND CONTINUE" : "CANCEL"}
            </Button>
          </div>

        </Modal.Footer>
      </Modal>
      <ConfirmPopup
        show={confirmationModalShow}
        onHide={cancelConfirmModal}
        isConfirmation={acceptConfirmModal}>
        {message}
      </ConfirmPopup>
      <InfoPopup
        children={showError.error}
        onHide={() =>
          setShowError({
            show: false,
            error: "",
          })
        }
        show={showError.show}
        variant="danger"
      />
    </>
  )
}

export default LeaderCountPopup;