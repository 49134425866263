import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Modal, Button,Table } from 'react-bootstrap';
import {FaCaretRight} from 'react-icons/fa';
import { resetAuditTrailList, useAuditTrailReducer } from '../../redux/slice/auditTrailSlice';
import { useAppDispatch } from '../../hooks/hooks';
import TableComponent from './TableComponent';

interface AuditTrailPopupProps {
    showAuditTrail:boolean,
    onHide: () => void,
    searchwarrantid:number,
    formType:string,
    caseNumber:string,
    auditType:string
}

const AuditTrialPopup: React.FC<AuditTrailPopupProps> = (props) => {
    const { showAuditTrail, onHide, searchwarrantid, formType, caseNumber, auditType} = props;
    const dispatch = useAppDispatch();
    const {auditTrailList, auditStatus} = useAuditTrailReducer();

    const tableHeader = useMemo(()=>{
        return ["", "Action", "TimeStamp", "User IP"];
    },[]);

    const tableData = useMemo(()=>{
        return auditTrailList.map((auditTrail: any) => 
            [
                auditTrail.description,
                auditTrail.auditTimeStamp,
                auditTrail.userIp,
            ]
         )
    },[auditTrailList]);

    const renderTableRow = useCallback((row: any[]) => {
        const [
            description,
            auditTimeStamp,
            userIp,
        ] = row;
        return(
          <tr>
          <td><FaCaretRight /></td>
          <td>{description}</td>
          <td>{auditTimeStamp}</td>
          <td>{userIp}</td>
        </tr>
        );

        },[]);

    useEffect(()=>()=>{
        dispatch(resetAuditTrailList());
    },[]);

        return (
            <Modal
                show ={showAuditTrail}
                dialogClassName="modal-45w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={onHide}
                backdrop="static"
                scrollable= {true}
                className="modal-backdrop-info">
                <Modal.Header>
                    <div>
                        <p className="court-text-danger">Audit Trail</p>
                        <p>{caseNumber} {(formType != "")? (" - " + formType) : ""}</p>
                        Audit Type : {auditType}
                    </div>
                </Modal.Header>
                <Modal.Body className = "modal-body-full-height">
                    <Row>
                        <Col className="mt-2">
                            <TableComponent
                                tableHeader={tableHeader}
                                tableData={tableData}
                                renderTableRow={renderTableRow}
                                isLoading={auditStatus !== "success"}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col className="text-center">
                            <Button variant="primary" onClick={onHide} className="pl-5 pr-5">OK</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    
       
}
export default AuditTrialPopup;

