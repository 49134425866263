import React, { useCallback, useEffect } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { updateElementObjectValue, useWitnessReducer } from '../../redux/slice/witnessSlice';
import ButtonComponent from '../common/ButtonComponent';
import FormComponent from '../common/FormComponent';
import * as yup from "yup";
import { useAppDispatch } from '../../hooks/hooks';
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { getCountryList, useComplaintReducer } from '../../redux/slice/complaintSlice';
import{ createWitness }from '../../redux/slice/witnessSlice';
import { useComplaintMainReducer } from '../../redux/slice/complaintMainSlice';
import { formatPhoneNumber, isEmailValidation, phoneNumberRegex, zipCodeRegex } from '../common/CommonUtil';
import { formattedZipcode } from '../../utils/common';
import { STATE_LIST } from '../../constants/common';
import TextInputComponent from '../common/TextInputComponent';
interface WitnessPopupProps {
    show: boolean;
    onHide: () => void;
    status: string;
    isComplient?: boolean; 
    witnessCompId?: number;
}

export const WitnessPopup: React.FC<WitnessPopupProps> = (props) => {
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const witnessReducer = useWitnessReducer();
  const dispatch = useAppDispatch();
  const { complaint } = useComplaintMainReducer();
  const { countries } = useComplaintReducer();
  const { show, onHide, status, isComplient, witnessCompId } = props;

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(updateElementObjectValue({

      elementName: "country",
      value: 'UNITED STATES',
    }))
  }, [dispatch, show]);

  const displayNonAsciiValues = (value: any) => {
    var asciiRegex = /[^\x00-\x7F]+/g;
    var cleanedText = value.match(asciiRegex);
    if (cleanedText) {
      return cleanedText;
    }

  }

  let yupValidations = yup.object().shape({
    firstName: yup.string().nullable().required("First Name is required").test(
      "check-with-firstName", function (value) {
        if (value) {

          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field First Name. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }


        }
        return true;
      }
    ),
    lastName: yup.string().nullable().required("Last Name is required").test(
      "check-with-lastName", function (value) {
        if (value) {

          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Last Name. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }


        }
        return true;
      }
    ),
    middleName: yup.string().nullable().test(
      "check-with-lastName", function (value) {
        if (value) {

          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Middle Name. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }


        }
        return true;
      }
    ),
    addressLine1: yup.string().nullable().required("Address Line1 is reuired").test(
      "check-with-lastName", function (value) {
        if (value) {

          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Address Line 1. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }


        }
        return true;
      }
    ),
    addressLine2: yup.string().nullable().test(
      "check-with-lastName", function (value) {
        if (value) {

          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Address Line 2. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }


        }
        return true;
      }
    ),
    city: yup.string().nullable().required('City is required').test(
      "check-with-City", function (value) {
        if (value) {

          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field City. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }


        }
        return true;
      }
    ),
    state: yup.string().required("State is required").test(
      "check-with-State", function (value) {
        if (value) {

          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field State. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }


        }
        return true;
      }

    ),
    zip: yup.string().nullable().required("Zip is required")
      .test("check-Zip", function (value) {
        const regex = new RegExp(zipCodeRegex);
        if (value) {

          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field zip. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }


        }
        if (witnessReducer.witnessObject.zip && !regex.test(witnessReducer.witnessObject.zip)) {
          return this.createError({
            message: "Invalid 'Zip'.",
          });
        } else {
          return true;
        }
      }),
    country: yup.string().required("county is required"),
    phone: yup.string().trim()
      .test("check-PhoneNumber", function (value) {
        let phoneNumb: string = witnessReducer.witnessObject.phoneNumber ? witnessReducer.witnessObject.phoneNumber : "";
        const regex = new RegExp(phoneNumberRegex);
        if (value) {

          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Phone. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }


        }
        if (phoneNumb && !regex.test(phoneNumb)) {
          return this.createError({
            message: "Invalid 'Phone'.",
          });
        } else {
          return true;
        }
      }),
    email: yup.string().trim()
      .test("check-email", function (value) {
        if (value) {

          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Email. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }


        }
        if (witnessReducer.witnessObject.email && !isEmailValidation(witnessReducer.witnessObject.email)) {
          return this.createError({
            message: "Invalid 'Email'.",
          });
        } else {
          return true;
        }
      }),
    victim: yup.boolean()
      .test('', function (value) {
        if (witnessReducer.witnessObject.victim && witnessReducer.witnessObject.familyMemberSuspect) {
          return this.createError({
            message: "Invalid selection cannot select both 'Is Victim' and 'Is Family Member of Suspect' options.",
          });
        }
        else {
          return true
        }
      })
  })

  const AddWitness = async () => {
    let Obj = {
      firstName: witnessReducer.witnessObject.firstName,
      lastName: witnessReducer.witnessObject.lastName,
      middleName: witnessReducer.witnessObject.middleName,
      addressLine1: witnessReducer.witnessObject.addressLine1,
      addressLine2: witnessReducer.witnessObject.addressLine2,
      city: witnessReducer.witnessObject.city,
      state: witnessReducer.witnessObject.state,
      zip: witnessReducer.witnessObject.zip,
      country: witnessReducer.witnessObject.country,
      phone: witnessReducer.witnessObject.phoneNumber,
    }
    try {
      await yupValidations.validate(Obj, {
        abortEarly: false,
      });
      SaveOrUpdateWitness();
      setErrorKeys(['']);
      handleError([''], []);
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }
  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );
  const cancelPopup = () => {
    setErrorKeys(['']);
    handleError([''], [])
    onHide();
  }
  const handelChangeCheckBox = (event: any) => {
    dispatch(updateElementObjectValue({ elementName: event.target.name, value: event.target.checked }));
  }
  const handleValChange = (e: any) => {
    dispatch(updateElementObjectValue({ elementName: e.target.name, value: e.target.value }));
  }
  const handleStatusValChange = (event: any) => {
    let { label } = event;
    dispatch(updateElementObjectValue({
      elementName: "state",
      value: label,
    }))
  };
  const handleCountryValChange = (event: any) => {
    let { label } = event;
    dispatch(updateElementObjectValue({
      elementName: "country",
      value: label,
    }))
  }
  const handleToUpperCaseOnBlur = (e: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: e.target.name,
        value: e.target.value.toUpperCase(),
      })
    );
  };
  const handleNumbersDashKeyPress = (evt: any) => {
    if (evt.which != 8 && evt.which != 9 && (evt.which < 48 || evt.which > 57) && evt.key !== '-') {
      evt.preventDefault();
    }
  }
  const handleFormatZip = (event: any) => {
    let { name, value } = event.target;
    const _zip = formattedZipcode(value);
    dispatch(updateElementObjectValue({ elementName: name, value: _zip }));
  };
  const phoneNumberFormat = (event: any) => {
    let { value } = event.target;
    var phoneNumber = formatPhoneNumber(value);
    phoneNumber = phoneNumber ? phoneNumber : value;
    dispatch(updateElementObjectValue({ elementName: "phoneNumber", value: phoneNumber }));
  };
  const SaveOrUpdateWitness = () => {
    const witnessFormObj = { ...witnessReducer.witnessObject };
    let dataObj = {
      ecomplaintId: complaint.ecomplaintId,
      witnessFormObj: witnessFormObj
    };
    dispatch(createWitness(dataObj));
    onHide();
  }
  const data = [
    {
      type: "checkbox",
      props: {
        label: <label>Complaining Witness   (If the Officer will serve as the Complaining Witness, please do not enter here and use the Certification Screen.)</label>,
        name: "complainingWitness",
        value: witnessReducer.witnessObject.complainingWitness,
        checked: witnessReducer.witnessObject.complainingWitness,
        disabled: (witnessReducer.witnessObject.witnessId === witnessCompId) ? false : isComplient,
        onChange: handelChangeCheckBox,
        autoComplete:"nope",
      },
      colSize: 12
    },
    {
      type: "checkbox",
      props: {
        label: "Is Victim",
        name: "victim",
        value: witnessReducer.witnessObject.victim,
        checked: witnessReducer.witnessObject.victim,
        onChange: handelChangeCheckBox,
        isError: errorKeys.includes("victim"),
        autoComplete:"nope",
      },
      colSize: 2
    },
    {
      type: "checkbox",
      props: {
        label: "Is Family Member of Suspect",
        name: "familyMemberSuspect",
        value: witnessReducer.witnessObject.familyMemberSuspect,
        checked: witnessReducer.witnessObject.familyMemberSuspect,
        onChange: handelChangeCheckBox,
        autoComplete:"nope",
      },
      colSize: 10
    },
    {
      type: "tooltipTextField",
      props: {
        type: "text",
        name: "lastName",
        value: witnessReducer.witnessObject.lastName,
        onChange: handleValChange,
        isRequired: true,
        labelName: "Last Name",
        onBlur: handleToUpperCaseOnBlur,
        isError: errorKeys.includes("lastName"),
        autoComplete:"nope",
        placeholder: "Last Name",
        tooltip: "Last Name of the witness here."
      },
      colSize: 4,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "middleName",
        value: witnessReducer.witnessObject.middleName,
        onChange: handleValChange,
        onBlur: handleToUpperCaseOnBlur,
        labelName: "Middle Name",
        isError: errorKeys.includes("middleName"),
        autoComplete:"nope",
        placeholder: "Middle Name"
      },
      colSize: 3,
    },
    {
      type: "tooltipTextField",
      props: {
        type: "text",
        name: "firstName",
        isRequired: true,
        value: witnessReducer.witnessObject.firstName,
        onChange: handleValChange,
        labelName: "First Name",
        onBlur: handleToUpperCaseOnBlur,
        isError: errorKeys.includes("firstName"),
        autoComplete:"nope",
        placeholder: "First Name",
        tooltip: "First Name of the witness here.",
      },
      colSize: 3,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "suffix",
        value: witnessReducer.witnessObject.suffix,
        onChange: handleValChange,
        onBlur: handleToUpperCaseOnBlur,
        labelName: "Suffix",
        autoComplete:"nope",
      },
      colSize: 2,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "email",
        value: witnessReducer.witnessObject.email,
        onChange: handleValChange,
        labelName: "Email",
        isError: errorKeys.includes("email"),
        autoComplete:"nope",
      },
      colSize: 7,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "addressLine1",
        value: witnessReducer.witnessObject.addressLine1,
        onChange: handleValChange,
        isRequired: true,
        labelName: "Address Line 1",
        onBlur: handleToUpperCaseOnBlur,
        isError: errorKeys.includes("addressLine1"),
        autoComplete:"nope",
        maxLength: 35,
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "addressLine2",
        value: witnessReducer.witnessObject.addressLine2,
        onChange: handleValChange,
        onBlur: handleToUpperCaseOnBlur,
        labelName: "Address Line 2",
        autoComplete:"nope",
        maxLength: 35,
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "city",
        value: witnessReducer.witnessObject.city,
        onChange: handleValChange,
        isRequired: true,
        labelName: "City",
        onBlur: handleToUpperCaseOnBlur,
        isError: errorKeys.includes("city"),
        autoComplete:"nope",
        maxLength: 25,
      },
      colSize: 3,
    },
    {
      type: "selectInput",
      props: {
        type: "text",
        isLabeled: true,
        labelName: <label>State<span>*</span></label>,
        placeholder: "-- Select --",
        isDefaultLabel: true,
        onChange: handleStatusValChange,
        autoComplete:"nope",
        options: [
          ...STATE_LIST.map((data: any) => {
            return {
              label: data,
              value: data
            };
          }),
        ],
        value: STATE_LIST.map((data: any) => {
          return {
            label: data,
          };
        })
          .find(
            (item) => {
              if (item.label === witnessReducer.witnessObject.state)
                return {
                  label: item.label,
                };
            }
          ),
        isError: errorKeys.includes("state"),
      },
      colSize: 3,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "Zip",
        name: "zip",
        placeholder: "XXXXX-XXXX",
        value: witnessReducer.witnessObject.zip,
        onChange: handleValChange,
        onKeyPress: handleNumbersDashKeyPress,
        onBlur: handleFormatZip,
        autoComplete:"nope",
        isRequired: true,
        isError: errorKeys.includes("zip"),
      },
      colSize: 3,
    },
    {
      type: "selectInput",
      props: {
        type: "text",
        labelName: <label>Country</label>,
        onChange: handleCountryValChange,
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete:"nope",
        defaultValue: {
          label: 'UNITED STATES',
          value: 'UNITED STATES'
        },
        options: [
          { label: "-- Select --", value: "" },
          ...countries.map((cntry: any) => {
            return { label: cntry, value: cntry };
          })
        ],
        value: [
          { label: "-- Select --", value: "" },
          ...countries.map((cntry: any) => {
            return { label: cntry, value: cntry };
          })
        ].find((item) => {
          if (item.label === witnessReducer.witnessObject.country)
            return {
              label: item.label,
            };
        }),
        isError: errorKeys.includes("country"),
      },
      colSize: 3,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "Phone",
        name: "phoneNumber",
        placeholder: "XXX-XXX-XXXX",
        value: witnessReducer.witnessObject.phoneNumber,
        onChange: handleValChange,
        onKeyPress: handleNumbersDashKeyPress,
        isError: errorKeys.includes("defPhoneNumber1"),
        onBlur: phoneNumberFormat,
        autoComplete:"nope",
      },
      colSize: 3,
    },
  ]

  return (
    <Modal
      show={show}
      dialogClassName="modal-85w"
      centered
      onHide={() => onHide()}
      backdrop='static'>
      <Modal.Header>
        <Modal.Title>
          <b>Witness Information</b>
        </Modal.Title>
      </Modal.Header>
      <ErrorMessageAlert
        messages={errorMessageAlert.messages}
        clearError={errorMessageAlert.clearError}
      />
      <br />
      <Modal.Body>
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data} 
          components={[
            {
              type: "tooltipTextField",
              node: (props: any) => (
                <OverlayTrigger
                  placement="top"
                  trigger="focus"
                  overlay={(
                    <Tooltip id="tooltip-disabled">{props.tooltip}</Tooltip>
                  )
                  }
                >
                  <TextInputComponent {...props} />
                </OverlayTrigger>
              )
            },
          ]}
          />
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent onClick={AddWitness}>
          {status === 'create' ? 'Add' : 'Update'} Witness</ButtonComponent>
        <ButtonComponent variant='danger' onClick={cancelPopup}>Cancel</ButtonComponent>
      </Modal.Footer>
    </Modal>
  );
}