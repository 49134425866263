import React, { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import ButtonComponent from "../common/ButtonComponent";
import SelectInput from "../common/SelectComponent";
import { useNavigate } from "react-router-dom";
import {
  SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER,
  SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT,
  SEARCH_WAR_TEMPLATE_TYPE_ORDER,
} from "../../constants/common";

interface SelectTemplateProps {
  show: boolean;
  onHide: () => void;
  children?: React.ReactNode;
}

const SelectTemplatePopup: React.FC<SelectTemplateProps> = (props) => {
  const { show, onHide, children } = props;

  const navigate = useNavigate();

  const [templateFormData, setTemplateFormData] = useState({
    templateType: 0,
    templateName: "",
  });

  const createTemplate = [
    {
      label: SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT,
      value: "ComplaintSearchwarrantTemp",
    },
    { label: SEARCH_WAR_TEMPLATE_TYPE_ORDER, value: "ApplicationAndOrderTemp" },
    { label: SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER, value: "BlankOrderTemp" },
  ];

  const hidePopup = () => {
    onHide();
  };

  const onChangeHandle = (event: any) => {
    setTemplateFormData({
      templateType: event.value,
      templateName: event.label,
    });
  };

  const handleSubmit = () => {
    if (templateFormData.templateType != 0) {
      navigate(`/add${templateFormData.templateType}`);
      onHide();
    } else {
      //add error
    }
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => hidePopup()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <b>Template Type</b>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col>
            <SelectInput
              name="templateType"
              isRequired
              placeholder="Select Template Type"
              options={createTemplate}
              onChange={onChangeHandle}
            />
          </Col>
        </Row>

        <hr />
        <Row className="g-2">
          <Col>
            <ButtonComponent
              variant="primary"
              onClick={handleSubmit}
              className="w-100"
            >
              <RiCheckFill /> Ok
            </ButtonComponent>
          </Col>
          <Col>
            <ButtonComponent
              variant="danger"
              onClick={() => hidePopup()}
              className="w-100"
            >
              <RiCloseFill /> Cancel
            </ButtonComponent>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SelectTemplatePopup;
