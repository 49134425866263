import { Navigate, useLocation } from "react-router-dom";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { PropsWithChildren } from "react";
import LayoutWrapper from "./LayoutWrapper";
import LoadingPopup from "./LoadingPopup";

type Props = {
  fallback?: string;
};

const ProtectedRoute = ({ children, fallback }: PropsWithChildren<Props>) => {
  const { status, isValid } = useLoginReducer();
  const location = useLocation();

  if (status === "idle" && fallback) {
    return <Navigate to={fallback} replace state={{ from: location }} />;
  } else if ((status === "success" || status === "error") && !isValid) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  if (status === "success" && isValid) {
    return <LayoutWrapper>{children}</LayoutWrapper>;
  }
  return <LoadingPopup show />;
};

export default ProtectedRoute;
