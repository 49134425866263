import { configureStore,
  combineReducers,
  AnyAction } from "@reduxjs/toolkit";
import login from "./slice/loginSlice";
import common from "./slice/commonSlice";
import signPanel from "./slice/signPanelSlice";
import counties from "./slice/countyListSlice";
import agency from "./slice/agencySlice";
import userProfileList from "./slice/userProfileListSlice";
import county from "./slice/countySlice";
import agencies from "./slice/agencyListSlice";
import userType from "./slice/userTypeSlice";
import userProfile from "./slice/userProfileSlice";
import complaintForm from "./slice/complaintSlice";
import template from "./slice/templateSlice";
import userManual from "./slice/userManualSlice";
import videoConference from "./slice/videoConferenceSlice";
import activeUser from "./slice/activeUserSlice";
import searchWarrant from "./slice/searchWarrantSlice";
import blankOrder from "./slice/blankOrderSlice";
import userStatutesList from "./slice/statutesListSlice";
import selectCountyAgency from "./slice/selectCountyAgencySlice";
import codeTableEntry from "./slice/codeTableEntrySlice";
import applicationAndOrder from "./slice/applicationAndOrderSlice";
import commonForm from "./slice/commonFormSlice";
import caseDetail from "./slice/caseDetailSlice";
import searchWarrantList from "./slice/searchWarrantListSlice";
import proofOfService from "./slice/proofOfServiceSlice";
import inventory from "./slice/inventorySlice";
import orderOfCustody from "./slice/orderOfCustodySlice";
import amendInventory from "./slice/amendInventorySlice";
import lfcDocumentList from "./slice/lfcDocumentSlice";
import arrestWarrant from "./slice/arrestWarrantSlice";
import complaintMain from "./slice/complaintMainSlice";
import ecViolation from "./slice/EcViolationSlice";
import countForm from "./slice/countFormSlice";
import complaintDashboard from "./slice/ecomplaintDashboardSlice";
import witness from "./slice/witnessSlice";
import userChangeProfile from "./slice/userChangeProfileSlice";
import certificationForm from "./slice/certificationSlice";
import dashboard from "./slice/dashboardSlice";
import webRms from "./slice/webRmsSlice";
import leaderCount from "./slice/leaderCountSlice";
import auditTrail from "./slice/auditTrailSlice";
import templateList from "./slice/templateListSlice";
import preTrialReleaseRecognizance from "./slice/preTrialReleaseRecognizanceSlice";
import probableCauseStatementSlice from "./slice/probableCauseStatementSlice";
import SecurityPassword from "../components/common/SecurityPasswordPopup";
import securityPasswordSlice from "./slice/securityPasswordSlice";
import summonsSlice from "./slice/summonsSlice";

const combinedReducer = combineReducers({
  activeUser : activeUser,
  agencies : agencies,
  agency : agency,
  amendInventory : amendInventory,
  applicationAndOrder : applicationAndOrder,
  arrestWarrant : arrestWarrant,
  auditTrail : auditTrail,
  blankOrder : blankOrder,
  caseDetail : caseDetail,
  certificationForm : certificationForm,
  codeTableEntry : codeTableEntry,
  commonForm : commonForm,
  common : common,
  complaintDashboard : complaintDashboard,
  complaintForm : complaintForm,
  complaintMain :complaintMain ,
  countForm :countForm ,
  counties : counties,
  county : county,
  dashboard : dashboard,
  ecViolation : ecViolation,
  inventory : inventory,
  leaderCount : leaderCount,
  lfcDocumentList : lfcDocumentList,
  login : login,
  orderOfCustody : orderOfCustody,
  proofOfService : proofOfService,
  probableCauseStatement : probableCauseStatementSlice,
  searchWarrantList : searchWarrantList ,
  searchWarrant : searchWarrant,
  securityPassword :securityPasswordSlice,
  selectCountyAgency : selectCountyAgency,
  signPanel : signPanel,
  summons : summonsSlice,
  template : template,
  templateList : templateList,
  userChangeProfile : userChangeProfile,
  userManual : userManual,
  userProfileList : userProfileList,
  userProfile : userProfile,
  userStatutesList : userStatutesList,
  userType : userType,
  videoConference : videoConference,
  webRms : webRms,
  witness : witness,
  preTrialReleaseRecognizance : preTrialReleaseRecognizance,
});

const rootReducers = (state : any, action : AnyAction) => {
  if (action.type === 'users/logoutUser/fulfilled') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducers,
  devTools: process.env.NODE_ENV !== "production"
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
