import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import {
  associateCoutiesToAgency,
  createAgency,
  getAgencyDataById,
  resetAgencyData,
  updateAgency,
  updateElementValueAgencyReducer,
  useAgencyReducer,
} from "../../redux/slice/agencySlice";
import {
  getLoggedInUserCounties,
  useCountyListReducer,
} from "../../redux/slice/countyListSlice";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import Header from "../common/Header";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { merge } from "../../utils/common";
import ConfirmPopup from "../common/ConfirmPopup";
import AssociateCountiesPopup from "../county/AssociateCountiesPopup";
import useModel from "../../hooks/useModel";

let defaultAgencySchema = yup.object().shape({
  countySelectList: yup.array().min(1, "County is required"),
  agencyCode: yup.string().required("Agency Code is required"),
  agencyName: yup.string().required("Agency Name is required"),
  policeAgency: yup.string().required("Police Agency Name is required"),
});

export default function AddAgency() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const agencyReducer = useAgencyReducer();
  const countiesReducer = useCountyListReducer();
  const [errorKeys, setErrorKeys] = useState([""]);
  const errorMessageAlert = useAlert();
  const [confirmPopupData, setconfirmPopupData] = useState({
    show: false,
    message: "",
    agencyId: 0,
  });
  const associateCountiesPopup = useModel();

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );
  useEffect(() => {
    return () => {
      const pathname = location.pathname.split("/")[1];
      if (pathname ! == "EditAgency") {
        dispatch(resetAgencyData());
      }
    };
  }, []);

  useEffect(() => {
    dispatch(getLoggedInUserCounties());
    const agencyId = location.pathname.split("/")[2];

    if (agencyId) {
      dispatch(
        getAgencyDataById({
          id: parseInt(agencyId),
          navigate,
        })
      );
    }
  }, [dispatch, location, navigate]);

  useEffect(() => {
    if (agencyReducer.createAgencyError) {
      if (agencyReducer.createAgencyError.includes("Agency already exist,")) {
        const agencyId = agencyReducer.createAgencyError.split(",")[1];
        setconfirmPopupData({
          show: true,
          message: `Agency already exists. Do you want to associate it with ${agencyReducer.countySelectList[0].countyName} County?`,
          agencyId: parseInt(agencyId),
        });
      } else {
        handleError([], [agencyReducer.createAgencyError]);
      }
      dispatch(
        updateElementValueAgencyReducer({
          elementName: "createAgencyError",
          value: undefined,
        })
      );
    }
    if (agencyReducer.updateAgencyError) {
      handleError([], [agencyReducer.updateAgencyError]);
      dispatch(
        updateElementValueAgencyReducer({
          elementName: "updateAgencyError",
          value: "",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agencyReducer.createAgencyError,
    agencyReducer.updateAgencyError,
    dispatch,
    handleError,
  ]);

  // const handleSelectChange = (name: string) => (e: any) => {
  //   if (name === "countyId") {
  //     dispatch(
  //         updateElementValueAgencyReducer({
  //           elementName: "countySelectList",
  //           value: [e],
  //         })
  //       );
  //     dispatch(
  //         updateElementValueAgencyReducer({
  //           elementName: "countyId",
  //           value: e.countyID,
  //         })
  //       );
  //   }
  // };

  useEffect(() => {
    let ctyId: string;
    const arrCounty: Array<string> = [];
    agencyReducer.countySelectList.map((county: any) => {
      arrCounty.push(county.countyID);
    });
    ctyId = arrCounty.join();
    dispatch(
      updateElementValueAgencyReducer({
        elementName: "countyIdList",
        value: ctyId,
      })
    );
  }, [agencyReducer.countySelectList]);

  const handleCounty = (e: any) => {
    console.log(e)
    let countyObject: string[] = [];
    countyObject = e;
    dispatch(
      updateElementValueAgencyReducer({
        elementName: "countySelectList",
        value: countyObject,
      })
    );
  };

  const handleToUpperCaseOnBlur = (e: any) => {
    dispatch(
      updateElementValueAgencyReducer({
        elementName: e.target.name,
        value: e.target.value.toUpperCase(),
      })
    );
  };

  const handleInputChange = (e: any) => {
    // Do not allow more than five characters in the agency code
    if (e.target.name === "agencyCode" && e.target.value.length > 5) {
      return;
    }
    // If the value is true or false then convert it to boolean (For checkbox)
    const value =
      e.target.value === "true" || e.target.value === "false"
        ? e.target.value !== "true"
        : e.target.value;

    dispatch(
      updateElementValueAgencyReducer({
        elementName: e.target.name,
        value,
      })
    );
  };

  const handleSubmit = async () => {
    handleError([""], []);
    try {
      let schema = [];
      schema.push(defaultAgencySchema);
      schema = merge(...schema);
      const agency = await schema.validate(agencyReducer, {
        abortEarly: false,
      });
      if (location.pathname === "/AddAgency") {
        dispatch(
          createAgency({
            agency,
            navigate,
          })
        );
      } else {
        dispatch(
          createAgency({
            agency,
            navigate,
          })
        );
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const handleConfirmationPopUp = () => {
    dispatch(
      associateCoutiesToAgency({
        agency: {
          ...agencyReducer,
          countyIdList: agencyReducer.countySelectList[0].countyID,
          agencyId: confirmPopupData.agencyId,
        },
        navigate,
      })
    );
    setconfirmPopupData({
      show: false,
      message: "",
      agencyId: 0,
    });
  };

  const data = [
    {
      type: "selectInput",
      props: {
        options: [
          { countyName: "-- Select --", countyID: 0 },
          ...countiesReducer.countyList.map((county: any) => {
            return { countyName: county.countyName, countyID: county.countyID };
          }),
        ],
        placeholder: "County",
        labelName: "County/Counties(Select one or more counties to associate with agency)",
        name: "countySelectList",
        isMulti: true,
        value: agencyReducer.countySelectList.map((county: any) => {
          return { countyName: county.countyName, countyID: county.countyID };
        }),
        onChange: handleCounty,
        getOptionLabel: (option: any) => option.countyName,
        getOptionValue: (option: any) => option.countyID,
        isError: errorKeys.includes("countySelectList"),
        // defaultValue: { countyName: "-- Select --", countyID: 0 },
        isLabeled: true,
        isRequired: true,
      },
    },
    {
      type: "empty",
    },
    {
      type: "textInput",
      props: {
        labelName: "Agency Code",
        type: "text",
        placeholder: "Agency Code",
        name: "agencyCode",
        value: agencyReducer.agencyCode,
        onChange: handleInputChange,
        isError: errorKeys.includes("agencyCode"),
        onBlur: handleToUpperCaseOnBlur,
        isRequired: true,
      },
    },
    {
      type: "empty",
    },
    {
      type: "textInput",
      props: {
        labelName: "Agency Name",
        type: "text",
        placeholder: "Agency Name",
        name: "agencyName",
        value: agencyReducer.agencyName,
        onChange: handleInputChange,
        onBlur: handleToUpperCaseOnBlur,
        isError: errorKeys.includes("agencyName"),
        isRequired: true,
      },
    },
    {
      type: "empty",
    },
    {
      type: "textInput",
      props: {
        labelName: "Police Agency Name",
        type: "text",
        placeholder: "Police Agency Name",
        name: "policeAgency",
        value: agencyReducer.policeAgency,
        onBlur: handleToUpperCaseOnBlur,
        onChange: handleInputChange,
        isError: errorKeys.includes("policeAgency"),
        isRequired: true,
      },
    },
    {
      type: "empty",
    },
    {
      type: "checkbox",
      props: {
        label: "State Agency",
        name: "isStateAgency",
        value: agencyReducer.isStateAgency,
        checked: agencyReducer.isStateAgency,
        onChange: handleInputChange,
      },
    },
    {
      type: "empty",
    },
    {
      type: "checkbox",
      props: {
        label: "Long Form Enable",
        name: "longFormEnabled",
        value: agencyReducer.longFormEnabled,
        checked: agencyReducer.longFormEnabled,
        onChange: handleInputChange,
      },
    },
    {
      type: agencyReducer.countySelectList.find((c) => c.isLPAllowed)
        ? "empty"
        : "",
    },
    {
      type: agencyReducer.countySelectList.find((c) => c.isLPAllowed)
        ? "checkbox"
        : "",
      props: {
        label: "Local Procecutor Allowed",
        name: "isLPAllowed",
        value: agencyReducer.isLPAllowed,
        checked: agencyReducer.isLPAllowed,
        onChange: handleInputChange,
      },
    },
    {
      type: "empty",
    },
    {
      type: "checkbox",
      props: {
        label: "Notarization Required",
        name: "notarizationReq",
        value: agencyReducer.notarizationReq,
        checked: agencyReducer.notarizationReq,
        onChange: handleInputChange,
      },
    },
    {
      type: "empty",
    },
    {
      type: "checkbox",
      props: {
        label: "Complaint Review/Approval [Before Filing] Required",
        name: "isComplaintSupervised",
        value: agencyReducer.isComplaintSupervised,
        checked: agencyReducer.isComplaintSupervised,
        onChange: handleInputChange,
      },
    },
    {
      type: "button",
      props: {
        children: location.pathname === "/AddAgency" ? "Save" : "Update",
        className: "mx-2",
        onClick: handleSubmit,
      },
    },
    // {
    //   type: location.pathname.includes("/EditAgency") ? "button" : "",
    //   props: {
    //     children: "Update County Association",
    //     className: "mx-2",
    //     onClick: associateCountiesPopup.open,
    //   },
    // },
    {
      type: "button",
      props: {
        children: "Cancel",
        className: "btn-danger",
        onClick: () => {
          navigate(-1);
        },
      },
    },
  ];
  return (
    <>
      <Header headerName="Agency" />
      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="Agency" />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent
          header="Agency"
          totalColumns={12}
          colSize={6}
          componentData={data}
          subHeader={
            location.pathname === "/AddAgency" ? "Add Agency" : "Edit Agency"
          }
        />
        <ConfirmPopup
          children={confirmPopupData.message}
          show={confirmPopupData.show}
          onHide={() =>
            setconfirmPopupData({
              show: false,
              message: "",
              agencyId: 0,
            })
          }
          isConfirmation={handleConfirmationPopUp}
        />
        <AssociateCountiesPopup
          show={associateCountiesPopup.isOpen}
          onHide={associateCountiesPopup.close}
        />
      </div>
    </>
  );
}

const itemLinkList = [
  { itemLink: "/", itemName: "DashBoard" },
  { itemLink: "/AgencyList", itemName: "Agency List" },
];
