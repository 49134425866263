import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../common/Header";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import moment from "moment";
import { useAppDispatch } from "../../hooks/hooks";
import {
  getUserDataById,
  getUserDataByUsername,
  resetUserProfilesData,
  useUserProfilesReducer,
} from "../../redux/slice/userProfileSlice";
import {
  listUserTypes,
  useUserTypeReducer,
} from "../../redux/slice/userTypeSlice";

type UserDetailProps = {};

const UserDetail: React.FC<UserDetailProps> = (props) => {
  

  const location = useLocation();
  const userReducer = useUserProfilesReducer();
  const { userTypeList } = useUserTypeReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const userName = location.pathname.split("/")[2];
    dispatch(resetUserProfilesData());
    dispatch(listUserTypes({}));
    if (userName) {
      dispatch(
        getUserDataByUsername({
          username: userName,
          navigate,
        })
      );
    }
  }, [dispatch, location, navigate]);

  // console.log(userTypeList.map((item: any) => {
  //   return { label: item.type, value: item.userTypeID };
  // })
  // .find((item) => item.value === userReducer.auditUserID)?.label);

  // const hidePassword = (password:string) => {
  //   for (let i = 0; i < password.length; i++) {
  //     password += password[i] + "*";
  //   }
  //   console.log(password);
  //   return password;
  // }

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/UserList", itemName: "User List" },
  ];
  const data = [
    {
        type: "textInput",
        props: {
          type: "text",
          labelName: <b>User Name</b>,
          value: userReducer.username,
          disabled: true
        },
        colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>County</b>,
        value: userReducer.countyNames !== null ? userReducer.countyNames.length > 0?userReducer.countyNames:"-":"-",
        disabled: true
      },
      colSize: 6,
  },
  {
    type: "textInput",
    props: {
      labelName: <b>Password</b>,
      type:"password",
      value: userReducer.password,
      disabled: true
    },
    colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Agency</b>,
    value: userReducer.agencyIdList !== null ? userReducer.agencyIdList.length > 0?userReducer.agencyNames:"-":"-",
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>User Type</b>,
    value: userReducer.typeName,
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Work Phone</b>,
    value: userReducer.workPhone,
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>First Name</b>,
    value: userReducer.firstName,
    disabled: true
  },
  colSize: 6,
},
// {
//   type: "textInput",
//   props: {
//     type: "text",
//     labelName: "Home Phone",
//     value: userReducer.homePhone,
//     className: "fw-bold",
//     disabled: true
//   },
//   colSize: 6,
// },
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Last Name</b>,
    value: userReducer.lastName,
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Mobile</b>,
    value: userReducer.mobileNo?userReducer.mobileNo:"-",
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Middle Name</b>,
    value: userReducer.middleName?userReducer.middleName:"-",
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Email</b>,
    value: userReducer.email,
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Badge Number</b>,
    value: userReducer.badgeNumber?userReducer.badgeNumber:"-",
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Attorney ID</b>,
    value: userReducer.attorneyID?userReducer.attorneyID:"-",
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>File On Behalf Of Others</b>,
    value: userReducer.fileOnBehalfOfOthers?"Y":"N",
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Modified by</b>,
    value: userReducer.auditUserName,
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Created By</b>,
    value: userReducer.creatorname,
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Modified Time</b>,
    value: moment(userReducer.auditTimeStamp).format("YYYY-MM-DD HH:mm A"),
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Creation Time</b>,
    value: moment(userReducer.creationTimeStamp).format("YYYY-MM-DD HH:mm A"),
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Commission</b>,
    value: userReducer.commissionNumber?userReducer.commissionNumber:"-",
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Expiration Date</b>,
    value: userReducer.expirationDate?moment(userReducer.expirationDate).format("YYYY-MM-DD"):"-",
    disabled: true
  },
  colSize: 6,
},
{
  type: "textInput",
  props: {
    type: "text",
    labelName: <b>Recieve Notification</b>,
    value: userReducer.receiveNotification?"Y":"N",
    disabled: true
  },
  colSize: 6,
},
{
  type: "button",
  props: {
    children: "Edit",
    // onClick: handleSubmit,
    className: "mx-2",
    onClick: () => {
      navigate("/editUser/" + userReducer.username);
    },
  },
},
{
  type: "button",
  props: {
    children: "Cancel",
    className: "btn-danger",
    onClick: () => {
      navigate(-1);
    },
  },
},
  ];

  return (
    <>
      <Header headerName="User" />
      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="User" />
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data}
          header="User"
          subHeader={"User Details"}
        />
      </div>
    </>
  );
};

export default UserDetail;
