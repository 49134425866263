import React, { useCallback, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import TextInputComponent from "../../common/TextInputComponent";
import Header from "../../common/Header";
import {  useNavigate } from "react-router-dom";
import {
  RiArrowGoBackLine,
  RiBallPenLine,
  RiCloseLine,
  RiFileList2Line,
  RiFileTextLine,
  RiSave3Line,
  RiSaveFill,
  RiSaveLine,
  RiUserShared2Line,
} from "react-icons/ri";
import BreadcrumbComponent from "../../common/BreadcrumbComponent";
import { useAppDispatch } from "../../../hooks/hooks";
import {
  useAmendInventoryReducer,
  updateElementObjectValue,
  updateAmendInventory,
  updateElementValueAmendInventoryReducer,
  returnAmendInventory,
  signAmendInventory,
} from "../../../redux/slice/amendInventorySlice";
import FormComponent from "../../common/FormComponent";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";
import { useSignPanelReducer } from "../../../redux/slice/signPanelSlice";
import ConfirmPopup from "../../common/ConfirmPopup";
import { validateSignaturePixel, validateSignPixel } from "../../common/CommonUtil";
import {  ACTIONTYPE_SAVE, AMENDED_INVENTORY, INVENTORY_RETURN_TYPE_VIDEO, ROLE_ADMINISTRATOR, ROLE_AGENCY_ADMIN, ROLE_ATTORNEY_GENERAL, ROLE_COUNTY_ADMIN, ROLE_JUDGE, ROLE_LOCAL_PROSECUTOR, ROLE_POLICE_OFFICER, ROLE_STATES_ATTORNEY, SEARCH_WAR_ASSIGNED_TO_AG_STATUS, SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS, SEARCH_WAR_ASSIGNED_TO_LP_STATUS, SEARCH_WAR_ASSIGNED_TO_SA_STATUS, SEARCH_WAR_COMPLETE_STATUS, SEARCH_WAR_INCOMPLETE_STATUS, SEARCH_WAR_LOCKED_BY_JUDGE_STATUS, SEARCH_WAR_OFFICER_PENDING_STATUS } from "../../../constants/common";
import { useLoginReducer } from "../../../redux/slice/loginSlice";
import { getSWCaseDetail, useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";
import { formatName, validateDate } from "../../../utils/common";
import { previewDraftingOrder, previewOrder } from "../../../redux/slice/commonFormSlice";
import { Util } from "../../common/FormUtil";
import RemarkPopup from "../../common/RemarkPopup";
import moment from 'moment';
import ButtonComponent from "../../common/ButtonComponent";
import FormFiller from "../../common/FormFiller";
import { useDashboardReducer, updateElementValueDashboardReducer } from "../../../redux/slice/dashboardSlice";
import { getJudgeSavedSignature } from "../../../redux/slice/userProfileSlice";

interface AmendInventoryProps {}

let fieldTitles =[' ','and the seizure of','LIST SEIZED ITEMS HERE'];
let names = ['txtPersonPremises','txtSeized1','txtSeized2'];

let defaultSwSchema = yup.object().shape({
  txtPersonPremises: yup.string().nullable().required("Person Premises is required."),
  txtPresentingDate: yup.date().nullable().required("Date of presenting seized inventory Judge is required."),
  txtSeized1: yup.string().nullable().required("seizure is required."),
  txtSeized2: yup.string().nullable().required("Seized information is required."),
  txtPersonPremiseslbl : yup.string().nullable().required("Person or premises value for Seized information is required.")
});

const AmendInventory: React.FC<AmendInventoryProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const amendInventoryReducer = useAmendInventoryReducer();
  const signPanelReducer = useSignPanelReducer();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const [confirmPopupData, setConfirmPopupData] = React.useState({
    isConfirm: false,
    clickButtonName: "",
  });
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const errorMessageAlert = useAlert();
  const loginReducer = useLoginReducer();
  const caseDetailReducer = useCaseDetailReducer();

  const [signatureCanvasVisible , setSignatureCanvasVisible ] = React.useState(false);
  const [btnSaveVisible , setBtnSaveVisible ] = React.useState(false);
  const [btnSaveIncompleteVisible , setBtnSaveIncompleteVisible ] = React.useState(false);
  const [btnSignVisible , setBtnSignVisible ] = React.useState(false);
  const [btnRevertVisible , setBtnRevertVisible ] = React.useState(false);
  const [btnPreviewVisible , setBtnPreviewVisible ] = React.useState(false);
  const [btnRevertLabel, setBtnRevertLabel] = React.useState("Return to Officer");
  const [btnSubmitVisible,setBtnSubmitVisible] = React.useState(false);

  const [signConfirmationModalShow , setsignConfirmationModalShow ] = React.useState(false)

  const [userNamevalue, setUserNamevalue] = React.useState("");
  // const [uploadPDfsText, setUploadPDfsText] = React.useState("");
  // const [uploadPDfsVisible, setUploadPDfsVisible] = React.useState(false);
  // const [deletePdfVisible, setDeletePdfVisible] = React.useState(false);
  // const [imageAttachTitleVisible, setImageAttachTitleVisible] = React.useState(false);
  // const [uploadedImagesVisible, setUploadedImagesVisible] = React.useState(false);
  // const [linkToHelpDocVisible, setLinkToHelpDocVisible] = React.useState(false);
  const [lblImpoundUnImpoundCase, setLblImpoundUnImpoundCase] = React.useState("");
  const [unImpoundCaseFlag, setUnImpoundCaseFlag] = React.useState(false);
  const [isUnImpoundCaseVisible, setIsUnImpoundCaseVisible] = React.useState(false);
  const [radioYes,setRadioYes] = React.useState(false);
  const [radioNo,setRadioNo] = React.useState(false);
  const [remarkModalShow, setRemarkModalShow] = React.useState(false);
  const [remarkRequired, setRemarkRequired] = React.useState(false);
  const [remarkFor, setRemarkFor] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [action, setAction] = React.useState("");
  const [statusValue, setStatusValue] = React.useState("");
  const [complainantNameFooter, setComplainantNameFooter] = React.useState(""); 
  const [whoSign, setWhoSign] = React.useState("");
  const [swornText, setSwornText] = React.useState("");

  const [showFormFiller, setShowFormFiller] = React.useState(false);
  const [fieldTitleValues, setFieldTitleValues] = React.useState<String[]>([]);
  const dashboardReducer = useDashboardReducer();
  
  let isLoaded = false;
  const [loadUserSignature, setLoadUserSignature] = React.useState(false);

  useEffect(() => {
    if (loginReducer.userType === ROLE_JUDGE && amendInventoryReducer.amendInventoryObject && !amendInventoryReducer.amendInventoryObject.hiddenJudgeSignature) {
      setLoadUserSignature(true);
    } else if (amendInventoryReducer.amendInventoryObject && !amendInventoryReducer.amendInventoryObject.hiddenComplaintSignature) {
      setLoadUserSignature(true);
    }

  }, [])

  const acceptSignConfirmModal = () => {
    setsignConfirmationModalShow(false);
    if(confirmPopupData.clickButtonName === "onSubmit"){
      submitInventory();
    }else{
      saveInventory();
    }
  }

  useEffect(() => {
    if (isLoaded === false) {
      isLoaded = true
      setSignatureCanvasVisible(true)
      if (amendInventoryReducer.amendInventoryObject.searchWarrantId > 0) {
        const {
          formStatus,
          creatorId,
          isOnBehalf,
          creatorUserType,
          ammendInventoryStatement,
          txtPresentingJudge,
          // uploadedFileName,
          isCaseImpounded
        } = amendInventoryReducer.amendInventoryObject;

        let loggedInUserId = loginReducer.userProfileId;
        let loggedInUserRole = loginReducer.userType;

        let userName = formatName(
          loginReducer.firstName,
          loginReducer.lastName,
          ""
        );

        setComplainantNameFooter(userName);
        setWhoSign("Complainant Signature");
        setSwornText("Officer Signature will be only applied after the content of this amended inventory is sworn before a judge");
        let ammendInventoryStatementNew = ammendInventoryStatement + " ";
        let oldTemplateText =
          ammendInventoryStatement === null
            ? "which directed the search of the person of and/or the place of and/or the item of "
            : "";
        let unImpoundCase = amendInventoryReducer.amendInventoryObject.unImpoundCase ? amendInventoryReducer.amendInventoryObject.unImpoundCase : "";
            if (typeof unImpoundCase !== 'undefined') {
              if (typeof isCaseImpounded !== 'undefined' && isCaseImpounded) {
                if (unImpoundCase) {
                  setRadioYes(true);
                  setRadioNo(false);
                } else {
                  setRadioYes(false);
                  setRadioNo(true);
                }
      
              } else {
                if (unImpoundCase) {
                  setRadioYes(false);
                  setRadioNo(true);
                } else {
                  setRadioYes(true);
                  setRadioNo(false);
                }
              }
            } else {
              setRadioYes(false);
              setRadioNo(true);
            }

        if (typeof isCaseImpounded !== 'undefined' && isCaseImpounded) {
          setLblImpoundUnImpoundCase("Case is currently impounded. Do you want to UN-impound it?");
        } else if (typeof isCaseImpounded !== 'undefined' && !isCaseImpounded) {
          setLblImpoundUnImpoundCase("Case is currently UN-impounded. Do you want to impound it?");
        }

        if (!isOnBehalf) {
          if (creatorUserType === ROLE_LOCAL_PROSECUTOR) {
            setBtnRevertLabel("RETURN TO LP");
          } else if (creatorUserType === ROLE_ATTORNEY_GENERAL) {
            setBtnRevertLabel("RETURN TO AG");
          } else if (creatorUserType === ROLE_STATES_ATTORNEY) {
            setBtnRevertLabel("RETURN TO SA");
          }
        }

        if (
          ROLE_ADMINISTRATOR === loggedInUserRole ||
          ROLE_COUNTY_ADMIN === loggedInUserRole
        ) {
          if (
            "" === formStatus ||
            formStatus === SEARCH_WAR_INCOMPLETE_STATUS ||
            formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS
          ) {
            setSignatureCanvasVisible(true);
            if (userName && userName.trim().length > 0) {
              setComplainantNameFooter(userName);
            }
            setBtnSaveVisible(true);
            setBtnSaveIncompleteVisible(true);
            setBtnSubmitVisible(true);
          }

          if (
            formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS ||
            formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS
          ) {
            setSignatureCanvasVisible(true);
            setWhoSign("JUDGE SIGNATURE");
            if (userName && userName.trim().length > 0) {
              setComplainantNameFooter(userName);
            }

            setIsUnImpoundCaseVisible(true);
            setBtnSignVisible(true);
            setBtnPreviewVisible(true);
            setBtnRevertVisible(true);
          }
        } else {
          if (creatorId === loggedInUserId) {
            if (
              loggedInUserRole === ROLE_POLICE_OFFICER ||
              loggedInUserRole === ROLE_STATES_ATTORNEY ||
              loggedInUserRole === ROLE_AGENCY_ADMIN ||
              loggedInUserRole === ROLE_ATTORNEY_GENERAL ||
              loggedInUserRole === ROLE_LOCAL_PROSECUTOR
            ) {
              setSignatureCanvasVisible(true);
              if (userName && userName.trim().length > 0) {
                setComplainantNameFooter(userName);
              }
            }
          }

          if (
            loggedInUserRole === ROLE_POLICE_OFFICER ||
            loggedInUserRole === ROLE_STATES_ATTORNEY ||
            loggedInUserRole === ROLE_AGENCY_ADMIN ||
            loggedInUserRole === ROLE_ATTORNEY_GENERAL ||
            loggedInUserRole === ROLE_LOCAL_PROSECUTOR
          ) {
            setBtnSaveVisible(true);
            setBtnSaveIncompleteVisible(true);
            setBtnSubmitVisible(true);

            // setUploadPDfsText(
            //   "Uploaded document must be a pdf file and will be appended to the inventory."
            // );
            // setUploadPDfsVisible(true);
            // setImageAttachTitleVisible(true);
            // setUploadedImagesVisible(true);
            // setLinkToHelpDocVisible(true);
            // if (uploadedFileName && uploadedFileName.trim().length > 0) {
            //   setDeletePdfVisible(true);
            // }
          }

          if (loggedInUserRole === ROLE_JUDGE) {
            setSignatureCanvasVisible(true);
            setWhoSign("JUDGE SIGNATURE");
            if (userName && userName.trim().length > 0) {
              setComplainantNameFooter(userName);
            }

            setIsUnImpoundCaseVisible(true);
            setBtnSignVisible(true);
            setBtnPreviewVisible(true);
            setBtnRevertVisible(true);

            // if (uploadedFileName && uploadedFileName.trim().length > 0) {
            //   setUploadPDfsVisible(true);
            //   setUploadPDfsText("Uploaded Images");
            // }
          }
        }
      }
    }
  }, [amendInventoryReducer.amendInventoryObject.formStatus,isLoaded]);

  const previewForm = () => {
    const amendInvObj = { ...amendInventoryReducer.amendInventoryObject };
    if (ROLE_JUDGE === loginReducer.userType) {
      dispatch(updateElementObjectValue({ elementName: "hiddenJudgeSignature", value: signPanelReducer.sign }));
      amendInvObj.hiddenJudgeSignature = signPanelReducer.sign;
    } else {
      dispatch(updateElementObjectValue({ elementName: "hiddenComplaintSignature", value: signPanelReducer.sign }));
      amendInvObj.hiddenComplaintSignature = signPanelReducer.sign;
    }

    
    dispatch( previewDraftingOrder({
      formId:
        amendInventoryReducer.amendInventoryObject.searchWarrantId,
      formName: AMENDED_INVENTORY,
      formObject: amendInvObj,
    }));
  }


  const handleChangeObjectValueForCkEditor = (name: string, editor: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: name,
        value: editor.getData(),
      })
    );
  };

  const saveIncomplete = () =>{
    const { userProfileId, userType } = loginReducer;
    const { formStatus, creatorId, creatorUserType } = amendInventoryReducer.amendInventoryObject;
    if (amendInventoryReducer.amendInventoryObject.searchWarrantId > 0) {
      let confirmMsg = Util.getConfirmMessage(SEARCH_WAR_INCOMPLETE_STATUS, AMENDED_INVENTORY,
        userProfileId, creatorId, formStatus, userType, creatorUserType, 0);

        setConfirmPopupData({
          isConfirm: true,
          clickButtonName: "onSaveIncomplete",
        });
        setconfirmText(confirmMsg);
    }
  }

  const saveClick = async () => {

    setConfirmPopupData({
      isConfirm: false,
      clickButtonName: "onSave",
    });

    try {
      await defaultSwSchema.validate(amendInventoryReducer.amendInventoryObject, {
        abortEarly: false,
      });
      let isValidateSignPixel = validateSignPixel(
        signPanelReducer.signThreshold
      );
      if (!isValidateSignPixel) {
        setsignConfirmationModalShow(true);
      }else{
        saveInventory();
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const saveInventory = () => {
    const { userProfileId, userType } = loginReducer;
    const { formStatus, creatorId, creatorUserType } = amendInventoryReducer.amendInventoryObject;
    
    if (amendInventoryReducer.amendInventoryObject.searchWarrantId > 0) {
      let confirmMsg = Util.getConfirmMessage(ACTIONTYPE_SAVE, AMENDED_INVENTORY,
          userProfileId, creatorId, formStatus, userType, creatorUserType, 0);

      setConfirmPopupData({
        isConfirm: true,
        clickButtonName: "onSave",
      });
      setconfirmText(confirmMsg);
    }
  };


  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const handleConfirmation = () => {
    if (confirmPopupData.clickButtonName === "onSaveIncomplete") {
      continueOnSaveIncompleteProcess();
    } else if (confirmPopupData.clickButtonName === "onSave") {
      continueOnSaveProcess();
    }else if (confirmPopupData.clickButtonName === "onReturn") {
      continueOnReturnProcess();
    }else if (confirmPopupData.clickButtonName === "onSign") {
      continueOnSignProcess();
    }else if (confirmPopupData.clickButtonName === "onSubmit") {
      continueOnSubmitProcess();
    }
  };

  const continueOnReturnProcess = () =>{
    let amendInventoryFormObj = {...amendInventoryReducer.amendInventoryObject};
     if (signatureCanvasVisible) {
        if (ROLE_JUDGE === loginReducer.userType) {
          dispatch(updateElementObjectValue({elementName:"hiddenJudgeSignature", value:signPanelReducer.sign}));
          amendInventoryFormObj.hiddenJudgeSignature = signPanelReducer.sign; 
        } else {
          dispatch(updateElementObjectValue({elementName:"hiddenComplaintSignature", value:signPanelReducer.sign}));
          amendInventoryFormObj.hiddenComplaintSignature = signPanelReducer.sign;
        }
    }

    amendInventoryFormObj.formStatus = statusValue;
    amendInventoryFormObj.remark = remark;
    amendInventoryFormObj.unImpoundCase = unImpoundCaseFlag;
    amendInventoryFormObj.updateImpounded = isUnImpoundCaseVisible;

    dispatch(returnAmendInventory({
      searchWarrantId : amendInventoryReducer.amendInventoryObject.searchWarrantId,
      proxy : amendInventoryFormObj,
      canAccessApplicationValue: loginReducer.summonsToAppear,
      previousUrl: dashboardReducer.PreviousURLSW,
      navigate : navigate
    }));
  }

  const continueOnSaveProcess = () =>{
    const amendInventoryObj = { ...amendInventoryReducer.amendInventoryObject };
    // const {autoCompleteInventory} = caseDetailReducer;

    if (ROLE_JUDGE === loginReducer.userType) {
      dispatch(
        updateElementObjectValue({
          elementName: "hiddenComplaintSignature",
          value: signPanelReducer.sign,
        })
      );
      amendInventoryObj.hiddenJudgeSignature = signPanelReducer.sign;
    } else {
      dispatch(
        updateElementObjectValue({
          elementName: "hiddenComplaintSignature",
          value: signPanelReducer.sign,
        })
      );
      amendInventoryObj.hiddenComplaintSignature = signPanelReducer.sign;
    }

    let status = SEARCH_WAR_OFFICER_PENDING_STATUS;
    if (
      ROLE_POLICE_OFFICER === loginReducer.userType ||
      ROLE_AGENCY_ADMIN === loginReducer.userType
    ) {
      // if (autoCompleteInventory) {
      //   status = SEARCH_WAR_COMPLETE_STATUS;
      // }
    }
    if (ROLE_ATTORNEY_GENERAL === loginReducer.userType) {
      status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
    } else if (ROLE_LOCAL_PROSECUTOR === loginReducer.userType) {
      status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
    } else if (ROLE_STATES_ATTORNEY === loginReducer.userType) {
      status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
    }

    amendInventoryObj.formStatus = status;
    amendInventoryObj.unImpoundCase = unImpoundCaseFlag;
    amendInventoryObj.updateImpounded = isUnImpoundCaseVisible;
    
    dispatch(updateAmendInventory({searchWarrantId : amendInventoryReducer.amendInventoryObject.searchWarrantId,amendInventoryObject : amendInventoryObj,navigate :navigate, callfrom: "onSave"}));
  }

  const continueOnSaveIncompleteProcess = () =>{

    const amendInventoryObj = {...amendInventoryReducer.amendInventoryObject};
    if (signatureCanvasVisible) {
      if (ROLE_JUDGE === loginReducer.userType) {
        dispatch(updateElementObjectValue({elementName:"hiddenComplaintSignature", value:signPanelReducer.sign}));
        amendInventoryObj.hiddenJudgeSignature = signPanelReducer.sign;
      } else {
        dispatch(updateElementObjectValue({elementName:"hiddenComplaintSignature", value:signPanelReducer.sign}));
        amendInventoryObj.hiddenComplaintSignature = signPanelReducer.sign;
      }
    }

    amendInventoryObj.unImpoundCase = unImpoundCaseFlag;
    amendInventoryObj.updateImpounded = isUnImpoundCaseVisible;
    if (amendInventoryReducer.amendInventoryObject.searchWarrantId > 0) {
      amendInventoryObj.formStatus = SEARCH_WAR_INCOMPLETE_STATUS;
    }
    dispatch(updateAmendInventory({searchWarrantId : amendInventoryReducer.amendInventoryObject.searchWarrantId,amendInventoryObject : amendInventoryObj,navigate :navigate, callfrom: "onSaveIncomplete"}));
  }


  const handleChangeObjectValue = (event: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };
  const handleInputBlur = (event: any) => {
    let { name, value } = event.target;
    value = validateDate(value);
     dispatch(updateElementObjectValue({ elementName: name, value: value }));
  }

  const handleYesChange = (event: any) => {
    let { name, checked, value } = event.target;
    if (typeof amendInventoryReducer.amendInventoryObject.isCaseImpounded !== 'undefined') {
      if (amendInventoryReducer.amendInventoryObject.isCaseImpounded) {
        dispatch(updateElementObjectValue({
          elementName: name,
          value: checked,
        }))
        setUnImpoundCaseFlag(checked)

      } else if (!amendInventoryReducer.amendInventoryObject.isCaseImpounded) {
        dispatch(updateElementObjectValue({
          elementName: name,
          value: !checked,
        }))
        setUnImpoundCaseFlag(!checked)

      }
    } else {
      dispatch(updateElementObjectValue({
        elementName: name,
        value: false,
      }))
      setUnImpoundCaseFlag(false)

    }
    setRadioYes(true)
    setRadioNo(false)
  }

  const handleNoChange = (event: any) => {
    let { name, checked } = event.target;
    if (typeof amendInventoryReducer.amendInventoryObject.isCaseImpounded !== 'undefined') {
      if (amendInventoryReducer.amendInventoryObject.isCaseImpounded) {
        dispatch(updateElementObjectValue({
          elementName: name,
          value: !checked,
        }))
        setUnImpoundCaseFlag(!checked)
      } else if (!amendInventoryReducer.amendInventoryObject.isCaseImpounded) {
        dispatch(updateElementObjectValue({
          elementName: name,
          value: checked,
        }))
        setUnImpoundCaseFlag(checked)
      }
    } else {
      dispatch(updateElementObjectValue({
        elementName: name,
        value: false,
      }))
      setUnImpoundCaseFlag(false)

    }
    setRadioNo(true)
    setRadioYes(false)

  }

  const returnClick = (actionName : string) =>{
    setAction(actionName);
    setRemarkRequired(true);
    setRemarkFor("RETURN");
    setRemarkModalShow(true);
  }

  const submitRemark = (remark: string, remarkFor: string) => {
    if ("RETURN" === remarkFor) {
      returnForm(remark);
    }
  }

  const returnForm = (remark : string) => {
    const { userProfileId, userType } = loginReducer;
    let { formStatus, creatorId, isOnBehalf, creatorUserType } = amendInventoryReducer.amendInventoryObject;

    let status = SEARCH_WAR_OFFICER_PENDING_STATUS;
    if (!isOnBehalf) {
        if (ROLE_ATTORNEY_GENERAL === creatorUserType) {
            status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
        } else if (ROLE_LOCAL_PROSECUTOR === creatorUserType) {
            status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
        } else if (ROLE_STATES_ATTORNEY === creatorUserType) {
            status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
        }
    }

    setStatusValue(status);

    let confirmMsg = Util.getConfirmMessage(status, AMENDED_INVENTORY,
      userProfileId, creatorId, formStatus, userType, creatorUserType, 0);
      setRemark(remark);
      setconfirmText(confirmMsg);
      setConfirmPopupData({ isConfirm: true, clickButtonName: "onReturn" });
  }

  const signClick = () =>{

    let isValidateSignPixel = validateSignaturePixel(signatureCanvasVisible, signPanelReducer.signThreshold);

    if(!isValidateSignPixel){
      setsignConfirmationModalShow(true);
    }else{
      signAmendInventoryProcess();
    }
  }

  const signAmendInventoryProcess = () =>{
    let confirmMsg = "Form cannot be changed once signed. Do you want to continue?";
    let inventoryReturnType = caseDetailReducer.county.inventoryReturnType;
    if (!inventoryReturnType || INVENTORY_RETURN_TYPE_VIDEO === inventoryReturnType) {
      confirmMsg = "Has the officer sworn to the content of this Search Warrant Inventory?";
    }
    
    setConfirmPopupData({
      isConfirm: true,
      clickButtonName: "onSign",
    });
    setconfirmText(confirmMsg);
  }

  const continueOnSignProcess = () => {
    let amendInventoryFormObj = {...amendInventoryReducer.amendInventoryObject};

    if (signatureCanvasVisible) {
      if (ROLE_JUDGE === loginReducer.userType) {
        dispatch(updateElementObjectValue({elementName:"hiddenJudgeSignature", value:signPanelReducer.sign}));
        amendInventoryFormObj.hiddenJudgeSignature = signPanelReducer.sign; 
      } else {
        dispatch(updateElementObjectValue({elementName:"hiddenComplaintSignature", value:signPanelReducer.sign}));
        amendInventoryFormObj.hiddenComplaintSignature = signPanelReducer.sign;
      }
    }

    amendInventoryFormObj.formStatus = SEARCH_WAR_COMPLETE_STATUS;
    amendInventoryFormObj.unImpoundCase = unImpoundCaseFlag;
    amendInventoryFormObj.updateImpounded = isUnImpoundCaseVisible;      

    dispatch(signAmendInventory({
    searchWarrantId: amendInventoryReducer.amendInventoryObject.searchWarrantId,
    amendInventoryObject: amendInventoryFormObj, 
    navigate: navigate,
    navigateTo : "/CaseDetails"
    }));
  }
  
  const saveSubmit= async () => {
    setConfirmPopupData({
      isConfirm: false,
      clickButtonName: "onSubmit",
    });

    try {
      await defaultSwSchema.validate(amendInventoryReducer.amendInventoryObject, {
        abortEarly: false,
      });
      let isValidateSignPixel = validateSignPixel(
        signPanelReducer.signThreshold
      );
      if (!isValidateSignPixel) {
        setsignConfirmationModalShow(true);
      }else{
        submitInventory();
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }

  const submitInventory = ()=>{
    if (signatureCanvasVisible) {
      const amendInventoryObj = { ...amendInventoryReducer.amendInventoryObject };
      if (ROLE_JUDGE === loginReducer.userType) {
        dispatch(
          updateElementObjectValue({
            elementName: "hiddenComplaintSignature",
            value: signPanelReducer.sign,
          })
        );
        amendInventoryObj.hiddenJudgeSignature = signPanelReducer.sign;
      } else {
        dispatch(
          updateElementObjectValue({
            elementName: "hiddenComplaintSignature",
            value: signPanelReducer.sign,
          })
        );
        amendInventoryObj.hiddenComplaintSignature = signPanelReducer.sign;
      }
    }

    setconfirmText("Do you want to submit the form for review to judge?");
    setConfirmPopupData({
      isConfirm: true,
      clickButtonName: "onSubmit",
    });
  }

  const continueOnSubmitProcess = () =>{
    const amendInventoryObj = { ...amendInventoryReducer.amendInventoryObject, formStatus : SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS  };
    
    dispatch(
      updateAmendInventory({
        searchWarrantId:
        amendInventoryObj.searchWarrantId,
        amendInventoryObject: amendInventoryObj,
        navigate: navigate,
        callfrom: "onSubmit",
      })
    );
  }

  const  onBreadcrumbClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }
  
  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/"+dashboardReducer.PreviousURLSW.replace(/^.*\/\/.*?\//, ''), itemName: "Warrant List", itemFunction: onBreadcrumbClick  },
    { itemLink: "/CaseDetails", itemName: "Existing Forms" },
  ];

  const formFillerClick = () =>{
    let array = [
      amendInventoryReducer.amendInventoryObject.txtPersonPremises, amendInventoryReducer.amendInventoryObject.txtSeized1, amendInventoryReducer.amendInventoryObject.txtSeized2,
    ];
    setShowFormFiller(true);
    setFieldTitleValues(array);
  }

  const updateFieldFromWizard = (name: string, value: string) =>{
    dispatch(updateElementObjectValue({elementName:name, value: value}));
  }
 

  const data = [
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: amendInventoryReducer.amendInventoryObject.lblHeadingStateSW,
        className: "fw-bold text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h5",
        value: (
          <>
            UNITED STATES OF AMERICA
            <br />
            {
              amendInventoryReducer.amendInventoryObject
                .lblHeadingInTheCircuitSW
            }
          </>
        ),
        className: "fw-bold text-center",
      },
      colSize: 6,
    },
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: amendInventoryReducer.amendInventoryObject.lblHeadingCountySW,
        className: "fw-bold text-end text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "agencyName",
        value: amendInventoryReducer.amendInventoryObject.txtAgency,
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h4",
        value: "AMENDED INVENTORY",
        className: "fw-bold text-center text-danger",
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "txtCaseNumber",
        value: amendInventoryReducer.amendInventoryObject.txtCaseNumber,
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
    },
    {
      type: "customComponents",
      props: {},
      colSize: 12,
      emptyCol: 10,
    },
    {
      type: 'formButton',
      props: {
        children: "Form Filler Wizard",
        onClick: formFillerClick,
      },
      inLine: true,
      colSize: 2,
    },
    {
      type: "editor",
      props: {
        data: amendInventoryReducer.amendInventoryObject.txtPersonPremises
          ? amendInventoryReducer.amendInventoryObject.txtPersonPremises
          : "",
        onChange: (event: any, editor: any) => {
          handleChangeObjectValueForCkEditor("txtPersonPremises", editor);
        },
        isError: errorKeys.includes("txtPersonPremises"),
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: <label>and the seizure of:</label>,
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: amendInventoryReducer.amendInventoryObject.txtSeized1
          ? amendInventoryReducer.amendInventoryObject.txtSeized1
          : "",
        onChange: (event: any, editor: any) => {
          handleChangeObjectValueForCkEditor("txtSeized1", editor);
        },
        isError: errorKeys.includes("txtSeized1"),
      },
      colSize: 12,
    },
    {
      type: "textInput",
      props: {
        onChange: handleChangeObjectValue,
        placeholder: "Warrant test Inventory",
        value: amendInventoryReducer.amendInventoryObject.txtPersonPremiseslbl,
        name :"txtPersonPremiseslbl",
        isError: errorKeys.includes("txtPersonPremiseslbl"),
      },
      colSize: 6,
      label: "In executing said Warrant, I seized the following from",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        onChange: handleChangeObjectValue,
        value: amendInventoryReducer.amendInventoryObject.txtPresentingJudge || userNamevalue,
        isError: errorKeys.includes("txtPresentingJudge"),
        name : "txtPresentingJudge",
        disabled : true
      },
      colSize: 6,
      label: "described above and have  returned the same before Judge",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        onChange: handleChangeObjectValue,
        onBlur: handleInputBlur,
        name : "txtPresentingDate",
        value: amendInventoryReducer.amendInventoryObject.txtPresentingDate,
        isError: errorKeys.includes("txtPresentingDate"),
        type: "date",
      },
      colSize: 3,
      label: "on",
      inLine: true,
    },
    {
      type: "htmlTag",
      props: {
        value: <label>LIST SEIZED ITEMS HERE :</label>,
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: amendInventoryReducer.amendInventoryObject.txtSeized2
          ? amendInventoryReducer.amendInventoryObject.txtSeized2
          : "",
        onChange: (event: any, editor: any) => {
          handleChangeObjectValueForCkEditor("txtSeized2", editor);
        },
        isError: errorKeys.includes("txtSeized2"),
      },
      colSize: 12,
    },
    {
      type: "customComponentsRadio",
      props: {},
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label className="mt-4">
            Under penalties as provided by law pursuant to Section 1-109 of the
            Code of Civil Procedure, the undersigned certifies that the
            statements set forth in this instrument are true and correct, except
            as to matters therein stated to be on information and belief and as
            to such matters the undersigned certifies as aforesaid that he
            verily believes the same to be true.
          </label>
        ),
      },
      colSize: 12,
    },
    /* not implmented in GWT search warrant */
    // {
    //   type: "fileUploader",
    //   props: {
    //     className: signatureCanvasVisible ? "" : "d-none",
    //     fileName:amendInventoryReducer.amendInventoryObject.uploadedFileName,
    //     formType: AMENDED_INVENTORY,
    //   },
    //   colSize: 12,
    // },
    {
      type: "signPanel",
      props: {
        className: signatureCanvasVisible ? "d-block" : "d-none",
        name: "sign",
        appearPersonText: complainantNameFooter,
        whoSign: whoSign,
        swText: swornText,
        isError: errorKeys.includes("sign"),
      },
      colSize: 12,
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: `btn-primary ms-2 ${btnSaveVisible?"d-block":"d-none"}`,
        children: (
          <>
            Save <RiSave3Line />
          </>
        ),
        onClick: () => {
          saveClick();
        },
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: `btn-parimary ms-2 ${btnSaveIncompleteVisible?"d-block":"d-none"}`,
        children: (
          <>
            Save Incomplete <RiSaveLine />
          </>
        ),
       onClick: () => {
          saveIncomplete();
        },
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: btnSubmitVisible ? "btn-success ms-2" : "d-none",
        children: (
          <>
            Submit To Judge <RiUserShared2Line />
          </>
        ),
        onClick: () => {
          saveSubmit();
        }
      },
    },
    
    {
      type: "button",
      colSize: 2,
      props: {
        variant:"success",
        className: `btn-parimary ms-2 ${btnSignVisible?"d-block":"d-none"}`,
        children: (
          <>
            Sign <RiBallPenLine />
          </>
        ),
       onClick: () => {
        signClick();
        },
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: `btn-danger ms-2 ${btnRevertVisible?"d-block":"d-none"}`,
        children: (
          <>
            {btnRevertLabel} <RiArrowGoBackLine />
          </>
        ),
       onClick: () => returnClick(btnRevertLabel),
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn ms-2",
        children: (
          <>
            Preview <RiFileTextLine />
          </>
        ),
        onClick: () =>previewForm(),
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-danger ms-2",
        children: (
          <>
            Cancel <RiCloseLine />
          </>
        ),
        onClick: () => {
          dispatch(
            getSWCaseDetail({
              searchWarrantId: caseDetailReducer.searchWarrantId,
              action: "cancelForm",
              navigate: navigate,
            })
          );
        },
      },
    },
  ];

  return (
    <>
      <Header headerName="Amend Inventory" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Amend Inventory"
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data}
          components={[
            {
              type: "customComponents",
              node: () => (
                <>
                  <Row>
                    <Col xs={"auto"}>
                      <span className="me-3"> On </span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          value={
                            amendInventoryReducer.amendInventoryObject
                              .txtExecutionDate
                          }
                          disabled
                        />
                      </div>
                      <span className="me-3">at</span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          value={
                            moment(amendInventoryReducer.amendInventoryObject
                              .txtExecutionTime, ["HH:mm"]).format("hh:mm A")
                          }
                          disabled
                        />
                      </div>
                      <span className="me-3">(HH:mm AM/PM), I,</span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          value={
                            amendInventoryReducer.amendInventoryObject
                              .txtComplainantName
                          }
                          disabled
                        />
                      </div>
                      <span className="me-3">
                        the undersigned Officer/Employee, executed a Search Warrant,
                        bearing the above number and signed by Judge
                      </span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          value={
                            amendInventoryReducer.amendInventoryObject
                              .txtJudgeName
                          }
                          disabled
                        />
                      </div>
                      <span className="me-3"> on </span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          value={
                            amendInventoryReducer.amendInventoryObject
                              .txtJudgeIssuingDate
                          }
                          disabled
                        />
                      </div>
                      <span className="me-3"> at</span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          value={
                            amendInventoryReducer.amendInventoryObject
                              .txtJudgeIssuingTime
                          }
                          disabled
                        />
                      </div>
                      <span className="me-3">
                        (HH:mm AM/PM){ amendInventoryReducer.amendInventoryObject.ammendInventoryStatement }
                      </span>
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              type: "customComponentsRadio",
              node: () => (
                <>
                  {!(radioYes && isUnImpoundCaseVisible) ?
                    <Row className="mt-3 mb-3 align-items-center no-gutters">
                      <Col xs={12} className="">
                        The impound order previously entered concerning the above captioned
                        search warrant number is hereby lifted by order of the court.
                      </Col>
                    </Row>
                    : ""}

                  {isUnImpoundCaseVisible ?
                    <Row className="align-items-center justify-content-start no-gutters">
                      <Col xs={12} className="text-start">
                        <label className="mb-0 pe-3">
                          <b>{lblImpoundUnImpoundCase}</b>
                        </label>
                        <Form.Check
                          inline
                          type="radio"
                          checked={radioYes}
                          name="unImpoundCase"
                          value={"Yes"}
                          id={"Yes"}
                          onChange={handleYesChange}
                        />
                        <label className="ms-n2 pe-4">Yes</label>
                        <Form.Check inline type="radio"
                          checked={radioNo}
                          name="unImpoundCase"
                          value={"No"}
                          id={"No"}
                          onChange={handleNoChange} />
                        <label className="ms-n2">No</label>
                      </Col>
                    </Row>
                    : ""}
                </>
              ),
            },
            {
              type: "formButton",
              node: (props: any) => (
                <div className="text-end">
                <ButtonComponent {...props}>{props.children}</ButtonComponent>
              </div>
                
              ),
            }
          ]}
        />
      </div>
      
      <RemarkPopup show={remarkModalShow} onHide={() => setRemarkModalShow(false)} 
        submitRemark={submitRemark} remarkFor={remarkFor}
        action={action} remarkRequired={remarkRequired}
        onlyView={false} remarksForView={""} /> 

      <ConfirmPopup
        children={confirmText}
        show={confirmPopupData.isConfirm}
        onHide={()=>setConfirmPopupData({ isConfirm: false, clickButtonName: "" })}
        isConfirmation={handleConfirmation}
      />

      <ConfirmPopup 
        show={signConfirmationModalShow}
        onHide={()=>setsignConfirmationModalShow(false)} 
        isConfirmation={acceptSignConfirmModal}>
        Are you sure the signature you provided is valid?
      </ConfirmPopup>

      <FormFiller
       show={showFormFiller}
       onHide = {() => setShowFormFiller(false)}
       fieldTitles={fieldTitles}
       names = {names}
       values = {fieldTitleValues}
       onChangeHandler={updateFieldFromWizard}
      />
    </>
  );
};
export default AmendInventory;
