import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import RouteContainer from "./components/common/RouteContainer";
import "./components/common/Common.scss";
import "./components/common/SideMenu.scss";
import store from "./redux/store";
import { Provider } from "react-redux";
import { handleUnloadMessage } from "./components/common/CommonUtil";
import { GlobalDebug } from "./utils/remove-console";
// import "./utils/InspectElement"; // For Inspect, ctrl+u resticted
function App() {
  useEffect(() => {
    window.addEventListener("beforeunload", handleUnloadMessage);
    return () => {
      window.removeEventListener("beforeunload", handleUnloadMessage);
    };
  }, []);

  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <RouteContainer />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
