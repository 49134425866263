import { Col, Row } from "react-bootstrap"

export type ToggleButtonProps = {
    label?: React.ReactNode;
    value: boolean;
    onChange: () => void;
} 

const ToggleButtonComponent: React.FC<ToggleButtonProps> = (props) => {
    const { label, value, onChange} = props;
    return (
        <>
            <Row style={{marginTop:"3px"}}>
                <Col xs={"auto"}>
                    <label className="switch">
                        <input type="checkbox" checked={value} onChange={onChange}></input>
                        <span className="slider round" ></span>
                    </label>
                </Col >
                <Col xs={"auto"}><label style={{marginTop:"8px"}}><b>{label}</b></label></Col>
            </Row>
        </>
    )
}

export default ToggleButtonComponent;