import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../common/Header";
import moment from "moment";
import ConfirmPopup from "../../components/common/ConfirmPopup";
import IncidentNumberConfirmPopup from "./IncidentNumberConfirmPopup";
import ButtonComponent from "../common/ButtonComponent";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import WarningMessageAlert, { useWarningAlert } from "../common/WarningAlert";
import { useNavigate } from "react-router-dom";
import {
  useComplaintReducer,
  updateElementValueComplaintReducer,
  updateElementObjectValueComplaint,
  getLoggedInUserCountiesForLFC,
  getLoggedInUserAgencies,
  getOfficerListForLoggedInUser,
  fetchAgencyListByLfcCounty,
  fetchOfficersByAgency,
  getCodeTableEntries,
  getCountryList,
  saveComplaint,
  resetComplaintData,
} from "../../redux/slice/complaintSlice";
import {
  CODE_TABLE_SUFFIX,
  CODE_TABLE_GENDER,
  CODE_TABLE_TSS_RACE,
  CODE_TABLE_EYECOLOR,
  CODE_TABLE_HAIRCOLOR,
  STATE_LIST,
  USE_OFFICERS_FROM_IUCS,
  MCHENRY_COUNTY,
  DEFAULT_STATE,
  CASEPROSECUTIONTYPELIST,
  LF_FILED_STATUS,
  LF_SEND_TO_JUDGE_STATUS,
  LF_STATUS_PENDING_SUPERVISOR_REVIEW,
  ROLE_POLICE_OFFICER,
  ROLE_STATES_ATTORNEY,
  LF_CASE_ASSIGNMENT_READY_STATUS,
  LF_ARRW_REJECTED_STATUS,
  LF_COMMUNI_FAILED_STATUS,
  LF_SEND_TO_SA_STATUS,
  LF_SEND_TO_OFFICER_STATUS,
  LF_PENDING_STATUS,
  REVISED_PROBABLE_CAUSE_STATEMENT,
  TYPE_OF_ARREST_ARRESTWARRANT,
  TYPE_OF_ARREST_ONVIEWARREST,
  TYPE_OF_ARREST_SUMMON,
  CREATE_LF_FORM_SUMMON,
} from "../../constants/common";
import {
  getIncidentNoByAgencyFormat,
  validateIncidentNoForAgency,
  merge,
  validateDLNumber,
  heightFormatValidation,
  formattedZipcode,
  validateDate,
  validateDateLong,
} from "../../utils/common";
import {
  isEmailValidation,
  formatPhoneNumber,
  zipCodeRegex,
  phoneNumberRegex,
} from "../common/CommonUtil";
import { useAppDispatch } from "../../hooks/hooks";
import * as yup from "yup";
import { getWebRmsList, useWebRmsReducer } from "../../redux/slice/webRmsSlice";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import CheckBoxComponent from "../common/CheckBoxComponent";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import OptionPopup from "../common/OptionsPopup";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import TextInputComponent from "../common/TextInputComponent";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { saveViewIntro } from "../../redux/slice/userProfileSlice";
import {
  useUserChangeProfileReducer,
  getUserDataByUsername,
} from "../../redux/slice/userChangeProfileSlice";
import { LookupWebRMSPopup } from "./LookupWebRMSPopup";
import {
  useDashboardReducer,
  updateElementValueDashboardReducer,
} from "../../redux/slice/dashboardSlice";
import { updateElementObjectValueArrestWarrantReducer } from "../../redux/slice/arrestWarrantSlice";

interface EComplaintCaseInfoPageProps {}

let deleteEcform: boolean = false;

const EComplaintCaseInfoPage: React.FC<EComplaintCaseInfoPageProps> = (
  props
) => {
  const intro = introJs();
  const dobLessThanOffenceDate: string =
    "'Date of Birth' must be less than offense date.";
  const dlNoStateRequired: string =
    "'DL Number' and 'DL State' both optional or both required.";

  let defaultSchemaError = yup.object().shape({
    onViewArrest_option: yup
      .string()
      .trim()
      .nullable()
      .test("check-postSafeTAct", function (value) {
        if (safeTactEnable) {
          if (
            complaintObject.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST &&
            !complaintObject.postSafeTAct
          ) {
            return this.createError({
              message: "'Pre-Trial Release Options' are required.",
            });
          } else {
            return true;
          }
        } else {
          return true;
        }
      }),
    countyName: yup.string().trim().required("'Filing County' is required."),
    onViewArrestOptionClickOnce: yup
      .boolean()
      .test("check-typeOfArrest", function (value) {
        if (
          complaintObject.ecomplaintId === 0 &&
          !complaintObject.onViewArrestOptionClickOnce
        ) {
          return this.createError({
            message: "'Type of Arrest' is required.",
          });
        } else {
          return true;
        }
      }),
    caseProsecutionType: yup
      .string()
      .trim()
      .required("'Prosecution Level' is required."),
    agencyDisplayName: yup
      .string()
      .trim()
      .required("'Arresting Agency' is required."),
    officerDisplayingName: yup
      .string()
      .trim()
      .required("'Officer' is required."),
    primaryAgencyRepNumber: yup
      .string()
      .trim()
      .required("'Incident Number' is required."),
    defDob: yup
      .string()
      .trim()
      .required("'Date of Birth' is required.")
      .test("check-defDob-future", function (value) {
        let dateOfBirth: any = undefined;
        if (complaintObject.defDob) {
          dateOfBirth = moment(complaintObject.defDob, "YYYY-MM-DD");
        }
        let today: any = new Date();
        today.setHours(0, 0, 0, 0);
        if (dateOfBirth && dateOfBirth > today.getTime()) {
          return this.createError({
            message: "Future dates are not allow for Date of Birth",
          });
        } else {
          return true;
        }
      }),
    firstIncidentDateTime: yup
      .string()
      .nullable()
      .trim()
      .required("'Arrest Date or Date of first Incident' is required.")
      .test("check-with-defDob", function (value) {
        let today: any = new Date();
        today.setHours(0, 0, 0, 0);
        let dateOfBirth: any = undefined;
        if (complaintObject.defDob) {
          dateOfBirth = moment(complaintObject.defDob, "YYYY-MM-DD");
        }
        let fIncDT: any = undefined;
        if (complaintObject.firstIncidentDateTime) {
          fIncDT = moment(complaintObject.firstIncidentDateTime, "YYYY-MM-DD");
        }
        if (fIncDT && fIncDT > today.getTime()) {
          return this.createError({
            message: "Future dates are not allow for first Incident date.",
          });
        } else if (dateOfBirth && fIncDT && dateOfBirth > fIncDT) {
          return this.createError({
            message: dobLessThanOffenceDate,
          });
        } else {
          return true;
        }
      }),

    defFirstName: yup
      .string()
      .trim()
      .required("'Defendant First Name' is required.")
      .nullable()
      .test("check-with-defFirstName", function (value) {
        if (value) {
          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message:
                "Non printable text/characters( " +
                cleanedText +
                " ) have been pasted or added into the field Defendant First Name. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }
        }
        return true;
      }),
    defLastName: yup
      .string()
      .trim()
      .required("'Defendant Last Name' is required.")
      .nullable()
      .test("check-with-defLastName", function (value) {
        if (value) {
          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message:
                "Non printable text/characters( " +
                cleanedText +
                " ) have been pasted or added into the field Defendant Last Name. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }
        }
        return true;
      }),
    defMiddleName: yup
      .string()
      .trim()
      .nullable()
      .test("check-with-defMiddleName", function (value) {
        if (value) {
          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message:
                "Non printable text/characters( " +
                cleanedText +
                " ) have been pasted or added into the field Defendant Middle Name. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }
        }
        return true;
      }),
    defGender: yup.string().trim().required("'Gender' is required.").nullable(),
    defRace: yup.string().trim().required("'Race' is required.").nullable(),
    defHeight: yup
      .string()
      .trim()
      .nullable()
      .test("check-Height-format", function (value) {
        if (
          complaintObject.defHeight &&
          heightFormatValidation(complaintObject.defHeight)
        ) {
          return this.createError({
            message:
              "The value entered in the 'Height' field is invalid. Enter height as numbers only (Example: 508).",
          });
        } else {
          return true;
        }
      }),
    defDlState: yup
      .string()
      .trim()
      .nullable()
      .test("check-DlState-required", function (value) {
        if (
          (complaintObject.defDriverlicense && !complaintObject.defDlState) ||
          (!complaintObject.defDriverlicense && complaintObject.defDlState)
        ) {
          return this.createError({
            message: dlNoStateRequired,
          });
        } else {
          return true;
        }
      }),
    defEmail: yup
      .string()
      .trim()
      .nullable()
      .test("check-email", function (value) {
        if (value) {
          var cleanedText = displayNonAsciiValues(value);

          if (cleanedText) {
            return this.createError({
              message:
                "Non printable text/characters( " +
                cleanedText +
                " ) have been pasted or added into the field email. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }
        }
        if (
          complaintObject.defEmail &&
          !isEmailValidation(complaintObject.defEmail)
        ) {
          return this.createError({
            message: "Invalid 'Email'.",
          });
        } else {
          return true;
        }
      }),
    defPhoneNumber1: yup
      .string()
      .trim()
      .nullable()
      .test("check-PhoneNumber", function (value) {
        let pho: string = complaintObject.defPhoneNumber1
          ? complaintObject.defPhoneNumber1
          : "";
        const regex = new RegExp(phoneNumberRegex);
        if (value) {
          var cleanedText = displayNonAsciiValues(value);

          if (cleanedText) {
            return this.createError({
              message:
                "Non printable text/characters( " +
                cleanedText +
                " ) have been pasted or added into the field Phone Number. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }
        }
        if (pho && !regex.test(pho)) {
          return this.createError({
            message: "Invalid 'Phone'.",
          });
        } else {
          return true;
        }
      }),
    defDriverlicense: yup
      .string()
      .trim()
      .nullable()
      .test("check-with-defDriverlicense", function (value) {
        if (value) {
          var cleanedText = displayNonAsciiValues(value);

          if (cleanedText) {
            return this.createError({
              message:
                "Non printable text/characters( " +
                cleanedText +
                " ) have been pasted or added into the field DL Number. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }
        }
        return true;
      }),
    dcn: yup
      .string()
      .trim()
      .nullable()
      .test("check-with-dcn", function (value) {
        if (value) {
          var cleanedText = displayNonAsciiValues(value);

          if (cleanedText) {
            return this.createError({
              message:
                "Non printable text/characters( " +
                cleanedText +
                " ) have been pasted or added into the field Document Control Number. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }
        }
        return true;
      }),
    defSID: yup
      .string()
      .trim()
      .nullable()
      .test("check-with-SID", function (value) {
        if (value) {
          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message:
                "Non printable text/characters( " +
                cleanedText +
                " ) have been pasted or added into the field SID. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }
        }
        return true;
      }),
    defFBI: yup
      .string()
      .trim()
      .nullable()
      .test("check-with-SID", function (value) {
        if (value) {
          var cleanedText = displayNonAsciiValues(value);
          if (cleanedText) {
            return this.createError({
              message:
                "Non printable text/characters( " +
                cleanedText +
                " ) have been pasted or added into the field FBI. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
            });
          }
        }
        return true;
      }),
  });

  let defaultSchemaWarning = yup.object().shape({
    primaryAgencyRepNumber: yup
      .string()
      .test("check-incident-number", function (value) {
        if (
          !warningFields.includes("primaryAgencyRepNumber") &&
          !validateIncidentNoForAgency(
            incidentNoFormat,
            complaintObject.primaryAgencyRepNumber
          )
        ) {
          return this.createError({
            message:
              "Incident Number format failed validation. Please confirm Incident Number.",
          });
        } else {
          return true;
        }
      }),
    defDob: yup.string().test("check-date-of-birth", function (value) {
      let dateOfBirth: any = undefined;
      if (complaintObject.defDob) {
        dateOfBirth = moment(complaintObject.defDob, "MM/dd/yyyy");
        var m = moment(dateOfBirth, "MM/dd/yyyy");
        var years = moment().diff(m, "years", false);
        var days = moment().diff(m.add(years, "years"), "days", false);

        if (!warningFields.includes("defDob") && years < 18) {
          return this.createError({
            message:
              "Defendant age under 18 years old. Please check the date of birth.",
          });
        }

        if (!warningFields.includes("defDob") && years > 120) {
          return this.createError({
            message:
              "Defendant age over 120 years old. Please check the date of birth.",
          });
        }
      }
      return true;
    }),
    defDriverlicense: yup
      .string()
      .nullable()
      .test("check-driving-license-number", function (value) {
        if (
          !warningFields.includes("defDriverlicense") &&
          complaintObject.defDlState === DEFAULT_STATE &&
          !validateDLNumber(
            complaintObject.defDriverlicense,
            complaintObject.defFirstName,
            complaintObject.defMiddleName,
            complaintObject.defLastName,
            complaintObject.defDob,
            complaintObject.defGender
          )
        ) {
          return this.createError({
            message: "DL Number failed validation. Please confirm DL Number.",
          });
        } else {
          return true;
        }
      }),
  });

  const userChangeProfileReducer = useUserChangeProfileReducer();
  let {
    lfcCountyList,
    loggedInUserAgencyList,
    loggedInUserOfficersByCounty,
    agenciesForLfcCounty,
    policeOfficers,
    suffixList,
    genderList,
    raceList,
    hairList,
    eyesList,
    countries,
    returnToPage,
  } = useComplaintReducer();
  const { complaintObject, existingDataToCompare } = useComplaintReducer();
  const { witnessList } = useWebRmsReducer();
  const dispatch = useAppDispatch();
  const { userProfileId, userType, safeTactEnable, username } =
    useLoginReducer();
  const [incidentNoFormat, setIncidentNoFormat] = useState("");
  const [webRms, setWebRms] = useState(false);
  const [confirmDeleteDoc, setConfirmDeleteDoc] = useState(false);
  const [lookupWebRMS, setLookupWebRMS] = useState(false);
  const [confirmText, setConfirmText] = React.useState<React.ReactNode>();
  const [incidentNumberWarning, setIncidentNumberWarning] = useState("");
  const [showIncidentNumberConfirmPopup, setShowIncidentNumberConfirmPopup] =
    useState(false);
  const [changingFillingCounty, setChangingFillingCounty] = useState({
    show: false,
    name: "",
    value: [],
  });
  const { complaint, countList, ecFormList } = useComplaintMainReducer();
  const navigate = useNavigate();

  const [errorKeys, setErrorKeys] = useState([""]);
  const [errorMessages, setErrorMessages] = useState([""]);
  const errorMessageAlert = useAlert();

  const [warningKeys, setWarningKeys] = useState([""]);
  const warningMessageAlert = useWarningAlert();
  const [warningFields, setWarningFields] = useState([""]);
  const [btnSaveNewComplaint, setBtnSaveNewComplaint] = useState(false);
  const [defDOB, setDefDOB] = useState(false);
  const [dateOfFirstInc, setDateOfFirstInc] = useState(false);
  const [comboFilingCounty, setComboFilingCounty] = useState(false);
  const [comboProSeLevel, setComboProSeLevel] = useState(false);
  const [comboMunicipality, setComboMunicipality] = useState(false);
  const [comboGender, setComboGender] = useState(false);
  const [comboState, setComboState] = useState(false);
  const [txtState, setTxtState] = useState(false);
  const [comboCountry, setComboCountry] = useState(false);
  const [comboDLState, setComboDLState] = useState(false);
  const [txtAgencyReportNo, setTxtAgencyReportNo] = useState(false);
  const [txtDocControlNo, setTxtDocControlNo] = useState(false);
  const [txtDefFName, setTxtDefFName] = useState(false);
  const [txtDefMName, setTxtDefMName] = useState(false);
  const [txtDefLName, setTxtDefLName] = useState(false);
  const [comboDefSuffix, setComboDefSuffix] = useState(false);
  const [txtAddress1, setTxtAddress1] = useState(false);
  const [txtAddress2, setTxtAddress2] = useState(false);
  const [txtCity, setTxtCity] = useState(false);
  const [txtZipFa, setTxtZipFa] = useState(false);
  const [txtZipUa, setTxtZipUa] = useState(false);
  const [txtDefEmail, setTxtDefEmail] = useState(false);
  const [txtDLNo, setTxtDLNo] = useState(false);
  const [chkUnknownAddress, setChkUnknownAddress] = useState(false);
  const [chkForeignAddress, setChkForeignAddress] = useState(false);
  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      setErrorMessages(errorMessages);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const dashboardReducer = useDashboardReducer();
  const displayNonAsciiValues = (value: any) => {
    var asciiRegex = /[^\x00-\x7F]+/g;
    var cleanedText = value.match(asciiRegex);
    if (cleanedText) {
      return cleanedText;
    }
  };

  const handleWarning = useCallback(
    (_warningKeys: string[], warningMessages: string[]) => {
      setWarningKeys(_warningKeys);
      warningMessageAlert.handleWarning(warningMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  let ecCountyList = lfcCountyList.filter((county: any) => {
    if (county.countyID !== 0) {
      return county;
    }
  });

  const lfcCountyListOptions = [];
  if (ecCountyList.length !== 1) {
    lfcCountyListOptions.push({ label: "-- Select --", value: undefined });
  }
  lfcCountyListOptions.push(
    ...ecCountyList.map((county: any) => {
      return { label: county.countyName, value: county };
    })
  );

  let agenciesForLfcCountyOptions: any = [];
  if (agenciesForLfcCounty.length !== 1) {
    agenciesForLfcCountyOptions.push({
      label: "-- Select --",
      value: undefined,
    });
  }
  agenciesForLfcCountyOptions.push(
    ...agenciesForLfcCounty.map((agency: any) => {
      return { label: agency.displayingName, value: agency };
    })
  );

  const policeOfficersOptions = [];
  if (policeOfficers.length !== 1) {
    policeOfficersOptions.push({ label: "-- Select --", value: undefined });
  }
  policeOfficersOptions.push(
    ...policeOfficers.map((officer: any) => {
      return { label: officer.displayingName, value: officer };
    })
  );

  if (complaintObject.defForeignAddress) {
    countries = countries.filter((cntry: any) => {
      if (complaintObject.defForeignAddress && cntry !== "UNITED STATES") {
        return cntry;
      }
    });
  }

  //mount
  useEffect(() => {
    dispatch(getCodeTableEntries({ tableName: CODE_TABLE_SUFFIX }));
    dispatch(getCodeTableEntries({ tableName: CODE_TABLE_GENDER }));
    dispatch(getCodeTableEntries({ tableName: CODE_TABLE_TSS_RACE }));
    dispatch(getCodeTableEntries({ tableName: CODE_TABLE_EYECOLOR }));
    dispatch(getCodeTableEntries({ tableName: CODE_TABLE_HAIRCOLOR }));
    dispatch(getCountryList());
    dispatch(getLoggedInUserCountiesForLFC());
    dispatch(getLoggedInUserAgencies());
    dispatch(getOfficerListForLoggedInUser());
    onMountComboVisible();
    handleEnableDisable(false);
  }, []);

  useEffect(() => {
    onMountComboVisible();
  }, [complaintObject]);

  //unmount
  useEffect(
    () => () => {
      dispatch(resetComplaintData());
    },
    []
  );

  useEffect(() => {
    if (lfcCountyList.length > 0) {
      if (complaintObject.ecomplaintId === 0) {
        dispatch(
          fetchAgencyListByLfcCounty({
            countyName: lfcCountyList[0].countyName,
            ecomplaintId: 0,
          })
        );
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "countyName",
            value: lfcCountyList[0].countyName,
          })
        );
      } else {
        dispatch(
          fetchAgencyListByLfcCounty({
            countyName: complaintObject.countyName,
            ecomplaintId: 0,
          })
        );
      }
    }
  }, [lfcCountyList]);

  useEffect(() => {
    if (loggedInUserAgencyList.length > 0 && lfcCountyList.length > 0) {
      if (complaintObject.ecomplaintId === 0) {
        dispatch(
          fetchOfficersByAgency({
            agencyCode: loggedInUserAgencyList[0].agencyCode,
            countyName: lfcCountyList[0].countyName,
          })
        );
        let agencyDisName =
          loggedInUserAgencyList[0].agencyCode +
          "-" +
          loggedInUserAgencyList[0].agencyName;
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "agencyDisplayName",
            value: agencyDisName,
          })
        );
      } else {
        dispatch(
          fetchOfficersByAgency({
            agencyCode: complaintObject.officerAgencyCode,
            countyName: complaintObject.countyName,
          })
        );
      }
    }
  }, [loggedInUserAgencyList, lfcCountyList]);

  useEffect(() => {
    if (complaintObject.ecomplaintId === 0 && loggedInUserOfficersByCounty) {
      if (complaintObject && complaintObject.countyName) {
        let countyName: any = complaintObject.countyName;
        let officer = loggedInUserOfficersByCounty[countyName];
        if (officer && officer.syncStatus !== "USE OFFICERS FROM IUCS") {
          let fn = officer.firstName ? officer.firstName.trim() : "";
          let ln = officer.lastName ? officer.lastName.trim() + " " : "";
          let badge = officer.badgeNumber ? "" + officer.badgeNumber : "";
          let officerVal = badge ? badge + "-" + ln + fn : "";
          dispatch(
            updateElementObjectValueComplaint({
              elementName: "officerDisplayingName",
              value: officerVal,
            })
          );
          dispatch(
            updateElementObjectValueComplaint({
              elementName: "officerFirstName",
              value: fn.trim(),
            })
          );
          dispatch(
            updateElementObjectValueComplaint({
              elementName: "officerLastName",
              value: ln.trim(),
            })
          );
          dispatch(
            updateElementObjectValueComplaint({
              elementName: "officerBadge",
              value: badge.trim(),
            })
          );
        }
      }
    }
  }, [loggedInUserOfficersByCounty, complaintObject.countyName]);

  useEffect(() => {
    if (
      agenciesForLfcCountyOptions &&
      agenciesForLfcCountyOptions.length > 0 &&
      complaintObject.agencyDisplayName
    ) {
      agenciesForLfcCountyOptions.find((item: any) => {
        if (
          item.label !== "-- Select --" &&
          item.label === complaintObject.agencyDisplayName &&
          item.value
        ) {
          dispatch(
            updateElementObjectValueComplaint({
              elementName: "officerAgencyName",
              value: item.value.agencyName,
            })
          );
          dispatch(
            updateElementObjectValueComplaint({
              elementName: "officerAgencyOri",
              value: item.value.agencyOri,
            })
          );
          dispatch(
            updateElementObjectValueComplaint({
              elementName: "officerAgencyCode",
              value: item.value.agencyCode,
            })
          );

          setIncidentNoFormat(item.value.incidentNoFormat);
          setWebRms(item.value.webRms);
        }
      });
    }
  }, [complaintObject.agencyDisplayName, agenciesForLfcCounty]);

  useEffect(() => {
    enableAddressFields(complaintObject.defAddressUnknown);
  }, [complaintObject.defAddressUnknown]);

  const handleLongFormListClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  };

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    {
      itemLink:
        "/" + dashboardReducer.PreviousURLLFC.replace(/^.*\/\/.*?\//, ""),
      itemName: "Long Form List",
      itemFunction: handleLongFormListClick,
    },
    { itemLink: "/EComplaintDashboard", itemName: "Existing Forms" },
  ];

  const handleInputChange = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementObjectValueComplaint({ elementName: name, value: value })
    );
  };
  const handleInputBlur = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementObjectValueComplaint({
        elementName: name,
        value: validateDate(value),
      })
    );
  };
  const nextRef = useRef(null);
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 9 && !e.shiftKey) {
      //when tab key is pressed
      handleInputBlur(e);
    }
  };

  const handleBithdayInputBlur = (event: any) => {
    let { name, value } = event.target;
    let returnDate = null;
    let formatedparsedDate = null;
    let dateFormats = ["MMDDYY", "MMDDYYYY", "MM/DD/YYYY", "MM/DD/YY"];
    let isValidDate = false;
    for (let format of dateFormats) {
      let parsedDate = moment(value, format, true);
      if (parsedDate.isValid()) {
        let parsedyear = parsedDate.year();
        let currentyear = moment().year();
        if (parsedyear > currentyear) {
          parsedDate = parsedDate.subtract(100, "year");
        }
        formatedparsedDate = parsedDate.format("MM/DD/YYYY");
        isValidDate = true;
        break;
      }
    }
    if (isValidDate && formatedparsedDate) {
      if (formatedparsedDate.length > 10) {
        // handle date string length errors
        returnDate = "";
      } else if (validateDateLong(formatedparsedDate)) {
        // CORRECT VALUE
        returnDate = formatedparsedDate;
      } else {
        // handle invalid date errors
        returnDate = "";
      }
    } else {
      returnDate = "";
    }
    dispatch(
      updateElementObjectValueComplaint({
        elementName: name,
        value: returnDate,
      })
    );
  };
  const handleHeightInputChange = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementObjectValueComplaint({ elementName: name, value: value })
    );
  };

  const handleHeightKeyPress = (evt: any) => {
    if (
      evt.which != 8 &&
      evt.which != 9 &&
      (evt.which < 48 || evt.which > 57)
    ) {
      evt.preventDefault();
    }
  };

  const handleWeightInputChange = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementObjectValueComplaint({ elementName: name, value: value })
    );
  };

  const handleWeightKeyPress = (evt: any) => {
    if (
      evt.which != 8 &&
      evt.which != 9 &&
      (evt.which < 48 || evt.which > 57)
    ) {
      evt.preventDefault();
    }
  };

  const handleNumbersDashKeyPress = (evt: any) => {
    if (
      evt.which != 8 &&
      evt.which != 9 &&
      (evt.which < 48 || evt.which > 57) &&
      evt.key !== "-"
    ) {
      if (!complaintObject.defForeignAddress) {
        evt.preventDefault();
      }
    }
  };

  const phoneNumberFormat = (event: any) => {
    let { name, value } = event.target;
    var phoneNumber = formatPhoneNumber(value);
    phoneNumber = phoneNumber ? phoneNumber : value;
    dispatch(
      updateElementObjectValueComplaint({
        elementName: "defPhoneNumber1",
        value: phoneNumber,
      })
    );
  };

  const handleFormatZip = (event: any) => {
    let { name, value } = event.target;
    const _zip = formattedZipcode(value.toUpperCase());
    dispatch(
      updateElementObjectValueComplaint({ elementName: name, value: _zip })
    );
  };

  const handleUpperCaseInputChange = (event: any) => {
    let { name, value } = event.target;
    dispatch(
      updateElementObjectValueComplaint({
        elementName: name,
        value: value.toUpperCase(),
      })
    );
  };

  const handleSelectDropDown = (name: string) => (obj: any) => {
    let { label, value } = obj;
    dispatch(
      updateElementObjectValueComplaint({ elementName: name, value: value })
    );
  };

  const onFocusPrimaryAgencyRepNumber =
    (incedFormat: string) => (event: any) => {
      let { value } = event.target;
      if (!value) {
        let val: string = getIncidentNoByAgencyFormat(incedFormat);
        val = val.replaceAll("#", "");
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "primaryAgencyRepNumber",
            value: val,
          })
        );
      }
    };

  const onBlurPrimaryAgencyRepNumber =
    (incedFormat: string) => (event: any) => {
      let { value } = event.target;
      if (value) {
        let incidentNo: string = value.trim();
        if (!validateIncidentNoForAgency(incedFormat, incidentNo)) {
          setIncidentNumberWarning(
            "Incident Number format failed validation. Please check Incident Number."
          );
          return;
        } else if (value.length > incidentNo.length) {
          dispatch(
            updateElementObjectValueComplaint({
              elementName: "primaryAgencyRepNumber",
              value: incidentNo,
            })
          );
        }
      }
      setIncidentNumberWarning("");
    };

  const continueOnChangingFillingCounty = () => {
    dispatch(
      updateElementObjectValueComplaint({
        elementName: "countyChanged",
        value: true,
      })
    );
    setChangingFillingCounty({ ...changingFillingCounty, show: false });
    setValueOnChangingFillingCounty(
      changingFillingCounty.value,
      changingFillingCounty.name
    );
  };

  const handleSelectCountyDropDown = (name: string) => (obj: any) => {
    let { label, value } = obj;

    //KHL PR 1256 25/01/24 - START
    if (
      ecCountyList.length > 0 &&
      label !== complaintObject.currentCountyName
    ) {
      if(ecCountyList.filter((county) => county.impoundEnable === true && 
      label === county.countyName).length <= 0
      ) {
        dispatch(updateElementObjectValueComplaint({
          elementName: "impoundWarrant",
          value: false,
        }));
      } else if(complaintObject.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT){
        dispatch(updateElementObjectValueComplaint({
          elementName: "impoundWarrant",
          value: true,   
        }));
      }
    }
    //KHL PR 1256 25/01/24 - END

    if (
      complaintObject.ecomplaintId > 0 &&
      complaintObject.countList.length > 0 &&
      label !== complaintObject.currentCountyName
    ) {
      if (label !== complaintObject.countyName && label !== "-- Select --") {
        let ObjValue = {
          show: true,
          name: name,
          value: value,
        };
        setChangingFillingCounty(ObjValue);
      }
    } else {
      setValueOnChangingFillingCounty(value, name);
    }
  };

  const setValueOnChangingFillingCounty = (value: any, name: string) => {
    dispatch(
      updateElementObjectValueComplaint({
        elementName: name,
        value: value ? value.countyName : "",
      })
    );

    if (value) {
      dispatch(
        fetchAgencyListByLfcCounty({
          countyName: value.countyName,
          ecomplaintId: 0,
        })
      );

      if (loggedInUserAgencyList && loggedInUserAgencyList[0]) {
        let agyDisName =
          loggedInUserAgencyList[0].agencyCode +
          "-" +
          loggedInUserAgencyList[0].agencyName;
        if (agyDisName !== complaintObject.agencyDisplayName) {
          dispatch(
            updateElementObjectValueComplaint({
              elementName: "primaryAgencyRepNumber",
              value: "",
            })
          );
        }
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "agencyDisplayName",
            value: agyDisName,
          })
        );

        dispatch(
          fetchOfficersByAgency({
            agencyCode: loggedInUserAgencyList[0].agencyCode,
            countyName: value.countyName,
          })
        );

        let officerVal = "";
        let fn = "";
        let ln = "";
        let badge = "";
        if (loggedInUserOfficersByCounty) {
          let officer = loggedInUserOfficersByCounty[value.countyName];
          if (officer && officer.syncStatus !== USE_OFFICERS_FROM_IUCS) {
            fn = officer.firstName ? officer.firstName.trim() : "";
            ln = officer.lastName ? officer.lastName.trim() + " " : "";
            badge =
              officer && officer.badgeNumber ? "" + officer.badgeNumber : "";
            officerVal = badge ? badge + "-" + ln + fn : "";
          }
        }
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "officerDisplayingName",
            value: officerVal,
          })
        );
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "officerFirstName",
            value: fn.trim(),
          })
        );
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "officerLastName",
            value: ln.trim(),
          })
        );
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "officerBadge",
            value: badge.trim(),
          })
        );
      }
    } else {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "agencyDisplayName",
          value: "",
        })
      );
      dispatch(
        updateElementValueComplaintReducer({
          elementName: "agenciesForLfcCounty",
          value: [],
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerAgencyName",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerAgencyOri",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerAgencyCode",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerDisplayingName",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerFirstName",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerLastName",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerBadge",
          value: "",
        })
      );
      dispatch(
        updateElementValueComplaintReducer({
          elementName: "policeOfficers",
          value: [],
        })
      );

      setIncidentNoFormat("");
      setWebRms(false);

      dispatch(
        updateElementObjectValueComplaint({
          elementName: "primaryAgencyRepNumber",
          value: "",
        })
      );
    }

    dispatch(
      updateElementObjectValueComplaint({
        elementName: "municipalityAgencyDisplayName",
        value: "",
      })
    );
    dispatch(
      updateElementObjectValueComplaint({
        elementName: "municipalityAgencyName",
        value: "",
      })
    );
    dispatch(
      updateElementObjectValueComplaint({
        elementName: "municipalityAgencyCode",
        value: "",
      })
    );

    if (!value || value.countyName !== MCHENRY_COUNTY) {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "nextCourtDate",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "nextCourtTime",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "nextCourtLocation",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "totalBond",
          value: "",
        })
      );
    }
  };

  const handleSelectAgencyDropDown = (name: string) => (obj: any) => {
    let { label, value } = obj;
    dispatch(
      updateElementObjectValueComplaint({
        elementName: name,
        value: value ? value.displayingName : "",
      })
    );
    if (value) {
      if (label !== complaintObject.agencyDisplayName) {
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "primaryAgencyRepNumber",
            value: "",
          })
        );
      }
      let countyName: any = complaintObject.countyName;
      dispatch(
        fetchOfficersByAgency({
          agencyCode: value.agencyCode,
          countyName: countyName,
        })
      );

      let officerVal = "";
      let fn = "";
      let ln = "";
      let badge = "";
      let loggedInUserAgency = "";
      if (loggedInUserAgencyList && loggedInUserAgencyList[0]) {
        loggedInUserAgency =
          loggedInUserAgencyList[0].agencyCode +
          "-" +
          loggedInUserAgencyList[0].agencyName;
      }
      if (label === loggedInUserAgency && loggedInUserOfficersByCounty) {
        let officer = loggedInUserOfficersByCounty[countyName];
        if (officer && officer.syncStatus !== USE_OFFICERS_FROM_IUCS) {
          fn = officer.firstName ? officer.firstName.trim() : "";
          ln = officer.lastName ? officer.lastName.trim() + " " : "";
          badge =
            officer && officer.badgeNumber ? "" + officer.badgeNumber : "";
          officerVal = badge ? badge + "-" + ln + fn : "";
        }
      }
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerDisplayingName",
          value: officerVal,
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerFirstName",
          value: fn.trim(),
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerLastName",
          value: ln.trim(),
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerBadge",
          value: badge.trim(),
        })
      );
    } else {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerAgencyName",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerAgencyOri",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerAgencyCode",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerDisplayingName",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerFirstName",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerLastName",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "officerBadge",
          value: "",
        })
      );
      dispatch(
        updateElementValueComplaintReducer({
          elementName: "policeOfficers",
          value: [],
        })
      );

      setIncidentNoFormat("");
      setWebRms(false);

      dispatch(
        updateElementObjectValueComplaint({
          elementName: "primaryAgencyRepNumber",
          value: "",
        })
      );
    }
  };

  const handleSelectOfficerDropDown = (name: string) => (obj: any) => {
    let { label, value } = obj;
    dispatch(
      updateElementObjectValueComplaint({
        elementName: name,
        value: value ? value.displayingName : "",
      })
    );

    dispatch(
      updateElementObjectValueComplaint({
        elementName: "officerFirstName",
        value: value ? value.firstName : "",
      })
    );
    dispatch(
      updateElementObjectValueComplaint({
        elementName: "officerLastName",
        value: value ? value.lastName : "",
      })
    );
    dispatch(
      updateElementObjectValueComplaint({
        elementName: "officerBadge",
        value: value ? "" + value.badgeNumber : "",
      })
    );
  };

  const handleSelectProsecutionTypeDropDown = (name: string) => (obj: any) => {
    let { label, value } = obj;
    dispatch(
      updateElementObjectValueComplaint({ elementName: name, value: value })
    );
    if ("LOCAL" === label && complaintObject.officerAgencyCode) {
      //TODO Need to add changes of File On Behalf Of Others
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "municipalityAgencyDisplayName",
          value: complaintObject.agencyDisplayName,
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "municipalityAgencyName",
          value: complaintObject.officerAgencyName,
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "municipalityAgencyCode",
          value: complaintObject.officerAgencyCode,
        })
      );
    } else {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "municipalityAgencyDisplayName",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "municipalityAgencyName",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "municipalityAgencyCode",
          value: "",
        })
      );
    }
    if ("LOCAL" !== label) {
      setComboMunicipality(true);
    } else {
      setComboMunicipality(false);
    }
  };

  const handleSelectMunicipalityAgencyDropDown =
    (name: string) => (obj: any) => {
      let { label, value } = obj;
      dispatch(
        updateElementObjectValueComplaint({
          elementName: name,
          value: value ? value.displayingName : "",
        })
      );

      dispatch(
        updateElementObjectValueComplaint({
          elementName: "municipalityAgencyName",
          value: value ? value.agencyName : "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "municipalityAgencyCode",
          value: value ? value.agencyCode : "",
        })
      );
    };

  const handelChangeCheck = (event: any) => {
    if (event.target.name === "onViewArrest") {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "typeOfArrest",
          value: TYPE_OF_ARREST_ONVIEWARREST,
        })
      );
      if (event.target.checked) {
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "impoundWarrant",
            value: false,
          })
        );
      }
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "onViewArrestOptionClickOnce",
          value: true,
        })
      );
    } else if (event.target.name === "arrestWarrant") {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "typeOfArrest",
          value: TYPE_OF_ARREST_ARRESTWARRANT,
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "postSafeTAct",
          value: !event.target.checked,
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "defendantReleased",
          value: !event.target.checked,
        })
      );
      if (!event.target.checked) {
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "impoundWarrant",
            value: false,
          })
        );
      }
      //KHL PR 1256 25/01/24 - START
      if(event.target.checked && ecCountyList.filter((county) => county.impoundEnable === true && 
      complaintObject.countyName === county.countyName).length > 0
      ) {
        dispatch(updateElementObjectValueComplaint({
          elementName: "impoundWarrant",
          value: true,
        }));
      }

      //KHL PR 1256 25/01/24 - END
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "onViewArrestOptionClickOnce",
          value: true,
        })
      );
    } else if (event.target.name === "summon") {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "typeOfArrest",
          value: TYPE_OF_ARREST_SUMMON,
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "postSafeTAct",
          value: !event.target.checked,
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "defendantReleased",
          value: false,
        })
      );
      if (event.target.checked) {
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "impoundWarrant",
            value: false,
          })
        );
      }
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "onViewArrestOptionClickOnce",
          value: true,
        })
      );
    } else if (event.target.name === "defAddressUnknown") {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: event.target.name,
          value: event.target.checked,
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "defAddressLine1",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "defAddressLine2",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({ elementName: "defCity", value: "" })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "defState",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({ elementName: "defZip", value: "" })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "defCountry",
          value: "",
        })
      );
      if (event.target.checked) {
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "defForeignAddress",
            value: false,
          })
        );
      }
      setCountry("unknown", event.target.checked);
    } else if (event.target.name === "defForeignAddress") {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "defState",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueComplaint({
          elementName: event.target.name,
          value: event.target.checked,
        })
      );
      if (event.target.checked) {
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "defAddressUnknown",
            value: false,
          })
        );
      }
      setCountry("foreign", event.target.checked);
    } else {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: event.target.name,
          value: event.target.checked,
        })
      );
    }
  };

  const handelChangeSubCheck = (event: any) => {
    if (event.target.name === "onViewArrest_option") {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "postSafeTAct",
          value: event.target.checked,
        })
      );
      if (event.target.value === "onViewArrestWarrantDefToBeRelease") {
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "defendantReleased",
            value: event.target.checked,
          })
        );
      } else if (event.target.value === "onViewArrestWarrantDefWillBeHeld") {
        dispatch(
          updateElementObjectValueComplaint({
            elementName: "defendantReleased",
            value: !event.target.checked,
          })
        );
      }
    }
  };

  const setCountry = (currentFeild: string, currentValue: boolean) => {
    const { defAddressUnknown, defForeignAddress } = complaintObject;
    if (
      currentFeild === "unknown" &&
      currentValue === false &&
      defForeignAddress === false
    ) {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "defCountry",
          value: "UNITED STATES",
        })
      );
    } else if (
      currentFeild === "foreign" &&
      currentValue === false &&
      defAddressUnknown === false
    ) {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "defCountry",
          value: "UNITED STATES",
        })
      );
    } else {
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "defCountry",
          value: "",
        })
      );
    }
  };

  const validateAndSaveComp = async () => {
    handleError([""], []);
    dispatch(
      updateElementObjectValueComplaint({
        elementName: "witnessList",
        value: witnessList,
      })
    );
    try {
      let schema = [];
      schema.push(defaultSchemaError);

      if (complaintObject.caseProsecutionType === "LOCAL") {
        schema.unshift(
          yup.object().shape({
            municipalityAgencyDisplayName: yup
              .string()
              .trim()
              .required("A Local 'Municipality' is required."),
          })
        );
      }
      if (
        (complaintObject.ecomplaintId === 0 &&
          complaintObject.onViewArrestOptionClickOnce &&
          complaintObject.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT) ||
        (complaintObject.ecomplaintId > 0 &&
          complaintObject.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT) ||
        (complaintObject.ecomplaintId === 0 &&
          complaintObject.onViewArrestOptionClickOnce &&
          complaintObject.typeOfArrest === TYPE_OF_ARREST_SUMMON) ||
        (complaintObject.ecomplaintId > 0 &&
          complaintObject.typeOfArrest === TYPE_OF_ARREST_SUMMON)
      ) {
        schema.unshift(
          yup.object().shape({
            defHairColor: yup
              .string()
              .trim()
              .required("'Hair' is required.")
              .nullable(),
            defEyeColor: yup
              .string()
              .trim()
              .required("'Eyes' is required.")
              .nullable(),
            defHeight: yup
              .string()
              .trim()
              .required("'Height' is required.")
              .nullable(),
            defWeight: yup
              .string()
              .trim()
              .required("'Weight' is required.")
              .nullable(),
          })
        );
      }
      if (!complaintObject.defAddressUnknown) {
        schema.unshift(
          yup.object().shape({
            defAddressLine1: yup
              .string()
              .trim()
              .required("'Address Line 1' is required.")
              .nullable()
              .test("check-with-defAddressLine1", function (value) {
                if (value) {
                  var cleanedText = displayNonAsciiValues(value);
                  if (cleanedText) {
                    return this.createError({
                      message:
                        "Non printable text/characters( " +
                        cleanedText +
                        " ) have been pasted or added into the field Address Line 1. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
                    });
                  }
                }
                return true;
              }),
            defAddressLine2: yup
              .string()
              .trim()
              .nullable()
              .test("check-with-defAddressLine2", function (value) {
                if (value) {
                  var cleanedText = displayNonAsciiValues(value);
                  if (cleanedText) {
                    return this.createError({
                      message:
                        "Non printable text/characters( " +
                        cleanedText +
                        " ) have been pasted or added into the field Address Line 2. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
                    });
                  }
                }
                return true;
              }),
            defCity: yup
              .string()
              .trim()
              .required("'City' is required.")
              .nullable()
              .test("check-with-city", function (value) {
                if (value) {
                  var cleanedText = displayNonAsciiValues(value);
                  if (cleanedText) {
                    return this.createError({
                      message:
                        "Non printable text/characters( " +
                        cleanedText +
                        " ) have been pasted or added into the field city. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
                    });
                  }
                }
                return true;
              }),
            defZip: yup
              .string()
              .trim()
              .required("'Zip' is required.")
              .nullable()
              .test("check-Zip", function (value) {
                const regex = new RegExp(zipCodeRegex);
                if (value) {
                  var cleanedText = displayNonAsciiValues(value);
                  if (cleanedText) {
                    return this.createError({
                      message:
                        "Non printable text/characters( " +
                        cleanedText +
                        " ) have been pasted or added into the field Zip. Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
                    });
                  }
                }
                if (!complaintObject.defForeignAddress) {
                  if (
                    complaintObject.defZip &&
                    !regex.test(complaintObject.defZip)
                  ) {
                    return this.createError({
                      message: "Invalid 'Zip'.",
                    });
                  } else {
                    return true;
                  }
                } else {
                  return true;
                }
              }),
            defCountry: yup.string().trim().required("'Country' is required."),
          })
        );
        if (complaintObject.defForeignAddress) {
          schema.unshift(
            yup.object().shape({
              defState: yup
                .string()
                .trim()
                .required("'State/Province' is required."),
            })
          );
        } else {
          schema.unshift(
            yup.object().shape({
              defState: yup.string().trim().required("'State' is required."),
            })
          );
        }
      }

      if (complaintObject.countyName === MCHENRY_COUNTY) {
        schema.unshift(
          yup.object().shape({
            nextCourtDate: yup
              .string()
              .nullable()
              .test("check-nextCourtDate-future", function (value) {
                let nextCDate: any = undefined;
                if (complaintObject.nextCourtDate) {
                  nextCDate = moment(
                    complaintObject.nextCourtDate,
                    "YYYY-MM-DD"
                  );
                }
                let today: any = new Date();
                today.setHours(0, 0, 0, 0);
                if (nextCDate && nextCDate < today.getTime()) {
                  return this.createError({
                    message: "'Next Court Date' should be a future date.",
                  });
                } else {
                  return true;
                }
              }),
          })
        );
      }

      schema = merge(...schema);
      const county = await schema.validate(complaintObject, {
        abortEarly: false,
      });
      continueOnSaveCompaintAfterValidate();
    } catch (err: any) {
      setWarningFields([""]);
      handleWarning([""], []);
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const continueOnSaveCompaintAfterValidate = async () => {
    handleWarning([""], []);
    try {
      let schema = [];
      schema.push(defaultSchemaWarning);
      schema = merge(...schema);
      const county = await schema.validate(complaintObject, {
        abortEarly: false,
      });
      setWarningFields([""]);
      continueOnSaveCompaintAfterWarn();
    } catch (err: any) {
      let fields = err.inner.map((e: any) => e.path);
      setWarningFields([...warningFields, ...fields]);
      handleWarning(fields, err.errors);
    }
  };

  const existingEcForms = () => {
    let existingFormTypeNameList: string[] = [];
    if (ecFormList) {
      ecFormList.forEach((element: any) => {
        existingFormTypeNameList.push(element.formTypeName);
      });
    }
    return existingFormTypeNameList;
  };

  const continueOnSaveCompaintAfterWarn = () => {
    let confirmMessage = "";
    if (complaintObject.ecomplaintId > 0) {
      let existingForms = existingEcForms();
      if (
        existingDataToCompare.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT &&
        complaintObject.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST
      ) {

        if (existingForms.includes(REVISED_PROBABLE_CAUSE_STATEMENT) && complaintObject.defendantReleased){
          confirmMessage =
          "Changing arrest type may delete the Arrest Warrant and Probable Cause Statement. Do you want to continue?";
        } else{
          confirmMessage =
          "Changing arrest type may delete the Arrest Warrant. Do you want to continue?";
        }
      } else if (
        existingDataToCompare.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST &&
        complaintObject.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT  && 
        MCHENRY_COUNTY === existingDataToCompare.countyName
      ) {
        confirmMessage =
          "Changing arrest type may delete the Notice of Arrest without Warrant. Do you want to continue?";
      } else if (
        existingDataToCompare.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST &&
        complaintObject.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST &&
        MCHENRY_COUNTY === existingDataToCompare.countyName &&
        existingDataToCompare.countyName !== complaintObject.countyName
      ) {
        confirmMessage =
          "Changing county may delete the Notice of Arrest without Warrant. Do you want to continue?";
      } else if (
        existingDataToCompare.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST &&
        complaintObject.typeOfArrest === TYPE_OF_ARREST_SUMMON &&
        existingForms.includes(REVISED_PROBABLE_CAUSE_STATEMENT)
      ) {
        confirmMessage =
          "Changing pre trail option may delete the Probable Cause Statement. Do you want to continue?";
      } else if (
        existingDataToCompare.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST &&
        complaintObject.typeOfArrest === TYPE_OF_ARREST_SUMMON &&
        MCHENRY_COUNTY === existingDataToCompare.countyName &&
        !existingForms.includes(REVISED_PROBABLE_CAUSE_STATEMENT)
      ) {
        confirmMessage =
          "Changing arrest type may delete the Notice of Arrest without Warrant. Do you want to continue?";
      } else if (
        existingDataToCompare.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT &&
        complaintObject.typeOfArrest === TYPE_OF_ARREST_SUMMON 
      ) {
        if (existingForms.includes(REVISED_PROBABLE_CAUSE_STATEMENT)){
          confirmMessage =
          "Changing arrest type may delete the Arrest Warrant and Probable Cause Statement. Do you want to continue?";
        }else{
          confirmMessage =
          "Changing arrest type may delete the Arrest Warrant. Do you want to continue?";
        }
      } else if (
        existingDataToCompare.typeOfArrest === TYPE_OF_ARREST_SUMMON &&
        complaintObject.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT 
      ) {
        confirmMessage =
          "Changing arrest type may delete the Summon. Do you want to continue?";
      } else if (
        existingDataToCompare.typeOfArrest === TYPE_OF_ARREST_SUMMON &&
        complaintObject.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST 
      ) {
        confirmMessage =
          "Changing arrest type may delete the Summon. Do you want to continue?";
      } else if (
        existingDataToCompare.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST &&
        complaintObject.typeOfArrest === TYPE_OF_ARREST_ONVIEWARREST &&
        complaintObject.defendantReleased && MCHENRY_COUNTY === existingDataToCompare.countyName
      ){
        confirmMessage =
        "Changing arrest type may delete the Probable Cause Statement. Do you want to continue?";
      }
    }
    if (confirmMessage) {
      setConfirmText(confirmMessage);
      setConfirmDeleteDoc(true);
    } else {
      continueOnSaveCompaintAfterDocChange(false);
    }
  };

  const continueOnSaveCompaintAfterDocChange = (delEcform: boolean) => {
    deleteEcform = delEcform;
    setConfirmDeleteDoc(false);
    if (
      !validateIncidentNoForAgency(
        incidentNoFormat,
        complaintObject.primaryAgencyRepNumber
      )
    ) {
      setShowIncidentNumberConfirmPopup(true);
    } else {
      continueOnSaveCompaintAfterIncidentNumberChange("");
    }
  };

  const continueOnSaveCompaintAfterIncidentNumberChange = (
    incidentNumber: string
  ) => {
    setShowIncidentNumberConfirmPopup(false);
    let comObj = { ...complaintObject };
    if (incidentNumber) {
      comObj.primaryAgencyRepNumber = incidentNumber;
    }
    comObj.deleteEcform = deleteEcform;
    let dataObj = {
      comp: comObj,
      navigate: navigate,
      returnToPage: returnToPage,
    };
    dispatch(saveComplaint(dataObj));
  };

  const cancel = () => {
    if (returnToPage === "ComplaintMainPage") {
      navigate("/EComplaintMainPage");
    } else if (returnToPage === "ComplaintList") {
      dispatch(
        updateElementValueDashboardReducer({
          elementName: "isCardActive",
          value: false,
        })
      );
      const url = dashboardReducer.PreviousURLLFC;
      const newUrl = url.replace(/^.*\/\/.*?\//, "");
      navigate("/" + newUrl);
    } else if (returnToPage === "Arrest Warrant") {
      navigate("/arrestWarrant");
    } else if (returnToPage === "Summons") {
      navigate("/issueSummons");
    }
  };

  const onClickRMS = () => {
    let incidentData: any = {
      firstName: complaintObject.defFirstName,
      lastName: complaintObject.defLastName,
      incidentNumber: complaintObject.primaryAgencyRepNumber,
      dob: "",
      ssn: "",
    };

    dispatch(getWebRmsList(incidentData));
    setLookupWebRMS(true);
  };

  const onMountComboVisible = () => {
    if (complaintObject.countList.length > 0) {
      setComboProSeLevel(true);

      if (
        (userType === ROLE_POLICE_OFFICER &&
          complaint.caseStatus === LF_PENDING_STATUS) ||
        (userType === ROLE_STATES_ATTORNEY &&
          complaint.caseStatus === LF_PENDING_STATUS)
      ) {
        setComboFilingCounty(false);
      } else {
        setComboFilingCounty(true);
      }
    }
    if (complaintObject.caseProsecutionType !== "LOCAL") {
      setComboMunicipality(true);
    }
  };

  const handleIntroMessageLastName = () => {
    intro.setOptions({
      steps: [
        {
          element: "#lastname",
          intro:
            '<label>Please enter defendant LAST NAME here.</label><br/><br/><input type="checkbox" id="doNotShowAgainLastName" name="doNotShowAgainLastName"><label for="doNotShowAgainLastName">&nbsp;Do not show this again</label>',
          title: "Attention",
          position: "top",
          tooltipClass: "customTooltip",
        },
      ],
    });
    const observer = new MutationObserver((mutationsList, observer) => {
      const checkboxValue = document.getElementById(
        "doNotShowAgainLastName"
      ) as HTMLInputElement;
      if (checkboxValue) {
        observer.disconnect(); // Stop observing mutations once the element is found

        checkboxValue.addEventListener("change", () => {
          if (checkboxValue.checked) {
            dispatch(
              saveViewIntro({
                userId: userProfileId,
                preferenceName: "do.Not.ShowAgain.LastName",
                preferenceValue: "Y",
              })
            )
              .then((res) => {
                dispatch(
                  getUserDataByUsername({
                    username: username,
                  })
                );
              })
              .then((res) => {
                intro.exit();
              });
          }
        });
      }
    });

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
    // intro.setOption("showProgress", true);
    intro.setOption("showBullets", false);
    intro.setOption("showButtons", false);
    if (!userChangeProfileReducer.canViewIntroLastName) {
      intro.start();
    }
  };

  const handleIntroMessageFirstName = () => {
    intro.setOptions({
      disableInteraction: true,
      steps: [
        {
          element: "#firstname",
          intro:
            '<label>Please enter defendant FIRST NAME here.</label><br/><br/><input type="checkbox" id="doNotShowAgain" name="doNotShowAgain"><label for="doNotShowAgain">&nbsp;Do not show this again</label>',
          title: "Attention",
          position: "top",
          tooltipClass: "customTooltip",
        },
      ],
    });
    const observer = new MutationObserver((mutationsList, observer) => {
      const checkboxValue = document.getElementById(
        "doNotShowAgain"
      ) as HTMLInputElement;
      if (checkboxValue) {
        observer.disconnect(); // Stop observing mutations once the element is found

        checkboxValue.addEventListener("change", () => {
          if (checkboxValue.checked) {
            dispatch(
              saveViewIntro({
                userId: userProfileId,
                preferenceName: "do.Not.ShowAgain.FirstName",
                preferenceValue: "Y",
              })
            )
              .then((res) => {
                dispatch(
                  getUserDataByUsername({
                    username: username,
                  })
                );
              })
              .then((res) => {
                intro.exit();
              });
          }
        });
      }
    });

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
    // intro.setOption("showProgress", true);
    intro.setOption("showBullets", false);
    intro.setOption("showButtons", false);
    if (!userChangeProfileReducer.canViewIntroFirstName) {
      intro.start();
    }
  };

  function handleKeydown(event: any) {
    if (event.key === "Tab") {
      intro.exit();
    }
  }

  const handleEnableDisable = (enabled: boolean) => {
    if (complaint && complaint.ecomplaintId < 0) {
      if (
        (complaint.certificationIndicator &&
          !(
            (LF_STATUS_PENDING_SUPERVISOR_REVIEW === complaint.caseStatus &&
              ROLE_POLICE_OFFICER === userType) ||
            !(
              LF_STATUS_PENDING_SUPERVISOR_REVIEW === complaint.caseStatus &&
              ROLE_STATES_ATTORNEY === userType
            )
          )) ||
        LF_FILED_STATUS === complaint.caseStatus ||
        LF_SEND_TO_JUDGE_STATUS === complaint.caseStatus ||
        LF_CASE_ASSIGNMENT_READY_STATUS === complaint.caseStatus ||
        LF_ARRW_REJECTED_STATUS === complaint.caseStatus ||
        LF_COMMUNI_FAILED_STATUS === complaint.caseStatus ||
        (ROLE_POLICE_OFFICER === userType &&
          LF_SEND_TO_SA_STATUS === complaint.caseStatus) ||
        (ROLE_STATES_ATTORNEY === userType &&
          LF_SEND_TO_OFFICER_STATUS === complaint.caseStatus)
      ) {
        setBtnSaveNewComplaint(enabled);
        setDefDOB(!enabled);
        setDateOfFirstInc(!enabled);
        setComboFilingCounty(!enabled);
        setComboProSeLevel(!enabled);
        setComboMunicipality(!enabled);
        setComboGender(!enabled);
        setTxtState(!enabled);
        setComboCountry(!enabled);
        setComboDLState(!enabled);
        setTxtAgencyReportNo(!enabled);
        setTxtDocControlNo(!enabled);
        setTxtDefFName(!enabled);
        setTxtDefMName(!enabled);
        setTxtDefLName(!enabled);
        setComboDefSuffix(!enabled);
        setTxtAddress1(enabled);
        setTxtAddress2(enabled);
        setTxtCity(!enabled);
        if (txtZipFa !== null) {
          setTxtZipFa(enabled);
        }
        if (txtZipUa !== null) {
          setTxtZipUa(enabled);
        }
        setTxtDefEmail(!enabled);
        setTxtDLNo(!enabled);
        setChkUnknownAddress(!enabled);
        setChkForeignAddress(!enabled);
      }
    }
  };

  const enableAddressFields = (isUnknown: boolean) => {
    setTxtAddress1(isUnknown);
    setTxtAddress2(isUnknown);
    setTxtCity(isUnknown);
    setComboState(isUnknown);
    setTxtZipFa(isUnknown);
    setTxtZipUa(isUnknown);
    setComboCountry(isUnknown);
    setTxtState(isUnknown);
  };

  const data = [
    {
      type: "customComponents",
      props: {},
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <>
            <i>
              Case Number will not be assigned until Felony Screening or when
              case is filed with the circuit clerk.
            </i>
          </>
        ),
      },
      colSize: 12,
      colClassName: "mt-2 mb-2",
    },
    {
      type: "selectInput",
      props: {
        options: lfcCountyListOptions,
        labelName: <b>Filing County</b>,
        placeholder: "-- Select --",
        value: lfcCountyListOptions.find((item) => {
          if (item.label === complaintObject.countyName) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.countyName) {
            return { label: "-- Select --", value: undefined };
          }
        }),
        defaultValue: { label: "-- Select --", value: undefined },
        name: "countyName",
        isError: errorKeys.includes("countyName"),
        onChange: handleSelectCountyDropDown("countyName"),
        isSelected: true,
        isRequired: true,
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete: "nope",
        isDisabled: comboFilingCounty,
      },
      colSize: 2,
    },
    {
      type: "selectInput",
      props: {
        options: agenciesForLfcCountyOptions,
        labelName: <b>Arresting Agency</b>,
        value: agenciesForLfcCountyOptions.find((item: any) => {
          if (item.label === complaintObject.agencyDisplayName) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.agencyDisplayName) {
            return { label: "-- Select --", value: undefined };
          }
        }),
        defaultValue: { label: "-- Select --", value: undefined },
        name: "agencyDisplayName",
        isError: errorKeys.includes("agencyDisplayName"),
        onChange: handleSelectAgencyDropDown("agencyDisplayName"),
        isSelected: true,
        isRequired: true,
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete: "nope",
      },
      colSize: 3,
    },
    {
      type: "selectInput",
      props: {
        options: policeOfficersOptions,
        labelName: <b>Officer</b>,
        value: policeOfficersOptions.find((item) => {
          if (item.label === complaintObject.officerDisplayingName) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.officerDisplayingName) {
            return { label: "-- Select --", value: undefined };
          }
        }),
        defaultValue: { label: "-- Select --", value: undefined },
        name: "officerDisplayingName",
        isError: errorKeys.includes("officerDisplayingName"),
        onChange: handleSelectOfficerDropDown("officerDisplayingName"),
        isSelected: true,
        isRequired: true,
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete: "nope",
      },
      colSize: 3,
      emptyCol: 4,
    },
    {
      type: "selectInput",
      props: {
        options: [
          ...CASEPROSECUTIONTYPELIST.map((prosecution: any) => {
            return { label: prosecution.label, value: prosecution.value };
          }),
        ],
        labelName: <b>Prosecution Level</b>,
        value: [
          ...CASEPROSECUTIONTYPELIST.map((prosecution: any) => {
            return { label: prosecution.label, value: prosecution.value };
          }),
        ].find((item) => {
          if (item.label === complaintObject.caseProsecutionType) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.caseProsecutionType) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "caseProsecutionType",
        isError: errorKeys.includes("caseProsecutionType"),
        onChange: handleSelectProsecutionTypeDropDown("caseProsecutionType"),
        isSelected: true,
        isRequired: true,
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete: "nope",
        isDisabled: comboProSeLevel,
      },
      colSize: 2,
    },
    {
      type: "selectInput",
      props: {
        options:
          agenciesForLfcCountyOptions.length === 1 &&
          complaintObject.caseProsecutionType !== "LOCAL"
            ? []
            : agenciesForLfcCountyOptions,
        labelName: <b>Municipality</b>,
        value:
          agenciesForLfcCountyOptions.length === 1 &&
          complaintObject.caseProsecutionType !== "LOCAL"
            ? { label: "-- Select --", value: undefined }
            : agenciesForLfcCountyOptions.find((item: any) => {
                if (
                  item.label === complaintObject.municipalityAgencyDisplayName
                ) {
                  return { label: item.label, value: item.value };
                } else if (!complaintObject.municipalityAgencyDisplayName) {
                  return { label: "-- Select --", value: undefined };
                }
              }),
        defaultValue: { label: "-- Select --", value: undefined },
        name: "municipalityAgencyDisplayName",
        isError: errorKeys.includes("municipalityAgencyDisplayName"),
        onChange: handleSelectMunicipalityAgencyDropDown(
          "municipalityAgencyDisplayName"
        ),
        isDisabled: comboMunicipality,
        isSelected: true,
        isRequired: complaintObject.caseProsecutionType === "LOCAL",
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete: "nope",
      },
      colSize: 3,
      emptyCol: 7,
    },
    {
      type: "textInput",
      props: {
        type: "Date",
        labelName: <b>Arrest Date or Date of first Incident</b>,
        name: "firstIncidentDateTime",
        value: complaintObject.firstIncidentDateTime,
        isError: errorKeys.includes("firstIncidentDateTime"),
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        onKeyDown: handleKeyDown,
        max: moment(new Date()).format("YYYY-MM-DD"),
        isRequired: true,
        isLabeled: true,
        autoComplete: "nope",
        style: { width: "150px" },
        disabled: dateOfFirstInc,
      },
      colSize: "auto",
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
      colClassName: "mt-3",
    },
    {
      type: "htmlTag",
      props: {
        value: <div className={"text-warning"}>{incidentNumberWarning}</div>,
      },
      colSize: 12,
      colHide: !incidentNumberWarning,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Report/Incident Number</b>,
        placeholder: incidentNoFormat,
        name: "primaryAgencyRepNumber",
        value: complaintObject.primaryAgencyRepNumber,
        isError: errorKeys.includes("primaryAgencyRepNumber"),
        isWarning: warningKeys.includes("primaryAgencyRepNumber"),
        onChange: handleUpperCaseInputChange,
        onFocus: onFocusPrimaryAgencyRepNumber(incidentNoFormat),
        onBlur: onBlurPrimaryAgencyRepNumber(incidentNoFormat),
        isRequired: true,
        isLabeled: true,
        autoComplete: "nope",
        disabled: txtAgencyReportNo,
      },
      colSize: 2,
    },
    {
      type: "htmlTag",
      props: {
        value: incidentNoFormat ? <b>Format: {incidentNoFormat}</b> : "",
      },
      colSize: 2,
      colClassName: "d-flex align-items-center",
    },
    {
      type: "formButton",
      props: {
        children: "Lookup Web RMS",
        onClick: onClickRMS,
        className: "mx-2 btn-secondary",
        isDisabled:
          !complaintObject.primaryAgencyRepNumber ||
          !complaintObject.defFirstName ||
          !complaintObject.defLastName,
      },
      colSize: 2,
      emptyCol: 6,
      colClassName: "d-flex align-items-center",
      colHide: !webRms,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Defendant Last Name</b>,
        name: "defLastName",
        value: complaintObject.defLastName,
        isError: errorKeys.includes("defLastName"),
        onChange: handleUpperCaseInputChange,
        isRequired: true,
        isLabeled: true,
        autoComplete: "nope",
        disabled: txtDefLName,
        placeholder: "Last Name",
        id: "lastname",
        onFocus: handleIntroMessageLastName,
        onKeyDown: handleKeydown,
      },
      colSize: 4,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Defendant First Name</b>,
        name: "defFirstName",
        value: complaintObject.defFirstName,
        isError: errorKeys.includes("defFirstName"),
        onChange: handleUpperCaseInputChange,
        isRequired: true,
        isLabeled: true,
        autoComplete: "nope",
        disabled: txtDefFName,
        placeholder: "First Name",
        id: "firstname",
        onFocus: handleIntroMessageFirstName,
        onKeyDown: handleKeydown,
      },
      colSize: 3,
      emptyCol: 5,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
      colClassName: "mb-2",
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Defendant Middle Name</b>,
        name: "defMiddleName",
        value: complaintObject.defMiddleName,
        isError: errorKeys.includes("defMiddleName"),
        onChange: handleUpperCaseInputChange,
        isLabeled: true,
        autoComplete: "nope",
        disabled: txtDefMName,
      },
      colSize: 3,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...suffixList.map((cte: any) => {
            return { label: cte.displayValue, value: cte.displayValue };
          }),
        ],
        labelName: <b>Defendant Suffix</b>,
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...suffixList.map((cte: any) => {
            return { label: cte.displayValue, value: cte.displayValue };
          }),
        ].find((item) => {
          if (item.label === complaintObject.defSuffix) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.defSuffix) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defSuffix",
        onChange: handleSelectDropDown("defSuffix"),
        isSelected: true,
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete: "nope",
        isDisabled: comboDefSuffix,
      },
      colSize: 2,
      emptyCol: 7,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Date of Birth</b>,
        name: "defDob",
        value: complaintObject.defDob,
        isError:
          errorKeys.includes("defDob") ||
          errorMessages.includes(dobLessThanOffenceDate),
        onChange: handleInputChange,
        onBlur: handleBithdayInputBlur,
        maxLength: 10,
        placeholder: "MM/dd/yyyy",
        isRequired: true,
        isLabeled: true,
        autoComplete: "nope",
        disabled: defDOB,
      },
      colSize: "auto",
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...genderList.map((cte: any) => {
            return { label: cte.displayValue, value: cte.displayValue };
          }),
        ],
        labelName: <b>Gender</b>,
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...genderList.map((cte: any) => {
            return { label: cte.displayValue, value: cte.displayValue };
          }),
        ].find((item) => {
          if (item.label === complaintObject.defGender) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.defGender) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defGender",
        isError: errorKeys.includes("defGender"),
        onChange: handleSelectDropDown("defGender"),
        isSelected: true,
        isRequired: true,
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete: "nope",
        isDisabled: comboGender,
      },
      colSize: 2,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...raceList.map((cte: any) => {
            return { label: cte.displayValue, value: cte.displayValue };
          }),
        ],
        labelName: <b>Race</b>,
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...raceList.map((cte: any) => {
            return { label: cte.displayValue, value: cte.displayValue };
          }),
        ].find((item) => {
          if (item.label === complaintObject.defRace) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.defRace) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defRace",
        isError: errorKeys.includes("defRace"),
        onChange: handleSelectDropDown("defRace"),
        isSelected: true,
        isRequired: true,
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete: "nope",
      },
      colSize: 2,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...hairList.map((cte: any) => {
            return { label: cte.displayValue, value: cte.displayValue };
          }),
        ],
        labelName: <b>Hair</b>,
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...hairList.map((cte: any) => {
            return { label: cte.displayValue, value: cte.displayValue };
          }),
        ].find((item) => {
          if (item.label === complaintObject.defHairColor) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.defHairColor) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defHairColor",
        isError: errorKeys.includes("defHairColor"),
        onChange: handleSelectDropDown("defHairColor"),
        isSelected: true,
        isLabeled: true,
        isDefaultLabel: true,
        isRequired:
          complaintObject.ecomplaintId === 0 &&
          !complaintObject.onViewArrestOptionClickOnce
            ? false
            : complaintObject.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT || complaintObject.typeOfArrest === TYPE_OF_ARREST_SUMMON,
        autoComplete: "nope",
      },
      colSize: 2,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...eyesList.map((cte: any) => {
            return { label: cte.displayValue, value: cte.displayValue };
          }),
        ],
        labelName: <b>Eyes</b>,
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...eyesList.map((cte: any) => {
            return { label: cte.displayValue, value: cte.displayValue };
          }),
        ].find((item) => {
          if (item.label === complaintObject.defEyeColor) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.defEyeColor) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defEyeColor",
        isError: errorKeys.includes("defEyeColor"),
        onChange: handleSelectDropDown("defEyeColor"),
        isSelected: true,
        isLabeled: true,
        isDefaultLabel: true,
        isRequired:
          complaintObject.ecomplaintId === 0 &&
          !complaintObject.onViewArrestOptionClickOnce
            ? false
            : complaintObject.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT || complaintObject.typeOfArrest === TYPE_OF_ARREST_SUMMON,
        autoComplete: "nope",
      },
      colSize: 2,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Height</b>,
        name: "defHeight",
        value: complaintObject.defHeight,
        isError: errorKeys.includes("defHeight"),
        title: "Ex: If the height is 5ft 9inches, please enter 509.",
        onChange: handleHeightInputChange,
        onKeyPress: handleHeightKeyPress,
        maxLength: 3,
        isLabeled: true,
        isRequired:
          complaintObject.ecomplaintId === 0 &&
          !complaintObject.onViewArrestOptionClickOnce
            ? false
            : complaintObject.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT || complaintObject.typeOfArrest === TYPE_OF_ARREST_SUMMON,
        autoComplete: "nope",
      },
      colSize: 1,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Weight</b>,
        name: "defWeight",
        value: complaintObject.defWeight,
        isError: errorKeys.includes("defWeight"),
        onChange: handleWeightInputChange,
        onKeyPress: handleWeightKeyPress,
        maxLength: 3,
        isLabeled: true,
        isRequired:
          complaintObject.ecomplaintId === 0 &&
          !complaintObject.onViewArrestOptionClickOnce
            ? false
            : complaintObject.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT || complaintObject.typeOfArrest === TYPE_OF_ARREST_SUMMON,
        autoComplete: "nope",
      },
      colSize: 1,
    },
    {
      type: "checkbox",
      props: {
        label: <b>Unknown Address</b>,
        name: "defAddressUnknown",
        value: complaintObject.defAddressUnknown,
        onChange: handelChangeCheck,
        checked: complaintObject.defAddressUnknown,
        autoComplete: "nope",
        isDisabled: chkUnknownAddress,
      },
      colSize: "auto",
      colClassName: "mt-1",
    },
    {
      type: "checkbox",
      props: {
        label: <b>Foreign Address</b>,
        name: "defForeignAddress",
        value: complaintObject.defForeignAddress,
        onChange: handelChangeCheck,
        checked: complaintObject.defForeignAddress,
        autoComplete: "nope",
        isDisabled: chkForeignAddress,
      },
      colSize: "auto",
      emptyCol: 6,
      colClassName: "mt-1",
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Address Line 1</b>,
        name: "defAddressLine1",
        value: complaintObject.defAddressLine1,
        isError: errorKeys.includes("defAddressLine1"),
        onChange: handleUpperCaseInputChange,
        isLabeled: true,
        isRequired: !complaintObject.defAddressUnknown,
        autoComplete: "nope",
        disabled: txtAddress1,
        maxLength: 35,
      },
      colSize: 4,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Address Line 2</b>,
        name: "defAddressLine2",
        value: complaintObject.defAddressLine2,
        isError: errorKeys.includes("defAddressLine2"),
        onChange: handleUpperCaseInputChange,
        isLabeled: true,
        autoComplete: "nope",
        disabled: txtAddress2,
        maxLength: 35,
      },
      colSize: 4,
      emptyCol: 4,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>City</b>,
        name: "defCity",
        value: complaintObject.defCity,
        isError: errorKeys.includes("defCity"),
        onChange: handleUpperCaseInputChange,
        isLabeled: true,
        isRequired: !complaintObject.defAddressUnknown,
        autoComplete: "nope",
        disabled: txtCity,
        maxLength: 25,
      },
      colSize: 3,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...STATE_LIST.map((sta: any) => {
            return { label: sta, value: sta };
          }),
        ],
        labelName: <b>State</b>,
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...STATE_LIST.map((sta: any) => {
            return { label: sta, value: sta };
          }),
        ].find((item) => {
          if (item.label === complaintObject.defState) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.defState) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defState",
        isError: errorKeys.includes("defState"),
        onChange: handleSelectDropDown("defState"),
        isSelected: true,
        isLabeled: true,
        isDefaultLabel: true,
        isDisabled: txtState,
        isRequired: !complaintObject.defAddressUnknown,
        autoComplete: "nope",
      },
      colSize: 2,
      colHide: complaintObject.defForeignAddress,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>State/Province</b>,
        name: "defState",
        value: complaintObject.defState,
        isError: errorKeys.includes("defState"),
        onChange: handleUpperCaseInputChange,
        isLabeled: true,
        isRequired: complaintObject.defForeignAddress,
        autoComplete: "nope",
      },
      colSize: 2,
      colHide: !complaintObject.defForeignAddress,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Zip</b>,
        name: "defZip",
        placeholder: "XXXXX-XXXX",
        value: complaintObject.defZip,
        isError: errorKeys.includes("defZip"),
        onChange: handleInputChange,
        onKeyPress: handleNumbersDashKeyPress,
        onBlur: handleFormatZip,
        isLabeled: true,
        disabled: txtZipFa,
        isRequired: !complaintObject.defAddressUnknown,
        maxLength: 10,
        autoComplete: "nope",
      },
      colSize: 2,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...countries.map((cntry: any) => {
            return { label: cntry, value: cntry };
          }),
        ],
        labelName: <b>Country</b>,
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...countries.map((cntry: any) => {
            return { label: cntry, value: cntry };
          }),
        ].find((item) => {
          if (item.label === complaintObject.defCountry) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.defCountry) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defCountry",
        isError: errorKeys.includes("defCountry"),
        onChange: handleSelectDropDown("defCountry"),
        isSelected: true,
        isLabeled: true,
        isDefaultLabel: true,
        isRequired: !complaintObject.defAddressUnknown,
        autoComplete: "nope",
        isDisabled: comboCountry,
      },
      colSize: 3,
      emptyCol: 2,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Phone</b>,
        name: "defPhoneNumber1",
        placeholder: "XXX-XXX-XXXX",
        value: complaintObject.defPhoneNumber1,
        onChange: handleInputChange,
        onKeyPress: handleNumbersDashKeyPress,
        onBlur: phoneNumberFormat,
        isError: errorKeys.includes("defPhoneNumber1"),
        isLabeled: true,
        maxLength: 12,
        autoComplete: "nope",
      },
      colSize: 2,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Defendant Email</b>,
        name: "defEmail",
        value: complaintObject.defEmail,
        onChange: handleInputChange,
        isLabeled: true,
        isError: errorKeys.includes("defEmail"),
        autoComplete: "nope",
        disabled: txtDefEmail,
        maxLength: 50,
      },
      colSize: 3,
      emptyCol: 7,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>DL Number</b>,
        name: "defDriverlicense",
        value: complaintObject.defDriverlicense,
        isError:
          errorKeys.includes("defDlState") ||
          errorKeys.includes("defDriverlicense"),
        isWarning:
          warningKeys.includes("defDlState") ||
          warningKeys.includes("defDriverlicense"),
        isRequired:
          !complaintObject.defDriverlicense && complaintObject.defDlState,
        onChange: handleUpperCaseInputChange,
        isLabeled: true,
        autoComplete: "nope",
        disabled: txtDLNo,
      },
      colSize: 3,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...STATE_LIST.map((sta: any) => {
            return { label: sta, value: sta };
          }),
        ],
        labelName: <b>DL State</b>,
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...STATE_LIST.map((sta: any) => {
            return { label: sta, value: sta };
          }),
        ].find((item) => {
          if (item.label === complaintObject.defDlState) {
            return { label: item.label, value: item.value };
          } else if (!complaintObject.defDlState) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defDlState",
        isError:
          errorKeys.includes("defDlState") ||
          errorKeys.includes("defDriverlicense"),
        isWarning:
          warningKeys.includes("defDlState") ||
          warningKeys.includes("defDriverlicense"),
        isRequired:
          !complaintObject.defDlState && complaintObject.defDriverlicense,
        onChange: handleSelectDropDown("defDlState"),
        isSelected: true,
        isLabeled: true,
        isDefaultLabel: true,
        autoComplete: "nope",
        isDisabled: comboDLState,
      },
      colSize: 2,
      emptyCol: 7,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Document Control Number</b>,
        name: "dcn",
        value: complaintObject.dcn,
        onChange: handleUpperCaseInputChange,
        isLabeled: true,
        isError: errorKeys.includes("dcn"),
        autoComplete: "nope",
        disabled: txtDocControlNo,
        maxLength: 12,
      },
      colSize: 3,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>SID</b>,
        name: "defSID",
        value: complaintObject.defSID,
        onChange: handleInputChange,
        isLabeled: true,
        isError: errorKeys.includes("defSID"),
        autoComplete: "nope",
        maxLength: 30,
      },
      colSize: 3,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>FBI</b>,
        name: "defFBI",
        value: complaintObject.defFBI,
        onChange: handleInputChange,
        isLabeled: true,
        isError: errorKeys.includes("defFBI"),
        autoComplete: "nope",
        maxLength: 30,
      },
      colSize: 3,
      emptyCol: 3,
    },
    {
      type: "textInput",
      props: {
        type: "Date",
        labelName: <b>Next Court Date</b>,
        name: "nextCourtDate",
        value: complaintObject.nextCourtDate,
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        min: moment(new Date()).format("YYYY-MM-DD"),
        isRequired: false,
        isLabeled: true,
        isError: errorKeys.includes("nextCourtDate"),
        autoComplete: "nope",
      },
      colSize: 2,
      colHide: complaintObject.countyName !== MCHENRY_COUNTY,
    },
    {
      type: "textInput",
      props: {
        type: "time",
        labelName: <b>Next Court Time</b>,
        name: "nextCourtTime",
        value: complaintObject.nextCourtTime,
        onChange: handleInputChange,
        isRequired: false,
        isLabeled: true,
        autoComplete: "nope",
      },
      colSize: 2,
      colHide: complaintObject.countyName !== MCHENRY_COUNTY,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Court Location</b>,
        name: "nextCourtLocation",
        value: complaintObject.nextCourtLocation,
        onChange: handleInputChange,
        isLabeled: true,
        autoComplete: "nope",
      },
      colSize: 3,
      colHide: complaintObject.countyName !== MCHENRY_COUNTY,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: <b>Total Bond</b>,
        name: "totalBond",
        value: complaintObject.totalBond,
        onChange: handleInputChange,
        isLabeled: true,
        autoComplete: "nope",
      },
      colSize: 3,
      emptyCol: 2,
      colHide: complaintObject.countyName !== MCHENRY_COUNTY,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
    },

    {
      type: "button",
      props: {
        children:
          complaintObject.ecomplaintId > 0
            ? "UPDATE COMPLAINT"
            : "SAVE NEW COMPLAINT",
        className: "mx-2",
        onClick: () => {
          validateAndSaveComp();
        },
        disabled: btnSaveNewComplaint,
      },
    },
    {
      type: "button",
      props: {
        children: "Cancel",
        className: "btn-danger",
        onClick: () => {
          cancel();
        },
      },
    },
  ];
  return (
    <>
      <Header headerName="Complaint Information" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Complaint Information"
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <WarningMessageAlert
          messages={warningMessageAlert.messages}
          clearWarning={warningMessageAlert.clearWarning}
        />
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data}
          components={[
            {
              type: "formButton",
              node: (props: any) => (
                <ButtonComponent {...props}>{props.children}</ButtonComponent>
              ),
            },
            {
              type: "tooltipTextField",
              node: (props: any) => (
                <OverlayTrigger
                  placement="top"
                  trigger="focus"
                  overlay={
                    <Tooltip id="tooltip-disabled">{props.tooltip}</Tooltip>
                  }
                >
                  <TextInputComponent {...props} />
                </OverlayTrigger>
              ),
            },
            {
              type: "customComponents",
              node: () => (
                <>
                  <Row>
                    <Col xs={"auto"} className={"mt-1"}>
                      <b>Type of Arrest</b>{" "}
                      <span className={"text-danger"}>*</span>
                    </Col>
                    <Col xs={"auto"}>
                      <div>
                        <CheckBoxComponent
                          label="ON VIEW/WARRANTLESS ARREST"
                          type="radio"
                          name="onViewArrest"
                          isError={errorKeys.includes(
                            "onViewArrestOptionClickOnce"
                          )}
                          onChange={handelChangeCheck}
                          checked={
                            complaintObject.ecomplaintId === 0 &&
                            !complaintObject.onViewArrestOptionClickOnce
                              ? false
                              : complaintObject.typeOfArrest ===
                                TYPE_OF_ARREST_ONVIEWARREST
                          }
                        />
                      </div>
                      <div className={safeTactEnable ? "d-block" : "d-none"}>
                        <div
                          className={
                            errorKeys.includes("onViewArrest_option")
                              ? "ms-5 ps-3 pe-3 border-danger border rounded"
                              : "ps-5"
                          }
                        >
                          <Form.Check
                            type="radio"
                            label={
                              "Defendant to be released on own recognizance"
                            }
                            name="onViewArrest_option"
                            value="onViewArrestWarrantDefToBeRelease"
                            onChange={handelChangeSubCheck}
                            checked={
                              complaintObject.postSafeTAct &&
                              complaintObject.defendantReleased
                                ? true
                                : false
                            }
                            disabled={
                              complaintObject.typeOfArrest !==
                              TYPE_OF_ARREST_ONVIEWARREST
                            }
                          />
                          <Form.Check
                            type="radio"
                            label={
                              "Defendant will be held and brought before a Judge"
                            }
                            name="onViewArrest_option"
                            value="onViewArrestWarrantDefWillBeHeld"
                            onChange={handelChangeSubCheck}
                            checked={
                              complaintObject.postSafeTAct &&
                              !complaintObject.defendantReleased
                                ? true
                                : false
                            }
                            disabled={
                              complaintObject.typeOfArrest !==
                              TYPE_OF_ARREST_ONVIEWARREST
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <Row>
                          <Col xs={"auto"}>
                            <CheckBoxComponent
                              label="ARREST WARRANT"
                              type="radio"
                              name="arrestWarrant"
                              isError={errorKeys.includes(
                                "onViewArrestOptionClickOnce"
                              )}
                              onChange={handelChangeCheck}
                              checked={
                                complaintObject.ecomplaintId === 0 &&
                                !complaintObject.onViewArrestOptionClickOnce
                                  ? false
                                  : complaintObject.typeOfArrest ===
                                    TYPE_OF_ARREST_ARRESTWARRANT
                              }
                            />
                          </Col>
                          <Col xs={"auto"}>
                            <div>
                              <div style={{ display: "inline-block" }}>
                                <b>IMPOUND CASE?</b>
                              </div>
                              <div
                                style={{ display: "inline-block" }}
                                className="ms-1"
                              >
                                <CheckBoxComponent
                                  type="checkbox"
                                  name="impoundWarrant"
                                  onChange={handelChangeCheck}
                                  checked={complaintObject.impoundWarrant}
                                  disabled={
                                    (complaintObject.ecomplaintId === 0 &&
                                      !complaintObject.onViewArrestOptionClickOnce) ||
                                    complaintObject.typeOfArrest !==
                                      TYPE_OF_ARREST_ARRESTWARRANT
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        {ecCountyList.filter(
                          (county) =>
                            county.summonsToAppear === true &&
                            complaintObject.countyName === county.countyName
                        ).length > 0 && (
                          <CheckBoxComponent
                            label="SUMMON"
                            type="radio"
                            name="summon"
                            isError={errorKeys.includes(
                              "onViewArrestOptionClickOnce"
                            )}
                            onChange={handelChangeCheck}
                            checked={
                              complaintObject.typeOfArrest ===
                              TYPE_OF_ARREST_SUMMON
                            }
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              ),
            },
          ]}
        />
      </div>

      {confirmDeleteDoc && (
        <ConfirmPopup
          children={confirmText}
          show={confirmDeleteDoc}
          onHide={() => setConfirmDeleteDoc(false)}
          isConfirmation={() => continueOnSaveCompaintAfterDocChange(true)}
        />
      )}

      {showIncidentNumberConfirmPopup && (
        <IncidentNumberConfirmPopup
          incidentNoFormat={incidentNoFormat}
          show={showIncidentNumberConfirmPopup}
          onHide={() => setShowIncidentNumberConfirmPopup(false)}
          isConfirmation={continueOnSaveCompaintAfterIncidentNumberChange}
        />
      )}

      <OptionPopup
        show={changingFillingCounty.show}
        header="Confirmation"
        children={"Changing filing county may delete all the selected counts."}
        buttons={[
          {
            name: "OK",
            variant: "primary",
            onClick: continueOnChangingFillingCounty,
          },
          {
            name: "Cancel",
            variant: "danger",
            onClick: () =>
              setChangingFillingCounty({
                ...changingFillingCounty,
                show: false,
              }),
          },
        ]}
      />

      <LookupWebRMSPopup
        show={lookupWebRMS}
        onHide={() => setLookupWebRMS(false)}
      />
    </>
  );
};
export default EComplaintCaseInfoPage;
