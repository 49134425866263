import React, { useMemo } from "react";
import { Table } from "react-bootstrap";

export type TableComponentProps = {
  tableHeader: string[];
  tableData: string[][];
  renderTableHeader?: JSX.Element;
  renderTableRow?: (item: string[]) => JSX.Element;
  isLoading?: boolean;
  emptyTableMessage?: string;
};
/**
 * The TableComponent is a wrapper component that contains a Table.
 * This component takes the following props:
 * @param {Array} tableHeader - An array of strings that will be the header of the table.
 * @param {Array} tableData - An array of arrays that will be the data of the table.
 * This is a 2D array where each array is a row of the table.
 * @param {JSX.Element} renderTableHeader - If you want to customize the table header you can pass a JSX element that will be rendered as the header of the table.
 * @param {(item: string[]) => JSX.Element} renderTableRow - If you want to customize the table rows you can pass a function that will be rendered as the rows of the table.
 * @param {boolean} isLoading - A boolean that will determine if the table is loading or not.
 * @param {string} emptyTableMessage - A string that will be displayed when the table has no data.
 */
const TableComponent: React.FC<TableComponentProps> = (props) => {
  const {
    tableHeader,
    tableData,
    renderTableHeader,
    renderTableRow,
    isLoading,
    emptyTableMessage = "No data to display",
  } = props;

  const _renderTableRow = useMemo(() => {
    if (renderTableRow) {
      return renderTableRow;
    }
  }, [renderTableRow]);

  return (
    <Table bordered className="mt-3" responsive hover>
      <thead className="table-secondary">
        <tr>
          {renderTableHeader
            ? renderTableHeader
            : tableHeader instanceof Array &&
              tableHeader.map((item) => <th key={item}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {!isLoading &&
          tableData instanceof Array &&
          tableData.length > 0 &&
          tableData.map((rowItems, i) =>
            _renderTableRow ? (
              _renderTableRow(rowItems)
            ) : (
              <tr key={i}>
                {rowItems.map((item) => (
                  <td key={item}>{item}</td>
                ))}
              </tr>
            )
          )}
        {(!tableData ||
          (tableData instanceof Array &&
            tableData.length === 0 &&
            !isLoading)) && (
          <tr>
            <td
              colSpan={tableHeader.length}
              className="text-center bg-secondary text-white"
            >
              <h6 style={{marginTop:5}}>{emptyTableMessage}</h6>
            </td>
          </tr>
        )}
        {isLoading && (
          <tr>
            <td
              colSpan={tableHeader.length}
              className="text-center bg-secondary text-white"
            >
              <h6 style={{marginTop:5}}>Loading...</h6>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
export default TableComponent;
