import React, { useCallback } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch } from "../../hooks/hooks";
import { createSearchWarrant, updateElementValueSearchWarrantReducer } from "../../redux/slice/searchWarrantSlice";
import { useSearchWarrantListReducer } from "../../redux/slice/searchWarrantListSlice";
import { agencyListByCounty, resetSelectCountyAgency, updateElementValueSelectCountyAgencyReducer, useSelectCountyAgencyReducer } from "../../redux/slice/selectCountyAgencySlice";
import { useUserProfilesReducer } from "../../redux/slice/userProfileSlice";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import ButtonComponent from "../common/ButtonComponent";
import SelectInput from "../common/SelectComponent";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { ROLE_POLICE_OFFICER } from "../../constants/common";

let defaultSchema = yup.object().shape({
    agencyId: yup.number().min(1,"Agency is required."),
    countyId: yup.number().min(1,"County is required."),
    enableOfficerField: yup.boolean(),
    officerUserProfileId: yup.number()
    .when('enableOfficerField', {
      is: true,
      then: yup.number().min(1,'Police Officer is required.'),
    })
  });

interface SelectCountyAgencyProps {
    show: boolean;
    onHide: () => void;
    children?: React.ReactNode;
}

const SelectCountyAgencyPopup: React.FC<SelectCountyAgencyProps> = (props) => {
    const { show, onHide, children } = props;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const errorMessageAlert = useAlert();
    const [errorKeys, setErrorKeys] = React.useState([""]);
    const { countyList } = useSearchWarrantListReducer();
    const { countyID, countyName, agencyId, agencyName, agenciesForCounty,
        officerUserProfileId, officerNameBadge, policeOfficers} = useSelectCountyAgencyReducer();
    const {userType} =useLoginReducer();
    const [enableOfficerField, setEnableOfficerField] = React.useState(false);
    const [policeOfficersList, setPoliceOfficersList] = React.useState([{}]);

    const hidePopup = () => {
        setEnableOfficerField(false);
        errorMessageAlert.clearError();
        errorMessageAlert.handleError([]);
        setErrorKeys([''])
        dispatch(resetSelectCountyAgency());
        setPoliceOfficersList([]);
        onHide();
    };

    const onChangeHandle = (event: any) => {
        dispatch(
            updateElementValueSearchWarrantReducer({
              elementName: event.name,
              value: event.value,
            })
          );
    };

    const handleCountyValChange = (event: any) => {
        let { label, value } = event;
        const countyId:number = parseInt(value);
        dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "countyID", value: countyId}));
        dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "countyName", value: label}));
        if (agenciesForCounty.length != 1){
            dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "agencyId", value: "0"}));
            dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "agencyName", value: ""}));
            setEnableOfficerField(false);
        }
        if (!countyId || countyId === 0) {
        }
        if (countyId && countyId > 0 && countyID !== countyId) {
            dispatch(agencyListByCounty({ countyId }));
        }
    }

    const handleAgencyValChange = (event: any) => {
        let { label, value} = event;
        let selectAgencyId = parseInt(value);
        dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "agencyId", value: selectAgencyId}));
        dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "agencyName", value: label}));
        dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "officerUserProfileId", value: "0"}));
        dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "officerNameBadge", value: ""}));
        getPoliceOfficerList(selectAgencyId);
    }

    const getPoliceOfficerList = (selectAgencyId: number) =>{
        let officers = [];
        for (let i = 0; i < policeOfficers.length; i++) {
            const officer:any = policeOfficers[i];
            if (officer.agencyId === selectAgencyId) {
                officers.push(officer);
            }
        }
        setPoliceOfficersList(officers);
    }

    const handleOfficerValChange = (event: any) => {
        let { label, value} = event;
        let officerId = parseInt(value);

        dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "officerUserProfileId", value: officerId}));
        dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "officerNameBadge", value: label}));
    }

    const onClickHandle = (event: any) => {
        setEnableOfficerField(event.target.checked);
        if (event.target.checked){
            dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "officerUserProfileId", value: "0"}));
            dispatch(updateElementValueSelectCountyAgencyReducer({elementName: "officerNameBadge", value: ""}));
            if (agenciesForCounty.length===1){
                getPoliceOfficerList(agencyId);
            }
        }
    };

    const handleSubmit = async () => {
        let dataObj = {
            agencyId: agencyId,
            countyId: countyID,
            onBehalfId: (officerUserProfileId ? officerUserProfileId : 0),
            navigate: navigate,
        };
        let dataObj1 = {
            enableOfficerField: enableOfficerField,
            agencyId: agencyId,
            countyId: countyID,
            officerUserProfileId: officerUserProfileId
        };
        // dispatch(createSearchWarrant(dataObj));
        // dispatch(resetSelectCountyAgency());

        try {
            await defaultSchema.validate(dataObj1, {
                abortEarly: false,
            });
            dispatch(createSearchWarrant(dataObj));
            dispatch(resetSelectCountyAgency());
        } catch (err: any) {
            handleError(
                err.inner.map((e: any) => e.path),
                err.errors
            );
        }
    };

    const handleError = useCallback(
        (_errorKeys: string[], errorMessages: string[]) => {
            setErrorKeys(_errorKeys);
            errorMessageAlert.handleError(errorMessages);
            window.scroll({ top: 0, behavior: "smooth" });
        },
        []
    );

    const label = (officer:any) =>{
        return((officer.firstName) ? (officer.firstName) : "")
        + ((officer.lastName) ? (" " + officer.lastName) : "")
            + ((officer.badgeNumber) ? (" - " + officer.badgeNumber) : " - 0")
    }

    const value = (officer:any) =>{
        return(officer.userProfileID)
    }

    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => hidePopup()}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <b>Select County and Agency</b>
            </Modal.Header>
            <ErrorMessageAlert
                messages={errorMessageAlert.messages}
                clearError={errorMessageAlert.clearError}
            />
            <Modal.Body>
                {/* <FormComponent totalColumns={12} colSize={6} componentData={formData} /> */}
                <Row>
                    <div className="col-sm-2 pt-2">
                        <label className="col-form-label">County<span className="text-danger">*</span>&nbsp;: </label>
                    </div>
                    <div className="col-sm-8 pt-2">
                        <SelectInput
                            name="County"
                            placeholder="-- Select --"
                            isError = {errorKeys.includes("countyId")?true:false}
                            options={[
                                ...countyList.map((county: any) => {
                                    return {
                                        label: county.countyName, value: county.countyID
                                    };
                                }),
                            ]}
                            value={countyID > 0 ? [{label: countyName, value: "" + countyID}] : []}
                            onChange={handleCountyValChange}
                            isDisabled = {(countyList.length===1)?true:false}
                            isDefaultLabel = {true}
                        />
                    </div>
                </Row>
                <Row>
                    <div className="col-sm-2 pt-2">
                        <label className="col-form-label">Agency<span className="text-danger">*</span>&nbsp;:</label>
                    </div>
                    <div className="col-sm-8 pt-2">
                        <SelectInput
                            name="agency"
                            placeholder="-- Select --"
                            isError = {errorKeys.includes("agencyId")?true:false}
                            options={[
                                ...agenciesForCounty.map((agency: any) => {
                                    return {
                                        label: agency.agencyCode + " - " + agency.agencyName, value: agency.agencyId
                                    };
                                }),
                            ]}
                            value={agencyId > 0 ? [{label: agencyName, value: "" + agencyId}] : []}
                            onChange={handleAgencyValChange}
                            isDisabled = {(agenciesForCounty.length===1)?true:false}
                            isDefaultLabel = {true}
                        />
                    </div>
                </Row>

                {(userType != ROLE_POLICE_OFFICER) ?
                <Row>
                    <Col className="pt-2">
                        <Form.Check
                            inline
                            name="group1"
                            type="checkbox"
                            className="fw-bold me-2"
                            //defaultChecked={resetViaEmail}
                            onChange={onClickHandle}
                            disabled = {(agencyId>0 && countyID>0)?false:true}
                            checked = {enableOfficerField}
                        />
                        <label className = {(agencyId>0 && countyID>0)?"":"text-disabled"}>
                            Create Search Warrant on behalf of Police Officer.
                        </label>
                    </Col>
                </Row>:""
                }
                {
                    enableOfficerField ?
                        <Row>
                            <div className="col-sm-3 pt-2">
                                <label className="col-form-label">Officer<span className="text-danger">*</span>&nbsp;:</label>
                            </div>
                            <div className="col-sm-9 pt-2">
                                <SelectInput
                                    name="officer"
                                    isRequired
                                    placeholder="Select Officer"
                                    isError = {errorKeys.includes("officerUserProfileId")?true:false}
                                    options={[
                                        ...policeOfficersList.map((officer: any) => {
                                            return {
                                                label: ((officer.firstName) ? (officer.firstName) : "")
                                                + ((officer.lastName) ? (" " + officer.lastName) : "")
                                                    + ((officer.badgeNumber) ? (" - " + officer.badgeNumber) : " - 0"), value: officer.userProfileID
                                            };
                                        }),
                                    ]}
                                    value = {(policeOfficersList.length != 1) ? ((officerUserProfileId > 0) ? [{label: officerNameBadge, value: "" + officerUserProfileId}] : []):[{label:label(policeOfficers[0]), value:value(policeOfficers[0])}]}
                                    onChange={handleOfficerValChange}
                                    isDisabled = {(policeOfficersList.length===1)?true:false}
                                />
                            </div>
                        </Row> : ""
                }

                <hr />
                <Row className="g-2">
                    <Col>
                        <ButtonComponent
                            variant="primary"
                            onClick={handleSubmit}
                            className="w-100"
                        >
                            <RiCheckFill /> Ok
                        </ButtonComponent>
                    </Col>
                    <Col>
                        <ButtonComponent
                            variant="danger"
                            onClick={() => hidePopup()}
                            className="w-100"
                        >
                            <RiCloseFill /> Cancel
                        </ButtonComponent>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default SelectCountyAgencyPopup;
