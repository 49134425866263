import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import {
  setComplaintMainData,
} from "./complaintMainSlice";
import { updateElementValuecomplaintDashReducer } from "./ecomplaintDashboardSlice";
import { formatNameWithSuffix } from "../../utils/common";
import {
  LF_SEND_TO_JUDGE_STATUS,
  LF_CASE_ASSIGNMENT_READY_STATUS,
  LF_STATUS_PENDING_SUPERVISOR_REVIEW,
} from "../../constants/common";
import moment from "moment";
import { getLFCConcurrentObj } from "./commonFormSlice";

interface CertifyReducer {
  txtStateAttorney: string;
  officerAsComplainingWitness: boolean;
  certifierName: string;
  optionalID: string;
  certifyDate: string;

  officerAsComplainingWitnessRequired: boolean;

  complaintStatus: LoadingType;
  complaintError?: string;
  successMessage?: string;

  gotoWhichUI?: string;
}

const initialState = {
  txtStateAttorney: "",
  officerAsComplainingWitness: false,
  certifierName: "",
  optionalID: "",
  certifyDate: "",

  officerAsComplainingWitnessRequired: false,

  complaintStatus: "idle",
  complaintError: "",
  successMessage: "",

  gotoWhichUI: "none",
} as CertifyReducer;

export const signAndNotarization = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    signType: string;
    certifyData: any;
    navigate: any;
    cancelPopup: any;
  },
  { state: RootState; rejectValue: string }
>(
  "complaint/signAndNotarization",
  async (
    { ecomplaintId, signType, certifyData, navigate, cancelPopup },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url: `ecomplaintservices/signAndNotarization/${ecomplaintId}/${signType}`,
        token,
        body: certifyData,
        type: "PUT",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let userRes = await response.json();
        dispatch(setComplaintMainData(userRes));
        let gotoUI: string = "ConfirmPopup";
        let msg = "";
        if (LF_SEND_TO_JUDGE_STATUS === userRes.complaint.caseStatus) {
          msg = "The complaint has been sent to judge for signing.";
          gotoUI = "DashBoard";
        } else if (
          LF_CASE_ASSIGNMENT_READY_STATUS === userRes.complaint.caseStatus
        ) {
          msg = "The complaint is ready for case assignment.";
          gotoUI = "DashBoard";
        } else if (
          LF_STATUS_PENDING_SUPERVISOR_REVIEW === userRes.complaint.caseStatus
        ) {
          msg = "The complaint has been sent to supervisor review.";
          gotoUI = "DashBoard";
        }
        dispatch(
          updateElementValuecomplaintDashReducer({
            elementName: "successMessage",
            value: msg,
          })
        );
        cancelPopup(gotoUI);
        if (gotoUI === "DashBoard") {
          navigate("/EComplaintDashboard");
        }
        return {
          gotoUI: gotoUI,
          message: msg,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(error || "Error while certifying complaint");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while certifying complaint");
    }
  }
);



export const checkNotarize = createAsyncThunk<
  any,
  { ecomplaint : object;
    password : string;
    commissionNo : string;
     },
  { state: RootState; rejectValue: string }
>(
  "complaint/checkNotarize",
  async ({ecomplaint, password, commissionNo }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    let queryParam = "";
    queryParam += `commissionNo=${commissionNo}&`; 
    queryParam += `password=${password}&`;

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      const response = await doFetch({
        url: `ecomplaintservices/checkNotarize?`+ `${queryParam}`,
        token,
        type : "POST",
        body : ecomplaint,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while certifying complaint");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while certifying complaint");
    }
  }
);

const getComplainingWitness = (witnesses: any) => {
  if (witnesses) {
    for (let i: number = 0; i < witnesses.length; i++) {
      if (witnesses[i].complainingWitness) {
        return witnesses[i];
      }
    }
  }
  return undefined;
};

const certificationSlice = createSlice({
  name: "CertifyComplaint",
  initialState,
  reducers: {
    updateElementValueCertifyReducer: updateElementValue,
    resetCertifyData: (state: CertifyReducer) => {
      return {
        ...state,
        ...initialState,
      };
    },
    loadInitialData: (state: CertifyReducer, action) => {
      let officerAsComplainingWitnessRequiredFlag = true;
      let cName: string = "";
      let optionID: string = "";
      let cDate = "";
      let compWitness = undefined;
      if (!action.payload.clickOnOfficerAsComplainingWitness) {
        compWitness = getComplainingWitness(action.payload.witnesses);
      }
      if (compWitness) {
        cName = formatNameWithSuffix(
          compWitness.firstName,
          compWitness.middleName,
          compWitness.lastName,
          compWitness.suffix
        );
        cName = cName ? cName.toUpperCase() : "";
        officerAsComplainingWitnessRequiredFlag = false;
        cDate = moment(new Date()).format("YYYY-MM-DD");
      } else if (action.payload.clickOnOfficerAsComplainingWitness) {
        if (action.payload.complaint) {
          let com = action.payload.complaint;
          cName = formatNameWithSuffix(
            com.officerFirstName,
            com.officerLastName,
            "",
            ""
          );
          let bag: string = com.officerBadge;
          let bagd: number = 0;
          try {
            bagd = bag ? parseInt(bag) : 0;
          } catch (error: any) {
            bagd = 0;
          }
          optionID = bagd > 0 ? "" + bagd : "";
          cDate = moment(new Date()).format("YYYY-MM-DD");
        }
      }

      return {
        ...state,
        certifierName: cName,
        optionalID: optionID,
        certifyDate: cDate,
        officerAsComplainingWitnessRequired:
          officerAsComplainingWitnessRequiredFlag,
      };
    },
  },
  extraReducers(builder) {
    builder
      // Create user
      .addCase(signAndNotarization.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(signAndNotarization.fulfilled, (state, action) => {
        state.complaintStatus = "success";
        state.gotoWhichUI = action.payload.gotoUI;
        state.successMessage = action.payload.message;
      })
      .addCase(signAndNotarization.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = action.payload;
      })
      .addCase(checkNotarize.pending, (state) => {
        state.complaintStatus = "loading";
      })
      .addCase(checkNotarize.fulfilled, (state) => {
        state.complaintStatus = "success";
      })
      .addCase(checkNotarize.rejected, (state) => {
        state.complaintStatus = "error";
      });
  },
});

export const {
  updateElementValueCertifyReducer,
  resetCertifyData,
  loadInitialData,
} = certificationSlice.actions;

export const useCertificationReducer = () =>
  useAppSelector((state) => state.certificationForm);

export default certificationSlice.reducer;
