import React,{useEffect,useCallback, useRef} from "react";
import { Card, Row, Col,Form } from "react-bootstrap";
import TextInputComponent from "../../common/TextInputComponent";
import Header from "../../common/Header";
import { RiSaveLine,RiArrowRightLine, RiBallPenLine, RiSave3Line, RiUserShared2Line, RiFileTextLine, RiCloseLine } from "react-icons/ri";
import BreadcrumbComponent from "../../common/BreadcrumbComponent";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/hooks";
import { getSearchWarrantDetail, insertInventory, updateInventory, useInventoryReducer,
  updateElementObjectValue, signInventory, returnInventory, updateInventrySignDocGenerateError, updateElementValueInventoryReducer} from "../../../redux/slice/inventorySlice";
import { getSWCaseDetail, useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";
import { useLoginReducer } from "../../../redux/slice/loginSlice";
import { SEARCH_WAR_INCOMPLETE_STATUS,SEARCH_WAR_TEMPLATE_TYPE_INVENTORY,SEARCH_WAR_OFFICER_PENDING_STATUS,SEARCH_WAR_COMPLETE_STATUS,SEARCH_WAR_ASSIGNED_TO_AG_STATUS,SEARCH_WAR_ASSIGNED_TO_LP_STATUS,SEARCH_WAR_ASSIGNED_TO_SA_STATUS,USER_TYPES,ACTIONTYPE_SAVE,SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS,INVENTORY, ROLE_ADMINISTRATOR,ROLE_COUNTY_ADMIN,SEARCH_WAR_LOCKED_BY_JUDGE_STATUS,INVENTORY_RETURN_TYPE_VERIFICATION,INVENTORY_RETURN_TYPE_VIDEO,ROLE_JUDGE} from "../../../constants/common";
import { Util } from "../../common/FormUtil";
import ConfirmPopup from "../../../components/common/ConfirmPopup";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";
import FormComponent from "../../common/FormComponent";
import * as yup from "yup";
import { previewOrder, useCommonFormReducer } from "../../../redux/slice/commonFormSlice";
import { setSignature, updateElementValueInSignPanelReducer, useSignPanelReducer } from "../../../redux/slice/signPanelSlice";
import { formatName, validateDate } from "../../../utils/common";
import {validateSignaturePixel} from "../../common/CommonUtil"
import RemarkPopup from "../../common/RemarkPopup";
import InfoPopup from "../../../components/common/InfoPopup";
import OptionPopup from "../../../components/common/OptionsPopup";
import moment from 'moment';
import { updateSearchWarrantDetail } from "../../../redux/slice/searchWarrantSlice";
import ButtonComponent from "../../common/ButtonComponent";
import FormFiller from "../../common/FormFiller";
import { useDashboardReducer, updateElementValueDashboardReducer } from "../../../redux/slice/dashboardSlice";
import { getJudgeSavedSignature } from "../../../redux/slice/userProfileSlice";

interface InventoryProps {}

let fieldTitles =[' ','and the seizure of','LIST SEIZED ITEMS HERE'];
let names = ['txtPersonPremises','txtSeized1','txtSeized2'];

const Inventory: React.FC<InventoryProps> = (props) => {

  const dashboardReducer = useDashboardReducer();
  const impoundcaseselection = useRef<HTMLDivElement>(null);
  const  onBreadcrumbClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }

  let defaultInventorySchema = yup.object().shape({
    txtPersonPremiseslbl: yup.string().nullable().required("Person or premises value for Seized information is required."),
    txtPresentingDate: yup.date().nullable().required("Date of presenting seized inventory Judge is required.").max(yup.ref('currentDate'), "Date of presenting seized inventory information to Judge cannot be a future date.").min(yup.ref('txtExecutionDate'),"Date of presenting seized inventory information to Judge should be after warrant execution date."),
    txtSeized2:yup.string().nullable().required("Seized is required."),
    sign: yup.string().nullable().required("Signature is required.")

  });
   
  const dispatch = useAppDispatch();
  const caseDetailReducer = useCaseDetailReducer();
  const inventoryReducer = useInventoryReducer()
  const loginReducer = useLoginReducer();
  const signPanelReducer = useSignPanelReducer();
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    isConfirm: false,
    clickButtonName: "",
  });
  const [confirmText,setconfirmText ] = React.useState<React.ReactNode>();
  const [confirmOriginPlace , setconfirmOriginPlace ] = React.useState<React.ReactNode>();
  const [signatureCanvasVisible , setSignatureCanvasVisible ] = React.useState(false);
  const errorMessageAlert = useAlert();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const [complainantNameFooter, setComplainantNameFooter] = React.useState(""); 
  const [whoSign, setWhoSign] = React.useState("");
  const [swornText, setSwornText] = React.useState("");
  const[btnSaveVisible, setBtnSaveVisible] = React.useState(false);
  const[btnSaveAsIncompleteVisible, setBtnSaveAsIncompleteVisible] = React.useState(false);
  const[btnSubmitVisible,setBtnSubmitVisible] = React.useState(false);
  const[btnSignVisible,setBtnSignVisible] = React.useState(false);
  const[btnPreviewVisible , setBtnPreviewVisible] = React.useState(false);
  const[btnRevertVisible,setBtnRevertVisible] = React.useState(false);
  const[unImpoundCaseVisible,setUnImpoundCaseVisible] = React.useState(false);
  const[radioYes,setRadioYes] = React.useState(false);
  const[radioNo,setRadioNo] = React.useState(false);
  const[unImpoundCaseFlag,setUnImpoundCaseFlag] = React.useState(false);
  const[lblDefaultVerbiageInventoryVisible,setLblDefaultVerbiageInventoryVisible] = React.useState(false);
  const [btnRevertLabel, setbtnRevertLabel] = React.useState("Return To Officer");
  const [lblImpoundUnImpoundCase, setlblImpoundUnImpoundCase] = React.useState("");
  const[lblInventoryReturnVisible,setLblInventoryReturnVisible] = React.useState(false);
  const[userName , setUserName] = React.useState("");
  const [txtPresentingJudge,setTxtPresentingJudge]=React.useState("");
  const[signConfirmationModalShow, setSignConfirmationModalShow] = React.useState(false);
  const[signConfirmMessage,setSignConfirmMessage] = React.useState("");
  const[signConfirmationYes, setSignConfirmationYes] =  React.useState(false);
  const[showInfoModalMessage, setShowInfoModalMessage] = React.useState("");
  const [combinedText , setCombinedText] = React.useState("");
  const[remarkModalShow,setRemarkModalShow] = React.useState(false);
  const [remarkFor,setRemarkFor] = React.useState("");
  const [remarkRequired, setRemarkRequired] = React.useState(false)
  const [action , setAction] = React.useState("")
  const [remarkStatus , setRemarkStatus] = React.useState("")
  const [remark , setRemark] = React.useState("")
  const [showInfoModal , setShowInfoModal]  = React.useState(false)
  const [showInfoPopup, setShowInfoPopup] =React.useState({
    show: false,
    info: "",
  });
  const navigate = useNavigate();
  const commonFormreducer = useCommonFormReducer();

  const [showFormFiller, setShowFormFiller] = React.useState(false);
  const [fieldTitleValues, setFieldTitleValues] = React.useState<String[]>([]);

  let isLoaded = false;
  const saveInventory = async() => {
    let Obj = {
      txtPersonPremiseslbl: (inventoryReducer.inventoryObject.txtPersonPremiseslbl !== null) ? inventoryReducer.inventoryObject.txtPersonPremiseslbl : null,
      txtPresentingDate: (inventoryReducer.inventoryObject.hasOwnProperty('txtPresentingDate')) ? ((inventoryReducer.inventoryObject.txtPresentingDate !== null) ? (inventoryReducer.inventoryObject.txtPresentingDate.trim().length > 0 ? new Date(inventoryReducer.inventoryObject.txtPresentingDate) : null) : null) : null,
      presentingDate: (inventoryReducer.inventoryObject.hasOwnProperty('txtPresentingDate')) ? ((inventoryReducer.inventoryObject.txtPresentingDate !== null) ? (inventoryReducer.inventoryObject.txtPresentingDate.trim().length > 0 ? new Date(inventoryReducer.inventoryObject.txtPresentingDate) : null) : null) : null,
      txtSeized2: (inventoryReducer.inventoryObject.txtSeized2 !== null) ? inventoryReducer.inventoryObject.txtSeized2 : null,
      sign: signPanelReducer.sign,
      currentDate: new Date(),
      txtExecutionDate: (inventoryReducer.inventoryObject.txtExecutionDate !== null) ? (inventoryReducer.inventoryObject.txtExecutionDate.trim().length > 0 ? new Date(inventoryReducer.inventoryObject.txtExecutionDate) : null) : null
    }
    try {
      await defaultInventorySchema.validate(Obj, {
        abortEarly: false,
      });
      const { userProfileId, userType } = loginReducer;
      let { formStatus, creatorId, creatorUserType } = inventoryReducer.inventoryObject;
      // let { autoCompleteInventory } = caseDetailReducer;

      let status = SEARCH_WAR_OFFICER_PENDING_STATUS;
      if (USER_TYPES["POLICE OFFICER"] === userType || USER_TYPES["AGENCY ADMIN"] === userType) {
        // if (autoCompleteInventory) {
        //   status = SEARCH_WAR_COMPLETE_STATUS;
        //   formStatus = status;
        // }
      }
      if (USER_TYPES["ATTORNEY GENERAL"] === userType) {
        status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
      } else if (USER_TYPES["LOCAL PROSECUTOR"] === userType) {
        status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
      } else if (USER_TYPES["STATES ATTORNEY"] === userType) {
        status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
      }

      dispatch(updateElementObjectValue({
        elementName: "formStatus",
        value: status,
      }))
      if (signatureCanvasVisible) {
        if (USER_TYPES["JUDGE"] === loginReducer.userType) {
          dispatch(updateElementObjectValue({
            elementName: "hiddenJudgeSignature",
            value: signPanelReducer.sign,
          }))

        } else {
          dispatch(updateElementObjectValue({
            elementName: "hiddenComplaintSignature",
            value: signPanelReducer.sign,
          }))
        }
      }
      dispatch(updateElementObjectValue({
        elementName: "unImpoundCase",
        value: unImpoundCaseFlag,
      }))
      dispatch(updateElementObjectValue({
        elementName: "updateImpounded",
        value: unImpoundCaseVisible,
      }))
      if (inventoryReducer.inventoryObject.searchWarrantId && inventoryReducer.inventoryObject.searchWarrantId > 0) {
        let confirmMsg = Util.getConfirmMessage(ACTIONTYPE_SAVE, SEARCH_WAR_TEMPLATE_TYPE_INVENTORY,
          userProfileId, creatorId, formStatus, userType, creatorUserType, 0);
        setconfirmText(confirmMsg);
        setconfirmOriginPlace("saveClick")

      } else {
        let confirmMsg = Util.getConfirmMessage(ACTIONTYPE_SAVE, SEARCH_WAR_TEMPLATE_TYPE_INVENTORY,
          userProfileId, creatorId, formStatus, userType, creatorUserType, 0);
        setconfirmText(confirmMsg);
        setconfirmOriginPlace("saveForTheFirstTime")
      }

      setconfirmPopupData({ isConfirm: true, clickButtonName: "onSave" });
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }
  
  const saveIncompleteInventory = () => {
    const { userProfileId, userType } = loginReducer;
    let { formStatus, creatorId, creatorUserType } = inventoryReducer.inventoryObject;
    let status = SEARCH_WAR_INCOMPLETE_STATUS

    dispatch(updateElementObjectValue({
      elementName: "formStatus",
      value: status,
    }))
    if (signatureCanvasVisible) {
      if (USER_TYPES["JUDGE"] === loginReducer.userType) {
        dispatch(updateElementObjectValue({
          elementName: "hiddenJudgeSignature",
          value: signPanelReducer.sign,
        }))

      } else {
        dispatch(updateElementObjectValue({
          elementName: "hiddenComplaintSignature",
          value: signPanelReducer.sign,
        }))
      }
    }
    dispatch(updateElementObjectValue({
      elementName: "unImpoundCase",
      value: unImpoundCaseFlag,
    }))
    dispatch(updateElementObjectValue({
      elementName: "updateImpounded",
      value: unImpoundCaseVisible,
    }))
    if (inventoryReducer.inventoryObject.searchWarrantId && inventoryReducer.inventoryObject.searchWarrantId > 0) {
      let confirmMsg = Util.getConfirmMessage(SEARCH_WAR_INCOMPLETE_STATUS, SEARCH_WAR_TEMPLATE_TYPE_INVENTORY,
        userProfileId, creatorId, formStatus, userType, creatorUserType, 0);
      setconfirmText(confirmMsg);
      setconfirmOriginPlace("saveIncompleteClick")

    } else {
      let confirmMsg = Util.getConfirmMessage(SEARCH_WAR_INCOMPLETE_STATUS, SEARCH_WAR_TEMPLATE_TYPE_INVENTORY,
        userProfileId, creatorId, formStatus, userType, creatorUserType, 0);
      setconfirmText(confirmMsg);
      setconfirmOriginPlace("saveForTheFirstTime")
    }

    setconfirmPopupData({ isConfirm: true, clickButtonName: "onSaveInComplete" });

  }

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const prepareMessage = (msg: String) => {
    let buff = [];
    if (msg && msg.trim().length > 0) {
      let arr = msg.split("<br>");
      let line = "";
      for (let i = 0; i < arr.length; i++) {
        line = arr[i];
        buff.push(<div>{line}</div>);
      }
    }
    return buff;
  }

  const saveSubmit= async () => {
    let Obj={
      txtPersonPremiseslbl: (inventoryReducer.inventoryObject.txtPersonPremiseslbl !== null)?inventoryReducer.inventoryObject.txtPersonPremiseslbl:null,
      txtPresentingDate: (inventoryReducer.inventoryObject.hasOwnProperty('txtPresentingDate'))?((inventoryReducer.inventoryObject.txtPresentingDate !== null) ? (inventoryReducer.inventoryObject.txtPresentingDate.trim().length > 0 ? new Date(inventoryReducer.inventoryObject.txtPresentingDate) : null) : null):null,
      presentingDate:(inventoryReducer.inventoryObject.hasOwnProperty('txtPresentingDate'))?((inventoryReducer.inventoryObject.txtPresentingDate !== null) ? (inventoryReducer.inventoryObject.txtPresentingDate.trim().length > 0 ? new Date(inventoryReducer.inventoryObject.txtPresentingDate) : null) : null):null,
      txtSeized2: (inventoryReducer.inventoryObject.txtSeized2 !== null)?inventoryReducer.inventoryObject.txtSeized2:null,
      sign: signPanelReducer.sign,
      currentDate: new Date(),
      txtExecutionDate:(inventoryReducer.inventoryObject.txtExecutionDate !== null)?(inventoryReducer.inventoryObject.txtExecutionDate.trim().length > 0 ? new Date(inventoryReducer.inventoryObject.txtExecutionDate) : null):null
   }
    try {
      await defaultInventorySchema.validate(Obj, {
        abortEarly: false,
      });
      let status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS

      dispatch(updateElementObjectValue({
        elementName: "formStatus",
        value: status,
      }))
      if (signatureCanvasVisible) {
        if (USER_TYPES["JUDGE"] === loginReducer.userType) {
          dispatch(updateElementObjectValue({
            elementName: "hiddenJudgeSignature",
            value: signPanelReducer.sign,
          }))

        } else {
          dispatch(updateElementObjectValue({
            elementName: "hiddenComplaintSignature",
            value: signPanelReducer.sign,
          }))
        }
      }
      // if (inventoryReducer.inventoryObject.remark && inventoryReducer.inventoryObject.remark.trim().length > 0) {
      //   let confirmMsg = "Inventory application can be resubmitted after it is modified as per remarks. "
      //     + "Click on remarks link to view details.";
      //   setShowInfoPopup({
      //     info: confirmMsg,
      //     show: true,
      //   });
      // } else {
        dispatch(updateElementObjectValue({
          elementName: "unImpoundCase",
          value: unImpoundCaseFlag,
        }))
        dispatch(updateElementObjectValue({
          elementName: "updateImpounded",
          value: unImpoundCaseVisible,
        }))
        if (inventoryReducer.inventoryObject.searchWarrantId && inventoryReducer.inventoryObject.searchWarrantId > 0) {
          let confirmMsg = "Do you want to submit the form for review to judge?";
          setconfirmText(confirmMsg);
          setconfirmOriginPlace("submitClick")

        } else {
          let confirmMsg = "Do you want to submit the form for review to judge?";
          setconfirmText(confirmMsg);
          setconfirmOriginPlace("saveForTheFirstTime")
        }
        setconfirmPopupData({ isConfirm: true, clickButtonName: "onSaveSubmit" });
      // }



    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
    
    

  }
 
  const handleChangeObjectValue = (event: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  const handleValOnBlur = (event: any) => {
    let {name, value} = event.target;
       value = validateDate(value);
    dispatch(updateElementObjectValue({ elementName: name, value: value }));
  }

  const handleCkEditorValChange = (name: string, editor: any) => {
    dispatch(updateElementObjectValue({ elementName: name, value: editor.getData() }));
  }

  const handleConfirmation = () => {
    
    if(confirmPopupData.clickButtonName === "onSave"){
      if (confirmOriginPlace === "saveClick"){
        continueOnSaveOrSaveIncompleteProcess("saveClick")
      }
      if (confirmOriginPlace === "saveForTheFirstTime"){
        continueOnSaveForTheFirstTimeProcess("onSave")
      }

    }
    if (confirmPopupData.clickButtonName === "onSaveInComplete"){
      if (confirmOriginPlace === "saveIncompleteClick"){
        continueOnSaveOrSaveIncompleteProcess("saveIncompleteClick")
      }
      if (confirmOriginPlace === "saveForTheFirstTime"){
        continueOnSaveForTheFirstTimeProcess("onSaveInComplete")
      }

    }
    if (confirmPopupData.clickButtonName ==="onSaveSubmit"){
      if (confirmOriginPlace === "submitClick") {
        continueOnSaveOrSaveIncompleteProcess("submitClick");
      }
      if (confirmOriginPlace === "saveForTheFirstTime"){
        continueOnSaveForTheFirstTimeProcess("onSaveSubmit")
      }

    }
    if (confirmPopupData.clickButtonName ==="signClick"){

      afterConfirmHandleSignClick()

    }
    if (confirmPopupData.clickButtonName === "returnClick"){
      afterConfirmHandleReturnClick()
    }    
  }
  
  const afterConfirmHandleSignClick = () => {
     continueOnSignProcess("YES");
  }

  const continueOnSignProcess = (validSpecialChars: string) => {
    let dataObjSign = {
      searchWarrantId: inventoryReducer.inventoryObject.searchWarrantId,
      inventory: inventoryReducer.inventoryObject,
      navigate: navigate,
      navigateTo: `/CaseDetails`,
      isOFCRequired: caseDetailReducer.county.isOFCRequired,
      validateSpecialChars: validSpecialChars,
    };

    dispatch(signInventory(dataObjSign))
  }

  const afterConfirmHandleReturnClick = () => {
    let navigatePath ="";
    if (loginReducer.summonsToAppear){
      navigatePath = "/WarrantsSummonsList";
    }else{
      navigatePath = "/WarrantList";
    }
    let returnObj = {
      searchWarrantId:inventoryReducer.inventoryObject.searchWarrantId,
      inventory:inventoryReducer.inventoryObject,
      navigate:navigate,
      navigateTo:navigatePath
    }
    dispatch(returnInventory(returnObj))
  }


  const continueOnSaveOrSaveIncompleteProcess = async (callfrom: string): Promise<void> => {
    let dataObjSave = {
      searchWarrantId: inventoryReducer.inventoryObject.searchWarrantId,
      inventory: inventoryReducer.inventoryObject,
      navigate: navigate,
      navigateTo: `/CaseDetails`,
      callfrom: callfrom
    };
    dispatch(updateInventory(dataObjSave))
  }

  /*const onSubmit = () =>{
    let proxy = {
      formStatus: inventoryReducer.inventoryObject.formStatus,
      remarks: "",
      notificationCallFrom: "Submit",
      formType: INVENTORY,
    };
    dispatch(
      updateSearchWarrantDetail({
        searchWarrantId: inventoryReducer.inventoryObject.searchWarrantId,
        action: "updateStatus",
        warrantFormObj: proxy,
        navigate: navigate,
        navigateTo: -1,
      })
    );
    //navigate(-1);
  }*/

  const continueOnSaveForTheFirstTimeProcess = (callingfrom: string) => {
    let dataObjSaveForFirstTime = {
      searchWarrantId: caseDetailReducer.searchWarrantId,
      inventory: inventoryReducer.inventoryObject,
      navigate: navigate,
      navigateTo: `/CaseDetails`,
      callingfrom: callingfrom,
    };
    dispatch(insertInventory(dataObjSaveForFirstTime));
  }
  
  const cancelSignConfirmModal = () => {
    setSignConfirmationModalShow(false)
  }

  const acceptSignConfirmModal = () => {
    setSignConfirmationModalShow(false)
    setSignConfirmationYes(true)
    saveInventory();
  }
  
  const handleYesChange = (event: any) => {
    let { name, checked, value } = event.target;
    if (typeof inventoryReducer.inventoryObject.isCaseImpounded !== 'undefined') {
      if (inventoryReducer.inventoryObject.isCaseImpounded) {
        dispatch(updateElementObjectValue({
          elementName: name,
          value: checked,
        }))
        setUnImpoundCaseFlag(checked)

      } else if (!inventoryReducer.inventoryObject.isCaseImpounded) {
        dispatch(updateElementObjectValue({
          elementName: name,
          value: !checked,
        }))
        setUnImpoundCaseFlag(!checked)

      }
    } else {
      dispatch(updateElementObjectValue({
        elementName: name,
        value: false,
      }))
      setUnImpoundCaseFlag(false)

    }
    setRadioYes(true)
    setRadioNo(false)
  }

  const submitRemark = (remark:any, remarkFor:any) => {
    if ("RETURN" === remarkFor) {
        returnForm(remark);
    }
  }

  const returnForm = (remark:any) => {
        const { userProfileId, userType } = loginReducer;
        let { formStatus, creatorId, onBehalf, creatorUserType } = inventoryReducer.inventoryObject;
        let status = ""
        status = SEARCH_WAR_OFFICER_PENDING_STATUS
        setRemark(remark)
        if (!onBehalf) {
            if (USER_TYPES["ATTORNEY GENERAL"] === creatorUserType) {
              status = SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS
            } else if (USER_TYPES["LOCAL PROSECUTOR"] === creatorUserType) {
              status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS
            } else if (USER_TYPES["STATES ATTORNEY"] === creatorUserType) {
              status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS
            }
        }
        let confirmMsg = Util.getConfirmMessage(status, INVENTORY,
          userProfileId, creatorId, formStatus, userType, creatorUserType, 0);
        setRemarkStatus(status)
        if (signatureCanvasVisible) {
          if (ROLE_JUDGE === loginReducer.userType) {
            dispatch(updateElementObjectValue({
              elementName: "hiddenJudgeSignature",
              value: signPanelReducer.sign,
            }))
    
          } else {
            dispatch(updateElementObjectValue({
              elementName: "hiddenComplaintSignature",
              value: signPanelReducer.sign,
            }))
          }
        }
        dispatch(updateElementObjectValue({
          elementName: "formStatus",
          value: status,
        }))
        dispatch(updateElementObjectValue({
          elementName: "remark",
          value: remark,
        }))
        dispatch(updateElementObjectValue({
          elementName: "unImpoundCase",
          value: unImpoundCaseFlag,
        }))
        dispatch(updateElementObjectValue({
          elementName: "updateImpounded",
          value: unImpoundCaseVisible,
        }))
        if(confirmMsg){
            setconfirmText(confirmMsg);
            setconfirmPopupData({ isConfirm: true, clickButtonName: "returnClick" });
        }
        

  }
  const remarkModalClose = () => {
    setRemarkModalShow(false)
  };

  const signClick = async () => {
    let Obj = {
      txtPersonPremiseslbl: inventoryReducer.inventoryObject.txtPersonPremiseslbl,
      txtPresentingDate: inventoryReducer.inventoryObject.txtPresentingDate,
      presentingDate: (inventoryReducer.inventoryObject.txtPresentingDate !== null) ? (inventoryReducer.inventoryObject.txtPresentingDate.trim().length > 0 ? new Date(inventoryReducer.inventoryObject.txtPresentingDate) : null) : null,
      txtSeized2: inventoryReducer.inventoryObject.txtSeized2,
      sign: signPanelReducer.sign,
      currentDate: new Date(),
      txtExecutionDate: inventoryReducer.inventoryObject.txtExecutionDate.trim().length > 0 ? new Date(inventoryReducer.inventoryObject.txtExecutionDate) : null
    }
    try {
      await defaultInventorySchema.validate(Obj, {
        abortEarly: false,
      });
      let isValidateSignPixel = validateSignaturePixel(signatureCanvasVisible, signPanelReducer.signThreshold);
      if (!isValidateSignPixel) {

        setSignConfirmationModalShow(true)
        setSignConfirmMessage('Are you sure the signature you provided is valid?')


      } else {
        signInventoryClick();
      }
    }catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
    
  }

  const signInventoryClick = () => {
    let formMessage = "Form cannot be changed once signed. Do you want to continue?";
    let inventoryReturnType = caseDetailReducer.county.inventoryReturnType;
    if (!inventoryReturnType || INVENTORY_RETURN_TYPE_VIDEO === inventoryReturnType) {
      formMessage = "Has the officer sworn to the content of this Search Warrant Inventory?";
    }
    if (signatureCanvasVisible) {
      if (USER_TYPES["JUDGE"] === loginReducer.userType) {
        dispatch(updateElementObjectValue({
          elementName: "hiddenJudgeSignature",
          value: signPanelReducer.sign,
        }))

      } else {
        dispatch(updateElementObjectValue({
          elementName: "hiddenComplaintSignature",
          value: signPanelReducer.sign,
        }))

      }
    }

    // inventoryObj.updateImpounded = unImpoundCaseVisible
    let status = SEARCH_WAR_COMPLETE_STATUS;
    dispatch(updateElementObjectValue({
      elementName: "formStatus",
      value: status,
    }))
    dispatch(updateElementObjectValue({
      elementName: "unImpoundCase",
      value: unImpoundCaseFlag,
    }))
    dispatch(updateElementObjectValue({
      elementName: "updateImpounded",
      value: unImpoundCaseVisible,
    }))
    setconfirmText(formMessage)
    setconfirmPopupData({
      isConfirm: true,
      clickButtonName: "signClick"
    })
  }

  const handleNoChange = (event: any) => {
    let { name, checked } = event.target;
    if (typeof inventoryReducer.inventoryObject.isCaseImpounded !== 'undefined') {
      if (inventoryReducer.inventoryObject.isCaseImpounded) {
        dispatch(updateElementObjectValue({
          elementName: name,
          value: !checked,
        }))
        setUnImpoundCaseFlag(!checked)
      } else if (!inventoryReducer.inventoryObject.isCaseImpounded) {
        dispatch(updateElementObjectValue({
          elementName: name,
          value: checked,
        }))
        setUnImpoundCaseFlag(checked)
      }
    } else {
      dispatch(updateElementObjectValue({
        elementName: name,
        value: false,
      }))
      setUnImpoundCaseFlag(false)

    }
    setRadioNo(true)
    setRadioYes(false)

  }

  const previewClick = () => {
    const inventoryObj = { ...inventoryReducer.inventoryObject };
    if (ROLE_JUDGE === loginReducer.userType) {
      dispatch(updateElementObjectValue({ elementName: "hiddenJudgeSignature", value: signPanelReducer.sign }));
      inventoryObj.hiddenJudgeSignature = signPanelReducer.sign;
    } else {
      dispatch(updateElementObjectValue({ elementName: "hiddenComplaintSignature", value: signPanelReducer.sign }));
      inventoryObj.hiddenComplaintSignature = signPanelReducer.sign;
    }
    inventoryObj.txtPresentingJudge = txtPresentingJudge;
    
    dispatch(
      previewOrder({
        formId: inventoryReducer.inventoryObject.searchWarrantId,
        formName: INVENTORY,
        formObject: inventoryObj,
      })
    );

  }

  const returnClick = async () => {
      setRemarkRequired(false);
      setRemarkFor("RETURN");
      setRemarkModalShow(true);
  }

  const cancelClick = () => {
    dispatch(getSWCaseDetail(({ searchWarrantId: caseDetailReducer.searchWarrantId, action: "cancelForm", navigate: navigate })));
  }

  const formFillerClick = () =>{
    let array = [
      inventoryReducer.inventoryObject.txtPersonPremises, inventoryReducer.inventoryObject.txtSeized1, inventoryReducer.inventoryObject.txtSeized2
    ];
    setShowFormFiller(true);
    setFieldTitleValues(array);
  }

  const updateFieldFromWizard = (name: string, value: string) =>{
    dispatch(updateElementObjectValue({elementName:name, value: value}));
  }
 
  const formData = [
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: "STATE OF ILLINOIS",
        className: "fw-bold text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h5",
        value: (
          <>
            UNITED STATES OF AMERICA
            <br />
            {inventoryReducer.inventoryObject.lblHeadingInTheCircuitSW}
          </>
        ),
        className: "fw-bold text-center",
      },
      colSize: 6,
    },
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: inventoryReducer.inventoryObject.lblHeadingCountySW,
        className: "fw-bold text-end text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        value: inventoryReducer.inventoryObject.txtAgency,
        name: "templateName",
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h4",
        value: "INVENTORY",
        className: "fw-bold text-center text-danger",
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        value: inventoryReducer.inventoryObject.txtCaseNumber,
        name: "templateName",
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {

      type: "customComponents",

      props: {},

      colSize: 12,
      emptyCol: 10,
    },
    {
      type: 'formButton',
      props: {
        children: "Form Filler Wizard",
        onClick: formFillerClick,
      },
      inLine: true,
      colSize: 2,
    },
    {
      type: "editor",
      props: {
        data: inventoryReducer.inventoryObject.txtPersonPremises
          ? inventoryReducer.inventoryObject.txtPersonPremises
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("txtPersonPremises", editor);
        },
        name: "txtPersonPremises",
        disabled: true,

        // isError: errorKeys.includes("orderTitle")},


      },

      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: "and the seizure of:",
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: inventoryReducer.inventoryObject.txtSeized1
          ? inventoryReducer.inventoryObject.txtSeized1
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("txtSeized1", editor);
        },
        name: "txtSeized1",
        disabled: true



      },

      colSize: 12,
    },
    {

      type: "customComponentsNew",

      props: {


      },

      colSize: 12,

    },
    {
      type: "htmlTag",
      props: {
        value: "LIST SEIZED ITEMS HERE :",
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: inventoryReducer.inventoryObject.txtSeized2
          ? inventoryReducer.inventoryObject.txtSeized2
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("txtSeized2", editor);

        },
        name: "txtSeized2",
        isError: errorKeys.includes("txtSeized2"),

      },

      colSize: 12,
    },
    {

      type: "customComponentsForImpounded",

      props: {


      },

      colSize: 12,

    },
    {
      type: "htmlTag",
      props: {
        value: "Under penalties as provided by law pursuant to Section 1-109 of the Code of Civil Procedure, the undersigned certifies that the statements set forth in this instrument are true and correct, except as to matters therein stated to be on information and belief and as to such matters the undersigned certifies as aforesaid that he verily believes the same to be true.",
      },
      colSize: 12,
    },
    /* not implmented in GWT search warrant */
    // {
    //   type: "fileUploader",
    //   props: {
    //     className: signatureCanvasVisible ? "" : "d-none",
    //     fileName: inventoryReducer.inventoryObject.uploadedFileName,
    //     formType: INVENTORY,
    //   },
    //   colSize: 12,
    // },

    {
      type: "signPanel",
      props: {
        className: signatureCanvasVisible ? "d-block" : "d-none",
        name: "sign",
        appearPersonText: complainantNameFooter,
        whoSign: whoSign,
        swText: swornText,
        isError: errorKeys.includes("sign"),
      },
      colSize: 12,
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: btnSaveVisible ? "btn-primary ms-2" : "d-none",
        children: (
          <>
            Save <RiSave3Line />
          </>
        ),
        onClick: () => {
          saveInventory();
        }
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: btnSaveAsIncompleteVisible ? "btn-primary ms-2" : "d-none",
        children: (
          <>
            Save Incomplete <RiSaveLine />
          </>
        ),
        onClick: () => {
          saveIncompleteInventory();
        }
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: btnSubmitVisible ? "btn-success ms-2" : "d-none",
        children: (
          <>
            Submit To Judge <RiUserShared2Line />
          </>
        ),
        onClick: () => {
          saveSubmit();
        }
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        variant:"success",
        className: btnSignVisible ? "btn-success ms-2" : "d-none",
        children: (
          <>
            Sign <RiBallPenLine />
          </>
        ),
        onClick: () => {
          signClick();
        }
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: btnRevertVisible ? "btn-danger ms-2" : "d-none",
        children: (
          <>
            {btnRevertLabel} <RiArrowRightLine />
          </>
        ),
        onClick: returnClick,
        actionName: { btnRevertLabel }
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-primary ms-2",
        children: (
          <>
            Preview <RiFileTextLine />
          </>
        ),
        onClick: () => {
          previewClick();
        }
      },
    },

    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-danger ms-2",
        children: (
          <>
            Cancel <RiCloseLine />
          </>
        ),
        onClick: () => {
          cancelClick();
        },
      },
    }
  ];

  useEffect(() => {
    if (impoundcaseselection.current && inventoryReducer.judgeSignControl) {

      const rect = impoundcaseselection.current.getBoundingClientRect();
      const offset = 50;

      window.scrollTo({
        top: window.scrollY + rect.top - offset,
        behavior: 'smooth',
      });
      dispatch(
        updateElementValueInventoryReducer({
          elementName: "judgeSignControl",
          value: false,
        })
      );
    }
  }, [impoundcaseselection.current])

  useEffect(() => {

    let combinedTextEditor = inventoryReducer.inventoryObject.inventoryStatement+" "+inventoryReducer.inventoryObject.txtPersonPremiseslbl+" "+"of";
    let isCaseImpounded = inventoryReducer.inventoryObject.isCaseImpounded ? inventoryReducer.inventoryObject.isCaseImpounded : false;
    setCombinedText(combinedTextEditor)
    if (typeof isCaseImpounded !== 'undefined' && isCaseImpounded) {
      if (radioYes) {
        setLblDefaultVerbiageInventoryVisible(true);
      }
      setlblImpoundUnImpoundCase("Case is currently impounded. Do you want to UN-impound it?");
    } else if (typeof isCaseImpounded !== 'undefined' && !isCaseImpounded) {
      if (!radioYes) {
        setLblDefaultVerbiageInventoryVisible(true);

      }
      setlblImpoundUnImpoundCase("Case is currently UN-impounded. Do you want to impound it?");
    }
    if (isLoaded === false) {

      isLoaded = true
      dispatch(getSearchWarrantDetail({ searchWarrantId: caseDetailReducer.searchWarrantId, navigate: navigate }));
      setSignatureCanvasVisible(true)
      let userName = formatName(loginReducer.firstName, loginReducer.lastName, "");
      setComplainantNameFooter(userName);
      setWhoSign("SIGNATURE OF COMPLAINANT");
      setSwornText("(Officer Signature will be only applied after the content of this inventory is sworn before a judge)");
      let loggedInUserRole = loginReducer.userType ? loginReducer.userType : "";
      let formStatus = inventoryReducer.inventoryObject.formStatus ? inventoryReducer.inventoryObject.formStatus : "";
      let loggedInUserId = loginReducer.userProfileId ? loginReducer.userProfileId : "";
      let creatorId = inventoryReducer.inventoryObject.creatorId ? inventoryReducer.inventoryObject.creatorId : "";
      let txtPresentingJudge = inventoryReducer.inventoryObject.txtPresentingJudge ? inventoryReducer.inventoryObject.txtPresentingJudge : "";
      setTxtPresentingJudge(txtPresentingJudge)
      let userType = loginReducer.userType ? loginReducer.userType : "";
      // let autoCompleteInventory = caseDetailReducer.autoCompleteInventory ? caseDetailReducer.autoCompleteInventory : "";
      let county = caseDetailReducer.county ? caseDetailReducer.county : "";
      let onBehalf = inventoryReducer.inventoryObject.onBehalf ? inventoryReducer.inventoryObject.onBehalf : "";
      let unImpoundCase = inventoryReducer.inventoryObject.unImpoundCase ? inventoryReducer.inventoryObject.unImpoundCase : "";
      
      let creatorUserType = inventoryReducer.inventoryObject.creatorUserType ? inventoryReducer.inventoryObject.creatorUserType : "";
      
      setShowInfoModalMessage("")
      if (typeof unImpoundCase !== 'undefined') {
        if (typeof isCaseImpounded !== 'undefined' && isCaseImpounded) {
          if (unImpoundCase) {
            setRadioYes(true);
            setRadioNo(false);
          } else {
            setRadioYes(false);
            setRadioNo(true);
          }

        } else {
          if (unImpoundCase) {
            setRadioYes(false);
            setRadioNo(true);
          } else {
            setRadioYes(true);
            setRadioNo(false);
          }
        }
      } else {
        setRadioYes(false);
        setRadioNo(true);
      }

      

      if (typeof isCaseImpounded !== 'undefined') {
        if (isCaseImpounded) {
          setUnImpoundCaseFlag(!radioYes);
        } else if (!isCaseImpounded) {
          setUnImpoundCaseFlag(radioYes);
        }
      } else {
        setUnImpoundCaseFlag(false);
      }



      if (county.inventoryReturnType && INVENTORY_RETURN_TYPE_VERIFICATION === county.inventoryReturnType) {
        setLblInventoryReturnVisible(true)
      }

      if (!onBehalf) {
        if (creatorUserType === USER_TYPES["LOCAL PROSECUTOR"]) {
          setbtnRevertLabel("RETURN TO LP");
        } else if (creatorUserType === USER_TYPES["ATTORNEY GENERAL"]) {
          setbtnRevertLabel("RETURN TO AG");
        } else if (creatorUserType === USER_TYPES["STATES ATTORNEY"]) {
          setbtnRevertLabel("RETURN TO SA");
        }
      }

      if (ROLE_ADMINISTRATOR === loggedInUserRole || ROLE_COUNTY_ADMIN === loggedInUserRole) {
        if ("" === formStatus || formStatus === SEARCH_WAR_INCOMPLETE_STATUS
          || formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) {
          setSignatureCanvasVisible(true)
          if (userName && userName.trim().length > 0) {
            setComplainantNameFooter(userName)
          }

          setBtnSaveVisible(true);
          setBtnSaveAsIncompleteVisible(true);
          setBtnSubmitVisible(true);
        }

        if (formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS || formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) {
          setSignatureCanvasVisible(true);
          setWhoSign("JUDGE SIGNATURE")
          if (userName && userName.trim().length > 0) {
            setComplainantNameFooter(userName);
          }
          txtPresentingJudge = (txtPresentingJudge && txtPresentingJudge.trim().length > 0)
            ? txtPresentingJudge.trim() : userName;
          setTxtPresentingJudge(txtPresentingJudge)
            

          setUnImpoundCaseVisible(true)

          setBtnSignVisible(true);
          setBtnPreviewVisible(true);
          setBtnRevertVisible(true);
        }
      } else {
        if (creatorId === loggedInUserId) {
          if (loggedInUserRole === USER_TYPES["POLICE OFFICER"] || loggedInUserRole === USER_TYPES["STATES ATTORNEY"]
            || loggedInUserRole === USER_TYPES["AGENCY ADMIN"] || loggedInUserRole === USER_TYPES["ATTORNEY GENERAL"]
            || loggedInUserRole === USER_TYPES["LOCAL PROSECUTOR"]) {
            setSignatureCanvasVisible(true);
            if (userName && userName.trim().length > 0) {
              setComplainantNameFooter(userName);
            }
          }
        }

        if (loggedInUserRole === USER_TYPES["POLICE OFFICER"] || loggedInUserRole === USER_TYPES["STATES ATTORNEY"]
          || loggedInUserRole === USER_TYPES["AGENCY ADMIN"] || loggedInUserRole === USER_TYPES["ATTORNEY GENERAL"]
          || loggedInUserRole === USER_TYPES["LOCAL PROSECUTOR"]) {
          setBtnSaveVisible(true);
          setBtnSaveAsIncompleteVisible(true);
          // if ((loggedInUserRole === USER_TYPES["POLICE OFFICER"] || USER_TYPES["AGENCY ADMIN"] === userType) && autoCompleteInventory) {
          //   setBtnSubmitVisible(false);
          // } else {
            setBtnSubmitVisible(true)
          // }



        }

        if (loggedInUserRole === USER_TYPES["JUDGE"]) {
          setSignatureCanvasVisible(true);
          setWhoSign("JUDGE SIGNATURE")
          if (userName && userName.trim().length > 0) {
            setComplainantNameFooter(userName);
          }
          txtPresentingJudge = (txtPresentingJudge && txtPresentingJudge.trim().length > 0)
            ? txtPresentingJudge.trim() : userName;
          setTxtPresentingJudge(txtPresentingJudge)
          setUnImpoundCaseVisible(true);

          setBtnSignVisible(true);
          setBtnPreviewVisible(true);
          setBtnRevertVisible(true);


        }
      }
    }

  }, [dispatch, isLoaded])

  let pdfError = [];
  if (inventoryReducer.inventrySignDocGenerateError && inventoryReducer.inventrySignDocGenerateErrorMsg) {
    pdfError.push(
      <div className="text-start">
        <b>
          When analyzing the content of this document, it was determined that the following areas may contain characters that are not compatible.
        </b>
      </div>
    );
    pdfError.push(<br></br>);
    for (const [key, value] of Object.entries(inventoryReducer.inventrySignDocGenerateErrorMsg)) {
      pdfError.push(<div className="text-start">{"Field: " + key}</div>);
      pdfError.push(<div className="text-start">{"Content: " + value}</div>);
    }
    pdfError.push(<br></br>);
    pdfError.push(
      <div className="text-start">
        <b>
          If you are certain that the document generates (Preview) successfully, please continue. Otherwise please change.
        </b>
      </div>
    );
  }

  const changeInvenDoc = () => {
    dispatch(
      updateInventrySignDocGenerateError({
        error: false,
        errorMsg: undefined,
      })
    );
  };

  const continueOnSignInventry = () => {
    dispatch(
      updateInventrySignDocGenerateError({
        error: false,
        errorMsg: undefined,
      })
    );
    continueOnSignProcess("NO");
  };

  const getItemsLink = () =>{
    if (loginReducer.summonsToAppear){
      return"WarrantsSummonsList";
    }else{
      return"WarrantList";
    }
  }

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/"+dashboardReducer.PreviousURLSW.replace(/^.*\/\/.*?\//, ''), itemName: getItemsLink(), itemFunction: onBreadcrumbClick  },
    { itemLink: "/CaseDetails", itemName: "Existing Forms" },
  ];

  return (
    <>
      <Header headerName="Search Warrant" />
      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="Inventory" />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent totalColumns={12} colSize={6} componentData={formData}
          components={[
            {
              type: "customComponents",
              node: () => (
                <>
                  <Row>
                    <Col xs={"auto"}>
                      <span className="me-3"> On </span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          name="txtExecutionDate"
                          disabled
                          value={inventoryReducer.inventoryObject.txtExecutionDate}
                        />
                      </div>
                      <span className="me-3">at</span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          name="txtExecutionTime"
                          disabled
                          value={moment(inventoryReducer.inventoryObject.txtExecutionTime, ["HH:mm"]).format("hh:mm A")}
                        />
                      </div>
                      <span className="me-3">(HH:mm AM/PM), I,</span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          name="txtComplainantName"
                          disabled
                          value={inventoryReducer.inventoryObject.txtComplainantName}
                        />
                      </div>
                      <span className="me-3">
                      the undersigned Officer/Employee, executed a Search Warrant, 
                      bearing the above number and signed by Judge
                      </span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          name="txtJudgeName"
                          disabled
                          value={inventoryReducer.inventoryObject.txtJudgeName}
                        />
                      </div>
                      <span className="me-3"> on </span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          name="txtJudgeIssuingDate"
                          disabled
                          value={inventoryReducer.inventoryObject.txtJudgeIssuingDate}
                        />
                      </div>
                      <span className="me-3"> at</span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          name="txtJudgeIssuingTime"
                          disabled
                          value={inventoryReducer.inventoryObject.txtJudgeIssuingTime}
                        />
                      </div>
                      <span className="me-3">
                        (HH:mm AM/PM){ inventoryReducer.inventoryObject.lblInventoryPrintText }
                      </span>
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              type: "customComponentsNew",
              node: (isError: any) => (
                <>
                  <Row>
                    <Col xs={"auto"}>
                      <span className="me-3">
                        In executing said Warrant, I seized the following from
                      </span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          name="txtPersonPremiseslbl"
                          value={inventoryReducer.inventoryObject.txtPersonPremiseslbl}
                          onChange={handleChangeObjectValue}
                          isError={errorKeys.includes("txtPersonPremiseslbl")}
                          placeholder="the Person and Premises"


                        />
                      </div>
                      <span className="me-3">
                        described above and have returned the same before Judge
                      </span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="text"
                          className="form-control"
                          name="txtPresentingJudge"
                          disabled
                          value={txtPresentingJudge}
                          onChange={handleChangeObjectValue}
                        />
                      </div>
                      <span className="me-3">on</span>
                      <div className="d-inline-block mt-2 me-3">
                        <TextInputComponent
                          type="date"
                          className="form-control"
                          name="txtPresentingDate"
                          value={inventoryReducer.inventoryObject.txtPresentingDate}
                          onChange={handleChangeObjectValue}
                          onBlur={handleValOnBlur}
                          isError={errorKeys.includes("txtPresentingDate")}

                        />
                      </div>
                    </Col>
                  </Row>
                </>
              ),
            }, {
              type: "customComponentsForImpounded",
              node: (isError: any) => (
                <>
                  {(radioYes && unImpoundCaseVisible) ?
                    <Row className="mt-3 mb-3 align-items-center no-gutters">
                      <Col xs={12} className="">
                        The impound order previously entered concerning the above captioned
                        search warrant number is hereby lifted by order of the court.
                      </Col>
                    </Row>
                    : ""}

                  {unImpoundCaseVisible ?
                    <Row ref={impoundcaseselection} className="mt-3 align-items-center justify-content-start no-gutters">
                      <Col xs={12} className="text-left">
                        <label style={{ marginBottom: "0px" }} className="pr-3">
                          <b>{lblImpoundUnImpoundCase}</b>
                        </label>&nbsp;&nbsp;&nbsp;
                        <Form.Check inline type="radio"
                          checked={radioYes}
                          name="unImpoundCase"
                          value={"Yes"}
                          id={"Yes"}
                          onChange={handleYesChange} />
                        <label className="ml-n2 pr-4">Yes</label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Check inline type="radio"
                          checked={radioNo}
                          name="unImpoundCase"
                          value={"No"}
                          id={"No"}
                          onChange={handleNoChange} />
                        <label className="ml-n2">No</label>
                      </Col>
                    </Row>
                    : ""}
                </>
              ),

            },
            {
              type: "formButton",
              node: (props: any) => (
                <div className="text-end">
                <ButtonComponent {...props}>{props.children}</ButtonComponent>
              </div>
                
              ),
            }
          ]} />
        <ConfirmPopup
        children={confirmText}
        show={confirmPopupData.isConfirm}
        onHide={() => setconfirmPopupData({ isConfirm: false, clickButtonName: "" })}
        isConfirmation={ handleConfirmation }
      />
      <ConfirmPopup 
        show={signConfirmationModalShow}
         onHide={cancelSignConfirmModal} 
        isConfirmation={acceptSignConfirmModal}>
        { prepareMessage(showInfoModalMessage) }
      </ConfirmPopup>

      <InfoPopup
        children={showInfoPopup.info}
        onHide={() =>
          setShowInfoPopup({
            show: false,
            info: "",
          })
        }
        show={showInfoPopup.show}
      />

    <OptionPopup
        show={inventoryReducer.inventrySignDocGenerateError}
        header="Confirmation"
        children={<div>{pdfError}</div>}
        buttons={[{ name: "Continue", variant: "primary", onClick: continueOnSignInventry },
        { name: "Change", variant: "primary", onClick: changeInvenDoc }]}
      />

      <RemarkPopup show={remarkModalShow} onHide={remarkModalClose}
                    submitRemark={submitRemark} remarkFor={remarkFor}
                    action={action} remarkRequired={remarkRequired}
                    onlyView={false} remarksForView={""} 
        />
        <FormFiller
          show={showFormFiller}
          onHide={() => setShowFormFiller(false)}
          fieldTitles={fieldTitles}
          names={names}
          values={fieldTitleValues}
          onChangeHandler={updateFieldFromWizard}
        />
      </div>
    </>
  );
};
export default Inventory;
