import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { APPLICATION_AND_ORDER, BLANK_ORDER, INVENTORY, AMENDED_INVENTORY, COMPL_SEARCH_WARRANT, ROLE_ADMINISTRATOR, ROLE_ATTORNEY_GENERAL, ROLE_JUDGE, ROLE_LOCAL_PROSECUTOR, ROLE_STATES_ATTORNEY, SEARCH_WAR_ASSIGNED_TO_AG_STATUS, SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS, SEARCH_WAR_ASSIGNED_TO_LP_STATUS, SEARCH_WAR_ASSIGNED_TO_SA_STATUS, SEARCH_WAR_DENIED_STATUS, SEARCH_WAR_LOCKED_BY_JUDGE_STATUS, SEARCH_WAR_OFFICER_PENDING_STATUS, SEARCH_WAR_VOID_STATUS, COMPL_SEARCH_WARRANT_WITH_SLASH, SW_MSG_CMSPENDING_SUCCESSFULLY } from "../../constants/common";
import { formatTextForCkEditor, setTemplateDataToFields } from "../../utils/common";
import { object } from "yup";
import { updateElementObject } from "../../utils/updateElementObject";
import { getSWCaseDetail, updateCaseDetailObject, updateElementValueCaseDetailReducer } from "./caseDetailSlice";
import { resetSignature } from "./signPanelSlice";
import { showHideLoadingGif, updatePdfGenerationError, updateDocGenerateError } from "./commonSlice";
import { updateElementValueInSearchWarrantListReducer } from "./searchWarrantListSlice";
import { Util } from "../../components/common/FormUtil";
import { getSearchWarrantData, getSWConcurrentObj } from "./commonFormSlice";
import CaseDetails from "../../components/warrantlist/CaseDetails";

export interface serachwarrantObj {

  //Search Warrant Complaint Form Data
  searchWarrantId: number;
  txtAgency: string;
  txtCaseNumber: string;
  txtAppears: string;
  searchWarrantType: string;
  templateId: number;
  approvalUserType: string;
  approvalUserTypeId: number;
  approvalSAId: number;
  badgeNo: string;
  formStatus: string;
  txtOffense1Header: string;
  txtOffense1: string;
  txtSeized1Header: string;
  txtSeized1: string;
  txtPoliceOfficerInfoHeader: string;
  txtPoliceOfficerInfo: string;
  txtPersonInfoHeader: string;
  txtPersonInfo: string;
  unImpoundCase: boolean;
  hiddenComplaintSignature: string;
  hiddenJudgeSignature: string;
  hiddenUserSignature: string;
  lblHeadingCountySW: string;
  lblHeadingInTheCircuitSW: string;
  countyNumberCircuitCourt: string;
  uploadedFileName: string;
  creatorId: number;
  creatorUserType: string;
  isOnBehalf: boolean;
  onBehalf: boolean;
  remark: string;
  agencyID: number;
  agencyName: string;
  agencyCode: string;
  countyID: number;
  countyName: number;
  isSARequired: boolean;
  lockUserId: number;
  hiddenUploadedPdf: string;
  agencyCaseNumber: string;
  securityPassword: string;
  protectedComplaint: boolean;
  // PR 0011324 - START
  personOfHeaderPrefix: string;
  personOfHeaderSuffix: string;
  offenseInfoHeaderSuffix: string;
  policeOfficerInfoHeaderSuffix: string;
  impoundLanguage: string;
  complaintHeaderPrefix: string;
  warrantField1Prefix: string;
  warrantField2Prefix: string;
  warrantHeader3Prefix: string;
  warrantOrderCommand: string;
  complaintTitle: string;
  swTitle: string;
  impoundComplaintLanguage: string;
  // PR 0011324 - END
}

interface searchWarrantState {

  searchWarrantObject: serachwarrantObj,

  swApprovalUserTypes: string[];
  warrantTemplateList: string[];
  fetchingError?: string;
  status: LoadingType;
  updateSwError?: string;
  updateSwStatus: LoadingType;
  swSuccessMsg: string;

}

const initialState = {
  swApprovalUserTypes: [],
  warrantTemplateList: [],
  fetchingError: undefined,
  status: "idle",
  updateSwError: undefined,
  updateSwStatus: "idle",
  swSuccessMsg: "",

  searchWarrantObject: {
    searchWarrantId: 0,
    //Search Warrant Complaint Form Data
    txtAgency: "",
    txtCaseNumber: "",
    txtAppears: "",
    searchWarrantType: "",
    templateId: 0,
    approvalUserType: "",
    approvalUserTypeId: 0,
    approvalSAId: 0,
    badgeNo: "",
    formStatus: "",
    txtOffense1Header: "",
    txtOffense1: "",
    txtSeized1Header: "",
    txtSeized1: "",
    txtPoliceOfficerInfoHeader: "",
    txtPoliceOfficerInfo: "",
    txtPersonInfoHeader: "",
    txtPersonInfo: "",
    unImpoundCase: false,
    hiddenComplaintSignature: "",
    hiddenJudgeSignature: "",
    hiddenUserSignature: "",
    lblHeadingCountySW: "",
    lblHeadingInTheCircuitSW: "",
    countyNumberCircuitCourt: "",
    uploadedFileName: "",
    creatorId: 0,
    creatorUserType: "",
    isOnBehalf: false,
    onBehalf: false,
    remark: "",
    agencyID: 0,
    agencyName: "",
    agencyCode: "",
    countyID: 0,
    countyName: 0,
    isSARequired: false,
    lockUserId: 0,
    hiddenUploadedPdf:"",
    agencyCaseNumber: "",
    securityPassword: "",
    protectedComplaint: false,
      // PR 0011324 - START
  personOfHeaderPrefix: "",
  personOfHeaderSuffix: "",
  offenseInfoHeaderSuffix: "",
  policeOfficerInfoHeaderSuffix: "",
  impoundLanguage: "",
  complaintHeaderPrefix: "",
  warrantField1Prefix: "",
  warrantField2Prefix: "",
  warrantHeader3Prefix: "",
  warrantOrderCommand: "",
  complaintTitle: "",
  swTitle: "",
  impoundComplaintLanguage: "",
  // PR 0011324 - END
  }
} as searchWarrantState;

export const createSearchWarrant = createAsyncThunk<
  any,
  {
    agencyId?: number;
    countyId?: number;
    onBehalfId?: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "searchWarrant/createSearchWarrant",
  async (data, { getState, rejectWithValue, dispatch }) => {
    dispatch(resetSearchWarrantData());
    dispatch(resetSignature());
    const { agencyId, countyId, onBehalfId, navigate } = data;
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url:
          "searchwarrantservices/createsearchwarrant/" +
          COMPL_SEARCH_WARRANT +
          "/" +
          agencyId +
          "/" +
          countyId +
          "/" +
          onBehalfId,
        token,
        body: "",
        type: "POST",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        navigate(`/SearchWarrant`);
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while creating SW");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while creating SW");
    }
  }
);

export const listSWApproveUserTypes = createAsyncThunk<
  any,
  {
    countyId?: number;
    agencyId?: number;
  },
  {
    state: RootState;
  }
>(
  "searchWarrant/listSWApproveUserTypes",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { countyId, agencyId } = data;

    let { token } = getState().login;
    let queryParam = `jwtToken=Bearer ${token}`;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url:
          "userprofileservices/getUserTypesForSWApproval/" +
          countyId +
          "/" +
          agencyId +
          `?${queryParam}`,
      });
      dispatch(showHideLoadingGif(false));
     return await reponse.json();
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);

export const getWarrantTemplateListWithData = createAsyncThunk<
  any,
  {
    countyId?: number;
    templateType?: string;
  },
  {
    state: RootState;
  }
>(
  "searchWarrant/getWarrantTemplateListWithData",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { countyId, templateType } = data;

    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse: any = await doFetch({
        url:"templatetypeservices/getWarrantTemplateListWithData/" + countyId + "/" + templateType,
        token,
      });
      dispatch(showHideLoadingGif(false));
      return await reponse.json();
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);

export const getWarrantTemplateData = createAsyncThunk<
  any,
  {
    countyId?: number;
    warrantForm?: string;
    templateName?: string;
  },
  {
    state: RootState;
  }
>(
  "searchWarrant/getWarrantTemplateData",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { countyId, warrantForm, templateName } = data;

    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    let queryParam = `jwtToken=Bearer ${token}`;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse: any = await doFetch({
        url:
          "templateservices/warranttemplatetypes/" +
          countyId +
          "/" +
          warrantForm +
          "/" +
          templateName +
          `?${queryParam}`,
          concurrentObject : concurrObj,
          concurrentIdentifier: "SW",
		      dispatch: dispatch
      });
      dispatch(showHideLoadingGif(false));
      return await reponse.json();
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);

export const updateSearchWarrant = createAsyncThunk<
  any,
  { searchWarrantId: number; warrantFormObj?: object; fileData: string, navigate: any, navigateTo: string, callingfrom: string },
  { state: RootState; rejectValue: string }
>("searchWarrant/updateSearchWarrant", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, warrantFormObj, navigate, navigateTo, callingfrom } = data;

  let queryParam = "";
  queryParam += `id=${searchWarrantId}`;
  let { token } = getState().login;
  dispatch(showHideLoadingGif(true));
  
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);

  try {
    const response = await doFetch({
      url: `searchwarrantservices/updatesearchwarrantform?${queryParam}`,
      type: "PUT",
      token,
      body: warrantFormObj,
      concurrentObject: concurrObj,
      concurrentIdentifier: "SW",
      dispatch: dispatch
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result) => {
        if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: result.errorInfo.validationErrors,
            })
          );
          return;
        } else {        
          let message:any =""
          if (callingfrom === "SWincomplete"){
            message = "Form data saved as Incomplete successfully."
          }
          result.responseMessage = message;
          let caseDetail = result;
          dispatch(updateCaseDetailObject(caseDetail));
        
          if (navigateTo === "/CaseDetails")
            dispatch(getSWCaseDetail({ searchWarrantId: searchWarrantId, action: "getDetails", navigate: navigate }));
          else
            navigate(navigateTo);
        }
      });
    } else {dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while updating search warrant");
    }
   
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Error while updating search warrant");
  }
}
);

export const saveSearchWarrant = createAsyncThunk<
  any,
  { searchWarrantId: number; proxy?: object; navigate: any, navigateTo: string },
  { state: RootState; rejectValue: string }
>("searchWarrant/saveSearchWarrant", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, proxy, navigate, navigateTo } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/savesearchwarrant/" + searchWarrantId,
      type: "PUT",
      token,
      body: proxy,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
		  dispatch: dispatch
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result) => {
        if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: result.errorInfo.validationErrors,
            })
          );
          return;
        } else {
          let message = "Form data saved as complete successfully.";

          result.responseMessage = message;

          let caseDetail = result;
          dispatch(updateCaseDetailObject(caseDetail));
        
          navigate(navigateTo);
        }
      });
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        COMPL_SEARCH_WARRANT_WITH_SLASH + " form data saving process is failed."
      );
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(
      error.message ||
        COMPL_SEARCH_WARRANT_WITH_SLASH + " form data saving process is failed."
    );
  }
}
);

export const submitSearchWarrant = createAsyncThunk<
  any,
  { searchWarrantId: number; proxy?: any; navigate: any, navigateTo: string },
  { state: RootState; rejectValue: string }
>("searchWarrant/submitSearchWarrant", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, proxy, navigate, navigateTo } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/submitsearchwarrant/" + searchWarrantId,
      type: "PUT",
      token,
      body: proxy,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
		  dispatch: dispatch
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result) => {
        if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: result.errorInfo.validationErrors,
            })
          );
          return;
        } else {
          let message = "";
          if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
            message = "Form submitted to SA for screening.";
          } else if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
            message = "Form submitted to LP for screening.";
          } else if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
            message = "Form submitted to AG for screening.";
          } else if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) {
            message = "Form submitted to Judge for approval.";
          }

          result.responseMessage = message;

          let caseDetail = result;
          dispatch(updateCaseDetailObject(caseDetail));

          navigate(navigateTo);
        }
      });
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        COMPL_SEARCH_WARRANT_WITH_SLASH +
          " form data submitting process is failed."
      );
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(
      error.message ||
        COMPL_SEARCH_WARRANT_WITH_SLASH +
          " form data submitting process is failed."
    );
  }
}
);

export const approveSearchwarrant = createAsyncThunk<
  any,
  { searchWarrantId: number; proxy?: object; navigate: any, navigateTo: string },
  { state: RootState; rejectValue: string }
>("searchWarrant/approveSearchwarrant", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, proxy, navigate, navigateTo } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/approvesearchwarrant/" + searchWarrantId,
      type: "PUT",
      token,
      body: proxy,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
		  dispatch: dispatch
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result) => {
        if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: result.errorInfo.validationErrors,
            })
          );
          return;
        } else {
          let message =
            "Search Warrant application for " +
            getState().searchWarrant.searchWarrantObject.txtCaseNumber +
            " is approved.";

          let caseDetail = result;
          dispatch(updateCaseDetailObject(caseDetail));
          dispatch(
            updateElementValueCaseDetailReducer({
              elementName: "responseMessage",
              value: message,
            })
          );
          navigate(navigateTo);
        }
      });
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        COMPL_SEARCH_WARRANT_WITH_SLASH +
          " form data approving process is failed."
      );
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(
      error.message ||
        COMPL_SEARCH_WARRANT_WITH_SLASH +
          " form data approving process is failed."
    );
  }
}
);

export const signSearchwarrant = createAsyncThunk<
  any,
  { searchWarrantId: number; callFrom: string; searchWarrantObject?: object; navigate: any, navigateTo: string },
  { state: RootState; rejectValue: string }
>("searchWarrant/signSearchwarrant", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, callFrom, searchWarrantObject, navigate, navigateTo } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url:
        "searchwarrantservices/signsearchwarrant/" +
        searchWarrantId +
        "/" +
        callFrom,
      type: "PUT",
      token,
      body: searchWarrantObject,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
		  dispatch: dispatch
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result) => {
        if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: result.errorInfo.validationErrors,
            })
          );
          return;
        } else {
          let caseNumber = "";
          if (
            getState().searchWarrant &&
            getState().searchWarrant.searchWarrantObject.txtCaseNumber &&
            getState().searchWarrant.searchWarrantObject.txtCaseNumber.trim()
              .length > 0
          ) {
            caseNumber =
              getState().searchWarrant.searchWarrantObject.txtCaseNumber;
          } else {
            caseNumber = getState().caseDetail.caseNumber;
          }

          if (result.errorInfo && result.errorInfo.error) {
            dispatch(
              updateElementValueCaseDetailReducer({
                elementName: "responseErrorMessage",
                value: result.errorInfo.errorMessage,
              })

            );
            if (result.errorInfo) {
              dispatch(updatePdfGenerationError({
                error: result.errorInfo.error,
                errorMsg: result.errorInfo.errorMessage,
              }));
            }
            navigate(navigateTo);
          } else {
            let message = "Search Warrant is issued for " + caseNumber + ".";
            result.responseMessage = message;
            let caseDetail = result;
            dispatch(updateCaseDetailObject(caseDetail));
          }
        
          navigate(navigateTo);
        }       
      });
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        COMPL_SEARCH_WARRANT_WITH_SLASH + " form signing process is failed."
      );
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(
      error.message ||
        COMPL_SEARCH_WARRANT_WITH_SLASH + " form signing process is failed."
    );
  }
}
);

export const denySearchWarrant = createAsyncThunk<
  any,
  { searchWarrantId: number; proxy?: object; navigate: any, navigateTo: string },
  { state: RootState; rejectValue: string }
>("searchWarrant/denySearchWarrant", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, proxy, navigate, navigateTo } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/denysearchwarrant/" + searchWarrantId,
      type: "PUT",
      token,
      body: proxy,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
      dispatch: dispatch
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result) => {
        let message =
          "Search Warrant of " +
          getState().searchWarrant.searchWarrantObject.txtCaseNumber +
          " case is denied.";

        let caseDetail = result;
        dispatch(updateCaseDetailObject(caseDetail));
        dispatch(
          updateElementValueInSearchWarrantListReducer({
            elementName: "responseMessage",
            value: message,
          })
        );
      });
      navigate(navigateTo);
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        COMPL_SEARCH_WARRANT_WITH_SLASH +
          " form data denying process is failed."
      );
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(
      error.message ||
        COMPL_SEARCH_WARRANT_WITH_SLASH +
          " form data denying process is failed."
    );
  }
}
);

export const returnSearchWarrant = createAsyncThunk<
  any,
  { searchWarrantId: number; proxy?: any; navigate: any, navigateTo: string },
  { state: RootState; rejectValue: string }
>("searchWarrant/returnSearchWarrant", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, proxy, navigate, navigateTo } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/returnsearchwarrant/" + searchWarrantId,
      type: "PUT",
      token,
      body: proxy,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
      dispatch: dispatch
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result) => {
        let message =
          "Search Warrant application for " +
          getState().searchWarrant.searchWarrantObject.txtCaseNumber +
          " ";
        if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
          message = message + "is sent back to State Attorney.";
        } else if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
          message = message + "is sent back to Local Prosecutors.";
        } else if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
          message = message + "is sent back to Attorney General.";
        } else if (proxy.formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) {
          message = message + "is sent back to officer.";
        }

        let caseDetail = result;
        dispatch(updateCaseDetailObject(caseDetail));

        dispatch(
          updateElementValueInSearchWarrantListReducer({
            elementName: "responseMessage",
            value: message,
          })
        );
      });
      navigate(navigateTo);
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        COMPL_SEARCH_WARRANT_WITH_SLASH +
          " form data returning process is failed for " +
          getState().searchWarrant.searchWarrantObject.txtCaseNumber +
          "."
      );
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(
      error.message ||
        COMPL_SEARCH_WARRANT_WITH_SLASH +
          " form data returning process is failed for " +
          getState().searchWarrant.searchWarrantObject.txtCaseNumber +
          "."
    );
  }
}
);

export const updateSearchWarrantDetail = createAsyncThunk<
  any,
  { searchWarrantId: number; action: string; warrantFormObj?: any; navigate?: any; navigateTo?: any;},
  { state: RootState; rejectValue: string }
>("searchWarrant/updateSearchWarrantDetail", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, action, warrantFormObj, navigate, navigateTo } = data;

  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/updatesearchwarrantdetail/" + searchWarrantId + "/" + action,
      type: "PUT",
      token,
      body: warrantFormObj,
      concurrentObject: concurrObj,
      concurrentIdentifier: "SW",
      dispatch: dispatch
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result) => {
        if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: result.errorInfo.validationErrors,
            })
          );
          return;
        } else {
          let message:any = "";
          if (warrantFormObj.notificationCallFrom === "Submit") {
              if(warrantFormObj.formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS){
                  message = "Form submitted to SA for screening.";
              } else if (warrantFormObj.formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
                  message = "Form submitted to AG for screening.";
              } else if (warrantFormObj.formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
                  message = "Form submitted to LP for screening.";
              } else if(warrantFormObj.formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS){
                  message = "Form submitted to Judge for approval.";
              } else if(INVENTORY === warrantFormObj.formType
                      && warrantFormObj.formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS){
                  message = "Form submitted to Judge for review.";
              } else if(AMENDED_INVENTORY === warrantFormObj.formType 
                      && warrantFormObj.formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS){
                  message = "Form submitted to Judge for review.";
              }
          } else if (warrantFormObj.notificationCallFrom === "Return" || warrantFormObj.notificationCallFrom === "Approve"
              || warrantFormObj.notificationCallFrom === "Deny" || warrantFormObj.notificationCallFrom === "Void") {
              let messageForm = null;
              if (COMPL_SEARCH_WARRANT_WITH_SLASH === warrantFormObj.formType) {
                  messageForm = "Search Warrant ";
              }
              if (INVENTORY === warrantFormObj.formType) {
                  messageForm = "Inventory ";
              }
              if (AMENDED_INVENTORY === warrantFormObj.formType) {
                  messageForm = "Amended Inventory";
              }
              if (APPLICATION_AND_ORDER === warrantFormObj.formType || BLANK_ORDER === warrantFormObj.formType) {
                  messageForm = "Order";
              }
              message = Util.getSuccessMessage(warrantFormObj.formType, warrantFormObj.formStatus,
                  getState().caseDetail.caseNumber, messageForm);
          } else if (warrantFormObj.notificationCallFrom === "Unlock"){
            message = "Form unlocked successfully.";
          }

          if (message.trim().length > 0) {
            result.responseMessage = message;
          }
          
          let caseDetail = result;
          dispatch(updateCaseDetailObject(caseDetail));
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>> search warrrant slice updateSearchWarrantDetail line number 786 updated case details object")
          console.log(caseDetail)
          if (warrantFormObj.notificationCallFrom === "N/A") {
            dispatch(
              getSearchWarrantData({
                searchWarrantId: searchWarrantId,
                formName: warrantFormObj.formType,
                navigate: navigate,
                searchWarrantList: caseDetail.searchWarrantList
              })
            );
          }

          if (navigateTo) {
            if (INVENTORY === warrantFormObj.formType) {
              navigate(navigateTo);
            }
          }
        }
      });
    } else {
      
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while updating search warrant");
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Error while updating search warrant");
  }
}
);
 

export const deleteSearchWarrant = createAsyncThunk<
  any,
  { searchWarrantId: number; proxy: any; navigate : any},
  { state: RootState; rejectValue: string }
>("searchWarrant/deleteSearchWarrant", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, proxy, navigate } = data;

  let { token } = getState().login;
  let  caseDetailSearchWarrantId  = getState().caseDetail.searchWarrantId;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/deleteSearchWarrant/" + searchWarrantId ,
      type: "DELETE",
      token,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
		  dispatch: dispatch
    });
    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      response.json().then((result) => {
        dispatch(
          getSWCaseDetail({
            searchWarrantId: caseDetailSearchWarrantId,
            action: "getDetails",
            navigate: navigate,
          })
        );

        let message:any = Util.getSuccessMessage(proxy.formType, proxy.formStatus,
          getState().caseDetail.caseNumber, proxy.formType);

      if (message.trim().length > 0) {
        result.responseMessage = message;
      }
      let caseDetail = result;
      dispatch(updateCaseDetailObject(caseDetail));

      });
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while deleting search warrant");
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Error while deleting search warrant");
  }
}
);

export const updateCmsSyncFormStatusToPending = createAsyncThunk<
  any,
  { searchWarrantId: number; navigate: any },
  { state: RootState; rejectValue: string }
>("searchWarrant/updateCmsFormStatusFromSyncToPending", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, navigate } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/updateCmsFormStatusFromSyncToPending/" + searchWarrantId,
      type: "PUT",
      token,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
		  dispatch: dispatch
    });
    if (response.ok) {
      if (response) {
        dispatch(updateElementValueSearchWarrantReducer({
          elementName: "swSuccessMsg",
          value: SW_MSG_CMSPENDING_SUCCESSFULLY,
        }));
        dispatch(
          getSWCaseDetail({
            searchWarrantId: searchWarrantId,
            action: "getDetails",
            navigate: navigate,
          })
        );
      }
      dispatch(showHideLoadingGif(false));
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
  }
}
);

export const getSearchWarrantDetails = createAsyncThunk<
  any,
  { searchWarrantId: number; action: string; detailObj : any, navigate : any},
  { state: RootState; rejectValue: string }
>("searchWarrant/getSearchWarrantDetails", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, action, detailObj, navigate } = data;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  let { token } = getState().login;
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/getSearchWarrantDetail/" + searchWarrantId ,
      type: "GET",
      token,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
		  dispatch: dispatch
    });
    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      response.json().then((result) => {
        if(action === "VOID"){
          if (result.formStatus && detailObj.formStatus === result.formStatus) {
            let proxy :any = {};
            proxy.formStatus = SEARCH_WAR_VOID_STATUS;
            proxy.remark = detailObj.remark;
            proxy.notificationCallFrom = "Void";
            proxy.formType = detailObj.formType;


            dispatch(
              updateSearchWarrantDetail({
                searchWarrantId: detailObj.searchWarrantId,
                action: "updateRemarks",
                warrantFormObj: proxy,
              })
            );
          }else {
            dispatch(
              getSWCaseDetail({
                searchWarrantId: searchWarrantId,
                action: "getDetails",
                navigate: navigate,
              })
            );
          }
        } else if (action === "RETURN"){
          if (result.formStatus && detailObj.formStatus === result.formStatus) {
            
            let status = "";
            if ((detailObj.userType === ROLE_ADMINISTRATOR
                || detailObj.userType === ROLE_JUDGE) && (detailObj.formStatus === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS
                    || detailObj.formStatus === SEARCH_WAR_LOCKED_BY_JUDGE_STATUS)
                && (detailObj.formCreatorType === ROLE_STATES_ATTORNEY || detailObj.formCreatorType === ROLE_LOCAL_PROSECUTOR
                    || detailObj.formCreatorType === ROLE_ATTORNEY_GENERAL)) {
                if (detailObj.formCreatorType === ROLE_STATES_ATTORNEY) {
                    status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
                } else if (detailObj.formCreatorType === ROLE_ATTORNEY_GENERAL) {
                    status = SEARCH_WAR_ASSIGNED_TO_AG_STATUS;
                } else if (detailObj.formCreatorType === ROLE_LOCAL_PROSECUTOR) {
                    status = SEARCH_WAR_ASSIGNED_TO_LP_STATUS;
                } else {
                    status = SEARCH_WAR_OFFICER_PENDING_STATUS;
                }
            } else if ((detailObj.userType === ROLE_ADMINISTRATOR
                || detailObj.userType === ROLE_JUDGE)
                && !detailObj.approvalSAId && detailObj.isSARequired
                && detailObj.userProfileId !== detailObj.formCreatorId
                && (detailObj.formType === APPLICATION_AND_ORDER || detailObj.formType === BLANK_ORDER)) {
                status = SEARCH_WAR_ASSIGNED_TO_SA_STATUS;
            } else if (detailObj.creatorId && detailObj.creatorId > 0 && detailObj.creatorId && detailObj.creatorId > 0
                && detailObj.creatorId !== detailObj.creatorId) {
                status = SEARCH_WAR_OFFICER_PENDING_STATUS;
            } else {
                status = SEARCH_WAR_OFFICER_PENDING_STATUS;
            }

            let proxy :any = {};
            proxy.formStatus = status;
            proxy.remark = detailObj.remark;
            proxy.notificationCallFrom = "Return";
            proxy.formType = detailObj.formType;
                   
            dispatch(
              updateSearchWarrantDetail({
                searchWarrantId: detailObj.searchWarrantId,
                action: "updateRemarks",
                warrantFormObj: proxy,
              })
            );     

          }else{
            dispatch(
              getSWCaseDetail({
                searchWarrantId: searchWarrantId,
                action: "getDetails",
                navigate: navigate,
              })
            );
          }
        }else if(action === "DENY"){
          if (result.formStatus && detailObj.formStatus === result.formStatus) {
            let proxy : any = {};
            proxy.formStatus = SEARCH_WAR_DENIED_STATUS;
            proxy.formType = detailObj.formType;
            proxy.remark = "";
            proxy.notificationCallFrom = "Deny";
            dispatch(
              updateSearchWarrantDetail({
                searchWarrantId: detailObj.searchWarrantId,
                action: "updateRemarks",
                warrantFormObj: proxy,
              })
            );

          } else {
            dispatch(
              getSWCaseDetail({
                searchWarrantId: searchWarrantId,
                action: "getDetails",
                navigate: navigate,
              })
            );
          }
        }
      });
    } else {

      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while updating search warrant");
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Error while updating search warrant");
  }
}
);

const searchWarrantSlice = createSlice({
  name: "searchWarrant",
  initialState,
  reducers: {
    updateElementValueSearchWarrantReducer: updateElementValue,
    updateElementObjectValue: (state: searchWarrantState, action) => {
      updateElementObject(state, action, state.searchWarrantObject);
    },
    updateSearchWarrantObject: (state: searchWarrantState, action) => {
      state.searchWarrantObject = action.payload;
    },
    resetSearchWarrantData: (state: searchWarrantState) => {
      return {
        ...state,
        ...initialState,
      };
    },
    updateSignElementSearchWarrant: (state: searchWarrantState, action) => {
      updateElementObject(state, action, state.searchWarrantObject);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createSearchWarrant.pending, (state) => {
        state.status = "loading";
        state.fetchingError = undefined;
      })
      .addCase(createSearchWarrant.fulfilled, (state, action) => {
        state.status = "success";
        state.searchWarrantObject = action.payload;
      })
      .addCase(createSearchWarrant.rejected, (state, action) => {
        state.status = "error";
        state.fetchingError = undefined;
      })

      .addCase(getWarrantTemplateListWithData.pending, (state) => {
        state.status = "loading";
        state.fetchingError = undefined;
      })
      .addCase(getWarrantTemplateListWithData.fulfilled, (state, action) => {
        state.status = "success";
        state.warrantTemplateList = action.payload;
      })
      .addCase(getWarrantTemplateListWithData.rejected, (state, action) => {
        state.status = "error";
        state.fetchingError = undefined;
      })

      .addCase(listSWApproveUserTypes.pending, (state) => {
        state.status = "loading";
        state.fetchingError = undefined;
      })
      .addCase(listSWApproveUserTypes.fulfilled, (state, action) => {
        state.status = "success";
        state.swApprovalUserTypes = action.payload;
      })
      .addCase(listSWApproveUserTypes.rejected, (state, action) => {
        state.status = "error";
        state.fetchingError = undefined;
      })

      .addCase(getWarrantTemplateData.pending, (state) => {
        state.status = "loading";
        state.fetchingError = undefined;
      })
      .addCase(getWarrantTemplateData.fulfilled, (state, action) => {
        state.status = "success";
        state.searchWarrantObject.txtOffense1 = formatTextForCkEditor(setTemplateDataToFields(action.payload.searchWarrantComplaint?.txtOffense1, state.searchWarrantObject.agencyName,state.searchWarrantObject.badgeNo, state.searchWarrantObject.txtAppears));
        state.searchWarrantObject.txtSeized1 = formatTextForCkEditor(setTemplateDataToFields(action.payload.searchWarrantComplaint.txtSeized1, state.searchWarrantObject.agencyName,state.searchWarrantObject.badgeNo, state.searchWarrantObject.txtAppears));
        state.searchWarrantObject.txtPoliceOfficerInfo = formatTextForCkEditor(setTemplateDataToFields(action.payload.searchWarrantComplaint.txtPoliceOfficerInfo, state.searchWarrantObject.agencyName,state.searchWarrantObject.badgeNo, state.searchWarrantObject.txtAppears));
        state.searchWarrantObject.txtPersonInfo = formatTextForCkEditor(setTemplateDataToFields(action.payload.searchWarrantComplaint.txtPersonInfo, state.searchWarrantObject.agencyName,state.searchWarrantObject.badgeNo, state.searchWarrantObject.txtAppears));
      })
      .addCase(getWarrantTemplateData.rejected, (state, action) => {
        state.status = "error";
        state.fetchingError = undefined;
      })

      .addCase(updateSearchWarrant.pending, (state) => {
        state.updateSwStatus = "loading";
        state.updateSwError = undefined;
      })
      .addCase(updateSearchWarrant.fulfilled, (state, action) => {
        state.updateSwStatus = "success";
        state.swSuccessMsg = action.payload;
      })
      .addCase(updateSearchWarrant.rejected, (state, action) => {
        state.updateSwStatus = "error";
        state.updateSwError = undefined;
      })

      .addCase(saveSearchWarrant.pending, (state) => {
        state.updateSwStatus = "loading";
        state.updateSwError = undefined;
      })
      .addCase(saveSearchWarrant.fulfilled, (state, action) => {
        state.updateSwStatus = "success";
        state.swSuccessMsg = action.payload;
      })
      .addCase(saveSearchWarrant.rejected, (state, action) => {
        state.updateSwStatus = "error";
        state.updateSwError = undefined;
      })


      .addCase(submitSearchWarrant.pending, (state) => {
        state.updateSwStatus = "loading";
        state.updateSwError = undefined;
      })
      .addCase(submitSearchWarrant.fulfilled, (state, action) => {
        state.updateSwStatus = "success";
        state.swSuccessMsg = action.payload;
      })
      .addCase(submitSearchWarrant.rejected, (state, action) => {
        state.updateSwStatus = "error";
        state.updateSwError = action.payload;
      })


      .addCase(approveSearchwarrant.pending, (state) => {
        state.updateSwStatus = "loading";
        state.updateSwError = undefined;
      })
      .addCase(approveSearchwarrant.fulfilled, (state, action) => {
        state.updateSwStatus = "success";
        state.swSuccessMsg = action.payload;
      })
      .addCase(approveSearchwarrant.rejected, (state, action) => {
        state.updateSwStatus = "error";
        state.updateSwError = undefined;
      })

      .addCase(signSearchwarrant.pending, (state) => {
        state.updateSwStatus = "loading";
        state.updateSwError = undefined;
      })
      .addCase(signSearchwarrant.fulfilled, (state, action) => {
        state.updateSwStatus = "success";
        state.swSuccessMsg = action.payload;
      })
      .addCase(signSearchwarrant.rejected, (state, action) => {
        state.updateSwStatus = "error";
        state.updateSwError = undefined;
      })


      .addCase(denySearchWarrant.pending, (state) => {
        state.updateSwStatus = "loading";
        state.updateSwError = undefined;
      })
      .addCase(denySearchWarrant.fulfilled, (state, action) => {
        state.updateSwStatus = "success";
        state.swSuccessMsg = action.payload;
      })
      .addCase(denySearchWarrant.rejected, (state, action) => {
        state.updateSwStatus = "error";
        state.updateSwError = undefined;
      })


      .addCase(returnSearchWarrant.pending, (state) => {
        state.updateSwStatus = "loading";
        state.updateSwError = undefined;
      })
      .addCase(returnSearchWarrant.fulfilled, (state, action) => {
        state.updateSwStatus = "success";
        state.swSuccessMsg = action.payload;
      })
      .addCase(returnSearchWarrant.rejected, (state, action) => {
        state.updateSwStatus = "error";
        state.updateSwError = undefined;
      })

      
      .addCase(updateSearchWarrantDetail.pending, (state) => {
        state.updateSwStatus = "loading";
        state.updateSwError = undefined;
      })
      .addCase(updateSearchWarrantDetail.fulfilled, (state, action) => {
        state.updateSwStatus = "success";
        state.swSuccessMsg = action.payload;
      })
      .addCase(updateSearchWarrantDetail.rejected, (state, action) => {
        state.updateSwStatus = "error";
        state.updateSwError = undefined;
      })

      .addCase(getSearchWarrantDetails.pending, (state) => {
        state.updateSwStatus = "loading";
        state.updateSwError = undefined;
      })
      .addCase(getSearchWarrantDetails.fulfilled, (state, action) => {
        state.updateSwStatus = "success";
        state.swSuccessMsg = action.payload;
      })
      .addCase(getSearchWarrantDetails.rejected, (state, action) => {
        state.updateSwStatus = "error";
        state.updateSwError = undefined;
      });
  },
});

export const {
  updateElementValueSearchWarrantReducer,
  resetSearchWarrantData,
  updateElementObjectValue,
  updateSearchWarrantObject,
  updateSignElementSearchWarrant,
} = searchWarrantSlice.actions;

export const useSearchWarrantReducer = () =>
  useAppSelector((state) => state.searchWarrant);

export default searchWarrantSlice.reducer;
