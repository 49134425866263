import { doFetch } from "../utils/doFetch";

export const insertUpdateEcomplaintData = async ({
    body,
    token,
    concurrentObject,
    dispatch,
}: {
    body?: any;
    token?: string;
    concurrentObject?: any[];
    dispatch?: any;
}) => {
    const response = await doFetch({
        url: "ecomplaintservices/saveEcomplaint",
        token,
        body: body,
        type: "PUT",
        concurrentObject: concurrentObject,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
    });
    return response;
};

export const getAllEcCounties = async ({
    token,
}: {
    token?: string;
}) => {
    const response = await doFetch({
        url: "ecomplaintservices/getAllEcCounty",
        token,
    });
    return response;
};

export const fetchCountryList = async ({
    token,
}: {
    token?: string;
}) => {
    const response = await doFetch({
        url: `ecomplaintservices/country`,
        token,
      });
    return response;
};