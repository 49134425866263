import moment from 'moment';
import React, { useCallback, useEffect } from "react";
import { RiCloseFill, RiFileTextLine, RiSave3Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import ConfirmPopup from "../../../components/common/ConfirmPopup";
import {
  MAX_DIFFERENCE_WARRANT_EXECUTION, PROOF_OF_SERVICE, SEARCH_WAR_EXECUTED_STATUS, SEARCH_WAR_POS_MAX_EXECUTION_TIME_WARNING, SEARCH_WAR_PROOF_OF_SERVICE_CONFIRM_COMPLETE, SEARCH_WAR_VOID_STATUS
} from "../../../constants/common";
import { useAppDispatch } from "../../../hooks/hooks";
import { useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";
import { previewOrder } from "../../../redux/slice/commonFormSlice";
import { getSearchWarrantDetail, saveProofOfService, updateElementObjectValue, useProofOfServiceReducer } from "../../../redux/slice/proofOfServiceSlice";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";
import BreadcrumbComponent from "../../common/BreadcrumbComponent";
import FormComponent from "../../common/FormComponent";
import Header from "../../common/Header";
import { validateDate } from '../../../utils/common';
import FormFiller from '../../common/FormFiller';
import ButtonComponent from '../../common/ButtonComponent';
import { useDashboardReducer, updateElementValueDashboardReducer } from "../../../redux/slice/dashboardSlice";
import { useLoginReducer } from '../../../redux/slice/loginSlice';

interface ProofOfServiceProps {}

let fieldTitles =[''];
let names = ['txtPersonPremises'];

const ProofOfService: React.FC<ProofOfServiceProps> = (props) => {

  const dashboardReducer = useDashboardReducer();
  
  const  onBreadcrumbClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }
  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/"+dashboardReducer.PreviousURLSW.replace(/^.*\/\/.*?\//, ''), itemName: "Warrant List", itemFunction: onBreadcrumbClick  },
    { itemLink: "/CaseDetails", itemName: "Existing Forms" },
  ];

  let defaultPosSchema = yup.object().shape({
    officerBadgeNo: yup.string().required("Badge number is required."),
    chkExecuted: yup.boolean().nullable(),
    chkNotExecuted: yup.boolean().nullable(),
    personPremisesAndOrText: yup.string().nullable()
      .when('chkExecuted', {
        is: true,
        then: yup.string().nullable().required("Person/Premises/Item information is required."),
      }),
    dateOfWarrantExecution: yup.date().nullable().max(
      yup.ref('currentDate'), "Warrant execution date cannot be future date."
    )
      .when('chkExecuted', {
        is: true,
        then: yup.date().nullable().required("Warrant execution date is required."),
      }),

    formatedEnterDateTime: yup.date().nullable().min(
      yup.ref('issuanceDateTime'), "Warrant execution date/time should be after its issuance date/time."
    ),

    timeOfWarrantExecution: yup.string().nullable()
      .when('chkExecuted', {
        is: true,
        then: yup.string().nullable().required("Warrant execution time is required."),
      })
  }).test('chkExecuteTest',
    (obj) => {
      if (obj.chkExecuted || obj.chkNotExecuted) {
        return true;
      } else {
        return new yup.ValidationError('Warrant execution option is not selected.');
      }
    }
  );
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const errorMessageAlert = useAlert();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const [confirmPopupData, setconfirmPopupData] = React.useState(false);
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [warrantStatus, setWarrantStatus] = React.useState("");  
  const proofOfServiceReducer = useProofOfServiceReducer();
  const caseDetailReducer = useCaseDetailReducer();
  const loginReducer = useLoginReducer();

  const [showFormFiller, setShowFormFiller] = React.useState(false);
  const [fieldTitleValues, setFieldTitleValues] = React.useState<String[]>([]);

  useEffect(() => {
    if (caseDetailReducer.searchWarrantId > 0) {
      dispatch(getSearchWarrantDetail({ searchWarrantId: caseDetailReducer.searchWarrantId, navigate: navigate }));
    }
  }, [caseDetailReducer.searchWarrantId]);

  const handleChangeDateObjectValue = (event: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };
  const handleInputBlur = (event: any) => {
    let { name, value } = event.target;
    value = validateDate(value);
     dispatch(updateElementObjectValue({ elementName: name, value: value }));
  }
  const handleChangeObjectValue = (event: any) => {
    dispatch(
      updateElementObjectValue({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  const handleCkEditorValChange = (name: string, editor: any) => {
    dispatch(updateElementObjectValue({ elementName: name, value: editor.getData() }));
  }

  const handleValChangeCheck = (event: any) => {
    if ("chkExecuted" === event.target.name) {
      dispatch(getSearchWarrantDetail({ searchWarrantId: caseDetailReducer.searchWarrantId, navigate: navigate }));
      dispatch(updateElementObjectValue({ elementName: event.target.name, value: event.target.checked }));
      dispatch(updateElementObjectValue({ elementName: "chkNotExecuted", value: !event.target.checked }));

      if (!event.target.checked) {
        dispatch(updateElementObjectValue({ elementName: "personPremisesAndOrText", value: "" }));
        dispatch(updateElementObjectValue({ elementName: "dateOfWarrantExecution", value: "" }));
        dispatch(updateElementObjectValue({ elementName: "timeOfWarrantExecution", value: "" }));

      }

    } else if ("chkNotExecuted" === event.target.name) {
      dispatch(updateElementObjectValue({ elementName: event.target.name, value: event.target.checked }));
      dispatch(updateElementObjectValue({ elementName: "chkExecuted", value: !event.target.checked }));

      dispatch(updateElementObjectValue({ elementName: "personPremisesAndOrText", value: "" }));
      dispatch(updateElementObjectValue({ elementName: "dateOfWarrantExecution", value: "" }));
      dispatch(updateElementObjectValue({ elementName: "timeOfWarrantExecution", value: "" }));
    }
  }

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

 
  const handleSave = async () => {
    let userSelectedDate = new Date(proofOfServiceReducer.proofOfServiceObj.dateOfWarrantExecution);
    let formatedEnterDate = proofOfServiceReducer.proofOfServiceObj.dateOfWarrantExecution ? moment(moment(userSelectedDate).add(1, 'days')).format('MM/DD/YYYY') : "";
    let userEnterTime = proofOfServiceReducer.proofOfServiceObj.timeOfWarrantExecution ? proofOfServiceReducer.proofOfServiceObj.timeOfWarrantExecution : "";
    let formatedEnterDateTime = formatedEnterDate + " " + userEnterTime;
    let issuanceDateTime = proofOfServiceReducer.proofOfServiceObj.issuanceDate + " " + proofOfServiceReducer.proofOfServiceObj.issuanceTime;

    let obj = {
      officerBadgeNo: proofOfServiceReducer.proofOfServiceObj.officerBadgeNo,
      chkExecuted: proofOfServiceReducer.proofOfServiceObj.chkExecuted,
      chkNotExecuted: proofOfServiceReducer.proofOfServiceObj.chkNotExecuted,
      personPremisesAndOrText: proofOfServiceReducer.proofOfServiceObj.personPremisesAndOrText,
      currentDate: new Date(),
      dateOfWarrantExecution: proofOfServiceReducer.proofOfServiceObj.dateOfWarrantExecution !== null && proofOfServiceReducer.proofOfServiceObj.dateOfWarrantExecution.trim().length > 0 ? new Date(proofOfServiceReducer.proofOfServiceObj.dateOfWarrantExecution) : null,
      timeOfWarrantExecution: proofOfServiceReducer.proofOfServiceObj.timeOfWarrantExecution,
      formatedEnterDateTime: (formatedEnterDate.trim().length > 0 && userEnterTime.trim().length > 0) ? new Date(formatedEnterDateTime) : null,
      issuanceDateTime: issuanceDateTime.trim().length > 0 ? new Date(issuanceDateTime) : null,
    }

    try {
      await defaultPosSchema.validate(obj, {
        abortEarly: false,
      });
      savePosData();
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }

  const oncancel = () =>{
    const url = dashboardReducer.PreviousURLSW;
    const newUrl = url.replace(/^.*\/\/.*?\//, '');
    if (newUrl === ""){
      if (loginReducer.summonsToAppear){
        navigate("/WarrantsSummonsList");
      }else{
        navigate("/WarrantList");
      }
    }else{
      navigate("/"+newUrl);
    }
  }

  const savePosData = async () => {
    let confirmMsg = "";
    if (proofOfServiceReducer.proofOfServiceObj.chkExecuted && proofOfServiceReducer.proofOfServiceObj.dateOfWarrantExecution
      && proofOfServiceReducer.proofOfServiceObj.timeOfWarrantExecution) {

      let userSelectedDate = new Date(proofOfServiceReducer.proofOfServiceObj.dateOfWarrantExecution).getDate() + 1;
      let formatedEnterDate = moment(moment(userSelectedDate).add(1, 'days')).format('MM/DD/YYYY');
      let userEnterTime = proofOfServiceReducer.proofOfServiceObj.timeOfWarrantExecution;
      let difference: number = 0;
      if (formatedEnterDate && userEnterTime) {
        let warrantIssuance = proofOfServiceReducer.proofOfServiceObj.issuanceDate + " " + proofOfServiceReducer.proofOfServiceObj.issuanceTime;
        let warrantExecution = formatedEnterDate + " " + userEnterTime;

        var executionDate = moment(warrantExecution);
        var issuanceDate = moment(warrantIssuance);
        difference = executionDate.diff(issuanceDate);
      }

      if (difference > MAX_DIFFERENCE_WARRANT_EXECUTION) {
        confirmMsg = SEARCH_WAR_POS_MAX_EXECUTION_TIME_WARNING;
      } else {
        confirmMsg = SEARCH_WAR_PROOF_OF_SERVICE_CONFIRM_COMPLETE + SEARCH_WAR_EXECUTED_STATUS + ". \nThis action cannot be reversed. Please confirm.";
      }
      setconfirmText(confirmMsg);
      setWarrantStatus(SEARCH_WAR_EXECUTED_STATUS);

    } else {
      confirmMsg = SEARCH_WAR_PROOF_OF_SERVICE_CONFIRM_COMPLETE + SEARCH_WAR_VOID_STATUS + ". \nThis action cannot be reversed. Please confirm.";
      setconfirmText(confirmMsg);
      setWarrantStatus(SEARCH_WAR_VOID_STATUS);
    }
    setconfirmText(confirmMsg);
    setconfirmPopupData(true);
  }

  const handleConfirmation = () => {
    setconfirmPopupData(false);
    let dataObj = {
      searchWarrantId: caseDetailReducer.searchWarrantId,
      warrantStatus: warrantStatus,
      proofOfService: proofOfServiceReducer.proofOfServiceObj,
      navigate: navigate,
      navigateTo: `/CaseDetails`,
    };
    dispatch(saveProofOfService(dataObj));
  };

  const previewForm = () => {
    dispatch(
      previewOrder({
        formId:proofOfServiceReducer.proofOfServiceObj.searchWarrantId,
        formName: PROOF_OF_SERVICE,
        formObject: proofOfServiceReducer.proofOfServiceObj
      })
    );
  };

  const formFillerClick = () =>{
    let array = [
      proofOfServiceReducer.proofOfServiceObj.txtPersonPremises
    ];
    setShowFormFiller(true);
    setFieldTitleValues(array);
  }

  const updateFieldFromWizard = (name: string, value: string) =>{
    dispatch(updateElementObjectValue({elementName:name, value: value}));
  }
  
  const formData = [
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: "STATE OF ILLINOIS",
        className: "fw-bold text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h5",
        value: (
          <>
            UNITED STATES OF AMERICA
            <br />
            {proofOfServiceReducer.proofOfServiceObj.lblHeadingInTheCircuitSW}
          </>
        ),
        className: "fw-bold text-center",
      },
      colSize: 6,
    },
    {
      type: "htmlTag",
      props: {
        type: "h6",
        value: proofOfServiceReducer.proofOfServiceObj.lblHeadingCountySW,
        className: "fw-bold text-end text-secondary",
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
    },

    {
      type: "textInput",
      props: {
        type: "text",
        value: proofOfServiceReducer.proofOfServiceObj.agencyName,
        name: "templateName",
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        type: "h4",
        value: "PROOF OF SERVICE",
        className: "fw-bold text-center text-danger",
      },
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        value: proofOfServiceReducer.proofOfServiceObj.txtCaseNumber,
        name: "templateName",
        className: "fw-bold",
        disabled: true,
      },
      colSize: 3,
    },
    {
      type: "textInput",
      props: {
        onChange: handleChangeObjectValue,
        placeholder: "Officer Name",
        name: "officerName",
        value: proofOfServiceReducer.proofOfServiceObj.officerName,
      },
      colSize: 4,
      label: "POLICE OFFICER :",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        name: "officerBadgeNo",
        value: proofOfServiceReducer.proofOfServiceObj.officerBadgeNo,
        onChange: handleChangeObjectValue,
        isError: errorKeys.includes("officerBadgeNo"),
        placeholder: "Badge",
      },
      colSize: 3,
      label: "BADGE / EMPLOYEE NO :",
      inLine: true,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr />,
      },
      colSize: 12,
      emptyCol: 10,
    },
    {
      type: 'formButton',
      props: {
        children: "Form Filler Wizard",
        onClick: formFillerClick,
        disabled: true,
      },
      inLine: true,
      colSize: 2,
    },
    {
      type: "editor",
      props: {
        data: proofOfServiceReducer.proofOfServiceObj.txtPersonPremises
          ? proofOfServiceReducer.proofOfServiceObj.txtPersonPremises
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("txtPersonPremises", editor);
        },
        disabled: true,
        // isError: errorKeys.includes("txtPersonInfo"),
      },
      colSize: 12,
    },

    {
      type: "checkbox",
      props: {
        label: <b>EXECUTED</b>,
        name: "chkExecuted",
        isError: errorKeys.includes("chkExecuteTest"),
        value: proofOfServiceReducer.proofOfServiceObj.chkExecuted,
        checked: proofOfServiceReducer.proofOfServiceObj.chkExecuted,
        onChange:handleValChangeCheck
      },
      colSize: 6,
    },
    {
      type: "checkbox",
      props: {
        label: <b>NOT EXECUTED</b>,
        name: "chkNotExecuted",
        isError: errorKeys.includes("chkExecuteTest"),
        value: proofOfServiceReducer.proofOfServiceObj.chkNotExecuted,
        checked: proofOfServiceReducer.proofOfServiceObj.chkNotExecuted,
        onChange:handleValChangeCheck
      },
      colSize: 6,
    },
    {
      type: "htmlTag",
      props: {
        value: proofOfServiceReducer.proofOfServiceObj.personPremisesExecutedText === null ? "I executed this Warrant by serving the above named" :
        proofOfServiceReducer.proofOfServiceObj.personPremisesExecutedText,
      },
      colSize: 6,
    },

    {
      type: "htmlTag",
      props: {
        value: <label>I did not execute this Warrant within ninety six (96) hours from the time of issuance, and it hereby returned to the Court as void and not executed.</label>,
      },
      colSize: 6,
    },

    {
      type: "textInput",
      props: {
        name: "personPremisesAndOrText",
        isError: errorKeys.includes("personPremisesAndOrText"),
        value: proofOfServiceReducer.proofOfServiceObj.personPremisesAndOrText,
        onChange: handleChangeObjectValue,
        disabled: !proofOfServiceReducer.proofOfServiceObj.chkExecuted
      },
      colSize: 2,
      colGrap : "g-2"
    },

    {
      type: "textInput",
      props: {
        name: "dateOfWarrantExecution",
        isError: errorKeys.includes("dateOfWarrantExecution") || errorKeys.includes("formatedEnterDateTime") ,
        type: "date",
        value: proofOfServiceReducer.proofOfServiceObj.dateOfWarrantExecution,
        onChange: handleChangeDateObjectValue,
        onBlur: handleInputBlur,
        disabled: !proofOfServiceReducer.proofOfServiceObj.chkExecuted
      },
      colSize: 2,
      label: "on",
      inLine: true,
      colGrap : "g-2"
    },
    {
      type: "textInput",
      props: {
        name: "timeOfWarrantExecution",
        isError: errorKeys.includes("timeOfWarrantExecution") || errorKeys.includes("formatedEnterDateTime"),
        type : "time",
        value: proofOfServiceReducer.proofOfServiceObj.timeOfWarrantExecution,
        onChange: handleChangeObjectValue,
        disabled: !proofOfServiceReducer.proofOfServiceObj.chkExecuted
      },
      colSize: 2,
      label: "at",
      inLine: true,
      colGrap : "g-2"
    },


    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-primary ms-2",
        children: (
          <>
            Preview <RiFileTextLine />
          </>
        ),
        onClick: () => {
          previewForm();
        },
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-primary ms-2",
        children: (
          <>
            Save <RiSave3Line />
          </>
        ),
        onClick: () => {
          handleSave();
        },
      },
    },
    {
      type: "button",
      colSize: 2,
      props: {
        className: "btn-danger ms-2",
        children: (
          <>
            <RiCloseFill /> Cancel
          </>
        ),
        onClick: () => {
          oncancel();
        },
      },
    },
  ];

  return (
    <>
      <Header headerName="Proof of Service" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Search Warrant"
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent
         totalColumns={12} 
         colSize={6} 
         componentData={formData}
         components={[
          {
            type: "formButton",
            node: (props: any) => (
              <div className="text-end">
              <ButtonComponent {...props}>{props.children}</ButtonComponent>
            </div>
              
            ),
          }
        ]}
          />
        <ConfirmPopup
          children={confirmText}
          show={confirmPopupData}
          onHide={() => setconfirmPopupData(false)}
          isConfirmation={handleConfirmation}
        />
        <FormFiller
          show={showFormFiller}
          onHide={() => setShowFormFiller(false)}
          fieldTitles={fieldTitles}
          names={names}
          values={fieldTitleValues}
          onChangeHandler={updateFieldFromWizard}
        />
      </div>
    </>
  );
};

export default ProofOfService;
