import React, { useCallback, useEffect, useState } from "react";
import {
  fetchArrestWarrant,
  fetchInitialArrestWarrant,
  useArrestWarrantReducer,
  updateElementObjectValueArrestWarrantReducer,
  updateElementValueArrestWarrantReducer,
  resetArrestWarrantData,
  saveArrestWarrant,
  returnToOfficerArrestWarrant,
  rejectArrestWarrant,
  resubmitArrestWarrantImage,
} from "../../redux/slice/arrestWarrantSlice";
import { formatNameWithSuffix, formatAddress } from "../../utils/common";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import Header from "../common/Header";
import { useAlert } from "../common/Alert";
import { RiEditLine, RiPlayMiniFill } from "react-icons/ri";
import ButtonComponent from "../common/ButtonComponent";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useAppDispatch } from "../../hooks/hooks";
import {
  getEcomplaintMainDetails,
  useComplaintMainReducer,
} from "../../redux/slice/complaintMainSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import {
  CREATE_LF_FORM_ARREST_WARRANT,
  EC_MSG_ALREADY_SENT_TO_JUDGE,
  LF_ARREST_WARRANT,
  LF_COMPLETE_STATUS,
  LF_DELETED_STATUS,
  LF_FILED_STATUS,
  LF_PENDING_STATUS,
  LF_REJECTED_STATUS,
  LF_RETURNED_STATUS,
  LF_SEND_TO_JUDGE_STATUS,
  LF_SIGNED_STATUS,
  LF_STATUS_PENDING_SUPERVISOR_RETURNED,
  LF_STATUS_PENDING_SUPERVISOR_REVIEW,
  LF_VOID_STATUS,
  ROLE_AGENCY_ADMIN,
  ROLE_COUNTY_ADMIN,
  ROLE_JUDGE,
  ROLE_POLICE_OFFICER,
  ROLE_STATES_ATTORNEY,
  ROLE_SA_ADMIN,
  FETCH_LF_MAIN_DETAILS_FROM_ARRESTWARRANT,
  TYPE_OF_ARREST_ARRESTWARRANT
} from "../../constants/common";
import CheckBoxComponent from "../common/CheckBoxComponent";
import { useNavigate } from "react-router-dom";
import ModifyDefendantPopup from "./ModifyDefendantPopup";
import * as yup from "yup";
import Alert from "../common/Alert";
import ConfirmPopup from "../common/ConfirmPopup";
import { useAgencyListReducer } from "../../redux/slice/agencyListSlice";
import {
  getEComplaintData,
  resetComplaintData,
  updateElementObjectValueComplaint,
  updateElementValueComplaintReducer,
} from "../../redux/slice/complaintSlice";
import SignPopup from "../common/SignPopup";
import { previewArrestWarrent } from "../../redux/slice/commonFormSlice";
import InfoPopup from "../common/InfoPopup";
import { editComplaint } from "../../redux/slice/ecomplaintDashboardSlice";
import {
  useDashboardReducer,
  updateElementValueDashboardReducer,
} from "../../redux/slice/dashboardSlice";
import { getJudgeSavedSignature } from "../../redux/slice/userProfileSlice";
import { setSignature } from "../../redux/slice/signPanelSlice";

let defInfoSchema = yup.object().shape({
  defDob: yup.string().required(),
  defFirstName: yup.string().required(),
  gender: yup.string().required(),
  defRace: yup.string().required(),
  defHair: yup.string().required(),
  defEyes: yup.string().required(),
  defHeight: yup.string().required(),
  defWeight: yup.string().required(),
});

interface ArrestWarrantProps {}

interface LocalState {
  disableImpoundRequested: boolean;
  disableOtherBelowFields: boolean;
  disableModifyInfo: boolean;
  modifyCaseInfo: boolean;
  goModifyCaseInfo: boolean;
  showDefendantInfoModel: boolean;
  showReturnToOfficerBtn: boolean;
  showConfirmPopup: boolean;
  confirmPopupMessage: string;
  confirmationPopupType: string;
  showSignPopup: boolean;
  ecFormStatus: string;
}

const initialState: LocalState = {
  disableImpoundRequested: false,
  disableOtherBelowFields: false,
  disableModifyInfo: false,
  modifyCaseInfo: false,
  goModifyCaseInfo: false,
  showDefendantInfoModel: false,
  showReturnToOfficerBtn: false,
  showConfirmPopup: false,
  confirmPopupMessage: "",
  confirmationPopupType: "",
  showSignPopup: false,
  ecFormStatus: "",
};

export const ArrestWarrant: React.FC<ArrestWarrantProps> = (props) => {
  let judgeUserValidationSchema = yup.object().shape({
    isImpoundWarrant: yup
      .string()
      .test("impoundWarrant-grant-deny", function (value) {
        if (
          value === "Y" &&
          !(this.parent.grantCheck === "Y" || this.parent.denyCheck === "Y")
        ) {
          return this.createError({
            message: "Grant or Deny is required.",
          });
        }
        return true;
      })
      .nullable(),
    otherCounty: yup
      .string()
      .test("other-county", function (value) {
        if (this.parent.otherCheck === "Y" && !value) {
          return this.createError({
            message: "Other field is required.",
          });
        }
        return true;
      })
      .nullable(),
    milegeLimitation: yup
      .string()
      .test("mileage-required-Limitation", function (value) {
        if (
          !(
            this.parent.defaultCountyNameCheck === "Y" ||
            this.parent.allCountiesCheck === "Y" ||
            this.parent.noLimitationCheck === "Y" ||
            this.parent.midwesternStatesCheck === "Y" ||
            this.parent.illinoisCheck === "Y" ||
            this.parent.otherCheck === "Y"
          )
        ) {
          return this.createError({
            message:
              "Please select an option under 'Mileage Limitations' section.",
          });
        }
        return true;
      }),
    release: yup
      .string()
      .test("release-Detention", function (value) {
        if (safeTactEnable) {
          if (
            !(
              this.parent.radioReleaseOn === "Y" ||
              this.parent.radioToBeHeld === "Y"
            )
          ) {
            return this.createError({
              message: "'Release/ Detention Options' are required.",
            });
          }
        }
        return true;
      })
      .nullable(),
    bail: yup
      .string()
      .test("bail", function (value) {
        if (!safeTactEnable) {
          let bailAmount = this.parent.bailFixedAtAmount;
          if (!(bailAmount !== null && bailAmount.length > 0))
            bailAmount = null;

          if (
            !(
              bailAmount !== null ||
              this.parent.noBondCheck === "Y" ||
              this.parent.toBeSetInBondCourt === "Y" ||
              this.parent.bailAuthorizedCheck === "Y" ||
              this.parent.fullBondRequiredCheck === "Y" ||
              this.parent.personalRecognizanceAuthorizedCheck === "Y"
            )
          ) {
            return this.createError({
              message: "Please enter correct details in ''Bail'' section.",
            });
          }
        }
        return true;
      })
      .nullable(),
    bailOption: yup
      .string()
      .test("bailOption", function (value) {
        if (!safeTactEnable) {
          let bailAmount = this.parent.bailFixedAtAmount;
          if (!(bailAmount !== null && bailAmount.length > 0))
            bailAmount = null;

          if (
            !(
              bailAmount ||
              this.parent.noBondCheck === "Y" ||
              this.parent.toBeSetInBondCourt === "Y" ||
              this.parent.bailAuthorizedCheck === "Y" ||
              this.parent.fullBondRequiredCheck === "Y" ||
              this.parent.personalRecognizanceAuthorizedCheck === "Y"
            )
          ) {
          } else if (
            !(
              this.parent.noBondCheck === "Y" ||
              this.parent.toBeSetInBondCourt === "Y" ||
              this.parent.bailAuthorizedCheck === "Y" ||
              this.parent.fullBondRequiredCheck === "Y" ||
              this.parent.personalRecognizanceAuthorizedCheck === "Y"
            )
          ) {
            return this.createError({
              message:
                "At least one option is required from bail radio buttons.",
            });
          }
        }
        return true;
      })
      .nullable(),
    bailFixedAmount: yup
      .string()
      .test("bailFixedAmount", function (value) {
        if (!safeTactEnable) {
          let bailAmount = this.parent.bailFixedAtAmount;
          if (!(bailAmount !== null && bailAmount.length > 0))
            bailAmount = null;

          if (
            !(
              bailAmount ||
              this.parent.noBondCheck === "Y" ||
              this.parent.toBeSetInBondCourt === "Y" ||
              this.parent.bailAuthorizedCheck === "Y" ||
              this.parent.fullBondRequiredCheck === "Y" ||
              this.parent.personalRecognizanceAuthorizedCheck === "Y"
            )
          ) {
          } else if (
            !(
              this.parent.bailAuthorizedCheck === "Y" ||
              this.parent.fullBondRequiredCheck === "Y" ||
              this.parent.personalRecognizanceAuthorizedCheck === "Y"
            )
          ) {
          } else if (
            !(
              bailAmount ||
              this.parent.noBondCheck === "Y" ||
              this.parent.toBeSetInBondCourt === "Y"
            )
          ) {
            return this.createError({
              message:
                "Please enter either a bail amount or check ''No Bond''.",
            });
          }
        }
        return true;
      })
      .nullable(),
  });

  const arrestWarrantReducer = useArrestWarrantReducer();
  const ecomplaintReducer = useComplaintMainReducer();
  const errorMessageAlert = useAlert();
  const { userType, safeTactEnable } = useLoginReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [state, _] = useState(initialState);
  const [errorKeys, setErrorKeys] = useState([""]);
  const { agencyList } = useAgencyListReducer();
  const [infoPopupShow, setInfoPopupShow] = useState(false);
  const complaintMain = useComplaintMainReducer();
  const {
    complaint,
    ecFormList,
    countList,
    localNote,
    successMessage,
    witnessList,
    complaintSupervised,
    createFormList,
    lfcCountyList
  } = useComplaintMainReducer();
  const loginReducer = useLoginReducer();
  const [
    isVisibleBtnReSubmitArrestWarrant,
    setIsVisibleBtnReSubmitArrestWarrant,
  ] = React.useState(false);
  const [isNoBondChk, setIsNoBondChk] = useState(false);
  const [isToBeSetInBondCourt, setIsToBeSetInBondCourt] = useState(false);
  const dashboardReducer = useDashboardReducer();
  const [defaultImpound, setDefaultImpound] = useState(false); //KHL PR 1256 25/01/24

  // Helper function to set state, pass key value pairs to update the existing state
  const setState = useCallback((e: Partial<LocalState>) => {
    _((s) => {
      return { ...s, ...e } as LocalState;
    });
  }, []);

  //Milage Limitation
  const setGeoLimitDefualtToMileageLimit = () => {
    let geoLimitDefault: string = "";
    if (ecomplaintReducer.geoLimitDefault) {
      let topKount: any = ecomplaintReducer.countList[0];
      if (topKount != null) {
        let geoLimitDefList: any = ecomplaintReducer.geoLimitDefault;
        if (geoLimitDefList != null) {
          geoLimitDefList.some((item: any) => {
            if (item.classOfViolation === topKount.classOfViolation) {
              geoLimitDefault = item.geoLimitDefault;
              return true;
            } else geoLimitDefault = "";
          });
        }
      }
    }

    if (geoLimitDefault) {
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: "defaultCountyNameCheck",
          value: geoLimitDefault === "DUPAGE" ? "Y" : "N",
        })
      );
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: "allCountiesCheck",
          value:
            geoLimitDefault === ecomplaintReducer.customGeoLimits ? "Y" : "N",
        })
      );
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: "customGeoLimitText",
          value: ecomplaintReducer.customGeoLimits
            ? ecomplaintReducer.customGeoLimits
            : "",
        })
      );
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: "noLimitationCheck",
          value: geoLimitDefault === "No Limitation" ? "Y" : "N",
        })
      );
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: "midwesternStatesCheck",
          value:
            geoLimitDefault ===
            "Midwestern States - IL, IN, WI, IA, MO, KY, MI and MN"
              ? "Y"
              : "N",
        })
      );
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: "illinoisCheck",
          value: geoLimitDefault === "Illinois" ? "Y" : "N",
        })
      );
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: "otherCheck",
          value: geoLimitDefault === "Other" ? "Y" : "N",
        })
      );
    }
  };

  ////KHL PR 1256 25/01/24 - START
  const ecCountyList = lfcCountyList.filter((county: any) => {
    if (county.countyID !== 0) {
      return county;
    }
  });
  //KHL PR 1256 25/01/24 - END

  useEffect(() => {
    getEcomplaintMainDetails({
      ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
      callingFrom: FETCH_LF_MAIN_DETAILS_FROM_ARRESTWARRANT,
    });
  });

  useEffect(() => {
    if (
      arrestWarrantReducer.awState === "edit" ||
      arrestWarrantReducer.awState === "editThrounghMainPage"
    ) {
      const arrestWarrant = ecomplaintReducer.ecFormList.find(
        (item: any) => item.formTypeName === LF_ARREST_WARRANT
      );

      let idValue = 0;
      if (arrestWarrantReducer.arrestWarrantObj.formId === 0) {
        idValue = arrestWarrant.formId;
      } else {
        idValue = arrestWarrantReducer.arrestWarrantObj.formId;
      }

      dispatch(fetchArrestWarrant(idValue));
    } else {
      //KHL PR 1256 25/01/24 - START
      if(arrestWarrantReducer.arrestWarrantObj.isImpoundWarrant === "Y") {
        setDefaultImpound(true);
      }
      //KHL PR 1256 25/01/24 - END
      dispatch(
        fetchInitialArrestWarrant(ecomplaintReducer.complaint.ecomplaintId)
      );
    }
    return () => {
      if (!state.goModifyCaseInfo) {
        dispatch(resetArrestWarrantData());
        //KHL PR 1256 06/02/24 - START
        if(ecCountyList.filter((county) => county.impoundEnable === true && 
        ecomplaintReducer.complaint.countyName === county.countyName).length > 0){
          dispatch(updateElementObjectValueArrestWarrantReducer({
            elementName: "isImpoundWarrant",
            value: "Y",
          }));
        }
        //KHL PR 1256 06/02/24 - END
      }
      dispatch(
        updateElementValueArrestWarrantReducer({
          elementName: "fetchArrestWarrantStatus",
          value: "idle",
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //KHL PR 1256 25/01/24 - START
  useEffect(() => {
    if (defaultImpound &&
      userType !== ROLE_JUDGE && arrestWarrantReducer.fetchArrestWarrantStatus === "success" ) {
        dispatch(updateElementObjectValueArrestWarrantReducer({
          elementName: "isImpoundWarrant",
          value: "Y",
        }));
      setDefaultImpound(false);
    }   
  }, [arrestWarrantReducer, ecCountyList]);
  //KHL PR 1256 25/01/24 - END

  const isFilledDemographics = () => {
    let { defDob, gender, defHair, defEyes, defHeight, defWeight } =
      arrestWarrantReducer.arrestWarrantObj;
    if (
      !defDob ||
      defDob.trim() === "" ||
      !gender ||
      gender.trim() === "" ||
      !defHair ||
      defHair.trim() === "" ||
      !defEyes ||
      defEyes.trim() === "" ||
      !defHeight ||
      isNaN(defHeight) ||
      defHeight.toString().trim() === "" ||
      parseFloat(defHeight) === 0 ||
      !defWeight ||
      isNaN(defWeight) ||
      defWeight.toString().trim() === "" ||
      parseFloat(defWeight) === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setVisibleElements();

    if (
      ecomplaintReducer.geoLimitDefault !== null &&
      arrestWarrantReducer.fetchArrestWarrantStatus === "success" &&
      userType === ROLE_JUDGE
    ) {
      setGeoLimitDefualtToMileageLimit();
      dispatch(
        updateElementValueArrestWarrantReducer({
          elementName: "fetchArrestWarrantStatus",
          value: "idle",
        })
      );
    }
  }, [ecomplaintReducer.geoLimitDefault, arrestWarrantReducer]);

  useEffect(() => {
    const arrestWarrantStatus =
      ecomplaintReducer.ecFormList instanceof Array
        ? ecomplaintReducer.ecFormList
            .filter((item) => item.formTypeName === LF_ARREST_WARRANT)
            .map((item) => (item.status ? item.status.trim() : ""))[0]
        : "";
    setState({
      ecFormStatus: arrestWarrantStatus,
    });
    if (
      userType === ROLE_POLICE_OFFICER ||
      userType === ROLE_AGENCY_ADMIN ||
      userType === ROLE_COUNTY_ADMIN
    ) {
      if (ecomplaintReducer.complaint.caseStatus !== LF_VOID_STATUS) {
        setState({
          disableImpoundRequested: true,
        });
        if (
          !state.modifyCaseInfo ||
          arrestWarrantStatus !== LF_REJECTED_STATUS
        ) {
          setState({
            disableImpoundRequested: false,
          });
        }
        // if (arrestWarrantStatus === LF_RETURNED_STATUS) {
        //   setState({
        //     disableModifyInfo: true,
        //   });
        // } else {
        //   setState({
        //     disableModifyInfo: false,
        //     disableImpoundRequested: false,
        //   });
        // }
        if (arrestWarrantStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW) {
          setState({
            showReturnToOfficerBtn: true,
          });
        }
      } else {
        setState({
          disableModifyInfo: true,
          disableImpoundRequested: true,
          disableOtherBelowFields: true,
        });
      }
    } else if (userType === ROLE_JUDGE) {
      setState({
        disableModifyInfo: true,
        disableImpoundRequested: false,
        showReturnToOfficerBtn: true,
      });
    }

    if (
      arrestWarrantStatus === LF_DELETED_STATUS ||
      arrestWarrantStatus === LF_SIGNED_STATUS
    ) {
      setState({
        disableModifyInfo: true,
        disableImpoundRequested: true,
        disableOtherBelowFields: true,
      });
    }
  }, [
    ecomplaintReducer.complaint.caseStatus,
    ecomplaintReducer.ecFormList,
    userType,
    setState,
  ]);

  useEffect(() => {
    if (
      arrestWarrantReducer.fetchArrestWarrantStatus === "success" &&
      !isFilledDemographics() &&
      ecomplaintReducer.complaint.caseStatus === LF_FILED_STATUS
    ) {
      handleModifyDefendantInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrestWarrantReducer.fetchArrestWarrantStatus]);

  useEffect(() => {
    if (
      (userType === ROLE_POLICE_OFFICER || userType === ROLE_AGENCY_ADMIN) &&
      ecomplaintReducer.complaint.caseStatus === LF_FILED_STATUS
    ) {
      if (
        state.ecFormStatus === LF_PENDING_STATUS ||
        state.ecFormStatus === LF_REJECTED_STATUS ||
        state.ecFormStatus === LF_RETURNED_STATUS ||
        state.ecFormStatus === LF_SEND_TO_JUDGE_STATUS ||
        state.ecFormStatus === LF_COMPLETE_STATUS
      ) {
        setState({
          modifyCaseInfo: false,
        });
      }
    } else {
      setState({
        modifyCaseInfo: true,
      });
    }
  }, [
    arrestWarrantReducer.arrestWarrantObj,
    ecomplaintReducer.complaint.caseStatus,
    setState,
    state.ecFormStatus,
    userType,
  ]);

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hideDefendantInfoModel = useCallback(
    () =>
      setState({
        showDefendantInfoModel: false,
      }),
    [setState]
  );

  const handleModifyInfo = useCallback(() => {
    if (state.modifyCaseInfo) {
      setState({
        goModifyCaseInfo: true,
      });
      let dataObj = {
        ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
        navigate: navigate,
      };
      dispatch(getEComplaintData(dataObj));
      dispatch(
        updateElementValueComplaintReducer({
          elementName: "returnToPage",
          value: "Arrest Warrant",
        })
      );
      navigate("/eComplaintCaseInfoPage");
    } else {
      setState({ showDefendantInfoModel: true });
      // hideDefendantInfoModel();
    }
  }, [
    state.modifyCaseInfo,
    setState,
    dispatch,
    navigate,
    hideDefendantInfoModel,
  ]);

  const handleModifyDefendantInfo = () => {
    const {
      defFirstName,
      defLastName,
      gender,
      defRace,
      defDob,
      defHeight,
      defWeight,
      defEyes,
      defHair,
    } = arrestWarrantReducer.arrestWarrantObj;

    let defendantName = formatNameWithSuffix(defFirstName, "", defLastName, "");

    if (
      !defDob ||
      defDob.trim() === "" ||
      !defendantName ||
      defendantName.trim() === "" ||
      !gender ||
      gender.trim() === "" ||
      !defRace ||
      defRace.trim() === "" ||
      !defHair ||
      defHair.trim() === "" ||
      !defEyes ||
      defEyes.trim() === "" ||
      !defHeight ||
      isNaN(defHeight) ||
      defHeight.toString().trim() === "" ||
      parseFloat(defHeight) === 0 ||
      !defWeight ||
      isNaN(defWeight) ||
      defWeight.toString().trim() === "" ||
      parseFloat(defWeight) === 0
    ) {
      setState({ showDefendantInfoModel: true });
    } else {
      setState({ showDefendantInfoModel: false });
    }
  };

  const validate = async () => {
    let _error = false;
    if (userType === ROLE_JUDGE) {
      await judgeUserValidationSchema
        .validate(arrestWarrantReducer.arrestWarrantObj, { abortEarly: false })
        .catch((error: any) => {
          _error = true;
          handleError(
            error.inner.map((e: any) => e.path),
            error.errors
          );
        });
    }

    await defInfoSchema
      .validate(
        arrestWarrantReducer.defInfoObject.defFirstName === ""
          ? arrestWarrantReducer.arrestWarrantObj
          : arrestWarrantReducer.defInfoObject,
        {
          abortEarly: false,
        }
      )
      .catch((error: any) => {
        _error = true;
        handleError(
          ["deInfoSchema"],
          [
            "Please complete defendant information before submitting this to the Judge.",
          ]
        );
      });
    return !_error;
  };

  const setVisibleElements = () => {
    if (
      loginReducer.canReSubmitEComplaint &&
      complaintMain.countyIntegratedWithCMS &&
      complaintMain.arrestWarrantUploadFailed
    ) {
      setIsVisibleBtnReSubmitArrestWarrant(true);
    }
  };

  const handleReturnClick = () => {
    let message = "";
    if (
      ecomplaintReducer.complaint.caseStatus ===
      LF_STATUS_PENDING_SUPERVISOR_REVIEW
    ) {
      message = "Do you want to return the arrest warrant to the officer?";
    } else {
      message =
        "Do you want to return the arrest warrant to the officer without signing the warrant?";
    }
    setState({
      showConfirmPopup: true,
      confirmPopupMessage: message,
      confirmationPopupType: "returnToOfficer",
    });
  };

  const handleRejectClick = () => {
    setState({
      showConfirmPopup: true,
      confirmPopupMessage:
        "Do you want to reject the arrest warrant without signing the warrant?",
      confirmationPopupType: "reject",
    });
  };

  const handleSaveClick = async () => {
    const isValid = await validate();
    if (!isValid) {
      return;
    }
    if (ecomplaintReducer.complaint.caseStatus === LF_SEND_TO_JUDGE_STATUS) {
      setInfoPopupShow(true);
      return;
    }
    if (
      ecomplaintReducer.complaint.caseStatus === LF_FILED_STATUS &&
      (state.ecFormStatus === LF_PENDING_STATUS ||
        state.ecFormStatus === LF_RETURNED_STATUS ||
        state.ecFormStatus === LF_REJECTED_STATUS)
    ) {
      setState({
        confirmPopupMessage:
          "You are about to send the arrest warrant to the Judge for a signature.  Are you sure you want to continue?",
      });
      if (
        agencyList.length > 0 &&
        agencyList[0].isComplaintSupervised &&
        userType === ROLE_POLICE_OFFICER &&
        ecomplaintReducer.complaint.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT
      ) {
        setState({
          confirmPopupMessage:
            "You are about to send the arrest warrant to the Supervisor. Are you sure you want to continue?",
        });
      }
      setState({
        showConfirmPopup: true,
      });
    } else if (
      state.ecFormStatus === LF_REJECTED_STATUS ||
      (state.ecFormStatus === LF_RETURNED_STATUS &&
        ecomplaintReducer.complaint.caseStatus ===
          LF_STATUS_PENDING_SUPERVISOR_RETURNED) ||
      state.ecFormStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW
    ) {
      setState({
        confirmPopupMessage:
          "You are about to submit this complaint and arrest warrant to the Judge for signature.  Are you sure you want to continue?",
      });
      if (
        agencyList[0].isComplaintSupervised &&
        userType === ROLE_POLICE_OFFICER
      ) {
        setState({
          confirmPopupMessage:
            "You are about to submit this complaint and arrest warrant to the Supervisor. Are you sure you want to continue?",
        });
      }
      setState({
        showConfirmPopup: true,
      });
    } else if (state.ecFormStatus === LF_RETURNED_STATUS) {
      dispatch(
        saveArrestWarrant({
          arrestWarrantObj: arrestWarrantReducer.arrestWarrantObj,
          status: LF_RETURNED_STATUS,
          ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
          navigate: navigate,
        })
      );
    } else {
      dispatch(
        saveArrestWarrant({
          arrestWarrantObj: arrestWarrantReducer.arrestWarrantObj,
          status: LF_COMPLETE_STATUS,
          ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
          navigate: navigate,
        })
      );
    }
  };

  const handleConfirmationClick = async () => {
    if (state.confirmationPopupType === "sign") {
      const res = await dispatch(getJudgeSavedSignature({
        userprofileId: loginReducer.userProfileId
      }));
      if (res.payload.message) {
        dispatch(setSignature(res.payload.message));
      }
      setState({ showSignPopup: true });
    } else if (state.confirmationPopupType === "returnToOfficer") {
      dispatch(
        returnToOfficerArrestWarrant({
          navigate: navigate,
          navigateFrom: "ArrestWarrant",
          formId: arrestWarrantReducer.arrestWarrantObj.formId,
          formData: arrestWarrantReducer.arrestWarrantObj,
        })
      );
      // .then((res)=>{
      //   if(res){
      //     navigate("/EComplaintDashboard");
      //   }
      // })
    } else if (state.confirmationPopupType === "reject") {
      // dispatch(
      //   rejectArrestWarrant({
      //     navigate: navigate,
      //     navigateFrom: "ArrestWarrant",
      //   })
      // );
      let data = {
        formId: arrestWarrantReducer.arrestWarrantObj.formId,
        formType: CREATE_LF_FORM_ARREST_WARRANT,
        ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
        status: LF_REJECTED_STATUS,
        formData: arrestWarrantReducer.arrestWarrantObj,
        navigate: navigate,
      };
      dispatch(editComplaint(data));
    } else {
      dispatch(
        saveArrestWarrant({
          arrestWarrantObj: arrestWarrantReducer.arrestWarrantObj,
          status: LF_COMPLETE_STATUS,
          ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
          navigate: navigate,
        })
      );
    }
  };

  const handleSignClick = async () => {
    const isValid = await validate();
    if (!isValid) {
      return;
    }
    setState({
      confirmationPopupType: "sign",
      confirmPopupMessage:
        "Form cannot be changed once signed. Do you want to continue?",
    });
    const isArrestWarrant =
      ecomplaintReducer.ecFormList instanceof Array &&
      ecomplaintReducer.ecFormList.find(
        (item) => item.formTypeName === CREATE_LF_FORM_ARREST_WARRANT
      );
    if (isArrestWarrant) {
      setState({
        confirmPopupMessage:
          "Has the officer sworn to the content of this Complaint/Arrest Warrant?",
      });
    }
    setState({
      showConfirmPopup: true,
    });
  };

  const handleSendToJudgeClick = () => {};

  const handlePreviewClick = () => {
    let Obj = {
      ...arrestWarrantReducer.arrestWarrantObj
    }
    let formId = arrestWarrantReducer.arrestWarrantObj.formId;
    if (formId === 0){
      const arrest = ecFormList.find(
        (item: any) => item.formTypeName === CREATE_LF_FORM_ARREST_WARRANT
      );
      Obj = {
        ...arrestWarrantReducer.arrestWarrantObj,
        formId: arrest.formId,
      };
      formId = arrest.formId;
    }
    dispatch(
      previewArrestWarrent({
        ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
        ecFormId: formId,
        formData: Obj,
      })
    );
  };

  const handleCancelClick = () => {
    dispatch(resetArrestWarrantData());
    if (arrestWarrantReducer.awState === "edit") {
      navigate("/EComplaintDashboard");
    } else if (arrestWarrantReducer.awState === "editThrounghMainPage") {
      navigate("/EComplaintMainPage");   
    } else if (arrestWarrantReducer.awState === "createFromDashBoard") { //KHL PR 1256 06/02/24
      navigate("/EComplaintDashboard"); //KHL PR 1256 06/02/24 
    } else {
      navigate(-1);
    }

    //KHL PR 1256 06/02/24 - START
    if(ecCountyList.filter((county) => county.impoundEnable === true && 
        ecomplaintReducer.complaint.countyName === county.countyName).length > 0){
        dispatch(updateElementObjectValueArrestWarrantReducer({
          elementName: "isImpoundWarrant",
          value: "Y",
        }));
      }
    //KHL PR 1256 06/02/24 - END
  };

  const handelChangeRadio = (event: any) => {
    const { id, checked, name } = event.target;
    dispatch(
      updateElementObjectValueArrestWarrantReducer({
        elementName: id,
        value: checked ? "Y" : "N",
      })
    );

    if (name === "selectedCount") {
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: "selectedKountId",
          value: id,
        })
      );
    } else if (name === "milegeLimitation") {
      if (id !== "otherCheck") {
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "otherCounty",
            value: "",
          })
        );
      }
      if (id === "allCountiesCheck" && checked) {
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "customGeoLimitText",
            value: ecomplaintReducer.customGeoLimits
              ? ecomplaintReducer.customGeoLimits
              : "",
          })
        );
      }
      dispatch((dispatch) => {
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "defaultCountyNameCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "allCountiesCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "noLimitationCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "midwesternStatesCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "illinoisCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "otherCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: id,
            value: checked ? "Y" : "N",
          })
        );
      });
    } else if (name === "release") {
      dispatch((dispatch) => {
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "radioReleaseOn",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "radioToBeHeld",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: id,
            value: checked ? "Y" : "N",
          })
        );
      });
    } else if (name === "impoundGrant") {
      dispatch((dispatch) => {
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "grantCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "denyCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: id,
            value: checked ? "Y" : "N",
          })
        );
      });
    } else if (name === "bail") {
      if (id === "bailAuthorizedCheck") {
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "bailAuthorizedCheck",
            value: "Y",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "fullBondRequiredCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "personalRecognizanceAuthorizedCheck",
            value: "N",
          })
        );
      } else if (id === "fullBondRequiredCheck") {
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "bailAuthorizedCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "fullBondRequiredCheck",
            value: "Y",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "personalRecognizanceAuthorizedCheck",
            value: "N",
          })
        );
      } else if (id === "personalRecognizanceAuthorizedCheck") {
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "bailAuthorizedCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "fullBondRequiredCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "personalRecognizanceAuthorizedCheck",
            value: "Y",
          })
        );
      }
    } else {
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: id,
          value: checked ? "Y" : "N",
        })
      );
    }
  };

  const handelChangeCheckBox = (event: any) => {
    const { id, checked, name } = event.target;
    if (name === "impoundWarrant") {
      if (!checked) {
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "grantCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "denyCheck",
            value: "N",
          })
        );
      }
      //KHL PR 1256 25/01/24 - START
      dispatch(
        updateElementObjectValueComplaint({
          elementName: "impoundWarrant",
          value: checked ? true : false,
        })
      );
      //KHL PR 1256 25/01/24 - END
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: id,
          value: checked ? "Y" : "N",
        })
      );
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: "isImpoundWarrant",
          value: checked ? "Y" : "N",
        })
      );
    } else if (name === "noBondCheck") {
      if (checked) {
        setIsNoBondChk(true);
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "bailFixedAtAmount",
            value: "No Bond",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "bailAuthorizedCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "fullBondRequiredCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "personalRecognizanceAuthorizedCheck",
            value: "N",
          })
        );
      } else {
        setIsNoBondChk(false);
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "bailFixedAtAmount",
            value: "",
          })
        );
      }
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: id,
          value: checked ? "Y" : "N",
        })
      );
    } else if (name === "toBeSetInBondCourt") {
      if (checked) {
        setIsToBeSetInBondCourt(true);
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "bailFixedAtAmount",
            value: "To Be Set In Bond Court",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "bailAuthorizedCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "fullBondRequiredCheck",
            value: "N",
          })
        );
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "personalRecognizanceAuthorizedCheck",
            value: "N",
          })
        );
      } else {
        setIsToBeSetInBondCourt(false);
        dispatch(
          updateElementObjectValueArrestWarrantReducer({
            elementName: "bailFixedAtAmount",
            value: "",
          })
        );
      }
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: id,
          value: checked ? "Y" : "N",
        })
      );
    } else {
      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: id,
          value: checked ? "Y" : "N",
        })
      );
    }
  };

  const handleValChange = (event: any) => {
    const { value, name } = event.target;
    dispatch(
      updateElementObjectValueArrestWarrantReducer({ elementName: name, value })
    );
  };

  const onHideInfoPopup = () => {
    setInfoPopupShow(false);
    navigate("/EComplaintDashboard");
  };

  const resubmitArrestWarrant = () => {
    let ecCaseDetail = { ...complaintMain };
    let ecomplaintId = complaint.ecomplaintId;
    let fromScheduler = false;
    let dataObj = {
      ecCaseDetail: ecCaseDetail,
      ecomplaintId: ecomplaintId,
      fromScheduler: fromScheduler,
      navigate: navigate,
    };
    dispatch(resubmitArrestWarrantImage(dataObj));
  };

  const formComplaintInfo = [
    {
      type: "customComponents1",
      props: {},
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Warrant Control Number : </b>{" "}
            {arrestWarrantReducer.arrestWarrantObj.warrantControlNumber}
          </label>
        ),
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr className="hrStyle" />,
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Name : </b>{" "}
            {formatNameWithSuffix(
              arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
                ? arrestWarrantReducer.arrestWarrantObj.defendentInfo
                    .defFirstName
                : ecomplaintReducer.complaint.defFirstName,
              arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
                ? arrestWarrantReducer.arrestWarrantObj.defendentInfo
                    .defMiddleName
                : ecomplaintReducer.complaint.defMiddleName,
              arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
                ? arrestWarrantReducer.arrestWarrantObj.defendentInfo
                    .defLastName
                : ecomplaintReducer.complaint.defLastName,
              arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
                ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defSuffix
                : ecomplaintReducer.complaint.defSuffix
            )}
          </label>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Date of Birth : </b>
            {arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
              ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defDob
              : ecomplaintReducer.complaint.defDob}
          </label>
        ),
      },
      colSize: 3,
    },

    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Sex : </b>{" "}
            {arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
              ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.gender
              : ecomplaintReducer.complaint.defGender}
          </label>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Race : </b>{" "}
            {arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
              ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defRace
              : ecomplaintReducer.complaint.defRace}
          </label>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr className="hrStyle" />,
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Height : </b>
            {arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
              ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defHeight
              : ecomplaintReducer.complaint.defHeight}
          </label>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Weight : </b>
            {arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
              ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defWeight
              : ecomplaintReducer.complaint.defWeight}
          </label>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Eyes : </b>
            {arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
              ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defEyes
              : ecomplaintReducer.complaint.defEyeColor}
          </label>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Hair : </b>
            {arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
              ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defHair
              : ecomplaintReducer.complaint.defHairColor}
          </label>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: <hr className="hrStyle" />,
      },
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Drivers License : </b>{" "}
            {arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
              ? arrestWarrantReducer.arrestWarrantObj.defendentInfo
                  .defDriversLicense
              : ecomplaintReducer.complaint.defDriverlicense}
          </label>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Document Control Number : </b>{" "}
            {arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
              ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.dcn
              : ecomplaintReducer.complaint.dcn}
          </label>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Address : </b>
            {formatAddress(
              arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
                ? arrestWarrantReducer.arrestWarrantObj.defendentInfo
                    .defAddress1
                : ecomplaintReducer.complaint.defAddressLine1,
              arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
                ? arrestWarrantReducer.arrestWarrantObj.defendentInfo
                    .defAddress2
                : ecomplaintReducer.complaint.defAddressLine2,
              arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
                ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defCity
                : ecomplaintReducer.complaint.defCity,
              arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
                ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defState
                : ecomplaintReducer.complaint.defState,
              arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
                ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defZip
                : ecomplaintReducer.complaint.defZip,
              arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
                ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defCountry
                : ecomplaintReducer.complaint.defCountry
            )}
          </label>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <label>
            <b>Telephone : </b>
            {arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null
              ? arrestWarrantReducer.arrestWarrantObj.defendentInfo.defTelephone
              : arrestWarrantReducer.arrestWarrantObj.defTelephone}
          </label>
        ),
      },
      colSize: 3,
    },
  ];

  const formDataOffenses = [
    {
      type: "htmlTag",
      props: {
        value: (
          <>
            <span className="title-text">
              <p>Offenses</p>
            </span>
          </>
        ),
      },
      colSize: 12,
    },
    {
      type: "customComponents2",
      props: {},
      colSize: 12,
    },
  ];

  const handleLongFormListClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  };

  const itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    {
      itemLink:
        "/" + dashboardReducer.PreviousURLLFC.replace(/^.*\/\/.*?\//, ""),
      itemName: "Long Form List",
      itemFunction: handleLongFormListClick,
    },
    { itemLink: "/EComplaintDashboard", itemName: "Existing Forms" },
  ];

  const formImpound = [
    {
      type: "htmlTag",
      props: {
        value: (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <p className="mt-3 ml-2">
              <b>IMPOUND CASE:</b>
            </p>
            <CheckBoxComponent
              name="impoundWarrant"
              onChange={handelChangeCheckBox}
              type="checkbox"
              disabled={state.disableImpoundRequested}
              id="impoundWarrant"
              checked={
                arrestWarrantReducer.arrestWarrantObj.isImpoundWarrant === "Y"
              }
            />
          </div>
        ),
      },
      colSize: 2,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginTop: "18px",
            }}
            hidden={
              ROLE_JUDGE !== userType ||
              arrestWarrantReducer.arrestWarrantObj.isImpoundWarrant !== "Y"
            }
          >
            <CheckBoxComponent
              id="grantCheck"
              onChange={handelChangeRadio}
              checked={arrestWarrantReducer.arrestWarrantObj.grantCheck === "Y"}
              label="Grant"
              type="radio"
              name="impoundGrant"
              isError={errorKeys.includes("isImpoundWarrant")}
              className="me-5"
            />
            <CheckBoxComponent
              id="denyCheck"
              onChange={handelChangeRadio}
              checked={arrestWarrantReducer.arrestWarrantObj.denyCheck === "Y"}
              label="Deny"
              type="radio"
              name="impoundGrant"
              isError={errorKeys.includes("isImpoundWarrant")}
              className="me-5"
            />
          </div>
        ),
      },
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <Row>
            <Col className="text-start">
              {state.showReturnToOfficerBtn && (
                <ButtonComponent onClick={handleReturnClick} variant="info">
                  Return to Officer
                </ButtonComponent>
              )}
              {userType === ROLE_JUDGE && (
                <ButtonComponent
                  onClick={handleRejectClick}
                  variant="danger"
                  className="mx-1"
                >
                  Reject
                </ButtonComponent>
              )}
            </Col>
            <Col className="text-center">
              {(userType === ROLE_POLICE_OFFICER ||
                userType === ROLE_AGENCY_ADMIN ||
                userType === ROLE_COUNTY_ADMIN ||
                userType === ROLE_STATES_ATTORNEY ||
                userType === ROLE_SA_ADMIN) &&
                ecomplaintReducer.complaint.caseStatus !== LF_VOID_STATUS &&
                state.ecFormStatus !== LF_SIGNED_STATUS &&
                state.ecFormStatus !== LF_DELETED_STATUS && (
                  <ButtonComponent onClick={handleSaveClick} variant="success">
                    {(userType === ROLE_POLICE_OFFICER ||
                      userType === ROLE_AGENCY_ADMIN) &&
                    ecomplaintReducer.complaint.caseStatus ===
                      LF_FILED_STATUS &&
                    (state.ecFormStatus === LF_PENDING_STATUS ||
                      state.ecFormStatus === LF_REJECTED_STATUS ||
                      state.ecFormStatus === LF_RETURNED_STATUS ||
                      state.ecFormStatus === LF_SEND_TO_JUDGE_STATUS ||
                      state.ecFormStatus === LF_COMPLETE_STATUS)
                      ? agencyList.length > 0 &&
                        agencyList[0].isComplaintSupervised &&
                        userType === ROLE_POLICE_OFFICER &&
                        ecomplaintReducer.complaint.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT
                        ? "Send to Supervisor"
                        : "Send to Judge"
                      : "Save"}
                  </ButtonComponent>
                )}
              {userType === ROLE_JUDGE &&
                state.ecFormStatus === LF_SEND_TO_JUDGE_STATUS && (
                  <ButtonComponent
                    onClick={handleSignClick}
                    variant="success"
                    className="mx-1"
                  >
                    Sign
                  </ButtonComponent>
                )}
              {loginReducer.canReSubmitEComplaint &&
                complaintMain.countyIntegratedWithCMS &&
                complaintMain.arrestWarrantUploadFailed && (
                  <ButtonComponent
                    className="btn-success mt-1"
                    onClick={() => resubmitArrestWarrant()}
                  >
                    Re-submit Arrest Warrant
                  </ButtonComponent>
                )}
            </Col>
            <Col className="text-end">
              {!(
                ((userType === ROLE_POLICE_OFFICER ||
                  userType === ROLE_AGENCY_ADMIN ||
                  userType === ROLE_COUNTY_ADMIN) &&
                  ecomplaintReducer.complaint.caseStatus === LF_VOID_STATUS) ||
                state.ecFormStatus === LF_DELETED_STATUS
              ) && (
                <ButtonComponent
                  onClick={handlePreviewClick}
                  variant="info"
                  className="mx-1"
                >
                  Preview
                </ButtonComponent>
              )}

              <ButtonComponent variant="danger" onClick={handleCancelClick}>
                Cancel
              </ButtonComponent>
            </Col>
          </Row>
        ),
      },
      colSize: 12,
    },
  ];

  const formDataMilageAndBail = [
    {
      type: "htmlTag",
      props: {
        value: (
          <>
            <span className="title-text">
              <p>Mileage Limitations</p>
            </span>
          </>
        ),
      },
      colSize: 12,
    },
    {
      type: "checkbox",
      props: {
        label: arrestWarrantReducer.arrestWarrantObj.defaultCountyName,
        type: "radio",
        id: "defaultCountyNameCheck",
        checked:
          arrestWarrantReducer.arrestWarrantObj.defaultCountyNameCheck === "Y",
        disabled: userType !== ROLE_JUDGE,
        name: "milegeLimitation",
        onChange: handelChangeRadio,
        isError: errorKeys.includes("milegeLimitation"),
        autoComplete: "nope",
      },
      colSize: 2,
    },
    {
      type: "checkbox",
      props: {
        label: "Illinois",
        type: "radio",
        id: "illinoisCheck",
        checked: arrestWarrantReducer.arrestWarrantObj.illinoisCheck === "Y",
        name: "milegeLimitation",
        onChange: handelChangeRadio,
        disabled: userType !== ROLE_JUDGE,
        isError: errorKeys.includes("milegeLimitation"),
        autoComplete: "nope",
      },
      colSize: 2,
    },
    {
      type: "checkbox",
      props: {
        label: "No Limitaion",
        type: "radio",
        id: "noLimitationCheck",
        checked:
          arrestWarrantReducer.arrestWarrantObj.noLimitationCheck === "Y",
        name: "milegeLimitation",
        onChange: handelChangeRadio,
        disabled: userType !== ROLE_JUDGE,
        isError: errorKeys.includes("milegeLimitation"),
        autoComplete: "nope",
      },
      colSize: 4,
    },
    {
      type: "checkbox",
      props: {
        label: ecomplaintReducer.customGeoLimits
          ? ecomplaintReducer.customGeoLimits
          : "DeKalb, DuPage, Lake, McHenry, Kane, Kendall, Will and Cook Counties",
        type: "radio",
        id: "allCountiesCheck",
        checked: arrestWarrantReducer.arrestWarrantObj.allCountiesCheck === "Y",
        name: "milegeLimitation",
        onChange: handelChangeRadio,
        disabled: userType !== ROLE_JUDGE,
        isError: errorKeys.includes("milegeLimitation"),
        autoComplete: "nope",
      },
      colSize: 12,
    },
    {
      type: "checkbox",
      props: {
        label: "Midwestern States - IL, IN, WI, IA, MO, KY, MI and MN",
        type: "radio",
        id: "midwesternStatesCheck",
        checked:
          arrestWarrantReducer.arrestWarrantObj.midwesternStatesCheck === "Y",
        name: "milegeLimitation",
        onChange: handelChangeRadio,
        disabled: userType !== ROLE_JUDGE,
        isError: errorKeys.includes("milegeLimitation"),
        autoComplete: "nope",
      },
      colSize: 12,
    },
    {
      type: "checkbox",
      props: {
        label: "Other",
        type: "radio",
        id: "otherCheck",
        checked: arrestWarrantReducer.arrestWarrantObj.otherCheck === "Y",
        disabled: userType !== ROLE_JUDGE,
        name: "milegeLimitation",
        onChange: handelChangeRadio,
        isError: errorKeys.includes("milegeLimitation"),
        autoComplete: "nope",
      },
      colSize: 2,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "otherCounty",
        value: arrestWarrantReducer.arrestWarrantObj.otherCounty,
        disabled: arrestWarrantReducer.arrestWarrantObj.otherCheck !== "Y",
        onChange: handleValChange,
        isError: errorKeys.includes("otherCounty"),
        autoComplete: "nope",
      },
      colSize: 6,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <>
            <span className="title-text">
              <p>Bail</p>
            </span>
          </>
        ),
      },
      colHide: safeTactEnable,
      colSize: 12,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "bailFixedAtAmount",
        value: arrestWarrantReducer.arrestWarrantObj.bailFixedAtAmount,
        onChange: handleValChange,
        autoComplete: "nope",
        disabled:
          userType !== ROLE_JUDGE || isNoBondChk || isToBeSetInBondCourt,
        isError:
          errorKeys.includes("bail") || errorKeys.includes("bailFixedAmount"),
      },
      colHide: safeTactEnable,
      colSize: 4,
      label: "Bail is fixed at: $",
      inLine: true,
    },
    {
      type: "checkbox",
      props: {
        label: <div>No Bond</div>,
        name: "noBondCheck",
        id: "noBondCheck",
        onChange: handelChangeCheckBox,
        checked: arrestWarrantReducer.arrestWarrantObj.noBondCheck === "Y",
        autoComplete: "nope",
        disabled: userType !== ROLE_JUDGE || isToBeSetInBondCourt,
        isError:
          errorKeys.includes("bail") || errorKeys.includes("bailFixedAmount"),
      },
      colHide: safeTactEnable,
      colSize: 1,
      colClassName: "mt-1",
    },
    {
      type: "checkbox",
      props: {
        label: <div>To Be Set In Bond Court</div>,
        name: "toBeSetInBondCourt",
        id: "toBeSetInBondCourt",
        onChange: handelChangeCheckBox,
        checked:
          arrestWarrantReducer.arrestWarrantObj.toBeSetInBondCourt === "Y",
        autoComplete: "nope",
        disabled: userType !== ROLE_JUDGE || isNoBondChk,
        isError: errorKeys.includes("bail"),
      },
      colHide: safeTactEnable,
      colSize: 6,
      colClassName: "mt-1",
    },
    {
      type: "checkbox",
      props: {
        label: "10% Bail Authorized",
        type: "radio",
        id: "bailAuthorizedCheck",
        checked:
          arrestWarrantReducer.arrestWarrantObj.bailAuthorizedCheck === "Y",
        name: "bail",
        onChange: handelChangeRadio,
        disabled:
          userType !== ROLE_JUDGE || isNoBondChk || isToBeSetInBondCourt,
        isError: errorKeys.includes("bail") || errorKeys.includes("bailOption"),
        autoComplete: "nope",
      },
      colHide: safeTactEnable,
      colSize: 2,
    },
    {
      type: "checkbox",
      props: {
        label: "Full Bond Required",
        type: "radio",
        id: "fullBondRequiredCheck",
        checked:
          arrestWarrantReducer.arrestWarrantObj.fullBondRequiredCheck === "Y",
        name: "bail",
        onChange: handelChangeRadio,
        disabled:
          userType !== ROLE_JUDGE || isNoBondChk || isToBeSetInBondCourt,
        isError: errorKeys.includes("bail") || errorKeys.includes("bailOption"),
        autoComplete: "nope",
      },
      colHide: safeTactEnable,
      colSize: 2,
    },
    {
      type: "checkbox",
      props: {
        label: "Personal Recognizance Authorized",
        type: "radio",
        id: "personalRecognizanceAuthorizedCheck",
        checked:
          arrestWarrantReducer.arrestWarrantObj
            .personalRecognizanceAuthorizedCheck === "Y",
        name: "bail",
        onChange: handelChangeRadio,
        disabled:
          userType !== ROLE_JUDGE || isNoBondChk || isToBeSetInBondCourt,
        isError: errorKeys.includes("bail") || errorKeys.includes("bailOption"),
        autoComplete: "nope",
      },
      colHide: safeTactEnable,
      colSize: 3,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <>
            <span className="title-text">
              <p>Release/ Detention</p>
            </span>
          </>
        ),
      },
      colHide: !safeTactEnable,
      colSize: 12,
    },
    {
      type: "checkbox",
      props: {
        label: "Release on personal recognizance authorized",
        type: "radio",
        name: "release",
        disabled: userType !== ROLE_JUDGE,
        id: "radioReleaseOn",
        checked: arrestWarrantReducer.arrestWarrantObj.radioReleaseOn === "Y",
        onChange: handelChangeRadio,
        isError: errorKeys.includes("release"),
        autoComplete: "nope",
      },
      colHide: !safeTactEnable,
      colSize: 12,
    },
    {
      type: "checkbox",
      props: {
        label:
          "Defendant to be held without release to be brought before this Court",
        type: "radio",
        name: "release",
        disabled: userType !== ROLE_JUDGE,
        id: "radioToBeHeld",
        checked: arrestWarrantReducer.arrestWarrantObj.radioToBeHeld === "Y",
        isError: errorKeys.includes("release"),
        onChange: handelChangeRadio,
        autoComplete: "nope",
      },
      colHide: !safeTactEnable,
      colSize: 12,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        value: arrestWarrantReducer.arrestWarrantObj.otherText,
        name: "otherText",
        onChange: handleValChange,
        disabled: state.disableOtherBelowFields,
        autoComplete: "nope",
      },
      colSize: 12,
      label: "Other",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "originatingDeptMunicipalNumber",
        value:
          arrestWarrantReducer.arrestWarrantObj.originatingDeptMunicipalNumber,
        onChange: handleValChange,
        autoComplete: "nope",
        // disabled: userType !== ROLE_JUDGE,
      },
      colSize: 12,
      label: "Originating Dept. or Municipal Number",
      inLine: true,
    },
  ];

  const CustomComponents1 = useCallback(() => {
    return (
      <Row>
        <Col className="text-end">
          <ButtonComponent
            variant="info"
            disabled={state.disableModifyInfo}
            onClick={handleModifyInfo}
          >
            <RiEditLine /> Modify{" "}
            {state.modifyCaseInfo ? "Complaint" : "Defendant"} Information
          </ButtonComponent>{" "}
        </Col>
      </Row>
    );
  }, [handleModifyInfo, state.disableModifyInfo, state.modifyCaseInfo]);

  const CustomComponents2 = useCallback(() => {
    return (
      <div>
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Violation</th>
              <th>Statute</th>
            </tr>
          </thead>
          <tbody>
            {ecomplaintReducer.countList instanceof Array &&
              ecomplaintReducer.countList.map((item, index) => (
                <tr key={item.violationCode}>
                  <td>
                    <RiPlayMiniFill className="text-info" />{" "}
                    {item.violationDescription}
                  </td>
                  <td>{item.violationCode}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  }, [ecomplaintReducer.countList]);

  return (
    <>
      <Header headerName="Arrest Warrant" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Arrest Warrant"
        />
        <Alert {...errorMessageAlert} />
        <Card>
          <Card.Body>
            <FormComponent
              totalColumns={12}
              colSize={6}
              componentData={formComplaintInfo}
              components={[
                {
                  type: "customComponents1",
                  node: CustomComponents1,
                },
              ]}
            />
            <FormComponent
              totalColumns={12}
              colSize={6}
              componentData={formDataOffenses}
              components={[
                {
                  type: "customComponents2",
                  node: CustomComponents2,
                },
              ]}
            />
            <FormComponent
              totalColumns={12}
              colSize={6}
              componentData={formDataMilageAndBail}
            />
            <FormComponent
              totalColumns={12}
              colSize={6}
              componentData={formImpound}
              formCardStyle="p-0"
            />
          </Card.Body>
        </Card>
      </div>
      <ConfirmPopup
        children={state.confirmPopupMessage}
        show={state.showConfirmPopup}
        onHide={() =>
          setState({
            showConfirmPopup: false,
          })
        }
        isConfirmation={handleConfirmationClick}
      />
      <ModifyDefendantPopup
        show={state.showDefendantInfoModel}
        onHide={hideDefendantInfoModel}
      />
      <SignPopup
        show={state.showSignPopup}
        onHide={() => setState({ showSignPopup: false })}
        formType={LF_ARREST_WARRANT}
        hideFooter
      />
      <InfoPopup
        children={EC_MSG_ALREADY_SENT_TO_JUDGE}
        onHide={onHideInfoPopup}
        show={infoPopupShow}
        variant="warning"
      />
    </>
  );
};
export default ArrestWarrant;
