import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../common/Header";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import { useAppDispatch } from "../../hooks/hooks";
import * as yup from "yup";
import { merge } from "../../utils/common";
import { isValidPassword } from "../common/CommonUtil";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import {
  resetUserProfileList,
  userPasswordReset,
  useUserProfileReducer,
  updateElementValueUserProfileReducer,
} from "../../redux/slice/userProfileListSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import AlertMessage from "../common/AlertMessage";
import InfoPopup from "../common/InfoPopup";

type ChangePasswordProps = {};

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
  let ChangePasswordSchema = yup.object().shape({
    currentPassword: yup.string().required("Current Password is required."),
    newPassword: yup
      .string()
      .required(" New Password is required.")
      .test("check-password", function (password) {
        if (password !== "" && !isValidPassword(password)) {
          return this.createError({
            message:
              "New Password must contain at least 8 characters including at least one special character and one numeric value",
          });
        } else {
          return true;
        }
      }),
    confirmPassword: yup.string().required("Confirm password is required."),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Password and confirm password does not match."
      ),
  });

  const passwordReducer = useUserProfileReducer();
  const [errorKeys, setErrorKeys] = useState([""]);
  const errorMessageAlert = useAlert();
  const { username } = useLoginReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [showInfoPopup, setShowInfoPopup] = React.useState({
    show: false,
    info: "",
  });

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const afterConfirmPopup = () => {
    setShowInfoPopup({ show: false, info: "" });
    navigate("/");
  };

  useEffect(() => {
    return () => {
      dispatch(resetUserProfileList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (passwordReducer.resetErrorMessage) {
      setAlertMessage("");
      handleError([], [passwordReducer.resetErrorMessage]);
      dispatch(
        updateElementValueUserProfileReducer({
          elementName: "resetErrorMessage",
          value: "",
        })
      );
    }
    if (passwordReducer.resetSuccessMessage) {
      errorMessageAlert.clearError();
      setAlertMessage(passwordReducer.resetSuccessMessage);
      dispatch(
        updateElementValueUserProfileReducer({
          elementName: "resetSuccessMessage",
          value: "",
        })
      );
    }
  }, [
    passwordReducer.resetErrorMessage,
    passwordReducer.resetSuccessMessage,
    dispatch,
    handleError,
  ]);

  useEffect(() => {
    return () => {
      if (
        passwordReducer.resetSuccessMessage === "Successfully changed password."
      ) {
        let alertMessage = "Successfully changed password.";
        setShowInfoPopup({ show: true, info: alertMessage });
      }
    };
  }, [passwordReducer.resetSuccessMessage]);

  const handleconfirmPassword = async (event: any) => {
    if (
      (passwordReducer.newPassword !== "" ||
        passwordReducer.confirmPassword !== "") &&
      !isValidPassword(passwordReducer.newPassword)
    ) {
      handleError(
        [event.target.name],
        [
          "Password must contain at least 8 characters including at least one special character and one numeric value",
        ]
      );
      return;
    } else {
      handleError([], []);
    }
    if (
      passwordReducer.newPassword === "" ||
      passwordReducer.confirmPassword === ""
    ) {
      return true;
    } else if (
      passwordReducer.newPassword !== passwordReducer.confirmPassword
    ) {
      handleError([event.target.name], ["Passwords do not match"]);
      setAlertMessage("");
    } else {
      handleError([], []);
    }
  };

  const handleInputChange = (event: any) => {
    dispatch(
      updateElementValueUserProfileReducer({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  const handleCurrentPasswordChange = (name: string) => (event: any) => {
    event.target.name = name;
    handleInputChange(event);
  };

  const handleSubmit = async () => {
    let schema = [];
    schema.push(ChangePasswordSchema);
    try {
      schema = merge(...schema);
      const password = await schema.validate(passwordReducer, {
        abortEarly: false,
      });

      let newPasswordObj = {
        username: username,
        password: passwordReducer.newPassword,
        oldPassword: passwordReducer.currentPassword,
      };

      dispatch(userPasswordReset(newPasswordObj));
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  let itemLinkList = [{ itemLink: "/", itemName: "DashBoard" }];
  const data = [
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "User Name",
        type: "text",
        name: "username",
        value: username,
        onChange: handleInputChange,
        placeholder: "User Name",
        disabled: true,
        isError: errorKeys.includes("username"),
        isRequired: true,
        autoComplete: "username",
      },
      colSize: 6,
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Current Password",
        type: "password",
        name: Date.now().toString(),
        onChange: handleCurrentPasswordChange("currentPassword"),
        placeholder: "Current Password",
        isError: errorKeys.includes("currentPassword"),
        isRequired: true,
        autoComplete: "new-password",
      },
      colSize: 6,
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "New Password",
        type: "password",
        name: "newPassword",
        onBlur: handleconfirmPassword,
        onChange: handleInputChange,
        placeholder: "New Password",
        isCorrect: isValidPassword(passwordReducer.newPassword),
        isError: errorKeys.includes("newPassword"),
        isRequired: true,
        autoComplete: "new-password",
      },
      colSize: 6,
    },
    {
      type: "textInputFloting",
      props: {
        floatingLabel: "Confirm Password",
        type: "password",
        name: "confirmPassword",
        onChange: handleInputChange,
        onBlur: handleconfirmPassword,
        placeholder: "Confirm Password",
        isCorrect:
          passwordReducer.confirmPassword !== "" &&
          passwordReducer.newPassword !== "" &&
          passwordReducer.confirmPassword === passwordReducer.newPassword,
        isError: errorKeys.includes("confirmPassword"),
        isRequired: true,
        autoComplete: "new-password",
      },
      colSize: 6,
    },
    {
      type: "button",
      props: {
        children: "Save",
        onClick: handleSubmit,
        className: "mx-2",
      },
    },
    {
      type: "button",
      props: {
        children: "Cancel",
        className: "btn-danger",
        onClick: () => {
          navigate(-1);
        },
      },
    },
  ];

  return (
    <>
      <Header headerName="Change Password" />
      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="User" />

        <AlertMessage
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data}
          header="Change Password"
          subHeader={"Change Password"}
        />
        <InfoPopup
          children={showInfoPopup.info}
          onHide={afterConfirmPopup}
          show={showInfoPopup.show}
        />
      </div>
    </>
  );
};

export default ChangePassword;
