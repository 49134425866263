import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface TableActionComponentProps {
  tootltip: string;
  children?: React.ReactNode;
  actionFunction?: any;
  text?: string;
  backgroundStyle?: string;
  isDisable?: boolean;
  color?:
  | "text-primary"
  | "text-danger"
  | "text-success"
  | "text-dark"
  | "text-info"
  | "text-light";
  bgcolor?:
  | "bg-primary"
  | "bg-danger"
  | "bg-success"
  | "bg-dark"
  | "bg-info"
  | "bg-transparent";
}

export const TableActionComponent: React.FC<TableActionComponentProps> = (
  props
) => {
  const { tootltip, children, actionFunction, color, bgcolor, text, backgroundStyle, isDisable } = props;

  return (
    <>         
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{tootltip}</Tooltip>}>
        <div className={`${isDisable ? "cutom-table-button-disabled" : backgroundStyle}`} onClick={isDisable ? "": actionFunction}>
          <span className={`d-inline-block ${color || "text-primary"} ${bgcolor || "bg-transparent"}`}>
            <div className={`${isDisable ? "cutom-table-text-disabled" : ""}`} >{children}</div>
            <div className="action-button-text">{text}</div>
        </span>
      </div>
    </OverlayTrigger>
    </>
  );
};
