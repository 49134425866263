import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { updateElementObject } from "../../utils/updateElementObject";
import { resetSignature, updateElementValueInSignPanelReducer } from "./signPanelSlice";
import { updateCaseDetailObject, updateElementValueCaseDetailReducer } from "./caseDetailSlice";
import {showHideLoadingGif, updatePdfGenerationError, updateDocGenerateError} from "./commonSlice";
import { updateElementValueInSearchWarrantListReducer } from "./searchWarrantListSlice";
import { getSWConcurrentObj } from "./commonFormSlice";
import { getJudgeSavedSignature } from "./userProfileSlice";

interface amendInventoryObj {
  searchWarrantId: number;
  formStatus: string;

  txtAgency: string;
  txtCaseNumber: string;
  txtPersonPremises: string;
  txtSeized1: string;
  txtSeized2: string;
  txtPersonPremiseslbl: string;
  txtPresentingJudge: string;
  txtPresentingDate: string;
  lblDefaultVerbiageInventoryVisible: string;
  txtExecutionDate: string;
  txtExecutionTime: string;
  txtComplainantName: string;
  txtJudgeName: string;
  txtJudgeIssuingDate: string;
  txtJudgeIssuingTime: string;
  hiddenJudgeSignature: string;
  hiddenComplaintSignature: string;
  hiddenUserSignature: string,
  approvalSAId: number,
  signingJudgeUserId: number,
  creatorId : number,
  creatorUserType:string,
  isOnBehalf : boolean,
  countyID: number,
  countyName: string,
  judicialNumber: string,
  city: string,
  state: string,
  zip: string,
  circuitClerkName: string,
  validationId: string,
  isCaseImpounded: boolean,
  unImpoundCase: boolean,
  remark: string,
  lblHeadingStateSW: string,
  lblHeadingCountySW: string,
  lblHeadingInTheCircuitSW: string,
  updateImpounded: boolean,
  // uploadedFileName: string,
  // hiddenUploadedPdf: string,
  ammendInventoryStatement: string,
}

interface amendInventoryState {
  status: LoadingType;
  amendInventoryTypeError?: string;
  amendInventoryObject: amendInventoryObj;
}

const initialState = {
  status: "idle",
  amendInventoryObject: {
    txtAgency: "",
    txtCaseNumber: "",
    txtPersonPremises: "",
    txtSeized1: "",
    txtSeized2: "",
    txtPersonPremiseslbl: "",
    txtPresentingJudge: "",
    txtPresentingDate: "",
    lblDefaultVerbiageInventoryVisible: "",
    txtExecutionDate: "",
    txtExecutionTime: "",
    txtComplainantName: "",
    txtJudgeName: "",
    txtJudgeIssuingDate: "",
    txtJudgeIssuingTime: "",
    hiddenJudgeSignature: "",
    hiddenComplaintSignature: "",
    hiddenUserSignature: "",
    approvalSAId: 0,
    signingJudgeUserId: 0,
    creatorId : 0,
    creatorUserType:"",
    isOnBehalf : false,
    countyID: 0,
    countyName: "",
    judicialNumber: "",
    city: "",
    state: "",
    zip: "",
    circuitClerkName: "",
    validationId: "",
    isCaseImpounded: false,
    unImpoundCase: false,
    remark: "",
    lblHeadingStateSW: "",
    lblHeadingCountySW: "",
    lblHeadingInTheCircuitSW: "",
    updateImpounded: false,
    // uploadedFileName: "",
    // hiddenUploadedPdf: "",
    ammendInventoryStatement: "",
  },
} as amendInventoryState;

export const createAmendInventory = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    amendFormType: string;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "amendInventory/createAmendInventory",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, amendFormType, navigate } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(resetAmendInventoryData());
    dispatch(resetSignature())

    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url:
          "searchwarrantservices/createAmendInventory/" +
          searchWarrantId +
          "/" +
          amendFormType,
        token,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });
      if (response.ok) {
        const result = await response.json();
        dispatch(showHideLoadingGif(false));
        const res = await dispatch(getJudgeSavedSignature({
          userprofileId: getState().login.userProfileId
        }));

        if (res.payload.message) {
          dispatch(
            updateElementValueInSignPanelReducer({
              elementName: "sign",
              value: res.payload.message,
            })
          );
        }
        
        dispatch(
          updateElementValueCaseDetailReducer({
            elementName: "searchWarrantList",
            value: result.searchWarrantList,
          }));
        navigate("/AmendInventory");
        return result;
      }
      else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while creating Amend Inventory");
      }
     
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while creating Amend Inventory"
      );
    }
  }
);

export const updateAmendInventory = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    amendInventoryObject: any;
    navigate: any;
    callfrom: string;
  },
  { state: RootState; rejectValue: string }
>(
  "amendInventory/updateAmendInventory",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, amendInventoryObject, navigate, callfrom } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "searchwarrantservices/updateAmendInventory/" + searchWarrantId,
        type: "PUT",
        body: amendInventoryObject,
        token,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result:any) => {
          if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
            dispatch(
              updateDocGenerateError({
                error: true,
                errorMsg: result.errorInfo.validationErrors,
              })
            );
            return;
          } else {
            let message:any = "";

            if (callfrom === "onSave"){
              message = "Form data saved as complete successfully."
            } else if (callfrom === "onSaveIncomplete"){
              message = "Form data saved as Incomplete successfully."
            }

            result.responseMessage = message;
            
            let caseDetail = result;
            dispatch(updateCaseDetailObject(caseDetail));
          
            navigate("/CaseDetails");
          }
        });
        return amendInventoryObject;
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while updating Amend Inventory");
      }
    
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while updating Amend Inventory"
      );
    }
  }
);

export const returnAmendInventory = createAsyncThunk<
  any,
  { searchWarrantId: number; 
    proxy?: object;
    canAccessApplicationValue : boolean;
    previousUrl : string;  
    navigate: any},
  { state: RootState; rejectValue: string }
>("amendInventory/returnAmendInventory", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, proxy,canAccessApplicationValue, previousUrl, navigate } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);

  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/returnAmendInventory/" + searchWarrantId,
      type: "PUT",
      token,
      body: proxy,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
      dispatch: dispatch
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result: any) => {

      let message =
      "Inventory application for " + getState().caseDetail.caseNumber + " is sent back to officer.";

      dispatch(showHideLoadingGif(false));
      dispatch(
        updateElementValueInSearchWarrantListReducer({
          elementName: "responseMessage",
          value: message,
        })
      );
    });
    const newUrl = previousUrl.replace(/^.*\/\/.*?\//, '');
    if (newUrl == ""){
      if (canAccessApplicationValue){
        navigate("/WarrantsSummonsList");
      }else{
        navigate("/WarrantList");
      }
    } else{
      navigate("/"+newUrl);
    }
    return response.json();
    } else{
      
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while returning Amend Inventory");
    }
  } catch (error: any) {
    return rejectWithValue(error.message || "Error while returning Amend Inventory");
  }
}
);

export const signAmendInventory = createAsyncThunk<
  any,
  { searchWarrantId: number;
    amendInventoryObject: object; 
    navigate: any;
    navigateTo : string
  },
  { state: RootState; rejectValue: string }
>("amendInventory/signAmendInventory", async (data, { getState, rejectWithValue, dispatch }) => {
  const { searchWarrantId, amendInventoryObject, navigate, navigateTo } = data;
  let { token } = getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/signAmendInventory/" + searchWarrantId,
      type: "PUT",
      token,
      body: amendInventoryObject,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
      dispatch: dispatch
    });

    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result) => {
        if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: result.errorInfo.validationErrors,
            })
          );
          return;
        } else {
          if (result.errorInfo && result.errorInfo.error) {
            dispatch(
              updateElementValueCaseDetailReducer({
                elementName: "responseErrorMessage",
                value: result.errorInfo.errorMessage,
              })           
            );
            dispatch(updatePdfGenerationError({
              error: result.errorInfo.error,
              errorMsg: result.errorInfo.errorMessage,
            }));
            navigate(navigateTo);
          } else {
            let message = "Inventory on " + getState().caseDetail.caseNumber + " is signed.";

            result.responseMessage = message;

            let caseDetail = result;
            dispatch(updateCaseDetailObject(caseDetail));
          }
          navigate(navigateTo);
        }
      });
      return amendInventoryObject;
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while approving Amend Inventory");
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Error while approving Amend Inventory");
  }
}
);


const amendInventorySlice = createSlice({
  name: "amendInventory",
  initialState,
  reducers: {
    updateElementValueAmendInventoryReducer: updateElementValue,
    updateElementObjectValue: (state: amendInventoryState, action) => {
      updateElementObject(state, action, state.amendInventoryObject);
    },
    updateAmendInventoryObject: (state: amendInventoryState, action) => {
      state.amendInventoryObject = action.payload;
    },
    resetAmendInventoryData: (state: amendInventoryState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      //create
      .addCase(createAmendInventory.pending, (state) => {
        state.status = "loading";
        state.amendInventoryTypeError = undefined;
      })
      .addCase(createAmendInventory.fulfilled, (state, action) => {
        state.status = "success";
        state.amendInventoryObject = action.payload;
      })
      .addCase(createAmendInventory.rejected, (state) => {
        state.status = "error";
        state.amendInventoryTypeError = undefined;
      })

      //update
      .addCase(updateAmendInventory.pending, (state) => {
        state.status = "loading";
        state.amendInventoryTypeError = undefined;
      })
      .addCase(updateAmendInventory.fulfilled, (state, action) => {
        state.status = "success";
        state.amendInventoryObject = action.payload;
      })
      .addCase(updateAmendInventory.rejected, (state) => {
        state.status = "error";
        state.amendInventoryTypeError = undefined;
      })
      
      //return
      .addCase(returnAmendInventory.pending, (state) => {
        state.status = "loading";
        state.amendInventoryTypeError = undefined;
      })
      .addCase(returnAmendInventory.fulfilled, (state, action) => {
        state.status = "success";
        state.amendInventoryObject = action.payload;
      })
      .addCase(returnAmendInventory.rejected, (state) => {
        state.status = "error";
        state.amendInventoryTypeError = undefined;
      })

       //sign
       .addCase(signAmendInventory.pending, (state) => {
        state.status = "loading";
        state.amendInventoryTypeError = undefined;
      })
      .addCase(signAmendInventory.fulfilled, (state, action) => {
        state.status = "success";
        state.amendInventoryObject = action.payload;
      })
      .addCase(signAmendInventory.rejected, (state) => {
        state.status = "error";
        state.amendInventoryTypeError = undefined;
      });
  },
});

export const {
  updateElementValueAmendInventoryReducer,
  resetAmendInventoryData,
  updateElementObjectValue,
  updateAmendInventoryObject,
} = amendInventorySlice.actions;

export const useAmendInventoryReducer = () =>
  useAppSelector((state) => state.amendInventory);

export default amendInventorySlice.reducer;
