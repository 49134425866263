import Modal from "react-bootstrap/esm/Modal"
import FormComponent from "./FormComponent";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { findBrackets, removeHtmlTags, setSelectionRange, singleStringReplace } from "../../utils/common";
import { Button, Col, Row } from "react-bootstrap";

interface FormFillerProps{
    show: boolean;
    fieldTitles: any;
    names: any;
    values: any;
    onHide: () => void;
    onChangeHandler: (name: string, data: string) => void
}

const FormFiller: React.FC<FormFillerProps> = (props) =>{
    const { show, fieldTitles, names, values, onHide, onChangeHandler} = props;

    const textNum = useRef(0);
    const textRef = useRef<HTMLTextAreaElement>(null);
    const inputRef = useRef();
    const [currText, setCurrText] = useState("");
    const matches = useMemo(() => findBrackets(currText)[0], [currText]);
    const initialLoad = useRef(true);
    const mountLoad = useRef(false);

    const [fieldName, setFieldName] = useState("");
    const [fieldValue, setFieldValue] = useState("");
    const [isBrackets, setIsBrackets] = useState(true);
    const [userInputDictionary, setUserInputDictionary] = useState<{id:string, value:string}[]>([{id:"", value:""}]);

    useEffect(() => {
      if (show && values){
        for (let i=0; i< values.length; i++){
          if (findBrackets(values[i]).length>0){
            setCurrText(values[i]);
            textNum.current = i;
            setIsBrackets(true);
            break;
          } else{
            setIsBrackets(false);
          }
        }

      }
    },[show])

    useEffect(() => {
      if ((textNum.current < values.length) && !matches && initialLoad.current && mountLoad.current){
        textNum.current += 1;
        setCurrText(values[textNum.current]);
      }
      else{
        initialLoad.current = false;
      }
      if (currText){
        onChangeHandler(names[textNum.current],currText);
      }
      mountLoad.current = true;
    },[currText, initialLoad.current])
    
    useEffect(() => {
      if (matches) {
        const { fieldName, start, end} = matches;
        setFieldName(fieldName);
        setSelectionRange(textRef.current, start,end);
        const value = userInputDictionary.find((el: any) => el.id === fieldName)?.value
        if(value) return setFieldValue(value)
      }else{
        setFieldName("");
      }
      setFieldValue("");
    }, [matches]);

    // const textAreaView = (string:string) => {
    //   return string && removeHtmlTags(singleStringReplace(string,"</p><p>","\n"))
    // }

    const onNextBtn = () => {
      if (matches) {
        const { fieldVal,fieldName } = matches;
        setCurrText(currText.replace(fieldVal, fieldValue?.replace(/^<p>/, '').replace(/<\/p>$/, '').replace(/<p>&nbsp;<\/p>/g, '\n')));
        userInputDictionary.push({id:fieldName, value:fieldValue})
      }
    };

    const onNextFiledBtn = () => {
      if ((textNum.current < values.length) && !matches){
              textNum.current += 1;
              setCurrText(values[textNum.current]);
          }
    }

    const onInputUpdate = (editor:any) => {
      setFieldValue(editor.getData());
    };

    const cancelPopup = () =>{
      setFieldName("");
      setFieldValue("");
      setUserInputDictionary([{id:"", value:""}]);
      setCurrText("");
      setIsBrackets(true);
      onHide();
      textNum.current =0;
      initialLoad.current = true;
      mountLoad.current = false;
    }

    let data = [
      {
        type: "htmlTag",
        props: {
          value: <label style={{fontSize:"13px", color: "blue"}}>{"Portion of the template that is getting filled out below (Read only)"}</label>
        },
        colSize: 12,
        colHide: (!(textNum.current !== values.length)  || !isBrackets)
      },
      {
        type: "editor",
        props: {
          data: currText? currText: "",
          ref: textRef,
          disabled: true,
        },
        colSize: 12,
        colHide: (!(textNum.current !== values.length)  || !isBrackets)
      },
        {
            type: "htmlTag",
            props: {
              value: 
              <h6><b>      
              <div
                dangerouslySetInnerHTML={{
                  __html: fieldName,
                }}
              >
              </div>
              </b></h6>
            },
            colSize: 12,
            colHide: !(textNum.current !== values.length) || !isBrackets
        },
        {
          type: "htmlTag",
          props: {
            value: <label style={{color:"blue", fontSize:"13px", marginTop: "-5px"}}>{"Enter the text that is appropriate for the tag in the space provided below"}</label>
          },
          colSize: 12,
          colHide: !(textNum.current !== values.length) || !isBrackets
        },
          {
            type: "editor",
            props: {
              data: fieldValue? fieldValue: "",
              onChange: (event: any, editor: any) => {
                onInputUpdate(editor);
              },
              ref: inputRef,
              disabled: !fieldName,
            },
            colSize: 12,
            colHide: !(textNum.current !== values.length)  || !isBrackets
          },
          {
            type: "htmlTag",
            props: {
              value: <h6>All the tags have been replaced.</h6>
            },
            colSize: 12,
            colHide: (textNum.current !== values.length) && isBrackets
        },
        {
          type: "customButtons",
          props:{},
          colSize: 12,
        }
    ]
    return(
      <>
        <Modal
          show={show}
          dialogClassName="modal-55w"
          onHide={cancelPopup}
          centered
          backdrop="static"
        >{textNum.current !== values.length ?
          <Modal.Header closeButton>
            <h5><b>TextArea | {Array.isArray(fieldTitles) && fieldTitles[textNum.current]}</b></h5>
          </Modal.Header>
          :
          <Modal.Header>
            <h5><b>Form Filler Wizard</b></h5>
          </Modal.Header>
          }

          <Modal.Body>
            <FormComponent
              isSpace={"mt-1"}
              totalColumns={12}
              colSize={12}
              componentData={data}
              formCardStyle={"none"}
              components={[
                {
                  type: "customButtons",
                  node: (props: any) => (
                    <Row className="mt-4">
                      <Col className="text-start mt-1" xs={6}>
                        {((textNum.current !== values.length) && isBrackets) ?
                          <Row>
                            <label style={{ color: "blue", fontSize: "13px" }}>
                              {"Next : Will take user to the next tag within the template"}
                            </label>
                          </Row>
                          : ""}
                        {((textNum.current !== values.length) && isBrackets) ?
                          <Row>
                            <label style={{ color: "blue", fontSize: "13px" }}>
                              {"Next Field : Will take the user to the next template field"}
                            </label>
                          </Row>
                          : ""}
                      </Col>
                      <Col className="text-end mt-1" xs={6}>
                        {((textNum.current !== values.length) && isBrackets) ?
                          <Button
                            className="btn-primary ms-2"
                            onClick={onNextBtn}
                            disabled={(!matches || !fieldValue)}>Next</Button>
                          : ""}
                        {((textNum.current !== values.length) && isBrackets) ?
                          <Button className="btn-primary ms-2"
                            onClick={onNextFiledBtn}
                            disabled={!(!matches && !fieldValue)}
                          >Next Field</Button> : ""}
                        {((textNum.current !== values.length) && isBrackets) ?
                          <Button className="btn-primary ms-2"
                            variant="danger"
                            onClick={cancelPopup}>Cancel</Button> : ""}
                        {(!(textNum.current !== values.length) || !isBrackets) ?
                          <Button className="btn-primary ms-2"
                            variant="danger"
                            onClick={cancelPopup}>Close</Button> : ""}
                      </Col>
                    </Row>
                  ),
                },
              ]}

            />
          </Modal.Body>
        </Modal>
      </>
    );
};

export default FormFiller;