import React, { useCallback, useEffect, useState } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import Header from "../common/Header";
import { useCountyListReducer } from "../../redux/slice/countyListSlice";
import {
  updateElementValuetemplateReducer,
  useTemplateReducer,
  defaultValue,
  createUpdateTemplate,
  resetTemplateData,
  getTemplateDataById,
} from "../../redux/slice/templateSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PERSON_OF_HEADER,
  ROLE_COUNTY_ADMIN,
  SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT,
  FIELDS_COMPLAINT_ORDER,
} from "../../constants/common";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { useLoginReducer } from "../../redux/slice/loginSlice";

let defaultCountySchema = yup.object().shape({
  templateCountyId: yup.number().min(1, "County is required."),
  templateName: yup.string().required("Template Name is required."),
  personOf: yup
    .string()
    .required("Person/Premises/Item information is required."),
  offenseInfo: yup.string().required("Offence is required."),
  seizedInfo: yup.string().required("Seized is required."),
  policeOfficerInfo: yup
    .string()
    .required("Probable cause / Officer background information is required."),
  pOSPrsnPremisesText: yup
    .string()
    .required("Person or premises value for Seized information is required."),
  pOSPrsnPremisesPrint: yup
    .string()
    .required("Inventory person and premises is required."),
  invntryPrsnPrmsTxt: yup
    .string()
    .required(
      "Proof of service person and premises print language is required."
    ),
  invntryPrsnPrmsPrint: yup
    .string()
    .required("Inventory person and premises print language is required."),
  personOfHeaderPrefix: yup
    .string()
    .required("Field 1 Heading Prefix is required."),
  personOfHeader: yup
    .string()
    .required("Field 1 Heading is required."),

  personOfHeaderSuffix: yup
    .string()
    .required("Field 1 Heading Suffix is required."),
  offenseInfoHeader: yup
    .string()
    .required("Field 2 Heading is required."),
  offenseInfoHeaderSuffix: yup
    .string()
    .required("Field 2 Heading Suffix is required."),
  seizedInfoHeader: yup
    .string()
    .required("Field 3 Heading is required."),
  policeOfficerInfoHeader: yup
    .string()
    .required("Field 4 Heading is required."),
  policeOfficerInfoHeaderSuffix: yup
    .string()
    .required("Field 4 Heading Suffix is required."),
  impoundLanguage: yup
    .string()
    .required("Impound Language is required."),
  complaintHeaderPrefix: yup
    .string()
    .required("Complaint Header Prefix is required."),
  warrantField1Prefix: yup
    .string()
    .required("Warrant Field 01 Prefix is required."),
  warrantOrderCommand: yup
    .string()
    .required("Warrant Order Command is required."),
    impoundComplaintLanguage: yup
    .string()
    .required("Complaint Impound Language is required."),
});

interface ComplaintOrSearchTempProps {}

const ComplaintOrSearchTemp: React.FC<ComplaintOrSearchTempProps> = (props) => {
  const { countyList } = useCountyListReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const templateReducer = useTemplateReducer();
  const [errorKeys, setErrorKeys] = useState([""]);
  const errorMessageAlert = useAlert();
  const location = useLocation();
  const { userType } = useLoginReducer();

  const handleSelectChange = (name: string) => (e: any) => {
    dispatch(
      updateElementValuetemplateReducer({
        elementName: "personOfHeader",
        value: e.Judicial + " " + PERSON_OF_HEADER,
      })
    );

    dispatch(
      updateElementValuetemplateReducer({
        elementName: name,
        value: e.value,
      })
    );
  };
  const handleCkEditorValChng = (name: any, editor: any) => {
    dispatch(
      updateElementValuetemplateReducer({
        elementName: name,
        value: editor.getData(),
      })
    );
  };
  const handleInputChange = (e: any) => {
    dispatch(
      updateElementValuetemplateReducer({
        elementName: e.target.name,
        value: e.target.value,
      })
    );
  };
  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );
  useEffect(() => {
    return () => {
      dispatch(resetTemplateData());
    };
  }, []);
  useEffect(() => {
    dispatch(resetTemplateData());
    dispatch(
      updateElementValuetemplateReducer({
        elementName: "templateType",
        value: SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT,
      })
    );
    dispatch(defaultValue());
  }, []);
  useEffect(() => {
    const templateId = location.pathname.split("/")[2];

    if (templateId) {
      dispatch(resetTemplateData());
      dispatch(getTemplateDataById(templateId));
    }
  }, [dispatch, location, navigate]);

  useEffect(() => {
    if (templateReducer.templateError) {
      handleError([], [templateReducer.templateError]);
      dispatch(
        updateElementValuetemplateReducer({
          elementName: "templateError",
          value: "",
        })
      );
    }
  }, [templateReducer.templateError, dispatch, handleError]);

  const handleSubmit = async () => {
    try {
      await defaultCountySchema.validate(templateReducer, {
        abortEarly: false,
      });

      let templateObj = {
        templateId: templateReducer.templateId,
        countyIdParam: templateReducer.templateCountyId,
        templateName: templateReducer.templateName,
        warrantFormName: SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT,
        templateField: FIELDS_COMPLAINT_ORDER,
        templateValue: [
          templateReducer.personOf,
          templateReducer.personOfHeader,
          templateReducer.offenseInfo,
          templateReducer.offenseInfoHeader,
          templateReducer.seizedInfo,
          templateReducer.seizedInfoHeader,
          templateReducer.policeOfficerInfo,
          templateReducer.policeOfficerInfoHeader,
          templateReducer.pOSPrsnPremisesText,
          templateReducer.invntryPrsnPrmsTxt,
          templateReducer.pOSPrsnPremisesPrint,
          templateReducer.invntryPrsnPrmsPrint,
          templateReducer.personOfHeaderPrefix,
          templateReducer.personOfHeaderSuffix,
          templateReducer.offenseInfoHeaderSuffix,
          templateReducer.policeOfficerInfoHeaderSuffix,
          templateReducer.impoundLanguage,
          templateReducer.complaintHeaderPrefix,
          templateReducer.warrantField1Prefix,
          templateReducer.warrantField2Prefix,
          templateReducer.warrantHeader3Prefix,
          templateReducer.warrantOrderCommand,
          templateReducer.complaintTitle,
          templateReducer.swTitle,
          templateReducer.impoundComplaintLanguage,
        ],
      };

      console.log(templateObj)

      let fetchType;
      if (location.pathname.split("/")[1].includes("edit")) {
        fetchType = "PUT";
      } else {
        fetchType = "POST";
      }
      dispatch(createUpdateTemplate({ templateObj, fetchType, navigate }));
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/TemplateList", itemName: "Template List" },
  ];

  const data = [
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- SELECT --", value: 0, Judicial: "" },
          ...countyList.map((county: any) => {
            return {
              label: county.countyName,
              value: county.countyID,
              Judicial: county.judicialNumberText,
            };
          }),
        ],
        value: countyList
          .map((county: any) => {
            return {
              label: county.countyName,
              value: county.countyID,
              Judicial: county.judicialNumberText,
            };
          })
          .find(
            (item) =>
              item.value ===
              (location.pathname.split("/")[2]
                ? templateReducer.templateCountyId
                : 0)
          ),
        onChange: handleSelectChange("templateCountyId"),
        placeholder: "Select County",
        name: "templateCountyId",
        defaultValue: { label: "-- SELECT --", value: 0, Judicial: "" },
        isError: errorKeys.includes("templateCountyId"),
        isDisabled:
          location.pathname.split("/")[1].includes("edit") ||
          (location.pathname.split("/")[1].includes("copy") &&
            userType === ROLE_COUNTY_ADMIN),
      },
      colSize: 4,
      label: "County",
      inLine: true,
    },

    {
      type: "textInput",
      props: {
        type: "text",
        placeholder: "Enter Template Name",
        onChange: handleInputChange,
        name: "templateName",
        value: templateReducer.templateName,
        isError: errorKeys.includes("templateName"),
        disabled: location.pathname.split("/")[1].includes("edit") || false,
      },
      colSize: 4,
      label: "Template Name",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        placeholder: "Enter Template type",
        onChange: handleInputChange,
        name: "templateType",
        disabled: true,
        value: templateReducer.templateType,
      },
      colSize: 4,
      label: "Template type",
      inLine: true,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        placeholder: "Enter Complaint Title",
        onChange: handleInputChange,
        name: "complaintTitle",
        value: templateReducer.complaintTitle,
      },
      colSize: 6,
      label: "Complaint Title",
    },
    {
      type: "textInput",
      props: {
        type: "text",
        placeholder: "Enter Search Warrant Title",
        onChange: handleInputChange,
        name: "swTitle",
        value: templateReducer.swTitle,
      },
      colSize: 6,
      label: "Search Warrant Title",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.personOfHeaderPrefix ? templateReducer.personOfHeaderPrefix : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("personOfHeaderPrefix", editor);
        },
        isError: errorKeys.includes("personOfHeaderPrefix"),
        disabled:
        location.pathname.split("/")[1].includes("copy") &&
        userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Field 1 Heading Prefix",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.personOfHeader ? templateReducer.personOfHeader : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("personOfHeader", editor);
        },
        isError: errorKeys.includes("personOfHeader"),
        disabled:
        location.pathname.split("/")[1].includes("copy") &&
        userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Field 1 Heading",
    },
    // {
    //   type: "textInput",
    //   props: {
    //     type: "text",
    //     placeholder: "Enter Field 1 Heading",
    //     name: "personOfHeader",
    //     onChange: handleInputChange,
    //     value: templateReducer.personOfHeader,
    //     isError: errorKeys.includes("personOfHeader"),
    //     disabled:
    //       location.pathname.split("/")[1].includes("copy") &&
    //       userType === ROLE_COUNTY_ADMIN,
    //   },
    //   colSize: 12,
    //   label: "Field 1 Heading",
    // },
    {
      type: "editor",
      props: {
        data: templateReducer.personOfHeaderSuffix ? templateReducer.personOfHeaderSuffix : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("personOfHeaderSuffix", editor);
        },
        isError: errorKeys.includes("personOfHeaderSuffix"),
        disabled:
          location.pathname.split("/")[1].includes("copy") &&
          userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Field 1 Heading Suffix",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.personOf ? templateReducer.personOf : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("personOf", editor);
        },
        isError: errorKeys.includes("personOf"),
      },
      colSize: 12,
      label: "Field 1 Description",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.offenseInfoHeader ? templateReducer.offenseInfoHeader : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("offenseInfoHeader", editor);
        },
        isError: errorKeys.includes("offenseInfoHeader"),
        disabled:
        location.pathname.split("/")[1].includes("copy") &&
        userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Field 2 Heading",
    },
    // {
    //   type: "textInput",
    //   props: {
    //     type: "text",
    //     placeholder: "Enter Field 2 Heading",
    //     name: "offenseInfoHeader",
    //     onChange: handleInputChange,
    //     value: templateReducer.offenseInfoHeader,
    //     isError: errorKeys.includes("offenseInfoHeader"),
    //     disabled:
    //       location.pathname.split("/")[1].includes("copy") &&
    //       userType === ROLE_COUNTY_ADMIN,
    //   },
    //   colSize: 12,
    //   label: "Field 2 Heading",
    // },
   {
    type: "editor",
    props: {
      data: templateReducer.offenseInfoHeaderSuffix ? templateReducer.offenseInfoHeaderSuffix : "",
      onChange: (event: any, editor: any) => {
        handleCkEditorValChng("offenseInfoHeaderSuffix", editor);
      },
      isError: errorKeys.includes("offenseInfoHeaderSuffix"),
      disabled:
      location.pathname.split("/")[1].includes("copy") &&
      userType === ROLE_COUNTY_ADMIN,
    },
    colSize: 12,
    label: "Field 2 Heading Suffix",
  },
    {
      type: "editor",
      props: {
        data: templateReducer.offenseInfo ? templateReducer.offenseInfo : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("offenseInfo", editor);
        },
        isError: errorKeys.includes("offenseInfo"),
      },
      colSize: 12,
      label: "Field 2 Description",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.seizedInfoHeader ? templateReducer.seizedInfoHeader : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("seizedInfoHeader", editor);
        },
        isError: errorKeys.includes("seizedInfoHeader"),
        disabled:
        location.pathname.split("/")[1].includes("copy") &&
        userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Field 3 Heading",
    },
    // {
    //   type: "textInput",
    //   props: {
    //     type: "text",
    //     placeholder: "Enter Field 3 Heading",
    //     name: "seizedInfoHeader",
    //     onChange: handleInputChange,
    //     value: templateReducer.seizedInfoHeader,
    //     isError: errorKeys.includes("seizedInfoHeader"),
    //     disabled:
    //       location.pathname.split("/")[1].includes("copy") &&
    //       userType === ROLE_COUNTY_ADMIN,
    //   },
    //   colSize: 12,
    //   label: "Field 3 Heading",
    // },
    {
      type: "editor",
      props: {
        data: templateReducer.seizedInfo ? templateReducer.seizedInfo : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("seizedInfo", editor);
        },
        isError: errorKeys.includes("seizedInfo"),
      },
      colSize: 12,
      label: "Field 3 Description",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.policeOfficerInfoHeader ? templateReducer.policeOfficerInfoHeader : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("policeOfficerInfoHeader", editor);
        },
        isError: errorKeys.includes("policeOfficerInfoHeader"),
        disabled:
        location.pathname.split("/")[1].includes("copy") &&
        userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Field 4 Heading",
    },
    // {
    //   type: "textInput",
    //   props: {
    //     type: "text",
    //     placeholder: "Enter Field 4 Heading",
    //     name: "policeOfficerInfoHeader",
    //     onChange: handleInputChange,
    //     value: templateReducer.policeOfficerInfoHeader,
    //     isError: errorKeys.includes("policeOfficerInfoHeader"),
    //     disabled:
    //       location.pathname.split("/")[1].includes("copy") &&
    //       userType === ROLE_COUNTY_ADMIN,
    //   },
    //   colSize: 12,
    //   label: "Field 4 Heading",
    // },
    {
      type: "editor",
      props: {
        data: templateReducer.policeOfficerInfoHeaderSuffix ? templateReducer.policeOfficerInfoHeaderSuffix : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("policeOfficerInfoHeaderSuffix", editor);
        },
        isError: errorKeys.includes("policeOfficerInfoHeaderSuffix"),
        disabled:
          location.pathname.split("/")[1].includes("copy") &&
          userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Field 4 Heading Suffix",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.policeOfficerInfo
          ? templateReducer.policeOfficerInfo
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("policeOfficerInfo", editor);
        },
        isError: errorKeys.includes("policeOfficerInfo"),
      },
      colSize: 12,
      label: "Field 4 Description",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.impoundLanguage
          ? templateReducer.impoundLanguage
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("impoundLanguage", editor);
        },
        isError: errorKeys.includes("impoundLanguage"),
      },
      colSize: 12,
      label: "Impound Order Language",
    },
    {
      type: "textInput",
      props: {
        type: "text",
        placeholder: "Enter Proof of service Person and Premises",
        name: "pOSPrsnPremisesText",
        onChange: handleInputChange,
        value: templateReducer.pOSPrsnPremisesText?templateReducer.pOSPrsnPremisesText:"Person and Premises",
        isError: errorKeys.includes("pOSPrsnPremisesText"),
      },
      colSize: 6,
      label: "Proof of service Person and Premises",
    },
    {
      type: "textInput",
      props: {
        type: "text",
        placeholder:
          "Enter Proof of service Person and Premises Print Language",
        name: "pOSPrsnPremisesPrint",
        onChange: handleInputChange,
        value: templateReducer.pOSPrsnPremisesPrint?templateReducer.pOSPrsnPremisesPrint:"I executed this Warrant by serving the above named",
        isError: errorKeys.includes("pOSPrsnPremisesPrint"),
      },
      colSize: 6,
      label: "Proof of service Person and Premises Print Language",
    },
    {
      type: "textInput",
      props: {
        type: "text",
        placeholder: "Enter Inventory Person and Premises",
        name: "invntryPrsnPrmsTxt",
        onChange: handleInputChange,
        value: templateReducer.invntryPrsnPrmsTxt?templateReducer.invntryPrsnPrmsTxt:"the Person and Premises",
        isError: errorKeys.includes("invntryPrsnPrmsTxt"),
      },
      colSize: 6,
      label: "Inventory Person and Premises",
    },
    {
      type: "textInput",
      props: {
        type: "text",
        placeholder: "Enter Inventory Person and Premises Print Language",
        name: "invntryPrsnPrmsPrint",
        onChange: handleInputChange,
        value: templateReducer.invntryPrsnPrmsPrint?templateReducer.invntryPrsnPrmsPrint:"Inventory Person and Premises Print Language",
        isError: errorKeys.includes("invntryPrsnPrmsPrint"),
      },
      colSize: 6,
      label: "Inventory Person and Premises Print Language",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.complaintHeaderPrefix ? templateReducer.complaintHeaderPrefix : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("complaintHeaderPrefix", editor);
        },
        isError: errorKeys.includes("complaintHeaderPrefix"),
        disabled:
          location.pathname.split("/")[1].includes("copy") &&
          userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Complaint header Prefix",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.warrantField1Prefix ? templateReducer.warrantField1Prefix : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("warrantField1Prefix", editor);
        },
        isError: errorKeys.includes("warrantField1Prefix"),
        disabled:
          location.pathname.split("/")[1].includes("copy") &&
          userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Warrant Field 01 Prefix",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.warrantField2Prefix ? templateReducer.warrantField2Prefix : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("warrantField2Prefix", editor);
        },
        disabled:
          location.pathname.split("/")[1].includes("copy") &&
          userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Warrant Field 02 Prefix",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.warrantHeader3Prefix ? templateReducer.warrantHeader3Prefix : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("warrantHeader3Prefix", editor);
        },
        disabled:
          location.pathname.split("/")[1].includes("copy") &&
          userType === ROLE_COUNTY_ADMIN,
      },
      colSize: 12,
      label: "Warrant Field 03 Prefix",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.impoundComplaintLanguage
          ? templateReducer.impoundComplaintLanguage
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("impoundComplaintLanguage", editor);
        },
        isError: errorKeys.includes("impoundComplaintLanguage"),
      },
      colSize: 12,
      label: "Impound Complaint Language",
    },
    {
      type: "editor",
      props: {
        data: templateReducer.warrantOrderCommand
          ? templateReducer.warrantOrderCommand
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChng("warrantOrderCommand", editor);
        },
        isError: errorKeys.includes("warrantOrderCommand"),
      },
      colSize: 12,
      label: "Warrant Order Command",
    },
    {
      type: "button",
      props: {
        children: location.pathname.split("/")[1].includes("edit")
          ? "Update"
          : "Save",
        className: "btn-primary ms-2",
        onClick: handleSubmit,
      },
    },
    {
      type: "button",
      props: {
        children: "Cancel",
        className: "btn-danger ms-2",
        onClick: () => {
          navigate(-1);
        },
      },
    },
  ];

  return (
    <>
      <Header headerName="Complaint Order" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Complaint Order"
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data}
          subHeader="Complaint Order Form"
        />
      </div>
    </>
  );
};

export default ComplaintOrSearchTemp;
