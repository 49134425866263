import React, { useCallback, useEffect, useState, useRef } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import ButtonComponent from "../common/ButtonComponent";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import "../common/TwoFactorAuthentication.scss";
import TextInputComponent from "../common/TextInputComponent";
import {
  RiArrowLeftSFill,
  RiArrowRightSFill,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import {
  getUserDataByUsername,
  useUserChangeProfileReducer,
  updateElementValueUserChangeProfileReducer,
} from "../../redux/slice/userChangeProfileSlice";
import { updateElementValueInLoginReducer, useLoginReducer } from "../../redux/slice/loginSlice";
import { useAppDispatch } from "../../hooks/hooks";
import {
  twoFactorRegistration,
  twoFactorRegistrationviaText,
  twoFactorSaveUser,
  twoFactorDisableUser,
} from "../../redux/slice/userProfileSlice";
import {
  formatPhoneNumber,
  isEmailValidation,
  isValidPhone,
} from "../common/CommonUtil";
import AlertMessage from "../common/AlertMessage";
import {
  getLoggedInUserCounties,
  useCountyListReducer,
} from "../../redux/slice/countyListSlice";
import * as yup from "yup";
import ConfirmPopup from "../common/ConfirmPopup";

interface TwoFactorAuthenticationRegisterPopupProps {
  show: boolean;
  onHide: () => void;
}

interface LocalState {
  windowNumber: number;
  isSelectPhone: boolean;
  isSelectEmail: boolean;
}
const initialState: LocalState = {
  windowNumber: 1,
  isSelectPhone: false,
  isSelectEmail: false,
};

export const TwoFactorAuthenticationRegisterPopup: React.FC<
  TwoFactorAuthenticationRegisterPopupProps
> = (props) => {
  let defaultTwoFactorEmailSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required.")
      .test("check-email", function (value) {
        if (!isEmailValidation(userChangeProfileReducer.email)) {
          return this.createError({
            message: "Please insert a valid Email Address",
          });
        } else {
          return true;
        }
      }),
  });

  let defaultTwoFactorMobileSchemaForMobileNo = yup.object().shape({
    mobileNo: yup
      .string()
      .required("Mobile Number is required.")
      .test("validate-mobileNo", function (value) {
        if (
          userChangeProfileReducer.mobileNo.length > 0 &&
          !isValidPhone(userChangeProfileReducer.mobileNo)
        ) {
          return this.createError({
            message: "Please insert a valid Mobile Number",
          });
        } else {
          return true;
        }
      }),
  });

  let defaultTwoFactorMobileSchemaFor2fPhone = yup.object().shape({
    twofactorPhone: yup
      .string()
      .required("Mobile Number is required.")
      .test("validate-mobileNo", function (value) {
        if (
          userChangeProfileReducer.twofactorPhone.length > 0 &&
          !isValidPhone(userChangeProfileReducer.twofactorPhone)
        ) {
          return this.createError({
            message: "Please insert a valid Mobile Number",
          });
        } else {
          return true;
        }
      }),
  });

  const dispatch = useAppDispatch();
  const loginReducer = useLoginReducer();
  const setState = useCallback((e: Partial<LocalState>) => {
    _((s) => {
      return { ...s, ...e } as LocalState;
    });
  }, []);

  const { onHide } = props;
  const errorMessageAlert = useAlert();
  const [state, _] = React.useState(initialState);
  const userChangeProfileReducer = useUserChangeProfileReducer();
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [combinedValue, setCombinedValue] = useState("");
  const [userVerificationCode, setUserVerificationCode] = useState("");
  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const input3Ref = useRef<HTMLInputElement>(null);
  const input4Ref = useRef<HTMLInputElement>(null);
  const input5Ref = useRef<HTMLInputElement>(null);
  const input6Ref = useRef<HTMLInputElement>(null);
  const [errorKeys, setErrorKeys] = useState([""]);
  const [twoFactorType, setTwoFactorType] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const countyListReducer = useCountyListReducer();
  const [isDupageIncluded, setIsDupageIncluded] = useState(false);
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
    disbaled: false,
  });

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  useEffect(() => {
    dispatch(getLoggedInUserCounties());
    dispatch(
      getUserDataByUsername({
        username: loginReducer.username,
      })
    );
  }, []);

  useEffect(() => {
    if (
      countyListReducer.countyList instanceof Array &&
      countyListReducer.countyList.length > 0
    ) {
      setIsDupageIncluded(
        countyListReducer.countyList.some(
          (county) => county.countyName === "DUPAGE"
        )
      );
    }
  }, [countyListReducer.loggedInUserCountyFetchStatus === "success"]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const setCookie = (name: string, value: number, days: number) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    const expires = `expires=${expirationDate.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  const generateRandomCode = (): string => {
    const codeLength = 6;
    let code = "";

    for (let i = 0; i < codeLength; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      code += randomDigit.toString();
    }
    setUserVerificationCode(code);
    return code;
  };

  const handleChangeEmail = (event: any) => {
    const newEmail = event.target.value;
    if (
      userChangeProfileReducer.twoFactorType === "Email" ||
      userChangeProfileReducer.twoFactorType === "Both"
    ) {
      dispatch(
        updateElementValueUserChangeProfileReducer({
          elementName: "twofactorEmail",
          value: newEmail,
        })
      );
    } else {
      dispatch(
        updateElementValueUserChangeProfileReducer({
          elementName: "email",
          value: newEmail,
        })
      );
    }
  };

  const handleChangePhone = (event: any) => {
    const newPhone = event.target.value;
    if (
      userChangeProfileReducer.twoFactorType === "Phone" ||
      userChangeProfileReducer.twoFactorType === "Both"
    ) {
      dispatch(
        updateElementValueUserChangeProfileReducer({
          elementName: "twofactorPhone",
          value: newPhone,
        })
      );
    } else {
      dispatch(
        updateElementValueUserChangeProfileReducer({
          elementName: "mobileNo",
          value: newPhone,
        })
      );
    }
  };

  const MobileNumberFormat = (event: any) => {
    if (
      userChangeProfileReducer.twoFactorType === "Phone" ||
      userChangeProfileReducer.twoFactorType === "Both"
    ) {
      if (
        userChangeProfileReducer.twofactorPhone.length > 9 &&
        userChangeProfileReducer.twofactorPhone != null
      ) {
        var mobileNumber = formatPhoneNumber(event.target.value);
        dispatch(
          updateElementValueUserChangeProfileReducer({
            elementName: "twofactorPhone",
            value: mobileNumber,
          })
        );
      }
    } else {
      if (
        userChangeProfileReducer.mobileNo.length > 9 &&
        userChangeProfileReducer.mobileNo != null
      ) {
        var mobileNumber = formatPhoneNumber(event.target.value);
        dispatch(
          updateElementValueUserChangeProfileReducer({
            elementName: "mobileNo",
            value: mobileNumber,
          })
        );
      }
    }
  };

  const onChangeWindow = (winNumber: number) => {
    let maxWindow = 3;
    if (state.isSelectEmail && state.isSelectPhone) {
      maxWindow = 4;
    }
    if (winNumber <= maxWindow && winNumber >= 1) {
      setState({
        windowNumber: winNumber,
      });
    }
  };

  const changeSelectPhone = () => {
    setState({
      isSelectPhone: !state.isSelectPhone,
      isSelectEmail: state.isSelectPhone,
    });
  };

  const changeSelectEmail = () => {
    setState({
      isSelectEmail: !state.isSelectEmail,
      isSelectPhone: state.isSelectEmail,
    });
  };

  const onHidePopup = () => {
    setState({ ...initialState });
    clearAlert();
    setCombinedValue("");
    onHide();
  };

  const SendOTPviaEmail = async () => {
    clearAlert();
    try {
      let Obj = userChangeProfileReducer;
      await defaultTwoFactorEmailSchema.validate(Obj, { abortEarly: false });

      let verifyCode = generateRandomCode();
      setOtp(verifyCode);
      setMinutes(1);
      setSeconds(30);

      dispatch(
        twoFactorRegistration({
          email:
            (userChangeProfileReducer.twoFactorEnable &&
              userChangeProfileReducer.twoFactorType === "Email") ||
            userChangeProfileReducer.twoFactorType === "Both"
              ? userChangeProfileReducer.twofactorEmail
              : userChangeProfileReducer.email,
          verificationCode: verifyCode,
          name:
            userChangeProfileReducer.firstName +
            " " +
            userChangeProfileReducer.lastName,
          mailtype: "Registration",
        })
      ).then((res) => {
        setAlertMessage(res.payload.message);
        setIsSent(true);
      });
      setTwoFactorType("email");
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const SendOTPviaText = async () => {
    clearAlert();
    try {
      let Obj = userChangeProfileReducer;
      if (userChangeProfileReducer.twoFactorType === "Phone" ||
        userChangeProfileReducer.twoFactorType === "Both") {
        await defaultTwoFactorMobileSchemaFor2fPhone.validate(Obj, { abortEarly: false });
      } else {
        await defaultTwoFactorMobileSchemaForMobileNo.validate(Obj, { abortEarly: false });
      }

      let verifyCode = generateRandomCode();
      setOtp(verifyCode);
      setMinutes(1);
      setSeconds(30);
      const convertedPhoneNumber =
        (userChangeProfileReducer.twoFactorEnable &&
          userChangeProfileReducer.twoFactorType === "Phone") ||
        userChangeProfileReducer.twoFactorType === "Both"
          ? userChangeProfileReducer.twofactorPhone.replace(/-/g, "")
          : userChangeProfileReducer.mobileNo.replace(/-/g, "");
      const currentUrl = window.location.origin;
      dispatch(
        twoFactorRegistrationviaText({
          mobile: convertedPhoneNumber,
          verificationCode: verifyCode,
          county: isDupageIncluded ? "DUPAGE" : "Other",
          url: currentUrl,
        })
      ).then((res) => {
        setAlertMessage(res.payload.message);
        setIsSent(true);
      });
      setTwoFactorType("phone");
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const clearAlert = () => {
    setErrorKeys([""]);
    handleError([""], []);
    setAlertMessage("");
  };

  const handleDisabletwoFactor = () => {
    setconfirmPopupData({
      show: true,
      disbaled: false,
    });
  };

  const disabletwoFactor = () => {
    dispatch(
      twoFactorDisableUser({
        userId: loginReducer.userProfileId,
        twoFactorStatus: userChangeProfileReducer.twoFactorEnable
          ? "Disable"
          : "Enable",
      })
    )
      .then((res) => {
        setAlertMessage(res.payload.message);
        setIsSent(true);
        // document.cookie = `TwoFactorAuthentication:${loginReducer.userProfileId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        // document.cookie = `TwoFactorRegistration:${loginReducer.userProfileId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      })
      .then((res) => {
        dispatch(
          getUserDataByUsername({
            username: loginReducer.username,
          })
        );
      });
    setconfirmPopupData({
      show: false,
      disbaled: false,
    });
  };

  let window1 = (
    <section>
      <div
        className={`${
          (!userChangeProfileReducer.twoFactorEnable &&
            userChangeProfileReducer.twoFactorType === "NONE") ||
          (userChangeProfileReducer.twoFactorEnable &&
            userChangeProfileReducer.twoFactorType !== "NONE")
            ? "d-block"
            : "d-none"
        }`}
      >
        <h3>Select Phone or Email</h3>
        <h6>
          We need to send you a security code to verify your Phone or Email.
          Please choose from below
        </h6>
        <Row className="g-0 justify-content-md-center">
          <Col xs={7}>
            <div className="radio-group row justify-content-between px-3 text-center a">
              <div
                className={`col-auto mr-sm-2 card-block  py-0 text-center radio ${
                  state.isSelectPhone ? "selected" : "un-selected"
                }`}
                onClick={changeSelectPhone}
              >
                <div className="flex-row">
                  <div className="cardSize">
                    <div className="pic">
                      <img
                        className="irc_mut img-fluid"
                        src={require("../../assets/mobile_icon.png")}
                        width="100"
                        height="100"
                      />
                    </div>
                    <p>Phone</p>
                  </div>
                </div>
              </div>
              <div
                className={`col-auto mr-sm-2 card-block  py-0 text-center radio ${
                  state.isSelectEmail ? "selected" : "un-selected"
                }`}
                onClick={changeSelectEmail}
              >
                <div className="flex-row">
                  <div className="cardSize">
                    <div className="pic">
                      <img
                        className="irc_mut img-fluid"
                        src={require("../../assets/email_icon.png")}
                        width="100"
                        height="100"
                      />
                    </div>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ConfirmPopup
        children={
          userChangeProfileReducer.twoFactorEnable
            ? "Are you sure you want to Disable two factor authentication?"
            : "Are you sure you want to Enable two factor authentication?"
        }
        show={confirmPopupData.show}
        onHide={() =>
          setconfirmPopupData({
            show: false,
            disbaled: false,
          })
        }
        isConfirmation={disabletwoFactor}
      />
    </section>
  );

  let window2 = (
    <section>
      <h3>Enter Your Phone number</h3>
      <h6>and click "Send Code" button to receive a security code.</h6>
      <Row className="g-0 justify-content-md-center">
        <Col xs={8} className="d-flex align-items-center">
          <TextInputComponent
            placeholder="xxx-xxxx-xxxx"
            className="mt-1"
            value={
              userChangeProfileReducer.twoFactorType === "Phone" ||
              userChangeProfileReducer.twoFactorType === "Both"
                ? userChangeProfileReducer.twofactorPhone
                : userChangeProfileReducer.mobileNo
            }
            onChange={handleChangePhone}
            onBlur={MobileNumberFormat}
            isError={errorKeys.includes("mobileNo")}
          />
          <ButtonComponent
            variant="danger"
            className="ms-3"
            disabled={seconds > 0 || minutes > 0}
            onClick={SendOTPviaText}
          >
            Send Code
          </ButtonComponent>
        </Col>
      </Row>
      {isSent ? (
        <h6 className="pt-4 pb-3">
          A security code is sent to you phone number
          <br />
          Please enter it here.
        </h6>
      ) : (
        ""
      )}
      <h5>
        {seconds > 0 || minutes > 0 ? (
          <p>
            Resend Verification Code in:{" "}
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </p>
        ) : otp != "" ? (
          <p>
            Didn't recieve code?{" "}
            <a href="#" onClick={SendOTPviaText}>
              resend
            </a>
          </p>
        ) : (
          ""
        )}
      </h5>
      <br />

      <Row className="justify-content-center mt-0">
        <Col className="d-flex justify-content-center" xs={6}>
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[0] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(newValue + combinedValue.slice(1));
              if (newValue.length === 1) {
                input2Ref.current?.focus();
              }
            }}
            ref={input1Ref}
          />
          &nbsp;
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[1] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(
                combinedValue.slice(0, 1) + newValue + combinedValue.slice(2)
              );
              if (newValue.length === 1) {
                input3Ref.current?.focus();
              }
              if (newValue.length === 0) {
                input1Ref.current?.focus();
              }
            }}
            ref={input2Ref}
          />
          &nbsp;
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[2] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(
                combinedValue.slice(0, 2) + newValue + combinedValue.slice(3)
              );
              if (newValue.length === 1) {
                input4Ref.current?.focus();
              }
              if (newValue.length === 0) {
                input2Ref.current?.focus();
              }
            }}
            ref={input3Ref}
          />
          &nbsp;
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[3] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(
                combinedValue.slice(0, 3) + newValue + combinedValue.slice(4)
              );
              if (newValue.length === 1) {
                input5Ref.current?.focus();
              }
              if (newValue.length === 0) {
                input3Ref.current?.focus();
              }
            }}
            ref={input4Ref}
          />
          &nbsp;
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[4] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(
                combinedValue.slice(0, 4) + newValue + combinedValue.slice(5)
              );
              if (newValue.length === 1) {
                input6Ref.current?.focus();
              }
              if (newValue.length === 0) {
                input4Ref.current?.focus();
              }
            }}
            ref={input5Ref}
          />
          &nbsp;
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[5] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(combinedValue.slice(0, 5) + newValue);
              if (newValue.length === 0) {
                input5Ref.current?.focus();
              }
            }}
            ref={input6Ref}
          />
        </Col>
      </Row>
      <h6 className="pt-3">
        <b>Do not share this code with anyone else.</b>
      </h6>
    </section>
  );

  let window3 = (
    <section>
      <h3>Enter Your Email</h3>
      <h6>and click "Send Code" button to receive a security code.</h6>
      <Row className="g-0 justify-content-md-center">
        <Col xs={8} className="d-flex align-items-center">
          <TextInputComponent
            placeholder="sample@sample.com"
            className="mt-1"
            value={
              userChangeProfileReducer.twoFactorType === "Email" ||
              userChangeProfileReducer.twoFactorType === "Both"
                ? userChangeProfileReducer.twofactorEmail
                : userChangeProfileReducer.email
            }
            onChange={handleChangeEmail}
            isError={errorKeys.includes("email")}
          />
          <ButtonComponent
            variant="danger"
            className="ms-3"
            disabled={seconds > 0 || minutes > 0}
            onClick={SendOTPviaEmail}
          >
            Send Code
          </ButtonComponent>
        </Col>
      </Row>
      {isSent ? (
        <h6 className="pt-4 pb-3">
          A security code was sent to your email
          <br />
          Please enter it here.
        </h6>
      ) : (
        ""
      )}
      <h5>
        {seconds > 0 || minutes > 0 ? (
          <p>
            Resend Verification Code in:{" "}
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </p>
        ) : otp != "" ? (
          <p>
            Didn't recieve code?{" "}
            <a href="#" onClick={SendOTPviaEmail}>
              resend
            </a>
          </p>
        ) : (
          ""
        )}
      </h5>
      <br />
      <Row className="justify-content-center mt-0">
        <Col className="d-flex justify-content-center" xs={6}>
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[0] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(newValue + combinedValue.slice(1));
              if (newValue.length === 1) {
                input2Ref.current?.focus();
              }
            }}
            ref={input1Ref}
          />
          &nbsp;
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[1] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(
                combinedValue.slice(0, 1) + newValue + combinedValue.slice(2)
              );
              if (newValue.length === 1) {
                input3Ref.current?.focus();
              }
              if (newValue.length === 0) {
                input1Ref.current?.focus();
              }
            }}
            ref={input2Ref}
          />
          &nbsp;
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[2] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(
                combinedValue.slice(0, 2) + newValue + combinedValue.slice(3)
              );
              if (newValue.length === 1) {
                input4Ref.current?.focus();
              }
              if (newValue.length === 0) {
                input2Ref.current?.focus();
              }
            }}
            ref={input3Ref}
          />
          &nbsp;
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[3] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(
                combinedValue.slice(0, 3) + newValue + combinedValue.slice(4)
              );
              if (newValue.length === 1) {
                input5Ref.current?.focus();
              }
              if (newValue.length === 0) {
                input3Ref.current?.focus();
              }
            }}
            ref={input4Ref}
          />
          &nbsp;
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[4] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(
                combinedValue.slice(0, 4) + newValue + combinedValue.slice(5)
              );
              if (newValue.length === 1) {
                input6Ref.current?.focus();
              }
              if (newValue.length === 0) {
                input4Ref.current?.focus();
              }
            }}
            ref={input5Ref}
          />
          &nbsp;
          <TextInputComponent
            className="text-center"
            value={combinedValue.length > 0 ? combinedValue[5] : ""}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 6);
              setCombinedValue(combinedValue.slice(0, 5) + newValue);
              if (newValue.length === 0) {
                input5Ref.current?.focus();
              }
            }}
            ref={input6Ref}
          />
        </Col>
      </Row>
      <h6 className="pt-3">
        <b>Do not share this code with anyone else.</b>
      </h6>
    </section>
  );

  let window4 = (
    <section>
      <h3>Successfully enabled</h3>
      <h1 className="text-success">
        <RiCheckboxCircleFill />
      </h1>
      <div className="mt-4">
        <h6 className="text-center">
          {twoFactorType === "phone" ? (
            <p>
              Your Phone number{" "}
              {userChangeProfileReducer.twoFactorEnable
                ? userChangeProfileReducer.twofactorPhone
                : userChangeProfileReducer.mobileNo}{" "}
              is now linked with your account.
            </p>
          ) : twoFactorType === "email" ? (
            <p>
              Your Email{" "}
              {userChangeProfileReducer.twoFactorEnable
                ? userChangeProfileReducer.twofactorEmail
                : userChangeProfileReducer.email}{" "}
              is now linked with your account.
            </p>
          ) : (
            <p>
              Your Phone number {userChangeProfileReducer.mobileNo} and Email{" "}
              {userChangeProfileReducer.email} are now linked with your account.
            </p>
          )}
          <p>
            Time to time, we will ask you to verify your identity using this
            phone number/email for added security.
          </p>
          <p>
            You can change the phone number or the email address by going to the
            "2-Factor Authentication" from the top right hand corner of the main
            screen.
          </p>
        </h6>
      </div>
    </section>
  );

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHidePopup}
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header>
        <b>Turn On 2-Step Verification</b>
      </Modal.Header>
      <Modal.Body>
        {errorMessageAlert.messages.length > 0 ? (
          <div className="p-3">
            <ErrorMessageAlert
              messages={errorMessageAlert.messages}
              clearError={errorMessageAlert.clearError}
            />
          </div>
        ) : (
          ""
        )}
        <AlertMessage
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />
        <section className="multi_step_form">
          <form id="authform">
            {!userChangeProfileReducer.twoFactorEnable &&
            userChangeProfileReducer.twoFactorType === "NONE" ? (
              <div>
                <p
                  className={`${
                    state.windowNumber === 1 ? "d-block" : "d-none"
                  }`}
                >
                  With 2-step Verification also called two-factor
                  authentication, you can add an extra layer of security to your
                  account in case your password is stolen. After you set up
                  2-Step Verification, you can sign in to your account with :
                  Your Password + Your phone/email
                </p>

                <h2>Registration Process</h2>
                <p>
                  In order to use this service, you have to complete this
                  registration process
                </p>
              </div>
            ) : (
              <div>
                <p
                  className={`${
                    state.windowNumber === 1 ? "d-block" : "d-none"
                  }`}
                >
                  Your account has already been registered for two-factor
                  authentication. As an added layer of security, we highly
                  encourage the use of two-factor authentication to protect your
                  account and personal information. Here you can update your
                  two-factor authentication details.
                </p>
                <p
                  className={`${
                    state.windowNumber === 1 ? "d-block" : "d-none"
                  }`}
                >
                  {!userChangeProfileReducer.twoFactorEnable
                    ? "Your two factor authentication has been disabled."
                    : ""}
                  You can{" "}
                  {userChangeProfileReducer.twoFactorEnable
                    ? "disable"
                    : "enable"}{" "}
                  the two factor authentication by clicking the following
                  button,
                </p>
                <ButtonComponent
                  className={`${
                    state.windowNumber === 1 ? "d-block mx-auto" : "d-none"
                  }`}
                  variant={
                    userChangeProfileReducer.twoFactorEnable
                      ? "danger"
                      : "success"
                  }
                  onClick={() => handleDisabletwoFactor()}
                >
                  {" "}
                  {userChangeProfileReducer.twoFactorEnable
                    ? "Disable"
                    : "Enable"}
                </ButtonComponent>
                <hr
                  className={`${
                    userChangeProfileReducer.twoFactorEnable &&
                    userChangeProfileReducer.twoFactorType !== "NONE" &&
                    state.windowNumber === 1
                      ? "d-block"
                      : "d-none"
                  }`}
                ></hr>
                <h3
                  className={`${
                    userChangeProfileReducer.twoFactorEnable &&
                    userChangeProfileReducer.twoFactorType !== "NONE"
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  Update Two-Factor Authentication
                </h3>
                <br />
              </div>
            )}
            <ul
              id="progressbar"
              className={`${
                (!userChangeProfileReducer.twoFactorEnable &&
                  userChangeProfileReducer.twoFactorType === "NONE") ||
                (userChangeProfileReducer.twoFactorEnable &&
                  userChangeProfileReducer.twoFactorType !== "NONE")
                  ? "d-block"
                  : "d-none"
              }`}
            >
              <li className={`${state.windowNumber >= 1 ? "active" : ""}`}>
                Select
              </li>
              <li className={`${state.windowNumber >= 2 ? "active" : ""}`}>
                Security code
              </li>
              <li
                className={`${
                  state.windowNumber >=
                  (state.isSelectEmail && state.isSelectPhone ? 4 : 3)
                    ? "active"
                    : ""
                }`}
              >
                Finish
              </li>
            </ul>
            {state.windowNumber === 1 ? window1 : ""}

            {state.isSelectPhone && state.isSelectEmail
              ? state.windowNumber === 2
                ? window2
                : state.windowNumber === 3
                ? window3
                : state.windowNumber === 4
                ? window4
                : ""
              : ""}

            {!state.isSelectPhone && state.isSelectEmail
              ? state.windowNumber === 2
                ? window3
                : state.windowNumber === 3
                ? window4
                : ""
              : ""}

            {state.isSelectPhone && !state.isSelectEmail
              ? state.windowNumber === 2
                ? window2
                : state.windowNumber === 3
                ? window4
                : ""
              : ""}
          </form>
        </section>
      </Modal.Body>
      <Modal.Footer>
        {!userChangeProfileReducer.twoFactorEnable &&
        userChangeProfileReducer.twoFactorType === "NONE" &&
        state.windowNumber === 1 ? (
          <Col>
            <ButtonComponent variant="info" onClick={() => onHidePopup()}>
              Skip For Now
            </ButtonComponent>
          </Col>
        ) : (
          ""
        )}
        <Row className="g-2">
          <Col>
            {state.windowNumber !== 1 ? (
              <ButtonComponent
                className="ps-2 pe-4 d-flex align-items-center"
                disabled={state.windowNumber !== 2 ? true : false}
                onClick={() => {
                  clearAlert();
                  onChangeWindow(state.windowNumber - 1);
                }}
              >
                <RiArrowLeftSFill />
                Back
              </ButtonComponent>
            ) : (
              ""
            )}
          </Col>
          <Col>
            {!userChangeProfileReducer.twoFactorEnable &&
            userChangeProfileReducer.twoFactorType !== "NONE" ? (
              ""
            ) : (
              <ButtonComponent
                className="ps-4 pe-2 d-flex align-items-center"
                onClick={() => {
                  if (state.windowNumber === 1) {
                    if (!state.isSelectEmail && !state.isSelectPhone) {
                      handleError(
                        ["Selection"],
                        ["Please select Phone or Email to continue"]
                      );
                    } else {
                      setAlertMessage("");
                      handleError([""], []);
                      onChangeWindow(state.windowNumber + 1);
                    }
                  } else if (state.windowNumber === 2) {
                    if (combinedValue.length == 6 && combinedValue !== null) {
                      if (userVerificationCode === combinedValue) {
                        clearAlert();
                        dispatch(
                          twoFactorSaveUser({
                            email:
                              (userChangeProfileReducer.twoFactorEnable &&
                                userChangeProfileReducer.twoFactorType ===
                                  "Email") ||
                              userChangeProfileReducer.twoFactorType === "Both"
                                ? userChangeProfileReducer.twofactorEmail
                                : userChangeProfileReducer.email,
                            phone:
                              (userChangeProfileReducer.twoFactorEnable &&
                                userChangeProfileReducer.twoFactorType ===
                                  "Phone") ||
                              userChangeProfileReducer.twoFactorType === "Both"
                                ? userChangeProfileReducer.twofactorPhone
                                : userChangeProfileReducer.mobileNo,
                            userId: loginReducer.userProfileId,
                            twoFactorType: twoFactorType,
                          })
                        );
                        setCookie(
                          "TwoFactorAuthentication:" +
                          loginReducer.userProfileId,
                          loginReducer.userProfileId,
                          parseInt(loginReducer.twoFactorAuthPopupExpireDays)
                        );
                        dispatch(updateElementValueInLoginReducer({
                          elementName: "twoFactorEnable",
                          value: true,
                        }));
                       setOtp("");
                        setMinutes(0);
                        setSeconds(0);
                        setCombinedValue("");
                        setIsSent(false);
                        onChangeWindow(state.windowNumber + 1);
                      } else {
                        setAlertMessage("");
                        handleError(
                          ["verificationCode1"],
                          ["Verification codes doesn't match"]
                        );
                      }
                    }
                  } else if (state.windowNumber === 3) {
                    onHidePopup();
                  } else {
                    onChangeWindow(state.windowNumber + 1);
                  }
                }}
                disabled={
                  state.windowNumber === 2
                    ? combinedValue.length == 6 &&
                      combinedValue !== null &&
                      combinedValue !== "000000"
                      ? false
                      : true
                    : false
                }
              >
                {state.windowNumber ===
                (state.isSelectEmail && state.isSelectPhone ? 4 : 3)
                  ? "Finish"
                  : "Next"}
                &nbsp;
                <RiArrowRightSFill />
              </ButtonComponent>
            )}
          </Col>
          <Col>
            <ButtonComponent
              variant="danger"
              onClick={() => onHidePopup()}
              disabled={state.windowNumber === 3 ? true : false}
            >
              Close
            </ButtonComponent>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
