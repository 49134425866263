import React, { useEffect, useCallback } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RiBallPenLine, RiCloseFill } from "react-icons/ri";
import OptionPopup from "../common/OptionsPopup";
import {
  AMENDED_INVENTORY,
  APPLICATION_AND_ORDER,
  BLANK_ORDER,
  COMPL_SEARCH_WARRANT_WITH_SLASH,
  INVENTORY,
  LF_ARREST_WARRANT,
  LF_SUMMONS,
  LF_FILED_STATUS,
  PROOF_OF_SERVICE,
  ROLE_JUDGE,
  SEARCH_WAR_COMPLETE_STATUS,
} from "../../constants/common";
import { useAppDispatch } from "../../hooks/hooks";
import { useCaseDetailReducer } from "../../redux/slice/caseDetailSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";

import {
  updateElementObjectValue as updateElementInventoryObjectValue,
  useInventoryReducer,
  signInventory,
  updateInventrySignDocGenerateError,
} from "../../redux/slice/inventorySlice";
import {
  updateElementObjectValue as updateElementSearchWarrantObjectValue,
  useSearchWarrantReducer,
  signSearchwarrant,
} from "../../redux/slice/searchWarrantSlice";
import {
  signApplicationAndOrder,
  updateElementObjectValue as updateElementApplicationOrderObjectValue,
  useApplicationAndOrderReducer,
} from "../../redux/slice/applicationAndOrderSlice";
import {
  signBlankOrder,
  updateElementObjectValue as updateElementBlankOrderObjectValue,
  useBlankOrderReducer,
} from "../../redux/slice/blankOrderSlice";
import {
  signAmendInventory,
  updateElementObjectValue as updateElementAmendInventoryObjectValue,
  useAmendInventoryReducer,
} from "../../redux/slice/amendInventorySlice";

import {
  resetSignature,
  updateElementValueInSignPanelReducer,
  useSignPanelReducer,
} from "../../redux/slice/signPanelSlice";
import { formatName } from "../../utils/common";
import FormComponent from "./FormComponent";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { validateSignaturePixel } from "./CommonUtil";
import ConfirmPopup from "./ConfirmPopup";
import {
  signArrestWarrant,
  useArrestWarrantReducer,
} from "../../redux/slice/arrestWarrantSlice";
import { signSummons, useSummonsReducer } from "../../redux/slice/summonsSlice";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import { getJudgeSavedSignature } from "../../redux/slice/userProfileSlice";

interface SignPopupProps {
  show: boolean;
  onHide: () => void;
  formType: string;
  searchWarrantId?: number;
  hideFooter?: boolean;
}

let defaultSchema = yup.object().shape({
  sign: yup.string().nullable().required("Signature required."),
});

const SignPopup: React.FC<SignPopupProps> = (props) => {
  const { show, onHide, formType, searchWarrantId, hideFooter } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loginReducer = useLoginReducer();

  const signPanelReducer = useSignPanelReducer();
  const searchWarrantReducer = useSearchWarrantReducer();
  const applicationAndOrderReducer = useApplicationAndOrderReducer();
  const blankOrderReducer = useBlankOrderReducer();
  const inventoryReducer = useInventoryReducer();
  const amendInventoryReducer = useAmendInventoryReducer();
  const caseDetailReducer = useCaseDetailReducer();
  const arrestWarrantReducer = useArrestWarrantReducer();
  const ecomplaintReducer = useComplaintMainReducer();
  const summonsReducer = useSummonsReducer();
  
  const [complainantNameFooter, setComplainantNameFooter] = React.useState("");
  const [whoSign, setWhoSign] = React.useState("");
  const [swText, setSwText] = React.useState("");

  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const [signConfirmationModalShow, setsignConfirmationModalShow] =
    React.useState(false);

  const acceptSignConfirmModal = () => {
    setsignConfirmationModalShow(false);
    updateSignOrderOnSubmit("YES");
  };

  const updateSignOrderOnSubmit = async (validSpecialChars: string) => {
    if (COMPL_SEARCH_WARRANT_WITH_SLASH === formType) {
      const warrantFormObj = { ...searchWarrantReducer.searchWarrantObject };
      try {
        warrantFormObj.hiddenJudgeSignature = signPanelReducer.sign;
      } catch (err: any) {
        handleError(
          err.inner.map((e: any) => e.path),
          err.errors
        );
      }

      let dataObj = {
        searchWarrantId:
          searchWarrantReducer.searchWarrantObject.searchWarrantId,
        callFrom: "SubmitPage",
        searchWarrantObject: warrantFormObj,
        navigate: navigate,
        navigateTo: "/CaseDetails",
      };
      dispatch(signSearchwarrant(dataObj));
      cancelSignPopup();
    } else if (APPLICATION_AND_ORDER === formType) {
      const aaoFormObj = {
        ...applicationAndOrderReducer.applicationAndOrderObject,
      };

      try{
        aaoFormObj.hiddenJudgeSignature = signPanelReducer.sign;

      } catch (err: any) {
        handleError(
          err.inner.map((e: any) => e.path),
          err.errors
        );
      }

      let dataObj = {
        searchWarrantId:
          applicationAndOrderReducer.applicationAndOrderObject.searchWarrantId,
        applicationObject: aaoFormObj,
        navigate: navigate,
        navigateTo: "/CaseDetails",
      };
      dispatch(signApplicationAndOrder(dataObj));
      cancelSignPopup();
    } else if (BLANK_ORDER === formType) {
      let boFormObj = { ...blankOrderReducer.blankOrderObj };

      try {      
        boFormObj.hiddenJudgeSignature = signPanelReducer.sign;
      }
      catch (err: any) {
        handleError(
          err.inner.map((e: any) => e.path),
          err.errors
        );
      }
  
      let dataObj = {
        searchWarrantId: blankOrderReducer.blankOrderObj.searchWarrantId,
        blankOrderObject: boFormObj,
        navigate: navigate,
        navigateTo: "/CaseDetails",
      };
      dispatch(signBlankOrder(dataObj));
      cancelSignPopup();
    } else if (INVENTORY === formType) {
      let inventoryObj = { ...inventoryReducer.inventoryObject };
      
      try {
        inventoryObj.hiddenJudgeSignature = signPanelReducer.sign;
      } catch (err: any) {
        handleError(
          err.inner.map((e: any) => e.path),
          err.errors
        );
      }

      inventoryObj.formStatus = SEARCH_WAR_COMPLETE_STATUS;
      let dataObj = {
        searchWarrantId: inventoryReducer.inventoryObject.searchWarrantId,
        inventory: inventoryObj,
        navigate: navigate,
        navigateTo: "/CaseDetails",
        isOFCRequired: caseDetailReducer.county.isOFCRequired,
        validateSpecialChars: validSpecialChars,
        cancelSignPopup: cancelSignPopup,
      };
      dispatch(signInventory(dataObj));
    } else if (AMENDED_INVENTORY === formType) {
      let amendInventoryObj = { ...amendInventoryReducer.amendInventoryObject };
      try {
        amendInventoryObj.hiddenJudgeSignature = signPanelReducer.sign;
      } catch (err: any) {
        handleError(
          err.inner.map((e: any) => e.path),
          err.errors
        );
      }

      amendInventoryObj.formStatus = SEARCH_WAR_COMPLETE_STATUS;
      let dataObj = {
        searchWarrantId:
          amendInventoryReducer.amendInventoryObject.searchWarrantId,
        amendInventoryObject: amendInventoryObj,
        navigate: navigate,
        navigateTo: "/CaseDetails",
      };
      dispatch(signAmendInventory(dataObj));
      cancelSignPopup();
    } else if (LF_ARREST_WARRANT === formType) {
      let arrestWarrantObj = { ...arrestWarrantReducer.arrestWarrantObj };
      arrestWarrantObj.judgeSignature = signPanelReducer.sign;
      
      try {
        arrestWarrantObj.judgeSignature = signPanelReducer.sign;
      } catch (err: any) {
        handleError(
          err.inner.map((e: any) => e.path),
          err.errors
        );
      }
      dispatch(
        signArrestWarrant({
          arrestWarrantObj: arrestWarrantObj,
          ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
          navigate: navigate,
        })
      );
      cancelSignPopup();
    } else if (LF_SUMMONS === formType) {
      let summonObj = { ...summonsReducer.summonsDataObject };
      summonObj.judgeSignature = signPanelReducer.sign;
      try {
        summonObj.judgeSignature = signPanelReducer.sign;
      } catch (err: any) {
        handleError(
          err.inner.map((e: any) => e.path),
          err.errors
        );
      }
      dispatch(
        signSummons({
          summonObj: summonObj,
          ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
          navigate: navigate,
        })
      );
      cancelSignPopup();
    }
  };

  useEffect(() => {
    const { lastName, firstName } = loginReducer;

    let userName = formatName(firstName, lastName, "");
    let complainantNameFooter = "";
    let whoSign = "JUDGE SIGNATURE";
    let swornText = "";

    if (userName && userName.trim().length > 0) {
      complainantNameFooter = userName;
    }

    if (COMPL_SEARCH_WARRANT_WITH_SLASH === formType) {
      swornText =
        "Complainant Signature will be only applied after the content of this complaint is sworn before a judge";
    } else if (PROOF_OF_SERVICE === formType) {
      swornText =
        "Complainant Signature will be only applied after the content of this proof of service is sworn before a judge";
    } else if (APPLICATION_AND_ORDER === formType) {
      swornText =
        "Complainant Signature will be only applied after the content of this application and order is sworn before a judge";
    } else if (BLANK_ORDER === formType) {
      swornText =
        "Complainant Signature will be only applied after the content of this blank order is sworn before a judge";
    } else if (AMENDED_INVENTORY === formType) {
      swornText =
        "Complainant Signature will be only applied after the content of this amended inventory is sworn before a judge";
    } else {
      swornText =
        "Officer Signature will be only applied after the content of this inventory is sworn before a judge";
    }

    setComplainantNameFooter(complainantNameFooter);
    setSwText(swornText);
    setWhoSign(whoSign);
  }, [formType]);
  const cancelSignPopup = () => {
    dispatch(resetSignature());
    onHide();
  };

  const onSign = async () => {
   try {
      let  Obj = { sign: signPanelReducer.sign };

      await defaultSchema.validate(Obj, {
        abortEarly: false,
      });
      let isValidateSignPixel = validateSignaturePixel(
        true,
        signPanelReducer.signThreshold
      );
      if (!isValidateSignPixel) {
        setsignConfirmationModalShow(true);
      } else {
        updateSignOrderOnSubmit("YES");
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const data = [
    {
      type: "signPanel",
      props: {
        className: "d-block",
        name: "sign",
        appearPersonText: complainantNameFooter,
        whoSign: whoSign,
        swText: swText,
        hideFooter: hideFooter,
        isError: errorKeys.includes("sign"),
        isBorderVisible: true,
      },
      colSize: 12,
    },
  ];

  let pdfError = [];
  if (inventoryReducer.inventrySignDocGenerateError && inventoryReducer.inventrySignDocGenerateErrorMsg) {
    pdfError.push(
      <div className="text-start">
        <b>
          When analyzing the content of this document, it was determined that the following areas may contain characters that are not compatible.
        </b>
      </div>
    );
    pdfError.push(<br></br>);
    for (const [key, value] of Object.entries(inventoryReducer.inventrySignDocGenerateErrorMsg)) {
      pdfError.push(<div className="text-start">{"Field: " + key}</div>);
      pdfError.push(<div className="text-start">{"Content: " + value}</div>);
    }
    pdfError.push(<br></br>);
    pdfError.push(
      <div className="text-start">
        <b>
          If you are certain that the document generates (Preview) successfully, please continue. Otherwise please change.
        </b>
      </div>
    );
  }

  const changeInvenDoc = () => {
    dispatch(
      updateInventrySignDocGenerateError({
        error: false,
        errorMsg: undefined,
      })
    );
  };

  const continueOnSignInventry = () => {
    dispatch(
      updateInventrySignDocGenerateError({
        error: false,
        errorMsg: undefined,
      })
    );
    updateSignOrderOnSubmit("NO");
  };

  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-85w"
        onHide={() => cancelSignPopup()}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5>
            <b>Judge Signature</b>
          </h5>
        </Modal.Header>
        <Modal.Body>
          <ErrorMessageAlert
            messages={errorMessageAlert.messages}
            clearError={errorMessageAlert.clearError}
          />
          <FormComponent
            isSpace={"none"}
            totalColumns={12}
            colSize={12}
            componentData={data}
            formCardStyle = {"none"}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row className="g-2">
            <Col>
              <Button
                variant="success"
                onClick={() => onSign()}
                className="sign-btn-width"
              >
            SIGN <RiBallPenLine />
              </Button>
            </Col>
            <Col>
              <Button
                variant="danger"
                onClick={() => cancelSignPopup()}
                className="w-100"
              >
                Cancel <RiCloseFill />
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <ConfirmPopup
        show={signConfirmationModalShow}
        onHide={() => setsignConfirmationModalShow(false)}
        isConfirmation={acceptSignConfirmModal}
      >
        Are you sure the signature you provided is valid?"
      </ConfirmPopup>

      <OptionPopup
        show={inventoryReducer.inventrySignDocGenerateError}
        header="Confirmation"
        children={<div>{pdfError}</div>}
        buttons={[{ name: "Continue", variant: "primary", onClick: continueOnSignInventry },
        { name: "Change", variant: "primary", onClick: changeInvenDoc }]}
      />
    </>
  );
};

export default SignPopup;
