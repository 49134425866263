import React, { Component } from 'react';
import Header from '../common/Header';

class SyncIucsData extends Component {
    render() {
        return (
            <>
                <Header headerName="Sync IUCS Data" />
                Sync IUCS Data
            </>
        );
    }
}

export default SyncIucsData;