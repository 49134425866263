import React, { useCallback, useEffect, useState } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import ButtonComponent from "../common/ButtonComponent";
import Header from "../common/Header";
import { Row, Col, InputGroup, Form, OverlayTrigger, Button, Tooltip } from "react-bootstrap";
import { useAppDispatch } from "../../hooks/hooks";
import ConfirmPopup from "../common/ConfirmPopup";
import { useLocation, useNavigate } from "react-router-dom";
import { TableActionComponent } from "../common/TableActionComponent";
import moment from "moment";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { RiEraserFill } from "react-icons/ri";
import { HiArrowsExpand } from "react-icons/hi";
import ReactSignatureCanvas from "react-signature-canvas";
import TextInputComponent from "../common/TextInputComponent";
import SignPanelExpandPopup from "../warrantlist/form/SignPanelExpandPopup";
import InfoPopup from "../common/InfoPopup";
import useModel from "../../hooks/useModel";
import { getJudgeSavedSignature, saveSignature, useUserProfilesReducer } from "../../redux/slice/userProfileSlice";
import AlertMessage from "../common/AlertMessage";

interface ObjData {
  sessionId?: string;
  error?: string;
  imageData?: string;
} 

const TemplateList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userType,isValid,userProfileId } = useLoginReducer();
  const {isTwoFactorEnable} = useUserProfilesReducer();
  const [sessionId, setSessionId] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [wsSignature, setWsSignature] = React.useState("");
  const [signature, setSignature] = React.useState("");
  const sigPad = React.useRef<ReactSignatureCanvas>(null);
  const [imgFileName, setImgFileName] = React.useState("");
  const signPanelPopup = useModel();
  const [alertMessage, setAlertMessage] = React.useState<string>("");

  const location = useLocation();
  const enableImageUpload = location.state?.signature  || false;
  const signatureSetUserid = location.state?.userId

  useEffect(() => {
    if (isValid) {
      connect();
    }
  }, [isValid]);

  useEffect(() => {
    const fetchSignature = async () => {
      const res = await dispatch(getJudgeSavedSignature({
        userprofileId: enableImageUpload ? signatureSetUserid : userProfileId
      }));

      if (res.payload.message) {
        setSignature(res.payload.message)
        setSignForPixelCanvas(res.payload.message);
        sigPad.current?.fromDataURL(res.payload.message, { width: 475, height: 200 });
      }
    }
    fetchSignature();
  }
    , [])
  let itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" }
  ];

  const setSignForPixelCanvas = (sign: string) => {
    let canvas: any = document.getElementById("pixelcanvas");
    let ctx = canvas.getContext("2d");
    let image = new Image();
    image.onload = function () {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0);
    };

    image.src = sign;
  };

  const clear = useCallback(() => {
    if (sigPad.current) {
      sigPad.current.clear();
      setSignature("");
    }
  }, [dispatch]);

  const returnToDashboard = () => {
    navigate(-1);
  }

  const saveJudgeSignature = () => {
    
    if(!signature && signature === "" ) {
      setAlertMessage("The signature cannot be empty.")
    } else {
     dispatch(
      saveSignature({
        signatureData :signature,
        userProfileId :enableImageUpload ?signatureSetUserid : userProfileId
      })).then((res) => {
        setAlertMessage(res.payload.message)
      })  
    }  
  }


  const connect = () => {
    var URL =
      process.env.REACT_APP_WSURL +
      "?user=" +
      process.env.REACT_APP_WSUSERNAME +
      "&password=" +
      process.env.REACT_APP_WSPASSWORD;
    console.log(URL);
    var ws = new WebSocket(URL);

    const that: any = this; // cache the this
    var connectInterval: string | number | NodeJS.Timeout | undefined;
    let sessionId;
    let error;
    let signature;

    ws.onopen = () => {
      console.log("connected websocket main component");

      console.log("connected websocket main component" + ws);
    };

    setLoading(true);
    ws.onmessage = (e: MessageEvent) => {
      try {
        const objData: ObjData = JSON.parse(e.data);

        if (objData.sessionId != null && objData.sessionId !== "") {
          const updatedSessionId: string = objData.sessionId;
          setSessionId(updatedSessionId);
          setError("");
        }

        if (objData.error != null && objData.error !== "") {
          console.log(objData.error);
          setError(
            "Unable to generate QR code. Your browser or network may be blocking access to https://mysignature.net"
          );
        }

        if (objData.imageData != null && objData.imageData !== "") {
          const updatedSignature: string = objData.imageData;
          setWsSignature(updatedSignature);
          setSignature(updatedSignature);
          sigPad.current?.clear();

          setSignForPixelCanvas(updatedSignature);
          sigPad.current?.fromDataURL(updatedSignature, { width: 475, height: 200 });
         }
        setLoading(false);
      } catch (error) {
        console.error("Error processing WebSocket message:", error);
        setLoading(false);
      }
    };
    // websocket onclose event listener
    ws.onclose = (e) => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      );

      that.timeout = that.timeout + that.timeout; //increment retry interval
    };

    // websocket onerror event listener
    ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",

        "Closing socket"
      );

      ws.close();
    };

    
  };

  const onEnd = () => {
    const sign = sigPad.current!.getCanvas().toDataURL("image/png");
    setSignForPixelCanvas(sign);
    setSignature(sign);
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  };


  const handleFileChange = async (event: any) => {
    try {
      
      const file = event.target.files[0];
      if(file){
        const fileName = file.name;
        const fileNameLower = fileName.toLowerCase();
        const acceptFormats = ['jpg', 'png', 'jpeg'];
        
        const fileExtension = fileNameLower.substring(fileNameLower.lastIndexOf('.') + 1);
        if (!(acceptFormats.includes(fileExtension))) {
          // setshowIntoModalMessage("Document must be a JPG, JPEG, or PNG format.");
          // throw "Only image formats allowed";
        };
        setImgFileName(fileName);
        let sign = String(await convertBase64(file));
        sigPad.current?.fromDataURL(sign, { width: 475, height: 200 });
        setTimeout(() => {
          let signNew = sigPad.current!.getCanvas().toDataURL('image/png');
          setSignature(signNew);
          setSignForPixelCanvas(signNew);
        }, 700);

      }
      event.target.value = null;
    } catch (e) {
    //  setshowInfoModal(true);
      event.preventDefault();
      event.target.value = null;
      return;
    }
  };
  const submitSignExpand: any = (exSign: any) => {
    sigPad.current?.clear();
    sigPad.current?.fromDataURL(exSign, { width: 475, height: 200 });
    setSignature(exSign);
    signPanelPopup.close();
  };

  const clearImg = () => {
    setImgFileName("");
    sigPad.current?.clear();
    setSignature("");
    setSignForPixelCanvas("");

  }


  return (
    <>
     <Header headerName="Save My Signature" />
     <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Save My Signature"       />
        <AlertMessage
          message={alertMessage}
          onClose={() => setAlertMessage("")}
          variant={
            alertMessage === "Signature saved successfully." ? "success" : "danger"
          }
        />
        <div>
        <div className="mt-2 mb-2 p-3 border rounded">
          <div className="mt-1 mb-1 p-2">
            <hr />
            <Row className={`row ${enableImageUpload ? '' : 'justify-content-center'}`}>
                <Col md={4}>
                  <div className="text-center">
                    <p className="fw-bold m-0">
                      Using a separate touch screen smartphone or tablet, open{" "}
                      <br />
                      {process.env.REACT_APP_MYSIGNATURE_URL}
                    </p>
                    {loading ? (
                      <p className="loading-text">
                        <br></br>Generating Session ID...
                      </p>
                    ) : sessionId ? (
                      <>
                        <p className="fw-bold m-0 pt-2">
                          Enter the signature ID shown below
                        </p>
                        <div className="text-danger fw-bold">
                          <label>Signature ID:</label>
                          <h3>{sessionId}</h3>
                        </div>
                        <div className="mt-1">
                          <img
                            id="qrCodeImg"
                            src={process.env.REACT_APP_QRCODE_IMAGE_SRC + sessionId}
                            alt="qr"
                            width={"150px"}
                            height={"150px"}
                          />
                        </div>
                        <label>
                          <b>
                            {" "}
                            <b>{process.env.REACT_APP_MYSIGNATURE_URL}</b>
                          </b>
                        </label>
                      </>
                    ) : (
                      <div className="alert alert-danger mt-3" role="alert">
                        <label>Error:</label>
                        <p>
                          Unable to generate QR code. Your browser or network may be
                          blocking access to {process.env.REACT_APP_MYSIGNATURE_URL}
                        </p>
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={5}>
                  <Row className="align-items-start no-gutters">
                    <Col>
                      {/* <div className="text-end" style={{ width: "490px" }}>
                        <OverlayTrigger
                        overlay={<Tooltip id="tooltip-disabled">Expand</Tooltip>}
                      >
                        <span className="d-inline-block">
                          <HiArrowsExpand
                            onClick={signPanelPopup.open}
                            className="text-secondary"
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </OverlayTrigger>
                      </div> */}
                      <ReactSignatureCanvas
                        penColor="black"
                        ref={sigPad}
                        onEnd={onEnd}
                        canvasProps={{
                          className: `sigCanvas border-sigCanvas`,
                          width: 475,
                          height: 200,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-start">
                    <Col md={2} style={{ marginLeft: '418px' }}> {/* Adjust the margin value as needed */}
                      <ButtonComponent className="btn-danger" onClick={clear}>
                        Clear
                      </ButtonComponent>
                    </Col>
                  </Row>
                </Col>
                {enableImageUpload ?
                <Col md={3}>
                <p className="fw-bold text-danger m-0">OPTION 3:</p>
              <p className="fw-bold m-0">
                Browse for a saved signature image file
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <TextInputComponent
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={handleFileChange}
                    accept=".png,.jpg,.jpeg"
                    style={{ width: "131px", color: "transparent" }}
                  />
                </div>
                <div>
                  {imgFileName ? <a style={{ margin: "0 auto" }} className="text-primary fileLink">{imgFileName.length > 30 ? (imgFileName.slice(0, 20) + "...") : imgFileName}</a> : ""}
                  {imgFileName ? <Button style={{ margin: "0 auto" }} variant="danger" onClick={clearImg} className={`fileButton`} >x</Button> : ""}
                </div>
              </div>
                </Col>
                :""}
              </Row>
              <Row className="justify-content-end">
                <Col md={2}>
                  <ButtonComponent className="btn-info ms-2" onClick={saveJudgeSignature}>
                    Save Signature
                  </ButtonComponent>
                  <ButtonComponent className="btn-danger ms-2" onClick={returnToDashboard}>
                    Cancel
                  </ButtonComponent>
                </Col>
              </Row>
            <canvas id={"pixelcanvas"} width={"475"} height={"200"} hidden></canvas>
            <SignPanelExpandPopup
              show={signPanelPopup.isOpen}
              onHide={signPanelPopup.close}
              submitSignExpand={submitSignExpand}
            />
          </div>
        </div>
      </div>
  
      </div>
    </>
  );
};

export default TemplateList;
