import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { getCountiesByUserRole, getECCountiesByUserRole } from "../../services/CountyService";

interface CountiesState {
  ecCountyList: any[];
  countyList: any[];
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  pagedCountyList: [];
  countyError?: string;
  pagedCountyListFetchstatus: LoadingType;
  listCountyError?: string;
  loggedInUserCountyFetchStatus: LoadingType;
  loggedInUserCountyError?: string;
  updateCountyFetchStatus: LoadingType;
  updateCountyError?: string;
  successMessage?: string;
  localCounty?: string;
  localCountyID?:number;
  county : string;
  countiesId : number;
  circuitCourtNumber : string;
  status : string;
}

const initialState = {
  ecCountyList:[],
  countyList: [],
  currentPage: 1,
  totalPages: 1,
  totalRecords: 0,
  pagedCountyList: [],
  pagedCountyListFetchstatus: "idle",
  listCountyError: undefined,
  loggedInUserCountyFetchStatus: "idle",
  loggedInUserCountyError: undefined,
  updateCountyFetchStatus: "idle",
  updateCountyError: undefined,
  successMessage: undefined,
  localCounty:"",
  localCountyID:0,
  county : "",
  countiesId : 0,
  circuitCourtNumber : "",
  status : ""
} as CountiesState;

export const listCounty = createAsyncThunk<
  any,
  {
    countyId?: number;
    judicialNumber?: number;
    status: string;
    pageNo?: number;
  },
  {
    state: RootState;
    rejectValue: string;
  }
>(
  "counties/listCounty",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { countyId, judicialNumber, status, pageNo = 0 } = data;

    let queryParam = `pageNo=${pageNo}&`;
    if (countyId) queryParam += `countyID=${countyId}&`;
    if (judicialNumber) queryParam += `judicialNumber=${judicialNumber}&`;

    queryParam += `status=${status}&`;

    let { token } = getState().login;
    try {
      dispatch(showHideLoadingGif(true));
      const reponse = await doFetch({
        url: `countieservices/getCounties?${queryParam}`,
        token,
      });

      dispatch(showHideLoadingGif(false));
      if (reponse.ok) {
        return reponse.json();
      } else {

        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching county list");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching county list"
      );
    }
  }
);

export const getLoggedInUserCounties = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "counties/getLoggedInUserCounties",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await getCountiesByUserRole({ token });
      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else { 
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching logged in user county list");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching logged in user county list"
      );
    }
  }
);

export const updateCountyStatus = createAsyncThunk<
  any,
  {countyId :number; listViewData :any },
  { state: RootState; rejectValue: string }
>(
  "counties/updateCountyStatus",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { countyId, listViewData } = data;
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `countieservices/updateCountyStatus/${countyId}`,
        token,
        type: "PUT",
      });
      dispatch(listCounty(listViewData));

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        const id = await reponse.text();
        return {
          message: `County ${id} status updated successfully`,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while updating county status");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while updating county status"
      );
    }
  }
);

export const getLoggedInUserECCounties = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "counties/getLoggedInUserECCounties",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await getECCountiesByUserRole({ token });
      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else { 
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching logged in user ECcounty list");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching logged in user ECcounty list"
      );
    }
  }
);

const countiesSlice = createSlice({
  name: "counties",
  initialState,
  reducers: {
    updateElementValueCountiesReducer: updateElementValue,
    resetCountiesData: (state: CountiesState) => {
      return {
        ...state,
        ...initialState,
      };
    },
    updateCountiesReducer: (state : CountiesState, action) => {
      return {
        ...state,
        county : action.payload.county,
        circuitCourtNumber : action.payload.circuitCourtNumber,
        status : action.payload.status,
        countiesId : action.payload.countyId,
      };
    },
  },
  extraReducers(builder) {
    builder
      // listCounty
      .addCase(listCounty.pending, (state) => {
        state.pagedCountyListFetchstatus = "loading";
        state.listCountyError = undefined;
      })
      .addCase(listCounty.fulfilled, (state, action) => {
        state.pagedCountyListFetchstatus = "success";
        state.totalPages = action.payload.totalPages;
        state.totalRecords = action.payload.totalRecords;
        state.currentPage = action.payload.currentPage;
        state.pagedCountyList = action.payload.countyList;
      })
      .addCase(listCounty.rejected, (state, action) => {
        state.pagedCountyListFetchstatus = "error";
        state.listCountyError = action.payload;
      })

      // getLoggedInUserCounties
      .addCase(getLoggedInUserCounties.pending, (state) => {
        state.loggedInUserCountyFetchStatus = "loading";
        state.loggedInUserCountyError = undefined;
      })
      .addCase(getLoggedInUserCounties.fulfilled, (state, action) => {
        state.loggedInUserCountyFetchStatus = "success";
        state.countyList = action.payload;
      })
      .addCase(getLoggedInUserCounties.rejected, (state, action) => {
        state.loggedInUserCountyFetchStatus = "error";
        state.loggedInUserCountyError = action.payload;
      })

      // updateCountyFetchStatus
      .addCase(updateCountyStatus.pending, (state) => {
        state.updateCountyFetchStatus = "loading";
        state.updateCountyError = undefined;
      })
      .addCase(updateCountyStatus.fulfilled, (state, action) => {
        state.updateCountyFetchStatus = "success";
        state.successMessage = action.payload.message;
      })
      .addCase(updateCountyStatus.rejected, (state, action) => {
        state.updateCountyFetchStatus = "error";
        state.updateCountyError = action.payload;
      })

      // getLoggedInUserECCounties
      .addCase(getLoggedInUserECCounties.pending, (state) => {
        state.loggedInUserCountyFetchStatus = "loading";
        state.loggedInUserCountyError = undefined;
      })
      .addCase(getLoggedInUserECCounties.fulfilled, (state, action) => {
        state.loggedInUserCountyFetchStatus = "success";
        state.ecCountyList = action.payload;
      })
      .addCase(getLoggedInUserECCounties.rejected, (state, action) => {
        state.loggedInUserCountyFetchStatus = "error";
        state.loggedInUserCountyError = action.payload;
      });
  },
});

export const { updateElementValueCountiesReducer, resetCountiesData, updateCountiesReducer } = countiesSlice.actions;

export const useCountyListReducer = () =>
  useAppSelector((state) => state.counties);

export default countiesSlice.reducer;
