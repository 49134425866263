import React, { Component } from "react";
import Header from "./Header";

class Page404 extends Component {
  render() {
    return (
      <>
        <Header headerName="404 Page" />
        <div className="container-center">
          <img src={require("../../assets/404.png")} alt="404 Not found" />
        </div>
      </>
    );
  }
}

export default Page404;
