import React from 'react'
import { Button as BootstrapButton, ButtonProps } from "react-bootstrap";

const ButtonComponent:React.FC<ButtonProps> = (props) => {
  return (
    <BootstrapButton {...props}>
      {props.children}
  </BootstrapButton>
  );
}

export  default ButtonComponent;