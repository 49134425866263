import React, { useCallback, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AMENDED_INVENTORY, COMPL_SEARCH_WARRANT_WITH_SLASH, DEVELOPER_OPTION, INVENTORY, REVISED_PROBABLE_CAUSE_STATEMENT } from "../../../constants/common";
import { useAppDispatch } from "../../../hooks/hooks";
import { updateElementObjectValue as updateElementObjectValueInventory, useInventoryReducer } from "../../../redux/slice/inventorySlice";
import { updateElementObjectValue as updateElementObjectValueSearchWarrant, useSearchWarrantReducer } from "../../../redux/slice/searchWarrantSlice";
import { updateElementObjectValue as updateElementObjectValueAmendInventory, useAmendInventoryReducer } from "../../../redux/slice/amendInventorySlice"
import ConfirmPopup from "../../common/ConfirmPopup";
import InfoPopup from "../../common/InfoPopup";
import { updateElementObjectValueCountFormObjectReducer, useCountFormReducer } from "../../../redux/slice/countFormSlice";
import { updateElementObjectValue as updateElementObjectValueProbableCause, useProbableCauseStatementReducer } from "../../../redux/slice/probableCauseStatementSlice";
import { updateElementValueCaseDetailReducer, useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";

interface FileUploader {
  formType: string;
  className?: string;
  fileName: string;
  isCanEdit? : boolean;
}
const FileUploader: React.FC<FileUploader> = (props) => {
  const { className, formType, fileName, isCanEdit } = props;
  const dispatch = useAppDispatch();
  const searchWarrantReducer = useSearchWarrantReducer();
  const inventoryReducer = useInventoryReducer();
  const amendInventoryReducer = useAmendInventoryReducer();
  const {probableDataObject} = useProbableCauseStatementReducer();

  const [confirmPopupData, setconfirmPopupData] = React.useState(false);
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [showIntoModalMessage, setshowIntoModalMessage] = React.useState("");
  const [file, setfile] = React.useState("");
  const [showInfoModal, setshowInfoModal] = React.useState(false);
  const countFormReducer = useCountFormReducer();
  const {uploadedFile, uploadedFileName} = useCaseDetailReducer();

  function prepareMessage(msg: any) {
    let buff = [];
    if (msg && msg.trim().length > 0) {
      let arr = msg.split("<br>");
      let line = "";
      for (let i = 0; i < arr.length; i++) {
        line = arr[i];
        buff.push(<div>{line}</div>);
      }
    }
    return buff;
  }
  const handleConfirmation = async () => {
    const stringFile = String(await convertBase64(file));
    continueHandleFileChange(file, stringFile);
    setconfirmPopupData(false);
  }
  const acceptInfoModal = () => {
    setshowInfoModal(false);
  }

  const convertBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    });
  };

  const handleFileChange = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      try {
        let file = event.target.files[0];
        let fileName = (file.name).toLowerCase()
        let fileSize = 0;
        let maxFileSize = 5 //in mb  
        fileSize = event.target.files[0].size //size in kb
        fileSize = fileSize / 1048576; //size in mb
        if (fileSize > maxFileSize) {
          setshowIntoModalMessage("File size should be less than 5MB.");
          throw "Too big"
        };
        if (!fileName.endsWith('.pdf')) {
          setshowIntoModalMessage("Document should be a pdf file");
          throw "Only pdf allowed"
        };
      } catch (e) {
        setshowInfoModal(true);
        event.preventDefault();
        return;
      }
      if (fileName && fileName.trim().length > 0 && fileName !== event.target.files[0].name) {
        let confirmText = (
          <>
            <label>
              Existing image document will be replaced.
            </label>
            <label>Do you want to continue?</label>
          </>
        );
        setfile(event.target.files[0]);
        setconfirmText(confirmText);
        setconfirmPopupData(true);
        event.target.value = null;
        return;
      }
      const stringFile = String(await convertBase64(event.target.files[0]));
      continueHandleFileChange(event.target.files[0], stringFile);
      event.target.value = null;
    }

  };
  const continueHandleFileChange = (file: any, stringFile: any) => {

    if (COMPL_SEARCH_WARRANT_WITH_SLASH === formType) {
      dispatch(updateElementObjectValueSearchWarrant({ elementName: "uploadedFileName", value: file.name }));
      dispatch(updateElementObjectValueSearchWarrant({ elementName: "hiddenUploadedPdf", value: stringFile }));
    } else if (INVENTORY === formType) {
      dispatch(updateElementObjectValueInventory({ elementName: "uploadedFileName", value: file.name }));
      dispatch(updateElementObjectValueInventory({ elementName: "hiddenUploadedPdf", value: stringFile }));
    } else if (AMENDED_INVENTORY === formType) {
      dispatch(updateElementObjectValueAmendInventory({ elementName: "uploadedFileName", value: file.name }));
      dispatch(updateElementObjectValueAmendInventory({ elementName: "hiddenUploadedPdf", value: stringFile }));
    } else if ("add eticket" === formType) {
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "uploadedFileName", value: file.name }));
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "hiddenUploadPdf", value: stringFile }));
    } else if (REVISED_PROBABLE_CAUSE_STATEMENT === formType){
      dispatch(updateElementObjectValueProbableCause({elementName: "uploadedFileName", value: file.name}));
      dispatch(updateElementObjectValueProbableCause({elementName: "uploadedFile", value: stringFile}));
      dispatch(updateElementObjectValueProbableCause({elementName: "isFileUploaded", value: "Y"}));
    } else if (formType === DEVELOPER_OPTION){
      dispatch(updateElementValueCaseDetailReducer({ elementName: "uploadedFileName", value: file.name }));
      dispatch(updateElementValueCaseDetailReducer({ elementName: "uploadedFile", value: stringFile }));
    }
  };
  const downloadPdf = () => {
    const downloadLink = document.createElement('a');
    if (COMPL_SEARCH_WARRANT_WITH_SLASH === formType) {
      const downloadLink = document.createElement('a');
      let file = searchWarrantReducer.searchWarrantObject.hiddenUploadedPdf;
      let fileName = searchWarrantReducer.searchWarrantObject.uploadedFileName;
      downloadLink.href = file;
      downloadLink.download = fileName;
      downloadLink.click();
    } 
    /* not implmented in GWT search warrant */
    // else if (INVENTORY === formType) {
    //   let file = inventoryReducer.inventoryObject.hiddenUploadedPdf;
    //   let fileName = inventoryReducer.inventoryObject.uploadedFileName;
    //   downloadLink.href = file;
    //   downloadLink.download = fileName;
    //   downloadLink.click();
    // } 
    // else if (AMENDED_INVENTORY === formType) {
    //   let file = amendInventoryReducer.amendInventoryObject.hiddenUploadedPdf;
    //   let fileName = amendInventoryReducer.amendInventoryObject.uploadedFileName;
    //   downloadLink.href = file;
    //   downloadLink.download = fileName;
    //   downloadLink.click();
    // } 
    else if ("add eticket" === formType) {
      let file = countFormReducer.countFormObject.hiddenUploadPdf;
      let fileName = countFormReducer.countFormObject.uploadedFileName;
      downloadLink.href = file;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    else if (REVISED_PROBABLE_CAUSE_STATEMENT === formType) {
      let file = probableDataObject.uploadedFile;
      let fileName = probableDataObject.uploadedFileName;
      downloadLink.href = file;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    else if (DEVELOPER_OPTION === formType) {
      let file = uploadedFile;
      let fileName = uploadedFileName;
      downloadLink.href = file;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };
  const clearPdf = () => {
    if (COMPL_SEARCH_WARRANT_WITH_SLASH === formType) {
      dispatch(updateElementObjectValueSearchWarrant({ elementName: "uploadedFileName", value: null }));
      dispatch(updateElementObjectValueSearchWarrant({ elementName: "hiddenUploadedPdf", value: null }));
    } else if (INVENTORY === formType) {
      dispatch(updateElementObjectValueInventory({ elementName: "uploadedFileName", value: null }));
      dispatch(updateElementObjectValueInventory({ elementName: "hiddenUploadedPdf", value: null }));
    } else if (AMENDED_INVENTORY === formType) {
      dispatch(updateElementObjectValueAmendInventory({ elementName: "uploadedFileName", value: null }));
      dispatch(updateElementObjectValueAmendInventory({ elementName: "hiddenUploadedPdf", value: null }));
    } else if ("add eticket" === formType) {
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "uploadedFileName", value: null }));
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "hiddenUploadPdf", value: null }));
    } else if (REVISED_PROBABLE_CAUSE_STATEMENT === formType) {
      dispatch(updateElementObjectValueProbableCause({ elementName: "uploadedFileName", value: null }));
      dispatch(updateElementObjectValueProbableCause({ elementName: "uploadedFile", value: null }));
      dispatch(updateElementObjectValueProbableCause({ elementName: "isFileUploaded", value: "N" }));
    } else if (formType === DEVELOPER_OPTION){
      dispatch(updateElementValueCaseDetailReducer({ elementName: "uploadedFileName", value: null }));
      dispatch(updateElementValueCaseDetailReducer({ elementName: "uploadedFile", value: null }));
    }
  }

  return (
    <div className={className}>
      {formType === COMPL_SEARCH_WARRANT_WITH_SLASH || formType === REVISED_PROBABLE_CAUSE_STATEMENT ? (
        <><Row className="d-block fw-bold text-center text-danger">
          <hr />
        </Row><Row>
            <Col className="d-block fw-bold text-left text-danger">ATTACH UPLOADED IMAGES</Col>
            {isCanEdit?
            <Col className="d-block text-primary">
              <a href={require("../../../assets/Attachment_Help.pdf")} target='_blank' download>Help to upload images</a>
            </Col>
            :""}
            <Col xs={8}></Col>
          </Row><Row>
            <Col>{isCanEdit?"Uploaded image document should be a pdf file. It will be added after complaint form.":fileName?"Uploaded Images":""}</Col>
          </Row></>
      ) : ("")}
      <Row>
        <Col xs={"auto"}>
          {isCanEdit?<input type="file" accept=".pdf" onChange={handleFileChange} className="d-block" style={{ width: "113px", color: "transparent" }} />:""}
          {fileName ?<a onClick={downloadPdf} className="text-primary fileLink">{fileName.length>30?(fileName.slice(0,29)+"..."):fileName}</a>:""}
          {(fileName && isCanEdit) ? <Button onClick={clearPdf} variant="danger" className= "fileButton">x</Button> : ""}
        </Col>
      </Row>
      <ConfirmPopup
        children={confirmText}
        show={confirmPopupData}
        onHide={() => setconfirmPopupData(false)}
        isConfirmation={handleConfirmation}
      />
      <InfoPopup
        show={showInfoModal}
        onHide={acceptInfoModal} variant="danger">
        {prepareMessage(showIntoModalMessage)}
      </InfoPopup>
    </div>
  );
};

export default FileUploader;
