import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { Accordion, Col, OverlayTrigger, Row, Toast, Tooltip } from "react-bootstrap";
import {
  FaUser,
  FaList,
  FaFile,
  FaBuilding,
  FaBook,
  FaDatabase,
  FaFilePdf,
  FaEye,
  FaCamera,
  FaCameraRetro,
  FaUniversity,
  FaTicketAlt,
  FaSignature,
  FaSign,
  FaSignOutAlt
} from "react-icons/fa";
import DashBoardCard from "./DashboardCard";
import CryptoJS from 'crypto-js';
import { CAN_ACCESS_APPLICATION, EC_MSG_OFFICER_INFORMATION_NOT_MATCH, RELEASE_ON_RECOG, ROLE_AGENCY_ADMIN, ROLE_POLICE_OFFICER, USER_TYPES, USE_OFFICERS_FROM_IUCS } from "../../constants/common";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { downloadUserManualById } from "../../redux/slice/userManualSlice";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { RiInformationFill } from "react-icons/ri";
import { getIncompleteSearchWarrantCount, updateElementValueDashboardReducer, useDashboardReducer } from "../../redux/slice/dashboardSlice";
import ConfirmPopup from "../../components/common/ConfirmPopup";
import { useComplaintReducer, getSyncIucsData, getOfficerListForLoggedInUser, getUpdatesEcOfficersFromIucs } from "../../redux/slice/complaintSlice";
import { resetCodeTableEntryData } from "../../redux/slice/codeTableEntrySlice";
import { listPendingSearchDocuments, updateElementValueInSearchWarrantListReducer } from "../../redux/slice/searchWarrantListSlice";
import { getAgencyAdminNameByAgency } from "../../redux/slice/agencyListSlice";
import OptionPopup from "../common/OptionsPopup";
import { getLoggedInUserCounties } from "../../redux/slice/countyListSlice";
import { TwoFactorAuthenticationPopup } from "../user/TwoFactorAuthenticationPopup";
import { TwoFactorAuthenticationRegisterPopup } from "../user/TwoFactorAuthenticationRegisterPopup";
import { fetchEcitationAdminSession, fetchEcitationSession } from "../../utils/common";
import useToken from "../../hooks/useToken";
import EcitationCard from "./EcitationCard";
import { handleUnloadMessage } from "../common/CommonUtil";
import Cookies from "js-cookie";
import EcitationAdminCard from "./EcitationAdminCard";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loginReducer = useLoginReducer();
  const complaintReducer = useComplaintReducer();
  const dashboardReducer = useDashboardReducer();
  const dispatch = useAppDispatch();
  const isTokenValueAvailable = useToken();
  const [confirmPopupData, setconfirmPopupData] = React.useState({
    isConfirm: false,
    clickButtonName: "",
  });
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const [confirmOriginPlace, setconfirmOriginPlace] = React.useState<React.ReactNode>();
  const [showIUCSwarning, setShowIUCSwarning] = React.useState(false);
  const [content, setContent] = React.useState<React.ReactNode>();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const handleUserManualClick = (id: number) => () => {
    dispatch(downloadUserManualById(id));
  };
  const handleElectronicOrderClick = () => {
    alert("Not implemented Electronic Order");
  };
  const continueOnSyncIucsData = () => {
    dispatch(getSyncIucsData())
    setconfirmPopupData({ isConfirm: false, clickButtonName: "" });

  }
  const handleSyncIucsData = () => {
    let confirmMsg = "This will run as a background process and it may take several minutes. Do you really want to proceed?";
    setconfirmText(confirmMsg);
    setconfirmOriginPlace("SyncIucsDataClick")
    setconfirmPopupData({ isConfirm: true, clickButtonName: "onSyncIucsData" });
  }
  const handleConfirmation = () => {
    if (confirmPopupData.clickButtonName === "onSyncIucsData") {
      if (confirmOriginPlace === "SyncIucsDataClick") {
        continueOnSyncIucsData()
      }

    }

  }
const hasEcitations = () => {
  const { userType, county,ecUserAgency } = loginReducer;
  if (USER_TYPES["POLICE OFFICER"] === userType || USER_TYPES["AGENCY ADMIN"] === userType) {
    if(ecUserAgency.ecitationKey)
    {
      return true
    }
  } else if (USER_TYPES["COUNTY ADMIN"] === userType && county === 'DUPAGE') {
    return true;
  }

  return false;
};
const hasEcitationJobCardOnly = () => {
  const { userType, county,ecUserAgency } = loginReducer;
  if (USER_TYPES["ADMINISTRATOR"] === userType) {
      return true
  } else{
    return false
  
  } 
};
  // const handleEcitationClick = async () =>{
  //   const url = 'user/login';
  //   const method = 'POST';
  //   const body =  {
  //     userName : loginReducer.username,
  //     securityKey: process.env.REACT_APP_ECITATION_SECURITY_KEY,
  //     userProfileId:loginReducer.userProfileId,
  //     firstName:loginReducer.firstName,
  //     lastName:loginReducer.lastName,
  //     badgeNumber:loginReducer.badgeNumber
  // }
  //   const response = await fetchEcitationSession(url,method,body)
  //   const redirectUrl = response.data.redirectUrl
  //   window.location.href = redirectUrl
  // }
  // const handleEcitationClick = async () => {
  //   let icsToken = Cookies.get("token");
  //   window.removeEventListener("beforeunload", handleUnloadMessage);
  //   const url = 'user/login';
  //   const method = 'POST';
    
  //   const body = {
  //     userName: loginReducer.username.trim(),
  //     securityKey: process.env.REACT_APP_ECITATION_SECURITY_KEY,
  //     userProfileId:loginReducer?.userProfileId,
  //     firstName: loginReducer.firstName.trim(),
  //     lastName: loginReducer.lastName.trim(),
  //     badgeNumber: loginReducer.badgeNumber,
  //     agencyCode: loginReducer?.ecUserAgency ? loginReducer?.ecUserAgency?.agencyCode : null,
  //     agencyOri: loginReducer?.ecUserAgency ? loginReducer?.ecUserAgency?.agencyOri : null,
  //     icstoken: icsToken ? icsToken : null
  //   };
  //   try {
  //     const response = await fetchEcitationSession(url, method, body);

  //     if (response && response?.code === 0 && response?.data && response?.data?.redirectUrl) {
  //       const redirectUrl = response.data.redirectUrl;
  //       window.location.href = redirectUrl;
  //     } else {
  //       // Handle the case where response or its properties are undefined
  //       showToastMessage('There is an issue while accessing the eCitation. Please try again after sometime.');
  //     }
  //   } catch (error: any) {
  //     // Pass the error message to showToastMessage
  //     showToastMessage(error.message || 'There is an issue while accessing the eCitation. Please try again after sometime.');
  //   }
  //   finally {
  //     // Re-add the event listener after the click event handling is done
  //      window.addEventListener("beforeunload", handleUnloadMessage);
  //   }
  // };
  
  class AesUtil {
    private keySize: number;
    private iterationCount: number;

    constructor(keySize: number, iterationCount: number) {
        this.keySize = keySize / 32;
        this.iterationCount = iterationCount;
    }

    private generateKey(salt: string, passPhrase: string | CryptoJS.lib.WordArray) {
        return CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
            keySize: this.keySize,
            iterations: this.iterationCount
        });
    }

    public encrypt(salt: string, iv: string, passPhrase: any, plainText: string | CryptoJS.lib.WordArray) {
        const key = this.generateKey(salt, passPhrase);
        return CryptoJS.AES.encrypt(plainText, key, { iv: CryptoJS.enc.Hex.parse(iv) });
    }

    public decrypt(salt: string, iv: string, passPhrase: any, ciphertext: string | CryptoJS.lib.CipherParams) {
        const key = this.generateKey(salt, passPhrase);
        const decrypted = CryptoJS.AES.decrypt(ciphertext, key, { iv: CryptoJS.enc.Hex.parse(iv) });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}

// Your handleEcitationClick function
const handleEcitationClick = async () => {
  let icsToken = Cookies.get("token");
  window.removeEventListener("beforeunload", handleUnloadMessage);
  const url = 'user/login';
  const method = 'POST';
  
  const aesUtil = new AesUtil(128, 1000);
  const iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
  const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

  const body = {
      userName: loginReducer.username.trim(),
      securityKey: process.env.REACT_APP_ECITATION_SECURITY_KEY,
      userProfileId: loginReducer?.userProfileId,
      firstName: loginReducer.firstName.trim(),
      lastName: loginReducer.lastName.trim(),
      badgeNumber: loginReducer.badgeNumber,
      agencyCode: loginReducer?.ecUserAgency ? loginReducer?.ecUserAgency?.agencyCode : null,
      agencyOri: loginReducer?.ecUserAgency ? loginReducer?.ecUserAgency?.agencyOri : null,
      icstoken: icsToken ? icsToken : null
  };

  try {
      const ciphertext = aesUtil.encrypt(salt, iv, process.env.REACT_APP_ECITATION_PASSPHRASE, JSON.stringify(body));
      
      
      const encryptedBody = {
          eci: iv,
          ecs: salt,
          ecRequest: ciphertext.toString()
      };

      let response = await fetchEcitationSession(url, method, encryptedBody);

      // if (response && response?.code === 0 && response?.data && response?.data?.redirectUrl) {
      //     const redirectUrl = response.data.redirectUrl;
      //     window.location.href = redirectUrl;
      // } else {
      //     showToastMessage('There is an issue while accessing the eCitation. Please try again after sometime.');
      // }
      if (response && response?.eci && response?.ecs && response?.ecResponse) {
        const { eci, ecs, ecResponse } = response;
        const decryptedData = aesUtil.decrypt(ecs, eci, process.env.REACT_APP_ECITATION_PASSPHRASE, ecResponse);
        response = JSON.parse(decryptedData);
    }

    if (response?.code === 0 && response?.data?.redirectUrl) {
        const redirectUrl = response.data.redirectUrl;
        window.location.href = redirectUrl;
    } else {
        showToastMessage(response.message||'There is an issue while accessing the eCitation. Please try again after sometime.');
    }
  } catch (error: any) {
      showToastMessage(error.message || 'There is an issue while accessing the eCitation. Please try again after sometime.');
  } finally {
      window.addEventListener("beforeunload", handleUnloadMessage);
  }
};

  // const handleEcitationAdminClick = async () => {
  //   let icsToken = Cookies.get("token");
  //   window.removeEventListener("beforeunload", handleUnloadMessage);
  //   const url = 'user/login';
  //   const method = 'POST';
    
  //   const body = {
  //     userName: loginReducer.username,
  //     securityKey: process.env.REACT_APP_ECITATION_ADMIN_SECURITY_KEY,
  //     userProfileId:loginReducer?.userProfileId,
  //     firstName: loginReducer.firstName,
  //     lastName: loginReducer.lastName,
  //     badgeNumber: loginReducer.badgeNumber,
  //     agencyCode: loginReducer?.ecUserAgency ? loginReducer?.ecUserAgency?.agencyCode : null,
  //     agencyOri: loginReducer?.ecUserAgency ? loginReducer?.ecUserAgency?.agencyOri : null,
  //     icstoken: icsToken ? icsToken : null
  //   };
  //   try {
  //     const response = await fetchEcitationAdminSession(url, method, body);

  //     if (response && response?.code === 0 && response?.data && response?.data?.redirectUrl) {
  //       const redirectUrl = response.data.redirectUrl;
  //       window.location.href = redirectUrl;
  //     } else {
  //       // Handle the case where response or its properties are undefined
  //       showToastMessage('There is an issue while accessing the eCitation. Please try again after sometime.');
  //     }
  //   } catch (error: any) {
  //     // Pass the error message to showToastMessage
  //     showToastMessage(error.message || 'There is an issue while accessing the eCitation. Please try again after sometime.');
  //   }
  //   // finally {
  //   //   // Re-add the event listener after the click event handling is done
  //   //    window.addEventListener("beforeunload", handleUnloadMessage);
  //   // }
  // };
  const handleEcitationAdminClick = async () => {
    let icsToken = Cookies.get("token");
    window.removeEventListener("beforeunload", handleUnloadMessage);
    const url = 'user/login';
    const method = 'POST';
    
    const aesUtil = new AesUtil(128, 1000);
    const iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

    const body = {
        userName: loginReducer.username.trim(),
        securityKey: process.env.REACT_APP_ECITATION_ADMIN_SECURITY_KEY,
        userProfileId: loginReducer?.userProfileId,
        firstName: loginReducer.firstName.trim(),
        lastName: loginReducer.lastName.trim(),
        badgeNumber: loginReducer.badgeNumber,
        agencyCode: loginReducer?.ecUserAgency ? loginReducer?.ecUserAgency?.agencyCode : null,
        agencyOri: loginReducer?.ecUserAgency ? loginReducer?.ecUserAgency?.agencyOri : null,
        icstoken: icsToken ? icsToken : null
    };

    try {
        const ciphertext = aesUtil.encrypt(salt, iv, process.env.REACT_APP_ECITATION_PASSPHRASE, JSON.stringify(body));
        const encryptedBody = {
            eci: iv,
            ecs: salt,
            ecRequest: ciphertext.toString()
        };

        let response = await fetchEcitationAdminSession(url, method, encryptedBody);

        if (response && response?.eci && response?.ecs && response?.ecResponse) {
            const { eci, ecs, ecResponse } = response;
            const decryptedData = aesUtil.decrypt(ecs, eci, process.env.REACT_APP_ECITATION_PASSPHRASE, ecResponse);
            response = JSON.parse(decryptedData);
        }

        if (response?.code === 0 && response?.data?.redirectUrl) {
            const redirectUrl = response.data.redirectUrl;
            window.location.href = redirectUrl;
        } else {
            showToastMessage(response.message||'There is an issue while accessing the eCitation. Please try again after sometime.');
        }
      
    } catch (error: any) {
        showToastMessage(error.message || 'There is an issue while accessing the eCitation. Please try again after sometime.');
    } finally {
        window.addEventListener("beforeunload", handleUnloadMessage);
    }
};


  const showToastMessage = (message: any) => {

    setToastMessage(message);
    setShowToast(true);
  };

  const [showTwoFactorAuthenicationModal, setShowTwoFactorAuthenicationModal] = React.useState(false);
  const [showTwoFactorRegistrationModal, setShowTwoFactorRegistrationModal] = React.useState(false);

  const getCookie = (name: string): string | null => {
    const cookieValue = document.cookie
      .split(';')
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith(`${name}=`));

    if (cookieValue) {
      return cookieValue.split('=')[1];
    }

    return null;
  };

  const setCookie = (name: string, value: number, days: number) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    const expires = `expires=${expirationDate.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  useEffect(() => {
    localStorage.clear();
    const AuthCookieValue = getCookie('TwoFactorAuthentication:' + loginReducer.userProfileId);
    const RegCookieValue = getCookie('TwoFactorRegistration:' + loginReducer.userProfileId);
    if (AuthCookieValue !== loginReducer.userProfileId.toString()) {
      if (loginReducer.twoFactorEnable) {
        setShowTwoFactorAuthenicationModal(true);
      } else {
        if (RegCookieValue !== loginReducer.userProfileId.toString()) {
          setShowTwoFactorRegistrationModal(true);
          setCookie('TwoFactorRegistration:' + loginReducer.userProfileId, loginReducer.userProfileId, parseInt(loginReducer.twoFactorRegPopupExpireDays));
        }
      }
    }
  }, []);

  useEffect(() => {
    dispatch(getIncompleteSearchWarrantCount());
    dispatch(resetCodeTableEntryData());
    dispatch(getLoggedInUserCounties());
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "localCounty",
        value: "-- ALL --",
      })
    )
    dispatch(
      updateElementValueInSearchWarrantListReducer({
        elementName: "localAgency",
        value: "-- ALL --",
      })
    )
  }, [dispatch]);

  const clickSearchWarrantListBywarrntIds = (pendingSWCount: string, searchWarrantIds: string) => {
    dispatch(updateElementValueDashboardReducer({ elementName: "pendingSWCount", value: pendingSWCount }));
    dispatch(updateElementValueDashboardReducer({ elementName: "searchWarrantIds", value: [searchWarrantIds] }));
    dispatch(updateElementValueDashboardReducer({ elementName: "previousLink", value: "dashboard" }));
    dispatch(updateElementValueDashboardReducer({ elementName: "isCardActive", value: true }));
    navigate("WarrantList");
  }

  let notificationList: any = [];
  dashboardReducer.incompleteSearchWarrantCountList instanceof Array && dashboardReducer.incompleteSearchWarrantCountList.forEach((items, i) => {
    if (!items.split("-")[0].includes("null")) {
      notificationList.push(
        <div key={i} className="d-flex align-items-center text-primary notification">
          <RiInformationFill />
          <div className="ps-2"
            onClick={() => clickSearchWarrantListBywarrntIds(items.split("-")[1], items.split("-")[2])}>{items.split("-")[0]}</div>
        </div>
      );
    }
  });

  const onCardClick = async (cardName: string, isActive: boolean) => {
    if (cardName === "Long Form Complaints") {
      if ((loginReducer.userType === ROLE_POLICE_OFFICER || loginReducer.userType === ROLE_AGENCY_ADMIN)) {
        let agencyAdminList = await dispatch(getAgencyAdminNameByAgency({ agencyId: loginReducer.ecUserAgency.agencyId }));
        let officerList = await dispatch(getOfficerListForLoggedInUser());
        let policeOfficerObject = officerList ? officerList.payload["EC POLICE OFFICER OBJECT"] : null;
        if (agencyAdminList.payload) {
          if ((policeOfficerObject !== null && policeOfficerObject.syncStatus === USE_OFFICERS_FROM_IUCS) &&
            (policeOfficerObject.officerId === null || policeOfficerObject.officerId === 0)) {
            officerList = await dispatch(getUpdatesEcOfficersFromIucs());
            policeOfficerObject = officerList ? officerList.payload["EC POLICE OFFICER OBJECT"] : null;
            if ((policeOfficerObject !== null && policeOfficerObject.syncStatus === USE_OFFICERS_FROM_IUCS) &&
              (policeOfficerObject.officerId === null || policeOfficerObject.officerId === 0)) {
              let index = 1;
              setShowIUCSwarning(true);
              setContent(
                <>
                  <div className="text-start">{EC_MSG_OFFICER_INFORMATION_NOT_MATCH}</div>
                  <br></br>
                  <div>{agencyAdminList.payload.map((data: any) => (
                    <div className="text-start">{index++ + ". " + data.firstName + " " + data.lastName}<br></br></div>
                  ))}
                  </div>
                </>
              )
            }
            else {
              onContinueLFclick();
            }
          } else {
            onContinueLFclick();
          }
        }
      } else {
        onContinueLFclick();
      }
    } 
    // PR-11312 START
    else if(cardName === RELEASE_ON_RECOG){
      navigate("/SearchEComplaint");
      dispatch(updateElementValueDashboardReducer({ elementName: "cardName", value: RELEASE_ON_RECOG }));
      dispatch(updateElementValueDashboardReducer({ elementName: "isCardActive", value: true }));
    }
    // PR-11312 END
    else {
      dispatch(updateElementValueDashboardReducer({ elementName: "previousLink", value: "dashboard" }));
      dispatch(updateElementValueDashboardReducer({ elementName: "cardName", value: cardName }));
      dispatch(updateElementValueDashboardReducer({ elementName: "isCardActive", value: isActive }));
    }

    dispatch(updateElementValueDashboardReducer({ elementName: "searchWarrantIds", value: [] }));
  }

  const onContinueLFclick = () => {
    setShowIUCSwarning(false);
    navigate("/SearchEComplaint");
    dispatch(updateElementValueDashboardReducer({ elementName: "cardName", value: "Long Form Complaints" }));
    dispatch(updateElementValueDashboardReducer({ elementName: "isCardActive", value: true }));
  }

  return (
    <>
      <Header headerName="DashBoard" />
      <div className="main-body p-3">
        {notificationList.length > 0 ?
          <Row>
            <Col className="mx-auto">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header><b>Notification</b></Accordion.Header>
                  <Accordion.Body>
                    {notificationList}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row> : ""}
        <Row>
          <DashBoardCard
            link="/UserList"
            icon={<FaUser />}
            title="User Maintenance"
            token={CAN_ACCESS_APPLICATION.USER_MAINTEN}
          />
          <DashBoardCard
            icon={<FaUniversity />}
            title="County Maintenance"
            link={
              loginReducer.userType === USER_TYPES["COUNTY ADMIN"]
                ? "/ViewCounty/" + loginReducer.countyId
                : "/CountyList"
            }
            token={CAN_ACCESS_APPLICATION.COUNTY_MAINTEN}
          />
          <DashBoardCard
            icon={<FaBuilding />}
            title="Agency Maintenance"
            link="/AgencyList"
            token={[
              CAN_ACCESS_APPLICATION.AGENCY_MAINTEN,
              CAN_ACCESS_APPLICATION.AGENCY_LIST,
            ]}
          />
          <DashBoardCard
            icon={<FaFile />}
            title="Long Form Complaints"
            hide={!loginReducer.longFormEnable}
            token={CAN_ACCESS_APPLICATION.ECOMPLAINT_LIST}
            onClick={() => onCardClick("Long Form Complaints", true)}
          />
          {/*  PR-11312 START*/}
          <DashBoardCard
            icon={<FaFile />}
            title={<>{RELEASE_ON_RECOG}<span style={{color: 'red'}}>{" (New)"}</span></>}
            hide={!loginReducer.longFormEnable}
            token={CAN_ACCESS_APPLICATION.RELEASE_ON_RECOG}
            onClick={() => onCardClick(RELEASE_ON_RECOG, true)}
            tooltip="Use this link to file a Release on Recog form on any arrest warrant issued through this system."
          />
          {/*  PR-11312 END*/}
          <DashBoardCard
            icon={<FaList />}
            title={loginReducer.summonsToAppear ? "Warrants/Summons List" : "Warrant List"}
            link={loginReducer.summonsToAppear ? "/WarrantsSummonsList" : "/WarrantList"}
            token={CAN_ACCESS_APPLICATION.WARRANT_LIST}
            onCardClick={() => onCardClick(loginReducer.summonsToAppear ? "Warrants/Summons List" : "Warrant List", true)}
          />
          <DashBoardCard
            icon={<FaList />}
            title="Search Warrants"
            link="/WarrantList"
            token={CAN_ACCESS_APPLICATION.SEARCH_WARRANTS}
            onCardClick={() => onCardClick("Search Warrants", true)}
          />
          <DashBoardCard
            icon={<FaBook />}
            title="Template List"
            link="/TemplateList"
            token={CAN_ACCESS_APPLICATION.TEMPLATE_MAINTEN}
          />
          <DashBoardCard
            icon={<FaDatabase />}
            title="Sync IUCS Data"
            onClick={handleSyncIucsData}
            //link="/SyncIucsData"
            token={CAN_ACCESS_APPLICATION.SYNC_IUCS_DATA}
          />
          <DashBoardCard
            icon={<FaFilePdf />}
            title="Electronic Orders"
            onClick={handleElectronicOrderClick}
            token={CAN_ACCESS_APPLICATION.EORDERS}
          />
          {loginReducer.userManualList &&
            loginReducer.userManualList.map((manual: any) => (
              <DashBoardCard
                icon={<FaFilePdf />}
                title={manual.documentName}
                onClick={handleUserManualClick(manual.userManualId)}
                token={CAN_ACCESS_APPLICATION.LONGFORM_USER_MANUAL}
                key={manual.userManualId}
              />
            ))}

          <DashBoardCard
            icon={<FaEye />}
            title="Active User Sessions"
            link="/ActiveUserSessionsList"
            token={CAN_ACCESS_APPLICATION.ACTIVE_USER_SESSIONS}
          />
          <DashBoardCard
            icon={<FaCamera />}
            title="Test Webcam"
            link="/TestWebCam"
            token={CAN_ACCESS_APPLICATION.TEST_WEBCAM}
          />
          <DashBoardCard
            icon={<FaCameraRetro />}
            title="Remote Hearing"
            link="/remoteHearing"
            token={CAN_ACCESS_APPLICATION.REMOTE_HEARING}
          />
          <DashBoardCard
            icon={<FaBook />}
            title="Statutes"
            link="/StatutesList"
            token={CAN_ACCESS_APPLICATION.EC_VIOLATION_LIST}
          />
          <EcitationCard
            icon={<FaTicketAlt />}
            hide={!hasEcitations()}
            title="eCitation"
            onClick={handleEcitationClick}
          />
          <EcitationAdminCard
            // icon={<FaTicketAlt />}
            hide={!hasEcitationJobCardOnly()}
            title="eCitation Admin"
            onClick={handleEcitationAdminClick}
          />
        </Row>
      </div>
      <Outlet />
      <ConfirmPopup
        children={confirmText}
        show={confirmPopupData.isConfirm}
        onHide={() => setconfirmPopupData({ isConfirm: false, clickButtonName: "" })}
        isConfirmation={handleConfirmation}
      />
      <OptionPopup
        show={showIUCSwarning}
        header={"Warning"}
        children={content}
        classStyle={""}
        questionMarkVisible={true}
        buttons={[{ name: "OK", variant: "primary", onClick: () => onContinueLFclick() },
        { name: "Cancel", variant: "danger", onClick: () => setShowIUCSwarning(false) }]}
      />
      <TwoFactorAuthenticationPopup
        show={showTwoFactorAuthenicationModal}
        onHide={() => setShowTwoFactorAuthenicationModal(false)}
      />
      <TwoFactorAuthenticationRegisterPopup
        show={showTwoFactorRegistrationModal}
        onHide={() => setShowTwoFactorRegistrationModal(false)}
      />
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        style={{
          position: 'fixed',
          top: '0', // Adjust the top position to place it at the very top
          right: '0',
          zIndex: '9999', // Ensure it's on top of other elements
        }}
      >
        <Toast.Header className="bg-danger text-white">
          <strong className="mr-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </>
  );
};

export default Dashboard;
