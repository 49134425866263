import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import {
  OFFENSE_INFO_HEADER,
  PERSON_OF_HEADER,
  POLICE_OFFICER_INFO_HEADER,
  SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER,
  SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT,
  SEARCH_WAR_TEMPLATE_TYPE_ORDER,
  SEIZED_INFO_HEADER,
  PROOF_OF_SERVICE_PERSON_AND_PREMISES,
  PROOF_OF_SERVICE_PERSON_AND_PREMISES_LANGUAGE,
  INVENTORY_PERSON_AND_PREMISES,
  INVENTORY_PERSON_AND_PREMISES_LANGUAGE,
  FIELDS_COMPLAINT_ORDER,
  FIELDS_APPLICATION_ORDER,
  FIELDS_BLANK_ORDER,
  PERSON_OF_HEADER_PREFIX,
  PERSON_OF_HEADER_SUFFIX,
  OFFENSE_INFO_HEADER_SUFFIX,
  POLICE_OFFICER_INFO_SUFFIX,
  IMPOUND_LANGUAGE,
  COMPLAINT_HEADER_PREFIX,
  WARRANT_FIELD01_PREFIX,
  WARRANT_FIELD02_PREFIX,
  WARRANT_HEADER03_PREFIX,
  WARRANT_ORDER_COMMAND,
  COMPLAINT_TITLE,
  SEARCH_WARRANT_TITLE,
  COMPLAINT_IMPOUND_LANG,
} from "../../constants/common";
import { showHideLoadingGif } from "./commonSlice";
import { getTemplateFieldValueByFieldName } from "../../utils/common";
import { templateListData } from "./templateListSlice";

interface templateState {
  swTypeList: string[];
  warrantTemplateList: string[];
  //templateList: string[];
  templateListByCounty: string[];
  templateError?: string;
  // currentPage: number;
  // totalPages: number;
  // totalRecords: number;

  templateId: number;
  templateCountyId: number;
  templateName: string;
  templateType: string;
  personOfHeader: string;
  personOf: string;
  offenseInfoHeader: string;
  offenseInfo: string;
  seizedInfoHeader: string;
  seizedInfo: string;
  policeOfficerInfoHeader: string;
  policeOfficerInfo: string;
  pOSPrsnPremisesText: string;
  pOSPrsnPremisesPrint: string;
  invntryPrsnPrmsTxt: string;
  invntryPrsnPrmsPrint: string;

  // PR 0011324 - START
  personOfHeaderPrefix: string;
  personOfHeaderSuffix: string;
  offenseInfoHeaderSuffix: string;
  policeOfficerInfoHeaderSuffix: string;
  impoundLanguage: string;
  impoundComplaintLanguage: string;
  complaintHeaderPrefix: string;
  warrantField1Prefix: string;
  warrantField2Prefix: string;
  warrantHeader3Prefix: string;
  warrantOrderCommand: string;
  complaintTitle: string;
  swTitle: string;
  // PR 0011324 - END

  templateApplicationCountyId: number;
  applicationTitle: string;
  applicationInfo: string;
  orderTitle: string;
  orderInfo: string;

  templateBlankCountyId: number;
  // blankOrderTitle: string;
  // blankOrderInfo: string;

  successMessage?: string;
  status: LoadingType;

  countyId : number;
  template : string;
  listStatus : string;
}

const initialState = {
  swTypeList: [],
  warrantTemplateList: [],
  //templateList: [],
  templateListByCounty: [],
  currentPage: 1,
  totalPages: 1,
  totalRecords: 0,
  templateError: undefined,
  status: "idle",

  templateId: 0,
  templateCountyId: 0,
  templateName: "",
  templateType: "",
  personOfHeader: "",
  personOf: "",
  offenseInfoHeader: "",
  offenseInfo: "",
  seizedInfoHeader: "",
  seizedInfo: "",
  policeOfficerInfoHeader: "",
  policeOfficerInfo: "",
  pOSPrsnPremisesText: "",
  pOSPrsnPremisesPrint: "",
  invntryPrsnPrmsTxt: "",
  invntryPrsnPrmsPrint: "",

  // PR 0011324 - START
  personOfHeaderPrefix: "",
  personOfHeaderSuffix: "",
  offenseInfoHeaderSuffix: "",
  policeOfficerInfoHeaderSuffix: "",
  impoundLanguage: "",
  complaintHeaderPrefix: "",
  warrantField1Prefix: "",
  warrantField2Prefix: "",
  warrantHeader3Prefix: "",
  warrantOrderCommand: "",
  complaintTitle: "",
  swTitle: "",
  impoundComplaintLanguage: "",
  // PR 0011324 - END

  templateApplicationCountyId: 0,
  applicationTitle: "",
  applicationInfo: "",
  orderTitle: "",
  orderInfo: "",

  templateBlankCountyId: 0,
  // blankOrderTitle: "",
  // blankOrderInfo: "",

  successMessage: undefined,

  countyId : 0,
  template : "",
  listStatus :""
} as templateState;

export const getLoggedInUserWarrantTemplates = createAsyncThunk<
  any,
  undefined,
  {
    state: RootState;
  }
>(
  "users/loggedInUserWarrantTemplates",
  async (_, { getState, rejectWithValue, dispatch }) => {
    dispatch(showHideLoadingGif(true));
    try {
      let { token } = getState().login;
      const reponse = await doFetch({
        url: `templateservices/getLoggedInUserWarrantTemplates`,
        token,
      });
      dispatch(showHideLoadingGif(false));
      return await reponse.json();
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);

// export const listTemplate = createAsyncThunk<
//   any,
//   {
//     countyId?: number;
//     templateName?: string;
//     status?: string;
//     stateId?: number;
//     pageNo?: number;
//   },
//   {
//     state: RootState;
//   }
// >(
//   "template/listTemplate",
//   async (data, { getState, rejectWithValue, dispatch }) => {
//     const { countyId, templateName, status = "all", pageNo = 0 } = data;

//     let queryParam = `pageNo=${pageNo}&status=${status}`;
//     if (countyId) queryParam += `&countyIdParam=${countyId}`;
//     if (templateName) queryParam += `&templateName=${templateName}`;

//     dispatch(showHideLoadingGif(true));
//     try {
//       let { token } = getState().login;
//       const response = await doFetch({
//         url: `templateservices/warrantTemplates?${queryParam}`,
//         token,
//       });
//       if (response.ok) {
//         dispatch(showHideLoadingGif(false));
//         return response.json();
//       } else {
//         dispatch(showHideLoadingGif(false));
//         return rejectWithValue(response.status);
//       }
//     } catch (error) {
//       dispatch(showHideLoadingGif(false));
//       return rejectWithValue(error);
//     }
//   }
// );

export const updateTemplateStatus = createAsyncThunk<
  any,
  { templateId: number; status: boolean, listViewData: any },
  { state: RootState; rejectValue: string }
>(
  "template/updateTemplateStatus",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: `templateservices/updateTemplateStatus/${data.templateId}?status=${data.status}`,
        token,
        type: "PUT",
      });
      dispatch(templateListData(data.listViewData));
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const id = await response.text();
        return {
          message: `User ${id} status updated successfully`,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while updating template status");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while updating template status"
      );
    }
  }
);

export const getTemplateDataById = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: string }
>(
  "template/getTemplateDataById",
  async (templateId, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: `templateservices/warrantTemplates/${templateId}`,
        token,
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while fetching template data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching template Data"
      );
    }
  }
);

export const createUpdateTemplate = createAsyncThunk<
  any,
  { templateObj: any; fetchType: any; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "template/createUpdateTemplate",
  async (
    { templateObj, fetchType, navigate },
    { getState, rejectWithValue, dispatch }
  ) => {
    dispatch(showHideLoadingGif(true));
    console.log(templateObj)
    try {
      let { token } = getState().login;
      const response = await doFetch({
        url: "templateservices/warrantTemplates",
        token,
        type: fetchType,
        body: templateObj,
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const createdTemplateName = await response.text();
        navigate(-1);
        return {
          message: `${createdTemplateName} template created successfully`,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while creating template");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while creating template");
    }
  }
);

export const listSearchWarrantType = createAsyncThunk<
  any,
  {
    countyId?: number;
    templateType?: string;
  },
  {
    state: RootState;
  }
>(
  "searchWarrant/listSearchWarrantType",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { countyId, templateType } = data;

    let { token } = getState().login;

    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url:
          "templatetypeservices/getSearchWarrantTypeList/" +
          countyId +
          "/" +
          templateType,
        token,
      });
      dispatch(showHideLoadingGif(false));
      return await reponse.json();
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);

export const getWarrantTemplateListWithData = createAsyncThunk<
  any,
  {
    countyId?: number;
    templateType?: string;
  },
  {
    state: RootState;
  }
>(
  "searchWarrant/getWarrantTemplateListWithData",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { countyId, templateType } = data;

    let { token } = getState().login;
    let queryParam = `jwtToken=Bearer ${token}`;

    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url:
          "templatetypeservices/getWarrantTemplateListWithData/" +
          countyId +
          "/" +
          templateType +
          `?${queryParam}`,
      });
      dispatch(showHideLoadingGif(false));
      return await reponse.json();
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);

const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    updateElementValuetemplateReducer: updateElementValue,
    defaultValue: (state) => {
      state.personOfHeader = PERSON_OF_HEADER;
      state.offenseInfoHeader = OFFENSE_INFO_HEADER;
      state.seizedInfoHeader = SEIZED_INFO_HEADER;
      state.policeOfficerInfoHeader = POLICE_OFFICER_INFO_HEADER;
      state.pOSPrsnPremisesText = PROOF_OF_SERVICE_PERSON_AND_PREMISES;
      state.pOSPrsnPremisesPrint =
        PROOF_OF_SERVICE_PERSON_AND_PREMISES_LANGUAGE;
      state.invntryPrsnPrmsTxt = INVENTORY_PERSON_AND_PREMISES;
      state.invntryPrsnPrmsPrint = INVENTORY_PERSON_AND_PREMISES_LANGUAGE;

      // PR 0011324 - START
      state.personOfHeaderPrefix = PERSON_OF_HEADER_PREFIX;
      state.personOfHeaderSuffix = PERSON_OF_HEADER_SUFFIX;
      state.offenseInfoHeaderSuffix = OFFENSE_INFO_HEADER_SUFFIX;
      state.policeOfficerInfoHeaderSuffix = POLICE_OFFICER_INFO_SUFFIX;
      state.impoundLanguage = IMPOUND_LANGUAGE;
      state.complaintHeaderPrefix = COMPLAINT_HEADER_PREFIX;
      state.warrantField1Prefix = WARRANT_FIELD01_PREFIX;
      state.warrantField2Prefix = WARRANT_FIELD02_PREFIX;
      state.warrantHeader3Prefix = WARRANT_HEADER03_PREFIX;
      state.warrantOrderCommand = WARRANT_ORDER_COMMAND;
      state.complaintTitle = COMPLAINT_TITLE;
      state.swTitle = SEARCH_WARRANT_TITLE;
      state.impoundComplaintLanguage = COMPLAINT_IMPOUND_LANG;
      // PR 0011324 - END
    },
    resetTemplateData: (state: templateState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLoggedInUserWarrantTemplates.pending, (state) => {
        state.status = "loading";
        state.templateError = undefined;
      })
      .addCase(getLoggedInUserWarrantTemplates.fulfilled, (state, action) => {
        state.status = "success";
        state.templateListByCounty = action.payload;
      })
      .addCase(getLoggedInUserWarrantTemplates.rejected, (state, action) => {
        state.status = "error";
        state.templateError = undefined;
      })

      .addCase(listSearchWarrantType.pending, (state) => {
        state.status = "loading";
        state.templateError = undefined;
      })
      .addCase(listSearchWarrantType.fulfilled, (state, action) => {
        state.status = "success";
        state.swTypeList = action.payload;
      })
      .addCase(listSearchWarrantType.rejected, (state, action) => {
        state.status = "error";
        state.templateError = undefined;
      })

      .addCase(getWarrantTemplateListWithData.pending, (state) => {
        state.status = "loading";
        state.templateError = undefined;
      })
      .addCase(getWarrantTemplateListWithData.fulfilled, (state, action) => {
        state.status = "success";
        state.warrantTemplateList = action.payload;
      })
      .addCase(getWarrantTemplateListWithData.rejected, (state, action) => {
        state.status = "error";
        state.templateError = undefined;
      })

      //updateTemplateStatus
      .addCase(updateTemplateStatus.pending, (state) => {
        state.status = "loading";
        state.templateError = undefined;
      })
      .addCase(updateTemplateStatus.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(updateTemplateStatus.rejected, (state, action) => {
        state.status = "error";
        state.templateError = undefined;
      })

      //createUpdateTempltae
      .addCase(createUpdateTemplate.pending, (state) => {
        state.status = "loading";
        state.templateError = undefined;
      })
      .addCase(createUpdateTemplate.fulfilled, (state, action) => {
        state.status = "success";
        state.successMessage = action.payload.message;
      })
      .addCase(createUpdateTemplate.rejected, (state, action) => {
        state.status = "error";
        state.templateError = action.payload;
      })

      // // template List
      // .addCase(listTemplate.pending, (state) => {
      //   state.status = "loading";
      //   state.templateError = undefined;
      // })
      // .addCase(listTemplate.fulfilled, (state, action) => {
      //   state.status = "success";
      //   state.templateList = action.payload.warrantTemplateList;
      //   state.totalPages = action.payload.totalPages;
      //   state.totalRecords = action.payload.totalRecords;
      //   state.currentPage = action.payload.currentPage;
      // })
      // .addCase(listTemplate.rejected, (state, action) => {
      //   state.status = "error";
      //   state.templateError = undefined;
      // })

      //get template Data
      .addCase(getTemplateDataById.pending, (state) => {
        state.status = "loading";
        state.templateError = undefined;
      })
      .addCase(getTemplateDataById.fulfilled, (state, action) => {
        let data = action.payload;

        let templateFields = data.templateField;
        let templateValue = data.templateValue;
        let values: any[] = [];

        const feils =
          data.templateType === SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT
            ? FIELDS_COMPLAINT_ORDER
            : data.templateType === SEARCH_WAR_TEMPLATE_TYPE_ORDER
            ? FIELDS_APPLICATION_ORDER
            : data.templateType === SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER
            ? FIELDS_BLANK_ORDER
            : [];

        feils.map((el) => {
          return templateFields.map((field: any, i: number) => {
            if (field.toLowerCase() === el.toLowerCase()) {
              return values.push(templateValue[i]);
            }
          });
        });

        if (data.templateType === SEARCH_WAR_TEMPLATE_TYPE_COMPLAINT) {
          state.status = "success";
          state.templateId = data.templateId;
          state.templateName = data.templateName;
          state.templateType = data.templateType;
          state.templateCountyId = data.countyIdParam;

          state.personOf = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[0],
            templateFields,
            templateValue
          );
          state.personOfHeader =
            getTemplateFieldValueByFieldName(
              FIELDS_COMPLAINT_ORDER[1],
              templateFields,
              templateValue
            ) ||
            "Judicial Circuit, and request the issuance of a Search Warrant to search the person of and/or the place of and/or the item of";
          state.offenseInfo = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[2],
            templateFields,
            templateValue
          );
          state.offenseInfoHeader =
            getTemplateFieldValueByFieldName(
              FIELDS_COMPLAINT_ORDER[3],
              templateFields,
              templateValue
            ) ||
            "and therefrom seize the following instruments, articles and things which have been used in the commission of, or which constitute evidence of, the offense of";
          state.seizedInfo = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[4],
            templateFields,
            templateValue
          );
          state.seizedInfoHeader =
            getTemplateFieldValueByFieldName(
              FIELDS_COMPLAINT_ORDER[5],
              templateFields,
              templateValue
            ) || "To wit";
          state.policeOfficerInfo = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[6],
            templateFields,
            templateValue
          );
          state.policeOfficerInfoHeader =
            getTemplateFieldValueByFieldName(
              FIELDS_COMPLAINT_ORDER[7],
              templateFields,
              templateValue
            ) ||
            "Complainant states that there is probable cause to believe, based on the following facts, that the above listed to beseized are now located upon the person of and/or the place of and/or the item of set forth above.";
          state.pOSPrsnPremisesText = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[8],
            templateFields,
            templateValue
          );
          state.invntryPrsnPrmsTxt = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[9],
            templateFields,
            templateValue
          );
          state.pOSPrsnPremisesPrint = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[10],
            templateFields,
            templateValue
          );
          state.invntryPrsnPrmsPrint = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[11],
            templateFields,
            templateValue
          );

          // PR 0011324 - START
          state.personOfHeaderPrefix = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[12],
            templateFields,
            templateValue
          );
          state.personOfHeaderSuffix = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[13],
            templateFields,
            templateValue
          );
          state.offenseInfoHeaderSuffix = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[14],
            templateFields,
            templateValue
          );
          state.policeOfficerInfoHeaderSuffix = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[15],
            templateFields,
            templateValue
          );
          state.impoundLanguage = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[16],
            templateFields,
            templateValue
          );
          state.complaintHeaderPrefix = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[17],
            templateFields,
            templateValue
          );
          state.warrantField1Prefix = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[18],
            templateFields,
            templateValue
          );
          state.warrantField2Prefix = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[19],
            templateFields,
            templateValue
          );
          state.warrantHeader3Prefix = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[20],
            templateFields,
            templateValue
          );
          state.warrantOrderCommand = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[21],
            templateFields,
            templateValue
          );
          state.complaintTitle = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[22],
            templateFields,
            templateValue
          );
          state.swTitle = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[23],
            templateFields,
            templateValue
          );
          state.impoundComplaintLanguage = getTemplateFieldValueByFieldName(
            FIELDS_COMPLAINT_ORDER[24],
            templateFields,
            templateValue
          );
          // PR 0011324 - END
        } else if (data.templateType === SEARCH_WAR_TEMPLATE_TYPE_ORDER) {
          state.status = "success";
          state.templateId = data.templateId;
          state.applicationTitle = getTemplateFieldValueByFieldName(
            FIELDS_APPLICATION_ORDER[0],
            templateFields,
            templateValue
          );
          state.applicationInfo = getTemplateFieldValueByFieldName(
            FIELDS_APPLICATION_ORDER[1],
            templateFields,
            templateValue
          );
          state.orderTitle = getTemplateFieldValueByFieldName(
            FIELDS_APPLICATION_ORDER[2],
            templateFields,
            templateValue
          );
          state.orderInfo = getTemplateFieldValueByFieldName(
            FIELDS_APPLICATION_ORDER[3],
            templateFields,
            templateValue
          );
          state.templateApplicationCountyId = data.countyIdParam;
          state.templateName = data.templateName;
          state.templateType = data.templateType;
        } else if (data.templateType === SEARCH_WAR_TEMPLATE_TYPE_BLANK_ORDER) {
          state.status = "success";
          state.templateId = data.templateId;
          state.templateBlankCountyId = data.countyIdParam;
          state.templateName = data.templateName;
          state.templateType = data.templateType;
          state.orderTitle = getTemplateFieldValueByFieldName(
            FIELDS_BLANK_ORDER[0],
            templateFields,
            templateValue
          );
          state.orderInfo = getTemplateFieldValueByFieldName(
            FIELDS_BLANK_ORDER[1],
            templateFields,
            templateValue
          );
        }
      })
      .addCase(getTemplateDataById.rejected, (state, action) => {
        state.status = "error";
        state.templateError = undefined;
      });
  },
});

export const {
  updateElementValuetemplateReducer,
  defaultValue,
  resetTemplateData,
} = templateSlice.actions;

export const useTemplateReducer = () =>
  useAppSelector((state) => state.template);

export default templateSlice.reducer;
