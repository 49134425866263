import React, { useEffect } from "react";
import { Row, Col, Modal, Button, Table } from "react-bootstrap";
import { RiQuestionLine, RiCheckFill, RiCloseFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import {
  associateCoutiesToAgency,
  updateElementValueAgencyReducer,
  useAgencyReducer,
} from "../../redux/slice/agencySlice";
import { useCountyListReducer } from "../../redux/slice/countyListSlice";
import ConfirmPopup from "../../components/common/ConfirmPopup";

interface AssociateCountiesPopupProps {
  show: boolean;
  onHide: () => void;
}

const AssociateCountiesPopup: React.FC<AssociateCountiesPopupProps> = (
  props
) => {
  const { show, onHide } = props;
  const countiesReducer = useCountyListReducer();
  const agencyReducer = useAgencyReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let  [valueForTest , setValueForTest] = React.useState([""])
  let [countySet , setCountySet] = React.useState("");
  const [confirmText, setconfirmText] = React.useState<React.ReactNode>();
  const[confirmPopupData, setconfirmPopupData] = React.useState(false);
  const[confirmOriginPlace , setconfirmOriginPlace] = React.useState("")
  let countyArr: any[] = [];
  let [countySelectList,setCountySelectList] = React.useState(Array<string>)
  let [existingCountySelectList , setExistingCountySelectList] = React.useState(Array<string>)
  useEffect(()=> {
    setExistingCountySelectList(agencyReducer.countySelectList);
  }) 

  
  const updateCountySelectList = (countySelectList:any) =>{
    dispatch(
      updateElementValueAgencyReducer({
        elementName: "countySelectList",
        value:agencyReducer.countySelectList.concat(countySelectList)
      })
    );
  }
  
  const handleConfirmation = () => {
    if (confirmOriginPlace === "onConfirmClick") {
      updateCountySelectList(countySelectList)
      dispatch(
        associateCoutiesToAgency({
          agency: agencyReducer,
          navigate,
        })
      );
      onHide();
    }
  }
  const handleCancel = () => {
    dispatch(
      updateElementValueAgencyReducer({
        elementName: "countyIdList",
        value: null}) 
    );
    onHide()
    valueForTest.splice(0,valueForTest.length)
  }
  const handleCountySelect = (county: any) => () => {
    if (!valueForTest.includes(county.countyID+ ",")){
      valueForTest.push(county.countyID + ",")
      setCountySet(countySet+county.countyName)
      countySelectList.push(county)
    }else{
      const index = valueForTest.indexOf(county.countyID+ ",");
      const indexNew = countyArr.indexOf(county.countyName+",")
      valueForTest.splice(index,1)
      countyArr.splice(indexNew,1);
    }
    if (agencyReducer.countyId+"" === (county.countyID+",")){
      const index = valueForTest.indexOf(county.countyID+ ",");
      const indexNew = countyArr.indexOf(county.countyName);
      valueForTest.splice(index,1);
      countyArr.splice(indexNew,1);
    }
    dispatch(
      updateElementValueAgencyReducer({
        elementName: "countyIdList",
        value: (agencyReducer.countyIdList)? agencyReducer.countyIdList+","+county.countyID :county.countyID
      })
    );
  };
  const handleSave = () => {
    let confirmText = (
      <>
        <label>
        Are you sure, you want to associate agency with selected counties?
        </label>
      </>
    );
    setconfirmText(confirmText);
    setconfirmOriginPlace("onConfirmClick");
    setconfirmPopupData(true);
   
    
  };
  const handleCancellation = () => {
    dispatch(
      updateElementValueAgencyReducer({
        elementName: "countySelectList",
        value:existingCountySelectList
      })
    );
    dispatch(
      updateElementValueAgencyReducer({
        elementName: "countyIdList",
        value: null
      })
    );
    valueForTest.splice(0,valueForTest.length)
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <b>Select more counties to associate agency with</b>
      </Modal.Header>
      <Modal.Body>
        <Row md={12}>
          <Col md={3} className="text-center mt-2">
            <label>
              County <span>*</span>
            </label>
          </Col>

          <Col
            md={9}
            className="text-center mt-2"
            style={{
              maxHeight: "250px",
              overflowY: "scroll",
              cursor: "pointer",
            }}
          >
            <Table bordered className="mt-3" responsive hover>
              <tbody>
                {countiesReducer.countyList
                  .filter((county: any) => {
                    return (
                      !agencyReducer.countySelectList.find(
                        (c: any) => c.countyID === county.countyID
                      ) && county.countyID
                    );
                  })
                  .map((county: any, i) => (
                    <tr
                      key={i}
                      className="hover"
                      onClick={handleCountySelect(county)}
                      style={{
                        backgroundColor:
                          agencyReducer.countyIdList &&
                          valueForTest.includes(county.countyID+ ",")
                            ? "#e6f7ff"
                            : "",
                      }}
                    >
                      <td>{county.countyName}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <hr />
        <Row className="g-2">
          <Col>
            <Button variant="primary" className="w-100" onClick={handleSave}>
              <RiCheckFill /> Save
            </Button>
          </Col>
          <Col>
            <Button variant="danger" onClick={() => handleCancel()} className="w-100">
              <RiCloseFill /> Cancel
            </Button>
          </Col>
        </Row>
        
      </Modal.Body>
      <ConfirmPopup
        children={confirmText}
        show={confirmPopupData}
        onHide={() =>{setconfirmPopupData(false)
          handleCancellation()} }
        isConfirmation={handleConfirmation}
      />
    </Modal>   
  );  
};

export default AssociateCountiesPopup;
