import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {useAppSelector} from "../../hooks/hooks";
import {updateElementValue} from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { updateElementObject } from "../../utils/updateElementObject";
import { showHideLoadingGif } from "./commonSlice";
import { getEcomplaintMainDetails, updateElementValueComplaintMainReducer } from "./complaintMainSlice";
import { getLFCConcurrentObj } from "./commonFormSlice";
import { FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE } from "../../constants/common";

interface witnessObj {
    // Witness Form Data
    witnessId: number,
    complainingWitness: boolean,
    victim: boolean,
    familyMemberSuspect: boolean,
    firstName: string,
    middleName: string,
    lastName: string,
    suffix: string,
    email: string,
    addressLine1: string,
    addressLine2: string,
    state: string,
    city: string,
    zip: string,
    country: string,
    phoneNumber: string,
    agencyId: number,
    leo: boolean,
}

interface witnessState {
    witnessObject: witnessObj,
    fetchingError?: string;
    status: string;
    witnessSavingStatus: string;
    witnessSavingError: string;
    witnessFetchStatus: string;
    witnessFetchError: string;
}

const initialState = {
    fetchingError: undefined,
    status: "idle",
    witnessSavingStatus: "idle",
    witnessSavingError: '',
    witnessFetchStatus: 'idle',
    witnessFetchError: '',

    witnessObject: {
        witnessId: 0,
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        email: '',
        agencyId: 0,
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phoneNumber: '',
        complainingWitness: false,
        leo: false,
        victim: false,
        familyMemberSuspect: false,
    },
} as witnessState;

export const createWitness = createAsyncThunk<any,
    {
        ecomplaintId: number;
        witnessFormObj?: object
    },
    { state: RootState; rejectValue: string }
>(
    "witness/saveWitness/",
    async (data, { getState, rejectWithValue, dispatch }) => {
        const { ecomplaintId, witnessFormObj } = data;
        let { token } = getState().login;
        dispatch(showHideLoadingGif(true));
        let ecList = getState().complaintMain.ecFormList;
        let concurrentObj = getLFCConcurrentObj(ecList);
        try {
            const response: any = await doFetch({
                url: "ecomplaintservices/saveWitness/" +
                    ecomplaintId,
                token,
                body: witnessFormObj,
                type: "POST",
                concurrentObject: concurrentObj,
                concurrentIdentifier: "LFC",
                dispatch: dispatch
            });
            if (response.ok) {
                const data = await response.json();
                dispatch(updateElementValueComplaintMainReducer({ elementName: 'witnessList', value: data }));
                dispatch(showHideLoadingGif(false));
                return data;
            }
        } catch (error: any) {
            dispatch(showHideLoadingGif(false));
            return rejectWithValue(error.message || "Error While creating Witness");
        }
    }
)

export const getWitnesses = createAsyncThunk<
    any,
    {
        ecomplaintId: number;
    },
    { state: RootState; rejectValue: string }
>(
    "witness/getComplientWitness/",
    async (data, { getState, rejectWithValue, dispatch }) => {
        const { ecomplaintId } = data;
        let { token } = getState().login;
        dispatch(showHideLoadingGif(true));
        try {
            const response: any = await doFetch({
                url: "ecomplaintservices/getWitness/" +
                    ecomplaintId,
                token,
                type: "GET",
            });
        } catch (error: any) {
            dispatch(showHideLoadingGif(false));
            return rejectWithValue(error.message || "Error While loading Witness");
        }
    }
)

export const deleteWitnessDetails = createAsyncThunk<
    any,
    {
        ecomplaintId: number,
        witnessId: number,
    },
    { state: RootState, rejectValue: string }
>
    (
        "witness/deleteWitness",
        async (data, { getState, rejectWithValue, dispatch }) => {
            const {witnessId } = data;
            let { token } = getState().login;
            let ecList = getState().complaintMain.ecFormList;
            let concurrentObj = getLFCConcurrentObj(ecList);
            try {
                const response: any = await doFetch({
                    url: "ecomplaintservices/deleteWitness/" + witnessId,
                    token,
                    type: 'GET',
                    concurrentObject: concurrentObj,
                    concurrentIdentifier: "LFC",
                    dispatch: dispatch
                });
                if (response.ok) {
                    const dataRes = await response.json();
                    dispatch(getEcomplaintMainDetails({ecomplaintId: data.ecomplaintId, callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE}));
                    dispatch(updateElementValueComplaintMainReducer({ elementName: 'witnessList', value: dataRes }))
                    return dataRes;
                }
                return rejectWithValue("Error on fetching Ecviolation ");
            } catch (error) {
                return rejectWithValue("Error on fetching ecviolation warrant List");
            }
        }
    )

export const getWitnessData = createAsyncThunk
    <any,
        {
            witnessId: number,
            ecomplaintId: number
        },
        { state: RootState; rejectValue: string }
    >("witness/getWitnessData",
        async (data, { getState, rejectWithValue, dispatch }) => {
            const { witnessId } = data;
            let { token } = getState().login;
            try {
                const response: any = await doFetch({
                    url: "ecomplaintservices/getWitnessByWitnessId/" +
                        witnessId,
                    token,
                });
                if (response.ok) {
                    const data = await response.json();
                    dispatch(updateWitnessObject(data));
                    return data;
                }
                return rejectWithValue('Error whilr fetching Witness data')
            } catch (error) {
                return rejectWithValue(`Error while fetching Witness Data`);
            }
        })

const witnessSlice = createSlice({
    name: "witness",
    initialState,
    reducers: {
        updateElementValueWitnessReducer: updateElementValue,
        updateElementObjectValue: (state: witnessState, action) => {
            updateElementObject(state, action, state.witnessObject);
        },
        updateWitnessObject: (state: witnessState, action) => {
            state.witnessObject = action.payload;
        },
        resetWitnessData: (state: witnessState) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
    extraReducers(builder) {
        builder
            .addCase(createWitness.pending, (state) => {
                state.status = "loading";
                state.fetchingError = undefined;
            })
            .addCase(createWitness.fulfilled, (state, action) => {
                state.status = "success";
            })
            .addCase(createWitness.rejected, (state) => {
                state.status = "error";
                state.fetchingError = undefined;
            })
            .addCase(getWitnessData.pending, (state) => {
                state.status = "loading";
                state.fetchingError = undefined;
            })
            .addCase(getWitnessData.fulfilled, (state, action) => {
                state.status = "success";
             })
            .addCase(getWitnessData.rejected, (state) => {
                state.status = "error";
                state.fetchingError = undefined;
            })
            .addCase(deleteWitnessDetails.pending, (state) => {
                state.status = "loading";
                state.fetchingError = undefined;
            })
            .addCase(deleteWitnessDetails.fulfilled, (state, action) => {
                state.status = "success";
             })
            .addCase(deleteWitnessDetails.rejected, (state, action) => {
                state.fetchingError = "error";
            })
    }
})
export const {
    updateElementValueWitnessReducer,
    updateElementObjectValue,
    updateWitnessObject,
    resetWitnessData,
} = witnessSlice.actions;
export const useWitnessReducer = () => 
    useAppSelector((state) => state.witness);
export default witnessSlice.reducer;

