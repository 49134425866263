import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { ErrorType, LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { properties, application_status } from "../../constants/common";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";

interface StatuteState {
  statutesList: string[];
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  statutesError?: ErrorType;
  pagedStatutesListFetchstatus: LoadingType;
  isValid: boolean;
  listStatutesError: string;
  listValue: string;
  county: object;
  availableCounties:number[];
  agency: object;
  availableAgencies:number[];
  refCode: string;
  desc: string;
  caseType: string;
  classofViolation:string;
  violationType: string;
  offenseCode: string;
  localCounty: string;
  localAgency: string;
  countyOptions: any[];
  agencyOptions: any[];
  didMount: boolean;
}

const initialState = {
  statutesList: [],
  currentPage: 1,
  totalPages: 1,
  totalRecords: 0,
  pagedStatutesListFetchstatus: "idle",
  isValid: false,
  listStatutesError: "",
  listValue: "",
  county:{},
  availableCounties: [],
  agency: {},
  availableAgencies:[],
  refCode: "",
  desc: "",
  caseType: "-- ALL --",
  classofViolation: "-- ALL --",
  violationType: "-- ALL --",
  offenseCode: "",
  localCounty: "-- ALL --",
  localAgency: "-- ALL --",
  countyOptions: [],
  agencyOptions:[],
  didMount: false,
} as StatuteState;

export const listStatutes = createAsyncThunk<
  any,
  {
    countyIds?: Array<number>;
    agencyIds?: Array<number>;
    refCode?: string;
    desc?: string;
    caseType?: string;
    classofViolation?: string;
    violationType?: string;
    offenseCode?: string;
    pageNo?: number;
  },
  {
    state: RootState;
    rejectValue: string;
  }
>("statutes/liststatutes", async (data, { getState, rejectWithValue, dispatch }) => {
  console.log(data);
  let { token } = getState().login;
  dispatch(showHideLoadingGif(true));
  try {
    const reponse = await doFetch({
      url: "ecviolationservice/violations",
      token,
      body: data,
      type: "POST",
    });
    if (reponse.ok) {
      dispatch(showHideLoadingGif(false));
      return reponse.json();
    } else {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching county list");
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Error while fetching county list");
  }
});

const statutesListSlice = createSlice({
  name: "statutes",
  initialState,
  reducers: {
    updateElementValueStatutesListReducer: updateElementValue,
    resetStatutesSearchData: (state: StatuteState) => {
      return {
        ...state,
        ...initialState,
      };
    },
    updateStatutesListReducer : (state : StatuteState, action) => {
      return {
          ...state,
          localCounty: action.payload.localCounty,
          localAgency: action.payload.localAgency,
          refCode: action.payload.refCode,
          desc:  action.payload.desc,
          caseType:  action.payload.caseType,
          classofViolation:  action.payload.classofViolation,
          violationType:  action.payload.violationType,
          offenseCode:  action.payload.offenseCode,
      };
  },
  },
  extraReducers(builder) {
    builder
      .addCase(listStatutes.pending, (state) => {
        state.pagedStatutesListFetchstatus = "loading";
      })
      .addCase(listStatutes.fulfilled, (state, action) => {
        state.pagedStatutesListFetchstatus = "success";
        state.statutesList = action.payload.statutesList;
        state.totalPages = action.payload.totalPages;
        state.totalRecords = action.payload.totalRecords;
        state.currentPage = action.payload.currentPage;
        state.isValid = true;
        state.listStatutesError = "";
        state.county = action.payload.county;
      })
      .addCase(listStatutes.rejected, (state, action) => {
        state.pagedStatutesListFetchstatus = "error";
        state.isValid = false;
        state.listStatutesError =
          JSON.stringify(action.payload) || "Error while fetching user list";
      });
  },
});

export const { updateElementValueStatutesListReducer, resetStatutesSearchData, updateStatutesListReducer } =
  statutesListSlice.actions;

export const useStatutesListReducer = () =>
  useAppSelector((state) => state.userStatutesList);

export default statutesListSlice.reducer;
