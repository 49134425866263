import { Routes, Route, useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import Login from "../Login";
import CaseDetails from "../warrantlist/CaseDetails";
import Page404 from "./Page404";
import User from "../user/User";
import {
  useLoginReducer,
  updateElementValueInLoginReducer,
  logoutUser,
  getCurrentAuthUser,
  reNewAccessToken,
} from "../../redux/slice/loginSlice";
import ProofOfService from "../warrantlist/form/ProofOfService";
import BlankOrder from "../warrantlist/form/BlankOrder";
import BlankOrderSubmit from "../warrantlist/form/BlankOrderSubmit";
import ApplicationAndOrder from "../warrantlist/form/ApplicationAndOrder";
import SearchWarrant from "../warrantlist/form/SearchWarrant";
import SearchWarrantSubmit from "../warrantlist/form/SearchWarrantSubmit";
import Inventory from "../warrantlist/form/Inventory";
import ApplicationAndOrderSubmit from "../warrantlist/form/ApplicationAndOrderSubmit";
import OrderOfCustody from "../warrantlist/form/OrderOfCustody";
import AmendInventory from "../warrantlist/form/AmendInventory";
import EComplaintCaseInfoPage from "../longform/EComplaintCaseInfoPage";
import EComplaintDashboard from "../longform/EComplaintDashBoard";
import EComplaintMainPage from "../longform/EComplaintMainPage";
import ArrestWarrant from "../longform/ArrestWarrant";
import AddCounty from "../county/AddCounty";
import AddAgency from "../agency/AddAgency";
import ApplicationAndOrderTemp from "../templatelist/ApplicationAndOrderTemp";
import BlankOrderTemp from "../templatelist/BlankOrderTemp";
import ComplaintOrSearchTemp from "../templatelist/ComplaintOrSearchTemp";
import Dashboard from "../dashboard/Dashboard";
import TestWebCam from "../testWebCam/TestWebCam";
import UserList from "../user/UserList";
import CountyList from "../county/CountyList";
import AgencyList from "../agency/AgencyList";
import SearchEComplaint from "../longform/SearchEComplaint";
import WarrantList from "../warrantlist/WarrantList";
import TemplateList from "../templatelist/TemplateList";
import SyncIucsData from "../sync/SyncIucsData";
import ActiveUserSessions from "../activeuser/ActiveUserSessions";
import MessagePopup from "../activeuser/MessagePopup";
import { useAppDispatch } from "../../hooks/hooks";
import { over } from "stompjs";
import SockJS from "sockjs-client";
import {
  EC_MSG_CONCURRENTCHANGE,
  RENEW_TOKEN_BY_TIMER,
} from "../../constants/common";
import InfoPopup from "./InfoPopup";
import LoadingPopup from "./LoadingPopup";
import {
  updateElementValueInCommonReducer,
  useCommonReducer,
} from "../../redux/slice/commonSlice";
import StatutesList from "../statutes/StatutesList";
import ChangePassword from "../user/ChangePassword";
import UserDetail from "../user/UserDetail";
import { ForgotPasswordReset } from "../user/ForgotPasswordReset";
import ECPreTrialReleaseOnOwnRecognizPage from "../longform/ECPreTrialReleaseOnOwnRecognizPage";
import ProbableCauseStatement from "../longform/ProbableCauseStatement";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import fetchIntercept from "fetch-intercept";
import { ReLogin } from "./ReLogin";
import useLogOut from "../../hooks/useLogOut";
import Summons from "../longform/Summons";
import CaptureSignature from "../user/CaptureSignature";

let stompClient: any = null;
const RouteContainer: React.FC = () => {
  const { isValid } = useLoginReducer();
  const {
    username,
    receiverName,
    message,
    refreshToken,
    accessTokenRenewTime,
    refreshTokenExpired,
    loadingpopupString,
  } = useLoginReducer();
  const {
    showPopup,
    concurrentPopup,
    concurrentMsg,
    pdfgenerationerror,
    pdfgenerationErrorMsg,
    docGenerateError,
    docGenerateErrorMsg,
  } = useCommonReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [privateChats, setPrivateChats] = useState(new Map());
  const [publicChats, setPublicChats] = useState<any>([]);
  const [showInfo, setShowInfo] = React.useState({
    show: false,
    info: "",
    status: "",
  });
  const logOut = useLogOut();

  useEffect(() => {
    dispatch(getCurrentAuthUser());
  }, [dispatch]);

  const confirmerrorPdf = () => {
    dispatch(
      updateElementValueInCommonReducer({
        elementName: "pdfgenerationerror",
        value: false,
      })
    );
    dispatch(
      updateElementValueInCommonReducer({
        elementName: "pdfgenerationErrorMsg",
        value: "",
      })
    );
  };

  const confirmDocGenError = () => {
    dispatch(
      updateElementValueInCommonReducer({
        elementName: "docGenerateError",
        value: false,
      })
    );

    dispatch(
      updateElementValueInCommonReducer({
        elementName: "docGenerateErrorMsg",
        value: undefined,
      })
    );
  };

  const connect = () => {
    // let Sock = new SockJS(properties.socketWS);
    let socketWs: any = process.env.REACT_APP_SOCKET_WS;
    let Sock = new SockJS(socketWs);

    stompClient = over(Sock);
    stompClient.connect({}, onConnected, onError);
  };

  const onConnected = () => {
    dispatch(
      updateElementValueInLoginReducer({
        elementName: "connected",
        value: true,
      })
    );
    stompClient.subscribe("/ics/connect", onMessageReceived);
    userJoin();
  };

  const userJoin = () => {
    const chatMessage = {
      senderName: username,
      status: "JOIN",
    };
    stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
  };

  const onMessageReceived = (payload: any) => {
    const payloadData: any = JSON.parse(payload.body);

    switch (payloadData.status) {
      case "JOIN":
        if (!privateChats.get(payloadData.senderName)) {
          privateChats.set(payloadData.senderName, []);
          setPrivateChats(new Map(privateChats));
        }
        break;
      case "MESSAGE":
        payloadData.receiverName.map((item: any) => {
          if (username === item) {
            setShowInfo({
              show: true,
              info: payloadData.message,
              status: payloadData.status,
            });
          }
        });
        publicChats.push(payloadData);
        setPublicChats(payloadData);
        break;
      case "LEAVE":
        payloadData.receiverName.map((item: any) => {
          if (username === item) {
            setShowInfo({
              show: true,
              info: "Your session is invalidated and you have been signed out from the system.",
              status: payloadData.status,
            });
          }
        });
    }
  };

  const onError = (err: any) => {
    console.log(err);
  };

  const sendValue = (status: string) => {
    if (stompClient) {
      const chatMessage = {
        senderName: username,
        message: message,
        status: status,
        receiverName: receiverName,
      };

      stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
      dispatch(
        updateElementValueInLoginReducer({
          elementName: "message",
          value: "",
        })
      );
      if (status === "MESSAGE") {
        setShowInfo({
          info: "Message send successfully.",
          show: true,
          status: "",
        });
      }
    }
  };

  const confirmConcurrent = () => {
    dispatch(
      updateElementValueInCommonReducer({
        elementName: "concurrentPopup",
        value: false,
      })
    );

    navigate("/");
  };

  const cancelReLogin = () => {
    logOut("Session time out. Please login again.");
  };

  const confirmMessage = () => {
    if (showInfo.status === "LEAVE") {
      dispatch(logoutUser());
    }
    setShowInfo({ show: false, info: "", status: "" });
  };

  useEffect(() => {
    if (isValid) {
      connect();
    }
  }, [isValid]);

  // window.addEventListener("beforeunload", (ev) => {
  //   ev.preventDefault();
  //   return (ev.returnValue = dispatch(logoutUser()));
  // });

  useEffect(() => {
    if (
      isValid &&
      refreshToken !== null &&
      refreshToken !== "" &&
      accessTokenRenewTime > 0
    ) {
      const intervalData = {
        callingFrom: RENEW_TOKEN_BY_TIMER,
        navigate: navigate,
        refreshToken: refreshToken,
      };
      const interval: any = setInterval(
        () => dispatch(reNewAccessToken(intervalData)),
        (accessTokenRenewTime * 1000 * 60) / 2
      );
      return () => clearInterval(interval);
    }
  }, [refreshToken, isValid]);

  const unregister = fetchIntercept.register({
    request: function (url, config) {
      return [url, config];
    },

    requestError: function (error) {
      return Promise.reject(error);
    },

    response: function (response) {
      response
        .clone()
        .json()
        .then(
          (result) => {
            if (result) {
              if (!result.refreshTokenExpired && result.token === null) {
                logOut("Token expired. Please login again.");
                return;
              }
            }
          },
          (error) => {}
        );
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });

  let pdfError = [];
  if (docGenerateError && docGenerateErrorMsg) {
    pdfError.push(
      <div className="text-start">
        <b>
          Special Characters that are not compatible were found in the content
          in the following areas:
        </b>
      </div>
    );
    pdfError.push(<br></br>);
    for (const [key, value] of Object.entries(docGenerateErrorMsg)) {
      pdfError.push(<div className="text-start">{"Field: " + key}</div>);
      pdfError.push(<div className="text-start">{"Content: " + value}</div>);
      // pdfError.push(<hr></hr>);
    }
    pdfError.push(<br></br>);
    pdfError.push(
      <div className="text-start">
        <b>
          Please remove any special characters to correct the content, Preview
          and make sure the PDF is properly generated before submitting.
        </b>
      </div>
    );
  }

  // if (isValid) {
  return (
    <Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute fallback="/login">
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route
            path="/TestWebCam"
            element={
              <ProtectedRoute fallback="/">
                <TestWebCam />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/UserList"
          element={
            <ProtectedRoute>
              <UserList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CountyList"
          element={
            <ProtectedRoute>
              <CountyList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/AgencyList"
          element={
            <ProtectedRoute>
              <AgencyList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/SearchEComplaint"
          element={
            <ProtectedRoute>
              <SearchEComplaint />
            </ProtectedRoute>
          }
        />
        <Route
          path="/WarrantList"
          element={
            <ProtectedRoute>
              <WarrantList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/WarrantsSummonsList"
          element={
            <ProtectedRoute>
              <WarrantList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/WarrantList/:id"
          element={
            <ProtectedRoute fallback="/">
              <WarrantList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/TemplateList"
          element={
            <ProtectedRoute>
              <TemplateList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/SyncIucsData"
          element={
            <ProtectedRoute fallback="/">
              <SyncIucsData />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ActiveUserSessionsList"
          element={
            <ProtectedRoute fallback="/">
              <ActiveUserSessions handleConfirm={sendValue} />
            </ProtectedRoute>
          }
        >
          <Route
            path="/ActiveUserSessionsList/MessagePopup"
            element={
              <ProtectedRoute fallback="/">
                <MessagePopup handleConfirm={sendValue} />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/StatutesList"
          element={
            <ProtectedRoute>
              <StatutesList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/captureSignature"
          element={
            <ProtectedRoute>
              <CaptureSignature />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CaseDetails"
          element={
            <ProtectedRoute>
              <CaseDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/User"
          element={
            <ProtectedRoute fallback="/">
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editUser/:username"
          element={
            <ProtectedRoute fallback="/">
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="/userDetail/:id"
          element={
            <ProtectedRoute fallback="/">
              <UserDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="changePassword"
          element={
            <ProtectedRoute fallback="/">
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ProofOfService"
          element={
            <ProtectedRoute fallback="/">
              <ProofOfService />
            </ProtectedRoute>
          }
        />
        <Route
          path="/BlankOrder"
          element={
            <ProtectedRoute fallback="/">
              <BlankOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/BlankOrderSubmit"
          element={
            <ProtectedRoute fallback="/">
              <BlankOrderSubmit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ApplicationAndOrder"
          element={
            <ProtectedRoute fallback="/">
              <ApplicationAndOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/SearchWarrant"
          element={
            <ProtectedRoute>
              <SearchWarrant />
            </ProtectedRoute>
          }
        />
        <Route
          path="/SearchWarrantSubmit"
          element={
            <ProtectedRoute fallback="/">
              <SearchWarrantSubmit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Inventory"
          element={
            <ProtectedRoute fallback="/">
              <Inventory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ApplicationAndOrderSubmit"
          element={
            <ProtectedRoute fallback="/">
              <ApplicationAndOrderSubmit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/OrderOfCustody"
          element={
            <ProtectedRoute fallback="/">
              <OrderOfCustody />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AmendInventory"
          element={
            <ProtectedRoute fallback="/">
              <AmendInventory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EComplaintCaseInfoPage"
          element={
            <ProtectedRoute fallback="/">
              <EComplaintCaseInfoPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EComplaintDashboard"
          element={
            <ProtectedRoute fallback="/">
              <EComplaintDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EComplaintMainPage"
          element={
            <ProtectedRoute fallback="/">
              <EComplaintMainPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/PreTrialReleaseOnOwnRecognizPage"
          element={
            <ProtectedRoute fallback="/">
              <ECPreTrialReleaseOnOwnRecognizPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/probableCauseStatement"
          element={
            <ProtectedRoute fallback="/">
              <ProbableCauseStatement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/issueSummons"
          element={
            <ProtectedRoute fallback="/">
              <Summons />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ArrestWarrant"
          element={
            <ProtectedRoute fallback="/">
              <ArrestWarrant />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AddCounty"
          element={
            <ProtectedRoute fallback="/">
              <AddCounty />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditCounty/:id"
          element={
            <ProtectedRoute fallback="/">
              <AddCounty />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ViewCounty/:id"
          element={
            <ProtectedRoute fallback="/">
              <AddCounty />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AddAgency"
          element={
            <ProtectedRoute fallback="/">
              <AddAgency />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditAgency/:id"
          element={
            <ProtectedRoute fallback="/">
              <AddAgency />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ViewAgency/:id"
          element={
            <ProtectedRoute fallback="/">
              <AddAgency />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addApplicationAndOrderTemp"
          element={
            <ProtectedRoute fallback="/">
              <ApplicationAndOrderTemp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editApplicationAndOrderTemp/:id"
          element={
            <ProtectedRoute fallback="/">
              <ApplicationAndOrderTemp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/copyApplicationAndOrderTemp/:id"
          element={
            <ProtectedRoute fallback="/">
              <ApplicationAndOrderTemp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addBlankOrderTemp"
          element={
            <ProtectedRoute fallback="/">
              <BlankOrderTemp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editBlankOrderTemp/:id"
          element={
            <ProtectedRoute fallback="/">
              <BlankOrderTemp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/copyBlankOrderTemp/:id"
          element={
            <ProtectedRoute fallback="/">
              <BlankOrderTemp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addComplaintSearchwarrantTemp"
          element={
            <ProtectedRoute fallback="/">
              <ComplaintOrSearchTemp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editComplaintSearchwarrantTemp/:id"
          element={
            <ProtectedRoute fallback="/">
              <ComplaintOrSearchTemp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/copyComplaintSearchwarrantTemp/:id"
          element={
            <ProtectedRoute fallback="/">
              <ComplaintOrSearchTemp />
            </ProtectedRoute>
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/account/reset/:code"
          element={
            <PublicRoute>
              <ForgotPasswordReset />
            </PublicRoute>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <InfoPopup
        children={showInfo.info}
        onHide={confirmMessage}
        show={showInfo.show}
      />
      <LoadingPopup show={showPopup} loadingpopupString={loadingpopupString} />
      <InfoPopup
        children={EC_MSG_CONCURRENTCHANGE}
        onHide={confirmConcurrent}
        show={concurrentPopup}
        variant="warning"
      />
      <InfoPopup
        children={concurrentMsg}
        onHide={() => confirmConcurrent()}
        show={concurrentPopup}
        variant="warning"
      />
      <ReLogin onHide={cancelReLogin} show={refreshTokenExpired} />
      <InfoPopup
        children={pdfgenerationErrorMsg}
        onHide={confirmerrorPdf}
        show={pdfgenerationerror}
        variant="danger"
      />
      <InfoPopup
        children={<div>{pdfError}</div>}
        title="Bad Content Found"
        onHide={confirmDocGenError}
        show={docGenerateError}
        variant="danger"
      />
    </Fragment>
  );
  // } else {
  //   return (
  //     <Routes>
  //       <Route path="/" element={<Login />} />
  //       <Route path="/account/reset/:code" element={<ForgotPasswordReset />} />
  //     </Routes>
  //   );
  // }
};

export default RouteContainer;
