import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  CODE_TABLE_EYECOLOR,
  CODE_TABLE_GENDER,
  CODE_TABLE_HAIRCOLOR,
  CODE_TABLE_SUFFIX,
  CODE_TABLE_TSS_RACE,
  STATE_LIST,
} from "../../constants/common";
import { useAppDispatch } from "../../hooks/hooks";
import {
  updateElementObjectValueArrestWarrantReducer,
  updateElementObjectValueDefInfoObject,
  useArrestWarrantReducer,
} from "../../redux/slice/arrestWarrantSlice";
import {
  getCodeTableEntries,
  getCountryList,
  useComplaintReducer,
} from "../../redux/slice/complaintSlice";
import Alert, { useAlert } from "../common/Alert";
import FormComponent from "../common/FormComponent";
import {
  formattedZipcode,
  heightFormatValidation,
  mapToSelectOptions,
  merge,
  validateDate,
  validateDateLong,
} from "../../utils/common";
import moment from "moment";
import ButtonComponent from "../common/ButtonComponent";
import * as yup from "yup";
import TextInputComponent from "../common/TextInputComponent";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";

interface ModifyDefendantPopupProps {
  show: boolean;
  onHide: () => void;
}

const ModifyDefendantPopup: React.FC<ModifyDefendantPopupProps> = ({
  show,
  onHide,
}) => {
  const ecomplaintReducer = useComplaintMainReducer();
  const arrestWarrantReducer = useArrestWarrantReducer();
  const dispatch = useAppDispatch();
  const complaintReducer = useComplaintReducer();
  const [errorKeys, setErrorKeys] = useState([""]);
  const errorMessageAlert = useAlert();
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const zipCodeRef = React.useRef<HTMLInputElement>(null);

  let editedDefInfo:any = {
    defFirstName: "",
    defMiddleName: "",
    defLastName: "",
    defSuffix: "",
    gender: "",
    defRace: "",
    defDob: "",
    defHeight: "",
    defWeight: "",
    defEyes: "",
    defHair: "",
    defDriversLicense: "",
    defDlState: "",
    dcn: "",
    defAddressUnknown: "",
    defForeignAddress: "",
    defAddress1: "",
    defAddress2: "",
    defCity: "",
    defState: "",
    defZip: "",
    defTelephone: "",
    defCountry: ""
  };

  useEffect(() => {
    dispatch(getCodeTableEntries({ tableName: CODE_TABLE_SUFFIX }));
    dispatch(getCodeTableEntries({ tableName: CODE_TABLE_GENDER }));
    dispatch(getCodeTableEntries({ tableName: CODE_TABLE_TSS_RACE }));
    dispatch(getCodeTableEntries({ tableName: CODE_TABLE_EYECOLOR }));
    dispatch(getCodeTableEntries({ tableName: CODE_TABLE_HAIRCOLOR }));
    dispatch(getCountryList());
  }, [dispatch]);

  useEffect(() => {
    setInitialDefendentValues();
  }, [arrestWarrantReducer.fetchArrestWarrantStatus === "success"])

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setInitialDefendentValues = async () => {
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defFirstName", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defFirstName:ecomplaintReducer.complaint.defFirstName }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defMiddleName", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defMiddleName:ecomplaintReducer.complaint.defMiddleName }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defLastName", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defLastName:ecomplaintReducer.complaint.defLastName }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defSuffix", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defSuffix:ecomplaintReducer.complaint.defSuffix }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "gender", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.gender:ecomplaintReducer.complaint.defGender }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defRace", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defRace:ecomplaintReducer.complaint.defRace }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defDob", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defDob:ecomplaintReducer.complaint.defDob }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defHeight", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defHeight:ecomplaintReducer.complaint.defHeight }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defWeight", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defWeight:ecomplaintReducer.complaint.defWeight }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defEyes", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defEyes:ecomplaintReducer.complaint.defEyeColor }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defHair", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defHair:ecomplaintReducer.complaint.defHairColor }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defDriversLicense", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defDriversLicense:ecomplaintReducer.complaint.defDriverlicense }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defDlState", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defDlState:ecomplaintReducer.complaint.defDlState }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "dcn", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.dcn:ecomplaintReducer.complaint.dcn }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defAddressUnknown", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defAddressUnknown:ecomplaintReducer.complaint.defAddressUnknown?"Y":"N" }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defForeignAddress", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defForeignAddress:ecomplaintReducer.complaint.defForeignAddress?"Y":"N" }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defAddress1", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defAddress1:ecomplaintReducer.complaint.defAddressLine1 }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defAddress2", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defAddress2:ecomplaintReducer.complaint.defAddressLine2 }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defCity", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defCity:ecomplaintReducer.complaint.defCity }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defState", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defState:ecomplaintReducer.complaint.defState }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defZip", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defZip:ecomplaintReducer.complaint.defZip }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defTelephone", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defTelephone:arrestWarrantReducer.arrestWarrantObj.defTelephone }));
    dispatch(updateElementObjectValueDefInfoObject({ elementName: "defCountry", value: arrestWarrantReducer.arrestWarrantObj.defendentInfo !== null ?arrestWarrantReducer.arrestWarrantObj.defendentInfo.defCountry:ecomplaintReducer.complaint.defCountry }));
  }

  const handleValChange = (event: any) => {
    const { name } = event.target;
    let value = event.target.value;

    if (name === "defZip") {
      value = value.replaceAll("-", "");
      value = value.replace(/[^0-9]/g, "");
      if (value.length > 5) {
        value = value.substring(0, 5) + "-" + value.substring(5, 9);
      }
    } else if (name === "defHeight") {
      if (value.length > 3) value = value.substring(0, 3);
    } else if (name === "defTelephone") {
      value = value.replaceAll("-", "");
      if (value.length > 6) {
        value =
          value.substring(0, 3) +
          "-" +
          value.substring(3, 6) +
          "-" +
          value.substring(6, 10);
      } else if (value.length > 3) {
        value = value.substring(0, 3) + "-" + value.substring(3, value.length);
      }
    } else if (name === "defDob") {
    dispatch(
      updateElementObjectValueDefInfoObject({
        elementName: "defDob",
        value: value,
      })
    );
    } else if (name === "defAddressUnknown") {
      value = value === "Y" ? "N" : "Y";
      dispatch(
        updateElementObjectValueDefInfoObject({
          elementName: "defAddress1",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueDefInfoObject({
          elementName: "defAddress2",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueDefInfoObject({
          elementName: "defCity",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueDefInfoObject({
          elementName: "defState",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueDefInfoObject({
          elementName: "defZip",
          value: "",
        })
      );
      dispatch(
        updateElementObjectValueDefInfoObject({
          elementName: "defCountry",
          value: "",
        })
      );
      if (value === "Y") {
        dispatch(
          updateElementObjectValueDefInfoObject({
            elementName: "defForeignAddress",
            value: "N",
          })
        );
      }

      if (
        arrestWarrantReducer.defInfoObject.defForeignAddress !== "Y" &&
        value !== "Y"
      ) {
        dispatch(
          updateElementObjectValueDefInfoObject({
            elementName: "defCountry",
            value: "UNITED STATES",
          })
        );
      } else {
        dispatch(
          updateElementObjectValueDefInfoObject({
            elementName: "defCountry",
            value: "",
          })
        );
      }
    } else if (name === "defForeignAddress") {
      value = value === "Y" ? "N" : "Y";
      dispatch(
        updateElementObjectValueDefInfoObject({
          elementName: "defState",
          value: "",
        })
      );
      if (value === "Y") {
        dispatch(
          updateElementObjectValueDefInfoObject({
            elementName: "defAddressUnknown",
            value: "N",
          })
        );
      }
      if (
        arrestWarrantReducer.defInfoObject.defAddressUnknown !== "Y" &&
        value !== "Y"
      ) {
        dispatch(
          updateElementObjectValueDefInfoObject({
            elementName: "defCountry",
            value: "UNITED STATES",
          })
        );
      } else {
        dispatch(
          updateElementObjectValueDefInfoObject({
            elementName: "defCountry",
            value: "",
          })
        );
      }
    }
    dispatch(
      updateElementObjectValueDefInfoObject({ elementName: name, value:value.toUpperCase() })
    );
  };
  
  const handleBithdayInputBlur = (event: any) => {
    let { name, value } = event.target;
    let returnDate = null;
    let formatedparsedDate = null;
    let dateFormats = ["MMDDYY", "MMDDYYYY", "MM/DD/YYYY", "MM/DD/YY",];
    let isValidDate = false;
    for (let format of dateFormats) {
      let parsedDate = moment(value, format, true);
      if (parsedDate.isValid()) {
        let parsedyear = parsedDate.year();
        let currentyear = moment().year();
        if (parsedyear > currentyear) {
          parsedDate = parsedDate.subtract(100, "year");
        }
        formatedparsedDate = parsedDate.format('MM/DD/YYYY');
        isValidDate = true;
        break;
      }
    }
    if (isValidDate && formatedparsedDate) {
      if (formatedparsedDate.length > 10) {
        // handle date string length errors
        returnDate = "";
      } else if (validateDateLong(formatedparsedDate)) {
        // CORRECT VALUE
        returnDate = formatedparsedDate;
      } else {
        // handle invalid date errors
        returnDate = ""
      }
    } else {
      returnDate = "";
    }
    dispatch(updateElementObjectValueDefInfoObject({ elementName: name, value: returnDate }));
  }
  const nextRef = useRef(null);
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 9 && !e.shiftKey) { // tab key was pressed
      handleBithdayInputBlur(e);
    }
  }
  const handleSelectChange = (name: string) => (obj: any) => {
    let { value } = obj;
    dispatch(
      updateElementObjectValueDefInfoObject({
        elementName: name,
        value: value,
      })
    );
  };

  const handleOnBlurUpperCased = (event: any) => {
    const { value, name } = event.target;
    dispatch(
      updateElementObjectValueDefInfoObject({
        elementName: name,
        value: value.toUpperCase(),
      })
    );
  };

  const handleOnBlurZipCode = (event: any) => {
    const { value } = event.target;
    if (
      (value.length > 0 && value.length < 5) ||
      (value.length > 6 && value.length < 10)
    ) {
      zipCodeRef.current?.focus();
      event.stopPropagation();
    }
  };

  const handleOnUpdateDefendant = async () => {
    const {
      defForeignAddress,
      defAddressUnknown,
      defDlState,
      defDriversLicense,
    } = arrestWarrantReducer.defInfoObject;
    try {
      let schema = [];
      schema.push(defaultSchemaError);
      if (defAddressUnknown !== "Y") {
        schema.unshift(addressSchemaError);
        if (defForeignAddress === "Y") {
          schema.unshift(foreingAddressSchemaError);
        } else {
          schema.unshift(localAddressSchemaError);
        }
        if (defDlState) {
          schema.unshift(defDriversLicenseSchemaError);
        } else if (defDriversLicense) {
          schema.unshift(defDlStateSchemaError);
        }
      }
      schema = merge(...schema);
      await schema.validate(arrestWarrantReducer.defInfoObject, {
        abortEarly: false,
      });

      editedDefInfo = {
        defFirstName: arrestWarrantReducer.defInfoObject.defFirstName,
        defMiddleName: arrestWarrantReducer.defInfoObject.defMiddleName,
        defLastName: arrestWarrantReducer.defInfoObject.defLastName,
        defSuffix: arrestWarrantReducer.defInfoObject.defSuffix,
        gender: arrestWarrantReducer.defInfoObject.gender,
        defRace: arrestWarrantReducer.defInfoObject.defRace,
        defDob: arrestWarrantReducer.defInfoObject.defDob,
        defHeight: arrestWarrantReducer.defInfoObject.defHeight,
        defWeight: arrestWarrantReducer.defInfoObject.defWeight,
        defEyes: arrestWarrantReducer.defInfoObject.defEyes,
        defHair: arrestWarrantReducer.defInfoObject.defHair,
        defDriversLicense: arrestWarrantReducer.defInfoObject.defDriversLicense,
        defDlState: arrestWarrantReducer.defInfoObject.defDlState,
        dcn: arrestWarrantReducer.defInfoObject.dcn,
        defAddressUnknown: arrestWarrantReducer.defInfoObject.defAddressUnknown,
        defForeignAddress: arrestWarrantReducer.defInfoObject.defForeignAddress,
        defAddress1: arrestWarrantReducer.defInfoObject.defAddress1,
        defAddress2: arrestWarrantReducer.defInfoObject.defAddress2,
        defCity: arrestWarrantReducer.defInfoObject.defCity,
        defState: arrestWarrantReducer.defInfoObject.defState,
        defZip: arrestWarrantReducer.defInfoObject.defZip,
        defTelephone: arrestWarrantReducer.defInfoObject.defTelephone,
        defCountry: arrestWarrantReducer.defInfoObject.defCountry
      };

      dispatch(
        updateElementObjectValueArrestWarrantReducer({
          elementName: "defendentInfo",
          value: editedDefInfo,
        })
      );

      onHide();
      handleError([],[]);
    } catch (error: any) {
      handleError(
        error.inner.map((e: any) => e.path),
        error.errors
      );
    }
  };



  const formComponentData = [
    {
      type: "tooltipTextField",
      props: {
        type: "text",
        name: "defLastName",
        onChange: handleValChange,
        value: arrestWarrantReducer.defInfoObject.defLastName,
        isRequired: true,
        labelName: "Last Name",
        onBlur: handleOnBlurUpperCased,
        isError: errorKeys.includes("defLastName"),
        placeholder: "Last Name",
        tooltip: "Last Name of the defendant here.",
      },
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "defMiddleName",
        onChange: handleValChange,
        value: arrestWarrantReducer.defInfoObject.defMiddleName,
        labelName: "Middle Name",
        onBlur: handleOnBlurUpperCased,
        isError: errorKeys.includes("defMiddleName"),
        placeholder: "Middle Name",
      },
    },
    {
      type: "tooltipTextField",
      props: {
        type: "text",
        name: "defFirstName",
        onChange: handleValChange,
        value: arrestWarrantReducer.defInfoObject.defFirstName,
        isRequired: true,
        isLabeled: true,
        labelName: "First Name",
        onBlur: handleOnBlurUpperCased,
        isError: errorKeys.includes("defFirstName"),
        placeholder: "First Name",
        tooltip: "First Name of the defendant here."
      },
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...mapToSelectOptions(
            complaintReducer.suffixList,
            "displayValue",
            "displayValue"
          ),
        ],
        labelName: "Suffix",
        defaultValue: { label: "-- Select --", value: "" },
        onChange: handleSelectChange("defSuffix"),
        isLabeled: true,
        isDefaultLabel: true,
        isError: errorKeys.includes("defSuffix"),
      },
    },
    {
      type: "textInput",
      props: {
        type: "text",
        name: "defDob",
        value: arrestWarrantReducer.defInfoObject.defDob,
        onChange: handleValChange,
        onBlur: handleBithdayInputBlur,
        onKeyDown:handleKeyDown,
        isRequired: true,
        labelName: "Date of Birth",
        maxLength: 10, 
        placeholder: "MM/dd/yyyy",
         isError: errorKeys.includes("defDob"),
        autoComplete:"nope",
      },
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...mapToSelectOptions(
            complaintReducer.genderList,
            "displayValue",
            "displayValue"
          ),
        ],
        labelName: "Gender",
        value: [
          { label: "-- Select --", value: "" },
          ...mapToSelectOptions(
            complaintReducer.genderList,
            "displayValue",
            "displayValue"
          ),
        ].find((item) => {
          if (item.label === arrestWarrantReducer.defInfoObject.gender) {
            return item;
          } else if (!arrestWarrantReducer.defInfoObject.gender) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "gender",
        isError: errorKeys.includes("gender"),
        onChange: handleSelectChange("gender"),
        isSelected: true,
        isRequired: true,
        isLabeled: true,
        isDefaultLabel: true,
      },
      colSize: 2,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...mapToSelectOptions(
            complaintReducer.raceList,
            "displayValue",
            "displayValue"
          ),
        ],
        labelName: "Race",
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...mapToSelectOptions(
            complaintReducer.raceList,
            "displayValue",
            "displayValue"
          ),
        ].find((item) => {
          if (item.label === arrestWarrantReducer.defInfoObject.defRace) {
            return { label: item.label, value: item.value };
          } else if (!arrestWarrantReducer.defInfoObject.defRace) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defRace",
        isError: errorKeys.includes("defRace"),
        onChange: handleSelectChange("defRace"),
        isSelected: true,
        isRequired: true,
        isLabeled: true,
        isDefaultLabel: true,
      },
      colSize: 3,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...mapToSelectOptions(
            complaintReducer.hairList,
            "displayValue",
            "displayValue"
          ),
        ],
        labelName: "Hair",
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...mapToSelectOptions(
            complaintReducer.hairList,
            "displayValue",
            "displayValue"
          ),
        ].find((item) => {
          if (item.label === arrestWarrantReducer.defInfoObject.defHair) {
            return { label: item.label, value: item.value };
          } else if (!arrestWarrantReducer.defInfoObject.defHair) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defHair",
        isError: errorKeys.includes("defHair"),
        onChange: handleSelectChange("defHair"),
        isSelected: true,
        isLabeled: true,
        isDefaultLabel: true,
        isRequired: true,
      },
      colSize: 2,
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...mapToSelectOptions(
            complaintReducer.eyesList,
            "displayValue",
            "displayValue"
          ),
        ],
        labelName: "Eyes",
        placeholder: "-- Select --",
        value: [
          { label: "-- Select --", value: "" },
          ...mapToSelectOptions(
            complaintReducer.eyesList,
            "displayValue",
            "displayValue"
          ),
        ].find((item) => {
          if (item.label === arrestWarrantReducer.defInfoObject.defEyes) {
            return { label: item.label, value: item.value };
          } else if (!arrestWarrantReducer.defInfoObject.defEyes) {
            return { label: "-- Select --", value: "" };
          }
        }),
        defaultValue: { label: "-- Select --", value: "" },
        name: "defEyes",
        isError: errorKeys.includes("defEyes"),
        onChange: handleSelectChange("defEyes"),
        isSelected: true,
        isLabeled: true,
        isDefaultLabel: true,
        isRequired: true,
      },
      colSize: 2,
    },
    {
      type: "textInput",
      props: {
        type: "number",
        labelName: "Height",
        name: "defHeight",
        value: arrestWarrantReducer.defInfoObject.defHeight,
        isError: errorKeys.includes("defHeight"),
        onChange: handleValChange,
        isRequired: true,
        maxLength: 3,
        title: "Ex: If the height is 5ft 9inches, please enter 509.",
      },
    },
    {
      type: "textInput",
      props: {
        type: "number",
        labelName: "Weight",
        name: "defWeight",
        value: arrestWarrantReducer.defInfoObject.defWeight,
        isError: errorKeys.includes("defWeight"),
        onChange: handleValChange,
        isRequired: true,
      },
    },
    {
      type: "empty",
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "DL Number",
        name: "defDriversLicense",
        value: arrestWarrantReducer.defInfoObject.defDriversLicense,
        isError: errorKeys.includes("defDriversLicense"),
        onChange: handleValChange,
      },
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...STATE_LIST.map((item) => {
            return { label: item, value: item };
          }),
        ],
        value: [
          { label: "-- Select --", value: "" },
          ...STATE_LIST.map((item) => {
            return { label: item, value: item };
          }),
        ].find((item) => {
          if (item.label === arrestWarrantReducer.defInfoObject.defDlState) {
            return { label: item.label, value: item.value };
          } else if (!arrestWarrantReducer.defInfoObject.defDlState) {
            return { label: "-- Select --", value: "" };
          }
        }),
        labelName: "DL State",
        defaultValue: { label: "-- Select --", value: "" },
        onChange: handleSelectChange("defDlState"),
        isError: errorKeys.includes("defDlState"),
        isLabeled: true,
        isDefaultLabel: true,
      },
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "Document Control Number",
        name: "dcn",
        value: arrestWarrantReducer.defInfoObject.dcn,
        isError: errorKeys.includes("dcn"),
        onChange: handleValChange,
      },
    },
    {
      type: "empty",
      colSize: 3,
    },
    {
      type: "checkbox",
      props: {
        label: "Unknow Address",
        name: "defAddressUnknown",
        onChange: handleValChange,
        value: arrestWarrantReducer.defInfoObject.defAddressUnknown,
        checked:
          arrestWarrantReducer.defInfoObject.defAddressUnknown === "Y",
      },
    },
    {
      type: "checkbox",
      props: {
        label: "Foreign Address",
        name: "defForeignAddress",
        onChange: handleValChange,
        value: arrestWarrantReducer.defInfoObject.defForeignAddress,
        checked:
          arrestWarrantReducer.defInfoObject.defForeignAddress === "Y",
      },
    },
    {
      type: "empty",
      colSize: 6,
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "Address Line 1",
        name: "defAddress1",
        value: arrestWarrantReducer.defInfoObject.defAddress1,
        isError: errorKeys.includes("defAddress1"),
        onChange: handleValChange,
        disabled:
          arrestWarrantReducer.defInfoObject.defAddressUnknown === "Y",
      },
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "Address Line 2",
        name: "defAddress2",
        value: arrestWarrantReducer.defInfoObject.defAddress2,
        isError: errorKeys.includes("defAddress2"),
        onChange: handleValChange,
        disabled:
          arrestWarrantReducer.defInfoObject.defAddressUnknown === "Y",
      },
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "City",
        name: "defCity",
        value: arrestWarrantReducer.defInfoObject.defCity,
        isError: errorKeys.includes("defCity"),
        onChange: handleValChange,
        disabled:
          arrestWarrantReducer.defInfoObject.defAddressUnknown === "Y",
      },
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...STATE_LIST.map((item) => {
            return { label: item, value: item };
          }),
        ],
        value: [
          { label: "-- Select --", value: "" },
          ...STATE_LIST.map((item) => {
            return { label: item, value: item };
          }),
        ].find((item) => {
          if (item.label === arrestWarrantReducer.defInfoObject.defState) {
            return { label: item.label, value: item.value };
          } else if (!arrestWarrantReducer.defInfoObject.defState) {
            return { label: "-- Select --", value: "" };
          }
        }),
        labelName: "State",
        defaultValue: { label: "-- Select --", value: "" },
        onChange: handleSelectChange("defState"),
        isLabeled: true,
        isDefaultLabel: true,
        isDisabled:
          arrestWarrantReducer.defInfoObject.defAddressUnknown === "Y",
      },
      colHide: arrestWarrantReducer.defInfoObject.defForeignAddress === "Y",
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "State/Province",
        name: "defState",
        value: arrestWarrantReducer.defInfoObject.defState,
        isError: errorKeys.includes("defState"),
        onChange: handleValChange,
      },
      colHide:
        arrestWarrantReducer.defInfoObject.defAddressUnknown === "Y" ||
        !(
          arrestWarrantReducer.defInfoObject.defForeignAddress === "Y" ||
          arrestWarrantReducer.defInfoObject.defAddressUnknown === "Y"
        ),
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "Zip",
        name: "defZip",
        value: arrestWarrantReducer.defInfoObject.defZip,
        isError: errorKeys.includes("defZip"),
        onChange: handleValChange,
        disabled: arrestWarrantReducer.defInfoObject.defAddressUnknown === "Y",
        onBlur: handleOnBlurZipCode,
        placeholder: "XXXXX-XXXX",
        ref: zipCodeRef,
      },
    },
    {
      type: "selectInput",
      props: {
        options: [
          { label: "-- Select --", value: "" },
          ...complaintReducer.countries
            .filter(
              (item: string) =>
                !(
                  item === "UNITED STATES" &&
                  arrestWarrantReducer.defInfoObject.defForeignAddress
                )
            )
            .map((item) => {
              return { label: item, value: item };
            }),
        ],
        value: [
          { label: "-- Select --", value: "" },
          ...complaintReducer.countries.map((item) => {
            return { label: item, value: item };
          }),
        ].find((item) => {
          if (arrestWarrantReducer.defInfoObject.defCountry) {
            if (
              item.label === arrestWarrantReducer.defInfoObject.defCountry
            ) {
              return true;
            }
          }
        }),
        labelName: "Country",
        defaultValue: { label: "-- Select --", value: "" },
        onChange: handleSelectChange("defCountry"),
        isError: errorKeys.includes("defCountry"),
        isLabeled: true,
        isDefaultLabel: true,
        isDisabled: arrestWarrantReducer.defInfoObject.defAddressUnknown === "Y",
      },
    },
    {
      type: "textInput",
      props: {
        type: "text",
        labelName: "Telephone",
        name: "defTelephone",
        value: arrestWarrantReducer.defInfoObject.defTelephone,
        isError: errorKeys.includes("defTelephone"),
        onChange: handleValChange,
        placeholder: "XXX-XXX-XXXX",
      },
    },
  ];

  return (
    <Modal
      {...{ show, onHide }}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="modal-backdrop-info"
    >
      <Modal.Header closeButton>
        <Modal.Title>Defendant Information</Modal.Title>
      </Modal.Header>
      <div ref={scrollRef} />
      <Modal.Body>
        <Alert {...errorMessageAlert} />
        <FormComponent
          totalColumns={12}
          colSize={3}
          componentData={formComponentData}
          components={[
            {
              type: "tooltipTextField",
              node: (props: any) => (
                <OverlayTrigger
                  placement="top"
                  trigger="focus"
                  overlay={(
                    <Tooltip id="tooltip-disabled">{props.tooltip}</Tooltip>
                  )
                  }
                >
                  <TextInputComponent {...props} />
                </OverlayTrigger>
              )
            },
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent onClick={handleOnUpdateDefendant}>
          UPDATE DEFENDANT
        </ButtonComponent>
        <ButtonComponent variant="danger" onClick={onHide}>
          CANCEL
        </ButtonComponent>
      </Modal.Footer>
    </Modal>
  );
};
export default ModifyDefendantPopup;

const displayNonAsciiValues = (value: any) => {
  var asciiRegex = /[^\x00-\x7F]+/g;
  var cleanedText = value.match(asciiRegex);
  if (cleanedText) {
    return cleanedText;
  }

}

let defaultSchemaError = yup.object().shape({
  defDob: yup
    .string()
    .trim()
    .nullable()
    .required("'Date of Birth' is required.")
    .test("check-defDob-future", function (value) {
      let dateOfBirth: any = undefined;
      if (value) {
        dateOfBirth = moment(value, "YYYY-MM-DD");
      }
      let today: any = new Date();
      today.setHours(0, 0, 0, 0);
      if (dateOfBirth && dateOfBirth > today.getTime()) {
        return this.createError({
          message: "Future dates are not allow for Date of Birth",
        });
      } else {
        return true;
      }
    }),
  defFirstName: yup
    .string()
    .trim()
    .nullable()
    .required("'Defendant First Name' is required.")
    .test(  "check-with-FirstName", function (value) {
      if (value) {

        var cleanedText = displayNonAsciiValues(value);
        if (cleanedText) {
          return this.createError({
            message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field First Name .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
          });
        }


      }
      return true;

    }),
  defMiddleName:yup.string().trim().nullable().test( "check-with-MiddleName", function (value) {
    if (value) {

      var cleanedText = displayNonAsciiValues(value);
      if (cleanedText) {
        return this.createError({
          message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Middle Name .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
        });
      }


    }
    return true;

  }

  ),
  defLastName: yup
    .string()
    .trim()
    .nullable()
    .required("'Defendant Last Name' is required.").test( "check-with-LastName", function (value) {
      if (value) {
  
        var cleanedText = displayNonAsciiValues(value);
        if (cleanedText) {
          return this.createError({
            message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Last Name .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
          });
        }
  
  
      }
      return true;
  
    }
  
    ),
  gender: yup.string().trim().nullable().required("'Gender' is required."),
  defRace: yup.string().trim().nullable().required("'Race' is required."),
  defHair: yup.string().trim().nullable().required("'Hair' is required."),
  defEyes: yup.string().trim().nullable().required("'Eyes' is required."),
  defHeight: yup
    .string()
    .trim()
    .nullable().required("'Height' is required.")
    .test("check-defHeight-number", function (value) {
      if (value) {
  
        var cleanedText = displayNonAsciiValues(value);
        if (cleanedText) {
          return this.createError({
            message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Height .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
          });
        }
  
  
      }
      if (value && isNaN(Number(value))) {
        return this.createError({
          message: "Height must be a number",
        });
      }else if (value && heightFormatValidation(value)) {
        return this.createError({
          message: "The value entered in the 'Height' field is invalid. Enter height as numbers only (Example: 508).",
        });
      } else {
        return true;
      }
      // } else if (value && Number(value) > 0) {
      //   let height = value;
      //   let feet: any = height.substring(0, 1);
      //   let inches = null;
      //   if (height.length > 2) {
      //     inches = height.substring(1, 3);
      //   }
      //   feet = Number(feet);
      //   inches = Number(inches);
      //   if (feet > 7 || inches > 11) {
      //     return this.createError({
      //       message:
      //         "The value entered in the height field is invalid. Enter height as numbers only (Example: 508).",
      //     });
      //   }
      // }
      //return true;
    }),
  defWeight: yup
    .string()
    .trim()
    .nullable().required("'Weight' is required.")
    .test("check-defWeight-number", function (value) {
      if (value) {
  
        var cleanedText = displayNonAsciiValues(value);
        if (cleanedText) {
          return this.createError({
            message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Weight .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
          });
        }
  
  
      }
      if (value && isNaN(Number(value))) {
        return this.createError({
          message: "Weight must be a number",
        });
      }
      return true;
    }),
  defTelephone: yup
    .string()
    .trim()
    .nullable()
    .test("check-defTelephone-valid", function (value) {
      if (value && !/^(\d{3})?(-|\s)?\d{3}(-|\s)\d{4}$/.test(value)) {
        return this.createError({
          message: "Phone number is invalid",
        });
      }
      return true;
    }),
    dcn: yup
    .string()
    .trim()
    .nullable()
    .test("check-defTelephone-valid", function (value) {
      if (value) {
  
        var cleanedText = displayNonAsciiValues(value);
        if (cleanedText) {
          return this.createError({
            message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Document Control Number .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
          });
        }
  
  
      }
      return true;
    }

    ),
    defState:yup.string().trim().nullable().test("check-defTelephone-valid", function (value) {
      if (value) {
  
        var cleanedText = displayNonAsciiValues(value);
        if (cleanedText) {
          return this.createError({
            message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field State/Province .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
          });
        }
  
  
      }
      return true;
    }

    )

});

let addressSchemaError = yup.object().shape({
  defAddress1: yup.string().trim().nullable().required("'Address Line 1' is required.") .test("check-defTelephone-valid", function (value) {
    if (value) {

      var cleanedText = displayNonAsciiValues(value);
      if (cleanedText) {
        return this.createError({
          message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Address Line 1 .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
        });
      }


    }
    return true;
  }
  ),
  defAddress2:yup.string().trim().nullable().test("check-defTelephone-valid", function (value) {
    if (value) {

      var cleanedText = displayNonAsciiValues(value);
      if (cleanedText) {
        return this.createError({
          message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Address Line 2 .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
        });
      }


    }
    return true;
  }
  ),
  defCity: yup.string().trim().nullable().required("'City' is required.").test("check-defTelephone-valid", function (value) {
    if (value) {

      var cleanedText = displayNonAsciiValues(value);
      if (cleanedText) {
        return this.createError({
          message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field City .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
        });
      }


    }
    return true;
  }
  ),
  defTelephone: yup.string().trim().nullable().test("check-defTelephone-valid", function (value) {
    if (value) {

      var cleanedText = displayNonAsciiValues(value);
      if (cleanedText) {
        return this.createError({
          message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field Telephone .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
        });
      }


    }
    return true;
  }

  ),
  defZip: yup.string().trim().nullable().required("'Zip' is required."),
});

let foreingAddressSchemaError = yup.object().shape({
  defCountry: yup
    .string()
    .trim()
    .nullable()
    .required("'Defendant Country' is required.")
    .test("check-defCountry-isNotUSA", function (value) {
      if (value && value === "UNITED STATES") {
        return this.createError({
          message: "'UNITED STATES is not valid for Foreign Address.",
        });
      }
      return true;
    }),
  defState: yup.string().nullable().trim().required("'State/Province' is required."),
});

let localAddressSchemaError = yup.object().shape({
  defCountry: yup.string().trim().nullable().required("'Defendant Country' is required."),
  defState: yup.string().trim().nullable().required("'State' is required."),
});

let defDriversLicenseSchemaError = yup.object().shape({
  defDriversLicense: yup
    .string()
    .trim()
    .required("'DL Number' and 'DL State' both optional or both required.")
    .test(  "check-with-DriversLicense", function (value) {
      if (value) {

        var cleanedText = displayNonAsciiValues(value);
        if (cleanedText) {
          return this.createError({
            message: "Non printable text/characters( " + cleanedText + " ) have been pasted or added into the field DL Number .Delete all of the text and manually re-type it or copy/paste the text from notepad to attempt to remove the bad characters.",
          });
        }


      }
      return true;

    }),
});

let defDlStateSchemaError = yup.object().shape({
  defDlState: yup
    .string()
    .trim()
    .required("'DL Number' and 'DL State' both optional or both required."),
});
