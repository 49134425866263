import { Button, Col, Modal, Row } from "react-bootstrap"
import SelectInput from "../../common/SelectComponent"
import React, { useCallback, useEffect } from "react";
import FileUploader from "./FileUploader";
import { APPLICATION_AND_ORDER, COMPL_SEARCH_WARRANT_WITH_SLASH, DEVELOPER_OPTION } from "../../../constants/common";
import { generateDocument, modifyDocument, updateElementValueCaseDetailReducer, useCaseDetailReducer } from "../../../redux/slice/caseDetailSlice";
import { useAppDispatch } from "../../../hooks/hooks";
import ConfirmPopup from "../../common/ConfirmPopup";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../../common/Alert";

let defaultCountSchema = yup.object().shape({
  formTypeName: yup.string().ensure().when('formType', { is: false, then: yup.string().nullable().required("Form Type is required.") }),
  file: yup.string().ensure().when('uploadedFile', { is: false, then: yup.string().nullable().required("PDF is required.") }),
});

interface DeveloperOptionPopupProps {
  show: boolean;
  onHide: () => void;
  isGenerate: boolean;
  formTypeName: string;
  formId: number;
}

const DeveloperOptionPopup: React.FC<DeveloperOptionPopupProps> = (props) => {
  const { show, onHide, isGenerate, formTypeName, formId } = props

  const dispatch = useAppDispatch();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const caseDetailReducer = useCaseDetailReducer();
  const [formType, setFormType] = React.useState("");
  const [formList, setFormList] = React.useState([{}]);
  const [confirmPopupShow, setConfirmPopupShow] = React.useState(false);

  useEffect(() => {
    if (show) {
      if (formTypeName === COMPL_SEARCH_WARRANT_WITH_SLASH) {
        setFormList([{ label: "--Select--", value: "" },
        { label: "Complaint", value: "Complaint" },
        { label: "Search Warrant", value: "Search Warrant" }]);
      } else if (formTypeName === APPLICATION_AND_ORDER) {
        setFormList([{ label: "--Select--", value: "" },
        { label: "Application", value: "Application" },
        { label: "Order", value: "Oder" }]);
      } else {
        setFormList([{}]);
        setFormType(formTypeName)
      }
    }
  }, [show])

  const cancelPopup = () => {
    onHide();
    setFormType("");
    setFormList([{}]);
    dispatch(updateElementValueCaseDetailReducer({ elementName: "uploadedFileName", value: "" }));
    dispatch(updateElementValueCaseDetailReducer({ elementName: "uploadedFile", value: "" }));
    resetAlert();
  }

  const resetAlert = () =>{
    setErrorKeys(['']);
    handleError([''], []);
  }

  const hadleValChange = (e: any) => {
    setFormType(e.value);
  }

  const generate = async() => {
    resetAlert()
    let Object = validationObject();
    try{
      await defaultCountSchema.validate(Object, { abortEarly: false, });
      let Obj = {
        searchWarrantid: formId,
        formType: formType,
      }
      dispatch(generateDocument(Obj));
      cancelPopup();
    }catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  
  }

  const save = async() => {
    resetAlert()
    let Obj = validationObject();
    try{
      await defaultCountSchema.validate(Obj, { abortEarly: false, });
      saveAfterValidation();
    }catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }

  const saveAfterValidation = () =>{
    let Obj = {
      searchWarrantid: formId,
      formType: formType,
      file: caseDetailReducer.uploadedFile,
    }
    dispatch(modifyDocument(Obj));
    setConfirmPopupShow(false);
    cancelPopup();
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const validationObject = () =>{
    let Obj;
    if (formTypeName === COMPL_SEARCH_WARRANT_WITH_SLASH || formTypeName === APPLICATION_AND_ORDER){
      if (isGenerate){
        Obj = {
          formType: formType? true: false,
          uploadedFile: true,
        }
      } else{
        Obj = {
          formType: formType? true: false,
          uploadedFile: caseDetailReducer.uploadedFile?true:false,
        }
      }
    } else{
      if(isGenerate){
        Obj = {
          formType: true,
          uploadedFile: true,
        }
      } else{
        Obj = {
          formType: true,
          uploadedFile: caseDetailReducer.uploadedFile?true:false,
        }
      }
    }
    return Obj;
  }

  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-30w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => cancelPopup()}
        backdrop="static"
      >
        <Modal.Header closeButton><b>Select Form Type</b></Modal.Header>

        <Modal.Body>
        <ErrorMessageAlert
            messages={errorMessageAlert.messages}
            clearError={errorMessageAlert.clearError}
          />
          <Row>
            <Col className="pt-2" md={"auto"}>
              <label className="col-form-label">Form Type <span className="text-danger">*</span>&nbsp;: </label>
            </Col>
            {(formTypeName === COMPL_SEARCH_WARRANT_WITH_SLASH || formTypeName === APPLICATION_AND_ORDER) ?
              <Col className="pt-2">
                <SelectInput
                  name="formType"
                  placeholder="-- Select --"
                  isError = {errorKeys.includes("formTypeName")}
                  options={[
                    ...formList.map((item: any) => {
                      return { label: item.label, value: item.value }
                    })
                  ]}
                  value={formList.map((data: any) => {
                    return { label: data.label, value: data.value }
                  }).find((item: any) => {
                    if (item.value === formType)
                      return { label: item.label, value: item.value }
                    else if (!formType)
                      return { label: "--Select--", value: "" }
                  })}
                  onChange={hadleValChange}
                  isDefaultLabel={true}
                />
              </Col>
              :
              <Col className="pt-3">{formTypeName}</Col>
            }
          </Row>
          {!isGenerate ?
            <Row className="pt-3">
              <Col md={"auto"} style={{ marginTop: 4 }}> PDF to upload : </Col>
              <Col >
                <FileUploader
                  formType={DEVELOPER_OPTION}
                  fileName={caseDetailReducer.uploadedFileName}
                />
              </Col>

            </Row>
            :
            ""
          }
          <hr />
          <Row>
            <Col className=" text-end">
              <Button onClick={isGenerate ? generate : save}>{isGenerate ? "generate" : "save"}</Button>
            </Col>
          </Row>

        </Modal.Body>
      </Modal>
      <ConfirmPopup
        children={`Existing pdf will be replaced, please confirm.`}
        show={confirmPopupShow}
        onHide={() =>
          setConfirmPopupShow(false)
        }
        isConfirmation={saveAfterValidation}
      />
    </>
  )
}

export default DeveloperOptionPopup;