import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { updateElementObject } from "../../utils/updateElementObject";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { getEcomplaintMainDetails,updateElementObjectValueComplaintMain } from "../slice/complaintMainSlice";
import { properties, LF_VOID_STATUS, LF_DELETED_STATUS, LF_RETURNED_STATUS, LF_REJECTED_STATUS, LF_SEND_TO_SA_STATUS, LF_SEND_TO_OFFICER_STATUS, FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE, LF_PENDING_STATUS, LF_STATUS_PENDING_SUPERVISOR_RETURNED} from '../../constants/common';
import { setComplaintMainData } from "./complaintMainSlice";
import { fetchArrestWarrant } from "../../redux/slice/arrestWarrantSlice";
import { getLFCConcurrentObj } from "./commonFormSlice";
import { fetchSummons } from "../../redux/slice/summonsSlice";

interface complaintDashboardReducer {
    dashboardStatus: LoadingType;
    dashboardError?: string;
    successMessage?: string;
    failureMessage?: string;
}

const initialState = {
  dashboardStatus: "idle",
  dashboardError: undefined,
  successMessage: "",
  failureMessage: "",
} as complaintDashboardReducer;

export const editComplaint = createAsyncThunk<
  any,
  { formId:number, formType:string, ecomplaintId:number, status:any, formData?: any; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/editComplaint",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { formId, formType, ecomplaintId, status, formData, navigate } = data;
    
    let { token } = getState().login;

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      dispatch(showHideLoadingGif(true));
      const reponse = await doFetch({
        url: `ecomplaintservices/updateEcomplaint/${formId}/${formType}/${ecomplaintId}/${status}`,
        type: "PUT",
        token,
        body: formData?formData:{},
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch : dispatch
      });
      dispatch(showHideLoadingGif(false));
      if (reponse.ok) {
        reponse.json().then((result)=>{
          console.log(result.complaint);
          dispatch(updateElementObjectValueComplaintMain(result));
          dispatch(
            getEcomplaintMainDetails({
              ecomplaintId: ecomplaintId,
              callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
            })
          );
        })
        let message = "";
        if (status === LF_VOID_STATUS) {
            message = "The complaint has been void.";
        } else if (status === LF_DELETED_STATUS) {
            message = "The complaint has been deleted.";
        } else if (status === LF_RETURNED_STATUS || status === LF_STATUS_PENDING_SUPERVISOR_RETURNED) {
            message = "The complaint has been returned.";
            navigate("/eComplaintDashBoard");
        } else if (status === LF_REJECTED_STATUS) {
            message = "The complaint has been rejected.";
            navigate("/eComplaintDashBoard");
        } else if (status === LF_SEND_TO_SA_STATUS || status === LF_SEND_TO_OFFICER_STATUS) {
            navigate("/eComplaintDashBoard");
        } else if(status === LF_PENDING_STATUS){
          navigate("/eComplaintDashBoard");
        }
        
        if (message.trim().length > 0) {
          dispatch(
            updateElementValuecomplaintDashReducer({
              elementName: "successMessage",
              value: message,
            })
          );
        }
        
    } else {
        dispatch(
          updateElementValuecomplaintDashReducer({
            elementName: "failureMessage",
            value: "Error Occurred",
          })
        );
    }
    dispatch(showHideLoadingGif(false));
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      dispatch(
        updateElementValuecomplaintDashReducer({
          elementName: "failureMessage",
          value: "Communication error occurred. Can not proceed now",
        })
      );
    }
    }
);


export const editComplaintFromSummons = createAsyncThunk<
  any,
  { formId:number, formType:string, ecomplaintId:number, status:any, formData?: any; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/editComplaintfromSummons",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { formId, formType, ecomplaintId, status, formData, navigate } = data;
    
    let { token } = getState().login;

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      dispatch(showHideLoadingGif(true));
      const reponse = await doFetch({
        url: `ecomplaintservices/updateEcomplaintFromSummons/${formId}/${formType}/${ecomplaintId}/${status}`,
        type: "PUT",
        token,
        body: formData?formData:{},
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch : dispatch
      });
      dispatch(showHideLoadingGif(false));
      if (reponse.ok) {
        reponse.json().then((result)=>{
          console.log(result.complaint);
          dispatch(updateElementObjectValueComplaintMain(result));
          dispatch(
            getEcomplaintMainDetails({
              ecomplaintId: ecomplaintId,
              callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
            })
          );
        })
        let message = "";
        if (status === LF_VOID_STATUS) {
            message = "The complaint has been void.";
        } else if (status === LF_DELETED_STATUS) {
            message = "The complaint has been deleted.";
        } else if (status === LF_RETURNED_STATUS || status === LF_STATUS_PENDING_SUPERVISOR_RETURNED) {
            message = "The complaint has been returned.";
            navigate("/eComplaintDashBoard");
        } else if (status === LF_REJECTED_STATUS) {
            message = "The complaint has been rejected.";
            navigate("/eComplaintDashBoard");
        } else if (status === LF_SEND_TO_SA_STATUS || status === LF_SEND_TO_OFFICER_STATUS) {
            navigate("/eComplaintDashBoard");
        } else if(status === LF_PENDING_STATUS){
          navigate("/eComplaintDashBoard");
        }
        
        if (message.trim().length > 0) {
          dispatch(
            updateElementValuecomplaintDashReducer({
              elementName: "successMessage",
              value: message,
            })
          );
        }
        
    } else {
        dispatch(
          updateElementValuecomplaintDashReducer({
            elementName: "failureMessage",
            value: "Error Occurred",
          })
        );
    }
    dispatch(showHideLoadingGif(false));
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      dispatch(
        updateElementValuecomplaintDashReducer({
          elementName: "failureMessage",
          value: "Communication error occurred. Can not proceed now",
        })
      );
    }
    }
);

export const resubmitAccordingToTheFormType = createAsyncThunk<
  any,
  { ecomplaintId: number, fromScheduler: boolean , formType: string},
  { state: RootState; rejectValue: string }
>("ecomplaint/resubmitAccordingToTheFormType",
  async ({ecomplaintId,fromScheduler,formType}, { getState, dispatch }) => {

    let { token } = getState().login;

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      dispatch(showHideLoadingGif(true));
      const response = await doFetch({
        url: `ecomplaintservices/reSubmitForm/${ecomplaintId}/${fromScheduler}/${formType}`,
        type: "POST",
        token,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        const responseData = await response.json();
        if (responseData) {
          dispatch(
            getEcomplaintMainDetails({
              ecomplaintId: ecomplaintId,
              callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
            })
          );
        }
      } else {
        dispatch(
          updateElementValuecomplaintDashReducer({
            elementName: "failureMessage",
            value: "Error Occurred",
          })
        );
      }
      dispatch(showHideLoadingGif(false));
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      dispatch(
        updateElementValuecomplaintDashReducer({
          elementName: "failureMessage",
          value: "Communication error occurred. Can not proceed now",
        })
      );
    }
  }
)

export const deleteArrestWarrant = createAsyncThunk<
  any,
  { formId:number, formType:string, ecomplaintId:number, status:string },
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/deleteArrestWarrant",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { formId, formType, ecomplaintId, status } = data;
    let { token } = getState().login;

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      dispatch(showHideLoadingGif(true));
      const reponse = await doFetch({
        url: `ecomplaintservices/deleteEcForms/${formId}/${ecomplaintId}`,
        type: "PUT",
        token,
        body: JSON.stringify({}),
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch : dispatch
      });
      dispatch(showHideLoadingGif(false));
      if (reponse.ok) {
        reponse.json().then((result)=>{
          console.log(result);
          dispatch(updateElementObjectValueComplaintMain(result));
          dispatch(
            getEcomplaintMainDetails({
              ecomplaintId: ecomplaintId,
              callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
            })
          );
        })
        

        let message = "The Arrest Warrant has been deleted.";

        if (message.trim().length > 0) {
            dispatch(
              updateElementValuecomplaintDashReducer({
                elementName: "successMessage",
                value: message,
              })
            );
        }
        
    } else {
        dispatch(
          updateElementValuecomplaintDashReducer({
            elementName: "failureMessage",
            value: "Error Occurred",
          })
        );
    }
    dispatch(showHideLoadingGif(false));
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      dispatch(
        updateElementValuecomplaintDashReducer({
          elementName: "failureMessage",
          value: "Communication error occurred. Can not proceed now",
        })
      );
    }
    }
);

export const updateLockById = createAsyncThunk<
  any,
  { formId:number, ecomplaintId:number, action:string },
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/deleteArrestWarrant",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { formId, ecomplaintId, action } = data;
    let { token } = getState().login;

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      dispatch(showHideLoadingGif(true));
      // dispatch(getEcomplaintMainDetails())
      const reponse = await doFetch({
        url: `ecomplaintservices/updateLockById/${formId}/${ecomplaintId}/${action}`,
        type: "PUT",
        token,
        body: JSON.stringify({}),
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch : dispatch
      });
      dispatch(showHideLoadingGif(false));
      if (reponse.ok) {
        reponse.json().then((result)=>{
          console.log(result);
          dispatch(updateElementObjectValueComplaintMain(result));
          dispatch(
            getEcomplaintMainDetails({
              ecomplaintId: ecomplaintId,
              callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
            })
          );
        })
        
    } else {
      // dispatch(fetchEComplaintMainFailed("Error Occurred"));
    }
    dispatch(showHideLoadingGif(false));
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
        console.log(error);
    }
    }
);

export const undoCertification = createAsyncThunk<
  any,
  { complaintId: number },
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/undoCertification",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { complaintId } = data;
    let { token } = getState().login;

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      dispatch(showHideLoadingGif(true));
      const reponse = await doFetch({
        url: `ecomplaintservices/undoCertification/${complaintId}`,
        type: "POST",
        token,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch : dispatch        
      });

      dispatch(showHideLoadingGif(false));
      if (reponse.ok) {
        dispatch(
          getEcomplaintMainDetails({
            ecomplaintId: complaintId,
            callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
          })
        );
        return reponse.json();
      }
      return rejectWithValue("Error while making undo Certification");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while making undo Certification");
    }
  }
);

export const takeComplaintBack = createAsyncThunk<
  any,
  { complaintId: number; objUse: any; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/takeComplaintBack",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { complaintId, objUse, navigate } = data;
    let { token } = getState().login;
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      dispatch(showHideLoadingGif(true));
      const reponse = await doFetch({
        url: `ecomplaintservices/takeComplaintBack/${complaintId}`,
        type: "POST",
        token,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch : dispatch
      });

      dispatch(showHideLoadingGif(false));
      if (reponse.ok) {
        reponse.json().then((result)=>{
          dispatch(setComplaintMainData(result));
          // dispatch(
          //   getEcomplaintMainDetails({
          //     ecomplaintId: complaintId,
          //     callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
          //   })
          // );
          // dispatch(updateElementObjectValueComplaintMain(result));
        dispatch(getEcomplaintMainDetails({ecomplaintId: complaintId, callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE}));
        if ("Complaint" === objUse.formType) {
          navigate("/eComplaintMainPage");
        } else if ("Arrest Warrant" === objUse.formType) {
          // dispatch(setECFormData(objUse.formId, result.ecForm.status));
          dispatch(
            fetchArrestWarrant(objUse.formId)
          ).then((res) => {
            if (res){
              navigate("/arrestWarrant");
            }
          })
        } else if ("Summon" === objUse.formType) {
          dispatch(
            fetchSummons(objUse.formId)
          ).then((res) => {
            if (res){
              navigate("/issueSummons");
            }
          })
        }
      }
      )}
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching agencies for LFC county"
      );
    }
  }
);

const complaintDashSlice = createSlice({
  name: "editComplaint",
  initialState,
  reducers: {
    updateElementValuecomplaintDashReducer: updateElementValue,
    // updateElementObjectValueComplaintDash: (state: complaintDashboardReducer, action) => {
    //   updateElementObject(state, action, state.complaint);
    // },
  },
  extraReducers(builder) {
    builder

    .addCase(editComplaint.pending, (state) => {
      state.dashboardStatus = "loading";
      state.dashboardError = undefined;
    })
    .addCase(editComplaint.fulfilled, (state, action) => {
      state.dashboardStatus = "success";
      // state.complaint = action.payload.complaint;
    })
    .addCase(editComplaint.rejected, (state, action) => {
      state.dashboardStatus = "error";
      state.dashboardError = undefined;
    })
    .addCase(editComplaintFromSummons.pending, (state) => {
      state.dashboardStatus = "loading";
      state.dashboardError = undefined;
    })
    .addCase(editComplaintFromSummons.fulfilled, (state, action) => {
      state.dashboardStatus = "success";
      // state.complaint = action.payload.complaint;
    })
    .addCase(editComplaintFromSummons.rejected, (state, action) => {
      state.dashboardStatus = "error";
      state.dashboardError = undefined;
    })
    .addCase(resubmitAccordingToTheFormType.pending,(state) => {
      state.dashboardStatus = "loading";
      state.dashboardError = undefined;
    })
    .addCase(resubmitAccordingToTheFormType.fulfilled,(state,action) => {
      state.dashboardStatus = "success";
      
    })
    .addCase(resubmitAccordingToTheFormType.rejected,(state) => {
      state.dashboardStatus = "error";
      state.dashboardError = undefined;
      
    })

    .addCase(deleteArrestWarrant.pending, (state) => {
      state.dashboardStatus = "loading";
      state.dashboardError = undefined;
    })
    .addCase(deleteArrestWarrant.fulfilled, (state, action) => {
      state.dashboardStatus = "success";
    })
    .addCase(deleteArrestWarrant.rejected, (state, action) => {
      state.dashboardStatus = "error";
      state.dashboardError = undefined;
    })

    .addCase(undoCertification.pending, (state) => {
      state.dashboardStatus = "loading";
      state.dashboardError = undefined;
    })
    .addCase(undoCertification.fulfilled, (state, action) => {
      state.dashboardStatus = "success";
      // state.complaint = action.payload.complaint;
    })
    .addCase(undoCertification.rejected, (state, action) => {
      state.dashboardStatus = "error";
      state.dashboardError = undefined;
    })
  },
});

export const { updateElementValuecomplaintDashReducer } = complaintDashSlice.actions;

export const useComplaintDashReducer = () => useAppSelector((state) => state.complaintDashboard);

export default complaintDashSlice.reducer;
