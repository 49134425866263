import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { RiQuestionLine, RiCheckFill, RiCloseFill } from "react-icons/ri";

interface ConfirmPopupProps {
  show: boolean;
  onHide: () => void;
  isConfirmation: () => void;
  children?: React.ReactNode;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = (props) => {
  const { show, onHide, isConfirmation, children } = props;

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <b>Confirmation</b>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="text-center">
            <h1>
              <RiQuestionLine className="text-danger" />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-2">
            {children}
          </Col>
        </Row>
        <hr />
        <Row className="g-2">
          <Col>
            <Button
              variant="primary"
              onClick={() => isConfirmation()}
              className="w-100"
            >
              <RiCheckFill /> Yes
            </Button>
          </Col>
          <Col>
            <Button variant="danger" onClick={() => onHide()} className="w-100">
              <RiCloseFill /> No
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmPopup;
