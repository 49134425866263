import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import ButtonComponent from "../common/ButtonComponent";
import Header from "../common/Header";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  RiSearchLine,
  RiAddCircleLine,
  RiDraftFill,
  RiFileExcelLine,
  RiCheckboxMultipleLine,
  RiUserShared2Line,
  RiKeyFill,
} from "react-icons/ri";
import {
  BsFillShieldLockFill,
  BsFillShieldSlashFill,
} from "react-icons/bs";
import ListViewComponent from "../common/ListViewComponent";
import {
  listUser,
  useUserProfileReducer,
  updateUserStatus,
  resetUserProfileList,
  updateElementValueUserProfileReducer,
  updateUserProfileReducer,
} from "../../redux/slice/userProfileListSlice";
import {
  useCountyListReducer,
  resetCountiesData,
  getLoggedInUserCounties,
} from "../../redux/slice/countyListSlice";
import {
  useAgencyListReducer,
  resetAgenciesData,
  getLoggedInUserAgencies,
  updateElementValueAgencyListReducer,
} from "../../redux/slice/agencyListSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import { useAppDispatch } from "../../hooks/hooks";
import {
  ROLE_COUNTY_ADMIN,
  ROLE_AGENCY_ADMIN,
  ROLE_POLICE_OFFICER,
  ROLE_STATES_ATTORNEY,
  ROLE_JUDGE,
  ROLE_CIRCUIT_CLERK,
  ROLE_CASE_CREATOR,
  ROLE_ATTORNEY_GENERAL,
  ROLE_LOCAL_PROSECUTOR,
  ROLE_SA_ADMIN,
  ACTIVE_STATUS,
  DEACTIVE_STATUS,
  ROLE_ADMINISTRATOR,
} from "../../constants/common";
import ConfirmPopup from "../common/ConfirmPopup";
import UserImport from "./UserImport";
import AlertMessage from "../common/AlertMessage";
import {
  updateElementValueUserProfilesReducer,
  useUserProfilesReducer,
} from "../../redux/slice/userProfileSlice";
import UserPassWordReset from "./UserPassWordReset";
import { TableActionComponent } from "../common/TableActionComponent";
import {
  agencyListByCounty,
  useSelectCountyAgencyReducer,
  updateElementValueSelectCountyAgencyReducer,
} from "../../redux/slice/selectCountyAgencySlice";
import { twoFactorDisableUser } from "../../redux/slice/userProfileSlice"
import { FaSignature } from "react-icons/fa";

interface UserListProps {}

let uTypeObj = [
  ROLE_COUNTY_ADMIN,
  ROLE_AGENCY_ADMIN,
  ROLE_POLICE_OFFICER,
  ROLE_STATES_ATTORNEY,
  ROLE_JUDGE,
  ROLE_CIRCUIT_CLERK,
  ROLE_CASE_CREATOR,
  ROLE_ATTORNEY_GENERAL,
  ROLE_LOCAL_PROSECUTOR,
  ROLE_SA_ADMIN,
];

export const UserList: React.FC<UserListProps> = (props) => {
  const {
    userList,
    totalPages,
    currentPage,
    totalRecords,
    countyId,
    agencyId,
    userTypename,
    firstName,
    lastName,
    userStatus,
    userName,
    badgeNumber,
    localCounty,
    localAgency,
    pagedUserListFetchstatus,
  } = useUserProfileReducer();
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const userReducer = useUserProfilesReducer();
  const { countyList, loggedInUserCountyFetchStatus } = useCountyListReducer();
  const { agencyList, loggedInUseragenciFetchStatus } = useAgencyListReducer();
  const { userType, canAccessApplicationValue } = useLoginReducer();
  const { agenciesForCounty, status } = useSelectCountyAgencyReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [importUserVisible, setImportUserVisible] = useState(false);

  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
    userProfileID: 0,
    active: false,
  });

  const [userImportPopupData, setUserImportPopupData] = React.useState({
    show: false,
  });

  const [confirmPopupData2F, setconfirmPopupData2F] = React.useState({
    show: false,
    userProfileID: 0,
    twoFactorEnable: false,
  });

  useEffect(() => {
    return () => {
      dispatch(resetUserProfileList());
      dispatch(resetCountiesData());
      dispatch(resetAgenciesData());
    };
  }, [dispatch]);

  const changeUserImportPopupData = () => {
    setUserImportPopupData({ show: true });
  };

  const [userPasswordResetPopup, setUserPasswordResetPopup] = React.useState({
    show: false,
    userName: "",
  });

  const CountyListOptions: any = [];
  if (countyList.length > 1) {
    CountyListOptions.push({ label: "-- All --", value: {} });
  }
  CountyListOptions.push(
    ...countyList.map((county: any) => {
      return { label: county.countyName, value: county };
    })
  );

  const AgencyListOptions: any = [];
  if (agencyList.length > 1) {
    AgencyListOptions.push({ label: "-- All --", value: {} });
  }
  AgencyListOptions.push(
    ...agencyList.map((agency: any) => {
      return { label: agency.agencyName, value: agency };
    })
  );

  const handleUserPasswordReset = (userName: string) => () => {
    setUserPasswordResetPopup({
      show: true,
      userName: userName,
    });
  };

  const handleUserStatusUpdate =
    (userProfileID: number, active: boolean) => () => {
      setconfirmPopupData({
        show: true,
        userProfileID,
        active,
      });
    };

  const handleUserStatusUpdateConfirm = () => {
    let newPageNo = parseInt(searchParams.get("pageNo")?.toString() || "0");
    let urlPathObj: any = {
      countyId: searchParams.get("countyId"),
      agencyId: searchParams.get("agencyId"),
      userTypename: searchParams.get("userTypename"),
      firstName: searchParams.get("firstName"),
      lastName: searchParams.get("lastName"),
      status: searchParams.get("status") || "true",
      username: searchParams.get("username") || "",
      badgeNumber: searchParams.get("badgeNumber"),
      pageNo: newPageNo > 0? newPageNo -1 : newPageNo,
      userTypeObj: uTypeObj,
    };

    let dataObj = {
      userId: confirmPopupData.userProfileID,
      userTypeObj: uTypeObj,
      status: "true",
      listViewData: urlPathObj,
    };

    dispatch(updateUserStatus(dataObj));
    setconfirmPopupData({
      show: false,
      userProfileID: 0,
      active: false,
    });
  };

  const handleDisabletwoFactor = (userProfileID:number, twoFactorEnable:boolean) => () => {
    setconfirmPopupData2F({
      show: true,
      userProfileID: userProfileID,
      twoFactorEnable: twoFactorEnable,
    });
  }

  const handlSetSignature = (userTypeID:any) => () => {
    navigate("/captureSignature", { state: { signature: true, userId: userTypeID } });
  }

  const disabletwoFactor = () => {
    dispatch(twoFactorDisableUser({
      userId: confirmPopupData2F.userProfileID,
      twoFactorStatus: confirmPopupData2F.twoFactorEnable ? "Disable" : "Enable",
    }
    )).then((res)=>{
        setAlertMessage(res.payload.message);
        let newPageNo = parseInt(searchParams.get("pageNo")?.toString() || "0");
        let urlPathObj: any = {
        countyId: searchParams.get("countyId"),
        agencyId: searchParams.get("agencyId"),
        userTypename: searchParams.get("userTypename"),
        firstName: searchParams.get("firstName"),
        lastName: searchParams.get("lastName"),
        status: searchParams.get("status") || "true",
        username: searchParams.get("username") || "",
        badgeNumber: searchParams.get("badgeNumber"),
        pageNo: newPageNo > 0? newPageNo -1 : newPageNo,
        userTypeObj: uTypeObj,
      };
      dispatch(listUser(urlPathObj));
    })
    setconfirmPopupData2F({
      show: false,
      userProfileID: 0,
      twoFactorEnable: false,
    });
  }

  const populateUTypeObj = () => {
  if (userType === ROLE_AGENCY_ADMIN) {
    uTypeObj = [ROLE_AGENCY_ADMIN, ROLE_POLICE_OFFICER];
  } else if (userType === ROLE_COUNTY_ADMIN) {
    uTypeObj = [
      ROLE_COUNTY_ADMIN,
      ROLE_AGENCY_ADMIN,
      ROLE_POLICE_OFFICER,
      ROLE_STATES_ATTORNEY,
      ROLE_JUDGE,
      ROLE_CIRCUIT_CLERK,
      ROLE_LOCAL_PROSECUTOR,
      ROLE_SA_ADMIN,
    ];
  } else if (userType === ROLE_CIRCUIT_CLERK) {
    uTypeObj = [ROLE_CIRCUIT_CLERK];
  } else if (userType === ROLE_ATTORNEY_GENERAL) {
    uTypeObj = [ROLE_ATTORNEY_GENERAL, ROLE_POLICE_OFFICER];
  } else if (userType === ROLE_SA_ADMIN) {
    uTypeObj = [ROLE_STATES_ATTORNEY, ROLE_SA_ADMIN];
  } else if (userType === ROLE_ADMINISTRATOR) {
    uTypeObj = [
      ROLE_COUNTY_ADMIN,
      ROLE_AGENCY_ADMIN,
      ROLE_POLICE_OFFICER,
      ROLE_STATES_ATTORNEY,
      ROLE_JUDGE,
      ROLE_CIRCUIT_CLERK,
      ROLE_CASE_CREATOR,
      ROLE_ATTORNEY_GENERAL,
      ROLE_LOCAL_PROSECUTOR,
      ROLE_SA_ADMIN,
    ];
  }
}

  const isTokenValueAvailableForImport = () => {
    const items = canAccessApplicationValue.split(";");
    if (items.includes("IMPORT_USERS")) {
      setImportUserVisible(true);
    } else {
      setImportUserVisible(false);
    }
  };

  useEffect(() => {
    // Clearing the search params on page load/ refresh
    // navigate(location.pathname);
    if (location.search === ""){
    populateUTypeObj();
    dispatch(getLoggedInUserCounties());
    dispatch(getLoggedInUserAgencies());
    console.log(uTypeObj);
    dispatch(
      listUser({
        userTypeObj: uTypeObj,
        status: "true",
      })
    );
    isTokenValueAvailableForImport();
    }
  }, []);

  const setUrlData = () => {
    let countValue = "-- All --";
    let countIdValue = parseInt(
      searchParams.get("countyId")?.toString() || "0"
    );
    dispatch(getLoggedInUserCounties());
    if (countIdValue === 0) {
      dispatch(getLoggedInUserAgencies());
    } else {
      dispatch(agencyListByCounty({ countyId: countIdValue }));
    }

    countyList.find((item: any) => {
      if (
        item.countyID ===
        parseInt(searchParams.get("countyId")?.toString() || "0")
      ) {
        countValue = item.countyName;
      }
    });

    let agencyValue = "-- All --";
    agencyList.find((item: any) => {
      if (
        item.agencyId ===
        parseInt(searchParams.get("agencyId")?.toString() || "0")
      ) {
        agencyValue = item.agencyName;
      }
    });
    let obj: any = {
      countyId: parseInt(searchParams.get("countyId")?.toString() || "0"),
      agencyId: parseInt(searchParams.get("agencyId")?.toString() || "0"),
      localCounty: countValue,
      localAgency: agencyValue,
      userTypename: searchParams.get("userTypename"),
      firstName: searchParams.get("firstName") || "",
      lastName: searchParams.get("lastName") || "",
      status: searchParams.get("status") || "true",
      userName: searchParams.get("username") || "",
      badgeNumber: searchParams.get("badgeNumber"),
    };

    dispatch(updateUserProfileReducer(obj));
  };

  useEffect(() => {
    if (userReducer.successMessage) {
      setAlertMessage(userReducer.successMessage);

      dispatch(
        updateElementValueUserProfilesReducer({
          elementName: "successMessage",
          value: "",
        })
      );
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [userReducer.successMessage, dispatch]);

  useEffect(() => {
    dispatch(
      updateElementValueAgencyListReducer({
        elementName: "agencyList",
        value: agenciesForCounty,
      })
    );
    dispatch(
      updateElementValueSelectCountyAgencyReducer({
        elementName: "status",
        value: "loading",
      })
    );
  }, [status == "success"]);

  useEffect(() => {
    if (
      loggedInUserCountyFetchStatus == "success" &&
      loggedInUseragenciFetchStatus == "success"
    ) {
      getAvailableCountyIds();
      getAvailableAgencyIds();
      getAvailableUserTypes();
    }
  }, [loggedInUserCountyFetchStatus, loggedInUseragenciFetchStatus]);

  const getAvailableCountyIds = () => {
    if (CountyListOptions.length === 1) {
      dispatch(
        updateElementValueUserProfileReducer({
          elementName: "localCounty",
          value: CountyListOptions[0].label,
        })
      );
    }
  };

  const getAvailableAgencyIds = () => {
    if (AgencyListOptions.length === 1) {
      dispatch(
        updateElementValueUserProfileReducer({
          elementName: "localAgency",
          value: AgencyListOptions[0].label,
        })
      );
    }
  };

  const getAvailableUserTypes = () => {
    if (uTypeObj.length === 1) {
      dispatch(
        updateElementValueUserProfileReducer({
          elementName: "userTypename",
          value: uTypeObj[0],
        })
      );
    }
  };

  const handleCounty = (e: any) => {
    dispatch(
      updateElementValueUserProfileReducer({
        elementName: "localCounty",
        value: e.value.countyName,
      })
    );
    dispatch(
      updateElementValueUserProfileReducer({
        elementName: "countyId",
        value: e.value.countyID,
      })
    );
    if (e.label == "-- All --") {
      dispatch(getLoggedInUserAgencies());
    } else {
      dispatch(agencyListByCounty({ countyId: e.value.countyID }));
    }
    resetAgency();
  };

  const handleBadgeNumberKeyPress = (evt: any) => {
    if (
      evt.which !== 8 &&
      evt.which !== 9 &&
      (evt.which < 47 || evt.which > 58) &&
      evt.key !== "-"
    ) {
      evt.preventDefault();
    }
  };

  const handleAgency = (e: any) => {
    dispatch(
      updateElementValueUserProfileReducer({
        elementName: "localAgency",
        value: e.value.agencyName,
      })
    );
    dispatch(
      updateElementValueUserProfileReducer({
        elementName: "agencyId",
        value: e.value.agencyId,
      })
    );
  };

  const resetAgency = () => {
    dispatch(
      updateElementValueUserProfileReducer({
        elementName: "localAgency",
        value: "-- All --",
      })
    );
    dispatch(
      updateElementValueUserProfileReducer({
        elementName: "agencyId",
        value: 0,
      })
    );
  };

  const userTypeChange = (event: any) => {
    dispatch(
      updateElementValueUserProfileReducer({
        elementName: "userTypename",
        value: event.value,
      })
    );
  };

  const handleStatus = (event: any) => {
    dispatch(
      updateElementValueUserProfileReducer({
        elementName: "userStatus",
        value: event.value,
      })
    );
  };

  const handleInputChange = (event: any) => {
    dispatch(
      updateElementValueUserProfileReducer({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  const handleSubmit = async (e: any) => {
    let obj = {
      status: userStatus || "true",
      firstName: firstName,
      lastName: lastName,
      username: userName,
      badgeNumber: badgeNumber,
      countyId: countyId ? countyId : undefined,
      agencyId: agencyId ? agencyId : undefined,
      userTypename: userTypename,
      userTypeObj: uTypeObj,
    };

    let urlPathObj: any = {
      countyId: countyId || 0,
      agencyId: agencyId || 0,
      userTypename: userTypename || "",
      firstName: firstName || "",
      lastName: lastName || "",
      status: userStatus || "true",
      username: userName || "",
      badgeNumber: badgeNumber || "",
      pageNo: 1,
    };
    dispatch(listUser(obj));
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlPathObj)}`,
    });
  };

  const paginationHandle = (page: number, searchBarState: any) => {
    let obj = {
      countyId: countyId ? countyId : undefined,
      agencyId: agencyId ? agencyId : undefined,
      userTypeObj: uTypeObj,
      userTypename: userTypename,
      firstName: firstName,
      lastName: lastName,
      status: userStatus ? userStatus : "true",
      username: userName,
      badgeNumber: badgeNumber,
      pageNo: page,
    };
    dispatch(listUser(obj));

    let urlPathObj: any = {
      countyId: countyId,
      agencyId: agencyId,
      userTypename: userTypename,
      firstName: firstName,
      lastName: lastName,
      status: userStatus || "true",
      username: userName,
      badgeNumber: badgeNumber,
      pageNo: (page + 1).toString(),
    };

    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(urlPathObj)}`,
    });
  };

  window.onpopstate = () => {
    let paramsValue = location.search;
    if (paramsValue.trim().length > 0) {
      let urlPath: any = {
        countyId: searchParams.get("countyId") ?? 0,
        agencyId: searchParams.get("agencyId") ?? 0,
        userTypename: searchParams.get("userTypename") ?? "",
        firstName: searchParams.get("firstName") ?? "",
        lastName: searchParams.get("lastName") ?? "",
        status: searchParams.get("status") ?? "true",
        username: searchParams.get("username") ?? "",
        badgeNumber: searchParams.get("badgeNumber") ?? "",
        pageNo: parseInt(searchParams.get("pageNo")?.toString() ?? "0") - 1,
        userTypeObj: uTypeObj,
      };
      dispatch(listUser(urlPath));
      setUrlData();
    } else {
      dispatch(
        listUser({
          userTypeObj: uTypeObj,
          status: "true",
        })
      );
      //     dispatch(resetUserProfileList());
      //     dispatch(resetCountiesData());
      //     dispatch(resetAgenciesData());
    }
  };

  let tableHeader = [
    "USER NAME",
    "USER TYPE (BADGE NO.)",
    "COUNTY",
    "AGENCY",
    "NAME",
    "STATUS",
    "ACTION",
  ];

  const handleUserEdit = (username: string) => () => {
    navigate("/editUser/" + username);
  };

  const userTypeOptions: any = [];
  userTypeOptions.push({ label: "-- ALL --", value: "" });
  userTypeOptions.push(
    ...uTypeObj.map((userType: any) => {
      return { label: userType, value: userType };
    })
  );

  const statusOptions: any = [];
  statusOptions.push(
    { value: "all", label: "-- ALL --" },
    { value: "true", label: "ACTIVE" },
    { value: "false", label: "INACTIVE" }
  );

  let tableData = userList.map(
    ({
      username,
      active,
      userTypeID,
      stateAttorneyType,
      firstName,
      lastName,
      middleName,
      countyIdList,
      agencyIdList,
      badgeNumber,
      userProfileID,
      twoFactorEnable,
      twoFactorType,
    }: any) => [
      username,
      userTypeID.type === ROLE_STATES_ATTORNEY
        ? userTypeID.type
        : userTypeID.type === ROLE_POLICE_OFFICER ||
          userTypeID.type === ROLE_AGENCY_ADMIN
        ? userTypeID.type + " (" + badgeNumber + ")"
        : userTypeID.type,
      countyIdList ? countyIdList : "-",
      agencyIdList ? agencyIdList : "-",
      firstName + " " + middleName + " " + lastName,
      active ? ACTIVE_STATUS : DEACTIVE_STATUS,
      userProfileID,
      twoFactorEnable,
      twoFactorType,
      handleUserStatusUpdate(userProfileID, active),
      handleUserPasswordReset(username),
      handleUserEdit(username),
      handleDisabletwoFactor(userProfileID, twoFactorEnable),
      handlSetSignature(userProfileID)
    ]
  );

  let itemLinkList = [{ itemLink: "/", itemName: "DashBoard" }];
  const data = {
    colSize: 3,
    componentData: [
      {
        type: "selectInput",
        props: {
          options: CountyListOptions,
          value: CountyListOptions.find((item: any) => {
            if (item.label === localCounty) {
              return { label: item.label, value: item.value };
            }
          }),
          onChange: handleCounty,
          placeholder: "Select County",
          name: "county",
          defaultValue: { label: "-- ALL --", value: 0 },
        },
      },
      {
        type: "selectInput",
        props: {
          options: AgencyListOptions,
          value: AgencyListOptions.find((item: any) => {
            if (item.label === localAgency) {
              return { label: item.label, value: item.value };
            }
          }),
          onChange: handleAgency,
          placeholder: "Select Agency",
          name: "agency",
          defaultValue: { label: "-- ALL --", value: 0 },
        },
      },
      {
        type: "selectInput",
        props: {
          options: userTypeOptions,
          value: userTypeOptions.find((item: any) => {
            if (item.label === userTypename) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select User Type",
          onChange: userTypeChange,
          name: "userType",
          defaultValue: { value: "all", label: "-- ALL --" },
        },
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "First Name",
          type: "text",
          name: "firstName",
          placeholder: "Enter First Name",
          onChange: handleInputChange,
          value: firstName,
        },
        colSize: 3,
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Last Name",
          type: "text",
          name: "lastName",
          placeholder: "Enter Last Name",
          onChange: handleInputChange,
          value: lastName,
        },
        colSize: 3,
      },
      {
        type: "selectInput",
        props: {
          options: statusOptions,
          value: statusOptions.find((item: any) => {
            if (item.value === userStatus) {
              return { label: item.label, value: item.value };
            }
          }),
          placeholder: "Select Status",
          onChange: handleStatus,
          defaultValue: { value: "true", label: "ACTIVE" },
          name: "userStatus",
        },
        colSize: 3,
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "User Name",
          type: "text",
          name: "userName",
          placeholder: "Enter User Name",
          onChange: handleInputChange,
          value: userName,
        },
        colSize: 3,
      },
      {
        type: "textInputFloting",
        props: {
          floatingLabel: "Badge No",
          type: "text",
          name: "badgeNumber",
          placeholder: "Enter Badge No",
          onChange: handleInputChange,
          onKeyPress: handleBadgeNumberKeyPress,
          maxLength: 8,
          value: badgeNumber === 0 ? "" : badgeNumber,
        },
        colSize: 2,
      },

      {
        type: "button",
        props: {
          className: "w-100",
          children: (
            <>
              <RiSearchLine /> Search
            </>
          ),
          onClick: handleSubmit,
        },
      },
    ],
  };

  return (
    <>
      <Header headerName="User Maintenance" />

      <div className="main-body p-3">
        <BreadcrumbComponent itemList={itemLinkList} itemActive="User List" />

        <AlertMessage
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />

        <ListViewComponent
          table={{
            tableHeader: tableHeader,
            tableData: tableData,
            renderTableRow: renderTableRow,
          }}
          searchBar={{
            colSize: data.colSize,
            componentData: data.componentData,
          }}
          middleComponent={() => (
            <ListViewMiddleComponent
              totalRecords={totalRecords}
              changeUserImportPopupData={changeUserImportPopupData}
              importUserVisible={importUserVisible}
            />
          )}
          pagination={{
            totalPages,
            numberOfPagesToShow: 5,
            currentPage,
            onPageChange: (page: number, searchBarState: any) => {
              paginationHandle(page, searchBarState);
            },
          }}
        />
      </div>
      <ConfirmPopup
        children="Are you sure you want to active/inactive user?"
        show={confirmPopupData.show}
        onHide={() =>
          setconfirmPopupData({
            show: false,
            userProfileID: 0,
            active: false,
          })
        }
        isConfirmation={handleUserStatusUpdateConfirm}
      />
      <ConfirmPopup
        children={confirmPopupData2F.twoFactorEnable?"Are you sure you want to Disable two factor authentication for this user?":"Are you sure you want to Enable two factor authentication for this user?"}
        show={confirmPopupData2F.show}
        onHide={() =>
          setconfirmPopupData2F({
            show: false,
            userProfileID: 0,
            twoFactorEnable: false,
          })
        }
        isConfirmation={disabletwoFactor}
      />

      {userImportPopupData.show && (
        <UserImport
          show={userImportPopupData.show}
          onHide={() =>
            setUserImportPopupData({
              show: false,
            })
          }
        />
      )}

      <UserPassWordReset
        show={userPasswordResetPopup.show}
        onHide={() =>
          setUserPasswordResetPopup({
            show: false,
            userName: "",
          })
        }
        userName={userPasswordResetPopup.userName}
      />
    </>
  );
};

const ListViewMiddleComponent = ({
  totalRecords,
  changeUserImportPopupData,
  importUserVisible,
}: {
  totalRecords: number;
  changeUserImportPopupData: () => void;
  importUserVisible: boolean;
}) => (
  <Row>
    <Col>
      <h4>User List</h4>
      <h6 className="my-n2">Total Users : {totalRecords}</h6>
    </Col>
    <Col className="text-end">
      <Link to={"/User"}>
        <ButtonComponent variant="primary">
          <RiAddCircleLine /> Create User
        </ButtonComponent>
      </Link>{" "}
      {importUserVisible ? (
        <ButtonComponent variant="info" onClick={changeUserImportPopupData}>
          <RiUserShared2Line /> Import User
        </ButtonComponent>
      ) : (
        ""
      )}
    </Col>
  </Row>
);

const renderTableRow = (row: any[]) => {
  const [
    username,
    userTypeID,
    countyIdList,
    agencyIdList,
    name,
    active,
    userProfileID,
    twoFactorEnable,
    twoFactorType,
    handleUserStatusUpdate,
    handleUserPasswordReset,
    handleUserEdit,
    handleDisabletwoFactor,
    handlSetSignature,
  ] = row;
  return (
    <tr key={userProfileID}>
      <td>
        <Link to={`/userDetail/${username}`}>
          <b>{username}</b>
        </Link>
      </td>
      <td>{userTypeID}</td>
      <td>{countyIdList}</td>
      <td>{agencyIdList}</td>
      <td>{name}</td>
      <td>{active}</td>
      <td className="justify-content-center d-flex align-items-stretch">
        <TableActionComponent
          tootltip="Edit User"
          children={<RiDraftFill />}
          actionFunction={handleUserEdit}
          color="text-primary"
          text="Edit User"
        />

        {row[5] === ACTIVE_STATUS ? (
          <TableActionComponent
            tootltip="User Inactivate"
            children={<RiFileExcelLine />}
            actionFunction={handleUserStatusUpdate}
            color="text-danger"
            text="Inactivate User"
          />
        ) : (
          <TableActionComponent
            tootltip="Activate User"
            children={<RiCheckboxMultipleLine />}
            actionFunction={handleUserStatusUpdate}
            color="text-success"
            text="Activate User"
          />
        )}

        <TableActionComponent
          tootltip="Reset Password"
          children={<RiKeyFill />}
          actionFunction={handleUserPasswordReset}
          color="text-info"
          text="Reset Password"
        />

        {twoFactorEnable && twoFactorType !== "NONE" ? (
          <TableActionComponent
            tootltip="Disable two-factor Authentication"
            children={<BsFillShieldSlashFill />}
            actionFunction={handleDisabletwoFactor}
            color="text-danger"
            text="Two-Factor Disable"
          />
        ) : !twoFactorEnable && twoFactorType !== "NONE" ?
        (
          <TableActionComponent
            tootltip="Enable two-factor Authentication"
            children={<BsFillShieldLockFill />}
            actionFunction={handleDisabletwoFactor}
            color="text-success"
            text="Two-Factor Enable"
          />
        ):""}

        <TableActionComponent
          tootltip="Set Signature"
          children={<FaSignature />}
          actionFunction={handlSetSignature}
          color="text-primary"
          text="Set Signature"
        />
      </td>
    </tr>
  );
};
export default UserList;
