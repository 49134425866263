import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { getAgencyListByCounty } from "../../services/AgencyService";
import { getPoliceOfficers } from "../../services/UserProfileService";

interface selectCountyAgencyState {
    agencyId: number,
    agencyName: string,
    agencyCode: string,
    agencyCodeName: string,
    countyID: number,
    countyName: string,
    officerUserProfileId: number,
    officerNameBadge: string,
    onBehalf: boolean,

    agenciesForCounty: string[],
    agenciesForLfcCounty: string[],
    policeOfficers: string[],

    userTypeError?: string;
    status: LoadingType;
}

const initialState = {
    agencyId: 0,
    agencyName: '',
    agencyCode: '',
    agencyCodeName: '',
    countyID: 0,
    countyName: '',
    officerUserProfileId: 0,
    officerNameBadge: '',
    onBehalf: false,

    agenciesForCounty: [],
    agenciesForLfcCounty: [],
    policeOfficers: [],
    userTypeError: undefined,
    status: "idle",
} as selectCountyAgencyState;

export const agencyListByCounty = createAsyncThunk<
    any,
    {
        countyId?: number;
    },
    {
        state: RootState;
    }
>("selectCountyAgency/agencyListByCounty", async (data, { getState, rejectWithValue, dispatch }) => {
    let { countyId } = data;
    let { token } = getState().login;

    dispatch(showHideLoadingGif(true));
    try {
        const reponse = await getAgencyListByCounty({
            countyId: countyId,
            token: token
        });
        dispatch(showHideLoadingGif(false));
        return await reponse.json();
    } catch (error) {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(error);
    }
});

export const getPoliceOfficersList = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "user/getPoliceOfficersList",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await getPoliceOfficers({token});
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while fetching user data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while fetching user data");
    }
  }
);

const selectCountyAgency = createSlice({
    name: "selectCountyAgency",
    initialState,
    reducers: {
        updateElementValueSelectCountyAgencyReducer: updateElementValue,
        resetSelectCountyAgency: (state: selectCountyAgencyState) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
    extraReducers(builder) {
        builder
            .addCase(agencyListByCounty.pending, (state) => {
                state.status = "loading";
                state.userTypeError = undefined;
            })
            .addCase(agencyListByCounty.fulfilled, (state, action) => {
                state.status = "success";
                state.agenciesForCounty = action.payload;
            })
            .addCase(agencyListByCounty.rejected, (state, action) => {
                state.status = "error";
                state.userTypeError = undefined;
            })
            .addCase(getPoliceOfficersList.pending, (state) => {
                state.status = "loading";
                state.userTypeError = undefined;
            })
            .addCase(getPoliceOfficersList.fulfilled, (state, action) => {
                state.status = "success";
                state.policeOfficers = action.payload;
            })
            .addCase(getPoliceOfficersList.rejected, (state, action) => {
                state.status = "error";
                state.userTypeError = undefined;
            });
    },
});

export const { updateElementValueSelectCountyAgencyReducer, resetSelectCountyAgency } = selectCountyAgency.actions;

export const useSelectCountyAgencyReducer = () =>
    useAppSelector((state) => state.selectCountyAgency);

export default selectCountyAgency.reducer;
