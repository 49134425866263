import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";

interface ActiveUserState {
  activeUserList: [];
  activeUserError?: string;
  pagedActiveUserFetchstatus: LoadingType;
  listActiveUserError?: string;
  totalRecords: number;
}

const initialState = {
  activeUserList: [],
  activeUserError: undefined,
  pagedActiveUserFetchstatus: "idle",
  listActiveUserError: undefined,
  totalRecords: 0,
} as ActiveUserState;

export const getActiveUserList = createAsyncThunk<
  any,
  undefined,
  { state: RootState }
>("activeUser/getActiveUserList", async (_, { getState, rejectWithValue, dispatch }) => {
  let { token } = getState().login;
  dispatch(showHideLoadingGif(true));
  try {
    const reponse = await doFetch({
      url: `userprofileservices/getActiveUserList`,
      token,
    });
    if (reponse.ok) {
      dispatch(showHideLoadingGif(false));
      return reponse.json();
    }
    return rejectWithValue("Error while fetching logged in user agencies list");
  } catch (error) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error);
  }
});

const activeUserSlice = createSlice({
  name: "activeUser",
  initialState,
  reducers: {
    updateElementValueActiveUserReducer: updateElementValue,
  },
  extraReducers(builder) {
    builder
      // list Active User List
      .addCase(getActiveUserList.pending, (state) => {
        state.pagedActiveUserFetchstatus = "loading";
        state.listActiveUserError = undefined;
      })
      .addCase(getActiveUserList.fulfilled, (state, action) => {
        state.pagedActiveUserFetchstatus = "success";
        state.listActiveUserError = "";
        state.activeUserList = action.payload;
        state.totalRecords = action.payload.length;
      })
      .addCase(getActiveUserList.rejected, (state) => {
        state.pagedActiveUserFetchstatus = "error";
        state.listActiveUserError = undefined;
      });
  },
});

export const { updateElementValueActiveUserReducer } = activeUserSlice.actions;

export const useActiveUserReducer = () =>
  useAppSelector((state) => state.activeUser);

export default activeUserSlice.reducer;
