import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import Header from "../common/Header";
import ListViewComponent from "../common/ListViewComponent";
import { Row, Col, Form } from "react-bootstrap";
import ButtonComponent from "../common/ButtonComponent";
import {
  RiCheckboxMultipleFill,
  RiLogoutCircleLine,
  RiRefreshLine,
  RiSendPlaneLine,
} from "react-icons/ri";
import {
  getActiveUserList,
  useActiveUserReducer,
} from "../../redux/slice/activeUserSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { TableActionComponent } from "../common/TableActionComponent";
import ConfirmPopup from "../common/ConfirmPopup";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  useLoginReducer,
  updateElementValueInLoginReducer,
} from "../../redux/slice/loginSlice";
import InfoPopup from "../common/InfoPopup";

interface ActiveUserSessionsProps {
  handleConfirm: (status: string) => void;
}

const ActiveUserSessions: React.FC<ActiveUserSessionsProps> = (props) => {
  const { handleConfirm } = props;
  const { username } = useLoginReducer();
  const { activeUserList, totalRecords } = useActiveUserReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [checkBoxList, setCheckBoxList] = useState<any[]>([]);
  const [isCheck, setIsCheck] = useState<string[]>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [showError, setShowError] = useState({
    show: false,
    error: "",
  });

  const [showInfoPopup, setShowInfoPopup] = useState({
    show: false,
    info: "",
  });

  const [confirmPopupData, setconfirmPopupData] = React.useState({
    show: false,
    userName: "",
  });

  const handleClickSignOutUser = (userName: string) => () => {
    setconfirmPopupData({
      show: true,
      userName: userName,
    });
    dispatch(
      updateElementValueInLoginReducer({
        elementName: "receiverName",
        value: [userName],
      })
    );
  };

  const handleSignOutConfirm = () => {
    setconfirmPopupData((confirmPopupData) => ({
      ...confirmPopupData,
      show: false,
    }));

    setShowInfoPopup({
      info: "User was signed out successfully.",
      show: true,
    });
    handleConfirm("LEAVE");
  };

  const handleClickSendMessageUser = () => {
    if (isCheck.length > 0) {
      navigate("/ActiveUserSessionsList/MessagePopup", {
        state: selectedUser,
      });
    } else {
      setShowError({
        show: true,
        error: "Please select at least one User",
      });
    }
  };

  const handleClickRefresh = () => {
    dispatch(getActiveUserList());
  };

  const handleClickSignOut = () => {
    if (isCheck.length > 0) {
      handleConfirm("LEAVE");
      const isFound = selectedUser.map((item: any) => {
        if (username === item) {
          return true;
        }
        return false;
      });
      if (isFound) {
        setShowInfoPopup({
          info: "User was signed out successfully.",
          show: true,
        });
      }
    } else {
      setShowError({
        show: true,
        error: "Please select at least one User",
      });
    }
  };

  useEffect(() => {
    dispatch(
      updateElementValueInLoginReducer({
        elementName: "receiverName",
        value: selectedUser,
      })
    );
  }, [selectedUser]);

  const handleClickCheckAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(checkBoxList.map((checkbox: any) => checkbox.checkboxName));
    let itemList: string[] = [];

    if (isCheck.length > 0) {
      setSelectedUser(itemList);
    } else {
      checkBoxList.map((item: any) => {
        itemList.push(item.checkboxUserName);
      });
      setSelectedUser(itemList);
    }

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  useEffect(() => {
    dispatch(getActiveUserList());
  }, []);

  useEffect(() => {
    let list: any = [];
    activeUserList.map((user: any) => {
      list.push({
        checkboxName: "checkBox" + user.userProfileID,
        checkboxUserName: user.username,
      });
    });
    setCheckBoxList(list);
  }, [activeUserList]);

  const handleChangeCheckbox = (userProfileID: any, userName: string) => () => {
    let isChecked = false;
    isCheck.map((checkbox) => {
      if (checkbox.includes("checkBox" + userProfileID)) {
        isChecked = true;
      }
    });

    setSelectedUser([...selectedUser, userName]);
    setIsCheck([...isCheck, "checkBox" + userProfileID]);
    if (isChecked) {
      setIsCheck(isCheck.filter((item) => item !== "checkBox" + userProfileID));
      setSelectedUser(selectedUser.filter((item) => item !== userName));
    }
  };

  let tableHeader = [
    "#",
    "NAME",
    "USER NAME",
    "USER TYPE",
    "WORK PHONE",
    "EMAIL",
    "IP ADDRESS",
    "LAST LOGIN TIME",
    "ACTIVE TIME",
    "ACTION",
  ];

  let tableData = activeUserList.map(
    ({
      userProfileID,
      fullName,
      username,
      userRole,
      contactNumber,
      emailAddress,
      ipAddress,
      loginTime,
      activePeriod,
    }: any) => [
      userProfileID,
      fullName,
      username,
      userRole,
      contactNumber,
      emailAddress,
      ipAddress,
      loginTime,
      activePeriod,
      isCheck,
      handleClickSignOutUser(username),
      handleChangeCheckbox(userProfileID, username),
    ]
  );

  let itemLinkList = [{ itemLink: "/", itemName: "DashBoard" }];

  return (
    <>
      <Header headerName="Active User Sessions List" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Active User Sessions List"
        />
        <ListViewComponent
          table={{
            tableHeader: tableHeader,
            tableData: tableData,
            renderTableRow: renderTableRow,
          }}
          middleComponent={() => (
            <ListViewMiddleComponent
              totalRecords={totalRecords}
              handleClickSendMessageUser={handleClickSendMessageUser}
              handleClickRefresh={handleClickRefresh}
              handleClickSignOut={handleClickSignOut}
              handleClickCheckAll={handleClickCheckAll}
            />
          )}
        />
      </div>

      <ConfirmPopup
        children={`Do you want to signout ${confirmPopupData.userName} account?`}
        show={confirmPopupData.show}
        onHide={() =>
          setconfirmPopupData({
            show: false,
            userName: confirmPopupData.userName,
          })
        }
        isConfirmation={handleSignOutConfirm}
      />
      <InfoPopup
        children={showError.error}
        onHide={() =>
          setShowError({
            show: false,
            error: "",
          })
        }
        show={showError.show}
        variant = "danger"
      />
      <InfoPopup
        children={showInfoPopup.info}
        onHide={() =>
          setShowInfoPopup({
            show: false,
            info: "",
          })
        }
        show={showInfoPopup.show}
      />

      <Outlet />
    </>
  );
};

const ListViewMiddleComponent = ({
  totalRecords,
  handleClickSendMessageUser,
  handleClickRefresh,
  handleClickSignOut,
  handleClickCheckAll,
}: {
  totalRecords: number;
  handleClickSendMessageUser: () => void;
  handleClickRefresh: () => void;
  handleClickSignOut: () => void;
  handleClickCheckAll: () => void;
}) => (
  <Row>
    <Col>
      <h4>Active User Sessions List</h4>
      <h6 className="my-n2">Total Active User Sessions : {totalRecords}</h6>
      <ButtonComponent variant="primary" onClick={handleClickCheckAll}>
        <RiCheckboxMultipleFill /> Check / Uncheck All
      </ButtonComponent>
    </Col>
    <Col className="text-end mt-5">
      <ButtonComponent
        variant="danger"
        onClick={handleClickSignOut}
        className="mt-2"
      >
        <RiLogoutCircleLine /> Sign out users
      </ButtonComponent>{" "}
      <ButtonComponent
        variant="primary"
        onClick={handleClickSendMessageUser}
        className="mt-2"
      >
        <RiSendPlaneLine /> Send Message to User
      </ButtonComponent>{" "}
      <ButtonComponent
        variant="info"
        onClick={handleClickRefresh}
        className="mt-2"
      >
        <RiRefreshLine /> Refresh
      </ButtonComponent>
    </Col>
  </Row>
);

const renderTableRow = (row: any[]) => {
  const [
    userProfileID,
    fullName,
    username,
    userRole,
    contactNumber,
    emailAddress,
    ipAddress,
    loginTime,
    activePeriod,
    isCheck,
    handleClickSignOutUser,
    handleChangeCheckbox,
  ] = row;
  return (
    <tr>
      <td>
        <Form.Check
          id={"checkBox" + userProfileID}
          type="checkbox"
          checked={isCheck.includes("checkBox" + userProfileID)}
          onChange={handleChangeCheckbox}
        />
      </td>
      <td>{fullName}</td>
      <td>{username}</td>
      <td>{userRole}</td>
      <td>{contactNumber}</td>
      <td>
        {emailAddress.split(',').map((email:any, index:any) => (
          <div key={index}>{email}</div>
        ))}
      </td>
      <td>{ipAddress}</td>
      <td>{loginTime}</td>
      <td>{activePeriod}</td>
      <td className="text-center" style={{ width: "100px" }}>
        <TableActionComponent
          tootltip="Signout"
          children={<RiLogoutCircleLine />}
          actionFunction={handleClickSignOutUser}
          text="Signout"
        />
      </td>
    </tr>
  );
};

export default ActiveUserSessions;
