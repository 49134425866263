import { updateElementValue } from "../../utils/updateElementValue";
import { INVENTORY } from "../../constants/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../types";
import { updateElementObject } from "../../utils/updateElementObject";
import { RootState } from "../store";
import { useAppSelector } from "../../hooks/hooks";
import { doFetch } from "../../utils/doFetch";
import { getSWCaseDetail, updateElementValueCaseDetailReducer, useCaseDetailReducer } from "./caseDetailSlice";
import { updateCaseDetailObject } from "./caseDetailSlice";
import { resetSignature, setSignature, updateElementValueInSignPanelReducer } from "./signPanelSlice";
import { createOrderOfCustody } from "./orderOfCustodySlice";
import { showHideLoadingGif, updatePdfGenerationError, updateDocGenerateError } from "./commonSlice";
import { updateElementValueInSearchWarrantListReducer } from "./searchWarrantListSlice";
import { getSWConcurrentObj } from "./commonFormSlice";
import { updateSearchWarrantDetail } from "./searchWarrantSlice";
import { getJudgeSavedSignature } from "./userProfileSlice";

interface inventoryObject {
  searchWarrantId: number;
  formStatus: string;

  txtCaseNumber: string;
  txtAgency: string;
  officerBadgeNo: string;
  txtExecutionDate: string;
  txtComplainantName: string;
  txtExecutionTime: string;

  txtJudgeName: string;
  txtJudgeIssuingDate: string;
  txtJudgeIssuingTime: string;

  creatorId: number;
  creatorUserType: string;
  onBehalf: boolean;

  txtPersonPremiseslbl: string;
  txtPersonPremises: string;
  txtSeized1: string;
  txtSeized2: string;
  txtPresentingJudge: string;
  txtPresentingDate: string;
  txtSubscribedDate: string;
  txtIssuancedDate: string;
  hiddenComplaintSignature: string;
  hiddenJudgeSignature: string;
  lblHeadingCountySW: string;
  lblHeadingInTheCircuitSW: string;
  agencyCode: string;
  warrantType: string;
  caseImpounded: boolean;
  unImpoundCase: boolean;
  inventoryStatement: string;
  hiddenUserSignature: string,
  lblInventoryPrintText: string;

  remark: string;

  countyID: string;
  countyName: string;
  judicialNumber: string;
  city: string;
  state: string;
  zip: string;
  circuitClerkName: string;
  validationId: string;
  isCaseImpounded: string;

  createStatus: string;
  creatingError: string;
  fetchStatus: string;
  fetchError: string;
  saveStatus: string;
  saveError: string;
  fetchInvPreviewStatus: string;
  fetchInvPreviewError: string;

  // uploadedFileName: string;
  // hiddenUploadedPdf: string;
}

interface inventoryState {
  inventoryObject: inventoryObject;
  fetchingError?: string;
  fetchingStatus: LoadingType;

  inventrySignDocGenerateError: boolean;
  inventrySignDocGenerateErrorMsg: any;
  judgeSignControl: boolean;
}

const initialState = {
  fetchingError: undefined,
  fetchingStatus: "idle",  

  inventrySignDocGenerateError: false,
  inventrySignDocGenerateErrorMsg: undefined,
  judgeSignControl: false,

  inventoryObject: {
    searchWarrantId: 0,
    formStatus: "",

    txtCaseNumber: "",
    txtAgency: "",
    officerBadgeNo: "",
    txtExecutionDate: "",
    txtComplainantName: "",
    txtExecutionTime: "",

    txtJudgeName: "",
    txtJudgeIssuingDate: "",
    txtJudgeIssuingTime: "",

    creatorId: 0,
    creatorUserType: "",
    onBehalf: false,

    txtPersonPremiseslbl: "",
    txtPersonPremises: "",
    txtSeized1: "",
    txtSeized2: "",
    txtPresentingJudge: "",
    txtPresentingDate: "",
    txtSubscribedDate: "",
    txtIssuancedDate: "",
    hiddenComplaintSignature: "",
    hiddenJudgeSignature: "",
    lblHeadingCountySW: "",
    lblHeadingInTheCircuitSW: "",
    agencyCode: "",
    warrantType: "",
    caseImpounded: false,
    unImpoundCase: false,
    inventoryStatement: "",
    hiddenUserSignature: "",
    lblInventoryPrintText: "",

    remark: "",

    countyID: "",
    countyName: "",
    judicialNumber: "",
    city: "",
    state: "",
    zip: "",
    circuitClerkName: "",
    validationId: "",
    isCaseImpounded: "",

    createStatus: "idle",
    creatingError: "",
    fetchStatus: "idle",
    fetchError: "",
    saveStatus: "idle",
    saveError: "",
    fetchInvPreviewStatus: "idle",
    fetchInvPreviewError: "",

    // uploadedFileName: "",
    // hiddenUploadedPdf: "",
  },
} as inventoryState;

export const createInventory = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "inventory/createInventory",
  async (data, { getState, rejectWithValue, dispatch }) => {
    dispatch(resetInventoryData());
    const { searchWarrantId, navigate } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(resetSignature());
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "searchwarrantservices/createInventory/" + searchWarrantId,
        token,
        type: "POST",
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const res = await dispatch(getJudgeSavedSignature({
          userprofileId: getState().login.userProfileId
        }));

        if (res.payload.message) {
          dispatch(
            updateElementValueInSignPanelReducer({
              elementName: "sign",
              value: res.payload.message,
            })
          );
        }

        navigate("/Inventory");
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while creating SW");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while creating SW");
    }
  }
);

export const getSearchWarrantDetail = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "inventory/getSearchWarrantDetail",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, navigate } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "searchwarrantservices/getSearchWarrantDetail/" + searchWarrantId,
        token,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result: any) => {
          dispatch(
            getWarrantTypeTemplateDetails({
              templateId: result.templateId,
              navigate: navigate,
            })
          );
        });
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while getting Search Warrant Detail"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while getting Search Warrant Detail"
      );
    }
  }
);

export const getWarrantTypeTemplateDetails = createAsyncThunk<
  any,
  {
    templateId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "inventory/getWarrantTypeTemplateDetails",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { templateId, navigate } = data;
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "templatetypeservices/getByWarrantTemplatedId/" + templateId,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while getting Warrant Type Template Detail"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while Warrant Type Template Detail"
      );
    }
  }
);

export const insertInventory = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    inventory?: object;
    navigate: any;
    navigateTo: string;
    callingfrom: string;
  },
  { state: RootState; rejectValue: string }
>(
  "searchWarrant/insertinventory",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, inventory, navigate, navigateTo, callingfrom } = data;
    let { token } = getState().login;
    // let jsonString = JSON.stringify(inventory)
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: `searchwarrantservices/insertinventory/` + searchWarrantId,
        type: "POST",
        token,
        body: inventory,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result: any) => {
          if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
            dispatch(
              updateDocGenerateError({
                error: true,
                errorMsg: result.errorInfo.validationErrors,
              })
            );
            return;
          } else {
            let message:any = ""
            if (callingfrom === "onSave") {
              message = "Form data saved as complete successfully.";
            } else if (callingfrom === "onSaveInComplete") {
              message = "Form data saved as Incomplete successfully.";
            } else if (callingfrom === "onSaveSubmit") {
              message = "Form submitted to Judge for approval.";
            }

            result.responseMessage = message;

            let caseDetail = result;
            dispatch(updateCaseDetailObject(caseDetail));

            dispatch(
              getSWCaseDetail({
                searchWarrantId: caseDetail.searchWarrantId,
                action: "getDetailsOnRowClick",
                navigate: navigate,
              })
            );
          
            navigate(navigateTo);
          }
        });
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while getting Warrant Type Template Detail"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while Warrant Type Template Detail"
      );
    }
  }
);

export const updateInventory = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    inventory?: any;
    navigate: any;
    navigateTo: string;
    callfrom: string;
  },
  { state: RootState; rejectValue: string }
>(
  "searchWarrant/updateinventory",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, inventory, navigate, navigateTo, callfrom } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "searchwarrantservices/updateinventory/" + searchWarrantId,
        token,
        type: "PUT",
        body: inventory,
        concurrentObject: concurrObj,
        concurrentIdentifier: "SW",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result: any) => {
          if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
            dispatch(
              updateDocGenerateError({
                error: true,
                errorMsg: result.errorInfo.validationErrors,
              })
            );
            return;
          } else {
            let message: any = ""
            if (callfrom === "saveClick") {
              message = "Form data saved as complete successfully.";
            } else if (callfrom === "saveIncompleteClick") {
              message = "Form data saved as Incomplete successfully.";
            }
            result.responseMessage = message;
            let caseDetail = result;
            dispatch(updateCaseDetailObject(caseDetail));

            if (callfrom === "submitClick") {
              let proxy = {
                formStatus: inventory ? inventory.formStatus : "",
                remarks: "",
                notificationCallFrom: "Submit",
                formType: INVENTORY,
              };
              dispatch(
                updateSearchWarrantDetail({
                  searchWarrantId: inventory ? inventory.searchWarrantId : 0,
                  action: "updateStatus",
                  warrantFormObj: proxy,
                  navigate: navigate,
                  navigateTo: -1,
                })
              );
            } else {
              navigate(navigateTo);
            }
          }
        });
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while getting Warrant Type Template Detail"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while Warrant Type Template Detail"
      );
    }
  }
);

export const signInventory = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    inventory?: object;
    navigate: any;
    navigateTo: string;
    isOFCRequired: boolean;
    validateSpecialChars: string;
    cancelSignPopup?: any;
  },
  { state: RootState; rejectValue: string }
>(
  "searchWarrant/signinventory",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, inventory, navigate, navigateTo, isOFCRequired, validateSpecialChars, cancelSignPopup } =
      data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "searchwarrantservices/signinventory/" + searchWarrantId + "/" + validateSpecialChars,
        token,
        type: "PUT",
        body: inventory,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result: any) => {
          if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
            dispatch(
              updateInventrySignDocGenerateError({
                error: true,
                errorMsg: result.errorInfo.validationErrors,
              })
            );
            return;
          } else {
            if (cancelSignPopup) {
              cancelSignPopup();
            }
            if (result.errorInfo && result.errorInfo.error) {
              dispatch(
                updateElementValueCaseDetailReducer({
                  elementName: "responseErrorMessage",
                  value: result.errorInfo.errorMessage,
                })
              );
              dispatch(updatePdfGenerationError({
                error: result.errorInfo.error,
                errorMsg: result.errorInfo.errorMessage,
              }));
              navigate(navigateTo);
            } else {
              let message =
                "Inventory on " + getState().caseDetail.caseNumber + " is signed.";
              result.responseMessage = message;
              let caseDetail = result;
              dispatch(updateCaseDetailObject(caseDetail));
              if (isOFCRequired) {
                dispatch(createOrderOfCustody({ navigate: navigate }));
              } else {
                navigate(navigateTo);
              }
            }
          }
        });
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while getting Warrant Type Template Detail"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while Warrant Type Template Detail"
      );
    }
  }
);

export const returnInventory = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    inventory?: object;
    navigate: any;
    navigateTo: string;
  },
  { state: RootState; rejectValue: string }
>(
  "searchWarrant/signinventory",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, inventory, navigate, navigateTo } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(resetSignature());
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "searchwarrantservices/returninventory/" + searchWarrantId,
        token,
        type: "PUT",
        body: inventory,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result: any) => {

          let message =
          "Inventory application for " + getState().caseDetail.caseNumber + " is sent back to officer.";

          let caseDetail = result;
          dispatch(updateCaseDetailObject(caseDetail));
          dispatch(
            updateElementValueInSearchWarrantListReducer({
              elementName: "responseMessage",
              value: message,
            })
          );
        });
        navigate(navigateTo);
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while getting Warrant Type Template Detail"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while Warrant Type Template Detail"
      );
    }
  }
);

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    updateElementValueInventoryReducer: updateElementValue,
    updateElementObjectValue: (state: inventoryState, action) => {
      let elementObj = state.inventoryObject;
      updateElementObject(state, action, elementObj);
    },
    updateInventoryObject: (state: inventoryState, action) => {
      state.inventoryObject = action.payload;
    },
    resetInventoryData: (state: inventoryState) => {
      return {
        ...state,
        ...initialState,
      };
    },
    updateSignElementInventory: (state: inventoryState, action) => {
      let elementObj = state.inventoryObject;
      updateElementObject(state, action, elementObj);
    },
    updateInventrySignDocGenerateError: (
      state: inventoryState,
      action: PayloadAction<{ error: boolean; errorMsg: any }>
    ) => {
      state.inventrySignDocGenerateError = action.payload.error;
      state.inventrySignDocGenerateErrorMsg = action.payload.errorMsg;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createInventory.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(createInventory.fulfilled, (state, action) => {
        state.fetchingStatus = "success";
        state.inventoryObject = action.payload;
      })
      .addCase(createInventory.rejected, (state, action) => {
        state.fetchingStatus = "error";
        state.fetchingError = undefined;
      })
      .addCase(getWarrantTypeTemplateDetails.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(getWarrantTypeTemplateDetails.fulfilled, (state, action) => {
        state.fetchingStatus = "success";
        let templateList = action.payload;
        if (templateList) {
          for (let i = 0; i < templateList.length; i++) {
            if (templateList[i].templateField === "InvntryPrsnPrmsTxt") {
              state.inventoryObject.inventoryStatement =
                templateList[i].templateValue;
            }
            if (templateList[i].templateField === "invntryPrsnPrmsPrint") {
              state.inventoryObject.txtPersonPremiseslbl =
                templateList[i].templateValue;
            }
          }
        }
      })
      .addCase(getWarrantTypeTemplateDetails.rejected, (state, action) => {
        state.fetchingStatus = "error";
        state.fetchingError = undefined;
      })
      .addCase(signInventory.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(signInventory.fulfilled, (state, action) => {
        state.fetchingStatus = "success";
      })
      .addCase(signInventory.rejected, (state, action) => {
        state.fetchingStatus = "error";
        state.fetchingError = undefined;
      })
      .addCase(insertInventory.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(insertInventory.fulfilled, (state, action) => {
        state.fetchingStatus = "success";
      })
      .addCase(insertInventory.rejected, (state, action) => {
        state.fetchingStatus = "error";
        state.fetchingError = undefined;
      })
      .addCase(updateInventory.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(updateInventory.fulfilled, (state, action) => {
        state.fetchingStatus = "success";
      })
      .addCase(updateInventory.rejected, (state, action) => {
        state.fetchingStatus = "error";
        state.fetchingError = undefined;
      });
  },
});
export const {
  updateElementValueInventoryReducer,
  resetInventoryData,
  updateElementObjectValue,
  updateInventoryObject,
  updateSignElementInventory,
  updateInventrySignDocGenerateError,
} = inventorySlice.actions;
export const useInventoryReducer = () =>
  useAppSelector((state) => state.inventory);
export default inventorySlice.reducer;
