import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { logoutUser } from "./loginSlice";

interface userChangeProfileState {
  userProfileChangeError?: string;
  status: LoadingType;

  username: string;
  userProfileID: number;
  userTypeId: number;
  userType: string;
  typeName: string;
  countyId: number;
  agencyId: number;
  password: string;
  confirmPassword: string;
  workPhone: string;
  homePhone: string;
  workExtension: string;
  homeExtension: string;
  firstName: string;
  middleName: string;
  mobileNo: string;
  lastName: string;
  email: string;
  badgeNumber: string;
  attorneyID: string;
  receiveNotification: boolean;
  twofactorPhone: string;
  twofactorEmail: string;
  twoFactorEnable : boolean,
  twoFactorType: string,
  canViewIntroFirstName : boolean;
  canViewIntroLastName : boolean;
}

const initialState = {
  userProfileChangeError: undefined,
  status: "idle",

  username: "",
  userProfileID: 0,
  userTypeId: 0,
  userType: "",
  typeName: "",
  countyId: 0,
  agencyId: 0,
  password: "",
  confirmPassword: "",
  workPhone: "",
  homePhone: "",
  workExtension: "",
  homeExtension: "",
  firstName: "",
  middleName: "",
  mobileNo: "",
  lastName: "",
  email: "",
  badgeNumber: "",
  attorneyID: "",
  receiveNotification :false,
  twofactorPhone: "",
  twofactorEmail: "",
  twoFactorEnable : false,
  twoFactorType: "NONE",
  canViewIntroFirstName : false,
  canViewIntroLastName : false
} as userChangeProfileState;

export const getUserDataByUsername = createAsyncThunk<
  any,
  {
    username: string;
  },
  { state: RootState; rejectValue: string }
>(
  "userProfileChange/getUserDataByUsername",
  async ({ username }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    // dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "userprofileservices/userProfileForUsername/" + username,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      }
      const error = await response.text();
      return rejectWithValue(error || "Error while fetching user profile data");
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while fetching user profile data");
    }
  }
);

export const changeUserProfile = createAsyncThunk<
  any,
  { user: userChangeProfileState; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "userProfileChange/changeUserProfile",
  async ({ user, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
  
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "userprofileservices/userProfileCreate",
        token,
        body: user,
        type: "POST",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        await response.text();
        dispatch(logoutUser());

      }else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while changing user profile");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while changing user profile");
    }
  }
);

const userChangeProfileSlice = createSlice({
  name: "userChangeProfile",
  initialState,
  reducers: {
    updateElementValueUserChangeProfileReducer: updateElementValue,
  },
  extraReducers(builder) {
    builder
      .addCase(getUserDataByUsername.pending, (state) => {
        state.status = "loading";
        state.userProfileChangeError = undefined;
      })
      .addCase(getUserDataByUsername.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: "success",
        };
      })
      .addCase(getUserDataByUsername.rejected, (state, action) => {
        state.status = "error";
        state.userProfileChangeError = undefined;
      });
  },
});

export const { updateElementValueUserChangeProfileReducer } = userChangeProfileSlice.actions;

export const useUserChangeProfileReducer = () =>
  useAppSelector((state) => state.userChangeProfile);

export default userChangeProfileSlice.reducer;
