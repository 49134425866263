import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { LoadingType } from "../../types";
import { updateElementObject } from "../../utils/updateElementObject";
import { getSWCaseDetail } from "./caseDetailSlice";
import { resetSignature, updateElementValueInSignPanelReducer } from "./signPanelSlice";
import { showHideLoadingGif, updatePdfGenerationError, updateDocGenerateError } from "./commonSlice";
import { updateCaseDetailObject } from "./caseDetailSlice";
import { getSWConcurrentObj } from "./commonFormSlice";
import { getJudgeSavedSignature } from "./userProfileSlice";

interface orderOfCustodyObj {
  searchWarrantId: number;
  txtCaseNumber: number;
  txtAgency: string;
  officerBadgeNo: number;
  txtExecutionDate: string;
  txtExecutionTime: string;
  txtComplainantName: string;
  txtJudgeName: string;
  txtJudgeIssuingDate: string;
  txtJudgeIssuingTime: string;
  txtPersonPremises: string;
  txtPresentingJudge: string;
  txtPresentingDate: string;
  txtSubscribedDate: string;
  txtIssuancedDate: string;
  hiddenComplaintSignature: string;
  hiddenJudgeSignature: string;
  agencyCode: string;
  warrantType: string;
  countyID: number;
  countyName: string;
  judicialNumber: number;
  city: string;
  state: string;
  zip: string;
  circuitClerkName: string;
  validationId: string;
  isCaseImpounded: string;
  unImpoundCase: string;
  chkImpounded: boolean;
  chkNotImpounded: boolean;
  lblHeadingStateSW: string;
  lblHeadingInTheCircuitSW: string;
}

interface orderOfCustodyState {
  orderOfCustodyObj: orderOfCustodyObj;
  createStatus: LoadingType;
  createError?: string;
  fetchOtcPreviewStatus: LoadingType;
  fetchOtcPreviewError: string;
}

const initialState = {
  createStatus: "idle",
  createError: undefined,
  fetchOtcPreviewStatus: "idle",
  fetchOtcPreviewError: "",
  orderOfCustodyObj: {
    searchWarrantId: 0,
    txtCaseNumber: 0,
    txtAgency: "",
    officerBadgeNo: 0,
    txtExecutionDate: "",
    txtExecutionTime: "",
    txtComplainantName: "",
    txtJudgeName: "",
    txtJudgeIssuingDate: "",
    txtJudgeIssuingTime: "",
    txtPersonPremises: "",
    txtPresentingJudge: "",
    txtPresentingDate: "",
    txtSubscribedDate: "",
    txtIssuancedDate: "",
    hiddenComplaintSignature: "",
    hiddenJudgeSignature: "",
    agencyCode: "",
    warrantType: "",
    countyID: 0,
    countyName: "",
    judicialNumber: 0,
    city: "",
    state: "",
    zip: "",
    circuitClerkName: "",
    validationId: "",
    isCaseImpounded: "",
    unImpoundCase: "",
    chkImpounded: false,
    chkNotImpounded: false,
    lblHeadingStateSW: "",
    lblHeadingInTheCircuitSW: "",
  },
} as orderOfCustodyState;

export const createOrderOfCustody = createAsyncThunk<
  any,
  { navigate: any },
  { state: RootState; rejectValue: string }
>(
  "CaseDetail/createOrderOfCustody",
  async ({ navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    let { searchWarrantId } = getState().caseDetail;
    dispatch(resetSignature());
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "searchwarrantservices/createOrderOfCustody/" + searchWarrantId,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));

        const res = await dispatch(getJudgeSavedSignature({
          userprofileId: getState().login.userProfileId
        }));

        if (res.payload.message) {
          dispatch(
            updateElementValueInSignPanelReducer({
              elementName: "sign",
              value: res.payload.message,
            })
          );
        }

        navigate("/orderOfCustody");
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching search warrant data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching search warrant data"
      );
    }
  }
);

export const saveOrderOfCustody = createAsyncThunk<
  any,
  { searchWarrantId: number; formdata?: object; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "searchWarrant/saveOrderOfCustody",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, formdata, navigate } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "searchwarrantservices/saveOrderOfCustody/" + searchWarrantId,
        type: "PUT",
        token,
        body: formdata,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        response.json().then((result) => {
          if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
            dispatch(
              updateDocGenerateError({
                error: true,
                errorMsg: result.errorInfo.validationErrors,
              })
            );
            return;
          } else {
            if (result.errorInfo && result.errorInfo.error) {
              if (result.errorInfo) {
                dispatch(updatePdfGenerationError({
                  error: result.errorInfo.error,
                  errorMsg: result.errorInfo.errorMessage,
                }));
              }
            } else {
              let message: any = "";
              message = "Order Of Custody for" + getState().caseDetail.caseNumber + " is complete.";
              result.responseMessage = message;
              let caseDetail = result;
              dispatch(updateCaseDetailObject(caseDetail));
              dispatch(
                getSWCaseDetail({
                  searchWarrantId: caseDetail.searchWarrantId,
                  action: "getDetailsOnRowClick",
                  navigate: navigate,
                })
              );
            }
            let message:any = "";
            message = "Order Of Custody for " + getState().caseDetail.caseNumber + " is complete.";

            result.responseMessage = message;
            let caseDetail = result;
            dispatch(updateCaseDetailObject(caseDetail));
            dispatch(
              getSWCaseDetail({
                searchWarrantId: caseDetail.searchWarrantId,
                action: "getDetailsOnRowClick",
                navigate: navigate,
              })
            );
            navigate("/CaseDetails");
          }
        });
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while saving Order Of Custody");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while saving Order Of Custody"
      );
    }
  }
);

const orderOfCustodySlice = createSlice({
  name: "OrderOfCustody",
  initialState,
  reducers: {
    updateElementValueOrderOfCustodyReducer: updateElementValue,
    updateOrderOfCustodyObject: (state: orderOfCustodyState, action) => {
      state.orderOfCustodyObj = action.payload;
    },
    updateElementObjectValue: (state: orderOfCustodyState, action) => {
      let elementObj = state.orderOfCustodyObj;
      updateElementObject(state, action, elementObj);
    },
    resetOrderOfCustodyData: (state: orderOfCustodyState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder

      .addCase(createOrderOfCustody.pending, (state) => {
        state.createStatus = "loading";
        state.createError = undefined;
      })
      .addCase(createOrderOfCustody.fulfilled, (state, action) => {
        state.createStatus = "success";
        state.orderOfCustodyObj = action.payload;
      })
      .addCase(createOrderOfCustody.rejected, (state, action) => {
        state.createStatus = "error";
        state.createError = undefined;
      });
  },
});
export const {
  updateElementValueOrderOfCustodyReducer,
  updateElementObjectValue,
  updateOrderOfCustodyObject,
  resetOrderOfCustodyData,
} = orderOfCustodySlice.actions;
export const useOrderOfCustodyReducer = () =>
  useAppSelector((state) => state.orderOfCustody);
export default orderOfCustodySlice.reducer;
