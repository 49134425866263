import React from "react";
import { Alert as DefaultAlert } from "react-bootstrap";
import { BsExclamationCircleFill } from "react-icons/bs";
type WarningAlertProps = {
  messages: string[];
  clearWarning: () => void;
  isExclamationMark?: boolean;
} & React.ComponentProps<typeof DefaultAlert>;
export default function WarningAlert({
  messages,
  clearWarning,
  variant = "warning",
  isExclamationMark = true,
  ...props
}: WarningAlertProps) {
  return (
    <DefaultAlert
      show={messages.length > 0}
      variant={variant}
      onClose={clearWarning}
      dismissible
      {...props}
    >
      {messages.map((str, i) => (
        <p key={i}>
          {isExclamationMark && <BsExclamationCircleFill className="mx-3" />}
          {str}
        </p>
      ))}
    </DefaultAlert>
  );
}

export const useWarningAlert = () => {
  const [messages, setMessages] = React.useState<string[]>([]);
  const handleWarning = (message: string[]) => {
    setMessages([...message]);
  };
  const clearWarning = () => {
    setMessages([]);
  };
  return {
    handleWarning,
    clearWarning,
    messages,
  };
};
