import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { getPoliceOfficers,getPoliceOfficersByAgencyId,getPoliceOfficersByCountyIds } from "../../services/UserProfileService";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import { setSignature } from "./signPanelSlice";
import { updateElementValueInLoginReducer } from "./loginSlice";

interface userProfileManage {
  username: string;
  oldUsername: string;
  userProfileID: number;
  userTypeId: number;
  userType: number;
  typeName: string;
  countyId: number;
  agencyId: number;
  agencyIdList: Array<number>;
  agencySelectList: Array<string>;
  agencyNames: Array<string>;
  countySelectList: Array<string>;
  countyIdList: Array<number>;
  countyNames: Array<string>;
  password: string;
  confirmPassword: string;
  workPhone: string;
  homePhone: string;
  workExtension: string;
  homeExtension: string;
  firstName: string;
  middleName: string;
  mobileNo: string;
  lastName: string;
  email: string;
  badgeNumber: string;
  attorneyID: string;
  expirationDate: string;
  commissionNumber: string;
  fileOnBehalfOfOthers: boolean;
  isPublcNotary: boolean;
  receiveNotification: boolean;
  userOparation: string;
  policeOfficersList: string[];
  sign: string;
  activateByEmail: boolean;
  auditUserName: string;
  creatorname: string;
  auditTimeStamp: string;
  creationTimeStamp: string;
  isTwoFactorEnable: boolean;
  twoFactorType: string;
}
interface UserProfilesReducer extends userProfileManage {
  getPoliceOfficerFetchStatus: LoadingType;
  getPoliceOfficerError?: string;
  getUserProfilesFetchStatus: LoadingType;
  getUserProfilesError?: string;
  UserProfilesStatus: LoadingType;
  UserProfilesError?: string;
  MergProfileStatus: LoadingType;
  MergProfileError?: string;
  updateUserProfilesStatus: LoadingType;
  updateUserProfilesError?: string;
  successMessage?: string;
}

const initialUserProfilesState: userProfileManage = {
  username: "",
  oldUsername: "",
  userProfileID: 0,
  userTypeId: 0,
  userType: 0,
  typeName: "",
  countyId: 0,
  agencyId: 0,
  agencyIdList: [],
  agencySelectList: [],
  agencyNames: [],
  countySelectList: [],
  countyIdList: [],
  countyNames: [],
  password: "",
  confirmPassword: "",
  workPhone: "",
  homePhone: "",
  workExtension: "",
  homeExtension: "",
  firstName: "",
  middleName: "",
  mobileNo: "",
  lastName: "",
  email: "",
  badgeNumber: "",
  attorneyID: "",
  expirationDate: "",
  commissionNumber: "",
  fileOnBehalfOfOthers: false,
  isPublcNotary: false,
  receiveNotification: false,
  userOparation: "",
  policeOfficersList: [],
  sign: "",
  activateByEmail: false,
  auditUserName: "",
  creatorname: "",
  auditTimeStamp: "",
  creationTimeStamp: "",
  isTwoFactorEnable: false,
  twoFactorType: "NONE",
};

const initialState = {
  ...initialUserProfilesState,
  getPoliceOfficerFetchStatus: "idle",
  getPoliceOfficerError: undefined,
  getUserProfilesFetchStatus: "idle",
  getUserProfilesError: undefined,
  UserProfilesStatus: "idle",
  UserProfilesError: undefined,
  MergProfileStatus: "idle",
  MergProfileError: undefined,
  updateUserProfilesStatus: "idle",
  updateUserProfilesError: undefined,
  successMessage: undefined,
} as UserProfilesReducer;

export const getUserDataById = createAsyncThunk<
  any,
  {
    id: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "user/getUserDataById",
  async ({ id, navigate }, { getState, rejectWithValue }) => {
    let { token } = getState().login;
    try {
      const response = await doFetch({
        url: "userprofileservices/userProfiles/" + id,
        token,
      });
      if (response.ok) {
        console.log(response.json());
        return response.json();
      }
      navigate("/UserList");

      const error = await response.text();
      return rejectWithValue(error || "Error while fetching user data");
    } catch (error: any) {
      navigate("/UserList");
      return rejectWithValue(error.message || "Error while fetching user data");
    }
  }
);

export const getUserDataByUsername = createAsyncThunk<
  any,
  {
    username: string;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "user/getUserDataByUsername",
  async ({ username, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "userprofileservices/userProfileForUsername/" + username,
        token,
      });
      if (response.ok) {
        const jsonData = response.json();
        dispatch(showHideLoadingGif(false));
        jsonData.then((result: any) => {
          dispatch(setSignature(result.sign));
        });
        return jsonData;
      }
      navigate("/UserList");

      const error = await response.text();
      return rejectWithValue(error || "Error while fetching user data");
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      navigate("/UserList");
      return rejectWithValue(error.message || "Error while fetching user data");
    }
  }
);

export const createUser = createAsyncThunk<
  any,
  { user: userProfileManage; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "user/createUser",
  async ({ user, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;

    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "userprofileservices/userProfileCreate",
        token,
        body: user,
        type: "POST",
      });
      if (response.ok) {
        if (response.ok) {
          dispatch(showHideLoadingGif(false));
          const userRes: any = await response.json();
          let { error, message, messageList } = userRes;
          if (error) {
            return rejectWithValue(message || "Error while creating user");
          } else {
            navigate(-1);
            return {
              message: `${message}.`,
            };
          }
        }
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while creating user");
    }
  }
);

export const getPoliceOfficersList = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "user/getPoliceOfficersList",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await getPoliceOfficers({token});
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while fetching user data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while fetching user data");
    }
  }
);

//KHL PR 11294 13/02/24 START
export const getPoliceOfficersListbyAgency = createAsyncThunk<
  any,
  {
    agencyId: any;
  },
  { state: RootState; rejectValue: string }
>(
  "user/getPoliceOfficersListbyAgency",
  async ({ agencyId }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await getPoliceOfficersByAgencyId({ agencyId, token });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while fetching user data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while fetching user data");
    }
  }
);
//KHL PR 11294 13/02/24 END

//KHL PR 11294 15/02/24 START
export const getPoliceOfficersListByCounty = createAsyncThunk<
  any,
  {
    countyIds: any;
  },
  { state: RootState; rejectValue: string }
>(
  "user/getPoliceOfficersListbyCounty",
  async ({ countyIds }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await getPoliceOfficersByCountyIds({ countyIds, token });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while fetching user data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while fetching user data");
    }
  }
);
//KHL PR 11294 15/02/24 END

export const mergeUserByUsername = createAsyncThunk<
  any,
  {
    mergeUserName: string;
    username: string;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "user/mergeUserByUsername",
  async ({ mergeUserName, username, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    // dispatch(showHideLoadingGif(true));

    let queryParam = ''
    queryParam += `mergeUserName=${mergeUserName}&`
    queryParam += `username=${username}`

    try {
      const response = await doFetch({
        url: `userprofileservices/mergeUserProfile/?${queryParam}`,
        token,
        type: "POST",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const userRes: any = await response.text();
        console.log(userRes);
        return {
          message: `${userRes}.`,
        };
      } else {
      dispatch(showHideLoadingGif(false));
      const error = await response.text();
      return rejectWithValue(error || "Error while fetching user data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while fetching user data");
    }
  }
);

export const twoFactorRegistration = createAsyncThunk<
any,
{
  email: string;
  verificationCode: string;
  name: string;
  mailtype: string;
},
{
  state: RootState;
}
>("users/sendTwoFactorRegEmail", async (data, { getState, rejectWithValue, dispatch }) => {
  const { email, verificationCode, name, mailtype } = data;

    let { token } = getState().login;

    let queryParam = "";
    queryParam += `email=${email}&`
    queryParam += `verificationCode=${verificationCode}&`
    queryParam += `name=${name}&`
    queryParam += `mailtype=${mailtype}`

    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: `userprofileservices/send2FEmail?${queryParam}`,
        token,
        type: "POST",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const userRes: any = await response.text();
        return {
          message: `${userRes}.`,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while Sending Verification code");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while Sending Verification code");
    }
  }
);

export const twoFactorRegistrationviaText = createAsyncThunk<
any,
{
  mobile: string;
  verificationCode: string;
  county: string;
  url : string,
},
{
  state: RootState;
}
>("users/sendTwoFactorRegEmail", async (data, { getState, rejectWithValue, dispatch }) => {
  const { mobile, verificationCode, county, url } = data;

    let { token } = getState().login;

    let queryParam = "";
    queryParam += `mobile=${mobile}&`
    queryParam += `verificationCode=${verificationCode}&`
    queryParam += `county=${county}&`
    queryParam +=`url=${url}`
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: `userprofileservices/send2FTextMessage?${queryParam}`,
        token,
        type: "POST",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const userRes: any = await response.text();
        return {
          message: `${userRes}.`,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while Sending Verification code");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while Sending Verification code");
    }
  }
);

export const twoFactorSaveUser = createAsyncThunk<
any,
{
  email: string;
  phone: string;
  userId: number;
  twoFactorType: string;
},
{
  state: RootState;
}
>("users/twoFactorSaveUser", async (data, { getState, rejectWithValue, dispatch }) => {
  const { email, phone, userId, twoFactorType } = data;

    let { token } = getState().login;

    let queryParam = "";
    queryParam += `email=${email}&`
    queryParam += `phone=${phone}&`
    queryParam += `userId=${userId}&`
    queryParam += `twoFactorType=${twoFactorType}`

    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: `userprofileservices/save2FUser?${queryParam}`,
        token,
        type: "POST",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const userRes: any = await response.text();
        return {
          message: `${userRes}.`,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while Saveing Two Factor details");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while Saveing Two Factor details");
    }
  }
);

export const saveSignature = createAsyncThunk<
  any,
  {
    signatureData: string,
    userProfileId: number
  },
  {
    state: RootState
  }
>
  ("users/saveSignature",
    async (data, { getState, rejectWithValue, dispatch }) => {

      const { signatureData, userProfileId } = data;
      let { token } = getState().login;
      dispatch(showHideLoadingGif(true));

      try {
        const response = await doFetch({
          url: `userprofileservices/saveSignature`,
          token,
          type: "POST",
          body: { signature: signatureData, userProfileId },
        });
        if (response.ok) {

          dispatch(showHideLoadingGif(false));
          const resCode = await response.text();
          return {
            message: `${resCode}.`,
          }
        } else {
          dispatch(showHideLoadingGif(false));
          const error = await response.text();
          return rejectWithValue(error || "Error whilewhile saving signature");
        }
      } catch (error: any) {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(error.message || "Network error while saving signature");
      }
    });

export const getJudgeSavedSignature = createAsyncThunk<
  any,
  {
    userprofileId: number;
  },
  {
    state: RootState
  }
>("users/getJudgeSavedSignature", async (data, { getState, rejectWithValue, dispatch }) => {
  const { userprofileId } = data;
  let { token } = getState().login;
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: `userprofileservices/getJudgeSavedSignature?userprofileId=${userprofileId}`,
      token,
      type: "POST",
    });
    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      const resCode = await response.text();
      return {
        message: `${resCode}`,
      }
    } else {
      const error = await response.text();
      return rejectWithValue(error || "Error whilewhile validating user");
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error.message || "Network error while validating user");
  }
});

export const twoFactorDisableUser = createAsyncThunk<
any,
{
  userId: number;
  twoFactorStatus: string;
},
{
  state: RootState;
}
>("users/twoFactorDisableUser", async (data, { getState, rejectWithValue, dispatch }) => {
  const { userId, twoFactorStatus } = data;

    let { token } = getState().login;

    let queryParam = "";
    queryParam += `userId=${userId}&`
    queryParam += `twoFactorStatus=${twoFactorStatus}`

    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: `userprofileservices/disable2F?${queryParam}`,
        token,
        type: "POST",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const userRes: any = await response.text();
        return {
          message: `${userRes}.`,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while Disabling Two Factor details");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while Disabling Two Factor details");
    }
  }
);

export const saveViewIntro = createAsyncThunk<
any,
{
  userId: number;
  preferenceName: string;
  preferenceValue: string;
},
{
  state: RootState;
}
>("users/saveViewIntro", async (data, { getState, rejectWithValue, dispatch }) => {
  const { userId, preferenceName, preferenceValue } = data;

    let { token } = getState().login;

    let queryParam = "";
    queryParam += `userId=${userId}&`
    queryParam += `preferenceName=${preferenceName}&`
    queryParam += `preferenceValue=${preferenceValue}`

    try {
      const response = await doFetch({
        url: `userprofileservices/saveUserPreference?${queryParam}`,
        token,
        type: "POST",
      });
      if (response.ok) {
        const userRes: any = await response.text();
        return {
          message: `${userRes}.`,
        };
      } else {
        const error = await response.text();
        return rejectWithValue(error || "Error while saving intro preference");
      }
    } catch (error: any) {
      return rejectWithValue(error.message || "Error while saving intro preference");
    }
  }
);

const userProfileManageSlice = createSlice({
  name: "CreateUser",
  initialState,
  reducers: {
    updateElementValueUserProfilesReducer: updateElementValue,
    resetUserProfilesData: (state: UserProfilesReducer) => {
      return {
        ...state,
        ...initialUserProfilesState,
      };
    },
  },
  extraReducers(builder) {
    builder
      // Get police officers
      .addCase(getPoliceOfficersList.pending, (state) => {
        state.getPoliceOfficerFetchStatus = "loading";
      })
      .addCase(getPoliceOfficersList.fulfilled, (state, action) => {
        state.getPoliceOfficerFetchStatus = "success";
        state.policeOfficersList = action.payload;
      })
      .addCase(getPoliceOfficersList.rejected, (state, action) => {
        state.getPoliceOfficerFetchStatus = "error";
        state.getPoliceOfficerError = action.payload;
      })
      // Get police officers by Agency //KHL PR 11294 13/02/24
      .addCase(getPoliceOfficersListbyAgency.pending, (state) => {
        state.getPoliceOfficerFetchStatus = "loading";
      })
      .addCase(getPoliceOfficersListbyAgency.fulfilled, (state, action) => {
        state.getPoliceOfficerFetchStatus = "success";
        state.policeOfficersList = action.payload;
      })
      .addCase(getPoliceOfficersListbyAgency.rejected, (state, action) => {
        state.getPoliceOfficerFetchStatus = "error";
        state.getPoliceOfficerError = action.payload;
      })
      // Get police officers by County //KHL PR 11294 15/02/24
      .addCase(getPoliceOfficersListByCounty.pending, (state) => {
        state.getPoliceOfficerFetchStatus = "loading";
      })
      .addCase(getPoliceOfficersListByCounty.fulfilled, (state, action) => {
        state.getPoliceOfficerFetchStatus = "success";
        state.policeOfficersList = action.payload;
      })
      .addCase(getPoliceOfficersListByCounty.rejected, (state, action) => {
        state.getPoliceOfficerFetchStatus = "error";
        state.getPoliceOfficerError = action.payload;
      })
      // Get user data by username
      .addCase(getUserDataByUsername.pending, (state, action) => {
        state.getUserProfilesFetchStatus = "loading";
        state.getUserProfilesError = undefined;
      })
      .addCase(getUserDataByUsername.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          getUserProfilesFetchStatus: "success",
        };
      })
      .addCase(getUserDataByUsername.rejected, (state, action) => {
        state.getUserProfilesFetchStatus = "error";
        state.getUserProfilesError = action.payload;
      })

      // Get user data by id
      .addCase(getUserDataById.pending, (state, action) => {
        state.getUserProfilesFetchStatus = "loading";
        state.getUserProfilesError = undefined;
      })
      .addCase(getUserDataById.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          getUserProfilesFetchStatus: "success",
        };
      })
      .addCase(getUserDataById.rejected, (state, action) => {
        state.getUserProfilesFetchStatus = "error";
        state.getUserProfilesError = action.payload;
      })

      // Create user
      .addCase(createUser.pending, (state) => {
        state.UserProfilesStatus = "loading";
        state.UserProfilesError = undefined;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.UserProfilesStatus = "success";
        state.successMessage = action.payload.message;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.UserProfilesStatus = "error";
        state.UserProfilesError = action.payload;
      })

      // Merg user
      .addCase(mergeUserByUsername.pending, (state) => {
        state.MergProfileStatus = "loading";
        state.MergProfileError = undefined;
      })
      .addCase(mergeUserByUsername.fulfilled, (state, action) => {
        state.MergProfileStatus = "success";
        state.successMessage = action.payload.message;
      })
      .addCase(mergeUserByUsername.rejected, (state, action) => {
        state.MergProfileStatus = "error";
        state.MergProfileError = action.payload;
      });
  },
});

export const { updateElementValueUserProfilesReducer, resetUserProfilesData } =
  userProfileManageSlice.actions;

export const useUserProfilesReducer = () =>
  useAppSelector((state) => state.userProfile);

export default userProfileManageSlice.reducer;