import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { LoadingType } from "../../types";
import { updateElementObject } from "../../utils/updateElementObject";
import { resetSignature } from "./signPanelSlice";
import caseDetailSlice, { getSWCaseDetail, updateCaseDetailObject, updateElementValueCaseDetailReducer } from "./caseDetailSlice";
import { Navigate } from "react-router-dom";
import { showHideLoadingGif, updateDocGenerateError, updatePdfGenerationError } from "./commonSlice";
import { formatTextForCkEditor, setTemplateDataToFields } from "../../utils/common";
import { BLANK_ORDER, SEARCH_WAR_ASSIGNED_TO_AG_STATUS, SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS, SEARCH_WAR_ASSIGNED_TO_LP_STATUS, SEARCH_WAR_ASSIGNED_TO_SA_STATUS, SEARCH_WAR_DENIED_STATUS, SEARCH_WAR_LOCKED_BY_JUDGE_STATUS, SEARCH_WAR_OFFICER_PENDING_STATUS, SEARCH_WAR_VOID_STATUS, COMPL_SEARCH_WARRANT_WITH_SLASH } from "../../constants/common";
import { updateElementValueInSearchWarrantListReducer } from "./searchWarrantListSlice";
import { getSWConcurrentObj } from "./commonFormSlice";
import { stat } from "fs";
import CaseDetails from "../../components/warrantlist/CaseDetails";

interface blankOrderObj {
  searchWarrantId: number,
    txtCaseNumber: string,
    txtAgency: string,
    txtAppears: string,
    hiddenComplaintSignature: string,
    txtSubscribedDate: string,
    hiddenJudgeSignature: string,
    hiddenUserSignature: string,
    txtIssuancedDate: string,
    uploadSWFrom: string,
    badgeNo: string,
    txtPoliceOfficerInfo: string,
    searchWarrantType: string,
    txtOrder: string,
    signingJudgeUserId: string,
    validationId: string,
    countyID: number,
    countyName: string,
    judicialNumber: string,
    city: string,
    state: string,
    zip: string,
    circuitClerkName: string,
    unImpoundCase: boolean,
    orderTitle: string,
    formStatus: string,
    templateId: number,
    approvalUserType: string,
    approvalUserTypeId: number,
    approvalSAId: number,
    creatorId: number,
    creatorUserType: string,
    isOnBehalf: boolean,
    remark: string,
    agencyID: number,
    agencyCode: string,
    agencyName: string,
    isSARequired: boolean,
    lblHeadingCountySW: string,
    lblHeadingInTheCircuitSW: string

}
interface blankOrderState { 
    blankOrderObj : blankOrderObj;
    swApprovalUserTypes: string[];
  warrantTemplateList: string[];
  userTypeError?: string;
  status: LoadingType;
}

const initialState = {
    swApprovalUserTypes: [],
    warrantTemplateList: [],
    userTypeError: undefined,
    status: "idle",
    blankOrderObj: {
      searchWarrantId: 0,
        txtCaseNumber: "",
        txtAgency: "",
        txtAppears: "",
        hiddenComplaintSignature: "",
        txtSubscribedDate: "",
        hiddenJudgeSignature: "",
        hiddenUserSignature: "",
        txtIssuancedDate: "",
        uploadSWFrom: "",
        badgeNo: "",
        txtPoliceOfficerInfo: "",
        searchWarrantType: "",
        txtOrder: "",
        signingJudgeUserId: "",
        validationId: "",
        countyID: 0,
        countyName: "",
        judicialNumber: "",
        city: "",
        state: "",
        zip: "",
        circuitClerkName: "",
        formStatus: "",
        templateId: 0,
        approvalUserType: "",
        approvalUserTypeId: 0,
        approvalSAId: 0,
        unImpoundCase: false,
        orderTitle: "",
    
        uploadedFileName: "",
    
        hiddenUploadedPdf: "",
    
        creatorId: 0,
        creatorUserType: "",
        isOnBehalf: false,
    
        remark: "",
    
        agencyID: 0,
        agencyCode: "",
        agencyName: "",
        isSARequired: false,
        lblHeadingStateSW: "",
        lblHeadingCountySW: "",
        lblHeadingInTheCircuitSW: ""
    
    }
} as blankOrderState;


export const submitBlankOrder = createAsyncThunk<
  any,
  {
    formId: number;
    status: string;
    navigate: any;
    navigateTo: string;
  },
  {
    state: RootState;
    rejectValue: string;
  }
>(
  "blankOrder/submitBlankOrder",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { formId, status, navigate, navigateTo } = data;

    let { token } = getState().login;
    let queryParam = `jwtToken=Bearer ${token}`;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: "searchwarrantservices/submitblankorder/" + formId + "/" + status,
        token,
        type: "PUT",
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
        dispatch: dispatch
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        response.json().then((result: any) => {
          if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
            dispatch(
              updateDocGenerateError({
                error: true,
                errorMsg: result.errorInfo.validationErrors,
              })
            );
            return;
          } else {
            let message = "";
            if (status === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
              message = "Form submitted to SA for screening.";
            } else if (status === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
              message = "Form submitted to LP for screening.";
            } else if (status === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
              message = "Form submitted to AG for screening.";
            } else if (status === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) {
              message = "Form submitted to Judge for approval.";
            }

            result.responseMessage = message;

            let caseDetail = result;
            dispatch(updateCaseDetailObject(caseDetail));
          }
        });
        navigate(navigateTo);
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || BLANK_ORDER + " form data submitting process is failed."
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message ||
          BLANK_ORDER + " form data submitting process is failed."
      );
    }
  }
);
export const getTemplateDataForBlankOrder = createAsyncThunk<
  any,
  {
    countyId?: number;
    warrantForm?: string;
    templateName?: string;
  },
  {
    state: RootState;
  }
>(
  "blankOrder/getTemplateDataForBlankOrder",
  async (data, { getState, rejectWithValue,dispatch }) => {
    const { countyId, warrantForm, templateName } = data;

    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
   dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url:
          "templateservices/warranttemplatetypes/" +
          countyId +
          "/" +
          warrantForm +
          "/" +
          templateName,
        token,  
       concurrentObject: concurrObj,
       concurrentIdentifier: "SW",
       dispatch: dispatch
      });
      dispatch(showHideLoadingGif(false));
      return reponse.json();
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error);
    }
  }
);
export const createBlankOrder = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "blankOrder/createBlankOrder",
  async (data, { getState, rejectWithValue,dispatch }) => {
    dispatch(resetBlankOrderData());
    dispatch(resetSignature())
    const { searchWarrantId, navigate } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response:any = await doFetch({
        url:
          "searchwarrantservices/createBlankOrder/" +
          BLANK_ORDER +
          "/" +
          searchWarrantId,
        token,
        type: "POST",
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const result = await response.json();
          dispatch(
            updateElementValueCaseDetailReducer({
              elementName: "searchWarrantList",
              value: result.searchWarrantList,
            }));
        navigate("/BlankOrder");
        return result;
      }
      else {

        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while creating SW");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while creating SW");
    }
  }
);
export const saveBlankOrder = createAsyncThunk<
  any,
  {
    formId: number;
    status: string;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "blankOrder/saveBlankOrder",
  async (data, { getState, rejectWithValue,dispatch }) => {
    const { formId, status, navigate } = data;

    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);

    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "searchwarrantservices/saveblankorder/" + formId + "/" + status,
        token,
        type: "PUT",
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result: any) => {
          if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
            dispatch(
              updateDocGenerateError({
                error: true,
                errorMsg: result.errorInfo.validationErrors,
              })
            );
            return;
          } else {
            let message =
              "Blank Order saved successfully for " +
              getState().caseDetail.caseNumber +
              ".";

            result.responseMessage = message;
            let caseDetail = result;
            dispatch(updateCaseDetailObject(caseDetail));
            navigate("/CaseDetails");
          }
        });
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || BLANK_ORDER + " form data saving process is failed."
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || BLANK_ORDER + " form data saving process is failed."
      );
    }
      }
);

export const approveBlankOrder = createAsyncThunk<
   any,
   {searchWarrentId: number; proxy?: object; navigate: any, navigateTo: string},
   {state: RootState; rejectValue: string }
   >("blankOrder/approveBlankOrder", async (data, {getState, rejectWithValue, dispatch}) => {
   const {searchWarrentId, proxy, navigate, navigateTo} = data;
   let {token} = getState().login;
   let swList = getState().caseDetail.searchWarrantList;
   let concurrObj = getSWConcurrentObj(swList);
   dispatch(showHideLoadingGif(true));
   try {
     const response = await doFetch({
       url: "searchwarrantservices/approveblankorder/" + searchWarrentId,
       type: "PUT",
       token,
       body: proxy,
       concurrentObject : concurrObj,
       concurrentIdentifier : "SW",
       dispatch: dispatch
     });

     if (response.ok) {
       dispatch(showHideLoadingGif(false));
       let res = response.json().then((result) => {
         if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
           dispatch(
             updateDocGenerateError({
               error: true,
               errorMsg: result.errorInfo.validationErrors,
             })
           );
           return;
         } else {
           let message =
             "Order is approved for case number " +
             getState().caseDetail.caseNumber +
             ".";

           let caseDetail = result;
           dispatch(updateCaseDetailObject(caseDetail));

           dispatch(
             updateElementValueCaseDetailReducer({
               elementName: "responseMessage",
               value: message,
             })
           );
         }
       });
       navigate(navigateTo);
     } else {
       dispatch(showHideLoadingGif(false));
       return rejectWithValue("Error while updating status of the form.");
     }
   } catch (error: any) {
     dispatch(showHideLoadingGif(false));
     return rejectWithValue(
       error.message || "Error while updating status of the form."
     );
   }
   });

   export const returnBlankOrder = createAsyncThunk<
   any,
   {searchWarrentId: number; proxy?: any; navigate: any, navigateTo: string},
   {state: RootState; rejectValue: string }
   >("blankOrder/returnBlankOrder", async (data, {getState, rejectWithValue, dispatch}) => {
   const {searchWarrentId, proxy, navigate, navigateTo} = data;
    
   let caseNumber = getState().caseDetail.caseNumber;
   let {token} = getState().login;
   let swList = getState().caseDetail.searchWarrantList;
   let concurrObj = getSWConcurrentObj(swList);
   dispatch(showHideLoadingGif(true));
   try {
     const response = await doFetch({
       url: "searchwarrantservices/returnblankorder/" + searchWarrentId,
       type: "PUT",
       token,
       body: proxy,
       concurrentObject: concurrObj,
       concurrentIdentifier: "SW",
       dispatch: dispatch
     });

     if (response.ok) {
       dispatch(showHideLoadingGif(false));
       let res = response.json().then((result) => {
         let message = "";
         if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
           message = "Return to SA for " + caseNumber + ".";
         } else if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
           message = "Return to LP for " + caseNumber + ".";
         } else if (proxy.formStatus === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
           message = "Return to AG for " + caseNumber + ".";
         } else if (proxy.formStatus === SEARCH_WAR_OFFICER_PENDING_STATUS) {
           message = "RETURN TO OFFICER for " + caseNumber + ".";
         }

         result.responseMessage = message;

         let caseDetail = result;
         dispatch(updateCaseDetailObject(caseDetail));
       });
       navigate(navigateTo);
     } else {
       dispatch(showHideLoadingGif(false));
       return rejectWithValue(
         BLANK_ORDER +
           " form data returning process is failed for " +
           caseNumber +
           "."
       );
     }
   } catch (error: any) {
     dispatch(showHideLoadingGif(false));
     return rejectWithValue(
       error.message ||
         BLANK_ORDER +
           " form data returning process is failed for " +
           caseNumber +
           "."
     );
   }
   });

export const signBlankOrder = createAsyncThunk<
   any,
   {searchWarrantId: number; blankOrderObject: object, navigate: any, navigateTo: string },
   {state: RootState; rejectValue: string}
> ("blankOrder/signBlankOrder", async (data, {getState, rejectWithValue, dispatch}) => {
  const {searchWarrantId, blankOrderObject, navigate, navigateTo} = data;
  let {token} =getState().login;
  let swList = getState().caseDetail.searchWarrantList;
  let concurrObj = getSWConcurrentObj(swList);
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: "searchwarrantservices/signblankorder/" + searchWarrantId,
      type: "PUT",
      token,
      body: blankOrderObject,
      concurrentObject : concurrObj,
      concurrentIdentifier: "SW",
      dispatch: dispatch
    });
    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      let res = response.json().then((result) => {
        if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: result.errorInfo.validationErrors,
            })
          );
          return;
        }
        else if (result.errorInfo && result.errorInfo.error) {
          dispatch(
            updateElementValueCaseDetailReducer({
              elementName: "responseErrorMessage",
              value: result.errorInfo.errorMessage,
            })

          );
          dispatch(updatePdfGenerationError({
            error: result.errorInfo.error,
            errorMsg: result.errorInfo.errorMessage,
          }));
          navigate(navigateTo);
        } else {
          let message =
            "Order is issued for " + getState().caseDetail.caseNumber + ".";

          result.responseMessage = message;

          let caseDetail = result;
          dispatch(updateCaseDetailObject(caseDetail));
        }

      });
      navigate(navigateTo);
    } else {
      return rejectWithValue(BLANK_ORDER + " form signing process is failed.");
    }
    dispatch(showHideLoadingGif(false));
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(
      error.message || BLANK_ORDER + " form signing process is failed."
    );
  }
})

const blankOrderSlice = createSlice({
    name: "blankOrder",
    initialState,
    reducers: {
        updateElementValueBlankOrderReducer: updateElementValue,
        updateElementObjectValue: (state: blankOrderState, action) => {
          let elementObj = state.blankOrderObj;
          updateElementObject(state, action, elementObj);
        },
        updateBlankOrderObject: (state: blankOrderState, action) => {
          state.blankOrderObj = action.payload;
        },
        resetBlankOrderData: (state: blankOrderState) => {
            return {
              ...state,
              ...initialState,
            };
          },
          updateSignElementBlackOrder: (state: blankOrderState, action) => {
            let elementObj = state.blankOrderObj;
            updateElementObject(state, action, elementObj);
          },
    },
    extraReducers(builder) {
        builder
          .addCase(createBlankOrder.pending, (state) => {
            state.status = "loading";
            state.userTypeError = undefined;
          })
          .addCase(createBlankOrder.fulfilled, (state, action) => {
           state.status = "success";
           state.blankOrderObj = action.payload;
          })
          .addCase(createBlankOrder.rejected, (state, action) => {
            state.status = "error";
            state.userTypeError = action.payload;
          })
    
          //
          .addCase(getTemplateDataForBlankOrder.pending, (state) => {
            state.status = "loading";
            state.userTypeError = undefined;
          })
          .addCase(
            getTemplateDataForBlankOrder.fulfilled,
            (state, action) => {
              state.status = "success";
              state.blankOrderObj.orderTitle =
              formatTextForCkEditor(setTemplateDataToFields(action.payload.blankOrder.orderTitle, state.blankOrderObj.agencyName, state.blankOrderObj.badgeNo, state.blankOrderObj.txtAppears));
              state.blankOrderObj.txtOrder =
              formatTextForCkEditor(setTemplateDataToFields(action.payload.blankOrder.txtOrder, state.blankOrderObj.agencyName, state.blankOrderObj.badgeNo, state.blankOrderObj.txtAppears));
            }
          )
          .addCase(getTemplateDataForBlankOrder.rejected, (state, action) => {
            state.status = "error";
            state.userTypeError = undefined;
          })
          .addCase(submitBlankOrder.pending, (state) => {
            state.status = "loading";
            state.userTypeError = undefined;
          })
          .addCase(submitBlankOrder.fulfilled, (state, action) => {
            state.status = "success";
          //  state.blankOrderObj = action.payload;
          })
          .addCase(submitBlankOrder.rejected, (state, action) => {
            state.status = "error";
            state.userTypeError = action.payload;
          })
    
          //save
          .addCase(saveBlankOrder.pending, (state) => {
            state.status = "loading";
            state.userTypeError = undefined;
          })
          .addCase(saveBlankOrder.fulfilled, (state, action) => {
            state.status = "success";
            state.blankOrderObj = action.payload;
          })
          .addCase(saveBlankOrder.rejected, (state, action) => {
            state.status = "error";
            state.userTypeError = action.payload;
          })
          //approve
          .addCase(approveBlankOrder.pending, (state) => {
            state.status = "loading";
            state.userTypeError = undefined;
          })
          .addCase(approveBlankOrder.fulfilled, (state, action) => {
            state.status = "success";
            state.blankOrderObj = action.payload;
          })
          .addCase(approveBlankOrder.rejected, (state, action) => {
            state.status = "error";
            state.userTypeError = action.payload;
          });
          
      },
});
export const { updateElementValueBlankOrderReducer, resetBlankOrderData ,
  updateElementObjectValue,updateBlankOrderObject,
  updateSignElementBlackOrder } = blankOrderSlice.actions;
export const useBlankOrderReducer = () =>
    useAppSelector((state) => state.blankOrder);
export default blankOrderSlice.reducer;