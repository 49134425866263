import React, { useCallback, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch } from "../../hooks/hooks";
import { changePassword } from "../../redux/slice/loginSlice";
import { merge } from "../../utils/common";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import ButtonComponent from "../common/ButtonComponent";
import { isValidPassword } from "../common/CommonUtil";
import Footer from "../common/Footer";
import Header from "../common/Header";
import TextInputComponent from "../common/TextInputComponent";

interface ForgotPasswordResetProps { }

export const ForgotPasswordReset: React.FC<ForgotPasswordResetProps> = (props) => {

  let defaultUserSchema = yup.object().shape({
    newPassword: yup.string().required("Password is required.")
      .test("check-password", function (newPassword) {
        if (newPassword !== "" && !isValidPassword(newPassword)) {
          return this.createError({
            message:
              "Password must contain at least 8 characters including at least one special character and one numeric value",
          });
        } else {
          return true;
        }
      }),
    confirmNewPassword: yup.string().required("Confirm password is required."),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), null],
        "Password and confirm password does not match."
      ),
  });

  const errorMessageAlert = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const { code } = useParams();
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");


  const onChangePassword = (event: any) => {
    setNewPassword(event.target.value);
  };

  const onChangeConfirmPassword = (event: any) => {
    setConfirmNewPassword(event.target.value);
  };


  const cancelClick = () => {
    errorMessageAlert.clearError();
    setNewPassword("");
    setConfirmNewPassword("");
    navigate("/");
  };

  const saveClick = async () => {
    let dataForVal = {
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
      passwordConfirmation: confirmNewPassword,
    };

    try {
      let schema = [];
      schema.push(defaultUserSchema);
      schema = merge(...schema);
      const user = await schema.validate(dataForVal, {
        abortEarly: false,
      });

      let dataObj = {
        code: code,
        password: newPassword,
        navigate: navigate,
      };
      dispatch(changePassword(dataObj));

    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  }

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );


  return (
    <>
      <Header headerName="Reset Password" />
      <div className="reset-background">
        <div className="center-body-reset">
          <>
            {errorMessageAlert.messages.length > 0 ? <div className='p-3'>
              <ErrorMessageAlert
                messages={errorMessageAlert.messages}
                clearError={errorMessageAlert.clearError}
              />
            </div> : ""}
          </>
          <Card>
            <Card.Body>
              <div className="text-center">
                <h3>
                  <img
                    src={require("../../assets/logo.png")}
                    width="35"
                    height="35"
                  />
                  <b className="ps-2">Illinois Court Services</b>
                </h3>
                <div className="title-text text-start mt-3">
                  <h6>Reset Password</h6>
                </div>
              </div>

              <div className="mt-4 mb-3 fw-bold">
                <label>New Password</label>
                <TextInputComponent
                  type="password"
                  className="form-control"
                  placeholder="Enter New password"
                  name="newPassword"
                  onChange={(e) => onChangePassword(e)}
                  isError={errorKeys.includes("newPassword") ? true : false}
                />
              </div>
              <div className="mb-3 fw-bold">
                <label>Confirm Password</label>
                <TextInputComponent
                  type="password"
                  className="form-control"
                  placeholder="Enter confirm password"
                  name="confirmNewPassword"
                  onChange={(e) => onChangeConfirmPassword(e)}
                  isError={errorKeys.includes("confirmNewPassword") ? true : false}
                />
              </div>
              <div className="mb-5 mt-4">
                <Row>
                  <Col>
                    <ButtonComponent className="w-100" onClick={() => saveClick()}>Save</ButtonComponent>
                  </Col>
                  <Col>
                    <ButtonComponent className="w-100" variant="danger" onClick={() => cancelClick()}>
                      Cancel
                    </ButtonComponent>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </div>
        <Footer />
      </div>
    </>
  );
};
