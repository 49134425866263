import React, { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import * as yup from "yup";
import { useAppDispatch } from '../../hooks/hooks';
import { forgotPassword, updateElementValueInLoginReducer, useLoginReducer } from '../../redux/slice/loginSlice';
import { merge } from '../../utils/common';
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import ButtonComponent from '../common/ButtonComponent';
import FormComponent from '../common/FormComponent';



interface ForgotYourPasswordPopupProps {
  show: boolean;
  onHide: () => void;
}

let defaultUserSchema = yup.object().shape({
  userName: yup.string().required("User Name is required."),
});

export const ForgotYourPasswordPopup: React.FC<ForgotYourPasswordPopupProps> = (props) => {
  const { onHide } = props;
  const dispatch = useAppDispatch();
  const loginReducer = useLoginReducer();
  const errorMessageAlert = useAlert();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const [userName, setUserName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [workPhone, setWorkPhone] = React.useState("");
  const [workExtension, setWorkExtension] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState<string>("");


  useEffect(() => {
    if (loginReducer.forgotPwMessage) {
      let message = "";
      if (loginReducer.forgotPwMessage === 'success') {
        message = "Password is sent on the email address configured in your account.";
      } else if (loginReducer.forgotPwMessage === 'failed') {
        message = 'Error occured while sending an email to reset password. Please try again.';
      } else {
        message = loginReducer.forgotPwMessage;
      }
      setAlertMessage(message);
    }
  }, [loginReducer.forgotPwMessage, dispatch]);

  const handleUserNameChange = (event: any) => {
    setUserName(event.target.value);
  };

  const hidePopup = () => {
    onHide();
    setErrorKeys([""]);
    errorMessageAlert.clearError();
    setUserName("");
    setFirstName("");
    setLastName("");
    setWorkPhone("");
    setWorkExtension("");
    dispatch( updateElementValueInLoginReducer({elementName: "forgotPwStatus", value: "idle" }));
    dispatch( updateElementValueInLoginReducer({elementName: "forgotPwMessage", value: "" }));
    setAlertMessage("");
  };

  const sendClick = async () => {
    let dataForVal = {
      userName: userName,
      firstName: firstName,
      lastName: lastName,
      workPhone: workPhone,
      workExtension: workExtension,
    };

    try {
      let schema = [];
      schema.push(defaultUserSchema);
      schema = merge(...schema);
      await schema.validate(dataForVal, {
        abortEarly: false,
      });

      dispatch(forgotPassword({userName: userName}));

    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    [errorMessageAlert]
  );

  const data = [

    {
      type: "textInput",
      props: {
        labelName: "UserName",
        name: "userName",
        value: userName,
        isRequired: true,
        onChange: handleUserNameChange,
        isError: errorKeys.includes("userName"),
      },
      colSize: 12,
    },
  ]

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered onHide={() => hidePopup()}
      backdrop='static'
      scrollable={true} >
      {errorMessageAlert.messages.length > 0 ? <div className='p-3'>
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
      </div> : ""}
      <Modal.Header><b>Forgot Password</b></Modal.Header>
      <Modal.Body>
        <FormComponent
          totalColumns={12}
          colSize={6}
          componentData={data} />
        <br/>
        <p className={ loginReducer.forgotPwStatus === "loading" ?  "text-info text-left" : loginReducer.forgotPwMessage === 'success' ? "text-success text-left" : "text-danger text-left"}>{ loginReducer.forgotPwStatus === "loading" ? "Searching account ..." : alertMessage }</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent onClick={() => sendClick()} href='#'>Send</ButtonComponent>
        <ButtonComponent variant='danger' href='#' onClick={() => hidePopup()}>Cancel</ButtonComponent>
      </Modal.Footer>
    </Modal>
  );
}