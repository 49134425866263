import { useCallback, useState } from "react";

export default function useModel(intialValue?: boolean) {
  const [state, set] = useState(intialValue ? intialValue : false);
  const open = useCallback(() => set(true), []);
  const close = useCallback(() => set(false), []);
  const toggle = useCallback(() => set((_state) => !_state), []);
  return {
    isOpen: state,
    open,
    close,
    toggle,
  };
}
