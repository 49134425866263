
import {
    COMPL_SEARCH_WARRANT_WITH_SLASH, PROOF_OF_SERVICE, INVENTORY, ORDER_OF_CUSTODY,
    BLANK_ORDER, AMENDED_INVENTORY, APPLICATION_AND_ORDER, ROLE_ADMINISTRATOR,
    ROLE_STATES_ATTORNEY, ROLE_JUDGE, ROLE_ATTORNEY_GENERAL,
    ROLE_LOCAL_PROSECUTOR, 
    SEARCH_WAR_OFFICER_PENDING_STATUS,
    SEARCH_WAR_INCOMPLETE_STATUS, SEARCH_WAR_COMPLETE_STATUS, SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS,
    SEARCH_WAR_VOID_STATUS, SEARCH_WAR_DENIED_STATUS, SEARCH_WAR_ASSIGNED_TO_SA_STATUS, SEARCH_WAR_ASSIGNED_TO_LP_STATUS,
    SEARCH_WAR_ASSIGNED_TO_AG_STATUS, SEARCH_WAR_LOCKED_BY_SA_STATUS, SEARCH_WAR_LOCKED_BY_JUDGE_STATUS, SEARCH_WAR_LOCKED_BY_AG_STATUS,
    SEARCH_WAR_LOCKED_BY_LP_STATUS, SEARCH_WAR_DELETED_STATUS, SEARCH_WAR_ACTIVE_STATUS, SEARCH_WAR_PROCESSED_STATUS, ACTIONTYPE_SAVE
} from "../../constants/common";

export const Util = {
    getConfirmMessage(status: string, formType: string, userId: number, creatorId: number, formStatus: string,
        userRole:string, formCreatorUserType:string, approvalSAId:number) {
        let confirm = null;
        if (status === ACTIONTYPE_SAVE) {
            if (creatorId === userId) {
                if ((SEARCH_WAR_ASSIGNED_TO_SA_STATUS === formStatus || SEARCH_WAR_LOCKED_BY_SA_STATUS === formStatus)
                    && (formType === APPLICATION_AND_ORDER || BLANK_ORDER === formType) && ROLE_ADMINISTRATOR !== userRole) {
                    confirm = "Form will be saved with Assign to SA status. Do you want to continue?";
                } else {
                    if ((SEARCH_WAR_INCOMPLETE_STATUS === formStatus || SEARCH_WAR_ASSIGNED_TO_AG_STATUS === formStatus
                        || (SEARCH_WAR_LOCKED_BY_AG_STATUS === formStatus)) && ROLE_ATTORNEY_GENERAL === userRole) {
                        confirm = 'Form will be saved with "Assigned to AG" status. Do you want to continue?';
                    } else if ((SEARCH_WAR_INCOMPLETE_STATUS === formStatus || SEARCH_WAR_ASSIGNED_TO_LP_STATUS === formStatus
                        || (SEARCH_WAR_LOCKED_BY_LP_STATUS === formStatus)) && ROLE_LOCAL_PROSECUTOR === userRole) {
                        confirm = 'Form will be saved with "Assigned to LP" status. Do you want to continue?';
                    } else if ((SEARCH_WAR_INCOMPLETE_STATUS === formStatus || SEARCH_WAR_ASSIGNED_TO_SA_STATUS === formStatus
                        || (SEARCH_WAR_LOCKED_BY_SA_STATUS === formStatus)) && ROLE_STATES_ATTORNEY === userRole) {
                        confirm = "Form will be saved with Assign to SA status. Do you want to continue?";
                    } else {
                        if(SEARCH_WAR_COMPLETE_STATUS === formStatus){
                            confirm = "Form will be saved with Complete status. Do you want to continue?";
                        }else{
                            confirm = "Form will be saved with Officer Pending status. Do you want to continue?";
                        }                        
                    }
                }
            } else if ((SEARCH_WAR_ASSIGNED_TO_SA_STATUS === formStatus || SEARCH_WAR_LOCKED_BY_SA_STATUS === formStatus)
                && (formType === APPLICATION_AND_ORDER || BLANK_ORDER === formType) && ROLE_STATES_ATTORNEY === userRole) {
                confirm = "Form will be saved with Officer Pending status. Do you want to continue?";
            } else if ((SEARCH_WAR_ASSIGNED_TO_AG_STATUS === formStatus || SEARCH_WAR_LOCKED_BY_AG_STATUS === formStatus)
                && (formType === APPLICATION_AND_ORDER || BLANK_ORDER === formType) && ROLE_ATTORNEY_GENERAL === userRole) {
                confirm = "Form will be saved with Officer Pending status. Do you want to continue?";
            } else if ((SEARCH_WAR_ASSIGNED_TO_LP_STATUS === formStatus || SEARCH_WAR_LOCKED_BY_LP_STATUS === formStatus)
                && (formType === APPLICATION_AND_ORDER || BLANK_ORDER === formType) && ROLE_LOCAL_PROSECUTOR === userRole) {
                confirm = "Form will be saved with Officer Pending status. Do you want to continue?";
            }
            else if ((SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS === formStatus || SEARCH_WAR_LOCKED_BY_JUDGE_STATUS === formStatus)
                && (formType === APPLICATION_AND_ORDER || BLANK_ORDER === formType) && (approvalSAId && approvalSAId > 0)
                && userRole === ROLE_JUDGE) {
                confirm = 'Return To Officer button returns Application and Order form to officer with status "Officer pending".';
            } else if ((SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS === formStatus || SEARCH_WAR_LOCKED_BY_JUDGE_STATUS === formStatus)
                && (formType === APPLICATION_AND_ORDER || BLANK_ORDER === formType)
                && (!approvalSAId || approvalSAId === 0)) {
                if (ROLE_ATTORNEY_GENERAL === formCreatorUserType) {
                    confirm = 'Form will be saved with "Assigned to AG" status. Do you want to continue?';
                } else if (ROLE_STATES_ATTORNEY === formCreatorUserType) {
                    confirm = 'Form will be saved with Assign to SA status. Do you want to continue?';
                } else if (ROLE_LOCAL_PROSECUTOR === formCreatorUserType) {
                     confirm = 'Form will be saved with "Assigned to LP" status. Do you want to continue?';
                
                } else if (ROLE_JUDGE === formCreatorUserType) {
                     confirm = 'Form will be saved with Officer Pending status. Do you want to continue?';
                }
                 
            } else {
                confirm = 'Form will be saved as incomplete. Do you want to continue?';
            }
        } else if ((status === SEARCH_WAR_ASSIGNED_TO_SA_STATUS || status === SEARCH_WAR_LOCKED_BY_SA_STATUS)
            && creatorId === userId && userRole === ROLE_STATES_ATTORNEY) {
            confirm = 'Form will be saved with Assign to SA status. Do you want to continue?';
        } else if (status === SEARCH_WAR_ASSIGNED_TO_SA_STATUS && creatorId === userId && userRole === ROLE_ADMINISTRATOR) {
            confirm = 'Form will be saved with Assign to SA status. Do you want to continue?';
        } else if (status === SEARCH_WAR_ASSIGNED_TO_SA_STATUS && creatorId !== userId && userRole === ROLE_JUDGE) {
            confirm = 'Form will be saved with Assign to SA status. Do you want to continue?';
        } else if (status === SEARCH_WAR_ACTIVE_STATUS) {
            confirm = 'Form cannot be changed once signed. Do you want to continue?';
        } else if (status === SEARCH_WAR_INCOMPLETE_STATUS) {
            confirm = 'Form will be saved as incomplete. Do you want to continue?';
        } else if (status === SEARCH_WAR_OFFICER_PENDING_STATUS) {
            confirm = 'Form will be saved with Officer Pending status. Do you want to continue?';
        } else if (status === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) {
            confirm = 'Form status will be changed to "Assigned to Judge". Do you want to continue?';
        } else if (status === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
            confirm = 'Do you want to submit the form for screening?';
        } else if ((status === SEARCH_WAR_ASSIGNED_TO_AG_STATUS || status === SEARCH_WAR_LOCKED_BY_AG_STATUS) && creatorId === userId
            && userRole === ROLE_ATTORNEY_GENERAL) {
            confirm = 'Form will be saved with "Assigned to AG" status. Do you want to continue?';
        } else if (status === SEARCH_WAR_ASSIGNED_TO_AG_STATUS && creatorId == userId && userRole === ROLE_ADMINISTRATOR) {
            confirm = 'Form will be saved with "Assigned to AG" status. Do you want to continue?';
        } else if (status === SEARCH_WAR_ASSIGNED_TO_AG_STATUS && !(creatorId == userId && userRole === ROLE_JUDGE)) {
            confirm = 'Form will be saved with "Assigned to AG" status. Do you want to continue?';
        } else if ((status === SEARCH_WAR_ASSIGNED_TO_LP_STATUS || status === SEARCH_WAR_LOCKED_BY_LP_STATUS) && creatorId === userId
            && userRole === ROLE_ATTORNEY_GENERAL) {
            confirm = 'Form will be saved with "Assigned to LP" status. Do you want to continue?';
        } else if (status === SEARCH_WAR_ASSIGNED_TO_LP_STATUS && creatorId === userId && userRole === ROLE_ADMINISTRATOR) {
            confirm = 'Form will be saved with "Assigned to LP" status. Do you want to continue?';
        } else if (status === SEARCH_WAR_ASSIGNED_TO_LP_STATUS && !(creatorId == userId && userRole === ROLE_JUDGE)) {
            confirm = 'Form will be saved with "Assigned to LP" status. Do you want to continue?';
        } else if (status === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
            confirm = 'Form will be saved with "Assigned to LP" status. Do you want to continue?';
        } else if (status === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
            confirm = 'Form will be saved with "Assigned to AG" status. Do you want to continue?';
        }
        return confirm;
    },

    getSuccessMessage(formName: string, status: string, caseNumber: string, msg: any) {
        let message = null;
        if (status && status === SEARCH_WAR_OFFICER_PENDING_STATUS) {
            message = msg + " application for " + caseNumber + " is sent back to officer.";
        } else if (status && status === SEARCH_WAR_ASSIGNED_TO_JUDGE_STATUS) {
            message = msg + " application for " + caseNumber + " is approved.";
        } else if (status && status === SEARCH_WAR_PROCESSED_STATUS && COMPL_SEARCH_WARRANT_WITH_SLASH === formName) {
            message = "Search Warrant is issued for " + caseNumber + ".";
        } else if (status && status === SEARCH_WAR_PROCESSED_STATUS && INVENTORY === formName) {
            message = "Inventory on " + caseNumber + " is signed.";
        } else if (status && status === SEARCH_WAR_DENIED_STATUS && COMPL_SEARCH_WARRANT_WITH_SLASH === formName) {
            message = "Search Warrant of " + caseNumber + " case is denied.";
        } else if (status && status === SEARCH_WAR_DENIED_STATUS && APPLICATION_AND_ORDER === formName) {
            message = "Application and Order of " + caseNumber + " case is denied.";
        } else if (status && status === SEARCH_WAR_DENIED_STATUS && BLANK_ORDER === formName) {
            message = "Blank Order of " + caseNumber + " case is denied.";
        } else if (status && status === SEARCH_WAR_DENIED_STATUS && INVENTORY === formName) {
            message = "Inventory of " + caseNumber + " case is denied.";
        } else if (status && status === SEARCH_WAR_DENIED_STATUS && AMENDED_INVENTORY === formName) {
            message = "Amended Inventory of " + caseNumber + " case is denied.";
        } else if (status && status === SEARCH_WAR_VOID_STATUS) {
            message = msg + " " + caseNumber + " case is voided.";
        } else if (status && status === SEARCH_WAR_ASSIGNED_TO_SA_STATUS && (APPLICATION_AND_ORDER === formName || BLANK_ORDER === formName)) {
            message = msg + " application for " + caseNumber + " is sent back to officer.";
        } else if (status === SEARCH_WAR_DELETED_STATUS && (PROOF_OF_SERVICE === formName)) {
            message = "Proof of Service form for " + caseNumber + " is deleted.";
        } else if (status === SEARCH_WAR_DELETED_STATUS && (INVENTORY === formName)) {
            message = "Inventory form for " + caseNumber + " is deleted.";
        } else if (status === SEARCH_WAR_DELETED_STATUS && (AMENDED_INVENTORY === formName)) {
            message = "Amended Inventory form for " + caseNumber + " is deleted.";
        } else if (status === SEARCH_WAR_DELETED_STATUS && (ORDER_OF_CUSTODY === formName)) {
            message = "Order of Custody form for " + caseNumber + " is deleted.";
        } else if (status === SEARCH_WAR_DELETED_STATUS) {
            message = "Order issued for " + caseNumber + " case is deleted.";
        } else if (status === SEARCH_WAR_ASSIGNED_TO_AG_STATUS) {
            message = msg + " application for " + caseNumber + " is sent back to Attorney General.";
        } else if (status === SEARCH_WAR_ASSIGNED_TO_LP_STATUS) {
            message = msg + " application for " + caseNumber + " is sent back to Local Prosecutors.";
        } else if (status === SEARCH_WAR_ASSIGNED_TO_SA_STATUS) {
            message = msg + " application for " + caseNumber + " is sent back to State Attorney.";
        }
        return message;
    },

};