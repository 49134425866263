import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";

export type AgencyType = {
  countyId: number;
  agencyId: number;
  agencyName: string;
  agencyCode: string;
  displayingName?: string;
  policeAgency: string;
  isStateAgency: boolean;
  longFormEnabled: boolean;
  notarizationReq: boolean;
  isComplaintSupervised: boolean;
  isLPAllowed: boolean;
  countySelectList: any[];
  countyIdList: string;
  countyList:string;
};

interface CountyReducerType extends AgencyType {
  getAgencyFetchStatus: LoadingType;
  getAgencyError?: string;
  createAgencyStatus: LoadingType;
  createAgencyError?: string;
  updateAgencyStatus: LoadingType;
  updateAgencyError?: string;
  associateCoutiesToAgencyStatus: LoadingType;
  associateCoutiesToAgencyError?: string;
  successMessage?: string;
}

const initialAgencyState: AgencyType = {
  countyId: 0,
  agencyId: 0,
  agencyName: "",
  agencyCode: "",
  policeAgency: "",
  isStateAgency: false,
  longFormEnabled: false,
  notarizationReq: false,
  isComplaintSupervised: false,
  isLPAllowed: false,
  countySelectList: [],
  countyIdList: "",
  countyList:""
};

const initialState = {
  ...initialAgencyState,
  getAgencyFetchStatus: "idle",
  createAgencyStatus: "idle",
  updateAgencyStatus: "idle",
} as CountyReducerType;

export const getAgencyDataById = createAsyncThunk<
  any,
  { id: number; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "agency/getAgencyDataById",
  async ({ id, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "agencyservices/getAgencyById/" + id,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      }
      navigate("/AgencyList");
      const error = await response.text();
      return rejectWithValue(error || "Error while fetching agency data");
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      navigate("/AgencyList");
      return rejectWithValue("Error while fetching agency data");
    }
  }
);

export const createAgency = createAsyncThunk<
  any,
  { agency: AgencyType; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "agency/createAgency",
  async ({ agency, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "agencyservices/agencies",
        token,
        body: agency,
        type: "POST",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const createdAgencyName = await response.text();
        navigate(-1);
        return {
          message: `${createdAgencyName} agency created successfully`,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while creating agency");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while creating agency");
    }
  }
);

export const updateAgency = createAsyncThunk<
  any,
  { agency: AgencyType; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "agency/updateAgency",
  async ({ agency, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "agencyservices/agencies/" + agency.agencyId,
        token,
        body: agency,
        type: "PUT",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const createdAgencyName = await response.text();
        navigate("/AgencyList");
        return {
          message: `${createdAgencyName} agency updated successfully`,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(error || "Error while update agency");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while update agency");
    }
  }
);

export const associateCoutiesToAgency = createAsyncThunk<
  any,
  { agency: AgencyType; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "agency/associateCoutiesToAgency",
  async ({ navigate, agency }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url: `agencyservices/associateCouties`,
        token,
        body: agency,
        type: "POST",
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        navigate("/AgencyList");
        return {
          message: `Agency associated with county successfully`,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while associating agency with county"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while associating agency with county");
    }
  }
);

const agencySlice = createSlice({
  name: "agency",
  initialState,
  reducers: {
    updateElementValueAgencyReducer: updateElementValue,
    resetAgencyData: (state: CountyReducerType) => {
      return {
        ...state,
        ...initialAgencyState,
      };
    },
  },
  extraReducers(builder) {
    builder
      // Get agency data by id
      .addCase(getAgencyDataById.pending, (state) => {
        state.getAgencyFetchStatus = "loading";
        state.getAgencyError = undefined;
      })
      .addCase(getAgencyDataById.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          getAgencyFetchStatus: "success",
        };
      })
      .addCase(getAgencyDataById.rejected, (state, action) => {
        state.getAgencyFetchStatus = "error";
        state.getAgencyError = action.payload;
      })

      // Create agency
      .addCase(createAgency.pending, (state) => {
        state.createAgencyStatus = "loading";
        state.createAgencyError = undefined;
      })
      .addCase(createAgency.fulfilled, (state, action) => {
        state.createAgencyStatus = "success";
        state.successMessage = action.payload.message;
      })
      .addCase(createAgency.rejected, (state, action) => {
        state.createAgencyStatus = "error";
        state.createAgencyError = action.payload;
      })

      // Update agency
      .addCase(updateAgency.pending, (state) => {
        state.updateAgencyStatus = "loading";
        state.updateAgencyError = undefined;
      })
      .addCase(updateAgency.fulfilled, (state, action) => {
        state.updateAgencyStatus = "success";
        state.successMessage = action.payload.message;
      })
      .addCase(updateAgency.rejected, (state, action) => {
        state.updateAgencyStatus = "error";
        state.updateAgencyError = action.payload;
      })

      // Associate counties to agency
      .addCase(associateCoutiesToAgency.pending, (state) => {
        state.associateCoutiesToAgencyStatus = "loading";
        state.associateCoutiesToAgencyError = undefined;
      })
      .addCase(associateCoutiesToAgency.fulfilled, (state, action) => {
        state.associateCoutiesToAgencyStatus = "success";
        state.successMessage = action.payload.message;
      })
      .addCase(associateCoutiesToAgency.rejected, (state, action) => {
        state.associateCoutiesToAgencyStatus = "error";
        state.associateCoutiesToAgencyError = action.payload;
      });
  },
});

export const { updateElementValueAgencyReducer, resetAgencyData } =
  agencySlice.actions;

export const useAgencyReducer = () => useAppSelector((state) => state.agency);

export default agencySlice.reducer;
