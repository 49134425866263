import React from "react";
import { OverlayTrigger, Row, Col, Tooltip } from "react-bootstrap";
import ButtonComponent from "../../common/ButtonComponent";
import {
  FiColumns,
  FiDownload,
  FiPrinter,
  FiRotateCcw,
  FiSmartphone,
  FiZoomIn,
  FiZoomOut,
} from "react-icons/fi";

interface VideoConferenceSplitScreenMenuProps {
  handleDownload: () => void;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleReset: () => void;
  handlePrint: () => void;
  handleSplitScreen: () => void;
  showSpliter: boolean;
}

const VideoConferenceSplitScreenMenu: React.FC<
  VideoConferenceSplitScreenMenuProps
> = (props) => {
  const {
    handleDownload,
    handleZoomIn,
    handleZoomOut,
    handleReset,
    handlePrint,
    handleSplitScreen,
    showSpliter,
  } = props;

  const btnStyleEnable = {
    outline: "none",
    backgroundColor: "#E7F3FE",
    padding: "0px 8px 4px 8px",
    borderRadius: "7px",
    margin: "0px 5px",
    cursor: "pointer",
    border: "none",
    minWidth: "60px",
  };

  const btnStyleDisable = {
    outline: "none",
    backgroundColor: "#F3ECF0",
    padding: "0px 8px 4px 8px",
    borderRadius: "7px",
    margin: "0px 5px",
    cursor: "not-allowed",
    border: "none",
    minWidth: "60px",
  };

  return (
    <Row className="w-100 text-center">
      <Col>
        <OverlayTrigger
          placement="bottom"
          overlay={
            showSpliter ? (
              <Tooltip id="tooltip-disabled">Split Screen</Tooltip>
            ) : (
              <Tooltip id="tooltip-disabled">Close Split Screen</Tooltip>
            )
          }
        >
          <span className="d-inline-block">
            <ButtonComponent
              style={btnStyleEnable}
              onClick={handleSplitScreen}
              className="p-2"
            >
              {showSpliter ? (
                <FiColumns
                  size={20}
                  className="text-light"
                  style={{ pointerEvents: "none" }}
                />
              ) : (
                <FiSmartphone
                  size={20}
                  className={showSpliter ? "text-secondary" : "text-light"}
                  style={{ pointerEvents: "none" }}
                />
              )}
            </ButtonComponent>
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="tooltip-disabled">Zoom In</Tooltip>}
        >
          <span className="d-inline-block">
            <ButtonComponent
              className="p-2"
              style={btnStyleDisable}
              onClick={handleZoomIn}
              disabled={showSpliter}
            >
              <FiZoomIn
                size={20}
                className={showSpliter ? "text-secondary" : "text-light"}
                style={{ pointerEvents: "none" }}
              />
            </ButtonComponent>
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="tooltip-disabled">Zoom Out</Tooltip>}
        >
          <span className="d-inline-block">
            <ButtonComponent
              className="p-2"
              style={showSpliter ? btnStyleDisable : btnStyleEnable}
              onClick={handleZoomOut}
              disabled={showSpliter}
            >
              <FiZoomOut
                size={20}
                className={showSpliter ? "text-secondary" : "text-light"}
                style={{ pointerEvents: "none" }}
              />
            </ButtonComponent>
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="tooltip-disabled">Reset</Tooltip>}
        >
          <span className="d-inline-block">
            <ButtonComponent
              className="p-2"
              style={showSpliter ? btnStyleDisable : btnStyleEnable}
              onClick={handleReset}
              disabled={showSpliter}
            >
              <FiRotateCcw
                size={20}
                className={showSpliter ? "text-secondary" : "text-light"}
                style={{ pointerEvents: "none" }}
              />
            </ButtonComponent>
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="tooltip-disabled">Download</Tooltip>}
        >
          <span className="d-inline-block">
            <ButtonComponent
              className="p-2"
              style={showSpliter ? btnStyleDisable : btnStyleEnable}
              onClick={handleDownload}
              disabled={showSpliter}
            >
              <FiDownload
                size={20}
                className={showSpliter ? "text-secondary" : "text-light"}
                style={{ pointerEvents: "none" }}
              />
            </ButtonComponent>
          </span>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="tooltip-disabled">Print</Tooltip>}
        >
          <span className="d-inline-block">
            <ButtonComponent
              className="p-2"
              style={showSpliter ? btnStyleDisable : btnStyleEnable}
              onClick={handlePrint}
              disabled={showSpliter}
            >
              <FiPrinter
                size={20}
                className={showSpliter ? "text-secondary" : "text-light"}
                style={{ pointerEvents: "none" }}
              />
            </ButtonComponent>
          </span>
        </OverlayTrigger>
      </Col>
    </Row>
  );
};

export default VideoConferenceSplitScreenMenu;
