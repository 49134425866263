import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { updateElementValue } from "../../utils/updateElementValue";
import { PROOF_OF_SERVICE } from "../../constants/common";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { LoadingType } from "../../types";
import { updateElementObject } from "../../utils/updateElementObject";
import { getSWCaseDetail, updateCaseDetailObject } from "./caseDetailSlice";
import { showHideLoadingGif, updatePdfGenerationError, updateDocGenerateError } from "./commonSlice";
import { getSWConcurrentObj } from "./commonFormSlice";

interface proofOfServiceObj {
  searchWarrantId: number;
  txtCaseNumber: string;
  txtPersonPremises: string;
  personPremisesAndOrText: string;
  dateOfWarrantExecution: string;
  timeOfWarrantExecution: string;
  dateOfFilingProofOfService: string;
  officerName: string;
  officerBadgeNo: string;
  agencyName: string;
  chkExecuted: boolean;
  chkNotExecuted: boolean;
  agencyCode: string;
  issuanceDate: string;
  issuanceTime: string;
  warrantJudgeName: string;
  personPremisesExecutedText: string;

  lblHeadingCountySW: string;
  lblHeadingInTheCircuitSW: string;

  dateWarrantExe?: Date;
}
interface proofOfServiceState {
  proofOfServiceObj: proofOfServiceObj;
  fetchingError?: string;
  fetchingStatus: LoadingType;
}

const initialState = {
  fetchingError: undefined,
  fetchingStatus: "idle",
  proofOfServiceObj: {
    searchWarrantId: 0,
    txtCaseNumber: "",
    txtPersonPremises: "",
    personPremisesAndOrText: "",
    dateOfWarrantExecution: "",
    timeOfWarrantExecution: "",
    dateOfFilingProofOfService: "",
    officerName: "",
    officerBadgeNo: "",
    agencyName: "",
    chkExecuted: false,
    chkNotExecuted: false,
    agencyCode: "",
    issuanceDate: "",
    issuanceTime: "",
    warrantJudgeName: "",
    personPremisesExecutedText: "",
    lblHeadingCountySW: "",
    lblHeadingInTheCircuitSW: "",
  },
} as proofOfServiceState;

export const createProofOfService = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "proofOfService/createProofOfService",
  async (data, { getState, rejectWithValue, dispatch }) => {
    dispatch(resetProofOfServiceData());
    const { searchWarrantId, navigate } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "searchwarrantservices/proofOfService/" + searchWarrantId,
        token,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        navigate("/proofOfService");
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while creating Proof Of Service"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || "Error while Proof Of Service");
    }
  }
);

export const getSearchWarrantDetail = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "proofOfService/getSearchWarrantDetail",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, navigate } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "searchwarrantservices/getSearchWarrantDetail/" + searchWarrantId,
        token,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result: any) => {
          dispatch(
            getWarrantTypeTemplateDetails({
              templateId: result.templateId,
              navigate: navigate,
            })
          );
        });
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while getting Search Warrant Detail"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while getting Search Warrant Detail"
      );
    }
  }
);

export const getWarrantTypeTemplateDetails = createAsyncThunk<
  any,
  {
    templateId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "proofOfService/getWarrantTypeTemplateDetails",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { templateId, navigate } = data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "templatetypeservices/getByWarrantTemplatedId/" + templateId,
        token,
        concurrentObject : concurrObj,
        concurrentIdentifier: "SW",
		    dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while getting Warrant Type Template Detail"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while Warrant Type Template Detail"
      );
    }
  }
);

export const saveProofOfService = createAsyncThunk<
  any,
  {
    searchWarrantId: number;
    warrantStatus: string;
    proofOfService?: object;
    navigate: any;
    navigateTo: string;
  },
  { state: RootState; rejectValue: string }
>(
  "searchWarrant/saveProofOfService",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantId, warrantStatus, proofOfService, navigate, navigateTo } =
      data;
    let { token } = getState().login;
    let swList = getState().caseDetail.searchWarrantList;
    let concurrObj = getSWConcurrentObj(swList);
    dispatch(showHideLoadingGif(true));
    try {
      const response = await doFetch({
        url:
          "searchwarrantservices/saveProofOfService/" +
          searchWarrantId +
          "/" +
          warrantStatus,
        type: "PUT",
        token,
        body: proofOfService,
        concurrentObject: concurrObj,
        concurrentIdentifier: "SW",
        dispatch: dispatch
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let res = response.json().then((result) => {
          if (result && result.errorInfo && result.errorInfo.error && result.errorInfo.validationErrors) {
            dispatch(
              updateDocGenerateError({
                error: true,
                errorMsg: result.errorInfo.validationErrors,
              })
            );
            return;
          } else {
            if (result.errorInfo && result.errorInfo.error) {
              dispatch(updatePdfGenerationError({
                error: result.errorInfo.error,
                errorMsg: result.errorInfo.errorMessage,
              }));
            } else {
              let message = PROOF_OF_SERVICE +
                " added successfully for the warrant.";
              result.responseMessage = message;
              let caseDetail = result;
              dispatch(updateCaseDetailObject(caseDetail));
            }
            navigate(navigateTo);
          }
       });
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while saving proof of service");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while saving proof of service"
      );
    }
  }
);

const proofOfServiceSlice = createSlice({
  name: "proofOfService",
  initialState,
  reducers: {
    updateElementValueProofOfServiceReducer: updateElementValue,
    updateElementObjectValue: (state: proofOfServiceState, action) => {
      let elementObj = state.proofOfServiceObj;
      updateElementObject(state, action, elementObj);
    },
    updateProofOfServiceObject: (state: proofOfServiceState, action) => {
      state.proofOfServiceObj = action.payload;
    },
    resetProofOfServiceData: (state: proofOfServiceState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createProofOfService.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(createProofOfService.fulfilled, (state, action) => {
        state.fetchingStatus = "success";
        state.proofOfServiceObj = action.payload;
      })
      .addCase(createProofOfService.rejected, (state, action) => {
        state.fetchingStatus = "error";
        state.fetchingError = undefined;
      })

      .addCase(getWarrantTypeTemplateDetails.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = undefined;
      })
      .addCase(getWarrantTypeTemplateDetails.fulfilled, (state, action) => {
        state.fetchingStatus = "success";
        let templateList = action.payload;
        if (templateList) {
          for (let i = 0; i < templateList.length; i++) {
            if (templateList[i].templateField === "POSPrsnPremisesText") {
              state.proofOfServiceObj.personPremisesAndOrText =
                templateList[i].templateValue;
            }
            if (templateList[i].templateField === "POSPrsnPremisesPrint") {
              state.proofOfServiceObj.personPremisesExecutedText =
                templateList[i].templateValue;
            }
          }
        }
      })
      .addCase(getWarrantTypeTemplateDetails.rejected, (state, action) => {
        state.fetchingStatus = "error";
        state.fetchingError = undefined;
      });
  },
});
export const {
  updateElementValueProofOfServiceReducer,
  resetProofOfServiceData,
  updateElementObjectValue,
  updateProofOfServiceObject,
} = proofOfServiceSlice.actions;
export const useProofOfServiceReducer = () =>
  useAppSelector((state) => state.proofOfService);
export default proofOfServiceSlice.reducer;
