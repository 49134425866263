import console from "console";
import React from "react";
import { Row, Col, Modal, Button, FormControl } from "react-bootstrap";

interface RemarkPopupProps {
    show: boolean;
    onHide: () => void;
    submitRemark: (remark: string, remarkFor: string) => void;
    remarkFor:string;
    action: string;
    onlyView: boolean;
    remarksForView: string;
    remarkRequired: boolean;
    children?: React.ReactNode;
    remarkAfterEdit?:() => void;
    isEditClick? :boolean;
}

const RemarkPopup: React.FC<RemarkPopupProps> = (props) => {
    const { show, onHide, submitRemark, remarkFor, action, onlyView, remarksForView, remarkRequired, children,remarkAfterEdit, isEditClick } = props;

    const [errorMsg, setErrorMsg] = React.useState("");
    const [remark, setRemark] = React.useState("");


    const handleFormSubmit = (evt:any) => {
        const isValidate = validate();
        if (isValidate) {
            submitRemark(remark, remarkFor);
            setRemark("");
            setErrorMsg("");
            onHide();
        }
    }

    const handleValChange = (event:any) => {
        let { name, value } = event.target;
        setRemark(value);
    }

    const validate = () => {
        if (props.remarkRequired) {
            if (!remark || remark.trim().length === 0) {
                setErrorMsg("Remarks is required.");
                return false;
            }
        }
        return true;
    }

    const closeModal = () => {
        setRemark("");
        setErrorMsg("");
        onHide();
    }
    return (
        <Modal size="xl" show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered onHide={() => onHide()} backdrop='static' >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {"Remarks" + (action && action.trim().length > 0 ? (" - " + action.trim()) : "")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(errorMsg && errorMsg.trim().length > 0) ?
                    <Row className="mt-1">
                        <Col>
                            <label className="text-danger pr-4">
                                {errorMsg}
                            </label>
                        </Col>
                    </Row>
                    : ""}
                <Row className="mt-1">
                    <Col>
                        <FormControl as="textarea" aria-label="With textarea" rows={3}
                            disabled={onlyView}
                            name="remark" value={onlyView ? remarksForView : remark}
                            onChange={handleValChange} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className="g-2">
                    <Col>
                        {onlyView ? "" :
                            <Button
                                variant="primary"
                                className="w-100"
                                onClick={handleFormSubmit}
                            >SAVE
                            </Button>
                        }
                    </Col>
                    <Col>
                       {remarkAfterEdit && isEditClick ? 
                        <Button
                            variant="primary"
                            className="w-100"
                            onClick={remarkAfterEdit}>
                                &nbsp;&nbsp;OK&nbsp;&nbsp;
                        </Button> : ""}
                        
                    </Col>
                    <Col>
                        <Button variant="danger" onClick={closeModal} className="w-100"> CANCEL
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default RemarkPopup;
