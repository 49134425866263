import React, { useCallback, useEffect, useState } from "react";
import BreadcrumbComponent from "../common/BreadcrumbComponent";
import FormComponent from "../common/FormComponent";
import Header from "../common/Header";
import { RiEditLine, RiPlayMiniFill } from "react-icons/ri";
import ButtonComponent from "../common/ButtonComponent";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDashboardReducer, updateElementValueDashboardReducer } from "../../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import { getEcomplaintMainDetails, useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import ConfirmPopup from "../common/ConfirmPopup";
import { usePreTrialReleaseRecognizance } from "../../redux/slice/preTrialReleaseRecognizanceSlice";
import {
  saveSummons,
  resetSummons,
  useSummonsReducer,
  updateElementObjectValue,
  updateElementValueSummonsReducer,
  fetchSummons,
  fetchInitialSummons,
  returnToOfficerSummons,
  resubmitSummonImage
} from "../../redux/slice/summonsSlice";
import * as yup from "yup";
import { summonsPreview } from "../../redux/slice/commonFormSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import {
  LF_COMPLETE_STATUS,
  ROLE_AGENCY_ADMIN,
  ROLE_COUNTY_ADMIN,
  ROLE_JUDGE,
  ROLE_POLICE_OFFICER,
  ROLE_STATES_ATTORNEY,
  ROLE_SA_ADMIN,
  LF_DELETED_STATUS,
  LF_FILED_STATUS,
  LF_PENDING_STATUS,
  LF_REJECTED_STATUS,
  LF_RETURNED_STATUS,
  LF_SEND_TO_JUDGE_STATUS,
  LF_SIGNED_STATUS,
  LF_VOID_STATUS,
  TYPE_OF_ARREST_ARRESTWARRANT,
  CREATE_LF_FORM_SUMMON,
  LF_SUMMONS,
  LF_STATUS_PENDING_SUPERVISOR_REVIEW,
  TYPE_OF_ARREST_SUMMON,
  LF_STATUS_PENDING_SUPERVISOR_RETURNED,
  EC_MSG_ALREADY_SENT_TO_JUDGE_SUMMON,
  LF_COMMUNI_FAILED_STATUS,
} from "../../constants/common";
import { useAgencyListReducer } from "../../redux/slice/agencyListSlice";
import SignPopup from "../common/SignPopup";
import ModifyDefendantPopup from "./ModifyDefendantPopup";
import { formatNameWithSuffix } from "../../utils/common";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import ModifyDefendantPopupSummon from "./ModifyDefendantPopupSummon";
import { getEComplaintData, updateElementValueComplaintReducer } from "../../redux/slice/complaintSlice";
import { editComplaintFromSummons } from "../../redux/slice/ecomplaintDashboardSlice";
import InfoPopup from "../common/InfoPopup";
import { getJudgeSavedSignature } from "../../redux/slice/userProfileSlice";
import { setSignature } from "../../redux/slice/signPanelSlice";

let defInfoSchema = yup.object().shape({
  defDob: yup.string().required(),
  defFirstName: yup.string().required(),
  gender: yup.string().required(),
  defRace: yup.string().required(),
  defHair: yup.string().required(),
  defEyes: yup.string().required(),
  defHeight: yup.string().required(),
  defWeight: yup.string().required(),
});


interface LocalState {
  disableImpoundRequested: boolean;
  disableOtherBelowFields: boolean;
  disableModifyInfo: boolean;
  modifyCaseInfo: boolean;
  goModifyCaseInfo: boolean;
  showDefendantInfoModel: boolean;
  showReturnToOfficerBtn: boolean;
  showConfirmPopup: boolean;
  confirmPopupMessage: string;
  confirmationPopupType: string;
  showSignPopup: boolean;
  ecFormStatus: string;
}

const initialState: LocalState = {
  disableImpoundRequested: false,
  disableOtherBelowFields: false,
  disableModifyInfo: false,
  modifyCaseInfo: false,
  goModifyCaseInfo: false,
  showDefendantInfoModel: false,
  showReturnToOfficerBtn: false,
  showConfirmPopup: false,
  confirmPopupMessage: "",
  confirmationPopupType: "",
  showSignPopup: false,
  ecFormStatus: ""
};

function Summons() {

  let summonsValidationSchema = yup.object().shape({
      chkAppearanceOption: yup
      .string()
      .nullable()
      .required("Appearance option is not selected."),
  });

  const { userType, safeTactEnable } = useLoginReducer();
  // const { summonsDataObject,summonState } = useSummonsReducer();
  const { summonsDataObject,summonState, defInfoObject, fetchSummonStatus } = useSummonsReducer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dashboardReducer = useDashboardReducer();
  const {complaint, countList, ecFormList} = useComplaintMainReducer();
  const { officerDateProxy } = usePreTrialReleaseRecognizance();
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const [state, _] = useState(initialState);
  const { agencyList } = useAgencyListReducer();
  const complaintMain = useComplaintMainReducer();
  const loginReducer = useLoginReducer();
  const [
    isVisibleBtnReSubmitSummon,
    setIsVisibleBtnReSubmitSummon,
  ] = React.useState(false);
  const errorMessageAlert = useAlert();
  const [infoPopupShow, setInfoPopupShow] = useState(false);

  const handleLongFormListClick = () => {
    dispatch(
      updateElementValueDashboardReducer({
        elementName: "isCardActive",
        value: false,
      })
    );
  }

  useEffect(() => {
    setVisibleElements();
    
      if (
      summonState === "edit" ||
      summonState === "editThrounghMainPage"
    ) {
      const summon = ecFormList.find(
        (item: any) => item.formTypeName === CREATE_LF_FORM_SUMMON
      );

      let idValue = 0;
      if (summonsDataObject.formId === 0) {
        idValue = summon.formId;
      } else {
        idValue = summonsDataObject.formId;
      }

      dispatch(fetchSummons(idValue));
    } else {
      dispatch(
        fetchInitialSummons(complaint.ecomplaintId)
      );
    }
  return () => {
      if (!state.goModifyCaseInfo) {
        dispatch(resetSummons());
      }
      dispatch(
        updateElementValueSummonsReducer({
          elementName: "fetchSummonStatus",
          value: "idle",
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Helper function to set state, pass key value pairs to update the existing state
  const setState = useCallback((e: Partial<LocalState>) => {
    _((s) => {
      return { ...s, ...e } as LocalState;
    });
  }, []);

  const validate = async () => {
    let _error = false;
    let Obj = {
      chkAppearanceOption:
      summonsDataObject.isAppearInPerson ||
      summonsDataObject.isAppearRemotely,
    };
        await summonsValidationSchema
          .validate(Obj, { abortEarly: false })
          .catch((error: any) => {
            _error = true;
            handleError(
              error.inner.map((e: any) => e.path),
              error.errors
            );
          });

    await defInfoSchema
      .validate(
        defInfoObject.defFirstName === ""
          ? summonsDataObject
          : defInfoObject,
        {
          abortEarly: false,
        }
      )
      .catch((error: any) => {
        _error = true;
        handleError(
          ["deInfoSchema"],
          [
            "Please complete defendant information before submitting this to the Judge.",
          ]
        );
      });
    return !_error;
  };

  useEffect(() => {
    const summonStatus =
      ecFormList instanceof Array
        ? ecFormList
          .filter((item) => item.formTypeName === "Summon")
          .map((item) => (item.status ? item.status.trim() : ""))[0]
        : "";
    console.log(summonStatus);
    setState({
      ecFormStatus: summonStatus,
    });
    if (
      userType === ROLE_POLICE_OFFICER ||
      userType === ROLE_AGENCY_ADMIN ||
      userType === ROLE_COUNTY_ADMIN
    ) {
      if (complaint.caseStatus !== LF_VOID_STATUS) {
        setState({
          disableImpoundRequested: true,
        });
        if (
          !state.modifyCaseInfo ||
          summonStatus !== LF_REJECTED_STATUS
        ) {
          setState({
            disableImpoundRequested: false,
          });
        }
        // if (arrestWarrantStatus === LF_RETURNED_STATUS) {
        //   setState({
        //     disableModifyInfo: true,
        //   });
        // } else {
        //   setState({
        //     disableModifyInfo: false,
        //     disableImpoundRequested: false,
        //   });
        // }
        if (summonStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW) {
          setState({
            showReturnToOfficerBtn: true,
          });
        }
      } else {
        setState({
          disableModifyInfo: true,
          disableImpoundRequested: true,
          disableOtherBelowFields: true,
        });
      }
    } else if (userType === ROLE_JUDGE) {
      setState({
        disableModifyInfo: true,
        disableImpoundRequested: false,
        showReturnToOfficerBtn: true,
      });
    }

    if (
      summonStatus === LF_DELETED_STATUS ||
      summonStatus === LF_SIGNED_STATUS
    ) {
      setState({
        disableModifyInfo: true,
        disableImpoundRequested: true,
        disableOtherBelowFields: true,
      });
    }
  }, [
    complaint.caseStatus,
    ecFormList,
    userType,
    setState,
  ]);

  const handlePreviewClick = () => {
    let Obj = {
      ...summonsDataObject
    }
    let formId = summonsDataObject.formId;
    if (formId === 0){
      const summon = ecFormList.find(
        (item: any) => item.formTypeName === CREATE_LF_FORM_SUMMON
      );
      Obj = {
        ...summonsDataObject,
        formId: summon.formId,
      };
      formId = summon.formId;
    }
    dispatch(
      summonsPreview({
        ecomplaintId: complaint.ecomplaintId,
        ecFormId: formId,
        formData: Obj,
      })
    );
  };

  const setVisibleElements = () => {
    if (
      loginReducer.canReSubmitEComplaint &&
      complaintMain.countyIntegratedWithCMS &&
      complaintMain.summonUploadFailed
    ) {
      setIsVisibleBtnReSubmitSummon(true);
    }
  };

  const handleReturnClick = () => {
    let message = "";
    if (
      complaint.caseStatus ===
      LF_STATUS_PENDING_SUPERVISOR_REVIEW
    ) {
      message = "Do you want to return the summon to the officer?";
    } else {
      message =
        "Do you want to return the summon to the officer without signing the warrant?";
    }
    setState({
      showConfirmPopup: true,
      confirmPopupMessage: message,
      confirmationPopupType: "returnToOfficer",
    });
  };

  const handleRejectClick = () => {
    setState({
      showConfirmPopup: true,
      confirmPopupMessage:
        "Do you want to reject the summons without signing the summons?",
      confirmationPopupType: "reject",
    });
  };

  const handleConfirmationClick = async () => {
    if (state.confirmationPopupType === "sign") {
      const res = await dispatch(getJudgeSavedSignature({
        userprofileId: loginReducer.userProfileId
      }));
      if (res.payload.message) {
        dispatch(setSignature(res.payload.message));
      }
      setState({ showSignPopup: true });
    } else if (state.confirmationPopupType === "returnToOfficer") {
        dispatch(
          returnToOfficerSummons({
            navigate: navigate,
            navigateFrom: "Summons",
            formId: summonsDataObject.formId,
            formData: summonsDataObject,
          })
        );
    } else if (state.confirmationPopupType === "reject") {
        let data = {
          formId: summonsDataObject.formId,
          formType: CREATE_LF_FORM_SUMMON,
          ecomplaintId: complaint.ecomplaintId,
          status: LF_REJECTED_STATUS,
          formData: summonsDataObject,
          navigate: navigate,
        };
        dispatch(editComplaintFromSummons(data));
    } else {
        let Obj = {
          ecSummonsData: summonsDataObject,
          ecomplaintId: complaint.ecomplaintId,
          status: LF_COMPLETE_STATUS,
          navigate: navigate,
        }
        dispatch(saveSummons(Obj))
    }
  };

  const handleSignClick = async () => {
    const isValid = await validate();
    if (!isValid) {
      return;
    }
    setState({
      confirmationPopupType: "sign",
      confirmPopupMessage:
        "Form cannot be changed once signed. Do you want to continue?",
    });
    const isSummon =
      ecFormList instanceof Array &&
      ecFormList.find(
        (item) => item.formTypeName === CREATE_LF_FORM_SUMMON
      );
    if (isSummon) {
      setState({
        confirmPopupMessage:
          "Has the officer sworn to the content of this Complaint/Summon?",
      });
    }
    setState({
      showConfirmPopup: true,
    });
  }

  const handleSaveClick = async () => {
    const isValid = await validate();
    if (!isValid) {
      return;
    }

    if(state.ecFormStatus === LF_COMMUNI_FAILED_STATUS && userType === ROLE_COUNTY_ADMIN){
      resubmitSummon()
    }else{
      if (complaint.caseStatus === LF_SEND_TO_JUDGE_STATUS) {
        setInfoPopupShow(true);
        return;
      }
      if (
        complaint.caseStatus === LF_FILED_STATUS &&
        (state.ecFormStatus === LF_PENDING_STATUS ||
          state.ecFormStatus === LF_RETURNED_STATUS ||
          state.ecFormStatus === LF_REJECTED_STATUS)
      ) {
        setState({
          confirmPopupMessage:
            "You are about to send the summons to the Judge for a signature.  Are you sure you want to continue?",
        });
        if (
          agencyList.length > 0 &&
          agencyList[0].isComplaintSupervised &&
          userType === ROLE_POLICE_OFFICER &&
          complaint.typeOfArrest === TYPE_OF_ARREST_SUMMON
        ) {
          setState({
            confirmPopupMessage:
              "You are about to send the summon to the Supervisor. Are you sure you want to continue?",
          });
        }
        setState({
          showConfirmPopup: true,
        });
      } else if (
        state.ecFormStatus === LF_REJECTED_STATUS ||
        (state.ecFormStatus === LF_RETURNED_STATUS &&
          complaint.caseStatus ===
            LF_STATUS_PENDING_SUPERVISOR_RETURNED) ||
        state.ecFormStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW
      ) {
        setState({
          confirmPopupMessage:
            "You are about to submit this complaint and summons to the Judge for signature.  Are you sure you want to continue?",
        });
        if (
          agencyList[0].isComplaintSupervised &&
          userType === ROLE_POLICE_OFFICER
        ) {
          setState({
            confirmPopupMessage:
              "You are about to submit this complaint and summons to the Supervisor. Are you sure you want to continue?",
          });
        }
        setState({
          showConfirmPopup: true,
        });
      } else if (state.ecFormStatus === LF_RETURNED_STATUS) {
        let Obj = {
          ecSummonsData: summonsDataObject,
          ecomplaintId: complaint.ecomplaintId,
          status: LF_RETURNED_STATUS,
          navigate: navigate,
        }
        dispatch(saveSummons(Obj))
      } else {
        let Obj = {
          ecSummonsData: summonsDataObject,
          ecomplaintId: complaint.ecomplaintId,
          status: LF_COMPLETE_STATUS,
          navigate: navigate,
        }
        dispatch(saveSummons(Obj))
      }
    }
  
  };

  const resubmitSummon = () => {
    let ecCaseDetail = { ...complaintMain };
    let ecomplaintId = complaint.ecomplaintId;
    let fromScheduler = false;
    let dataObj = {
      ecCaseDetail: ecCaseDetail,
      ecomplaintId: ecomplaintId,
      fromScheduler: fromScheduler,
      navigate: navigate,
    };
    dispatch(resubmitSummonImage(dataObj));
  };

  const saveSummonsDataAfterConfirm = () => {
    const data = {
      ...summonsDataObject,
    }
    // data.defDob = moment(probableDataObject.defDob).format("MM/DD/YYYY");
    // data.arrestDate = moment(probableDataObject.arrestDate).format("MM/DD/YYYY");

    let Obj = {
      ecSummonsData: data,
      ecomplaintId: complaint.ecomplaintId,
      status: LF_COMPLETE_STATUS,
      navigate: navigate,
    }
    dispatch(saveSummons(Obj))
  }

  const itemLinkList = [
    { itemLink: "/", itemName: "DashBoard" },
    { itemLink: "/" + dashboardReducer.PreviousURLLFC.replace(/^.*\/\/.*?\//, ''), itemName: "Long Form List", itemFunction: handleLongFormListClick },
    { itemLink: "/EComplaintDashboard", itemName: "Existing Forms" },
  ];

  const lstCourtTime = officerDateProxy.courtTimeList
    ? officerDateProxy.courtTimeList.split(";").map((item) => {
      return { label: item, value: item };
    })
    : [];

    const handleCancelClick = () => {
      dispatch(resetSummons());
      if (summonState === "edit") {
        navigate("/EComplaintDashboard");
      } else if (summonState === "editThrounghMainPage" || summonState === "create") {
        navigate("/EComplaintMainPage");
      } else {
        navigate(-1);
      }
    };

  const handleValChange = (event: any) => {
    const { value, name } = event.target;
    dispatch(
      updateElementObjectValue({ elementName: name, value })
    );
  };

  const handleValChangeCheck = (event: any) => {
    if ("isAppearInPerson" === event.target.name) {
      dispatch(
        updateElementObjectValue({
          elementName: event.target.name,
          value: "Y",
        })
      );
      dispatch(
        updateElementObjectValue({
          elementName: "isAppearRemotely",
          value: "N",
        })
      );
    } else if ("isAppearRemotely" === event.target.name) {
      dispatch(
        updateElementObjectValue({
          elementName: event.target.name,
          value: "Y",
        })
      );
      dispatch(
        updateElementObjectValue({
          elementName: "isAppearInPerson",
          value: "N",
        })
      );
    }
  };

  const handleCkEditorValChange = (name: string, editor: any) => {
    dispatch(updateElementObjectValue({ elementName: name, value: editor.getData() }));
  }

  const formDataOffenses = [
    {
      type: "htmlTag",
      props: {
        value: (
          <>
            <span className="title-text">
              <p>Offenses</p>
            </span>
          </>
        ),
      },
      colSize: 12,
    },
    {
      type: "customComponents2",
      props: {},
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: "Conditions of Pre-Trial Release :",
      },
      colSize: 12,
    },
    {
      type: "editor",
      props: {
        data: summonsDataObject.conditionsOfPretrialRelease
          ? summonsDataObject.conditionsOfPretrialRelease
          : "",
        onChange: (event: any, editor: any) => {
          handleCkEditorValChange("conditionsOfPretrialRelease", editor);
        },
      },
      colSize: 12,
    },
  ];

  const CustomComponents2 = useCallback(() => {
    return (
      <div>
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Violation</th>
              <th>Statute</th>
            </tr>
          </thead>
          <tbody>
            {countList instanceof Array &&
              countList.map((item, index) => (
                <tr key={item.violationCode}>
                  <td>
                    <RiPlayMiniFill className="text-info" />{" "}
                    {item.violationDescription}
                  </td>
                  <td>{item.violationCode}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  }, [countList]);

  // const CourtProceedingComponent = () => {
  //     return (
  //         <>
  //             <span className="title-text">
  //                 <p>Court Proceedings</p>
  //             </span>
  //             <br />
  //             <Row className="g-2 align-items-center">
  //                 <Col className="d-flex align-items-center" xs={"auto"}>
  //                     <label style={{ paddingRight: "2px" }}>Court&nbsp;Proceedings :</label>
  //                 </Col>
  //                 <Col md={2}>
  //                     <TextInputComponent
  //                         type="text"
  //                         name="courtRoom"
  //                       onChange={handleValChange}
  //                       value={summonsDataObject.courtRoom}
  //                     //   isWarning={warningKeys.includes("courtRoom")}
  //                     />
  //                 </Col>
  //                 <Col md={2}>
  //                     <TextInputComponent
  //                         type="date"
  //                         name="courtDate"
  //                       onChange={handleValChange}
  //                     //   onBlur={handleInputBlur}
  //                       value={summonsDataObject.courtDate}
  //                     //   disabled={radioNoCourtText === "Y"}
  //                     //   isError={errorKeys.includes("courtDate")}
  //                     />
  //                 </Col>
  //                 <Col md={2}>
  //                     {/* <SelectComponent
  //                         options={[
  //                             { label: "-- SELECT --", value: "" },
  //                             ...lstCourtTime,
  //                         ]}
  //                         placeholder="-- SELECT --"
  //                         name="courtTime"
  //                         //   onChange={handelChangeSelect("courtTime")}
  //                         //   isError={errorKeys.includes("courtTime")}
  //                         //   isDisabled={radioNoCourtText === "Y"}
  //                         isDefaultLabel={true}
  //                     //   value={lstCourtTime.find((item) => item.value === courtTime)}
  //                     /> */}
  //                                             <TextInputComponent
  //                         type="text"
  //                         name="courtTime"
  //                       onChange={handleValChange}
  //                     //   onBlur={handleInputBlur}
  //                       value={summonsDataObject.courtTime}
  //                     //   disabled={radioNoCourtText === "Y"}
  //                     //   isError={errorKeys.includes("courtDate")}
  //                     />
  //                 </Col>

  //             </Row>
  //         </>
  //     );
  // };

  // const courtProceedingData = [
  //     {
  //         type: "htmlTag",
  //         props: {
  //             value: <CourtProceedingComponent />,
  //         },
  //         colSize: 12,
  //     },
  // ]

    // ##################################################

    const isFilledDemographics = () => {
      let { defDob, gender, defHair, defEyes, defHeight, defWeight } =
        summonsDataObject;
      if (
        !defDob ||
        defDob.trim() === "" ||
        !gender ||
        gender.trim() === "" ||
        !defHair ||
        defHair.trim() === "" ||
        !defEyes ||
        defEyes.trim() === "" ||
        !defHeight ||
        isNaN(defHeight) ||
        defHeight.toString().trim() === "" ||
        parseFloat(defHeight) === 0 ||
        !defWeight ||
        isNaN(defWeight) ||
        defWeight.toString().trim() === "" ||
        parseFloat(defWeight) === 0
      ) {
        return false;
      } else {
        return true;
      }
    };

    const handleError = useCallback(
      (_errorKeys: string[], errorMessages: string[]) => {
        setErrorKeys(_errorKeys);
        errorMessageAlert.handleError(errorMessages);
        window.scrollTo({
          behavior: "smooth",
          top: 0,
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    useEffect (() => {
      if(fetchSummonStatus === "success" && !isFilledDemographics() && complaint.caseStatus === LF_FILED_STATUS){
        handleModifyDefendantInfo()
      }
    }, [fetchSummonStatus])

    useEffect(() => {
      if (
        fetchSummonStatus === "success" &&
        !isFilledDemographics() &&
        complaint.caseStatus === LF_FILED_STATUS
      ) {
        handleModifyDefendantInfo();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchSummonStatus]);
  

    const hideDefendantInfoModel = useCallback(
      () => 
      setState({
        showDefendantInfoModel: false,
      }),
      [setState]
    );

    const onHideInfoPopup = () => {
      setInfoPopupShow(false);
      navigate("/EComplaintDashboard");
    };

    const handleModifyDefendantInfo = () => {
      const {
        defFirstName,
        defLastName,
        gender,
        defRace,
        defDob,
        defHeight,
        defWeight,
        defEyes,
        defHair
      } = summonsDataObject;

      let defendantName = formatNameWithSuffix(defFirstName, "", defLastName, "");

      if (
      !defDob ||
      defDob.trim() === "" ||
      !defendantName ||
      defendantName.trim() === "" ||
      !gender ||
      gender.trim() === "" ||
      !defRace ||
      defRace.trim() === "" ||
      !defHair ||
      defHair.trim() === "" ||
      !defEyes ||
      defEyes.trim() === "" ||
      !defHeight ||
      isNaN(defHeight) ||
      defHeight.toString().trim() === "" ||
      parseFloat(defHeight) === 0 ||
      !defWeight ||
      isNaN(defWeight) ||
      defWeight.toString().trim() === "" ||
      parseFloat(defWeight) === 0
    ) {
      setState({ showDefendantInfoModel: true });
    } else {
      setState({ showDefendantInfoModel: false });
    };
  }


  const handleModifyInfo = useCallback(() => {
    if (state.modifyCaseInfo) {
      setState({
        goModifyCaseInfo: true,
      });
      let dataObj = {
        ecomplaintId: complaint.ecomplaintId,
        navigate: navigate,
      };
      dispatch(getEComplaintData(dataObj));
      dispatch(
        updateElementValueComplaintReducer({
          elementName: "returnToPage",
          value: "Summons",
        })
      );
      navigate("/eComplaintCaseInfoPage");
    } else {
      setState({ showDefendantInfoModel: true });
      // hideDefendantInfoModel();
    }
  }, [
    state.modifyCaseInfo,
    setState,
    dispatch,
    navigate,
    hideDefendantInfoModel,
  ]);



  useEffect(() => {
    if (
      (userType === ROLE_POLICE_OFFICER || userType === ROLE_AGENCY_ADMIN) &&
      complaint.caseStatus === LF_FILED_STATUS
    ) {
      if (
        state.ecFormStatus === LF_PENDING_STATUS ||
        state.ecFormStatus === LF_REJECTED_STATUS ||
        state.ecFormStatus === LF_RETURNED_STATUS ||
        state.ecFormStatus === LF_SEND_TO_JUDGE_STATUS ||
        state.ecFormStatus === LF_COMPLETE_STATUS
      ) {
        setState({
          modifyCaseInfo: false,
        });
      }
    } else {
      setState({
        modifyCaseInfo: true,
      });
    }
  }, [
    summonsDataObject,
    complaint.caseStatus,
    setState,
    state.ecFormStatus,
    userType,
  ]);


  const CustomComponents1 = useCallback(() => {
    return (
      <Row>
        <Col className="text-end">
          <ButtonComponent
            variant="info"
            disabled={state.disableModifyInfo}
            onClick={handleModifyInfo}
          >
            <RiEditLine /> Modify{" "}
            {state.modifyCaseInfo ? "Complaint" : "Defendant"} Information
          </ButtonComponent>{" "}
        </Col>
      </Row>
    );
  }, [handleModifyInfo, state.disableModifyInfo, state.modifyCaseInfo]);






    // ##################################################
  

  const formDataAppearanceOptions = [
    {
      type: "customComponents1",
      props: {},
      colSize: 12,
    },
    {
      type: "htmlTag",
      props: {
        value: (
          <>
            <span className="title-text">
              <p>Appearance Options</p>
            </span>
          </>
        ),
      },
      colSize: 12,
    },
    {
      type: "checkbox",
      props: {
        label: "Appear in person ",
        type: "radio",
        id: "isAppearInPerson",
        name: "isAppearInPerson",
        onChange: handleValChangeCheck,
        checked: summonsDataObject.isAppearInPerson === "Y" ? true : false,
        isError: errorKeys.includes("chkAppearanceOption"),
        autoComplete: "nope",
      },
      colSize: 12,
    },
    {
      type: "checkbox",
      props: {
        label: "Appear in person or remotely",
        type: "radio",
        id: "isAppearRemotely",
        name: "isAppearRemotely",
        onChange: handleValChangeCheck,
        checked: summonsDataObject.isAppearRemotely === "Y" ? true : false,
        isError: errorKeys.includes("chkAppearanceOption"),
        autoComplete: "nope",
      },
      colSize: 12,
    },
  ];

  const formImpound = [
    // {
    //     type: "htmlTag",
    //     props: {
    //         value: (
    //             <Row>
    //                 <Col className="text-center">
    //                     {
    //                         <ButtonComponent
    //                             onClick={saveSummonsDataAfterConfirm}
    //                             variant="success"
    //                         // className="mx-1"
    //                         >
    //                             Save
    //                         </ButtonComponent>
    //                     }
    //                 </Col>
    //                 <Col className="text-end">
    //                     {
    //                         <ButtonComponent
    //                             onClick={handlePreviewClick}
    //                             variant="info"
    //                             className="mx-1"
    //                         >
    //                             Preview
    //                         </ButtonComponent>
    //                     }

    //                     <ButtonComponent variant="danger"
    //                         onClick={handleCancelClick}
    //                     >
    //                         Cancel
    //                     </ButtonComponent>
    //                 </Col>
    //             </Row>
    //         ),
    //     },
    //     colSize: 12,
    // },
    {
      type: "htmlTag",
      props: {
        value: (
          <Row>
            <Col className="text-start">
              {state.showReturnToOfficerBtn && (
                <ButtonComponent onClick={handleReturnClick} variant="info">
                  Return to Officer
                </ButtonComponent>
              )}
              {userType === ROLE_JUDGE && (
                <ButtonComponent
                  onClick={handleRejectClick}
                  variant="danger"
                  className="mx-1"
                >
                  Reject
                </ButtonComponent>
              )}
            </Col>
            <Col className="text-center">
              {(userType === ROLE_POLICE_OFFICER ||
                userType === ROLE_AGENCY_ADMIN ||
                userType === ROLE_COUNTY_ADMIN ||
                userType === ROLE_STATES_ATTORNEY ||
                userType === ROLE_SA_ADMIN) &&
                complaint.caseStatus !== LF_VOID_STATUS &&
                state.ecFormStatus !== LF_SIGNED_STATUS &&
                state.ecFormStatus !== LF_DELETED_STATUS && 
                complaint.caseStatus !== LF_COMMUNI_FAILED_STATUS && (
                  <ButtonComponent onClick={handleSaveClick} variant="success">
                    {(userType === ROLE_POLICE_OFFICER ||
                      userType === ROLE_AGENCY_ADMIN) &&
                      complaint.caseStatus ===
                      LF_FILED_STATUS &&
                      (state.ecFormStatus === LF_PENDING_STATUS ||
                        state.ecFormStatus === LF_REJECTED_STATUS ||
                        state.ecFormStatus === LF_RETURNED_STATUS ||
                        state.ecFormStatus === LF_SEND_TO_JUDGE_STATUS ||
                        state.ecFormStatus === LF_COMPLETE_STATUS)
                      ? agencyList.length > 0 &&
                        agencyList[0].isComplaintSupervised &&
                        userType === ROLE_POLICE_OFFICER &&
                        complaint.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT
                        ? "Send to Supervisor"
                        : "Send to Judge"
                      : state.ecFormStatus === LF_COMMUNI_FAILED_STATUS && userType === ROLE_COUNTY_ADMIN ? "Re-submit Summon": "Save"}
                  </ButtonComponent>
                )}
              {userType === ROLE_JUDGE &&
                state.ecFormStatus === LF_SEND_TO_JUDGE_STATUS && (
                  <ButtonComponent
                    onClick={handleSignClick}
                    variant="success"
                    className="mx-1"
                  >
                    Sign
                  </ButtonComponent>
                )}
              {/* {loginReducer.canReSubmitEComplaint &&
                complaintMain.countyIntegratedWithCMS &&
                complaintMain.summonUploadFailed && (
                  <ButtonComponent
                    className="btn-success mt-1"
                    onClick={() => resubmitSummon()}
                  >
                    Re-submit Summon
                  </ButtonComponent>
                )} */}
            </Col>
            <Col className="text-end">
              {!(
                ((userType === ROLE_POLICE_OFFICER ||
                  userType === ROLE_AGENCY_ADMIN ||
                  userType === ROLE_COUNTY_ADMIN) &&
                  complaint.caseStatus === LF_VOID_STATUS) ||
                state.ecFormStatus === LF_DELETED_STATUS
              ) && (
                  <ButtonComponent
                    onClick={handlePreviewClick}
                    variant="info"
                    className="mx-1"
                  >
                    Preview
                  </ButtonComponent>
                )}

              <ButtonComponent variant="danger" onClick={handleCancelClick}>
                Cancel
              </ButtonComponent>
            </Col>
          </Row>
        ),
      },
      colSize: 12,
    },
  ];

  return (
    <>
      <Header headerName="Summons" />
      <div className="main-body p-3">
        <BreadcrumbComponent
          itemList={itemLinkList}
          itemActive="Summons"
        />
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <Card>
          <Card.Body>
            {/* <FormComponent
                            totalColumns={12}
                            colSize={6}
                            componentData={courtProceedingData}
                        /> */}
            <FormComponent
              totalColumns={12}
              colSize={6}
              componentData={formDataAppearanceOptions}
              components={[
                {
                  type: "customComponents1",
                  node: CustomComponents1,
                },
              ]}
            />
            <FormComponent
              totalColumns={12}
              colSize={6}
              componentData={formDataOffenses}
              components={[
                {
                  type: "customComponents2",
                  node: CustomComponents2,
                },
              ]}
            />
            <FormComponent
              totalColumns={12}
              colSize={6}
              componentData={formImpound}
              formCardStyle="p-0"
            />
          </Card.Body>
        </Card>
      </div>
      <ConfirmPopup
        children={state.confirmPopupMessage}
        show={state.showConfirmPopup}
        onHide={() =>
          setState({
            showConfirmPopup: false,
          })
        }
        isConfirmation={handleConfirmationClick}
      />
      <SignPopup
        show={state.showSignPopup}
        onHide={() => setState({ showSignPopup: false })}
        formType={LF_SUMMONS}
        hideFooter
      />
      <ModifyDefendantPopupSummon 
        show={state.showDefendantInfoModel}
        onHide={hideDefendantInfoModel}
        // reducerType = {useSummonsReducer}
        // objectName = {summonsDataObject}
      />
      <InfoPopup
        children={EC_MSG_ALREADY_SENT_TO_JUDGE_SUMMON}
        onHide={onHideInfoPopup}
        show={infoPopupShow}
        variant="warning"
      />
    </>
  );
}

export default Summons