import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LF_ARREST_WARRANT,
  LF_ARRW_REJECTED_STATUS,
  LF_ARRW_RETURNED_STATUS,
  LF_COMPLETE_STATUS,
  LF_FILED_STATUS,
  LF_REJECTED_STATUS,
  LF_RETURNED_STATUS,
  LF_SEND_TO_JUDGE_STATUS,
  LF_STATUS_PENDING_SUPERVISOR_RETURNED,
  LF_STATUS_PENDING_SUPERVISOR_REVIEW,
  ROLE_POLICE_OFFICER,
  LF_COMPLAINT,
  LF_COMPLAINT_PROCESSING,
  LF_SIGNED_STATUS,
  LF_CASE_ASSIGNMENT_READY_STATUS,
  ROLE_AGENCY_ADMIN,
  FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
  TYPE_OF_ARREST_ARRESTWARRANT,
} from "../../constants/common";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementObject } from "../../utils/updateElementObject";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";
import {
  getEcomplaintMainDetails,
  tackBack,
  undoCertification,
  updateElementObjectValueComplaintMain,
} from "./complaintMainSlice";
import { editComplaint, updateElementValuecomplaintDashReducer } from "./ecomplaintDashboardSlice";
import { getLFCConcurrentObj } from "./commonFormSlice";

interface defInfoObj {
  defFirstName: string,
  defMiddleName: string,
  defLastName: string,
  defSuffix: string,
  gender: string,
  defRace: string,
  defDob: string,
  defHeight: string,
  defWeight: string,
  defEyes: string,
  defHair: string,
  defDriversLicense: string,
  defDlState: string,
  dcn: string,
  defAddressUnknown: string,
  defForeignAddress: string,
  defAddress1: string,
  defAddress2: string,
  defCity: string,
  defState: string,
  defZip: string,
  defTelephone: string,
  defCountry: string
}

interface ArrestWarrantObj {
  formId: number;
  warrantControlNumber: string;
  selectedKountId: number;
  defaultCountyName: string;
  defaultCountyNameCheck: string;
  mileageLimitationsOption1: string;
  customGeoLimitText: string;
  allCountiesCheck: string;
  noLimitationCheck: string;
  mileageLimitationsOption2: string;
  midwesternStatesCheck: string;
  defaultStateName: string;
  illinoisCheck: string;
  otherCheck: string;
  otherCounty: string;
  bailFixedAtAmount: string;
  noBondCheck: string;
  toBeSetInBondCourt: string;
  bailAuthorizedCheck: string;
  fullBondRequiredCheck: string;
  personalRecognizanceAuthorizedCheck: string;
  otherText: string;
  originatingDeptMunicipalNumber: string;
  courtLocation: string;
  suggestedCourtDates: string;
  grantCheck: string;
  denyCheck: string;
  orderingJudge: string;
  judgeSignature: string;
  defFirstName: string;
  defMiddleName: string;
  defLastName: string;
  defSuffix: string;
  gender: string;
  defRace: string;
  defDob: string;
  defHeight: any;
  defWeight: any;
  defEyes: string;
  defHair: string;
  defDriversLicense: string;
  defDlState: string;
  dcn: string;
  defAddressUnknown: string;
  defForeignAddress: string;
  defAddress1: string;
  defAddress2: string;
  defCity: string;
  defState: string;
  defZip: string;
  defTelephone: string;
  defCountry: string;
  isImpoundWarrant: string;
  assignedCaseNumber: string;

  radioToBeHeld: string;
  radioReleaseOn: string;
  defendentInfo: {  
    defFirstName: string,
    defMiddleName: string,
    defLastName: string,
    defSuffix: string,
    gender: string,
    defRace: string,
    defDob: string,
    defHeight: string,
    defWeight: string,
    defEyes: string,
    defHair: string,
    defDriversLicense: string,
    defDlState: string,
    dcn: string,
    defAddressUnknown: string,
    defForeignAddress: string,
    defAddress1: string,
    defAddress2: string,
    defCity: string,
    defState: string,
    defZip: string,
    defTelephone: string,
    defCountry: string
  };
}

interface ArrestWarrantState {
  defInfoObject: defInfoObj;
  arrestWarrantObj: ArrestWarrantObj;
  status: LoadingType;
  initialDataStatus: LoadingType;
  fetchArrestWarrantStatus: LoadingType;
  saveStatus: LoadingType;
  awState: string;
  signStatus: LoadingType;
}

const initialArrestWarrantState: ArrestWarrantObj = {

  formId: 0,
  warrantControlNumber: "",
  selectedKountId: 0,
  defaultCountyName: "",
  defaultCountyNameCheck: "N",
  mileageLimitationsOption1: "",
  customGeoLimitText: "",
  allCountiesCheck: "N",
  noLimitationCheck: "N",
  mileageLimitationsOption2: "",
  midwesternStatesCheck: "N",
  defaultStateName: "",
  illinoisCheck: "N",
  otherCheck: "N",
  otherCounty: "",
  bailFixedAtAmount: "",
  noBondCheck: "",
  toBeSetInBondCourt: "",
  bailAuthorizedCheck: "",
  fullBondRequiredCheck: "",
  personalRecognizanceAuthorizedCheck: "",
  otherText: "",
  originatingDeptMunicipalNumber: "",
  courtLocation: "",
  suggestedCourtDates: "",
  grantCheck: "N",
  denyCheck: "N",
  orderingJudge: "",
  judgeSignature: "",
  defFirstName: "",
  defMiddleName: "",
  defLastName: "",
  defSuffix: "",
  gender: "",
  defRace: "",
  defDob: "",
  defHeight: "",
  defWeight: "",
  defEyes: "",
  defHair: "",
  defDriversLicense: "",
  defDlState: "",
  dcn: "",
  defAddressUnknown: "",
  defForeignAddress: "",
  defAddress1: "",
  defAddress2: "",
  defCity: "",
  defState: "",
  defZip: "",
  defTelephone: "",
  defCountry: "",
  isImpoundWarrant: "N",
  assignedCaseNumber: "",
  radioToBeHeld: "N",
  radioReleaseOn: "N",
  defendentInfo: {
    defFirstName: "",
    defMiddleName: "",
    defLastName: "",
    defSuffix: "",
    gender: "",
    defRace: "",
    defDob: "",
    defHeight: "",
    defWeight: "",
    defEyes: "",
    defHair: "",
    defDriversLicense: "",
    defDlState: "",
    dcn: "",
    defAddressUnknown: "",
    defForeignAddress: "",
    defAddress1: "",
    defAddress2: "",
    defCity: "",
    defState: "",
    defZip: "",
    defTelephone: "",
    defCountry: ""
  }
};

const initialDefInfoObjectState: defInfoObj = {
  defFirstName: "",
  defMiddleName: "",
  defLastName: "",
  defSuffix: "",
  gender: "",
  defRace: "",
  defDob: "",
  defHeight: "",
  defWeight: "",
  defEyes: "",
  defHair: "",
  defDriversLicense: "",
  defDlState: "",
  dcn: "",
  defAddressUnknown: "",
  defForeignAddress: "",
  defAddress1: "",
  defAddress2: "",
  defCity: "",
  defState: "",
  defZip: "",
  defTelephone: "",
  defCountry: ""
}

const initialState = {
  swApprovalUserTypes: [],
  warrantTemplateList: [],
  userTypeError: undefined,
  status: "idle",
  initialDataStatus: "idle",
  fetchArrestWarrantStatus : "idle",
  arrestWarrantObj: initialArrestWarrantState,
  defInfoObject: initialDefInfoObjectState,
  saveStatus: "idle",
  awState: "",
  signStatus: "idle",
} as ArrestWarrantState;

export const fetchArrestWarrant = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: string }
>(
  "arrestWarrant/fetchArrestWarrant",
  async (ecFormId, { rejectWithValue, getState, dispatch }) => {
    dispatch(showHideLoadingGif(true));
    try {
      let { token } = getState().login;
      const response = await doFetch({
        url: "ecomplaintservices/getArrestWarrant/" + ecFormId,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching arrest warrant"
      );
    }
  }
);

export const fetchInitialArrestWarrant = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: string }
>(
  "arrestWarrant/fetchInitialArrestWarrant",
  async (ecomplaintId, { rejectWithValue, getState, dispatch }) => {
    dispatch(showHideLoadingGif(true));
    try {
      let { token } = getState().login;
      const response = await doFetch({
        url: "ecomplaintservices/getInitialArrestWarrantData/" + ecomplaintId,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();

      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching arrest warrant"
      );
    }
  }
);

export const saveArrestWarrant = createAsyncThunk<
  any,
  {
    arrestWarrantObj: ArrestWarrantObj;
    ecomplaintId: number;
    status: string;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "arrestWarrant/saveArrestWarrant",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const { awState } = getState().arrestWarrant;
    dispatch(showHideLoadingGif(true));

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      let { token } = getState().login;
      const response = await doFetch({
        url:
          "ecomplaintservices/saveArrestWarrant/" +
          data.ecomplaintId +
          "/" +
          data.status,
        token,
        type: "POST",
        body: data.arrestWarrantObj,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        if (awState === "create" || awState === "editThrounghMainPage") {
          dispatch(getEcomplaintMainDetails({ecomplaintId: data.ecomplaintId, callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE}));
          data.navigate("/eComplaintMainPage");
        } else {
          data.navigate("/eComplaintDashBoard");
        }
        dispatch(resetArrestWarrantData());
        dispatch(showHideLoadingGif(false));
        const complaintMain = await response.json();
        dispatch(updateElementObjectValueComplaintMain(complaintMain));
        return;
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while saving arrest warrant"
      );
    }
  }
);

export const signArrestWarrant = createAsyncThunk<
  any,
  {
    arrestWarrantObj: ArrestWarrantObj;
    ecomplaintId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "arrestWarrant/signArrestWarrant",
  async (data, { rejectWithValue, getState, dispatch }) => {
    dispatch(showHideLoadingGif(true));
    
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      let { token } = getState().login;
      const response = await doFetch({
        url: "ecomplaintservices/signArrestWarrant/" + data.ecomplaintId,
        token,
        type: "POST",
        body: data.arrestWarrantObj,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        data.navigate("/eComplaintDashBoard");
        dispatch(showHideLoadingGif(false));
        const responseData = await response.text();
        if (responseData) {
          //alert(responseData);
          let message = "";
          if (responseData === LF_COMPLAINT_PROCESSING) {
            message = "The warrant has been submitted for processing.";
          } else if (responseData === LF_SIGNED_STATUS) {
            message = "The warrant has been signed.";
          } else if (responseData === LF_FILED_STATUS) {
            message = "The warrant has been signed.";
          } else if (responseData === LF_CASE_ASSIGNMENT_READY_STATUS) {
            message = "The warrant has been submitted for assigning case number.";
          }
          dispatch(
            updateElementValuecomplaintDashReducer({
              elementName: "successMessage",
              value: message,
            })
          );
        }
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while saving arrest warrant"
      );
    }
  }
);

export const rejectArrestWarrant = createAsyncThunk<
  any,
  {
    navigate: any;
    navigateFrom: string;
  },
  { state: RootState; rejectValue: string }
>(
  "arrestWarrant/rejectArrestWarrant",
  async ({ navigate }, { rejectWithValue, getState, dispatch }) => {
    let ecomplaintReducer = getState().complaintMain;
    let { ecIsCompalintSupervised, userType } = getState().login;

    const arrestWarrant = ecomplaintReducer.ecFormList.find(
      (item: any) => item.formTypeName === LF_ARREST_WARRANT
    );
    let statusObj = insertEcFormData(
      arrestWarrant.status,
      LF_REJECTED_STATUS,
      ecIsCompalintSupervised,
      userType,
      ecomplaintReducer
    );

    let container: any = {};
    container.className = "Ecomplaint";
    let propsValues: any = [];
    propsValues.push(["caseStatus", statusObj.caseStatus]);
    container.propsValues = propsValues;
    let dataObj = {
      ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
      ecStatus: statusObj.ecFormStatus,
      dto: container,
      identifier: "rejectArrestWarrant",
      navigate: navigate,
    };

    let undoObj: any = {
      ecomplaintId: ecomplaintReducer.complaint.ecomplaintId,
      navigate: navigate,
    };
    dispatch(tackBack(dataObj));
  }
);

export const returnToOfficerArrestWarrant = createAsyncThunk<
  any,
  {
    navigate: any;
    navigateFrom: string;
    formId : number
    formData? : any
  },
  { state: RootState; rejectValue: string }
>(
  "arrestWarrant/returnToOfficerArrestWarrant",
  async ({ navigate, navigateFrom, formId, formData }, { getState, dispatch }) => {
    let ecomplaintReducer = getState().complaintMain;
    let { ecIsCompalintSupervised, userType } = getState().login;

    const arrestWarrant = ecomplaintReducer.ecFormList.find(
      (item: any) => item.formTypeName === LF_ARREST_WARRANT
    );
    let statusObj;
    let data:any;
    if (arrestWarrant.status === LF_STATUS_PENDING_SUPERVISOR_REVIEW) {
      statusObj = insertEcFormData(
        arrestWarrant.status,
        LF_STATUS_PENDING_SUPERVISOR_RETURNED,
        ecIsCompalintSupervised,
        userType,
        ecomplaintReducer
      );
      data = {
        formId: arrestWarrant.formId,
        formType: arrestWarrant.formTypeName,
        ecomplaintId: arrestWarrant.ecomplaintId,
        status: statusObj.caseStatus,
        formData: formData?formData:{},
        navigate: navigate,
      };

    } else {
      statusObj = insertEcFormData(
        arrestWarrant.status,
        LF_RETURNED_STATUS,
        ecIsCompalintSupervised,
        userType,
        ecomplaintReducer
      );
      data = {
        formId: arrestWarrant.formId,
        formType: arrestWarrant.formTypeName,
        ecomplaintId: arrestWarrant.ecomplaintId,
        status: statusObj.ecFormStatus,
        formData: formData?formData:{},
        navigate: navigate,
      };

    }
    dispatch(editComplaint(data));
  }
);

export const resubmitArrestWarrantImage = createAsyncThunk<
  any,
  {
    ecCaseDetail: any;
    ecomplaintId: number;
    fromScheduler: boolean;
    navigate: any
  },
  { state: RootState; rejectValue: string } 
>("arrestWarrant/resubmitArrestWarrantImage",
    async ({ ecCaseDetail, ecomplaintId ,  fromScheduler , navigate  }, { rejectWithValue, getState, dispatch }) => {
    
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    
    dispatch(showHideLoadingGif(true));
    try {
      let { token } = getState().login;
      const response = await doFetch({
        url:`ecomplaintservices/resubmitArrestWarrantImage/${ecomplaintId}/${fromScheduler}`,
        token,
        type: "POST",
        body: ecCaseDetail,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        response.json().then((result: any) => {
          navigate("/EComplaintDashboard");
          return result;
        });
        navigate(("/EComplaintDashboard"))

      } else {

      }
    } catch (error: any) {

    }
  }

)

const insertEcFormData = (
  ecStatus: string,
  status: string,
  ecIsCompalintSupervised: boolean,
  userType: string,
  ecomplaintReducer: any
) => {
  let ecFormStatus;
  if (
    LF_COMPLETE_STATUS === status &&
    (LF_REJECTED_STATUS === ecStatus ||
      LF_RETURNED_STATUS === ecStatus ||
      LF_STATUS_PENDING_SUPERVISOR_REVIEW === ecStatus)
  ) {
    if (ecIsCompalintSupervised && userType === ROLE_POLICE_OFFICER) {
      ecFormStatus = LF_STATUS_PENDING_SUPERVISOR_REVIEW;
    } else {
      ecFormStatus = LF_SEND_TO_JUDGE_STATUS;
    }
  } else if (LF_STATUS_PENDING_SUPERVISOR_RETURNED === status) {
    ecFormStatus = LF_RETURNED_STATUS;
  } else {
    ecFormStatus = status;
  }

  if ((ROLE_POLICE_OFFICER === userType || ROLE_AGENCY_ADMIN === userType) &&
    LF_FILED_STATUS === ecomplaintReducer.complaint.caseStatus
  ) {
    if (
      ecIsCompalintSupervised &&
      userType === ROLE_POLICE_OFFICER &&
      ecomplaintReducer.complaint.typeOfArrest === TYPE_OF_ARREST_ARRESTWARRANT
    ) {
      if (ecStatus === LF_COMPLETE_STATUS || ecStatus === LF_RETURNED_STATUS) {
        ecFormStatus = LF_STATUS_PENDING_SUPERVISOR_REVIEW;
      }
    } else if (LF_STATUS_PENDING_SUPERVISOR_RETURNED !== status)
      ecFormStatus = LF_SEND_TO_JUDGE_STATUS;
  }

  let caseStatus;

  if (
    LF_REJECTED_STATUS === status &&
    LF_FILED_STATUS !== ecomplaintReducer.complaint.caseStatus
  ) {
    caseStatus = LF_ARRW_REJECTED_STATUS;
  } else if (
    LF_RETURNED_STATUS === status &&
    LF_ARRW_RETURNED_STATUS !== ecomplaintReducer.complaint.caseStatus &&
    LF_FILED_STATUS !== ecomplaintReducer.complaint.caseStatus
  ) {
    caseStatus = LF_ARRW_RETURNED_STATUS;
  } else if (
    (LF_STATUS_PENDING_SUPERVISOR_RETURNED === status ||
      LF_STATUS_PENDING_SUPERVISOR_REVIEW === status) &&
    LF_FILED_STATUS !== ecomplaintReducer.complaint.caseStatus
  ) {
    caseStatus = status;
  } else if (LF_COMPLETE_STATUS === status) {
    if (
      ecFormStatus === LF_STATUS_PENDING_SUPERVISOR_REVIEW &&
      LF_FILED_STATUS !== ecomplaintReducer.complaint.caseStatus
    ) {
      caseStatus = LF_STATUS_PENDING_SUPERVISOR_REVIEW;
    } else if (
      LF_ARRW_REJECTED_STATUS === ecomplaintReducer.complaint.caseStatus ||
      (ecFormStatus === LF_SEND_TO_JUDGE_STATUS &&
        LF_FILED_STATUS !== ecomplaintReducer.complaint.caseStatus)
    ) {
      caseStatus = LF_SEND_TO_JUDGE_STATUS;
    }
  }

  const statusObj = {
    caseStatus: caseStatus,
    ecFormStatus: ecFormStatus,
  };

  return statusObj;
};

const arrestWarrantSlice = createSlice({
  name: "arrestWarrant",
  initialState,
  reducers: {
    updateElementValueArrestWarrantReducer: updateElementValue,
    updateElementObjectValueArrestWarrantReducer: (
      state: ArrestWarrantState,
      action: PayloadAction<{ elementName: string; value: any }>
    ) => {
      let elementObj = state.arrestWarrantObj;
      updateElementObject(state, action, elementObj);
    },
    updateArrestWarrantObject: (state: ArrestWarrantState, action) => {
      state.arrestWarrantObj = action.payload;
    },
    resetArrestWarrantData: (state: ArrestWarrantState) => {
      state.arrestWarrantObj = initialArrestWarrantState;
    },
    updateSignElementBlackOrder: (state: ArrestWarrantState, action) => {
      let elementObj = state.arrestWarrantObj;
      updateElementObject(state, action, elementObj);
    },
    updateElementObjectValueDefInfoObject: (state: ArrestWarrantState, action) => {
      updateElementObject(state, action, state.defInfoObject);
    },
    setECFormData: (
      state: ArrestWarrantState,
      action: PayloadAction<{ status: string; formId: number }>
    ) => {
      return {
        ...state,
        arrestWarrantObj: {
          ...state.arrestWarrantObj,
          ecFormStatus: action.payload.status,
          formId: action.payload.formId,
        },
      };
    },
    updateAwState: (
      state: ArrestWarrantState,
      action: PayloadAction<string>
    ) => {
      state.awState = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchArrestWarrant.pending, (state) => {
        state.fetchArrestWarrantStatus = "loading";
      })
      .addCase(fetchArrestWarrant.fulfilled, (state, action) => {
        state.arrestWarrantObj = action.payload;
        state.fetchArrestWarrantStatus = "success";
      })
      .addCase(fetchArrestWarrant.rejected, (state, action) => {
        state.fetchArrestWarrantStatus = "error";
      });

    builder
      .addCase(fetchInitialArrestWarrant.pending, (state) => {
        state.fetchArrestWarrantStatus = "loading";
      })
      .addCase(fetchInitialArrestWarrant.fulfilled, (state, action) => {
        state.arrestWarrantObj = action.payload;
        state.fetchArrestWarrantStatus = "success";
      })
      .addCase(fetchInitialArrestWarrant.rejected, (state, action) => {
        state.fetchArrestWarrantStatus = "error";
      });

    builder
      .addCase(saveArrestWarrant.pending, (state) => {
        state.saveStatus = "loading";
      })
      .addCase(saveArrestWarrant.fulfilled, (state, action) => {
        state.saveStatus = "success";
      })
      .addCase(saveArrestWarrant.rejected, (state, action) => {
        state.saveStatus = "error";
      });

    builder
      .addCase(signArrestWarrant.pending, (state) => {
        state.signStatus = "loading";
      })
      .addCase(signArrestWarrant.fulfilled, (state, action) => {
        state.signStatus = "success";
      })
      .addCase(signArrestWarrant.rejected, (state, action) => {
        state.signStatus = "error";
      });
  },
});
export const {
  updateElementValueArrestWarrantReducer,
  resetArrestWarrantData,
  updateElementObjectValueArrestWarrantReducer,
  updateElementObjectValueDefInfoObject,
  updateArrestWarrantObject,
  updateSignElementBlackOrder,
  setECFormData,
  updateAwState,
} = arrestWarrantSlice.actions;
export const useArrestWarrantReducer = () =>
  useAppSelector((state) => state.arrestWarrant);
export default arrestWarrantSlice.reducer;
