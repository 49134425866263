import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { useAppSelector } from "../../hooks/hooks";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { updateElementObject } from "../../utils/updateElementObject";
import { showHideLoadingGif } from "./commonSlice";
import { getSWCaseDetail } from "./caseDetailSlice";

interface SecurityPasswordObject{
    password: string,
    passwordMatchingStatus: boolean,
    removePassword: boolean,
    resetPassword: boolean,
    protection: boolean,
}

interface SecurityPasswordState{
    securityPasswordObject: SecurityPasswordObject;
    fetchStatus: LoadingType;
}

const initialState = {
    fetchStatus: "idle",
    securityPasswordObject:{
        password: "",
        passwordMatchingStatus: false,
        removePassword: false,
        resetPassword: false,
        protection: false,
    }
} as SecurityPasswordState


export const resetOrRemovePasswordToForm = createAsyncThunk<
  any,
  {
    searchWarrantid: number;
    password: string;
    navigate : any;
  },
  { state: RootState; rejectValue: string }
>(
  "securityPassword/resetOrReplacePasswordToForm",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantid, password, navigate} = data;
    let { token } = getState().login;

    try {
      let proxyObj :any = {};
      proxyObj.password = password;
      dispatch(showHideLoadingGif(true));
      const response: any = await doFetch({
        url:
          "searchwarrantservices/resetOrReplacePasswordToForm/" +
          searchWarrantid,
        token,
        type: "POST",
        body: proxyObj,
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        dispatch(
          getSWCaseDetail({
            searchWarrantId: searchWarrantid,
            action: "getDetails",
            navigate: navigate,
          })
        );
        return response.text();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while set the password."
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while set the password."
      );
    }
  }
);

export const checkSecurityPassword = createAsyncThunk<
  any,
  {
    searchWarrantid: number;
    password: string;
  },
  { state: RootState; rejectValue: string }
>(
  "securityPassword/checkSecurityPassword",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { searchWarrantid, password} = data;
    let { token } = getState().login;

    try {
      let proxyObj :any = {};
      proxyObj.password = password;
      dispatch(showHideLoadingGif(true));
      const response: any = await doFetch({
        url:
          "searchwarrantservices/checkSecurityPassword/" +
          searchWarrantid,
        token,
        type: "POST",
        body: proxyObj,
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.text();
        return rejectWithValue(
          error || "Error while set the password."
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while set the password."
      );
    }
  }
);

const securityPasswordSlice = createSlice({
    name: "securityPasswordReducer",
    initialState,
    reducers: {
      updateElementValueSecurityPasswordReducer: updateElementValue,
      updateElementSecurityPasswordObjectValue: (state: SecurityPasswordState, action) => {
        updateElementObject(state, action, state.securityPasswordObject);
      },
      resetSecurityPasswordReducer: (state: SecurityPasswordState) => {
        return {
          ...state,
          ...initialState,
        };
      },
    },
    extraReducers(builder) {
      builder
      .addCase(resetOrRemovePasswordToForm.pending, (state) => {
        state.fetchStatus = "loading";
      })
      .addCase(resetOrRemovePasswordToForm.fulfilled, (state, action) => {
          state.fetchStatus =  "success";
      })
      .addCase(resetOrRemovePasswordToForm.rejected, (state, action) => {
        state.fetchStatus = "error";
      })
      .addCase(checkSecurityPassword.pending, (state) => {
        state.fetchStatus = "loading";
      })
      .addCase(checkSecurityPassword.fulfilled, (state, action) => {
          state.fetchStatus =  "success";
          state.securityPasswordObject.passwordMatchingStatus = action.payload;
      })
      .addCase(checkSecurityPassword.rejected, (state, action) => {
        state.fetchStatus = "error";
      });
    },
  });

  export const {
    updateElementValueSecurityPasswordReducer,
    updateElementSecurityPasswordObjectValue,
    resetSecurityPasswordReducer,
  } = securityPasswordSlice.actions;
  export const useSecurityPasswordReducer = () =>
    useAppSelector((state) => state.securityPassword);
  export default securityPasswordSlice.reducer;
  