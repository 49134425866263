import React, { useCallback } from "react";
import { Alert as DefaultAlert } from "react-bootstrap";
import { BsExclamationCircleFill } from "react-icons/bs";
type AlertProps = {
  messages: string[];
  clearError: () => void;
  isExclamationMark?: boolean;
} & React.ComponentProps<typeof DefaultAlert>;
export default function Alert({
  messages,
  clearError,
  variant = "danger",
  isExclamationMark = true,
  ...props
}: AlertProps) {
  return (
    <DefaultAlert
      show={messages.length > 0}
      variant={variant}
      onClose={clearError}
      dismissible
      {...props}
    >
      {messages.map((str, i) => (
        <p key={i}>
          {isExclamationMark && <BsExclamationCircleFill className="mx-3" />}
          {str}
        </p>
      ))}
    </DefaultAlert>
  );
}

export const useAlert = () => {
  const [messages, setMessages] = React.useState<string[]>([]);
  const handleError = useCallback(
    (message: string[]) => {
      setMessages([...message]);
    },[]
  );
  const clearError = useCallback(() => {
    setMessages([]);
  },[]);
  return {
    handleError,
    clearError,
    messages,
  };
};
