import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { updateElementValue } from "../../utils/updateElementValue";
import { doFetch } from "../../utils/doFetch";
import { RootState } from "../store";
import { showHideLoadingGif } from "./commonSlice";

interface userTypeState {
  userTypeList: string[];
  userTypeError?: string;
  status: LoadingType;
}

const initialState = {
  userTypeList: [],
  userTypeError:undefined,
  status: "idle",
} as userTypeState;

export const listUserTypes = createAsyncThunk<
  any,
  {},
  {
    state: RootState;
  }
>("users/listUserTypes", async (data,{ getState, rejectWithValue, dispatch }) => {

  let { token } = getState().login;

  let queryParam = `jwtToken=Bearer ${token}`;

  dispatch(showHideLoadingGif(true));
  try {
    const reponse = await doFetch({
      url: `userTypeServiceImpls/usertypelist?${queryParam}`,
    });
    dispatch(showHideLoadingGif(false));
    return await reponse.json();
  } catch (error) {
    dispatch(showHideLoadingGif(false));
    return rejectWithValue(error);
  }
});

const userTypeSlice = createSlice({
  name: "userTypes",
  initialState,
  reducers: {
    updateElementValueUserTypeReducer: updateElementValue,
  },
  extraReducers(builder) {
    builder
      .addCase(listUserTypes.pending, (state) => {
          state.status = "loading";
          state.userTypeError = undefined;
      })
      .addCase(listUserTypes.fulfilled, (state, action) => {
          state.status = "success";
          state.userTypeList = action.payload;
      })
      .addCase(listUserTypes.rejected, (state, action) => {
          state.status = "error";
          state.userTypeError = undefined;
      });
  },
});

export const { updateElementValueUserTypeReducer } = userTypeSlice.actions;

export const useUserTypeReducer = () =>
  useAppSelector((state) => state.userType);

export default userTypeSlice.reducer;
