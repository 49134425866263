import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import useModel from "../../hooks/useModel";
import { getLoggedInUserCounties } from "../../redux/slice/countyListSlice";
import { useLoginReducer } from "../../redux/slice/loginSlice";
import {
  getTestVideoConferenceURL,
  updateElementValueVideoConferenceReducer,
} from "../../redux/slice/videoConferenceSlice";
import VideoConferenceSplitScreenPopUp from "../warrantlist/form/VideoConferenceSplitScreenPopUp";
import VideoTestSelectionPopup from "../warrantlist/form/VideoTestSelectionPopup";

export default function TestWebCam() {
  const { userType, countyId } = useLoginReducer();
  const countySelectPopUp = useModel(!countyId);
  const videoConferencePopup = useModel();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onHide = () => {
    navigate("/");
    dispatch(
      updateElementValueVideoConferenceReducer({
        elementName: "videoConferenceStatus",
        value: "idle",
      })
    );
  };
  const handleCountySelectPopUpConfirm = useCallback((countId: number) => {
    videoConferencePopup.open();
    dispatch(getTestVideoConferenceURL(countId));
  }, []);

  useEffect(() => {
    if (countyId) {
      handleCountySelectPopUpConfirm(countyId);
    } else {
      dispatch(getLoggedInUserCounties());
    }
  }, [dispatch, userType, countyId, handleCountySelectPopUpConfirm]);

  return (
    <>
      <VideoTestSelectionPopup
        show={countySelectPopUp.isOpen}
        onHide={onHide}
        handleContinue={handleCountySelectPopUpConfirm}
      />
      <VideoConferenceSplitScreenPopUp
        show={videoConferencePopup.isOpen}
        onHide={onHide}
        isTestWebCam={true}
      />
    </>
  );
}
