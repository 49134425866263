import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementValue } from "../../utils/updateElementValue";
import { updateElementObject } from "../../utils/updateElementObject";
import { RootState } from "../store";
import { showHideLoadingGif, updatePdfGenerationError } from "./commonSlice";
import {
  resetComplaintData,
  getEComplaintData,
  updateElementValueComplaintReducer,
} from "./complaintSlice";
import {
  EC_MSG_SENTTOOFFICE,
  EC_MSG_SENTTOSA,
  FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
  LF_ARREST_WARRANT,
  LF_SEND_TO_OFFICER_STATUS,
  LF_SEND_TO_SA_STATUS,
  PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE,
  REVISED_PROBABLE_CAUSE_STATEMENT,
} from "../../constants/common";
import { updateElementValuecomplaintDashReducer } from "./ecomplaintDashboardSlice";
import { getAllEcCounties } from "../../services/EComplaintService";
import { updateprobableCauseObject } from "./probableCauseStatementSlice";
import moment from "moment";
import { getLFCConcurrentObj } from "./commonFormSlice";
import { updateElementValuePpretiralData, updateElementValuePreTrialReleaseRecognizanceReducer, updatePreTrialReleaseRecognizanceObject } from "./preTrialReleaseRecognizanceSlice";

interface complaintMainObj {
  ecomplaintId: number;
  complaintNumber: string;
  caseNumber: string;
  caseStatus: string;
  primaryCaseNumber: string;
  impoundWarrant: boolean;
  typeOfArrest: string;
  onViewArrestOptionClickOnce: boolean;
  countyName: string;
  firstIncidentDateTime: string;
  primaryAgencyRepNumber: string;
  defFirstName: string;
  defMiddleName: string;
  defLastName: string;
  defSuffix: string;
  defDob: string;
  defRace: string;
  defHairColor: string;
  defEyeColor: string;
  defHeight: string;
  defWeight: string;
  defAddressLine1: string;
  defAddressLine2: string;
  defCity: string;
  defState: string;
  defZip: string;
  defCountry: string;
  defEmail: string;
  defAddressUnknown: boolean;
  defForeignAddress: boolean;
  defPhoneNumber1: string;
  defPhoneNumber2: string;
  defGender: string;
  defDriverlicense: string;
  defDlState: string;
  dcn: string;
  defSID: string;
  defFBI: string;
  nextCourtDate: string;
  nextCourtTime: string;
  nextCourtLocation: string;
  totalBond: string;
  agencyDisplayName: string;
  officerDisplayingName: string;
  caseProsecutionType: string;
  municipalityAgencyDisplayName: string;
  officerAgencyName: string;
  officerAgencyOri: string;
  officerAgencyCode: string;
  officerFirstName: string;
  officerLastName: string;
  officerBadge: string;
  municipalityAgencyName: string;
  municipalityAgencyCode: string;
  certificationIndicator: boolean;
  caseSaApproved: string;
  saViewed: boolean;
  notes: string;
  ecOfficerUserProfilePhone: string;
  ecOfficerUserProfileEmail: string;
  auditTimeStamp: string;
  defendantReleased: boolean;
  postSafeTAct: boolean;
  commFailed: boolean;
}

interface ComplaintMainReducer {
  complaint: complaintMainObj;

  countList: any;
  ecFormList: any;
  // EcKountList: any;
  createFormList: any;
  updateEcomplainCountList: any;
  ecCountyList: any;

  multiCaseNumberCounty: boolean;
  felonyScreeningRequired: boolean;
  customGeoLimits: string;
  geoLimitDefault: any;
  countyIntegratedWithCMS: boolean;
  summonsToAppear: boolean;
  videoConferenceType: string;

  complaintSupervised: boolean;
  notarizationReq: boolean;
  officerEmail: string;
  officerPhone: string;
  witnessList: any;

  getComplaintMainStatus: LoadingType;
  getComplaintError?: string;
  complaintStatus: LoadingType;
  complaintError?: string;
  successMessage?: string;
  assignedFelonyCaseNumber?: string;
  statechangeMsg?: string;
  localNote: string;
  reqCmsCaseNumberError: string;

  //KHL PR 1256 25/01/24 - START
  lfcCountyList: any[];
  loggedInUserCountyForLfcFetchStatus: LoadingType
  loggedInUserCountyForLfcFetchError?: string
  //KHL PR 1256 25/01/24 - END

  // concurrentObj: any;

  receivedCaseNumber: string;
  caseCreatedByAgencyCode: string;
  approvedSAInitial: string;
  documentUploadFailed: boolean;
  arrestWarrantUploadFailed: boolean;
  summonUploadFailed: boolean;
  preTrialReleaseUploadFailed: boolean;
  probableCauseUploadFailed: boolean;
  ecOfficerUserProfileEmail: string;
  ecOfficerUserProfilePhone: string;
  receiptEnable: boolean; //KHL PR 11264 09/01/24
}

const initialState = {
  complaint: {
    ecomplaintId: 0,
    complaintNumber: "",
    caseNumber: "",
    caseStatus: "",
    primaryCaseNumber: "",
    impoundWarrant: false,
    typeOfArrest: "",
    onViewArrestOptionClickOnce: false,
    countyName: "",
    firstIncidentDateTime: "",
    primaryAgencyRepNumber: "",
    defFirstName: "",
    defMiddleName: "",
    defLastName: "",
    defSuffix: "",
    defDob: "",
    defRace: "",
    defHairColor: "",
    defEyeColor: "",
    defHeight: "",
    defWeight: "",
    defAddressLine1: "",
    defAddressLine2: "",
    defCity: "",
    defState: "",
    defZip: "",
    defCountry: "UNITED STATES",
    defEmail: "",
    defAddressUnknown: false,
    defForeignAddress: false,
    defPhoneNumber1: "",
    defPhoneNumber2: "",
    defGender: "",
    defDriverlicense: "",
    defDlState: "",
    dcn: "",
    defSID: "",
    defFBI: "",
    nextCourtDate: "",
    nextCourtTime: "",
    nextCourtLocation: "",
    totalBond: "",
    agencyDisplayName: "",
    officerDisplayingName: "",
    caseProsecutionType: "",
    municipalityAgencyDisplayName: "",
    officerAgencyName: "",
    officerAgencyOri: "",
    officerAgencyCode: "",
    officerFirstName: "",
    officerLastName: "",
    officerBadge: "",
    municipalityAgencyName: "",
    municipalityAgencyCode: "",
    certificationIndicator: false,
    caseSaApproved: "",
    saViewed: false,
    notes: "",
    ecOfficerUserProfilePhone: "",
    ecOfficerUserProfileEmail: "",
    auditTimeStamp: "",

    defendantReleased: false,
    postSafeTAct: false,
    commFailed: false,
  },

  countList: [],
  ecFormList: [],
  createFormList: [],
  // EcKountList: [],
  ecCountyList: [],
  updateEcomplainCountList: [],
  multiCaseNumberCounty: false,
  felonyScreeningRequired: false,
  customGeoLimits: "",
  geoLimitDefault: [],
  countyIntegratedWithCMS: false,
  summonsToAppear: false,
  videoConferenceType: "",

  complaintSupervised: false,
  notarizationReq: false,
  officerEmail: "",
  officerPhone: "",
  witnessList: [],
  getComplaintMainStatus: "idle",
  getComplaintError: undefined,
  complaintStatus: "idle",
  complaintError: undefined,
  successMessage: "",
  statechangeMsg: "",
  reqCmsCaseNumberError: "",
  localNote: "",

  //KHL PR 1256 25/01/24 - START
  lfcCountyList: [],
  loggedInUserCountyForLfcFetchStatus: "idle",
  loggedInUserCountyForLfcFetchError: undefined,
  //KHL PR 1256 25/01/24 - END

  //concurrentObj: {},

  receivedCaseNumber: "",
  caseCreatedByAgencyCode: "",
  approvedSAInitial: "",
  documentUploadFailed: false,
  arrestWarrantUploadFailed: false,
  summonUploadFailed: false,
  preTrialReleaseUploadFailed: false,
  probableCauseUploadFailed: false,
  ecOfficerUserProfileEmail: "",
  ecOfficerUserProfilePhone: "",
  receiptEnable: false, //KHL PR 11264 09/01/24
} as ComplaintMainReducer;

export const getEcomplaintMainDetails = createAsyncThunk<
  any,
  { ecomplaintId: number; callingFrom: any },
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/getEcomplaintMainDetails",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId, callingFrom } = data;

    let queryParam = "";
    queryParam += `callingFrom=${callingFrom}`;
    dispatch(showHideLoadingGif(true));
    try {
      let { token } = getState().login;

      const reponse = await doFetch({
        url: `ecomplaintservices/getEcomplaintMainDetails/${ecomplaintId}?${queryParam}`,
        token,
      });
      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching E Complaint Data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching E Complaint Data"
      );
    }
  }
);

export const saveComplaint = createAsyncThunk<
  any,
  { ecomplaintId: number; dto: any; identifier: string },
  { state: RootState; rejectValue: string }
>(
  "complaint/saveComplaint",
  async (
    { ecomplaintId, dto, identifier },
    { getState, rejectWithValue, dispatch }
  ) => {
    let msg = "";
    if (identifier === "SaveNote") {
      msg = "Error while saving note";
    } else if (identifier === "SavePrimaryCaseNumber") {
      msg = "Error while saving Primary Case Number";
    }
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      const response = await doFetch({
        url: `ecomplaintservices/saveComplaint/${ecomplaintId}`,
        token,
        body: dto,
        type: "PUT",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const complaint = await response.json();
        let resp: any = {};
        resp.complaint = complaint;
        resp.identifier = identifier;
        return resp;
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(error || msg);
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || msg);
    }
  }
);

export const insertEcomplaintData = createAsyncThunk<
  any,
  { _complaint: any; navigate: any; navigateTo: string },
  { state: RootState; rejectValue: string }
>(
  "complaint/insertEcomplaintData",
  async (
    { _complaint, navigate, navigateTo },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    let error = null;
    let resp: string = "";
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      const response = await doFetch({
        url: "ecomplaintservices/saveEcomplaint",
        token,
        body: _complaint,
        type: "PUT",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const complaindto = await response.json();
        if (
          complaindto.complaint !== null &&
          complaindto.complaint.caseStatus === LF_SEND_TO_SA_STATUS
        ) {
          resp = EC_MSG_SENTTOSA;
        } else if (
          complaindto.complaint !== null &&
          complaindto.complaint.caseStatus === LF_SEND_TO_OFFICER_STATUS
        ) {
          resp = EC_MSG_SENTTOOFFICE;
        }
        dispatch(
          getEcomplaintMainDetails({
            ecomplaintId: _complaint.ecomplaintId,
            callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
          })
        );

        navigate(navigateTo);
        return complaindto;
      } else {
        dispatch(showHideLoadingGif(false));
        error = await response.json();
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
    }
  }
);
//start here by adding insertEcomplaintData
export const addModifyEcCountsEcomplaint = createAsyncThunk<
  any,
  { complaint: complaintMainObj; addModifyEcomplaint: boolean; countList: [] },
  { state: RootState; rejectValue: string }
>(
  "complaint/addModifyEcCountsEcomplaint",
  async (
    { complaint, addModifyEcomplaint, countList },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    let ecomplaintId = complaint.ecomplaintId;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url: `ecomplaintservices/addModifyEcCountsEcomplaint/${ecomplaintId}`,
        token,
        body: countList,
        type: "PUT",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
    }
  }
);

export const reGenerateComplaint = createAsyncThunk<
  any,
  {
    _ecCaseDetail: any;
    navigate: any
  },
  { state: RootState; rejectValue: string }
>(
  "complaint/reGenerateComplaint",
  async ({ _ecCaseDetail, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url: `ecomplaintservices/reGenerateComplaint`,
        token,
        type: "POST",
        body: _ecCaseDetail,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        response.json().then((result: any) => {
          dispatch(setComplaintMainData(result));
          navigate("/EComplaintDashboard");
          return result;
        });
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(
          error || "Error while regenerating ecomplaint documents"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while regenerating ecomplaint documents");
    }
  }


);

export const resubmitComplaint = createAsyncThunk<
  any,
  {
    _ecCaseDetail: any;
    fromScheduler: boolean;
    navigate: any
  },
  { state: RootState; rejectValue: string }
>(
  "complaint/reSubmitComplaint",
  async ({ _ecCaseDetail, fromScheduler, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url: `ecomplaintservices/reSubmitComplaint/${fromScheduler}`,
        token,
        type: "POST",
        body: _ecCaseDetail,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        response.json().then((result: any) => {
          dispatch(setComplaintMainData(result));
          navigate("/EComplaintDashboard");
          return result;
        });
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(
          error || "Error while submit for review with Supervisor"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while submit for review with Supervisor");
    }
  }


);

export const resubmitImageToCms = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    fromScheduler: boolean;
    navigate: any
  },
  { state: RootState; rejectValue: string }
>(
  "complaint/resubmitImageToCms",
  async ({ ecomplaintId, fromScheduler, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url: `ecomplaintservices/reSubmitImageToCMS/${ecomplaintId}/${fromScheduler}`,
        token,
        type: "POST",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        navigate("/EComplaintDashboard");
        return response;
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(
          error || "Error while submit for review with Supervisor"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while submit for review with Supervisor");
    }
  }


);

export const tackBack = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    ecStatus: string;
    dto: any;
    identifier: string;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "complaint/takeBack",
  async (
    { ecomplaintId, ecStatus, dto, identifier, navigate },
    { getState, rejectWithValue, dispatch }
  ) => {
    let msg = "";
    if (identifier === "takeBack") {
      msg = "Error while take back";
    } else if (identifier === "returnToOfficer") {
      msg = "Error while return to officer";
    } else if (identifier === "rejectArrestWarrant") {
      msg = "Error while rejecting arrest warrant";
    }
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      const response = await doFetch({
        url: `ecomplaintservices/takeBack/${ecomplaintId}/${ecStatus}`,
        token,
        body: dto,
        type: "PUT",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const complaint = await response.json();
        let resp: any = {};
        resp.complaint = complaint;
        resp.identifier = identifier;
        navigate("/EComplaintDashboard");
        return resp;
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(error || msg);
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(error.message || msg);
    }
  }
);

export const getWitnesses = createAsyncThunk<
  any,
  {
    ecomplientId: number;
  },
  { state: RootState; rejectValues: string }
>(
  "complaint/getComplientWitnees/",
  async (data, { getState, rejectWithValue }) => {
    const { ecomplientId } = data;
    let { token } = getState().login;
    try {
      const response: any = await doFetch({
        url: "ecomplaintservices/getWitness/" + ecomplientId,
        token,
        type: "GET",
      });
      if (response.ok) {
        const witnessList = await response.json();
        return witnessList;
      }
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Error in loading ecomplient witness"
      );
    }
  }
);

export const forceCreateArrestWarrant = createAsyncThunk<
  any,
  { ecomplaintId: number; modifyComplaint: boolean; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "complaint/forceCreateArrestWarrant",
  async (
    { ecomplaintId, modifyComplaint, navigate },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url: `ecomplaintservices/forceCreateArrestWarrant/${ecomplaintId}`,
        token,
        type: "PUT",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const userRes = await response.json();
        dispatch(setComplaintMainData(userRes));
        if (modifyComplaint) {
          dispatch(resetComplaintData());
          let dataObj = {
            ecomplaintId: ecomplaintId,
            navigate: navigate,
          };
          dispatch(getEComplaintData(dataObj));
          dispatch(
            updateElementValueComplaintReducer({
              elementName: "returnToPage",
              value: "ComplaintMainPage",
            })
          );
        } else {
          navigate("/arrestWarrant");
        }
        return {
          message: "Success",
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(error || "Error while saving complaint");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while saving complaint");
    }
  }
);

export const forceCreateSummon = createAsyncThunk<
  any,
  { ecomplaintId: number; modifyComplaint: boolean; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "complaint/forceCreateSummon",
  async (
    { ecomplaintId, modifyComplaint, navigate },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url: `ecomplaintservices/forceCreateSummons/${ecomplaintId}`,
        token,
        type: "PUT",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const userRes = await response.json();
        dispatch(setComplaintMainData(userRes));
        if (modifyComplaint) {
          dispatch(resetComplaintData());
          let dataObj = {
            ecomplaintId: ecomplaintId,
            navigate: navigate,
          };
          dispatch(getEComplaintData(dataObj));
          dispatch(
            updateElementValueComplaintReducer({
              elementName: "returnToPage",
              value: "ComplaintMainPage",
            })
          );
        } else {
          navigate("/issueSummons");
        }
        return {
          message: "Success",
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(error || "Error while saving complaint");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while saving complaint");
    }
  }
);

export const undoCertification = createAsyncThunk<
  any,
  { ecomplaintId: number; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "complaint/undoCertificationMain",
  async (
    { ecomplaintId, navigate },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      const response = await doFetch({
        url: `ecomplaintservices/undoCertification/${ecomplaintId}`,
        token,
        type: "PUT",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const userRes = await response.json();
        dispatch(
          getEcomplaintMainDetails({
            ecomplaintId: ecomplaintId,
            callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE,
          })
        )

        dispatch(setComplaintMainData(userRes));
        return {
          message: "Success",
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(error || "Error while saving complaint");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while saving complaint");
    }
  }
);

export const fileWithCircuitClerk = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "complaint/fileWithCircuitClerk",
  async (
    { ecomplaintId, navigate },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url: `ecomplaintservices/fileWithCircuitClerk/${ecomplaintId}`,
        token,
        type: "PUT",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let eComplaintMain = await response.json();
        dispatch(setComplaintMainData(eComplaintMain));

        let msg = "";
        if (
          !eComplaintMain.messageList ||
          eComplaintMain.messageList.length === 0
        ) {
          msg =
            "The complaint has been filed and new case number is " +
            eComplaintMain.complaint.caseNumber +
            ".";
          dispatch(
            updateElementValuecomplaintDashReducer({
              elementName: "successMessage",
              value: msg,
            })
          );
        } else {
          msg = eComplaintMain.messageList[0];
          dispatch(
            updateElementValuecomplaintDashReducer({
              elementName: "failureMessage",
              value: msg,
            })
          );
        }
        let gotoUI: string = "DashBoard";
        navigate("/EComplaintDashboard");
        return {
          gotoUI: gotoUI,
          message: msg,
        };
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(error || "Error while certifying complaint");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while certifying complaint");
    }
  }
);

export const submitForReviewWithSupervisor = createAsyncThunk<
  any,
  { ecomplaint: any; navigate: any },
  { state: RootState; rejectValue: string }
>(
  "complaint/submitForReviewWithSupervisor",
  async ({ ecomplaint, navigate }, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));

    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);

    try {
      const response = await doFetch({
        url: "ecomplaintservices/submitForReviewWithSupervisor",
        token,
        type: "POST",
        body: ecomplaint,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        response.json().then((result: any) => {
          dispatch(setComplaintMainData(result));
          navigate("/EComplaintDashboard");
          return result;
        });
      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(
          error || "Error while submit for review with Supervisor"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while submit for review with Supervisor");
    }
  }
);

export const getCMSIntegratedFelonyCaseNumber = async (
  ecomplaintId: number,
  felonyCaseType: string,
  token: string,
  dispatch: any
) => {
  dispatch(showHideLoadingGif(true));
  try {
    const response = await doFetch({
      url: `ecomplaintservices/getAssignedFelonyCaseNumber/${ecomplaintId}/${felonyCaseType}`,
      token,
      type: "POST",
    });
    if (response.ok) {
      dispatch(showHideLoadingGif(false));
      const caseNumberval = await response.text();
      return caseNumberval;
    } else {
      dispatch(showHideLoadingGif(false));
      return "Error while getting generated case number arrest warrant";
    }
  } catch (error: any) {
    dispatch(showHideLoadingGif(false));
    return "Error while getting generated case number arrest warrant";
  }
};

export const getAllEcCounty = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/getEcCounties",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response = await getAllEcCounties({ token });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching E Complaint Data");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching E Complaint Data"
      );
    }
  }
);

export const forceCreateProbableCauseStatement = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>("complaint/forceCreateProbableCauseStatement",
  async (
    {
      ecomplaintId,
      navigate,
    },
    { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    try {
      dispatch(showHideLoadingGif(true));
      let ecList = getState().complaintMain.ecFormList;
      let concurrentObj = getLFCConcurrentObj(ecList);

      const response: any = await doFetch({
        url: "ecomplaintservices/forceCreateProbableCauseStatement/" + ecomplaintId,
        token,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const resp = await response.json();
        if (resp && resp.ecFormList) {
          resp.ecFormList.forEach((element: any) => {
            if (element.formTypeName === REVISED_PROBABLE_CAUSE_STATEMENT) {
              let data = JSON.parse(element.formData);
              let Obj = {
                ...data,
                defAddressUnknown: data.defAddressUnknown === "Y" ? true : false,
                defForeignAddress: data.defForeignAddress === "Y" ? true : false,
                defDob: moment(data.defDob).format("YYYY-MM-DD"),
                arrestDate: moment(data.arrestDate).format("YYYY-MM-DD"),
              };
              dispatch(updateprobableCauseObject(Obj));
            }
          });
        }
        dispatch(getEcomplaintMainDetails({ ecomplaintId: ecomplaintId, callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE }));
        navigate("/probableCauseStatement")
        return resp;
      }
      return rejectWithValue("Error while fetching forceCreateProbableCauseStatement");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching forceCreateProbableCauseStatement");
      console.log('error');
    }

  }
);


export const getInitialPreTrialData = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>("complaint/getInitialPreTrialData",
  async (
    {
      ecomplaintId,
      navigate,
    },
    { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    try {
      dispatch(showHideLoadingGif(true));
      let ecList = getState().complaintMain.ecFormList;
      let concurrentObj = getLFCConcurrentObj(ecList);

      const response: any = await doFetch({
        url: "ecomplaintservices/getInitialPreTrialData/" + ecomplaintId,
        token,
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const resp = await response.json();
        if (resp && resp.ecFormList) {
          resp.ecFormList.forEach((element: any) => {
            if (element.formTypeName === PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE) {
              let data = JSON.parse(element.formData);
              dispatch(updatePreTrialReleaseRecognizanceObject(data));
              dispatch(getEcomplaintMainDetails({ ecomplaintId: ecomplaintId, callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE }));
              if (data.formId > 0) {
                navigate(
                  "/PreTrialReleaseOnOwnRecognizPage?ecFormId=" + data.formId + "&ecomplaintId=" + ecomplaintId
                );
              }
            }
          });
        }
        dispatch(updateElementValuePreTrialReleaseRecognizanceReducer({ elementName: "createStatus", value: "success" }));
        return resp;
      }
      return rejectWithValue("Error while fetching getInitialPreTrialData");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching getInitialPreTrialData");
      console.log('error');
    }

  }
);

export const getEcDocumentsStatus = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
  },
  { state: RootState; rejectValue: string }
>(
  "ecomplaint/getEcDocumentsStatus",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const { ecomplaintId } = data;
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: `ecomplaintservices/getEcDocumentsStatus/${ecomplaintId}`,
        token
      });

      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching EcDocuments");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching EcDocuments"
      );
    }
  }
);

export const saveEcomplaintDocument = createAsyncThunk<
  any,
  {
    ecomplaintId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>(
  "complaint/saveEcomplaintDocument",
  async (
    { ecomplaintId, navigate },
    { getState, rejectWithValue, dispatch }
  ) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url: `ecomplaintservices/saveEcomplaintDocument/${ecomplaintId}`,
        token,
        type: "PUT",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        let eComplaintMain = await response.json();
        if (eComplaintMain.errorInfo && eComplaintMain.errorInfo.error) {
          dispatch(updatePdfGenerationError({
            error: eComplaintMain.errorInfo.error,
            errorMsg: eComplaintMain.errorInfo.errorMessage,
          }));
        }
        dispatch(setComplaintMainData(eComplaintMain));
        navigate("/EComplaintDashboard");

      } else {
        dispatch(showHideLoadingGif(false));
        const error = await response.json();
        return rejectWithValue(error || "Error while saveEcomplaintDocument");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while saveEcomplaintDocument");
    }
  }
);

//KHL PR 1256 25/01/24 - START
export const getLoggedInUserCountiesForLFC = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "counties/getUserCountiesForLfc",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await doFetch({
        url: `countieservices/loggedInUserCountiesForLfc`,
        token,
      });

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue(
          "Error while fetching logged in user county list For LFC"
        );
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message ||
        "Error while fetching logged in user county list For LFC"
      );
    }
  }
);
//KHL PR 1256 25/01/24 - END

export const savePreTrialReleaseDataTemp = createAsyncThunk<
  any,
  {
    ecFormId: number;
    ecomplaintId: number;
    data: any;
  },
  { state: RootState; rejectValue: string }
>(
  "preTrialReleaseRecognizance/savePreTrialReleaseDataTemp",
  async (
    { ecFormId, ecomplaintId, data },
    { rejectWithValue, getState, dispatch }
  ) => {
    dispatch(showHideLoadingGif(true));
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
      const response = await doFetch({
        url:
          "ecomplaintservices/savePreTrialReleaseDataTemp/" +
          ecomplaintId +
          "/" +
          ecFormId,
        token: getState().login.token,
        body: data,
        type: "POST",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      dispatch(showHideLoadingGif(false));
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        const resp = await response.json();
        if (resp && resp.ecFormList) {
          resp.ecFormList.forEach((element: any) => {
            if (element.formTypeName === PRE_TRIAL_RELEASE_ON_OWN_RECOGNIZANCE) {
              let data = JSON.parse(element.formData);
              dispatch(updatePreTrialReleaseRecognizanceObject(data));
              dispatch(getEcomplaintMainDetails({ ecomplaintId: ecomplaintId, callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE }));
            }
          });
        }
        dispatch(updateElementValuePreTrialReleaseRecognizanceReducer({ elementName: "createStatus", value: "success" }));
        return resp;
      }
      return rejectWithValue("Error occured while creating pre trial data");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error occured while creating pre trial data");
    }
  }
);

const complaintMainManageSlice = createSlice({
  name: "ComplaintMain",
  initialState,
  reducers: {
    updateElementValueComplaintMainReducer: updateElementValue,
    updateElementCompliantObjectValue: (
      state: ComplaintMainReducer,
      action
    ) => {
      updateElementObject(state, action, state.complaint);
    },
    updateElementComplaintObjectValue: (
      state: ComplaintMainReducer,
      action
    ) => {
      let elementObj = state.complaint;
      updateElementObject(state, action, elementObj);
    },
    updateReducerElement: (
      state: ComplaintMainReducer,
      action
    ) => {
      let elementObj = state;
      updateElementObject(state, action, elementObj);
    },
    updateElementValueCountFormReducer: updateElementValue,
    updateElementObjectValueComplaintMain: (
      state: ComplaintMainReducer,
      action
    ) => {
      const object = action.payload as ComplaintMainReducer;
      return {
        ...state,
        ...object,
      };
    },
    setComplaintMainData: (state: ComplaintMainReducer, action) => {
      let note = action.payload.complaint.notes;
      const object = action.payload as ComplaintMainReducer;
      return {
        ...state,
        ...object,
        localNote: note,
        getComplaintMainStatus: "success",
      };
    },
    resetComplaintMainData: (state: ComplaintMainReducer) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder
      //KHL PR 1256 25/01/24 - START
      // getLoggedInUserCounties
      .addCase(getLoggedInUserCountiesForLFC.pending, (state) => {
        state.loggedInUserCountyForLfcFetchStatus = "loading";
        state.loggedInUserCountyForLfcFetchError = undefined;
      })
      .addCase(getLoggedInUserCountiesForLFC.fulfilled, (state, action) => {
        state.loggedInUserCountyForLfcFetchStatus = "success";
        state.lfcCountyList = action.payload;
      })
      .addCase(getLoggedInUserCountiesForLFC.rejected, (state, action) => {
        state.loggedInUserCountyForLfcFetchStatus = "error";
        state.loggedInUserCountyForLfcFetchError = action.payload;
      })
      //KHL PR 1256 25/01/24 - END

      .addCase(getEcomplaintMainDetails.pending, (state) => {
        state.getComplaintMainStatus = "loading";
        state.getComplaintError = undefined;
      })
      .addCase(getEcomplaintMainDetails.fulfilled, (state, action) => {
        let note = action.payload.complaint.notes;
        return {
          ...state,
          ...action.payload,
          localNote: note,
          getComplaintMainStatus: "success",
        };
      })
      .addCase(getEcomplaintMainDetails.rejected, (state, action) => {
        state.getComplaintMainStatus = "error";
        state.getComplaintError = undefined;
      })

      // Save note
      .addCase(saveComplaint.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(saveComplaint.fulfilled, (state, action) => {
        state.complaintStatus = "success";
        if (action.payload.identifier === "SaveNote") {
          state.successMessage = "Notes added/updated successfully.";
          state.localNote = action.payload.complaint.notes;
        } else if (action.payload.identifier === "SavePrimaryCaseNumber") {
          if (
            action.payload.complaint.primaryCaseNumber &&
            action.payload.complaint.primaryCaseNumber.trim().length > 0
          ) {
            state.successMessage = "Primary Case Number is saved successfully.";
          } else {
            state.successMessage =
              "Primary Case Number is cleared successfully.";
          }
          state.complaint.primaryCaseNumber =
            action.payload.complaint.primaryCaseNumber;
        }
      })
      .addCase(insertEcomplaintData.rejected, (state, action) => {
        state.getComplaintMainStatus = "error";
        state.getComplaintError = undefined;
      })

      // Save note
      .addCase(insertEcomplaintData.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(insertEcomplaintData.fulfilled, (state, action) => {
        state.complaintStatus = "success";
        state.complaint = action.payload.complaint;
        if (state.complaint)
          if (
            state.complaint !== null &&
            state.complaint.caseStatus === LF_SEND_TO_SA_STATUS
          ) {
            state.statechangeMsg = EC_MSG_SENTTOSA;
          } else if (
            state.complaint !== null &&
            state.complaint.caseStatus === LF_SEND_TO_OFFICER_STATUS
          ) {
            state.statechangeMsg = EC_MSG_SENTTOOFFICE;
          }
      })
      .addCase(saveComplaint.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = action.payload;
      })
      .addCase(addModifyEcCountsEcomplaint.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(addModifyEcCountsEcomplaint.fulfilled, (state, action) => {
        state.complaintStatus = "success";
      })
      .addCase(addModifyEcCountsEcomplaint.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = action.payload;
      })
      // takeBack
      .addCase(tackBack.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(tackBack.fulfilled, (state, action) => {
        state.complaintStatus = "success";
        if (action.payload.identifier === "takeBack") {
          state.successMessage = "Take back successfully.";
          state.ecFormList = action.payload.complaint.ecFormList;
        }
      })
      .addCase(tackBack.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = action.payload;
      })

      .addCase(getWitnesses.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(getWitnesses.fulfilled, (state, action) => {
        state.witnessList = action.payload;
        state.complaintStatus = "success";
      })
      .addCase(getWitnesses.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = undefined;
      })
      // Force create arrest warrant
      .addCase(forceCreateArrestWarrant.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(forceCreateArrestWarrant.fulfilled, (state, action) => {
        state.complaintStatus = "success";
      })
      .addCase(forceCreateArrestWarrant.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = action.payload;
      })
      // Force create summon
      .addCase(forceCreateSummon.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(forceCreateSummon.fulfilled, (state, action) => {
        state.complaintStatus = "success";
      })
      .addCase(forceCreateSummon.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = action.payload;
      })
      // Undo Certification
      .addCase(undoCertification.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(undoCertification.fulfilled, (state, action) => {
        state.complaintStatus = "success";
      })
      .addCase(undoCertification.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = action.payload;
      })
      // File With Circuit Clerk
      .addCase(fileWithCircuitClerk.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(fileWithCircuitClerk.fulfilled, (state, action) => {
        state.complaintStatus = "success";
      })
      .addCase(fileWithCircuitClerk.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = action.payload;
      })
      //Submit for review with Supervisor
      .addCase(submitForReviewWithSupervisor.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(submitForReviewWithSupervisor.fulfilled, (state, action) => {
        state.complaintStatus = "success";
      })
      .addCase(submitForReviewWithSupervisor.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = action.payload;
      })
      .addCase(getAllEcCounty.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(getAllEcCounty.fulfilled, (state, action) => {
        state.ecCountyList = action.payload;
        state.complaintStatus = "success";
      })
      .addCase(getAllEcCounty.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = undefined;
      })

      .addCase(reGenerateComplaint.pending, (state, action) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(reGenerateComplaint.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload
        };
      })
      .addCase(reGenerateComplaint.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = undefined;
      })

      .addCase(resubmitComplaint.pending, (state, action) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(resubmitComplaint.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload
        };
      })
      .addCase(resubmitComplaint.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = undefined;
      })
      .addCase(forceCreateProbableCauseStatement.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(forceCreateProbableCauseStatement.fulfilled, (state, action) => {
        state.complaintStatus = "success";
        state.ecFormList = action.payload.ecFormList;
        state.complaint = action.payload.complaint;
      })
      .addCase(forceCreateProbableCauseStatement.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = undefined;
      })
      .addCase(getInitialPreTrialData.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(getInitialPreTrialData.fulfilled, (state, action) => {
        state.complaintStatus = "success";
        state.ecFormList = action.payload.ecFormList;
        state.complaint = action.payload.complaint;
      })
      .addCase(getInitialPreTrialData.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = undefined;
      })
      //Save PreTrialRelease Data Tempority
      .addCase(savePreTrialReleaseDataTemp.pending, (state) => {
        state.complaintStatus = "loading";
        state.complaintError = undefined;
      })
      .addCase(savePreTrialReleaseDataTemp.fulfilled, (state, action) => {
        state.complaintStatus = "success";
        state.ecFormList = action.payload.ecFormList;
        state.complaint = action.payload.complaint;
      })
      .addCase(savePreTrialReleaseDataTemp.rejected, (state, action) => {
        state.complaintStatus = "error";
        state.complaintError = undefined;
      });
  },
});

export const {
  updateElementValueComplaintMainReducer,
  updateElementObjectValueComplaintMain,
  updateElementComplaintObjectValue,
  updateReducerElement,
  resetComplaintMainData,
  setComplaintMainData,
  updateElementCompliantObjectValue,
} = complaintMainManageSlice.actions;

export const useComplaintMainReducer = () =>
  useAppSelector((state) => state.complaintMain);

export default complaintMainManageSlice.reducer;
