import React, { useCallback } from "react";
import { Button, Col, Modal} from "react-bootstrap";
import ButtonComponent from "../common/ButtonComponent";
import FormComponent from "../common/FormComponent";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { useAppDispatch } from "../../hooks/hooks";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import { checkNotarize } from "../../redux/slice/certificationSlice";
import TextInputComponent, { TextInputProps } from "../common/TextInputComponent";
import { NOTARY_COMMISSION_EXPIRED, NOTARY_PASSWORD_NOTMATCH, NOTARY_USER_CANNOT_BE_SAMEASTHISUSER } from "../../constants/common";

let defaultLeaderSchema = yup.object().shape({
  CommissionNumber : yup.string().nullable().required("commission Number is required."),
  password : yup.string().nullable().required("password is required."),
})

interface NotarizationPopupProps {
  show: boolean;
  onHide: () => void;
  data:(name:string) => void;
}
const ViolationPopup: React.FC<NotarizationPopupProps> = (props) => {
  const { show, onHide, data} = props;

  const errorMessageAlert = useAlert();
  const dispatch = useAppDispatch();
  const complaintMainReducer = useComplaintMainReducer();
  const [commissionNo, setCommissionNo] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const handleValChange = (event: any) =>{
    if (event.target.name === "CommissionNumber"){
      setCommissionNo(event.target.value);
    }
    else if (event.target.name === "password"){
      setPassword(event.target.value);
    }
  }

  const checkNotarizeBtn = async() =>{
    setErrorKeys([""]);
    handleError([''],[]);
    let Obj = {
      CommissionNumber : commissionNo,
      password : password,
    }
    try{
      await defaultLeaderSchema.validate(Obj,{abortEarly:false,});
      let Obj1 = {
        ecomplaint : complaintMainReducer.complaint,
        password : password,
        commissionNo : commissionNo,
      };
      let data =await dispatch(checkNotarize(Obj1));
      if ("ERROR_MSG" in data.payload){
        errorPopup(data.payload.ERROR_MSG)
      }else{
        props.data(data.payload.NOTARY_USER_NAME);
        cancelPopup();
      }
  }
  catch(err:any){
    console.log(err.errors)
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
  }
    
  }

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
    },
    []
  );

  const cancelPopup = () =>{
    setErrorKeys([""]);
    handleError([''],[]);
    onHide();
  }

  const errorPopup = (err:any) =>{
    if (err ==NOTARY_USER_CANNOT_BE_SAMEASTHISUSER){
      setErrorKeys(['CommissionNumber']);
      errorMessageAlert.handleError([NOTARY_USER_CANNOT_BE_SAMEASTHISUSER]);
    }else if(err ==NOTARY_PASSWORD_NOTMATCH){
      setErrorKeys(['password']);
      errorMessageAlert.handleError([NOTARY_PASSWORD_NOTMATCH]);
    }else if(err == NOTARY_COMMISSION_EXPIRED){
      errorMessageAlert.handleError(["notary commission expired"]);
    }else{
      errorMessageAlert.handleError(["notary user not found"]);
    }
  }

  let formData = [
    {
        type : "htmlTag",
        props : {
            type : "label",
            value : "Please get your local NOTARY user to enter their credentials to complete the signature and notarization process."
        } ,
        colSize : 12, 
    },
    {
        type : "textInput",
        props : {
          name : "CommissionNumber",
          onChange : handleValChange,
          isError : errorKeys.includes("CommissionNumber"),
        },
        label : "Notary Commission Number",
        colSize : 12,
    },
    {
        type : "textInput",
        props : {
          name : "password",
          onChange : handleValChange,
          isError : errorKeys.includes("password"),
        },
        label: "Notary Password",
        colSize : 12,
    },
    {
        type: 'formButton',
        props: {
            children : "NOTARIZE",
            onClick : checkNotarizeBtn,
           },
          colSize: 12,

    },
  ]


  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-45w"
        onHide={() => cancelPopup()}
        backdrop="static"
        scrollable={true}
        centered = {true}
        Id = 'ViolationPopup'
        
      >
        <Modal.Header closeButton>
        SIGN AND NOTORIZE
        </Modal.Header>
        <Modal.Body>
        <ErrorMessageAlert
          messages={errorMessageAlert.messages}
          clearError={errorMessageAlert.clearError}
        />
        <FormComponent 
          totalColumns={12} 
          colSize={6} 
          componentData={formData}
          formCardStyle = "p-0"
          isSpace="mt-1"
          components={[
            {
              type: "formButton",
              node: (props: any) => (
                <div className = "text-center">
                <ButtonComponent {...props}>{props.children}</ButtonComponent>
                </div>
              ),
            },
            {
              type: "formTextInput",
              node: (props :TextInputProps) =>(
                <div className = "text-center">
                <TextInputComponent {...props} />
                </div>
              )
            }
          ]}
        /> 

        </Modal.Body>
        <Modal.Footer>
          <Col xs={6}></Col>
            <div>
            <Button
                variant="danger"
                onClick={() => cancelPopup()}
                className="w-100"
              >
                Cancel
              </Button>
            </div>
        </Modal.Footer>
      </Modal>
  </>
  );
};

export default ViolationPopup;
