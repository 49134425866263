import moment from "moment";
import React, { useEffect, useCallback } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useAppDispatch } from "../../hooks/hooks";
import { updateElementObjectValueCountFormObjectReducer, useCountFormReducer, saveCount, resetCountFormData, updateCountFormObject } from "../../redux/slice/countFormSlice";
import { resetEcViolationData } from "../../redux/slice/EcViolationSlice";
import ButtonComponent from "../common/ButtonComponent"
import ViolationPopup from "./ViolationPopup";
import * as yup from "yup";
import ErrorMessageAlert, { useAlert } from "../common/Alert";
import { useComplaintMainReducer } from "../../redux/slice/complaintMainSlice";
import LeaderCountPopup from "./LeaderCountPopup";
import ConfirmPopup from "../common/ConfirmPopup";
import InfoPopup from "../common/InfoPopup";
import { formatName, formatNameWithSuffix, getInchoateDesc, isDUICount, validateDate } from "../../utils/common";
import { CASEPROSECUTIONTYPELIST, COUNT_LEADER, COUNT_LEADER_MANUAL, INCHO_TYPE_ATTEMPT_DESC, INCHO_TYPE_CONSPIRACY_DESC, INCHO_TYPE_DRUG_DESC, INCHO_TYPE_SOLICIT_DESC, STATE_LIST } from "../../constants/common";
import SelectComponent from "../common/SelectComponent";
import TextInputComponent from "../common/TextInputComponent";
import OptionPopup from "../common/OptionsPopup";
import CheckBoxComponent from "../common/CheckBoxComponent";
import CKEditor from '../common/CKEditor';
import FileUploader from "../warrantlist/form/FileUploader";
import { getValidationError } from "../common/CommonUtil";


interface CountPopupProps {
  show: boolean;
  onHide: () => void;
  status: string;
}

const CountPopup: React.FC<CountPopupProps> = (props) => {
  const { show, onHide, status } = props;
  const dispatch = useAppDispatch();
  const [violationModalShow, setviolationModalShow] = React.useState(false);
  const [offenceTxtDate, setoffenceTxtDate] = React.useState("<FMC_DATE>");
  const [offenceTxtMonth, setoffenceTxtMonth] = React.useState("<FMC_MONTH>");
  const [offenceTxtYear, setoffenceTxtYear] = React.useState("<FMC_YEAR>");
  const [offenceOccurrFromDate, setoffenceOccurrFromDate] = React.useState("<OCCURR_FROM_DATE>");
  const [offenceOccurrThrough, setoffenceOccurrThrough] = React.useState("<OCCURR_THROUGH_DATE>");
  const [offenceTime, setoffenceTime] = React.useState("<FMC_OFFENSE_TIME>");
  const [municipalty, setmunicipalty] = React.useState("<MUNICIPALTY>");
  const [complainantName, setcomplainantName] = React.useState("<COMPLAINANT_NAME>");
  const [county, setcounty] = React.useState("<COUNTY>");
  const [defendantName, setdefendantName] = React.useState("<DEFENDANT_NAME>");
  const [cityVillageName, setcityVillageName] = React.useState("<CITY_VILLAGE_NAME>");
  const [violationDescription, setViolationDescription] = React.useState("<VIOLATION_DESCRIPTION>");
  const [violationCode, setViolationCode] = React.useState("<VIOLATION_CODE>");
  const { countFormObject, ecCountyList, fugitiveList } = useCountFormReducer();
  const [offenseOccurred, useoffenseOccurred] = React.useState(true);
  const [errorKeys, setErrorKeys] = React.useState([""]);
  const errorMessageAlert = useAlert();
  const { complaint, countList, witnessList } = useComplaintMainReducer();
  const refPopup = React.createRef<any>()
  const [confirmationModalShow, setConfirmationModalShow] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [showFelonyMisdemeanor, setShowFelonyMisdemeanor] = React.useState(false);
  const [originalViolationDesc, setOriginalViolationDesc] = React.useState("");
  const [showError, setShowError] = React.useState({
    show: false,
    error: "",
  });
  const [inchoateDisabled, setInchoateDisabled] = React.useState(true);
  const [isLeaderVisible, setIsLeaderVisible] = React.useState(false);
  const countFormReducer = useCountFormReducer();

  let defaultCountSchema = yup.object().shape({
    prosecution: yup.string().nullable().required("Procesution is required."),
    violationCode: yup.string().nullable().required("Select the violation to update the count."),
    offenceCounty: yup.string().nullable().required("Offence County is required."),
    offenceState: yup.string().nullable().required("Offence State is required."),
    offenceDate: yup.string().when('offenceOccurredOn', {
      is: true, then: yup.string().nullable().required('Offense date is required')
        .test("check-offenceDate-future", function (value) {
          let offenceDate: any = undefined;
          if (countFormObject.offenceDate) {
            offenceDate = moment(countFormObject.offenceDate, "YYYY-MM-DD");
          }
          let today: any = new Date();
          today.setHours(0, 0, 0, 0);
          if (offenceDate && offenceDate > today.getTime()) {
            return this.createError({
              message: "Future dates are not allowed for 'Offense occurred on date'.",
            });
          } else {
            return true;
          }
        })
    }),
    offenceOccTime: yup.string().ensure().when('offenceOccurredOn', { is: true, then: yup.string().nullable().required('offense occured time is required.') }),
    offenceFromDate: yup.string().ensure().when('offenceOccurredFrom', {
      is: true, then: yup.string().nullable().required('offense occured from is required.')
        .test("check-offenceFrom-futureDate", function (value) {
          let offenceFromDate: any = undefined;
          if (countFormObject.offenceFromDate) {
            offenceFromDate = moment(countFormObject.offenceFromDate, "YYYY-MM-DD");
          }
          let today: any = new Date();
          today.setHours(0, 0, 0, 0);
          if (offenceFromDate && offenceFromDate > today.getTime()) {
            return this.createError({
              message: "Future dates are not allowed for 'Offense occurred from'.",
            });
          } else {
            return true;
          }
        })
    }),
    offenceThroughDate: yup.string().ensure().when('offenceOccurredFrom', {
      is: true, then: yup.string().nullable().required('offense occured to is required.')
        .test("check-offenceThroughDate-future", function (value) {
          let offenceThroughDate: any = undefined;
          if (countFormObject.offenceThroughDate) {
            offenceThroughDate = moment(countFormObject.offenceThroughDate, "YYYY-MM-DD");
          }
          let today: any = new Date();
          today.setHours(0, 0, 0, 0);
          if (offenceThroughDate && offenceThroughDate > today.getTime()) {
            return this.createError({
              message: "Future dates are not allowed for 'through' date.",
            });
          } else {
            return true;
          }
        })
    }),
    invalidDateSelection: yup.string().ensure().when('invalidOffenceDate',{is: true,then: yup.string().required("'Offense occurred from' date should be less than 'through' date.")}),
    chargeNarrative: yup.string().nullable().required("Charge Narrative is required.").test('validator-ck-editor', function (value) {
      const errorMessage = getValidationError("Charge Narrative", value);
      if (errorMessage.length > 0) {
        return this.createError({
          message: errorMessage,
        });
      } else {
        return true;
      }
    }),
    citationNumber: yup.string().ensure().when('eticketadder', { is: true, then: yup.string().nullable().required("Complaint number is required") }),
    fugFrmJstcVialation: yup.string().ensure().when('isfugFrmJstcVialation', { is: true, then: yup.string().nullable().required("A Peace Bond/Fugitive from Justice charge must be filed separately.") }),
    nonfugFrmJstcVialation: yup.string().ensure().when('isnonfugFrmJstcVialation', { is: true, then: yup.string().nullable().required("Fugitive from Justice charges can only be added as a standalone complaint.") }),
    canAddFugCount: yup.string().ensure().when('canAddFug', { is: true, then: yup.string().nullable().required("A Leader Count cannot be added to a Peace Bond/Fugitive from Justice complaint. Fugitive from Justice Count has to be filed as a standalone complaint.") }),
    canAddLeadCount: yup.string().ensure().when('canAddLeaderCount', { is: true, then: yup.string().nullable().required("A Leader Count cannot be added to a Peace Bond/Fugitive from Justice complaint. Fugitive from Justice Count has to be filed as a standalone complaint.") }),
   
  });
  let textBlock1 = `In the name and by the authority of `
    + `${municipalty} ${complainantName} hereinafter called the Complainant, `
    + `on oath charges that `
    + `${countFormObject.offenceOccurredOn ?
      "at or about the hour of " + offenceTime + " on or about the " + offenceTxtDate + " of " + offenceTxtMonth + ", " + offenceTxtYear :
      "on or about the dates " + offenceOccurrFromDate + " through " + offenceOccurrThrough} `
    + `in the County of ${county}, State `
    + `of Illinois, ${defendantName} hereinafter called the `
    + `Defendant committed the offense of ${violationDescription} `
    + `in violation of ${violationCode} `
    + `in this, to wit, that the said Defendant`;
  let textBlock2 = `contrary to the form of the statute in such case made and provided and against the `
    + `peace and dignity of the People of ${cityVillageName}`;

  const inchoateList = [
    { label: "-- Select --", value: "" },
    { label: INCHO_TYPE_ATTEMPT_DESC, value: INCHO_TYPE_ATTEMPT_DESC },
    { label: INCHO_TYPE_CONSPIRACY_DESC, value: INCHO_TYPE_CONSPIRACY_DESC },
    { label: INCHO_TYPE_SOLICIT_DESC, value: INCHO_TYPE_SOLICIT_DESC },
    { label: INCHO_TYPE_DRUG_DESC, value: INCHO_TYPE_DRUG_DESC },
  ];

  useEffect(() => {
    if (show) {
      if(countFormReducer.isAddCount){
        setviolationModalShow(true)
      }
      if (countFormObject.countFrom === COUNT_LEADER && status === "create")
        setIsLeaderVisible(true);
      if (status !== "create") {
        setViolationDescription(countFormObject.violationDescription);
        if (countFormObject.editedViolation || !countFormObject.inchoate) {
          setInchoateDisabled(false);
          getOrginalDecsription()
        }
      }
      let complainantname = getComplainingWitness(complaint);
      if (complainantname !== null && complainantname !== "") {
        setcomplainantName(complainantname ? complainantname : "<COMPLAINANT_NAME>");
      }
    }
  }, [show])

  useEffect(() => {
    if (countFormObject.violationCode) {
      setViolationCode(countFormObject.violationCode);
    }
    if (countFormObject.offenceCounty) {
      setcounty(countFormObject.offenceCounty);
    }
    if (countFormObject.offenceDate) {
      setoffenceOccuranceDateText(countFormObject.offenceDate);
    }
    if (complaint.defFirstName) {
      setdefendantName(complaint.defFirstName + ' ' + complaint.defLastName)
    }
    if (countFormObject.offenceOccTime) {
      setoffenceOccuranceTimeText(countFormObject.offenceOccTime);
    }
    if (complaint.defCity) {
      setcityVillageName(complaint.defCity);
    }
    if (countFormObject.prosecution != null
      && countFormObject.prosecution === "LOCAL") {
      setcityVillageName(complaint.municipalityAgencyName);
      setmunicipalty(complaint.municipalityAgencyName + " A MUNICIPAL CORPORATION,")

    } else {
      setcityVillageName("THE STATE OF ILLINOIS");
      setmunicipalty("THE PEOPLE OF THE STATE OF ILLINOIS,");
    }
  }, [countFormObject.offenceOccTime, countFormObject.offenceDate, countFormObject.offenceCounty, countFormObject.violationCode]);

  const getOrginalDecsription = () => {
    let desc = countFormObject.violationDescription.replaceAll(countFormObject.inchoate + " - ", "");
    setOriginalViolationDesc(desc);
  }

  const showViolationModal = () => {
    setviolationModalShow(true);
    dispatch(resetEcViolationData())
  }

  const cancelViolationModal = () => {
    dispatch(resetEcViolationData());
    setviolationModalShow(false);
  }

  const showLeaderPopup = () => {
    setIsLeaderVisible(true);
  }

  const cancelLeaderPopup = () => {
    setIsLeaderVisible(false);
  }

  const getComplainingWitness = (complaint: any) => {
    if (complaint.officerAsComplainingWitness) {
      return formatName(complaint.defFirstName, complaint.defLastName, "")
    }
    else {
      if (witnessList !== null && witnessList.length > 0) {
        for (let i = 0; i < witnessList.length; i++) {
          if (witnessList[i].complainingWitness)
            return formatNameWithSuffix(witnessList[i].firstName, witnessList[i].lastName, witnessList[i].middleName, witnessList[i].suffix)
        }
      }
    }
  }

  const setoffenceOccuranceDateText = (offenceDate: any) => {
    if (offenceDate !== null && offenceDate !== "") {
      let date = moment(offenceDate, 'YYYY/MM/DD');
      let month = date.format('MMMM');
      let day = date.format('Do');
      let year = date.format('YYYY');
      setoffenceTxtDate(day);
      setoffenceTxtMonth(month);
      setoffenceTxtYear(year);
    }
  }


// const validateDate = (inputValue:any) => {
//   const regex = /^(\d{2,4})-(\d{2})-(\d{2})$/;
//   const match = regex.exec(inputValue);

//   if (!match) {
//     return null; // invalid format
//   }

//   const [, yyyy, mm, dd] = match;
//   let year = parseInt(yyyy);

//   // Adjust year for 2-digit input values
//   if (year < 100) {
//     const currentYear = new Date().getFullYear();
//     const century = Math.floor(currentYear / 100) * 100;
//     year = parseInt(yyyy) <= (currentYear % 100) ? parseInt(yyyy) + century : parseInt(yyyy) + century - 100;
//   }

//   const month = parseInt(mm);
//   const day = parseInt(dd);

//   // Check if the date is valid
//   const date = new Date(year, month - 1, day);
//   if (isNaN(date.getTime())) {
//     return null; 
//   }

//   // Return the transformed date
//   return `${year}-${mm}-${dd}`;
// };
  const setoffenceOccurFromText = (fromDate: any) => {
    
    if (fromDate !== null && fromDate !== "") {
      setoffenceOccurrFromDate(moment(fromDate).format('MM/DD/yyyy'));
    }
  }

  const setoffenceOccurToText = (toDate: any) => {
    if (toDate !== null && toDate !== "") {
      setoffenceOccurrThrough(moment(toDate).format('MM/DD/yyyy'));
    }
  }

  const setoffenceOccuranceTimeText = (time: any) => {
    if (time !== null || time !== "") {
      setoffenceTime(moment(time, 'HH:mm').format('hh:mm A'));
    }
  }

  const handleProsecutionValChange = (event: any) => {
    let { label, value } = event;
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: 'prosecution', value: label }));
  }
  const handleOffenceCountyValChange = (event: any) => {
    let { label, value } = event;
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: 'offenceCounty', value: label }));
  }
  const handleOffenceinchoateValChange = (event: any) => {
    let { label, value } = event;
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: 'inchoate', value: value }));
    if (!value) {
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "editedViolation", value: false }));
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "violationDescription", value: originalViolationDesc }));
      setViolationDescription(originalViolationDesc);
    }
    else {
      let des = value + " - " + originalViolationDesc;
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "violationDescription", value: des }));
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "editedViolation", value: true }));
      setViolationDescription(des);
    }
  }
  const handleOffenceStateValChange = (event: any) => {
    let { label, value } = event;
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: 'offenceState', value: label }));
  }
  const handleValChange = (event: any) => {
    let { name, value, options, selectedIndex } = event.target;
    if (name === 'incidentNo')
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: 'incidentNo', value: value }));
    if (name === "complaintTicketNumber") {
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: 'chargeNarrative', value: 'Count added by reference, see complaint/ticket number: ' + event.target.value }));
    }
    if (name === 'offenceOccTime')
      setoffenceOccuranceTimeText(value);
    if (name === 'offenceFromDate')
      setoffenceOccurFromText(value);
    if (name === 'offenceThroughDate')
      setoffenceOccurToText(value);
    if (name === 'offenceDate')
      setoffenceOccuranceDateText(value);
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: name, value: value }));
  }

const handleValOnBlur = (event: any) => {
  let {name, value} = event.target;
  if(name === 'offenceDate') {
     value = validateDate(value);
     setoffenceOccuranceDateText(value);  
  }
  if(name === 'offenceFromDate') {
     value = validateDate(value);
     setoffenceOccuranceDateText(value);  
  }
  if(name === 'offenceThroughDate') {
     value = validateDate(value);
     setoffenceOccuranceDateText(value);  
  }
  dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: name, value: value }));
}

  const handelChangeOnCheck = (event: any) => {
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceOccurredOn", value: event.target.checked }));
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceOccurredFrom", value: !event.target.checked }));
    if (event.target.checked) {
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceThroughDate", value: '' }));
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceFromDate", value: '' }));
      if (complaint.firstIncidentDateTime) {
        dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceDate", value: moment(complaint.firstIncidentDateTime).format('YYYY-MM-DD') }))
      }
      if (countList.length > 0) {
        countList.forEach((el: any) => {
          if (el.countnumber === 1)
            dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceOccTime", value: moment(el.offenceOccTime, 'hh:mm A').format('HH:mm') }))
        })
      }

      setoffenceOccurrThrough("<OCCURR_THROUGH_DATE>");
      setoffenceOccurrFromDate("<OCCURR_FROM_DATE>");
    }
  }
  const handelChangeFromCheck = (event: any) => {
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceOccurredFrom", value: event.target.checked }));
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceOccurredOn", value: !event.target.checked }));
    if (event.target.checked) {
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceFromDate", value: '' }));
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceOccTime", value: '' }));
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceDate", value: '' }));
      dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: "offenceThroughDate", value: '' }));
      setoffenceTxtDate("<FMC_DATE>");
      setoffenceTxtMonth("<FMC_MONTH>");
      setoffenceTxtYear("<FMC_YEAR>");
      setoffenceTime("<FMC_OFFENSE_TIME>")
    }
  }

  const handleCkEditorValChange = (name: any, editor: any) => {
    const data = editor.getData();
    dispatch(updateElementObjectValueCountFormObjectReducer({ elementName: 'chargeNarrative', value: data }));
  }

  const cancelConfirmModal = () => {
    setMessage('');
    setConfirmationModalShow(false)
  }

  const showConfirmationModal = () => {
    setConfirmationModalShow(true)
    let msg = "These citations will be removed from Leader Transmittal.Do you really want to proceed?";
    setMessage(msg);
  }

  const acceptConfirmModal = () => {
    addOrModifyCount(countFormObject);
    setConfirmationModalShow(false)
  }
 
  const isfugFrmJstcVialationfunction = (violationKey: string) => {
    let fug: string[] = [];
    fugitiveList.forEach((el: any) => {
      fug.push(el.displayValue);
    })
    if (fug.includes(countFormObject.violationkey))
      return true;
    return false;
  };

  const canAddFugFromJstcViolation = () => {
    let fug: string[] = [];
    fugitiveList.forEach((el: any) => {
      fug.push(el.displayValue);
    })
    for (let i = 0; i < countList.length; i++) {
      const violation: any = countList[i].violationkey;
      if (!(fug.includes(violation))) {
        return false;

      }
    }
    return true;
  };
 
 
  const canAddFugs = () => {
    for (let i = 0; i < countList.length; i++) {
      const from: any = countList[i].countFrom;
      if (from === COUNT_LEADER) {
        return false;
 
      }
    }
    return true;
  };
 
  const canAddLeaderCounts = () => {
    for (let i = 0; i < countList.length; i++) {
      const violation: any = countList[i].violationCode;
      const violationKey: any = countList[i].violationKey;
      if (violation && isfugFrmJstcVialationfunction(violationKey)) {
        return false;
      }
    };
    return true;
  }
 
 
  const canAddnonFugFromJstcViolation = () => {
    let fug: string[] = [];
    fugitiveList.forEach((el: any) => {
      fug.push(el.displayValue);
    })
    for (let i = 0; i < countList.length; i++) {
      const violation: any = countList[i].violationkey;
      if ((fug.includes(violation))) {
        return false;

      }
    }
    return true;
  };

  const addOrSaveClick = async () => {
    try {
      let isfugFrmJstcVialation = false;
      let isnonfugFrmJstcVialation = false;
      let canAddFug = false;
      let canAddLeaderCount = false;
      let invalidOffenceDate = false;
      let offenceFromDate: any = undefined;
      let offenceThroughDate: any = undefined;


      if (countFormObject.violationCode) {
        if (isfugFrmJstcVialationfunction(countFormObject.violationkey)) {
          if (!canAddFugs())
            canAddFug = true;
          else
            isfugFrmJstcVialation = !canAddFugFromJstcViolation();
        }
        else if (!isfugFrmJstcVialationfunction(countFormObject.violationkey)){
          isnonfugFrmJstcVialation = !canAddnonFugFromJstcViolation();
        }
        else if (countFormObject.countFrom === COUNT_LEADER){
          canAddLeaderCount = !canAddLeaderCounts();
        }
      }
      if (countFormObject.offenceFromDate) {
        offenceFromDate = moment(countFormObject.offenceFromDate, "YYYY-MM-DD");
      }
      if (countFormObject.offenceThroughDate){
        offenceThroughDate = moment(countFormObject.offenceThroughDate, "YYYY-MM-DD");
      }
      if (offenceFromDate > offenceThroughDate){
        invalidOffenceDate = true;
      }
      let Obj = {
        ...countFormObject,
        eticketadder: (countFormObject.countFrom === COUNT_LEADER_MANUAL),
        isfugFrmJstcVialation: isfugFrmJstcVialation,
        isnonfugFrmJstcVialation: isnonfugFrmJstcVialation,
        invalidOffenceDate: invalidOffenceDate,
        canAddFug: canAddFug,
        canAddLeaderCount: canAddLeaderCount,
      }
      await defaultCountSchema.validate(Obj, { abortEarly: false, });
      if (countFormObject.countFrom === COUNT_LEADER && status === "create") {
        showConfirmationModal();
      }
      else if ((countFormObject.editedViolation && countFormObject.inchoate === INCHO_TYPE_ATTEMPT_DESC)
        && (countFormObject.classOfViolation.trim() === "3" || countFormObject.classOfViolation.trim() === "4")) {
        setShowFelonyMisdemeanor(true);
      }
      else {
        addOrModifyCount(countFormObject);
      }
    } catch (err: any) {
      handleError(
        err.inner.map((e: any) => e.path),
        err.errors
      );
    }
  };

  const addOrModifyCount = async (countObj: any) => {
    let Obj1 = {
      ecomplaintId: complaint.ecomplaintId,
      countDetails: [countObj],
    }
    let data = await dispatch(saveCount(Obj1));
    if (!(data && data.payload && data.payload === "ErrorFound")) {
      cancelPopup();
    }
  }

  const handleError = useCallback(
    (_errorKeys: string[], errorMessages: string[]) => {
      setErrorKeys(_errorKeys);
      errorMessageAlert.handleError(errorMessages);
      window.scroll({ top: 0, behavior: "smooth" });
    },
    []
  );

  const cancelPopup = () => {
    resetLocalState();
    dispatch(resetCountFormData());
    onHide();
  };

  const onContinueWithUpdateFelonyMisdemeanor = (buttonName: string) => {
    let Obj = {}
    if (buttonName === "Felony") {
      Obj = {
        ...countFormObject,
        caseType: "CF",
      }
    }
    else if (buttonName === "Misdemeanor") {
      Obj = {
        ...countFormObject,
        classOfViolation: "A",
        caseType: "CM",
      };
    }
    addOrModifyCount(Obj);
    setShowFelonyMisdemeanor(false);
  };

  const resetLocalState = () => {
    setErrorKeys(['']);
    handleError([''], []);
    setInchoateDisabled(true);
    setcomplainantName("<COMPLAINANT_NAME>");
    setViolationDescription("<VIOLATION_DESCRIPTION>");
    setViolationCode("<VIOLATION_CODE>");
    setOriginalViolationDesc("");
  };

  const selectedLeaders = (list: any, isIgnoreAndContinue: boolean) => {
    if (list.length === 1) {
      setOriginalViolationDesc(list[0].violationDescription);
      dispatch(updateCountFormObject(list[0]));
      setViolationDescription(list[0].violationDescription);
      setViolationCode(list[0].violationCode);
    }
    else {
      let Obj1 = {
        ecomplaintId: complaint.ecomplaintId,
        countDetails: list,
      }
      dispatch(saveCount(Obj1))
      cancelPopup();
    }
  }

  const selectedViolation = (data: any) => {
    let Obj = {
      ...countFormObject,
      violationCode: data.violationCode,
      classOfViolation: data.classOfViolation,
      caseType: data.caseType,
      inchoate: getInchoateDesc(data.inChoateOffense),
      violationDescription: data.violationDescription,
      originalViolationId: data.violationId,
      originalEcViolation: data,
      violationkey: data.countyViolationKey,
      offenseCode: data.offenseCode,
      chargeNarrative: data.verbiage,
      editedViolation: false,
    };
    setOriginalViolationDesc(data.violationDescription);
    dispatch(updateCountFormObject(Obj));
    if ((data.inChoateOffense !== null && data.inChoateOffense.trim() !== "") || isDUICount(data.caseType.trim())) {
      setInchoateDisabled(true);
    } else {
      setInchoateDisabled(false);
    }
    setViolationDescription(data.violationDescription);
    setViolationCode(data.violationCode);
  };

  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-60w"
        onHide={() => cancelPopup()}
        backdrop="static"
        scrollable={true}
      >
        <Modal.Header closeButton ><div className="popupHeader"><b>Add/Modify Count</b></div></Modal.Header>
        <Modal.Body ref={refPopup}>
          <ErrorMessageAlert
            messages={errorMessageAlert.messages}
            clearError={errorMessageAlert.clearError}
          />
          <div className="p-2">
            <Row className="g-2">
              <Col className="d-flex align-items-center" xs={4}>
                <b>Prosecution : <span className="text-danger pe-2">*</span></b>
                <div className="position-relative flex-grow-1 ms-2">
                  <SelectComponent
                    options={[
                      ...CASEPROSECUTIONTYPELIST.map((data: any) => {
                        return {
                          label: data.label,
                          value: data.value
                        };
                      }),
                    ]}
                    onChange={handleProsecutionValChange}
                    placeholder="-- Select --"
                    isDefaultLabel={true}
                    isRequired={true}
                    isError={errorKeys.includes("prosecution")}
                    value={CASEPROSECUTIONTYPELIST
                      .map((data: any) => {
                        return {
                          label: data.value,
                          value: data.value,
                        };
                      })
                      .find(
                        (item) => {
                          if (item.label === countFormObject.prosecution)
                            return {
                              label: item.label,
                              value: item.value,
                            };
                          else if (!countFormObject.prosecution)
                            return {
                              label: "-- Select --",
                              value: "",
                            }
                        }
                      )}
                    isDisabled={(complaint.caseProsecutionType) ? true : false}
                  />
                </div>
              </Col>
              <Col>
                {!(countFormObject.countFrom === COUNT_LEADER && status !== "create") ?
                  <ButtonComponent
                    onClick={(countFormObject.countFrom === COUNT_LEADER) ? showLeaderPopup : showViolationModal}
                  >{(countFormObject.countFrom === COUNT_LEADER) ? "Search LEADER" : "Search violation"}</ButtonComponent>
                  : ""}
              </Col>
            </Row>
            <Row className="g-2 mt-1 align-items-center">
              <Col className="d-flex align-items-center" xs={4}>
                <b>Voilation Code : </b>
                <div className="position-relative flex-grow-1 ms-2">
                  <TextInputComponent
                    value={countFormObject.violationCode}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col xs={"auto"}><b>Class : </b></Col>
              <Col xs={1}>
                <TextInputComponent
                  value={countFormObject.classOfViolation}
                  disabled={true}
                />
              </Col>
              <Col xs={"auto"}><b>Case Type : </b></Col>
              <Col xs={1}>
                <TextInputComponent
                  value={countFormObject.caseType}
                  disabled={true}
                />
              </Col>
              <Col xs={"auto"}><b>Inchoate :</b></Col>
              <Col className="w-100 pe-2">
                {countFormObject.countFrom !== COUNT_LEADER ?
                  <SelectComponent
                    options={[
                      ...inchoateList.map((data: any) => {
                        return {
                          label: data.label,
                          value: data.value
                        };
                      }),
                    ]}
                    onChange={handleOffenceinchoateValChange}
                    placeholder={"-- Select --"}
                    isDefaultLabel={true}
                    name="Inchoate"
                    isRequired={false}
                    value={inchoateList
                      .map((data: any) => {
                        return {
                          label: data.label,
                          value: data.value,
                        };
                      })
                      .find(
                        (item) => {
                          if (item.label === countFormObject.inchoate)
                            return {
                              label: item.label,
                            };
                          else if (!countFormObject.inchoate)
                            return {
                              label: "-- Select --",
                              value: "",
                            }
                        }
                      )}
                    isDisabled={inchoateDisabled}
                  />
                  : ""}
              </Col>
            </Row>
            <Row className="g-2 align-items-center mt-1">
              <Col xs={"auto"}><b className="pe-2">Description :</b>&nbsp;&nbsp;&nbsp;</Col>
              <Col className="w-100 pe-2">
                <TextInputComponent
                  value={countFormObject.violationDescription}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row className="g-2 align-items-center mt-1">
              <Col xs={"auto"}><b>Offense occurred in County of : <span className="text-danger">*</span></b></Col>
              <Col xs={3} md={2} style={{ paddingLeft: "6px" }}>
                <SelectComponent
                  options={[
                    ...ecCountyList.map((data: any) => {
                      return {
                        label: data.countyName,
                        value: data.countyName
                      };
                    }),
                  ]}
                  onChange={handleOffenceCountyValChange}
                  isRequired={true}
                  isError={errorKeys.includes("offenceCounty")}
                  placeholder="-- Select --"
                  isDefaultLabel={true}
                  value={ecCountyList
                    .map((data: any) => {
                      return {
                        label: data,
                        value: data
                      };
                    })
                    .find(

                      (item: any) => {
                        if (item.label = countFormObject.offenceCounty)
                          return {
                            label: item,
                          };
                      }
                    )}
                />
              </Col>
              <Col xs={"auto"}><b className="ps-5 pe-1">In State : <span className="text-danger">*</span></b></Col>
              <Col xs={2}>
                <SelectComponent options={[
                  ...STATE_LIST.map((data: any) => {
                    return {
                      label: data,
                      value: data
                    };
                  })]
                }
                  isDefaultLabel={true}
                  onChange={handleOffenceStateValChange}
                  placeholder="-- Select --"
                  isRequired={true}
                  name="In State"
                  value={STATE_LIST
                    .map((data: any) => {
                      return {
                        label: data,
                        value: data
                      };
                    })
                    .find(
                      (item: any) => {
                        if (item.label = countFormObject.offenceState)
                          return {
                            label: item,
                          };
                      }
                    )}
                  isError={errorKeys.includes("offenceState")}
                />
              </Col>
              <Col xs={"auto"}><b className="ps-3">Secondary Incident Number :</b></Col>
              <Col className="w-100 pe-2">
                <TextInputComponent
                  name="incidentNo"
                  onChange={handleValChange}
                  value={countFormObject.incidentNo}
                />
              </Col>
            </Row>
            <Row className ="g-2 align-items-center mt-1">
              <Col xs={"auto"}>
                <CheckBoxComponent
                  type="radio"
                  label={<b>Offense occurred on date : <span className="text-danger">*</span></b>}
                  name="offenseOccurred"
                  onChange={handelChangeOnCheck}
                  checked={offenseOccurred === countFormObject.offenceOccurredOn}
                />
              </Col>
              <Col xs={2}>
                <TextInputComponent type="date"
                  name="offenceDate"
                  max={moment(new Date()).format('YYYY-MM-DD')}
                  onChange={handleValChange}
                  onBlur={handleValOnBlur}
                  disabled={!countFormObject.offenceOccurredOn}
                  value={countFormObject.offenceDate}
                  isError={errorKeys.includes('offenceDate')}
                />
              </Col>
              <Col xs={"auto"}>
                <b className="ps-5" style={{paddingRight: "5.5px"}}>at time : <span className="text-danger">*</span></b>
              </Col>
              <Col xs={2}>
                <TextInputComponent type="time"
                  name="offenceOccTime"
                  onChange={handleValChange}
                  disabled={!countFormObject.offenceOccurredOn}
                  value={countFormObject.offenceOccTime}
                  isError={errorKeys.includes("offenceOccTime")}
                />
              </Col>
            </Row>
            <Row className ="g-2 align-items-center mt-1">
              <Col xs={"auto"}>
                <CheckBoxComponent
                  type="radio"
                  name="offenseOccurred"
                  onChange={handelChangeFromCheck}
                  label={<b className="pe-3">Offense occurred from : <span className="text-danger">*</span>&nbsp;</b>}
                  checked={offenseOccurred === countFormObject.offenceOccurredFrom}
                />
              </Col>
              <Col xs={2}>
                <TextInputComponent type="date"
                  name="offenceFromDate"
                  max={moment(new Date()).format('YYYY-MM-DD')}
                  onChange={handleValChange}
                  onBlur={handleValOnBlur}
                  disabled={!countFormObject.offenceOccurredFrom}
                  value={countFormObject.offenceFromDate}
                  isError={errorKeys.includes('offenceFromDate') || errorKeys.includes("invalidDateSelection")}
                />
              </Col>
              <Col xs={"auto"}>
                <b className="ps-5">through : <span className="text-danger">*</span></b>
              </Col>
              <Col xs={2}>
                <TextInputComponent type="date"
                  name="offenceThroughDate"
                  isError={errorKeys.includes("offenceThroughDate") || errorKeys.includes("invalidDateSelection")}
                  max={moment(new Date()).format('YYYY-MM-DD')}
                  value={countFormObject.offenceThroughDate}
                  onChange={handleValChange}
                  onBlur={handleValOnBlur}
                  disabled={!countFormObject.offenceOccurredFrom}
                />
              </Col>
            </Row>
            <Row className ="g-2 align-items-center mt-1">
            {(countFormObject.countFrom === COUNT_LEADER_MANUAL) ?
                <>
                  <Col xs={"auto"}><b className="pe-2">Complaint Number : <span className="text-danger">*</span></b></Col>
                  <Col xs={3}>
                    <Row>
                      <Col xs={11}><TextInputComponent
                        name='citationNumber'
                        value={countFormObject.citationNumber}
                        onChange={handleValChange}
                        isError={errorKeys.includes("citationNumber")}
                      />
                      </Col>
                    </Row>
                  </Col>
                </>
              : ""}
              {(countFormObject.countFrom === COUNT_LEADER_MANUAL) ?
                <>
                  <Col xs={"auto"}><b className="ps-3">File Upload : </b></Col>
                  <Col xs={3}>
                    <FileUploader fileName={countFormObject.uploadedFileName} formType="add eticket" ></FileUploader>
                  </Col>
                </>
                : ""}
            </Row>
            <Row className="mt-3">
              <Col>
                  <label>
                  {textBlock1}
                   </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col><b>Charge Narrative</b></Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <CKEditor onChange={(event: any, editor: any) => {
                  handleCkEditorValChange('chargeNarrative', editor);
                }}
                  id=""
                  data={countFormObject.chargeNarrative}
                  isError={errorKeys.includes("chargeNarrative")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
              contrary to the form of the statute in such case made and provided and against the peace and dignity of the People of THE STATE OF ILLINOIS
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col className="text-center">
              <Button className="btn btn-primary pl-5 pr-5 ml-4" onClick={addOrSaveClick}>
                {(status === "edit" ? "Update " : "Add ") + (countFormObject.countFrom === COUNT_LEADER ? "Leader Count" : (countFormObject.countFrom === COUNT_LEADER_MANUAL ? "E-Ticket" : "Count"))}
              </Button>
              <> </>
              <Button className="btn btn-danger pl-5 pr-5 ml-4" onClick={cancelPopup}>Cancel</Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <ViolationPopup
        show={violationModalShow}
        onHide={cancelViolationModal}
        selectedViolationData={selectedViolation}
      />
      <LeaderCountPopup
        show={isLeaderVisible}
        onHide={cancelLeaderPopup}
        selectedLeader={selectedLeaders}
      />
      <ConfirmPopup
        show={confirmationModalShow}
        onHide={cancelConfirmModal}
        isConfirmation={acceptConfirmModal}>
        {message}
      </ConfirmPopup>
      <InfoPopup
        children={showError.error}
        onHide={() =>
          setShowError({
            show: false,
            error: "",
          })
        }
        show={showError.show}
        variant="danger"
      />
      <OptionPopup
        show={showFelonyMisdemeanor}
        header=""
        children="Please choose a type to file this Charge as"
        buttons={[{ name: "Felony", variant: "primary", onClick: () => onContinueWithUpdateFelonyMisdemeanor("Felony") },
        { name: "Misdemeanor", variant: "primary", onClick: () => onContinueWithUpdateFelonyMisdemeanor("Misdemeanor") }]}
      />
    </>
  );
};

export default CountPopup;
