import React, { useEffect } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import Footer from "./common/Footer";
import ButtonComponent from "./common/ButtonComponent";
import TextInputComponent from "./common/TextInputComponent";
import { useAppDispatch } from "../hooks/hooks";
import {
  loginUser,
  updateElementValueInLoginReducer,
  useLoginReducer,
} from "../redux/slice/loginSlice";
import { NewAgencyRegPopup } from "./user/NewAgencyRegPopup";
import { ForgotYourPasswordPopup } from "./user/ForgotYourPasswordPopup";
import AlertMessage from "./common/AlertMessage";
import { TiWarning } from "react-icons/ti";
import { TwoFactorAuthenticationPopup } from "./user/TwoFactorAuthenticationPopup";
import { useSearchParams } from "react-router-dom";

interface LoginProps {}

export const Login: React.FC<LoginProps> = (props) => {
  const dispatch = useAppDispatch();
  const { username, password, incorrectCredentialError } = useLoginReducer();
  const [showagencyModal, setShowAgencyModal] = React.useState(false);
  const [showForgotPassword, setShowForgotPassword] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const loginReducer = useLoginReducer();
  const [showTwoFactorAuthenicationModal, setShowTwoFactorAuthenicationModal] =
    React.useState(false);
  const [searchParams] = useSearchParams();
  // PR 11027 Starts
  const [testSystemAlert, setTestSystemAlert] = React.useState(false);
  // PR 11027 Ends

  useEffect(() => {
    if (loginReducer.changePwMessage) {
      let message = "";
      if (loginReducer.changePwMessage === "success") {
        message = "Password change success.";
      } else if (loginReducer.changePwMessage === "noAccount") {
        message =
          "Could not find an account matching for your password reset request. Please make a new request to change password.";
      } else if (loginReducer.changePwMessage === "noRequest") {
        message =
          "A password reset request is only valid for one-time use. Please make a new request to change password.";
      } else {
        message = "Failed to change the password.";
      }
      setAlertMessage(message);
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }

    //PR 11027 Starts
    if (
      process.env.REACT_APP_PROFILE !== "PRODUCTION" &&
      (!sessionStorage.getItem("isConfirmedTestSystem") ||
        sessionStorage.getItem("isConfirmedTestSystem") === "false")
    ) {
      setTestSystemAlert(true);
      sessionStorage.setItem("isConfirmedTestSystem", "false");
    }

    //PR 11027 Ends
  }, [loginReducer.changePwMessage, dispatch]);

  const onClickForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const handleValChange = (event: any) => {
    dispatch(
      updateElementValueInLoginReducer({
        elementName: event.target.name,
        value: event.target.value,
      })
    );
  };

  // PR 11027 Starts
  const confirm = () => {
    setTestSystemAlert(false);
    sessionStorage.setItem("isConfirmedTestSystem", "true");
  };
  // PR 11027 Ends


  useEffect(() => {
    const message = searchParams.get("logoutMessage");
    if(message!==null){
      if (message === "SessionTimeOut"){
        dispatch(
          updateElementValueInLoginReducer({
            elementName: "incorrectCredentialError",
            value: "Session time out. Please login again.",
          })
        );
      }else if (message === "TokenExpired"){
        dispatch(
          updateElementValueInLoginReducer({
            elementName: "incorrectCredentialError",
            value: "Token expired. Please login again.",
          })
        );
      }
    }
  },[])

  useEffect(()=>{
    return ()=>{
      dispatch(
        updateElementValueInLoginReducer({
          elementName: "incorrectCredentialError",
          value: "",
        })
      );
    }
  },[]);



  return (
    <>
      <div className="image-background">
        <div className="center-body">
          <AlertMessage
            message={alertMessage}
            onClose={() => setAlertMessage("")}
            variant={
              alertMessage === "Password change success." ? "success" : "danger"
            }
          />
          <Card>
            <Card.Body>
              <div className="logo">
                <img
                  alt="conscisys logo"
                  src={require("../assets/conscisys_logo.png")}
                />
              </div>
              <div className="text-center">
                <h3>
                  <img
                    src={require("../assets/logo.png")}
                    width="35"
                    height="35"
                    alt="Logo"
                  />
                  <b className="ps-2">Illinois Court Services</b>
                </h3>
              </div>
              <hr />
              <div className="mb-3 fw-bold">
                <label>Username</label>
                <TextInputComponent
                  type="text"
                  className="form-control"
                  placeholder="Enter username"
                  name="username"
                  value={username}
                  onChange={(e) => handleValChange(e)}
                />
              </div>
              <div className="mb-3 fw-bold">
                <label>Password</label>
                <TextInputComponent
                  type="password"
                  className="form-control"
                  placeholder="Enter password"
                  name="password"
                  value={password}
                  onChange={(e) => handleValChange(e)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      event.stopPropagation();
                      dispatch(
                        loginUser({
                          username: username,
                          password: password,
                        })
                      );
                    }
                  }}
                />
                <label className="text-danger">
                  {incorrectCredentialError}
                </label>
              </div>
              <div className="d-grid">
                <ButtonComponent
                  onClick={() =>
                    dispatch(
                      loginUser({
                        username: username,
                        password: password,
                      })
                    )
                  }
                >
                  Login
                </ButtonComponent>
              </div>

              <p className="forgot-password text-center mt-3">
                Forgot your{" "}
                <a
                  href="#forgot-password"
                  role="button"
                  onClick={onClickForgotPassword}
                >
                  password?
                </a>
              </p>
              <p className="forgot-password text-center mt-3">
                <a
                  href="#new-user"
                  role="button"
                  onClick={() => setShowAgencyModal(true)}
                >
                  New Agency User Registration
                </a>
              </p>
            </Card.Body>
          </Card>
        </div>
        <ForgotYourPasswordPopup
          show={showForgotPassword}
          onHide={() => setShowForgotPassword(false)}
        />
        <Footer />
      </div>
      <NewAgencyRegPopup
        show={showagencyModal}
        onHide={() => setShowAgencyModal(false)}
      />

      {/* PR 11027 Starts */}
      <Modal
        show={testSystemAlert}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <b>Test System Alert</b>
        </Modal.Header>
        <Modal.Body>
          {/* <Row>
          <Col className="text-center">
            <h1>
              <RiQuestionLine className="text-danger" />
            </h1>
          </Col>
        </Row> */}
          <Row>
            <Col className="text-center text-danger">
              <h1>
                <TiWarning />
              </h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-2 text-danger">
              <label>
                <b>
                  Attention ! This is a Test System. For Production system,
                  please visit the Production site.
                </b>
              </label>
            </Col>
          </Row>
          <hr />
          <Row className="g-2">
            <Col>
              <Button
                variant="primary"
                onClick={() => confirm()}
                className="w-100"
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <TwoFactorAuthenticationPopup
        show={showTwoFactorAuthenicationModal}
        onHide={() => setShowTwoFactorAuthenicationModal(false)}
      />
    </>
  );
};

export default Login;
