import React, { Component } from 'react';
import moment from "moment";

class Footer extends Component {
    render() {
        let thisYear = moment().format('YYYY');
        return (
            <div className="text-center mt-5">
                <div className="footer fixed-bottom pt-2 pb-2 ">
                    <div className="text-light footer-text ms-5">
                        { "Copyright © " + thisYear + " Conscisys Corporation"}
                    </div>
                </div> 
            </div>
        );
    }
}

export default Footer;