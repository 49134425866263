import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/hooks";
import { LoadingType } from "../../types";
import { doFetch } from "../../utils/doFetch";
import { updateElementObject } from "../../utils/updateElementObject";
import { updateElementValue } from "../../utils/updateElementValue";
import { RootState } from "../store";
import moment from "moment";
import { showHideLoadingGif, updateDocGenerateError } from "./commonSlice";
import { fetchCountryList } from "../../services/EComplaintService";
import { getEcomplaintMainDetails, setComplaintMainData, updateReducerElement } from "./complaintMainSlice";
import { getLFCConcurrentObj } from "./commonFormSlice";
import { FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE } from "../../constants/common";

interface probableDataObject {
  formId: number,
  caseNumber: string,
  arrestingAgency: string,
  agencyRptNo: string,
  defName: string,
  defDob: string,
  defAddressUnknown: boolean,
  defForeignAddress: boolean,
  defAddressLine1: string,
  defAddressLine2: string,
  defCity: string,
  defState: string,
  defZip: string,
  defCountry: string,
  arrestingOfficer: string,
  arrestDate: string,
  relationVictimAndDef: string,
  victimNames: string,
  victimPhoneNumbers: string,
  factsCrime: string,
  chargesAuthorized: string,
  isFileUploaded: string,
  uploadedFile: string,
  uploadedFileName: string,
  victimsAndInvolved: boolean,
}
interface probableDataState {
  saveStatus: LoadingType,
  status: LoadingType,
  countries: any[],
  probableDataObject: probableDataObject
}

const initialState = {

  saveStatus: "idle",
  status: "idle",
  countries: [],
  probableDataObject: {
    formId: 0,
    caseNumber: "",
    arrestingAgency: "",
    agencyRptNo: "",
    defName: "",
    defDob: "",
    defAddressUnknown: false,
    defForeignAddress: false,
    defAddressLine1: "",
    defAddressLine2: "",
    defCity: "",
    defState: "",
    defZip: "",
    defCountry: "",
    arrestingOfficer: "",
    arrestDate: "",
    relationVictimAndDef: "",
    victimNames: "",
    victimPhoneNumbers: "",
    factsCrime: "",
    chargesAuthorized: "",
    isFileUploaded: "",
    uploadedFile: "",
    uploadedFileName: "",
    victimsAndInvolved: true,
  }

} as probableDataState;

export const fetchProbableCauseStatementData = createAsyncThunk<
  any,
  {
    formId: number;
    navigate: any;
  },
  { state: RootState; rejectValue: string }
>("probableCauseStatement/fetchProbableCauseStatementData",
  async (
    {
      formId,
      navigate,
    },
    { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "ecomplaintservices/fetchProbableCauseStatementData/" + formId,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        if (navigate) {
          navigate("/probableCauseStatement")
        }
        return response.json();
      }
      return rejectWithValue("Error while fetching fetchProbableCauseStatementData");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching fetchProbableCauseStatementData");
      console.log('error');
    }

  }
);

export const fetchExistingProbableCauseStatementData = createAsyncThunk<
  any,
  {
    formId: number;
  },
  { state: RootState; rejectValue: string }
>("probableCauseStatement/fetchExistingProbableCauseStatementData",
  async (
    {
      formId,
    },
    { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const response: any = await doFetch({
        url: "ecomplaintservices/fetchProbableCauseStatementData/" + formId,
        token,
      });
      if (response.ok) {
        dispatch(showHideLoadingGif(false));
        return response.json();
      }
      return rejectWithValue("Error while fetching fetchExistingProbableCauseStatementData");
    } catch (error) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue("Error while fetching fetchExistingProbableCauseStatementData");
    }
  }
);

export const getCountryList = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: string }
>(
  "probableCauseStatement/getCountries",
  async (_, { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    dispatch(showHideLoadingGif(true));
    try {
      const reponse = await fetchCountryList({ token })

      if (reponse.ok) {
        dispatch(showHideLoadingGif(false));
        return reponse.json();
      } else {
        dispatch(showHideLoadingGif(false));
        return rejectWithValue("Error while fetching country list");
      }
    } catch (error: any) {
      dispatch(showHideLoadingGif(false));
      return rejectWithValue(
        error.message || "Error while fetching country list"
      );
    }
  }
);


export const saveProbableCauseStatmentData = createAsyncThunk<
  any,
  {
    formTypeName: string,
    countyName: string,
    navigate: any,
    ecProbCauseData: probableDataObject,
    ecomplaintId : number
  },
  { state: RootState; rejectValue: string }
>("probableCauseStatement/saveProbableCauseStatmentData",
  async (
    {
      formTypeName,
      countyName,
      navigate,
      ecProbCauseData,
      ecomplaintId
    },
    { getState, rejectWithValue, dispatch }) => {
    let { token } = getState().login;
    token = `Bearer ${token}`;
    let queryParam = "";
    queryParam += `formTypeName=${formTypeName}&`;
    queryParam += `countyName=${countyName}`;
    let ecList = getState().complaintMain.ecFormList;
    let concurrentObj = getLFCConcurrentObj(ecList);
    try {
            const response: any = await doFetch({
        url: "ecomplaintservices/saveProbableCauseStatmentData" + `?jwtToken=${token}` + `&${queryParam}`,
        body: ecProbCauseData,
        type: "POST",
        concurrentObject: concurrentObj,
        concurrentIdentifier: "LFC",
        dispatch: dispatch
      });
      if (response.ok) {
        const res = await response.json();
        if (res && res.errorInfo && res.errorInfo.error && res.errorInfo.validationErrors) {
          dispatch(
            updateDocGenerateError({
              error: true,
              errorMsg: res.errorInfo.validationErrors,
            })
          );
          return "ErrorFound";
        } else {
          dispatch(getEcomplaintMainDetails({ecomplaintId: ecomplaintId, callingFrom: FETCH_LF_MAIN_DETAILS_FROM_MAIN_PAGE}));
          navigate("/EComplaintMainPage");
          return res;
        }
      }
      return rejectWithValue("Error while fetching EcViolation");
    } catch (error) {
      return rejectWithValue("Error while fetching search warrant list");
      console.log('error');
    }

  }
);

export const probableCauseStatementSlice = createSlice({
  name: "probableCauseStatement",
  initialState,
  reducers: {
    updateElementValueprobableCauseStatementReducer: updateElementValue,
    updateElementObjectValue: (state: probableDataState, action) => {
      updateElementObject(state, action, state.probableDataObject);
    },
    updateprobableCauseObject: (state: probableDataState, action) => {
      state.probableDataObject = action.payload;
    },
    updateElementReducerValue: (state: probableDataState, action) => {
      updateElementObject(state, action, state);
    },
    resetprobableCauseStatement: (state: probableDataState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers(builder) {
    builder

      .addCase(fetchProbableCauseStatementData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProbableCauseStatementData.fulfilled, (state, action) => {
        state.status = "success";
        let Obj = {
          ...action.payload,
          defAddressUnknown: action.payload.defAddressUnknown === "Y" ? true : false,
          defForeignAddress: action.payload.defForeignAddress === "Y" ? true : false,
          defDob: moment(action.payload.defDob).format("YYYY-MM-DD"),
          arrestDate: moment(action.payload.arrestDate).format("YYYY-MM-DD"),
        };
        state.probableDataObject = Obj;
      })
      .addCase(fetchProbableCauseStatementData.rejected, (state) => {
        state.status = "error";
      })
      .addCase(fetchExistingProbableCauseStatementData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExistingProbableCauseStatementData.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(fetchExistingProbableCauseStatementData.rejected, (state) => {
        state.status = "error";
      })
      .addCase(getCountryList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(getCountryList.rejected, (state) => {
        state.status = "error";
      })
  }
})
export const { updateElementValueprobableCauseStatementReducer, updateElementObjectValue, updateprobableCauseObject,
  resetprobableCauseStatement, updateElementReducerValue } = probableCauseStatementSlice.actions;
export const useProbableCauseStatementReducer = () =>
  useAppSelector((state) => state.probableCauseStatement);
export default probableCauseStatementSlice.reducer;
